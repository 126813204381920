<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">

    <div class="vwTitle">
      钉钉授权免登录
    </div>

    <div class="vwTopCont">
      <div class="vwCont vwLeft VerticalMid">
        <div class="dingdingBox VerticalMid">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#fax-dingtalk-o"></use>
          </svg>
        </div>
        <!-- <img src="@/static/logo_dingding.png" class="vwImg" /> -->
      </div>
      <div class="vwCont vwMid VerticalMid">
        <svg class="icon iconSwitch" aria-hidden="true">
          <use xlink:href="#fax-arrow-left-right-alt"></use>
        </svg>
        <!-- <img src="@/static/icon/icon_convert.png" class="vwImg" /> -->
      </div>
      <div class="vwCont vwRight VerticalMid">
        <div class="logoBox VerticalMid">
          <img src="@/assets/image/logo1.png" class="vwImg" width="80" />
        </div>
      </div>
    </div>



    <div class="vwLoading" v-if="isStatus">
      <div class="vwFont">
        正在授权中
      </div>
      <i class="el-icon-loading color-primary"></i>
    </div>

    <div class="vwFail" v-else>
      <svg class="icon color-warning" aria-hidden="true">
        <use xlink:href="#fax-exclamation-triangle-alt"></use>
      </svg>
      {{ errFont }}

      <div class="divBtnBody">
        <el-button round @click="onSubmit">返回登录页面，通过账号登录</el-button>
      </div>
    </div>


  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import {
    getCode
  } from '@/plugins/dingding.js';
  export default {
    data() {
      return {
        // 动画
        fullscreenLoading: false,
        isStatus: true,
        errFont: null,
        console: [],
      };
    },
    created() {
      let that = this;
      getCode(code => {
        if (!regNull(code)) {
          // 判断是否未通过钉钉应用登录
          if (code == 'notInDingTalk') {
            warning(that, '请通过钉钉应用登录');
            that.errFont = '请通过钉钉应用登录';
            that.isStatus = false;
            recordLog(that, 4, 'dingding', '未通过钉钉应用登录', null, null, null)
            return false;
          }

          ajaxDingDingLogin(that, '/api/dingtalk/token?code=' + code, null, function(res) {


            if (res.data.success != false) {

              if (res.data.data.success != false) {
                // 判断是否为老师
                that.$RemoveKey();
                if (res.data.data.userType == 1) {
                  that.$SaveKey(new Date(), 'tokenTime');
                  that.$SaveKey(res.data.data);

                  // 教师登录
                  // 获取活动类型树形列表
                  ajaxGet(that, "/api/admin/ActivityType/treelist", null, function(resType) {
                    if (resType.length > 0) {
                      that.$SaveKey(resType, "activityTypes");
                    }

                    // 获取用户信息
                    ajaxGet(that, '/api/admin/sysuser/currentuser', null, function(resUser) {

                      if (regNullArray(resUser.userRoles)) {
                        that.$RemoveKey();
                        warning(that, "该用户没有任何角色，无法登录，请联系管理员");
                        return false;
                      } else {
                        that.$SaveKey(resUser, "currentUserData");
                        // 判断是否有管理员角色
                        let tempUser = resUser.userRoles.find(item => item.name.indexOf('管理员') != -1);
                        if (regNull(tempUser)) {
                          // 没有管理员角色
                          that.onRoleInfo(resUser, resUser.userRoles, 0);
                        } else {
                          // 有管理员角色
                          that.onRoleInfo(resUser, [tempUser], 0);
                        }
                      }
                    });
                  });
                } else if (res.data.data.userType == 2) {
                  that.$SaveKey(new Date(), 'tokenTime');
                  that.$SaveKey(res.data.data);
                  ajaxGet(that, '/api/student/student', null, function(resStudent) {
                    if (!regNull(resStudent)) {
                      // 记录学生的学号和姓名
                      that.$SaveKey(resStudent, that.$cacheStudent);
                      that.$SaveKey(resStudent.studentNo, 'StudentNo');
                      var path = decodeURIComponent(that.$route.query.url);
                      if (path != null && typeof(path) != "undefined" && path != "undefined" && path !=
                        "") {
                        var paths = path.toLowerCase().split("/login?url=");
                        var url = paths[paths.length - 1];
                        if (url != null && typeof(url) != "undefined" && url != "undefined" && url != "") {
                          that.$router.replace(url);
                        } else {
                          that.$router.replace('/student/SelfReport/index');
                          // that.$router.replace('/Student/LiteracyActivities/Index');
                        }
                      } else {
                        that.$router.replace('/student/SelfReport/index');
                        // that.$router.replace('/Student/LiteracyActivities/Index');
                      }
                    } else {
                      that.$RemoveKey();
                    }
                  });
                } else {
                  that.$RemoveKey();
                  error(that, res.data.data.message);
                  that.errFont = res.data.data.message;
                  that.isStatus = false;
                }
              } else {
                error(that, res.data.data.message);
                that.errFont = res.data.data.message;
                that.isStatus = false;
              }
            } else {
              error(that, res.data.message);
              that.errFont = res.data.message;
              that.isStatus = false;
            }

          });

        } else {
          // 未获取授权码
          recordLog(that, 4, 'dingding', '未获取临时授权码', null, {
            message: that.$GetKey('errDingDing')
          }, null)
          warning(that, '未获取临时授权码');
          that.errFont = '未获取钉钉临时授权码';
          that.isStatus = false;
        }
      });
    },
    methods: {
      onSubmit() {
        let that = this;
        that.$router.replace('/Login');
      },
      /**
       * @description 多角色时可循环登录
       * @param {Object} objUser
       * @param {Object} objItem
       * @param {Object} objCount
       */
      onRoleInfo(objUser, objItem, objCount) {
        let that = this;
        let tpActionApi = '/api/admin/sysaction/byroleid?roleId=' + objItem[objCount].roleId;
        // 获取菜单权限
        ajaxGet(that, tpActionApi, null, function(resAction) {
          if (resAction.success == true) {
            // 判断是否有权限
            if (regNullArray(resAction.data.actions)) {
              // 多角色时，当是最后角色时，则提示无权限
              if (objCount < objItem.length - 1) {
                that.onRoleInfo(objUser, objItem, objCount + 1);
              } else {
                that.$RemoveKey();
                warning(that, "当前用户的角色没有任何权限，无法登录，请联系管理员");
                that.errFont = "当前角色没有任何权限，无法登录，请联系管理员";
                that.isStatus = false;
                return false;
              }
            }

            //获取班主任是当前用户的班级
            ajaxGet(that, "/api/admin/class/userclass", null, function(resManageClass) {

              // 如果是班主任，又没有管理的班级，那就要重新循环角色
              if (objItem[objCount].isAdviser && regNullArray(resManageClass)) {
                if (objCount < objItem.length - 1) {
                  that.onRoleInfo(objUser, objItem, objCount + 1);
                } else {
                  that.$RemoveKey();
                  warning(that, "当前用户班主任无管理班级，无法登录，请联系管理员");
                  that.errFont = "当前用户班主任无管理班级，无法登录，请联系管理员";
                  that.isStatus = false;
                  return false;
                }
              }

              //当前用户所有班级范围
              ajaxGet(that, "/api/admin/sysuser/userfilter", null, function(resAllClass) {
                // 当角色名不是管理员，且不是admin时要判断当前角色有没有学生范围
                // if (objUser.name != '管理员' && objUser.userName != 'admin') {
                //   if (regNullArray(resManageClass) && regNullArray(resAllClass)) {
                //     warning(that, "当前用户没有学生范围，无法登录，请联系管理员");
                //     that.errFont = "当前用户没有学生范围，无法登录，请联系管理员";
                //     that.isStatus = false;
                //     return false;
                //   }
                // }

                // 用于缓存教师信息
                let tempTeacher = {
                  userId: objUser.userId,
                  isTeacher: objItem[objCount].isAdviser, // 是否是班主任
                  manageClass: regNullArray(resManageClass) ? [] : resManageClass, // 如果是班主任这里记录所管辖的班级
                  allClass: regNullArray(resAllClass) ? [] : resAllClass, // 学生范围
                  userName: resAction.data.userName,
                  name: resAction.data.name,
                  userRoles: objUser.userRoles,
                };

                // 缓存角色信息
                that.$SaveKey(objItem[objCount], "nowRole");
                // 记录权限信息
                that.$SaveKey(resAction.data.actions, "actions");
                // 记录菜单信息
                that.$SaveKey(resAction.data.menus, "menus");
                // 记录教师的数据
                that.$SaveKey(tempTeacher, that.$cacheTeacher);

                // 记录教师的用户名和姓名
                that.$SaveKey({
                  sysName: resAction.data.userName,
                  name: resAction.data.name
                }, that.$cacheTeacherName);

                // 记录教师的姓名（临时）
                that.$SaveKey(resAction.data.name, "Name");

                // 用于判断登录角色
                that.$SaveKey(true, "isManager");

                // 判断是否有其他页面跳转过来的
                if (!regNullArray(resAction.data.menus)) {
                  that.fullscreenLoading = true;
                  setTimeout(function() {
                    that.fullscreenLoading = false;
                    if (!regNull(that.optionUrl) && that.optionUrl.toLowerCase().indexOf('/admin/') != -
                      1) {
                      that.$router.replace(that.optionUrl);
                    } else {
                      var it = resAction.data.menus.find(x => x.isLink == true);
                      if (regNull(it)) {
                        it = resAction.data.menus[0].children.find(x => x.isLink == true);
                      }
                      that.$router.replace(it.url);
                    }
                    return false;
                  }, 500);
                }

              });
            });
          } else {
            that.$RemoveKey();
            error(that, res.data.data.message);
            that.errFont = res.data.data.message;
            that.isStatus = false;
          }
        });

      },
    },
    beforeDestroy() {
      this.$RemoveKey('errDingDing')
    }
  };
</script>

<style scoped="scoped" lang="less">
  .divBtnBody {
    margin-top: 40px;
  }

  .dingdingBox {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    background-image: linear-gradient(#3eb4fe, #2b68fd);
    color: #FFFFFF;
    box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.3);

    .icon {
      font-size: 62px;
    }
  }

  .logoBox {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.3);
  }

  .vwFail {
    text-align: center;
    padding-top: 100px;
    color: #E6A23C;

    .icon {
      margin-right: 5px;

    }
  }

  .vwLoading {
    text-align: center;
    padding-top: 100px;
    color: #606266;

    .vwFont {
      padding-bottom: 15px;
    }
  }

  .vwTitle {
    margin-top: 10%;
    padding-bottom: 50px;
    text-align: center;
    font-size: 22px;
  }

  .vwTopCont {
    width: 800px;
    margin: 0 auto;
    border-radius: 10px;
    background-color: #FFFFFF;
    border: 1px solid #eee;
    padding: 50px;
    display: flex;

    .vwCont {
      width: 33%;
      text-align: center;
    }

    .vwImg {}

    .vwMid {
      .iconSwitch {
        font-size: 72px;
      }
    }
  }
</style>
