<!--
  学生管理-学生奖励
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <search :ColNumber="3" :SearchList="SearchList" :key="random" ref="SearchList" @onSearch="onSearch"
      v-if="SearchList != null"></search>

    <!-- 搜索 -->
    <el-card class="card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          搜索
        </h3>
      </div>
      <div class="card-body">
        <el-row :gutter="24">
          <el-col :span="6" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">学期</span>
            </div>
            <div class="selBodyBox ">
              <el-select v-model="searchForm.semesterId" placeholder="请选择学期" filterable class="width_100Pie">
                <el-option v-for="(item, index) in searchForm.optSemester" :key="index" :label="item.name"
                  :value="item.semesterId">
                </el-option>
              </el-select>
            </div>
          </el-col>
<!--          <el-col :span="6" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">请假类型</span>
            </div>
            <div class="selBodyBox ">
              <el-select v-model="searchForm.typeId" placeholder="请选择请假类型" filterable class="width_100Pie" clearable>
                <el-option v-for="(item, index) in searchForm.optType" :key="index" :label="item.Text" :value="item.Value">
                </el-option>
              </el-select>
            </div>
          </el-col> -->
          <el-col :span="6" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">学生</span>
            </div>
            <div class="selBodyBox">
              <el-input v-model="searchForm.student" placeholder="请输入学生姓名/学号"></el-input>
            </div>
          </el-col>
          <el-col :span="6" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">宿舍楼</span>
            </div>
            <div class="selBodyBox">
              <el-select v-model="searchForm.buildingId" placeholder="请选择宿舍楼" filterable class="width_100Pie" clearable
                @change="onChangeBuilding">
                <el-option v-for="(item, index) in searchForm.optBuilding" :key="index" :label="item.name"
                  :value="item.buildingId">
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="6" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">楼层</span>
            </div>
            <div class="selBodyBox">
              <el-select v-model="searchForm.floorId" placeholder="请选择楼层" filterable class="width_100Pie" clearable
                @change="onChangeFloor">
                <el-option v-for="(item, index) in searchForm.optFloor" :key="index" :label="item.name"
                  :value="item.floorId">
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="6" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">房间</span>
            </div>
            <div class="selBodyBox">
              <el-select v-model="searchForm.roomId" placeholder="请选择房间" filterable class="width_100Pie" clearable>
                <el-option v-for="(item, index) in searchForm.optRoom" :key="index" :label="item.name"
                  :value="item.roomId">
                </el-option>
              </el-select>
            </div>
          </el-col>
         <el-col :span="6" class="mb-3">
             <div class="selTitleBox">
               <span class="spTitle">请假时间</span>
             </div>
             <div class="selBodyBox">
               <el-date-picker v-model="searchForm.StartDate" type="date"   class="width_100Pie"  value-format="yyyy-MM-dd">
               </el-date-picker>
             </div>
           </el-col>
        </el-row>
      </div>
      <div class="card-footer text-center">
        <el-button type="primary" class="mr-2" @click="onSearch()">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#fax-search"></use>
          </svg>
          查询
        </el-button>
        <el-button class="ml-2" @click="onReset()">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#fax-redo-alt"></use>
          </svg>
          重置
        </el-button>
      </div>
    </el-card>



    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          {{ isTeacher ? '学生请假记录' : '学生请假管理' }}
        </h3>
      </div>
      <div class="card-body p-0" v-if="itemData != null && itemData.length != 0">
        <el-table stripe :data="itemData" class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="className" label="班级" min-width="140" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="studentNo" label="学号" min-width="120" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="studentName" label="姓名" min-width="120" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="categoryName" label="请假分类" min-width="100" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="typeName" label="请假类型" min-width="100" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="days" label="请假时长(天)" min-width="120" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column label="请假时间" min-width="320">
            <template slot-scope="scope">
              <span v-if="scope.row.category == 2 || scope.row.category == 3 || scope.row.category == 5">
                  {{ scope.row.startDate | dateformatDay }}
                  至
                  {{ scope.row.endDate | dateformatDay }}
              </span>
              <span v-else>
                {{ scope.row.startDate | dateformatMinute }}
                至
                {{ scope.row.endDate | dateformatMinute }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="dormitory" label="宿舍信息" min-width="150" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column label="操作" min-width="100" fixed="right">
            <template slot-scope="scope">
              <operationBtn :btnName="'查看'"
                @click.native="onDetail(scope.row.leaveId)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>


  </div>
</template>

<script>
import '@/assets/css/Admin.css';
import comPage from '@/components/Page.vue';
import search from '@/components/search.vue';
import operationBtn from '@/components/operationBtn.vue';
export default {
  name: '',
  components: {
    comPage,
    search,
    operationBtn
  },
  data() {
    return {
      fullscreenLoading: false,
      random: 0, //刷新组件
      page: null, //分页查询接口返回的结果
      itemData: [], //列表数据
      pageBtnList: null, //分页左侧按钮集合
      paging: { //分页数据
        pageLength: 0, // 总共页数
        Size: 10, // 当前请求数量
        Index: 1 //当前在第几页
      },
      SearchList: null, //搜索集合
      isPower: {
        leavemgt_default_teach: false,
        leavemgt_default_politics: false,
        leavemgt_default_dorm: false,


        leavemgt_teacher_create: false,
        leavemgt_teacher_default: false,
        leavemgt_teacher_edit: false,
        leavemgt_teacher_delete: false,
      },
      searchForm: {
        // 学期
        optSemester: [],
        semesterId: null,
        // 请假类型
        optType: [],
        typeId: null,
        // 学生
        student: null,
        // 宿舍楼
        optBuilding: [],
        buildingId: null,
        // 层
        floorId: null,
        optFloor: [],
        // 房间
        optRoom: [],
        roomId: null,
        // 班级
        classId: null,
        optClass: [],
        // 请假时间
        dateRange: [],
        StartDate:dateFormat(new Date(), '-', 'YYYY-MM-DD'),
        copyId: null,
      },
      searchFormReset: null,



      url: null, //当前页面的路径
      // 判断是否为班主任
      isTeacher: false,
      isDorm: false,
      isIdentity: null,
    };
  },
  created() {
    let that = this;
    ajaxGet(that, "/api/admin/Semester", null, function (resSemester) {
      that.searchForm.optSemester = resSemester;
      if (!regNullArray(resSemester)) {
        let tpSemester = resSemester.find(item => item.isCurrent == true);
        if (regNull(that.searchForm.semesterId)) {
          that.searchForm.semesterId = regNull(tpSemester) ? resSemester[0].semesterId : tpSemester.semesterId;
        }
      }
      // 请假类型
      ajaxGet(that, '/api/admin/askforleave/type', null, function (resType) {
        that.searchForm.optType = resType;
          // 宿舍楼
          ajaxGet(that, '/api/admin/building/enable', null, function (resBuilding) {
            that.searchForm.optBuilding = resBuilding;
            that.searchFormReset = JSON.parse(JSON.stringify(that.searchForm));
            pagingPage(that);
          });
      });
    });



  },
  methods: {
    /**
     * @description 宿舍楼楼层选择事件
     * @param {*} objValue
     */
    onChangeFloor(objValue) {
      let that = this;
      if (!regNull(objValue)) {
        ajaxGet(that, '/api/admin/room/floor/' + objValue, null, function (resRoom) {
          let tpData = resRoom.filter(item => item.enable == true);
          that.searchForm.optRoom = regNullArray(tpData) ? [] : tpData.sort((a, b) => a.name - b.name);
        });
      } else {
        that.searchForm.roomId = null;
        that.searchForm.optRoom = [];
      }

    },
    /**
     * @description 宿舍楼选择事件
     * @param {*} objValue
     */
    onChangeBuilding(objValue) {
      let that = this;
      if (!regNull(objValue)) {
        ajaxGet(that, '/api/admin/floor/building/enable/' + objValue, null, function (resFloor) {
          that.searchForm.optFloor = regNullArray(resFloor) ? [] : resFloor.sort((a, b) => a.name - b.name);
        });
      } else {
        that.searchForm.floorId = null;
        that.searchForm.optFloor = [];
      }
    },
    /**
     * @description 重置
     */
    onReset() {
      let that = this;
      that.searchForm = JSON.parse(JSON.stringify(that.searchFormReset));
      pagingPage(that);
    },
    /**
     * @description 列表序号索引
     * @param {Object} index
     */
    indexMethod(index) {
      return (this.paging.Index - 1) * this.paging.Size + index + 1;
    },
    /**
     * @description 详情
     * @param {*} Id
     */
    onDetail(Id) {
      let that = this;
      that.$router.push({
        path: "/Admin/LeaveMGT/Default/" + Id,
        query: {
          url: that.$router.history.current.fullPath
        }
      });
    },
    pageBtnClick(index) {
      //分页组件左侧的按钮事件，按顺序进行判断
    },
    /**
     * @description 搜索
     */
    onSearch() {
      let that = this;
      that.paging.Index = 1;
      pagingPage(that);
    },
    pagingClick(type, val) {
      let that = this;
      if (type == 1) {
        //更改每页条数触发的事件
        that.paging.Size = val;
        that.paging.Index = 1;
      } else {
        //更改当前页时触发的事件
        that.paging.Index = val;
      }
      pagingPage(that);
    },
  },
  watch: {
    $route() {
      let that = this;
      pagingPage(that);
    }
  }
};

function pagingPage(that) {



  that.fullscreenLoading = true;
  let start = new Date(that.searchForm.StartDate).setHours(0, 0, 0, 0);
  let end = new Date(that.searchForm.StartDate).setHours(23, 59, 59, 999);
  let tpParams = {
    PageNumer: that.paging.Index,
    PageSize: that.paging.Size,
    // SemesterId: that.searchForm.semesterId,
    BuildingId: that.searchForm.buildingId,
    ClassId: that.searchForm.classId,
    Student: that.searchForm.student,
    FloorId: that.searchForm.floorId,
    RoomId: that.searchForm.roomId,
    StartDate:timeTransform(start),
    endDate : timeTransform(end),
    // Identity: 1,
    CopyType: that.searchForm.copyId,
    Category:4,
  };

  ajaxGet(that,'/api/admin/askforleave/search' , tpParams, function (resData) {
    that.fullscreenLoading = false;
    that.page = resData;
    that.itemData = resData.items;
  });

}



</script>

<style scoped="scoped">
.selTitleBox {
  font-size: 14px;
  margin-bottom: 5px;
}

.selTitleBox .spTitle {
  color: #303133;
}
</style>
