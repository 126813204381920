<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <!--   <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          搜索
        </h3>
      </div>
      <div class="card-body ">
        <el-form ref="form" :model="searchForm" label-width="80px">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="学期">
                <el-select v-model="value" placeholder="请选择" class="width_100Pie">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="月份">
                <el-select v-model="value" class="width_100Pie" placeholder="请选择">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="周">
                <el-select v-model="value" placeholder="请选择" class="width_100Pie">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="时间范围">
                <el-date-picker v-model="value1" type="daterange" range-separator="至" start-placeholder="开始日期"
                  end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="card-footer text-center">
        <el-button type="primary" class="mr-2" slot="append" icon="el-icon-search" @click.native="onSearch()">查询
        </el-button>
        <el-button slot="append" class="mr-2" icon="el-icon-refresh" @click.native="onResetSearch()">重置</el-button>
      </div>
    </el-card> -->


    <!-- 列表-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          综合之星信息
        </h3>
        <div class="card-tools" v-if="isPower.literacystarconfig_create">
          <ul class="nav nav-pills ml-auto">
            <li class="nav-item">
              <el-button type="success" icon="el-icon-circle-plus-outline" @click="onCreate()">新增</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table :data="itemData" stripe class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="name" label="综合之星名称" class="elColumn" min-width="180" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="rangeString" label="评比范围" class="elColumn" min-width="100" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column label="时间范围" class="elColumn" min-width="240">
            <template slot-scope="scope">
              {{scope.row.startDate | dateformatDay}} 至 {{scope.row.endDate | dateformatDay}}
            </template>
          </el-table-column>
          <el-table-column label="是否计算" class="elColumn" width="100">
            <template slot-scope="scope">
              <span class="text-success" v-if="scope.row.isCalculation">已计算</span>
              <span class="text-muted" v-else>未计算</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="240" fixed="right">
            <template slot-scope="scope">
              <operationBtn :btnName="'查看'" v-if="isPower.literacystarconfig_details" ref="detail" @click.native="onDetail(scope.row.starId)"></operationBtn>
              <operationBtn :btnName="'编辑'" v-if="isPower.literacystarconfig_edit&&!scope.row.isCalculation" ref="update" @click.native="onUpdate(scope.row.starId)"></operationBtn>
              <operationBtn :btnName="'删除'" v-if="isPower.literacystarconfig_delete&&!scope.row.isCalculation" ref="delete" @click.native="onDelete(scope.row.starId)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>

      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  export default {
    components: {
      comPage,
      operationBtn
    },
    data() {
      return {
        // 加载动画
        fullscreenLoading: false,
        //分页查询接口返回的结果
        page: null,
        //分页左侧按钮集合
        pageBtnList: null,
        //分页数据
        paging: {
          // 总共页数
          pageLength: 0,
          // 当前请求数量
          Size: 10,
          //当前在第几页
          Index: 1
        },
        // 列表数据
        itemData: [],
        //搜索集合
        SearchList: null,
        //增删改查 权限管理
        isPower: {
        		literacystarconfig_edit: false,
        		literacystarconfig_delete: false,
        		literacystarconfig_create: false,
        		literacystarconfig_details: false,
        },
        thWidth:100,
      }
    },
    watch: {
      $route() {
        let that = this;
        onGetData(that);
      }
    },
    methods: {
      /**
       * @description 权限验证
       * @param {Object} that
       */
      onIsPower(that){
        isPower(that, "literacystarconfig_edit","literacystarconfig_edit");
        isPower(that, "literacystarconfig_delete","literacystarconfig_delete");
        isPower(that, "literacystarconfig_create","literacystarconfig_create");
        isPower(that, "literacystarconfig_details","literacystarconfig_details");
      },


      /**
       * @description 编辑
       * @param {Object} _valID id
       */
      onUpdate(_valID) {
        let that = this;
        that.$router.push({
          path: "/Admin/LiteracyStarConfig/Update/" + _valID,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      /**
       * @description 查看详情
       * @param {Object} _valID ID
       */
      onDetail(_valID) {
        let that = this;
        that.$router.push({
          path: "/Admin/LiteracyStarConfig/Details/" + _valID,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      /**
       * @description
       * @param {Object} _valID
       */
      onDelete(_valID) {
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, "/api/admin/literacystar/" + _valID, null, function(r) {
              that.fullscreenLoading = false;
              onGetData(that);
            });
          }
        })
      },

      /**
       * @description 搜索方法
       */
      onSearch() {
        let that = this;
        onGetData(that);
      },
      /**
       * @description 重置搜索内容
       */
      onResetSearch() {
        let that = this;
        that.infoForm = JSON.parse(JSON.stringify(that.infoFormReset));
        onGetData(that);
      },
      /**
       * @description 分页按钮
       * @param {Object} type
       * @param {Object} val
       */
      pagingClick(type, val) {
        let that = this;
        if (type == 1) { //更改每页条数触发的事件
          that.paging.Size = val;
          that.paging.Index = 1;
          onGetData(that);
        } else { //更改当前页时触发的事件
          that.paging.Index = val;
          onGetData(that);
        }

      },

      /**
       * @description 分页组件左侧的按钮事件，按顺序进行判断
       * @param {Object} index
       */
      pageBtnClick(index) {},
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },
      /**
       * @description 新增
       */
      onCreate() {
        let that = this;
        that.$router.push({
          path: "/Admin/LiteracyStarConfig/Create",
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
    },
    created() {
      let that=this;
      onGetData(that);
      that.onIsPower(that);
      
      
      that.thWidth=onGetWidth(that,[
        that.isPower.literacystarconfig_details,
        that.isPower.literacystarconfig_edit,
        that.isPower.literacystarconfig_delete
      ])+20;
    }
  };

  /**
   * @description 加载列表数据
   * @param {Object} that
   */
  function onGetData(that) {
    that.fullscreenLoading = true;
    let _data = {
      Name: '',
      IsCalculation: null,
      PageNumer: that.paging.Index,
      PageSize: that.paging.Size,
    }
    ajaxGet(that, '/api/admin/literacystar/search', _data, function(resData) {
      that.fullscreenLoading = false;
      that.page = resData;
      that.itemData = resData.items;
    });
  }
</script>

<style>
</style>
