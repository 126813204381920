<!--
  班级获奖
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <search :ColNumber="3" :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null">
    </search>
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          班级获奖
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item" v-if="isPower.classprize_import == true">
              <!--导入-->
              <comImport @onImportSuccess="onImportSuccess" :template="'/templates/班级奖励导入模版.xlsx'" :path="uploadInfoApi"
                :btnIcon="'el-icon-upload2'" :title="'批量导入'" :btnName="'导入'" ref="conImportInfoFile">
              </comImport>
            </li>
            <li class="nav-item" v-if="isPower.classprize_create">
              <el-button type="success" icon="el-icon-circle-plus-outline" @click="onAdd()">新增</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="item!=null && item.length!=0">
        <el-table :data="item" stripe class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="className" label="班级" class="elColumn" width="160" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="name" label="获奖名称" class="elColumn" min-width="200" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="type" label="获奖类型" class="elColumn" width="120" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="level" label="获奖级别" class="elColumn" width="120" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="时间" class="elColumn" width="130">
            <template slot-scope="scope">
              {{scope.row.prizeDate | dateformat('YYYY-MM-DD')}}
            </template>
          </el-table-column>
          <el-table-column label="操作" :width="200" fixed="right">
            <template slot-scope="scope">
              <operationBtn :btnName="'查看'" v-if="isPower.classprize_details"
                @click.native="onDetails(scope.row.prizeId)"></operationBtn>
              &nbsp;
              <el-dropdown v-if="isPower.classprize_edit || isPower.classprize_delete" trigger="click"
                @command="handleCommand($event,scope.row.prizeId)">
                <el-button size="mini">
                  更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="isPower.classprize_edit" class="color-warning"
                    command="onEdit">编辑</el-dropdown-item>
                  <el-dropdown-item divided v-if="isPower.classprize_delete" class="color-danger"
                    command="onDelete">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>

    </el-card>


  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import comImport from '@/components/Import.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';

  export default {
    name: '',
    components: {
      comImport,
      comPage,
      operationBtn,
      search
    },
    data() {
      return {
        teacherdata: [],
        fullscreenLoading: false,
        page: null, //分页查询接口返回的结果
        item: [], //列表数据
        pageBtnList: null, //分页左侧按钮集合
        paging: { //分页数据
          pageLength: 0, // 总共页数
          Size: 10, // 当前请求数量
          Index: 1 //当前在第几页
        },
        SearchList: null, //搜索集合
        isPower: {
          //增删改查 权限管理
          classprize_create: false,
          classprize_details: false,
          classprize_edit: false,
          classprize_delete: false,
          classprize_import: false,
        },

        TeacherInfo: {}, // 教师信息

        // 上传学生信息接口
        uploadInfoApi: '/api/admin/classprize/import',
      };
    },
    created() {
      let that = this;
      that.TeacherInfo = onGetNowUser(that);
      isPower(that, 'classprize_create', 'classprize_create');
      isPower(that, 'classprize_details', 'classprize_details');
      isPower(that, 'classprize_edit', 'classprize_edit');
      isPower(that, 'classprize_delete', 'classprize_delete');
      isPower(that, 'classprize_import', 'classprize_import');
      pagingPage(that);
    },
    methods: {
      /**
       * @description 更多操作
       * @param {Object} command
       * @param {Object} objValID
       */
      handleCommand(command, objValID) {
        let that = this;
        switch (command) {
          case 'onEdit':
            that.onEdit(objValID);
            break;
          case 'onDelete':
            that.onDelete(objValID);
            break;
        }
      },
      /**
       * @description 导出学生信息成功后执行
       * @param {Object} param
       */
      onImportSuccess(param) {
        let that = this;
        pagingPage(that);
      },
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },
      /**
       * @description 删除
       * @param {Object} Id
       */
      onDelete(Id) {
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, "/api/admin/classprize/" + Id, null, function(r) {
              that.fullscreenLoading = false;
              pagingPage(that);
            });
          }
        })
      },
      /**
       * @description 详情
       * @param {Object} Id
       */
      onDetails(Id) {
        let that = this;
        that.$router.push({
          path: "/Admin/ClassPrize/Details/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      /**
       * @description 编辑
       * @param {Object} Id
       */
      onEdit(Id) {
        let that = this;
        that.$router.push({
          path: "/Admin/ClassPrize/Edit/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      /**
       * @description 新增
       */
      onAdd() {
        let that = this;
        that.$router.push({
          path: '/Admin/ClassPrize/Create',
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      /**
       * @description 搜索
       * @param {Object} params
       */
      onSearch(params) {
        let that = this;
        let data = {};

        let searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = Join(element.data);
          }
        });
        data.PageNumer = 1;
        routerPath(that, "/Admin/ClassPrize/Index", data, null, "PageSize");
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      pagingClick(type, val) {
        let that = this;
        let url = "/Admin/ClassPrize/Index";
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "Name",
            "ClassId",
            "Time"
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "Name",
            "ClassId",
            "Time"
          );
        }
      },
    },
    watch: {
      $route() {
        let that = this;
        pagingPage(that);
      }
    }
  };

  function pagingPage(that) {
    paging(
      that,
      function(r) {
        let Myurl = "/api/admin/classprize/search";
        that.fullscreenLoading = true;

        let resultClass = that.TeacherInfo.isTeacher ? that.TeacherInfo.manageClass : that.TeacherInfo.allClass;

        var ClassId = r.ClassId;
        var classList = [];
        resultClass.forEach((item) => {
          classList.push({
            Title: item.alias,
            Value: item.classId,
          })
        });

        if (that.TeacherInfo.isTeacher) {
          if (regNull(ClassId)) {
            ClassId = resultClass[0].classId;
          }
        };
        that.SearchList = [{
            type: "input",
            Name: "获奖名称",
            data: r.Name,
            holder: "请输入获奖名称关键字",
            zhi: "Name",
          },
          {
            type: "select",
            Name: "班级",
            data: ClassId,
            select: classList,
            zhi: "ClassId",
            resetData: ClassId,
            isClear: !that.TeacherInfo.isTeacher, //select是否可以清除
          },
          {
            type: "time",
            Name: "日期",
            data: r.Time,
            zhi: "Time"
          }
        ];

        let data = {};
        if (r.Time != null) {
          var DateList = r.Time.split(',');
          data = {
            PageNumer: r.PageNumer,
            PageSize: r.PageSize,
            ClassId: ClassId,
            Name: r.Name,
            PrizeDateStart: DateList[0],
            PrizeDateEnd: DateList[1]
          };
        } else {
          data = {
            PageNumer: r.PageNumer,
            PageSize: r.PageSize,
            Name: r.Name,
            ClassId: ClassId,
          };
        }
        that.paging.Index = r.PageNumer;
        that.paging.Size = r.PageSize;
        that.item = [];
        ajaxGet(that, Myurl, data, function(r1) {
          that.page = r1;
          that.item = r1.items;
          that.fullscreenLoading = false;
        });

      },
      "Name",
      "ClassId",
      "Time"
    );
  }

</script>

<style scoped="scoped">
</style>

