<!--
  我的成绩 - 成绩详情
-->
<template>
  <div class="SubWebPageStudent" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form label-width="100px" v-if="itemData && itemData.length > 0">
      <el-card class="box-card" v-for="(item,index) in itemData" :key="index">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{item.semester}}
          </h3>
        </div>
        <div class="card-body p-0">
          <el-row :gutter="24">
          	<el-col :span="24">
          			<div class="card-body">
          			  <div class="colRemarksCont" v-html="item.description"></div>
          			</div>
                <div class="card-footer">
                  <el-tag size="medium" style="float: right;" v-if="item.commentsRank=='优'" type="success" effect="plain">
                    {{item.commentsRank}}
                  </el-tag>
                  <el-tag size="medium" style="float: right;" v-if="item.commentsRank=='良'" type="warning" effect="plain">
                    {{item.commentsRank}}
                  </el-tag>
                  <el-tag size="medium" style="float: right;" v-if="item.commentsRank=='合格'" type="info" effect="plain">
                    {{item.commentsRank}}
                  </el-tag>
                  <el-tag size="medium" style="float: right;" v-if="item.commentsRank=='不合格'" type="danger" effect="plain">
                    {{item.commentsRank}}
                  </el-tag>
                </div>
          	</el-col>
          </el-row>
        </div>
      </el-card>
    </el-form>
    
    <el-card class="box-card" v-else>
      <div class="card-body">
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
  </div>
</template>

<script>

  import '@/assets/css/Student.css';
  export default{
    components:{

    },
    data(){
      return{
        // 动画
        fullscreenLoading: false,
        dialogVisible: false,
        //列表数据
        itemData: [],
      };
    },
    methods:{
      onBack()
      {
      	let that = this;
      	back(that);
      },
    },
    created()
    {
      let that = this;
      that.fullscreenLoading = true;
      ajaxGet(that, "/api/student/growth/studentcommensearch", null, function(resData) {
      	that.fullscreenLoading = false;
      	that.itemData = resData;
      });
    },
    mounted()
    {

    },
    filters:{
      flNoValue(obj){
        if (obj == null || typeof(obj) == "undefined" || obj == ''){
          return '暂无数据';
        }
        return obj;
      }
    },

  };


</script>

<style scoped="scoped">

</style>
