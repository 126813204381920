<!--
  五育评价 - 素养参数
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <!-- 搜索 -->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">搜索</h3>
      </div>
      <div class="card-body">
        <el-row :gutter="20">
          <el-col :lg="6" :sm="12" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">学期</span>
            </div>
            <div class="selBodyBox">
              <el-select v-model="selectForm.semesterId" placeholder="请选择学期" class="width_100Pie">
                <el-option v-for="item in optSemester" :key="item.semesterId" :label="item.name" :value="item.semesterId"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :lg="6" :sm="12" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">班级</span>
            </div>
            <div class="selBodyBox">
              <el-select v-model="selectForm.classId" placeholder="请选择班级" class="width_100Pie">
                <el-option v-for="item in optClass" :key="item.classId" :label="item.alias" :value="item.classId"></el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="card-footer text-center">
        <el-button type="primary" class="mr-2" slot="append" icon="el-icon-search" @click.native="onSearch()">查询</el-button>
        <el-button slot="append" class="mr-2" icon="el-icon-refresh" @click.native="onResetSearch()">重置</el-button>
      </div>
    </el-card>

    <!-- 列表 -->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          学期评语
          <span class="color-danger" v-if="!regNullArray(numNoRatio)">
            （检测到有
            <b>{{ numNoRatio.length }}</b>
            名学生未有总分。）
          </span>
        </h3>
        <div class="card-tools">
          <ul class="nav nav-pills ml-auto">
            <li class="nav-item" style="margin-right: 5px" v-if="isPower.studentcomment_export">
              <el-button @click="onOpenExport" icon="el-icon-download" type="primary">导出</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="!regNullArray(itemData)">
        <el-table :data="itemData" stripe class="elTableDataTwo">
          <el-table-column type="index" label="序号" class="elColumn" width="80"></el-table-column>
          <el-table-column prop="studentNo" label="学号" class="elColumn" width="140" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="studentName" label="姓名" class="elColumn" width="160" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="score" label="分数" class="elColumn" width="120" v-if="TeacherInfo.isTeacher"></el-table-column>
          <el-table-column prop="type" label="短语级别" class="elColumn" width="140" v-if="TeacherInfo.isTeacher"></el-table-column>
          <el-table-column prop="commentsRankString" label="等第" class="elColumn" width="160">
            <template slot-scope="scope">
              <div v-if="!scope.row.status">
                {{ TeacherInfo.isTeacher ? scope.row.commentsRankString : scope.row.commentsRank }}
              </div>
              <div v-else>
                <el-select v-model="scope.row.commentsRank" placeholder="请选择等第">
                  <el-option v-for="item in optRank" :key="item.Value" :label="item.Text" :value="item.Value"></el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="description" label="评语" class="elColumn" min-width="400"></el-table-column>
          <el-table-column label="操作" :width="TeacherInfo.isTeacher ? 200 : 100" fixed="right" align="center">
            <template slot-scope="scope">
              <div v-if="TeacherInfo.isTeacher">
                <operationBtn :btnName="'确认'" ref="confirm" @click.native="onConfirm(scope.row)" v-if="scope.row.status && isPower.studentcomment_create"></operationBtn>
                <operationBtn
                  :btnName="'查看'"
                  ref="detail"
                  @click.native="onDetail(scope.row)"
                  v-else-if="isPower.studentcomment_details && !regNull(scope.row.description)"
                ></operationBtn>
                <operationBtn :btnName="'编辑'" ref="update" @click.native="onSetComment(scope.row)" v-if="isPower.studentcomment_edit"></operationBtn>
              </div>
              <div v-else>
                <operationBtn :btnName="'查看'" ref="detailuser" @click.native="onDetail(scope.row)" v-if="isPower.studentcomment_details"></operationBtn>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="card-body" v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>

    <el-dialog :title="dialogTitle" :visible.sync="isDialogShow" width="80%" :before-close="handleClose">
      <div v-if="isDetail != true">
        <div class="divCommentBox">
          <div class="divTitleBox">可选短语</div>
          <div class="divCommentBody" v-if="!regNullArray(optPhrase)">
            <div
              class="divItem"
              v-for="(item, index) in optPhrase"
              :key="Math.random() + index"
              :class="{ divActItem: currentIndex == index }"
              @click="onChangeComment(item, index)"
            >
              {{ item.content }}
            </div>
          </div>
          <div class="divCommentBody" v-else>
            <el-empty :image-size="200" description="暂无短语数据" name="3"></el-empty>
          </div>
        </div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item prop="commentsRank" label="等第">
                <el-select v-model="ruleForm.commentsRank" placeholder="请选择等第" class="width_100Pie">
                  <el-option v-for="item in optRank" :key="item.Value" :label="item.Text" :value="item.Value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item prop="description" label="评语">
                <el-input placeholder="请输入学期评语" type="textarea" clearable resize="none" :rows="5" v-model="ruleForm.description" class="width_100Pie"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <div class="pl-5 pr-5" v-else>
        <el-row :gutter="20">
          <el-col :span="12" class="colInfo">
            <label>学号</label>
            <div class="colFonts">{{ ruleForm.studentNo }}</div>
          </el-col>
          <el-col :span="12" class="colInfo">
            <label>姓名</label>
            <div class="colFonts">{{ ruleForm.studentName }}</div>
          </el-col>
          <el-col :span="12" class="colInfo" v-if="TeacherInfo.isTeacher">
            <label>学期总分分数</label>
            <div class="colFonts">{{ ruleForm.score }}</div>
          </el-col>
          <!-- <el-col :span="12" class="colInfo" v-if="TeacherInfo.isTeacher">
            <label>名次比例（%）</label>
            <div class="colFonts">{{ruleForm.typeRatio}}</div>
          </el-col> -->
          <el-col :span="12" class="colInfo">
            <label>等第</label>
            <div class="colFonts">{{ TeacherInfo.isTeacher ? ruleForm.commentsRankString : ruleForm.commentsRank }}</div>
          </el-col>
          <el-col :span="24" class="colInfo">
            <label>评语</label>
            <div class="colFonts">
              {{ ruleForm.description }}
            </div>
          </el-col>
        </el-row>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">关 闭</el-button>
        <el-button type="primary" @click="onSave()" v-if="isDetail != true">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import '@/assets/css/Admin.css';
import operationBtn from '@/components/operationBtn.vue';
import moment from 'moment';
export default {
  components: {
    operationBtn
  },
  data() {
    return {
      fullscreenLoading: false,
      selectForm: {
        classId: null,
        semesterId: null
      },
      selectFormReset: null,
      optClass: [],
      // 数据
      itemData: [],

      //弹出框
      isDialogShow: false,
      dialogTitle: null,
      isDetail: false,

      // 参数
      ruleForm: {
        semesterId: null,
        studentId: null,
        classId: null,
        description: null,
        commentsRank: null
      },
      ruleFormReset: null,
      rules: {
        commentsRank: [
          {
            required: true,
            message: '请选择等第',
            trigger: 'change'
          }
        ],
        description: [
          {
            required: true,
            message: '请输入学期评语',
            trigger: 'change'
          }
        ]
      },

      TeacherInfo: {},
      // 选择评语
      currentIndex: null,
      // 短语
      optPhrase: [],

      // 学期
      optSemester: [],
      // 等第
      optRank: [],

      numNoRatio: [],

      optAllPhrases: [],
      optOldPhrases: [],

      isPower: {
        studentcomment_create: false,
        studentcomment_edit: false,
        studentcomment_details: false,
        studentcomment_export: false
      }
    };
  },
  created() {
    let that = this;
    isPower(that, 'studentcomment_create', 'studentcomment_create');
    isPower(that, 'studentcomment_edit', 'studentcomment_edit');
    isPower(that, 'studentcomment_details', 'studentcomment_details');
    isPower(that, 'studentcomment_export', 'studentcomment_export');

    // 获取用户信息
    that.TeacherInfo = onGetNowUser(that);
    that.optClass = JSON.parse(JSON.stringify(that.TeacherInfo.manageClass));
    if (!regNullArray(that.optClass)) {
      that.selectForm.classId = that.optClass[0].classId;
    }

    that.fullscreenLoading = true;
    // 等第
    ajaxGet(that, '/api/admin/studentcomment/type', null, function (resRank) {
      // 学期
      ajaxGet(that, '/api/admin/semester', null, function (resSemester) {
        that.optSemester = resSemester;
        that.optRank = resRank;
        let tempSemester = resSemester.find((item) => item.isCurrent == true);
        if (regNull(tempSemester)) {
          that.selectForm.semesterId = regNullArray(resSemester) ? null : resSemester[0].semesterId;
        } else {
          that.selectForm.semesterId = tempSemester.semesterId;
        }
        that.selectFormReset = JSON.parse(JSON.stringify(that.selectForm));
        that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));

        that.fullscreenLoading = false;
        setTimeout(function () {
          if (that.TeacherInfo.isTeacher) {
            that.onGetData();
          } else {
            that.onGetDataUser();
          }
          return false;
        }, 500);
      });
    });
    // that.onSearch();
  },
  methods: {
    /**
     * @description 导出学期信息
     */
    onOpenExport() {
      let that = this;
      if (!regNullArray(that.itemData)) {
        let tpParams = {
          ClassId: that.selectForm.classId,
          SemesterId: that.selectForm.semesterId
        };
        that.fullscreenLoading = true;

        ajaxExport(that, that.TeacherInfo.isTeacher ? '/api/admin/studentcomment/adviserexport' : '/api/admin/studentcomment/export', tpParams, '学期评语.xls', function (resData) {
          that.fullscreenLoading = false;
        });
      } else {
        warning(that, '暂无数据可导出');
      }
    },
    /**
     * @description 去除HTML
     */
    onDelHtml(objEvent) {
      return delHtml(objEvent);
    },
    /**
     * @description 获取管理员数据
     */
    onGetDataUser() {
      let that = this;
      that.itemData = [];
      // if (!regNull(that.selectForm.classId)) {
      that.fullscreenLoading = true;
      let tpParams = {
        ClassId: that.selectForm.classId,
        SemesterId: that.selectForm.semesterId,
        pageSize: 99999
      };
      ajaxGet(that, '/api/admin/studentcomment/user/search', tpParams, function (resData) {
        that.fullscreenLoading = false;
        // 评语
        if (!regNullArray(resData.items)) {
          that.itemData = resData.items.map((item) => ({
            studentId: item.studentId,
            studentNo: item.studentNo,
            studentName: item.name,
            score: item.score,
            typeId: item.typeId,
            typeRatio: item.typeRatio,
            commentsRankString: item.commentsRankString,
            commentsRank: item.commentsRank,
            description: regNull(item.description) ? item.description : delHtml(item.description),
            status: false
          }));
        }
      });
    },
    /**
     * @description 确认事件
     * @param {*} objEvent
     */
    onConfirm(objEvent) {
      let that = this;
      if (!regNull(objEvent.commentsRank)) {
        if (!regNull(delHtml(objEvent.description))) {
          let tpParams = [
            {
              semesterId: that.selectForm.semesterId,
              classId: that.selectForm.classId,
              studentId: objEvent.studentId,
              description: delHtml(objEvent.description),
              commentsRank: setInt(objEvent.commentsRank)
            }
          ];
          that.fullscreenLoading = true;
          ajaxPost(that, '/api/admin/studentcomment/entercomment', tpParams, function (res) {
            that.fullscreenLoading = false;
            if (res.result) {
              let tempRank = that.optRank.find((item) => item.Value == objEvent.commentsRank);
              objEvent.status = false;
              objEvent.commentsRankString = regNull(tempRank) ? '更新中' : tempRank.Text;
            }
          });
        } else {
          warning(that, '学生【' + objEvent.studentName + '】还未设置评语，请先设置该学生评语');
        }
      } else {
        warning(that, '学生【' + objEvent.studentName + '】还未设置等第，请先设置该学生等第');
      }
    },
    /**
     * @description 获取
     * @param {*} objEvent
     */
    onGetPhraseContent(objEvent) {
      let that = this;
      if (!regNullArray(that.optAllPhrases)) {
        // 循环短语级别
        for (let item of that.optAllPhrases) {
          if (item.typeId == objEvent.typeId) {
            // 匹配到相对的短语级别，进行循环短语
            if (!regNullArray(item.children)) {
              let randomIndex = Math.floor(Math.random() * item.children.length);
              let tempDesc = item.children[randomIndex].content;
              item.children.splice(randomIndex, 1);
              return tempDesc;
            }
          }
        }
      }
      return null;
    },
    /**
     * @description 选择短语事件
     * @param {*} objEvent
     * @param {*} objIndex
     */
    onChangeComment(objEvent, objIndex) {
      let that = this;
      that.currentIndex = objIndex;
      that.ruleForm.description = objEvent.content;
    },
    /**
     * @description 获取数据
     */
    onGetData() {
      let that = this;
      that.fullscreenLoading = true;
      let tpParams = {
        ClassId: that.selectForm.classId,
        SemesterId: that.selectForm.semesterId
      };
      that.itemData = [];
      that.optAllPhrases = [];

      // 短语级别
      ajaxGet(that, '/api/admin/commentphrasetype', null, function (resType) {
        // 获取评语
        ajaxGet(that, '/api/admin/commentphrase', null, function (resPhrase) {
          // 设置短语
          if (!regNullArray(resPhrase) && !regNullArray(resType)) {
            resType.forEach((item) => {
              that.optAllPhrases.push({
                typeId: item.typeId,
                name: item.name,
                children: resPhrase
                  .filter((fl) => fl.typeId == item.typeId)
                  .map((mp) => ({
                    content: mp.content,
                    phraseId: mp.phraseId,
                    typeId: mp.typeId
                  }))
              });
            });

            that.optOldPhrases = resPhrase.map((item) => ({
              content: item.content,
              phraseId: item.phraseId,
              typeId: item.typeId
            }));
          }

          ajaxGet(that, '/api/admin/studentcomment/student', tpParams, function (resData) {
            that.fullscreenLoading = false;
            // 评语
            if (!regNullArray(resData)) {
              that.itemData = resData.map((item) => ({
                status: regNull(item.commentsRank),
                studentId: item.studentId,
                studentNo: item.studentNo,
                studentName: item.studentName,
                score: item.score,
                typeId: item.typeId,
                typeRatio: item.typeRatio,
                commentsRankString: item.commentsRankString,
                commentsRank: item.commentsRank,
                type: item.type,
                description: regNull(item.commentsRank) ? (regNull(item.description) ? that.onGetPhraseContent(item) : item.description) : item.description
              }));
              that.numNoRatio = resData.filter((item) => regNull(item.ratio) == true);
            }
          });
        });
      });
    },
    /**
     * @description 设置评语
     */
    onSetComment(objEvent) {
      let that = this;
      that.isDialogShow = true;
      that.dialogTitle = '设置评语【' + objEvent.studentName + '】';
      that.isDetail = false;
      that.ruleForm.semesterId = that.selectForm.semesterId;
      that.ruleForm.classId = that.selectForm.classId;
      that.ruleForm.studentId = objEvent.studentId;
      that.ruleForm.description = objEvent.description;
      that.ruleForm.commentsRank = regNull(objEvent.commentsRank) ? null : objEvent.commentsRank.toString();
      that.ruleForm.typeRatio = objEvent.typeRatio;

      if (!regNull(objEvent.typeId)) {
        that.optPhrase = that.optOldPhrases.filter((item) => item.typeId == objEvent.typeId);
      }
    },
    /**
     * @description 提交数据
     */
    onSave() {
      let that = this;
      that.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let tpParams = [
            {
              semesterId: that.ruleForm.semesterId,
              classId: that.ruleForm.classId,
              studentId: that.ruleForm.studentId,
              description: delHtml(that.ruleForm.description),
              commentsRank: setInt(that.ruleForm.commentsRank)
            }
          ];
          that.fullscreenLoading = true;
          ajaxPost(that, '/api/admin/studentcomment/entercomment', tpParams, function (res) {
            that.fullscreenLoading = false;
            if (res.result) {
              let tempRank = that.optRank.find((item) => item.Value == that.ruleForm.commentsRank);
              that.itemData.forEach((item) => {
                if (item.studentId == that.ruleForm.studentId) {
                  item.status = false;
                  item.description = that.ruleForm.description;
                  item.commentsRankString = regNull(tempRank) ? '更新中' : tempRank.Text;
                  item.commentsRank = that.ruleForm.commentsRank;
                }
              });
            }
            that.handleClose();
          });
        } else {
          setTimeout(() => {
            var isError = document.getElementsByClassName('is-error');
            isError[0].querySelector('input').focus();
          }, 1);
          return false;
        }
      });
    },
    /**
     * @description 获取联动默认值
     * @param {Object} objItem
     */
    onDefaultLinkageClass(objItem) {
      let that = this;
      that.selectForm.classId = objItem;
      that.selectFormReset = JSON.parse(JSON.stringify(that.selectForm));
    },
    /**
     * @description 联动班级事件
     * @param {Object} objItem
     */
    onChangeLinkageClass(objItem) {
      let that = this;
      if (!regNull(objItem)) {
        that.selectForm.classId = objItem;
      }
    },
    /**
     * @description 查看详情
     * @param {Object} objItem
     */
    onDetail(objItem) {
      let that = this;
      if (!regNull(objItem)) {
        that.isDialogShow = true;
        that.isDetail = true;
        that.ruleForm = objItem;
      }
    },
    /**
     * @description 关闭弹出框
     */
    handleClose() {
      let that = this;
      that.isDialogShow = false;
      that.dialogTitle = null;
      that.currentIndex = null;
      that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
      if (!that.isDetail) {
        setTimeout(function () {
          that.$refs['ruleForm'].clearValidate();
        }, 30);
      }
    },
    /**
     * @description 搜索功能
     */
    onSearch() {
      let that = this;
      if (that.TeacherInfo.isTeacher) {
        that.onGetData();
      } else {
        that.onGetDataUser();
      }
    },
    /**
     * @description 重置搜索
     */
    onResetSearch() {
      let that = this;
      that.selectForm = JSON.parse(JSON.stringify(that.selectFormReset));
      if (that.TeacherInfo.isTeacher) {
        if (!regNull(that.$refs.LinkageClass)) {
          that.$refs.LinkageClass.onSetDefault(that.selectForm.classId, 3);
        }
        that.onGetData();
      } else {
        if (!regNull(that.$refs.LinkageClass)) {
          that.$refs.LinkageClass.onReset();
        }
        that.onGetDataUser();
      }
    }
  }
};
</script>

<style scoped="scoped" lang="less">
.divCommentBox {
  margin-bottom: 30px;

  .divTitleBox {
    font-size: 18px;
    margin-bottom: 15px;
    color: #303133;
  }

  .divCommentBody {
    height: 300px;
    overflow-y: auto;

    .divItem {
      border-radius: 4px;
      border: 2px solid #ebeef5;
      background-color: #fff;
      overflow: hidden;
      transition: 0.3s;
      padding: 15px;
      margin: 15px 20px;
      font-size: 18px;
      letter-spacing: 1px;
      box-shadow: 0 1px 8px 0 rgba(130, 130, 130, 0.1);
      color: #909399;

      &:hover {
        cursor: pointer;
        border-color: #909399;
      }
    }

    .divActItem {
      border-color: #409eff !important;
      color: #303133;
    }
  }
}

.elTableDataTwo .cell {
  font-size: 16px !important;
}

.spLeafBox {
  margin-left: 10px;

  .spFonts {
    margin-left: 5px;
    color: #909399;
    font-size: 18px;
  }

  .spIcon {
    font-size: 20px;
  }
}

.spFruitBox {
  .spFonts {
    margin-left: 5px;
    font-size: 18px;
    color: #909399;
  }

  .spIcon {
    font-size: 20px;
  }
}

.selTitleBox {
  font-size: 16px;
}

.selTitleBox .spTitle {
  color: #303133;
}
</style>
<style>
.el-radio {
  margin-bottom: 0;
}

.el-table {
  font-size: 16px;
}
</style>
