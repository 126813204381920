<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">

    <div class="callout callout-warning">
      <div><small>房间号前颜色为房间状态：1、绿色为全部已打卡；2、黄色为部分未打卡；3、红色为全部未打卡。<b class="color-danger">（ 打卡人数 / 总人数 ）</b></small></div>
    </div>

    <el-card class="box-card" v-for="floor in itemData.floors" :key="floor.floorId">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          楼层：{{ floor.name }}
          <span class="spFloorEnable">打卡状态：
            <span class="color-success" v-if="floor.name == '1'">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#fax-circle"></use>
              </svg>
              全部已打卡
            </span>
            <span class="color-danger" v-else-if="floor.name == '2'">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#fax-circle"></use>
              </svg>
              全部未打卡
            </span>
            <span class="color-warning" v-else>
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#fax-circle"></use>
              </svg>
              部分未打卡
            </span>
          </span>
        </h3>
      </div>
      <div class="card-body" v-if="!regNullArray(floor.rooms)">
        <el-row :gutter="24">
          <el-col :span="4" v-for="(room, index) in onGetRoomData(floor.rooms)" :key="room.roomId">
            <el-tooltip class="TooltipItem" effect="dark" content="点击查看详情" placement="top">
              <div class="info-box" @click="onDialogRoom(room)">
                <span class="info-box-icon bg-primary" v-if="index == 0">
                  {{ room.beds.length }} / {{ room.beds.length }}
                </span>
                <span class="info-box-icon bg-danger" v-else-if="index == 1">
                  {{ room.beds.length }} / {{ room.beds.length }}
                </span>
                <span class="info-box-icon bg-success" v-else>
                  {{ room.beds.length }} / {{ room.beds.length }}
                </span>
                <div class="info-box-content">
                  <span class="info-box-text">{{ room.name }}</span>
                </div>
              </div>
            </el-tooltip>
          </el-col>
        </el-row>
      </div>
      <div class="card-body" v-else>
        <el-empty :image-size="100" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>


    <div class="row">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
      </div>
    </div>

    <!-- 二维码 -->
    <el-dialog :title="dialogTitle" :visible.sync="isShowRoom" width="65%" :close-on-click-modal="false"
      :close-on-press-escape="false">
      <div class="dialogBody" v-if="!regNullArray(bedData)">
        <el-row :gutter="20">
          <el-col :md="24" :lg="12" v-for="(item, index) in bedData" :key="item.bedId + '-' + index">
            <div class="itemBox">
              <!-- 头像 -->
              <div class="divAvatar">
                <el-image :src="onGetAvatar(item.photo)" class="elImage">
                  <div slot="error" class="imageSlot VerticalMid">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#fax-user-alt"></use>
                    </svg>
                  </div>
                </el-image>
              </div>
              <!-- 信息 -->
              <div class="divInfo" v-if="!regNull(item.studentId)">
                <div class="divFonts">床位：{{ item.name }}</div>
                <div class="divFonts">学号：{{ item.studentNo }}</div>
                <div class="divFonts">姓名：{{ item.studentName }}</div>
                <div class="divFonts">班级：{{ item.className }}</div>
                <div class="divFonts">班主任：{{ item.teacherName }}</div>
                <div class="divFonts">班主任电话：13299890009</div>
                <div class="divFonts">状态：
                  <b class="color-success" v-if="index == 1">已打卡</b>
                  <b class="color-danger" v-else-if="index == 2">未打卡</b>
                  <b class="color-warning" v-else>已请假</b>
                </div>

                <div class="btnCheckIn" v-if="index == 2">
                  <el-button size="mini" type="primary">打卡</el-button>
                </div>
              </div>
              <div class="divInfo" v-else>
                <div class="nullBed VerticalMid">
                  床位：{{item.name}}<br/>
                  空
                </div>
              </div>
            </div>
          </el-col>
        </el-row>

      </div>

    </el-dialog>


  </div>
</template>

<script>
import '@/assets/css/Admin.css';
export default {
  components: {

  },
  data() {
    return {
      fullscreenLoading: false,
      itemData: [],
      buildingId: '',


      dialogTitle: '详情',
      isShowRoom: false,
      dialogVisibleRQcode: false,

      bedData: [],// 房间信息
    };
  },
  methods: {
    /**
     * @description 对房间号排序
     * @param {@} objData
     */
    onGetRoomData(objData) {
      let that = this;
      if (!regNullArray(objData)) {
        return objData.sort(function (a, b) { return setInt(a.name) - setInt(b.name); });
      }
    },
    /**
     * @description 头像
     */
    onGetAvatar(objItem) {
      let that = this;
      return that.onImgSpliceUrlNoV(objItem);



    },
    // 返回
    onBack() {
      let that = this;
      back(that);
    },
    /**
     * @description 打开房间事件
     */
    onDialogRoom(objItem) {
      let that = this;
      console.log(objItem);
      if (!regNull(objItem)) {
        that.dialogTitle = '宿舍房间：' + objItem.name;
        if (!regNullArray(objItem.beds)) {
          that.bedData = objItem.beds.sort(function (a, b) { return setInt(a.name) - setInt(b.name); });
        }
        that.isShowRoom = true;
      }
    }
  },
  created() {
    let that = this;
    that.buildingId = that.$route.params.Id;
    onGetData(that);
  }
};


// 加载页面数据
function onGetData(that) {
  that.fullscreenLoading = true;
  ajaxGet(that, '/api/admin/building/rooms/' + that.buildingId, null, function (resFloorData) {
    that.itemData.buildingId = resFloorData.buildingId;
    that.itemData.name = resFloorData.name;

    if (!regNullArray(resFloorData.floors)) {
      that.itemData.floors = resFloorData.floors.sort(function (a, b) { return setInt(a.name) - setInt(b.name); })
    }

    console.log(resFloorData)
    //冒泡排序房间


    that.itemData.floors = resFloorData.floors;
    // that.onDialogRoom(that.itemData.floors[0].rooms[0]);
    that.fullscreenLoading = false;
  });
}

</script>

<style scoped="scoped" lang="less">
.dialogBody {
  .itemBox {
    display: flex;
    margin: 15px;

    .divAvatar {
      width: 150px;
      height: 209px;
      border: 1px solid #e4e7ed;
      border-radius: 5px;
      overflow: hidden;

      .elImage {
        width: 150px;
        height: 209px;

        .imageSlot {
          width: 150px !important;
          height: 209px !important;
        }
      }

    }

    .divInfo {
      margin-left: 15px;
      position: relative;

      .divFonts {
        font-size: 16px;
        padding-top: 5px;
      }

      .btnCheckIn {
        position: absolute;
        bottom: 3px;
        right: 0;
      }

      .nullBed {
        height: 200px;
        width: 200px;
        font-weight: bolder;
        font-size: 32px;
        color: #c0c4cc;
        text-align: center;
      }
    }
  }
}


.TooltipItem {
  cursor: pointer;
}

.spFloorEnable {
  font-size: 14px;
  padding-left: 20px;
}

label {
  margin-bottom: 0;
}


.info-box {
  min-height: 40px;
  position: relative;
}

.info-box .info-box-icon {
  font-family: 'OswaldLight';
  width: 60px;
  font-size: 24px;
  text-align: center;
}

.info-box .info-box-text {
  font-size: 22px;
}
</style>

<style lang="less">
.elImage {
  .imageSlot {
    width: 150px;
    height: 209px;
    font-size: 72px;
    color: #c0c4cc;
  }
}</style>

