<!--
  基础信息-学年信息
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <search :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null"></search>
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          设备信息
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <el-button v-if="isPower.admin_device_create == true" type="success" icon="el-icon-circle-plus-outline"
                @click="onCreate()">新增</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table :data="itemData" stripe class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="deviceId" label="设备Id" class="elColumn"></el-table-column>
          <el-table-column prop="deviceName" label="设备名称" class="elColumn"></el-table-column>
          <el-table-column prop="deviceTypeStr" label="设备类型" class="elColumn"></el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <operationBtn v-if="isPower.admin_device_details == true" :btnName="'编辑'" @click.native="onEdit(scope.row.deviceId)"></operationBtn>
              <operationBtn v-if="isPower.admin_device_details == true" :btnName="'删除'" @click.native="onDelete(scope.row.deviceId)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>

    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%" :close-on-click-modal="false"
      :close-on-press-escape="false" :append-to-body="true" :fullscreen="false">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="90px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="设备Id" prop="deviceId">
              <el-input v-model="ruleForm.deviceId" placeholder="请输入设备Id"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="设备名称" prop="deviceName">
              <el-input v-model="ruleForm.deviceName" placeholder="请输入设备名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="设备类型" prop="deviceType">
              <el-select v-model="ruleForm.deviceType" placeholder="请选择设备类型" style="width: 100%;">
                <el-option v-for="item in optType" :key="item.Value" :label="item.Title" :value="item.Value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <div v-if="isDetail!=true">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button @click="onReset()">重 置</el-button>
          <el-button type="primary" @click="onSave()">确 定</el-button>
        </div>
        <div v-else>
          <el-button @click="dialogVisible = false">关 闭</el-button>
        </div>
      </span>
    </el-dialog>

  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';

  export default {
    components: {
      comPage,
      operationBtn,
      search
    },
    data() {
      return {
        //加载动画
        fullscreenLoading: false,
        //搜索
        SearchList: null,
        //分页查询接口返回的结果
        page: null,
        //待重置时的表单数据
        ruleFormReset: null,
        //列表数据
        itemData: [],
        //分页左侧按钮集合
        pageBtnList: null,
        //分页数据
        paging: {
          // 总共页数
          pageLength: 0,
          // 每页请求的数据量
          Size: 1,
          //当前在第几页
          Index: 1
        },
        dialogVisible: false,
        dialogTitle: "新增", //弹出框标题
        isDetail: false, //是否是查看弹出框
        ruleForm: {
          name: null, //学年名称
          year: null,
        },
        rules: {
         deviceId: [{
           required: true,
           message: '请输入设备id',
           trigger: 'change'
         }],
         deviceName: [{
           required: true,
           message: '请输入设备名称',
           trigger: 'change'
         }],
         deviceType: [{
           required: true,
           message: '请选择设备类型',
           trigger: 'change'
         }],
        },
        isPower: {
          //增删改查 权限管理
          admin_device_details: false,
          admin_device_create: false,
          admin_device_edit: false,
          admin_device_delete: false,
        },
        optType:[],
      };
    },
    methods: {
      /**
       * @description 更多操作
       * @param {Object} command
       * @param {Object} objValID
       */
      handleCommand(command, objValID) {
        let that = this;
        switch (command) {
          case 'onEdit':
            that.onEdit(objValID);
            break;
          case 'onDelete':
            that.onDelete(objValID);
            break;
        }
      },
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },
      onSave() //保存（新增和编辑）
      {
        let that = this;
        that.$refs["ruleForm"].validate(valid => {
          //编辑或新增
          if (!regNull(that.ruleForm.deviceId)) {
            that.ruleForm.deviceType = parseInt(that.ruleForm.deviceType);
            //新增
            if (that.dialogTitle == "新增") {
              that.fullscreenLoading = true;
              ajaxPost(that, "/api/admin/dumudevice", that.ruleForm, function(r) {
                that.fullscreenLoading = false;
                that.dialogVisible = false;
                pagingPage(that);
                //
              });
            }//编辑
             else {
              that.fullscreenLoading = true;
              ajaxPut(that, "/api/admin/dumudevice/" + that.ruleForm.deviceId, that.ruleForm, function(r) {
                that.ruleForm.deviceType = toString(that.ruleForm.deviceType);
                that.fullscreenLoading = false;
                that.dialogVisible = false;
                pagingPage(that);
              });
            }

          } else {

            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              isError[0].querySelector('input').focus();
            }, 1)
            return false;
          }
        });

      },
      onCreate() //新增
      {
        let that = this;
        that.dialogTitle = "新增";
        var ruleForm = JSON.parse(JSON.stringify(that.ruleForm));
        for (var i in ruleForm) {
          ruleForm[i] = null;
        }
        that.ruleForm = JSON.parse(JSON.stringify(ruleForm));
        that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
        that.dialogVisible = true;
        that.isDetail = false;
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      onDetail(Id) //查看
      {
        let that = this;
        that.fullscreenLoading = true;
        that.isDetail = true;
        ajaxGet(that, "/api/admin/dumudevice/" + Id, null, function(r) {
          that.fullscreenLoading = false;
          that.dialogTitle = "查看";
          that.ruleForm = r;
          that.dialogVisible = true;
        });
      },
      onEdit(Id) //编辑
      {
        let that = this;
        that.fullscreenLoading = true;
        that.isDetail = false;
        ajaxGet(that, "/api/admin/dumudevice/" + Id, null, function(r) {
          that.fullscreenLoading = false;
          that.dialogTitle = "编辑";
          that.ruleForm = r;
          that.ruleForm.deviceType = that.ruleForm.deviceType.toString();
          that.dialogVisible = true;
          that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
        });
      },
      onSearch(params) // 搜索
      {
        let _this = this;
        let data = {};
        let searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = element.data;
          }
        });
        data.PageNumer = 1;
        routerPath(_this, "/Admin/Device/Index", data, null, "PageSize");
      },
      onReset() //重置
      {
        let that = this;
        this.ruleForm = JSON.parse(JSON.stringify(this.ruleFormReset));
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      onDelete(Id) //删除
      {
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, "/api/admin/dumudevice/" + Id, null, function(r) {
              that.fullscreenLoading = false;
              pagingPage(that);
            });
          }
        })
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      // 分页面按钮
      pagingClick(type, val) {
        let that = this;
        let url = "/Admin/Device/Index";
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "DeviceName",
            "DeviceType",
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "DeviceName",
            "DeviceType",
          );
        }
      }
    },
    created() {
      let that = this;
      isPower(that, "admin_device_details", "admin_device_details");
      isPower(that, "admin_device_create", "admin_device_create");
      isPower(that, "admin_device_edit", "admin_device_edit");
      isPower(that, "admin_device_delete", "admin_device_delete");
      ajaxGet(that,'/api/admin/dumudevice/devicetype',null,function(res){
        for(var it of res){
          that.optType.push({
            Value : it.Value,
            Title : it.Text
          })
        }
          pagingPage(that);
      })
    },
    watch: {
      $route() {
        let that = this;
        pagingPage(that);
      }
    },
    filters: {
      flGrade(obj) {
        let gradeYear = new Date().getFullYear()+1;
        return gradeYear - obj;
      },
    }
  };

  function pagingPage(that) {
    paging(
      that,
      function(r) {
        let apiUrl = "/api/admin/admin_device/search";
        let data = {
          PageNumer: r.PageNumer,
          PageSize: r.PageSize,
          DeviceName: r.DeviceName,
          DeviceType: r.DeviceType,
        };
        that.SearchList = [{
          type: "input",
          Name: "设备名称",
          data: r.DeviceName,
          holder: "请输入设备名称",
          zhi: "DeviceName"
        }, {
          type: "select",
          Name: "设备类型",
          data: r.DeviceType,
          select:that.optType,
          holder: "请选择设备类型",
          zhi: "DeviceType"
        }, ];
        that.paging.Index=r.PageNumer;
        that.paging.Size=r.PageSize;
        that.fullscreenLoading = true;
        ajaxGet(that, "/api/admin/dumudevice/search", data, function(r) {
          that.page = r;
          that.itemData = r.items;
          that.fullscreenLoading = false;
        });
      },
      "DeviceName",
      "DeviceType",
    );
  }
</script>

<style scoped="scoped">

</style>
