<!--
  系統管理 - 用户管理 - 学生范围
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">

      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            学生范围信息
            <span  class="colorRed">(班主任无需设置学生范围)</span>
          </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item" style="margin-right: 5px;">
              <el-button @click="setStudentLevel()" icon="el-icon-s-tools" type="success"
               >
                设置学生范围
              </el-button>
            </li>
          </ul>
        </div>
        </div>
        <div class="card-body p-0">
          <el-table :data="itemData" stripe class="elTableData">
            <el-table-column type="index" label="序号" width="80"></el-table-column>
            <el-table-column prop="collegeName" label="年级" class="elColumn"></el-table-column>
            <el-table-column prop="className" label="班级" class="elColumn"></el-table-column>
            <el-table-column label="操作" width="100">
              <template slot-scope="scope">
                <operationBtn :btnName="'删除'" @click.native="onDelete(scope.row.filterId)"></operationBtn>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <comPage :isPage="false" :paging="paging"></comPage>
      </el-card>

      <div class="row" style="margin-top: 10px;">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
        </div>
      </div>
    </el-form>
      <el-dialog
        title="设置学生范围"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
        <div class="content-box">
          <el-row :gutter="24">
              <el-col :span="24">
                  <div>
                      <span class="textStyle">年级:</span>
                      <el-select v-model="ruleForm.collegeId" placeholder="请选择年级" style="width:100%"
                      @change="onChangeCollege(ruleForm.collegeId)">
                      <el-option v-for="item in collegeOptions" :key="item.collegeId" :label="item.name"
                        :value="item.collegeId">
                      </el-option>
                      </el-select>
                  </div>
              </el-col>
               <el-col :span="24" style="marginTop:20px">
                 <div>
                   <span class="textStyle">班级:</span>
                    <el-select v-model="ruleForm.classId" multiple collapse-tags style="width:100%" placeholder="请选择班级"
                    filterable  @change="onChangeMajor" @remove-tag="onClearMajor">
                    <el-option-group v-for="group in classOptions" :key="group.value" :label="group.label" >
                      <el-option v-for="item in group.class" :key="item.value" :label="item.label" :value="item.value"
                        :disabled="item.disabled" >
                      </el-option>
                      </el-option-group>
                    </el-select>
                 </div>
              </el-col>
          </el-row>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="closeLevelAdd">取 消</el-button>
          <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
          <el-button type="primary" @click="onSave">确 定</el-button>
        </span>
      </el-dialog>
  </div>
</template>


<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  export default {
    components: {
      operationBtn,
      comPage
    },
    data() {
      return {
        dialogVisible:false,
        isCreateFlag: null,
        fullscreenLoading: false,
        ruleFormReset: null,
        ruleFormInit: {},
        ruleForm: {
          yearId: null,
          collegeId: null,
          classId: null,
        },
        itemData: [],
        yearOptions: [],
        collegeOptions: [],
        majorOptions: [],
        classOptions: [{
          label:'全部',
          value:1,
          class:[{
            label:'全部',
            value:1,
            disabled:false
          }]
        }],
        paging: {
          totalCount: 0,
        },
        // props: { multiple: true },
        rules: {},
      };
    },
    methods: {
      setStudentLevel(){
        let that = this
        that.dialogVisible = true
      },
      handleClose(){
        this.dialogVisible =false
        this.onReset()
      },
      closeLevelAdd(){
        this.dialogVisible =false
        this.onReset()
      },
      onClearMajor(_value) {
        let _this = this;
        if (_value == 1) {
          _this.classOptions.forEach(function(obj) {
            obj.class.forEach(function(obj1) {
              obj1.disabled=false;
            })
          });
        }
      },
      onChangeMajor(_value) {
        let _this = this;
        if (_value.find(val => val == 1) != undefined) {
          if (_this.ruleForm.classId.length>1&&_this.ruleForm.classId[0]==1){
                    _this.ruleForm.classId=[_this.ruleForm.classId[1]];
          }
          else{
                    _this.ruleForm.classId=[1,];
          }
        }
      },
      onSave() //保存
      {

        let that = this;
        let isMajor = false;
        that.dialogVisible = false
            let Id = that.$route.params.id;
            var newMajors = {};
            var newClass = {};
            var collegeId = that.ruleForm.collegeId;
            var majorList = [];
            var classList = [];
            for (var i in that.ruleForm.classId) {
              if (i == 0) {
                newClass = that.ruleForm.classId[i]
              } else {
                newClass = newClass + "," + that.ruleForm.classId[i];
              }
            }
            if (that.ruleForm.collegeId == 1) {
              collegeId = null;
            }
            if (that.ruleForm.classId == 1) {
              newClass = null;
            }
            if(that.ruleForm.classId.find(val=>val==1)!=undefined){
              newClass = null;
            }
            //拼接班级Id

            var data = {
              userId: Id,
              collegeId: collegeId,
              classes: newClass,
            };
            that.fullscreenLoading = true;
            ajaxPost(that, "/api/admin/sysuser/filter/" + Id, data, function(r) {
              that.fullscreenLoading = false;
              that.onReset();
              Init(that);
            });
      },
      onReset() //重置
      {
        let that = this;
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
        that.majorOptions = JSON.parse(JSON.stringify([]));
        that.classOptions = [{
          label:'全部',
          value:1,
          class:[{
            label:'全部',
            value:1,
            disabled:false
          }]
        }];
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      onBack() //返回
      {
        let that = this;
        that.$router.push({
          path:'/admin/user/index'
        })
      },
      onDelete(Id) //删除
      {
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, "/api/admin/sysuser/filter/" + Id, null, function(r) {
              that.fullscreenLoading = false;
              Init(that);
            });
          }
        })
      },
      //去除重复值
      onArrUnique(arr)
      {
        const res = new Map();
        return arr.filter((arr) => !res.has(arr.label) && res.set(arr.label, 1));
      },
      //选择年级根据collegeId获取班级
      onChangeCollege(val) {
        let that = this;
        let arr=[];
        that.classOptions=[];
        that.ruleForm.majorId = [];
        that.classOptions = [{
          label:'全部',
          value:1,
          class:[{
            label:'全部',
            value:1,
            disabled:false
          }]
        }];
        if (val != 1) {
          let data = {
            collegeId: val,
          }
            that.ruleForm.classId = [1, ];
          ajaxGet(that, '/api/admin/class/classbycollegeid', data, function(resdata) {

            for(var it of resdata)
            {
               arr.push({
                label:it.year+'学年',
                value:it.year,
                class:[],
              })
            }
            let optClass = JSON.parse(JSON.stringify(that.onArrUnique(arr)));
            for(var i in optClass){
              for(var j in resdata){
                if(optClass[i].value==resdata[j].year)
                {
                    optClass[i].class.push({
                      label: resdata[j].alias,
                      value: resdata[j].classId,
                      disabled: false,
                    })
                }
              }
            }
              for(var k in optClass){
                that.classOptions.push({
                  label:optClass[k].label,
                  value:optClass[k].value,
                  class:optClass[k].class,
                })
              }
          })
        }

      },
    },
    created() {
      let that = this;
      var flag = that.$route.query.flag;
      if (flag != null) {
        that.isCreateFlag = flag;
      }
      that.ruleForm.collegeId = 1;
      that.ruleForm.classId = [1, ];
      that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
      Init(that);
    }
  };

  function Init(that) {
    let Id = that.$route.params.id;
    //获取学生范围
    ajaxGet(that, "/api/admin/sysuser/filter/" + Id, null, function(r) {
      that.itemData = r;
      that.paging.totalCount = that.itemData.length;
    })
    //获取入学的学年
    that.yearOptions = [];
    ajaxGet(that, "/api/admin/student/entryyear", null, function(r) {
      for (var i in r) {
        var newYearOptions = {
          label: r[i].Text,
          value: r[i].Value,
        }
        that.yearOptions.push(newYearOptions);
      }
      try {
        that.$refs["ruleForm"].resetFields();
      } catch {}
    });
    //获取所有学院信息（联动）
    ajaxGet(that, "/api/admin/college/linkage", null, function(r) {

     that.collegeOptions= [{
        collegeId: 1,
        name: '全部',
      }];
      for (var i in r) {
        that.collegeOptions.push({
          collegeId: r[i].collegeId,
          name: r[i].name,
        })
      }

    });
  }
</script>

<style scoped="scoped">
.textStyle{
  font-size: 15px;
  margin-bottom: 20px;
  width: 100%;
  text-align: left;
  /* background-color: red; */
  display: block;

}
.content-box{
  width: 100%;
  height: auto;
}
  .formCheckCont .spCheck {
    display: inline-block;
    margin-top: 5px;
  }

  label {
    margin-bottom: 0;
  }
</style>
