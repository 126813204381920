<!--
  房源管理 - 新增
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">

    <div class="callout callout-warning">
      <div><small>1、鼠标移至房间号，可对选中的房间进行“<b>编辑</b>”和“<b>删除</b>”。</small></div>
      <div><small>2、拉至最下方。进行“<b>新增楼层</b>”操作</small></div>
      <div><small>3、房间号前颜色为房间状态：灰色为关闭，彩色为开启，数字为床位总数</small></div>
    </div>

    <el-card class="box-card" v-for="floor in itemData.floors" :key="floor.floorId">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          楼层：{{ floor.name }}
          <span class="spFloorEnable">启用状态：
            <span class="color-success" v-if="floor.enable">开启</span>
            <span class="color-gray" v-else>关闭</span>
          </span>
        </h3>
        <div class="card-tools">
          <ul class="nav nav-pills ml-auto navToolsCont">
            <li class="nav-item pl-1 pr-1">
              <a href="javascript:void(0)" class="btn btn-success btn-xs" @click="onOpenAddRooms(floor.floorId)">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#fax-circle-plus-alt"></use>
                </svg>
                批量新增房间
              </a>
            </li>
            <li class="nav-item pl-1 pr-1">
              <a href="javascript:void(0)" class="btn btn-success btn-xs" @click="onOpenAddRoom(floor.floorId)">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#fax-circle-plus-alt"></use>
                </svg>
                新增房间
              </a>
            </li>
            <li class="nav-item pl-1 pr-1">
              <a href="javascript:void(0)" class="btn btn-warning btn-xs" @click="onOptenEditFloor(floor)">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#fax-edit-o"></use>
                </svg>
                编辑楼层
              </a>
            </li>
            <li class="nav-item pl-1 pr-1">
              <el-button slot="reference" class="btn btn-danger btn-xs" @click="onRemoveFloor(floor.floorId)">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#fax-times-circle"></use>
                </svg>
                删除楼层
              </el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body">
        <el-row :gutter="24">
          <el-col :span="4" v-for="room in floor.rooms" :key="room.roomId">
            <el-dropdown class="divDropdownCont">
              <div class="info-box">
                <span class="info-box-icon" :class="room.enable ? 'bg-primary' : 'bg-secondary'">
                  {{ room.beds.length }}
                </span>
                <div class="info-box-content" style="line-height: 1;">
                  <span class="info-box-text">{{ room.name }}</span>
                </div>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <span class="color-warning" @click="onOpenEditRoom(room)">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#fax-edit-o"></use>
                    </svg>
                    编辑房间
                  </span>
                </el-dropdown-item>
                <el-dropdown-item class="spBtnCont">
                  <span class="color-danger" @click="onRemoveRoom(room.roomId)">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#fax-times-circle"></use>
                    </svg>
                    删除房间
                  </span>
                </el-dropdown-item>
                <el-dropdown-item divided>
                  <span class="color-success" @click="onOpenAddBed(room.roomId)">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#fax-circle-plus-alt"></use>
                    </svg>
                    添加床位
                  </span>
                </el-dropdown-item>
                <el-dropdown-item>
                  <span class="color-warning" @click="onOpenEditBeds(room.beds, room.roomId)">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#fax-edit-o"></use>
                    </svg>
                    编辑床位
                  </span>
                </el-dropdown-item>
              </el-dropdown-menu>


            </el-dropdown>
          </el-col>
        </el-row>
      </div>
    </el-card>

    <div class="row">
      <div class="col-6">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
      </div>
      <div class="col-6 text-right">
        <el-button type="success" icon="el-icon-circle-plus-outline" @click="onOpenAddFloor()">新增楼层</el-button>
      </div>
    </div>










    <!--批量新增房间-->
    <el-dialog :title="roomSwitch.boxTitle" :visible.sync="dialogVisibleForRooms" width="30%"
      :before-close="handleCloseForRooms" :close-on-click-modal="false">
      <div class="card-body ">
        <el-form :model="ruleRoomsForm" :rules="rulesRooms" ref="ruleRoomsForm" label-width="100px">
          <el-row :gutter="24">
            <el-col :span="24" v-if="!roomSwitch.isRooms">
              <el-form-item label="房间号" prop="name">
                <el-input v-model="ruleRoomsForm.name" placeholder="请输入" class="formControl"></el-input>
              </el-form-item>
            </el-col>
           <el-col :span="24" v-if="roomSwitch.isRooms">
              <el-form-item label="前缀" prop="prefix">
                <el-input v-model="ruleRoomsForm.prefix" placeholder="" class="formControl"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" v-if="roomSwitch.isRooms">
              <el-form-item label="起始房间号" prop="start">
                <el-input v-model="ruleRoomsForm.start" placeholder="请输入" class="formControl"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" v-if="roomSwitch.isRooms">
              <el-form-item label="截止房间号" prop="end">
                <el-input v-model="ruleRoomsForm.end" placeholder="请输入" class="formControl"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="床位数" prop="beds">
                <el-input v-model="ruleRoomsForm.beds" placeholder="请输入" class="formControl"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onResetForm('ruleRoomsForm')">取 消</el-button>
        <el-button type="primary" @click="onVerification('ruleRoomsForm', 'addRooms')">确 定</el-button>
      </span>
    </el-dialog>

    <!--编辑楼层-->
    <el-dialog :title="floorSwitch.boxTitle" :visible.sync="dialogVisibleForFloor" width="30%"
      :before-close="handleCloseForFloor" :close-on-click-modal="false">
      <div class="card-body">
        <el-form :model="ruleFloorForm" :rules="rulesFloor" ref="ruleFloorForm" label-width="100px">
          <el-row :gutter="24">
            <!-- <el-col :span="24">
              <el-form-item label="楼层" prop="name">
                <el-input v-model="ruleFloorForm.name" placeholder="请输入楼层名称"></el-input>
              </el-form-item>
            </el-col> -->
            <el-form-item label="楼层" prop="name" :key="floorSwitch.isFloor">
              <el-input v-model="ruleFloorForm.name" v-if="!floorSwitch.isFloor" placeholder="请输入楼层名称"></el-input>
              <el-input v-model="ruleFloorForm.name" v-else></el-input>
            </el-form-item>
            <el-col :span="24">
              <el-form-item label="启用状态">
                <el-switch v-model="ruleFloorForm.enable"></el-switch>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onResetForm('ruleFloorForm')">取 消</el-button>
        <el-button type="primary" @click="onVerification('ruleFloorForm', 'addFloor')" v-if="floorSwitch.isFloor">确 定
        </el-button>
        <el-button type="primary" @click="onVerification('ruleFloorForm', 'editFloor')" v-else>确 定</el-button>
      </span>
    </el-dialog>

    <!--编辑房间-->
    <el-dialog title="编辑房间" :visible.sync="dialogVisibleForRoom" width="30%" :before-close="handleCloseForRoom"
      :close-on-click-modal="false">
      <div class="card-body">
        <el-form :model="ruleEditRoomForm" :rules="rulesEditRoom" ref="ruleEditRoomForm" label-width="90px">

          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="房间名" prop="name">
                <el-input v-model="ruleEditRoomForm.name" placeholder="请输入房间名称" class="formControl"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="启用状态">
                <el-switch v-model="ruleEditRoomForm.enable" class="formControl"></el-switch>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onResetForm('ruleEditRoomForm')">取 消</el-button>
        <el-button type="primary" @click="onVerification('ruleEditRoomForm', 'editRoom')">确 定</el-button>
      </span>
    </el-dialog>

    <!--新增床位-->
    <el-dialog title="新增床位" :visible.sync="dialogVisibleForBed" width="30%" :before-close="handleCloseForBed"
      :close-on-click-modal="false">
      <div class="card-body">
        <el-form :model="ruleBedForm" :rules="rulesBed" ref="ruleBedForm" label-width="90px">
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="床位名" prop="name">
                <el-input v-model="ruleBedForm.name" placeholder="请输入床位名" class="formControl"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="启用状态">
                <el-switch v-model="ruleBedForm.enable" class="formControl"></el-switch>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onResetForm('ruleBedForm')">取 消</el-button>
        <el-button type="primary" @click="onVerification('ruleBedForm', 'addBed')">确 定</el-button>
      </span>
    </el-dialog>


    <!--编辑床位-->
    <el-dialog title="编辑床位" :visible.sync="dialogVisibleForBeds" width="40%" :before-close="handleCloseForBeds"
      :close-on-click-modal="false">
      <div class="card-body">
        <el-form :model="ruleBedForm" ref="ruleBedForm" label-width="90px">
          <el-row :gutter="24" v-for="(item, index) in ruleBedForm.arrBed" :key="index">
            <el-col :span="14">
              <el-form-item label="床位名" :prop="'arrBed.' + index + '.name'"
                :rules="[{ required: true, message: '请填写床位名', trigger: 'change' }]">
                <el-input v-model="item.name" placeholder="请输入床位名" class="formControl"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="启用状态">
                <el-switch v-model="item.enable" class="formControl"></el-switch>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <div style="margin-left: 10px;">
                <el-popconfirm title="此操作将永久删除该记录, 是否确认继续?" @confirm="onRemoveBed(item.bedId)">
                  <el-button slot="reference" type="danger" icon="el-icon-delete" circle></el-button>
                </el-popconfirm>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onResetForm('ruleBedForm')">取 消</el-button>
        <el-button type="primary" @click="onVerification('ruleBedForm', 'editBed')">确 定</el-button>
      </span>

    </el-dialog>


  </div>
</template>

<script>
import '@/assets/css/Admin.css';
export default {
  components: {},
  data() {
    return {
      // 加载动画
      fullscreenLoading: false,
      // 新增房间弹出框
      dialogVisibleForRooms: false,
      // 编辑房间弹出框
      dialogVisibleForRoom: false,
      // 新增楼层或编辑楼层弹出框
      dialogVisibleForFloor: false,
      // 新增床位
      dialogVisibleForBed: false,
      // 编辑床位
      dialogVisibleForBeds: false,


      // 列表数据
      itemData: [],
      buildingId: 0,




      // 新增/编辑楼层
      ruleFloorForm: {
        buildingId: '',
        floorId: '',
        name: '',
        enable: true
      },
      ruleFloorResetForm: null,
      // 新增/编辑 转换
      floorSwitch: {
        boxTitle: '新增楼层',
        isFloor: true,
      },
      rulesFloor: {
        name: [{
          required: true,
          message: '请输入楼层名',
          trigger: 'change'
        },
        {
          max: 20,
          message: '长度在 20 个字符内',
          trigger: 'change'
        },
        ],
      },





      // 批量新增房间
      ruleRoomsForm: {
        floorId: '',
        name: '',
        start: '',
        end: '',
        beds: '',
        qz:''
      },
      // 单间和批量的变化操作
      roomSwitch: {
        boxTitle: '批量新增房间',
        isRooms: true, // 是否批量新增房间 false:单间新增 true:批量新增
      },
      // 重新批量房间数据
      ruleRoomResetForm: null,
      // 批量新增房间验证
      rulesRooms: {
        name: [{
          required: true,
          message: '请输入房间号',
          trigger: 'change'
        },
        {
          validator: checkInt,
          trigger: 'change'
        },
        ],
        prefix:[{
          required: true,
          message: '请输入前缀',
          trigger: 'change'
        }],
        start: [{
          required: true,
          message: '请输入起始房间号',
          trigger: 'change'
        },

        {
          validator: checkInt,
          trigger: 'change'
        },
        ],
        end: [{
          required: true,
          message: '请输入截止房间号',
          trigger: 'change'
        },
        {
          validator: checkInt,
          trigger: 'change'
        },
        ],
        beds: [{
          required: true,
          message: '请输入房间床位数',
          trigger: 'change'
        },
        {
          validator: checkInt,
          trigger: 'change'
        },
        ],
      },



      // 编辑房间
      ruleEditRoomForm: {
        roomId: '',
        name: '',
        enable: true,
      },
      ruleEditResetForm: null,
      rulesEditRoom: {
        name: [{
          required: true,
          message: '请输入房间名',
          trigger: 'change'
        },
        {
          max: 20,
          message: '长度在 20 个字符内',
          trigger: 'change'
        },
        ],
      },


      // 床位信息集合
      ruleBedForm: {
        roomId: '',
        bedId: '',
        name: '',
        enable: true,
        arrBed: [],
        arrOldbed: [],
      },
      ruleBedResetForm: null,
      rulesBed: {
        name: [{
          required: true,
          message: '请输入床位名',
          trigger: 'change'
        },
        {
          max: 20,
          message: '长度在 20 个字符内',
          trigger: 'change'
        },
        ],
      }






    };
  },
  methods: {
    // 批量编辑床位
    onOpenEditBeds(_data, _dataID) {
      let _this = this;
      _this.dialogVisibleForBeds = true;
      _this.ruleBedForm = JSON.parse(JSON.stringify(_this.ruleBedResetForm));
      _this.ruleBedForm.roomId = _dataID;
      _this.ruleBedForm.arrBed = JSON.parse(JSON.stringify(_data));
      _this.ruleBedForm.arrOldbed = JSON.parse(JSON.stringify(_data));
    },
    // 新增床位
    onOpenAddBed(_dataID) {
      let _this = this;
      _this.dialogVisibleForBed = true;
      _this.ruleBedForm = JSON.parse(JSON.stringify(_this.ruleBedResetForm));
      _this.ruleBedForm.roomId = _dataID;
    },

    // 编辑房间弹出框
    onOpenEditRoom(_data) {
      let _this = this;
      _this.ruleEditRoomForm.roomId = _data.roomId;
      _this.ruleEditRoomForm.name = _data.name;
      _this.ruleEditRoomForm.enable = _data.enable;
      _this.dialogVisibleForRoom = true;
    },
    // 打开新增楼层弹出框
    onOpenAddFloor() {
      let _this = this;
      _this.ruleFloorForm = JSON.parse(JSON.stringify(_this.ruleFloorResetForm));
      _this.ruleFloorForm.buildingId = _this.buildingId;
      _this.dialogVisibleForFloor = true;
      _this.floorSwitch.isFloor = true;
      _this.floorSwitch.boxTitle = '新增楼层';
      try {
        that.$refs["ruleFloorForm"].resetFields();
      } catch { }
    },
    // 打开编辑楼层弹出框
    onOptenEditFloor(_data) {
      let _this = this;
      _this.ruleFloorForm = JSON.parse(JSON.stringify(_this.ruleFloorResetForm));
      _this.ruleFloorForm.buildingId = _this.buildingId;
      _this.ruleFloorForm.floorId = _data.floorId;
      _this.ruleFloorForm.name = _data.name;
      _this.ruleFloorForm.enable = _data.enable;
      _this.floorSwitch.boxTitle = '编辑楼层';
      _this.floorSwitch.isFloor = false;
      _this.dialogVisibleForFloor = true;
    },
    // 打开新增批量房间弹出框
    onOpenAddRooms(_dataID) {
      let _this = this;
      _this.ruleRoomsForm = JSON.parse(JSON.stringify(_this.ruleRoomResetForm));
      _this.ruleRoomsForm.floorId = _dataID;
      _this.dialogVisibleForRooms = true;
      _this.roomSwitch.isRooms = true;
      _this.roomSwitch.boxTitle = '批量新增房间';

    },
    // 打开新增单间房间弹出框
    onOpenAddRoom(_dataID) {
      let _this = this;
      _this.ruleRoomsForm = JSON.parse(JSON.stringify(_this.ruleRoomResetForm));
      _this.ruleRoomsForm.floorId = _dataID;
      _this.dialogVisibleForRooms = true;
      _this.roomSwitch.isRooms = false;
      _this.roomSwitch.boxTitle = '新增房间';
    },

    // 关闭床位编辑
    handleCloseForBeds(done) {
      done();
      this.$refs['ruleBedForm'].resetFields();
    },
    // 关闭床位新增
    handleCloseForBed(done) {
      done();
      this.$refs['ruleBedForm'].resetFields();
    },
    handleCloseForRoom(done) {
      done();
      this.$refs['ruleEditRoomForm'].resetFields();
    },
    // 关闭批量新增房间
    handleCloseForRooms(done) {
      this.$refs['ruleRoomsForm'].resetFields();
      done();
    },
    // 关闭新增楼层弹出框
    handleCloseForFloor(done) {
      this.$refs['ruleFloorForm'].resetFields();
      done();
    },
    // 返回
    onBack() {
      let _this = this;
      back(_this);
    },
    // 关闭验证
    onResetForm(_formType) {
      let _this = this;
      _this.$refs[_formType].resetFields();
      // 闭关弹出窗
      _this.dialogVisibleForRooms = false;
      _this.dialogVisibleForRoom = false;
      _this.dialogVisibleForFloor = false;
      _this.dialogVisibleForBed = false;
      _this.dialogVisibleForBeds = false;
    },

    // 验证
    onVerification(_formType, _operatType) {
      let _this = this;
      _this.$refs[_formType].validate((valid) => {
        if (valid) {
          switch (_operatType) {
            case 'addRooms': // 批量/新增房间
              _this.onCreateRooms(_this);
              break;
            case 'editRoom'://编辑房间
              _this.onEditRoom(_this);
              break;
            case 'addFloor': // 新增楼层
              _this.onCreateFloor(_this);
              break;
            case 'editFloor': // 编辑楼层
              _this.onEditFloor(_this);
              break;
            case 'addBed':// 新增床位
              _this.onCreateBed(_this);
              break;
            case 'editBed'://编辑床位
              _this.onEditBed(_this);
              break;
          }
          _this.onResetForm(_formType);
        } else {
          return false;
        }
      });
    },
    // 批量新增房间
    onCreateRooms(_this) {
      _this.fullscreenLoading = true;
      // 判断是否为批量
      if (!_this.roomSwitch.isRooms) {
        _this.ruleRoomsForm.end = _this.ruleRoomsForm.name;
        _this.ruleRoomsForm.start = _this.ruleRoomsForm.name;
      }
      // if(_this.ruleRoomsForm.qz){
      //   _this.ruleRoomsForm.end = _this.ruleRoomsForm.qz+_this.ruleRoomsForm.name;
      //   _this.ruleRoomsForm.start = _this.ruleRoomsForm.qz+_this.ruleRoomsForm.name;
      // }
      ajaxPost(_this, '/api/admin/room/batch', _this.ruleRoomsForm, function (res) {
        _this.fullscreenLoading = false;
        onGetData(_this);
      });
    },
    // 新增楼层
    onCreateFloor(_this) {
      _this.fullscreenLoading = true;
      ajaxPost(_this, '/api/admin/floor', _this.ruleFloorForm, function (res) {
        _this.fullscreenLoading = false;
        onGetData(_this);
      });
    },
    // 编辑楼层
    onEditFloor(_this) {
      _this.fullscreenLoading = true;
      ajaxPut(_this, '/api/admin/floor/' + _this.ruleFloorForm.floorId, _this.ruleFloorForm, function (res) {
        _this.fullscreenLoading = false;
        onGetData(_this);
      });
    },
    // 删除楼层
    onRemoveFloor(_dataID) //删除
    {
      let _this = this;
      confirmDelete(_this, null, function (res) {
        if (res == true) {
          _this.fullscreenLoading = true;
          ajaxDelete(_this, '/api/admin/floor/' + _dataID, null, function (res) {
            _this.fullscreenLoading = false;
            onGetData(_this);
          })
        }
      })
    },
    // onRemoveFloor(_dataID) {
    //   let _this = this;
    //   _this.fullscreenLoading = true;
    //   ajaxDelete(_this, '/api/admin/floor/' + _dataID, null, function(res) {
    //     _this.fullscreenLoading = false;
    //     onGetData(_this);
    //   })
    // },
    // 编辑房间
    onEditRoom(_this) {
      _this.fullscreenLoading = true;
      ajaxPut(_this, '/api/admin/room/' + _this.ruleEditRoomForm.roomId, _this.ruleEditRoomForm, function (res) {
        _this.fullscreenLoading = false;
        onGetData(_this);
      });
    },
    // 删除房间
    onRemoveRoom(_dataID) {
      let _this = this;
      confirmDelete(_this, null, function (res) {
        if (res == true) {
          _this.fullscreenLoading = true;
          ajaxDelete(_this, '/api/admin/room/' + _dataID, null, function (res) {
            _this.fullscreenLoading = false;
            onGetData(_this);
          });
        }
      })
    },
    // 添加床位
    onCreateBed(_this) {
      _this.fullscreenLoading = true;
      ajaxPost(_this, '/api/admin/bed', _this.ruleBedForm, function (res) {
        _this.fullscreenLoading = false;
        onGetData(_this);
      })
    },
    // 编辑床位
    onEditBed(_this) {
      let isBooln = false;
      for (let item of _this.ruleBedForm.arrBed) {
        // 进行排查,将有变动的数据找出来
        if (_this.ruleBedForm.arrOldbed.findIndex(arr => {
          return arr.bedId === item.bedId && arr.name === item.name && arr.enable === item.enable
        }) == -1) {
          _this.onEditBedInfo(_this, item);
          isBooln = true;
        }
      }
      // 如果有与数据库交互则更新数据
      if (isBooln) {
        onGetData(_this);
      }
    },
    // 将有变动的床位数据提交给数据库
    onEditBedInfo(_this, _data) {
      _this.fullscreenLoading = true;
      ajaxPut(_this, '/api/admin/bed/' + _data.bedId, _data, function (res) {
        _this.fullscreenLoading = false;
        onGetData(_this);
      })
    },

    // 删除床位
    onRemoveBed(_dataID) {
      let _this = this;
      _this.fullscreenLoading = true;
      ajaxDelete(_this, '/api/admin/bed/' + _dataID, null, function (res) {
        let arrTemp = _this.ruleBedForm.arrBed.filter((item) => {
          if (item.bedId != _dataID) return item;
        })
        _this.ruleBedForm.arrBed = JSON.parse(JSON.stringify(arrTemp));
        arrTemp = _this.ruleBedForm.arrOldbed.filter((item) => {
          if (item.bedId != _dataID) return item;
        })
        _this.ruleBedForm.arrOldbed = JSON.parse(JSON.stringify(arrTemp));
        _this.fullscreenLoading = false;
        onGetData(_this);
      });
    }

  },
  created() {
    let _this = this;
    _this.buildingId = _this.$route.params.id;
    _this.ruleRoomResetForm = JSON.parse(JSON.stringify(_this.ruleRoomsForm));
    _this.ruleFloorResetForm = JSON.parse(JSON.stringify(_this.ruleFloorForm));
    _this.ruleEditResetForm = JSON.parse(JSON.stringify(_this.ruleEditRoomForm));
    _this.ruleBedResetForm = JSON.parse(JSON.stringify(_this.ruleBedForm));
    //判断是否有传值过来
    if (_this.buildingId != null && typeof (_this.buildingId) != "undefined" && _this.buildingId != "") {
      onGetData(_this);
    }
  },

};

// 加载页面数据
function onGetData(_this) {
  _this.fullscreenLoading = true;
  ajaxGet(_this, '/api/admin/building/rooms/' + _this.buildingId, null, function (resFloorData) {
    _this.itemData.buildingId = resFloorData.buildingId;
    _this.itemData.name = resFloorData.name;
    //冒泡排序房间
    for (var i = 0; i < resFloorData.floors.length; i++) {
      for (var j = 0; j < resFloorData.floors[i].rooms.length - 1; j++) {
        for (var k = 0; k < resFloorData.floors[i].rooms.length - 1 - j; k++) {
          if (parseInt(resFloorData.floors[i].rooms[k].name.match(/\d+/)) > parseInt(resFloorData.floors[i].rooms[k + 1].name.match(/\d+/))) {
            var temp = resFloorData.floors[i].rooms[k];
            resFloorData.floors[i].rooms[k] = resFloorData.floors[i].rooms[k + 1];
            resFloorData.floors[i].rooms[k + 1] = temp;
          }
          const targetPrefix = '男寝';
          // if (resFloorData.floors[4].rooms[0].name.startsWith(targetPrefix)) {
          //   _this.ruleEditRoomForm.enable = true;
          //   _this.ruleEditRoomForm.name = targetPrefix + resFloorData.floors[i].rooms[j].name.match(/\d+/)
          //   _this.ruleEditRoomForm.roomId = resFloorData.floors[i].rooms[j].roomId;
          //   _this.fullscreenLoading = true;
          //   ajaxPut(_this, '/api/admin/room/' + _this.ruleEditRoomForm.roomId, _this.ruleEditRoomForm, function (res) {
          //     _this.fullscreenLoading = false;
          //   });
          // }
          // const targetPrefix2 = '女寝';
          // if (resFloorData.floors[0].rooms[0].name.startsWith(targetPrefix2)) {
          //   _this.ruleEditRoomForm.enable = true;
          //   _this.ruleEditRoomForm.name = targetPrefix2 + resFloorData.floors[i].rooms[j].name.match(/\d+/)
          //   _this.ruleEditRoomForm.roomId = resFloorData.floors[i].rooms[j].roomId;
          //   _this.fullscreenLoading = true;
          //   ajaxPut(_this, '/api/admin/room/' + _this.ruleEditRoomForm.roomId, _this.ruleEditRoomForm, function (res) {
          //     _this.fullscreenLoading = false;
          //   });
          // }
        }
      }
    }

    //冒泡排序楼层
    for (var i = 0; i < resFloorData.floors.length - 1; i++) {
      for (var j = 0; j < resFloorData.floors.length - 1 - i; j++) {
        if (parseInt(resFloorData.floors[j].name) > parseInt(resFloorData.floors[j + 1].name)) {
          var temp = resFloorData.floors[j];
          resFloorData.floors[j] = resFloorData.floors[j + 1];
          resFloorData.floors[j + 1] = temp;
        }
      }
    }
    _this.itemData.floors = resFloorData.floors;
    _this.fullscreenLoading = false;
  });
}


// 验证是否为正整数
let checkInt = (rule, value, callback) => {
  if (regInt(value)) {
    callback(new Error("请输入正整数"));
  } else {
    callback();
  }
};
</script>

<style scoped="scoped">
.divRemvoeBedBox {
  padding-top: 35px;
  text-align: center;
}

.spFloorEnable {
  font-size: 14px;
  padding-left: 20px;
}

label {
  margin-bottom: 0;
}

.inputGroupCont {
  padding-top: 15px;
}

.inputGroupCont .inputList {
  padding-bottom: 10px;
}


.info-box {
  min-height: 40px;
  position: relative;
  margin-bottom: 0;

  border: 1px solid #FFFFFF;
}

.info-box:hover {
  cursor: pointer;
}

.info-box .info-box-icon {
  font-family: 'OswaldLight';
  width: 45px;
  font-size: 24px;
}

.info-box .info-box-text {
  font-size: 22px;
}

.divDropdownCont {
  width: 100%;
  margin-bottom: 15px;
}

.divDropdownCont:hover .info-box {
  /* box-shadow:0 0 1px rgb(0 0 0 / 23%), 0 1px 3px rgb(0 0 0 / 50%) */
  border: 1px solid #C3C3C3;
}
</style>
<style>
.spBtnCont .el-button {
  border: 0px solid #FFFFFF;
  padding: 0;
}
</style>
