import { render, staticRenderFns } from "./Ai.vue?vue&type=template&id=fc41f2fc&scoped=true"
import script from "./Ai.vue?vue&type=script&lang=js"
export * from "./Ai.vue?vue&type=script&lang=js"
import style0 from "./Ai.vue?vue&type=style&index=0&id=fc41f2fc&prod&scoped=scoped&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc41f2fc",
  null
  
)

export default component.exports