<!--
  用户管理 - 新增
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">

    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          {{ Title }}
        </h3>
      </div>
      <div class="card-body pl-5">


        <el-row :gutter="24">
          <el-col :span="24" class="colInfo">
            <label>角色名称</label>
            <div class="colFonts">
              {{roleName }}
            </div>
          </el-col>
          <el-col :span="24" class="colInfo">
            <label>描述</label>
            <div class="colFonts">
              <div v-html="roleDescription"></div>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-card>


    <el-card class="box-card" v-if="!regNullArray(roleUsers)">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          授权用户
        </h3>
      </div>
      <div class="card-body">
        <el-table :data="roleUsers" border stripe class="elTableData">
          <el-table-column prop="userName" label="姓名（用户名）"></el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-button size="small" type="danger" @click="onDeleteRoleUser(scope.row.userId)">移除用户</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>


    <el-card class="box-card" v-if="!regNullArray(actions)">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          权限详情
        </h3>
      </div>
      <div class="card-body">
        <div style="overflow-y: auto; border: 1px solid #efefef; border-radius: 3px; padding: 10px;"
          :style="'height:400px'">
          <el-tree :data="actions" :props="defaultProps" ref="menuTree" v-if="actions.length > 0"
            node-key="actionId" show-checkbox auto-expand-parent :expand-on-click-node="false"
            :default-checked-keys="idArr" :default-expanded-keys="idArr">
          </el-tree>
        </div>
      </div>
    </el-card>



    <div class="row" style="margin-top: 10px;">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';

  export default {
    components: {},
    data() {
      return {
        height: 380,
        idArr: [],
        idInit: [],
        actions: [],
        roleName: null,
        roleDescription: null,
        Title: "查看",
        fullscreenLoading: false,
        defaultProps: {
          children: 'children',
          label: 'name',
          id: 'actionId'
        },
        roleUsers: [],
        users: [],
      };
    },
    created() {
      let that = this;
      let id = that.$route.params.id;
      var detailUrl = null;
      if (id != null && typeof(id) != "undefined" && id != "") {
        that.Title = "查看";
        detailUrl = "/api/admin/sysRole/" + id;
      }
      that.fullscreenLoading = true;

      ajaxGet(that, detailUrl, null, function(r) {
        if (r != null) {
          that.roleName = r.name;
          that.roleDescription = r.description;
          that.roleUsers = r.roleUsers;
        }

        ajaxGet(that, "/api/admin/sysAction/treelist", null, function(r1) {
          that.actions = r1;
          GetItem(that, that.actions);
        });

        ajaxGet(that, "/api/admin/sysRole/action/" + id, null, function(r1) {
          that.idArr = r1;
          that.idInit = JSON.parse(JSON.stringify(that.idArr));
        });

        that.fullscreenLoading = false;

      });
    },
    mounted() {
      let that = this;
      that.height = document.body.clientHeight - 388;
    },
    methods: {
      // 移除角色用户
      onDeleteRoleUser(userId) {
        let that = this;
        confirmDelete(that, "确认移除该角色用户？", function(res) {
          if (res == true) {
            let url = "/api/admin/sysrole/deleteuserrole?userId=" + userId + "&roleId=" + that.$route.params.id;
            ajaxDelete(that, url, null, function(r) {
              if (r.result) {
                ajaxGet(that, "/api/admin/sysRole/" + that.$route.params.id, null, function(resDate) {
                  if (resDate != null) {
                    that.roleUsers = resDate.roleUsers;
                  }
                });
              }
            });
          }
        })
      },
      handleChange() {

      },
      onBack() {
        let that = this;
        back(that);
      },
    },
  };

  function GetItem(that, val) {
    if (val.length !== 0) {
      val.forEach((item) => {
        item.disabled = true;
        if (item.children.length >= 1) {
          GetItem(that, item.children);
        }
      });

    }
  }

</script>

<style scoped="scoped" lang="less">
  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }

  .spIcon {
    width: 5px;
    height: 20px;
    border-radius: 5px;
    background-image: linear-gradient(#4545FF, #2D8EFF);
  }

  .spTitle {
    float: left;
    margin-left: 20px;
    color: #323E56;
    letter-spacing: 2px;
    font-size: 16px;
    margin-top: -3px;
  }

  .wMax {
    width: 100% !important;
  }

  .deleteFont {
    color: #F56C6C;
  }

  .deleteFont:hover {
    color: #f3baba;
  }

</style>

