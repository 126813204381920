<!-- 评比 -->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          搜索
        </h3>
      </div>
      <div class="card-body ">
        <el-form ref="form" :model="SearchList" label-width="80px" class="ruleForm">
          <el-row :gutter="20">
            <el-col :lg="6" :sm="12">
              <el-form-item label="学期">
                <el-select v-model="SearchList.SemesterId" filterable placeholder="请选择" class="width_100Pie"
                  @change="handleSemsterChange">
                  <el-option v-for="item in semesterList" :key="item.semesterId" :label="item.name"
                    :value="item.semesterId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="6" :sm="12">
              <el-form-item label="年级">
                <el-select v-model="SearchList.CollegeId" filterable placeholder="请选择年级" @change="handleCollegeChange"
                  class="width_100Pie">
                  <el-option v-for="item in collegeList" :key="item.Value" :label="item.Title" :value="item.Value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="6" :sm="12">
              <el-form-item label="班级">
                <el-select v-model="SearchList.ClassId" filterable clearable placeholder="请选择班级" class="width_100Pie">
                  <el-option v-for="item in classList" :key="item.classId" :label="item.alias" :value="item.classId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="card-footer text-center">
        <el-button type="primary" class="mr-2" slot="append" icon="el-icon-search" @click.native="onSearch()">查询
        </el-button>
        <el-button slot="append" class="mr-2" icon="el-icon-refresh" @click.native="onResetSearch()">重置</el-button>
      </div>
    </el-card>



    <el-card class="box-card ">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          <ul class="nav nav-pills navTabsCont">
            <li class="nav-item" v-for="item in typeList" :key="item.id">
              <el-button :class="ruleForm.id==item.id?'nav-link active':'nav-link'" @click="onChangetype(item.id)"
                class="mr-2">
                {{item.name}}
              </el-button>
            </li>
          </ul>
        </h3>
      </div>
      <div class="card-body" v-if="itemData!=null && itemData.length!=0">
        <el-table stripe :data="itemData" class="elTableData" :border="true">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="class" label="班级" min-width="160"></el-table-column>
          <el-table-column prop="semester" label="学期" min-width="160" v-if="ruleForm.id!=3"></el-table-column>
          <el-table-column label="日期" min-width="160" v-if="ruleForm.id==2">
            <template slot-scope="scope">
              第{{scope.row.date}}周
            </template>
          </el-table-column>
          <el-table-column prop="name" label="奖励名称" min-width="160" v-if="ruleForm.id==3"></el-table-column>
          <el-table-column prop="semester" label="学期" min-width="160" v-if="ruleForm.id == 3">
            <template>
              <span>{{getSemester}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="member" label="学生" min-width="200" v-if="ruleForm.id==3">
            <template slot-scope="scope">
              <span>{{scope.row.students}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="type" label="奖励级别" min-width="120" v-if="ruleForm.id==3">
            <template slot-scope="scope">
              <span>{{scope.row.level.name}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="date" label="时间" min-width="160" v-if="ruleForm.id==4">
            <template slot-scope="scope">
              第{{scope.row.date}}周
            </template>
          </el-table-column>
          <el-table-column prop="topic" label="班课课题" min-width="160" v-if="ruleForm.id==5">
            <template slot-scope="scope">
              <span class="hiddenText" :title="scope.row.topic | formatText">
                {{scope.row.topic | formatText}}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="objective" label="本课目的" min-width="160" v-if="ruleForm.id==5">
            <template slot-scope="scope" :title="cope.row.objective | formatText">
              <span class="hiddenText"
                :title="scope.row.objective | formatText">{{scope.row.objective | formatText}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="studentNo" label="学号" min-width="160"
            v-if="ruleForm.id==6||ruleForm.id==7||ruleForm.id==8||ruleForm.id==10"></el-table-column>
          <el-table-column prop="studentName" label="姓名" min-width="160" v-if="ruleForm.id==6"></el-table-column>
          <el-table-column prop="merits" label="主要优点" min-width="300" v-if="ruleForm.id==6">
            <template slot-scope="scope">
              <span class="hiddenText" :title="scope.row.merits | formatText">
                {{ scope.row.merits| formatText}}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="abnormalBehavior" label="主要偏长行为" min-width="300" v-if="ruleForm.id==7">
            <template slot-scope="scope">
              <span v-html="scope.row.abnormalBehavior"></span>
            </template>
          </el-table-column>
          <el-table-column prop="relationShip" label="与学生的关系" min-width="160" v-if="ruleForm.id==8"></el-table-column>
          <el-table-column prop="contactWay" label="联系方式" min-width="160" v-if="ruleForm.id==8"></el-table-column>
          <el-table-column prop="effect" label="效果" min-width="300" v-if="ruleForm.id==8">
            <template slot-scope="scope">
              <span class="hiddenText" :title="scope.row.effect | formatText ">{{scope.row.effect | formatText}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="dateString" label="时间" min-width="160" v-if="ruleForm.id==9"></el-table-column>
          <el-table-column prop="place" label="地点" min-width="160" v-if="ruleForm.id==9"></el-table-column>
          <el-table-column prop="numberOfPeople" label="人数" min-width="160" v-if="ruleForm.id==9"></el-table-column>
          <el-table-column prop="organizer" label="组织者" min-width="160" v-if="ruleForm.id==9"></el-table-column>
          <el-table-column prop="talkDateString" label="日期" min-width="160" v-if="ruleForm.id==10"></el-table-column>


          <el-table-column prop="adviser" label="班主任" min-width="160" v-if="ruleForm.id!=3"></el-table-column>
          <el-table-column prop="number" label="操作" min-width="120">
            <template slot-scope="scope">
              <operationBtn v-if="isPower.manualinspection_detalis&&ruleForm.id==1" :btnName="'查看'"
                @click.native="onDetail(scope.row.planId,ruleForm.id)"></operationBtn>
              <operationBtn v-if="isPower.manualinspection_detalis&&ruleForm.id==2" :btnName="'查看'"
                @click.native="onDetail(scope.row.planId,ruleForm.id)"></operationBtn>
              <operationBtn v-if="isPower.manualinspection_priceDetail&&ruleForm.id==3" :btnName="'查看'"
                @click.native="onDetail(scope.row.prizeId,ruleForm.id)"></operationBtn>
              <operationBtn v-if="isPower.manualinspection_detalis&&ruleForm.id==4" :btnName="'查看'"
                @click.native="onDetail(scope.row.memoId,ruleForm.id)"></operationBtn>
              <operationBtn v-if="isPower.manualinspection_detalis&&ruleForm.id==5" :btnName="'查看'"
                @click.native="onDetail(scope.row.meetingId,ruleForm.id)"></operationBtn>
              <operationBtn v-if="isPower.manualinspection_detalis&&ruleForm.id==6" :btnName="'查看'"
                @click.native="onDetail(scope.row.doubleEugenicsId,ruleForm.id)"></operationBtn>
              <operationBtn v-if="isPower.manualinspection_detalis&&ruleForm.id==7" :btnName="'查看'"
                @click.native="onDetail(scope.row.abnormalId,ruleForm.id)"></operationBtn>
              <operationBtn v-if="isPower.manualinspection_detalis&&ruleForm.id==8" :btnName="'查看'"
                @click.native="onDetail(scope.row.recordId,ruleForm.id)"></operationBtn>
              <operationBtn v-if="isPower.manualinspection_detalis&&ruleForm.id==9" :btnName="'查看'"
                @click.native="onDetail(scope.row.activityId,ruleForm.id)"></operationBtn>
              <operationBtn v-if="isPower.manualinspection_detalis&&ruleForm.id==10" :btnName="'查看'"
                @click.native="onDetail(scope.row.educationId,ruleForm.id)"></operationBtn>
              <operationBtn v-if="isPower.manualinspection_detalis&&ruleForm.id==11" :btnName="'查看'"
                @click.native="onDetail(scope.row.summaryId,ruleForm.id)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick"
          v-if="ruleForm.id!=1||ruleForm.id!=11">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>

  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';

  export default {
    name: '',
    components: {
      comPage,
      operationBtn
    },
    computed: {
      getSemester() {
        return this.semesterList.find(it => it.semesterId == this.SearchList.SemesterId).name
      }
    },
    data() {
      return {
        fullscreenLoading: false,

        tabsIndex: 0,

        itemData: [], //列表数据
        collegeList: [], //年级列表
        typeList: [{
            name: '学期工作计划',
            id: 1,
          },
          {
            name: '活动计划',
            id: 2,
          },
          {
            name: '学生获奖',
            id: 3,
          },
          {
            name: '班级备忘录',
            id: 4,
          },
          {
            name: '主题班会',
            id: 5,
          },
          {
            name: '双优生分析',
            id: 6,
          },
          {
            name: '行为偏长辅导',
            id: 7,
          },
          {
            name: '家校联系记录',
            id: 8,
          },
          {
            name: '家长学校活动',
            id: 9,
          },
          {
            name: '个别谈话',
            id: 10,
          },
          {
            name: '学期总结',
            id: 11,
          },
        ], //手册类型
        classList: [], //班级列表
        semesterList: [], //学期列表
        mapCollegeToClass: {}, //部门隐射班级
        daterange: [],
        page: null,
        pageBtnList: null,
        query: null,
        //分页数据
        paging: {
          // 总共页数
          pageLength: 0,
          // 每页请求的数据量
          Size: 10,
          //当前在第几页
          Index: 1
        },
        ruleForm: {
          id: null,
        },
        SearchList: { //搜索列表集合
          SemesterId: null,
          CollegeId: null,
          ClassId: null,
          RecordDateStart: null,
          RecordDateEnd: null,
          AppraisalType: null,
        },
        api: '/api/admin/adviserworkplan/user/search',
        isPower: { //权限
          manualinspection_detalis: false,
          manualinspection_priceDetail: false
        }
      };
    },
    filters: {
      formatText(val) {
        let content = val.replace(/<.+?>/g, '');
        content = content.replace(/ /ig, ''); /* 去除  */
        content = content.replace(/\s/ig, ''); /* 去除空格 */
        return content
      }
    },
    created() {
      let that = this;
      that.fullscreenLoading = true
      isPower(that, "manualinspection_detalis", "manualinspection_detalis")
      isPower(that, "manualinspection_priceDetail", "manualinspection_priceDetail")
      ajaxGet(that, "/api/admin/semester", null, function(res) {
        if (!regNullArray(res)) {
          let curSemester = res.find(it => it.isCurrent).semesterId
          that.curSemester = curSemester
          that.SearchList.SemesterId = that.$route.query.SemesterId || curSemester //默认为当前学期
          that.ruleForm.id = that.$route.query.type || 1
          that.SearchList.ClassId = that.$route.query.ClassId || null
          that.semesterList = res;
        }


        ajaxGet(that, "/api/admin/college", null, function(res) {
          if (!regNullArray(res)) {
            that.collegeList = setSelect(res, "name", "collegeId")
            that.SearchList.CollegeId = that.$route.query.CollegeId || res[0].collegeId
          }

          ajaxGet(that, "/api/admin/college/linkage", null, function(res2) {
            that.fullscreenLoading = false
            if (!regNullArray(res2)) {
              mapCollege(res2, that.mapCollegeToClass)
              that.handleCollegeChange(res[0].collegeId)
              if (that.$route.query.type) {
                that.onChangetype(that.$route.query.type)
              } else {
                initPage(that)
              }
            }

          })
        })
      })
    },
    methods: {
      onTabClick() {

      },
      //切换手册类型
      onChangetype(id) {
        let that = this;
        that.api = null,
          that.ruleForm.id = id;
        if (id == 1) {
          that.api = '/api/admin/adviserworkplan/user/search';
          initPage(that);
        } else if (id == 2) {
          that.api = '/api/admin/adviseractivityplan/user/search';
          initPage(that);
        } else if (id == 3) {
          that.api = '/api/admin/prize/search';
          initPage(that);
        } else if (id == 4) {
          that.api = '/api/admin/adviserclassworkmemo/user/search';
          initPage(that);
        } else if (id == 5) {
          that.api = '/api/admin/adviserclassmeeting/user/search';
          initPage(that);
        } else if (id == 6) {
          that.api = '/api/admin/adviserdoubleeugenics/user/search';
          initPage(that);
        } else if (id == 7) {
          that.api = '/api/admin/adviserabnormalbehavior/user/search';
          initPage(that);
        } else if (id == 8) {
          that.api = '/api/admin/advisercontactrecord/user/search';
          initPage(that);
        } else if (id == 9) {
          that.api = '/api/admin/adviserparentactivityrecord/user/search';
          initPage(that);
        } else if (id == 10) {
          that.api = '/api/admin/adviserindividualeducation/user/search';
          initPage(that);
        } else if (id == 11) {
          that.api = '/api/admin/adviserworksummary/user/search';
          initPage(that);
        }
      },
      handleCollegeChange(val) {
        this.classList = []
        this.SearchList.ClassId = null
        this.classList = this.mapCollegeToClass[val]
      },
      handleDateChange() {
        let that = this
        if (that.daterange && that.daterange.length > 0) {
          that.SearchList.RecordDateStart = that.daterange[0]
          that.SearchList.RecordDateEnd = that.daterange[1]
        } else {
          that.SearchList.RecordDateStart = null
          that.SearchList.RecordDateEnd = null
        }
      },
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },
      onExport() {
        let that = this
        that.fullscreenLoading = true
        let params = JSON.parse(JSON.stringify(that.SearchList))
        ajaxExport(that, "/api/admin/nightstudyappraisal/export", params, "晚自修评比统计.xls", function(res) {
          that.fullscreenLoading = false
        })
      },
      onDetail(id, type) {
        const {} = this.SearchList
        if (type == 3) {
          this.$router.push({
            path: `/Admin/Adviser/ManualInspection/studentPriceDetail/${id}`,
            query: {
              type,
              ...this.SearchList
            }
          })
        } else {
          this.$router.push({
            path: `/Admin/Adviser/ManualInspection/Details/${id}`,
            query: {
              type,
              ...this.SearchList
            }
          })
        }
      },
      onResetSearch() {
        let that = this
        that.SearchList.CollegeId = that.collegeList[0].Value;
        that.SearchList.SemesterId = that.$route.query.SemesterId || that.curSemester //默认为当前学期
        that.SearchList.ClassId = null
        that.SearchList.RecordDateStart = null
        that.SearchList.RecordDateEnd = null
        // that.SearchList.AppraisalType = that.typeList[0].Value
        that.daterange = []
        initPage(that)
      },
      onSearch() {
        let that = this;
        initPage(that)
      },
      handleSemsterChange(val) {

      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      pagingClick(type, val) {
        let that = this;
        if (type == 1) {
          //更改每页条数触发的事件
          that.paging.Size = val;
          that.paging.Index = 1;
        } else {
          //更改当前页时触发的事件
          that.paging.Index = val;
        }
        initPage(that)
      },
    }
  };

  function mapCollege(data, obj = {}) {
    data.forEach(item => {
      obj[item.collegeId] = item.classes
    })
  }

  function setSelect(data, name, id) {
    return data.map(item => {
      return {
        Title: item[name],
        Value: item[id]
      }
    })
  }

  function initPage(that) {
    let params = {
      PageNumer: that.paging.Index,
      PageSize: that.paging.Size,
      CollegeId: that.SearchList.CollegeId,
      ClassId: that.SearchList.ClassId,
      SemesterId: that.SearchList.SemesterId,
    }
    that.pageBtnList = null,
      that.page = null;
    that.itemData = [];
    ajaxGet(that, that.api, params, function(res) {
      if (that.ruleForm.id == 1 || that.ruleForm.id == 11) {
        that.itemData = res
      } else if (that.ruleForm.id == 3) {
        that.page = res;
        that.itemData = res.items.map(it => {
          it.students = it.members.map(item => item.student.name).join(',')
          it.class = it.members[0].student.className
          return it
        })
        that.fullscreenLoading = false;
      } else {
        that.page = res;
        that.itemData = res.items;
      }
      if (that.$route.query.type) {
        that.$router.push({
          path: '/Admin/Adviser/ManualInspection/Index',
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      }
    })
  }
</script>

<style scoped="scoped" lang="less">
  .tabsHeader {
    margin: -18px -20px;
    background-color: #f5f7fa;
    position: relative;

    .divSemesterBox {
      position: absolute;
      right: 5px;
      top: 5px;

      .spTitle {
        font-size: 14px;
      }
    }
  }


  .navTabsCont {
    margin: -5px;

    .nav-item {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .textStr {
    display: block;
  }

  .hiddenText {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
  }

  /deep/.cell {
    text-align: center;
  }
</style>

<style lang="less">
  .tabsHeader {
    .el-tabs__item {
      height: 50px;
      line-height: 50px;
    }

    .el-tabs__header {
      margin: 0;
      margin-bottom: -1px;
    }

    .el-tabs--card>.el-tabs__header .el-tabs__nav {
      border: none;
    }

    .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
      background-color: #FFFFFF;
    }
  }
</style>
