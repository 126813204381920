<!--
  ECHARTS 自定义饼图 只显示饼图，其他信息不显示
-->
<template>
  <div :id="ChartID" :style="pieStyle"></div>
</template>

<script>
  import 'echarts/lib/component/tooltip';
  // 引入基本模板
  let echarts = require('echarts/lib/echarts');
  require('echarts/lib/chart/pie');
  export default {
    name: 'chart_pie',
    props: {
      //ID
      ChartID: {
        type: String,
        default: 'myChart'
      },
      // 数据
      ChartData: {
        type: Array,
        default: () => {
          return [];
        }
      },
      // 颜色
      ChartColor: {
        type: Array,
        default: () => {
          return ['#7964E4', '#2291D9', '#73CF43', '#F9BB4A'];
        }
      },
      //高度
      ChartHeight: {
        type: Number,
        default: 250
      },
      // 饼图环圈厚度
      ChartRadius: {
        type: Array,
        default: () => {
          return ['90%', '100%'];
        }
      },
      // 饼图位置
      ChartCenter: {
        type: Array,
        default: () => {
          return ['50%', '50%'];
        }
      },
      // 数据源名称
      ChartName: {
        type: String,
        default: '访问来源'
      }
    },
    data() {
      return {
        pieStyle: '',
        myChart: {}
      };
    },
    created() {
      let that=this;
      if(that.ChartData!=null && that.ChartData.length!=0){
        that.$nextTick(() => {
          that.drawLine();
        })
        that.pieStyle = 'width: 100%; height:' + (that.ChartHeight * 0.0625) + 'rem;';
      }
    },
    mounted() {
      let that = this;
      window.onresize = function() {
        that.myChart.resize();
        that.pieStyle = 'width: 100%; height:' + (that.ChartHeight * 0.0625) + 'rem;';
      }


    },
    methods: {
      onGetRadar(valChartData) {
        let that = this;
        if(valChartData!=null && valChartData.length!=0){
          that.ChartData = valChartData;
          that.$nextTick(() => {
            that.drawLine();
          })
          that.pieStyle = 'width: 100%; height:' + (that.comHeight * 0.0625) + 'rem;';
        }
      },
      drawLine() {
        let that=this;
        // 基于准备好的dom，初始化echarts实例
        that.myChart = echarts.init(document.getElementById(that.ChartID));
        // 绘制图表
        that.myChart.setOption({

          color: that.ChartColor,
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)'
          },
          series: [{
            name: that.ChartName,
            type: 'pie',
            radius: that.ChartRadius,
            center: that.ChartCenter,
            data: that.ChartData,
            hoverAnimation: false,
            labelLine: {
              show: false
            },
            label: {
              show: false
            },
          }]
        });
      }
    }
  };
</script>

<style>
</style>
