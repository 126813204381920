<!--
  新增流程活动
-->
<template>
  <div class="SubWebPage">

    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{Title}}
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="获奖名称" prop="name" label-width="100px">
                <el-input v-model="ruleForm.name" placeholder="请输入获奖名称" class="formControl"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="综合分系数" prop="coefficient" label-width="100px">
                <el-input @change="onCoefficientChange()" v-model="ruleForm.coefficient" placeholder="请输入综合分系数,活动获奖所得综合分数 = 活动综合分×综合分系数（建议填写的综合分系数小于1）"
                  class="formControl"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" v-if="ruleForm.joinType == 1">
              <el-form-item label="学生" prop="studentId" label-width="100px">
                <el-select v-model="ruleForm.studentId" placeholder="请选择" style="width: 100% !important;" @change="onChangeStudent"
                  class="formControl" filterable>
                  <el-option v-for="item in optStudent" :key="item.studentId" :label="item.studentName"
                    :value="item.studentId">
                    {{ item.studentName }}（{{item.className}}，{{item.studentNo}}，{{item.roleName}}）
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24" v-if="ruleForm.joinType == 2">
              <el-form-item label="班级" prop="classId" label-width="100px">
                <el-select v-model="ruleForm.classId" placeholder="请选择" style="width: 100% !important;" class="formControl" filterable>
                  <el-option v-for="item in optClass" :key="item.classId" :label="item.className" :value="item.classId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>
      <div class="row" style="margin-top: 10px;">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
          <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
          <el-button type="primary" icon="el-icon-circle-check" @click="onSave()">提交</el-button>
        </div>
      </div>

    </el-form>



  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  export default {
    components: {},
    data() {

      return {
        ruleForm: {
          activityId: null, //活动主键
          prizeId: null, //获奖主键
          name: null, //获奖名称
          studentId: null, //学生主键
          coefficient: null, //系数
          joinType: null,
          classId: null,
        },
        ruleFormReset: null,
        Title: "新增",
        optStudent: [],
        optClass: [],
        rules: {
          name: [
            { required: true, message: '获奖名称不能为空', trigger: 'change' },
            { max: 256, message: '获奖名称不能超过256个字符', trigger: 'change'},
          ],
          classId: [
            { required: true, message: '班级不能为空', trigger: 'change' }
          ],
          studentId: [
            { required: true, message: '学生不能为空', trigger: 'change' }
          ],
          coefficient: [{
            required: true,
            message: '综合分系数不能为空',
            trigger: 'change'
          }, ],
        },
      };
    },
    created() {
      let that = this;
      // 评奖ID
      let prizeID = that.$route.params.id;
      // 活动ID
      let activityId = that.$route.params.activityId;
      that.ruleForm.activityId = activityId;
      var detailUrl = null;
      that.fullscreenLoading = true;
      // 获取活动数据
      ajaxGet(that, "/api/admin/activity/" + that.ruleForm.activityId, null, function(resActivity) {
        that.ruleForm.joinType = resActivity.joinType;
        that.optClass = resActivity.rosters;

        if (regNull(prizeID)) {
          that.Title = '新增【' + resActivity.name + '—评奖】';
        } else {
          that.Title = '编辑【' + resActivity.name + '—评奖】';
        }
        
        that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
        that.fullscreenLoading = false;


        // 获取评奖数据
        ajaxGet(that, "/api/admin/activity/prize/" + that.ruleForm.activityId, null, function(resPrize) {
          // 获取名单数据
          ajaxGet(that, "/api/admin/activity/roster/" + that.ruleForm.activityId, null, function(resRoster) {

            that.optStudent = [];
            for (let listRoster of resRoster) {
              // 判断是否已经评奖，已评奖的学生不能再次评奖
              if (resPrize.find(val => val.studentId == listRoster.studentId) == undefined) {
                // 判断是否需要报名签到
                if (resActivity.isAttendance) {
                  // 只有签到的学生才可以评奖
                  if (listRoster.attended) {
                    that.optStudent.push(listRoster);
                  }
                } else {
                  // 不需要签到时
                  // 不需要报名审核时，学生报名状态与审核通过一致，所以只需要判断学生状态是否为 审核通过
                  if (listRoster.status == 3) {
                    that.optStudent.push(listRoster);
                  }
                }
              }

            }



          });
        });

      });


    },

    mounted() {},
    methods: {
      onChangeStudent() {
        let that = this;
        that.ruleForm.classId = that.optStudent.find(x => x.studentId == that.ruleForm.studentId).classId;
      },
      onReset() {
        let that = this;
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
        that.$nextTick(() => {
          that.$refs.ruleForm.clearValidate();
        })
      },
      onBack() {
        let that = this;
        back(that);
      },
      onCoefficientChange() {
        let that = this;
        that.ruleForm.coefficient = setTwoDecimal(setTwoFloat(that.ruleForm.coefficient));
      },
      onSave() {
        let that = this;
        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            if (that.ruleForm.prizeId == null) {
              that.fullscreenLoading = true;
              ajaxPost(that, "/api/admin/activity/prize", that.ruleForm, function(r) {
                that.fullscreenLoading = false;
                that.onBack();
              });
            }
          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              isError[0].querySelector('input').focus();
            }, 1)
            return false;
          }
        });
      },

    },
    watch: {

    }
  };
</script>

<style scoped="scoped">

</style>
