<!--
  我的成绩
-->
<template>
  <div class="SubWebPageStudent" v-loading.fullscreen.lock="fullscreenLoading">


    <!-- 列表-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          我的成绩
        </h3>
        <!-- 搜索 -->
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <comSearch :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null"></comSearch>
            </li>
          </ul>
        </div>

      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table :data="itemData" stripe class="elTableData">
          <el-table-column type="index" label="序号" width="80"></el-table-column>
            <!-- <el-table-column prop="semester" label="学期" class="elColumn" width="200"></el-table-column> -->
            <el-table-column prop="courseName" label="课程" class="elColumn"></el-table-column>
            <el-table-column prop="peaceTime" label="平时" class="elColumn"></el-table-column>
            <el-table-column prop="midTerm" label="期中" class="elColumn"></el-table-column>
            <el-table-column prop="final" label="期末" class="elColumn"></el-table-column>
            <el-table-column prop="total" label="总评" class="elColumn"></el-table-column>
            <el-table-column prop="gpa" label="绩点" class="elColumn"></el-table-column>
            <el-table-column prop="credit" label="学分" class="elColumn"></el-table-column>
        </el-table>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
      <div class="hidden"><comPage :isPage="false" :paging="paging"></comPage></div>
    </el-card>
  </div>
</template>

<script>
  import '@/assets/css/Student.css';
  import comPage from '@/components/Page.vue';
  import comSearch from '@/components/search.vue';

  export default{
    components:{
      comSearch,comPage
    },
    data(){
      return{
        // 动画
        fullscreenLoading: false,
        // 搜索
        SearchList: null,
        paging:{
        	totalCount:0,
        },
        //列表数据
        itemData: [],
      };
    },
    methods:{
      onSearch(params)// 搜索
      {
      	let that = this;
      	let data = {};
      	let searchData = params;
      	searchData.forEach(element => {
      		if (element.data) {
      			data[element.zhi] = element.data;
      		}
      	});
      	routerPath(that, "/Student/AcademicRecord/Index", data, null, "PageSize");
      },
      // onDetail(Id)
      // {
      //   let that=this;
      //   that.$router.push({
      //     path: "/Student/AcademicRecord/Details/"+Id,
      //     query: { url: that.$router.history.current.fullPath }
      //   });
      // },


    },
    created() {
      let that = this;
      onGetDataPage(that);
    },
    mounted() {

    },
    watch: {
    	$route() {
    		let that = this;
    		onGetDataPage(that);
    	}
    }
  };

  function onGetDataPage(that) {
    paging(
      that,
      function(res) {
        let apiUrl = "/api/student/academicrecord/search";
        that.fullscreenLoading = true;

        // 搜索 下拉学期
        ajaxGet(that, "/api/student/semester/student", null, function(resSemester) {
          // 获得学期数据
          var SemesterId = res.SemesterId;
          let arrSemester = [];
          for (var i in resSemester) {
            let arrTemp = {
              Title: resSemester[i].name, //semester[item].name,
              Value: resSemester[i].semesterId //semester[item].semesterId,
            };
            if(SemesterId ==null || typeof(SemesterId) == "undefined" || SemesterId == "")
            {
            		if(resSemester[i].isCurrent == true)
            		{
            			SemesterId = resSemester[i].semesterId;
            		}
            }
            arrSemester.push(arrTemp);
          }
          that.SearchList = [
            // 学期
            {
              type: "select",
              Name: "学期",
              data: SemesterId,
              holder: "请选择学期",
              select: arrSemester, //选择的数据
              zhi: "SemesterId", // 传值
            },
          ];

          let data = {
            SemesterId:SemesterId,
          };
          ajaxGet(that, apiUrl, data, function(resData) {
            that.itemData = resData;
            that.paging.totalCount = that.itemData.length;
            that.fullscreenLoading = false;
          });
        })

      },
      "SemesterId",
    );
  }
</script>

<style scoped="scoped">

</style>
