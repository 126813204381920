<!--
  学生管理-学生奖励
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <search :ColNumber="3" :SearchList="SearchList" :key="random" ref="SearchList" @onSearch="onSearch"
      v-if="SearchList!=null"></search>
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          班主任学期考核
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <el-button type="success" size="mini" icon="el-icon-circle-plus-outline" @click.native="onCalculation()">发起学期考核</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table stripe :data="itemData" class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod" fixed="left"></el-table-column>
          <el-table-column prop="semester" label="学期" min-width="100" fixed="left"></el-table-column>
          <el-table-column prop="class" label="班级" min-width="100" fixed="left"></el-table-column>
          <el-table-column prop="teacher" label="班主任" min-width="100" fixed="left"></el-table-column>
          <el-table-column prop="rank" label="学期考核排名" min-width="100"></el-table-column>
          <el-table-column prop="score" label="学期考核分数" min-width="100"></el-table-column>
          <el-table-column prop="resultStr" label="学期考核结果" min-width="100"></el-table-column>
          <el-table-column label="操作" width="160px" fixed="right">
            <template slot-scope="scope">
              <operationBtn :btnName="'查看'" @click.native="onDetail(scope.row.assessmentId)"></operationBtn>
              <operationBtn :btnName="'编辑'" @click.native="onEdit(scope.row.assessmentId)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible2" width="30%" :close-on-click-modal="false"
      :close-on-press-escape="false" :append-to-body="true" :fullscreen="false">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="90px">
        <el-row >
          <el-col :span="24">
            <el-form-item label="学期" prop="semesterId">
              <el-select v-model="ruleForm.semesterId" placeholder="请选择学期" style="width: 100%;">
                <el-option v-for="item in semesterlist" :key="item.Value" :label="item.Title " :value="item.Value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="专业" prop="majorId">
              <el-select v-model="ruleForm.collegeId" placeholder="请选择专业" style="width: 100%;">
                <el-option v-for="item in majorList" :key="item.collegeId" :label="item.name " :value="item.collegeId">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <div >
          <el-button @click="dialogVisible2 = false">取 消</el-button>
          <el-button @click="onReset()">重 置</el-button>
          <el-button type="primary" @click="onSave()">确 定</el-button>
        </div>
      </span>
    </el-dialog>

    <el-dialog :title="itemdata.teacher" :visible.sync="dialogVisible" width="30%">
      <el-form :model="itemdata" ref="itemdata" label-width="120px">
        <el-row :gutter="24">
          <el-col :span="23" class="pr-4">
            <el-form-item label="学期考核结果" prop="date">
              <el-select v-model="itemdata.result" placeholder="请选择结果" style="width: 100%;">
                <el-option v-for="item in typelist" :key="item.Value" :label="item.Text" :value="item.Value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSaveType(itemdata.assessmentId)">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';

  export default {
    name: '',
    components: {
      comPage,
      operationBtn,
      search
    },
    data() {
      return {
        textarea:null,
        fullscreenLoading: false,
        dialogTitle: '学期考核计算',
        dialogVisible: false,
        dialogVisible2:false,
        page: null, //分页查询接口返回的结果
        itemData: [], //列表数据
        pageBtnList: null, //分页左侧按钮集合
        paging: { //分页数据
          pageLength: 0, // 总共页数
          Size: 10, // 当前请求数量
          Index: 1 //当前在第几页
        },
        random:null,
        rules:{
          semesterId:[{
            required: true,
            message: '请选择学期',
            trigger: 'change'
          }],
          collegeId:[{
          required: true,
          message: '请选择年级',
          trigger: 'change'
          }]
        },
        ruleForm:{
          collegeId:null,
        },
        ruleFormReset:{},
        SearchList: null, //搜索集合
        itemdata:{},
        majorList:[],
        semesterlist:[],
        typelist:[],
        isPower:{
          admin_assessment_semester_detail:false,
          admin_assessment_semester_create:false,
          admin_assessment_academic_edit:false,
        }
      };
    },
    created() {
      let that = this;
      isPower(that,'admin_assessment_semester_detail','admin_assessment_semester_detail');
      isPower(that,'admin_assessment_semester_create','admin_assessment_semester_create');
      isPower(that,'admin_assessment_academic_edit','admin_assessment_academic_edit');
      that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
      pagingPage(that);
    },
    methods: {
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },
      onSaveType(id){
        let that =this;
        if (regNull(that.itemdata.result)) {
          warning(that, '请选择更改的考核结果');
          return false;
        }
        let data={
          semesterResult:parseInt(that.itemdata.result),
        }
        that.fullscreenLoading=true;
        ajaxPut(that,'/api/admin/advisersemesterassessment/'+id+'?semesterResult='+data.semesterResult,null,function(res){
          that.fullscreenLoading=false;
          that.dialogVisible=false;
          pagingPage(that);
        })
      },
      onCalculation(){
        let that=this;
        that.fullscreenLoading=true;
        ajaxGet(that,'/api/admin/college',null,function(resdata){
          that.fullscreenLoading=false;
          that.majorList=resdata;
          that.dialogVisible2=true;
        })
      },
      onDetail(Id) {
        let that = this;
        that.$router.push({
          path: "/Admin/Assessment/Semester/Details/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onEdit(Id) {
        let that = this;
        that.fullscreenLoading=true;
        ajaxGet(that,'/api/admin/advisersemesterassessment/'+Id,null,function(res){
          if(res!=''){
            ajaxGet(that,'/api/admin/advisersemesterassessment/type',null,function(r2){
            that.typelist=r2;
            that.itemdata=res;
            if(that.itemdata.result!=''&&that.itemdata.result!=null&&that.itemdata.result!=undefined)
            {
              that.itemdata.result=that.itemdata.result.toString();
            }
            that.fullscreenLoading=false;
            })
          }
        })
        that.dialogVisible=true;
      },
      onReset(){
          let that=this;
          that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
      },
      onSave(){
        let that =this;
        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
        that.fullscreenLoading=true;
        ajaxPost(that,'/api/admin/advisersemesterassessment',that.ruleForm,function(resdata){
          that.fullscreenLoading=false;
          that.dialogVisible2=false;
          pagingPage(that);
        })
        }
        else{
          setTimeout(() => {
            var isError = document.getElementsByClassName("is-error");
            isError[0].querySelector('input').focus();
          }, 1)
          return false;
        }
        })
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      onSearch(params) {
        let that = this,
          data = {},
          searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = Join(element.data);
          }
        });
        data.PageNumer = 1;
        routerPath(that, "/Admin/Assessment/Semester/Index", data, null, "PageSize");
      },
     pagingClick(type, val) {
       let that = this;
       let url = '/Admin/Assessment/Semester/Index';
       if (type == 1) { //更改每页条数触发的事件
         routerPath(
           that,
           url,
           "PageSize",
           val,
           "PageNumer",
           "semesterId",
           "teacher",
         );
       } else { //更改当前页时触发的事件
         routerPath(
           that,
           url,
           "PageNumer",
           val,
           "PageSize",
           "semesterId",
           "teacher",
         );
       }
     },
    },
    watch: {
      $route() {
        let that = this;
        pagingPage(that);
      }
    }
  };

  function pagingPage(that) {
    paging(
      that,
      function(r) {
        ajaxGet(that,'/api/admin/semester',null,function(ressemester){
          let semester=[];
          for(var it of ressemester)
          {
            semester.push({
              Value:it.semesterId,
              Title:it.name,
            })
            that.semesterlist=semester;
          }
        let semesterId=r.semesterId;
        that.SearchList=[
        {
          type: "select",
          Name: "学期",
          data: semesterId,
          select: that.semesterlist,
          zhi: "semesterId",
          isClear: false, //select是否可以清除
        }, {
          type: "input",
          Name: "班主任",
          data: r.teacher,
          holder: "请输入学号/姓名",
          zhi: "teacher"
        }];
        let tempData={
          PageNumer: r.PageNumer,
          PageSize: r.PageSize,
          SemesterId:semesterId,
          TeacherName:r.teacher,
        }
        that.itemData=[];
        ajaxGet(that,'/api/admin/advisersemesterassessment/search',tempData,function(resData){
          that.fullscreenLoading = false;
          if (!regNullArray(resData.items)) {
            that.page = resData;
            that.itemData = resData.items;
          }
          });
        });
      },
      "semesterId",
      "teacher",
    );
  }
</script>

<style scoped="scoped">
</style>
