
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-row :gutter="20">
      <el-col :span="24">
        <el-card class="box-card">
          <div slot="header" class="card-header">
            <h3 class="card-title titleCont" style="padding-left: 0px;">
              宿舍楼打卡率
            </h3>
          </div>
          <div class="card-body">
            <comChartBar :comID="'myChartBar'" :comHeight="500" ref="myChartBar" :key="ChartBarKey"></comChartBar>
          </div>
        </el-card>
      </el-col>
      <el-col :span="24">
        <el-card class="box-card">
          <div slot="header" class="card-header">
            <h3 class="card-title titleCont" style="padding-left: 0px;">
              男女生打卡率
            </h3>
          </div>
          <div class="card-body">
            <el-row :gutter="20">
              <el-col :span="12">
                <div class="comTitle"> 男生打卡率</div>
                <comChartBar :comID="'myChartPie1'" :comHeight="500" ref="myChartPie1" :key="ChartPieKey1"></comChartBar>
              </el-col>
              <el-col :span="12">
                <div class="comTitle"> 女生打卡率</div>
                <comChartBar :comID="'myChartPie2'" :comHeight="500" ref="myChartPie2" :key="ChartPieKey2"></comChartBar>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <div class="row">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/css/Admin.css';
import comChartBar from '@/components/Statistics/DivChartBar.vue';
import { element } from 'screenfull';
export default {
  components: {
    comChartBar
  },
  data() {
    return {
      fullscreenLoading: false,
      itemData: [], //列表数据

      isPower: {

      },
      ChartBarKey: Math.floor(Math.random() * (100000 - 1 + 1)) + 10000,
      ChartPieKey1: Math.floor(Math.random() * (100000 - 1 + 1)) + 10000,
      ChartPieKey2: Math.floor(Math.random() * (100000 - 1 + 1)) + 10000,
      attendanceId: null,
    };
  },
  created() {
    let that = this;
    onGetData(that);
  },
  methods: {
    // 返回
    onBack() {
      let that = this;
      back(that);
    },
  },
  watch: {
    $route() {
      let that = this;
      onGetData(that);
    }
  }
}

function onGetData(that) {
  if (regNull(that.attendanceId)) {
    that.attendanceId = that.$route.query.memberId;
  }
  that.fullscreenLoading = true;
  ajaxGet(that, '/api/attendance/statisbybuilding', {attendanceId: that.attendanceId}, function (resBuilding) {
    ajaxGet(that, '/api/attendance/statisbygender', {attendanceId: that.attendanceId}, function (resGender) {
      that.fullscreenLoading = false;
      // 男女生打卡率
      resGender.forEach((element, index) => {
        if (!regNull(that.$refs['myChartPie' + (index + 1)])) {
          that.$refs['myChartPie' + (index + 1)].onGetRadar(onGetBarHtml2(element));
        }
      });

      // 宿舍楼打卡率
      if (!regNull(that.$refs.myChartBar)) {
        that.$refs.myChartBar.onGetRadar(onGetBarHtml(resBuilding));
      }
    });
    

  });
  
  

}

function onGetBarHtml2(res) {
  return  {
    tooltip: {
      trigger: 'item'
    },
    series: [
      {
        type: 'pie',
        radius: '50%',
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2
        },
        label: {
          formatter: '{a|{b}}{abg|}\n{hr|}\n  {b|{c}}  {per|{d}%}  ',
          backgroundColor: '#F6F8FC',
          borderColor: '#8C8D8E',
          borderWidth: 1,
          borderRadius: 4,
          rich: {
            a: {
              color: '#6E7079',
              lineHeight: 22,
              fontWeight: 'bold',
              align: 'center'
            },
            hr: {
              borderColor: '#8C8D8E',
              width: '100%',
              borderWidth: 1,
              height: 0
            },
            b: {
              color: '#4C5058',
              fontSize: 14,
              fontWeight: 'bold',
              lineHeight: 33
            },
            per: {
              color: '#fff',
              backgroundColor: '#4C5058',
              padding: [3, 4],
              borderRadius: 4
            }
          }
        },
        data: [
          { value: res.actual, name: '已打卡' },
          { value: res.total - res.actual, name: '未打卡' },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  }
}

function onGetBarHtml(res) {
  return {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        crossStyle: {
          color: '#999'
        }
      }
    },

    legend: {
      data: ['已打卡', '未打卡', '打卡率']
    },
    xAxis: [
      {
        type: 'category',
        data: res.map(x => { return x.text } ),
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: '',
        splitLine: {
          show: false
        },
        axisLabel: {
          formatter: '{value} 人'
        }
      },
      {
        type: 'value',
        name: '',
        splitLine: {
          show: false
        },
        axisLabel: {
          formatter: '{value} %'
        }
      },

    ],
    series: [
      {
        name: '已打卡',
        type: 'bar',
        stack: 'Ad',
        barWidth: '40%',
        tooltip: {
          valueFormatter: function (value) {
            return value + ' （人）';
          }
        },

        data: res.map(x => { return x.actual } )
      },
      {
        name: '未打卡',
        type: 'bar',
        stack: 'Ad',
        tooltip: {
          valueFormatter: function (value) {
            return value + ' （人）';
          }
        },
        data: res.map(x => { return x.total - x.actual } )
      },
      {
        name: '打卡率',
        type: 'line',
        yAxisIndex: 1,
        tooltip: {
          valueFormatter: function (value) {
            return value + ' %';
          }
        },
        data: res.map(x => { return x.scale } )
      }
    ]
  }
}
</script>

<style scoped="scoped" lang="less">
.comTitle {
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
  color: #7c7b7b;
}
.tabsHeader {
  margin: -18px -20px;
  background-color: #f5f7fa;
  position: relative;

  .divSemesterBox {
    position: absolute;
    right: 6px;
    top: 6px;

    .spTitle {
      font-size: 14px;
    }
  }
}

.selTitleBox {
  font-size: 14px;
}

.selTitleBox .spTitle {
  color: #303133;
}

.contentHidden {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
}

.divBtnCont {
  text-align: right;
  padding-bottom: 0.9375rem;
  /*15px*/
}
</style>

<style lang="less">
.tabsHeader {
  .el-tabs__header {
    margin: 0;
    margin-bottom: -1px;
  }

  .el-tabs--card>.el-tabs__header .el-tabs__nav {
    border: none;
  }

  .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
    background-color: #FFFFFF;
  }
}

.elTableData {
  .tdClass {
    background-color: #edf5ff !important;
  }

  .tdDormitory {
    background-color: #f0f9eb !important;
  }
}
</style>
