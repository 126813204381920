<!--
  处分管理 - 新增
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          查看奖励信息
        </h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-12 colInfo">
            <label>奖项名称</label>
            <div class="colFonts">
              {{ruleForm.name | flNoValue}}
            </div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>奖励级别</label>
            <div class="colFonts">
              {{ruleForm.level.name | flNoValue}}
            </div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>奖励日期</label>
            <div class="colFonts">
              {{ruleForm.prizeDateString | flNoValue}}
            </div>
          </div>
          <div class="col-lg-12 colInfo">
            <label>备注</label>
            <div class="colFonts" v-if="ruleForm.remark!=null && ruleForm.remark.length>0" v-html="ruleForm.remark">
            </div>
            <div class="colFonts" v-else>暂无数据</div>
          </div>
        </div>
      </div>

    </el-card>

    <!--学生列表-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          获奖名单
        </h3>
      </div>
      <div class="card-body p-0">
        <el-table stripe :data="selectStudentList">
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column prop="studentName" label="学生"></el-table-column>
          <el-table-column prop="className" label="班级"></el-table-column>
          <el-table-column prop="studentNo" label="学号"></el-table-column>
          <el-table-column prop="prizeCount" label="奖励记录（条）"></el-table-column>
          <el-table-column prop="score" label="累计分数（分）"></el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <el-button size="mini" type="success"
                @click="onStudentPrizeDetail(scope.row.studentName,scope.row.className,scope.row.studentNo,scope.row.studentId)">
                查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>

    <!--附件-->
    <el-card class="box-card" v-if="uploadFiles!=null && uploadFiles.length!=0">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          附件<small class="color-danger">(点击图片查看原图)</small>
        </h3>
      </div>
      <div class="card-body ">
        <el-carousel height="500px" :autoplay="!showViewer">
          <el-carousel-item v-for="(item,index) in uploadFiles" :key="index" class="VerticalMid">
            <img :src="item" @click="onPreview" height="100%">
          </el-carousel-item>
        </el-carousel>
      </div>
    </el-card>

    <!-- 综合类型 -->
    <el-card class="box-card" v-if="arrGrowthConfigs.length!=0">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          综合类型
        </h3>
      </div>
      <div class="card-body ">
        <div class="divLiteracyBox clearfix">
          <div class="listBox" :style="{'width':styleWidth}" v-for="item in arrGrowthConfigs" :key="item.configId">
            <el-tooltip class="item" effect="dark" placement="top" content="点击查看详细">
              <div @click="onOpenTypeInfo(item)">
                <div class="literacyName">
                  {{item.name}}
                </div>
                <div class="literacyNum">
                  {{onCalculationScore(item.children)}}
                </div>
              </div>
            </el-tooltip>
          </div>

        </div>
      </div>
    </el-card>

    <div class="row">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
      </div>
    </div>

    <el-dialog :title="dialogStudentName+'（'+dialogClassName+' | '+dialogStudentNo+'） 奖励记录'"
      :visible.sync="dialogTableVisible" width="80%">
      <el-table :data="studentPrizes" border show-summary :summary-method="getDialogSummaries">
        <el-table-column type="index" width="120px;" label="序号"></el-table-column>
        <el-table-column prop="name" label="奖项"></el-table-column>
        <el-table-column prop="type" label="奖励类型"></el-table-column>
        <el-table-column prop="level" label="奖励级别"></el-table-column>
        <el-table-column prop="prizeDate" label="奖励时间"></el-table-column>
        <el-table-column prop="score" label="分数"></el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogTableVisible = false">关 闭</el-button>
      </div>
    </el-dialog>

    <!-- 综合类型弹出框 -->
    <el-dialog :title="dialogScoreName" :visible.sync="dialogScoreVisible" width="30%">
      <div class="divTagBox">
        <span class="spTab" v-for="item in dialogArray" :key="item.configId">
          <el-tag type="info">{{item.name}}<span class="spScore">
              {{item.score}}
            </span></el-tag>
        </span>
      </div>
    </el-dialog>


    <!--配合走马灯效果放大图片组件-->
    <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="uploadFiles" />

  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import ElImageViewer from 'element-ui/packages/image/src/image-viewer';

  export default {
    components: {
      ElImageViewer
    },
    data() {
      return {
        // 综合类型详情框
        dialogScoreVisible: false,
        // 综合类型弹出框名称
        dialogScoreName: null,
        // 综合弹出框内的数据
        dialogArray: [],

        // 学生历史数据弹出框
        dialogTableVisible: false,
        // 学生历史弹出框 学生名称
        dialogStudentName: null,
        // 学生历史弹出框 班级名称
        dialogClassName: null,
        // 学生历史弹出框 学号名称
        dialogStudentNo: null,
        // 单个学生的历史处分
        studentPunsihs: [],
        // 学生列表
        selectStudentList: [],
        isOne: false, //是否只能选择一个学生
        //单个学生的历史奖励
        studentPrizes: [],
        selectStudentListReset: [], //选中的学生集合
        random: 0, //刷新组件




        ruleForm: //表单数据
        {
          name: null,
          prizeId: null, //奖励主键，新增时为null
          semesterId: null, //学期
          typeId: null, //奖励类型
          levelId: null, //奖励等级主键
          prizeDate: null, //奖励日期
          remark: null, //备注
          GrowthConfigs: [], //综合类型集合
          members: [],
          attachment: null,
          level: {
            name: null,
          },
          type: {
            name: null,
          }
        },
        query: null,
        allStudents: [], //所有学生集合
        selectClassId: null,
        selectStudentId: null,

        StudentList: [], //班级学生集合

        fullscreenLoading: false,

        // 放大图片
        showViewer: false,
        uploadFiles: [],
        arrGrowthConfigs: [],
        styleWidth: '25%',
      };
    },
    filters: {
      flNoValue(obj) {
        if (obj == null || typeof(obj) == "undefined" || obj == '') {
          return '暂无数据';
        }
        return obj;
      }
    },
    created() {
      let _this = this;
      _this.query = _this.$route.query
      let id = _this.$route.params.Id;
      var detailUrl = null;
      if (id != null && typeof(id) != "undefined" && id != "") {
        _this.ruleForm.reasonId = id;
        detailUrl = "/api/admin/prize/" + id;
      }
      _this.fullscreenLoading = true;
      ajaxGet(_this, detailUrl, null, function(resData) {
        if (resData != null) {
          _this.ruleForm = resData;
          _this.uploadFiles = [];
          if (resData.attachment != null && typeof(resData.attachment) != "undefined" && resData.attachment != "") {
            var files = resData.attachment.split('|');
            for (var i in files) {
              _this.uploadFiles.push(_this.$ServerUrl() + files[i]);
            }
            var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
            _this.random += random;
          }
        }
        let punishRecord = [];
        if (resData.members != null && resData.members.length > 0) {
          punishRecord = resData.members[0].configs
        }


        _this.allStudents = [];
        ajaxGet(_this, "/api/admin/prize/Class", null, function(r1) {
          _this.ClassList = r1;
          for (var i in _this.ClassList) {
            var it = _this.ClassList[i];
            for (var j in it.students) {
              var stu = it.students[j];
              stu.classId = it.classId;
              stu.className = it.name;
              _this.allStudents.push(stu);
            }
          }



          _this.selectStudentList = [];
          for (var i in resData.members) {
            var member = resData.members[i];
            var prizeCount = 0;
            var score = 0;
            for (var j in _this.allStudents) {
              if (member.student.studentId == _this.allStudents[j].studentId) {
                prizeCount = _this.allStudents[j].prizes.length;
                for (var m in _this.allStudents[j].prizes) {
                  score += _this.allStudents[j].prizes[m].score;
                }
              }
            }
            _this.selectStudentList.push({
              studentId: member.student.studentId,
              prizeCount: prizeCount,
              score: score,
              className: member.student.className,
              studentName: member.student.name,
              studentNo: member.student.studentNo,
            });
          }


          ajaxGet(_this, "/api/admin/growthconfig", null, function(resGrowth) {
            _this.fullscreenLoading = false;
            //新增时
            if (punishRecord != null && punishRecord.length > 0) {
              for (let item of resGrowth) {
                let temp = punishRecord.find(val => val.configId == item.configId);
                if (temp != undefined) {
                  item.score = temp.score
                } else {
                  item.score = 0;
                }
              }
              RecursionData(_this, resGrowth);
              _this.styleWidth = setInt(100 / _this.arrGrowthConfigs.length) + '%';
            }

          });


        })

        _this.fullscreenLoading = false;

      });
    },

    methods: {
      /**
       * @description 打开综合类型弹出框
       * @param {Object} _data
       */
      onOpenTypeInfo(_data) {
        let _this = this;
        _this.dialogScoreName = _data.name;
        _this.dialogArray = [];
        for (let item of _data.children) {
          if (item.score != 0) {
            _this.dialogArray.push({
              name: item.name,
              score: item.score
            })
          }
        }

        if (_this.dialogArray.length != 0) {
          _this.dialogScoreVisible = true;
        } else {
          info(_this, '未配综合类型');
        }
      },


      /**
       * @description 计算综合分数
       * @param {Object} _data
       * @return {Number} 计算出的总分
       */
      onCalculationScore(_data) {
        let temp = 0;
        for (let item of _data) {
          temp = item.score + temp;
        }
        return temp;
      },

      /**
       * @description 打开放大图片
       */
      onPreview() {
        this.showViewer = true
      },


      /**
       * @description 关闭图片预览图
       */
      closeViewer() {
        this.showViewer = false
      },

      getTreeData(data) {
        // 循环遍历json数据
        for (var i = 0; i < data.length; i++) {

          if (data[i].children.length < 1) {
            // children若为空数组，则将children设为undefined
            data[i].children = undefined;
          } else {
            // children若不为空数组，则继续 递归调用 本方法
            this.getTreeData(data[i].children);
          }
        }
        return data;
      },
      onPictureHandleSuccess(param) {
        let that = this;
        that.ruleForm.attachment = "";
        for (var i in param) {
          if (that.ruleForm.attachment == "") {
            that.ruleForm.attachment = param[i].path;
          } else {
            that.ruleForm.attachment += "|" + param[i].path;
          }
        }
      },
      getDialogSummaries(param) {
        const {
          columns,
          data
        } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '累计分数';
            return;
          }
          const values = data.map(item => Number(item[column.property]));
          if (column.property === 'score') {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index];
          } else {
            sums[index] = '--';
          }
        });

        return sums;
      },
      getSummaries(param) {
        const {
          columns,
          data
        } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '累计分数';
            return;
          }
          const values = data.map(item => Number(item[column.property]));
          if (column.property === 'score') {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index];
          } else {
            sums[index] = '--';
          }
        });

        return sums;
      },
      onStudentPrizeDetail(studentName, className, studentNo, studentId) {
        let that = this;
        that.dialogStudentName = studentName;
        that.dialogClassName = className;
        that.dialogStudentNo = studentNo;
        that.dialogTableVisible = true;
        that.studentPrizes = [];
        for (var i in that.allStudents) {
          if (that.allStudents[i].studentId == studentId) {
            that.studentPrizes = that.allStudents[i].prizes;
          }
        }

        that.studentPrizes.sort(function(a, b) {
          return new Date(b.prizeDate).getTime() - new Date(a.prizeDate).getTime();
        });
      },
      onBack() {
        let that = this;
        that.$router.push({
          path: '/Admin/Adviser/ManualInspection/Index',
          query: that.query
        })
      },
    },
  };


  /**
   * @description 递归综合类型数据
   * @param {Object} _this
   * @param {Object} _data 数据组
   */
  function RecursionData(_this, _data) {
    if (_data.length !== 0) {
      _data.forEach((item) => {
        if (item.parentId == "00000000-0000-0000-0000-000000000000" && item.isConductEvaluation != true) {
          _this.arrGrowthConfigs.push({
            configId: item.configId,
            name: item.name,
            parentId: item.parentId,
            score: item.score,
            children: _data.filter(val => val.parentId == item.configId)
          });
        }
      });
    }
  }
</script>

<style scoped="scoped">
  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }

  .spIcon {
    width: 5px;
    height: 20px;
    border-radius: 5px;
    background-image: linear-gradient(#4545FF, #2D8EFF);
  }

  .spTitle {
    float: left;
    margin-left: 20px;
    color: #323E56;
    letter-spacing: 2px;
    font-size: 16px;
    margin-top: -3px;
  }

  .wMax {
    width: 100% !important;
  }
</style>

<!-- 综合类型 -->
<style scoped="scoped">
  .divTagBox {
    padding: -5px;
  }

  .divTagBox .spTab {
    padding: 5px;
  }

  .divTagBox .spScore {
    color: #fa3534;
  }

  .divLiteracyBox {
    padding: 15px;
  }

  .divLiteracyBox .listBox {
    text-align: center;
    padding: 20px;
    float: left;
    border: 2px solid #FFFFFF;
    border-radius: 5px;
  }

  .divLiteracyBox .listBox:hover {
    border: 2px solid #409eff;
    cursor: pointer;
  }

  .divLiteracyBox .literacyName {
    font-size: 24px;
    letter-spacing: 2px;
    padding-bottom: 10px;
  }

  .divLiteracyBox .literacyNum {
    padding-top: 10px;
    font-family: 'OswaldLight';
    border-top: 2px solid #EEEEEE;
    font-size: 32px;
    color: #409eff;
  }
</style>
