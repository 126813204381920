<!--

-->
<template>
  <div class="SubWebPageStudent" v-loading.fullscreen.lock="fullscreenLoading">
    <!-- 搜索 -->
    <comSearch class="dataRange" :ColNumber="3" :SearchList="SearchList" ref="SearchList" @onSearch="onSearch"
      v-if="SearchList!=null">
    </comSearch>
    <!-- 列表-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          我的假请信息
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <el-button type="success" icon="el-icon-circle-plus-outline" @click="onCreate()">新增</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table :data="itemData" stripe class="elTableData">
          <el-table-column prop="categoryName" label="请假分类" min-width="100" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="typeName" label="请假类型" min-width="120"></el-table-column>
          <el-table-column label="请假开始时间" min-width="200">
            <template slot-scope="scope">
              {{scope.row.startDate | dateformatMinute}}
            </template>
          </el-table-column>
          <el-table-column label="请假结束时间" min-width="200">
            <template slot-scope="scope">
              {{scope.row.endDate | dateformatMinute}}
            </template>
          </el-table-column>
          <el-table-column prop="days" label="请假天数" min-width="120"></el-table-column>
          <el-table-column label="状态" min-width="160">
            <template slot-scope="scope">
              <span class="color-warning"
                v-if="scope.row.status==1 || scope.row.status==4">{{scope.row.statusName}}</span>
              <span class="color-danger"
                v-else-if="scope.row.status==-3 || scope.row.status==-2">{{scope.row.statusName}}</span>
              <span v-else>{{scope.row.statusName}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="160" fixed="right">
            <template slot-scope="scope">
              <operationBtn :btnName="'查看'" @click.native="onDetail(scope.row.leaveId)"></operationBtn>
              <operationBtn :btnName="'编辑'" v-if="scope.row.status==-3 || scope.row.status==-2 || scope.row.status == -4"
                @click.native="onEdit(scope.row.leaveId)"></operationBtn>
              <operationBtn :btnName="'撤销'" v-if="scope.row.status==2 || scope.row.status==8 || scope.row.status== 16"
                @click.native="onDelete(scope.row.leaveId)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>

        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>


    </el-card>
  </div>
</template>

<script>
  import '@/assets/css/Student.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import comSearch from '@/components/search.vue';

  export default {
    components: {
      operationBtn,
      comPage,
      comSearch
    },
    data() {
      return {
        // 动画
        fullscreenLoading: false,
        dialogVisible: false,
        dialogVisiblePhoto: false,
        // 搜索
        SearchList: null,
        //列表数据
        itemData: [],
        //分页查询接口返回的结果
        page: null,
        //分页左侧按钮集合
        pageBtnList: null,
        //搜索条件日期
        PunishDate: [],
        RecordDateStart: null,
        RecordDateEnd: null,
        //分页数据
        paging: {
          // 总共页数
          pageLength: 0,
          // 每页请求的数据量
          Size: 1,
          //当前在第几页
          Index: 1
        },
      };
    },
    methods: {
      /**
       * @description 搜索
       */
      onSearch(params) {
        let that = this;
        let data = {};
        let searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = Join(element.data);
          }
        });

        routerPath(that, "/Student/LeaveMGT/Index", data, null, "PageSize");
      },
      /**
       * @description 新增
       */
      onCreate() {
        let that = this;
        that.$router.push({
          path: "/Student/LeaveMGT/Create",
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },

      /**
       * @description 详情
       * @param {Object} Id
       */
      onDetail(Id) {
        let that = this;
        that.$router.push({
          path: "/Student/LeaveMGT/Default/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },

      /**
       * @description 编辑
       * @param {Object} Id
       */
      onEdit(Id) {
        let that = this;
        that.$router.push({
          path: "/Student/LeaveMGT/Edit/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },

      /**
       * @description 撤消
       * @param {Object} Id
       */
      onDelete(Id)
      {
        let that = this;
        confirmDelete(that, '确定撤销该请假申请吗？', function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that,'/api/student/askforleave/'+Id,null,function(resData){
              that.fullscreenLoading=false;
              onGetDataPage(that);
            });
          }
        })
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },

      // 分页面按钮
      pagingClick(type, val) {
        let that = this;
        let url = "/Student/LeaveMGT/Index";
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PunishDate",
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "PunishDate",
          );
        }
      }
    },
    created() {
      let that = this;
      onGetDataPage(that);
    },
    mounted() {

    },
    watch: {
      $route() {
        let that = this;
        onGetDataPage(that);
      }
    }
  };

  function onGetDataPage(that) {
    paging(
      that,
      function(res) {
        that.fullscreenLoading = true;
        // 搜索 下拉学期
        ajaxGet(that, "/api/student/semester/student", null, function(resSemester) {

          that.SearchList = [
            {
              type: "time",
              Name: "请假时间",
              data: res.PunishDate,
              zhi: "PunishDate"
            },
          ];


          let data = {
            PageNumer: res.PageNumer,
            PageSize: res.PageSize,
          };
          if (res.PunishDate != null) {
            that.PunishDate = res.PunishDate.split(",");
            that.RecordDateStart = that.PunishDate[0];
            that.RecordDateEnd = that.PunishDate[1];
            data = ({
              PageNumer: res.PageNumer,
              PageSize: res.PageSize,
              StartDate: that.RecordDateStart,
              EndDate: that.RecordDateEnd,
            })
          }

          ajaxGet(that, '/api/student/askforleave/search', data, function(resData) {
            that.page = resData;
            that.itemData = resData.items;
            that.fullscreenLoading = false;
          });
        })

      },
      "PunishDate",
    );
  }
</script>
<style scoped="scoped">
  .dataRange>>>.el-range-separator {
    width: 10%;
  }
</style>
