<!--
  综合课堂详情
-->
<template>
  <div class="SubWebPageStudent DetailsPro " v-loading.fullscreen.lock="fullscreenLoading">

    <!--基本信息-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          综合课堂详情
        </h3>
      </div>
      <div class="card-body p-0">
        <div class="infoList clearfix">
          <span class="spName">{{infoData.name}}</span>
        </div>
        <!--活动状态-->
        <div class="infoList clearfix pb-4">
          <span class="spStatus">{{infoData.statusString}}</span>
          <span class="spStatus">{{infoData.type}}</span>
          <span class="spStatus" v-if="infoData.isTest">{{infoData.isTest?'需要测验':null}}</span>
        </div>
        <div class="infoList clearfix">
          <span class="spFonts">
            <span class="spLabel">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#fax-clock-o"></use>
              </svg>
              &nbsp;
              课程时间
            </span>
            {{infoData.startDate | dateformat('YYYY-MM-DD HH:mm:ss')}} 至
            {{infoData.endDate | dateformat('YYYY-MM-DD HH:mm:ss')}}
          </span>
          <span class="spFonts" v-if="isVideo">
            <span class="spLabel">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#fax-percent-akt"></use>
              </svg>
              &nbsp;
              完成度
            </span>
            &nbsp;
            <span class="spNum">{{onGetProgress(infoData.duration,nowTime)}}</span>
          </span>
          <span class="spFonts" v-if="infoData.isTest">
            <span class="spLabel">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#fax-basis-o"></use>
              </svg>
              &nbsp;
              通过最低分
            </span>
            &nbsp;
            <span class="spNum">{{infoData.floor}}</span>
          </span>
        </div>
      </div>
    </el-card>

    <!--课程详情-->
    <el-card class="box-card" style="margin-top: 16px;">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          课程介绍
        </h3>
      </div>
      <div class="card-body">
        <div class="colRemarksCont">
          <span v-html="infoData.description"></span>
        </div>
      </div>
    </el-card>


    <!--课程内容-->
    <el-card class="box-card" style="margin-top: 16px;" v-if="infoData.attachment!=''">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          课程内容
        </h3>
      </div>
     <div class="card-body" v-if="isVideo">
        <video id="videoBox" width="100%" height="600" controls disablePictureInPicture controlsList="noplaybackrate  nodownload noremoteplayback"
          oncontextmenu="return false">
          <source :src="infoData.attachment" type="video/mp4">
          您的浏览器不支持 HTML5 video 标签。
        </video>
      </div>
      <div class="card-body" v-else>
        <ul class="mailbox-attachments d-flex align-items-stretch clearfix">
          <li>
            <span class="mailbox-attachment-icon">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#fax-file-pdf-o"></use>
              </svg>
            </span>
            <div class="mailbox-attachment-info">
              <span class="mailbox-attachment-size clearfix mt-1 text-center">
                <a :href="infoData.attachment" class="btn btn-default btn-sm" target="_blank">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#fax-search-square-o"></use>
                  </svg>
                  点击浏览
                </a>
              </span>
            </div>
          </li>
        </ul>

        <!-- <div class="divPdfCont">
          <comPDF :src="infoData.attachment" :page="pdfSetUp.currentPage" @num-pages="pdfSetUp.pageCount=$event"
            @page-loaded="pdfSetUp.currentPage=$event" @loaded="loadPdfHandler">
          </comPDF>
          <div class="divPageCont">
            <el-row :gutter="20">
              <el-col :span="12">
                <div class="divPageNum"> {{pdfSetUp.currentPage}} / {{pdfSetUp.pageCount}}</div>
              </el-col>
              <el-col :span="12" class="text-right">
                <el-button size="small" @click="changePdfPage(0)" :disabled="(pdfSetUp.currentPage==1)?true:false">上一页
                </el-button>
                <el-button size="small" @click="changePdfPage(1)"
                  :disabled="(pdfSetUp.currentPage==pdfSetUp.pageCount)?true:false">下一页
                </el-button>
              </el-col>
            </el-row>
          </div>

        </div> -->
      </div>
    </el-card>

    <div class="row" style="margin-top: 16px;">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="$router.back(-1)">返回</el-button>
      </div>
    </div>


  </div>
</template>

<script>
  import '@/assets/css/Student.css';
  //import comPDF from 'vue-pdf';
  var onUpdateDate;
  export default {
    components: {
      //comPDF
    },
    data() {
      return {
        // 数据加载动画
        fullscreenLoading: false,
        // 数据集合
        infoData: {
          courseId: null,
        },
        // 判断附件是否为 视频
        isVideo: false,
        // 当前播放的时间进度
        nowTime: 0,
        // 总时长
        allTime: 0,
      };
    },
    created() {
      let _this = this;
      let dataID = _this.$route.params.id;
      if (dataID != null && typeof(dataID) != "undefined" && dataID != "") {
        _this.infoData.courseId = dataID;
        onGetData(_this);
      }
    },
    mounted() {
      let _this = this;
      // 因为异步，延迟判断 endDate
      let iBoolen = false;




      setTimeout(function() {
        // 用于判断 课程规定时间是否已过
        if (!compareDate(_this.infoData.endDate, timeTransform(new Date()))) {
          if(!regNull(_this.infoData.attachment)){
          if (_this.infoData.duration >= 0) {
            onVideo();
            if (_this.infoData.watchTime < _this.infoData.duration) {
              playBySeconds(_this.infoData.watchTime);
              // 播放获取当前时间
              $("#videoBox").on("timeupdate",
                function(event) {
                  _this.onCalculate(this.currentTime, this.duration);

                }
              );

              var myVideo = document.getElementById("videoBox");
              // 播放暂停
              myVideo.addEventListener('pause', function() {

                //onOperation(_this, _this.nowTime);
                onSetInterval(_this, false);
              });
              // 播放结束
              myVideo.addEventListener('ended', function() {

                //onOperation(_this, false);
                onSetInterval(_this, false);
              });

              myVideo.addEventListener('play', function() {
                onSetInterval(_this, true);
              }, false);

              // 用于重置
              if (_this.infoData.watchTime < 0) {
                onOperation(_this, 0);
              }
            }



          } else {
            if (_this.infoData.watchTime != -1) {
              onOperation(_this, -1);
            }
          }
          }
        }


        return false;
      }, 2000);

    },
    watch: {
      'nowTime': {
        handler: function(newVar, oldVar) {
          this.nowTime = newVar;
        },
        deep: true
      },
      'allTime': {
        handler: function(newVar, oldVar) {
          this.allTime = newVar;
        },
        deep: true
      },
      'isVideo': {
        handler: function(newVar, oldVar) {
          this.isVideo = newVar;
        },
        deep: true
      }
    },
    methods: {
      // 计算完成度
      onGetProgress(_targetVar, _nowVar) {
        if (_targetVar == -1) { // PDF
          if (_nowVar == -1) {
            return '100.00 %';
          } else {
            return '0.00 %'
          }
        } else {
          if (_nowVar == 0) {
            return '0.00 %';
          } else {
            let strTime = setTwoDecimal((_nowVar / _targetVar) * 100, 2);
            return strTime >= 100 ? '100.00 %' : strTime + ' %';
          }
        }
      },
      // 保存视频时长
      onCalculate(_nowVar, _allVar) {
        var myVideo = document.getElementById("videoBox");
        if(_nowVar-this.nowTime<=1){
          this.nowTime = _nowVar;
        }else{
          this.nowTime = this.nowTime;
          myVideo.currentTime = this.nowTime;
        }
        this.allTime = _allVar
      }
    },
    beforeDestroy() {
      let _this = this;
      if (!compareDate(_this.infoData.endDate, timeTransform(new Date()))) {
        if (_this.infoData.duration > 0) {
          if (_this.infoData.watchTime < _this.infoData.duration) {
            onSetInterval(_this, false);
          }
        }
      }
    }
  };

  function onSetInterval(_this, _type) {
    // 循环提交当前播放时间 每10秒进行一次提交
    if (_type) {
      onUpdateDate = setInterval(function() {
        if (parseInt(_this.nowTime) != _this.infoData.watchTime) {
          if (parseInt(_this.nowTime) < (_this.infoData.duration - 0.01)) {

            onOperation(_this, _this.nowTime);
          } else {

            clearInterval(onUpdateDate);
          }
        }
      }, 10000);
    } else {

      onOperation(_this, _this.nowTime);
      clearInterval(onUpdateDate);
    }
  }


  // 加载计算
  function onOperation(_this, _Length) {
    let _data = {
      courseId: _this.infoData.courseId,
      length: parseInt(_Length)
    }
    ajaxPostForNoTip(_this, '/api/student/literacycourse', _data, function(res) {});
  }

  // 加载数据
  function onGetData(_this) {
    _this.fullscreenLoading = true;
    ajaxGet(_this, '/api/student/literacycourse/' + _this.infoData.courseId, null, function(resData) {
      _this.fullscreenLoading = false;
      _this.infoData = resData;
      //用于判断附件是视频还是PDF
      let temp = resData.attachment;
      if(!regNull(temp)){
      _this.infoData.attachment = _this.$ServerUrl() + resData.attachment;
      if (temp.substring(temp.length - 3).toLowerCase() == 'pdf') {
        _this.isVideo = false;
      } else {
        _this.isVideo = true;
      }
      _this.allTime = resData.duration;
      _this.nowTime = resData.watchTime;
      };
      // 数据处理
    });
  }

  // 视频样式的设置，禁用下载等操作方式
  function onVideo() {
    var video = $('#videoBox');
    //通过打印拿到所有的属性和方法
    video[0]['disablePictureInPicture'] = true;
  }

  // 如果已经有观看过视频的，从上一次观看时间开始
  function playBySeconds(num) {
    if (num && document.getElementById('videoBox')) {
      let myVideo = document.getElementById('videoBox');
      myVideo.currentTime = num;
    }
  }
</script>

<style scoped="scoped">
  video::-webkit-media-controls-timeline {
    display: none;
  }

  .divNullDataBox {
    text-align: center;
    padding: 30px;
    color: #9E9E9E;
  }

  .divPdfCont {
    width: 80%;
    margin: 0 auto;
  }

  .divPdfCont .divPageCont {
    padding-top: 15px;
  }


  .dialogBox {
    padding: 10px 5px;
    height: 140px;
    overflow: hidden;
  }

  .dialogVideoBox {
    padding: 10px 5px;
  }

  .dialogBox .mailbox-attachment-icon {
    font-size: 32px;
    padding: 15px 10px;
  }

  .dialogBox .mailbox-attachment-size>span {
    font-size: 12px;
  }

  .dialogBox .mailbox-attachment-info {
    padding: 7px 10px;
  }



  .bodyAwardsCont .stuAwardsCont {
    position: relative;
    padding: 0px 15px 45px 15px;
  }

  .bodyAwardsCont .stuAwardsCont .stuAvatar {
    position: absolute;
    left: 15px;
    top: 0px;
    border: 1px solid #eee;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 5px;
  }

  .bodyAwardsCont .stuAwardsLabel {
    font-size: 12px;
    padding-left: 65px;
    color: #B1B1B1;
  }

  .bodyAwardsCont .awardsName {
    padding-top: 10px;
    color: #3e3e3e;
  }

  .bodyAwardsCont .stuAwardsCont .stuName {
    font-size: 16px;
    padding-left: 65px;
  }

  .bodyAwardsCont .stuAwardsFont {
    font-size: 14px;
    padding-left: 65px;
    color: #666;
  }

  .bodyAwardsCont .stuFileCont {
    padding-top: 5px;
  }




  .bodyRecordCont .stuRecordCont {
    position: relative;
    padding: 0px 15px 45px 15px;
    padding-left: 75px;

  }

  .bodyRecordCont .stuRecordCont .stuAvatar {
    position: absolute;
    left: 15px;
    top: 0px;
    border: 1px solid #eee;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 5px;
  }


  .bodyRecordCont .stuRecordCont .stuName {
    font-size: 16px;
  }

  .bodyRecordCont .stuRecordFont {
    font-size: 12px;
    padding-top: 5px;
    color: #767676;
  }

  .bodyRecordCont .stuRecordLabel {
    font-size: 12px;
    padding-top: 5px;
    color: #B1B1B1;
  }

  .bodyRecordCont .stuFileCont {
    padding-top: 10px;
  }

  .bodyRecordCont .stuFileCont .divFileIcon {
    border: 1px solid #eee;
    width: 100%;
    height: 80px;
    overflow: hidden;
    border-radius: 4px;
  }

  .bodyRecordCont .stuFileCont .divFileIcon .elIcon {
    font-size: 32px;
  }





  .colRecordOther {}

  .colRecordOther .divList {
    float: left;
    width: 33.3%;
  }

  .colRecordCont {
    padding: 5px;
    font-size: 12px;
    color: #767676;
  }

  .divVideoCont {
    overflow-y: auto;
    width: 100%;
    height: 500px;
    padding-right: 10px;
  }

  .divVideoCont ul li .aItem {
    display: block;
    padding: 7px 0px;
    padding-left: 30px;
    border-bottom: 1px dotted #DDDDDD;
    position: relative;
  }

  .divVideoCont ul li:last-child .aItem {
    border-bottom: none;
  }

  .divVideoCont ul li:hover {
    background-color: #5AAAFF;
    color: #FFFFFF;
  }

  .divVideoCont ul li .aItem span {
    font-family: 'OswaldLight';
    font-size: 14px;
    margin-left: 10px;
  }

  .divVideoCont ul li .aItem .faxIcon {
    position: absolute;
    top: 11px;
    left: 5px;
    display: none;
  }

  .divVideoCont ul li .aItemAct {
    background-color: #007BFF;
    color: #FFFFFF;
    border-bottom: none;
  }

  .divVideoCont ul li .aItemAct .faxIcon {
    display: block;
  }


  .bodyImgCont .mailbox-attachment-icon {
    min-height: 165px;
  }

  .bodyFileCont .mailbox-attachments li,
  .bodyImgCont .mailbox-attachments li {
    width: 250px;
  }

  .bodyImgCont .mailbox-attachment-icon {
    height: 198px;
  }

  .bodyFileCont .mailbox-attachment-size .spFont,
  .bodyImgCont .mailbox-attachment-size .spFont {
    font-size: 12px;
  }





  .tabListCont .divNavTitle {}

  .tabListCont .divNavTitle {}

  .tabListCont .divNavTitle .divTitleCont {
    padding: 10px 0px 10px 10px;
    color: #DEE2E6;
    cursor: pointer;
    font-size: 18px;
  }

  .tabListCont .divNavTitle .divTitleCont .spNum {
    background-color: #DEE2E6;
    font-family: 'OswaldLight';
    padding: 2px 5px;
    border-radius: 4px;
    margin-left: 5px;
    font-size: 14px;
    color: #FFFFFF;
  }

  .tabListCont .divNavTitle .divTitleAct {
    color: #3e3e3e;
  }

  .tabListCont .divNavTitle .divTitleAct .spNum {
    background-color: #17A2B8;
    color: #FFFFFF;
  }

  .tabListCont .divNavTitle .nav-link {
    font-size: 14px;
    color: #B1B1B1;
  }

  .tabListCont .divNavTitle .nav-link .spNum {
    font-family: 'OswaldLight';
    font-weight: normal;
    font-size: 14px;
    padding-left: 10px;
  }

  .tabListCont .divNavTitle .active {
    color: #007bff;
  }




  .DetailsPro .divRoleTitle {
    font-size: 18px;
    color: #323E56;
    padding-top: 20px;
  }

  .DetailsPro .divRoleTitle .spItem {
    float: right;
    margin-left: 5px;
    font-size: 14px;
    color: #28A745;
  }

  .DetailsPro .divRoleTitle .spItem em {
    font-style: normal;
    background-color: #28A745;
    color: #FFFFFF;
    margin-left: 5px;
    margin-right: 5px;
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 12px;
    font-family: 'OswaldLight';
  }

  .DetailsPro .divRoleTitle .el-button {
    padding: 0;
    border: none;
  }

  .DetailsPro .divRoleTitle .el-button:hover {
    background-color: transparent;
  }





  .stuInfo {
    position: relative;
    height: 100%;
    min-height: 60px;
    padding-left: 65px;
    margin: 15px;
  }

  .stuInfo .stuFraction {
    position: absolute;
    top: 5px;
    bottom: 0;
    right: -5px;
    font-family: 'OswaldLight';
    font-size: 26px;
    color: #28A745;
  }

  .stuInfo .stuBtnDown {
    position: absolute;
    bottom: 20px;
    right: 0;
  }

  .stuInfo .stuBtnDown .aView {
    margin-right: 5px;
    margin-left: 5px;
  }

  .stuInfo .stuBtnDown .aView .faxSize {
    font-size: 12px;
    color: #343a40;
  }

  .stuInfo .stuBtnDown .aView:hover .faxSize {
    color: #1666B3;
  }

  .stuInfo .stuImgCont {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
  }

  .stuInfo .stuImgCont img {
    width: 100%;
  }

  .stuInfo .stuName {
    color: #212E48;
    font-size: 18px;
    font-weight: bold;
    padding-top: 5px;
  }

  .stuInfo .stuRole {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
  }

  .stuRole .spStatus {
    font-size: 12px;
    color: #B1B1B1;
  }

  .stuInfo .stuOther {
    color: #767676;
    font-size: 12px;
  }


  .cardTabs .abtnTabs {
    font-size: 16px;
    color: #B1B1B1;
    margin-left: 15px;
    position: relative;
    display: block;

    float: left;
    text-align: center;
  }

  .cardTabs .spDivide {
    float: left;
    padding-left: 15px;
  }


  .cardTabs .abtnTabs:hover {
    color: #323E56;
  }

  .cardTabs .abtnTabs .spFaxIcon {
    display: none;
  }

  .cardTabs .abtnTabs:first-child {
    margin-left: 0;
  }

  .cardTabs .abtnTabs .spNumber {
    background-color: #DEE2E6;
    font-size: 12px;
    color: #FFFFFF;
    font-family: 'OswaldLight';

    padding: 1px 5px;
    border-radius: 5px;
  }



  .cardTabs .abtnTabAct {
    font-size: 1.1rem;
    color: #323E56;
  }

  .cardTabs .abtnTabAct .spFaxIcon {
    display: block;
    position: absolute;
    top: 22px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 12px;
  }

  .cardTabs .abtnTabAct .spNumber {
    background-color: #1666B3;
    color: #FFFFFF;
    font-size: 14px;
  }



  .infoList {
    padding-bottom: 17px;
    padding-left: 30px;
  }

  .infoList .spName {
    font-size: 32px;
    color: #212E48;
    padding-right: 30px;
  }


  .infoList .spFonts {
    color: #3B475D;
    padding-right: 30px;
    padding-left: 30px;
    border-right: 1px solid #D6D6D6;
  }

  .infoList .spFonts:first-child {
    padding-left: 0;
  }

  .infoList .spFonts:last-child {
    border-right: none;
  }

  .infoList .spMedal {
    padding-right: 15px;
  }

  .infoList .spFonts .faxSize {
    font-size: 18px;
  }

  .infoList .spFonts .icon {
    color: #FFFFFF;
  }

  .infoList .spStatus {
    background-color: #EBF3FA;
    color: #61AFFE;
    padding: 5px 20px;
    margin-right: 10px;
  }

  .infoList .spFonts .spLabel {
    font-size: 14px;
    padding: 2px 5px;
    border-radius: 5px;
    background-color: #B1B1B1;
    color: #FFFFFF;
    margin-right: 10px;
  }

  .infoList .spFonts .spNum {
    padding-left: 10px;
    padding-right: 5px;
    font-family: 'OswaldLight';
    font-size: 16px;
    font-weight: bold;
    color: #DC3545;
  }

  .DetailsPro .colRemarksCont {
    font-size: 14px;
    letter-spacing: 1px;
  }
</style>
