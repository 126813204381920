<!--
 ECHARTS 的 雷达图
-->
<template>
  <div>
    <!-- 250px -->
    <div :id="comID" :style="pieStyle"></div>

    <div class="literacyFractionCont">
      <div class="fractionItem divTotal" :style="{'color':optColor[indexColor]}">
        <span class="spTopLabel">综合</span>
        {{infoForm.score}}
        <span class="spUnit">分</span>
      </div>
      <div class="fractionItem divTotal" :style="{'color':optColor[indexColor]}">
        <span class="spTopLabel">记录</span>
        {{infoForm.quantity}}
        <span class="spUnit">条</span>
      </div>
    </div>
  </div>

  
</template>

<script>
  import 'echarts/lib/component/tooltip';
  import 'echarts/lib/component/legend';

  // 引入基本模板
  let echarts = require('echarts/lib/echarts');
  // 引入柱状图组件
  require('echarts/lib/chart/radar');
  export default {
    name: 'report_radar',
    props: {
      comID: {
        type: String,
        default: 'myChart'
      },
      comHeight: {
        type: Number,
        default: 250
      },
      ConfigId: {
        type: String,
      },
      searchData: {
        type: Object,
      },
      indexColor: {
        type: Number,
      },
    },
    data() {
      return {
        pieStyle: '',
        myChart: {},
        arrData: [],
        // 颜色数组
        optColor: ['#7E66F6', '#3191D9', '#4FBD76', '#F9BB4A', '#F50737',
          '#8CB93D', '#3AAF9D', '#4261E4', '#A75CE7', '#6610f2',
          '#e83e8c', '#fd7e14', '#28a745', '#17a2b8'
        ],
        infoForm: {
          score: 0,
          quantity: 0,
        },
      };
    },
    watch: {
      searchData() {
        let that = this;
        that.titleData = [];
        that.arrData = [];


        that.searchData.ConfigId = that.ConfigId;
        ajaxGet(that, '/api/admin/growthclass/chartdata', that.searchData, function(res) {
          that.arrData = res;
          res.forEach(element => {
            that.infoForm.score += element.score;
            that.infoForm.quantity += element.quantity;
          });
        })
        that.$nextTick(() => {
          setTimeout(function() {
            that.drawLine();
            return false;
          }, 1500);
        });
        that.pieStyle = 'width: 100%; height:' + (that.comHeight * 0.0625) + 'rem;';
      }
    },
    created() {
      let that = this;
      that.titleData = [];
      that.arrData = [];

      that.searchData.ConfigId = that.ConfigId;
      ajaxGet(that, '/api/admin/growthclass/chartdata', that.searchData, function(res) {
        that.arrData = res;
        res.forEach(element => {
          that.infoForm.score += element.score;
          that.infoForm.quantity += element.quantity;
        });
        
      })
      that.$nextTick(() => {
        setTimeout(function() {
          that.drawLine();
          return false;
        }, 1500);
      });
      that.pieStyle = 'width: 100%; height:' + (that.comHeight * 0.0625) + 'rem;';
    },
    mounted() {
      let that = this;
      window.onresize = function() {
        // that.myChart.resize();
        that.pieStyle = 'width: 100%; height:' + (that.ChartHeight * 0.0625) + 'rem;';
      }
    },
    methods: {
      // 绘画雷达图
      drawLine() {
        let that = this;
        let arrTitle = [];
        let arrOneself = [];
        // 数据处理
        for (let item of that.arrData) {
          arrTitle.push({
            name: item.name,
            max: item.ceiling == 0 ? (item.score + item.baseScore) + 50 : item.ceiling
          });
          // 判断是否有上限分
          if (item.ceiling == 0) {
            arrOneself.push(item.score + item.baseScore);
          } else {
            // 判断学生总分是否超过上限分
            if ((item.score + item.baseScore) >= item.ceiling) {
              arrOneself.push(item.ceiling);
            } else if (item.score + item.baseScore < 0) {
              arrOneself.push(0);
            } else {
              arrOneself.push(item.score + item.baseScore);
            }
          }
        }

        // 基于准备好的dom，初始化echarts实例
        that.myChart = echarts.init(document.getElementById(that.comID));

        // 绘制图表
        that.myChart.setOption({
          tooltip: {
            padding: [10, 10],
          },
          color: ['#1E90FF'],
          radar: {
            center: ["50%", "50%"],
            radius: '60%',
            shape: 'circle',
            name: {
              textStyle: {
                color: '#fff',
                backgroundColor: '#999',
                borderRadius: 3,
                padding: [5, 10],
                fontSize: 16
              },
            },
            indicator: arrTitle
          },
          series: [{
            name: '总评',
            type: 'radar',
            data: [
              {
                value: arrOneself,
                name: '综合分',
                label: {
                  show: true,
                  formatter: function(params) {
                    return params.value;
                  }
                }
              },

            ],
            areaStyle: {
              color: new echarts.graphic.RadialGradient(0.6, 0.6, 1, [
                {
                  color: 'rgba(38, 111, 188, 0.3)',
                  offset: 0
                },
                {
                  color: 'rgba(40, 63, 151, 0.9)',
                  offset: 1
                }
              ])
            },
          }]
        });
      }
    }
  };
</script>

<style scoped="scoped" lang="less">
.literacyFractionCont {
  text-align: center;
  margin-top: 40px;

  .fractionItem {
    display: inline-block;
    color: #256fbc;
    position: relative;
  }

  .divTotalScore {
    font-family: 'OswaldLight';
    font-size: 82px;
    letter-spacing: 2px;

    .spUnit {
      font-size: 32px;
      color: #8D8F91;
      margin-left: -15px;
    }
  }

  .divEvaluation {
    font-size: 62px;
    font-weight: bold;
    letter-spacing: 2px;
    margin-left: 50px;
    margin-right: 50px;

    .spTopLabel {
      color: #959595;
      letter-spacing: 1px;
      font-weight: normal;
      position: absolute;
      top: -20px;
      left: 0;
      font-size: 22px;
    }
  }

  .divTotal {
    width: 50%;
    font-family: 'OswaldLight';
    font-size: 62px;
    letter-spacing: 2px;
    min-width: 100px;
    text-align: center;

    .spTopLabel {
      color: #959595;
      letter-spacing: 1px;
      font-weight: normal;
      font-size: 22px;
    }

    .spUnit {
      font-size: 26px;
      color: #8D8F91;
      margin-left: 5px;
    }
  }
}
</style>
