<!--
  新增流程活动
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="90px">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{Title}}
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24">
            <el-col :lg="8" :sm="12">
              <el-form-item prop="semesterId" label="学期" label-width="90px">
                <el-select v-model="ruleForm.semesterId" filterable placeholder="请选择学期" style="width: 100%;"
                  @change="handSemesterId" :disabled="Title=='编辑'">
                  <el-option v-for="item in optSemester" :key="item.semesterId" :label="item.name"
                    :value="item.semesterId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="8" :sm="12">
              <el-form-item prop="classId" label="班级" label-width="90px">
                <el-select v-model="ruleForm.classId" filterable placeholder="请选择班级" style="width: 100%;"
                  :disabled="Title=='编辑'">
                  <el-option v-for="item in optClass" :key="item.classId" :label="item.alias" :value="item.classId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="8" :sm="12">
              <el-form-item label="校历周" prop="week">
                <el-select v-model="ruleForm.week" filterable placeholder="请选择" style="width: 100%;">
                  <el-option v-for="item in optWeek" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="8" :sm="12">
              <el-form-item label="星期" prop="dayOfWeek">
                <el-select v-model="ruleForm.dayOfWeek" filterable placeholder="请选择" style="width: 100%;">
                  <el-option v-for="item in optWeekDay" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="8" :sm="12">
              <el-form-item label="课节" prop="schedule">
                <el-input v-model="ruleForm.schedule" style="width: 100%;"></el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="8" :sm="12">
              <el-form-item label="节数" prop="duration">
                <el-input v-model="ruleForm.duration" style="width: 100%;"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="班会课题" prop="topic">
                <quill-editor height="300px" v-model="ruleForm.topic" ref="text" class="myQuillEditor"
                  :options="editorOption" />
                <!-- <el-input v-model="ruleForm.topic" style="width: 100%;"></el-input> -->
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item prop="objective" label="班会目的" label-width="90px">
                <quill-editor height="300px" v-model="ruleForm.objective" ref="text" class="myQuillEditor"
                  :options="editorOption" />
                <!-- <el-input type="textarea" v-model="ruleForm.objective" :autosize="{ minRows: 5}" maxlength="500"
                  show-word-limit></el-input> -->
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item prop="form" label="班会形式" label-width="90px">
                <quill-editor height="300px" v-model="ruleForm.form" ref="text" class="myQuillEditor"
                  :options="editorOption" />
                <!-- <el-input type="textarea" v-model="ruleForm.form" :autosize="{ minRows: 5}" maxlength="500"
                  show-word-limit></el-input> -->
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item prop="processDesign" label="过程设计" label-width="90px">
                <quill-editor height="300px" v-model="ruleForm.processDesign" ref="text" class="myQuillEditor"
                  :options="editorOption" />
                <!-- <el-input type="textarea" v-model="ruleForm.processDesign" :autosize="{ minRows: 5}" maxlength="500"
                  show-word-limit></el-input> -->
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>

      <div class="row" style="margin-top: 10px;">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
          <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
          <el-button type="primary" icon="el-icon-circle-check" @click="onSave()">提交</el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import {
    quillEditor
  } from 'vue-quill-editor';
  import * as Quill from 'quill' //引入编辑器
  var fonts = ['SimSun', 'SimHei', 'Microsoft-YaHei', 'KaiTi', 'FangSong', 'Arial', 'Times-New-Roman', 'sans-serif'];
  var Font = Quill.import('formats/font');
  Font.whitelist = fonts; //将字体加入到白名单
  Quill.register(Font, true);

  export default {
    components: {
      quillEditor
    },
    data() {
      return {
        fullscreenLoading: false,
        editorOption: {
          placeholder: '请在此输入内容',
          modules: {
            toolbar: [
              [{
                'font': fonts
              }], //字体，把上面定义的字体数组放进来
              ['bold', 'italic', 'underline',
                'strike'
              ], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
              ['blockquote', 'code-block'], // 引用  代码块-----['blockquote', 'code-block']
              [{
                header: 1
              }, {
                header: 2
              }], // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
              [{
                list: 'ordered'
              }, {
                list: 'bullet'
              }], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
              [{
                script: 'sub'
              }, {
                script: 'super'
              }], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
              [{
                indent: '-1'
              }, {
                indent: '+1'
              }], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
              [{
                'direction': 'rtl'
              }], // 文本方向-----[{'direction': 'rtl'}]
              [{
                size: ['small', false, 'large', 'huge']
              }], // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
              [{
                header: [1, 2, 3, 4, 5, 6, false]
              }], // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
              [{
                color: []
              }, {
                background: []
              }], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
              [{
                align: []
              }], // 对齐方式-----[{ align: [] }]
              ['clean'], // 清除文本格式-----['clean']
              // ['image'], // 链接、图片、视频-----['link', 'image', 'video']
            ]
          }
        },
        optClass: [],
        optSemester: [],
        optWeek: [],
        optWeekDay: [{
            value: 1,
            label: '星期一',
          },
          {
            value: 2,
            label: '星期二',
          },
          {
            value: 3,
            label: '星期三',
          },
          {
            value: 4,
            label: '星期四',
          },
          {
            value: 5,
            label: '星期五',
          },
          {
            value: 6,
            label: '星期六',
          },
          {
            value: 0,
            label: '星期日',
          }
        ],
        ruleForm: {
          meetingId: null,
          semesterId: null,
          classId: null,
          topic: null, //班课课题
          objective: null, //目的
          form: null, //形式
          processDesign: null, //过程设计
          week: null, //第几周
          dayOfWeek: null,
          schedule: null, //第几节课
          duration: null //时长（节课）
        },
        ruleFormReset: {}, //重置
        Title: "新增",
        rules: {
          semesterId: [{
            required: true,
            message: '学期不能为空',
            trigger: 'change'
          }, ],
          classId: [{
            required: true,
            message: '班级不能为空',
            trigger: 'change'
          }, ],
          week: [{
            required: true,
            message: '校历周不能为空',
            trigger: 'change'
          }, ],
          dayOfWeek: [{
            required: true,
            message: '星期不能为空',
            trigger: 'change'
          }, ],
          schedule: [{
              required: true,
              message: '课节不能为空',
              trigger: 'change'
            },
            {
              validator: (rule, value, callback) => {
                let that = this;
                var reg = /^-?[1-9]\d*$/;
                if (value != 0 && !reg.exec(value)) {
                  callback(new Error("课节只能输入整数！"));
                } else {
                  callback();
                }
              },
              trigger: 'change'
            }
          ],
          duration: [{
              required: true,
              message: '节数不能为空',
              trigger: 'change'
            },
            {
              validator: (rule, value, callback) => {
                let that = this;
                var reg = /^-?[1-9]\d*$/;
                if (value != 0 && !reg.exec(value)) {
                  callback(new Error("节数只能输入整数！"));
                } else {
                  callback();
                }
              },
              trigger: 'change'
            }
          ],
          topic: [{
            required: true,
            message: '班会课题不能为空',
            trigger: 'change'
          }, ],
          objective: [{
            required: true,
            message: '班会目的不能为空',
            trigger: 'change'
          }, ],
          form: [{
            required: true,
            message: '班会形式不能为空',
            trigger: 'change'
          }, ],
          processDesign: [{
            required: true,
            message: '过程设计不能为空',
            trigger: 'change'
          }, ],
        },
        TeacherInfo: {}, // 教师信息
      };
    },
    created() {
      let that = this;
      that.TeacherInfo = onGetNowUser(that);
      let id = that.$route.params.Id;
      that.ruleForm.meetingId = id;
      that.fullscreenLoading = true;
      ajaxGet(that, "/api/admin/Semester", null, function(resultSemester) {

        let resultClass = that.TeacherInfo.isTeacher ? that.TeacherInfo.manageClass : that.TeacherInfo.allClass;


        that.fullscreenLoading = false;
        that.optSemester = resultSemester;
        that.optClass = resultClass;
        if (!regNull(id)) {
          that.Title = '编辑';
          that.fullscreenLoading = true;
          ajaxGet(that, "/api/admin/adviserclassmeeting/" + id, null, function(res) {
            ajaxGet(that, "/api/admin/semesterday/week/" + res.semesterId, null, function(r) {
              that.fullscreenLoading = false;
              for (var i = 0; i < r; i++) {
                var newWeek = {
                  value: i + 1,
                  label: '第' + (i + 1) + '周'
                }
                that.optWeek.push(newWeek);
              }
              that.ruleForm = res;
              that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
            })
          })
        } else {
          for (var item of resultSemester) {
            if (item.isCurrent == true) {
              that.ruleForm.semesterId = item.semesterId;
              ajaxGet(that, "/api/admin/semesterday/week/" + item.semesterId, null, function(r) {
                that.fullscreenLoading = false;
                for (var i = 0; i < r; i++) {
                  var newWeek = {
                    value: i + 1,
                    label: '第' + (i + 1) + '周'
                  }
                  that.optWeek.push(newWeek);
                }
              })
            }
          }
          that.ruleForm.classId = regNullArray(resultClass) ? null : resultClass[0].classId;
          that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
        }
      })
    },
    methods: {
      handSemesterId(val) {
        var that = this;
        that.optWeek = [];
        that.fullscreenLoading = true;
        ajaxGet(that, "/api/admin/semesterday/week/" + val, null, function(r) {
          that.fullscreenLoading = false;
          for (var i = 0; i < r; i++) {
            var newWeek = {
              value: i + 1,
              label: '第' + (i + 1) + '周'
            }
            that.optWeek.push(newWeek);
          }
        })
      },
      onReset() {
        let that = this;
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      onBack() {
        let that = this;
        back(that);
      },
      onSave() {
        let that = this;
        if (that.ruleForm.form != null && that.ruleForm.objectiv != null && that.ruleForm.processDesign != null && that
          .ruleForm.topic != null) {
          that.ruleForm.form = that.ruleForm.form.replace(new RegExp(/( )/g), '&nbsp;');
          that.ruleForm.objective = that.ruleForm.objective.replace(new RegExp(/( )/g), '&nbsp;');
          that.ruleForm.processDesign = that.ruleForm.processDesign.replace(new RegExp(/( )/g), '&nbsp;');
          that.ruleForm.topic = that.ruleForm.topic.replace(new RegExp(/( )/g), '&nbsp;');
        }
        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            if (that.ruleForm.meetingId == null) {
              that.fullscreenLoading = true;

              that.ruleForm.week = parseInt(that.ruleForm.week);
              ajaxPost(that, "/api/admin/adviserclassmeeting", that.ruleForm, function(r) {
                that.fullscreenLoading = false;
                that.onBack();
              });
            } else {
              that.fullscreenLoading = true;
              for (var i in that.optWeek) {
                if (that.ruleForm.week == that.optWeek[i].label) {
                  that.ruleForm.week = that.optWeek[i].value;
                }
              }
              that.ruleForm.week = parseInt(that.ruleForm.week);
              for (var i in that.optWeekDay) {
                if (that.ruleForm.dayOfWeek == that.optWeekDay[i].label) {
                  that.ruleForm.dayOfWeek = that.optWeekDay[i].value;
                }
              }
              ajaxPut(that, "/api/admin/adviserclassmeeting/" + that.ruleForm.meetingId, that.ruleForm, function(
                r) {
                that.fullscreenLoading = false;
                that.onBack();
              });
            }
          }
        });
      }
    },
    watch: {

    }
  };

</script>

<style scoped="scoped">

</style>
<style>
  .ql-toolbar.ql-snow {
    line-height: 20px;
  }

  .ql-snow .ql-tooltip[data-mode=link]::before {
    content: "请输入链接地址:";
  }

  .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    border-right: 0px;
    content: '保存';
    padding-right: 0px;
  }

  .ql-snow .ql-tooltip[data-mode=video]::before {
    content: "请输入视频地址:";
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: '14px';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=small]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
    content: '10px';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=large]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
    content: '18px';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=huge]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
    content: '32px';
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=SimSun]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=SimSun]::before {
    content: "宋体";
    font-family: "SimSun";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=SimHei]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=SimHei]::before {
    content: "黑体";
    font-family: "SimHei";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=Microsoft-YaHei]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=Microsoft-YaHei]::before {
    content: "微软雅黑";
    font-family: "Microsoft YaHei";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=KaiTi]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=KaiTi]::before {
    content: "楷体";
    font-family: "KaiTi";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=FangSong]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=FangSong]::before {
    content: "仿宋";
    font-family: "FangSong";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=Arial]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=Arial]::before {
    content: "Arial";
    font-family: "Arial";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=Times-New-Roman]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=Times-New-Roman]::before {
    content: "Times New Roman";
    font-family: "Times New Roman";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=sans-serif]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=sans-serif]::before {
    content: "sans-serif";
    font-family: "sans-serif";
  }

  .ql-font-SimSun {
    font-family: "SimSun";
  }

  .ql-font-SimHei {
    font-family: "SimHei";
  }

  .ql-font-Microsoft-YaHei {
    font-family: "Microsoft YaHei";
  }

  .ql-font-KaiTi {
    font-family: "KaiTi";
  }

  .ql-font-FangSong {
    font-family: "FangSong";
  }

  .ql-font-Arial {
    font-family: "Arial";
  }

  .ql-font-Times-New-Roman {
    font-family: "Times New Roman";
  }

  .ql-font-sans-serif {
    font-family: "sans-serif";
  }

</style>

