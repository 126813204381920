<!--
  基础信息 - 综合类型
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
		<el-card class="box-card">
		 <div slot="header" class="card-header">
				<div style="float: left;">
					 <h3 class="card-title titleCont" style="padding-left: 0px; line-height: 30px;">
						行为规范分类
					 </h3>
				 </div>
				 <div style="float: left;margin-left: 20px;">
					 <el-row :gutter="24">
						 <el-col :span="24">
							<div style="float: left;">
								 <ul class="nav nav-pills navTabsCont">
									<li class="nav-item" v-if="isPower.moralityclassreason_type == true">
										<router-link :to="'/admin/moralityreason/type?type=1'"  :class="currentType==1?'nav-link active':'nav-link'">
											班级
										</router-link>
									</li>
									<li class="nav-item" v-if="isPower.moralitydormitoryreason_type == true">
										<router-link :to="'/admin/moralityreason/type?type=2'" :class="currentType==2?'nav-link active':'nav-link'">
											宿舍
										</router-link>
									</li>
									<li class="nav-item" v-if="isPower.moralityreason_type == true">
										<router-link :to="'/admin/moralityreason/type?type=3'" :class="currentType==3?'nav-link active':'nav-link'">
											个人
										</router-link>
									</li>
<!-- 									<li class="nav-item" v-if="isPower.moralitydpreason_type == true">
										<router-link :to="'/admin/moralityreason/type?type=4'" :class="currentType==4?'nav-link active':'nav-link'">
											宿舍个人
										</router-link>
									</li> -->
								 </ul>
							</div>
						 </el-col>
					 </el-row>
				 </div>
			</div>
			<div class="card-body">
				<el-row :gutter="24">
					<el-col :span="6">
						<div class="divTreeCont">
              <el-input
                placeholder="输入关键字进行过滤"
                v-model="filterText">
								<el-button slot="append" icon="el-icon-refresh-right" @click="onFilterText()"></el-button>
              </el-input>

							<el-tree :data="data" :props="defaultProps" ref="tree" style="margin-top:10px;"
							      node-key="id"
							      default-expand-all @node-click="handleNodeClick"
							      :expand-on-click-node="false" :render-content="renderContent"
                    :filter-node-method="filterNode"
							>
							</el-tree>
						</div>
					</el-col>
					<el-col :span="18">
						<el-row :gutter="24">
							<el-col :span="20">
								<el-breadcrumb separator-class="el-icon-arrow-right">
									<el-breadcrumb-item v-for="(p,index) in currentNav" :key="index">{{p}}</el-breadcrumb-item>
								</el-breadcrumb>
							</el-col>
							<el-col :span="4" style="text-align: right;">
								<el-button v-if="isPower.moralityclassreason_type_create==true || isPower.moralityreason_type_create==true || isPower.dormitoryreason_type_create==true || isPower.moralitydpreason_type_create==true"  @click="onAdd()" type="success" icon="el-icon-circle-plus-outline">新增</el-button>
							</el-col>
						</el-row>
						<el-table stripe :data="item" class="tableTwo table-hover text-nowrap "
											style="width: 100%; margin-top: 0px; ">
								<el-table-column type="index" width="120px;" label="序号"></el-table-column>
								<el-table-column prop="name" label="参数名称"></el-table-column>
								<!--<el-table-column prop="weight" label="权重"></el-table-column>!-->
								<el-table-column label="操作" width="180px">
									<template slot-scope="scope">
										<operationBtn v-if="isPower.moralityclassreason_type_edit==true || isPower.moralityreason_type_edit==true || isPower.dormitoryreason_type_edit==true || isPower.moralitydpreason_type_edit==true" :btnName="'编辑'" @click.native="onEdit(scope.row)"></operationBtn>
										<operationBtn v-if="isPower.moralityclassreason_type_delete==true || isPower.moralityreason_type_delete==true || isPower.dormitoryreason_type_delete==true || isPower.moralitydpreason_type_delete==true" :btnName="'删除'" @click.native="onDelete(scope.row.id)"></operationBtn>
									</template>
								</el-table-column>
						</el-table>
					</el-col>
				</el-row>
			</div>
		</el-card>

		<el-dialog :title="dialogTitle"
		:visible.sync="dialogVisible"
		width="50%" :top="top + 'px'"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		:append-to-body="true" :before-close="onClose"
		:fullscreen="false">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
					<div slot="header" class="card-header">
					  <h3 class="card-title titleCont">
					    <span class="spIcon"></span>
					    参数配置
					  </h3>
					</div>
					<div class="card-body tabListCont">
						<el-row :gutter="24" v-if="isDetail!=true" style="margin-top: 10px;">
							<el-col :span="24">
								<el-form-item label="名称" prop="name">
									<el-input v-model="ruleForm.name" placeholder="请输入名称"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="cols">
								<el-form-item label="所属" prop="parentId">
									<treeSelect :key="random" @getValue="getValue" :options="data" :value="ruleForm.parentId" ></treeSelect>
								</el-form-item>
							</el-col>
              <el-col :span="cols">
              	<el-form-item label="是否作为入口" prop="entrance">
                  <el-switch
                    v-model="ruleForm.entrance"
                    active-color="#13ce66"
                    inactive-color="#ff4949">
                  </el-switch>
              	</el-form-item>
              </el-col>
							<el-col :span="cols">
								<el-form-item label="优先级" prop="priority">
									<el-input @change="onPriorityChange()" v-model="ruleForm.priority" placeholder="请输入优先级"></el-input>
								</el-form-item>
							</el-col>
						</el-row>
					</div>
				</el-form>
		    <span slot="footer" class="dialog-footer">
					<div v-if="isDetail!=true">
						<el-button @click="onClose()">取 消</el-button>
						<el-button @click="onReset()">重 置</el-button>
						<el-button type="primary" @click="onSave()">确 定</el-button>
					</div>
					<div v-else>
						<el-button @click="dialogVisible = false">关 闭</el-button>
					</div>
		    </span>
		</el-dialog>

  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
	import operationBtn from '@/components/operationBtn.vue';
	import treeSelect from '@/components/TreeSelect.vue';
  export default{
    name: '',
    components:{
      operationBtn,
			treeSelect
    },
    data(){
      return{
				cols:8,
				top:10,
        filterText: '',//查找树形菜单
				currentNav:[],
				random:0, //刷新组件
				currentId:null,
				isDetail:false,
				dialogTitle:"新增行为规范",
				dialogVisible:false,
				currentTitle:"请在左侧选择行为规范",
				item:null,
				fullscreenLoading:false,
        data: [],
        defaultProps: {
          children: 'children',
          label: 'name'
        },
				ruleForm:{
					categoryId:"",
					name:"",
					priority:"",
					parentId:"",
					description:"",
          entrance:true,
				},
				ruleFormInit:null,
				ruleFormReset:null,
				currentType:1,
				isConfig:false,
				isPower: {


						//增删改查 权限管理
						moralityclassreason_type: false,//班级
						moralityreason_type: false,//班级个人
						moralitydormitoryreason_type: false,//宿舍
						moralitydpreason_type:false,//宿舍个人

						moralityclassreason_type_create:false,
						moralityclassreason_type_edit:false,
						moralityclassreason_type_delete:false,

						moralityreason_type_create:false,
						moralityreason_type_edit:false,
						moralityreason_type_delete:false,

						dormitoryreason_type_create:false,
						dormitoryreason_type_delete:false,
						dormitoryreason_type_edit:false,

						moralitydpreason_type_create:false,
						moralitydpreason_type_edit:false,
						moralitydpreason_type_delete:false,
				},
				rules: {
					name: [
						{ required: true, message: '名称不能为空', trigger: 'change' },
						{ max: 64,message: '名称不能超过64个字符', trigger: 'change' },
					],
					priority: [
						{
							validator: (rule, value, callback) => {
								let that = this;
								var reg =/^-?[1-9]\d*$/;
								if (value!=0 && !reg.exec(value)) {
									callback(new Error("优先级只能输入整数！"));
								}
								else {
									callback();
								}
							},
							trigger: 'change'
						}
					],
				},
      };
    },
		created()
		{
			let that = this;
			isPower(that, "moralityclassreason_type","moralityclassreason_type");
			isPower(that, "moralityreason_type","moralityreason_type");
			isPower(that, "moralitydormitoryreason_type","moralitydormitoryreason_type");
			isPower(that, "moralitydpreason_type","moralitydpreason_type");

			isPower(that, "moralityclassreason_type_create","moralityclassreason_type_create");
			isPower(that, "moralityclassreason_type_edit","moralityclassreason_type_edit");
			isPower(that, "moralityclassreason_type_delete","moralityclassreason_type_delete");

			isPower(that, "moralityreason_type_create","moralityreason_type_create");
			isPower(that, "moralityreason_type_edit","moralityreason_type_edit");
			isPower(that, "moralityreason_type_delete","moralityreason_type_delete");

			isPower(that, "dormitoryreason_type_create","dormitoryreason_type_create");
			isPower(that, "dormitoryreason_type_delete","dormitoryreason_type_delete");
			isPower(that, "dormitoryreason_type_edit","dormitoryreason_type_edit");

			isPower(that, "moralitydpreason_type_create","moralitydpreason_type_create");
			isPower(that, "moralitydpreason_type_edit","moralitydpreason_type_edit");
			isPower(that, "moralitydpreason_type_delete","moralitydpreason_type_delete");
			that.ruleFormInit = JSON.parse(JSON.stringify(that.ruleForm));
			InitType(that);
		},
    methods:{
			onFilterText()
			{
				this.filterText = "";
			},
      filterNode(value, data)
      {
        if (!value) return true;
        return data.name.indexOf(value) !== -1;
      },

			onPriorityChange()
			{
				let that = this;
				var value = setInt(that.ruleForm.priority);
				that.ruleForm.priority = value;
			},
			onSave()
			{
				let that = this;
				if(that.ruleForm.priority==null || that.ruleForm.priority == "")
				{
					that.ruleForm.priority = 0
				}
				that.ruleForm.categoryType = that.currentType;
        let data = null;
				that.$refs["ruleForm"].validate(valid => {
						if (valid) {
							if(regNull(that.ruleForm.categoryId))
							{
								var createUrl = "";
								that.fullscreenLoading = true;
                data  = {
                  name: that.ruleForm.name,
                  categoryType: parseInt(that.currentType),
                  priority:that.ruleForm.priority,
                  entrance:that.ruleForm.entrance,
                }
                if(!regNull(that.ruleForm.parentId)){
                  data.parentId = that.ruleForm.parentId;
                }
								ajaxPost(that,"/api/admin/moralcategory",data,function(r){
									that.fullscreenLoading = false;
									that.dialogVisible = false;
									that.currentId = that.ruleForm.parentId;
									InitType(that);
								});
							}
							else{
								if(that.ruleForm.categoryId == that.ruleForm.parentId && that.ruleForm.categoryId!=null)
								{
									warning(that,"不能把行为规范设置在自身级别下");
									return false;
								}
                data  = {
                  categoryId: that.ruleForm.categoryId,
                  name: that.ruleForm.name,
                  categoryType: parseInt(that.currentType),
                  priority:that.ruleForm.priority,
                  entrance:that.ruleForm.entrance,
                }
                if(!regNull(that.ruleForm.parentId)){
                  data.parentId = that.ruleForm.parentId;
                }
								that.fullscreenLoading = true;
								ajaxPut(that,"/api/admin/moralcategory/"+that.ruleForm.categoryId,data,function(r){
									that.fullscreenLoading = false;
									that.dialogVisible = false;
									that.currentId = that.ruleForm.parentId;
									InitType(that);
								});
							}
						}
						else {

								setTimeout(() => {
										var isError = document.getElementsByClassName("is-error");
										isError[0].querySelector('input').focus();
								}, 1)
								return false;
						}
				});
			},
			onClose()
			{
				let that = this;
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormInit));
        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;
        that.dialogVisible = false;
        try
        {
        	that.$refs["ruleForm"].resetFields();
        } catch{}
			},
			onReset()
			{
				let that = this;
				this.ruleForm = JSON.parse(JSON.stringify(this.ruleFormReset));
				var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
				that.random += random;
				try
				{
					that.$refs["ruleForm"].resetFields();
				} catch{}
			},
			getValue(value)
			{
				let that = this;
				that.ruleForm.parentId = value;
			},
			onAdd()
			{
				let that = this;

        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormInit));
				that.ruleForm.parentId = that.currentId;
				that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
				that.ruleForm = that.ruleFormReset;
				if(that.currentType == 1 || that.currentType == 3)
				{
					that.cols = 24;
					that.top = 50;
					that.isConfig = false;
				}
				else{
					that.cols = 24;
					that.top = 50;
					that.isConfig = true;
				}
				if(that.currentType == 1)
				{
					that.dialogTitle = "行为规范【班级】";
				}
				else if(that.currentType == 2)
				{
					that.dialogTitle = "行为规范【宿舍】";
				}
				else if(that.currentType == 3)
				{
					that.dialogTitle = "行为规范【个人】";
				}
				else if(that.currentType == 4)
				{
					that.dialogTitle = "行为规范【宿舍个人】";
				}

				var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
				that.random += random;
				try
				{
					that.$refs["ruleForm"].resetFields();
				} catch{}
				that.dialogVisible = true;
			},
			onEdit(data)
			{

				let that = this;
				that.ruleForm = JSON.parse(JSON.stringify(data));
				that.ruleForm.categoryId = data.id;
				that.dialogVisible = true;

				if(that.currentType == 1 || that.currentType == 3)
				{
					that.cols = 24;
					that.top = 50;
					that.isConfig = false;
				}
				else{
					that.cols = 24;
					that.top = 50;
					that.isConfig = true;
				}

				if(that.currentType == 1)
				{
					that.dialogTitle = "行为规范【班级】";
				}
				else if(that.currentType == 2)
				{
					that.dialogTitle = "行为规范【宿舍】";
				}
				else if(that.currentType == 3)
				{
					that.dialogTitle = "行为规范【个人】";
				}
				else if(that.currentType == 4)
				{
					that.dialogTitle = "行为规范【宿舍个人】";
				}

				that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
				var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
				that.random += random;
				try
				{
					that.$refs["ruleForm"].resetFields();
				} catch{}
			},
			onDelete(id)
			{
				let that = this;
				confirmDelete(that,null,function(res){
					if(res == true)
					{
						that.fullscreenLoading = true;
						ajaxDelete(that,"/api/admin/moralcategory/"+id,null,function(r){
							that.fullscreenLoading = false;
							Init(that);

						});
					}
				})
			},
      handleNodeClick(data)
			{
				let that = this;
				that.item = data.children;
				that.currentTitle = data.name;
				that.currentId = data.id;
      },
			renderContent(h, { node, data, store })
			{
				return (
					<span class="custom-tree-node">
						<span style="font-size:14px;">{node.label}</span>
					</span>);
			},
    },
		watch:
		{
			currentTitle(val)
			{
				let that = this;
				var titles = val.split('/');
				that.currentNav = ["行为规范"];
				for(var i in titles)
				{
					if(titles[i] != "行为规范")
					{
						that.currentNav.push(titles[i]);
					}
				}
			},
      filterText(val)
			{
        this.$refs.tree.filter(val);
      },
			$route()
			{
				let that = this;
        that.currentId = null;
        that.ruleForm.parentId = null;
				InitType(that);
			},
		},
    mounted() {

    },
  };

	function InitType(that)
	{
		that.currentType = that.$route.query.type;
		if(that.currentType == null || typeof(that.currentType)=="undefined")
		{
			if(that.isPower.moralityclassreason_type == true)
			{
				that.currentType = 1;
				that.dialogTitle = "行为规范【班级】";
			}
			else if(that.isPower.moralityreason_type == true)
			{
				that.currentType = 2;
				that.dialogTitle = "行为规范【宿舍】";
			}
			else if(that.isPower.moralitydormitoryreason_type == true)
			{
				that.currentType = 3;
				that.dialogTitle = "行为规范【个人】";
			}
			else if(that.isPower.moralitydpreason_type == true)
			{
				that.currentType = 4;
				that.dialogTitle = "行为规范【宿舍个人】";
			}
      else
      {
        that.currentType = 1;
      }
		}
		Init(that);
	}



	function GetItem(that,val)
	{
		 if (val.length !== 0) {
			 val.forEach((item) => {
					if(item.id == that.currentId)
					{
						that.item = item.children;
						that.currentTitle = item.name;
					}
					else if (item.children.length >= 1) {
						GetItem(that,item.children);
					}
				});

		 }
	}

	function Init(that)
	{
		that.fullscreenLoading = true;
		that.data = [];
		var param = {
			categoryType:that.currentType,
		}
		ajaxGet(that, "/api/admin/moralcategory/treelist", param, function (r) {
			that.fullscreenLoading = false;
			//that.data = r;
			var tree = {
				id:null,
				value:null,
				name:"行为规范",
				title:"行为规范",
				children:r,
			};

			that.data.push(tree);
      that.data = renameCategoryIdToId(that.data)
			GetItem(that,that.data);
		});
	}
  function renameCategoryIdToId(categories) {
    return categories.map(category => {
      const { categoryId, children, ...rest } = category;
      const newCategory = { id: categoryId, ...rest };
      if (children && Array.isArray(children)) {
        newCategory.children = renameCategoryIdToId(children);
      }
      return newCategory;
    });
  }
</script>

<style scoped="scoped">
  .divTreeCont{
    border: 1px solid #DDDDDD;
    padding: 15px;
    border-radius: 4px;
  }
 .divTreeCont .el-tree-node__content{
    height: 40px;
  }
  .divTreeCont .custom-tree-node {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      padding-right: 15px;
    }

		 .navTabsCont .nav-link{
		    font-size: 12px;
		  }
		  .navToolsCont{
		    margin-top: 1px;
		  }


</style>
