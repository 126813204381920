<template>
  <div class="SubWebPageStudent" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form  :model="ruleForm" :rules="rules" ref="ruleForm">

      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{Title}}
          </h3>
        </div>
        <div class="card-body ">
          <el-row :gutter="24">
              <el-col :span="8">
                <el-form-item label="请假分类" prop="category">
                  <el-select v-model="ruleForm.category" placeholder="请选择" class="width_100Pie"
                    @change='onClickCategory'>
                    <el-option v-for="item in optCategory" :key="item.Value" :label="item.Text" :value="item.Value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="请假类型" prop="type" v-if='ruleForm.category ==1'>
                  <el-select v-model="ruleForm.type" placeholder="请选择" class="width_100Pie">
                    <el-option v-for="item in optType" :key="item.Value" :label="item.Text" :value="item.Value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="当前位置" prop="location" v-if='ruleForm.category ==1'>
                  <el-select v-model="ruleForm.location" placeholder="请选择" class="width_100Pie">
                    <el-option v-for="item in positions" :key="item.Value" :label="item.Text" :value="item.Value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="ruleForm.category == 5">
                <el-form-item label="通校规则" prop="syncGroupId">
                  <el-select v-model="ruleForm.syncGroupId" placeholder="请选择" class="width_100Pie">
                    <el-option v-for="item in optGroup" :key="item.groupId"
                      :label="item.groupName+'('+item.description+')'" :value="item.groupId">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="ruleForm.category == 2 || ruleForm.category == 3 || ruleForm.category == 5">
                <el-form-item label="请假开始日期" prop="startDate">
                  <el-date-picker v-model="ruleForm.startDate" type="date" range-separator="至" placeholder="开始日期"
                    class="width_100Pie" format="yyyy-MM-dd" @change="onChangeDateDay" @clear="onClearDate">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="ruleForm.category == 2 || ruleForm.category == 3 || ruleForm.category == 5">
                <el-form-item label="请假结束日期" prop="endDate">
                  <el-date-picker v-model="ruleForm.endDate" type="date" placeholder="结束日期" class="width_100Pie"
                    format="yyyy-MM-dd" @change="onChangeDateDay" @clear="onClearDate">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="ruleForm.category == 4">
                <el-form-item label="请假开始时间" prop="startDate">
                  <el-date-picker v-model="ruleForm.startDate" type="datetime" placeholder="开始时间" class="width_100Pie"
                    format="yyyy-MM-dd HH:mm" @change="onChangeDateTime" @clear="onClearDate">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="ruleForm.category == 4">
                <el-form-item label="请假结束时间" prop="endDate">
                  <el-date-picker v-model="ruleForm.endDate" type="datetime" placeholder="结束时间" class="width_100Pie"
                    format="yyyy-MM-dd HH:mm" @change="onChangeDateTime" @clear="onClearDate"
                    :picker-options="endPickerOptions">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="ruleForm.category == 1">
                <el-form-item label="请假开始时间" prop="startDate">
                  <el-date-picker v-model="ruleForm.startDate" type="datetime" range-separator="至"
                    start-placeholder="开始日期" end-placeholder="结束日期" class="width_100Pie" format="yyyy-MM-dd HH:mm"
                    @change="onChangeDate" @clear="onClearDate">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="ruleForm.category == 1">
                <el-form-item label="请假结束时间" prop="endDate">
                  <el-date-picker v-model="ruleForm.endDate" type="datetime" range-separator="至"
                    start-placeholder="开始日期" end-placeholder="结束日期" class="width_100Pie" format="yyyy-MM-dd HH:mm"
                    @change="onChangeDate" @clear="onClearDate">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="时长（天）">
                  <el-input v-model="ruleForm.days" class="width_100Pie" disabled> </el-input>
                  <!-- {{ruleForm.days}} -->
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if='ruleForm.type == 1 '>
                <el-form-item label="疾病名称" prop="type">
                  <el-select v-model="ruleForm.sickness" placeholder="请选择" class="width_100Pie">
                    <el-option v-for="item in diseaseList" :key="item" :label="item" :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if='ruleForm.type == 1 '>
                <el-form-item label="症状" prop="symptom">
                  <el-select v-model="ruleForm.symptom" placeholder="请选择" class="width_100Pie">
                    <el-option v-for="item in symptomList" :key="item" :label="item" :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="请假事由" prop="reason">
                  <el-input v-model="ruleForm.reason" class="width_100Pie"> </el-input>
                </el-form-item>
              </el-col>
          </el-row>
        </div>
      </el-card>

      <el-card style="margin-top: 10px;">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            附件
          </h3>
        </div>
        <div class="card-body">
          <comUpload :key="random" @onPictureHandleSuccess="onPictureHandleSuccess" :files="uploadFiles"></comUpload>
        </div>
      </el-card>

      <div class="row" style="margin-top: 10px;">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返 回</el-button>
          <el-button @click="onReset()">重 置</el-button>
          <el-button type="primary" @click="onSave()">提 交</el-button>
        </div>
      </div>
    </el-form>

  </div>
</template>

<script>
  import '@/assets/css/Student.css';
  import comUpload from '@/components/Upload.vue';
  export default {
    components: {
      comUpload,
    },
    data() {
      return {

        diseaseList: ["原因不明", "感冒", "气管炎", "肺炎", "水痘", "风疹", "麻疹", "腮腺炎", "肠胃道疾病", "心脏病", "眼病", "牙痛", "耳鼻喉疾病", "泌尿系疾病",
          "神经衰弱", "意外伤害", "结核肝炎", "其他传染病",
        ],
        symptomList: ["发热、咳嗽、头痛、咽痛",  "腹痛、腹污", "呕吐", "红眼", "皮疹", "受伤", "其他"],

        fullscreenLoading: false,
        Title: "新增请假",
        //列表数据
        itemData: [],
        ruleForm: {
          leaveId: null,
          startDate: null,
          endDate: null,
          days: null,
          type: null,
          reason: null, //描述
          attachment: null, //附件
          recordDate: null,
          category: null,
          sickness: null,
          symptom: null,
          syncGroupId: null,
          location: true,
          symptom: '',
        },
        positions: [{
            Value: true,
            Text: '在校',
          },
          {
            Value: false,
            Text: '在家',
          }
        ],
        //待重置时的表单数据
        ruleFormReset: null,
        rules: {

          type: [{
            required: true,
            message: '请假类型不能为空',
            trigger: 'change'
          }, ],
          recordDate: [{
            required: true,
            message: '请输入请假时间',
            trigger: 'change'
          }],
          startDate: [{
            required: true,
            message: '请输入请假开始时间',
            trigger: 'change'
          }],
          endDate: [{
            required: true,
            message: '请输入请假结束时间',
            trigger: 'change'
          }],
          startDatetime: [{
            required: true,
            message: '请输入请假开始时间',
            trigger: 'change'
          }],
          endDatetime: [{
            required: true,
            message: '请输入请假结束时间',
            trigger: 'change'
          }],
          reason: [{
              required: true,
              message: '请假事由不能为空',
              trigger: 'change'
            },
            {
              max: 1000,
              message: '请假事由不能超过1000个字符',
              trigger: 'change'
            },
          ],
          category: [{
            required: true,
            message: '请假分类不能为空',
            trigger: 'change'
          }],
          symptom: [{
            required: true,
            message: '症状不能为空',
            trigger: 'change'
          }],
          syncGroupId: [{
            required: true,
            message: '通校规则不能为空',
            trigger: 'change'
          }],
        },
        uploadFiles: [],
        random: null,

        optType: [],
        optCategory: [

        ],
      };
    },
    computed: {
      endPickerOptions() {
        return {
          disabledDate: (time) => {
            if (this.ruleForm.startDate) {
              return time.getTime() < new Date(this.ruleForm.startDate).setHours(0, 0, 0, 0) || time.getTime() >
                new Date(this.ruleForm.startDate.setHours(23, 59, 59, 999)).getTime();
            }
          }
        };
      }
    },
    methods: {
      //切换请假分类
      onClickCategory() {
        let that = this;
        let today = new Date();
        that.ruleForm.startDate = null;
        that.ruleForm.endDate = null;
        that.ruleForm.type = '';
        that.ruleForm.days = '';
        if (that.ruleForm.category == 2 || that.ruleForm.category == 3 || that.ruleForm.category == 5) {
          that.ruleForm.startDate = dateFormat(today);
        }
        if(that.ruleForm.category == 1){
          that.ruleForm.startDate = dateFormat(today,'-','YYYY-MM-DD HH:MM');
        }
      },
      //选择症状
      onClickAddr(val) {
        let that = this;
        if (that.ruleForm.symptom == null) {
          that.ruleForm.symptom = val;
        } else {
          that.ruleForm.symptom = that.ruleForm.symptom + val;
        }
      },
      /**
       * @description 清除时间事件
       */
      onClearDate() {
        let that = this;
        that.ruleForm.days = 0;
        that.ruleForm.startDate = null;
        that.ruleForm.endDate = null;
      },
      onChangeDateDay() {
        let that = this;
        if (!regNull(that.ruleForm.startDate) && !regNull(that.ruleForm.endDate)) {
          // 时间正常，就开始计算时长
          let dateStart = new Date(that.ruleForm.startDate);
          dateStart.setHours(0, 0, 0, 0)
          let dateEnd = new Date(that.ruleForm.endDate);
          let dateVal = dateEnd.getTime() - dateStart.getTime();
          let tpDays = Math.floor(dateVal / (24 * 3600 * 1000));
          let tpHours = Math.floor((dateVal % (24 * 3600 * 1000)) / (3600 * 1000));
          that.ruleForm.days = tpDays + 1;
        } else {
          that.ruleForm.days = null;
        }
      },
      onChangeDateTime() {
        let that = this;
        if (!regNull(that.ruleForm.startDate) && !regNull(that.ruleForm.startDate)) {
          // 时间正常，就开始计算时长
          let dateStart = new Date(that.ruleForm.startDate);
          let dateEnd = new Date(that.ruleForm.startDate);
          let dateVal = dateEnd.getTime() - dateStart.getTime();
          let tpDays = Math.floor(dateVal / (24 * 3600 * 1000));
          let tpHours = Math.floor((dateVal % (24 * 3600 * 1000)) / (3600 * 1000));
          // 进行时间判断，小于4小时的算半天，超过算1天，以此类推
          if (tpDays < 1) {

            if (0 < tpHours <= 4) {
              that.ruleForm.days = 0.5;
            } else {
              that.ruleForm.days = 1;
            }
          }
        }
      },
      /**
       * @description 时间事件，计算时长
       * @param {Object} objItem
       */
      onChangeDate(objItem) {
        let that = this;
        if (!regNull(that.ruleForm.startDate) && !regNull(that.ruleForm.endDate)) {

          // 时间正常，就开始计算时长
          let dateStart = new Date(that.ruleForm.startDate);
          // that.ruleForm.startDate = timeTransform(objItem[0]);
          // that.ruleForm.endDate = timeTransform(objItem[1]);
          let dateEnd = new Date(that.ruleForm.endDate);
          let dateVal = dateEnd.getTime() - dateStart.getTime();

          let tpDays = Math.floor(dateVal / (24 * 3600 * 1000));
          let tpHours = Math.floor((dateVal % (24 * 3600 * 1000)) / (3600 * 1000));
          // 进行时间判断，小于4小时的算半天，超过算1天，以此类推
          if (tpDays < 1) {

            if (tpHours <= 4) {
              that.ruleForm.days = 0.5;
            } else {
              that.ruleForm.days = 1;
            }
          } else {
            if ((tpHours <= 4) && (tpHours > 0)) {
              that.ruleForm.days = tpDays + 0.5;
            } else if (tpHours > 4) {
              that.ruleForm.days = tpDays + 1;
            } else {
              that.ruleForm.days = tpDays;
            }
          }
        } else {
          that.ruleForm.days = null;
        }
      },
      /**
       * @description 图片上传
       * @param {Object} param
       */
      onPictureHandleSuccess(param) {
        let that = this;
        that.ruleForm.attachment = "";
        for (var i in param) {
          if (that.ruleForm.attachment == "") {
            that.ruleForm.attachment = param[i].path;
          } else {
            that.ruleForm.attachment += "|" + param[i].path;
          }
        }
      },
      /**
       * @description 提交事件
       */
      onSave() {
        let that = this;
        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
              that.ruleForm.startDate = timeTransform(that.ruleForm.startDate);
              that.ruleForm.endDate = timeTransform(that.ruleForm.endDate);
            if(that.ruleForm.category == 1){
              that.ruleForm.type = setInt(that.ruleForm.type);
              that.ruleForm.location = that.ruleForm.location;
            }else{
              that.ruleForm.location = null;
              that.ruleForm.type = null;
            }
            that.ruleForm.category = setInt(that.ruleForm.category);
            if(that.ruleForm.category == 5){
              that.ruleForm.syncGroupId = regNull(that.ruleForm.syncGroupId) ? null : setInt(that.ruleForm.syncGroupId);
            }else{
              that.ruleForm.syncGroupId = null;
            }
            if (that.ruleForm.leaveId == null) {
              that.fullscreenLoading = true;
              ajaxPost(that, "/api/student/askforleave", that.ruleForm, function(r) {
                that.fullscreenLoading = false;
                that.onBack();
              });
            } else {
              that.fullscreenLoading = true;
              ajaxPut(that, "/api/student/askforleave/" + that.ruleForm.leaveId, that.ruleForm, function(r) {
                that.fullscreenLoading = false;
                that.onBack();
              });
            }
          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              isError[0].querySelector('input').focus();
            }, 1)
            return false;
          }
        })

      },
      onReset() {
        let that = this;
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      onBack() {
        let that = this;
        back(that);
      },
    },
    created() {
      let that = this;
      // that.fullscreenLoading = true;

      if(that.ruleForm.category == 1){
        that.ruleForm.startDate = dateFormat(today,'-','YYYY-MM-DD HH:MM');
      }
      that.fullscreenLoading = true;
      ajaxGet(that, '/api/dict/leave/category', null, function(res) {
        that.optCategory = res;
      })
      ajaxGet(that, '/api/student/askforleave/type', null, function(resData) {
        that.optType = resData;

        if (!regNull(that.$route.params.Id)) {
          that.Title = "编辑我的请假";
          ajaxGet(that, '/api/student/askforleave/' + that.$route.params.Id, null, function(resData) {
            that.ruleForm = resData;
            that.ruleForm.type = that.ruleForm.type.toString();
            that.ruleForm.category = that.ruleForm.category.toString();
            that.fullscreenLoading = false;
            that.ruleForm.recordDate = [that.ruleForm.startDate, that.ruleForm.endDate];
            that.uploadFiles = [];
            if (!regNull(resData.attachment)) {
              var files = resData.attachment.split('|');
              for (var i in files) {
                var it = i + 1;
                that.uploadFiles.push({
                  uid: it,
                  path: files[i],
                });
              }
              var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
              that.random += random;
            }
            that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
          });
        } else {
          that.fullscreenLoading = false;
          that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
        }
      });


    }
  };
</script>

<style scoped="scoped">
  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }
</style>
