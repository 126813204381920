<!--
  综合类型
-->
<template>
  <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
    <el-card style="margin-top: 10px;">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          综合类型
        </h3>
      </div>
      <div class="card-body">
        <el-row :gutter="24" style="padding-left: 20px;" class="rowConfig">
          <el-col :span="24" v-for="(p,index) in ruleForm.treeList" :key="p.configId"
            class="colConfig mb-5 elLiteracyType">
            <!-- 标题 -->
            <div v-if="p.parentId==null || p.parentId=='00000000-0000-0000-0000-000000000000'">
              <label class="mb-3">{{p.name}}</label>
            </div>
            <el-row>
              <el-col :xl="4" :lg="6" :md="8" :sm="8"
                v-if="(p.parentId==null ||p.parentId=='00000000-0000-0000-0000-000000000000')&&(p.children==null||p.score!=null)">
                <div class="inputGroupCont">
                  <div class="inputList mb-4">
                    <el-form-item v-if="p.isSelect == true" label-width="0px" :prop="'treeList.' + index + '.score'"
                      :rules="getScoreVerify">
                      <el-input @change="onScoreChange(p.configId,p)" @blur="handleBlurChange" placeholder="请输入"
                        v-model="p.score" style="width:200px">
                        <template slot="prepend">
                          <el-checkbox v-model="p.isSelect" @change="onSelectGrowthConfig(p.configId,p)">{{p.name}}
                          </el-checkbox>
                        </template>
                      </el-input>
                    </el-form-item>
                    <el-input v-else placeholder="请输入" v-model="p.score" :disabled="true" style="width:200px">
                      <template slot="prepend">
                        <el-checkbox v-model="p.isSelect" @change="onSelectGrowthConfig(p.configId,p)">{{p.name}}
                        </el-checkbox>
                      </template>
                    </el-input>
                  </div>
                </div>
              </el-col>
              <el-col :xl="4" :lg="6" :md="8" :sm="8" v-for="(p1,index1) in p.children" :key="index1">
                <div class="inputGroupCont">
                  <div class="inputList mb-4">
                    <el-form-item v-if="p1.isSelect == true" label-width="0px" :prop="getTableProp(index,index1)"
                      :rules="getScoreVerify">
                      <el-input @change="onScoreChange(p1.configId,p1)" @blur="handleBlurChange" placeholder="请输入"
                        v-model="p1.score" style="width:200px">
                        <template slot="prepend">
                          <el-checkbox v-model="p1.isSelect" @change="onSelectGrowthConfig(p1.configId,p1)">{{p1.name}}
                          </el-checkbox>
                        </template>
                      </el-input>
                    </el-form-item>
                    <el-input v-else placeholder="请输入" v-model="p1.score" :disabled="true" style="width:200px">
                      <template slot="prepend">
                        <el-checkbox v-model="p1.isSelect" @change="onSelectGrowthConfig(p1.configId,p1)">{{p1.name}}
                        </el-checkbox>
                      </template>
                    </el-input>
                  </div>
                </div>
              </el-col>
            </el-row>

          </el-col>
        </el-row>
      </div>
    </el-card>
  </el-form>
</template>

<script>
  export default {
    data() {
      return {
        ruleForm: {
          treeList: null,
          GrowthConfigs: null,
        },
        resetForm: [], //重置列表
        rules: {
          name: [{
              required: true,
              message: '综合课堂名称不能为空',
              trigger: 'change'
            },
            {
              max: 256,
              message: '综合课堂不能超过256个字符',
              trigger: 'change'
            },
          ],
        },
      }
    },
    props: {
      defaultConfig: { //默认选中的配置
        type: Array,
        default: () => []
      },
      configList: { //渲染列表list
        type: Array,
        default: () => [],
        required: true
      }
    },
    computed: {
      getScoreVerify() {
        let rules = [{
            required: true,
            message: '综合类型的分值必须填写',
            trigger: 'change'
          },
          {
            pattern: /^([0-9]+\.?[0-9]*|-[0-9]+\.?[0-9]*)$/,
            message: '请输入数字',
            trigger: 'change'
          }
        ]
        return rules
      },
      getTableProp() {
        return function(index, index2) {
          return `treeList[${index}].children[${index2}].score`
        }
      }
    },
    methods: {
      handleBlurChange(val) {},
      resetLoad() {
        let that = this
        initPage(that)
      },
      validateForm(callback) { //用于父组件调用的验证函数
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            callback && callback(true)
            return
          }
          callback && callback(false)
        })
      },
      onReset() { //重置列表
        let that = this
        if (that.defaultConfig.length > 0) { //编辑
          for (let item of that.ruleForm.GrowthConfigs) {
            item.isSelect = false;
            item.score = null;
          }
          let tree = onGetJsonToTree(that.ruleForm.GrowthConfigs.map(item => ({
            configId: item.configId,
            name: item.name,
            priority: item.priority,
            parentId: regNull(item.parentId) ? '00000000-0000-0000-0000-000000000000' : item.parentId,
            description: item.description,
            ceiling: item.ceiling,
            baseScore: item.baseScore,
            applys: item.applys,
          })), 'configId', 'parentId', 'children');;
          mapTreeChildren(tree);
          that.ruleForm.treeList = tree;
          that.ruleForm.treeList = setTreeScore(that.ruleForm.treeList, that.defaultConfig); //设置成绩
          setDefaultConfig(that, that.ruleForm.GrowthConfigs, that.defaultConfig); //更新配置列表并赋予初值
        } else { //新增
          for (let item of that.ruleForm.GrowthConfigs) {
            item.isSelect = false;
            item.score = null;
          }
          let tree = onGetJsonToTree(that.ruleForm.GrowthConfigs.map(item => ({
            configId: item.configId,
            name: item.name,
            priority: item.priority,
            parentId: regNull(item.parentId) ? '00000000-0000-0000-0000-000000000000' : item.parentId,
            description: item.description,
            ceiling: item.ceiling,
            baseScore: item.baseScore,
            applys: item.applys,
          })), 'configId', 'parentId', 'children'); //将列表变成tree treeData(that.ruleForm.GrowthConfigs);
          mapTreeChildren(tree);
          that.ruleForm.treeList = tree;
        }
      },
      onSelectGrowthConfig(Id, obj) //复选框选中
      {
        let that = this;
        for (let i of that.ruleForm.GrowthConfigs) {
          if (i.configId == Id) {
            i.isSelect = obj.isSelect;
          }
        }
        that.$emit('change', that.ruleForm.GrowthConfigs);
      },
      onScoreChange(Id, obj) //成绩改变
      {
        let that = this;
        let reg = /^([0-9]+\.?[0-9]*|-[0-9]+\.?[0-9]*)$/
        if (reg.test(obj.score)) {
          for (let i in that.ruleForm.GrowthConfigs) {
            if (that.ruleForm.GrowthConfigs[i].configId == Id) {
              let score = Number(obj.score).toFixed(1);
              obj.score = Number(obj.score).toFixed(1);
              that.ruleForm.GrowthConfigs[i].score = score;
              that.$set(that.ruleForm.GrowthConfigs, i, that.ruleForm.GrowthConfigs[i]);
            }
          }
          that.$emit('change', that.ruleForm.GrowthConfigs);
        }
      },
      onEdit() {
        let that = this
        that.ruleForm.treeList = setTreeScore(that.ruleForm.treeList, that.defaultConfig); //设置成绩
        setDefaultConfig(that, that.ruleForm.GrowthConfigs, that.defaultConfig); //更新配置列表并赋予初值
      },
      onAdd() {
        let that = this
        that.ruleForm.GrowthConfigs = that.configList.map(item => {
          item.isSelect = false;
          item.score = null;
          return item;
        })
      }

    },
    watch: {
      'configs': {
        handler(val) {
          this.configs = val
        }
      },
      'defaultConfig': {
        handler(val) {
          this.defaultConfig = val
        }
      }
    },
    created() {
      let that = this;
      that.ruleForm.treeList = [];
      that.ruleForm.GrowthConfigs = [];
      that.ruleForm.GrowthConfigs = that.configList; //设置列表
      that.ruleForm.treeList = onGetJsonToTree(that.configList.map(item => ({
        configId: item.configId,
        name: item.name,
        priority: item.priority,
        parentId: regNull(item.parentId) ? '00000000-0000-0000-0000-000000000000' : item.parentId,
        description: item.description,
        ceiling: item.ceiling,
        baseScore: item.baseScore,
        applys: item.applys,
      })), 'configId', 'parentId', 'children'); //将列表变成tree
      that.resetForm = onGetJsonToTree(that.configList.map(item => ({
        configId: item.configId,
        name: item.name,
        priority: item.priority,
        parentId: regNull(item.parentId) ? '00000000-0000-0000-0000-000000000000' : item.parentId,
        description: item.description,
        ceiling: item.ceiling,
        baseScore: item.baseScore,
        applys: item.applys,
      })), 'configId', 'parentId', 'children'); //设置重置
      mapTreeChildren(that.ruleForm.treeList); //将tree的子项变成列表
      if (that.defaultConfig.length > 0) { //编辑
        that.onEdit();
      } else { //新增
        that.onAdd();
      }
    }
  };

  function initPage(that) {
    that.ruleForm.treeList = []
    that.ruleForm.GrowthConfigs = []
    that.ruleForm.GrowthConfigs = that.configList //设置列表
    that.ruleForm.treeList = onGetJsonToTree(that.configList.map(item => ({
       configId: item.configId,
      name: item.name,
      priority: item.priority,
       parentId: regNull(item.parentId) ? '00000000-0000-0000-0000-000000000000' : item.parentId,
      description: item.description,
       ceiling: item.ceiling,
       baseScore: item.baseScore,
       applys: item.applys,
    })), 'configId', 'parentId', 'children'); //将列表变成tree


    that.resetForm = onGetJsonToTree(that.configList.map(item => ({
       configId: item.configId,
      name: item.name,
      priority: item.priority,
       parentId: regNull(item.parentId) ? '00000000-0000-0000-0000-000000000000' : item.parentId,
      description: item.description,
       ceiling: item.ceiling,
       baseScore: item.baseScore,
       applys: item.applys,
    })), 'configId', 'parentId', 'children'); //设置重置
    mapTreeChildren(that.ruleForm.treeList); //将tree的子项变成列表
    that.$emit('change', that.ruleForm.GrowthConfigs);

  }

  function setDefaultConfig(that, sign, configs) {
    for (let it of sign) {
      if (configs.length > 0) {
        configs.forEach(item => {
          if (it.configId == item.configId) {
            it.isSelect = true
            it.score = item.score
          }
        })
      }
    }
    that.$emit('change', JSON.parse(JSON.stringify(sign)))
  }

  function setTreeScore(tree, config) {
    let data = JSON.parse(JSON.stringify(config));
    let end = JSON.parse(JSON.stringify(tree));
    end.forEach(item => {
      for (let i = 0; i < data.length; i++) {
        if (item.configId === data[i].configId) {
          item.isSelect = true;
          item.score = data[i].score;
        }
        if (item.children && item.children.length > 0) {
          let current = item.children.findIndex(it => it.configId == data[i].configId);
          if (current != -1) {
            item.children[current].isSelect = true;
            item.children[current].score = data[i].score;
          }
        }
      }
    })
    return end;
  }
  //组合一颗树菜单孩子为list的数组
  function mapTreeChildren(data) {
    if (data && data.length > 0) {
      data.forEach(item => {
        if (item.parentId === '00000000-0000-0000-0000-000000000000') {
          if (item.children && item.children.length > 0) {
            item.children = flapTree(item.children);
          }
        }
      });
    }
  }
  //将树菜单的孩子变成列表
  function flapTree(data, arr = []) {
    data.forEach(item => {
      arr.push(item)
      if (item.children && item.children.length > 0) {
        flapTree(item.children, arr);
      }
    })
    return arr;
  }
  //通过List生成一颗树
  function treeData(data) {
    let cloneData = JSON.parse(JSON.stringify(data));
    return cloneData.filter(father => {
      let branchArr = cloneData.filter(child => father['configId'] == child['parentId']);
      branchArr.length > 0 ? father['children'] = branchArr : father['children'] = null;
      return father['parentId'] == '00000000-0000-0000-0000-000000000000';
    });
  }
</script>

<style>
  .inputGroupCont label {
    margin-bottom: 0;
  }
</style>
