<!--
  综合活动 活动详情
-->
<template>
  <div class="SubWebPageStudent DetailsPro">

    <!--活动报名-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          查看活动详情
        </h3>
      </div>
      <div class="card-body">
        <el-row :gutter="24">
          <el-col :span="8" v-if="imgArr!=null && imgArr.length!=0">
            <el-carousel height="300px" style="padding-left: 20px;">
              <el-carousel-item v-for="(item,index) in imgArr" :key="index">
                <img :src="item" alt="" width="100%">
              </el-carousel-item>
            </el-carousel>
          </el-col>
          <el-col :span="imgArr!=null && imgArr.length!=0?16:24">
            <div class="infoList clearfix">
              <span class="spName">{{itemData.name}}</span>
            </div>
            <div class="infoList clearfix" v-if="itemData.applyStartDate!=null">
              <span class="spFonts">
                <span class="spLabel">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#fax-clock-o"></use>
                  </svg>
                  &nbsp;
                  报名时间
                </span>
                <span>{{itemData.applyStartDate|dateformatMinute}} 至 {{itemData.applyEndDate|dateformatMinute}}</span>
              </span>
            </div>
            <div class="infoList clearfix" v-if="itemData.startDate!=null">
              <span class="spFonts">
                <span class="spLabel">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#fax-date-clock-o"></use>
                  </svg>
                  &nbsp;
                  活动时间
                </span>
                &nbsp;
                <span>{{itemData.startDate|dateformatMinute}} 至 {{itemData.endDate|dateformatMinute}}</span>
              </span>
            </div>
            <div class="infoList clearfix">
              <!--已报名人数-->
              <span class="spFonts" v-if="itemData.rangesNum!=null">
                <span class="spLabel">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#fax-cube-users-alt"></use>
                  </svg>
                  &nbsp;
                  已报名人数
                </span>
                &nbsp;
                <span class="spNum">{{itemData.rangesNum}}</span>
              </span>
              <!--活动计划人数-->
              <span class="spFonts" v-if="itemData.numberLimit!=null">
                <span class="spLabel">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#fax-cube-users-alt"></use>
                  </svg>
                  &nbsp;
                  活动计划人数
                </span>
                &nbsp;
                <span class="spNum">{{itemData.numberLimit}}</span>
              </span>
              <span class="spFonts" v-if="itemData.place!=null">
                <span class="spLabel">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#fax-fax-map-marker-o"></use>
                  </svg>
                  &nbsp;
                  地址
                </span>
                {{itemData.place}}
              </span>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-card>


    <!--活动内容-->
    <el-card class="box-card" v-if="itemData.description!=null">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          活动内容
        </h3>
      </div>
      <div class="card-body">
        <el-row :gutter="24">
          <el-col :span="24">
            <div class="colRemarksCont" v-html="itemData.description"></div>
          </el-col>
        </el-row>
      </div>
    </el-card>


    <!-- 个人上传数据 -->
    <el-card class="box-card" v-if="myImageArr!=null && myImageArr.length!=0">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          已上传附件
        </h3>
      </div>
      <div class="card-body">
        <el-row :gutter="24">
          <el-col :span="8" v-for="(item,index) in myImageArr" :key="index">
            <el-image :src="item" class="elImageBox" fit="cover" :preview-src-list="myImageArr"></el-image>
          </el-col>
        </el-row>
      </div>
    </el-card>


    <el-card class="box-card mt-3" v-if="rosterData!=null && rosterData.length!=0">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          其他同学附件
        </h3>
      </div>
      <div class="card-body">
        <el-tabs tab-position="left">
          <el-tab-pane :label="item.className" v-for="(item,index) in rosterData" :key="index">

            <div class="tabRoleCont">
              <div class="row">
                <div :class="list.attachment!=null && list.attachment!=0?'col-12':'col-3'"
                  v-for="(list,index2) in item.children" :key="index2">
                  <div class="stuInfoBox clearfix">
                    <div class="stuImgCont">
                      <el-avatar :size="42" :src="list.photo" v-if="list.photo==null"></el-avatar>
                      <svg class="icon font-size-42 color-gray" aria-hidden="true" v-else>
                        <use xlink:href="#fax-use-circle-alt"></use>
                      </svg>
                    </div>
                    <div class="stuInfoCont">
                      <div class="stuName">{{list.studentName}}</div>
                      <div class="stuOther">{{list.studentNo}}</div>
                      <div class="stuStatus">
                        <span class="color-success" v-if="list.scoring">{{已计综合分}}</span>
                      </div>
                    </div>
                    <div class="imgList" v-if="list.attachment!=null && list.attachment!=0">
                      <el-row :gutter="20">
                        <el-col :span="8" v-for="(listImg,index3) in list.attachment" :key="index3">
                          <el-image :src="listImg" class="elImageBoxTwo" fit="cover"
                            :preview-src-list="list.attachment">
                          </el-image>
                        </el-col>
                      </el-row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-card>




    <!-- 获奖人员 -->
    <el-card class="box-card" v-if="itemData.isHasPrize && arrPrize!=null && arrPrize.length!=0">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          获奖人员
        </h3>
      </div>
      <div class="card-body pt-0">
        <div class="row">
          <div class="col-lg-3" v-for="(item,index) in arrPrize" :key="index">
            <div class="stuInfo">
              <div class="stuImgCont VerticalMid">
                <img :src="item.photo" alt="" v-if="item.photo!=null" style="width: 42px;">
                <svg class="icon font-size-42 color-gray" aria-hidden="true" v-else>
                  <use xlink:href="#fax-use-circle-alt"></use>
                </svg>
              </div>
              <div class="stuName">{{item.studentName}}&nbsp;</div>
              <div class="stuOther">{{item.studentNo}}</div>
              <div class="stuRole"><span class="spStatus">{{item.name}}</span></div>
            </div>
          </div>
        </div>
      </div>
    </el-card>



    <div class="row" style="margin-top: 10px;">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
        <el-button type="success" @click="onEnroll()" v-if="arrOperate.isBtnJoin" >
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#fax-attendance-check"></use>
          </svg>
          报名参加
        </el-button>
        <el-button type="danger" @click="onRevokeEnroll()" v-if="arrOperate.isBtnCancel">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#fax-sign-out-alt"></use>
          </svg>
          取消报名
        </el-button>
      </div>
    </div>



  </div>
</template>

<script>
  import '@/assets/css/Student.css';
  export default {
    components: {},
    data() {
      return {
        // 动画
        fullscreenLoading: false,
        dialogVisible: false,
        uploadFiles: [],
        random: 0,
        //列表数据
        itemData: {},
        imgArr: [],
        // 放大图片
        showViewer: false,
        //用于互看记录
        rosterData: [],
        //自己的上传图片记录
        myImageArr: [],
        arrPrize: [],
        arrOperate:{
          isBtnJoin:false,
          isBtnCancel:false,
        }
      };
    },
    methods: {
      /**
       * @description 学生报名活动
       * @param {Object} Id
       */
      onEnroll() {
        let that = this;
        confirmDelete(that, '确定参加本次活动吗？', function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxPost(that, '/api/student/activity/enroll/' + that.itemData.activityId, null, function(r) {
              that.fullscreenLoading = false;
              onGetData(that);
            });
          }
        })
      },
      /**
       * @description 取消活动报名
       */
      onRevokeEnroll() {
        let that = this;
        confirmDelete(that, '确定要取消报名活动吗？', function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, "/api/student/activity/revokeenroll/" + that.itemData.activityId, null, function(r) {
              that.fullscreenLoading = false;
              onGetData(that);
            });
          }
        })
      },

      /**
       * @description 返回
       */
      onBack() {
        let that = this;
        back(that);
      },

      /**
       * @description 数组去重复数据
       * @param {Object} arr
       */
      onArrUnique(arr) {
        const res = new Map();
        return arr.filter((arr) => !res.has(arr.className) && res.set(arr.className, 1));
      }
    },
    created() {
      let that = this;
      // 活动ID
      onGetData(that)

    },
  }

 function onGetData(that){
    let id = that.$route.params.id;
    that.fullscreenLoading = true;
    // 加载活动详情
    ajaxGet(that, "/api/student/activity/" + id, null, function(resActivity) {
      // 加载报名人数
      ajaxGet(that, "/api/student/activity/roster/" + id, null, function(resRoster) {

        that.arrOperate=onGetActivityStatus(
          resActivity.isApply, //是否需要报名
          resActivity.status, //活动当前状态
          resActivity.isImport, //是否导入报名范围
          resActivity.isUpload, //是否上传
          resActivity.applyStartDate, //报名开始时间
          resActivity.applyEndDate, //报名结束时间
          resActivity.startDate, //活动开始时间
          resActivity.endDate, //活动结束时间
          resActivity.uploadDeadline, //学生上传文件截止时间
          false, //是否为审核员
          false, //是否可以代报名
          true, //是否为学生
          resActivity.isAllowApply, //是否可参加活动
          Number(resActivity.rosterStatusInt), //名单状态
          resActivity.isAttendance, //是否需要签到
          resActivity.attended, //学生签到情况
          resActivity.isApplyAudit, //是否需要报名审核
        )


        // 处理活动介绍图片
        if (!regNull(resActivity.attachment)) {
          var attachments = resActivity.attachment.split('|');
          for (var i in attachments) {
            that.imgArr.push(that.$ServerUrl() + attachments[i]);
          }
        }
        that.fullscreenLoading = false;
        that.itemData = resActivity;

        // 过滤参赛人员 学生列表只显示报名审核通过的学生
        resRoster = resRoster.filter(val => val.status == 3);
        // 已报名人数
        that.itemData.rangesNum = resRoster.length;



        // 判断是否上传附件
        if (resActivity.isUpload) {
          //学生自己上传的附件
          if (regNull(resActivity.selfAttachment)) {
            that.myImageArr = [];
          } else {
            let tempMyImage = resActivity.selfAttachment.split('|');
            that.myImageArr = [];
            for (let list of tempMyImage) {
              that.myImageArr.push(that.$ServerUrl() + list);
            }
          }


          // 判断是否允许互看上传附件
          if (resActivity.isView) {
            // 过滤没有上传的学生和自己
            let tempData = resRoster.filter(val => val.attachment != null && val.attachment != '' && val
              .studentNo != that.$GetKey('StudentNo'));

            // 整理出班级
            let arrClass = that.onArrUnique(tempData);
            //对学生的头像和上传附件进行处理。
            tempData.forEach(function(item) {
              item.photo = regNull(item.photo) ? null : that.$ServerUrl() + item.photo;
              // 处理上传附件
              if (regNull(item.attachment)) {
                item.attachment = [];
              } else {
                let tempImage = item.attachment.split('|');
                item.attachment = [];
                for (let list of tempImage) {
                  item.attachment.push(that.$ServerUrl() + list);
                }
              }
            });

            // 根据学生上传的附件进行排序
            that.rosterData = [];
            for (let item of arrClass) {
              that.rosterData.push({
                className: item.className,
                children: tempData.filter(val => val.className == item.className),
              });
            }

          }
        }


        // 判断是否有评奖数据
        if (resActivity.isHasPrize) {
          ajaxGet(that, '/api/student/activity/prize/' + id, null, function(resPrize) {
            that.arrPrize = resPrize;
            that.arrPrize.forEach(function(val) {
              if (regNull(val.photo)) {
                val.photo = null;
              } else {
                val.photo = that.$ServerUrl() + val.photo;
              }
            });
          });
        }
      });
    });
  }

</script>

<style scoped="scoped">
  .dialogBox {
    padding: 10px 5px;
    height: 140px;
    overflow: hidden;
  }

  .dialogVideoBox {
    padding: 10px 5px;
  }

  .dialogBox .mailbox-attachment-icon {
    font-size: 32px;
    padding: 15px 10px;
  }

  .dialogBox .mailbox-attachment-size>span {
    font-size: 12px;
  }

  .dialogBox .mailbox-attachment-info {
    padding: 7px 10px;
  }



  .bodyAwardsCont .stuAwardsCont {
    position: relative;
    padding: 0px 15px 45px 15px;
  }

  .bodyAwardsCont .stuAwardsCont .stuAvatar {
    position: absolute;
    left: 15px;
    top: 0px;
    border: 1px solid #eee;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 5px;
  }

  .bodyAwardsCont .stuAwardsLabel {
    font-size: 12px;
    padding-left: 65px;
    color: #B1B1B1;
  }

  .bodyAwardsCont .awardsName {
    padding-top: 10px;
    color: #3e3e3e;
  }

  .bodyAwardsCont .stuAwardsCont .stuName {
    font-size: 16px;
    padding-left: 65px;
  }

  .bodyAwardsCont .stuAwardsFont {
    font-size: 14px;
    padding-left: 65px;
    color: #666;
  }

  .bodyAwardsCont .stuFileCont {
    padding-top: 5px;
  }




  .bodyRecordCont .stuRecordCont {
    position: relative;
    padding: 0px 15px 45px 15px;
    padding-left: 75px;

  }

  .bodyRecordCont .stuRecordCont .stuAvatar {
    position: absolute;
    left: 15px;
    top: 0px;
    border: 1px solid #eee;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 5px;
  }


  .bodyRecordCont .stuRecordCont .stuName {
    font-size: 16px;
  }

  .bodyRecordCont .stuRecordFont {
    font-size: 12px;
    padding-top: 5px;
    color: #767676;
  }

  .bodyRecordCont .stuRecordLabel {
    font-size: 12px;
    padding-top: 5px;
    color: #B1B1B1;
  }

  .bodyRecordCont .stuFileCont {
    padding-top: 10px;
  }

  .bodyRecordCont .stuFileCont .divFileIcon {
    border: 1px solid #eee;
    width: 100%;
    height: 80px;
    overflow: hidden;
    border-radius: 4px;
  }

  .bodyRecordCont .stuFileCont .divFileIcon .elIcon {
    font-size: 32px;
  }





  .colRecordOther {}

  .colRecordOther .divList {
    float: left;
    width: 33.3%;
  }

  .colRecordCont {
    padding: 5px;
    font-size: 12px;
    color: #767676;
  }

  .divVideoCont {
    overflow-y: auto;
    width: 100%;
    height: 500px;
    padding-right: 10px;
  }

  .divVideoCont ul li .aItem {
    display: block;
    padding: 7px 0px;
    padding-left: 30px;
    border-bottom: 1px dotted #DDDDDD;
    position: relative;
  }

  .divVideoCont ul li:last-child .aItem {
    border-bottom: none;
  }

  .divVideoCont ul li:hover {
    background-color: #5AAAFF;
    color: #FFFFFF;
  }

  .divVideoCont ul li .aItem span {
    font-family: 'OswaldLight';
    font-size: 14px;
    margin-left: 10px;
  }

  .divVideoCont ul li .aItem .faxIcon {
    position: absolute;
    top: 11px;
    left: 5px;
    display: none;
  }

  .divVideoCont ul li .aItemAct {
    background-color: #007BFF;
    color: #FFFFFF;
    border-bottom: none;
  }

  .divVideoCont ul li .aItemAct .faxIcon {
    display: block;
  }


  .bodyImgCont .mailbox-attachment-icon {
    min-height: 165px;
  }

  .bodyFileCont .mailbox-attachments li,
  .bodyImgCont .mailbox-attachments li {
    width: 250px;
  }

  .bodyImgCont .mailbox-attachment-icon {
    height: 198px;
  }

  .bodyFileCont .mailbox-attachment-size .spFont,
  .bodyImgCont .mailbox-attachment-size .spFont {
    font-size: 12px;
  }





  .tabListCont .divNavTitle {}

  .tabListCont .divNavTitle {}

  .tabListCont .divNavTitle .divTitleCont {
    padding: 10px 0px 10px 10px;
    color: #DEE2E6;
    cursor: pointer;
    font-size: 18px;
  }

  .tabListCont .divNavTitle .divTitleCont .spNum {
    background-color: #DEE2E6;
    font-family: 'OswaldLight';
    padding: 2px 5px;
    border-radius: 4px;
    margin-left: 5px;
    font-size: 14px;
    color: #FFFFFF;
  }

  .tabListCont .divNavTitle .divTitleAct {
    color: #3e3e3e;
  }

  .tabListCont .divNavTitle .divTitleAct .spNum {
    background-color: #17A2B8;
    color: #FFFFFF;
  }

  .tabListCont .divNavTitle .nav-link {
    font-size: 14px;
    color: #B1B1B1;
  }

  .tabListCont .divNavTitle .nav-link .spNum {
    font-family: 'OswaldLight';
    font-weight: normal;
    font-size: 14px;
    padding-left: 10px;
  }

  .tabListCont .divNavTitle .active {
    color: #007bff;
  }




  .DetailsPro .divRoleTitle {
    font-size: 18px;
    color: #323E56;
    padding-top: 20px;
  }

  .DetailsPro .divRoleTitle .spItem {
    float: right;
    margin-left: 5px;
    font-size: 14px;
    color: #28A745;
  }

  .DetailsPro .divRoleTitle .spItem em {
    font-style: normal;
    background-color: #28A745;
    color: #FFFFFF;
    margin-left: 5px;
    margin-right: 5px;
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 12px;
    font-family: 'OswaldLight';
  }

  .DetailsPro .divRoleTitle .el-button {
    padding: 0;
    border: none;
  }

  .DetailsPro .divRoleTitle .el-button:hover {
    background-color: transparent;
  }





  .stuInfo {
    position: relative;
    height: 100%;
    min-height: 80px;
    padding-left: 80px;
    margin: 15px;
  }

  .stuInfo .stuFraction {
    position: absolute;
    top: -5px;
    bottom: 0;
    right: -5px;
    font-family: 'OswaldLight';
    font-size: 26px;
    color: #28A745;
  }

  .stuInfo .stuBtnDown {
    position: absolute;
    bottom: 20px;
    right: 0;
  }

  .stuInfo .stuBtnDown .aView {
    margin-right: 5px;
    margin-left: 5px;
  }

  .stuInfo .stuBtnDown .aView .faxSize {
    font-size: 12px;
    color: #343a40;
  }

  .stuInfo .stuBtnDown .aView:hover .faxSize {
    color: #1666B3;
  }

  .stuInfo .stuImgCont {
    position: absolute;
    top: 0;
    left: 0;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
  }

  .stuInfo .stuImgCont img {
    width: 100%;
    padding-top: 10px;
  }

  .stuInfo .stuName {
    color: #212E48;
    font-size: 18px;
    font-weight: bold;
  }

  .stuInfo .stuRole {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
  }

  .stuRole .spStatus {
    font-size: 12px;
    color: #B1B1B1;
  }

  .stuInfo .stuOther {
    color: #767676;
    font-size: 12px;
  }


  .cardTabs .abtnTabs {
    font-size: 16px;
    color: #B1B1B1;
    margin-left: 15px;
    position: relative;
    display: block;

    float: left;
    text-align: center;
  }

  .cardTabs .spDivide {
    float: left;
    padding-left: 15px;
  }


  .cardTabs .abtnTabs:hover {
    color: #323E56;
  }

  .cardTabs .abtnTabs .spFaxIcon {
    display: none;
  }

  .cardTabs .abtnTabs:first-child {
    margin-left: 0;
  }

  .cardTabs .abtnTabs .spNumber {
    background-color: #DEE2E6;
    font-size: 12px;
    color: #FFFFFF;
    font-family: 'OswaldLight';

    padding: 1px 5px;
    border-radius: 5px;
  }



  .cardTabs .abtnTabAct {
    font-size: 1.1rem;
    color: #323E56;
  }

  .cardTabs .abtnTabAct .spFaxIcon {
    display: block;
    position: absolute;
    top: 22px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 12px;
  }

  .cardTabs .abtnTabAct .spNumber {
    background-color: #1666B3;
    color: #FFFFFF;
    font-size: 14px;
  }



  .infoList {
    padding-bottom: 17px;
    padding-left: 30px;
  }

  .infoList .spName {
    font-size: 32px;
    color: #212E48;
    padding-right: 30px;
    height: 40px;
  }


  .infoList .spFonts {
    color: #3B475D;
    padding-right: 30px;
    padding-left: 30px;
    border-right: 1px solid #D6D6D6;
  }

  .infoList .spFonts:first-child {
    padding-left: 0;
  }

  .infoList .spFonts:last-child {
    border-right: none;
  }

  .infoList .spMedal {
    padding-right: 15px;
  }

  .infoList .spFonts .faxSize {
    font-size: 18px;
  }

  .infoList .spFonts .icon {
    color: #FFFFFF;
  }

  .infoList .spStatus {
    border: 1px solid #007BFF;
    background-color: #007BFF;
    color: #FFFFFF;
    padding: 5px 20px;
    border-radius: 5px;

  }

  .infoList .spNStatus {
    border: 1px solid #007BFF;
    background-color: #007BFF;
    color: #FFFFFF;
    padding: 5px 20px;
    border-radius: 5px;
  }

  .infoList .spFonts .spLabel {
    font-size: 14px;
    padding: 2px 5px;
    border-radius: 5px;
    background-color: #B1B1B1;
    color: #FFFFFF;
    margin-right: 10px;
  }

  .infoList .spFonts .spNum {
    padding-left: 10px;
    padding-right: 5px;
    font-family: 'OswaldLight';
    font-size: 16px;
    font-weight: bold;
    color: #DC3545;
  }

  .DetailsPro .colRemarksCont {
    font-size: 14px;
    letter-spacing: 1px;
  }



  .stuInfoBox {
    margin-bottom: 0.9375rem;
    display: flex;
  }

  .stuInfoBox .stuImgCont {
    width: 3.125rem;
    height: 3.75rem;
    text-align: center;
  }

  .stuInfoBox .stuInfoCont {
    padding-left: 0.9375rem;
    padding-right: 30px;
    min-width: 150px;
  }

  .stuInfoBox .stuInfoCont .stuName {
    color: #212E48;
    font-size: 18px;
    font-weight: bold;
  }

  .stuInfoBox .stuInfoCont .stuOther {
    color: #767676;
    font-size: 12px;
  }

  .stuInfoBox .stuInfoCont .stuStatus {
    font-size: 12px;
  }

  .stuInfoBox .imgList {
    flex: 1;
  }

  .elImageBox {
    height: 200px;
    width: 100%;
    margin-bottom: 10px;
  }

  .elImageBoxTwo {
    height: 160px;
    width: 100%;
    margin-bottom: 10px;
  }

  .tabRoleCont {
    padding-left: 0.9375rem;
  }

</style>

