<!--
  基础信息 - 活动配置
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="width:100%;padding-left: 0px;">
          班主任常规配置
        </h3>
      </div>
      <div class="card-body">
        <el-row :gutter="24">
          <el-col :xl="6"  :sm="8" :xs="8">
            <div class="divTreeCont">
              <el-input placeholder="输入关键字进行过滤" v-model="filterText">
                <el-button slot="append" icon="el-icon-refresh-right" @click="onFilterText()"></el-button>
              </el-input>
              <el-tree :data="data" :props="defaultProps" ref="tree" style="margin-top:10px;" node-key="id"
                default-expand-all @node-click="handleNodeClick" :expand-on-click-node="false"
                :render-content="renderContent" :filter-node-method="filterNode">
              </el-tree>
            </div>
          </el-col>
          <el-col :xl="18" :sm="16" :xs="16">
            <el-row :gutter="24">
              <el-col :span="19">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item v-for="(p,index) in currentNav" :key="index">{{p}}</el-breadcrumb-item>
                </el-breadcrumb>
              </el-col>
              <el-col :span="4" style="text-align: right; margin-right: 5px;" v-if="isPower.AdviserGeneralReason_add">
                <el-button @click="onAdd()" type="success" icon="el-icon-circle-plus-outline">新增</el-button>
              </el-col>
            </el-row>

            <div v-if="item!=null && item.length!=0">
              <el-table stripe :data="item" class="elTableData">
                <el-table-column type="index" width="80" label="序号"></el-table-column>
                <el-table-column prop="name" label="活动名称" min-width="160" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="priority" label="优先级" width="100"></el-table-column>
                <el-table-column prop="score" label="默认分" width="100"></el-table-column>
                <el-table-column label="操作" width="160" fixed="right">
                  <template slot-scope="scope">
                    <!-- <operationBtn :btnName="'配置'" @click.native="onConfig(scope.row.id)"></operationBtn> -->
                    <operationBtn v-if="isPower.AdviserGeneralReason_edit" :btnName="'编辑'"
                      @click.native="onEdit(scope.row)"></operationBtn>
                    <operationBtn v-if="isPower.AdviserGeneralReason_delete" :btnName="'删除'"
                      @click.native="onDelete(scope.row.id)"></operationBtn>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div v-else>
              <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
            </div>

          </el-col>
        </el-row>
      </div>
    </el-card>


    <!-- 弹出框 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" top="10px" width="40%" :close-on-press-escape="false"
      :append-to-body="true" :fullscreen="false">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="90px">
        <div class="card-body">
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="项目名称" prop="name">
                <el-input v-model="ruleForm.name" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="所属类型" prop="parentId">
                <treeSelect :key="random" @getValue="getValue" :options="data" :value="ruleForm.parentId">
                </treeSelect>
              </el-form-item>
            </el-col>
            <el-col :span="24" v-if="!isTreeChildren">
              <el-form-item label="优先级" prop="priority">
                <el-input @change="onPriorityChange()" v-model="ruleForm.priority" placeholder="请输入优先级"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" v-if="!isTreeChildren">
              <el-form-item label="默认分" prop="score">
                <el-input @change="onPriorityChange()" v-model="ruleForm.score" placeholder="请输入默认分"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" v-if="!isTreeChildren">
              <el-form-item label="描述" prop="description">
                <el-input type="textarea" placeholder="请输入内容" v-model="ruleForm.description" maxlength="300"
                  show-word-limit>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>


      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeForm()">取 消</el-button>
        <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
        <el-button type="primary" icon="el-icon-circle-check" @click="onSave()">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import operationBtn from '@/components/operationBtn.vue';
  import treeSelect from '@/components/TreeSelect.vue';
  export default {
    name: '',
    components: {
      operationBtn,
      treeSelect
    },
    data() {
      return {
        filterText: '', //查找树形菜单
        activeName: "",
        currentNav: [],
        currentEdit: null,
        isTreeChildren: false,
        random: 0, //刷新组件
        currentId: null,
        isDetail: false,
        dialogTitle: "新增配置",
        dialogVisible: false,
        currentTitle: "请在左侧选择综合类型",
        item: null,
        fullscreenLoading: false,
        data: [],
        selectData: [],
        defaultProps: {
          children: 'children',
          label: 'name'
        },
        isPower: {
          AdviserGeneralReason_edit: false,
          AdviserGeneralReason_add: false,
          AdviserGeneralReason_delete: false
        },
        ruleForm: {
          name: null,
          priority: null,
          score: null,
          parentId: null,
          description: null,
        },
        activityroleReset: [],
        activityitem: [],
        ruleFormInit: null,
        ruleFormReset: null, //重置时的表单数据
        rules: {
          name: [{
              required: true,
              message: '考核项目名称不能为空',
              trigger: 'change'
            },
            {
              max: 256,
              message: '考核项目名称不能超过256个字符',
              trigger: 'change'
            },
          ],
        },
      };
    },
    created() {
      let that = this;
      that.fullscreenLoading = true;
      that.ruleFormInit = JSON.parse(JSON.stringify(that.ruleForm));
      isPower(that, 'AdviserGeneralReason_edit', 'AdviserGeneralReason_edit')
      isPower(that, 'AdviserGeneralReason_add', 'AdviserGeneralReason_add')
      isPower(that, 'AdviserGeneralReason_delete', 'AdviserGeneralReason_delete')
      Init(that);
    },
    watch: {
      ruleForm() {
        let that = this;
      },
    },
    methods: {
      closeForm() {
        let that = this
        let random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;
        that.$set(that.ruleForm, "parentId", null)
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormInit));

        try {
          that.$refs['ruleForm'].resetFields()
          that.dialogVisible = false
        } catch (err) {}
      },
      onFilterText() {
        this.filterText = "";
      },
      filterNode(value, data) {
        if (!value) return true;
        return data.name.indexOf(value) !== -1;
      },
      onAdd() //新增
      {
        let that = this;
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormInit));
        let random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;
        that.isTreeChildren = that.currentId ? true : false
        that.ruleForm.parentId = that.currentId
        that.dialogVisible = true;
        that.dialogTitle = "新增配置";
        that.isDetail = false;
        try {
          that.$refs['ruleForm'].resetFields()
        } catch (err) {}
      },
      onEdit(data) //编辑
      {
        let that = this
        that.isDetail = true
        that.currentEdit = data
        that.dialogTitle = '编辑配置'
        that.ruleForm.name = data.name
        that.ruleForm.score = data.score
        that.ruleForm.priority = data.priority
        that.ruleForm.parentId = data.parentId
        that.ruleForm.description = data.description
        that.isTreeChildren = that.currentId ? true : false
        that.dialogVisible = true
      },
      onDelete(Id) //删除
      {
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, `/api/admin/advisergeneralreason/${Id}`, null, function(r) {
              that.fullscreenLoading = false;
              Init(that);
            });
          }
        })
      },
      onReset() //重置
      {
        let that = this;
        let random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;
        if (!that.isDetail) {
          that.isTreeChildren = that.currentId ? true : false
          that.$refs['ruleForm'].resetFields()
        } else {
          that.isTreeChildren = that.currentId ? true : false
          that.ruleForm.name = that.currentEdit.name
          that.ruleForm.score = that.currentEdit.score
          that.ruleForm.priority = that.currentEdit.priority
          that.ruleForm.parentId = that.currentEdit.parentId
          that.ruleForm.description = that.currentEdit.description
        }
      },
      handleNodeClick(data) {
        let that = this;
        that.item = data.children;
        that.currentTitle = data.title;
        that.currentId = data.id;
      },
      renderContent(h, {
        node,
        data,
        store
      }) {
        return ( < span class = "custom-tree-node" >
          <
          span style = "font-size:14px;" > {
            node.label
          } < /span> </span > );
      },
      onPriorityChange() {
        let that = this;
        var value = setInt(that.ruleForm.priority);
        that.ruleForm.priority = value;
      },
      getValue(value) {
        let that = this;
        that.isTreeChildren = value ? true : false;
        that.ruleForm.parentId = value;
      },
      onSave() //保存（新增和编辑）
      {
        let that = this
        if(that.ruleForm.priority==null || that.ruleForm.priority == "")
        {
        	that.ruleForm.priority = 0
        }
        if (!that.isDetail) {
          that.$refs['ruleForm'].validate(valid => {
            if (valid) {
              let url = '/api/admin/advisergeneralreason';
              let data = JSON.parse(JSON.stringify(that.ruleForm));
              if (!data.priority) {
                data.priority = 0;
              }
              if (!data.score) {
                data.score = 0;
              }
              that.fullscreenLoading = true;
              ajaxPost(that, url, data, function(res) {
                that.fullscreenLoading = false;
                that.closeForm();
                Init(that);
              });
            } else {
              return false;
            }
          })
        } else {
          that.$refs['ruleForm'].validate(valid => {
            if (valid) {
              let url = `/api/admin/advisergeneralreason/${that.currentEdit.id}`;
              let data = JSON.parse(JSON.stringify(that.ruleForm));
              data.reasonId = that.currentEdit.id;
              that.fullscreenLoading = true;
              if (data && !data.priority) {
                data.priority = 0;
              }
              if (data && !data.score) {
                data.score = 0;
              }
              ajaxPut(that, url, data, function(res) {
                that.fullscreenLoading = false;
                that.closeForm();
                Init(that);
              })
            } else {
              return false
            }
          })
        }
      },
      onSelect(p, d) {
        if (p.Value == 3 || p.Value == 2 || p.Value == 1) {
          if (d.indexOf(p.Value) < 0) {
            d.push(p.Value);
          }
        }
      },
      onBack() {
        let that = this;
        back(that);
      },
    },
    watch: {
      currentTitle(val) {
        let that = this;
        var titles = val.split('/');
        that.currentNav = ["班主任常规配置"];
        for (var i in titles) {
          if (titles[i] != "班主任常规配置") {
            that.currentNav.push(titles[i]);
          }
        }
      },
      filterText(val) {
        this.$refs.tree.filter(val);
      }
    },
    mounted() {

    },
  };

  function GetItem(that, val) {
    if (val.length !== 0) {
      val.forEach((item) => {
        if (item.id == that.currentId) {
          that.item = item.children;
          that.currentTitle = item.title;
        } else if (item.children.length >= 1) {
          GetItem(that, item.children);
        }
      });

    }
  }

  function Init(that) {
    that.fullscreenLoading = true;
    that.data = [];
    that.selectData = [];
    ajaxGet(that, "/api/admin/advisergeneralreason/treelist", null, function(r) {
      that.fullscreenLoading = false;
      //that.data = r;
      var tree = {
        id: null,
        value: null,
        name: "班主任常规配置",
        title: "班主任常规配置",
        children: r,
      };
      /*用于新增、编辑时的选择，下拉框只能选择第一级*/
      var newTree = JSON.parse(JSON.stringify(tree));
      for (var j = 0; j < newTree.children.length; j++) {
        newTree.children[j].children = [];
      }
      that.selectData.push(newTree);
      that.data.push(tree);
      GetItem(that, that.data);
    });
  }
</script>

<style scoped="scoped">
  .divTreeCont {
    border: 1px solid #DDDDDD;
    padding: 15px;
    border-radius: 4px;
  }

  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }

  .spIcon {
    width: 5px;
    height: 20px;
    border-radius: 5px;
    background-image: linear-gradient(#4545FF, #2D8EFF);
  }

  .spTitle {
    float: left;
    margin-left: 20px;
    color: #323E56;
    letter-spacing: 2px;
    font-size: 16px;
    margin-top: -3px;
  }
</style>
