<!--后台网站框架-->
<template>
  <div class="tmpl_IndexPage">
    <div class="wrapper">
      <comHeader :userName="userName"></comHeader>
    </div>
    <!--
    <keep-alive>
      <router-view v-wechat-title="$route.meta.title"/>
    </keep-alive>
!-->
    <router-view v-wechat-title="$route.meta.title" v-if="ifRouterAlive"/>
    <div id="toTop" class="VerticalMid">
      <el-tooltip class="item" effect="dark" content="返回顶部" placement="top">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#fax-font-top-o"></use>
        </svg>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
  import '@/assets/css/Student.css';
  import comHeader from '@/components/Student/Header.vue';

  export default {
    components: {
      comHeader
    },
    // 用于刷新子页面方法
    provide(){
      return{
        reload:this.reload
      }
    },
    data() {
      return {
        activeIndex: '1',
        userName: null,
        ifRouterAlive:true,
      };
    },
    created() {
      let that = this;
      IsToken(that);
      that.userName = JSON.parse(that.$GetKey(that.$cacheStudent)).name + " / " + JSON.parse(that.$GetKey(that.$cacheStudent)).studentNo;
    },
    methods: {
      /**
       * @description 刷新子页面方法
       */
      reload(){
        let that=this;
        that.ifRouterAlive=false;
        that.$nextTick(()=>{
          that.ifRouterAlive=true;
        })
      },
      handleSelect(key, keyPath) {

      }
    },
    mounted() {
      let that=this;
      $(window).scroll(function() {
        if ($(this).scrollTop() != 0) {
          $("#toTop").fadeIn();
        } else {
          $("#toTop").fadeOut();
        }
      });

      $("#toTop").click(function() {
        $("body,html").animate({
          scrollTop: 0,
        }, 800);
      });


      // 实时更新token
      onRefreshToken(this);
    },
  };



</script>

<style scoped="scoped">
  #toTop {
    position: fixed;
    right: 10px;
    bottom: 10px;
    z-index: 99;
    display: none;
    padding: 2px 8px;
    cursor: pointer;
    background-color: #F7F7F7;
    color: #444444;
    border: 1px solid #DDDDDD;
    font-size: 26px;
    border-radius: 5px;
  }

  #toTop:hover {
    background-color: #007BFF;
    color: #FFFFFF;
    border-color: #007BFF;
  }
</style>
