<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            奖学金批次详情
          </h3>
        </div>
       <div class="card-body">
         <div class="row">
           <div class="col-lg-3 colInfo">
             <label>批次名称</label>
             <div class="colFonts">2021年</div>
           </div>
           <div class="col-lg-3 colInfo">
             <label>批次时间</label>
             <div class="colFonts">2012-01-01 至 2012-12-12</div>
           </div>
           <div class="col-lg-3 colInfo">
             <label>批次创建时间</label>
             <div class="colFonts">2011-12-21</div>
           </div>
           <div class="col-lg-3 colInfo">
             <label>发起人</label>
             <div class="colFonts">张老师</div>
           </div>
         </div>
       </div>
      </el-card>


      <!-- 配置单 -->
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            奖学金评比要求
          </h3>
        </div>

        <div class="card-body p-0" v-if="ruleForm.configs!=null && ruleForm.configs.length!=0">
          <el-table :data="ruleForm.configs" stripe class="elTableData">
            <el-table-column prop="configName" label="奖学金名称" class="elColumn" width="150"></el-table-column>
            <el-table-column label="评比要求" class="elColumn">
              <template slot-scope="scope">
                &nbsp;
                <span class="badge badge-success">
                  {{'名次：'+((scope.row.ratio)*100)+'%'}}
                </span>
                &nbsp;
                <span class="badge badge-success">
                  {{'操行评定：优秀'}}
                </span>
                &nbsp;
                <span class="badge badge-success">
                  {{'旷课情况：无旷课'}}
                </span>
                &nbsp;
                <span class="badge badge-success">
                  {{'各科成绩：90分以上'}}
                </span>
                &nbsp;
                <span class="badge badge-success">
                  {{'考查课成绩：优'}}
                </span>
                &nbsp;
                <span class="badge badge-success">
                  {{'体育成绩：80分以上'}}
                </span>

              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="card-body " v-else>
          <el-empty :image-size="200" description="暂无配置" name="3"></el-empty>
        </div>
      </el-card>


      <div class="row" style="margin-top: 10px;">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack">返回</el-button>
          <el-button icon="el-icon-refresh" @click="onReset">重 置</el-button>
          <el-button type="primary" icon="el-icon-circle-check" @click="onSubmit">提交</el-button>
        </div>
      </div>
    </el-form>

  </div>

</template>

<script>
  import '@/assets/css/Admin.css';
  export default {
    components: {

    },
    data() {
      return {
        // 加载动画
        fullscreenLoading: false,

        // 表单对象
        ruleForm: {
          starId: null,
          name: '', // 综合之星名称
          startDate: '', // 开始时间
          endDate: '', // 结束时间
          range: null, // 评比范围
          icon: '', // 图标
          configs: [
            {configName:'一等奖'}
          ], //综合配置
          isCalculation: false,
          // 时间范围
          arrTimeRange: [],
          // 校历选择的值
          cycleVal: null,
          // 学期
          semesterVal: '',
          monthVal: '',
        },
      };
    },
    created() {
      let that = this;
      that.frameFromReset = JSON.parse(JSON.stringify(that.frameFrom));



      let dataID = that.$route.params.id;
      if (dataID != null && typeof(dataID) != "undefined" && dataID != "") {
        that.ruleForm.starId = dataID;
      }



    },
    methods: {
      /**
       * @description 关闭弹出框
       */
      onHandleClose() {
        let that = this;
        // 重置
        that.frameFrom = JSON.parse(JSON.stringify(that.frameFromReset));
        that.dialogVisible = false;
      },

      /**
       * @description 删除
       * @param {Object} _valID ID
       */
      onDelete(_valID) {
      },

      /**
       * @description 提交事件
       */
      onSubmit() {


      },


      /**
       * @description 返回
       */
      onBack() {
        back(this);
      },




      /**
       * @description 弹出框配置提交事件
       */
      onClickConfig() {

      },


    }
  };


</script>

<style scoped="scoped">
  .icoImageBox {
    width: 100px;
    height: 100px;
    margin: 5px auto;
    border: 1px dotted #DDDDDD;
    border-radius: 10px;
    padding: 5px;
    cursor: pointer;
  }

  .actIcon {
    border: 1px solid #007bff;
  }


  .divCheckedBox {
    width: 100%;
  }

  .divCheckedBox .spChecked {
    padding-right: 30px;
  }

  .divErrCont {
    font-size: 0.75rem;
    margin-top: -20px;
    display: block;
    width: 100%;
    color: #F56C6C;
  }
</style>
