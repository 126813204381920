<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">


    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          未打卡名单
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item" style="margin-right: 5px;">
              <el-button type="success" icon="el-icon-check" @click="onBatchSign()">批量打卡</el-button>
            </li>
            <li class="nav-item" >
              <comSearch :ColNumber="2" :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null">
              </comSearch>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table :data="itemData" stripe class="elTableData" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" :selectable="selectable"></el-table-column>
          <el-table-column label="考勤日期" class="elColumn" width="120" >
            <template slot-scope="scope">
              {{scope.row.attendanceDate | dateformat('YYYY-MM-DD')}}
            </template>
          </el-table-column>
          <el-table-column prop="studentName" label="姓名" min-width="140" class="elColumn"></el-table-column>
          <el-table-column prop="studentNo" label="学号"  min-width="140"  class="elColumn"></el-table-column>
          <el-table-column prop="gender" width="80"  label="性别" class="elColumn"></el-table-column>
          <el-table-column prop="class" label="班级"  min-width="140"  class="elColumn"></el-table-column>
          <el-table-column prop="dormitory" label="床位"  min-width="180"  class="elColumn"></el-table-column>
          <el-table-column prop="leaveId" label="请假标记" class="elColumn"  min-width="120" ></el-table-column>
          <el-table-column label="操作" width="120" fixed="right">
            <template slot-scope="scope">
              <operationBtn :btnName="'打卡'" :btnType="'success'" ref="sign" @click.native="onSign(scope.row.memberId)" ></operationBtn>
              <!-- <operationBtn :btnName="'删除'" ref="delete" @click.native="onDelete(scope.row.memberId)" ></operationBtn> -->
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
    <div class="row">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import comSearch from '@/components/search.vue';
  export default {
    components: {
      comPage,
      operationBtn,
      comSearch
    },
    data() {
      return {
        attendanceId: null,
        // 加载动画
        fullscreenLoading: false,
        // 列表数据
        itemData: null,
        SearchList: null,
        isPower: {
          admin_notclockedin_sign: false,
          admin_notclockedin_memberdelete: false,
        },
        page: null, //分页查询接口返回的结果
        pageBtnList: null, //分页左侧按钮集合
        paging: { //分页数据
          pageLength: 0, // 总共页数
          Size: 10, // 当前请求数量
          Index: 1 //当前在第几页
        },
        searchDate: null,
        multipleSelection: [],
      };
    },
    methods: {
      // 返回
      onBack() {
        let that = this;
        that.$router.push({
          path: "/Admin/DormitoryAttn/Index",
          query: {
            currentIndex:2,
          }
        });
      },
      // 全选
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      pagingClick(type, val) {
        let that = this;
        let attendanceId = that.attendanceId;
        let url = "/Admin/Statistics/PunchInDetails?attendanceId="+ attendanceId +'&BuildingId='+that.BuildingId+'&date='+that.searchDate;
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "IsAttend",
            "Student",
            "Time",
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "IsAttend",
            "Student",
            "Time",
          );
        }
      },
      // 删除
      onDelete(_dataID) {
        let that = this;
        confirmDelete(that, null, function(res) {
      		if(res == true) {
      			that.fullscreenLoading = true;
      			ajaxDelete(that,"/api/attendance/member/" + _dataID, null, function(r) {
      				that.fullscreenLoading = false;
      				pagingPage(that);
      			});
      		}
      	})
      },
      // 设置禁止选中
      // 这个方法你有多少条数据就会执行多少次
      selectable(row, index){
        // row是遍历的每一条数据，index是下标
        // 设置第一个为不可选中
        if (!regNull(row.attendDate)) {
            return false
        } else {
            return true
        }
      },
      // 批量手动考勤
      onBatchSign() {
        let that = this;
        let list = that.multipleSelection.map(x => {
          return x.memberId;
        })
        that.fullscreenLoading = true;
        ajaxPost(that,"/api/attendance/sign", list, function(r) {
          that.fullscreenLoading = false;
          pagingPage(that);
        });
      },
      // 手动考勤
      onSign(_dataID) {
        let that = this;
        that.fullscreenLoading = true;
        ajaxPost(that,"/api/attendance/sign", [_dataID], function(r) {
          that.fullscreenLoading = false;
          pagingPage(that);
        });
      },
      onSearch(params) {
        let that = this;
        let data = {};
        let searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = Join(element.data);
          }
        });
        data.attendanceId = that.attendanceId;
        data.BuildingId = that.BuildingId;
        data.date=that.searchDate;
        data.PageNumer = 1;
        routerPath(that, "/Admin/Statistics/PunchInDetails", data, null, "PageSize");
      },
    },
    watch: {
      $route() {
        let that = this;
        pagingPage(that);
      }
    },
    created() {
      let that = this;
      that.searchDate=that.$route.query.date;
      pagingPage(that);
    }
  };

  function pagingPage(that) {
    paging(
      that,
      function(r) {
        let url = "/api/attendance/membersearch";
        if (regNull(that.attendanceId)) {
          that.attendanceId = that.$route.query.attendanceId;
          that.BuildingId = that.$route.query.BuildingId;
        }

        that.SearchList = [{
          type: "input",
          Name: "学生",
          data: r.Student,
          holder: "请输入姓名或学号",
          zhi: "Student"
        },];
        let data = {
          AttendanceId: that.attendanceId,
          PageNumer: r.PageNumer,
          PageSize: r.PageSize,
          IsAttend: false,
          Student: r.Student,
          BuildingId:that.BuildingId,
        };
        that.paging.Index = r.PageNumer;
        that.paging.Size = r.PageSize;
        that.fullscreenLoading = true;
        ajaxGet(that, url, data, function(r) {
          that.page = r;
          that.itemData = r.items;
          that.fullscreenLoading = false;
        });
      },
      "IsAttend",
      "Student",
      "Time"
    );
  }

</script>

<style scoped="scoped">

.navTabsCont .nav-link {
    font-size: 12px;
  }

  .navToolsCont {
    margin-top: 1px;
  }


</style>

