<!--
  新增学期总结
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{Title}}
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24">
            <el-col :lg="8" :sm="12">
              <el-form-item prop="semesterId" label="学期" label-width="90px">
                <el-select v-model="ruleForm.semesterId" filterable placeholder="请选择学期" style="width: 100%;"
                  :disabled="Title=='编辑'">
                  <el-option v-for="item in optSemester" :key="item.semesterId" :label="item.name"
                    :value="item.semesterId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="8" :sm="12">
              <el-form-item prop="classId" label="班级" label-width="90px">
                <el-select v-model="ruleForm.classId" filterable placeholder="请选择班级" style="width: 100%;"
                  :disabled="Title=='编辑'">
                  <el-option v-for="item in optClass" :key="item.classId" :label="item.alias" :value="item.classId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item prop="content" label="总结内容" label-width="90px">
                <quill-editor height="300px" v-model="ruleForm.content" ref="text" class="myQuillEditor"
                  :options="editorOption" />
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>

      <div class="row" style="margin-top: 10px;">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
          <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
          <el-button type="primary" icon="el-icon-circle-check" @click="onSave()">提交</el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import {
    quillEditor
  } from 'vue-quill-editor';
  import * as Quill from 'quill' //引入编辑器
  var fonts = ['SimSun', 'SimHei', 'Microsoft-YaHei', 'KaiTi', 'FangSong', 'Arial', 'Times-New-Roman', 'sans-serif'];
  var Font = Quill.import('formats/font');
  Font.whitelist = fonts; //将字体加入到白名单
  Quill.register(Font, true);


  export default {
    components: {
      quillEditor
    },
    data() {
      return {
        fullscreenLoading: false,
        editorOption: {
          placeholder: '请在此输入内容',
          modules: {
            toolbar: [
              [{
                'font': fonts
              }], //字体，把上面定义的字体数组放进来
              ['bold', 'italic', 'underline',
                'strike'
              ], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
              ['blockquote', 'code-block'], // 引用  代码块-----['blockquote', 'code-block']
              [{
                header: 1
              }, {
                header: 2
              }], // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
              [{
                list: 'ordered'
              }, {
                list: 'bullet'
              }], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
              [{
                script: 'sub'
              }, {
                script: 'super'
              }], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
              [{
                indent: '-1'
              }, {
                indent: '+1'
              }], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
              [{
                'direction': 'rtl'
              }], // 文本方向-----[{'direction': 'rtl'}]
              [{
                size: ['small', false, 'large', 'huge']
              }], // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
              [{
                header: [1, 2, 3, 4, 5, 6, false]
              }], // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
              [{
                color: []
              }, {
                background: []
              }], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
              [{
                align: []
              }], // 对齐方式-----[{ align: [] }]
              ['clean'], // 清除文本格式-----['clean']
              ['image'], // 链接、图片、视频-----['link', 'image', 'video']
            ]
          }
        },
        optClass: [],
        optSemester: [],
        optType: [],
        ruleForm: {
          summaryId: null,
          semesterId: null,
          classId: null,
          summaryType: null,
          content: null,
        },
        ruleFormReset: {}, //重置
        Title: "新增",
        rules: {
          semesterId: [{
            required: true,
            message: '学期不能为空',
            trigger: 'change'
          }, ],
          classId: [{
            required: true,
            message: '班级不能为空',
            trigger: 'change'
          }, ],
          summaryType: [{
            required: true,
            message: '总结类型不能为空',
            trigger: 'change'
          }, ],
          content: [{
            required: true,
            message: '总结内容不能为空',
            trigger: 'change'
          }, ],
        },

        TeacherInfo: {},
      };
    },
    created() {
      let that = this;
      that.TeacherInfo = onGetNowUser(that);
      let id = that.$route.params.Id;
      that.ruleForm.summaryId = id;
      that.fullscreenLoading = true;
      ajaxGet(that, "/api/admin/Semester", null, function(resultSemester) {
        ajaxGet(that, "/api/admin/adviserworksummary/type", null, function(resultType) {

          let resultClass = that.TeacherInfo.isTeacher ? that.TeacherInfo.manageClass : that.TeacherInfo
            .allClass;

          that.fullscreenLoading = false;
          that.optSemester = resultSemester;
          that.optClass = resultClass;
          that.optType = resultType;
          if (id != null) {
            that.Title = '编辑';
            that.fullscreenLoading = true;
            ajaxGet(that, "/api/admin/adviserworksummary/" + id, null, function(res) {
              that.fullscreenLoading = false;
              that.ruleForm = res;
              that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
            })
          } else {
            for (var item of resultSemester) {
              if (item.isCurrent == true) {
                that.ruleForm.semesterId = item.semesterId;
              }
            }
            that.ruleForm.classId = regNullArray(resultClass) ? null : resultClass[0].classId;
            that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
          }
        });
      });
    },
    methods: {
      onReset() {
        let that = this;
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      onBack() {
        let that = this;
        back(that);
      },
      onSave() {
        let that = this;
        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            that.ruleForm.content = that.ruleForm.content.replace(new RegExp(/( )/g), '&nbsp;');
            if (that.ruleForm.summaryId == null) {
              that.fullscreenLoading = true;
              var data = JSON.parse(JSON.stringify(that.ruleForm));
              data.summaryType = parseInt(data.summaryType);
              ajaxPost(that, "/api/admin/adviserworksummary", data, function(r) {
                that.fullscreenLoading = false;
                that.onBack();
              });
            } else {
              that.fullscreenLoading = true;
              var data = JSON.parse(JSON.stringify(that.ruleForm));
              for (var i in that.optType) {
                if (data.summaryType == that.optType[i].Text) {
                  data.summaryType = that.optType[i].Value;
                }
              }
              data.summaryType = parseInt(data.summaryType);
              ajaxPut(that, "/api/admin/adviserworksummary/" + data.summaryId, data, function(r) {
                that.fullscreenLoading = false;
                that.onBack();
              });
            }
          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              isError[0].querySelector('input').focus();
            }, 1)
            return false;
          }
        });
      }
    },
    watch: {

    }
  };

</script>

<style scoped="scoped">

</style>
<style>
  .ql-toolbar.ql-snow {
    line-height: 20px;
  }

  .ql-snow .ql-tooltip[data-mode=link]::before {
    content: "请输入链接地址:";
  }

  .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    border-right: 0px;
    content: '保存';
    padding-right: 0px;
  }

  .ql-snow .ql-tooltip[data-mode=video]::before {
    content: "请输入视频地址:";
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: '14px';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=small]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
    content: '10px';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=large]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
    content: '18px';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=huge]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
    content: '32px';
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=SimSun]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=SimSun]::before {
    content: "宋体";
    font-family: "SimSun";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=SimHei]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=SimHei]::before {
    content: "黑体";
    font-family: "SimHei";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=Microsoft-YaHei]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=Microsoft-YaHei]::before {
    content: "微软雅黑";
    font-family: "Microsoft YaHei";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=KaiTi]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=KaiTi]::before {
    content: "楷体";
    font-family: "KaiTi";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=FangSong]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=FangSong]::before {
    content: "仿宋";
    font-family: "FangSong";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=Arial]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=Arial]::before {
    content: "Arial";
    font-family: "Arial";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=Times-New-Roman]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=Times-New-Roman]::before {
    content: "Times New Roman";
    font-family: "Times New Roman";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=sans-serif]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=sans-serif]::before {
    content: "sans-serif";
    font-family: "sans-serif";
  }

  .ql-font-SimSun {
    font-family: "SimSun";
  }

  .ql-font-SimHei {
    font-family: "SimHei";
  }

  .ql-font-Microsoft-YaHei {
    font-family: "Microsoft YaHei";
  }

  .ql-font-KaiTi {
    font-family: "KaiTi";
  }

  .ql-font-FangSong {
    font-family: "FangSong";
  }

  .ql-font-Arial {
    font-family: "Arial";
  }

  .ql-font-Times-New-Roman {
    font-family: "Times New Roman";
  }

  .ql-font-sans-serif {
    font-family: "sans-serif";
  }

</style>

