<!--
  新增家校活动记录
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="ruleForm" ref="ruleForm" label-width="120px">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            查看
          </h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-3 colInfo">
              <label>学期</label>
              <div class="colFonts">{{ruleForm.semester | flNoValue}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>班级</label>
              <div class="colFonts">{{ruleForm.class | flNoValue}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>联系日期</label>
              <div class="colFonts">{{ruleForm.date | dateformat('YYYY-MM-DD')}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>人数</label>
              <div class="colFonts">{{ruleForm.numberOfPeople | flNoValue}}</div>
            </div>
           
            <div class="col-lg-3 colInfo">
              <label>组织者</label>
              <div class="colFonts">{{ruleForm.organizer | flNoValue}}</div>
            </div>
            <div class="col-lg-6 colInfo">
              <label>地点</label>
              <div class="colFonts">{{ruleForm.place | flNoValue}}</div>
            </div>
          </div>
        </div>
      </el-card>

      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            目的
          </h3>
        </div>
        <div class="card-body">
          <div class="colRemarksCont" v-html="ruleForm.objective"></div>
        </div>
      </el-card>

      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            形式
          </h3>
        </div>
        <div class="card-body">
          <div class="colRemarksCont" v-html="ruleForm.form"></div>
        </div>
      </el-card>


      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            过程
          </h3>
        </div>
        <div class="card-body">
          <div class="colRemarksCont" v-html="ruleForm.process"></div>
        </div>
      </el-card>


      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            效果
          </h3>
        </div>
        <div class="card-body">
          <div class="colRemarksCont" v-html="ruleForm.effect"></div>
        </div>
      </el-card>



			<div class="row">
					<div class="col-12">
						<el-button  icon="el-icon-back" @click="onBack()">返回</el-button>

					</div>
			</div>
    </el-form>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';


  export default {
    components: {

    },
    data() {
      return {
				fullscreenLoading:false,
				ruleForm:{
          activityId:null,
          semesterId:null,
          classId:null,
          date: null,//时间
          place: null,//地点
          numberOfPeople: null,//人数
          objective: null,//目的
          form: null,//形式
          process: null,//过程
          effect: null,//效果
          organizer: null//组织者
				},
      };
    },
		created()
    {
			let that = this;
			let id = that.$route.params.Id;
      that.ruleForm.activityId=id;
      that.fullscreenLoading=true;
      ajaxGet(that,"/api/admin/adviserparentactivityrecord/"+id,null,function(res){
          that.fullscreenLoading=false;
          that.ruleForm=res;
      })
      that.fullscreenLoading=true;
		},

		mounted() {
    },
    methods: {
			onBack()
			{
				let that = this;
				back(that);
			},
		},
		watch: {

		},
    filters:{
          flNoValue(obj){
            if (obj == null || typeof(obj) == "undefined" || obj == ''){
              return '暂无数据';
            }
            return obj;
          }
        },
  };






</script>

<style scoped="scoped">

</style>
