import { render, staticRenderFns } from "./Delegate.vue?vue&type=template&id=6882ad80&scoped=true"
import script from "./Delegate.vue?vue&type=script&lang=js"
export * from "./Delegate.vue?vue&type=script&lang=js"
import style0 from "./Delegate.vue?vue&type=style&index=0&id=6882ad80&prod&lang=less&scoped=true"
import style1 from "./Delegate.vue?vue&type=style&index=1&id=6882ad80&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6882ad80",
  null
  
)

export default component.exports