<!--

-->
<template>
  <div class="SubWebPageStudent DetailsPro" v-loading.fullscreen.lock="fullscreenLoading">

    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          社团详情
        </h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-3 text-center">
            <el-carousel height="150px" class="elCarouselBox">
              <el-carousel-item v-for="(item,index) in infoData.imageList" :key="index">
                <img :src="item" alt="" width="100%">
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="col-lg-8">
            <div class="infoList clearfix">
              <span class="spName">{{infoData.name}}</span>
            </div>
            <div class="infoList clearfix">
              <span class="spFonts">
                <span class="spLabel">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#fax-user"></use>
                  </svg>
                  &nbsp;
                  {{infoData.managerTitle}}
                </span>
                {{infoData.managerName}}
              </span>
            </div>
            <div class="infoList clearfix">
              <span class="spFonts">
                <span class="spLabel">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#fax-cube-users-alt"></use>
                  </svg>
                  &nbsp;
                  管理人员
                </span>
                &nbsp;
                <span class="spNum">{{infoData.managerNum}}</span>
              </span>
              <span class="spFonts">
                <span class="spLabel">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#fax-user-crowd-alt"></use>
                  </svg>
                  &nbsp;
                  普通成员
                </span>
                &nbsp;
                <span class="spNum">{{infoData.ordinaryNum}}</span>
              </span>
              <span class="spFonts">
                <span class="spLabel">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#fax-child-alt"></use>
                  </svg>
                  &nbsp;
                  已举办活动
                </span>
                &nbsp;
                <span class="spNum">{{activityData.length}}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </el-card>



    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          社团简介
        </h3>
      </div>
      <div class="card-body ">
        <div class="colRemarksCont" v-html="onGetRemarks()">
        </div>
      </div>
    </el-card>

    <el-card class="box-card" v-if="activityData!=null && activityData.length!=0">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          最新社团活动
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <el-button size="mini" type="info" icon="el-icon-d-arrow-right"
                @click="onGetMore">浏览更多活动</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body bodyAwardsCont ">
        <el-row :gutter="20">
          <el-col :span="6" v-for="(item,index) in activityData" :key="index">
            <div class="stuAwardsCont" @click="onDetailActivities(item.activityId)">
              <div class="awardsName">
                {{item.name}}
              </div>
              <div class="stuFileCont VerticalMid">
                <el-image :src="onGetAttachment(item.attachment)">
                  <div slot="error" class="image-slot errorBox">
                    <div class="spIcon">
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#fax-image-split"></use>
                      </svg>
                    </div>
                    <div class="spError">
                      加载失败
                    </div>
                  </div>
                </el-image>
                <div class="divTimeBox">
                  {{onDateFormat(item.activityDate)}}
                </div>
              </div>

            </div>
          </el-col>
        </el-row>
      </div>
    </el-card>


    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          社团成员
        </h3>
      </div>
      <div class="card-body p-0">
        <el-table :data="infoData.member" stripe class="elTableData">
          <el-table-column type="index" label="序号" width="100"></el-table-column>
          <el-table-column prop="studentName" label="姓名" min-width="120"></el-table-column>
          <el-table-column prop="studentNo" label="学号" min-width="100"></el-table-column>
          <el-table-column prop="className" label="班级" min-width="120"></el-table-column>
          <el-table-column label="职位" min-width="100">
            <template slot-scope="scope">
              <span class="spPosition" :class="{'color-danger':scope.row.isManager}">
                {{scope.row.position}}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180" fixed="right" v-if="isPower.isDirector">
            <template slot-scope="scope">
              <operationBtn :btnName="'调职'" :btnType="'warning'" v-if="!scope.row.isDirector"
                @click.native="onTransfer(scope.row.memberId,scope.row.studentId,scope.row.position)"></operationBtn>
              <operationBtn :btnName="'移除'" :btnType="'danger'" v-if="!scope.row.isDirector"
                @click.native="onDelete(scope.row.clubId,scope.row.memberId)"></operationBtn>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180" fixed="right" v-else-if="isPower.isManager">
            <template slot-scope="scope">
              <operationBtn :btnName="'调职'" :btnType="'warning'" v-if="!scope.row.isDirector && !scope.row.isManager"
                @click.native="onTransfer(scope.row.memberId,scope.row.studentId,scope.row.position)"></operationBtn>
              <operationBtn :btnName="'移除'" :btnType="'danger'" v-if="!scope.row.isDirector && !scope.row.isManager"
                @click.native="onDelete(scope.row.clubId,scope.row.memberId)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>



    <!--文档弹出框-->
    <el-dialog title="社团务职调整" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <el-form class="ruleForm" :rules="rules" :model="clubPositionForm" ref="clubPositionForm">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="当前职务">
              <div class="infoFontBox">
                {{clubPositionForm.positionName}}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="职位" prop="positionId">
              <el-select v-model="clubPositionForm.positionId" placeholder="请选择" class="formControl">
                <el-option v-for="item in arrClubPosition" :key="item.positionId" :label="item.name"
                  :value="item.positionId">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">

        <el-button @click="onClose()">取 消</el-button>
        <el-button type="primary" @click="onSaveTransfer()">确 定</el-button>

      </span>
    </el-dialog>


    <div class="row mt-10">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
      </div>
    </div>



  </div>
</template>

<script>
  import '@/assets/css/Student.css';
  import operationBtn from '@/components/operationBtn.vue';
  export default {
    components: {
      operationBtn,
    },
    data() {
      return {
        // 加载动画
        fullscreenLoading: false,

        dialogVisible: false,

        // 社团活动列表
        activityData: [],




        // 社团信息对象
        infoData: {
          clubId: '',
          imageList: [],
          managerNum: 0, // 管理人数
          ordinaryNum: 0, // 普通人数
          managerName: '', // 最高负责人
          managerTitle: '', // 最高负责的职位

        },
        arrClubPosition: [],
        clubPositionName: '',
        clubPositionForm: {
          memberId: '',
          studentId: '',
          positionId: '',
          positionName: '',
          clubId: '',
        },
        rules:{
          positionId:[{
              required: true,
              message: '请选择职务',
              trigger: 'change'
            },],
        },
        clubPositionReset: null,
        isPower: {
          isDirector: false,
          isManager: false,
        }
      };
    },
    methods: {
      onGetMore(){
        let that = this;
        that.$router.push({
          path: '/Student/Club/ClubActivities/Index',
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },







      onDateFormat(_val) {
        return dateFormat(_val, '.', 'YYYY-MM-DD');
      },

      onDetailActivities(_activityId) {
        let that = this;
        that.$router.push({
          path: '/Student/Club/ClubActivities/Details/' + _activityId,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },

      /**
       * @description 编辑社团活动
       */
      onEditActivities(_activityId) {
        let that = this;
        that.$router.push({
          path: '/Student/Club/ClubActivities/Edit/' + that.infoData.clubId + '/' + _activityId,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },

      /**
       * @description 删除社团活动
       * @param {Object} _activityId 社团活动ID
       */
      onDelActivities(_activityId) {
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, "/api/student/clubactivity/" + _activityId, null, function(r) {
              that.fullscreenLoading = false;
              onGetActivity(that);
            });
          }
        })
      },



      onGetAttachment(_val) {
        let that = this;
        if (regNull(_val)) {
          return null;
        } else {
          let attachments = _val.split('|');
          for (let item of attachments) {
            return that.$ServerUrl() + item;
          }
        }
      },


      onBack() {
        // back(this);
        let that=this;
        that.$router.push({
          path: '/Student/Club/ClubMGMT/Index',
          query: {
            url: that.$router.history.current.fullPath
          }
        });

      },

      onSaveTransfer() {
        let that = this;
        that.$refs["clubPositionForm"].validate(valid => {
          if (valid) {
        that.fullscreenLoading = true;
        ajaxPut(that, '/api/student/club/member/' + that.clubPositionForm.memberId, that.clubPositionForm, function(
          res) {
          that.fullscreenLoading = false;
          that.dialogVisible = false;
          that.clubPositionForm = JSON.parse(JSON.stringify(that.clubPositionReset));
          try
          {
          	that.$refs["clubPositionForm"].resetFields();
          } catch{}
          onGetInfo(that);
        });
        }else{
            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              isError[0].querySelector('input').focus();
            }, 1)
            return false;
        }
        })
      },





      onTransfer(_memberId, _studentId, _name) {
        let that = this;
        that.clubPositionForm.memberId = _memberId;
        that.clubPositionForm.studentId = _studentId;
        that.clubPositionForm.positionName = _name;
        that.dialogVisible = true;
      },
      onDelete(_clubId, _studentId) {
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            let data = [_studentId];
            ajaxPut(that, "/api/student/club/quitclub/" + _clubId, data, function(r) {
              that.fullscreenLoading = false;
              onGetInfo(that);
            });
          }
        })
      },

      /**
       * @description 显示社团简介
       * @return {String} 如没有数据返回”暂无数据”
       */
      onGetRemarks() {
        let that = this;
        if (regNull(that.infoData.description)) {
          return '暂无数据';
        } else {
          return that.infoData.description;
        }
      },
      //取消调职
      onClose() {
        let that = this;
        that.clubPositionForm = JSON.parse(JSON.stringify(that.clubPositionReset));
        that.dialogVisible = false
        try
        {
        	that.$refs["clubPositionForm"].resetFields();
        } catch{}
      },
      // 弹出窗关闭
      handleClose(done) {
        let that = this;
        that.clubPositionForm = JSON.parse(JSON.stringify(that.clubPositionReset));
        try
        {
        	that.$refs["clubPositionForm"].resetFields();
        } catch{}
        done();
      }
    },
    created() {
      let that = this;
      let id = that.$route.params.Id;
      if (id != null && typeof(id) != "undefined" && id != "") {
        that.infoData.clubId = id;
        that.clubPositionReset = JSON.parse(JSON.stringify(that.clubPositionForm));
        onGetInfo(that);
      } else {
        back(that);
      }
    }
  };

  function onGetInfo(that) {
    that.fullscreenLoading = true;
    ajaxGet(that, '/api/student/club/' + that.infoData.clubId, null, function(resData) {
      that.fullscreenLoading = false;
      that.infoData = resData;
      // 设置图片
      that.infoData.imageList = [];
      if (!regNull(resData.attachment)) {
        let attachments = resData.attachment.split('|');
        for (let item of attachments) {
          that.infoData.imageList.push(that.$ServerUrl() + item);
        }
      }
      // 人员管理
      that.infoData.managerNum = 0;
      that.infoData.ordinaryNum = 0;
      for (let item of resData.member) {
        if (item.isManager) {
          that.infoData.managerNum++;
        } else {
          that.infoData.ordinaryNum++;
        }
        if (item.isDirector) {
          that.infoData.managerName = item.studentName;
          that.infoData.managerTitle = item.position;
        }
      }
      that.clubPositionForm.clubId = resData.clubId;
      onGetActivity(that);
      onGetPosition(that);
    });
  }



  function onGetActivity(that) {
    that.fullscreenLoading = true;
    let _data = {
      ClubId: that.infoData.clubId,
      PageNumer: 1,
      PageSize: 8,
    }
    ajaxGet(that, '/api/student/clubactivity/search', _data, function(resData) {
      that.fullscreenLoading = false;
      that.activityData = resData.items;
    });
  }

  function onGetPosition(that) {
     that.fullscreenLoading = true;
     ajaxGet(that,'/api/student/club/memberofme/'+that.infoData.clubId,null,function(resData){
       that.fullscreenLoading=false;
       that.isPower=resData;
     });

    ajaxGet(that, '/api/student/club/position/enable', null, function(resData) {
      that.fullscreenLoading = false;
      that.arrClubPosition = resData;
    });
  }
</script>

<style scoped="scoped">
  .errorBox .spIcon {
    font-size: 42px;
    color: #767676;
    text-align: center;
  }

  .errorBox .spError {
    text-align: center;
    font-size: 12px;
    color: #767676;
  }


  .infoFontBox {
    display: block;
    margin-top: 40px;
    padding-left: 15px;
  }

  .elTableData .spPosition {
    color: #767676;
  }

  .elCarouselBox {
    border: 1px solid #EFEFEF;
  }

  .dialogBox {
    padding: 10px 5px;
    height: 140px;
    overflow: hidden;
  }

  .dialogVideoBox {
    padding: 10px 5px;
  }

  .dialogBox .mailbox-attachment-icon {
    font-size: 32px;
    padding: 15px 10px;
  }

  .dialogBox .mailbox-attachment-size>span {
    font-size: 12px;
  }

  .dialogBox .mailbox-attachment-info {
    padding: 7px 10px;
  }

  .bodyAwardsCont .stuAwardsCont {
    position: relative;
    padding: 0px 15px 45px 15px;
    height: 260px;
  }
  .bodyAwardsCont .stuAwardsCont:hover{
    cursor: pointer;
  }

  .bodyAwardsCont .stuAwardsCont:hover .awardsName{
    color: #000;
  }
  .bodyAwardsCont .stuAwardsCont:hover .stuFileCont{
    border-color: #D9ECFF;
  }

  .bodyAwardsCont .stuAwardsCont .awardFooter {
    text-align: center;
    padding-top: 5px;
  }

  .bodyAwardsCont .stuAwardsCont .awardFooter .divBtnBox {}

  .bodyAwardsCont .awardsName {
    padding-top: 10px;
    color: #666;
  }

  .bodyAwardsCont .stuFileCont {
    padding-top: 5px;
    border: 1px solid #EEEEEE;
    height: 150px;
    overflow: hidden;
    width: 100%;
    margin-top: 5px;
    position: relative;
  }

  .bodyAwardsCont .stuFileCont .divTimeBox {
    position: absolute;
    right: 0;
    bottom: 0;
    background: url(../../../../assets/image/bg_50_opacity.png) repeat;
    color: #FFFFFF;
    font-size: 12px;
    padding: 2px 5px;
  }





  .bodyImgCont .mailbox-attachment-icon {
    min-height: 165px;
  }

  .bodyFileCont .mailbox-attachments li,
  .bodyImgCont .mailbox-attachments li {
    width: 250px;
  }

  .bodyImgCont .mailbox-attachment-icon {
    height: 198px;
  }

  .bodyFileCont .mailbox-attachment-size .spFont,
  .bodyImgCont .mailbox-attachment-size .spFont {
    font-size: 12px;
  }


  .infoList {
    padding-bottom: 17px;
    padding-left: 30px;
  }

  .infoList .spName {
    font-size: 32px;
    color: #212E48;
    padding-right: 30px;
    height: 40px;
  }


  .infoList .spFonts {
    color: #3B475D;
    padding-right: 30px;
    padding-left: 30px;
    border-right: 1px solid #D6D6D6;
  }

  .infoList .spFonts:first-child {
    padding-left: 0;
  }

  .infoList .spFonts:last-child {
    border-right: none;
  }

  .infoList .spMedal {
    padding-right: 15px;
  }

  .infoList .spFonts .faxSize {
    font-size: 18px;
  }

  .infoList .spFonts .icon {
    color: #FFFFFF;
  }

  .infoList .spFonts .spLabel {
    font-size: 14px;
    padding: 2px 5px;
    border-radius: 5px;
    background-color: #B1B1B1;
    color: #FFFFFF;
    margin-right: 10px;
  }

  .infoList .spFonts .spNum {
    padding-left: 10px;
    padding-right: 5px;
    font-family: 'OswaldLight';
    font-size: 16px;
    font-weight: bold;
    color: #DC3545;
  }

  .DetailsPro .colRemarksCont {
    font-size: 14px;
    letter-spacing: 1px;
  }
</style>


<style>
  .clubBtnImport {
    margin-left: 10px;
    display: inline-block;
  }
</style>
