<!--
  学生管理-学生处分
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <search :ColNumber="3" :SearchList="SearchList" :key="random" ref="SearchList" @onSearch="onSearch"
      v-if="SearchList!=null"></search>
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          处分信息
        </h3>
<!--        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <el-button type="success" v-if="isPower.punishrevocation_create==true" icon="el-icon-circle-plus-outline"
                @click="onAdd()">新增</el-button>
            </li>
          </ul>
        </div> -->
      </div>
      <div class="card-body p-0" v-if="item!=null && item.length!=0">
        <el-table stripe :data="item" class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="className" label="班级" min-width="120" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <span>{{scope.row.className}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="studentNo" label="学号" min-width="120">
            <template slot-scope="scope">
              <span>{{scope.row.studentNo}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="studentName" label="学生姓名" min-width="120" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <div v-html="scope.row.studentName"></div>
            </template>
          </el-table-column>
          <el-table-column prop="applyDate" label="撤销申请时间" :formatter="formatter" min-width="130"></el-table-column>
          <el-table-column prop="revokeDate" label="撤销日期" :formatter="formatter" min-width="120"></el-table-column>
          <el-table-column prop="user" label="记录人" min-width="120" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column label="操作" fixed="right" :width="thWidth">
            <template slot-scope="scope">
              <!-- 管理员 -->
              <operationBtn v-if="isPower.punishrevocation_detils == true" :btnName="'查看'"
                @click.native="onDetail(scope.row.revokeId)"></operationBtn>
              <operationBtn v-if="isPower.punishrevocation_delete == true" :btnName="'删除'"
                @click.native="onDelete(scope.row.revokeId)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>

      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
  </div>
</template>
<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';
  export default {
    name: '',
    components: {
      comPage,
      operationBtn,
      search
    },
    data() {
      return {
        fullscreenLoading: false,
        itemData: [],
        dialogTitle: '',
        dialogVisible: false,
        random: 0, //刷新组件
        page: null, //分页查询接口返回的结果
        item: [], //列表数据
        pageBtnList: null, //分页左侧按钮集合
        paging: { //分页数据
          pageLength: 0, // 总共页数
          Size: 10, // 当前请求数量
          Index: 1 //当前在第几页
        },
        SearchList: null, //搜索集合
        isPower: {
          //增删改查 权限管理
          punishrevocation_detils: false,
          punishrevocation_create: false,
          punishrevocation_delete: false,

        },
        url: null, //当前页面路径
        isTeacher: false,
        thWidth: 100,

        TeacherInfo: {},
      };
    },
    created() {
      let that = this;
      isPower(that, "punishrevocation_detils", "punishrevocation_detils");
      isPower(that, "punishrevocation_create", "punishrevocation_create");
      isPower(that, "punishrevocation_delete", "punishrevocation_delete");
      that.TeacherInfo = onGetNowUser(that);

      pagingPage(that);

      that.thWidth = onGetWidth(that, [that.isPower.punishrevocation_detils, that.isPower.punishrevocation_delete]);
    },
    methods: {

      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },

      onAdd() {
        let that = this;
        that.$router.push({
          path: '/Admin/StudentPunishRevocation/Create',
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onDetail(Id) {
        let that = this;
        this.$router.push({
          path: "/Admin/StudentPunishRevocation/Detils/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onDelete(Id) {
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, "/api/admin/punishrevoke/" + Id, null, function(r) {
              that.fullscreenLoading = false;
              pagingPage(that);
            });
          }
        })
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      onSearch(params) {
        let that = this;
        let data = {};
        let searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = Join(element.data);
          }
        });
        data.PageNumer = 1;
        routerPath(that, "/Admin/StudentPunishRevocation/Index", data, null, "PageSize");
      },
      pagingClick(type, val) {
        let that = this;
        let url = '';
        url = "/Admin/StudentPunishRevocation/Index";
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "SemesterId",
            "ClassId",
            "Student",
            "PunishDate",
            "PunishNo",
            "Reason",
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "SemesterId",
            "ClassId",
            "Student",
            "PunishDate",
            "PunishNo",
            "Reason",
          );
        }
      },
      formatter(row, column) {
        let data = row[column.property]
        let dt = new Date(data)
        return dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate()
      },
    },
    watch: {
      $route() {
        let that = this;
        pagingPage(that);
      }
    }
  };

  function pagingPage(that) {
    paging(
      that,
      function(r) {
        let Myurl = "/api/admin/punishrevoke/search";
        that.url = that.$route.path.toLowerCase();
        that.fullscreenLoading = true;
        ajaxGet(that, "/api/admin/Semester", null, function(semester) {

          let resultClass = that.TeacherInfo.isTeacher ? that.TeacherInfo.manageClass : that.TeacherInfo.allClass;

          var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
          that.random += random;

          var ClassId = r.ClassId;
          var classList = [];
          for (var i in resultClass) {
            var it = {
              Title: resultClass[i].alias,
              Value: resultClass[i].classId,
            };
            classList.push(it);
          }


          if (that.TeacherInfo.isTeacher) {
            if (regNull(ClassId)) {
              ClassId = resultClass[0].classId;
            }
          }


          that.SearchList = [{
            type: "select",
            Name: "班级",
            data: ClassId,
            select: classList,
            zhi: "ClassId",
            isClear: !that.TeacherInfo.isTeacher,

          }, {
            type: "input",
            Name: "学生",
            data: r.Student,
            holder: "请输入学号/姓名",
            zhi: "Student"
          }, ];

          let data = {
            PageNumer: r.PageNumer,
            PageSize: r.PageSize,
            PunishNo: r.PunishNo,
            ClassId: ClassId,
            Student: r.Student,
            PunishDate: r.PunishDate,
            Reason: r.Reason,
          };
          that.paging.Index = r.PageNumer;
          that.paging.Size = r.PageSize;
          var optManagerClass = JSON.parse(that.$GetKey(that.$cacheTeacher)).manageClass;
          that.isTeacher = false;
          ajaxGet(that, Myurl, data, function(r1) {

            if (optManagerClass != '') {
              for (var i in optManagerClass) {
                if (data.ClassId == optManagerClass[i].classId) {
                  that.isTeacher = true;
                }
              }
            }
            that.page = r1;
            that.item = r1.items;
            that.fullscreenLoading = false;
            for (var i in that.item) {
              var it = that.item[i];
              it.member = "";
              for (var j in it.members) {
                var member = it.members[j];
                var score = 0;
                for (var m in member.configs) {
                  score += member.configs[m].score;
                }
                if (it.member == "") {
                  it.member = member.student.name;
                } else {
                  it.member += "、" + member.student.name;
                }
              }
            }
          });


        });
      },
      "ClassId",
      "Student",
      "PunishDate",
      "PunishNo",
      "Reason"
    );
  }

</script>

<style scoped="scoped">
</style>

