<!--
 ECHARTS 的 饼图
-->
<template>
  <!-- 250px -->
  <div :id="comID" :style="pieStyle"></div>
</template>

<script>
  // 按需引入
  import 'echarts/lib/component/tooltip';
  import 'echarts/lib/component/legend';

  import * as echarts from 'echarts/lib/echarts';


  // 引入柱状图组件
  require('echarts/lib/chart/radar');
  export default {
    props: {
      // ID
      comID: {
        type: String,
        default: 'myChart_total_radar'
      },
      // 高度
      comHeight: {
        type: Number,
        default: 700
      },
      comColor: {
        type: Array,
        default: () => {
          return [];
        }
      },
      searchData: {
        type: Object,
      },
    },
    data() {
      return {
        // 用于计算高度
        pieStyle: '',
        titleData: [],
        arrData: [],
      };
    },
    watch: {
      searchData() {
        let that = this;
        that.titleData = [];
        that.arrData = [];
        
        ajaxGet(that, '/api/admin/growthclass/chartdata', that.searchData, function(res) {
          res.forEach(item => {
            that.titleData.push({
              name: item.name,
              max: item.ceiling+50
            });
            let total = item.baseScore + item.score;
            if (total > item.ceiling) {
              that.arrData.push(item.ceiling);
            } else {
              that.arrData.push(total);
            }
          })
          
        })
        that.$nextTick(() => {
          setTimeout(function() {
            that.drawLine();
            return false;
          }, 1500);
        });
        that.pieStyle = 'width: 100%; height:' + (that.comHeight * 0.0625) + 'rem;';
      }
    },
    created() {
      let that = this;
      that.titleData = [];
      that.arrData = [];
      
      ajaxGet(that, '/api/admin/growthclass/chartdata', that.searchData, function(res) {
        res.forEach(item => {
          that.titleData.push({
            name: item.name,
            max: item.ceiling+50
          });
          let total = item.baseScore + item.score;
          if (total > item.ceiling) {
            that.arrData.push(item.ceiling);
          } else if (total < 0) {
            that.arrData.push(0);
          } else {
            that.arrData.push(total);
          }
        })
        
      })
      that.$nextTick(() => {
        setTimeout(function() {
          that.drawLine();
          return false;
        }, 1500);
      });
      that.pieStyle = 'width: 100%; height:' + (that.comHeight * 0.0625) + 'rem;';
    },
    mounted() {
      let that = this;
      window.onresize = function() {
        // that.myChart.resize();
        that.pieStyle = 'width: 100%; height:' + (that.comHeight * 0.0625) + 'rem;';
      }
    },
    methods: {
      drawLine() {
        let that = this;

        // 基于准备好的dom，初始化echarts实例
        let myChart = echarts.init(document.getElementById(that.comID));
        // 绘制图表
        myChart.setOption({
          tooltip: {
            trigger: 'axis'
          },
          radar: {
            // shape: 'circle',
            indicator: that.titleData,
            center: ['50%', '50%'],
            radius: '75%',
            axisName: {
              color: '#303333',
              fontSize: 16,
            },
            axisLine: {
              show: false
            },
          },
          series: [{
            tooltip: {
              trigger: 'item'
            },
            type: 'radar',
            color: "#256fbd",
            symbolSize: 0,
            label: {
              show: true,
              formatter: function(params) {
                return params.value;
              }
            },
            data: [{
              value: that.arrData,
              name: '总评分'
            }, ]
          }]


        });
      }
    }
  };
</script>

<style>

</style>
