<!--
  学生管理-学生基础信息
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <!-- 搜索 -->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          搜索
        </h3>
      </div>
      <div class="card-body ">
        <el-row :gutter="20">
          <el-col :lg="6" :sm="12" class="mb-3" v-if="!isTeacher">
            <div class="selTitleBox">
              <span class="spTitle">年级</span>
            </div>
            <div class="selBodyBox">
              <el-select v-model="searchForm.selCollegeVar" filterable clearable placeholder="请选择" class="width_100Pie"
                @change="onChangeCollege" @clear="onClearCollege">
                <el-option v-for="item in optCollege" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :lg="6" :sm="12" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">班级</span>
            </div>
            <div class="selBodyBox">
              <el-select v-model="searchForm.selClassVar" filterable :clearable="!isTeacher" placeholder="请选择"
                class="width_100Pie">
                <el-option v-for="item in arrClass" :key="item.value" :label="item.alias" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :lg="6" :sm="12" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">学生</span>
            </div>
            <div class="selBodyBox">
              <el-input placeholder="请输入学号/姓名" v-model="searchForm.Student" clearable></el-input>
            </div>
          </el-col>

          <el-col :lg="6" :sm="12" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">身份证号</span>
            </div>
            <div class="selBodyBox">
              <el-input placeholder="请输入身份证号" v-model="searchForm.IdCardNo" clearable></el-input>
            </div>
          </el-col>

          <el-col :lg="6" :sm="12" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">入学年份</span>
            </div>
            <div class="selBodyBox">
              <el-input placeholder="请输入入学年份" v-model="searchForm.EntryYear" clearable></el-input>
            </div>
          </el-col>
          <el-col :lg="6" :sm="12" class="mb-3">
            <div class="selTitleBox">
              学籍状态
            </div>
            <div class="selBodyBox">
              <el-select v-model="searchForm.selStatus" filterable placeholder="请选择" class="width_100Pie">
                <el-option v-for="item in arrStatus" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="card-footer text-center">
        <el-button type="primary" class="mr-2" slot="append" icon="el-icon-search" @click.native="onSearch()">查询
        </el-button>
        <el-button slot="append" class="mr-2" icon="el-icon-refresh" @click.native="onResetSearch()">重置</el-button>
      </div>
    </el-card>
    <!-- 导入提示 -->
    <el-alert title="导入失败的学生原因" type="warning" show-icon class="divAlertCont" v-if="errTip!=null && errTip!=''">
      <div Slot="title" class="divTipCot" v-html="errTip"></div>
    </el-alert>


    <!-- 列表-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          学生信息
        </h3>
        <div class="card-tools">
          <ul class="nav nav-pills ml-auto">
<!--            <li class="nav-item ">
              <el-button type="text" @click="onSyncInfo" class="btn btn-info ml-1 mr-1 pl-2 pr-2 btn-mini">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#fax-sync-alt"></use>
                </svg>
                同步
              </el-button>
            </li> -->
            <li class="nav-item" style="margin-right: 5px;">
              <!--导入-->
              <comImport @onImportSuccess="onImportSuccess" :template="'/templates/学生信息导入模版.xlsx'" :path="uploadInfoApi"
                :btnIcon="'el-icon-upload2'" :title="'批量导入学生'" :btnName="'导入学生'" ref="conImportInfoFile"
                v-if="isPower.admin_import_student == true">
              </comImport>
            </li>
            <li class="nav-item" style="margin-right: 5px;">
              <!--头像-->
              <el-button v-if="isPower.prize_photo_import == true" @click="onOptenPhoto()" icon="el-icon-user-solid"
                style="background-color:#3c8dbc;color: white;">
                导入学生照片
              </el-button>
            </li>

            <li class="nav-item">
              <!--导入家长信息-->
              <comImport  v-if="isPower.prize_familyimport_import" @onImportSuccess="onImportSuccess" :title="'导入家长信息'"
                :template="'/Templates/学生家庭信息导入模版.xlsx'" :btnName="'导入家长信息'" :path="'/api/admin/student/familyimport'" >
              </comImport>
            </li>
            
            <li class="nav-item" style="margin-right: 5px;"> 
              <el-button v-if="isPower.student_img_export" @click="studentsImgExport" icon="el-icon-download"
                style="background-color:#17a2b8	;color: white;">
                导出学生照片
              </el-button>
            </li>

            <li class="nav-item" style="margin-right: 5px;">
              <el-button v-if="isPower.student_export == true" @click="onOpenExport()" icon="el-icon-download"
                style="background-color:#17a2b8	;color: white;">
                导出
              </el-button>
            </li>
            <li class="nav-item" style="margin-right: 5px;">
              <el-button v-if="isPower.student_print" @click="onOpenPrint()" icon="el-icon-printer"
                style="background-color:#17a2b8	;color: white;">
                打印学生证
              </el-button>
            </li>
            <li class="nav-item" >
              <el-button v-if="isPower.student_create == true" type="success" icon="el-icon-circle-plus-outline"
                @click="onCreate()">新增</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table :data="itemData" stripe class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="studentNo" label="学号" class="elColumn" min-width="120"></el-table-column>
          <el-table-column prop="name" label="姓名" class="elColumn" min-width="140" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="gender" label="性别" class="elColumn" min-width="80"></el-table-column>
          <el-table-column prop="entryYear" label="入学年份" class="elColumn" min-width="100"></el-table-column>
          <el-table-column prop="college" label="年级" class="elColumn" min-width="160" :show-overflow-tooltip="true">
          </el-table-column>
          <!-- <el-table-column prop="major" label="专业" class="elColumn" min-width="160" :show-overflow-tooltip="true"></el-table-column> -->
          <el-table-column prop="class" label="班级" class="elColumn" min-width="160" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="group" label="门禁组" class="elColumn" min-width="160" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="操作" :width="thWidth" fixed="right">
            <template slot-scope="scope">
              <operationBtn v-if="isPower.student_details == true" :btnName="'查看'" ref="detail"
                @click.native="onDetail(scope.row.studentId)"></operationBtn>
              &nbsp;
              <el-dropdown
                v-if="isPower.student_edit || isPower.student_updatestatus || isPower.student_resetpwd || isPower.student_delete"
                trigger="click" @command="handleCommand($event,scope.row.studentId,scope.row.duMuGroupId)">
                <el-button size="mini">
                  更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="isPower.student_edit" class="color-warning" command="onUpdate">编辑
                  </el-dropdown-item>
                  <el-dropdown-item divided v-if="isPower.student_updatestatus" class="color-warning" command="status">
                    更改学籍
                  </el-dropdown-item>
                  <el-dropdown-item divided v-if="isPower.student_updateClass" class="color-warning" command="class">
                    转班
                  </el-dropdown-item>
                  <el-dropdown-item v-if="isPower.student_resetpwd" class="color-gray" command="reset">初始化密码
                  </el-dropdown-item>
                  <el-dropdown-item v-if="isPower.student_DuMu" class="color-warning" command="Dumu">设置门禁组
                  </el-dropdown-item>
                  <el-dropdown-item divided v-if="isPower.student_delete" class="color-danger" command="delete">删除
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>

      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>

    <!--导出-->
    <el-dialog title="导出" :visible.sync="dialogVisible" width="40%" :before-close="handleClose"
      :close-on-click-modal="false">
      <div class="card-body">
        <el-row :gutter="20">
          <el-col :span="6" v-for="(item,index) in arrCheckFields" :key="index">
            <el-checkbox v-model="item.checked" :disabled="item.disabled">{{item.label}}</el-checkbox>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="row">
          <div class="col-4 text-left">
            <el-checkbox v-model="checkAll" label="全选" border @change="onCheckAll()"></el-checkbox>
          </div>
          <div class="col-8">
            <el-button @click="dialogVisible=false">取 消</el-button>
            <el-button type="primary" @click="onGetExport()">确 定</el-button>
          </div>
        </div>
      </span>
    </el-dialog>

    <!--批量上传学生照片-->
    <el-dialog title="批量上传学生照片" :visible.sync="dialogVisiblePhoto" width="50%" :before-close="handleClose"
      :close-on-click-modal="false" :close-on-press-escape="false" :append-to-body="true" :fullscreen="false">
      <!--弹出框内容-->
      <el-upload ref="import" class="upload-demo" action="" :limit="1" :before-upload="beforeUpload"
        :file-list="filePhotoList" :http-request="onUpdatePhoto" :auto-upload="false">
        <div class="text-left">
          <el-button size="small" type="primary">点击上传文件</el-button>
        </div>
        <div style="">
          <div slot="tip" class="el-upload__tip">只允许图片以学生学号命名,以zip格式的压缩包上传，且不超过{{tipSize}}MB</div>
        </div>
      </el-upload>
      <!--弹出框内容-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisiblePhoto = false">取 消</el-button>
        <el-button type="primary" @click="submitUpload()">确 定</el-button>
      </span>
    </el-dialog>

    <!--更改学生状态-->
    <el-dialog :title="updateStatus.dialogTitle" :visible.sync="dialogVisibleStatus" width="30%"
      :before-close="handleClose" :close-on-click-modal="false" :close-on-press-escape="false" :append-to-body="true"
      :fullscreen="false">
      <div class="row">
        <div class="col-lg-12 colInfo ">
          <label>更换学籍</label>
          <div class="colFonts">
            <el-select v-model="updateStatus.statusVal" placeholder="请选择" class="width_100Pie">
              <el-option v-for="item in updateStatus.option" :key="item.Value" :label="item.Text" :value="item.Value">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleStatus = false">取 消</el-button>
        <el-button type="primary" @click="onUpdateStatus">确 定</el-button>
      </span>
    </el-dialog>


    <el-dialog :title="'转班'" :visible.sync="dialogVisibleClass" width="30%" :before-close="handleClose"
      :close-on-click-modal="false" :close-on-press-escape="false" :append-to-body="true" :fullscreen="false">
      <div class="row">
        <div class="col-lg-12 colInfo ">
          <label>班级</label>
          <div class="colFonts">
            <el-select v-model="updateClass.classId" filterable placeholder="请选择班级" style="width: 100%;"
              @change="handleChangeClass">
              <el-option-group v-for="group in updateClass.option" :key="group.collegeId" :label="group.name">
                <el-option v-for="item in group.classes	" :key="item.classId" :label="item.alias" :value="item.classId">
                </el-option>
              </el-option-group>
            </el-select>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleClass = false">取 消</el-button>
        <el-button type="primary" @click="onUpdateClass">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :title="'设置门禁组'" :visible.sync="dialogVisibleDumu" width="30%" :before-close="handleClose"
      :close-on-click-modal="false" :close-on-press-escape="false" :append-to-body="true" :fullscreen="false">
      <div class="row">
        <div class="col-lg-12 colInfo ">
          <label>门禁组</label>
          <div class="colFonts">
            <el-select v-model="updateGroup.groupId" filterable placeholder="请选择门禁组" style="width: 100%;">
              <el-option v-for="item in updateGroup.option" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleDumu = false">取 消</el-button>
        <el-button type="primary" @click="onUpdateGroup">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="打印学生证预览" :visible.sync="dialogVisiblePrint" width="80%" :close-on-click-modal="false"
      :close-on-press-escape="false" :append-to-body="true" :fullscreen="false" :before-close="handleClosePrint">
      <div class="color-danger">注：请等待校牌全加载完毕后，再点击“打印”按钮</div>
      <div class="webPrintView">
        <div class="webDialogPrintBody" id="printTest">
          <div v-for="(printList, num) in printData" :key="num" class="A4CardBox">
            <el-row :gutter="0">
              <el-col :span="12" v-for="(p, index) in printList" :key="index">
                <div class="divPrintBox">
                  <div class="printBody">
                    <!-- 背景图片 -->
                    <el-image :src="p.imageUrl" class="bgImage"></el-image>
                    <!-- <img src="@/assets/image/AB1.jpg" alt="" class="bgImage"> -->
                    <!-- 头像 -->
                    <div class="photoBody">
                      <el-image :src="p.photo" class="photoBox" fit="cover" style="height: 100%;">
                      </el-image>
                    </div>
                    <div class="infoBody">
                      <div class="fontInfo">{{p.mobile}}</div>
                      <div class="fontName">{{p.name}}</div>
                    </div>
                    <div class="qrcodeBody">
                      <div class="qrBox">
                        <vue-qr :logoSrc="imageQRCodeUrl" :text="p.url" :margin="0" :size="80">
                        </vue-qr>
                      </div>
                    </div>
                  </div>
                  <div class="printBody">
                    <!-- 背景图片 -->
                    <el-image :src="p.imageUrl" class="bgImage"></el-image>
                    <!-- 头像 -->
                    <div class="photoBody">
                      <el-image :src="p.photo" class="photoBox" fit="cover" style="height: 100%;">
                      </el-image>
                    </div>
                    <div class="infoBody">
                      <div class="fontInfo" style="color:#FFFFFF">{{p.class}}</div>
                      <div class="fontName">{{p.name}}</div>
                    </div>
                    <div class="qrcodeBody">
                      <div class="qrBox">
                        <vue-qr :logoSrc="imageQRCodeUrl" :text="p.url" :margin="0" :size="80">
                        </vue-qr>
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-row>
          <el-col :span="24">
            <el-button icon="el-icon-printer" v-print="'#printTest'">打 印</el-button>
          </el-col>
        </el-row>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import comImport from '@/components/Import.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import print from 'vue-print-nb';
  import vueQr from 'vue-qr';
  directives: {
    print
  }
  export default {
    name: '',
    components: {
      comPage,
      comImport,
      operationBtn,
      vueQr
    },
    data() {
      return {
        // 动画
        fullscreenLoading: false,
        dialogVisible: false,
        dialogVisiblePhoto: false,
        dialogVisiblePrint: false,
        dialogVisibleStatus: false,
        dialogVisibleClass: false,
        optCollege: [],
        arrClass: [],
        arrStatus: [],
        // 更换学籍状态
        updateStatus: {
          studentId: '',
          dialogTitle: '',
          statusVal: '',
          option: [],
        },
        // 搜索
        SearchList: null,
        //列表数据
        itemData: [],

        //分页查询接口返回的结果
        page: null,
        //分页左侧按钮集合
        pageBtnList: null,
        //分页数据
        paging: {
          // 总共页数
          pageLength: 0,
          // 每页请求的数据量
          Size: 10,
          //当前在第几页
          Index: 1
        },
        // 上传学生信息接口
        uploadInfoApi: '/api/admin/student/import',

        // 导出弹出框
        checkAll: false,
        arrCheckFields: [],


        // 批量上传照片
        uploadPhotoPath: '/api/admin/student/importavatar', //上传路径

        filePhotoList: [],
        isPower: {
          //增删改查 权限管理
          student_details: false,
          student_create: false,
          student_edit: false,
          student_delete: false,
          admin_import_student: false,
          prize_photo_import: false,
          student_import: false,
          student_export: false,
          student_img_export:false,
          student_resetpwd: false,
          student_updatestatus: false,
          student_updateClass: false,
          student_print: false,
          student_DuMu:false,
          prize_familyimport_import:false
        },
        // 打印
        printData: [],
        printList: [],
        nowDate: timeTransform(new Date(), 'YYYY年MM月'),
        // 二维码LOGO
        imageQRCodeUrl: require("@/assets/image/logo1.png"),

        // 搜索集合
        searchForm: {
          selCollegeVar: '',
          optCollege: [],
          selStatus: '',
          selClassVar: '',
          optClass: [],
          Student: '',
          //所有班级数据
          allClassData: [],
          IdCardNo: '', //身份证号
          EntryYear: '', //入学年份
        },
        searchFormReset: null,

        errTip: null,
        thWidth: 100,



        tipSize: webUploadFileSize(),
        //转班
        updateClass: {
          studentId: '',
          option: [],
          classId: '',
        },

        // 判断是否为班主任
        isTeacher: false,
        updateGroup:{
          option:[],
          studentId:'',
          groupId:'',
        },
        dialogVisibleDumu:false,
      };
    },
    methods: {
      onSyncInfo(){
        let that = this;
        that.fullscreenLoading = true;
        ajaxPut(that,'/api/admin/student/resetallpassword',null,function(res){
          that.fullscreenLoading = false;
        })
      },
      onUpdateGroup(){
        let that = this;
        let data = {
          id:that.updateGroup.studentId,
          groupId : that.updateGroup.groupId,
        }
        if(regNull(that.updateGroup.groupId)){
          warning(that,'请选择门禁组');
          return false;
        }
        ajaxPut(that,'/api/admin/student/dumugroup?id=' + that.updateGroup.studentId +'&groupId='+that.updateGroup.groupId,null,function(res){
            that.dialogVisibleDumu = false;
            pagingPage(that);
        })
      },
      /**
       * @description 关闭打印弹出框
       */
      handleClosePrint() {
        let that = this;
        that.dialogVisiblePrint = false;
        that.printData = [];
      },
      /**
       * @description 打开打印预览页面
       */
      onOpenPrint() {
        let that = this;
        confirmDelete(that, '生成打印预览的时间根据需要打印的学生数量来决定，生成时间会过长，生成时可能会出现卡顿，这是都正常现象，确认继续吗？', function(res) {
          if (res == true) {
            let data = {
              IdCardNo: that.searchForm.IdCardNo,
              EntryYear: that.searchForm.EntryYear,
              Student: that.searchForm.Student,
              ClassId: that.searchForm.selClassVar,
              CollegeId: that.searchForm.selCollegeVar,
              Status: that.searchForm.selStatus,
            };
            that.printData = [];
            that.fullscreenLoading = true;
            ajaxGet(that, "/api/admin/student/searchnopage", data, function(r) {
              that.fullscreenLoading = false;
              var printList = [];
              r.forEach(ele => {
                ele.imageUrl = require('@/assets/image/AB1.jpg');
                ele.photo =that.$ServerUrl() + ele.photo;
                // ele.photo = 'data:image/jpg;base64,' + ele.photo;
                ele.url = that.$WebUrl() + "/mobile/#/pages/QRCode?Id=" + ele.studentId + '&type=1';
                printList.push(ele);
                if (printList.length == 8) {
                  that.printData.push(printList);
                  printList = [];
                }
              });
              if (printList.length > 0) {
                that.printData.push(printList);
              }
              that.dialogVisiblePrint = true;
            });
          }
        });
      },
      /**
       * @description 打开导出弹出窗
       */
      onOpenExport() {
        let that = this;
        that.dialogVisible = true;
        that.arrCheckFields = JSON.parse(JSON.stringify([]));
        that.checkAll = false;
        that.onGetFields(that);
      },
      /**
       * @description 转班
       */
      onUpdateClass() {
        let that = this;
        if (!regNull(that.updateClass.classId)) {
          confirmDelete(that, '请确认是否要对该学生进行转班操作吗!', function(res) {
            if (res == true) {
              that.fullscreenLoading = true;
              ajaxPut(that, "/api/admin/student/changeclass/" + that.updateClass.studentId + '?classId=' + that
                .updateClass.classId, null,
                function(r) {
                  that.fullscreenLoading = false;
                  that.dialogVisibleClass = false;
                  pagingPage(that);
                });
            }
          })
        } else {
          warning(that, '请选择需要转至的班级');
        }
      },
      /**
       * @description 关闭转班弹出框
       * @param {Object} _data
       */
      handleChangeClass(_data) {
        let that = this;
        that.updateClass.classId = _data;
      },
      /**
       * @description 重置搜索
       */
      onResetSearch() {
        let that = this;
        that.searchForm = JSON.parse(JSON.stringify(that.searchFormReset));
        that.paging.Index = 1;
        newRouterPath(that);
      },
      /**
       * @description 更改学籍状态
       */
      onUpdateStatus() {
        let that = this;
        that.fullscreenLoading = true;
        ajaxPut(that, '/api/admin/student/status/' + that.updateStatus.studentId + '?status=' + that.updateStatus
          .statusVal, null,
          function(res) {
            that.fullscreenLoading = false;
            that.dialogVisibleStatus = false;
            pagingPage(that);
          });
      },
      /**
       * @description 更多操作
       * @param {Object} command
       * @param {Object} _valueID
       */
      handleCommand(command, _valueID,_groupId) {
        let that = this;
        switch (command) {
          case 'status': // 更改学籍状态
            that.dialogVisibleStatus = true;
            let temp = that.itemData.find(item => item.studentId == _valueID);
            if (temp != undefined) {
              that.updateStatus.dialogTitle = temp.studentNo + ' | ' + temp.name;
              that.updateStatus.studentId = _valueID;
            }
            that.fullscreenLoading = true;
            ajaxGet(that, '/api/admin/student/status', null, function(resData) {
              that.fullscreenLoading = false;
              that.updateStatus.option = resData;
            });
            break;
          case 'class':
            // 转班
            let temp1 = that.itemData.find(item => item.studentId == _valueID);
            if (temp1 != undefined) {
              that.updateClass.studentId = _valueID;
            }
            that.updateClass.classId = '';
            that.fullscreenLoading = true;
            ajaxGet(that, '/api/admin/college/normallinkage', null, function(resData) {
              that.fullscreenLoading = false;
              that.updateClass.option = resData;
              that.dialogVisibleClass = true;
            });
            break;
          case 'reset':
            // 初始化密码
            that.onResetPwd(_valueID);
            break;
          case 'delete':
            // 删除
            that.onDelete(_valueID);
            break;
          case 'onUpdate':
            // 更新
            that.onUpdate(_valueID);
            break;
          case 'Dumu':
            // 门禁组
            that.groupId = '';
            that.updateGroup.studentId = _valueID;
            that.updateGroup.groupId = _groupId;
            that.dialogVisibleDumu = true;
            break;
        }
      },
      /**
       * @description 清除年级事件
       */
      onClearCollege() {
        let that = this;
        that.arrClass = [];
        that.searchForm.selClassVar = '';
        for (let item of that.searchForm.allClassData) {
          that.arrClass.push({
            value: item.classId,
            label: item.name,
            alias:item.alias,
          });
        }
      },
      /**
       * @description 选择年级事件
       * @param {Object} res
       */
      onChangeCollege(res) {
        let that = this;
        that.searchForm.selClassVar = '';
        let arrTemp = that.searchForm.allClassData.filter(item => item.collegeId == res);
        that.arrClass = [];
        for (let item of arrTemp) {
          that.arrClass.push({
            value: item.classId,
            label: item.name,
            alias:item.alias,
          });
        }
      },
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },
      /**
       * @description 导出字段全选
       */
      onCheckAll() {
        let that = this;
        that.arrCheckFields = that.arrCheckFields.filter((item) => {
          if (item.value != 'StudentNo' && item.value != 'Name') {
            item.checked = that.checkAll;
          }
          return item;
        });
      },
      /**
       * @description 关闭弹出框提示
       * @param {Object} done
       */
      handleClose(done) {
        done();
      },

      /**
       * @description 学生照片导出方法
       */
       studentsImgExport(){
        let that = this;
        that.fullscreenLoading = true;
        ajaxPostExport(that,'/api/admin/student/exportphoto',null,'学生照片.zip',function(resData) {
          that.fullscreenLoading = false;
        });
      },
      /**
       * @description 导出方法
       */
      onGetExport() {
        let that = this,
          arrTemp = '';
        for (let item of that.arrCheckFields) {
          if (item.checked) {
            arrTemp += item.value + ',';
          }
        }
        //处理字符串
        let _data = {
          field: arrTemp.substring(0, arrTemp.length - 1),
          IdCardNo: that.searchForm.IdCardNo,
          EntryYear: that.searchForm.EntryYear,
          Student: that.searchForm.Student,
          ClassId: that.searchForm.selClassVar,
          CollegeId: that.searchForm.selCollegeVar,
          Status: that.searchForm.selStatus,
        }

        that.fullscreenLoading = true;
        ajaxExport(that, '/api/admin/student/export', _data, "学生信息.xls", function(resData) {
          that.fullscreenLoading = false;
          // if(that.SearchList.selClassVar == resData.ClassId){

          // }
        });
      },
      /**
       * @description 获取导出字段
       * @param {Object} that
       */
      onGetFields(that) {
        that.fullscreenLoading = true;
        ajaxGet(that, '/api/admin/sysrole/userfields', null, function(resData) {
          for (let item of resData) {
            let arr = {
              value: item.name,
              label: item.text,
              checked: false,
              disabled: false,
            };
            if (arr.value == 'StudentNo' || arr.value == 'Name') {
              arr.checked = true;
              arr.disabled = true;
            }
            that.arrCheckFields.push(arr);
          }
          that.fullscreenLoading = false;
        });
      },
      /**
       * @description 初始化密码
       * @param {Object} Id
       */
      onResetPwd(Id) {
        let that = this;
        confirmResetPwd(that, '重置后密码为fz身份证号6位', function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxPut(that, "/api/admin/student/resetpassword/" + Id, null, function(r) {
              that.fullscreenLoading = false;
            });
          }
        });
      },
      /**
       * @description 打开上传照片
       */
      onOptenPhoto() {
        let that = this;
        that.dialogVisiblePhoto = true;
      },
      /**
       * @description 上传照片格式要求
       * @param {Object} file
       */
      beforeUpload(file) {
        let that = this;
        // 限制上传压缩包格式

        let isZip = false;
        if (file.type == 'application/zip' || file.type === 'application/x-zip-compressed') {
          isZip = true;
        }

        if (!isZip) {
          warning(that, '仅支持ZIP文件上传!');
        }
        // 限制上传文件大小
        const isLt200M = file.size / 1024 / 1024 < webUploadFileSize();
        if (!isLt200M) {
          warning(that, '上传文件大小不能超过 ' + webUploadFileSize() + 'M!');
        }
        return isZip && isLt200M;
      },
      /**
       * @description 上传头像方法
       * @param {Object} param
       */
      onUpdatePhoto(param) {
        let that = this;
        var fileObj = param.file;
        let files = new FormData();
        files.append('file', fileObj);
        files.append('tag', "avatar"); //头像存放地址
        that.fullscreenLoading = true;
        ajaxUpload(that, that.uploadPhotoPath, files, function(resData) {
          that.fullscreenLoading = false;
          if (resData.result == true) {
            that.dialogVisiblePhoto = false;
          } else {
            warning(that, resData.message);
          }
        });
      },
      /**
       * @description 上传照片提交按钮
       */
      submitUpload() {
        let that = this;
        that.$refs.import.submit();
      },
      /**
       * @description 导出学生信息成功后执行
       * @param {Object} param
       */
      onImportSuccess(param) {
        let that = this;
        pagingPage(that);
      },
      /**
       * @description 搜索事件
       */
      onSearch() {
        let that = this;
        that.paging.Index = 1;
        newRouterPath(that);
      },
      //分页组件左侧的按钮事件，按顺序进行判断
      pageBtnClick(index) {},
      /**
       * @description 分页面按钮
       * @param {Object} type
       * @param {Object} val
       */
      pagingClick(type, val) {
        let that = this;
        if (type == 1) {
          //更改每页条数触发的事件
          that.paging.Size = val;
          that.paging.Index = 1;
        } else {
          //更改当前页时触发的事件
          that.paging.Index = val;
        }
        newRouterPath(that);
      },
      /**
       * @description 编辑跳转
       * @param {Object} objID
       */
      onUpdate(objID) {
        let that = this;
        that.$router.push({
          path: "/Admin/Student/Edit/" + objID,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      /**
       * @description 详情
       * @param {Object} objID
       */
      onDetail(objID) {
        console.log(objID)
        let that = this;
        that.$router.push({
          path: "/Admin/Student/Detail/" + objID,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      /**
       * @description 新增跳转
       */
      onCreate() {
        let that = this;
        that.$router.push({
          path: "/Admin/Student/Create",
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      /**
       * @description 删除
       * @param {Object} Id
       */
      onDelete(Id) {
        let that = this;
        confirmDelete(that, '删除该学生数据后，无法恢复，是否确认继续？', function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, "/api/admin/student/" + Id, null, function(r) {
              that.fullscreenLoading = false;
              pagingPage(that);
            });
          }
        })
      },
      /**
       * @description 去除数组内的重复
       * @param {Object} arr
       */
      onArrUnique(arr) {
        const res = new Map();
        return arr.filter((arr) => !res.has(arr.label) && res.set(arr.label, 1));
      },
    },
    beforeRouteEnter(to, from, next) {
      if (from.fullPath == '/admin/Preparations/index') {
        let {
          Name,
          ClassId
        } = to.query;
      }
      next()
    },
    created() {
      let that = this;
      isPower(that, "student_details", "student_details");
      isPower(that, "student_create", "student_create");
      isPower(that, "student_edit", "student_edit");
      isPower(that, "student_delete", "student_delete");
      isPower(that, "admin_import_student", "admin_import_student");
      isPower(that, "student_import", "student_import");
      isPower(that, "student_export", "student_export");
      isPower(that, "student_img_export", "student_img_export");

      isPower(that, "student_print", "student_print");
      isPower(that, "prize_photo_import", "prize_photo_import");
      isPower(that, "student_resetpwd", "student_resetpwd");
      isPower(that, "student_updatestatus", "student_updatestatus");
      isPower(that, "student_updateClass", "student_updateClass");
      isPower(that,'student_DuMu','student_DuMu');
      isPower(that,'prize_familyimport_import','prize_familyimport_import');

      let tempUser = onGetNowUser(that);
      that.isTeacher = tempUser.isTeacher;


      let resArrUnique = new Map(),
        arrCollege = [];
      ajaxGet(that, '/api/admin/student/status', null, function(resStatus) {
        that.searchForm.allClassData = tempUser.allClass;
        // 学籍状态
        that.arrStatus = [];
        resStatus.forEach(function(item) {
          that.arrStatus.push({
            label: item.Text,
            value: item.Value,
          });
        });

        ajaxGet(that,'/api/admin/dumugroup',null,function(res){
          res.forEach(function(item){
            if(item.groupType == 4){
              that.updateGroup.option.push({
                value: item.groupId,
                label: regNull(item.description) ? item.groupName :item.groupName +'('+item.description+')'
              })
            }
          });
        })
        // 年级
        if (!regNullArray(tempUser.allClass)) {
          tempUser.allClass.filter((arr) => !resArrUnique.has(arr.collegeId) && resArrUnique.set(arr.collegeId,
              1))
            .forEach(function(item) {
              arrCollege.push({
                label: item.college,
                value: item.collegeId,
              });
            });
          // 班级
          if (that.isTeacher) {
            that.arrClass = [];
            for (let item of tempUser.manageClass) {
              that.arrClass.push({
                value: item.classId,
                label: item.name,
                alias:item.alias,
              });
            }
            that.searchForm.selClassVar = that.arrClass[0].value;
          } else {
            for (let item of tempUser.allClass) {
              that.arrClass.push({
                value: item.classId,
                label: item.name,
                alias:item.alias,
              });
            }
          }
          that.optCollege = arrCollege;

        }
        that.searchFormReset = JSON.parse(JSON.stringify(that.searchForm));
        let queryDate = that.$route.query;
        for (const key in queryDate) {
          if (queryDate[key] != null && queryDate[key] != "" && typeof(queryDate[key]) != "undefined") {
            if (key == "PageSize") {
              that.paging.Size = queryDate[key];
            } else if (key == "PageNumer") {
              that.paging.Index = queryDate[key];
            } else if (key == "Student") {
              that.searchForm.Student = queryDate[key];
            } else if (key == "ClassId") {
              that.searchForm.selClassVar = queryDate[key];
            } else if (key == "CollegeId") {
              that.searchForm.selCollegeVar = queryDate[key];
            } else if (key == "Status") {
              that.searchForm.selStatus = queryDate[key];
            } else if (key == "IdCardNo") {
              that.searchForm.IdCardNo = queryDate[key];
            } else if (key == "EntryYear") {
              that.searchForm.EntryYear = queryDate[key];
            }
          }
        }
        pagingPage(that);
      });

      //用于重置搜索
      that.thWidth = onGetWidth(that, [
        that.isPower.student_details,
        that.isPower.student_edit || that.isPower.student_updatestatus || that.isPower.student_resetpwd || that
        .isPower.student_delete
      ]) + 50;
    },
    watch: {
      '$route': function(to) {
        let that = this;
        pagingPage(that);
      },
      searchFormReset(newVal) {
        this.searchFormReset = newVal;
      }
    }

  };

  function newRouterPath(that) {
    let data = {
      PageNumer: that.paging.Index,
      PageSize: that.paging.Size,
      Student: that.searchForm.Student,
      EntryYear: that.searchForm.EntryYear,
      IdCardNo: that.searchForm.IdCardNo,
      ClassId: that.searchForm.selClassVar,
      CollegeId: that.searchForm.selCollegeVar,
      Status: that.searchForm.selStatus,
    };
    let routeData = {};
    for (const key in data) {
      if (data[key] != null && data[key] != "" && typeof(data[key]) != "undefined") {
        routeData[key] = data[key];
      }
    }
    that.$router.push({
      path: "/Admin/Student/Index",
      query: routeData
    })
  }
  // 加载数据
  function pagingPage(that) {
    that.fullscreenLoading = true;
    if (that.$route.query.url == '/admin/Preparations/index') {
      const {
        Name,
        ClassId
      } = that.$route.query
      if (Name) {
        that.searchForm.Student = that.$route.query.Name
      }
      if (ClassId) {
        that.searchForm.selClassVar = that.$route.query.ClassId
      }
    }

    // 学籍有默认值
    if (regNull(that.searchForm.selStatus)) {
      that.searchForm.selStatus = '1';
    }


    let data = {
      PageNumer: that.paging.Index,
      PageSize: that.paging.Size,
      Student: that.searchForm.Student,
      EntryYear: that.searchForm.EntryYear,
      IdCardNo: that.searchForm.IdCardNo,
      ClassId: that.searchForm.selClassVar,
      CollegeId: that.searchForm.selCollegeVar,
      Status: that.searchForm.selStatus,
    };
    ajaxGet(that, "/api/admin/student/search", data, function(resData) {
      that.page = resData;
      that.itemData = resData.items;
      that.fullscreenLoading = false;
      if (that.$route.query.url == '/admin/Preparations/index') {
        that.$router.push({
          path: '/Admin/Student/Index'
        })
      }

    });
  }

  // 初始化密码确认弹出框
  function confirmResetPwd(that, msg, callback) {
    if (msg == null || typeof(msg) == "undefined" || msg == "") {
      msg = "您确定要初始化密码吗？ 初始化后的密码是yaoyaokeji";
    }
    confirm(that, msg, callback);
  }

</script>

<style scoped="scoped" lang="less">
  .webPrintView {
    width: 100%;
    height: 400px;
    overflow-y: auto;
    margin-top: 30px;
  }

  .webDialogPrintBody {
    margin: 0 auto;
    position: relative;
    width: 100%;

    .A4CardBox {
      height: 339.37mm;
      width: 262mm;
      padding-top: 10px;
      // border: 1px solid #000;
    }

    .divPrintBox {
      display: flex;
      margin-bottom: 10px;
      padding-left: 13px;
      padding-right: 13px;
    }

    .printBody {
      margin: 0 auto;
      width: 51.5mm;
      height: 81.5mm;
      position: relative;
      padding-top: 11mm;

      .photoBody {
        width: 100%;
        position: relative;
        text-align: center;
        z-index: 10;
        border-radius: 1.84mm;
        width: 20.2mm;
        height: 27.1mm;
        overflow: hidden;
        margin: 0 auto;

        .photoBox {}
      }

      .infoBody {
        position: relative;
        z-index: 10;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        padding-top: 9px;

        .fontInfo {
          height: 21px;
          font-size: 14px;
          color: rgba(255, 255, 255, 1);
        }

        .fontName {
          font-size: 16px;
          color: rgba(255, 255, 255, 1);
        }
      }

      .qrcodeBody {
        position: relative;
        z-index: 10;
        text-align: center;
        padding-top: 18px;

        .qrBox {
          margin: 0 auto;
        }

      }

      .bgImage {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .selTitleBox {
    font-size: 14px;
  }

  .selTitleBox .spTitle {
    color: #303133;
  }

  .selBodyBox {
    padding-top: 5px;
  }

  .divAlertCont {
    margin-bottom: 0.9375rem;
  }

  .divAlertCont .divTipCot p {
    margin-bottom: 0.3125rem !important;
  }

  .btnCheckAll label {
    margin-bottom: 0;
  }

</style>
<style>
  .studentIndex .importStyle {
    font-size: 12px;
    font-weight: normal;
    padding: 9px 15px;
  }

</style>

<style scoped>
  @media print {
    @page {
      size: portrait;
      margin: 0;
    }

    * {
      -webkit-print-color-adjust: exact !important;
      -moz-print-color-adjust: exact !important;
      -ms-print-color-adjust: exact !important;
      print-color-adjust: exact !important;
    }
  }

</style>

