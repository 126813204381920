<template>
  <div class="SubWebPageStudent DetailsPro" v-loading.fullscreen.lock="fullscreenLoading">

    <!--活动报名-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          查看活动详情
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <operationBtn v-if="isPower.isManager || isPower.isDirector" :btnName="'编辑'"
                @click.native="onEdit()"></operationBtn>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body">

        <div class="infoList clearfix">
          <span class="spName">{{itemData.name}}</span>
        </div>
        <div class="infoList clearfix" v-if="itemData.activityDate!=null">
          <span class="spFonts">
            <span class="spLabel">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#fax-date-clock-o"></use>
              </svg>
              &nbsp;
              活动时间
            </span>
            &nbsp;
            <span>{{itemData.activityDate|dateformatMinute}}</span>
          </span>
          <!--  -->
          <span class="spFonts" v-if="itemData.roster!=null && itemData.roster.length>0">
            <span class="spLabel">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#fax-cube-users-alt"></use>
              </svg>
              &nbsp;
              报名人数
            </span>
            &nbsp;
            <span class="spNum">{{itemData.roster.length}}</span>
          </span>
          <span class="spFonts" v-if="itemData.place!=null">
            <span class="spLabel">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#fax-fax-map-marker-o"></use>
              </svg>
              &nbsp;
              地址
            </span>
            {{itemData.place}}
          </span>
        </div>
      </div>
    </el-card>


    <!--活动内容-->
    <el-card class="box-card" v-if="itemData.description!=null">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          活动内容
        </h3>
      </div>
      <div class="card-body">
        <div class="colRemarksCont" v-html="itemData.description"></div>
      </div>
    </el-card>



    <el-card class="box-card" v-if="imgArr!=null && imgArr.length>0">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          活动相关图片
        </h3>
      </div>
      <div class="card-body ">
        <el-carousel height="500px" :autoplay="!showViewer">
          <el-carousel-item v-for="(item,index) in imgArr" :key="index" class="VerticalMid">
            <img :src="item" @click="onPreview">
          </el-carousel-item>
        </el-carousel>
      </div>
    </el-card>


    <!--参与人员-->
    <el-card class="box-card" v-if="itemData.roster!=null && itemData.roster.length>0">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          参与人员
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <comImport @onImportSuccess="onImportSuccess" v-if="isPower.isDirector || isPower.isManager"  :template="'/templates/社团成员导入模版.xlsx'"
                :path="onGetApi()" :btnSize="'mini'" :btnClass="'clubBtnImport'" :btnIcon="'el-icon-upload'"
                :title="'导入参与名单'" :btnName="'导入名单'" ref="conImportInfoFile">
              </comImport>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body">
        <el-table :data="itemData.roster" stripe class="elTableData">
          <el-table-column type="index" label="序号" width="130"></el-table-column>
          <el-table-column prop="studentNo" label="学号" width="140"></el-table-column>
          <el-table-column prop="studentName" label="姓名" min-width="120"></el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
             <operationBtn :btnName="'移除'" :btnType="'danger'"
               v-if="isPower.isDirector || isPower.isManager"
               @click.native="onDelStudent(scope.row.activityId,scope.row.rosterId)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>

    <div class="row">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
      </div>
    </div>

    <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="imgArr" />

  </div>
</template>

<script>
  import '@/assets/css/Student.css';
  import operationBtn from '@/components/operationBtn.vue';
  import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

  import comImport from '@/components/Import.vue';
  export default {
    components: {
      ElImageViewer,
      operationBtn,
      comImport
    },
    data() {
      return {
        // 动画
        fullscreenLoading: false,
        dialogVisible: false,
        uploadFiles: [],
        random: 0,
        //列表数据
        itemData: {
          activityId: null,
        },
        configData: [],
        imgArr: [],

        showViewer: false,

        isPower: {
          isDirector: false,
          isManager: false,
        },
        uploadInfoApi: '/api/student/clubactivity/activityroster/',
      };
    },

    methods: {
      
      /**
       * @description 编辑社团活动
       * @param {Object} Id
       */
      onEdit() {
        let _this = this;
        _this.$router.push({
          path: '/Student/Club/ClubActivities/Edit/' + _this.itemData.clubId + '/' + _this.itemData.activityId,
          query: {
            url: _this.$router.history.current.fullPath
          }
        });
      },
      
      /**
       * @description 生成API地址
       * @return {String} 组合成的新Api接口地址
       */
      onGetApi() {
        return this.uploadInfoApi + this.itemData.activityId;
      },


      /**
       * @description 导入名单
       * @param {Object} param
       */
      onImportSuccess(param) {
        let _this = this;
       onGetInfo(_this);
      },
      onDelStudent(_activityId,rosterId){
        let _this = this;
        let _data=[rosterId];
        confirmDelete(_this, null, function(res) {
          if (res == true) {
            _this.fullscreenLoading = true;
            ajaxDelete(_this, "/api/student/clubactivity/roster/" + _activityId, _data, function(r) {
              _this.fullscreenLoading = false;
              onGetInfo(_this);
            });
          }
        })
      },
      onPreview() {
        this.showViewer = true
      },

      closeViewer() {
        this.showViewer = false
      },
      onBack() {
        back(this);
      },
    },
    created() {
      let _this = this;
      let id = _this.$route.params.Id;
      if (id != null && typeof(id) != "undefined" && id != "") {
        _this.itemData.activityId = id;
        onGetInfo(_this);
      }
    },
  };

  function onGetInfo(_this) {
    _this.fullscreenLoading = true;
    ajaxGet(_this, "/api/student/clubactivity/" + _this.itemData.activityId, null, function(resData) {
      // 附件
      if (resData.attachment != null) {
        var attachments = resData.attachment.split('|');
        for (var i in attachments) {
          _this.imgArr.push(_this.$ServerUrl() + attachments[i]);
        }
      }
      _this.itemData = resData;


      ajaxGet(_this,'/api/student/club/memberofme/'+resData.clubId,null,function(resPosition){
         _this.fullscreenLoading = false;
         _this.isPower=resPosition;
      });

    });



  }
</script>

<style scoped="scoped">
  .dialogBox {
    padding: 10px 5px;
    height: 140px;
    overflow: hidden;
  }

  .dialogVideoBox {
    padding: 10px 5px;
  }

  .dialogBox .mailbox-attachment-icon {
    font-size: 32px;
    padding: 15px 10px;
  }

  .dialogBox .mailbox-attachment-size>span {
    font-size: 12px;
  }

  .dialogBox .mailbox-attachment-info {
    padding: 7px 10px;
  }



  .bodyAwardsCont .stuAwardsCont {
    position: relative;
    padding: 0px 15px 45px 15px;
  }

  .bodyAwardsCont .stuAwardsCont .stuAvatar {
    position: absolute;
    left: 15px;
    top: 0px;
    border: 1px solid #eee;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 5px;
  }

  .bodyAwardsCont .stuAwardsLabel {
    font-size: 12px;
    padding-left: 65px;
    color: #B1B1B1;
  }

  .bodyAwardsCont .awardsName {
    padding-top: 10px;
    color: #3e3e3e;
  }

  .bodyAwardsCont .stuAwardsCont .stuName {
    font-size: 16px;
    padding-left: 65px;
  }

  .bodyAwardsCont .stuAwardsFont {
    font-size: 14px;
    padding-left: 65px;
    color: #666;
  }

  .bodyAwardsCont .stuFileCont {
    padding-top: 5px;
  }




  .bodyRecordCont .stuRecordCont {
    position: relative;
    padding: 0px 15px 45px 15px;
    padding-left: 75px;

  }

  .bodyRecordCont .stuRecordCont .stuAvatar {
    position: absolute;
    left: 15px;
    top: 0px;
    border: 1px solid #eee;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 5px;
  }


  .bodyRecordCont .stuRecordCont .stuName {
    font-size: 16px;
  }

  .bodyRecordCont .stuRecordFont {
    font-size: 12px;
    padding-top: 5px;
    color: #767676;
  }

  .bodyRecordCont .stuRecordLabel {
    font-size: 12px;
    padding-top: 5px;
    color: #B1B1B1;
  }

  .bodyRecordCont .stuFileCont {
    padding-top: 10px;
  }

  .bodyRecordCont .stuFileCont .divFileIcon {
    border: 1px solid #eee;
    width: 100%;
    height: 80px;
    overflow: hidden;
    border-radius: 4px;
  }

  .bodyRecordCont .stuFileCont .divFileIcon .elIcon {
    font-size: 32px;
  }





  .colRecordOther {}

  .colRecordOther .divList {
    float: left;
    width: 33.3%;
  }

  .colRecordCont {
    padding: 5px;
    font-size: 12px;
    color: #767676;
  }

  .divVideoCont {
    overflow-y: auto;
    width: 100%;
    height: 500px;
    padding-right: 10px;
  }

  .divVideoCont ul li .aItem {
    display: block;
    padding: 7px 0px;
    padding-left: 30px;
    border-bottom: 1px dotted #DDDDDD;
    position: relative;
  }

  .divVideoCont ul li:last-child .aItem {
    border-bottom: none;
  }

  .divVideoCont ul li:hover {
    background-color: #5AAAFF;
    color: #FFFFFF;
  }

  .divVideoCont ul li .aItem span {
    font-family: 'OswaldLight';
    font-size: 14px;
    margin-left: 10px;
  }

  .divVideoCont ul li .aItem .faxIcon {
    position: absolute;
    top: 11px;
    left: 5px;
    display: none;
  }

  .divVideoCont ul li .aItemAct {
    background-color: #007BFF;
    color: #FFFFFF;
    border-bottom: none;
  }

  .divVideoCont ul li .aItemAct .faxIcon {
    display: block;
  }


  .bodyImgCont .mailbox-attachment-icon {
    min-height: 165px;
  }

  .bodyFileCont .mailbox-attachments li,
  .bodyImgCont .mailbox-attachments li {
    width: 250px;
  }

  .bodyImgCont .mailbox-attachment-icon {
    height: 198px;
  }

  .bodyFileCont .mailbox-attachment-size .spFont,
  .bodyImgCont .mailbox-attachment-size .spFont {
    font-size: 12px;
  }





  .tabListCont .divNavTitle {}

  .tabListCont .divNavTitle {}

  .tabListCont .divNavTitle .divTitleCont {
    padding: 10px 0px 10px 10px;
    color: #DEE2E6;
    cursor: pointer;
    font-size: 18px;
  }

  .tabListCont .divNavTitle .divTitleCont .spNum {
    background-color: #DEE2E6;
    font-family: 'OswaldLight';
    padding: 2px 5px;
    border-radius: 4px;
    margin-left: 5px;
    font-size: 14px;
    color: #FFFFFF;
  }

  .tabListCont .divNavTitle .divTitleAct {
    color: #3e3e3e;
  }

  .tabListCont .divNavTitle .divTitleAct .spNum {
    background-color: #17A2B8;
    color: #FFFFFF;
  }

  .tabListCont .divNavTitle .nav-link {
    font-size: 14px;
    color: #B1B1B1;
  }

  .tabListCont .divNavTitle .nav-link .spNum {
    font-family: 'OswaldLight';
    font-weight: normal;
    font-size: 14px;
    padding-left: 10px;
  }

  .tabListCont .divNavTitle .active {
    color: #007bff;
  }




  .DetailsPro .divRoleTitle {
    font-size: 18px;
    color: #323E56;
    padding-top: 20px;
  }

  .DetailsPro .divRoleTitle .spItem {
    float: right;
    margin-left: 5px;
    font-size: 14px;
    color: #28A745;
  }

  .DetailsPro .divRoleTitle .spItem em {
    font-style: normal;
    background-color: #28A745;
    color: #FFFFFF;
    margin-left: 5px;
    margin-right: 5px;
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 12px;
    font-family: 'OswaldLight';
  }

  .DetailsPro .divRoleTitle .el-button {
    padding: 0;
    border: none;
  }

  .DetailsPro .divRoleTitle .el-button:hover {
    background-color: transparent;
  }





  .stuInfo {
    position: relative;
    height: 100%;
    min-height: 80px;
    padding-left: 70px;
    margin: 15px;
  }

  .stuInfo .stuFraction {
    position: absolute;
    top: -5px;
    bottom: 0;
    right: -5px;
    font-family: 'OswaldLight';
    font-size: 26px;
    color: #28A745;
  }

  .stuInfo .stuBtnDown {
    position: absolute;
    bottom: 20px;
    right: 0;
  }

  .stuInfo .stuBtnDown .aView {
    margin-right: 5px;
    margin-left: 5px;
  }

  .stuInfo .stuBtnDown .aView .faxSize {
    font-size: 12px;
    color: #343a40;
  }

  .stuInfo .stuBtnDown .aView:hover .faxSize {
    color: #1666B3;
  }

  .stuInfo .stuImgCont {
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 80px;
    border-radius: 4px;
    overflow: hidden;
  }

  .stuInfo .stuImgCont img {
    width: 100%;
  }

  .stuInfo .stuName {
    color: #212E48;
    font-size: 18px;
    font-weight: bold;
  }

  .stuInfo .stuRole {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
  }

  .stuRole .spStatus {
    font-size: 12px;
    color: #B1B1B1;
  }

  .stuInfo .stuOther {
    color: #767676;
    font-size: 12px;
  }


  .cardTabs .abtnTabs {
    font-size: 16px;
    color: #B1B1B1;
    margin-left: 15px;
    position: relative;
    display: block;

    float: left;
    text-align: center;
  }

  .cardTabs .spDivide {
    float: left;
    padding-left: 15px;
  }


  .cardTabs .abtnTabs:hover {
    color: #323E56;
  }

  .cardTabs .abtnTabs .spFaxIcon {
    display: none;
  }

  .cardTabs .abtnTabs:first-child {
    margin-left: 0;
  }

  .cardTabs .abtnTabs .spNumber {
    background-color: #DEE2E6;
    font-size: 12px;
    color: #FFFFFF;
    font-family: 'OswaldLight';

    padding: 1px 5px;
    border-radius: 5px;
  }



  .cardTabs .abtnTabAct {
    font-size: 1.1rem;
    color: #323E56;
  }

  .cardTabs .abtnTabAct .spFaxIcon {
    display: block;
    position: absolute;
    top: 22px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 12px;
  }

  .cardTabs .abtnTabAct .spNumber {
    background-color: #1666B3;
    color: #FFFFFF;
    font-size: 14px;
  }



  .infoList {
    padding-bottom: 17px;
    padding-left: 30px;
  }

  .infoList .spName {
    font-size: 32px;
    color: #212E48;
    padding-right: 30px;
    height: 40px;
  }


  .infoList .spFonts {
    color: #3B475D;
    padding-right: 30px;
    padding-left: 30px;
    border-right: 1px solid #D6D6D6;
  }

  .infoList .spFonts:first-child {
    padding-left: 0;
  }

  .infoList .spFonts:last-child {
    border-right: none;
  }

  .infoList .spMedal {
    padding-right: 15px;
  }

  .infoList .spFonts .faxSize {
    font-size: 18px;
  }

  .infoList .spFonts .icon {
    color: #FFFFFF;
  }

  .infoList .spStatus {
    border: 1px solid #007BFF;
    background-color: #007BFF;
    color: #FFFFFF;
    padding: 5px 20px;
    border-radius: 5px;

  }

  .infoList .spNStatus {
    border: 1px solid #007BFF;
    background-color: #007BFF;
    color: #FFFFFF;
    padding: 5px 20px;
    border-radius: 5px;
  }

  .infoList .spFonts .spLabel {
    font-size: 14px;
    padding: 2px 5px;
    border-radius: 5px;
    background-color: #B1B1B1;
    color: #FFFFFF;
    margin-right: 10px;
  }

  .infoList .spFonts .spNum {
    padding-left: 10px;
    padding-right: 5px;
    font-family: 'OswaldLight';
    font-size: 16px;
    font-weight: bold;
    color: #DC3545;
  }

  .DetailsPro .colRemarksCont {
    font-size: 14px;
    letter-spacing: 1px;
  }
</style>
