<!--
  基础信息 - 班级综合类型
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          班级综合类型
        </h3>
      </div>
      <div class="card-body">
        <el-row :gutter="24">
          <el-col :span="6">
            <div class="divTreeCont">
              <el-input placeholder="输入关键字进行过滤" v-model="filterText">
                <el-button slot="append" icon="el-icon-refresh-right" @click="onFilterText()"></el-button>
              </el-input>

              <el-tree :data="data" :props="defaultProps" ref="tree" style="margin-top:10px;" node-key="id"
                default-expand-all @node-click="handleNodeClick" :expand-on-click-node="false"
                 :filter-node-method="filterNode">
              </el-tree>
            </div>
          </el-col>
          <el-col :span="18">
            <el-row :gutter="24">
              <el-col :span="20">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item v-for="(p,index) in currentNav" :key="index">{{p}}</el-breadcrumb-item>
                </el-breadcrumb>
              </el-col>
              <el-col :span="4" style="text-align: right;">
                <el-button v-if="isPower.growthconfigclass_create == true" @click="onAdd()" type="success"
                  icon="el-icon-circle-plus-outline">新增</el-button>
              </el-col>
            </el-row>
            <el-table stripe :data="item" class="tableTwo table-hover text-nowrap "
              style="width: 100%; margin-top: 0px; ">
              <el-table-column type="index" width="120px;" label="序号"></el-table-column>
              <el-table-column prop="name" label="参数名称"></el-table-column>
              <el-table-column prop="ceiling" label="上限分"></el-table-column>
              <el-table-column prop="baseScore" label="基础分"></el-table-column>
              <el-table-column label="操作" width="230px">

                <template slot-scope="scope">
                 <operationBtn :btnName="'查看'" v-if="isPower.growthconfigclass_details" @click.native="onDetail(scope.row.id)"></operationBtn>
                  <operationBtn v-if="isPower.growthconfigclass_edit == true" :btnName="'编辑'"
                    @click.native="onEdit(scope.row)"></operationBtn>
                  <operationBtn v-if="isPower.growthconfigclass_delete == true" :btnName="'删除'"
                    @click.native="onDelete(scope.row.id)"></operationBtn>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
    </el-card>

    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%" :before-close="onClose"
      :close-on-click-modal="false" :close-on-press-escape="false" :append-to-body="true" :fullscreen="false">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="90px">
        <el-row v-if="isDetail!=true">
          <el-col :span="24">
            <el-form-item label="类型" prop="name">
              <el-input v-model="ruleForm.name" placeholder="请输入类型"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="所属类型" prop="parentId">
              <treeSelect :key="random" @getValue="getValue" :options="data" :value="ruleForm.parentId"></treeSelect>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="上限分" prop="ceiling">
              <el-input v-model="ruleForm.ceiling" placeholder="请输入上限分" @blur="ceilingBlur" :disabled="isEditValue"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="基础分" prop="baseScore">
              <el-input @blur="baseScoreBlur" v-model="ruleForm.baseScore" placeholder="请输入基础分" :disabled="isEditValue"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="优先级" prop="priority">
              <el-input @change="onPriorityChange()" v-model="ruleForm.priority" placeholder="请输入优先级"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注" prop="description">
              <el-input class="formControl" type="textarea" :rows="4" show-word-limit placeholder="请输入备注"
                maxlength="500" v-model="ruleForm.description">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <div v-if="isDetail!=true">
          <el-button @click="onClose()">取 消</el-button>
          <el-button @click="onReset()">重 置</el-button>
          <el-button type="primary" @click="onSave()">确 定</el-button>
        </div>
        <div v-else>
          <el-button @click="dialogVisible = false">关 闭</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import operationBtn from '@/components/operationBtn.vue';
  import treeSelect from '@/components/TreeSelect.vue';
  export default {
    name: '',
    components: {
      operationBtn,
      treeSelect
    },
    data() {
      return {
        filterText: '', //查找树形菜单
        currentNav: [],
        random: 0, //刷新组件
        currentId: null,
        isDetail: false,
        dialogTitle: "新增综合类型",
        dialogVisible: false,
        currentTitle: "请在左侧选择综合类型",
        item: null,
        fullscreenLoading: false,
        data: [],
        defaultProps: {
          children: 'children',
          label: 'name'
        },
        ruleForm: {
          id: null,
          name: null,
          priority: null,
          description: null,
          parentId: null,
          weight: null,
          ceiling: null,
          baseScore: 0,
          configType: 2
        },
        isEditValue: false,
        ruleFormInit: null,
        ruleFormReset: null,
        isPower: {
          //增删改查 权限管理
          growthconfigclass_details: false,
          growthconfigclass_create: false,
          growthconfigclass_edit: false,
          growthconfigclass_delete:false
        },
        rules: {
          name: [{
              required: true,
              message: '综合类型不能为空',
              trigger: 'change'
            },
            {
              max: 64,
              message: '综合类型不能超过64个字符',
              trigger: 'change'
            },
          ],

          priority: [{
            validator: (rule, value, callback) => {
              let that = this;
              var reg = /^-?[1-9]\d*$/;
              if (value != 0 && !reg.exec(value)) {
                callback(new Error("优先级只能输入整数！"));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }],
          ceiling: [{
            validator: (rule, value, callback) => {
              let that = this;
              var reg = 　/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/
              if (value==0 || !reg.exec(value)) {
                callback(new Error("上限分只能输入大于0的数！"));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }],
          baseScore: [{
            required:false,
            validator: (rule, value, callback) => {
              let that = this;
              var reg = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/
              if (value !=0 && !reg.exec(value)) {
                callback(new Error("基础分只能输入大于等于0的数"));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }],
        },
      };
    },
    created() {
      let that = this;
      isPower(that, "growthconfigclass_details", "growthconfigclass_details");
      isPower(that, "growthconfigclass_create", "growthconfigclass_create");
      isPower(that, "growthconfigclass_edit", "growthconfigclass_edit");
      isPower(that,'growthconfigclass_delete','growthconfigclass_delete')
      that.ruleFormInit = JSON.parse(JSON.stringify(that.ruleForm));
      Init(that);
    },
    methods: {
      onDetail(objVal) {
        let that = this;
        that.$router.push({
          path: "/Admin/GrowthConfigClass/Details/" + objVal,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      baseScoreBlur(){
        let that = this
        let str = that.ruleForm.baseScore
        let reg = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/
        if(reg.test(str) || str == 0){
           that.ruleForm.baseScore =  fomatFloat(str,1)
        }
      },
      ceilingBlur(){
        let that = this
        let str = that.ruleForm.ceiling
        let reg = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/
        if(reg.test(str) && str != 0){
           that.ruleForm.ceiling =  fomatFloat(str,1)
        }
      },
      onFilterText() {
        this.filterText = "";
      },
      filterNode(value, data) {
        if (!value) return true;
        return data.name.indexOf(value) !== -1;
      },
      onWeightChange() {
        let that = this;
        var value = setTwoFloat(that.ruleForm.weight);
        that.ruleForm.weight = value;
      },
      onPriorityChange() {
        let that = this;
        var value = setInt(that.ruleForm.priority);
        that.ruleForm.priority = value;
      },
      onClose() {
        let that = this;
        that.onReset();
        that.dialogVisible = false;
      },
      onSave() {
        let that = this;
        if (that.ruleForm.priority == null || that.ruleForm.priority == "") {
          that.ruleForm.priority = "0"
        }
        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            if (that.ruleForm.id == null) {
              if(!that.ruleForm.baseScore){
                that.ruleForm.baseScore = 0
                
              }
              that.fullscreenLoading = true;
              ajaxPost(that, "/api/admin/growthConfig", that.ruleForm, function(r) {
                that.fullscreenLoading = false;
                that.dialogVisible = false;
                that.currentId = that.ruleForm.parentId;
                Init(that);
              });
            } else {
              that.ruleForm.configId = that.ruleForm.id;
              if (that.ruleForm.configId == that.ruleForm.parentId && that.ruleForm.configId != null) {
                warning(that, "不能把班级评价类型设置在自身级别下");
                return false;
              }
               if(!that.ruleForm.baseScore){
                that.ruleForm.baseScore = 0 
              }             
              that.fullscreenLoading = true;
              ajaxPut(that, "/api/admin/growthConfig/" + that.ruleForm.id, that.ruleForm, function(r) {
                that.fullscreenLoading = false;
                that.dialogVisible = false;
                that.currentId = that.ruleForm.parentId;
                Init(that);
              });
            }
          } else {

            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              isError[0].querySelector('input').focus();
            }, 1)
            return false;
          }
        });
      },
      onReset() {
        let that = this;
        this.ruleForm = JSON.parse(JSON.stringify(this.ruleFormReset));
        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      getValue(value) {
        let that = this;
        that.ruleForm.parentId = value;
      },
      onAdd() {
        let that = this;
        that.isEditValue = false;
        that.dialogVisible = true;
        that.dialogTitle = "新增班级评价类型";
        that.isDetail = false;
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormInit));
        that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleFormInit));
        that.ruleForm.parentId = that.currentId;
        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      /**
       * @description 编辑按钮
       * @param {Object} data
       */
      onEdit(data) {
        let that = this;
        if (data.children.length != 0) {
          that.isEditValue = true;

        } else {
          that.isEditValue = false;
        }

        that.ruleForm = JSON.parse(JSON.stringify(data));
        that.dialogVisible = true;
        that.dialogTitle = "编辑综合类型";
        that.isDetail = false;
        that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;
      },
      onDelete(id) {
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, "/api/admin/growthConfig/" + id, null, function(r) {
              that.fullscreenLoading = false;
              Init(that);
            });
          }
        })
      },
      handleNodeClick(data) {
        let that = this;
        that.item = data.children;
        that.currentTitle = data.title;
        that.currentId = data.id;
      },
      // renderContent(h, {
      //   node,
      //   data,
      //   store
      // }) {
      //   return ( <
      //     span class = "custom-tree-node" >
      //     <
      //     span style = "font-size:14px;" > {
      //       node.label
      //     } < /span> < /
      //     span > );
      // },
    },
    watch: {
      currentTitle(val) {
        let that = this;
        var titles = val.split('/');
        that.currentNav = ["班级评价"];
        for (var i in titles) {
          if (titles[i] != "班级评价") {
            that.currentNav.push(titles[i]);
          }
        }
      },
      filterText(val) {
        this.$refs.tree.filter(val);
      }
    },
    mounted() {

    },
  };
  function GetItem(that, val) {
    if (val.length !== 0) {
      val.forEach((item) => {
        if (item.id == that.currentId) {
          that.item = item.children;
          that.currentTitle = item.title;
        } else if (item.children.length >= 1) {
          GetItem(that, item.children);
        }
      });

    }
  }
  function fomatFloat(value, n) {
      let f= Math.round(value*Math.pow(10,n))/Math.pow(10,n)
      let s = f.toString();
      let rs = s.indexOf('.');  
      if (rs < 0) {     
          s += '.'; 
      } 
      for(let i = s.length - s.indexOf('.'); i <= n; i++){
        s += "0";
      }
      return s;
  }
  function Init(that) {
    that.fullscreenLoading = true;
    that.data = [];
    ajaxGet(that, "/api/admin/growthconfig/classtreelist", null, function(r) {
      that.fullscreenLoading = false;
      //that.data = r;
      var tree = {
        id: null,
        value: null,
        name: "班级评价",
        title: "班级评价",
        //label:"班级评价",
        children: r,
      };
      that.data.push(tree);
      GetItem(that, that.data);
    });
  }
</script>

<style scoped="scoped">
  .divTreeCont {
    border: 1px solid #DDDDDD;
    padding: 15px;
    border-radius: 4px;
  }
</style>
