<template>
  <div class="SubWebPageStudent" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form label-width="100px" :model="ruleForm" :rules="rules" ref="ruleForm">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{Title}}
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24">
            <el-col :span="24">
              <el-col :span="8">
                <el-form-item label="获奖名称" prop="name">
                  <el-input v-model="ruleForm.name" placeholder="请输入获奖名称"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="获奖时间" prop="recordDate">
                  <el-date-picker v-model="ruleForm.recordDate" type="date" placeholder="选择获奖时间" style="width: 100%;">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="颁奖单位" prop="place">
                  <el-input v-model="ruleForm.place" placeholder="请输入颁奖单位"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="备注" prop="description">
                  <el-input type="textarea" v-model="ruleForm.description" placeholder="请输入备注" :autosize="{ minRows: 5}" maxlength="200" show-word-limit style="width: 100%;"></el-input>
<!--                  <quill-editor height="300px" v-model="ruleForm.description" ref="text" class="myQuillEditor"
                    :options="editorOption" /> -->
                </el-form-item>
              </el-col>
            </el-col>
          </el-row>
        </div>
      </el-card>

      <el-card style="margin-top: 10px;">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            佐证材料
          </h3>
        </div>
        <div class="card-body">
          <comUpload :key="random" @onPictureHandleSuccess="onPictureHandleSuccess" :files="uploadFiles"></comUpload>
        </div>
      </el-card>

      <div class="row" style="margin-top: 10px;">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返 回</el-button>
          <el-button @click="onReset()">重 置</el-button>
          <el-button type="primary" @click="onSave()">提 交</el-button>
        </div>
      </div>
    </el-form>

  </div>
</template>

<script>
  import '@/assets/css/Student.css';
  import comUpload from '@/components/Upload.vue';
  import {
    quillEditor
  } from 'vue-quill-editor';
  import * as Quill from 'quill' //引入编辑器
  var fonts = ['SimSun', 'SimHei', 'Microsoft-YaHei', 'KaiTi', 'FangSong', 'Arial', 'Times-New-Roman', 'sans-serif'];
  var Font = Quill.import('formats/font');
  Font.whitelist = fonts; //将字体加入到白名单
  Quill.register(Font, true);
  export default {
    components: {
      comUpload,
      // quillEditor
    },
    data() {
      return {
        //富文本框
        editorOption: {
          placeholder: '请在此输入内容',
          modules: {
            toolbar: [
              [{
                'font': fonts
              }], //字体，把上面定义的字体数组放进来
              ['bold', 'italic', 'underline',
              'strike'], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
              ['blockquote', 'code-block'], // 引用  代码块-----['blockquote', 'code-block']
              [{
                header: 1
              }, {
                header: 2
              }], // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
              [{
                list: 'ordered'
              }, {
                list: 'bullet'
              }], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
              [{
                script: 'sub'
              }, {
                script: 'super'
              }], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
              [{
                indent: '-1'
              }, {
                indent: '+1'
              }], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
              [{
                'direction': 'rtl'
              }], // 文本方向-----[{'direction': 'rtl'}]
              [{
                size: ['small', false, 'large', 'huge']
              }], // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
              [{
                header: [1, 2, 3, 4, 5, 6, false]
              }], // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
              [{
                color: []
              }, {
                background: []
              }], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
              [{
                align: []
              }], // 对齐方式-----[{ align: [] }]
              ['clean'], // 清除文本格式-----['clean']
              ['image'], // 链接、图片、视频-----['link', 'image', 'video']
            ]
          }
        },
        fullscreenLoading: false,
        Title: "新增获奖上报",
        //列表数据
        itemData: [],
        ruleForm: {
          reportId: null,
          name: null, //记录名称
          recordDate: null, //时间
          place: null, //地点
          description: null, //描述
          attachment: null //附件
        },
        //待重置时的表单数据
        ruleFormReset: null,
        rules: {
          name: [{
              required: true,
              message: '名称不能为空',
              trigger: 'change'
            },
            {
              max: 20,
              message: '名称不能超过20个字符',
              trigger: 'change'
            },
          ],
          recordDate: [{
            required: true,
            message: '记录日期不能为空',
            trigger: 'change'
          }, ],
          place: [{
              required: true,
              message: '记录地址不能为空',
              trigger: 'change'
            },
            {
              max: 20,
              message: '记录地址不能超过20个字符',
              trigger: 'change'
            },
          ],
        },
        uploadFiles: [],
        random: null
      };
    },
    methods: {
      //图片上传
      onPictureHandleSuccess(param) {
        let that = this;
        that.ruleForm.attachment = "";
        for (var i in param) {
          if (that.ruleForm.attachment == "") {
            that.ruleForm.attachment = param[i].path;
          } else {
            that.ruleForm.attachment += "|" + param[i].path;
          }
        }
      },
      onSave() //提交
      {
        let that = this;
        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            if (that.ruleForm.reportId == null) {
              that.fullscreenLoading = true;
              ajaxPost(that, "/api/student/selfreport", that.ruleForm, function(r) {
                that.fullscreenLoading = false;
                that.onBack();
              });
            } else {
              that.fullscreenLoading = true;
              ajaxPut(that, "/api/student/selfreport/" + that.ruleForm.reportId, that.ruleForm, function(r) {
                that.fullscreenLoading = false;
                that.onBack();
              });
            }
          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              isError[0].querySelector('input').focus();
            }, 1)
            return false;
          }
        })

      },
      onReset() //重置
      {
        let that = this;
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      onBack() {
        let that = this;
        back(that);
      },
    },
    created() {
      let that = this;
      that.fullscreenLoading = true;
      that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
      let id = that.$route.params.id;
      var detailUrl = null;
      if (id != null && typeof(id) != "undefined" && id != "") {
        that.Title = "编辑获奖上报";
        that.ruleForm.reasonId = id;
        detailUrl = "/api/student/selfreport/" + id;
      }
      that.fullscreenLoading = true;
      ajaxGet(that, detailUrl, null, function(r) {
        that.fullscreenLoading = false;
        if (r != null) {
          that.ruleForm = r;
          that.uploadFiles = [];
          if (r.attachment != null && typeof(r.attachment) != "undefined" && r.attachment != "") {
            var files = r.attachment.split('|');
            for (var i in files) {
              var it = i + 1;
              that.uploadFiles.push({
                uid: it,
                path: files[i],
              });
            }
            var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
            that.random += random;
          }
        }
      })
    }
  };
</script>

<style scoped="scoped">
  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }
</style>
