<!--
  综合活动 - 确认名单
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
 <!--   <search :SearchTitles="SearchTitles" :ColNumber="3" :SearchList="SearchList" ref="SearchList" @onSearch="onSearch"
      v-if="SearchList!=null"></search> -->
    <!-- 列表-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          {{Title}}
        </h3>
        <div class="card-tools" v-if="joinType==1">
          <comImport v-if="ImportFile!=null && ImportUrl!=null" @onImportSuccess="onImportSuccess" :title="ImportTitle"
            :template="ImportFile" :path="ImportUrl" ref="conImportFile" :btnSize="'small'" :btnName="'导入名单'">
          </comImport>
        </div>
        <div class="card-tools" style="margin-right: 5px;">
          <el-button  size="small" type="success" icon="el-icon-circle-plus-outline"
            @click="onAdd()">选择名单</el-button>
        </div>
      </div>
      <div class="card-body p-0" v-if="item!=null && item.length!=0">

        <div class="text item">
          <el-table v-if="item!=null" stripe sortable="custom" :data="item"
            style="width: 100%; margin-top: 10px; padding-left: 10px; padding-right: 10px;">
            <el-table-column type="index" width="100px;" label="序号"></el-table-column>
            <el-table-column prop="className" label="班级"></el-table-column>
            <el-table-column prop="studentNo" label="学号">
              <template slot-scope="scope">
                <span v-if="scope.row.studentNo == null || scope.row.studentNo == ''">/</span>
                <span v-else>{{scope.row.studentNo}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="studentName" label="学生">
              <template slot-scope="scope">
                <span v-if="scope.row.studentName == null || scope.row.studentName == ''">/</span>
                <span v-else>{{scope.row.studentName}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="roleName" label="角色" v-if="joinType==1">
              <template slot-scope="scope">
                <el-select v-model="scope.row.roleId" placeholder="请选择" :size="'mini'" :disabled="scope.row.scoring">
                  <el-option v-for="item in options"  :key="item.roleId" :label="item.name" :value="item.roleId">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column prop="applyDate" label="报名时间">
              <template slot-scope="scope">
                {{scope.row.applyDate | dateformatMinute}}
              </template>
            </el-table-column>
            <el-table-column prop="attended" label="签到" width="80px" v-if="activityData.isAttendance">
              <template slot-scope="scope">
                <svg class="icon" aria-hidden="true" v-if="scope.row.attended">
                  <use xlink:href="#fax-circle-check-o"></use>
                </svg>
                <svg class="icon" aria-hidden="true" v-else>
                  <use xlink:href="#fax-times-circle-o"></use>
                </svg>
              </template>
            </el-table-column>
            <el-table-column prop="images" label="上传（点击图片浏览）" width="160px" v-if="activityData.isUpload">
              <template slot-scope="scope">
                <!-- <div>
                  <el-image v-for="(p1,index1) in scope.row.images" :key="index1"
                    style="width: 45px; height: 45px;margin-right: 5px;" :src="p1" :preview-src-list="scope.row.images">
                  </el-image>
                </div> -->
                <div v-if="scope.row.images.length>0">
                  <el-image style="width: 24px; height: 24px" :src="scope.row.images[0]"
                    :preview-src-list="scope.row.images">
                  </el-image>
                </div>
                <div class="color-gray" v-else>
                  无上传附件
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="status" label="是否计入综合分">
              <template slot-scope="scope">
                <el-radio-group v-model="scope.row.status">
                  <el-radio :label="3" :disabled="scope.row.scoring">是</el-radio>
                  <el-radio :label="2" :disabled="scope.row.scoring">否</el-radio>
                </el-radio-group>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <comPage :paging="paging" :isPage="false">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>

    </el-card>

    <div class="row" style="margin-top: 10px;">
      <div class="col-6">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
        <el-button slot="reference" type="primary" icon="el-icon-circle-check" @click="onSave()">计入综合分</el-button>
      </div>
      <div class="col-6 text-right">
        <el-button @click="onCheckAll">
          <svg class="icon" aria-hidden="true" v-if="iconAllCheck">
            <use xlink:href="#fax-circle-check"></use>
          </svg>
          <svg class="icon" aria-hidden="true" v-else>
            <use xlink:href="#fax-circle-o"></use>
          </svg>
          全选
        </el-button>
      </div>
    </div>
    <el-dialog :title="'选择班级'" :visible.sync="dialogFormVisible" width="60%" :close-on-click-modal="false"
      :close-on-press-escape="false" :append-to-body="true" :fullscreen="false">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px">
      <el-row :gutter="24" v-if="joinType==1">
        <el-col :span="24">
          <el-form-item label="活动角色" prop="roleId">
            <el-select v-model="ruleForm.roleId" placeholder="请选择">
              <el-option v-for="item in options" :key="item.roleId" :label="item.name" :value="item.roleId">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="24">
          <el-input placeholder="输入关键字进行过滤" v-model="filterTextSignUp">
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="24" class="EqualDiv pt-2">
        <el-col :span="10" class="divTreeCont" style="height: 300px; overflow-y: auto;">
        <el-tree
          :data="signUpColleges"
          :props="defaultProps"
          node-key="id"
          show-checkbox
          ref="treeSignUp"
          :filter-node-method='filterNodeJoinIn'
          default-expand-all
          @check-change="handleCheckChange">
        </el-tree>
        </el-col>
        <el-col :span="4" class="VerticalMid" >
          <svg class="icon iconBox" aria-hidden="true">
            <use xlink:href="#fax-arrow-left-right-alt"></use>
          </svg>
        </el-col>
        <el-col :span="10" class="divTreeCont" style="height: 300px; overflow-y: auto;">
          <el-tree default-expand-all :data="selectTreeJoinInRanges" :props="defaultProps" node-key="id">
          </el-tree>
        </el-col>
      </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <div>
          <el-button @click="onClose()">取 消</el-button>
          <el-button type="primary" @click="onSummit()">确 定</el-button>
        </div>
      </span>
    </el-dialog>


  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import comImport from '@/components/Import.vue';
  // import search from '@/components/search.vue';
  export default {
    components: {
      comPage,
      comImport,
      // search
    },
    data() {
      return {
        //是否选择班级
        dialogFormVisible:false,
        signUpColleges: [],
        signUpCollegesReset: [],
        selectTreeJoinInRanges: [],
        selectTreeJoinInRangesReset: [],
        filterTextSignUp: '',
        signUpRanges:[],//选择的班级
        options:[],//活动角色
        ruleForm:{
          roleId:null,
          ranges:null,
        },
        // 用于全选状态
        iconAllCheck: false,
        itemData: [],
        dialogTitle: '',
        dialogVisible: false,
        SearchTitles: [],
        SearchList: null, //搜索集合
        id: null,
        parentType: null,
        fullscreenLoading: false,
        Title: null,
        ImportTitle: null,
        ImportUrl: null,
        ImportFile: null,
        item: null,
        page: null, //分页查询接口返回的结果
        pageBtnList: null, //分页左侧按钮集合
        paging: { //分页数据
          totalCount: 0,
        },
        selectRosters: [],
        // 角色
        optRoles: [],
        // 收集活动数据
        activityData: {},
        defaultProps: {
          children: 'children',
          label: 'name'
        },
        rules:{
          roleId: [{
              required: true,
              message: '请选择活动角色',
              trigger: 'change'
            },
          ],},
        joinType: null,
      };
    },
    created() {
      let that = this;
      that.id = that.$route.params.id;
      that.parentType = that.$route.params.type;
      var detailUrl = null;
      if (that.id != null && typeof(that.id) != "undefined" && that.id != "") {
        detailUrl = "/api/admin/activity/" + that.id;
      }
      ajaxGet(that,'/api/admin/activityrole',null,function(resRole){
            if(!regNullArray(resRole)){
              that.options = resRole;
            }
      })

      that.fullscreenLoading = true;
      ajaxGet(that, detailUrl, null, function(r) {
        if (r != null) {
          // 获取
          that.joinType = r.joinType;
          that.activityData = r;
          that.Title = r.name;
          // 获取这个活动勾选的几个角色
          for (let item of r.configs) {
            that.optRoles.push({
              value: item.roleId,
              label: item.roleName,
            })
          }
          that.ruleForm.roleId = r.roleId;
          that.optRoles = that.onArrUnique(that.optRoles);
          // if (r.isApply) {
          //   that.Title = that.Title + '【确认最终名单】';
          //   that.ImportTitle = '导入报名名单';
          //   that.ImportUrl = '/api/admin/activity/registerroster/' + that.id;
          //   that.ImportFile = '/Templates/活动名单导入模版.xlsx';
          // } else if (r.isApply == false && r.isUpload == true) {
          //   that.Title = that.Title + '【确认最终名单】';
          //   that.ImportTitle = '导入参与名单';
          //   that.ImportUrl = '/api/admin/activity/registerroster/' + that.id;
          //   that.ImportFile = '/Templates/活动名单导入模版.xlsx';
          // }
          that.Title = that.Title + '【确认最终名单】';
          that.ImportTitle = '导入参与名单';
          that.ImportUrl = '/api/admin/activity/participateroster/' + that.id;
          that.ImportFile = '/Templates/活动名单导入模版.xlsx';
        }
        that.fullscreenLoading = false;
      });
      Init(that);
    },

    methods: {
      // 去除重复性数据
      onArrUnique(arr) {
        const res = new Map();
        return arr.filter((arr) => !res.has(arr.label) && res.set(arr.label, 1));
      },
      /**
       * @description 全选
       */
      onCheckAll() {
        let that = this;
        if (that.iconAllCheck) {
          that.iconAllCheck = false;
        } else {
          that.iconAllCheck = true;
        }
        let tempArr = [];
        for (var item of that.item) {
          let tempStatus = that.iconAllCheck ? 3 : 2;
          tempArr.push({
            applyDate: item.applyDate,
            attachment: item.attachment,
            attended: item.attended,
            className: item.className,
            images: item.images,
            photo: item.photo,
            roleId: item.roleId,
            roleName: item.roleName,
            rosterId: item.rosterId,
            scoring: item.scoring,
            status: item.scoring ? item.status : tempStatus,
            statusString: item.statusString,
            studentId: item.studentId,
            studentName: item.studentName,
            studentNo: item.studentNo,
          });
        }
        that.item = tempArr;
      },
      onImportSuccess(param) {
        let that = this;
        Init(that);
      },
      handleCheckChange(data, checked, indeterminate){
        let that = this;
        if (data.parentId != "00000000-0000-0000-0000-000000000000") {
          if (checked == true && data.id != null && typeof(data.id) != "undefined" ) {
            if(data.id!='00000000-0000-0000-0000-000000000000'){
              that.signUpRanges.push(data.id);
            }
          } else if (checked == false && data.id != null && typeof(data.id) != "undefined") {
            let newRanges = that.signUpRanges.filter((item, index, arr) => {
              return item != data.id;
            });
            that.signUpRanges = newRanges;
          }
          that.selectSigeUpRanges = [];
          for (var classId of that.signUpRanges) {
            GetSelectSigeUpRanges(that, classId);
          }
          that.selectTreeJoinInRanges = [];
          that.selectTreeJoinInRanges = toTreeByRecursion(that.selectSigeUpRanges, 'id', 'parentId', null, 'children');
          that.selectTreeJoinInRangesReset = JSON.parse(JSON.stringify(that.selectTreeJoinInRanges));
        }
      },
      //取消
      onClose(){
          let that = this;
          that.dialogFormVisible = false;
      },
      //确认
      onSummit(){
          let that = this;
          if (regNullArray(that.signUpRanges)) {
            warning(that, '请选择班级');
            return false;
          }
          that.$refs["ruleForm"].validate(valid => {
            if (valid) {
              that.fullscreenLoading = true;
              ajaxPut(that,'/api/admin/activity/addparticipateroster?activityId='+that.id+'&roleId='+that.ruleForm.roleId,that.signUpRanges,function(res){
                that.dialogFormVisible = false;
                that.fullscreenLoading = false;
                ajaxGet(that, "/api/admin/activity/roster/" + that.id, null, function(r1) {
                  var item = JSON.parse(JSON.stringify(r1));

                  that.item = r1;
                  that.paging.totalCount = r1.length;
                  for (var it of r1) {


                    it.images = [];
                    if (it.attachment != null && it.attachment != "") {
                      var images = it.attachment.split('|');
                      for (var it1 of images) {
                        it.images.push(that.$ServerUrl() + it1);
                      }
                    }
                  }
                });
              })
            }

          })
      },
      filterNodeJoinIn(value, data) {
        if (!value) return true;
        return data.name.indexOf(value) !== -1;
      },
      //选择名单
      onAdd(){
        let that = this;
        let resArrUnique = new Map();
        ajaxGet(that, "/api/admin/sysuser/userfilternograduate", null, function(resultClass) {
          let arrCollege = []
          resultClass.filter((arr) => !resArrUnique.has(arr.collegeId) && resArrUnique.set(arr.collegeId,
                1))
          .forEach(function(item) {
            arrCollege.push({
              name: item.college,
              id: item.collegeId,
              parentId: '00000000-0000-0000-0000-000000000000',
              children:[]
              });
           });
          arrCollege.forEach(item =>{
            for(var it of resultClass){
              if(it.collegeId==item.id){
                item.children.push({
                     id:it.classId,
                     name:it.alias,
                     parentId:it.collegeId,
                     children:[],
                })
              }
            }
          });
          that.colleges = [{
            id: "00000000-0000-0000-0000-000000000000",
            name: "全校学生",
            children: arrCollege,
            parentId: null,
          }];
          that.allColleges = [];
          InitCollege(that, that.colleges);
          that.signUpColleges = JSON.parse(JSON.stringify(that.colleges));
          that.signUpCollegesReset = JSON.parse(JSON.stringify(that.signUpColleges));
          try {
            that.$refs["ruleForm"].resetFields();
          } catch {}
          that.selectTreeJoinInRanges = [];
          that.fullscreenLoading = false;
        })
        that.dialogFormVisible = true;
      },
      /**
       * @description 计入综合分
       */
      onSave() {
        let that = this;
        var rosterIds = [];
        for (var it of that.item) {
          if (it.status == 3 && !it.scoring) {
            // 计入综合分的数组
            rosterIds.push({
              rosterId: it.rosterId,
              roleId: it.roleId
            });
          }
        }
        var title = "";
        if (rosterIds != null && rosterIds.length != 0) {
          title = "计入综合分是活动的最终操作流程，完成后确认通过的学生将增加相应的综合分数，但不能再调整活动名单和编辑、修改、删除等操作，是否确定？";
          confirm(that, title, function() {
            that.fullscreenLoading = true;

            ajaxPut(that, "/api/admin/activity/ParticipateConfirm/" + that.id, rosterIds, function(r) {
              that.fullscreenLoading = false;
              that.onBack();
            });
          });
        } else {
          title = "当前没有选择人员计入综合分!";
          warning(that, title);
        }


      },
      onBack() {
        let that = this;
        back(that);
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      onSearch(params) {
        let that = this;
        let data = {};
        let searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = element.data;
          }
        });
        data["url"] = that.$route.query.url;
        data.PageNumer = 1;
        routerPath(that, "/Admin/activity/roster/" + that.parentType + "/" + that.id, data, null, "PageSize");
      },
      pagingClick(type, val) {
        let that = this;
        let url = "/Admin/activity/roster/" + that.parentType + "/" + that.id;
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "Name",
            "ClassId",
            "StudentNo",
            "url"
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "Name",
            "ClassId",
            "StudentNo",
            "url"
          );
        }
      },
    },
    watch: {
      $route() {
        let that = this;
        Init(that);
      },
      filterTextSignUp(val) {
        this.$refs.treeSignUp.filter(val);
      },
    },
  };
  function GetSelectSigeUpRanges(that, objID) {
    let temp = that.allColleges.find(item => item.id == objID);
    if (temp != undefined) {
      if (that.selectSigeUpRanges.find(item => item.id == temp.id) == undefined) {
        that.selectSigeUpRanges.push(temp);
        if (!regNull(temp.parentId)) {
          GetSelectSigeUpRanges(that, temp.parentId);
        }
      }
    }
  }
  function InitCollege(that, val) {
    if (val.length !== 0) {
      val.forEach((item) => {
        that.allColleges.push(item);
        if (item.children != null && typeof(item.children) != "undefined" && item.children.length >= 1) {
          InitCollege(that, item.children);
        }
      });
    }
  }
  function Init(that) {
    pagingPage(that);
  }

  function pagingPage(that) {
    paging(
      that,
      function(r) {

        that.SearchList = [{
            type: "input",
            Name: "班级",
            data: r.ClassId,
            holder: "请输入班级",
            zhi: "ClassId"
          },
          {
            type: "input",
            Name: "姓名",
            data: r.Name,
            holder: "请输入学生姓名",
            zhi: "Name"
          },
          {
            type: "input",
            Name: "学号",
            data: r.StudentNo,
            holder: "请输入学号",
            zhi: "StudentNo"
          },
        ];

        let data = {
          StudentNo: r.StudentNo,
          Name: r.Name,
          ClassId: r.ClassId,
        };

        ajaxGet(that, "/api/admin/activity/roster/" + that.id, null, function(r1) {
          var item = JSON.parse(JSON.stringify(r1));
          // if (data != null) {
          //   var newItem = [];
          //   if (data.StudentNo != null && data.StudentNo != "") {
          //     newItem = [];
          //     for (var it of item) {
          //       if (it.studentNo.indexOf(data.StudentNo) >= 0) {
          //         newItem.push(it);
          //       }
          //     }
          //     item = JSON.parse(JSON.stringify(newItem));
          //   }

          //   if (data.Name != null && data.Name != "") {
          //     newItem = [];
          //     for (var it of item) {
          //       if (it.studentName.indexOf(data.Name) >= 0) {
          //         newItem.push(it);
          //       }
          //     }
          //     item = JSON.parse(JSON.stringify(newItem));
          //   }

          //   if (data.ClassId != null && data.ClassId != "") {
          //     newItem = [];
          //     for (var it of item) {
          //       if (it.className.indexOf(data.ClassId) >= 0) {
          //         newItem.push(it);
          //       }
          //     }
          //     item = JSON.parse(JSON.stringify(newItem));
          //   }
          // }
          let tempArr = [];
          // 判断是否签到
          if (that.activityData.isAttendance) {
            // 需要签到
            tempArr = r1.filter(item => item.attended == true);
          } else {
            //不需要签到
            tempArr = r1.filter(item => item.status == 3);
          }

          that.item = r1;
          that.paging.totalCount = tempArr.length;
          for (var it of tempArr) {


            it.images = [];
            if (it.attachment != null && it.attachment != "") {
              var images = it.attachment.split('|');
              for (var it1 of images) {
                it.images.push(that.$ServerUrl() + it1);
              }
            }
          }
        });
      },
      "Name",
      "StudentNo",
      "ClassId"
    );
  }
</script>


<style scoped="scoped">
  .divTreeCont {
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    padding: 15px;
    min-height: 150px;
  }

</style>
