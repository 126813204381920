<!--
  系统管理-角色管理
-->
<template>
  <div class="SubWebPage"  v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          角色管理
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <search :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null"></search>
            </li>
            <li class="nav-item">
              <el-button type="success" v-if="isPower.sysrole_create == true" icon="el-icon-circle-plus-outline"  @click="onAdd()">新增</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="item!=null && item.length!=0">
        <el-table stripe :data="item" class="tableTwo table-hover text-nowrap "
        					style="width: 100%; margin-top: 0px; ">
          <el-table-column type="index" width="120px;" label="序号"></el-table-column>
          <el-table-column prop="name" label="角色">
        	  <template slot-scope="scope">
        		  <span>{{scope.row.name}}</span>
        	  </template>
          </el-table-column>
          <el-table-column prop="description" label="描述"></el-table-column>
          <el-table-column label="操作" width="340px">
        	<template slot-scope="scope">
        		<operationBtn :btnName="'查看'" v-if="isPower.sysrole_details == true" @click.native="onDetail(scope.row.roleId)"></operationBtn>
        		<operationBtn :btnName="'编辑'" v-if="isPower.sysrole_edit == true" @click.native="onEdit(scope.row.roleId)"></operationBtn>
        		<operationBtn :btnName="'删除'" v-if="!scope.row.inner && isPower.sysrole_delete == true" @click.native="onDelete(scope.row.roleId)"></operationBtn>
        		<operationBtn :btnName="'授权'" v-if="isPower.sysrole_authorize == true" @click.native="onPower(scope.row.roleId)"></operationBtn>
            <operationBtn :btnName="'原因授权'" v-if="isPower.sysrole_authorize == true" @click.native="onAuthorization(scope.row.roleId,1)"></operationBtn>
        	</template>
         </el-table-column>
        </el-table>

        <comPage
          :paging="page"
          :pageBtnList="pageBtnList"
          @pagingClick="pagingClick"
          @pageBtnClick="pageBtnClick"
        ></comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
  </div>
</template>
<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';
  export default{
    components:{
      comPage,operationBtn,search
    },
    data(){
      return{
				fullscreenLoading:false,
				page:null,//分页查询接口返回的结果
				item:[], //列表数据
				pageBtnList:null,//分页左侧按钮集合
				paging: { //分页数据
					pageLength: 0, // 总共页数
					Size: 10, // 当前请求数量
					Index: 1 //当前在第几页
				},
				isPower: {
						//增删改查 权限管理
						sysrole_details: false,
						sysrole_create: false,
						sysrole_edit: false,
						sysrole_delete:false,
						sysrole_authorize:false,
				},
				SearchList: null,//搜索集合
      };
    },
	created()
	{
		let that = this;
		isPower(that, "sysrole_details","sysrole_details");
		isPower(that, "sysrole_create","sysrole_create");
		isPower(that, "sysrole_edit","sysrole_edit");
		isPower(that, "sysrole_delete","sysrole_delete");
		isPower(that, "sysrole_authorize","sysrole_authorize");
		pagingPage(that);
	},
	methods:{
    /**
     * @description 批量授权
     */
    onAuthorization(id,type){
      let that=this;
      that.$router.push({
        path: "/Admin/MoralityReason/Authorization/" + type,
        query: {
          url: that.$router.history.current.fullPath,
          roleId : id,
        },
      });
    },
		onSearch(params)
		{
			let that = this;
			let data = {};
			let searchData = params;
			searchData.forEach(element => {
				if (element.data) {
					data[element.zhi] = element.data;
				}
			});
			data.PageNumer = 1;
			routerPath(that, "/Admin/role/index", data, null, "PageSize");
		},
		onPower(Id)
		{
			let that = this;
			that.$router.push({
				path: "/Admin/role/power/" + Id,
				query: { url: that.$router.history.current.fullPath }
			});
		},
		onAdd()//新增
		{
			let that = this;
			//that.$router.push('/Admin/role/Create');
			that.$router.push({
				path: "/Admin/role/Create",
				query: { url: that.$router.history.current.fullPath }
			});
		},
		onDetail(Id)//查看
		{
			let that = this;
			that.$router.push({
				path: "/Admin/role/Detail/" + Id,
				query: { url: that.$router.history.current.fullPath }
			});

		},
		onEdit(Id)//编辑
		{
			let that = this;
			that.$router.push({
				path: "/Admin/role/Edit/" + Id,
				query: { url: that.$router.history.current.fullPath }
			});
		},
		onDelete(Id) //删除
		{
			let that = this;
			confirmDelete(that,null,function(res){
				if(res == true)
				{
					that.fullscreenLoading = true;
					ajaxDelete(that,"/api/admin/sysRole/" + Id,null,function(r){
						that.fullscreenLoading = false;
						pagingPage(that);
					});
				}
			})
		},
		pageBtnClick(index) {
			//分页组件左侧的按钮事件，按顺序进行判断
		},
		pagingClick(type, val) {
			let that = this;
			let url = "/Admin/Role/Index";
			if (type == 1) {//更改每页条数触发的事件
				routerPath(
					that,
					url,
					"PageSize",
					val,
					"PageNumer",
					"Name",

				);
			} else { //更改当前页时触发的事件
				routerPath(
					that,
					url,
					"PageNumer",
					val,
					"PageSize",
					"Name",
				);
			}
		},
	},
	watch: {
		$route() {
			let that = this;
			pagingPage(that);
		}
	}
  };

 function pagingPage(that) {
	  paging(
		  that,
		  function (r) {
			  let Myurl = "/api/admin/sysRole/search";
			  let data = {
				  PageNumer: r.PageNumer,
				  PageSize: r.PageSize,
				  Name: r.Name,
			  };

			  that.SearchList = [
				  {
					  type: "input",
					  Name: "角色名称",
					  data: r.Name,
					  holder: "请输入角色名称",
					  zhi: "Name"
				  },
			  ];
			  that.fullscreenLoading = true;
			  ajaxGet(that, Myurl, data, function (r) {
				  that.page = r;
				  that.item = r.items;
				  that.fullscreenLoading = false;
			  });
		  },
		  "Name",
	  );
  }
</script>

<style scoped="scoped">

</style>
