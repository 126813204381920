<!--
 ECHARTS 的 雷达图
-->
<template>
  <!-- 250px -->
  <div :id="comID" :style="pieStyle"></div>
</template>

<script>
  import 'echarts/lib/component/tooltip';
  import 'echarts/lib/component/legend';
  import 'echarts/lib/component/grid';

  // 引入基本模板
  import * as echarts from 'echarts/lib/echarts';

  import {
    GridComponent
  } from 'echarts/components';
  echarts.use([GridComponent]);

  // 引入柱状图组件
  require('echarts/lib/chart/pie');
  export default {
    name: 'report_proportion',
    props: {
      //ID
      comID: {
        type: String,
        default: 'myChart'
      },
      // 高度
      comHeight: {
        type: Number,
        default: 250
      },
      // 颜色数组
      comColor: {
        type: Array,
        default: () => {
          return ['#7964E4', '#2291D9', '#73CF43', '#F9BB4A'];
        }
      },
      // 数据
      comData: {
        type: Array,
        default: () => {
          return [];
        }
      }
    },
    data() {
      return {
        pieStyle: '',
        myChart: {},
        arrData: [],
      };
    },
    watch: {
      'arrData': {
        handler: function(newVar, oldVar) {
          this.arrData = newVar;
        },
        deep: true
      },

    },
    created() {
      let that = this;
      if (that.comData != null && that.comData.length != 0) {
        that.arrData = that.comData;
        that.$nextTick(() => {
          that.drawLine();
        });
        that.pieStyle = 'width: 100%; height:' + (that.comHeight * 0.0625) + 'rem;';
      }

    },
    mounted() {
      let that = this;
      window.onresize = function() {
        that.myChart.resize();
        that.pieStyle = 'width: 100%; height:' + (that.ChartHeight * 0.0625) + 'rem;';
      }
    },
    updated() {

    },
    methods: {
      // 生成
      onGetRadar(_data) {
        let that = this;

        if (_data != null && _data.length != 0) {
          that.arrData = _data;
          that.$nextTick(() => {
            that.drawLine();
          })
          that.pieStyle = 'width: 100%; height:' + (that.comHeight * 0.0625) + 'rem;';
        }


      },
      // 绘画雷达图
      drawLine() {
        let that = this;
        // 基于准备好的dom，初始化echarts实例
        that.myChart = echarts.init(document.getElementById(that.comID));
        // 绘制图表
        that.myChart.setOption({


          series: [{
            type: 'pie',
            center: ['50%', '50%'],
            radius: ['40%', '95%'],
            roseType: 'area',
            itemStyle: {
               borderRadius: 5,
              color: (params) => {
                return that.comColor.slice()[params.dataIndex];
              }
            },
            label: {
              show: true,
              position: 'outer',
              lineHeight: 0,
              backgroundColor: 'auto',
              padding: [2, -2, 2, -2],
              borderRadius: 2,
              formatter: '{top|{b}}\n{bottom|{d} %}',
              align: 'center',
              rich: {
                top: {
                  verticalAlign: 'bottom',
                  // bottom：文字在上圆点在下
                  // top: 文字在下圆点在上
                  padding: [20, 10, 5, 0],
                  // padding：[上， 右， 下，左]，
                  // 上：圆点到上文字间距, 文字上移距离
                  // 右：文字与右侧圆点间距, 文字左移距离
                  // 下：圆点到下文字间距, 文字下移距离
                  // 左：文字与左侧圆点间距, 文字右移距离
                  align: 'center',
                  // center:文字圆点居中，right文字在圆点左侧，left文字在圆点右侧
                  fontSize: 28,
                },
                bottom: {
                  padding: [5, 30, 10, 20],
                  verticalAlign: 'top',
                  fontSize: 22,
                  color: '#313E56'
                }
              }
            },

            data: that.comData
          }]

        });



      }
    }
  };
</script>

<style>

</style>
