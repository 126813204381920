<!--
  日常事务 - 日常动态管理 - 自我记录
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">

    <!-- 搜索 -->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          搜索
        </h3>
      </div>
      <div class="card-body ">
        <el-row :gutter="20">
          <el-col :lg="8" :sm="12" class="mb-3" v-if="!TeacherInfo.isTeacher">
            <div class="selTitleBox">
              <span class="spTitle">年级</span>
            </div>
            <div class="selBodyBox">
              <el-select v-model="searchForm.selCollegeVar" filterable clearable placeholder="请选择" class="width_100Pie"
                @change="onChangeCollege" @clear="onClearCollege">
                <el-option v-for="item in optCollege" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :lg="8" :sm="12" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">班级</span>
            </div>
            <div class="selBodyBox">
              <el-select v-model="searchForm.selClassVar" filterable :clearable="!TeacherInfo.isTeacher" placeholder="请选择" class="width_100Pie">
                <el-option v-for="item in arrClass" :key="item.value" :label="item.alias" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :lg="8" :sm="12" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">学生</span>
            </div>
            <div class="selBodyBox">
              <el-input placeholder="请输入学号/姓名" v-model="searchForm.Student" clearable></el-input>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="card-footer text-center">
        <el-button type="primary" class="mr-2" slot="append" icon="el-icon-search" @click.native="onSearch()">查询
        </el-button>
        <el-button slot="append" class="mr-2" icon="el-icon-refresh" @click.native="onResetSearch()">重置</el-button>
      </div>
    </el-card>
    <!-- 列表-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          记录信息
        </h3>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table :data="itemData" stripe class="elTableData">
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column prop="studentName" label="学生姓名" class="elColumn" width="120"
            :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="name" label="记录名称" class="elColumn" min-width="200"
            :show-overflow-tooltip="true"></el-table-column>
          <el-table-column label="记录时间" class="elColumn" min-width="140">
            <template slot-scope="scope">
              {{scope.row.recordDate | dateformatDay}}
            </template>
          </el-table-column>
          <el-table-column prop="place" label="记录地点" class="elColumn" min-width="200"
            :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="auditor" label="审核人" class="elColumn" width="120"
            :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="auditedBy" label="校审核人员" class="elColumn" width="120"
            :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="auditState" label="状态" class="elColumn" width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.auditState==1">{{scope.row.auditStateString}}</span>
              <span v-if="scope.row.auditState==2 || scope.row.auditState==4" class="color-success">{{scope.row.auditStateString}}</span>
              <span v-if="scope.row.auditState==3 || scope.row.auditState==5" class="color-danger">{{scope.row.auditStateString}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200" fixed="right">
            <template slot-scope="scope">
              <operationBtn v-if="isPower.selfreport_details" :btnName="'查看'"
                @click.native="onDetail(scope.row.reportId)"></operationBtn>
              &nbsp;
              <el-dropdown trigger="click" @command="handleCommand($event,scope.row.reportId)">
                <el-button size="mini">
                  更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item class="color-warning" command="audit"
                    v-if="isPower.selfreport_audit == true && (scope.row.auditState==1 || scope.row.auditState==2)">
                    审核</el-dropdown-item>
                  <el-dropdown-item class="color-danger" command="delete" v-if="isPower.selfreport_delete">
                    删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>

        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  export default {
    components: {
      operationBtn,
      comPage
    },
    data() {
      return {
        fullscreenLoading: false,
        random: 0, //刷新组件
        // 搜索
        SearchList: null,
        //列表数据
        itemData: [],
        //分页查询接口返回的结果
        page: null,
        //分页左侧按钮集合
        pageBtnList: null,
        //分页数据
        paging: {
          // 总共页数
          pageLength: 0,
          // 每页请求的数据量
          Size: 10,
          //当前在第几页
          Index: 1
        },
        optCollege: [],
        arrClass: [],
        // 搜索集合
        searchForm: {
          selCollegeVar: '',
          optCollege: [],
          selStatus: '',
          selClassVar: '',
          optClass: [],
          Student: '',
          //所有班级数据
          allClassData: [],
        },
        isPower: {
          //增删改查 权限管理
          selfreport_details: false,
          selfreport_delete: false,
          selfreport_audit: false,
        },
        SelectList: [],
        item: [],
        url: null, //当前页面路径,
        thWidth: 100,
        searchFormReset: null,

        TeacherInfo: {},
      };
    },
    methods: {
      /**
       * @description 更新选项事件
       * @param {Object} command
       * @param {Object} _valueID
       */
      handleCommand(command, _valueID) {
        let that = this;
        switch (command) {
          case 'audit': // 审核
            that.onAudit(_valueID);
            break;
          case 'delete': // 删除
            that.onDelete(_valueID);
            break;
        }
      },
      onClearCollege() {
        let that = this;
        that.arrClass = [];
        that.searchForm.selClassVar = '';
        for (let item of that.searchForm.allClassData) {
          that.arrClass.push({
            value: item.classId,
            label: item.name,
            alias:item.alias,
          });
        }
      },
      onChangeCollege(res) {
        let that = this;
        that.searchForm.selClassVar = '';
        let arrTemp = that.searchForm.allClassData.filter(item => item.collegeId == res);
        that.arrClass = [];
        for (let item of arrTemp) {
          that.arrClass.push({
            value: item.classId,
            label: item.name,
            alias:item.alias,
          });
        }
      },
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },
      onSearch(params) // 搜索
      {
        let that = this;
        that.paging.Index = 1;
        onGetDataPage(that);
      },
      onResetSearch() {
        let that = this;
        that.searchForm = JSON.parse(JSON.stringify(that.searchFormReset));
        that.paging.Index = 1;
        onGetDataPage(that);
      },
      onDetail(Id) {
        let that = this;
        that.$router.push({
          path: "/Admin/SelfReport/Details/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onAudit(Id) {
        let that = this;
        that.$router.push({
          path: "/Admin/SelfReport/Audit/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onDelete(Id) { //删除
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, "/api/admin/selfreport/" + Id, null, function(r) {
              that.fullscreenLoading = false;
              onGetDataPage(that);
            });
          }
        })
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      // 分页面按钮
      pagingClick(type, val) {
        let that = this;
        if (type == 1) {
          //更改每页条数触发的事件
          that.paging.Size = val;
          that.paging.Index = 1;
        } else {
          //更改当前页时触发的事件
          that.paging.Index = val;
        }
        onGetDataPage(that);
      },
    },
    created() {
      let that = this;
      that.TeacherInfo = onGetNowUser(that);

      isPower(that, "selfreport_details", "selfreport_details");
      isPower(that, "selfreport_delete", "selfreport_delete");
      isPower(that, "selfreport_audit", "selfreport_audit");

      let resArrUnique = new Map();
      that.optCollege = [];

      let resultClass = that.TeacherInfo.isTeacher ? that.TeacherInfo.manageClass : that.TeacherInfo.allClass;

      var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
      that.random += random;
      that.searchForm.allClassData = resultClass;
      // 年级
      if (!regNullArray(resultClass)) {
        resultClass.filter((arr) => !resArrUnique.has(arr.collegeId) && resArrUnique.set(arr.collegeId, 1)).forEach(function(item) {
          that.optCollege.push({
            label: item.college,
            value: item.collegeId,
          });
        });

        // 班级
        that.arrClass = [];
        for (let item of resultClass) {
          that.arrClass.push({
            value: item.classId,
            label: item.name,
            alias:item.alias,
          });
        }

        if (that.TeacherInfo.isTeacher) {
          that.searchForm.selCollegeVar = that.optCollege[0].value;
          that.searchForm.selClassVar = resultClass[0].classId;
        }

        that.searchFormReset = JSON.parse(JSON.stringify(that.searchForm));
      }
      onGetDataPage(that);

      that.thWidth = onGetWidth(that, [
        that.isPower.selfreport_details,
        that.isPower.selfreport_audit,
      ]);
    },

    watch: {
      $route() {
        let that = this;
        onGetDataPage(that);
      }
    }
  };

  function onGetDataPage(that) {
    if (that.$route.query.url == '/admin/Preparations/index') {
      const {
        Student,
      } = that.$route.query
      if (Student) {
        that.searchForm.Student = that.$route.query.Student
      }
    }
    let apiUrl = "/api/admin/selfreport/search";
    let data = {
      PageNumer: that.paging.Index,
      PageSize: that.paging.Size,
      Student: that.searchForm.Student,
      ClassId: that.searchForm.selClassVar,
      CollegeId: that.searchForm.selCollegeVar,
    };
    ajaxGet(that, apiUrl, data, function(resData) {
      resData.items.forEach(item => {
        item.isState = false;
      })
      that.page = resData;
      that.itemData = resData.items;
      for (var it of that.SelectList) {
        that.itemData.forEach(item => {
          if (it.reportId == item.reportId) {
            item.isState = true;
          } else {
            if (item.isState != true) {
              item.isState = false;
            }
          }
        })
      }
      that.fullscreenLoading = false;
    });
  }

</script>

<style scoped="scoped" lang="less">
  .selTitleBox {
    font-size: 14px;
  }

  .selTitleBox .spTitle {
    color: #303133;
  }


  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }

  .spIcon {
    width: 5px;
    height: 20px;
    border-radius: 5px;
    background-image: linear-gradient(#4545FF, #2D8EFF);
  }



  .wMax {
    width: 100% !important;
  }

  .navTabsCont .nav-link {
    font-size: 12px;
  }

  .navToolsCont {
    margin-top: 1px;
  }

  /*重置样式*/
  .table tbody td,
  .table thead th {
    font-size: 0.875rem;
    /*14*/
  }

  .table tr td,
  .table tr th {
    text-align: center;
  }

  .table tr th:nth-child(1),
  .table tr th:nth-child(2),
  .table tr td:nth-child(1),
  .table tr td:nth-child(2) {
    text-align: left;
  }

</style>
