<!--
  班主任手册-品德评语
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <search :ColNumber="3" :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList != null"></search>
    <el-card class="box-card" v-if="item == null && item.length == 0">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px">品德评语</h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <el-button v-if="isPower.studentcomment_create == true && isCurrentTeacher == true" type="success" icon="el-icon-circle-plus-outline" @click="onAdd()">
                新增
              </el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body">
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
    <div slot="header" class="card-header">
      <div class="card-tools">
        <ul class="nav nav-pills ml-auto">
          <li class="nav-item mr-1">
            <el-button type="success" icon="el-icon-circle-plus-outline" @click="onAiAdd()">AI评语</el-button>
          </li>
          <li class="nav-item">
            <!--导入-->
            <comImport
              @onImportSuccess="onImportSuccess"
              :template="'/templates/品德评语导入模版.xlsx'"
              :path="uploadInfoApi"
              :btnIcon="'el-icon-upload2'"
              :title="'批量导入品德评语 '"
              :btnName="'导入品德评语'"
              ref="conImportInfoFile"
              v-if="isPower.studentcomment_import == true"
            ></comImport>
          </li>
          <li class="nav-item">
            <el-button v-if="isPower.studentcomment_create == true && isCurrentTeacher == true" type="success" icon="el-icon-circle-plus-outline" @click="onAdd()">
              新增品德评语
            </el-button>
          </li>
        </ul>
      </div>
    </div>
    <el-row :gutter="20" style="margin-top: 25px" v-if="item != null && item.length > 0">
      <el-col :span="8" v-for="(p, index) in item" :key="index">
        <el-card class="box-card">
          <div slot="header" class="card-header">
            <h3 class="card-title titleCont" style="padding-left: 0px">
              {{ p.name }}
            </h3>
            <div class="card-tools">
              <ul class="nav">
                <li class="nav-item">
                  <operationBtn v-if="isPower.studentcomment_edit == true && isCurrentTeacher == true" :btnName="'编辑'" @click.native="onEdit(p.commentId)"></operationBtn>
                </li>
                <li class="nav-item">
                  <operationBtn v-if="isPower.studentcomment_delete == true && isCurrentTeacher == true" :btnName="'删除'" @click.native="onDelete(p.commentId)"></operationBtn>
                </li>
              </ul>
            </div>
          </div>
          <div class="card-body DetailsFonts min-h-170">
            <div class="contentHidden">
              <div class="spFontTitle">评语</div>
              <div>{{ onDelHtml(p.description) }}</div>
            </div>
          </div>
          <div class="card-footer">
            <operationBtn v-if="isPower.studentcomment_details == true" :btnName="'查看'" @click.native="onDetail(p.commentId)"></operationBtn>
            <el-tag size="medium" style="float: right" v-if="p.commentsRank == '优'" type="success" effect="plain">
              {{ p.commentsRank }}
            </el-tag>
            <el-tag size="medium" style="float: right" v-if="p.commentsRank == '良'" type="warning" effect="plain">
              {{ p.commentsRank }}
            </el-tag>
            <el-tag size="medium" style="float: right" v-if="p.commentsRank == '合格'" type="info" effect="plain">
              {{ p.commentsRank }}
            </el-tag>
            <el-tag size="medium" style="float: right" v-if="p.commentsRank == '不合格'" type="danger" effect="plain">
              {{ p.commentsRank }}
            </el-tag>
          </div>
        </el-card>
      </el-col>
      <el-col :span="24">
        <comPage :paging="page" :pageSizes="[12, 24, 48, 96]" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick"></comPage>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import '@/assets/css/Admin.css';
import operationBtn from '@/components/operationBtn.vue';
import search from '@/components/search.vue';
import comImport from '@/components/Import.vue';
import comPage from '@/components/Page.vue';
export default {
  name: '',
  components: {
    comImport,
    operationBtn,
    search,
    comPage
  },
  data() {
    return {
      fullscreenLoading: false,
      page: null, //分页查询接口返回的结果
      item: [], //列表数据
      pageBtnList: null, //分页左侧按钮集合
      paging: {
        //分页数据
        pageLength: 0, // 总共页数
        Size: 12, // 当前请求数量
        Index: 1 //当前在第几页
      },
      SearchList: null, //搜索集合
      isPower: {
        //增删改查 权限管理
        studentcomment_details: false,
        studentcomment_create: false,
        studentcomment_edit: false,
        studentcomment_delete: false,
        studentcomment_user: false,
        studentcomment_import: false
      },
      isCurrentTeacher: true, //历史班主任是否为当前用户
      ManagerClass: [], //班主任管理的班级的集合
      uploadInfoApi: '/api/admin/studentcomment/import',

      TeacherInfo: {} // 教师信息
    };
  },
  created() {
    let that = this;
    that.TeacherInfo = onGetNowUser(that);
    isPower(that, 'studentcomment_details', 'studentcomment_details');
    isPower(that, 'studentcomment_create', 'studentcomment_create');
    isPower(that, 'studentcomment_edit', 'studentcomment_edit');
    isPower(that, 'studentcomment_delete', 'studentcomment_delete');
    isPower(that, 'studentcomment_user', 'studentcomment_user');
    isPower(that, 'studentcomment_import', 'studentcomment_import');
    pagingPage(that);
  },
  methods: {
    /**
     * @description 去除HTML和截取字符串，最多140个字符
     * @param {Object} _value
     */
    onDelHtml(_value) {
      if (!regNull(_value)) {
        let strFont = delHtml(_value);
        if (strFont.length > 140) {
          return strFont.substring(0, 140) + '...';
        } else {
          return strFont;
        }
      }
    },
    onDetail(Id) {
      let that = this;
      that.$router.push({
        path: '/Admin/Adviser/StudentComment/Details/' + Id,
        query: {
          url: that.$router.history.current.fullPath
        }
      });
    },
    onDelete(
      Id //删除
    ) {
      let that = this;
      confirmDelete(that, null, function (res) {
        if (res == true) {
          that.fullscreenLoading = true;
          ajaxDelete(that, '/api/admin/studentcomment/' + Id, null, function (r) {
            that.fullscreenLoading = false;
            pagingPage(that);
          });
        }
      });
    },
    onEdit(Id) {
      let that = this;

      that.$router.push({
        path: '/Admin/Adviser/StudentComment/Edit/' + Id,
        query: {
          url: that.$router.history.current.fullPath
        }
      });
    },
    onAdd() {
      let that = this;
      that.$router.push({
        path: '/Admin/Adviser/StudentComment/Create',
        query: {
          url: that.$router.history.current.fullPath
        }
      });
    },
    onAiAdd() {
      let that = this;
      that.$router.push({
        path: '/Admin/Adviser/StudentComment/AI',
        query: {
          url: that.$router.history.current.fullPath
        }
      });
    },
    // 导出学生信息成功后执行
    onImportSuccess(param) {
      let that = this;
      pagingPage(that);
    },
    onSearch(params) {
      let that = this;
      let data = {};
      let searchData = params;
      searchData.forEach((element) => {
        if (element.data) {
          data[element.zhi] = Join(element.data);
        }
      });
      data.PageNumer = 1;
      routerPath(that, '/Admin/Adviser/StudentComment/Index', data, null, 'PageSize');
    },
    pageBtnClick(index) {
      //分页组件左侧的按钮事件，按顺序进行判断
    },
    pagingClick(type, val) {
      let that = this;
      let url = '/Admin/Adviser/StudentComment/Index';
      let data = {};
      data.PageSize = 12;
      data.PageNumer = val;
      if (type == 1) {
        //更改每页条数触发的事件
        routerPath(that, url, 'PageSize', val, 'PageNumer', 'SemesterId', 'ClassId', 'Student');
      } else {
        //更改当前页时触发的事件
        routerPath(that, url, data, null, 'PageSize', 'SemesterId', 'ClassId', 'Student');
      }
    }
  },
  watch: {
    $route() {
      let that = this;
      pagingPage(that);
    }
  }
};

function pagingPage(that) {
  paging(
    that,
    function (r) {
      that.fullscreenLoading = true;
      ajaxGet(that, '/api/admin/Semester', null, function (semester) {
        if (!regNullArray(semester)) {
          let resultClass = that.TeacherInfo.isTeacher ? that.TeacherInfo.manageClass : that.TeacherInfo.allClass;
          let Myurl = that.TeacherInfo.isTeacher ? '/api/admin/studentcomment/search' : '/api/admin/studentcomment/user/search';

          var SemesterId = r.SemesterId;
          var semesters = [];
          for (var i in semester) {
            var it = {
              Title: semester[i].name,
              Value: semester[i].semesterId
            };
            if (regNull(SemesterId)) {
              if (semester[i].isCurrent == true) {
                SemesterId = semester[i].semesterId;
              }
            }
            semesters.push(it);
          }

          var ClassId = r.ClassId;
          var classList = [];

          if (regNull(ClassId) && !regNullArray(resultClass)) {
            ClassId = resultClass[0].classId;
          }

          for (var i in resultClass) {
            classList.push({
              Title: resultClass[i].alias,
              Value: resultClass[i].classId
            });
          }

          that.SearchList = [
            {
              type: 'select',
              Name: '学期',
              data: SemesterId,
              select: semesters, //选择的数据
              zhi: 'SemesterId',
              isClear: false, //select是否可以清除
              resetData: semesters[0].Value //重置数据默认第一个
            },
            {
              type: 'select',
              Name: '班级',
              data: ClassId,
              select: classList,
              zhi: 'ClassId',
              isClear: false, //select是否可以清除
              resetData: resultClass[0].classId //重置数据默认第一个
            },
            {
              type: 'input',
              Name: '学生',
              data: r.Student,
              holder: '请输入学号/姓名',
              zhi: 'Student'
            }
          ];

          if (regNull(r.PageSize)) {
            r.PageSize = 12;
          }

          let data = {
            PageNumer: r.PageNumer,
            PageSize: r.PageSize,
            SemesterId: SemesterId,
            ClassId: ClassId,
            Student: r.Student
          };
          ajaxGet(that, Myurl, data, function (r1) {
            that.page = r1;
            that.item = r1.items;

            var name = JSON.parse(that.$GetKey('Name'));
            that.isCurrentTeacher = true;
            if (that.item != '') {
              if (that.item[0].teacher != name) {
                that.isCurrentTeacher = false;
              }
            }
            that.fullscreenLoading = false;
          });
        } else {
          that.fullscreenLoading = false;
        }
      });
    },
    'SemesterId',
    'ClassId',
    'Student'
  );
}
</script>

<style scoped="scoped">
.contentHidden {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
}

.divBtnCont {
  text-align: right;
  padding-bottom: 0.9375rem;
  /*15px*/
}
</style>
