<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          基本信息
        </h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-4 colInfo">
            <label>批次学年</label>
            <div class="colFonts">
              {{ruleForm.year | flNoValue}}
            </div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>批次时段</label>
            <div class="colFonts">
              {{ruleForm.startDate | dateformatDay}} 至 {{ruleForm.startDate | dateformatDay}}
            </div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>申请学生</label>
            <div class="colFonts">
              {{ruleForm.studentName | flNoValue}}
            </div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>学号</label>
            <div class="colFonts">
              {{ruleForm.studentNo | flNoValue}}
            </div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>班级</label>
            <div class="colFonts">
              {{ruleForm.className | flNoValue}}
            </div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>申请时间</label>
            <div class="colFonts">
              {{ruleForm.createDate | dateformatMinute}}
            </div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>申请等级</label>
            <div class="colFonts">
              {{ruleForm.applyLevelString | flNoValue}}
            </div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>状态</label>
            <div class="colFonts">
              {{ruleForm.statusString | flNoValue}}
            </div>
          </div>
          <div class="col-lg-4 colInfo" v-if="ruleForm.level!=null">
            <label>评定等级</label>
            <div class="colFonts">
              {{ruleForm.levelString | flNoValue}}
            </div>
          </div>
        </div>
      </div>
    </el-card>

    <div v-if="arrScore!=null && arrScore.length!=0">
      <el-card class="box-card" v-for="(item,index) in arrScore" :key="index">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{item.semesterName}}
          </h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-4 colInfo" v-for="(list,indexTwo) in item.children" :key="indexTwo">
              <label>{{list.courseName}}</label>
              <div class="colFonts">
                {{list.total | flNoValue}}
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </div>

    <el-card class="box-card" v-if="ruleForm.description!=null">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          主要事迹
        </h3>
      </div>
      <div class="card-body">
        <div v-html="ruleForm.description"></div>
      </div>
    </el-card>


    <!--证明材料-->
    <el-card class="box-card" v-if="uploadFiles!=null && uploadFiles.length!=0">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          证明材料
        </h3>
      </div>
      <div class="card-body ">
        <el-carousel height="500px" :autoplay="!showViewer">
          <el-carousel-item v-for="(item,index) in uploadFiles" :key="index" class="VerticalMid">
            <img :src="item" @click="onPreview">
          </el-carousel-item>
        </el-carousel>
      </div>
    </el-card>

    <el-card class="box-card" v-if="itemData!=null && itemData.length!=0">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          审核记录
        </h3>
      </div>
      <div class="card-body ">
        <el-table :data="itemData" border style="width: 100%">
          <el-table-column prop="status" label="审核结果" width="200"></el-table-column>
          <el-table-column prop="auditor" label="审核人" width="140"></el-table-column>
          <el-table-column label="审核意见">
            <template slot-scope="scope">
              {{scope.row.reason!=null?scope.row.reason:'无'}}
            </template>
          </el-table-column>
          <el-table-column label="审核时间" width="140">
            <template slot-scope="scope">
              {{scope.row.createDate | dateformatMinute}}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
     <el-card class="box-card" v-if="isPower.scholarship_school_assess && ruleForm.status==4 && isLevel==null">
       <div slot="header" class="card-header">
         <h3 class="card-title titleCont">
           <span class="spIcon"></span>
           评定等级
         </h3>
       </div>
       <div class="card-body ">
         <el-select v-model="ruleForm.level" placeholder="请选择">
           <el-option v-for="item in optLevel" :key="item.Value" :label="item.Text" :value="item.Value">
           </el-option>
         </el-select>
       </div>
     </el-card>
    <div class="row" style="margin-top: 10px;">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
        <el-button icon="el-icon-circle-check" type="primary" @click="onAssess()">评定</el-button>
      </div>
    </div>

    <!--配合走马灯效果放大图片组件-->
    <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="uploadFiles" />

  </div>
</template>

<script>
  import "@/assets/css/Admin.css";
  import ElImageViewer from "element-ui/packages/image/src/image-viewer";

  export default {
    components: {
      ElImageViewer,
    },
    data() {
      return {
        // 加载动画
        fullscreenLoading: false,
        // 对象
        ruleForm: {
          applyId: null
        },
        //审核记录
        auditRecords: [],
        arrScore: [],

        // 放大图片需要
        showViewer: false,
        // 上传的附件图片
        uploadFiles: [],
        styleWidth: "25%",
        auditType: null,
        isPower: {
          scholarship_school_assess: false,
        },
        isLevel:null,
        optLevel:[],
        itemData:[],
      };
    },
    filters: {
      /**
       * @description 判断是否有数据
       * @param {Object} obj
       */
      flNoValue(obj) {
        if (obj == null || typeof obj == "undefined" || obj == "") {
          return "暂无数据";
        }
        return obj;
      },
    },
    created() {
      let that = this;
      let tempID = that.$route.params.Id;
      isPower(that, "scholarship_school_assess", "scholarship_school_assess");
      if (!regNull(tempID)) {
        that.ruleForm.applyId = tempID;
        that.onGetData(that);
      }
    },
    methods: {
      /**
       * @description 获取数据
       * @param {Object} that
       */
      onGetData(that) {
        that.fullscreenLoading = true;
        ajaxGet(that, '/api/admin/scholarshipapply/' + that.ruleForm.applyId, null, function(resApply) {
          if (!regNull(resApply)) {
            that.fullscreenLoading = false;
            that.ruleForm = resApply;
            that.arrScore = [];
            if (!regNullArray(resApply.total)) {
              let tempScore = that.onArrUnique(resApply.total);
              tempScore.forEach(function(item) {
                that.arrScore.push({
                  semesterId: item.semesterId,
                  semesterName: item.semesterName,
                  children: resApply.total.filter(list => list.semesterId == item.semesterId),
                });
              });
            }

            that.uploadFiles = [];
            if (!regNull(resApply.attachment)) {
              var files = resApply.attachment.split('|');
              for (var i in files) {
                that.uploadFiles.push(that.$ServerUrl() + files[i]);
              }
            }
             if(that.isPower.scholarship_school_assess && resApply.status==4){
               ajaxGet(that,'/api/admin/scholarshipapply/leveltype',null,function(resType){
                 that.optLevel=resType;
                 that.ruleForm.level=that.ruleForm.applyLevel.toString();
               });
             }
            ajaxGet(that,'/api/admin/scholarshipapply/audit/'+that.ruleForm.applyId,null,function(resAudit){
              that.itemData=resAudit;
            });
          }
        });
      },
       /**
        * @description 评定
        */
       onAssess(){
         let that=this;
         that.fullscreenLoading=true;
         let tempData={
           level:parseInt(that.ruleForm.level)
         }
         if(!regNull(that.ruleForm.level)){
           ajaxPut(that,'/api/admin/scholarshipapply/universitylevel/'+that.ruleForm.applyId+'?level='+tempData.level,null,function(res){
             that.onGetData(that);
           });
         }
       },
      /**
       * @description 去重
       * @param {Object} arr
       */
      onArrUnique(arr) {
        const res = new Map();
        return arr.filter((arr) => !res.has(arr.semesterId) && res.set(arr.semesterId, 1));
      },
      /**
       * @description 放大图片
       */
      onPreview() {
        this.showViewer = true;
      },
      /**
       * @description 关闭预览
       */
      closeViewer() {
        this.showViewer = false;
      },
      /**
       * @description 返回
       */
      onBack() {
        let that = this;
        back(that);
      },
    },
  };
</script>

<style scoped="scoped">
  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }

  .spIcon {
    width: 5px;
    height: 20px;
    border-radius: 5px;
    background-image: linear-gradient(#4545ff, #2d8eff);
  }

  .spTitle {
    float: left;
    margin-left: 20px;
    color: #323e56;
    letter-spacing: 2px;
    font-size: 16px;
    margin-top: -3px;
  }

  .wMax {
    width: 100% !important;
  }
</style>
