<!--
  用户管理 - 新增
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{Title}}
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24" v-if="ruleForm.roleId == null && isPower.sysrole_authorize == true">
            <el-col :span="24">
              <el-steps :active="1" align-center>
                <el-step title="角色信息"></el-step>
                <el-step title="功能权限"></el-step>
              </el-steps>
            </el-col>
          </el-row>
          <el-row :gutter="24" style="margin-top: 20px;">
            <el-col :span="24">
              <el-form-item label="名称" prop="name">
                <el-input show-word-limit auto-complete="new-name" maxlength="20" v-model="ruleForm.name"
                  placeholder="请输入名称,长度不能超过20个字符" class="formControl"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="描述">
                <el-input type="text" placeholder="请输入描述" v-model="ruleForm.description" maxlength="60" show-word-limit>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>

      </el-card>
      <div class="row" style="margin-top: 10px;">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
          <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
          <el-button type="primary" icon="el-icon-circle-check" @click="onSave()">提交</el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';

  export default {
    components: {},
    data() {
      return {
        ruleForm: //表单数据
        {
          roleId: null,
          name: null, //名称
          description: null, //描述
        },
        ruleFormReset: null,
        Title: "新增角色信息",
        fullscreenLoading: false,
        rules: {
          name: [{
              required: true,
              message: '名称不能为空',
              trigger: 'change'
            },
            {
              max: 20,
              message: '名称不能超过20个字符',
              trigger: 'change'
            },
          ],
        },
        isPower: {
          sysrole_authorize: false,
        },
      };
    },
    created() {
      let that = this;
      isPower(that, "sysrole_authorize", "sysrole_authorize");
      let id = that.$route.params.id;
      var detailUrl = null;
      if (id != null && typeof(id) != "undefined" && id != "") {
        that.Title = "编辑角色信息";
        that.ruleForm.reasonId = id;
        detailUrl = "/api/admin/sysRole/" + id;
      }
      that.fullscreenLoading = true;

      ajaxGet(that, detailUrl, null, function(r) {
        if (r != null) {
          that.ruleForm = r;

        }
        that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
        that.fullscreenLoading = false;

      });
    },
    methods: {
      onReset() {
        let that = this;
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      onSave() {
        let that = this;
        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            if (that.ruleForm.roleId == null) {
              that.fullscreenLoading = true;
              ajaxPost(that, "/api/admin/sysRole", that.ruleForm, function(r) {
                that.fullscreenLoading = false;
                if (that.isPower.sysrole_authorize == true) {
                  var url = that.$route.query.url;
                  if (url == null || typeof(url) == "undefined" || url == "") {
                    that.$router.push({
                      path: '/admin/role/power/' + r.message,
                      query: {
                        flag: that.isPower.sysrole_authorize
                      }
                    });
                  } else {
                    that.$router.push({
                      path: '/admin/role/power/' + r.message,
                      query: {
                        flag: that.isPower.sysrole_authorize,
                        url: url
                      }
                    });
                  }
                } else {
                  that.onBack();
                }
              });
            } else {
              that.fullscreenLoading = true;
              let value = setInt(that.ruleForm.priority);
              that.ruleForm.priority = value;
              ajaxPut(that, "/api/admin/sysRole/" + that.ruleForm.roleId, that.ruleForm, function(r) {
                that.fullscreenLoading = false;
                that.onBack();
              });
            }
          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              isError[0].querySelector('input').focus();
            }, 1)
            return false;
          }
        });
      },
      onBack() {
        let that = this;
        back(that);
      },
    },
  };
</script>

<style scoped="scoped">
  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }

  .spIcon {
    width: 5px;
    height: 20px;
    border-radius: 5px;
    background-image: linear-gradient(#4545FF, #2D8EFF);
  }

  .spTitle {
    float: left;
    margin-left: 20px;
    color: #323E56;
    letter-spacing: 2px;
    font-size: 16px;
    margin-top: -3px;
  }

  .wMax {
    width: 100% !important;
  }
</style>