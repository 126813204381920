<!--
  网站抬头
-->
<template>
  <div class="divHeaderBox clearfix" v-loading.fullscreen.lock="fullscreenLoading">
    <div v-show="isCollapse==true"
      style="float:left;margin-left: 80px;line-height: 60px; color:#fff;font-size:22px;cursor: pointer;">
      <i class="el-icon-s-unfold" @click="onSetCollapse(false)"></i>
    </div>
    <div v-show="isCollapse==false"
      style="float:left;margin-left: 240px;line-height: 60px; color:#fff;font-size:22px;cursor: pointer;">
      <i class="el-icon-s-fold" @click="onSetCollapse(true)"></i>
    </div>
    <!--快捷按钮-->
    <div class="divFastCont clearfix" style="padding-top: 17px;">
      <a class="headerLink" @click="onClickCharts" v-if="isPower.admin_statistics_behavior">
        <i class="el-icon-s-data"></i> 统计分析
      </a>
      <a class="headerLink" @click="onClickGrowth" v-if="isPower.growth_index == true">
        <i class="el-icon-pie-chart"></i> 综合评价
      </a>
      <a class="headerLink" @click="onClickGrowthArchives" v-if="isPower.growtharchives_index == true">
        <i class="el-icon-data-line"></i> 成长档案
      </a>
     <!-- <a class="headerLink" style="margin-right: 50px;">
        <i class="el-icon-tickets"></i> 操作说明
      </a> -->
      <!--
			<el-badge :value="3" class="item" style="margin-right: 50px;">
				<a class="headerLink" @click="badgeClick()">
				  <i class="el-icon-s-comment"></i> 即时信息
				</a>
			</el-badge>
     <a class="aFastList VerticalMid">
        <comAvatar :gender="'男'" :imgClass="'teachAvatar'"/>
      </a>
      <a class="aFastList VerticalMid">
        <svg class="icon faxIcon" aria-hidden="true">
            <use xlink:href="#fax-home-o"></use>
        </svg>
      </a>
      <a class="aFastList VerticalMid">
        <svg class="icon faxIcon" aria-hidden="true">
            <use xlink:href="#fax-bell-o"></use>
        </svg>
        <span class="spNum VerticalMid">52</span>
      </a>
      <el-popconfirm title="是否确认退出登录吗"  class="popconfirmBox"  @onConfirm="onLogout()">
        <el-button slot="reference" class="btnLogout">
          <svg class="icon faxIcon" aria-hidden="true">
              <use xlink:href="#fax-logout-o"></use>
          </svg>
        </el-button>
      </el-popconfirm>!-->
      <el-dropdown @command="handleCompanyChange" trigger="hover" style="cursor: pointer;">
        <span class="el-dropdown-link" style="color:#fff;font-size:14px;">
          {{userName}}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item icon="el-icon-lock" :command="'修改密码'">修改密码</el-dropdown-item>
          <el-dropdown-item icon="el-icon-user-solid" :command="'个人信息'">个人信息</el-dropdown-item>
          <el-dropdown-item divided icon="el-icon-bangzhu" :command="'技术支持'">技术支持</el-dropdown-item>
          <el-dropdown-item divided icon="el-icon-switch-button" :command="'退出登录'">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-dialog title="修改密码" :visible.sync="passwordDialogVisible" width="40%" :close-on-click-modal="false"
      :close-on-press-escape="false" :append-to-body="true" :fullscreen="false">
      <el-form :model="passwordRuleForm" :rules="passwordRules" ref="passwordRuleForm" label-width="90px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="原密码" prop="originalPassword">
              <el-input v-model="passwordRuleForm.originalPassword" placeholder="请输入原密码" auto-complete="new-password">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="输入密码" prop="newPassword">
              <el-input v-model="passwordRuleForm.newPassword" placeholder="请输入新密码" show-password
                auto-complete="new-password"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="确认密码" prop="againPassword">
              <el-input v-model="passwordRuleForm.againPassword" placeholder="请确认密码" show-password
                auto-complete="new-password"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="passwordDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onUpdatePassword()">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="个人信息" :visible.sync="userDialogVisible" width="50%" top="10px" :close-on-click-modal="false"
      :close-on-press-escape="false" :append-to-body="true" :fullscreen="false">
       <el-row :gutter="20">
         <el-col :lg="8" :sm="12" class="colInfo">
           <label>用户名</label>
           <div class="colFonts">
             {{itemData.userName | flNoValue}}
           </div>
         </el-col>
         <el-col :lg="8" :sm="12" class="colInfo">
           <label>姓名</label>
           <div class="colFonts">
            {{itemData.name | flNoValue}}
           </div>
         </el-col>
         <el-col :lg="8" :sm="12" class="colInfo">
           <label>所属年级</label>
           <div class="colFonts">
            {{itemData.college | flNoValue}}
           </div>
         </el-col>
         <el-col :sm="24" class="colInfo">
           <label>角色</label>
           <div class="colFonts">
           <span v-for="(p,index) in itemData.userRoles" :key="index"
             style="margin-right: 10px;">{{p.name|flNoValue}}</span>
           </div>
         </el-col>
       </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="userDialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>

    <el-dialog title="技术支持" :visible.sync="helpDialogVisible" width="50%" :close-on-click-modal="false"
      :close-on-press-escape="false" :append-to-body="true" :fullscreen="false">
      <el-form ref="helpRuleForm" label-width="90px">
        <el-row>
          <el-col :span="24">
            <p style="text-indent:28px;">学生综合评价系统由<a target="_blank"
                href="http://www.yaoyaotech.com">浙江尧瑶科技有限公司</a>自主研发，并提供技术支持。</p>
            <p style="text-indent:28px;">
              浙江尧瑶科技有限公司成立于2009年，是一家专业从事教育软件开发、教育大数据分析的科技企业。2013年成为浙江省科技型中小企业，2014年认定为软件企业。2017年被评为浙江省高成长科技型企业，2019年认定为国家高新技术企业，2022年复评国家高企。曾承担并完成国家科技部中小企业创新基金项目。
            </p>
            <p style="text-indent:28px;">
              如果在使用过程中出现疑问，可致电公司客服电话：400-672-0778，或联系相关技术支持人员。
            </p>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="helpDialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  //import comAvatar from '@/components/Avatar.vue';
  export default {
    props: {
      isCollapse: {
        type: Boolean,
        default: false,
      },
      userName: {
        type: String,
        default: ""
      }
    },
    components: {
      // comAvatar
    },
    data() {
      return {
        fullscreenLoading: false,
        itemData: {},
        passwordDialogVisible: false,
        passwordRuleForm: {
          originalPassword: null,
          newPassword: null,
          againPassword: null,
        },
        passwordRules: {
          originalPassword: [{
            required: true,
            message: '原密码不能为空',
            trigger: 'change'
          }, ],
          newPassword: [{
              required: true,
              message: '新密码不能为空',
              trigger: 'change'
            },
            {
              validator: (rule, value, callback) => {
                let that = this;
                if (that.passwordRuleForm.newPassword == null || that.passwordRuleForm.newPassword.length <= 0) {
                  callback(new Error("请输入密码！"));
                } else {
                  var reg = /^.{8,16}$/;
                  if (value != 0 && !reg.exec(value)) {
                    callback(new Error("密码为8-16位"));
                  }
                }
                callback();
              },
              trigger: 'change'
            }
          ],
          againPassword: [{
              required: true,
              message: '确认密码不能为空',
              trigger: 'change'
            },
            {
              validator: (rule, value, callback) => {
                let that = this;
                if (that.passwordRuleForm.againPassword == null || that.passwordRuleForm.againPassword.length <=
                  0) {
                  callback(new Error("请确认密码！"));
                } else {
                  if (that.passwordRuleForm.newPassword != value) {
                    callback(new Error("两次密码不一致！"));
                  }
                }
                callback();
              },
              trigger: 'change'
            }
          ],
        },
        userDialogVisible: false,
        userRuleForm: {

        },

        helpDialogVisible: false,
        isPower: {
          //增删改查 权限管理
          growtharchives_index: false,
          growth_index: false,
          noteacher_class_echarts: false,

          // 行为规范统计
          admin_statistics_behavior:false,
        },
      }
    },
    methods: {
      /**
       * @description 是否显示统计分析
       */
      onIsShowCharts(){
        let that = this;
        let temp = JSON.parse(that.$GetKey('teacher'));
        if(regNullArray(temp.allClass) && regNullArray(temp.manageClass)){
          return false;
        }
        return true;
      },
      /**
       * @description 统计分析
       */
      onClickCharts() {
        let that = this;
        that.$router.push({
          path: '/Admin/Statistics/Behavior',
					query: {
					  url: that.$router.history.current.fullPath
					}
        });
      },
      onClickGrowthArchives() {
        let that = this;
        that.$router.push({
          path: "/Admin/GrowthArchives/Index",
					query: {
					  url: that.$router.history.current.fullPath
					}
        });
      },
      onClickGrowth() {
        let that = this;
        that.$router.push({
          path: "/Admin/Growth/Index",
					query: {
					  url: that.$router.history.current.fullPath
					}
        });
      },
      badgeClick() {
        let that = this;
        alert("暂时无此功能");
      },
      handleCompanyChange(command) {
        let that = this;
        switch (command) {
          case "退出登录": {
            that.onLogout();
          }
          break;
        case "技术支持": {
          that.helpDialogVisible = true;
        }
        break;
        case "修改密码": {
          that.passwordRuleForm.originalPassword = "";
          that.passwordRuleForm.newPassword = "";
          that.passwordRuleForm.againPassword = "";
          that.passwordDialogVisible = true;
          try {
            that.$refs["passwordRuleForm"].resetFields();
          } catch {}
        }
        break;
        case "个人信息": {
          that.fullscreenLoading = true;
          ajaxGet(that, "/api/admin/sysuser/currentuser", null, function(r) {
            that.fullscreenLoading = false;
            that.itemData = r;
            that.userDialogVisible = true;
          })

        }
        break;
        }
      },
      onSetCollapse(val) {
        let that = this;
        that.$emit('onSetCollapse', val);
      },
      // 注销
      onLogout() {
        let that = this;
        WebLogout(that);

      },
      onUpdatePassword() {
        let that = this;


        that.$refs["passwordRuleForm"].validate(valid => {
          if (valid) {
            that.fullscreenLoading = true;
            var data = {
              oldPassword: that.$md5(that.passwordRuleForm.originalPassword),
              password: that.$md5(that.passwordRuleForm.newPassword)
            };
            ajaxPut(that, "/api/admin/sysuser/changepassword", data, function(r) {
              that.fullscreenLoading = false;
              that.passwordDialogVisible = false;
            });
            that.passwordDialogVisible = false;
          } else {

            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              isError[0].querySelector('input').focus();
            }, 1)
            return false;
          }
        });
      }
    },
    created() {
      let that = this;
      isPower(that, "growtharchives_index", "growtharchives_index");
      isPower(that, "growth_index", "growth_index");
      isPower(that, "noteacher_class_echarts", "noteacher_class_echarts");
      isPower(that, "admin_statistics_behavior", "admin_statistics_behavior");
    },
    filters: {
      flNoValue(obj) {
        if (obj == null || typeof(obj) == "undefined" || obj == '') {
          return '暂无数据';
        }
        return obj;
      }
    },
  };
</script>

<style scoped="scoped">
  .headerLink {
    margin-top: -17px;
    width: 60px;
    color: #fff;
    font-size: 14px;
    margin-right: 20px;
  }

  .headerLink:hover {
    color: #efefef;
  }

  .divHeaderBox {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    background-color: #406AD3;
    border-bottom: 1px solid #dee2e6;
    z-index: 1;
    min-width: 1024px;
  }

  .divHeaderBox .divSearchBox {
    background-color: #F2F4F6;
    top: 0.9375rem;
    /*15px*/
    height: 3.125rem;
    /*50px*/
    width: 23.75rem;
    /*380px*/
    left: 16.25rem;
    /*260px*/
    border-radius: 0.25rem;
    /*4px*/
    position: absolute;
  }

  .divHeaderBox .divSearchBox .txtSearch {
    background-color: transparent;
    height: 3.125rem;
    /*50px*/
    width: 20rem;
    /*320px*/
    border: none;
    padding: 0rem 1.25rem 0rem 1.875rem;
    /*0px 20px 0px 30px*/
    color: #999999;
  }

  .divHeaderBox .divSearchBox .abtnSearch {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 3.75rem;
    /*60px*/
    font-size: 1.375rem;
    /*22px*/
    color: #0F0F0F;
  }

  .divHeaderBox .divFastCont {
    position: absolute;
    right: 0.9375rem;
    /*15px*/
    top: 0rem;
    /*10px*/
  }

  .divHeaderBox .divFastCont .popconfirmBox,
  .divHeaderBox .divFastCont .aFastList {
    float: left;
    height: 3.75rem;
    /*60px*/
    width: 3.75rem;
    /*60px*/
    margin-left: 1.25rem;
    /*20px*/
    position: relative;
  }

  .divHeaderBox .divFastCont .aFastList .faxIcon {
    font-size: 1.625rem;
    /*26px*/
    color: #BFDEFF;
  }

  .divHeaderBox .divFastCont .aFastList .faxIcon:hover {
    color: #FFFFFF;
  }

  .divHeaderBox .divFastCont .aFastList .spNum {
    background-color: #FF5F5F;
    color: #FFFFFF;
    font-size: 0.75rem;
    /*12px*/
    position: absolute;
    top: 0.625rem;
    /*10px*/
    right: 0.625rem;
    /*10px*/
    width: 1.25rem;
    /*20px*/
    height: 1.25rem;
    /*20px*/
    border-radius: 50%;
  }
</style>

<style>
  .webDefault .divFastCont .teachAvatar {
    width: 1.875rem;
    /*30px*/
    height: 1.875rem;
    /*30px*/
  }

  .popconfirmBox .btnLogout {
    padding: 0;
    background-color: transparent;
    border: none;
    height: 60px;
    width: 60px;
    text-align: center;
    color: #BFDEFF;
    font-size: 1.625rem;
    /*26px*/
  }

  .popconfirmBox .btnLogout:hover {
    color: #FFFFFF;
  }
</style>
