import { render, staticRenderFns } from "./Details.vue?vue&type=template&id=607fd854&scoped=true"
import script from "./Details.vue?vue&type=script&lang=js"
export * from "./Details.vue?vue&type=script&lang=js"
import style1 from "./Details.vue?vue&type=style&index=1&id=607fd854&prod&scoped=scoped&lang=less"
import style2 from "./Details.vue?vue&type=style&index=2&id=607fd854&prod&lang=css"
import style3 from "./Details.vue?vue&type=style&index=3&id=607fd854&prod&scoped=scoped&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "607fd854",
  null
  
)

export default component.exports