<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="width: 100%; padding-left: 0px" v-if="!regNullArray(optTabs)">
          <ul class="nav nav-pills navTabsCont">
            <li class="nav-item" v-for="(item, index) in optTabs" :key="index">
              <span :class="currentIndex == index ? 'nav-link active' : 'nav-link'" role="button"
                @click="onCKTabs(index)">
                {{ item.name }}
              </span>
            </li>
          </ul>
        </h3>
      </div>
      <div class="card-body">
        <el-row :gutter="24">
          <el-col :span="8" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">学期</span>
            </div>
            <div class="selBodyBox">
              <el-select v-model="searchForm.semesterId" class="w-100" placeholder="请选择学期" filterable
                @change="onChangeSemester">
                <el-option v-for="(item, index) in optSemester" :key="index" :label="item.name"
                  :value="item.semesterId">
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="8" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">考勤日期</span>
            </div>
            <div class="selBodyBox">
              <el-cascader v-model="searchForm.attendanceId" class="w-100" placeholder="请选择考勤日期"
                :options="optAttendance" :props="{ expandTrigger: 'hover' }" filterable clearable></el-cascader>
            </div>
          </el-col>
          <el-col :span="8" class="mb-3" v-if="currentIndex == 1">
            <div class="selTitleBox">
              <span class="spTitle">学生</span>
            </div>
            <div class="selBodyBox">
              <el-input v-model="searchForm.Student" placeholder="请输入学生姓名或学号" clearable></el-input>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="card-footer text-center">
        <el-button type="primary" icon="el-icon-search" @click.native="onSearch()">查询</el-button>
        <el-button icon="el-icon-refresh" @click.native="onResetSearch()">重置</el-button>
      </div>
    </el-card>

    <div v-if="currentIndex == 0 || currentIndex == 1">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont" style="padding-left: 0px">
            {{ optTabs[currentIndex].name }}
          </h3>
          <div class="card-tools" v-if="currentIndex == 1 && isPower.admin_dormitoryattn_notcheckin_sign">
            <ul class="nav">
              <li class="nav-item">
                <el-button type="success" icon="el-icon-check" @click="onBatchSign()">批量打卡</el-button>
              </li>
            </ul>
          </div>
        </div>
        <div class="card-body p-0" v-if="!regNullArray(itemData) && currentIndex == 0">
          <el-table :data="itemData" stripe class="elTableData" :key="tableKEY">
            <el-table-column type="index" label="序号" width="80" min-width="100"></el-table-column>
            <el-table-column prop="text" label="楼名" class="elColumn" min-width="120"
              :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="total" label="总人数" class="elColumn" min-width="120"></el-table-column>
            <el-table-column prop="should" label="应到人数" class="elColumn" min-width="120"></el-table-column>
            <el-table-column prop="actual" label="实到人数" class="elColumn" min-width="120"></el-table-column>
            <el-table-column prop="leave" label="请假人数" class="elColumn" min-width="120"></el-table-column>
            <el-table-column label="当前打卡率（%）" class="elColumn" min-width="140">
              <template slot-scope="scope">
                <span class="color-primary">
                  {{ scope.row.scale }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="130" fixed="right">
              <template slot-scope="scope">
                <operationBtn :btnName="'查看'" ref="detail" @click.native="onDetail(scope.row)"></operationBtn>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="card-body p-0" v-else-if="!regNullArray(itemData) && currentIndex == 1">
          <el-table :data="itemData" stripe class="elTableData" @selection-change="handleSelectionChange"
            :key="tableKEY">
            <el-table-column type="selection" width="55" :selectable="selectable"></el-table-column>
            <el-table-column label="考勤日期" class="elColumn">
              <template slot-scope="scope">
                {{ scope.row.attendanceDate | dateformat('YYYY-MM-DD') }}
              </template>
            </el-table-column>
            <el-table-column prop="studentName" label="姓名" class="elColumn"></el-table-column>
            <el-table-column prop="studentNo" label="学号" class="elColumn"></el-table-column>
            <el-table-column prop="gender" label="性别" class="elColumn"></el-table-column>
            <el-table-column prop="class" label="班级" class="elColumn"></el-table-column>
            <el-table-column prop="dormitory" label="床位" class="elColumn"></el-table-column>
            <el-table-column label="打卡时间" class="elColumn">
              <template slot-scope="scope">
                <span v-if="!regNull(scope.row.attendDate)">
                  {{ scope.row.attendDate | dateformat('YYYY-MM-DD HH:mm') }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="mendedBy" label="补卡教师" class="elColumn"></el-table-column>
            <el-table-column prop="leaveId" label="请假标记" class="elColumn">
              <template slot-scope='scope'>
                <span v-if="scope.row.leaveId"  style="font-size: 14px; color: #409eff;cursor: pointer;" @click="onLeave(scope.row)">已请假
                  </span>
              </template>
            </el-table-column>

            <el-table-column label="操作" width="200" fixed="right">
              <template slot-scope="scope">
                <operationBtn :btnName="'打卡'" :btnType="'success'" ref="sign"
                  @click.native="onSign(scope.row.memberId)"
                  v-if="regNull(scope.row.attendDate) && isPower.admin_dormitoryattn_notcheckin_sign"></operationBtn>
                <operationBtn :btnName="'删除'" ref="delete" @click.native="onDelete(scope.row.memberId)"
                  v-if="isPower.admin_dormitoryattn_notcheckin_delete"></operationBtn>
              </template>
            </el-table-column>
          </el-table>
          <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
          </comPage>
        </div>
        <div class="card-body" v-else>
          <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
        </div>
      </el-card>
    </div>
    <div v-else-if="currentIndex == 2">
      <el-row :gutter="20" class="elRowBox" v-if="!regNullArray(itemData)">
        <el-col :md="12" :xs="24" v-for="(item,index) in itemData" :key="index" class=" mb-3">

          <el-card class="box-card" style="height: 100%;">
            <div slot="header" class="card-header">
              <h3 class="card-title titleCont">
                <span class="spIcon"></span>
                {{item.text}}
              </h3>
              <div class="card-tools">
                <ul class="nav">
                  <li class="nav-item">
                    <el-button @click="onGoNoPunchin(item.id)" >未打卡名单</el-button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="card-body">

              <div class="divProgressBody" v-for="(it,index) in item.floorList" :key="index">
                <div class="divFloorName">{{it.floor}} 层</div>
                <div class="divGraph">
                  <div class="progress">
                    <div class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" :style="'width: '+it.scale+'%'">{{it.scale}}%</div>
                  </div>
                </div>
                <div class="divNumber">{{it.actual+it.leave}}/{{it.total}}</div>
              </div>

            </div>
          </el-card>

        </el-col>
      </el-row>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </div>
    <div v-else-if="currentIndex == 2 && false" >
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont" style="padding-left: 0px;">
            宿舍楼打卡率
          </h3>
        </div>
        <div class="card-body">
          <comChartBar :comID="'myChartBar'" :comHeight="500" ref="myChartBar" :key="ChartBarKey"></comChartBar>
        </div>
      </el-card>

      <el-card class="box-card" >
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont" style="padding-left: 0px;">
            男女生打卡率
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="20">
            <el-col :span="12">
              <div class="comTitle"> 男生打卡率</div>
              <comChartBar :comID="'myChartPie1'" :comHeight="500" ref="myChartPie1" :key="ChartPieKey1"></comChartBar>
            </el-col>
            <el-col :span="12">
              <div class="comTitle"> 女生打卡率</div>
              <comChartBar :comID="'myChartPie2'" :comHeight="500" ref="myChartPie2" :key="ChartPieKey2"></comChartBar>
            </el-col>
          </el-row>
        </div>
      </el-card>
    </div>





  </div>
</template>

<script>
import "@/assets/css/Admin.css";
import operationBtn from "@/components/operationBtn.vue";
import comPage from '@/components/Page.vue';
import comChartBar from '@/components/Statistics/DivChartBar.vue';

export default {
  components: {
    operationBtn, comPage,comChartBar
  },
  data() {
    return {
      // 加载动画
      fullscreenLoading: false,
      // 列表数据
      itemData: null,
      attendanceId: null,
      // 搜索条件
      searchForm: {
        semesterId: null,
        attendanceId: [],
        Student: null,
      },
      searchFormReset: null,
      optSemester: [], // 学期
      optAttendance: [],// 考勤
      optTabs: [
        {
          id: 0,
          name: "宿舍考勤",
        },
        {
          id: 1,
          name: "未打卡名单",
        },
        {
          id: 2,
          name: "考勤统计",
        },
      ],
      currentIndex: 0,
      tableKEY: onGetRandomGUID(),
      // 选择的数组
      multipleSelection: [],
      page: null, //分页查询接口返回的结果
      pageBtnList: null, //分页左侧按钮集合
      paging: { //分页数据
        pageLength: 0, // 总共页数
        Size: 10, // 当前请求数量
        Index: 1 //当前在第几页
      },
      isPower: {
        admin_dormitoryattn_notcheckin_sign: false,
        admin_dormitoryattn_notcheckin_delete: false,
      },

      ChartBarKey: onGetRandomGUID(),
      ChartPieKey1: onGetRandomGUID(),
      ChartPieKey2: onGetRandomGUID(),


    };
  },
  methods: {
    onLeave(obj){
      let that = this;
      that.$router.push({
        path: "/Admin/LeaveMGT/Default/" + obj.leaveId,
        query: {
          url: that.$router.history.current.fullPath
        }
      });
    },
    onGoNoPunchin(id){
      let that = this;
      that.$router.push({
        path: "/Admin/Statistics/PunchInDetails",
        query: {
          attendanceId: that.searchForm.attendanceId[1],
          BuildingId:id,
          date:timeTransform(that.startDate,'YYYY-MM-DD'),
          url: that.$router.history.current.fullPath
        }
      });
    },
    pageBtnClick(index) {
      //分页组件左侧的按钮事件，按顺序进行判断
    },
    pagingClick(type, val) {
      let that = this;
      if (type == 1) {
        //更改每页条数触发的事件
        that.paging.Size = val;
        that.paging.Index = 1;
      } else {
        //更改当前页时触发的事件
        that.paging.Index = val;
      }
      onGetNotCheckIn(that);
    },
    /**
     * @description 删除
     * @param _dataID
     */
    onDelete(_dataID) {
      let that = this;
      confirmDelete(that, null, function (res) {
        if (res == true) {
          that.fullscreenLoading = true;
          ajaxDelete(that, "/api/attendance/member/" + _dataID, null, function (r) {
            that.fullscreenLoading = false;
            onGetNotCheckIn(that);
          });
        }
      })
    },
    /**
     * @description 批量手动考勤
     */
    onBatchSign() {
      let that = this;
      let list = that.multipleSelection.map(x => {
        return x.memberId;
      })
      that.fullscreenLoading = true;
      ajaxPost(that, "/api/attendance/sign", list, function (r) {
        that.fullscreenLoading = false;
        onGetNotCheckIn(that);
      });
    },
    /**
     * @description 手动考勤
     * @param _dataID
     */
    onSign(_dataID) {
      let that = this;
      that.fullscreenLoading = true;
      ajaxPost(that, "/api/attendance/sign", [_dataID], function (r) {
        that.fullscreenLoading = false;
        onGetNotCheckIn(that);
      });
    },
    // 设置禁止选中
    // 这个方法你有多少条数据就会执行多少次
    selectable(row, index) {
      // row是遍历的每一条数据，index是下标
      // 设置第一个为不可选中
      if (!regNull(row.attendDate)) {
        return false
      } else {
        return true
      }
    },
    /**
     * @description 全选
     * @param val
     */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * @description 搜索
     */
    onSearch() {
      let that = this;
      that.onSwitchGetData();
    },
    /**
     * @description 搜索重置
     */
    onResetSearch() {
      let that = this;
      that.searchForm = JSON.parse(JSON.stringify(that.searchFormReset));
      that.onChangeSemester(that.searchForm.semesterId);
      that.onSwitchGetData();
    },
    /**
     * @description 选择学期触发事件
     */
    onChangeSemester(objItem) {
      let that = this;
      let tempSemester = that.optSemester.find(item => item.semesterId == objItem);
      if (!regNull(tempSemester)) {
        that.searchForm.attendanceId = [];
        that.onGetAttendanceDate(tempSemester);
      }
    },
    /**
     * @description tabs页面切换事件
     * @param {Object} objIndex
     */
    onCKTabs(objIndex) {
      let that = this;
      that.currentIndex = objIndex;
      that.tableKEY = onGetRandomGUID();
      that.onSwitchGetData();
    },
    /**
     * @description 根据选项加载数据
     */
    onSwitchGetData() {
      let that = this;
      if (that.currentIndex == 0) {
        onGetDormitoryData(that);
      } else if (that.currentIndex == 1) {
        onGetNotCheckIn(that);
      }else if(that.currentIndex == 2) {
        onGetStatistics(that)
        // onGetChartData(that);
      }
    },
    /**
     * @description 去除重复值
     * @param arr
     */
    onArrUnique(arr) {
      const res = new Map();
      return arr.filter((arr) => !res.has(arr.label) && res.set(arr.label, 1));
    },
    /**
     * @description 考勤日期
     */
    onGetAttendanceDate(objItem) {
      let that = this;
      that.fullscreenLoading = true;
      let tpParams = {
        StartDate: timeTransform(objItem.startDate),
        EndDate: timeTransform(objItem.endDate),
        PageNumer: 1,
        PageSize: 9999999,
      }
      ajaxGet(that, '/api/attendance/search', tpParams, function (resAttendance) {
        that.fullscreenLoading = false;
        that.optAttendance = [];
        if (!regNullArray(resAttendance.items)) {
          // 处理成树型数据
          let tempDate = resAttendance.items.map(item => ({
            value: timeTransform(item.date, 'YYYY-MM-DD'),
            label: timeTransform(item.date, 'YYYY-MM-DD'),
            parentId: null,
          }));
          let tempAllData = resAttendance.items.map(item => ({
            value: item.attendanceId,
            label: item.attendanceTypeStr,
            parentId: timeTransform(item.date, 'YYYY-MM-DD'),
          }));
          that.optAttendance = onGetJsonToTree([...that.onArrUnique(tempDate), ...tempAllData], 'value', 'parentId', 'children');
        }
      });
    },
    // 详情
    onDetail(_data) {
      let that = this;
      that.$router.push({
        path: "/Admin/DormPunchIn/Floor/" + _data.id,
        query: {
          attendanceId: that.searchForm.attendanceId[1],
          name: _data.text,
          url: that.$router.history.current.fullPath,
        },
      });
    },
  },
  created() {
    let that = this;
    that.fullscreenLoading = true;

    isPower(that, 'admin_dormitoryattn_notcheckin_sign', 'admin_dormitoryattn_notcheckin_sign');
    isPower(that, 'admin_dormitoryattn_notcheckin_delete', 'admin_dormitoryattn_notcheckin_delete');
    that.currentIndex =  regNull(that.$route.query.currentIndex) ? 0 : that.$route.query.currentIndex
    // 获取学期数据
    ajaxGet(that, "/api/admin/semester", null, function (resSemester) {
      that.fullscreenLoading = false;
      that.optSemester = resSemester;
      if (!regNullArray(resSemester)) {
        let tempSemester = resSemester.find((item) => item.isCurrent == true);
        tempSemester = regNull(tempSemester) ? resSemester[0] : tempSemester;
        that.searchForm.semesterId = tempSemester.semesterId; // 默认学期
        that.searchFormReset = JSON.parse(JSON.stringify(that.searchForm));
        that.onGetAttendanceDate(tempSemester);
        setTimeout(function () {
          that.searchForm.attendanceId = [that.optAttendance[0].value, that.optAttendance[0].children[0].value]
          that.onSwitchGetData()
          return false;
        }, 500);

      }
    });
  },
};

function onGetDormitoryData(that) {
  that.itemData = [];
  if (!regNullArray(that.searchForm.attendanceId)) {
    that.fullscreenLoading = true;
    ajaxGet(that, "/api/attendance/statisbybuilding", { attendanceId: that.searchForm.attendanceId[1] },
      function (resData) {
        that.itemData = resData;
        that.fullscreenLoading = false;
      }
    );
  }
}

function onGetNotCheckIn(that) {
  that.itemData = [];
  if (!regNullArray(that.searchForm.attendanceId)) {
    let tpParams = {
      AttendanceId: that.searchForm.attendanceId[1],
      PageNumer: that.paging.Index,
      PageSize: that.paging.Size,
      IsAttend: false,
      Student: that.searchForm.Student,
    };
    that.fullscreenLoading = true;
    ajaxGet(that, "/api/attendance/membersearch", tpParams, function (resData) {
      that.page = resData;
      that.itemData = resData.items;
      that.fullscreenLoading = false;
    });
  }
}

 //获取统计数据
function onGetStatistics(that) {
        ajaxGet(that, '/api/attendance/statisbybuilding?attendanceId=' + that.searchForm.attendanceId[1], null, function(res) {
          if(!regNullArray(res)){
            that.itemData = res;
            that.fullscreenLoading = true; // 数据加载完成
            that.itemData.forEach(item => {
              ajaxGet(that, '/api/attendance/statisbyfloor?attendanceId=' + that.searchForm.attendanceId[1] + '&buildingId=' + item.id, null,
                function(resfloor) {
                  item.floorList = resfloor;
                });
              setTimeout(() => {
                that.fullscreenLoading = false; // 数据加载完成
              }, 1000);
            });
          }
        })
      }
function onGetChartData(that) {
  that.itemData = [];
  if (!regNullArray(that.searchForm.attendanceId)) {
    that.fullscreenLoading = true;
    ajaxGet(that, '/api/attendance/statisbybuilding', { attendanceId: that.searchForm.attendanceId[1] }, function (resBuilding) {
      ajaxGet(that, '/api/attendance/statisbygender', { attendanceId: that.searchForm.attendanceId[1] }, function (resGender) {
        that.fullscreenLoading = false;
        // 男女生打卡率
        resGender.forEach((element, index) => {
          if (!regNull(that.$refs['myChartPie' + (index + 1)])) {
            that.$refs['myChartPie' + (index + 1)].onGetRadar(onGetBarHtml2(element));
          }
        });

        // 宿舍楼打卡率
        if (!regNull(that.$refs.myChartBar)) {
          that.$refs.myChartBar.onGetRadar(onGetBarHtml(resBuilding));
        }
      });
    });
  }
}

function onGetBarHtml2(res) {
  return {
    tooltip: {
      trigger: 'item'
    },
    series: [
      {
        type: 'pie',
        radius: '50%',
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2
        },
        label: {
          formatter: '{a|{b}}{abg|}\n{hr|}\n  {b|{c}}  {per|{d}%}  ',
          backgroundColor: '#F6F8FC',
          borderColor: '#8C8D8E',
          borderWidth: 1,
          borderRadius: 4,
          rich: {
            a: {
              color: '#6E7079',
              lineHeight: 22,
              fontWeight: 'bold',
              align: 'center'
            },
            hr: {
              borderColor: '#8C8D8E',
              width: '100%',
              borderWidth: 1,
              height: 0
            },
            b: {
              color: '#4C5058',
              fontSize: 14,
              fontWeight: 'bold',
              lineHeight: 33
            },
            per: {
              color: '#fff',
              backgroundColor: '#4C5058',
              padding: [3, 4],
              borderRadius: 4
            }
          }
        },
        data: [
          { value: res.actual, name: '已打卡' },
          { value: res.total - res.actual, name: '未打卡' },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  }
}

function onGetBarHtml(res) {
  return {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        crossStyle: {
          color: '#999'
        }
      }
    },

    legend: {
      data: ['已打卡', '未打卡', '打卡率']
    },
    xAxis: [
      {
        type: 'category',
        data: res.map(x => { return x.text }),
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: '',
        splitLine: {
          show: false
        },
        axisLabel: {
          formatter: '{value} 人'
        }
      },
      {
        type: 'value',
        name: '',
        splitLine: {
          show: false
        },
        axisLabel: {
          formatter: '{value} %'
        }
      },

    ],
    series: [
      {
        name: '已打卡',
        type: 'bar',
        stack: 'Ad',
        barWidth: '40%',
        tooltip: {
          valueFormatter: function (value) {
            return value + ' （人）';
          }
        },

        data: res.map(x => { return x.actual })
      },
      {
        name: '未打卡',
        type: 'bar',
        stack: 'Ad',
        tooltip: {
          valueFormatter: function (value) {
            return value + ' （人）';
          }
        },
        data: res.map(x => { return x.total - x.actual })
      },
      {
        name: '打卡率',
        type: 'line',
        yAxisIndex: 1,
        tooltip: {
          valueFormatter: function (value) {
            return value + ' %';
          }
        },
        data: res.map(x => { return x.scale })
      }
    ]
  }
}
</script>

<style scoped="scoped" lang="less">
.navTabsCont .nav-link {
  font-size: 12px;
}

  .elRowBox{
    display: flex;
    flex-wrap: wrap;
  }
  .divProgressBody{
    display: flex;
    padding:10px 0;
    .divFloorName{
      width: 80px;
      text-align: right;
      padding-right: 15px;
    }
    .divGraph{
      padding-top: 5px;
      flex: 1;
    }
    .divNumber{
      padding-left: 15px;
      width: 100px;
      text-align: left;
    }
  }


  .navTabsCont {
    .liItemBox {
      font-size: 12px;
      border: 1px solid #FFFFFF;
      padding: 0.5rem 1rem;
      border-radius: 0.25rem;
      color: #6c757d;
    }

    .liActive {
      background: #007bff;
      color: #FFFFFF;
    }
  }
</style>
