<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="ruleForm" ref="ruleForm" label-width="100px">
      <el-card class="card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            课程管理
          </h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-4 colInfo">
              <label>课程名称</label>
              <div class="colFonts">{{ruleForm.name | flNoValue}}</div>
            </div>
            <div class="col-lg-4 colInfo">
              <label>课程类型</label>
              <div class="colFonts">{{ruleForm.courseType==null?'暂未设置':(ruleForm.courseType==1?'考试课':'考查课')}}</div>
            </div>
            <div class="col-lg-4 colInfo">
              <label>学期</label>
              <div class="colFonts">{{ruleForm.semesterName | flNoValue}}</div>
            </div>
            <div class="col-lg-8 colInfo">
              <label>班级</label>
              <div class="colFonts">{{ruleForm.className | flNoValue}}</div>
            </div>
          </div>
        </div>
      </el-card>

      <el-card class="card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            课程配置详情
          </h3>
        </div>
        <div class="card-body p-0">
          <el-table :data="ruleForm.configs" stripe class="elTableData">
            <el-table-column type="index" label="序号" width="80"></el-table-column>
            <el-table-column prop="Name" label="配置区间">
              <template slot-scope="scope">
                <span>{{scope.row.typeFloor}} 至 {{scope.row.typeCeiling}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="综合类型" class="elColumn">
              <template slot-scope="scope">
                <span v-for="(item,index) in scope.row.children" :key="index" class="badge badge-dark ml-1 mr-1">
                  {{item.configName}}&nbsp;{{item.score}}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-card>

      <div class="row" style="margin-top: 10px;">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
        </div>
      </div>

    </el-form>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  export default {
    data() {
      return {
        fullscreenLoading: false,
        ruleForm: {
          courseId: null,
          name: null,
          courseType: 0,
          configs: [],
          GrowthConfigs: [],
          typeLevelName: '',
          typeFloor: 0, //底限
          typeCeiling: 0, //上限
        },
        item: [],
      };
    },
    created() {
      let that = this,
        dataID = that.$route.params.id;
      if (dataID != null && typeof(dataID) != "undefined" && dataID != "") {
        that.ruleForm.courseId = dataID;
        that.onGetObtainData(that);
      } else {
        that.onBack();
      }
    },
    filters: {
      flNoValue(obj) {
        return fieldNoValue(obj);
      }
    },
    methods: {
      // 返回
      onBack() {
        let that = this;
        back(that);
      },
      onGetObtainData(that) {
        let apiUrl = '/api/admin/academiccourse/' + that.ruleForm.courseId;
        that.fullscreenLoading = true;
        ajaxGet(that, apiUrl, null, function(resData) {


          that.ruleForm = resData;

          let arrTemp = [];
          resData.configs.forEach((item, index) => {
            arrTemp.push({
              tbID: onGetRandomGUID(),
              typeLevelName: item.name,
              typeFloor: item.floor,
              typeCeiling: item.ceiling,
              children: that.onGetEditChildrenData(that, resData, item.floor, item.ceiling, item.name)
            });
          })


          //arrTemp=
          that.ruleForm.configs = JSON.parse(JSON.stringify(that.onArrUnique(arrTemp)));
          that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
          that.fullscreenLoading = false;
        })
      },
      // 去重复值
      onArrUnique(arr) {
        const res = new Map();
        let temp = arr.filter((item) => {
          item.typeLevelName = item.typeFloor.toString() + item.typeCeiling.toString();
          return item;
        });
        let arrTemp = temp.filter((arr) => !res.has(arr.typeLevelName) && res.set(arr.typeLevelName, 1));
        temp = arrTemp.filter((item) => {
          item.typeLevelName = '';
          return item;
        });
        return temp;

      },
      /**
       * @description 整理需要编辑的综合配置数据
       * @param {Object} that
       * @param {Object} _data
       * @param {Object} _Floor
       * @param {Object} _Ceiling
       * @param {Object} _Name
       */
      onGetEditChildrenData(that, _data, _Floor, _Ceiling, _Name) {
        let arrTemp = [];
        _data.configs.forEach((item, index) => {
          if (item.floor == _Floor && item.ceiling == _Ceiling) {
            let arr = {
              name: item.name,
              floor: item.floor,
              ceiling: item.ceiling,
              configId: item.configId,
              configName: item.configName,
              score: item.score,
            }
            arrTemp.push(arr);
          }
        });
        return arrTemp;
      }
    }
  };
</script>

<style>
</style>
