<!--
  日常事务 - 日常动态管理 - 行为规范
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div class="card-body p-2">
        <ul class="nav nav-pills navTabsCont" v-if="currentType == 1 || currentType == 2">
          <li class="nav-item" v-if="isPower.moralityclasslog_create == true">
            <router-link :to="'/Admin/Morality/Create/1'" :class="currentType == 1 ? 'nav-link active' : 'nav-link'">
              班级
            </router-link>
          </li>
          <li class="nav-item" v-if="isPower.moralitylog_create == true">
            <router-link :to="'/Admin/Morality/Create/2'" :class="currentType == 2 ? 'nav-link active' : 'nav-link'">
              班级个人
            </router-link>
          </li>
        </ul>
        <ul class="nav nav-pills navTabsCont" v-if="currentType == 3 || currentType == 4">
          <li class="nav-item" v-if="isPower.moralitydormitorylog_create == true">
            <router-link :to="'/Admin/Morality/Create/3'" :class="currentType == 3 ? 'nav-link active' : 'nav-link'">
              宿舍
            </router-link>
          </li>
          <li class="nav-item" v-if="isPower.moralitydplog_create == true">
            <router-link :to="'/Admin/Morality/Create/4'" :class="currentType == 4 ? 'nav-link active' : 'nav-link'">
              宿舍个人
            </router-link>
          </li>
        </ul>
      </div>
    </el-card>

    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="ruleForm">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            新增行为规范{{currentTitle}}
          </h3>
        </div>
        <div class="card-body tabListCont ">
          <el-row :gutter="24">
            <el-col :span="24" v-if="currentType==2 || currentType==4">
              <el-form-item label="管理原因" prop="reasonIds">
                <el-cascader @change="onChangeReason" class="width_100Pie" v-model="ruleForm.reasonIds"
                  :options="reasons" :show-all-levels="false" filterable
                  :props="{ expandTrigger: 'hover',label:'name',value:'id',children:'children' }"></el-cascader>
              </el-form-item>
            </el-col>

            <!--按选择模式录入-->
            <el-col :span="8" v-if="itemReason.inputScoreMode==1 && (currentType==2 || currentType==4)">
              <el-form-item label="分数" prop="scoreId">
                <el-select v-model="ruleForm.scoreId" placeholder="请选择分数" class="width_100Pie">
                  <el-option v-for="item in itemReason.reasonDeploys" :key="item.deployId" :label="item.score"
                    :value="item.deployId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <!--自定义录入-->
            <el-col :span="8" v-if="itemReason.inputScoreMode==4 && (currentType==2 || currentType==4)">
              <el-form-item label="分数" prop="score">
                <el-input @change="onScoreChange()" v-model="ruleForm.score" placeholder="请输入分数" class="width_100Pie">
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="8" v-if="currentType==3">
              <el-form-item label="宿舍" prop="roomIds">
                <el-cascader v-model="ruleForm.roomIds" :options="optRooms" :show-all-levels="true" filterable @change="onChangeRoom"
                  :props="{ expandTrigger: 'hover',label:'label',value:'value',children:'children' }" class="width_100Pie">
                </el-cascader>
              </el-form-item>
            </el-col>

            <el-col :span="8" v-if="currentType==1">
              <el-form-item label="班级" prop="classIds">
                <el-cascader v-model="ruleForm.classIds" :options="optClass" :show-all-levels="true" filterable
                  :props="{ expandTrigger: 'hover',label:'label',value:'value',children:'children' }" class="width_100Pie">
                </el-cascader>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="行为时间" prop="logDate">
                <el-date-picker type="datetime" class="width_100Pie" v-model="ruleForm.logDate" value-format="yyyy-MM-dd HH:mm"
                  placeholder="选择行为时间">
                </el-date-picker>
              </el-form-item>
            </el-col>

            <!-- 宿舍混寝的时候多选班级 不需要了-->
            <!-- <el-col :span="8" v-if="currentType==3 && optClass.length > 1">
              <el-form-item label="班级">
                <el-select v-model="ruleForm.classId" filterable clearable placeholder="请选择" class="width_100Pie">
                  <el-option v-for="item in optClass" :key="item.classId" :label="item.name" :value="item.classId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col> -->
          </el-row>
        </div>
      </el-card>

      <div v-if="currentType==1 || currentType==3">
        <el-card class="box-card">
          <div slot="header" class="card-header">
            <h3 class="card-title titleCont">
              <span class="spIcon"></span>
              行为规范原因{{currentTitle}}
            </h3>
          </div>
          <div class="card-body tabListCont">
            <div v-if="regNullArray(itemReasonData)">
              <el-empty :image-size="50" description="暂无原因" name="2"></el-empty>
            </div>
            <div v-else>
              <el-table :data="itemReasonData" border class="width_100Pie">
                <el-table-column label="原因">
                  <template slot-scope="scope">
                    {{ scope.row.name }}
                  </template>
                </el-table-column>
                <el-table-column label="分值" width="180">
                  <template slot-scope="scope">
                    <div v-if="scope.row.inputScoreMode==1">
                      <el-select v-model="scope.row.score" placeholder="请选择分数" class="width_100Pie" size="mini">
                        <el-option v-for="item in scope.row.reasonDeploys" :key="item.deployId" :label="item.score"
                          :value="item.score">
                        </el-option>
                      </el-select>
                    </div>
                    <div v-else-if="scope.row.inputScoreMode==4">
                      <el-input @change="onScoreChange(scope.row)" v-model="scope.row.score" placeholder="请输入分数" size="mini"
                        class="width_100Pie">
                      </el-input>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="100">
                  <template slot-scope="scope">
                    <el-button type="danger" @click="onDelReason(scope.row)" size="mini">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="card-footer text-right">
            <el-button type="success" icon="el-icon-circle-plus-outline" @click="onAddReason()">新增原因</el-button>
          </div>
        </el-card>
      </div>

      <!-- 学生名单 -->
      <el-card class="box-card" v-if="currentType==2 || currentType==4">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont" style="float: left;">
            <span class="spIcon"></span>
            学生名单
          </h3>
          <div class="card-tools">
            <ul class="nav">
              <li class="nav-item" v-if="currentType==2">
                <el-button type="success" icon="el-icon-circle-plus-outline" size="mini" @click="onOpenDialogVisible()">
                  新增学生名单
                </el-button>
              </li>
              <li class="nav-item" v-if="currentType==4">
                <el-button type="success" icon="el-icon-circle-plus-outline" size="mini"
                  @click="onOpenDialogVisibleForBed()">
                  新增学生名单
                </el-button>
              </li>
              <li class="nav-item">
                <el-button type="danger" icon="el-icon-close" size="mini" @click="onEmptyList()">清空名单</el-button>
              </li>
            </ul>
          </div>
        </div>
        <div class="card-body tabListCont">
          <el-row :gutter="20" v-if="ruleForm.members!=null && ruleForm.members.length>0">
            <el-col :lg="8" :md="8" :sm="12" v-for="(p,index) in ruleForm.members" :key="index">
              <div class="stuInfo">
                <div class="stuInfoBox">
                  <div class="stuImgCont VerticalMid">
                    <el-image :src="p.photo">
                      <div slot="error" class="image-slot">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#fax-use-circle-alt"></use>
                        </svg>
                      </div>
                    </el-image>
                  </div>
                  <div class="divInfoBox">
                    <div class="stuName">{{p.name}}</div>
                    <div class="stuOther">{{p.studentNo}}</div>
                    <div class="stuRole">{{p.className}}</div>
                  </div>
                </div>
                <div class="stuRoom" v-if="currentType==4">
                  {{p.buildingName}}（楼）{{p.floorName}}（层）{{p.roomName}}（室）{{p.bedName}}（床位）
                </div>

                <div class="stuRemove color-danger">
                  <i class="el-icon-error" @click="onDeleteMember(p.studentId)"></i>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24" v-else>
            <el-col :span="24" style="text-align: center;line-height: 40px;font-size: 14px;color: #909399;">
              请选择学生
            </el-col>
          </el-row>
        </div>
      </el-card>

      <!-- 附件 -->
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            行为图片
          </h3>
        </div>
        <div class="card-body tabListCont">
          <el-row :gutter="24" style="margin-top: 20px;">
            <el-col :span="24">
              <comUpload :key="random" @onPictureHandleSuccess="onPictureHandleSuccess" :files="uploadFiles">
              </comUpload>
            </el-col>
          </el-row>
        </div>
      </el-card>

      <!-- 备注 -->
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            备注
          </h3>
        </div>
        <div class="card-body tabListCont">
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="" prop="description">
                <el-input class="formControl" v-model="ruleForm.remark" type="textarea" :rows="10" show-word-limit
                  placeholder="请输入备注" maxlength="500">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>
    </el-form>

    <!-- 学生名单 班级个人 -->
    <el-dialog title="添加学生名单" :visible.sync="dialogVisible" width="580px" :before-close="onCloseStudent">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <el-cascader v-model="currentClassId" :options="optClass" :show-all-levels="true" filterable @change="onChangeClass" placeholder="请选择班级"
            :props="{ expandTrigger: 'hover',label:'label',value:'value',children:'children' }" class="width_100Pie">
          </el-cascader>
        </div>
        <div class="card-body tabListCont">
          <div v-if="regNullArray(optStudent)">
            <el-empty :image-size="50" description="暂无学生，请先选择班级" name="2"></el-empty>
          </div>
          <div v-else style="display: inline-flex;flex-wrap: wrap;">
            <div v-for="(stu, index) in optStudent" :key="index" style="width: 100px;">
              <div class="studentBox">
                <el-checkbox-group v-model="currentStudentId">
                  <el-checkbox :label="stu.studentId" @change="handleCheckedClassChange($event, stu.studentId)">{{stu.name}}</el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
          </div>
        </div>
      </el-card>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCloseStudent">取 消</el-button>
        <el-button type="primary" @click="onChangeStudent">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 学生名单 宿舍个人 -->
    <el-dialog title="添加学生名单" :visible.sync="dialogVisibleForBed" width="640px" :before-close="onCloseBed">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <el-cascader v-model="currentBedId" :options="optRooms" :show-all-levels="true" filterable @change="onChangeRooms" placeholder="请选择宿舍"
            :props="{ expandTrigger: 'hover',label:'label',value:'value',children:'children' }" class="width_100Pie">
          </el-cascader>
        </div>
        <div class="card-body tabListCont">
          <div v-if="regNullArray(optStudent)">
            <el-empty :image-size="50" description="暂无学生，请先选择宿舍" name="2"></el-empty>
          </div>
          <div v-else style="display: inline-flex;flex-wrap: wrap;">
            <div v-for="(stu, index) in optStudent" :key="index" style="width: 280px;">
              <div class="studentBox">
                <el-checkbox-group v-model="currentStudentId">
                  <el-checkbox :label="stu.value" @change="handleCheckedBedChange($event, stu.value)">{{stu.label}}</el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
          </div>
        </div>
      </el-card>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCloseBed">取 消</el-button>
        <el-button type="primary" @click="onChangeBed">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 行为规范原因 -->
    <el-dialog title="选择原因" :visible.sync="dialogReason" width="60%" :before-close="onCloseReason">
      <div class="divDialogReasonBox">
        <div class="divCheckNum">共勾选（<b>{{ckNumber}}</b>）条行为规范的原因</div>
        <el-tabs tab-position="left">
          <el-tab-pane :label="onGetLabel(item)" v-for="(item,index) in optReason" :key="item.id+'-'+index">
            <div class="tabBody">
              <div v-if="!regNullArray(item.children)">
                <table class="table table-bordered width_100Pie">
                  <tr>
                    <th>原因</th>
                    <th>分值</th>
                  </tr>
                  <tr v-for="(list,indexTwo) in item.children" :key="list.id+'-'+indexTwo">
                    <td>
                      <div class="listBox" :class="{'actList':list.status}" @click="onCheckReason(list)">
                        <svg class="icon" aria-hidden="true" v-if="list.status">
                          <use xlink:href="#fax-square-check-alt"></use>
                        </svg>
                        <svg class="icon" aria-hidden="true" v-else>
                            <use xlink:href="#fax-square-radius-o"></use>
                        </svg>
                        {{ list.name }}
                      </div>
                    </td>
                    <td width="140px">
                      <div v-if="list.inputScoreMode==1">
                        <el-select v-model="list.score" placeholder="请选择分数" class="width_100Pie" size="mini">
                          <el-option v-for="item in list.reasonDeploys" :key="item.deployId" :label="item.score"
                            :value="item.score">
                          </el-option>
                        </el-select>
                      </div>
                      <div v-else-if="list.inputScoreMode==4">
                        <el-input  v-model="list.score" @change="onScoreChange(list)" placeholder="请输入分数" class="width_100Pie"  size="mini">
                        </el-input>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogReason = false">取 消</el-button>
        <el-button type="primary" @click="onBtnSubmitReason">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 快捷操作 -->
    <el-dialog
      title="快捷操作"
      :visible.sync="dialogVisibleSave"
      width="35%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="beforeClose">
      <el-row :gutter="20">
        <el-col :span="12" class="operateCol">
          <el-button type="primary" icon="el-icon-right" @click="onSave(0)" class="width_100Pie">保留评价项目继续</el-button>
        </el-col>
        <el-col :span="12" class="operateCol">
          <el-button type="primary" icon="el-icon-right" @click="onSave(1)" class="width_100Pie">保留评价对象继续</el-button>
        </el-col>
        <el-col :span="24" class="operateCol" v-if="currentType == 1">
          <el-button type="primary" icon="el-icon-right" @click="onSave(1.5)" class="width_100Pie">下一个班级继续</el-button>
          <div class="operateDivTag">
            <div v-for="(p, index) in nextClass" :key="index" style="flex: 1">
              <el-tag type="success" class="operateTag" @click="onClickClass(p.collegeId, p.value)">{{p.label}}</el-tag>
            </div>
          </div>
        </el-col>
        <el-col :span="24" class="operateCol" v-if="currentType == 3">
          <el-button type="primary" icon="el-icon-right" @click="onSave(2)" class="width_100Pie">下一个宿舍继续</el-button>
          <div class="operateDivTag">
            <div v-for="(p, index) in nextRooms" :key="index" style="flex: 1">
              <el-tag type="success" class="operateTag" @click="onClickRoom(p.buildingId, p.floorId, p.value)">{{p.floor}}{{p.label}}</el-tag>
            </div>
          </div>
        </el-col>
        <el-col :span="24" class="operateCol">
          <el-button @click="onSave(3)" class="width_100Pie">返 回</el-button>
        </el-col>
      </el-row>
    </el-dialog>

    <div class="row" style="margin-top: 10px;">
      <div class="col-12 ">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
        <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
        <el-button type="primary" icon="el-icon-circle-check" @click="onSaveOperate">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comUpload from '@/components/Upload.vue';
import { element } from 'screenfull';

  export default {
    components: {
      comUpload
    },
    data() {
      return {
        // 加载动画
        fullscreenLoading: false,
        //添加学生信息弹出框
        dialogVisible: false,
        dialogVisibleForBed: false,
        dialogVisibleSave: false,
        // 页面标题
        currentTitle: "",
        //刷新组件
        random: 0,
        // 上传图片数组
        uploadFiles: [],
        // 表单对象
        ruleForm: {
          reasonId: null,
          reasonIds: [],
          // 记录时间
          logDate: null,
          // 备注
          remark: null,
          // 分数
          score: null,
          scoreId: null,
          // 附件
          attachment: null,
          // 人员
          members: [],
          // 班级ID
          classIds: null,
          // 计量
          number: null,
          //宿舍ID
          roomIds: [],
          roomId: null,
        },
        ruleFormReset: null,
        ruleFormReserve: null,
        // 扣分原因选择的数组
        allReasons: [],
        // 行为类型 1：班级 2：班级个人 3：宿舍 4：宿舍个人
        currentType: null,
        // 扣分原因数组
        reasons: null,
        // 班级数组
        optClass:[],
        allClass: [],
        nextClass: [],
        // 学生数组
        optStudent:[],

        currentClassId: [],
        currentClassName: null,
        currentClassIdReset: null,

        currentBedId: [],
        // 选择学生的ID
        currentStudentId: [],
        // 验证
        rules: {
          reasonIds: [{
            required: true,
            message: '请选择管理原因',
            trigger: 'change'
          }],
          scoreId: [{
            required: true,
            message: '请选择分数',
            trigger: 'change'
          }],
          score: [{
            required: true,
            message: '请输入分数',
            trigger: 'change'
          }, {
            validator: (rule, value, callback) => {
              let that = this;
              var reg = /^(\-|\+)?\d+(\.\d{1,2})?$/;
              if (value != 0 && !reg.exec(value)) {
                callback(new Error("分数只能输入带1-2位小数的正数或负数！"));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }],
          number: [{
            required: true,
            message: '请输入计量数',
            trigger: 'change'
          }, {
            validator: (rule, value, callback) => {
              let _this = this;
              var reg = /^\+?[1-9][0-9]*$/;
              if (value != 0 && !reg.exec(value)) {
                callback(new Error("计量数只能输入正整数！"));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }],
          logDate: [{
            required: true,
            message: '请选择行为时间',
            trigger: 'change'
          }],
          classIds: [{
            required: true,
            message: '请选择班级',
            trigger: 'change'
          }],
          roomIds: [{
            required: true,
            message: '请选择宿舍',
            trigger: 'change'
          }],
        },
        // 录入分类类型
        itemReason: {
          inputScoreMode: 0,
          reasonDeploys: [],
        },
        itemReasonReserveContinue: null,

        typeScoreMode: {
          labelName: '',
          valTotalScore: 0,
          tipFont: '',
        },
        typeScoreModeReset: null,
        // 宿舍数组
        optRooms: [],
        allRooms: [],
        nextRooms: [],
        optBeds: [],

        //宿舍混寝班级数据
        optRoomClass: [],

        // 多条原因数组
        tbReasonDataReserve: null,
        tbReasonDesc: [],
        tempStudent: null,

        // 行为规范原因弹出框
        dialogReason:false,
        optReason:[],// 原因数组
        optReasonReset:[],// 原因数组重置
        itemReasonData:[],// 原因Table
        itemReasonDataReset: [],// 用于原因重置
        ckNumber:0,

        isPower: {
          moralityclasslog_create: false,
          moralitylog_create: false,
          moralitydormitorylog_create: false,
          moralitydplog_create: false,
        },
        optMembers: [],
      };
    },
    created() {
      let that = this;
      isPower(that, "moralityclasslog_create", "moralityclasslog_create");
      isPower(that, "moralitylog_create", "moralitylog_create");
      isPower(that, "moralitydormitorylog_create", "moralitydormitorylog_create");
      isPower(that, "moralitydplog_create", "moralitydplog_create");
      that.currentType = that.$route.params.type;
      InitType(that);
    },
    methods: {
      /**
       * @description 班级个人名单复选框选中
       * @param {Object} e
       * @param {Object} studentId
       */
      handleCheckedClassChange(e, studentId) {
        let that = this;
        if (e) {
          let tempStudent = that.optStudent.find(fd => fd.studentId == studentId);
          if(!regNull(tempStudent)) {
            that.optMembers.push({
              studentId: tempStudent.studentId,
              classId: that.currentClassId[1],
              score: 0,
              photo: that.onImgSpliceUrlNoV(tempStudent.photo),
              name: tempStudent.name,
              studentNo: tempStudent.studentNo,
              className: that.currentClassName,
            });
          }
        } else {
          that.optMembers = that.optMembers.filter(x => x.studentId != studentId);
        }
      },
      /**
       * @description 宿舍个人名单复选框选中
       * @param {Object} e
       * @param {Object} value
       */
      handleCheckedBedChange(e, value) {
        let that = this;
        if (e) {
          let tempStudent = that.optStudent.find(fd => fd.value == value);
          if(!regNull(tempStudent)) {
            that.optMembers.push({
              id: tempStudent.value,
              score: 0,
              photo: that.onImgSpliceUrlNoV(tempStudent.photo),
              studentId: tempStudent.studentId,
              name: tempStudent.studentName,
              classId: tempStudent.classId,
              className: tempStudent.className,
              bedName: tempStudent.bed,
              roomName: tempStudent.room,
              roomId: tempStudent.parentId,
              floorName: tempStudent.floor,
              buildingName: tempStudent.building
            });
          }
        } else {
          that.optMembers = that.optMembers.filter(x => x.value != value);
        }
      },
      /**
       * @description 选择宿舍继续
       */
      onClickRoom(buildingId, floorId, roomId) {
        let that = this;
        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            // 选择原因
            if (regNullArray(that.itemReasonData)) {
              warning(that, "请选择原因");
              return false;
            }
            //宿舍
            let members = [];
            if (that.ruleForm.classId) {
              members.push({
                classId: that.ruleForm.classId
              })
            }
            let tpParams = [];
            let tpRoomId = that.ruleForm.roomIds[that.ruleForm.roomIds.length - 1];
            that.itemReasonData.forEach((item) => {
              tpParams.push({
                roomId: tpRoomId,
                reasonId: item.id,
                score: parseFloat(item.score),
                attachment: that.ruleForm.attachment,
                remark: that.ruleForm.remark,
                logDate: timeTransform(that.ruleForm.logDate),
                members: members
              });
            });

            that.fullscreenLoading = true;
            ajaxPost(that, '/api/admin/moralitydormitorylog/addrange', tpParams, function(r) {
              that.fullscreenLoading = false;

              let data = {
                logDate: new Date(),
                remark: that.ruleForm.remark,
                attachment: null,
                classId: null,
                classIds: [],
                roomId: roomId,
                roomIds: [buildingId, floorId, roomId],
              };

              // 保存数据
              that.ruleFormReserve = JSON.parse(JSON.stringify(data));
              // 还原数据
              that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReserve));
              // 重置
              that.uploadFiles = [];
              var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
              that.random += random;
              try {
                that.$refs["ruleForm"].resetFields();
              } catch {}

              that.dialogVisibleSave = false;
            });
          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              var el = isError[0].querySelector('input') || isError[0].querySelector('textarea') || isError[0].querySelector('select');
              if(el) {
                el.focus();
              }
            }, 1)
            return false;
          }
        });
      },
      /**
       * @description 选择班级继续
       */
      onClickClass(collegeId, classId) {
        let that = this;
        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            // 选择原因
            if (regNullArray(that.itemReasonData)) {
              warning(that, "请选择原因");
              return false;
            }
            // 班级
            let tpParams = [];
            let tpClassId = that.ruleForm.classIds[that.ruleForm.classIds.length - 1];
            that.itemReasonData.forEach((item) => {
              tpParams.push({
                classId: tpClassId,
                reasonId: item.id,
                score: parseFloat(item.score),
                attachment: that.ruleForm.attachment,
                remark: that.ruleForm.remark,
                // number: that.ruleForm.number,
                logDate: timeTransform(that.ruleForm.logDate),
              });
            });
            that.fullscreenLoading = true;
            ajaxPost(that, '/api/admin/moralityclasslog/addrange', tpParams, function(r) {
              that.fullscreenLoading = false;

              let data = {
                logDate: new Date(),
                remark: that.ruleForm.remark,
                attachment: null,
                classId: classId,
                classIds: [collegeId, classId],
                roomId: null,
                roomIds: [],
              };

              // 保存数据
              that.ruleFormReserve = JSON.parse(JSON.stringify(data));
              // 还原数据
              that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReserve));
              // 重置
              that.uploadFiles = [];
              var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
              that.random += random;
              try {
                that.$refs["ruleForm"].resetFields();
              } catch {}

              that.dialogVisibleSave = false;
            });
          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              var el = isError[0].querySelector('input') || isError[0].querySelector('textarea') || isError[0].querySelector('select');
              if(el) {
                el.focus();
              }
            }, 1)
            return false;
          }
        });

      },
      beforeClose() {
        this.onSave(3);
      },
      /**
       * @description 提交
       */
      onSaveOperate() {
        let that = this;
        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            if (that.currentType == 2 || that.currentType == 4) {
              // 选择学生
              if (that.ruleForm.members == null || that.ruleForm.members.length <= 0) {
                warning(that, "请选择学生");
                return false;
              }
            } else {
              // 选择原因
              if (regNullArray(that.itemReasonData)) {
                warning(that, "请选择原因");
                return false;
              }
            }
            if (that.currentType == 1) {
              let index = that.allClass.findIndex(x => x.value == that.ruleForm.classIds[1]);
              that.nextClass = that.allClass.slice(index + 1, index + 7);
            }
            if (that.currentType == 3) {
              let rooms = that.allRooms.filter(x => x.buildingId == that.ruleForm.roomIds[0]);
              let index = rooms.findIndex(y => y.value == that.ruleForm.roomIds[2]);
              that.nextRooms = rooms.slice(index + 1, index + 7);
            }
            that.dialogVisibleSave = true;
          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              var el = isError[0].querySelector('input') || isError[0].querySelector('textarea') || isError[0].querySelector('select');
              if(el) {
                el.focus();
              }
            }, 1)
            return false;
          }
        });
      },
      /**
       * @description 弹出框中 原因名称的生成
       */
      onGetLabel(objItem){
        let that = this;
        if (!regNullArray(objItem.children)) {
          let tpCKNumber = objItem.children.filter(item => item.status);
          return regNullArray(tpCKNumber) ? objItem.name + '（ 0 ）': objItem.name + '（ ' + tpCKNumber.length + ' ）';
        } else {
          return objItem.name + '（ 0 ）';
        }
      },
      /**
       * @description 关闭原因弹出框
       */
      onCloseReason(){
        let that = this;
        that.dialogReason = false;
      },
      /**
       * @description 点击原因,进行勾选事项
       */
      onCheckReason(objItem){
        let that = this;
        if(!regNull(objItem)){
          that.optReason.forEach(item=> {
            if(item.id==objItem.parentId){
              if(!regNullArray(item.children)){
                item.children.forEach(list=> {
                  if(list.id==objItem.id){
                    if(objItem.status){

                      list.status=false;
                      that.ckNumber=that.ckNumber>0?that.ckNumber-1:0;

                      // 重置分数
                      if(list.inputScoreMode==1){
                        let tpDefaultScore=regNullArray(list.reasonDeploys)?null:list.reasonDeploys.find(fd=> fd.isDefault);
                        list.score=regNull(tpDefaultScore)?0:tpDefaultScore.score;
                      }else{
                        list.score=0;
                      }

                    }else{
                      list.status=true;
                      that.ckNumber+=1;
                    }
                  }
                });
              }
            }
          });
        }
      },
      /**
       * @description 提交原因
       */
      onBtnSubmitReason(){
        let that = this;
        if(!regNullArray(that.optReason)){
          that.optReason.forEach(item=> {
            if(!regNullArray(item.children)){
              item.children.forEach(list=>{
                if(list.status){
                  if(regNull(that.itemReasonData.find(fd=> fd.id==list.id))){
                    that.itemReasonData.push(list);
                  }
                }else{
                  that.itemReasonData=that.itemReasonData.filter(fl=>fl.id!=list.id);
                }
              });
            }
          });
        }
        that.onCloseReason();
      },
      /**
       * @description [宿舍]选择宿舍事件
       * @param {*} e
       */
      onChangeRoom(e) {
        let that = this;
        that.optClass = [];
        that.ruleForm.classId = null;
        ajaxGet(that, "/api/admin/moralitydormitorylog/roomclass/" + e[2], null, function(r) {
          if(r && r.length > 0) {
            that.optClass = r;
          }
        })
      },
      /**
       * @description 删除原因
       * @param {Object} objItem
       */
      onDelReason(objItem) {
        let that = this;
        if(!regNull(objItem)){
          that.itemReasonData=that.itemReasonData.filter(item=> item.id!=objItem.id);
          that.onCheckReason(objItem);
        }
      },
      /**
       * @description 当按数量计算录入时，计算总分数[班级，宿舍]
       */
      onGetTbValidator(objItem, objValID) {
        let that = this;
        var reg = /^[0-9]*$/;
        if (!regNull(objItem) && objItem != 0) {
          if (objItem != 0 && !reg.exec(objItem)) {
            warning(that, '数量只能输入正整数！');
          } else {
            that.tbReasonDesc.forEach((item) => {
              if (item.reasonId == objValID) {
                item.typeScoreMode.valTotalScore = parseFloat(item.reasonDeploys[0].score) * parseInt(objItem);
                item.number = objItem;
              }
            });
          }
        } else {
          that.tbReasonDesc.forEach((item) => {
            if (item.reasonId == objValID) {
              item.typeScoreMode.valTotalScore = 0;
              item.number = null;
            }
          });
        }
      },
      /**
       * @description 新加原因数量
       */
      onAddReason() {
        let that = this;
        that.dialogReason = true;
      },
      /**
       * @description 确认床位
       * @return {void}
       */
      onChangeBed() {
        let that = this;
        that.ruleForm.members = JSON.parse(JSON.stringify(that.optMembers));

        that.optStudent = [];
        that.optMembers = [];
        that.currentStudentId = [];
        that.currentBedId = [];
        that.dialogVisibleForBed = false;
      },
      /**
       * @description 关闭弹出框
       * @return {void}
       */
      onCloseBed() {
        let that = this;
        that.optStudent = [];
        that.currentStudentId = [];
        that.currentBedId = [];
        that.dialogVisibleForBed = false;
      },
      /**
       * @description 清空名单
       * @return {void}
       */
      onEmptyList() {
        let that = this;
        that.ruleForm.members = [];
        that.currentStudentId = [];
        that.currentBedId = [];
      },
      /**
       * @description 打开名单(班级个人)
       * @return {void}
       */
      onOpenDialogVisible() {
        let that = this;
        that.optMembers = JSON.parse(JSON.stringify(that.ruleForm.members));
        that.dialogVisible = true;
      },
      /**
       * @description 打开名单（宿舍个人）
       * @return {void}
       */
      onOpenDialogVisibleForBed() {
        let that = this;
        that.optMembers = JSON.parse(JSON.stringify(that.ruleForm.members));
        that.dialogVisibleForBed = true;
      },

      /**
       * @description 生成输入框的标题
       * @return {String} 标题
       */
      onGetUnitLabel() {
        return '计量数（单位：' + this.itemReason.reasonDeploys[0].unit + '）';
      },
      /**
       * @description 管理原因选择事件
       * @return {void}
       */
      onChangeReason(_val) {
        let that = this;
        that.typeScoreMode = JSON.parse(JSON.stringify(that.typeScoreModeReset));
        that.ruleForm.scoreId = null;
        that.ruleForm.score = null;
        that.ruleForm.number = null;
        if (!regNullArray(_val)) {
          // 根据最后一ID获取数据
          let tempItem = that.allReasons.find(item => item.id == _val[_val.length - 1]);
          // 获取当前原因的对象数据
          if (!regNull(tempItem)) {
            that.itemReason = tempItem;
            // 按选择模式录入
            if (tempItem.inputScoreMode == 1) {
              if (!regNullArray(tempItem.reasonDeploys)) {
                let tempIsCheck = tempItem.reasonDeploys.find(item => item.isDefault == true);

                if (!regNull(tempIsCheck)) {
                  that.ruleForm.scoreId = tempIsCheck.deployId;
                } else {
                  that.ruleForm.scoreId = tempItem.reasonDeploys[0].deployId;
                }
              }
            }
          }
        }
      },
      /**
       * @description 输入分数，将格式化
       * @return {void}
       */
      onScoreChange(objItem) {
        var tpScore = setTwoDecimal(setTwoFloat(objItem.score));
        if(regNull(tpScore)) {
          objItem.score = 0;
        } else {
          objItem.score = tpScore;
        }
      },
      /**
       * @description 返回
       * @return {void}
       */
      onBack() {
        let that = this;
        let url = '';
        if (that.currentType == 1) {
          url = '/Admin/Morality/Index?type=1';
        } else if (that.currentType == 2) {
          url = '/Admin/Morality/Index?type=2';
        } else if (that.currentType == 3) {
          url = '/Admin/Dormitory/Index?type=1';
        } else if (that.currentType == 4) {
          url = '/Admin/Dormitory/Index?type=2';
        }
        that.$router.push({
          path: url
        });
      },
      /**
       * @description 下一个班级继续
       */
      onReserveClass() {
        let that = this;
        let data = {
          logDate: new Date(),
          remark: that.ruleForm.remark,
          attachment: null,
          classId: null,
          classIds: [],
          roomId: null,
          roomIds: [],
        };
        if(!regNullArray(that.ruleForm.classIds)) {
          // 年级Id
          let collegeId = that.ruleForm.classIds[0];
          // 班级Id
          let classId = that.ruleForm.classIds[1];
          let college = that.optClass.find(x => x.value == collegeId);
          if (!regNull(college)) {
            if (!regNullArray(college.children)) {
              let classIndex = college.children.findIndex(x => x.value == classId);
              // 如果是最后一个班级
              if (college.children.length == classIndex + 1) {
               let collegeIndex = that.optClass.findIndex(x => x.value == collegeId);
               // 如果是最后一个年级
               if (that.optClass.length == collegeIndex + 1) {
                setTimeout(() => {
                  warning(that, `这是最后一个班级`);
                }, 500)
               } else {
                data.classIds = [that.optClass[collegeIndex + 1].value, that.optClass[collegeIndex + 1].children[0].value];
               }
              } else {
                data.classIds = [college.value, college.children[classIndex + 1].value];
              }
            }
          }
        }
        // 保存数据
        that.ruleFormReserve = JSON.parse(JSON.stringify(data));
        // 还原数据
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReserve));
        // 重置
        that.uploadFiles = [];
        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      /**
       * @description 下一个宿舍继续
       */
      onReserveRoom() {
        let that = this;
        let data = {
          logDate: new Date(),
          remark: that.ruleForm.remark,
          attachment: null,
          classId: null,
          classIds: [],
          roomId: null,
          roomIds: [],
        };
        if(!regNullArray(that.ruleForm.roomIds)){
          let tempBuildId = that.ruleForm.roomIds[0];// 获取宿舍楼ID
          let rooms = that.allRooms.filter(item => item.buildingId == tempBuildId);
          if(!regNullArray(rooms)) {
            // 获取宿舍下标
            let roomIndex = rooms.findIndex(x => x.value == that.ruleForm.roomIds[2]);
            if(rooms.length == roomIndex + 1) {
              setTimeout(() => {
                warning(that, `这是宿舍楼【${rooms[0].building}】的最后一个宿舍`);
              }, 500)
            } else {
              data.roomId = rooms[roomIndex + 1].value;
              data.roomIds = [rooms[roomIndex + 1].buildingId, rooms[roomIndex + 1].floorId, rooms[roomIndex + 1].value]
            }
          }
        }
        // 保存数据
        that.ruleFormReserve = JSON.parse(JSON.stringify(data));
        // 还原数据
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReserve));
        // 重置
        that.uploadFiles = [];
        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      /**
       * @description 保留评价对象继续
       */
      onReserveObject() {
        let that = this;
        let data = null;
        // 班级
        if (that.currentType == 1) {
          data = {
            logDate: new Date(),
            remark: null,
            attachment: null,
            classId: that.ruleForm.classId,
            classIds: that.ruleForm.classIds,
          }
        }
        // 宿舍
        if (that.currentType == 3) {
          data = {
            logDate: new Date(),
            remark: null,
            attachment: null,
            classId: that.ruleForm.classId,
            classIds: that.ruleForm.classIds,
            roomId: that.ruleForm.roomId,
            roomIds: that.ruleForm.roomIds,
          }
        }

        // 班级个人 || 宿舍个人
        if (that.currentType == 2 || that.currentType == 4) {
          data = {
            logDate: new Date(),
            reasonId: null,
            reasonIds: null,
            remark: null,
            scoreId: null,
            score: null,
            number: null,
            attachment: null,
            members: that.ruleForm.members,
          }
        }

        // 保存数据
        that.ruleFormReserve = JSON.parse(JSON.stringify(data));
        // 还原数据
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReserve));
        that.itemReasonData = [];
        that.itemReasonDataReset=[];
        // 重置
        // that.onReset();
        that.optReason=JSON.parse(JSON.stringify(that.optReasonReset));
        that.ckNumber=0;
        that.uploadFiles = [];
        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}


      },
      /**
       * @description 保留评价项目继续
       */
      onReserveContinue() {
        let that = this;
        let data = null;
        // 班级
        if (that.currentType == 1) {
          data = {
            logDate: new Date(),
            remark: that.ruleForm.remark,
            attachment: null,
            classId: null,
            classIds:[],
          }
        }
        // 宿舍
        if (that.currentType == 3) {
          data = {
            logDate: new Date(),
            remark: that.ruleForm.remark,
            attachment: null,
            classId: null,
            classIds:[],
            roomId: null,
            roomIds: [],
          }
        }
        // 班级个人 || 宿舍个人
        if (that.currentType == 2 || that.currentType == 4) {
          data = {
            logDate: new Date(),
            reasonId: that.ruleForm.reasonId,
            reasonIds: that.ruleForm.reasonIds,
            remark: that.ruleForm.remark,
            scoreId: that.ruleForm.scoreId,
            score: that.ruleForm.score,
            number: that.ruleForm.number,
            attachment: null,
            members: [],
          }
        }
        that.itemReasonDataReset = JSON.parse(JSON.stringify(that.itemReasonData))
        // 保存数据
        that.ruleFormReserve = JSON.parse(JSON.stringify(data));
        // 重置
        that.onReset();
        // 还原数据
        that.ruleForm = JSON.parse(JSON.stringify(data));
      },
      /**
       * @description 提交事件
       * @return {void} 3：提交    2：下一个宿舍继续    1.5：下一个班级继续    1：保留评价对象继续    0：保留评价项目继续
       */
      onSave(typeNumber) {
        let that = this;

        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            if (that.currentType == 2 || that.currentType == 4) {
              // 选择学生
              if (that.ruleForm.members == null || that.ruleForm.members.length <= 0) {
                warning(that, "请选择学生");
                return false;
              }
              // 根据类型进行
              if (that.itemReason.inputScoreMode == 1) {
                // 选择分数
                let temp = that.itemReason.reasonDeploys.find(val => val.deployId == that.ruleForm.scoreId);
                that.ruleForm.score = parseFloat(temp != undefined ? temp.score : 0);
              } else if (that.itemReason.inputScoreMode == 4) {
                that.ruleForm.score = parseFloat(that.ruleForm.score);
              }

              // 分值
              for (var it of that.ruleForm.members) {
                it.score = parseFloat(that.ruleForm.score);
              }

              var postUrl = "";
              if (that.currentType == 1) {
                postUrl = "/api/admin/moralityclassLog";
              } else if (that.currentType == 2) {
                postUrl = "/api/admin/moralitylog";
              } else if (that.currentType == 3) {
                postUrl = "/api/admin/moralitydormitorylog";
                that.ruleForm.roomId = that.ruleForm.roomIds[that.ruleForm.roomIds.length - 1];
              } else if (that.currentType == 4) {
                postUrl = "/api/admin/moralitydplog";
              }
              if (postUrl != "") {
                that.ruleForm.reasonId = that.ruleForm.reasonIds[that.ruleForm.reasonIds.length - 1];
                that.fullscreenLoading = true;
                let tpParams = JSON.parse(JSON.stringify(that.ruleForm));
                tpParams.logDate = timeTransform(tpParams.logDate);
                ajaxPost(that, postUrl, tpParams, function(r) {
                  that.fullscreenLoading = false;
                  if (typeNumber == 0) {
                    that.onReserveContinue();
                    that.dialogVisibleSave = false;
                  } else if (typeNumber == 1) {
                    that.onReserveObject();
                    that.dialogVisibleSave = false;
                  } else if (typeNumber == 3) {
                    that.onBack();
                  }
                });
              }

            } else if (that.currentType == 1) {
              // 选择原因
              if (regNullArray(that.itemReasonData)) {
                warning(that, "请选择原因");
                return false;
              }
              // 班级
              let tpParams = [];
              let tpClassId = that.ruleForm.classIds[that.ruleForm.classIds.length - 1];
              that.itemReasonData.forEach((item) => {
                tpParams.push({
                  classId: tpClassId,
                  reasonId: item.id,
                  score: parseFloat(item.score),
                  attachment: that.ruleForm.attachment,
                  remark: that.ruleForm.remark,
                  // number: that.ruleForm.number,
                  logDate: timeTransform(that.ruleForm.logDate),
                });
              });
              that.fullscreenLoading = true;
              ajaxPost(that, '/api/admin/moralityclasslog/addrange', tpParams, function(r) {
                that.fullscreenLoading = false;
                if (typeNumber == 0) {
                  that.onReserveContinue();
                  that.dialogVisibleSave = false;
                } else if (typeNumber == 1) {
                  that.onReserveObject();
                  that.dialogVisibleSave = false;
                } else if (typeNumber == 1.5) {
                  that.onReserveClass();
                  that.dialogVisibleSave = false;
                } else if (typeNumber == 3) {
                  that.onBack();
                }
              });
            } else if (that.currentType == 3) {
              // 选择原因
              if (regNullArray(that.itemReasonData)) {
                warning(that, "请选择原因");
                return false;
              }
              //宿舍
              let members = [];
              if (that.ruleForm.classId) {
                members.push({
                  classId: that.ruleForm.classId
                })
              }
              let tpParams = [];
              let tpRoomId = that.ruleForm.roomIds[that.ruleForm.roomIds.length - 1];
              that.itemReasonData.forEach((item) => {
                tpParams.push({
                  roomId: tpRoomId,
                  reasonId: item.id,
                  score: parseFloat(item.score),
                  attachment: that.ruleForm.attachment,
                  remark: that.ruleForm.remark,
                  logDate: timeTransform(that.ruleForm.logDate),
                  members: members
                });
              });

              that.fullscreenLoading = true;
              ajaxPost(that, '/api/admin/moralitydormitorylog/addrange', tpParams, function(r) {
                that.fullscreenLoading = false;
                if (typeNumber == 0) {
                  that.onReserveContinue();
                  that.dialogVisibleSave = false;
                } else if (typeNumber == 1) {
                  that.onReserveObject();
                  that.dialogVisibleSave = false;
                } else if (typeNumber == 2) {
                  that.onReserveRoom();
                  that.dialogVisibleSave = false;
                } if (typeNumber == 3) {
                  that.onBack();
                }
              });
            }
          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              var el = isError[0].querySelector('input') || isError[0].querySelector('textarea') || isError[0].querySelector('select');
              if(el) {
                el.focus();
              }
            }, 1)
            return false;
          }
        });
      },
      /**
       * @description 重置事件
       * @return {void}
       */
      onReset() {
        let that = this;
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
        that.currentClassId = JSON.parse(JSON.stringify(that.currentClassIdReset));
        that.typeScoreMode = JSON.parse(JSON.stringify(that.typeScoreModeReset));
        that.itemReasonData = JSON.parse(JSON.stringify(that.itemReasonDataReset));
        that.optReason = JSON.parse(JSON.stringify(that.optReasonReset));
        that.ckNumber = 0;
        that.uploadFiles = [];
        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      /**
       * @description 删除学生名单
       * @param {Object} objValID 学生ID
       * @return {void}
       */
      onDeleteMember(objValID) {
        let that = this;
        that.ruleForm.members = that.ruleForm.members.filter(item => item.studentId != objValID);
        // 弹出框中学生的名单也要删除
        if(!regNullArray(that.currentBedId)) {
          that.currentBedId = that.currentBedId.filter(item => {
            if(regNull(item.find(fd => fd == objValID))){
              return item;
            }
          });
        }
      },
      /**
       * @description 【班级个人】中选择班级的事件
       * @param {*} objItem
       */
      onChangeClass(objItem) {
        let that = this;
        that.currentClassName = that.optClass.find(x => x.value == objItem[0]).children.find(y => y.value == objItem[1]).label;
        // 根据班级id获取学生信息
        ajaxGet(that, '/api/admin/student/class/' + objItem[1], null, function(resClass) {
          that.optStudent = resClass;
          if (!regNullArray(that.ruleForm.members)) {
            that.ruleForm.members.forEach(element => {
              that.currentStudentId.push(element.studentId);
            })
          }
        });
      },
      /**
       * @description 【宿舍个人】中选择宿舍的事件
       * @param {*} objItem
       */
      onChangeRooms(objItem) {
        let that = this;
        let roomId = objItem[2];
        that.currentStudentId = [];
        that.optStudent = that.optBeds.filter(x => x.parentId == roomId);
        if (!regNullArray(that.ruleForm.members)) {
          that.ruleForm.members.forEach(element => {
            that.currentStudentId.push(element.id);
          })
        }
      },
      /**
       * @description 关闭弹窗
       */
      onCloseStudent() {
        let that = this;
        that.optStudent = [];
        that.currentStudentId = [];
        that.currentClassId = [];
        that.dialogVisible = false;
      },
      /**
       * @description [班级个人]勾选学生，确认事件
       * @return {void}
       */
      onChangeStudent() {
        let that = this;
        that.ruleForm.members = JSON.parse(JSON.stringify(that.optMembers));

        that.optStudent = [];
        that.optMembers = [];
        that.currentStudentId = [];
        that.currentClassId = [];
        that.dialogVisible = false;
      },
      /**
       * @description 数据递归
       * @param {Array} data 需要递归的原数据
       * @return {Array} 返回递归后的数据
       */
      getTreeData(data) {
        // 循环遍历json数据
        for (var i = 0; i < data.length; i++) {
          if (data[i].children.length < 1) {
            // children若为空数组，则将children设为undefined
            data[i].children = undefined;
          } else {
            // children若不为空数组，则继续 递归调用 本方法
            this.getTreeData(data[i].children);
          }
        }
        return data;
      },
      /**
       * @description 上传图片成功后事件
       * @param {Object} param 上传成功后的地址
       * @return {void}
       */
      onPictureHandleSuccess(param) {
        let that = this;
        that.ruleForm.attachment = "";
        for (var i in param) {
          if (that.ruleForm.attachment == "") {
            that.ruleForm.attachment = param[i].path;
          } else {
            that.ruleForm.attachment += "|" + param[i].path;
          }
        }
      },
    },
    watch: {
      $route() {
        let that = this;
        that.onReset();
        InitType(that);
      },
    },
  };

  // 初始化数据加载
  function InitType(that) {
    that.tempStudent = JSON.parse(that.$GetKey(that.$cacheStudent));
    that.currentClassIdReset = JSON.parse(JSON.stringify(that.currentClassId));
    that.typeScoreModeReset = JSON.parse(JSON.stringify(that.typeScoreMode));
    that.itemReasonDataReset = JSON.parse(JSON.stringify(that.itemReasonData));
    that.currentType = that.$route.params.type;

    var reasonUrl = "";
    if (that.currentType == 1) {
      that.currentTitle = "【班级】";
      that.typeScoreMode.labelName = "班级";
      reasonUrl = "/api/admin/moralityclassreason/usertreelist";
    } else if (that.currentType == 2) {
      that.currentTitle = "【班级个人】";
      that.typeScoreMode.labelName = "学生";
      reasonUrl = "/api/admin/moralityreason/usertreelist";
    } else if (that.currentType == 3) {
      that.currentTitle = "【宿舍】";
      that.typeScoreMode.labelName = "宿舍";
      reasonUrl = "/api/admin/moralitydormitoryreason/usertreelist";
    } else if (that.currentType == 4) {
      that.currentTitle = "【宿舍个人】";
      that.typeScoreMode.labelName = "学生";
      reasonUrl = "/api/admin/moralitydpreason/usertreelist";
    }
    if (reasonUrl != "") {
      var param = {
        ReasonType: 2,
      }
      that.fullscreenLoading = true;
      ajaxGet(that, reasonUrl, param, function(resData) {
        InitItem(that, resData);

        if(!regNullArray(resData)){
          that.optReason=resData.map(item=>({
            configs: item.configs,
            cycle:item.cycle,
            cycleString:item.cycleString,
            description:item.description,
            id:item.id,
            inputScoreMode:item.inputScoreMode,
            lastUpdateDate:item.lastUpdateDate,
            lastUpdateDateString:item.lastUpdateDateString,
            name:item.name,
            parentId:item.parentId,
            priority:item.priority,
            reasonDeploys:item.reasonDeploys,
            reasonType:item.reasonType,
            score:item.score,
            title:item.title,
            status:false,
            children:regNullArray(item.children)?[]:item.children.map(list=>({
              children:list.children,
              configs:list.configs,
              cycle:list.cycle,
              cycleString:list.cycleString,
              description:list.description,
              id:list.id,
              inputScoreMode:list.inputScoreMode,
              lastUpdateDate:list.lastUpdateDate,
              lastUpdateDateString:list.lastUpdateDateString,
              name:list.name,
              parentId:list.parentId,
              priority:list.priority,
              reasonDeploys:list.reasonDeploys,
              reasonType:list.reasonType,
              score:list.score,
              title:list.title,
              status:false,
            })),
          }));
          that.optReasonReset=JSON.parse(JSON.stringify(that.optReason));
        }
        that.reasons = that.getTreeData(resData);
        that.fullscreenLoading = false;
      });
    }

    if (that.currentType == 1 || that.currentType == 2) {
      that.fullscreenLoading = true;
      // 获取班级数据
      that.allClass = [];
      ajaxGet(that, '/api/admin/college/normallinkage', null, function(resClass) {
        that.optClass = resClass;
        that.optClass.forEach(element => {
          element.value = element.collegeId;
          element.label = element.name;
          element.children = [];
          if (!regNullArray(element.classes)) {
            element.classes.forEach(chil => {
              element.children.push({
                value: chil.classId,
                label: chil.name
              })
              that.allClass.push({
                collegeId: element.collegeId,
                value: chil.classId,
                label: chil.alias
              })
            })
          }
        })
      })
    }

    if (that.currentType == 3 || that.currentType == 4) {
      that.fullscreenLoading = true;
      ajaxGet(that,'/api/admin/building/checkin',null,function(resCheckin) {
        that.fullscreenLoading = false;
        if(!regNullArray(resCheckin)){
          // 楼
          let tpBuilding = onArrUnique(resCheckin.map(item => ({
            value: item.buildingId,
            label: item.building,
            parentId: null,
          })));
          // 层
          let tpFloorArr = onArrUnique(resCheckin.map(item => ({
            value: item.floorId,
            label: item.floor+'（层）',
            parentId: item.buildingId,
            currentIndex:setInt(item.floor)
          }))).sort(function (a, b) { return a.currentIndex - b.currentIndex; });
          // 室
          let tpRoomArr = onArrUnique(resCheckin.map(item => ({
            value: item.roomId,
            label: item.room+'（室）',
            floor: item.floor+'（层）',
            floorId: item.floorId,
            building: item.building,
            buildingId: item.buildingId,
            parentId: item.floorId,
            currentIndex:setInt(item.room)
          }))).sort(function (a, b) { return a.currentIndex - b.currentIndex; });
          that.allRooms = JSON.parse(JSON.stringify(tpRoomArr));

          that.optRooms = onGetJsonToTree([...tpBuilding, ...tpFloorArr, ...tpRoomArr], 'value', 'parentId', 'children');
          // 床位
          if(that.currentType == 4 ){
            let tpBedArr = onArrUnique(resCheckin.map(item => ({
              value: item.bedId,
              label: '床号：'+ item.name + "（" + item.studentName + "，" + item.className + "）",
              studentId: item.studentId,
              studentName: item.studentName,
              classId: item.classId,
              className: item.className,
              bed: item.name,
              room: item.room,
              floor: item.floor,
              building: item.building,
              photo: item.photo,
              parentId: item.roomId,
              currentIndex:setInt(item.name),
            }))).sort(function (a, b) { return a.currentIndex - b.currentIndex; });
            that.optBeds = tpBedArr;
          }

        }
      });
    }
    that.ruleForm.logDate = new Date();
    that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
  }

  /**
   * @description 数据递归
   * @param {Object} that this
   * @param {Object} val 需要递归数据
   */
  function InitItem(that, val) {
    if (val.length !== 0) {
      val.forEach((item) => {
        that.allReasons.push(item);
        if (item.children.length != null && typeof(item.children.length) != "undefined" && item.children.length >=
          1) {
          InitItem(that, item.children);
        }
      });

    }
  }
</script>

<style scoped="scoped" lang="less">
  .studentBox {
    background: #e2e2e2;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
  }
  .operateCol {
    margin: 10px 0;
  }
  .operateDivTag {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    flex-wrap: wrap;
    text-align: center;
    margin-top: 20px;
    .operateTag {
      cursor: pointer;
    }
  }
  .divDialogReasonBox{
    .divCheckNum{
      margin-top: -20px;
      margin-bottom: 15px;
      b{
        color: #E6A23C;
        margin: 0 5px;
      }
    }

    .tabBody{
      padding: 5px;
        .listBox{
          color: #909199;
          &:hover{
            color: #303133;
            cursor: pointer;
          }
        }
        .actList{
          color:#409EFF;
          &:hover{
            color: #409EFF;
            cursor: pointer;
          }
        }
    }

  }
  .nav-item {
    padding: 0 10px;
  }

  .divDialogBox {
    margin: 0 auto;
    width: 80%;
  }

  .scoreBox .spLabel {
    padding-left: 2px;
    padding-right: 2px;
    font-weight: bolder;
  }

  .scoreBox .itemList {
    padding-top: 5px;
    padding-bottom: 5px;
  }


  .stuInfo {
    position: relative;
    height: 100%;
    min-height: 80px;
    margin: 15px;
  }

  .stuInfo .stuInfoBox {
    display: flex;
  }

  .stuInfo .divInfoBox {
    padding-left: 10px;
  }


  .stuInfo .stuImgCont {
    width: 70px;
    height: 70px;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #DDDDDD;
  }



  .stuInfo .stuName {
    color: #212E48;
    font-size: 18px;
    font-weight: bold;
  }

  .stuInfo .stuRole {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
  }

  .stuInfo .stuRoom {
    font-size: 12px;
    color: #767676;
  }

  .stuInfo .stuOther {
    color: #767676;
    font-size: 12px;
  }

  .stuInfo .stuRemove {
    display: none;
  }

  .stuInfo:hover .stuRemove {
    display: block;
    position: absolute;
    top: -0.9375rem;
    left: -0.625rem;
    font-size: 22px;
    cursor: pointer;
  }


  .transferBox {
    margin: 0 auto;
  }

  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }


  .tabListCont .divNavTitle .divTitleCont {
    padding: 10px 0px 10px 10px;
    color: #DEE2E6;
    cursor: pointer;
    font-size: 18px;
  }

  .tabListCont .divNavTitle .divTitleCont .spNum {
    background-color: #DEE2E6;
    font-family: 'OswaldLight';
    padding: 2px 5px;
    border-radius: 4px;
    margin-left: 5px;
    font-size: 14px;
    color: #FFFFFF;
  }

  .tabListCont .divNavTitle .divTitleAct {
    color: #3e3e3e;
  }

  .tabListCont .divNavTitle .divTitleAct .spNum {
    background-color: #17A2B8;
    color: #FFFFFF;
  }

  .tabListCont .divNavTitle .nav-link {
    font-size: 14px;
    color: #B1B1B1;
  }

  .tabListCont .divNavTitle .nav-link .spNum {
    font-family: 'OswaldLight';
    font-weight: normal;
    font-size: 14px;
    padding-left: 10px;
  }

  .tabListCont .divNavTitle .active {
    color: #007bff;
  }

  .divAlertCont {
    padding-left: 30px;
    padding-top: 10px;
    color: #9595A0;
  }
</style>

<style>
  .transferCont .el-transfer__buttons {
    width: 20%;
  }

  .transferCont .el-transfer__buttons .el-transfer__button:first-child {
    float: left;
  }

  .transferCont .el-transfer__buttons .el-transfer__button:last-child {
    float: right;
  }

  .transferCont .el-transfer-panel {
    width: 40%;
  }

  .formGroupCont .el-date-editor--datetimerange.el-input__inner {
    width: 100%;
  }

  .stuInfo .stuImgCont .image-slot {
    font-size: 28px;
    color: #DDDDDD;
  }

  .navTabsCont .nav-link {
    font-size: 12px;
  }

  .navToolsCont {
    margin-top: 1px;
  }

  .dialog-footer-operate {
    text-align: center;
  }

  .colOperate {
    margin-bottom: 20px;
  }
</style>
