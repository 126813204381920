<!--
 ECHARTS 的 雷达图
-->
<template>
  <!-- 250px -->
  <div :id="comID" :style="pieStyle"></div>
</template>

<script>
  import 'echarts/lib/component/tooltip';
  import 'echarts/lib/component/legend';

  // 引入基本模板
  let echarts = require('echarts/lib/echarts');
  // 引入柱状图组件
  require('echarts/lib/chart/radar');
  export default {
    name: 'chart_radar',
    props: {
      /**
       * @description 控件ID
       */
      comID: {
        type: String,
        default: 'myChart'
      },
      /**
       * @description 高度
       */
      comHeight: {
        type: Number,
        default: 250
      },
      /**
       * @description 数据
       */
      comChartData:{
        type: Array,
        default: () => {
          return [];
        }
      }
    },
    data() {
      return {
        pieStyle: '',
        myChart: {},
        chartData: [],
        // 雷达图标题和上限分
        optIndicator: [],
      };
    },
    created() {
      let that = this;
        if (!regNullArray(that.comChartData)) {
          that.onGetRadar(that.comChartData);
        }
    },
    mounted() {
      let that = this;
      window.onresize = function() {
        that.myChart.resize();
        that.pieStyle = 'width: 100%; height:' + (this.comHeight * 0.0625) + 'rem;';
      }
    },
    methods: {
      /**
       * @description 调用方法
       * @param {Object} objData
       */
      onGetRadar(objData) {
        let that = this;
        that.chartData = [];
        that.optIndicator = [];
        if (!regNullArray(objData)) {

          objData.forEach((item) => {
            that.optIndicator.push({
              name: item.name,
              max: regNull(item.ceiling) ? item.score + item.baseScore + 10 : ((item.score + item.baseScore) >=
                item.ceiling ? item.score + item.baseScore + 10 : item.ceiling)
            });
            that.chartData.push(item.score + item.baseScore);
          });


          that.$nextTick(() => {
            that.drawLine();
          })
          that.pieStyle = 'width: 100%; height:' + (that.comHeight * 0.0625) + 'rem;';
        }


      },
      /**
       * @description 生成雷达图
       */
      drawLine() {
        let that = this;
        // 基于准备好的dom，初始化echarts实例
        let myChart = echarts.init(document.getElementById(that.comID));
        // 绘制图表

        if (!regNullArray(that.optIndicator) && !regNullArray(that.chartData)) {
          myChart.setOption({
            tooltip: {
              trigger: 'axis'
            },
            color: ['#1e79d9'],
            radar: {
              radius: '50%',
              name: {
                textStyle: {
                  color: '#303333',
                  // backgroundColor: '#1e79d9',
                  borderRadius: 3,
                  padding: [5, 5],
                  fontSize: 14
                },
              },
              indicator: that.optIndicator
              // [
              //   {
              //     name: '品德优良',
              //     max: 100
              //   },
              //   {
              //     name: '身心健康',
              //     max: 100
              //   },
              //   {
              //     name: '技能精湛',
              //     max: 100
              //   },
              //   {
              //     name: '人文扎实',
              //     max: 100
              //   },
              // ]
            },
            series: [{
              name: '总评',
              type: 'radar',
              tooltip: {
                trigger: 'item'
              },
              areaStyle: {
                color: 'rgba(44, 121, 217, 0.3)',
              },
              data: [{
                value: that.chartData,
                name: '综合总评',
              }, ],
            }]
          });
        }
      },
    }
  };
</script>

<style>

</style>
