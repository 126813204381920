<!--
  处分管理 - 新增
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">

    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          查看奖励信息
        </h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-12 colInfo">
            <label>奖项名称</label>
            <div class="colFonts">{{ruleForm.name | flNoValue}}</div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>奖励级别</label>
            <div class="colFonts">{{ruleForm.level | flNoValue}}</div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>奖励类型</label>
            <div class="colFonts">{{ruleForm.type | flNoValue}}</div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>奖励日期</label>
            <div class="colFonts">{{ruleForm.prizeDate | flNoValue | dateformatDay}}</div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>获奖班级</label>
            <div class="colFonts">{{ruleForm.className | flNoValue }}</div>
          </div>
          <div class="col-lg-12 colInfo">
            <label>备注</label>
            <div class="colFonts" v-if="ruleForm.remark!=null && ruleForm.remark.length>0" v-html="ruleForm.remark">
            </div>
            <div class="colFonts" v-else>暂无数据</div>
          </div>
        </div>
      </div>
    </el-card>






    <!--附件-->
    <comShowImage ref="comShowImage" :uploadFiles="uploadFiles"></comShowImage>



    <div class="row">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
      </div>
    </div>

    <!-- 综合类型弹出框 -->
    <el-dialog :title="dialogScoreName" :visible.sync="dialogScoreVisible" width="30%">
      <div class="divTagBox">
        <span class="spTab" v-for="item in dialogArray" :key="item.configId">
          <el-tag type="info">{{item.name}}<span class="spScore">
              {{item.score}}
            </span></el-tag>
        </span>
      </div>
    </el-dialog>


    <!--配合走马灯效果放大图片组件-->
    <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="uploadFiles" />


  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comShowImage from '@/components/showImage.vue';
  export default {
    components: {
      comShowImage
    },
    data() {
      return {
        showViewer: false,
        ruleForm: //表单数据
        {
          name: null,
          prizeId: null, //奖励主键，新增时为null
          semesterId: null, //学期
          typeId: null, //奖励类型
          levelId: null, //奖励等级主键
          prizeDate: null, //奖励日期
          remark: null, //备注
          GrowthConfigs: null, //综合类型集合
          members: [],
          attachment: null,
          level: {
            name: null,
          },
          type: {
            name: null,
          }
        },

        fullscreenLoading: false,
        uploadFiles: [],

        // 综合类型详情框
        dialogScoreVisible: false,
        // 综合类型弹出框名称
        dialogScoreName: null,
        // 综合弹出框内的数据
        dialogArray: [],
        styleWidth:'25%',

        arrGrowthConfigs:[],
      };
    },

    filters: {
      flNoValue(obj) {
        if (obj == null || typeof(obj) == "undefined" || obj == '') {
          return '暂无数据';
        }
        return obj;
      }
    },
    created() {
      let that = this;
      let id = that.$route.params.id;
      var detailUrl = null;
      if (id != null && typeof(id) != "undefined" && id != "") {
        that.ruleForm.reasonId = id;
        detailUrl = "/api/admin/classprize/" + id;
        onGetData(that);
      }

    },

    methods: {
      /**
       * @description 打开综合类型弹出框
       * @param {Object} _data
       */
      onOpenTypeInfo(_data) {
        let _this = this;
        _this.dialogScoreName = _data.name;
        _this.dialogArray = [];
        for (let item of _data.children) {
          if (item.score != 0) {
            _this.dialogArray.push({
              name: item.name,
              score: item.score
            })
          }
        }

        if (_this.dialogArray.length != 0) {
          _this.dialogScoreVisible = true;
        } else {
          info(_this, '未配综合类型');
        }
      },


      /**
       * @description 计算综合分数
       * @param {Object} _data
       * @return {Number} 计算出的总分
       */
      onCalculationScore(_data) {
        let temp = 0;
        for (let item of _data) {
          temp = item.score + temp;
        }
        return temp;
      },



      getTreeData(data) {
        // 循环遍历json数据
        for (var i = 0; i < data.length; i++) {

          if (data[i].children.length < 1) {
            // children若为空数组，则将children设为undefined
            data[i].children = undefined;
          } else {
            // children若不为空数组，则继续 递归调用 本方法
            this.getTreeData(data[i].children);
          }
        }
        return data;
      },
      onPictureHandleSuccess(param) {
        let that = this;
        that.ruleForm.attachment = "";
        for (var i in param) {
          if (that.ruleForm.attachment == "") {
            that.ruleForm.attachment = param[i].path;
          } else {
            that.ruleForm.attachment += "|" + param[i].path;
          }
        }
      },
      onBack() {
        let that = this;
        back(that);
      },
    },
  };


  function onGetData(that){
    that.fullscreenLoading = true;
    ajaxGet(that, '/api/admin/classprize/'+that.ruleForm.reasonId, null, function(resData) {
      if (resData != null) {
        that.ruleForm = resData;
        that.uploadFiles = [];
        if (resData.attachment != null && typeof(resData.attachment) != "undefined" && resData.attachment != "") {
          var files = resData.attachment.split('|');
          for (var i in files) {
            that.uploadFiles.push(that.$ServerUrl() + files[i]);
          }
        }
      }

      let tempConfig = resData.configs;


        ajaxGet(that, "/api/admin/GrowthConfig", null, function(resGrowth) {

          that.fullscreenLoading = false;
          if (tempConfig != null && tempConfig.length > 0) {
            for (let item of resGrowth) {
              let temp = tempConfig.find(val => val.configId == item.configId);
              if (temp != undefined) {
                item.score = temp.score
              } else {
                item.score = 0;
              }
            }
              RecursionData(that, resGrowth);
            that.styleWidth=setInt(100/that.arrGrowthConfigs.length)+'%';
          }



          // let punishRecord = [];
          // if (resData.members != null && resData.members.length > 0) {
          //   punishRecord = resData.members[0].configs
          // }

          // var configs = r.configs;
          // for (var i in r2) {
          //   r2[i].isSelect = false;
          //   r2[i].score = null;
          //   for (var j in configs) {
          //     if (configs[j].configId == r2[i].configId) {
          //       r2[i].isSelect = true;
          //       r2[i].score = configs[j].score;
          //     }
          //   }
          // }



        });

      that.fullscreenLoading = false;
    });
  }

  /**
   * @description 递归综合类型数据
   * @param {Object} _this
   * @param {Object} _data 数据组
   */
  function RecursionData(_this, _data) {
    if (_data.length !== 0) {
      _data.forEach((item) => {
        if (item.parentId == "00000000-0000-0000-0000-000000000000") {
          _this.arrGrowthConfigs.push({
            configId: item.configId,
            name: item.name,
            parentId: item.parentId,
            score: item.score,
            children: _data.filter(val => val.parentId == item.configId)
          });
        }
      });
    }
  }
</script>

<style scoped="scoped">
  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }

  .spIcon {
    width: 5px;
    height: 20px;
    border-radius: 5px;
    background-image: linear-gradient(#4545FF, #2D8EFF);
  }

  .spTitle {
    float: left;
    margin-left: 20px;
    color: #323E56;
    letter-spacing: 2px;
    font-size: 16px;
    margin-top: -3px;
  }

  .wMax {
    width: 100% !important;
  }
</style>

<!-- 综合类型 -->
<style scoped="scoped">
  .divTagBox {
    padding: -5px;
  }

  .divTagBox .spTab {
    padding: 5px;
  }

  .divTagBox .spScore {
    color: #fa3534;
  }

  .divLiteracyBox {
    padding: 15px;
  }

  .divLiteracyBox .listBox {
    text-align: center;
    padding: 20px;
    float: left;
    border: 2px solid #FFFFFF;
    border-radius: 5px;
  }

  .divLiteracyBox .listBox:hover {
    border: 2px solid #409eff;
    cursor: pointer;
  }

  .divLiteracyBox .literacyName {
    font-size: 24px;
    letter-spacing: 2px;
    padding-bottom: 10px;
  }

  .divLiteracyBox .literacyNum {
    padding-top: 10px;
    font-family: 'OswaldLight';
    border-top: 2px solid #EEEEEE;
    font-size: 32px;
    color: #409eff;
  }
</style>
