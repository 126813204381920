<!--社会实践-->
<template>
  <div class="SubWebPageStudent" v-loading.fullscreen.lock="fullscreenLoading">

    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">我的奖学金</h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <el-button type="success" size="mini" icon="el-icon-circle-plus-outline" @click="onCreate()">申请奖学金
              </el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table stripe :data="itemData" class="elTableData">
          <el-table-column type="index" label="序号" width="80"></el-table-column>
          <el-table-column prop="year" label="学年"></el-table-column>
          <el-table-column label="批次时段">
            <template slot-scope="scope">
              {{scope.row.startDate | dateformatDay}} 至 {{scope.row.endDate | dateformatDay}}
            </template>
          </el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              <span class="color-warning" v-if="scope.row.status==1">待审核</span>
              <span class="color-gray" v-else>{{scope.row.statusString}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="160px" fixed="right">
            <template slot-scope="scope">
              <operationBtn :btnName="'查看'" @click.native="onDetail(scope.row.applyId)"></operationBtn>
              <operationBtn :btnName="'编辑'" @click.native="onEdit(scope.row)" v-if="scope.row.status<0"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
  </div>
</template>

<script>
  import '@/assets/css/Student.css';
  import operationBtn from '@/components/operationBtn.vue';
  export default {
    components: {
      operationBtn,
    },
    data() {
      return {
        // 加载动画
        fullscreenLoading: false,
        //列表数据
        itemData: [],
      };
    },
    created() {
      let that = this;
      that.onGetData(that);
    },
    methods: {
      /**
       * @description
       * @param {Object} that
       */
      onGetData(that) {
        that.fullscreenLoading = true;
        ajaxGet(that, '/api/student/scholarshipapply', null, function(resData) {
          that.fullscreenLoading = false;
          that.itemData = resData;
        });
      },
      /**
       * @description
       * @param {Object} that
       */
      onCreate() {
        let that = this;
        ajaxGet(that, '/api/student/scholarshipbatch', null, function(res) {
          if (!regNullArray(res)) {
            ajaxGet(that, '/api/student/scholarshipapply/candidate/' + res[0].batchId, null, function(resdata) {
              if (resdata != null) {
                that.$router.push({
                  path: "/Student/ScholarshipMGT/Create",
                  query: {
                    url: that.$router.history.current.fullPath,
                    batchId: resdata.batchId,
                    level: resdata.level,
                    studentId: resdata.studentId,
                    yearId: resdata.yearId,
                  }
                });
              } else {
                warning(that, '没有可申请的奖学金!');
              }
            })
          }else{
            warning(that, '没有可申请的奖学金!');
          }
        })

      },

      /**
       * @description 详情
       * @param {Object} objID
       */
      onDetail(objID) {
        let that = this;
        that.$router.push({
          path: "/Student/ScholarshipMGT/Details/" + objID,
          query: {
            url: that.$router.history.current.fullPath,
          }
        });
      },

      /**
       * @description 编辑
       * @param {Object} objID
       */
      onEdit(obj) {
        let that = this;
        that.$router.push({
          path: "/Student/ScholarshipMGT/Edit/" + obj.applyId,
          query: {
            url: that.$router.history.current.fullPath,
            studentId: obj.studentId,
            yearId: obj.yearId,
          }
        });
      },

    },
  };
</script>

<style scoped="scoped">

</style>
