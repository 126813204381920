<!--
  基础信息-课程管理
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <comSearch :ColNumber="3" :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null">
    </comSearch>
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          课程信息
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item mr-2" v-if="isPower.course_import">
              <!--导入-->
              <!-- <el-button type="info" icon="el-icon-upload2" @click="onOpenDialog()">导入总课表</el-button> -->
              <comImport @onImportSuccess="onImportSuccess" :template="'/templates/总课表导入模版.xlsx'" :path="uploadInfoApi"
                :btnIcon="''" :title="'导入总课表'" :btnName="'批量导入课程'" ref="conImportInfoFile">
              </comImport>
            </li>
            <li class="nav-item mr-2" v-if="isPower.course_batch">
              <!--导入-->
              <el-button type="info" icon="el-icon-edit-outline" @click="onBatch()">批量课程配置</el-button>

            </li>
            <li class="nav-item">
              <el-button v-if="isPower.course_create == true" type="success" icon="el-icon-circle-plus-outline"
                @click="onCreate()">新增</el-button>
            </li>

          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table :data="itemData" stripe class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="name" label="课程名称" class="elColumn"></el-table-column>
          <el-table-column prop="semesterName" label="所属学期" class="elColumn"></el-table-column>
          <el-table-column prop="className" label="所属班级" class="elColumn"></el-table-column>
          <el-table-column label="课程类型" class="elColumn">
            <template slot-scope="scope">
              <span :class="scope.row.courseType==null?'color-LightGray':''">
                {{scope.row.courseType==null?'暂未设置':(scope.row.courseType==1?'考试课':'考查课')}}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <operationBtn v-if="isPower.course_details == true" :btnName="'查看'" ref="detail" @click.native="onDetail(scope.row.courseId)"></operationBtn>
              &nbsp;
              <el-dropdown v-if="isPower.course_edit || isPower.course_delete" trigger="click"
                @command="handleCommand($event,scope.row.courseId)">
                <el-button size="mini">
                  更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="isPower.course_edit" class="color-warning"
                    command="onEdit">编辑</el-dropdown-item>
                  <el-dropdown-item divided v-if="isPower.course_delete" class="color-danger"
                    command="onDelete">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body" v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>

  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import comSearch from '@/components/search.vue';
  import comImport from '@/components/CourseImport.vue';
  export default {
    components: {
      comPage,
      operationBtn,
      comSearch,
      comImport
    },
    data() {
      return {
        fullscreenLoading: false,
        //搜索
        SearchList: null,
        //分页查询接口返回的结果
        page: null,
        typeList: [],
        semesterList: [],
        classList: [],
        //列表数据
        itemData: [],
        //分页左侧按钮集合
        pageBtnList: null,
        //分页数据
        paging: {
          // 总共页数
          pageLength: 0,
          // 每页请求的数据量
          Size: 1,
          //当前在第几页
          Index: 1
        },
        isPower: {
          //增删改查 权限管理
          course_details: false,
          course_create: false,
          course_edit: false,
          course_delete: false,
          course_import: false,
          course_batch:false,
        },

        // 确认学期模态框
        dialogVisible: false,
        uploadInfoApi: null,
        optSemester: [],
        nowSemesterId: null,
      };
    },
    methods: {
      /**
       * @description 更多操作
       * @param {Object} command
       * @param {Object} objValID
       */
      handleCommand(command, objValID) {
        let that = this;
        switch (command) {
          case 'onEdit':
            that.onEdit(objValID);
            break;
          case 'onDelete':
            that.onDelete(objValID);
            break;
        }
      },
      /**
       * @description 批量配置课程
       */
      onBatch() {
        let that = this;
        that.$router.push({
          path: "/Admin/Course/Batch",
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      /**
       * @description 打开确认学期模态框
       */
      onOpenDialog() {
        let that = this;
        that.dialogVisible = true;
      },
      /**
       * @description 导出学生信息成功后执行
       * @param {Object} param
       */
      onImportSuccess(param) {
        let that = this;
        onGetDataPage(that);
      },
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },
      // 跳转新增页面
      onCreate() {
        let that = this;
        that.$router.push({
          path: '/Admin/Course/Create',
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      // 详情
      onDetail(dataID) {
        this.$router.push({
          path: "/Admin/Course/Detail/" + dataID,
          query: {
            url: this.$router.history.current.fullPath
          }
        });
      },
      // 编辑
      onEdit(dataID) {
        this.$router.push({
          path: "/Admin/Course/Edit/" + dataID,
          query: {
            url: this.$router.history.current.fullPath
          }
        });
      },
      // 删除
      onDelete(dataID) {
        let that = this;
        confirm(that, '删除该课程后,将无法恢复，您还要选择删除吗?', (r) => {
          if (r) {
            let apiUrl = '/api/admin/academiccourse/' + dataID;
            that.fullscreenLoading = true;
            ajaxDelete(that, apiUrl, null, function(obj) {
              onGetDataPage(that);
            });
          }
        })
      },
      // 搜索
      onSearch(params) {
        let that = this;
        let data = {};
        let searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = element.data;
          }
        });
        data.radom = Math.random() * 1000
        data.PageNumer = 1;
        routerPath(that, "/Admin/Course/Index", data, null, "PageSize");
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      // 分页面按钮
      pagingClick(type, val) {
        let that = this;
        let url = "/Admin/Course/Index";
        that.paging.Index = val;
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "Name",
            "CourseType",
            "ClassId",
            "SemesterId"
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "Name",
            "CourseType",
            "ClassId",
            "SemesterId"
          );
        }
      },
    },
    created() {
      let that = this
      isPower(that, "course_details", "course_details");
      isPower(that, "course_create", "course_create");
      isPower(that, "course_edit", "course_edit");
      isPower(that, "course_delete", "course_delete");
      isPower(that, "course_import", "course_import");
      isPower(that, "course_batch", "course_batch");
      that.fullscreenLoading = true
      ajaxGet(that, "/api/admin/semester", null, function(res) {
        that.semesterList = res.map(it => {
          return {
            Title: it.name,
            Value: it.semesterId
          }
        });
        that.optSemester = that.semesterList;
        ajaxGet(that, "/api/admin/sysuser/userfilternograduate", null, function(res1) {
          that.classList = res1.map(it => {
            return {
              Title: it.alias,
              Value: it.classId
            }
          })
          ajaxGet(that, "/api/admin/academiccourse/type", null, function(res2) {
            that.typeList = res2.map(it => {
              return {
                Title: it.Text,
                Value: it.Value
              }
            })
            that.fullscreenLoading = false
            onGetDataPage(that);
          })
        })
      })
    },
    watch: {
      $route() {
        onGetDataPage(this);
      }
    }
  };

  function onGetDataPage(that) {
    paging(
      that,
      function(r) {
        that.uploadInfoApi = '/api/admin/academiccourse/import/' + r.SemesterId;
        let data = {
          PageNumer: r.PageNumer,
          PageSize: r.PageSize,
          Name: r.Name,
          ClassId: r.ClassId,
          SemesterId: r.SemesterId,
          CourseType: r.CourseType
        };
        that.nowSemesterId = r.SemesterId;
        that.SearchList = [{
            type: "input",
            Name: "课程名称",
            data: r.Name,
            holder: "请输入课程关键字",
            zhi: "Name"
          },
          {
            type: "select",
            Name: "学期",
            data: r.SemesterId,
            select: that.semesterList, //选择的数据
            zhi: "SemesterId",
            isClear: false, //select是否可以清除
          },
          {
            type: "select",
            Name: "班级",
            data: r.ClassId,
            select: that.classList, //选择的数据
            zhi: "ClassId",
            isClear: false, //select是否可以清除
          },
          {
            type: "select",
            Name: "课程类型",
            data: r.CourseType,
            select: that.typeList, //选择的数据
            zhi: "CourseType",
            isClear: true, //select是否可以清除
          },
        ];
        that.paging.Index = r.PageNumer;
        that.paging.Size = r.PageSize;
        that.fullscreenLoading = true;
        ajaxGet(that, "/api/admin/academiccourse/search", data, function(r) {
          that.page = r;
          that.itemData = r.items;
          that.fullscreenLoading = false;
        });
      },
      "Name", "SemesterId", "ClassId", "CourseType"
    );
  }
</script>

<style scoped="scoped" lang="less">
  .divdvContBox {
    padding-left: 15px;
    margin-top: -15px;
    display: flex;

    .divSelect {
      padding-right: 15px;
      width: 70%;
    }
  }
</style>
