<!--
  新增家校活动记录
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{Title}}
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item prop="semesterId" label="学期">
                <el-select v-model="ruleForm.semesterId" filterable placeholder="请选择学期" style="width: 100%;"
                  :disabled="Title=='编辑'">
                  <el-option v-for="item in optSemester" :key="item.semesterId" :label="item.name"
                    :value="item.semesterId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="classId" label="班级">
                <el-select v-model="ruleForm.classId" filterable placeholder="请选择班级" style="width: 100%;"
                  :disabled="Title=='编辑'">
                  <el-option v-for="item in optClass" :key="item.classId" :label="item.alias" :value="item.classId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系日期" prop="date">
                <el-date-picker v-model="ruleForm.date" type="datetime" format="yyyy-MM-dd HH:mm:ss"
                  placeholder="请选择日期时间" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="人数" prop="numberOfPeople">
                <el-input v-model="ruleForm.numberOfPeople" style="width: 100%;"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="地点" prop="place">
                <el-input v-model="ruleForm.place" style="width: 100%;"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="组织者" prop="organizer">
                <el-input v-model="ruleForm.organizer" style="width: 100%;"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item prop="objective" label="活动目的">
                <quill-editor height="300px" v-model="ruleForm.objective" ref="text" class="myQuillEditor"
                  :options="editorOption" />
                <!-- <el-input type="textarea" v-model="ruleForm.objective" :autosize="{ minRows: 5}" maxlength="500"
                  show-word-limit></el-input> -->
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item prop="form" label="活动形式">
                <quill-editor height="300px" v-model="ruleForm.form" ref="text" class="myQuillEditor"
                  :options="editorOption" />
                <!-- <el-input type="textarea" v-model="ruleForm.form" :autosize="{ minRows: 5}" maxlength="500"
                  show-word-limit></el-input> -->
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item prop="process" label="过程设计">
                <quill-editor height="300px" v-model="ruleForm.process" ref="text" class="myQuillEditor"
                  :options="editorOption" />
                <!-- <el-input type="textarea" v-model="ruleForm.process" :autosize="{ minRows: 5}" maxlength="500"
                  show-word-limit></el-input> -->
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="效果" prop="effect">
                <quill-editor height="300px" v-model="ruleForm.effect" ref="text" class="myQuillEditor"
                  :options="editorOption" />
                <!-- <el-input v-model="ruleForm.effect" style="width: 100%;"></el-input> -->
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>

      <div class="row" style="margin-top: 10px;">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
          <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
          <el-button type="primary" icon="el-icon-circle-check" @click="onSave()">提交</el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import {
    quillEditor
  } from 'vue-quill-editor';
  import * as Quill from 'quill' //引入编辑器
  var fonts = ['SimSun', 'SimHei', 'Microsoft-YaHei', 'KaiTi', 'FangSong', 'Arial', 'Times-New-Roman', 'sans-serif'];
  var Font = Quill.import('formats/font');
  Font.whitelist = fonts; //将字体加入到白名单
  Quill.register(Font, true);

  export default {
    components: {
      quillEditor
    },
    data() {
      return {
        fullscreenLoading: false,
        editorOption: {
          placeholder: '请在此输入内容',
          modules: {
            toolbar: [
              [{
                'font': fonts
              }], //字体，把上面定义的字体数组放进来
              ['bold', 'italic', 'underline',
                'strike'
              ], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
              ['blockquote', 'code-block'], // 引用  代码块-----['blockquote', 'code-block']
              [{
                header: 1
              }, {
                header: 2
              }], // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
              [{
                list: 'ordered'
              }, {
                list: 'bullet'
              }], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
              [{
                script: 'sub'
              }, {
                script: 'super'
              }], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
              [{
                indent: '-1'
              }, {
                indent: '+1'
              }], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
              [{
                'direction': 'rtl'
              }], // 文本方向-----[{'direction': 'rtl'}]
              [{
                size: ['small', false, 'large', 'huge']
              }], // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
              [{
                header: [1, 2, 3, 4, 5, 6, false]
              }], // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
              [{
                color: []
              }, {
                background: []
              }], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
              [{
                align: []
              }], // 对齐方式-----[{ align: [] }]
              ['clean'], // 清除文本格式-----['clean']
              // ['image'], // 链接、图片、视频-----['link', 'image', 'video']
            ]
          }
        },
        optClass: [],
        optSemester: [],
        ruleForm: {
          activityId: null,
          semesterId: null,
          classId: null,
          date: null, //时间
          place: null, //地点
          numberOfPeople: null, //人数
          objective: null, //目的
          form: null, //形式
          process: null, //过程
          effect: null, //效果
          organizer: null //组织者
        },
        ruleFormReset: {}, //重置
        Title: "新增",
        rules: {
          semesterId: [{
            required: true,
            message: '学期不能为空',
            trigger: 'change'
          }, ],
          classId: [{
            required: true,
            message: '班级不能为空',
            trigger: 'change'
          }, ],
          date: [{
            required: true,
            message: '时间不能为空',
            trigger: 'change'
          }, ],
          place: [{
            required: true,
            message: '地点不能为空',
            trigger: 'change'
          }, ],
          objective: [{
            required: true,
            message: '活动目的不能为空',
            trigger: 'change'
          }, ],
          form: [{
            required: true,
            message: '活动形式不能为空',
            trigger: 'change'
          }, ],
          process: [{
            required: true,
            message: '过程设计不能为空',
            trigger: 'change'
          }, ],
          effect: [{
            required: true,
            message: '效果不能为空',
            trigger: 'change'
          }, ],
          organizer: [{
            required: true,
            message: '组织者不能为空',
            trigger: 'change'
          }, ],
          numberOfPeople: [{
              required: true,
              message: '人数不能为空',
              trigger: 'change'
            },
            {
              validator: (rule, value, callback) => {
                let that = this;
                var reg = /^[1-9]\d*$/;
                if (!reg.exec(value)) {
                  callback(new Error("人数必须为正整数"));
                } else {
                  callback();
                }
              },

            }
          ],
        },
        TeacherInfo: {}, // 教师信息
      };
    },
    created() {
      let that = this;
      that.TeacherInfo = onGetNowUser(that);
      let id = that.$route.params.Id;
      that.ruleForm.activityId = id;
      that.fullscreenLoading = true;
      ajaxGet(that, "/api/admin/Semester", null, function(resultSemester) {
        let resultClass = that.TeacherInfo.isTeacher ? that.TeacherInfo.manageClass : that.TeacherInfo.allClass;

        that.fullscreenLoading = false;
        that.optSemester = resultSemester;
        that.optClass = resultClass;
        if (id != null) {
          that.Title = '编辑';
          that.fullscreenLoading = true;
          ajaxGet(that, "/api/admin/adviserparentactivityrecord/" + id, null, function(res) {
            that.fullscreenLoading = false;
            that.ruleForm = res;
            that.ruleForm.date = timeTransform(that.ruleForm.date);
            that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
          })
        } else {
          for (var item of resultSemester) {
            if (item.isCurrent == true) {
              that.ruleForm.semesterId = item.semesterId;
            }
          }
          that.ruleForm.classId = regNullArray(resultClass) ? null : resultClass[0].classId;
          that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
        }

      })
    },

    mounted() {},
    methods: {
      onReset() {
        let that = this;
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      onBack() {
        let that = this;
        back(that);
      },
      onSave() {
        let that = this;
        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            that.ruleForm.effect = that.ruleForm.effect.replace(new RegExp(/( )/g), '&nbsp;');
            that.ruleForm.process = that.ruleForm.process.replace(new RegExp(/( )/g), '&nbsp;');
            that.ruleForm.objective = that.ruleForm.objective.replace(new RegExp(/( )/g), '&nbsp;');
            that.ruleForm.form = that.ruleForm.form.replace(new RegExp(/( )/g), '&nbsp;');
            if (that.ruleForm.activityId == null) {
              that.fullscreenLoading = true;
              that.ruleForm.date = timeTransform(that.ruleForm.date);

              ajaxPost(that, "/api/admin/adviserparentactivityrecord", that.ruleForm, function(r) {
                that.fullscreenLoading = false;
                that.onBack();
              });
            } else {
              that.fullscreenLoading = true;
              that.ruleForm.date = timeTransform(that.ruleForm.date);
              ajaxPut(that, "/api/admin/adviserparentactivityrecord/" + that.ruleForm.activityId, that.ruleForm,
                function(r) {
                  that.fullscreenLoading = false;
                  that.onBack();
                });
            }
          }
        });
      }
    },
    watch: {

    }
  };

</script>

<style scoped="scoped">

</style>
<style>
  .ql-toolbar.ql-snow {
    line-height: 20px;
  }

  .ql-snow .ql-tooltip[data-mode=link]::before {
    content: "请输入链接地址:";
  }

  .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    border-right: 0px;
    content: '保存';
    padding-right: 0px;
  }

  .ql-snow .ql-tooltip[data-mode=video]::before {
    content: "请输入视频地址:";
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: '14px';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=small]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
    content: '10px';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=large]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
    content: '18px';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=huge]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
    content: '32px';
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=SimSun]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=SimSun]::before {
    content: "宋体";
    font-family: "SimSun";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=SimHei]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=SimHei]::before {
    content: "黑体";
    font-family: "SimHei";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=Microsoft-YaHei]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=Microsoft-YaHei]::before {
    content: "微软雅黑";
    font-family: "Microsoft YaHei";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=KaiTi]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=KaiTi]::before {
    content: "楷体";
    font-family: "KaiTi";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=FangSong]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=FangSong]::before {
    content: "仿宋";
    font-family: "FangSong";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=Arial]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=Arial]::before {
    content: "Arial";
    font-family: "Arial";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=Times-New-Roman]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=Times-New-Roman]::before {
    content: "Times New Roman";
    font-family: "Times New Roman";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=sans-serif]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=sans-serif]::before {
    content: "sans-serif";
    font-family: "sans-serif";
  }

  .ql-font-SimSun {
    font-family: "SimSun";
  }

  .ql-font-SimHei {
    font-family: "SimHei";
  }

  .ql-font-Microsoft-YaHei {
    font-family: "Microsoft YaHei";
  }

  .ql-font-KaiTi {
    font-family: "KaiTi";
  }

  .ql-font-FangSong {
    font-family: "FangSong";
  }

  .ql-font-Arial {
    font-family: "Arial";
  }

  .ql-font-Times-New-Roman {
    font-family: "Times New Roman";
  }

  .ql-font-sans-serif {
    font-family: "sans-serif";
  }

</style>

