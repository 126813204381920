<!--
  日志管理-系统日志
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
		<search :ColNumber="2" :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null"></search>
		<el-card class="box-card">
			<div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          系统日志
        </h3>
			</div>
		<div class="card-body p-0" v-if="item!=null && item.length!=0">
				<el-table stripe :data="item" class="elTableData">
				  <!-- <el-table-column type="index" width="80px;" label="序号"></el-table-column> -->
					<el-table-column prop="message" label="异常消息" min-width="600">
					  <template slot-scope="scope">
						  <span>{{scope.row.message}}</span>
					  </template>
					</el-table-column>
				  <el-table-column prop="url" label="异常地址" min-width="200">
					  <template slot-scope="scope">
						  <span>{{scope.row.url}}</span>
					  </template>
				  </el-table-column>
          <el-table-column prop="logType" label="异常类型" min-width="120">
            <template slot-scope="scope">
          	  <span>{{scope.row.logType}}</span>
            </template>
          </el-table-column>
					<el-table-column prop="createDate" label="异常时间" min-width="180">
					  <template slot-scope="scope">
						  <span>{{dateFormat('YYYY-mm-dd HH:MM:SS',scope.row.createDate)}}</span>
					  </template>
					</el-table-column>
				  <el-table-column label="操作" width="100px"  fixed="right">
					<template slot-scope="scope">
						<operationBtn v-if="isPower.log_details == true" :btnName="'查看'" @click.native="onDetail(scope.row.logId)"></operationBtn>
					</template>
				 </el-table-column>
				</el-table>
        <comPage
        	:paging="page"
        	:pageBtnList="pageBtnList"
        	@pagingClick="pagingClick"
        	@pageBtnClick="pageBtnClick">
        </comPage>
      </div>
		
      
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
		</el-card>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
	import operationBtn from '@/components/operationBtn.vue';
	import search from '@/components/search.vue';

  export default{
    name: '',
    components:{
      comPage,
			operationBtn,
			search
    },
    data(){
      return{
				fullscreenLoading:false,
				page:null,//分页查询接口返回的结果
				item:[], //列表数据
				pageBtnList:null,//分页左侧按钮集合
				paging: { //分页数据
					pageLength: 0, // 总共页数
					Size: 10, // 当前请求数量
					Index: 1 //当前在第几页
				},
				SearchList: null,//搜索集合
        isPower: {
        		//增删改查 权限管理
        		log_details: false,
        },
      };
    },
		created() {
			let that = this;
			isPower(that, "log_details","log_details");
			pagingPage(that);
		},
		mounted() {

		},
    methods:{
      //时间格式转换
      dateFormat(fmt, date)
      {
        let ret="";
        date=new Date(date);
        const opt = {
          'Y+': date.getFullYear().toString(), // 年
          'm+': (date.getMonth() + 1).toString(), // 月
          'd+': date.getDate().toString(), // 日
          'H+': date.getHours().toString(), // 时
          'M+': date.getMinutes().toString(), // 分
          'S+': date.getSeconds().toString() // 秒
          // 有其他格式化字符需求可以继续添加，必须转化成字符串
        }
        for (let k in opt) {
          ret = new RegExp('(' + k + ')').exec(fmt)
          if (ret) {
            fmt = fmt.replace(
              ret[1],
              ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, '0')
            )
          }
        }
        return fmt
      },
      onDetail(Id)
      {
      	let that = this;
      	that.$router.push({
      		path: "/Admin/ExceptionLog/Teacher/Details/" + Id,
      		query: { url: that.$router.history.current.fullPath }
      	});
      },
      pageBtnClick(index) {
      	//分页组件左侧的按钮事件，按顺序进行判断
      },
      onSearch(params)
      {
      	let that = this;
      	let data = {};
      	let searchData = params;
      	searchData.forEach(element => {
      		if (element.data) {
      			data[element.zhi] = element.data;
      		}
      	});
      	data.PageNumer = 1;
      	routerPath(that, "/Admin/ExceptionLog/Teacher/Index", data, null, "PageSize");
      },
      pagingClick(type, val) {
      	let that = this;
      	let url = "/Admin/ExceptionLog/Teacher/Index";
      	if (type == 1) {//更改每页条数触发的事件
      		routerPath(
      			that,
      			url,
      			"PageSize",
      			val,
      			"PageNumer",
						"logType",
						"Message"
      		);
      	} else { //更改当前页时触发的事件
      		routerPath(
      			that,
      			url,
      			"PageNumer",
      			val,
      			"PageSize",
						"logType",
						"Message"
      		);
      	}
      },
    },
		watch: {
			$route() {
				let that = this;
				pagingPage(that);
			}
		}
  };

function pagingPage(that) {
	paging(
			that,
			function (r) {
				let Myurl = "/api/admin/log/search";
				that.fullscreenLoading = true;
				ajaxGet(that,"/api/admin/log/type",null,function(resultLogType){
          var LogTypeList = [];
          for(var i in resultLogType)
          {
          	var it = {
          		Title:resultLogType[i].Text,
          		Value:resultLogType[i].Value,
          	};
          	LogTypeList.push(it);
          }
          that.SearchList = [
            {
              type: "select",
              Name: "异常类型",
              data: r.logType,
              select: LogTypeList,
              zhi: "logType"
            },
            {
              type: "input",
              Name: "异常消息",
              data: r.Message,
              holder: "请输入异常消息关键词",
              zhi: "Message"
            }
          ];
		  

          let data = {
            PageNumer: r.PageNumer,
            PageSize: r.PageSize,
            logType:r.logType,
            Message:r.Message,
          };
          ajaxGet(that, Myurl, data, function (r1) {
            that.page = r1;
            that.item = r1.items;
            that.fullscreenLoading = false;
          });
        })
			},
			"logType",
			"Message"
		);
}
</script>

<style scoped="scoped">
</style>
