<!--
  奖励信息
-->
<template>
  <div class="SubWebPageStudent" v-loading.fullscreen.lock="fullscreenLoading">
    <!-- 搜索 -->
    <search :key="random" :ColNumber="2" :SearchList="SearchList" ref="SearchList"
      @onSearch="onSearch" v-if="SearchList!=null"></search>
    <!-- 列表-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="width:100%;padding-left: 0px;font-size: 12px;">
          <ul class="nav nav-pills navTabsCont" style="float: left;">
            <li class="nav-item">
              <router-link :to="'/Student/Prize/Index'" class="nav-link">
                我的奖励
              </router-link>
            </li>
            <li class="nav-item">
              <router-link :to="'/Student/Prize/List'" class="nav-link active">
                我的申请
              </router-link>
            </li>
          </ul>
          <div style="float: right;">
            <el-button type="success" icon="el-icon-circle-plus-outline" @click="onAdd()">新增申请</el-button>
          </div>
        </h3>
      </div>
      <!-- <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0"> -->
      <div class="card-body p-0" v-if="itemData!=null && itemData.length>0">
        <el-table :data="itemData" stripe class="elTableData">
          <el-table-column type="index" label="序号" width="80"></el-table-column>
          <el-table-column prop="name" label="奖项" min-width="200"></el-table-column>

          <el-table-column prop="level" label="奖励级别" min-width="170">
            <template slot-scope="scope">
          	  <span v-if="scope.row.level!=null && scope.row.level!=''">{{scope.row.level.name}}</span>
            </template>
          </el-table-column>
          <el-table-column label="奖励时间" min-width="120">
            <template slot-scope="scope">
              <span>{{scope.row.punishDate | dateformat('YYYY-MM-DD')}}</span>
            </template>
          </el-table-column>
          <el-table-column label="申请时间" min-width="120">
            <template slot-scope="scope">
              <span>{{scope.row.lastUpdateDate | dateformat('YYYY-MM-DD')}}</span>
            </template>
          </el-table-column>
          <el-table-column label="状态" min-width="80">
            <template slot-scope="scope">
              <div>
                 <el-tag type="success"  v-if="scope.row.status == 4">已通过</el-tag>
                 <el-tag type="warning"  v-if="scope.row.status == 2">待审核</el-tag>
                 <el-tag type="danger"   v-if="scope.row.status == 3">未通过</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100" fixed="right">
            <template slot-scope="scope">
              <operationBtn v-if="scope.row.status == 3" :btnName="'编辑'" @click.native="onEdit(scope.row.applyId)"></operationBtn>
              <operationBtn v-else :btnName="'查看'" @click.native="onDetail(scope.row.applyId)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
        <comPage
        	:paging="paging"
        	:pageBtnList="pageBtnList"
        	@pagingClick="pagingClick"
        	@pageBtnClick="pageBtnClick">
        </comPage>
      </div>
     <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
  </div>
</template>

<script>
  import '@/assets/css/Student.css';
  import comPage from '@/components/Page.vue';
  import search from '@/components/search.vue';
  import operationBtn from '@/components/operationBtn.vue';

  export default{
    components:{
      search,operationBtn,comPage
    },
    data(){
      return{
        random: 0, //刷新组件
        // 动画
        fullscreenLoading: false,
        // 搜索
        SearchList: null,
        //列表数据
        itemData: [],
        //分页查询接口返回的结果
        //分页左侧按钮集合
        pageBtnList: null,
        //分页数据
        statusList:[
          {
            Title:'待审核',
            Value:'2'
          },
          {
            Title:'已通过',
            Value:'4'
          },
          {
            Title:'未通过',
            Value:'3'
          }
        ],
        paging: {
          totalCount: 0,
          currentPage: 1,
          pageSize: 10
        },
        isPower: {
        		//增删改查 权限管理
        		prize_detail: false,
        },
      };
    },
    methods:{
      onSearch(params){
        let that = this;
        let data = {};
        let searchData = params
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = Join(element.data);
          }
        });
        data.random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        routerPath(that, "/Student/Prize/List", data, null, "PageSize");
      },
      onAdd(){
        let that=this;
        that.$router.push({
          path: "/Student/Prize/Apply/",
          query: { url: that.$router.history.current.fullPath }
        });
      },
      onDetail(Id)
      {
        let that=this;
        that.$router.push({
          path: "/Student/Prize/Details/"+Id,
          query: {
            url: that.$router.history.current.fullPath,
            type:2
          }
        });
      },
      onEdit(Id){
        let that=this;
        that.$router.push({
          path: "/Student/Prize/Apply/",
          query: { url: that.$router.history.current.fullPath,Id}
        });
      },
      pageBtnClick(index) {
      	//分页组件左侧的按钮事件，按顺序进行判断
      },
      // 分页面按钮
      pagingClick(type, val)
      {
        let that = this;
        let url = '/Student/Prize/List';
        if (type == 1) { //更改每页条数触发的事件
          routerPath(that,url,"PageSize",val,"PageNumer","Name","Status");
        } else { //更改当前页时触发的事件
          routerPath(that,url,"PageNumer",val,"PageSize", "Name","Status");
        }
      }
    },
    watch: {
      $route() {
        let that = this;
        onGetDataPage(that);
      }
    },
    created() {
      let that = this;
      onGetDataPage(that)
    },
  };

  function onGetDataPage(that) {
    paging(
      that,
      function(res) {
        let apiUrl = "/api/student/prizeapply/search";
        that.SearchList = [
          // {
          //   type: "time",
          //   Name: "奖励时间",
          //   data: res.PrizeDate,
          //   zhi: "PrizeDate"
          // },
          {
            type: "input",
            Name: "奖项名称",
            data: res.Name,
            holder: "请输入奖项",
            zhi: "Name"
          },
          {
            type: "select",
            Name: "审核状态",
            select:that.statusList,
            data: res.Status,
            holder: "请选择类型",
            zhi: "Status"
          },
        ];
        let random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;
        let data = {
          PageSize:res.PageSize*1 || 10,
          PageNumer:res.PageNumer*1 || 1,
          Name:res.Name,
          Status:res.Status*1 || null
        }
        that.paging.currentPage = data.PageNumer,
        that.paging.pageSize = data.PageSize
        that.fullscreenLoading = true
        ajaxGet(that,apiUrl,data,function(r){
          that.paging.totalCount = r.totalCount
          that.itemData = r.items
          that.fullscreenLoading = false
        })
      },
      "Name",
      "PrizeDate",
      "Status"
    );
  }
</script>

<style scoped="scoped">

</style>
