<!--
  学生管理-综合积分
-->
<template>
  <div class="SubWebPageStudent" v-loading.fullscreen.lock="fullscreenLoading">
    <search :ColNumber="3" :SearchList="SearchList" :key="random" ref="SearchList" @onSearch="onSearch"
      v-if="SearchList!=null"></search>
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          综合积分
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item ml-3">
              <el-button type="success" icon="el-icon-circle-plus-outline" @click="onCreate">新增</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table stripe :data="itemData" class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="reason" label="原因" min-width="300"></el-table-column>
          <el-table-column label="学生" min-width="300">
            <template slot-scope="scope">
              {{onGetStudentInfo(scope.row.members)}}
            </template>
          </el-table-column>
          <el-table-column prop="score" label="分数" min-width="80"></el-table-column>
          <el-table-column prop="auditor" label="记录人" min-width="80"></el-table-column>
          <el-table-column label="行为时间" min-width="130">
            <template slot-scope="scope">
              {{scope.row.logDate | dateformat('YYYY-MM-DD')}}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="160px" fixed="right">
            <template slot-scope="scope">
              <operationBtn :btnName="'查看'" @click.native="onDetail(scope.row.logId)"></operationBtn>
              <operationBtn :btnName="'编辑'" v-if="scope.row.auditor == student.name" @click.native="onEdit(scope.row.logId)">
              </operationBtn>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
    <el-dialog title="查看详情" :visible.sync="dialogVisible" width="80%">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-lg-12 colInfo">
            <label>德育原因</label>
            <div class="colFonts">{{ruleFormdata.reason}}</div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>分数</label>
            <div class="colFonts">{{ruleFormdata.score}}</div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>行为时间</label>
            <div class="colFonts">{{ruleFormdata.logDate | dateformat('YYYY-MM-DD')}}</div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>操作时间</label>
            <div class="colFonts">{{ruleFormdata.createDate | dateformatMinute}}</div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>记录人</label>
            <div class="colFonts">{{ruleFormdata.auditor}}</div>
          </div>
          <div class="col-lg-12 colInfo">
            <label>记录对象</label>
            <el-row :gutter="20" v-if="ruleFormdata.members!=null && ruleFormdata.members.length>0">
              <el-col :span="8" v-for="(p,index) in ruleFormdata.members" :key="index">
                <div class="stuInfo">
                  <div class="stuInfoBox">
                    <div class="stuImgCont VerticalMid">
                      <el-image :src="p.photo">
                        <div slot="error" class="image-slot">
                          <svg class="icon" aria-hidden="true">
                            <use xlink:href="#fax-image-split"></use>
                          </svg>
                        </div>
                      </el-image>
                    </div>
                    <div class="divInfoBox">
                      <div class="stuName">{{p.studentName}}</div>
                      <div class="stuOther">{{p.studentNo}}</div>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
  import '@/assets/css/Student.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';

  export default {
    name: '',
    components: {
      comPage,
      operationBtn,
      search
    },
    data() {
      return {
        random: null,
        fullscreenLoading: false,
        itemData: [],
        //分页查询接口返回的结果
        page: null,
        //分页左侧按钮集合
        pageBtnList: null,
        //分页数据
        paging: {
          // 总共页数
          pageLength: 0,
          // 每页请求的数据量
          Size: 1,
          //当前在第几页
          Index: 1
        },
        ruleFormdata: {},
        SearchList: null, //搜索集合
        dialogVisible: false,
        student:null,
      };
    },
    created() {
      let that = this;
      that.student = JSON.parse(that.$GetKey('Student'));
      pagingPage(that);
    },
    methods: {
      /**
       * @description 获取学生信息
       * @param {Object} objVal
       */
      onGetStudentInfo(objVal) {
        let tempArr = new Array(objVal.map(item => item.studentName));
        return tempArr.join(' ，');
      },
      /**
       * @description 返回
       */
      onBack() {
        back(this);
      },
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },

      /**
       * @description 详情
       * @param {Object} objID
       */
      onDetail(objID) {
        let that = this;
        ajaxGet(that, "/api/student/classcreditlog/" + objID, null, function(resdata) {

          if (resdata != "") {
            that.ruleFormdata = resdata;
          }
          that.dialogVisible = true;
        })
      },
      onEdit(objID) {
        let that = this;
        that.$router.push({
          path: "/Student/MoralEducation/Edit/" + objID,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      /**
       * @description 新增
       */
      onCreate() {
        let that = this;
        that.$router.push({
          path: "/Student/MoralEducation/Create",
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },

      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      onSearch(params) {
        let that = this;
        let searchData = params;
        let data = {};
        searchData.forEach(element => {
          data[element.zhi] = Join(element.data);
        });
        data.PageNumer = 1;
        data.radom = Math.random(1000)
        routerPath(that, "/Student/MoralEducation/Index", data, null, "PageSize");
      },
      pagingClick(type, val) {
        let that = this;
        let url = "/Student/MoralEducation/Index";
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "Date",
            "Reason"
          );
        }
      },
    },
    watch: {
      $route() {
        let that = this;
        pagingPage(that);
      }
    }
  };

  function pagingPage(that) {
    paging(
      that,
      function(res) {
        that.SearchList = [];
        that.SearchList.push({
          type: "time",
          Name: "行为时间",
          data: res.Date,
          zhi: "Date",
        }, {
          type: "input",
          Name: "原因",
          data: res.Reason,
          holder: "请输入原因关键词",
          zhi: "Reason"
        });

        let tempData = {
          LogDateStart: null,
          LogDateEnd: null,
          Reason:res.Reason,
          PageNumer: res.PageNumer,
          PageSize: res.PageSize,
        }


        if (!regNull(res.Date)) {
          let tempTime = res.Date.toString().split(',');
          tempData.LogDateStart = timeTransform(tempTime[0]);
          tempData.LogDateEnd = timeTransform(tempTime[1]);
        }
        that.fullscreenLoading = true;
        ajaxGet(that, '/api/student/classcreditlog/search', tempData, function(resData) {
          that.fullscreenLoading = false;
          that.page = resData;
          that.itemData = resData.items;
        });
      },
      "Date",
      "Reason",
    );
  }
</script>

<style scoped="scoped">
  .stuInfo {
    position: relative;
    height: 100%;
    min-height: 80px;
    margin: 15px;
  }

  .stuInfo .stuInfoBox {
    display: flex;
  }

  .stuInfo .divInfoBox {
    padding-left: 10px;
  }


  .stuInfo .stuImgCont {
    width: 50px;
    height: 50px;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #DDDDDD;
  }



  .stuInfo .stuName {
    color: #212E48;
    font-size: 18px;
    font-weight: bold;
  }

  .stuInfo .stuRole {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
  }

  .stuInfo .stuRoom {
    font-size: 12px;
    color: #767676;
  }

  .stuInfo .stuOther {
    color: #767676;
    font-size: 12px;
  }

  .stuInfo .stuRemove {
    display: none;
  }

  .stuInfo:hover .stuRemove {
    display: block;
    position: absolute;
    top: -0.9375rem;
    left: -0.625rem;
    font-size: 22px;
    cursor: pointer;
  }
</style>
