<!--
  学生管理-学生奖励
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <search :ColNumber="3" :SearchList="SearchList" ref="SearchList" @onSearch="onSearch"
      v-if="SearchList!=null"></search>
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont" style="padding-left: 0px;">
            搜索
          </h3>
        </div>
        <div class="card-body ">
          <el-form ref="form" :model="searchForm" label-width="80px">
            <el-row :gutter="20">
              <el-col :span="6">
                <div class="selTitleBox">
                  <span class="spTitle">月份</span>
                </div>
                <div class="selBodyBox">
                  <el-date-picker v-model="searchForm.MonthDate" type="month" class="width_100Pie"
                    placeholder="请选择月份时间" value-format="yyyy-MM" ></el-date-picker>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="selTitleBox">
                  <span class="spTitle">年级</span>
                </div>
                <div class="selBodyBox">
                  <el-select v-model="searchForm.selMajor" filterable clearable placeholder="请选择"
                    class="width_100Pie" @change="onChangeMajor" @clear="onClearCollege">
                    <el-option v-for="item in searchForm.tempMajor" :key="item.value" :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="selTitleBox">
                  <span class="spTitle">班级</span>
                </div>
                <div class="selBodyBox">
                  <el-select v-model="searchForm.selClass" @change="onChangeClass" filterable clearable placeholder="请选择" class="width_100Pie">
                    <el-option v-for="item in searchForm.tempClass" :key="item.value" :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="selTitleBox">
                  <span class="spTitle">班主任</span>
                </div>
                <div class="selBodyBox">
                  <el-input placeholder="请输入班主任姓名" v-model="searchForm.Teacher" clearable></el-input>
                </div>
              </el-col>
        </el-row>
            </el-form>
          </div>
          <div class="card-footer text-center">
            <el-button type="primary" class="mr-2" slot="append" icon="el-icon-search" @click.native="onSearch()">查询
            </el-button>
            <el-button slot="append" class="mr-2" icon="el-icon-refresh" @click.native="onResetSearch()">重置</el-button>
          </div>
        </el-card>
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          班主任月度考核
        </h3>
        <div class="card-tools" v-if="isPower.admin_assessment_moon_calculate">
          <ul class="nav">
            <li class="nav-item">
              <el-button type="success" size="mini" @click="onCalculate" icon="el-icon-circle-plus-outline">计算月度考核
              </el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0 " v-if="itemData!=null && itemData.length!=0">
        <el-table stripe :data="itemData" class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod" fixed="left">
          </el-table-column>
          <el-table-column prop="teacher" label="班主任" min-width="180">
          </el-table-column>
          <el-table-column prop="className" label="班级" min-width="200">
          </el-table-column>
          <el-table-column prop="score" label="考核分" min-width="100">
          </el-table-column>
          <el-table-column prop="resultStr" label="考核结果" min-width="100">
          </el-table-column>
          <el-table-column label="操作" width="160px" fixed="right">
            <template slot-scope="scope">
              <operationBtn :btnName="'查看'" v-if="isPower.admin_assessment_moon_details" @click.native="onDetail(scope.row.assessmentId)"></operationBtn>
              <operationBtn :btnName="'编辑'" v-if="isPower.admin_assessment_moon_edit" @click.native="onEdit(scope.row.assessmentId)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>

    <el-dialog title="编辑班主任考核结果" :visible.sync="dialogVisible" width="30%" :before-close="handleClose"
      :append-to-body="true" :fullscreen="false">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-12 colInfo">
            <label>班级</label>
            <div class="colFonts">{{ruleForm.className}}</div>
          </div>
          <div class="col-lg-12 colInfo">
            <label>班主任</label>
            <div class="colFonts">{{ruleForm.teacher}}</div>
          </div>
          <div class="col-lg-12 colInfo">
            <label>原考核结果</label>
            <div class="colFonts">{{ruleForm.resultStr | nullData}}</div>
          </div>
          <div class="col-lg-12 colInfo">
            <label>更改考核结果</label>
            <div class="colFonts ">
              <el-select v-model="txtNewResult" placeholder="请选择" class="width_100Pie">
                <el-option v-for="item in optType" :key="item.Value" :label="item.Text" :value="item.Value">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">

        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="onSave">确 定</el-button>

      </span>
    </el-dialog>


  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  // import search from '@/components/search.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import comPage from '@/components/Page.vue';
  export default {
    name: '',
    components: {
      // search,
      operationBtn,
      comPage
    },
    data() {
      return {
        fullscreenLoading: false,
        itemData: [], //列表数据
        SearchList: null, //搜索集合
        arrTitle: [],
        page: null, //分页查询接口返回的结果
        pageBtnList: null, //分页左侧按钮集合
        paging: { //分页数据
          pageLength: 0, // 总共页数
          Size: 10, // 当前请求数量
          Index: 1 //当前在第几页
        },
        searchForm:{
          tempMajor:[],
          selMajor:null,
          tempClass:[],
          selSelect:[],
          MonthDate:'',
          Teacher:'',
          allClassData: [],//所有班级数据
        },
        ruleForm: {},
        txtNewResult: null,
        optType: [],
        dialogVisible: false,
        tipFont: {
          time: null,
          majorId: null,
          major: null,
        },
        isPower:{
          admin_assessment_moon_details:false,
          admin_assessment_moon_calculate:false,
          admin_assessment_moon_edit:false
        }
      };
    },
    created() {
      let that = this;
      isPower(that,'admin_assessment_moon_details','admin_assessment_moon_details');
      isPower(that,'admin_assessment_moon_calculate','admin_assessment_moon_calculate');
      isPower(that,'admin_assessment_moon_edit','admin_assessment_moon_edit');
      that.onGetType(that);
      pagingPage(that);
    },
    methods: {
      /**
       * @description 计算月度考核
       */
      onCalculate() {
        let that = this;
        let msg = '';
        if (!regNull(that.tipFont.collegeId)) {
          if (!regNull(that.tipFont.time) && !regNull(that.tipFont.major)) {
            msg = '确认时间为【' + that.tipFont.time + '】，年级为【' + that.tipFont.major + '】的班主任进行月度考核计算？计算时等待时间可能过长，需要耐心等待。';
          } else {
            msg = '确认进行班主任月度考核计算？计算时等待时间可能过长，需要耐心等待。';
          }
          confirmDelete(that, msg, function(res) {
            if (res == true) {
              that.fullscreenLoading = true;
              let tempData = {
                time: that.tipFont.time,
                collegeId: that.tipFont.collegeId
              }
              ajaxPost(that, "/api/admin/advisermonthlyassessment", tempData, function(r) {
                that.fullscreenLoading = false;
                pagingPage(that);
              });
            }
          });
        } else {
          warning(that, '未选择专业或时间');
        }

      },
      /**
       * @description 提交编辑考核结果
       */
      onSave() {
        let that = this;
        if (regNull(that.txtNewResult)) {
          warning(that, '请选择更改的考核结果');
          return false;
        }
        that.fullscreenLoading = true;
        ajaxPut(that, '/api/admin/advisermonthlyassessment/' + that.ruleForm.assessmentId + '?monthlyResult=' + setInt(
          that.txtNewResult), null, function(searchForm) {
          that.fullscreenLoading = false;
          that.handleClose();
          pagingPage(that);
        });
      },
      // 清除专业
      onClearCollege() {
        let that = this;
        that.searchForm.tempClass = [];
        that.searchForm.selClass = '';
        for (let item of that.searchForm.allClassData) {
          that.searchForm.tempClass.push({
            value: item.classId,
            label: item.name
          });
        }
      },
      //选择年级
      onChangeMajor(searchForm){
        let that = this;
        that.searchForm.selClass = '';
        let arrTemp = that.searchForm.allClassData.filter(item => item.collegeId == searchForm);
        that.searchForm.tempClass = [];
        for (let item of arrTemp) {
          that.searchForm.tempClass.push({
            value: item.classId,
            label: item.name
          });
        }
        },
      /**
       * @description 加载类型数据
       * @param {Object} that
       */
      onGetType(that) {
        that.fullscreenLoading = true;
        ajaxGet(that, '/api/admin/advisermonthlyassessment/type', null, function(resType) {
          that.fullscreenLoading = false;
          that.optType = resType;
        });
      },
      /**
       * @description 关闭弹出框
       */
      handleClose() {
        let that = this;
        that.ruleForm = {};
        that.dialogVisible = false;
      },
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },
      /**
       * @description 详情
       * @param {Object} Id
       */
      onDetail(objID) {
        let that = this;
        that.$router.push({
          path: "/Admin/Assessment/Moon/Details/" + objID,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onChangeClass(id){
        let that=this;
         that.$forceUpdate()
      },
      //去除数组内的重复
      onArrUnique(arr) {
        const searchForm = new Map();
        return arr.filter((arr) => !searchForm.has(arr.label) && searchForm.set(arr.label, 1));
      },
      /**
       * @description 编辑
       * @param {Object} objID
       */
      onEdit(objID) {
        let that = this;
        let tempData = that.itemData.find(item => item.assessmentId == objID);
        if (tempData != undefined) {
          that.ruleForm = tempData;
          that.dialogVisible = true;
        }
      },
      onSearch(params) {
        let that = this;
        that.paging.Index = 1;
        pagingPage(that);
      },
      // 搜索重置
      onResetSearch() {
        let that = this;
        that.searchForm.selMajor = that.searchForm.tempMajor[0].collegeId;
        that.searchForm.MonthDate = '';
        that.searchForm.Teacher = '';
        that.paging.Index = 1;
        pagingPage(that);
      },
      //分页组件左侧的按钮事件，按顺序进行判断
      pageBtnClick(index) {},
      // 分页面按钮
      pagingClick(type, val) {
        let that = this;
        if (type == 1) {
          //更改每页条数触发的事件
          that.paging.Size = val;
          that.paging.Index = 1;
        } else {
          //更改当前页时触发的事件
          that.paging.Index = val;
        }
        pagingPage(that);
      },
    },
    watch: {
      $route() {
        let that = this;
        pagingPage(that);
      }
    }
  };

  function pagingPage(that) {
        that.fullscreenLoading = true;
        ajaxGet(that, '/api/admin/college', null, function(resMajor) {
          ajaxGet(that, '/api/admin/sysuser/userfilternograduate', null, function(resClass) {
            if (regNull(that.searchForm.MonthDate)) {
              that.searchForm.MonthDate = dateFormat(new Date(), '-', 'YYYY-MM');
            }
            that.tipFont.time = that.searchForm.MonthDate;
            let tempMajor = [];
            resMajor.forEach(function(item) {
              tempMajor.push({
                value: item.collegeId,
                label: item.name,
              });
            });
            let tempClass = [];
            resClass.forEach(function(item) {
              tempClass.push({
                value: item.classId,
                label: item.alias,
              });
            });
            that.searchForm.allClassData = resClass;
            if (regNull(that.searchForm.selMajor) && !regNullArray(resMajor)) {
              that.searchForm.selMajor = resMajor[0].collegeId;
              that.tipFont.major = resMajor[0].name;
              that.tipFont.collegeId = resMajor[0].collegeId;
              that.onChangeMajor(that.searchForm.selMajor)
            } else {
              let tempMajorItem = resMajor.find(item => item.collegeId == that.searchForm.selMajor);
              if (tempMajorItem != undefined) {
                that.tipFont.major = tempMajorItem.name;
                that.tipFont.collegeId = tempMajorItem.collegeId;
              }
            }
            that.searchForm.tempMajor=tempMajor;
            let tempData = {
              Time: that.searchForm.MonthDate,
              CollegeId: that.searchForm.selMajor,
              ClassId: that.searchForm.selClass,
              TeacherName: that.searchForm.Teacher,
              PageNumer: that.paging.Index,
              PageSize: that.paging.Size,
            }
            // 获取班主任月考核
            ajaxGet(that, '/api/admin/advisermonthlyassessment/search', tempData, function(resData) {
              that.fullscreenLoading = false;
              if (!regNullArray(resData.items)) {
                that.page = resData;
                that.itemData = resData.items;
              } else {
                that.itemData = [];
              }
            });
          });
      },
    );
  }
</script>

<style scoped="scoped">
.selTitleBox {
  font-size: 14px;
}

.selTitleBox .spTitle {
  color: #303133;
}
</style>
