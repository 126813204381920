<template>
  <div class="btnContent">
    <el-button :size="Size" :type="Type">{{btnName}}</el-button>
  </div>
</template>

<script>
  function btnChange(that) {
    let name = that.btnName;
    if (name) {
      that.Icon = that.btnIcon;
      if (that.btnSize == null || typeof(that.btnSize) == "undefined" || that.btnSize == "") {
        that.Size = "mini";
      } else {
        that.Size = that.btnSize;
      }

      if (name.indexOf("查看") >= 0) {
        if (that.btnType == null || typeof(that.btnType) == "undefined" || that.btnType == "") {
          that.Type = "success";
        } else {
          that.Type = that.btnType;
        }
      } else if (name.indexOf("编辑") >= 0) {
        if (that.btnType == null || typeof(that.btnType) == "undefined" || that.btnType == "") {
          that.Type = "warning";
        } else {
          that.Type = that.btnType;
        }
      } else if (name.indexOf("删除") >= 0) {
        if (that.btnType == null || typeof(that.btnType) == "undefined" || that.btnType == "") {
          that.Type = "danger";
        } else {
          that.Type = that.btnType;
        }
      } else if (name.indexOf("确认名单") >= 0 || name.indexOf("学生记录") >= 0 || name.indexOf("初始化密码") >= 0 || name.indexOf(
          "授权") >= 0 || name.indexOf("学生范围") >= 0) {
        if (that.btnType == null || typeof(that.btnType) == "undefined" || that.btnType == "") {
          that.Type = "info";
        } else {
          that.Type = that.btnType;
        }
      } else {
        that.Type = that.btnType;
      }


    }
  }
  export default {
    props: {
      btnName: String,
      btnType: String,
      btnIcon: String,
      btnSize: String
    },
    created() {
      btnChange(this);
    },
    data() {
      return {
        Type: null,
        Icon: null,
        Size: null,
      };
    },
    methods: {
      composeValue(it, params) {
        return {
          'id': it,
          'params': params
        }
      },
      handleCommand(command) {

        this.$emit('dropdownClick', command);
      },
    },
    watch: {
      btnName() {
        btnChange(this);
      },
    }
  };
</script>
<style lang="less" scoped>
  .btnContent {
    margin-left: 2px;
    display: inline-block;
  }
</style>
