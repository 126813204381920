<!--
  首页
-->
<template>
  <div class="SubWebPageStudent" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          搜索
        </h3>
      </div>
      <div class="card-body">
        <el-form ref="form" :model="infoForm" label-width="80px">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="课程名称">
                <el-input v-model="infoForm.name" placeholder="请输入课程名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="课程类型">
                <el-cascader v-model="infoForm.typeId" class="width_100Pie" :options="optType" @change="onChangeType">
                </el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="课程日期">
                <el-date-picker style="width: 100%;" v-model="infoForm.currentDate" type="date" placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="card-footer text-center">
        <el-button type="primary" class="mr-2" slot="append" icon="el-icon-search" @click.native="onSearch()">查询
        </el-button>
        <el-button slot="append" class="mr-2" icon="el-icon-refresh" @click.native="onResetSearch()">重置</el-button>
      </div>
    </el-card>

    <el-card class="box-card" style="margin-top: 16px;">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          综合课堂信息
        </h3>
      </div>

      <div class="card-body p-0" v-if="itemData.length!=0">
        <el-table :data="itemData" stripe class="elTableData">
          <el-table-column type="index" label="序号" width="80"></el-table-column>
          <el-table-column prop="name" label="名称"></el-table-column>
          <el-table-column prop="type" label="类型" width="120"></el-table-column>
          <el-table-column label="完成度" width="120">
            <template slot-scope="scope">
              {{onGetProgress(scope.row.duration,scope.row.watchTime)}}
            </template>
          </el-table-column>
          <el-table-column prop="name" label="课程时间">
            <template slot-scope="scope">
              {{onGetDateFormat(scope.row.startDate)}} 至 {{onGetDateFormat(scope.row.endDate)}}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100px">
            <template slot-scope="scope">
              <operationBtn :btnName="'观看'" @click.native="onDetail(scope.row.courseId)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
        <comPage v-if="itemData.length!=0" :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick"
          @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body p-0" v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>

    </el-card>
  </div>
</template>

<script>
  import '@/assets/css/Student.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  export default {
    components: {
      comPage,
      operationBtn
    },
    data() {
      return {
        // 加载动画
        fullscreenLoading: false,
        // 搜索集合
        infoForm: {
          name: '',
          typeId: '',
          currentDate: '',
        },
        optType: [],
        //列表数据
        itemData: [],
        //分页查询接口返回的结果
        page: null,
        //分页左侧按钮集合
        pageBtnList: null,
        //分页数据
        paging: {
          // 总共页数
          pageLength: 0,
          // 每页请求的数据量
          Size: 10,
          //当前在第几页
          Index: 1
        },
      };
    },
    created() {
      let _this = this;
      _this.infoFormReset = JSON.parse(JSON.stringify(_this.infoForm));
      pagingPage(_this);
    },
    methods: {
      // 综合课堂类型
      onChangeType(value) {


      },
      // 搜索方法
      onSearch(params) {
        let _this = this;
        pagingPage(_this);
      },
      // 重置搜索内容
      onResetSearch() {
        let _this = this;
        _this.infoForm = JSON.parse(JSON.stringify(_this.infoFormReset));
        pagingPage(_this);
      },
      // 计算完成度
      onGetProgress(_targetVar, _nowVar) {
        if (_targetVar == -1) { // PDF
          if (_nowVar == -1) {
            return '100.00 %';
          } else {
            return '0.00 %'
          }
        } else {
          if (_nowVar == 0) {
            return '0.00 %';
          } else {
            let strTime = setTwoDecimal((_nowVar / _targetVar) * 100, 2);
            return strTime >= 100 ? '100.00 %' : strTime + ' %';
          }
        }
      },
      // 查看详情
      onDetail(_dataID) {
        let _this = this;
        this.$router.push({
          path: "/Student/LiteracyCourse/Details/" + _dataID,
          query: {
            url: _this.$router.history.current.fullPath
          }
        });
      },
      // 时间格式化
      onGetDateFormat(_value) {
        return dateFormat(_value, '.', 'YYYY-MM-DD HH:MM');
      },
      //分页组件左侧的按钮事件，按顺序进行判断
      pageBtnClick(index) {

      },
      // 分页面按钮
      pagingClick(type, val) {
        let _this = this;
        if (type == 1) {
          _this.paging.Index = 1;
          _this.paging.Size = val;
        } else {
          _this.paging.Index = val;
        }
        pagingPage(_this);
      },
      // 数据处理
      onGetOptions(_this, _data) {
        let arrTemp = [];
        if (_data.length != 0) {
          let temp = [];
          for (let item of _data) {
            if (item.children.length != 0) {
              temp.push({
                value: item.id,
                label: item.name,
                children: _this.onGetOptions(_this, item.children)
              });
            } else {
              temp.push({
                value: item.id,
                label: item.name,
              });
            }
          }
          arrTemp = temp;
        }
        return arrTemp;
      },

    },
    watch: {
      $route() {
        let _this = this;
        pagingPage(_this);
      }
    }
  };


  function pagingPage(_this) {
    _this.fullscreenLoading = true;
    // 选择的类型
    let typeID = '';
    if (_this.infoForm.typeId != '') {
      typeID = _this.infoForm.typeId[_this.infoForm.typeId.length - 1]
    }

    let data = {
      PageNumer: _this.paging.Index,
      PageSize: _this.paging.Size,
      CurrentDate: _this.infoForm.currentDate,
      Name: _this.infoForm.name,
      TypeId: typeID,
    };
    ajaxGet(_this, "/api/student/literacycourse/search", data, function(resData) {
      // 综合课堂类型
      ajaxGet(_this, "/api/admin/literacycoursetype/treelist", null, function(resType) {
        _this.fullscreenLoading = false;
        // 复制类型数据
        _this.optType = _this.onGetOptions(_this, resType);
        _this.page = resData;
        _this.itemData = resData.items;
      })
    });
  }
</script>



<style scoped="scoped">
  .divNullDataBox {
    padding: 30px;
    text-align: center;
    color: #9E9E9E;
  }
</style>
