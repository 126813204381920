<template>
  <div class="SubWebPage " v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          学生会管理
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <search :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null"></search>
            </li>
            <li class="nav-item">
              <el-button type="success" v-if="isPower.admin_studentunion_create" icon="el-icon-circle-plus-outline"
                @click="onCreate()">新增</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table :data="itemData" stripe>
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="name" label="学生会" min-width="200" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column label="年级" min-width="140" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              {{scope.row.collegeId==null?'校学生会':scope.row.college}}
            </template>
          </el-table-column>
          <el-table-column prop="number" label="人员" min-width="80"></el-table-column>

          <el-table-column label="操作" :width="300" fixed="right">
            <template slot-scope="scope">
              <operationBtn :btnName="'查看'" v-if="isPower.admin_studentunion_details"
                @click.native="onDetail(scope.row)"></operationBtn>
                &nbsp;
                <operationBtn :btnName="'原因授权'"  @click.native="onAuthorization(scope.row.associationId,2)"></operationBtn>
                &nbsp;
                <el-dropdown v-if="isPower.admin_studentunion_edit || isPower.admin_studentunion_delete" trigger="click"
                  @command="handleCommand($event,scope.row.associationId)">
                  <el-button size="mini">
                    更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-if="isPower.admin_studentunion_edit" class="color-warning"
                      command="onEdit">编辑</el-dropdown-item>
                    <el-dropdown-item divided v-if="isPower.admin_studentunion_delete" class="color-danger"
                      command="onDelete">删除</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>


            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>


    <!-- 新增/编辑 -->
    <el-dialog :title="ruleForm.associationId!=null ? '编辑' : '新增'" :visible.sync="dialogVisible" width="30%"
      :close-on-click-modal="false" :close-on-press-escape="false" :append-to-body="true" :fullscreen="false"
      :before-close="handleClose">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
        <el-row :gutter="20">
          <el-col :span="23">
            <el-form-item label="学生会名称" prop="name">
              <el-input v-model="ruleForm.name" placeholder="请输入学生会名称" class="width_100Pie"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="23">
            <el-form-item label="年级" prop="">
              <el-select :disabled="isEdit" v-model="ruleForm.collegeId" placeholder="请选择年级" class="width_100Pie" @change="onChangeClass">
                <el-option v-for="item in optClass" :key="item.collegeId" :label="item.name" :value="item.collegeId">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer ">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="onSave()">确 定</el-button>
      </span>
    </el-dialog>



  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';

  export default {
    components: {
      comPage,
      operationBtn,
      search,
    },
    data() {
      return {
        //加载动画
        fullscreenLoading: false,
        //搜索
        SearchList: null,
        //分页查询接口返回的结果
        page: null,
        isEdit:false,
        optClass: [],
        //列表数据
        itemData: [],
        rules: {
          name: [{
            required: true,
            message: '请输入学生会名称',
            trigger: 'change'
          }],
          collegeId: [{
            required: true,
            message: '请选择年级',
            trigger: 'change'
          }],
        },
        //分页左侧按钮集合
        pageBtnList: null,
        //分页数据
        paging: {
          // 总共页数
          pageLength: 0,
          // 每页请求的数据量
          Size: 1,
          //当前在第几页
          Index: 1
        },
        dialogVisible: false,
        ruleForm: {
          associationId: null,
        },
        ruleFormReset: null,

        // 权限限制
        isPower: {
          admin_studentunion_delete: false,
          admin_studentunion_create: false,
          admin_studentunion_edit: false,
          admin_studentunion_details: false,
        },
      };
    },
    methods: {
      /**
       * @description 批量授权
       */
      onAuthorization(id,type){
        let that=this;
        that.$router.push({
          path: "/Admin/MoralityReason/Authorization/" + type,
          query: {
            url: that.$router.history.current.fullPath,
            associationId : id,
          },
        });
      },
      /**
       * @description 更多操作
       * @param {Object} command
       * @param {Object} objValID
       */
      handleCommand(command, objValID) {
        let that = this;
        switch (command) {
          case 'onEdit':
            that.onEdit(objValID);
            break;
          case 'onDelete':
            that.onDelete(objValID);
            break;
        }
      },
      /**
       * @description 弹窗关闭
       * @param {Object} done
       */
      handleClose() {
        let that = this;
        that.dialogVisible = false;
        that.ruleForm={};
        try
        {
        	that.$refs["ruleForm"].resetFields();
        } catch{}
      },
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },

      /**
       * @description 打开新增弹出框
       */
      onCreate() {
        let that = this;
        that.isEdit = false
        that.dialogVisible = true;
      },
      /**
       * @description  选择年级
       */
      onChangeClass() {
        let that = this;

      },
      /**
       * @description 查看详情
       * @param {Object} Id
       */
      onDetail(row) {
        let that = this;
        that.$router.push({
          path: '/Admin/StudentUnion/Details/' + row.associationId,
          query: {
            collegeId:row.collegeId,
            name:row.name,
            url: that.$router.history.current.fullPath
          }
        });
      },
      /**
       * @description 新增
       */
      onSave() {
        let that = this;
        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            that.fullscreenLoading = true;
            if (that.ruleForm.associationId != null) {
              ajaxPut(that, '/api/admin/association/' + that.ruleForm.associationId, that.ruleForm, function(res) {
                that.fullscreenLoading = false;
                that.handleClose();
                pagingPage(that);
              })
            } else {
              ajaxPost(that, '/api/admin/association', that.ruleForm, function(res) {
                that.fullscreenLoading = false;
                that.handleClose();
                pagingPage(that);
              })
            }
          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              isError[0].querySelector('input').focus();
            }, 1)
            return false;
          }
        })
      },
      /**
       * @description 打开编辑弹出框
       * @param {String} Id
       */
      onEdit(objItem) {
        let that = this;
        that.fullscreenLoading = true;
        that.isEdit = true
        ajaxGet(that, '/api/admin/association/' + objItem, null, function(resdata) {
          that.fullscreenLoading = false;
          that.ruleForm = resdata;
          that.dialogVisible = true;
        })
      },
      /**
       * @description 搜索事件
       * @param {Object} params 条件
       */
      onSearch(params) {
        let that = this;
        let data = {};
        let searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = element.data;
          }
        });
        data.PageNumer = 1
        routerPath(that, "/Admin/StudentUnion/Index", data, null, "PageSize");
      },
      /**
       * @description 删除事件
       * @param {Object} Id
       */
      onDelete(objID) {
        let that = this;
        confirmDelete(that, '您确定要删除选中的学生会吗？ 删除后信息将不可恢复，请知悉', function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, "/api/admin/association/" + objID, null, function(r) {
              that.fullscreenLoading = false;
              pagingPage(that);
            });
          }
        })
      },
      /**
       * @description 分页组件左侧的按钮事件，按顺序进行判断
       * @param {Object} index
       */
      pageBtnClick(index) {},
      /**
       * @description 分页面按钮
       * @param {Object} type
       * @param {Object} val
       */
      pagingClick(type, val) {
        let that = this;
        let url = "/Admin/StudentUnion/Index";
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "Name",
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "Name",
          );
        }
      },
    },
    created() {
      let that = this;
      that.fullscreenLoading = true;
      isPower(that, 'admin_studentunion_delete', 'admin_studentunion_delete');
      isPower(that, 'admin_studentunion_create', 'admin_studentunion_create');
      isPower(that, 'admin_studentunion_edit', 'admin_studentunion_edit');
      isPower(that, 'admin_studentunion_details', 'admin_studentunion_details');

      ajaxGet(that, '/api/admin/college', null, function(res) {
        that.optClass = res;
        that.optClass.unshift({
          collegeId: null,
          name: '校学生会',
        });
        pagingPage(that);
        that.fullscreenLoading = false;
      });


    },
    watch: {
      $route() {
        let that = this;
        pagingPage(that);
      }
    }
  }
  /**
   * @description 分页加载数据
   * @param {Object} that
   */
  function pagingPage(that) {
    paging(
      that,
      function(res) {
        that.SearchList = [{
          type: "input",
          Name: "学生会名称",
          data: res.Name,
          holder: "请输入学生会",
          zhi: "Name"
        }, ];
        let data = {
          Name: res.Name,
          PageNumer: res.PageNumer,
          PageSize: res.PageSize,
        };
        that.paging.Index=res.PageNumer;
        that.paging.Size=res.PageSize;
        that.fullscreenLoading = true;
        ajaxGet(that, '/api/admin/association/search', data, function(resdata) {
          that.page = resdata;
          that.itemData = resdata.items;
          that.fullscreenLoading = false;
        })
      },
      "Name",
    );
  }
</script>
<style scoped="scoped">
</style>
<style>
  .iconClass {
    font-size: 22px;
    color: #406AD3;
  }

  .iconNoClass {
    font-size: 22px;
    color: #EFEFEF;
  }

  .clubBtnImport {
    margin-left: 2px;
    display: inline-block;
  }
</style>
