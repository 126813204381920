<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">

    <search :ColNumber="3" :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null">
    </search>

    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          奖学金评定名单
        </h3>
        <div class="card-tools">
          <ul class="nav nav-pills ml-auto">
            <li class="nav-item" style="margin-right: 5px;">
              <el-button @click="onOpenExport()" icon="el-icon-download" style="background-color:#17a2b8	;color: white;"
                v-if="isPower.scholarship_info_export">
                导出
              </el-button>
            </li>
            <li class="nav-item">
              <!--导入-->
              <comImport @onImportSuccess="onImportSuccess" :template="'/templates/学校评定等级导入模版.xlsx'"
                :path="uploadInfoApi" :btnIcon="'el-icon-upload2'" :title="'批量导入奖学金评定'" :btnName="'奖学金评定导入'"
                ref="conImportInfoFile" v-if="isPower.scholarship_info_import">
              </comImport>
            </li>
          </ul>
        </div>
      </div>

      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table stripe :data="itemData" class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="studentNo" label="学号" min-width="100"></el-table-column>
          <el-table-column prop="studentName" label="姓名" min-width="150"></el-table-column>
          <el-table-column prop="className" label="班级" min-width="120"></el-table-column>
          <el-table-column prop="applyLevelString" label="申报等级" min-width="150"></el-table-column>
          <el-table-column prop="levelString" label="评定等级" min-width="150"></el-table-column>
          <el-table-column prop="statusString" label="审核状态" min-width="150"></el-table-column>
          <el-table-column label="申请时间" min-width="140">
            <template slot-scope="scope">
              {{scope.row.createDate | dateformatMinute}}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100px" fixed="right">
            <template slot-scope="scope">
              <!-- <operationBtn :btnName="'查看'" @click.native="onDetail(scope.row.applyId)"></operationBtn> -->
              <operationBtn :btnName="'评定'" @click.native="onDetail(scope.row.applyId)"
                v-if="isPower.scholarship_school_assess && scope.row.status==4"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
    <div class="row" style="margin-top: 10px;">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack">返回</el-button>
      </div>
    </div>


  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';
  import comImport from '@/components/Import.vue';
  export default {
    name: '',
    components: {
      comPage,
      operationBtn,
      search,
      comImport,
    },
    data() {
      return {
        // 加载动画
        fullscreenLoading: false,
        // 列表数据
        itemData: [],
        page: null, //分页查询接口返回的结果
        pageBtnList: null, //分页左侧按钮集合
        paging: { //分页数据
          pageLength: 0, // 总共页数
          Size: 10, // 当前请求数量
          Index: 1 //当前在第几页
        },
        isBtnAudit: false,
        //搜索集合
        SearchList: null,
        isPower: {
          // 学校审核
          scholarship_school_audit: false,
          // 班主任审核
          scholarship_headteacher_audit: false,
          // 学校评定
          scholarship_school_assess: false,
          // 学院审核
          scholarship_college_audit: false,
          scholarship_info_export: false,
          scholarship_info_import: false,
        },
        uploadInfoApi: '/api/admin/scholarshipapply/import/',
        Statuslist: [{
            Title: '',
            Value: null,
          },
          {
            Title: '已评定',
            Value: true,
          },
          {
            Title: '待评定',
            Value: false,
          }
        ],

      };
    },
    created() {
      let that = this;
      that.uploadInfoApi = '/api/admin/scholarshipapply/import/' + that.$route.params.Id;
      isPower(that, "scholarship_school_assess", "scholarship_school_assess");
      isPower(that, "scholarship_info_export", "scholarship_info_export");
      isPower(that, "scholarship_info_import", "scholarship_info_import");
      pagingPage(that);

    },
    methods: {
      /**
       * @description 导入后的事件
       * @param {Object} param
       */
      onImportSuccess(param) {

        let that = this;
        pagingPage(that);
      },
      //打开导出弹出窗
      onOpenExport() {
        let _this = this;
        _this.dialogVisible = true;
        _this.arrCheckFields = JSON.parse(JSON.stringify([]));
        let data = {
          BatchId: _this.$route.params.Id,
          Status: 4,
        }
        let fileName = '奖学金评定信息' + getTimeID() + '.xls';
        _this.fullscreenLoading = true;
        ajaxExport(_this, '/api/admin/scholarshipapply/export', data, fileName, function(res) {
          _this.fullscreenLoading = false;
        })
      },
      /**
       * @description 返回
       */
      onBack() {
        back(this);
      },
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },
      /**
       * @description 详情
       * @param {Object} objID
       */
      onDetail(objID) {
        let that = this;
        that.$router.push({
          path: "/Admin/ScholarshipMGT/Info/Details/" + objID,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },


      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      /**
       * @description 搜索
       * @param {Object} params
       */
      onSearch(params) {
        let that = this,
          data = {},
          searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = Join(element.data);
          }
        });
        data.PageNumer = 1;
        routerPath(that, "/Admin/ScholarshipMGT/Info/Index/" + that.$route.params.Id, data, null, "PageSize");
      },
      /**
       * @description 页码
       * @param {Object} type
       * @param {Object} val
       */
      pagingClick(type, val) {
        let that = this;
        let url = '/Admin/ScholarshipMGT/Info/Index/' + that.$route.params.Id;
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "BatchId",
            "ClassId",
            "Student",
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "SemesterId",
            "BatchId",
            "ClassId",
            "Student",
          );
        }
      },
    },
    watch: {
      $route() {
        let that = this;
        pagingPage(that);
      }
    }
  };

  function pagingPage(that) {
    paging(
      that,
      function(res) {
        isPower(that, "scholarship_school_audit", "scholarship_school_audit");
        isPower(that, "scholarship_headteacher_audit", "scholarship_headteacher_audit");
        isPower(that, "scholarship_school_assess", "scholarship_school_assess");
        isPower(that, "scholarship_college_audit", "scholarship_college_audit");
        that.fullscreenLoading = true;

        // 获取批次
        ajaxGet(that, '/api/admin/scholarshipbatch/now', null, function(resBatch) {
          ajaxGet(that, '/api/admin/sysuser/userfilter', null, function(resClass) {


            ajaxGet(that, '/api/admin/scholarshipapply/auditortype', null, function(resType) {
              that.SearchList = [];

              let tempTypeId = res.Status,
                tempType = [];
              resType.forEach(function(item) {
                tempType.push({
                  Title: item.Text,
                  Value: item.Value,
                });
              });

              // 班级ID，
              let tempClassId = res.ClassId,
                tempCalss = [];
              resClass.forEach(function(item) {
                tempCalss.push({
                  Title: item.alias,
                  Value: item.classId,
                });
              });

              // 批次ID
              let tempBatchId = res.BatchId,
                tempBatch = [];
              resBatch.forEach(function(item) {
                tempBatch.push({
                  Title: item.yearName,
                  Value: item.batchId,
                });
              });

              if (regNull(res.BatchId)) {
                tempBatchId = regNullArray(resBatch) ? null : resBatch[0].batchId;
              }

              that.SearchList.push({
                  type: "select",
                  Name: "班级",
                  data: tempClassId,
                  select: tempCalss,
                  zhi: "ClassId",
                },
                // {
                //   type: "select",
                //   Name: "状态",
                //   data: tempTypeId,
                //   select: tempType,
                //   zhi: "Status",
                // },
                {
                  type: "input",
                  Name: "学生",
                  data: res.Student,
                  holder: "请输入学号/姓名",
                  zhi: "Student"
                });

              let tempData = {
                BatchId: that.$route.params.Id,
                ClassId: tempClassId,
                Name: res.Student,
                // IsAudit: true,
                Status: 4,
                PageNumer: res.PageNumer,
                PageSize: res.PageSize,
              }
              that.itemData = [];
              ajaxGet(that, '/api/admin/scholarshipapply/search', tempData, function(resData) {
                that.fullscreenLoading = false;
                if (!regNullArray(resData.items)) {
                  that.page = resData;
                  that.itemData = resData.items;
                } else {
                  that.itemData = [];
                }
              });
            });
          });
        });
      },
      "BatchId",
      "ClassId",
      "Status",
      "Student",
    );
  }
</script>

<style scoped="scoped">
</style>
