<!--
  新增家校联系
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="ruleForm" label-width="120px">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            查看
          </h3>
        </div>
        <div class="card-body">

          <div class="row">
            <div class="col-lg-3 colInfo">
              <label>学期</label>
              <div class="colFonts">{{ruleForm.semester | flNoValue}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>班级</label>
              <div class="colFonts">{{ruleForm.class | flNoValue}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>学生</label>
              <div class="colFonts">{{ruleForm.studentName | flNoValue}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>联系日期</label>
              <div class="colFonts">{{ruleForm.contactDate | dateformat('YYYY-MM-DD HH:mm')}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>联系对象</label>
              <div class="colFonts">{{ruleForm.relationShip | flNoValue}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>联系方式</label>
              <div class="colFonts">{{ruleForm.contactWay | flNoValue}}</div>
            </div>
          </div>
        </div>
      </el-card>

      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            联系原因
          </h3>
        </div>
        <div class="card-body">
          <div class="colRemarksCont" v-html="ruleForm.contactReason"></div>
        </div>
      </el-card>


      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            效果
          </h3>
        </div>
        <div class="card-body">
          <div class="colRemarksCont" v-html="ruleForm.effect"></div>
        </div>
      </el-card>


      <div class="row">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';


  export default {
    components: {

    },
    data() {
      return {
        fullscreenLoading: false,
        optClass: [],
        optSemester: [],
        optStudent: [],
        ruleForm: {
          recordId: null,
          semesterId: null,
          classId: null,
          studentId: null,
          relationShip: null, //与学生的关系
          contactDate: null, //联系日期
          contactReason: null, //联系原因
          contactWay: null, //联系方式
          effect: null //效果
        },
      };
    },
    created() {
      let that = this;
      let id = that.$route.params.Id;
      that.ruleForm.recordId = id;
      that.fullscreenLoading = true;
      ajaxGet(that, "/api/admin/advisercontactrecord/" + id, null, function(res) {
        that.fullscreenLoading = false;
        that.ruleForm = res;

        that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
      })

      that.fullscreenLoading = true;
    },

    mounted() {},
    methods: {
      onBack() {
        let that = this;
        back(that);
      },
    },
    watch: {

    },
    filters: {
      flNoValue(obj) {
        if (obj == null || typeof(obj) == "undefined" || obj == '') {
          return '暂无数据';
        }
        return obj;
      }
    },
  };
</script>

<style scoped="scoped">

</style>
