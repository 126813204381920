<!--
  综合活动 - 确认名单
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">

    <!-- 列表-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          {{Title}}
        </h3>
        <div class="card-tools">
          <el-select v-model="ruleForm.classId" placeholder="请选择班级" style="width: 100%;" @change="onChangeClass"
            filterable>
            <el-option v-for="item in optClass" :key="item.classId" :label="item.alias" :value="item.classId">
            </el-option>
          </el-select>
        </div>
        <div class="card-tools" style="margin-right:10px;" v-if="isAllCheackDisplay">
          <el-button @click="onClickAll">
            <el-checkbox v-model="isAllCheack" @change="onClickAll"></el-checkbox>
            {{isAllCheack?'取消全选':'全选'}}
          </el-button>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table stripe sortable="custom" :data="itemData"
          style="width: 100%; margin-top: 10px; padding-left: 10px; padding-right: 10px;">
          <el-table-column type="index" width="100px;" label="序号"></el-table-column>
          <el-table-column prop="studentNo" label="学号"></el-table-column>
          <el-table-column prop="name" label="学生"></el-table-column>
          <el-table-column prop="applyDate" label="报名状态">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.checked" :disabled="scope.row.disabled" @change="onChangeChecked"
                :class="{ckDisabled:scope.row.disabled}">报名</el-checkbox>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>

    <div class="row" style="margin-top: 10px;">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
        <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
        <el-button type="primary" icon="el-icon-circle-check" @click="onSave()">提交</el-button>
      </div>
    </div>


  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  export default {

    data() {
      return {
        dialogVisible: false,
        itemData: [],
        SearchTitles: [],
        SearchList: null, //搜索集合
        activityId: null,
        parentType: null,
        fullscreenLoading: false,
        Title: null,

        ruleForm: {
          classId: null,
          studentId: null,
          roleId: null
        },
        roleFormReset: null,

        optClass: [],
        optStudent: [],
        optRole: [],
        classRangs: [],
        studentRangs: [],

        isAllCheack: false, //全选不全选状态
        isAllCheackDisplay: false, //如果已经全选报名，全选取消全选按钮消失
      };
    },
    created() {
      let that = this;
      that.activityId = that.$route.params.id;
      that.parentType = that.$route.params.type;
      var detailUrl = null;
      if (that.activityId != null && typeof(that.activityId) != "undefined" && that.activityId != "") {
        onGetData(that)
      }
    },

    methods: {
      // 单个选择
      onChangeChecked() {
        let that = this;
        if (that.isAllCheack) { // 全选状态
          if(that.itemData.find(x => x.checked == false)) {
            that.isAllCheack = false;
          }
        } else { //取消全选状态
          if(that.itemData.find(x => x.checked == false) == null) {
            that.isAllCheack = true;
          }
        }
      },
      // 全选，取消全选
      onClickAll() {
        let that = this;
        that.isAllCheack = !that.isAllCheack;
        if(that.itemData && that.itemData.length > 0) {
          that.itemData.forEach(ele => {
            if (!ele.disabled) {
              ele.checked = that.isAllCheack;
            }
          })
        }
      },
      /**
       * @description 提交代报名数据
       */
      onSave() {
        let that = this;
        let _data = [];
        for (let item of that.itemData.filter(val => val.checked)) {
          if (!item.disabled) {
            _data.push(item.studentId)
          }
        }
        that.fullscreenLoading = true;
        ajaxPost(that, "/api/admin/activity/adviserenroll/" + that.activityId, _data, function(r) {
          that.fullscreenLoading = false;
          onGetData(that);
        });
      },
      /**
       * @description 选择班级
       * @param {Object} val
       */
      onChangeClass(val) {
        let that = this;
        that.isAllCheack = false;
        onGetData(that);
      },
      /**
       * @description 返回
       */
      onBack() {
        let that = this;
        back(that);
      },
      /**
       * @description 重置
       */
      onReset() {
        let that = this;
        onGetData(that);
      },

    },
    watch: {
      $route() {
        let that = this;
        onGetData(that);
      },
    },
  };


  /**
   * @description 加载学生数据
   * @param {Object} that
   */
  function onGetData(that) {
    that.isAllCheackDisplay = false;
    that.fullscreenLoading = true;
    ajaxGet(that, '/api/admin/activity/' + that.activityId, null, function(resData) {
      ajaxGet(that, "/api/admin/class/userclass", null, function(resClass) { //获取班主任管理的班级
        that.fullscreenLoading = false;
        if (resData != null) {
          that.Title = resData.name;
          that.Title = that.Title + '【班主任代报名】';
          //判断班级和学生是否在报名的范围中
          that.optClass = []
          resClass.forEach(item =>{
            if(resData.ranges.length>0 && resData.ranges.findIndex(it =>it.classId == item.classId)!==-1){
               that.optClass.push(item)
            }
          })
          let tempClassName = '';
          // 判断是否有默认值班级ID
          if (regNull(that.ruleForm.classId)) {
            that.ruleForm.classId = that.optClass[0].classId;
            tempClassName = that.optClass[0].alias;
          } else {
            tempClassName = resClass.find(val => val.classId == that.ruleForm.classId).name;
          }
          // 加载班级数据
          that.itemData = [];
          // 判断是否为导入名单


          ajaxGet(that, '/api/admin/activity/roster/' + that.activityId, null, function(resRoster) {
            if (resData.isImport) {
              let tempClass = resData.ranges.filter(val => val.className == tempClassName);
              for (let item of tempClass) {
                if (resRoster.find(val => val.studentId == item.studentId) == undefined) {
                  that.isAllCheackDisplay = true;
                  that.itemData.push({
                    studentId: item.studentId,
                    studentNo: item.studentNo,
                    name: item.studentName,
                    checked: false,
                    disabled: false,
                  });
                } else {
                  that.itemData.push({
                    studentId: item.studentId,
                    studentNo: item.studentNo,
                    name: item.studentName,
                    checked: true,
                    disabled: true,
                  });
                }
              }
            } else {
              ajaxGet(that, '/api/admin/student/class/' + that.ruleForm.classId, null, function(
              resStudent) {
                for (let item of resStudent) {
                  if (resRoster.find(val => val.studentId == item.studentId) != undefined) {
                    that.itemData.push({
                      studentId: item.studentId,
                      studentNo: item.studentNo,
                      name: item.name,
                      checked: true,
                      disabled: true,
                    })
                  } else {
                    that.isAllCheackDisplay = true;
                    that.itemData.push({
                      studentId: item.studentId,
                      studentNo: item.studentNo,
                      name: item.name,
                      checked: false,
                      disabled: false,
                    })
                  }
                }
              });
            }
          });


        }
      })
    });
  }
</script>

<style lang="less" scoped>
/deep/ .el-checkbox {
  margin-bottom: 0;
}
</style>>

<style>
  .ckDisabled .el-checkbox__input.is-disabled.is-checked+span.el-checkbox__label {
    color: #c0c4cc !important;
  }

  .ckDisabled .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
    background-color: #c0c4cc !important;
    border-color: #c0c4cc !important;
  }
</style>
