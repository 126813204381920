<!--
  日常事务 - 班级奖励类型
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          班级奖励类型
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <search :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null"></search>
            </li>
            <li class="nav-item">
              <el-button type="success" v-if="isPower.classprizelevel_create == true" icon="el-icon-circle-plus-outline"
                @click="onAdd()">新增</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="item!=null && item.length!=0">
        <el-table stripe :data="item" class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="name" label="班级奖励类型">
            <template slot-scope="scope">
              <span>{{scope.row.name}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="priority" label="优先级"></el-table-column>
          <!-- <el-table-column prop="lastUpdateDateString" label="操作时间"></el-table-column> -->
          <el-table-column label="操作" width="240px">
            <template slot-scope="scope">
              <operationBtn :btnName="'查看'" v-if="isPower.classprizelevel_details == true"
                @click.native="onDetail(scope.row.typeId)"></operationBtn>
              <operationBtn :btnName="'编辑'" v-if="isPower.classprizelevel_edit == true"
                @click.native="onEdit(scope.row.typeId)"></operationBtn>
              <operationBtn :btnName="'删除'" v-if="isPower.classprizelevel_delete == true"
                @click.native="onDelete(scope.row.typeId)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';
  export default {
    components: {
      comPage,
      operationBtn,
      search
    },
    data() {
      return {
        fullscreenLoading: false,
        page: null, //分页查询接口返回的结果
        item: [], //列表数据
        pageBtnList: null, //分页左侧按钮集合
        paging: { //分页数据
          pageLength: 0, // 总共页数
          Size: 10, // 当前请求数量
          Index: 1 //当前在第几页
        },
        isPower: {
          //奖励类型 增删改查 权限管理
          classprizelevel_details: false,
          classprizelevel_create: false,
          classprizelevel_edit: false,
          classprizelevel_delete: false,
        },
        SearchList: null, //搜索集合
      };
    },
    created() {
      let that = this;
      isPower(that, "classprizelevel_details", "classprizelevel_details");
      isPower(that, "classprizelevel_create", "classprizelevel_create");
      isPower(that, "classprizelevel_edit", "classprizelevel_edit");
      isPower(that, "classprizelevel_delete", "classprizelevel_delete");
      pagingPage(that);
    },
    methods: {
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },
      onSearch(params) {
        let that = this;
        let data = {};
        let searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = element.data;
          }
        });
        data.PageNumer = 1;
        routerPath(that, "/Admin/ClassPrizeType/Index", data, null, "PageSize");
      },
      onAdd() //新增
      {
        let that = this;
        that.$router.push({
          path: '/Admin/ClassPrizeType/Create',
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onDetail(Id) //查看
      {
        let that = this;
        that.$router.push({
          path: "/Admin/ClassPrizeType/Details/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });

      },
      onEdit(Id) //编辑
      {
        let that = this;
        that.$router.push({
          path: "/Admin/ClassPrizeType/Edit/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onDelete(Id) //删除
      {
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, "/api/admin/classprizetype/" + Id, null, function(r) {
              that.fullscreenLoading = false;
              pagingPage(that);
            });
          }
        })
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      pagingClick(type, val) {
        let that = this;
        let url = "/Admin/ClassPrizeType/Index";
        that.paging.Index=val;
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "Name",

          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "Name",
          );
        }
      },
    },
    watch: {
      $route() {
        let that = this;
        pagingPage(that);
      }
    }
  };

  function pagingPage(that) {
    paging(
      that,
      function(r) {
        let Myurl = "/api/admin/classprizetype/search";
        let data = {
          PageNumer: r.PageNumer,
          PageSize: r.PageSize,
          Name: r.Name,
        };

        that.SearchList = [{
          type: "input",
          Name: "奖励类型",
          data: r.Name,
          holder: "请输入奖励类型",
          zhi: "Name"
        }, ];
        that.paging.Index=r.PageNumer;
        that.paging.Size=r.PageSize;
        that.fullscreenLoading = true;
        ajaxGet(that, Myurl, data, function(r) {

          that.page = r;
          that.item = r.items;
          that.fullscreenLoading = false;
        });
      },
      "Name",
    );
  }
</script>

<style scoped="scoped">

</style>
