<!--
  系統管理 - 数据字典 - 新增
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="ruleForm" ref="ruleForm" label-width="100px">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{Title}}
          </h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-4 colInfo">
              <label>奖励类型</label>
              <div class="colFonts">{{ruleForm.name}}</div>
            </div>
            <div class="col-lg-4 colInfo">
              <label>优先级</label>
              <div class="colFonts">{{ruleForm.priority}}</div>
            </div>
            <div class="col-lg-4 colInfo">
              <label>操作时间</label>
              <div class="colFonts">{{ruleForm.lastUpdateDateString}}</div>
            </div>
          </div>
        </div>
      </el-card>
      <div v-if="configs.length > 0">
        <comLiteracyConfig :configList='configs' :defaultConfig ='defaultConfigs' ref="comLiteracyConfig" :comType="false">
        </comLiteracyConfig>
      </div>
      <!-- <div v-if="configsClass.length > 0">
        <comLiteracyConfig  :configList='configsClass' :defaultConfig ='defaultConfigs' ref="comLiteracyConfig2" :title="'班级评价类型'" :comType="false">
        </comLiteracyConfig>
      </div> -->

      <div class="row">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comLiteracyConfig from '@/components/Admin/LiteracyConfigScore.vue';
  export default {
      components: {
        comLiteracyConfig
      },
    data() {
      return {
        ruleForm: //表单数据
        {
          typeId: null,
          name: null,
          priority: null,
          GrowthConfigs: [], //综合类型集合
        },
        ruleFormReset: null,
        Levels: [], //处分等级集合
        Title: "查看奖励类型",
        fullscreenLoading: false,

        configs: [],
        configsClass: [],
        defaultConfigs: [],
      };
    },
    created() {
      let that = this;
      let id = that.$route.params.id;
      var detailUrl = null;
      if (id != null && typeof(id) != "undefined" && id != "") {
        that.Title = "查看奖励类型";
        that.ruleForm.reasonId = id;
        detailUrl = "/api/admin/prizeType/" + id;
        that.fullscreenLoading = true;
      }

      ajaxGet(that, detailUrl, null, function(r) {
        if (!regNull(r)) {
          that.ruleForm = r;
          that.defaultConfigs = r.configs;
        }
        ajaxGet(that, "/api/admin/GrowthConfig", null, function(r1) {
          that.fullscreenLoading = false;

          that.configs = r1.filter(val => {return val.configType == 1});
          // that.configsClass = r1.filter(val => {return val.configType == 2});
        });
      });

      that.fullscreenLoading = true;
      ajaxGet(that, "/api/admin/punishLevel", null, function(r) {
        that.Levels = r;
        that.fullscreenLoading = false;
      });


    },
    methods: {
      onBack() {
        let that = this;
        back(that);
      },

    }
  };
</script>

<style scoped="scoped">
  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }

  .spIcon {
    width: 5px;
    height: 20px;
    border-radius: 5px;
    background-image: linear-gradient(#4545FF, #2D8EFF);
  }

  .spTitle {
    float: left;
    margin-left: 20px;
    color: #323E56;
    letter-spacing: 2px;
    font-size: 16px;
    margin-top: -3px;
  }
</style>

<!-- 综合类型 -->
<style scoped="scoped">
  .divTagBox {
    padding: -5px;
  }

  .divTagBox .spTab {
    padding: 5px;
  }

  .divTagBox .spScore {
    color: #fa3534;
  }

  .divLiteracyBox {
    padding: 15px;
  }

  .divLiteracyBox .listBox {
    text-align: center;
    padding: 20px;
    float: left;
    border: 2px solid #FFFFFF;
    border-radius: 5px;
  }

  .divLiteracyBox .listBox:hover {
    border: 2px solid #409eff;
    cursor: pointer;
  }

  .divLiteracyBox .literacyName {
    font-size: 24px;
    letter-spacing: 2px;
    padding-bottom: 10px;
  }

  .divLiteracyBox .literacyNum {
    padding-top: 10px;
    font-family: 'OswaldLight';
    border-top: 2px solid #EEEEEE;
    font-size: 32px;
    color: #409eff;
  }
</style>
