<template>
  <div class="SubWebPageStudent" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          基本信息
        </h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-4 colInfo">
            <label>批次学年</label>
            <div class="colFonts">
              {{ruleForm.year | flNoValue}}
            </div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>批次时段</label>
            <div class="colFonts">
              {{ruleForm.startDate | dateformatDay}} 至 {{ruleForm.endDate | dateformatDay}}
            </div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>申请等级</label>
            <div class="colFonts">
              {{ruleForm.applyLevelString | flNoValue}}
            </div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>申请时间</label>
            <div class="colFonts">
              {{ruleForm.createDate | dateformatMinute}}
            </div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>状态</label>
            <div class="colFonts">
              {{ruleForm.statusString | flNoValue}}
            </div>
          </div>
          <div class="col-lg-4 colInfo" v-if="ruleForm.level!=null">
            <label>评定等级</label>
            <div class="colFonts">
              {{ruleForm.levelString | flNoValue}}
            </div>
          </div>
        </div>
      </div>
    </el-card>

    <el-card class="box-card" v-if="ruleForm.description!=null">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          主要事迹
        </h3>
      </div>
      <div class="card-body">
        <div v-html="ruleForm.description"></div>
      </div>
    </el-card>

    <div v-if="arrScore!=null && arrScore.length!=0">
      <el-card class="box-card" v-for="(item,index) in arrScore" :key="index">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{item.semesterName}}
          </h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-4 colInfo" v-for="(list,indexTwo) in item.children" :key="indexTwo">
              <label>{{list.courseName}}</label>
              <div class="colFonts">
                {{list.total | flNoValue}}
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </div>


   <!--证明材料-->
   <comShowImage :uploadFiles="uploadFiles" :comName="'证明材料'"></comShowImage>



    <el-card class="box-card" v-if="itemData!=null && itemData.length!=0">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          审核记录
        </h3>
      </div>
      <div class="card-body ">
        <el-table :data="itemData" border style="width: 100%">
          <el-table-column prop="status" label="审核结果" width="200"></el-table-column>
          <el-table-column prop="auditor" label="审核人" width="140"></el-table-column>
          <el-table-column prop="reason" label="审核意见"></el-table-column>
          <el-table-column label="审核时间" width="160">
            <template slot-scope="scope">
              {{scope.row.createDate | dateformatMinute}}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>



    <div class="row" style="margin-top: 10px;">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
      </div>
    </div>



  </div>
</template>

<script>
  import "@/assets/css/Student.css";
  import comShowImage from '@/components/showImage.vue';

  export default {
    components: {
      comShowImage,
    },
    data() {
      return {
        // 加载动画
        fullscreenLoading: false,
        // 对象
        ruleForm: {
          applyId: null
        },
        //审核记录
        auditRecords: [],
        arrScore: [],

        // 放大图片需要
        showViewer: false,
        // 上传的附件图片
        uploadFiles: [],
        styleWidth: "25%",
        auditType: null,

        itemData: [],
      };
    },
    filters: {
      /**
       * @description 判断是否有数据
       * @param {Object} obj
       */
      flNoValue(obj) {
        if (obj == null || typeof obj == "undefined" || obj == "") {
          return "暂无数据";
        }
        return obj;
      },
    },
    created() {
      let that = this;
      let tempID = that.$route.params.Id;
      if (!regNull(tempID)) {
        that.ruleForm.applyId = tempID;
        that.onGetData(that);
      }
    },
    methods: {
      /**
       * @description 获取数据
       * @param {Object} that
       */
      onGetData(that) {
        that.fullscreenLoading = true;
        ajaxGet(that, '/api/student/scholarshipapply/' + that.ruleForm.applyId, null, function(resApply) {
          that.fullscreenLoading = false;
          if (!regNullArray(resApply)) {

            that.ruleForm = resApply;
            that.arrScore = [];
            if (!regNullArray(resApply.total)) {
              let tempScore = that.onArrUnique(resApply.total);
              tempScore.forEach(function(item) {
                that.arrScore.push({
                  semesterId: item.semesterId,
                  semesterName: item.semesterName,
                  children: resApply.total.filter(list => list.semesterId == item.semesterId),
                });
              });
            }

            that.uploadFiles = [];
            if (!regNull(resApply.attachment)) {
              var files = resApply.attachment.split('|');
              for (var i in files) {
                that.uploadFiles.push(that.$ServerUrl() + files[i]);
              }
            }


            ajaxGet(that,'/api/student/scholarshipapply/audit/'+resApply.applyId,null,function(resAudit){
              that.itemData=resAudit;
            });

          }
        });
      },

      /**
       * @description 去重
       * @param {Object} arr
       */
      onArrUnique(arr) {
        const res = new Map();
        return arr.filter((arr) => !res.has(arr.semesterId) && res.set(arr.semesterId, 1));
      },

      /**
       * @description 返回
       */
      onBack() {
        let that = this;
        back(that);
      },
    },
  };
</script>

<style scoped="scoped">
  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }

  .spIcon {
    width: 5px;
    height: 20px;
    border-radius: 5px;
    background-image: linear-gradient(#4545ff, #2d8eff);
  }

  .spTitle {
    float: left;
    margin-left: 20px;
    color: #323e56;
    letter-spacing: 2px;
    font-size: 16px;
    margin-top: -3px;
  }

  .wMax {
    width: 100% !important;
  }
</style>
