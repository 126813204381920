<!--
  分页
-->
<template>
  <div v-if="paging!=null" class="card-footer">
		<div class="float-left m-0 spPageInfo" v-if="pageBtnList!=null && pageBtnList.length>0">
			<span v-for="(p,index) in pageBtnList" :key="index">
				<span style="cursor: pointer;margin-right: 15px;" v-if="p.data==null || p.data.length<=0" @click="pageBtnClick(index)"  v-show="(p.hide==false || p.hide ==null)"> 
					<i :class="p.icon"></i> {{p.name}}
				</span>
			</span>
		</div>
		<div class="float-right">
			<div v-if="isPage==true">
				<el-pagination style="padding-right: 0px;float:right;margin-right: 0px; text-align: right;"
							background
							 @size-change="handleSizeChange"
							 @current-change="handleCurrentChange"
							 :page-sizes="pageSizes"
							 :page-size="paging.pageSize"
							 :current-page="paging.currentPage"
							 layout="total, sizes, prev, pager, next,jumper"
							 :total="paging.totalCount">
							 </el-pagination>
			 </div>
			 <div v-else>
				 <span style="font-size: 14px;padding-right: 10px;">共 {{paging.totalCount}} 条</span>
			 </div>
		</div>

  </div>
</template>

<script>
  export default {
		props: {
			paging: Object,
			pageSizes://每页的条数
			{
				type: Array,
				default: () => [10, 20, 50, 100],
			},
			pageBtnList: Array, //左侧按钮集合，每个按钮对象包括icon、name和hide
			isPage://是否显示分页，如果不分页，右侧只显示记录的总条数
			{
				type: Boolean,
				default: true,
			}
		},
		created() {
		},
		data() {
			return{};
		},
		methods: {
			handleSizeChange(val) {//更改每页条数触发的事件
				this.$emit('pagingClick', 1, val);
			},
			handleCurrentChange(val) {//更改当前页时触发的事件
				this.$emit('pagingClick', 2, val);
			},
			pageBtnClick(index) {//点击左侧按钮的事件
				this.$emit('pageBtnClick', index);
			}
		}
	};
</script>

<style scoped="scoped">
	.el-pagination{
		padding-right: 0px;
		margin-right: 0px;
	}
	.el-pagination .btn-next{
		padding-right: 0px;
		margin-right: 0px;
		margin-left: 0px;
		border:1px solid red;
	}
</style>
