import axios from 'axios';


const isNewVersion = () => {
  let url = `//${window.location.host}/version.json?t=${new Date().getTime()}`;
  axios.get(url).then(res => {
    if (res.status === 200) {
      let vueVersion = res.data.version || '1.0.0';
      let localVueVersion = localStorage.getItem('vueVersion');
      if (regNull(localVueVersion) || localVueVersion != vueVersion) {
        console.log('====>刷新');
        localStorage.setItem('vueVersion', vueVersion);
        setTimeout(function() {
          window.location.reload(true);
          return false;
        }, 500);
      }
    }
  });
}

export default {
  isNewVersion
}
