<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="width:100%;padding-left: 0px;">
          菜单管理
        </h3>
      </div>
      <div class="card-body">
        <el-row :gutter="24">
          <el-col :span="6">
            <div class="divTreeCont">
              <el-tree :data="data" :props="defaultProps" ref="menuTree" v-if="data.length>0" node-key="id"
                :accordion="true" @node-click="handleNodeClick" auto-expand-parent :expand-on-click-node="false"
                :render-content="renderContent" :default-expanded-keys="idArr">
              </el-tree>
            </div>
          </el-col>
          <el-col :span="18">
            <el-row :gutter="24">
              <el-col :span="20">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item v-for="(p,index) in currentNav" :key="index">{{p}}</el-breadcrumb-item>
                </el-breadcrumb>
              </el-col>
              <el-col :span="4" style="text-align: right;">
                <el-button size="small" @click="onAdd()" type="success" icon="el-icon-circle-plus-outline">新增
                </el-button>
              </el-col>
            </el-row>

            <div v-if="item!=null && item.length!=0">
              <el-table stripe :data="item" class="tableTwo table-hover text-nowrap "
                style="width: 100%; margin-top: 0px; ">
                <el-table-column type="index" width="90px;" label="序号"></el-table-column>
                <el-table-column prop="name" label="名称"></el-table-column>
                <el-table-column prop="code" label="代码" width="300px"></el-table-column>
                <el-table-column prop="icon" label="图标" width="150px"></el-table-column>
                <el-table-column prop="enable" label="启用" width="100px">
                  <template slot-scope="scope">
                    <el-switch disabled v-model="scope.row.enable">
                    </el-switch>
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="180px">
                  <template slot-scope="scope">
                    <operationBtn :btnName="'编辑'" @click.native="onEdit(scope.row)"></operationBtn>
                    <operationBtn :btnName="'删除'" @click.native="onDelete(scope.row.id)"></operationBtn>
                  </template>
                </el-table-column>
              </el-table>
            </div>


            <div v-else>
              <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
            </div>

          </el-col>
        </el-row>
      </div>
    </el-card>

    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="40%" top="20px" :close-on-click-modal="false"
      :close-on-press-escape="false" :append-to-body="true" :fullscreen="false">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="90px">
        <el-row v-if="isDetail!=true">
          <el-col :span="24">
            <el-form-item label="菜单名称" prop="name">
              <el-input maxlength="64" show-word-limit v-model="ruleForm.name" placeholder="请输入菜单名称,最多64个字符"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="代码" prop="code">
              <el-input maxlength="64" show-word-limit v-model="ruleForm.code" placeholder="请输入代码,最多64个字符"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="图标" prop="icon">
              <el-input maxlength="128" show-word-limit v-model="ruleForm.icon" placeholder="请输入图标,最多128个字符"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="所属类型" prop="parentId">
              <treeSelect :key="random" @getValue="getValue" :options="data" :value="ruleForm.parentId"></treeSelect>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="链接地址" prop="url">
              <el-input maxlength="256" show-word-limit v-model="ruleForm.url"
                placeholder="请输入链接地址,格式为/admin/action/index"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="优先级" prop="priority">
              <el-input @change="onPriorityChange()" v-model="ruleForm.priority" placeholder="请输入优先级"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="启用" prop="enable">
              <el-switch v-model="ruleForm.enable">
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="是否菜单" prop="isMenu">
              <el-switch v-model="ruleForm.isMenu">
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="是否链接" prop="isLink">
              <el-switch v-model="ruleForm.isLink">
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="是否为手机端菜单" prop="isMobile" label-width="150px">
              <el-switch v-model="ruleForm.isMobile">
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="ruleForm.isMobile">
            <el-form-item label="移动端链接地址" prop="mobileUrl" label-width="120px">
              <el-input maxlength="256" show-word-limit v-model="ruleForm.mobileUrl"
                placeholder="请输入链接地址,格式为/pages/admin/action/index"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注" prop="description">
              <el-input class="formControl" type="textarea" :rows="4" show-word-limit placeholder="请输入备注"
                maxlength="500" v-model="ruleForm.description">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <div v-if="isDetail!=true">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button @click="onReset()">重 置</el-button>
          <el-button type="primary" @click="onSave()">确 定</el-button>
        </div>
        <div v-else>
          <el-button @click="dialogVisible = false">关 闭</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import operationBtn from '@/components/operationBtn.vue';
  import treeSelect from '@/components/TreeSelect.vue';
  export default {
    name: '',
    components: {
      operationBtn,
      treeSelect
    },
    data() {
      return {
        currentNav: [],
        idArr: [],
        isInit: true,
        random: 0, //刷新组件
        currentId: null,
        allMenu: [],
        isDetail: false,
        dialogTitle: "新增菜单",
        dialogVisible: false,
        currentTitle: "请在左侧选择菜单",
        item: null,
        fullscreenLoading: false,
        data: [],
        defaultProps: {
          children: 'children',
          label: 'name',
          id: 'actionId'
        },
        ruleForm: {
          id: null,
          name: null,
          priority: null,
          description: null,
          parentId: null,
          icon: 'el-icon-s-flag',
          code: null,
          url: null,
          enable: true, //启用
          isMenu: false,
          isLink: false,
          isMobile:false,// 是否为手机端菜单
        },
        ruleFormInit: null,
        ruleFormReset: null,
        rules: {
          name: [{
              required: true,
              message: '菜单名称不能为空',
              trigger: 'change'
            },
            {
              max: 64,
              message: '菜单名称不能超过64个字符',
              trigger: 'change'
            },
          ],
          code: [{
              required: true,
              message: '代码不能为空',
              trigger: 'change'
            },
            {
              max: 64,
              message: '代码不能超过64个字符',
              trigger: 'change'
            },
          ],
          icon: [{
              required: true,
              message: '图标不能为空',
              trigger: 'change'
            },
            {
              max: 128,
              message: '图标不能超过128个字符',
              trigger: 'change'
            },
          ],
          priority: [{
            validator: (rule, value, callback) => {
              let that = this;
              var reg = /^-?[1-9]\d*$/;
              if (value != 0 && !reg.exec(value)) {
                callback(new Error("优先级只能输入整数！"));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }],
        },
      };
    },
    created() {
      let that = this;
      that.ruleFormInit = JSON.parse(JSON.stringify(that.ruleForm));
      Init(that);
    },
    methods: {
      onPriorityChange() {
        let that = this;
        var value = setInt(that.ruleForm.priority);
        that.ruleForm.priority = value;
      },
      onSave() {
        let that = this;
        if (that.ruleForm.priority == null || that.ruleForm.priority == "") {
          that.ruleForm.priority = "0"
        }
        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            if (that.ruleForm.parentId == "11111111-1111-1111-1111-111111111111") {
              that.ruleForm.parentId = null;
            }
            if (that.ruleForm.actionId == null) {
              that.fullscreenLoading = true;
              ajaxPost(that, "/api/admin/sysAction", that.ruleForm, function(r) {
                that.fullscreenLoading = false;
                that.dialogVisible = false;
                that.currentId = that.ruleForm.parentId;
                Init(that);
              });
            } else {
              that.fullscreenLoading = true;
              //that.ruleForm.actionId = that.ruleForm.id;

              ajaxPut(that, "/api/admin/sysAction/" + that.ruleForm.actionId, that.ruleForm, function(r) {
                that.fullscreenLoading = false;
                that.dialogVisible = false;
                that.currentId = that.ruleForm.parentId;
                Init(that);
              });
            }
          } else {

            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              isError[0].querySelector('input').focus();
            }, 1)
            return false;
          }
        });
      },
      onReset() {
        let that = this;
        this.ruleForm = JSON.parse(JSON.stringify(this.ruleFormReset));
        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      getValue(value) {
        let that = this;
        that.ruleForm.parentId = value;
      },
      onAdd() {
        let that = this;
        that.dialogVisible = true;
        that.dialogTitle = "新增菜单";
        that.isDetail = false;
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormInit));
        that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleFormInit));
        that.ruleForm.parentId = that.currentId;
        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      onEdit(data) {
        let that = this;
        that.ruleForm = data;
        that.dialogVisible = true;
        that.dialogTitle = "编辑菜单";
        that.isDetail = false;
        that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;
      },
      onDelete(id) {
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, "/api/admin/sysAction/" + id, null, function(r) {
              that.fullscreenLoading = false;
              Init(that);
            });
          }
        })
      },
      handleNodeClick(data) {
        let that = this;
        that.item = data.children;
        that.currentTitle = data.title;
        that.currentId = data.id;
      },
      renderContent(h, {
        node,
        data,
        store
      }) {
        return ( <
          span class = "custom-tree-node" >
          <
          span style = "font-size:14px;" > {
            node.label
          } < /span> <
          /span>);
        },
      },
      mounted() {

      },
      watch: {
        currentTitle(val) {
          let that = this;
          var titles = val.split('/');
          that.currentNav = ["菜单管理"];
          for (var i in titles) {
            if (titles[i] != "菜单管理") {
              that.currentNav.push(titles[i]);
            }
          }
        }
      }
    };

    function GetItem(that, val) {
      if (val.length !== 0) {
        val.forEach((item) => {
          if (item.actionId == that.currentId) {
            that.item = item.children;
            that.currentTitle = item.title;
          } else if (item.children.length >= 1) {
            GetItem(that, item.children);
          }
        });

      }
    }

    function InitItem(that, val) {
      if (val.length !== 0) {
        val.forEach((item) => {
          item.id = item.actionId;
          that.allMenu.push(item);
          if (item.children.length >= 1) {
            InitItem(that, item.children);
          }
        });

      }
    }

    function Init(that) {
      that.fullscreenLoading = true;
      that.data = [];
      ajaxGet(that, "/api/admin/sysAction/treelist", null, function(r) {
        that.fullscreenLoading = false;
        //that.data = r;
        var tree = {
          id: "11111111-1111-1111-1111-111111111111",
          actionId: "11111111-1111-1111-1111-111111111111",
          name: "菜单管理",
          title: "菜单管理",
          children: r,
        };
        that.data.push(tree);
        if (that.isInit == true) {
          that.currentId = that.data[0].actionId;
          that.isInit = false;
        }


        that.allMenu = [];
        InitItem(that, that.data);
        GetItem(that, that.data);


        that.idArr = [];
        GetKeys(that, that.currentId);

      });
    }

    function GetKeys(that, id) {
      for (var i in that.allMenu) {
        if (that.allMenu[i].id == id) {
          that.idArr.push(id);
          if (that.allMenu[i].parentId != null && that.allMenu[i].parentId != '11111111-1111-1111-1111-111111111111') {
            GetKeys(that, that.allMenu[i].parentId);
          }
        }
      }
    }
</script>

<style scoped="scoped">
  .divTreeCont {
    border: 1px solid #DDDDDD;
    padding: 15px;
    border-radius: 4px;
  }
</style>
