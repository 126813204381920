<!--
  学生管理-学生奖励
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <search :ColNumber="2" :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null">
    </search>
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          班主任学期考核加减分项
        </h3>
        <div class="card-tools" v-if="isPower.ass_semester_plusminus_create">
          <ul class="nav">
            <li class="nav-item">
              <!--导入-->
              <comImport @onImportSuccess="onImportSuccess" :template="'/templates/班主任学期考核加减分导入模版.xlsx'"
                :path="uploadInfoApi" :btnIcon="'el-icon-upload2'" :title="'批量导入班主任学期考核加减分 '" :btnName="'导入'"
                ref="conImportInfoFile" v-if="isPower.ass_semester_plusminus_import">
              </comImport>
            </li>
            <li class="nav-item">
              <el-button type="success" icon="el-icon-circle-plus-outline" @click="onCreate">新增</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table stripe :data="itemData" class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod">
          </el-table-column>
          <el-table-column prop="teacher" label="班主任" min-width="100" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="class" label="班级" min-width="120" :show-overflow-tooltip="true"></el-table-column>

          <el-table-column prop="reason" label="原因" min-width="200"></el-table-column>
          <el-table-column prop="score" label="分数" width="80"></el-table-column>
          <el-table-column prop="semester" label="学期" width="200" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="auditStatusStr" label="审核状态" width="100"></el-table-column>
          <el-table-column label="操作" :width="thWidth" fixed="right">
            <template slot-scope="scope">
              <div v-if="isPower.ass_semester_plusminus_audit">
                <operationBtn :btnName="'审核'" v-if="isPower.ass_semester_plusminus_audit  && scope.row.auditStatus==0"
                  @click.native="onAudit(scope.row.projectId)"></operationBtn>
                <operationBtn :btnName="'查看'" v-if="isPower.ass_semester_plusminus_details && scope.row.auditStatus!=0"
                  @click.native="onDetail(scope.row.projectId)"></operationBtn>
              </div>
              <div v-else>
                <operationBtn :btnName="'查看'" v-if="isPower.ass_semester_plusminus_details"
                  @click.native="onDetail(scope.row.projectId)"></operationBtn>
                <operationBtn :btnName="'编辑'" v-if="isPower.ass_semester_plusminus_edit"
                  @click.native="onEdit(scope.row.projectId)"></operationBtn>
                <operationBtn :btnName="'删除'" v-if="isPower.ass_semester_plusminus_delete"
                  @click.native="onDelete(scope.row.projectId)"></operationBtn>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>


  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';
  import comImport from '@/components/Import.vue';
  export default {
    name: '',
    components: {
      comPage,
      comImport,
      operationBtn,
      search
    },
    data() {
      return {
        fullscreenLoading: false,
        dialogTitle: '',
        dialogVisible: false,
        page: null, //分页查询接口返回的结果
        itemData: [], //列表数据
        pageBtnList: null, //分页左侧按钮集合
        paging: { //分页数据
          pageLength: 0, // 总共页数
          Size: 10, // 当前请求数量
          Index: 1 //当前在第几页
        },
        uploadInfoApi: '/api/admin/advisersemesterproject/import',
        SearchList: null, //搜索集合
        isPower: {
          ass_semester_plusminus_create: false,
          ass_semester_plusminus_details: false,
          ass_semester_plusminus_delete: false,
          ass_semester_plusminus_edit: false,
          ass_semester_plusminus_audit: false,
          ass_semester_plusminus_import: false,
        },
        thWidth: 100,
      };
    },
    created() {
      let that = this;
      isPower(that, 'ass_semester_plusminus_create', 'ass_semester_plusminus_create');
      isPower(that, 'ass_semester_plusminus_details', 'ass_semester_plusminus_details');
      isPower(that, 'ass_semester_plusminus_delete', 'ass_semester_plusminus_delete');
      isPower(that, 'ass_semester_plusminus_edit', 'ass_semester_plusminus_edit');
      isPower(that, 'ass_semester_plusminus_audit', 'ass_semester_plusminus_audit');
      isPower(that, 'ass_semester_plusminus_import', 'ass_semester_plusminus_import');
      pagingPage(that);

      if (that.isPower.ass_semester_plusminus_audit) {
        that.thWidth = onGetWidth(that, [
          that.isPower.ass_semester_plusminus_audit,
          that.isPower.ass_semester_plusminus_details
        ]);
      } else {
        that.thWidth = onGetWidth(that, [
          that.isPower.ass_semester_plusminus_details,
          that.isPower.ass_semester_plusminus_edit,
          that.isPower.ass_semester_plusminus_delete
        ]);
      }

    },
    methods: {
      /**
       * @description 新增
       */
      onCreate() {
        let that = this;
        that.$router.push({
          path: "/Admin/Assessment/Semester/PlusMinus/Create",
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      //导入成功后
      onImportSuccess(param) {
        let that = this;
        pagingPage(that);
      },
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },

      /**
       * @description 详情
       * @param {Object} Id
       */
      onDetail(Id) {
        let that = this;
        that.$router.push({
          path: "/Admin/Assessment/Semester/PlusMinus/Details/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      /**
       * @description 编辑
       * @param {Object} Id
       */
      onEdit(Id) {
        let that = this;
        that.$router.push({
          path: "/Admin/Assessment/Semester/PlusMinus/Edit/" + Id,
          query: {
            url: that.$router.history.current.fullPath,
            isAudit: false
          }
        });
      },
      onAudit(Id) {
        let that = this;
        that.$router.push({
          path: "/Admin/Assessment/Semester/PlusMinus/Details/" + Id,
          query: {
            url: that.$router.history.current.fullPath,
            isAudit: true
          }
        });
      },
      /**
       * @description 删除
       * @param {Object} objID
       */
      onDelete(objID) {
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, "/api/admin/advisersemesterproject/" + objID, null, function(r) {

              that.fullscreenLoading = false;
              pagingPage(that);
            });
          }
        })

      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      onSearch(params) {
        let that = this,
          data = {},
          searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = Join(element.data);
          }
        });
        data.PageNumer = 1;
        routerPath(that, "/Admin/Assessment/Semester/PlusMinus/Index", data, null, "PageSize");
      },
      pagingClick(type, val) {
        let that = this;
        let url = '/Admin/Assessment/Semester/PlusMinus/Index';
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "semesterId",
            "TeacherName",
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "SemesterId",
            "semesterId",
            "TeacherName",
          );
        }
      },
    },
    watch: {
      $route() {
        let that = this;
        pagingPage(that);
      }
    }
  };

  function pagingPage(that) {
    paging(
      that,
      function(res) {
        ajaxGet(that, '/api/admin/semester', null, function(ressemester) {
          let semester = [];
          for (var it of ressemester) {
            semester.push({
              Value: it.semesterId,
              Title: it.name,
            })
            that.semesterlist = semester;
          }
          let semesterId = res.semesterId;
          that.SearchList = [];
          that.SearchList.push({
            type: "select",
            Name: "学期",
            data: semesterId,
            select: that.semesterlist,
            zhi: "semesterId",
            isClear: false, //select是否可以清除
          }, {
            type: "input",
            Name: "班主任",
            data: res.TeacherName,
            holder: "请输入班主任姓名",
            zhi: "TeacherName"
          });
          let tempData = {
            PageNumer: res.PageNumer,
            PageSize: res.PageSize,
            SemesterId: semesterId,
            TeacherName: res.TeacherName,
          }
          that.itemData = [];
          ajaxGet(that, '/api/admin/advisersemesterproject/search', tempData, function(resData) {
            that.fullscreenLoading = false;
            if (!regNullArray(resData.items)) {
              that.page = resData;
              that.itemData = resData.items;
            }
          });
        });
      },
      "semesterId",
      "TeacherName",
    );
  }

</script>

<style scoped="scoped">
</style>

