<!--
  宿舍管理 - 房源管理
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">

    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          房源信息
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <el-button v-if="isPower.building_create == true" type="success" icon="el-icon-circle-plus-outline"
                @click="onCreate()">新增</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table :data="itemData" stripe class="elTableData">
          <el-table-column type="index" label="序号" width="80" min-width="100"></el-table-column>
          <el-table-column prop="name" label="楼名" class="elColumn" min-width="100"
            :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="floors" label="楼层数" class="elColumn" min-width="100"></el-table-column>
          <el-table-column prop="rooms" label="房间数" class="elColumn" min-width="100"></el-table-column>
          <el-table-column prop="beds" label="床位数" class="elColumn" min-width="100"></el-table-column>
          <el-table-column label="已使用床位" class="elColumn" min-width="110">
            <template slot-scope="scope">
              <span class="color-primary">
                {{scope.row.used}}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="剩余数床位" class="elColumn" min-width="110">
            <template slot-scope="scope">
              <span class="color-success">
                {{scope.row.remain}}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="操作" :width="200" fixed="right">
            <template slot-scope="scope">
              <operationBtn v-if="isPower.building_details == true" :btnName="'查看'" ref="detail"
                @click.native="onDetail(scope.row.buildingId)"></operationBtn>
              &nbsp;
              <el-dropdown v-if="isPower.building_edit || isPower.building_delete" trigger="click"
                @command="handleCommand($event,scope.row.buildingId)">
                <el-button size="mini">
                  更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="isPower.building_edit" class="color-warning" command="onUpdate">编辑宿舍楼</el-dropdown-item>                   <el-dropdown-item v-if="isPower.building_edit" class="color-warning" command="onUpdateRoom">编辑房间</el-dropdown-item>
                  <el-dropdown-item divided v-if="isPower.building_delete" class="color-danger" command="onRemove">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <comPage :isPage="false" :paging="paging"></comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  export default {
    components: {
      operationBtn,
      comPage
    },
    data() {
      return {
        // 加载动画
        fullscreenLoading: false,
        // 列表数据
        itemData: null,
        paging: {
         	totalCount: 0,
        },
        isPower: {
        		//增删改查 权限管理
          building_details: false,
          building_create: false,
          building_edit: false,
          building_delete: false,
        },
      };
    },
    methods: {
      /**
       * @description 更多操作
       * @param {Object} command
       * @param {Object} objValID
       */
      handleCommand(command, objValID) {
        let that = this;
        switch (command) {
          case 'onUpdate':
            that.onUpdate(objValID);
            break;
          case 'onUpdateRoom':
            that.onUpdateRoom(objValID);
            break;
          case 'onRemove':
            that.onRemove(objValID);
            break;
        }
      },
      // 新增按钮
      onCreate() {
        let that = this;
        that.$router.push({
          path: "/Admin/Dorm/Create/",
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      // 详情
      onDetail(_dataID) {
        let that = this;
        that.$router.push({
          path: "/Admin/Dorm/Details/" + _dataID,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      // 编辑
      onUpdate(_dataID) {
        let that = this;
        that.$router.push({
          path: "/Admin/Dorm/Edit/" + _dataID,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      // 编辑
      onUpdateRoom(_dataID) {
        let that = this;
        that.$router.push({
          path: "/Admin/Dorm/Room/" + _dataID,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      // 删除
      onRemove(_dataID) {
        let that = this;
       	confirmDelete(that, null, function(res) {
      		  if (res == true) {
            let apiUrl = '/api/admin/building/' + _dataID;
            that.fullscreenLoading = true;
       			  ajaxDelete(that, apiUrl, null, function(obj) {
              that.fullscreenLoading = false;
              onGetData(that);
            });
          }
        })
      },
    },
    created() {
      let that = this;
      isPower(that, "building_details", "building_details");
      isPower(that, "building_create", "building_create");
      isPower(that, "building_edit", "building_edit");
      isPower(that, "building_delete", "building_delete");
      onGetData(that);
    }
  };

  function onGetData(that) {
    that.fullscreenLoading = true;
    ajaxGet(that, '/api/admin/dormitory/preview', null, function(resData) {
      that.itemData = resData;
      that.paging.totalCount = that.itemData.length;
      that.fullscreenLoading = false;
    });
  };

</script>

<style scoped="scoped">
  .divSocItemCont {
    margin: 10px auto;
    background-color: #FFFFFF;
  }

  .divSocItemCont .divSocietiesImg {
    width: 100%;
    height: 200px;
    overflow: hidden;
    border: 1px solid #EEEEEE;
  }

  .divSocItemCont .divSocInfoCont {
    background: #FFFFFF;
    padding: 15px;
  }

  .divSocItemCont .divSocInfoCont .divSocTitle {
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 10px;
  }

  .divSocItemCont .divSocInfoCont .divSocFont {
    color: #9D9D9D;
    height: 60px;
    font-size: 12px;
    overflow: hidden;
  }

  .divSocItemCont .divSocInfoCont .divSocLevelCont {
    border-top: 2px solid #F6F6F6;
    margin-top: 10px;
    padding-top: 15px;
  }

  .divSocItemCont .divSocInfoCont .divSocLevelCont .divSocList {
    float: left;
    width: 49.9%;
    text-align: center;
  }

  .divSocItemCont .divSocInfoCont .divSocLevelCont .divSocList span {
    font-size: 14px;
  }

  .divSocItemCont .divSocInfoCont .divSocLevelCont .spStar .faxIcon {
    color: #F7BA2A;
  }

</style>

