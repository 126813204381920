<!--
  综合课堂类型
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          综合课堂类型
        </h3>
      </div>
      <div class="card-body">
        <el-row :gutter="24">
          <el-col :span="6">
            <div class="divTreeCont">
              <el-input placeholder="输入关键字进行过滤" v-model="filterText">
                <el-button slot="append" icon="el-icon-refresh-right" @click="onFilterText()"></el-button>
              </el-input>
              <el-tree :data="data" :props="defaultProps" ref="tree" style="margin-top:10px;" node-key="id"
                default-expand-all @node-click="handleNodeClick" :expand-on-click-node="false"
                :render-content="renderContent" :filter-node-method="filterNode">
              </el-tree>
            </div>
          </el-col>
          <el-col :span="18">
            <el-row :gutter="24">
              <el-col :span="20">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item v-for="(p,index) in currentNav" :key="index">{{p}}</el-breadcrumb-item>
                </el-breadcrumb>
              </el-col>
              <el-col :span="4" style="text-align: right;">
                <el-button v-if="isPower.literacycoursetype_create == true" @click="onAdd()" type="success"
                  icon="el-icon-circle-plus-outline">新增</el-button>
              </el-col>
            </el-row>
            <el-table stripe :data="item" class="tableTwo table-hover text-nowrap "
              style="width: 100%; margin-top: 0px; ">
              <el-table-column type="index" width="120px;" label="序号"></el-table-column>
              <el-table-column prop="name" label="类型名称"> </el-table-column>
              <el-table-column prop="priority" label="优先级"></el-table-column>
              <el-table-column label="操作" width="180px">
                <template slot-scope="scope">
                  <operationBtn v-if="isPower.literacycoursetype_edit == true" :btnName="'编辑'"
                    @click.native="onEdit(scope.row.id)"></operationBtn>
                  <operationBtn v-if="isPower.literacycoursetype_delete == true" :btnName="'删除'"
                    @click.native="onDelete(scope.row.id)"></operationBtn>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
    </el-card>

  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import operationBtn from '@/components/operationBtn.vue';
  export default {
    name: '',
    components: {
      operationBtn
    },
    data() {
      return {
        //查找树形菜单
        filterText: '',
        currentNav: [],
        currentId: null,
        currentTitle: "请在左侧选择类型",
        item: null,
        fullscreenLoading: false,
        data: [],
        // 树型
        defaultProps: {
          children: 'children',
          label: 'name'
        },
        // 权限管理
        isPower: {
          //增删改查 权限管理
          literacycoursetype_create: false,
          literacycoursetype_edit: false,
          literacycoursetype_delete: false,
          literacycoursetype_detail: false,
        },
      };
    },
    created() {
      let _this = this;
      isPower(_this, "literacycoursetype_create", "literacycoursetype_create");
      isPower(_this, "literacycoursetype_edit", "literacycoursetype_edit");
      isPower(_this, "literacycoursetype_delete", "literacycoursetype_delete");
      isPower(_this, "literacycoursetype_detail", "literacycoursetype_detail");
      Init(_this);
    },
    watch: {
      // 树型地址
      currentTitle(val) {

        let _this = this;
        var titles = val.split('/');
        _this.currentNav = ["综合课堂类型"];
        for (var i in titles) {
          if (titles[i] != "综合课堂类型") {
            _this.currentNav.push(titles[i]);
          }
        }
      },
      filterText(val) {
        this.$refs.tree.filter(val);
      }
    },
    methods: {
      onFilterText() {
        this.filterText = "";
      },
      filterNode(value, data) {
        if (!value) return true;
        return data.name.indexOf(value) !== -1;
      },
      getValue(value) {
        let _this = this;
        _this.ruleForm.parentId = value;
      },
      // 新增
      onAdd() {
        let _this = this;
        _this.$router.push('/Admin/LiteracyCourseType/Create');
      },
      // 编辑
      onEdit(_dataID) {
        let _this = this;
        _this.$router.push({
          path: "/Admin/LiteracyCourseType/Edit/" + _dataID,
          query: {
            url: _this.$router.history.current.fullPath
          }
        });
      },
      // 删除类型
      onDelete(_dataID) {
        let _this = this;

        confirmDelete(_this, null, function(res) {
          if (res == true) {
            _this.fullscreenLoading = true;
            ajaxDelete(_this, "/api/admin/literacycoursetype/" + _dataID, null, function(r) {
              _this.fullscreenLoading = false;
              Init(_this);
            });
          }
        })
      },
      handleNodeClick(data) {
        let _this = this;
        _this.item = data.children;
        _this.currentTitle = data.title;
      },
      renderContent(h, {
        node,
        data,
        store
      }) {
        return ( < span class = "custom-tree-node" >
          <
          span style = "font-size:14px;" > {
            node.label
          } < /span></span > );
      },
    },

  };

  function Init(_this) {
    _this.fullscreenLoading = true;
    _this.data = [];
    ajaxGet(_this, "/api/admin/literacycoursetype/treelist", null, function(r) {
      _this.fullscreenLoading = false;
      var tree = {
        id: null,
        value: null,
        name: "综合课堂类型",
        title: "综合课堂类型",
        children: r,
      };
      _this.data.push(tree);
      _this.currentTitle = _this.data[0].title;
      //  let _this = this;
      _this.item = tree.children;
    });
  }
</script>

<style scoped="scoped">
  .divTreeCont {
    border: 1px solid #DDDDDD;
    padding: 15px;
    border-radius: 4px;
  }
</style>
