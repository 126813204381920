<!--
  新增家校联系
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{Title}}
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24">
            <el-col :lg="8" :sm="12">
              <el-form-item prop="semesterId" label="学期">
                <el-select v-model="ruleForm.semesterId" filterable placeholder="请选择学期" style="width: 100%;"
                  :disabled="Title=='编辑'">
                  <el-option v-for="item in optSemester" :key="item.semesterId" :label="item.name"
                    :value="item.semesterId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="8" :sm="12">
              <el-form-item prop="classId" label="班级">
                <el-select v-model="ruleForm.classId" filterable placeholder="请选择班级" style="width: 100%;"
                  @change="handClassId" :disabled="Title=='编辑'">
                  <el-option v-for="item in optClass" :key="item.classId" :label="item.alias" :value="item.classId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="8" :sm="12">
              <el-form-item label="学生" prop="studentId">
                <el-select v-model="ruleForm.studentId" filterable placeholder="请选择" style="width: 100%;"
                  @change="handStudentId" :disabled="Title=='编辑'">
                  <el-option v-for="item in optStudent" :key="item.studentId" :label="item.name"
                    :value="item.studentId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item prop="abnormalBehavior" label="主要偏常行为">
                <quill-editor height="300px" v-model="ruleForm.abnormalBehavior" ref="text" class="myQuillEditor"
                  :options="editorOption" />
                <!-- <el-input type="textarea" v-model="ruleForm.abnormalBehavior" :autosize="{ minRows: 5}" maxlength="500" show-word-limit></el-input> -->
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item prop="merits" label="主要优点">
                <quill-editor height="300px" v-model="ruleForm.merits" ref="text" class="myQuillEditor"
                  :options="editorOption" />
                <!-- <el-input type="textarea" v-model="ruleForm.merits" :autosize="{ minRows: 5}" maxlength="500" show-word-limit></el-input> -->
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item prop="familySituation" label="家庭情况简要">
                <quill-editor height="300px" v-model="ruleForm.familySituation" ref="text" class="myQuillEditor"
                  :options="editorOption" />
                <!-- <el-input type="textarea" v-model="ruleForm.familySituation" :autosize="{ minRows: 5}" maxlength="500" show-word-limit></el-input> -->
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item prop="remark" label="备注">
                <quill-editor height="300px" v-model="ruleForm.remark" ref="text" class="myQuillEditor"
                  :options="editorOption" />
                <!-- <el-input type="textarea" v-model="ruleForm.remark" :autosize="{ minRows: 5}" maxlength="500" show-word-limit></el-input> -->
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>

      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            惩处记录
          </h3>
        </div>
        <div class="card-body p-0">
          <el-table :data="itemData" stripe class="elTableData">
            <el-table-column type="index" label="序号" width="80"></el-table-column>
            <el-table-column prop="punishNo" label="处分号" class="elColumn"></el-table-column>
            <el-table-column prop="reason" label="处分原因" class="elColumn"></el-table-column>
            <el-table-column prop="level" label="处分类型" class="elColumn"></el-table-column>
            <el-table-column prop="score" label="分数" class="elColumn"></el-table-column>
            <el-table-column prop="punishDate" label="处分时间" class="elColumn"></el-table-column>
          </el-table>
        </div>
      </el-card>

      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            辅导及转化情况
          </h3>
          <div class="card-tools">
            <ul class="nav">
              <li class="nav-item">
                <el-button type="success" icon="el-icon-circle-plus-outline" @click="addDomain">新增</el-button>
              </li>
            </ul>
          </div>
        </div>
        <div class="card-body">
          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item label="辅导人" prop="tutor">
                <el-input v-model="ruleForm.tutor" placeholder="请输入辅导人"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item v-for="(p, index) in ruleForm.abnormalTutors" :label="'第' + (index+1) + '次辅导内容'"
                :key="p.key" :prop="'abnormalTutors.' + index + '.tutorSituation'" :rules="{
                        required: true, message: '辅导内容不能为空', trigger: 'change'
                      }">
                <quill-editor height="300px" v-model="p.tutorSituation" ref="text" class="myQuillEditor"
                  :options="editorOption" />
                <!-- <el-input type="textarea" :autosize="{ minRows: 5}" maxlength="500" show-word-limit v-model="p.tutorSituation" style="width: 90%;"></el-input> -->
                <el-button type="danger" @click.prevent="removeDomain(p)" style="float: right;margin-top: 10px;">删除
                </el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>
      <div class="row" style="margin-top: 10px;">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
          <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
          <el-button type="primary" icon="el-icon-circle-check" @click="onSave()">提交</el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import {
    quillEditor
  } from 'vue-quill-editor';
  import * as Quill from 'quill' //引入编辑器
  var fonts = ['SimSun', 'SimHei', 'Microsoft-YaHei', 'KaiTi', 'FangSong', 'Arial', 'Times-New-Roman', 'sans-serif'];
  var Font = Quill.import('formats/font');
  Font.whitelist = fonts; //将字体加入到白名单
  Quill.register(Font, true);


  export default {
    components: {
      quillEditor
    },
    data() {
      return {
        fullscreenLoading: false,
        editorOption: {
          placeholder: '请在此输入内容',
          modules: {
            toolbar: [
              [{
                'font': fonts
              }], //字体，把上面定义的字体数组放进来
              ['bold', 'italic', 'underline',
                'strike'
              ], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
              ['blockquote', 'code-block'], // 引用  代码块-----['blockquote', 'code-block']
              [{
                header: 1
              }, {
                header: 2
              }], // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
              [{
                list: 'ordered'
              }, {
                list: 'bullet'
              }], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
              [{
                script: 'sub'
              }, {
                script: 'super'
              }], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
              [{
                indent: '-1'
              }, {
                indent: '+1'
              }], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
              [{
                'direction': 'rtl'
              }], // 文本方向-----[{'direction': 'rtl'}]
              [{
                size: ['small', false, 'large', 'huge']
              }], // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
              [{
                header: [1, 2, 3, 4, 5, 6, false]
              }], // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
              [{
                color: []
              }, {
                background: []
              }], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
              [{
                align: []
              }], // 对齐方式-----[{ align: [] }]
              ['clean'], // 清除文本格式-----['clean']
              // ['image'], // 链接、图片、视频-----['link', 'image', 'video']
            ]
          }
        },
        optClass: [],
        optSemester: [],
        optStudent: [],
        optPunish: [],
        itemData: [],
        ruleForm: {
          abnormalId: null,
          semesterId: null,
          classId: null,
          studentId: null,
          abnormalBehavior: null, //主要偏常行为
          merits: null, //主要优点
          familySituation: null, //家庭情况
          tutor: null, //辅导人
          remark: null, //备注
          abnormalTutors: [{ //行为偏常学生辅导记录
            tutorSituation: null, //辅导情况
          }]
        },
        ruleFormReset: {}, //重置
        Title: "新增",
        rules: {
          semesterId: [{
            required: true,
            message: '学期不能为空',
            trigger: 'change'
          }, ],
          classId: [{
            required: true,
            message: '班级不能为空',
            trigger: 'change'
          }, ],
          studentId: [{
            required: true,
            message: '学生不能为空',
            trigger: 'change'
          }, ],
          abnormalBehavior: [{
            required: true,
            message: '主要偏常行为不能为空',
            trigger: 'change'
          }, ],
          merits: [{
            required: true,
            message: '主要优点不能为空',
            trigger: 'change'
          }, ],
          familySituation: [{
            required: true,
            message: '家庭情况不能为空',
            trigger: 'change'
          }, ],
          tutor: [{
            required: true,
            message: '辅导人不能为空',
            trigger: 'change'
          }, ],
        },
        TeacherInfo: {}, // 教师信息
      };
    },
    created() {
      let that = this;
      that.TeacherInfo = onGetNowUser(that);
      let id = that.$route.params.Id;
      that.ruleForm.abnormalId = id;
      that.fullscreenLoading = true;
      ajaxGet(that, "/api/admin/Semester", null, function(resultSemester) {
        ajaxGet(that, "/api/admin/punish/class", null, function(resultPunish) {

          let resultClass = that.TeacherInfo.isTeacher ? that.TeacherInfo.manageClass : that.TeacherInfo
            .allClass;

          that.optSemester = resultSemester;
          if (!regNullArray(resultClass)) {
            ajaxGet(that, "/api/admin/student/class/" + resultClass[0].classId, null, function(r) {
              that.fullscreenLoading = false;
              that.optClass = resultClass;
              for (var i in resultPunish) {
                for (var j in resultPunish[i].students) {
                  var it = {
                    studentId: resultPunish[i].students[j].studentId,
                    punishs: resultPunish[i].students[j].punishs,
                  }
                  that.optPunish.push(it);
                }
              }
              that.optStudent = r;
              if (id != null) {
                that.Title = '编辑';
                that.fullscreenLoading = true;
                ajaxGet(that, "/api/admin/adviserabnormalbehavior/" + id, null, function(res) {
                  ajaxGet(that, "/api/admin/student/class/" + res.classId, null, function(r) {
                    that.fullscreenLoading = false;
                    that.ruleForm = res;
                    // that.ruleForm.contactDate=new Date(that.ruleForm.contactDate);
                    that.optStudent = r;
                    for (var i in that.optPunish) {
                      if (res.studentId == that.optPunish[i].studentId) {
                        that.itemData = that.optPunish[i].punishs;
                      }
                    }
                    that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
                  })
                })
              } else {
                for (var item of resultSemester) {
                  if (item.isCurrent == true) {
                    that.ruleForm.semesterId = item.semesterId;
                  }
                }
                that.ruleForm.classId = resultClass[0].classId;
                that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
              }
            })
          } else {
            that.fullscreenLoading = false;
          }

        })

      })

    },

    methods: {
      removeDomain(item) //删除辅导内容
      {
        var that = this;
        var index = that.ruleForm.abnormalTutors.indexOf(item)
        if (index !== -1) {
          that.ruleForm.abnormalTutors.splice(index, 1)
        }
      },
      addDomain() //新增辅导内容
      {
        var that = this;
        that.ruleForm.abnormalTutors.push({
          tutorSituation: '',
          key: Date.now()
        });
      },
      handClassId(val) {
        let that = this;
        that.ruleForm.studentId = null;
        that.itemData = [];
        that.optStudent = [];
        that.fullscreenLoading = true;
        ajaxGet(that, "/api/admin/student/class/" + val, null, function(r) {
          that.fullscreenLoading = false;
          that.optStudent = r;

        });
      },
      handStudentId(val) {
        let that = this;
        that.itemData = [];
        for (var i in that.optPunish) {
          if (val == that.optPunish[i].studentId) {
            that.itemData = that.optPunish[i].punishs;

          }
        }
      },
      onReset() {
        let that = this;
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      onBack() {
        let that = this;
        back(that);
      },
      onSave() {
        let that = this;

        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            that.ruleForm.abnormalBehavior = that.ruleForm.abnormalBehavior.replace(new RegExp(/( )/g), '&nbsp;');
            that.ruleForm.merits = that.ruleForm.merits.replace(new RegExp(/( )/g), '&nbsp;');
            that.ruleForm.familySituation = that.ruleForm.familySituation.replace(new RegExp(/( )/g), '&nbsp;');
            if (that.ruleForm.remark != null) {
              that.ruleForm.remark = that.ruleForm.remark.replace(new RegExp(/( )/g), '&nbsp;');
            }
            for (var item of that.ruleForm.abnormalTutors) {
              item.tutorSituation = item.tutorSituation.replace(new RegExp(/( )/g), '&nbsp;');
            }
            if (that.ruleForm.abnormalId == null) {
              that.fullscreenLoading = true;
              // that.ruleForm.contactDate=new Date(that.ruleForm.contactDate);

              ajaxPost(that, "/api/admin/adviserabnormalbehavior", that.ruleForm, function(r) {
                that.fullscreenLoading = false;
                that.onBack();
              });
            } else {
              that.fullscreenLoading = true;
              // that.ruleForm.contactDate=new Date(that.ruleForm.contactDate);
              ajaxPut(that, "/api/admin/adviserabnormalbehavior/" + that.ruleForm.abnormalId, that.ruleForm,
                function(r) {
                  that.fullscreenLoading = false;
                  that.onBack();
                });
            }
          }
        });
      }
    },
    watch: {

    }
  };

</script>

<style scoped="scoped">

</style>
<style>
  .ql-toolbar.ql-snow {
    line-height: 20px;
  }

  .ql-snow .ql-tooltip[data-mode=link]::before {
    content: "请输入链接地址:";
  }

  .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    border-right: 0px;
    content: '保存';
    padding-right: 0px;
  }

  .ql-snow .ql-tooltip[data-mode=video]::before {
    content: "请输入视频地址:";
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: '14px';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=small]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
    content: '10px';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=large]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
    content: '18px';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=huge]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
    content: '32px';
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=SimSun]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=SimSun]::before {
    content: "宋体";
    font-family: "SimSun";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=SimHei]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=SimHei]::before {
    content: "黑体";
    font-family: "SimHei";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=Microsoft-YaHei]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=Microsoft-YaHei]::before {
    content: "微软雅黑";
    font-family: "Microsoft YaHei";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=KaiTi]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=KaiTi]::before {
    content: "楷体";
    font-family: "KaiTi";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=FangSong]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=FangSong]::before {
    content: "仿宋";
    font-family: "FangSong";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=Arial]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=Arial]::before {
    content: "Arial";
    font-family: "Arial";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=Times-New-Roman]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=Times-New-Roman]::before {
    content: "Times New Roman";
    font-family: "Times New Roman";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=sans-serif]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=sans-serif]::before {
    content: "sans-serif";
    font-family: "sans-serif";
  }

  .ql-font-SimSun {
    font-family: "SimSun";
  }

  .ql-font-SimHei {
    font-family: "SimHei";
  }

  .ql-font-Microsoft-YaHei {
    font-family: "Microsoft YaHei";
  }

  .ql-font-KaiTi {
    font-family: "KaiTi";
  }

  .ql-font-FangSong {
    font-family: "FangSong";
  }

  .ql-font-Arial {
    font-family: "Arial";
  }

  .ql-font-Times-New-Roman {
    font-family: "Times New Roman";
  }

  .ql-font-sans-serif {
    font-family: "sans-serif";
  }

</style>

