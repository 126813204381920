<!--
  处分管理 - 新增
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">

    <el-card class="box-card" v-if="ruleForm.punishId!=null">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          查看处分信息
        </h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-3 colInfo">
            <label>处分号</label>
            <div class="colFonts">
              {{ruleForm.punishNo | flNoValue}}
            </div>
          </div>
          <div class="col-lg-3 colInfo">
            <label>处分日期</label>
            <div class="colFonts">
              {{ruleForm.punishDate | dateformat('YYYY-MM-DD') | flNoValue}}
            </div>
          </div>
          <div class="col-lg-3 colInfo">
            <label>操作时间</label>
            <div class="colFonts">
              {{ruleForm.lastUpdateDate | dateformat('YYYY-MM-DD')}}
            </div>
          </div>
          <div class="col-lg-12 colInfo">
            <label>处分原因</label>
            <div class="colFonts">
              {{ruleForm.reason | flNoValue}}
            </div>
          </div>
          <div class="col-lg-12 colInfo">
            <label>备注</label>
            <div class="colFonts" v-if="ruleForm.remark!=null && ruleForm.remark.length>0" v-html="ruleForm.remark">
            </div>
            <div class="colFonts" v-else>暂无数据</div>
          </div>
        </div>
      </div>

    </el-card>


    <!--学生列表-->
    <el-card class="box-card" v-if="ruleForm.members.length>0">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          处分名单
        </h3>
      </div>
      <div class="card-body p-0">
        <el-table stripe :data="ruleForm.members">
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column prop="student.studentNo" label="学号"></el-table-column>
          <el-table-column prop="student.name" label="学生"></el-table-column>
          <el-table-column prop="student.className" label="班级"></el-table-column>
          <el-table-column prop="levelName" label="处分等级"></el-table-column>
          <el-table-column label="是否撤销">
            <template slot-scope="scope">
             <span v-if="scope.row.isRevoke">已撤销</span>
             <span v-if="!scope.row.isRevoke">未撤销</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
    <!--附件-->
    <comShowImage :uploadFiles="uploadFiles" :comName="'附件'"></comShowImage>


    <div class="row">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
      </div>
    </div>
    <!-- 综合类型弹出框 -->
    <el-dialog :title="dialogScoreName" :visible.sync="dialogScoreVisible" width="30%">
      <div class="divTagBox">
        <span class="spTab" v-for="item in dialogArray" :key="item.configId">
          <el-tag type="info">{{item.name}}<span class="spScore">
              {{item.score}}
            </span></el-tag>
        </span>
      </div>
    </el-dialog>


  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comShowImage from '@/components/showImage.vue';
  export default {
    components: {
      comShowImage,
    },
    data() {
      return {
        // 加载动画
        fullscreenLoading: false,
        // 综合类型详情框
        dialogScoreVisible: false,
        // 综合类型弹出框名称
        dialogScoreName: null,
        // 综合弹出框内的数据
        dialogArray: [],
        inform: [],
        // 学生历史数据弹出框
        dialogTableVisible: false,
        // 学生历史弹出框 学生名称
        dialogStudentName: null,
        // 学生历史弹出框 班级名称
        dialogClassName: null,
        // 学生历史弹出框 学号名称
        dialogStudentNo: null,
        // 单个学生的历史处分
        studentPunsihs: [],
        // 处分名称
        selectStudentList: [],

        // 附件集合
        uploadFiles: [],
        // 放大图片
        showViewer: false,
        //刷新组件
        random: 0,

        //表单数据
        ruleForm: {
          semesterName: null,
          punishId: null, //处分主键，新增时为null
          semesterId: null, //学期
          punishNo: null, //处分号
          punishDateString: null, //处分日期
          reasonId: null, //处分原因
          levelId: null, //默认处分级别主键
          remark: null, //备注
          GrowthConfigs: [], //综合类型集合
          members: [],
        },
        allStudents: [], //所有学生集合
        ClassList: [], //班级集合
        StudentList: [], //班级学生集合
        isPower: {
          punishrevocation_create: false,
        },
        dialogPunishRevocationVisible: false,
        // 综合类型集合
        arrGrowthConfig: [],
        styleWidth: '25%',
        studentForm: {
          applyDate:'',
          revokeDate:'',
          reason:'',
        },
        studentFormReast:null,
        studentRules: {
          student: [{
            type: 'array',
            required: true,
            message: '请选择学生',
            trigger: 'change',
          }],
          applyDate: [{
            required: true,
            message: '请选择撤销申请日期',
            trigger: 'change',
          }],
          revokeDate: [{
            required: true,
            message: '请选择撤销日期',
            trigger: 'change',
          }],
          reason: [{
            max: 500,
            required: true,
            message: '撤销原因不能超过500个字符',
            trigger: 'change'
          }, ],
        },
        Id:'',
        AdduploadFiles: [],
      };
    },
    filters: {
      /**
       * @description 过滤显示数据是否为空
       * @param {String} obj
       */
      flNoValue(obj) {
        if (obj == null || typeof(obj) == "undefined" || obj == '') {
          return '暂无数据';
        }
        return obj;
      }
    },
    created() {
      let that = this;
      let id = that.$route.params.id;
      var detailUrl = null;
      if (id != null && typeof(id) != "undefined" && id != "") {
        that.ruleForm.reasonId = id;
        that.Id = id;
      }
      that.studentFormReast = JSON.parse(JSON.stringify(that.studentForm));
      isPower(that, 'punishrevocation_create', 'punishrevocation_create');
      onGetData(that);
    },

    methods: {
      /**
       * @description 附件上传成功后事件
       * @param {Object} param
       */
      onPictureHandleSuccess(param) {
        let that = this;
        that.studentForm.attachment = "";
        for (var i in param) {
          if (that.studentForm.attachment == "") {
            that.studentForm.attachment = param[i].path;
          } else {
            that.studentForm.attachment += "|" + param[i].path;
          }
        }
      },
      onCreate(obj) {
        let that = this;
        that.studentForm = JSON.parse(JSON.stringify(that.studentFormReast));
        that.dialogPunishRevocationVisible = true;
        that.studentForm.studentId = obj.studentId;
        that.studentForm.applyDate = new Date();
        that.studentForm.revokeDate = that.ruleForm.punishDate;
        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;
        try {
          that.$refs["studentForm"].resetFields();
        } catch {}
      },
      onAdd() {
        let that = this;
        that.$refs["studentForm"].validate(valid => {
          if (valid) {
            that.fullscreenLoading = true;
            ajaxPost(that, "/api/admin/punishrevoke", that.studentForm, function(r) {
              that.fullscreenLoading = false;
              onGetData(that);
            });
          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              isError[0].querySelector('input').focus();
            }, 1)
            return false;
          }
        });
      },
      handleCalce() {
        let that = this;
        that.dialogPunishRevocationVisible = false;
      },

      /**
       * @description 打开综合类型弹出框
       * @param {Object} _data
       */
      onOpenTypeInfo(_data) {
        let that = this;
        that.dialogScoreName = _data.name;
        that.dialogArray = [];
        for (let item of _data.children) {
          if (item.score != 0) {
            that.dialogArray.push({
              name: item.name,
              score: item.score
            })
          }
        }

        if (that.dialogArray.length != 0) {
          that.dialogScoreVisible = true;
        } else {
          info(that, '未配综合类型');
        }
      },


      /**
       * @description 计算综合分数
       * @param {Object} _data
       * @return {Number} 计算出的总分
       */
      onCalculationScore(_data) {
        let temp = 0;
        for (let item of _data) {
          temp = item.score + temp;
        }
        return temp;
      },


      /**
       * @description 打开放大图片
       */
      onPreview() {
        this.showViewer = true
      },


      /**
       * @description 关闭图片预览图
       */
      closeViewer() {
        this.showViewer = false
      },


      /**
       * @description 处分学生列表的累计分数
       * @param {Object} param
       */
      getDialogSummaries(param) {
        const {
          columns,
          data
        } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '累计分数';
            return;
          }
          const values = data.map(item => Number(item[column.property]));
          if (column.property === 'score') {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index];
          } else {
            sums[index] = '';
          }
        });

        return sums;
      },


      /**
       * @description 打开学生处分历史记录
       * @param {Object} studentName 学生姓名
       * @param {Object} className 班级名称
       * @param {Object} studentNo 学号
       * @param {Object} studentId 学生ID
       */
      onStudentPunishDetail(studentName, className, studentNo, studentId) {
        let that = this;
        that.dialogStudentName = studentName;
        that.dialogClassName = className;
        that.dialogStudentNo = studentNo;
        that.dialogTableVisible = true;
        that.studentPunsihs = [];
        for (var i in that.allStudents) {
          if (that.allStudents[i].studentId == studentId) {
            that.studentPunsihs = that.allStudents[i].punishs;
          }
        }
        that.studentPunsihs.sort(function(a, b) {
          return new Date(b.punishDate).getTime() - new Date(a.punishDate).getTime();
        })
      },


      /**
       * @description 返回上一页
       */
      onBack() {
        let that = this;
        back(that);
      },
    },
  };

  function onGetData(that){
    // 获取处分记录详情信息
    that.fullscreenLoading = true;
    ajaxGet(that, "/api/admin/punish/" + that.Id, null, function(resData) {
      if (resData != null) {
        that.ruleForm = resData;
        that.uploadFiles = [];
        if (resData.attachment != null && typeof(resData.attachment) != "undefined" && resData.attachment != "") {
          var files = resData.attachment.split('|');
          for (var i in files) {
            that.uploadFiles.push(that.$ServerUrl() + files[i]);
          }
        }
      }
      that.fullscreenLoading = false;

    });
  }
  /**
   * @description 递归综合类型数据
   * @param {Object} that
   * @param {Object} _data 数据组
   */
  function RecursionData(that, _data) {
    if (_data.length !== 0) {
      _data.forEach((item) => {
        if (item.parentId == "00000000-0000-0000-0000-000000000000") {
          that.arrGrowthConfig.push({
            configId: item.configId,
            name: item.name,
            parentId: item.parentId,
            score: item.score,
            children: _data.filter(val => val.parentId == item.configId)
          });
        }
      });
    }
  }
</script>

<style scoped="scoped">
  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }

  .spIcon {
    width: 5px;
    height: 20px;
    border-radius: 5px;
    background-image: linear-gradient(#4545FF, #2D8EFF);
  }

  .spTitle {
    float: left;
    margin-left: 20px;
    color: #323E56;
    letter-spacing: 2px;
    font-size: 16px;
    margin-top: -3px;
  }

  .wMax {
    width: 100% !important;
  }
</style>


<!-- 综合类型 -->
<style scoped="scoped">
  .divTagBox {
    padding: -5px;
  }

  .divTagBox .spTab {
    padding: 5px;
  }

  .divTagBox .spScore {
    color: #fa3534;
  }

  .divLiteracyBox {
    padding: 15px;
  }

  .divLiteracyBox .listBox {
    text-align: center;
    padding: 20px;
    float: left;
    border: 2px solid #FFFFFF;
    border-radius: 5px;
  }

  .divLiteracyBox .listBox:hover {
    border: 2px solid #409eff;
    cursor: pointer;
  }

  .divLiteracyBox .literacyName {
    font-size: 24px;
    letter-spacing: 2px;
    padding-bottom: 10px;
  }

  .divLiteracyBox .literacyNum {
    padding-top: 10px;
    font-family: 'OswaldLight';
    border-top: 2px solid #EEEEEE;
    font-size: 32px;
    color: #409eff;
  }
</style>
