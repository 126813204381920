<!--
  用户管理 - 新增
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="ruleForm" ref="ruleForm" label-width="100px">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{Title}}
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24">
            <el-col :lg="6" :sm="24" class="text-center">
              <img class="imgAvatar" :src="imageUrl" :alt="ruleForm.name">
            </el-col>
            <el-col :span="12">
              <el-form-item label="用户名">
                {{ruleForm.userName}}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="姓名">
                {{ruleForm.name}}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="部门">
                <el-cascader class="wMax formControl" v-model="userDepartments" :disabled="true" :options="depts"
                  :props="{multiple:true,label:'name',value:'id',children:'children'}" clearable></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="启用">
                <el-switch :disabled="true" v-model="ruleForm.enable">
                </el-switch>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="角色" v-if="roles!=null">
                <el-checkbox-group v-model="userRoles">
                  <el-checkbox :disabled="true" v-for="role in roles" :label="role.roleId"
                    :key="role.roleId">{{role.name}}</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
        </div>

      </el-card>
      <div class="row" style="margin-top: 10px;">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';

  export default {
    components: {},
    data() {
      return {
        idArr: [],
        allDept: [],
        depts: [],
        roles: null,
        userDepartments: [], //部门
        userRoles: [],
        ruleForm: //表单数据
        {
          userId: null, //用户主键，新增时为null
          userName: null, //用户名
          password: null, //密码
          name: null, //姓名
          enable: true, //启用
          userDepartments: null, //部门
          userRoles: null, //角色
        },
        Title: "查看用户信息",
        fullscreenLoading: false,
        imageUrl: null,
      };
    },
    created() {
      let that = this;
      let id = that.$route.params.id;
      var detailUrl = null;
      if (id != null && typeof(id) != "undefined" && id != "") {
        that.Title = "查看用户信息";
        that.ruleForm.reasonId = id;
        detailUrl = "/api/admin/sysUser/" + id;
      }
      that.fullscreenLoading = true;

      ajaxGet(that, detailUrl, null, function(r) {
        if (r != null) {
          that.ruleForm = r;
          that.userRoles = [];
          that.imageUrl = that.onAvatorUrl(r);
          for (var it of that.ruleForm.userRoles) {
            that.userRoles.push(it.roleId);
          }
        }

        ajaxGet(that, "/api/admin/department/treelist", null, function(r1) {
          that.depts = that.getTreeData(r1);
          that.allDept = [];
          InitItem(that, that.depts);
          if (r != null) {
            that.userDepartments = [];
            for (var it of that.ruleForm.userDepartments) {
              that.idArr = [];
              GetKeys(that, it.departmentId);
              var temp = [];
              if (that.idArr.length == 1) {
                temp = that.idArr;
              } else {
                for (var i = (that.idArr.length - 1); i >= 0; i--) {
                  temp.push(that.idArr[i]);
                }
              }
              that.userDepartments.push(temp);
            }
          }
        })

        ajaxGet(that, "/api/admin/sysRole", null, function(r1) {
          that.roles = r1;
        })

        that.fullscreenLoading = false;

      });
    },
    mounted() {
      //document.removeAttribute('is-disabled')
    },
    methods: {
      onAvatorUrl(_data) {
        let that = this;
        let tempUrl = '';
        if (_data.photo != null || typeof(_data.photo) != "undefined" || _data.photo != '') {
          tempUrl = that.$ServerUrl() + _data.photo;
        }
        return tempUrl;
      },
      getTreeData(data) {
        // 循环遍历json数据
        for (var i = 0; i < data.length; i++) {

          if (data[i].children.length < 1) {
            // children若为空数组，则将children设为undefined
            data[i].children = undefined;
          } else {
            // children若不为空数组，则继续 递归调用 本方法
            this.getTreeData(data[i].children);
          }
        }
        return data;
      },
      onSave() {
        let that = this;
        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            that.ruleForm.userRoles = [];
            for (var it of that.userRoles) {
              that.ruleForm.userRoles.push({
                roleId: it,
              });
            }
            that.ruleForm.userDepartments = [];
            for (var it of that.userDepartments) {
              that.ruleForm.userDepartments.push({
                departmentId: it[it.length - 1],
              });
            }
            if (that.ruleForm.userId == null) {
              that.fullscreenLoading = true;
              ajaxPost(that, "/api/admin/sysUser", that.ruleForm, function(r) {
                success(that, r.message);
                that.fullscreenLoading = false;
                var url = that.$route.query.url;
                if (url == null || typeof(url) == "undefined" || url == "") {
                  this.$router.push({
                    path: '/user/student'
                  });
                } else {
                  this.$router.push({
                    path: '/user/student',
                    query: {
                      url: url
                    }
                  });
                }
              });
            } else {

              that.fullscreenLoading = true;
              ajaxPut(that, "/api/admin/sysUser/" + that.ruleForm.userId, that.ruleForm, function(r) {
                success(that, r.message);
                that.fullscreenLoading = false;
                that.onBack();
              });
            }
          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              isError[0].querySelector('input').focus();
            }, 1)
            return false;
          }
        });
      },
      onBack() {
        let that = this;
        back(that);
      },
    },
  };

  function InitItem(that, val) {
    if (val.length !== 0) {
      val.forEach((item) => {
        that.allDept.push(item);
        if (item.children != null && typeof(item.children) != "undefined" && item.children.length >= 1) {
          InitItem(that, item.children);
        }
      });

    }
  }

  function GetKeys(that, id) {
    for (var i in that.allDept) {
      if (that.allDept[i].id == id) {
        that.idArr.push(id);
        if (that.allDept[i].parentId != null && that.allDept[i].parentId != '11111111-1111-1111-1111-111111111111') {
          GetKeys(that, that.allDept[i].parentId);
        }
      }
    }
  }
</script>

<style scoped="scoped">
  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }

  .spIcon {
    width: 5px;
    height: 20px;
    border-radius: 5px;
    background-image: linear-gradient(#4545FF, #2D8EFF);
  }
  .imgAvatar {
    width: 180px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  .spTitle {
    float: left;
    margin-left: 20px;
    color: #323E56;
    letter-spacing: 2px;
    font-size: 16px;
    margin-top: -3px;
  }

  .wMax {
    width: 100% !important;
  }
</style>
