<!--
  学生管理-综合积分
-->
<template>
  <div class="SubWebPageStudent" v-loading.fullscreen.lock="fullscreenLoading">
    <search :ColNumber="3" :SearchList="SearchList" :key="random" ref="SearchList" @onSearch="onSearch"
      v-if="SearchList!=null"></search>
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          我的综合积分
        </h3>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table stripe :data="itemData" class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="reason" label="原因" min-width="300"></el-table-column>
          
         <el-table-column label="学生" min-width="120" :show-overflow-tooltip="true">
           <template slot-scope="scope">
             {{onGetStudentInfo(scope.row.members)}}
           </template>
         </el-table-column>
          <el-table-column prop="score" label="分数" min-width="80"></el-table-column>
          <el-table-column label="行为时间" min-width="100">
            <template slot-scope="scope">
              {{scope.row.logDate | dateformatMinute}}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="160px" fixed="right">
            <template slot-scope="scope">
              <operationBtn :btnName="'查看'" @click.native="onDetail(scope.row.logId)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>

    <el-dialog  :visible.sync="dialogVisible" width="80%">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-lg-12 colInfo">
            <label>原因</label>
            <div class="colFonts">{{ruleFormdata.reason}}</div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>分数</label>
            <div class="colFonts">{{ruleFormdata.score}}</div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>行为时间</label>
            <div class="colFonts">{{ruleFormdata.logDate | dateformatMinute}}</div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>记录人</label>
            <div class="colFonts">{{ruleFormdata.auditor}}</div>
          </div>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
  import '@/assets/css/Student.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';

  export default {
    name: '',
    components: {
      comPage,
      operationBtn,
      search
    },
    data() {
      return {
        random: null,
        fullscreenLoading: false,
        itemData: [],
        page: null, //分页查询接口返回的结果
        pageBtnList: null, //分页左侧按钮集合
        paging: { //分页数据
          pageLength: 0, // 总共页数
          Size: 10, // 当前请求数量
          Index: 1 //当前在第几页
        },
        ruleFormdata: {},
        SearchList: null, //搜索集合
        dialogVisible: false,
      };
    },
    created() {
      let that = this;

      pagingPage(that);
    },
    methods: {
      /**
       * @description 返回
       */
      onBack() {
        back(this);
      },
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },
      /**
       * @description 获取学生信息
       * @param {Object} objVal
       */
      onGetStudentInfo(objVal){
        let tempArr=new Array(objVal.map(item=> item.studentName));
        return tempArr.join('、');
      },
      /**
       * @description 详情
       * @param {Object} objID
       */
      onDetail(objID) {
        let that = this;
        ajaxGet(that, "/api/student/classcreditlog/" + objID, null, function(resdata) {
          if (resdata != "") {
            that.ruleFormdata = resdata;
          }
          that.dialogVisible = true;
        })
      },
      onEdit(objID) {
        let that = this;
        that.$router.push({
          path: "/Student/MoralEducation/Edit/" + objID,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      /**
       * @description 新增
       */
      onCreate() {
        let that = this;
        that.$router.push({
          path: "/Student/MoralEducation/Create",
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },

      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      onSearch(params) {
        let that = this;
        let searchData = params;
        let data = {};
        searchData.forEach(element => {
          data[element.zhi] = Join(element.data);
        });
        data.PageNumer = 1;
        data.radom = Math.random(1000)
        routerPath(that, "/Student/MyMoraleducation/Index", data, null, "PageSize");
      },
      pagingClick(type, val) {
        let that = this;
        let url = "/Student/MyMoraleducation/Index";
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "Date",
            "Reason"
          );
        }
      },
    },
    watch: {
      $route() {
        let that = this;
        pagingPage(that);
      }
    }
  };

  function pagingPage(that) {
    paging(
      that,
      function(r) {
        that.SearchList = [];
        that.SearchList.push({
          type: "time",
          Name: "行为时间",
          data: r.Date,
          zhi: "Date",
        }, {
          type: "input",
          Name: "原因",
          data: r.Reason,
          holder: "请输入原因关键词",
          zhi: "Reason"
        });

        that.fullscreenLoading = true;
        ajaxGet(that, '/api/student/student', null, function(res) {
          let data = {
            LogDateStart: null,
            LogDateEnd: null,
            Reason: r.Reason,
            PageNumer: r.PageNumer,
            PageSize: r.PageSize,
            Student: res.studentNo,
          }
          if (!regNull(r.Date)) {
            let tempTime = r.Date.toString().split(',');
            data.LogDateStart = tempTime[0];
            data.LogDateEnd = tempTime[1];
          }
          that.itemData = [];
          ajaxGet(that, '/api/student/classcreditlog/search', data, function(resdata) {
            that.fullscreenLoading = false;
            if (resdata.items != '') {
              that.itemData = resdata.items;
              that.page = resdata;
            }
          })
        })

      },
      // "SemesterId",
      "Date",
      "Reason",
    );
  }
</script>

<style scoped="scoped">
</style>
