<!--
  行为规范
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <!-- <el-card class="box-card">
      <div class="card-body p-2">
        <ul class="nav nav-pills navTabsCont">
          <li class="nav-item" v-if="isPower.school_record_classlog_create == true">
            <router-link :to="'/Admin/Adviser/Morality/Create/1'"
              :class="currentType == 1 ? 'nav-link active' : 'nav-link'">
              班级
            </router-link>
          </li>
          <li class="nav-item" v-if="isPower.school_record_dormitorylog_create == true">
            <router-link :to="'/Admin/Adviser/Morality/Create/2'"
              :class="currentType == 2 ? 'nav-link active' : 'nav-link'">
              宿舍
            </router-link>
          </li>
          <li class="nav-item" v-if="isPower.school_record_log_create == true">
            <router-link :to="'/Admin/Adviser/Morality/Create/3'"
              :class="currentType == 3 ? 'nav-link active' : 'nav-link'">
              个人
            </router-link>
          </li>
        </ul>
      </div>
    </el-card> -->
        <el-card class="box-card"  v-if="currentType==3">
          <div slot="header" class="card-header">
            <h3 class="card-title titleCont">
              <span class="spIcon"></span>
              行为规范原因{{currentTitle}}
            </h3>
            <div class=" text-right">
              <el-button type="success" icon="el-icon-circle-plus-outline" @click="onAddReason()">新增原因</el-button>
            </div>
          </div>
          <div class="card-body tabListCont">
            <div v-if="regNullArray(itemReasonData)">
              <el-empty :image-size="50" description="暂无原因" name="2"></el-empty>
            </div>
            <div v-else>
              <el-table :data="itemReasonData" border class="width_100Pie">
                <el-table-column label="原因">
                  <template slot-scope="scope">
                    {{ scope.row.name }}
                  </template>
                </el-table-column>
                <el-table-column label="备注" width="160">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.remark" placeholder="可输入备注说明" size="mini" class="width_100Pie">
                    </el-input>
                  </template>
                </el-table-column>
                <el-table-column label="学生" width="600" v-if="currentType!=1">
                  <template slot-scope="scope" >
                    <el-cascader style="width: 100%;" v-model="scope.row.selectstudents" :options="optStudent"
                      :props="{ expandTrigger: 'hover', multiple: true}" @change="handleChange"  v-if="currentType ==3"
                      filterable></el-cascader>
                   <el-select v-if="currentType ==2" v-model="scope.row.selectstudents" placeholder="请选择学生" multiple class="width_100Pie"
                      size="mini" filterable v-el-select-loadmore:rangeNumber="loadMore(rangeNumber)"
                      :filter-method="filterValue">
                      <el-option v-for="item in optStudent.slice(0, rangeNumber)" :key="item.value" :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column label="分值" width="100">
                  <template slot-scope="scope">
                    <el-select v-model="scope.row.score" v-if="currentType == 1" placeholder="请选择分数"
                      class="width_100Pie" size="mini">
                      <el-option v-for="item in scope.row.score2List" :key="item" :label="item" :value="item">
                      </el-option>
                    </el-select>
                    <el-select v-model="scope.row.score" v-if="currentType == 3" placeholder="请选择分数"
                      class="width_100Pie" size="mini">
                      <el-option v-for="item in scope.row.score1List" :key="item" :label="item" :value="item">
                      </el-option>
                    </el-select>
                    <el-select v-model="scope.row.score" v-if="currentType == 2 && !regNullArray(ruleForm.roomIds)"
                      placeholder="请选择分数" class="width_100Pie" size="mini">
                      <el-option v-for="item in scope.row.score2List" :key="item" :label="item" :value="item">
                      </el-option>
                    </el-select>
                    <el-select v-model="scope.row.score" v-if="currentType == 2 && !regNullArray(ruleForm.members)"
                      placeholder="请选择分数" class="width_100Pie" size="mini">
                      <el-option v-for="item in scope.row.score1List" :key="item" :label="item" :value="item">
                      </el-option>
                    </el-select>

                  </template>
                </el-table-column>
                <el-table-column label="操作" width="100">
                  <template slot-scope="scope">
                    <el-button type="danger" @click="onDelReason(scope.row)" size="mini">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>

        </el-card>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="ruleForm">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            新增行为规范{{currentTitle}}
          </h3>
        </div>
        <div class="card-body tabListCont ">
          <el-row :gutter="24">
            <el-col :span="8" v-if="currentType==2">
              <el-form-item label="宿舍">
                <el-cascader v-model="ruleForm.roomIds" :options="optRooms" :show-all-levels="true" clearable filterable
                  @change="onChangeRoom"
                  :props="{ expandTrigger: 'hover',label:'label',value:'value',children:'children' }"
                  class="width_100Pie">
                </el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="currentType==1">
              <el-form-item label="班级" prop="classIds">
                <el-cascader v-model="ruleForm.classIds" :options="optClass" :show-all-levels="true" filterable
                  :props="{ expandTrigger: 'hover',label:'label',value:'value',children:'children' }"
                  class="width_100Pie">
                </el-cascader>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="行为时间" prop="logDate">
                <el-date-picker type="datetime" class="width_100Pie" v-model="ruleForm.logDate"
                  value-format="yyyy-MM-dd HH:mm" placeholder="选择行为时间" format="yyyy-MM-dd HH:mm">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>




      <div>
        <el-card class="box-card" v-if="currentType==2 || currentType==1">
          <div slot="header" class="card-header">
            <h3 class="card-title titleCont">
              <span class="spIcon"></span>
              行为规范原因{{currentTitle}}
            </h3>
            <div class=" text-right">
              <el-button type="success" icon="el-icon-circle-plus-outline" @click="onAddReason()">新增原因</el-button>
            </div>
          </div>
          <div class="card-body tabListCont">
            <div v-if="regNullArray(itemReasonData)">
              <el-empty :image-size="50" description="暂无原因" name="2"></el-empty>
            </div>
            <div v-else>
              <el-table :data="itemReasonData" border class="width_100Pie">
                <el-table-column label="原因">
                  <template slot-scope="scope">
                    {{ scope.row.name }}
                  </template>
                </el-table-column>
                <el-table-column label="备注" width="160">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.remark" placeholder="可输入备注说明" size="mini" class="width_100Pie">
                    </el-input>
                  </template>
                </el-table-column>
                <el-table-column label="学生" width="600" v-if="currentType!=1">
                  <template slot-scope="scope" >
                    <el-cascader style="width: 100%;" v-model="scope.row.selectstudents" :options="optStudent"
                      :props="{ expandTrigger: 'hover', multiple: true}" @change="handleChange"  v-if="currentType ==3"
                      filterable></el-cascader>
                   <el-select v-if="currentType ==2" v-model="scope.row.selectstudents" placeholder="请选择学生" multiple class="width_100Pie"
                      size="mini" filterable v-el-select-loadmore:rangeNumber="loadMore(rangeNumber)"
                      :filter-method="filterValue">
                      <el-option v-for="item in optStudent.slice(0, rangeNumber)" :key="item.value" :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column label="分值" width="100">
                  <template slot-scope="scope">
                    <el-select v-model="scope.row.score" v-if="currentType == 1" placeholder="请选择分数"
                      class="width_100Pie" size="mini">
                      <el-option v-for="item in scope.row.score2List" :key="item" :label="item" :value="item">
                      </el-option>
                    </el-select>
                    <el-select v-model="scope.row.score" v-if="currentType == 3" placeholder="请选择分数"
                      class="width_100Pie" size="mini">
                      <el-option v-for="item in scope.row.score1List" :key="item" :label="item" :value="item">
                      </el-option>
                    </el-select>
                    <el-select v-model="scope.row.score" v-if="currentType == 2 && !regNullArray(ruleForm.roomIds)"
                      placeholder="请选择分数" class="width_100Pie" size="mini">
                      <el-option v-for="item in scope.row.score2List" :key="item" :label="item" :value="item">
                      </el-option>
                    </el-select>
                    <el-select v-model="scope.row.score" v-if="currentType == 2 && !regNullArray(ruleForm.members)"
                      placeholder="请选择分数" class="width_100Pie" size="mini">
                      <el-option v-for="item in scope.row.score1List" :key="item" :label="item" :value="item">
                      </el-option>
                    </el-select>

                  </template>
                </el-table-column>
                <el-table-column label="操作" width="100">
                  <template slot-scope="scope">
                    <el-button type="danger" @click="onDelReason(scope.row)" size="mini">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>

        </el-card>
      </div>
      <!-- 附件 -->
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            行为图片
          </h3>
        </div>
        <div class="card-body tabListCont">
          <el-row :gutter="24" style="margin-top: 20px;">
            <el-col :span="24">
              <comUpload :key="random" @onPictureHandleSuccess="onPictureHandleSuccess" :files="uploadFiles">
              </comUpload>
            </el-col>
          </el-row>
        </div>
      </el-card>

      <!-- 备注 -->
      <!--      <el-card class="box-card" v-if="currentType==2 || currentType==4">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            备注
          </h3>
        </div>
        <div class="card-body tabListCont">
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="" prop="description">
                <el-input class="formControl" v-model="ruleForm.remark" type="textarea" :rows="5" show-word-limit
                  placeholder="请输入备注" maxlength="500">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card> -->
    </el-form>
    <!-- 行为规范原因 -->
    <el-dialog title="选择原因" :visible.sync="dialogReason" width="90%" :before-close="onCloseReason">
      <div class="divDialogReasonBox">
        <div class="divCheckNum">共勾选（<b>{{ckNumber}}</b>）条行为规范的原因</div>
        <el-tabs tab-position="left">
          <el-tab-pane :label="onGetLabel(item)" v-for="(item,index) in optReason" :key="item.id+'-'+index">
            <div class="tabBody">
              <div v-if="!regNullArray(item.children)">
                <table class="table table-bordered width_100Pie">
                  <tr>
                    <th>原因</th>
                    <th v-if="currentType!=1">学生</th>
                    <th>分值</th>
                  </tr>
                  <tr v-for="(list,indexTwo) in item.children" :key="list.id+'-'+indexTwo">
                    <td>
                      <div class="listBox" :class="{'actList':list.status}" @click="onCheckReason(list)">
                        <svg class="icon" aria-hidden="true" v-if="list.status">
                          <use xlink:href="#fax-square-check-alt"></use>
                        </svg>
                        <svg class="icon" aria-hidden="true" v-else>
                          <use xlink:href="#fax-square-radius-o"></use>
                        </svg>
                        {{ list.name }}
                      </div>
                      <el-input v-model="list.remark" size="mini" placeholder="可输入备注说明" v-if="list.status"></el-input>

                    </td>
                    <td v-if="currentType!=1">
                      <el-cascader v-if="currentType == 3"  style="width: 100%;" v-model="list.selectstudents" :options="optStudent"
                        :props="{ expandTrigger: 'hover', multiple: true}" @change="handleChange"
                        filterable></el-cascader>
                    <el-select v-if="currentType == 2" v-model="list.selectstudents" placeholder="请选择学生" multiple class="width_100Pie"
                        size="mini" filterable :disabled="!list.status" @change="onChangeStudent(list)"
                        v-el-select-loadmore:rangeNumber="loadMore(rangeNumber)" @blur="blur" :filter-method="filterValue">
                        <el-option v-for="item in optStudent.slice(0, rangeNumber)" :key="item.value"
                          :label="item.label" :value="item.value">
                        </el-option>
                      </el-select>
                    </td>
                    <td width="120px" v-if="currentType == 1">
                      <el-select v-model="list.score" placeholder="请选择分数" class="width_100Pie" size="mini"
                        :disabled="!list.status">
                        <el-option v-for="item in list.score2List" :key="item.deployId" :label="item" :value="item">
                        </el-option>
                      </el-select>
                    </td>
                    <td width="120px" v-if="currentType == 3">
                      <el-select v-model="list.score" placeholder="请选择分数" class="width_100Pie" size="mini"
                        :disabled="!list.status">
                        <el-option v-for="item in list.score1List" :key="item.deployId" :label="item" :value="item">
                        </el-option>
                      </el-select>
                    </td>
                    <td width="120px" v-if="currentType == 2">
                      <el-select v-model="list.score" placeholder="请选择分数" class="width_100Pie" size="mini"
                        :disabled="!list.status" v-if="regNullArray(list.selectstudents)">
                        <el-option v-for="item in list.score2List" :key="item.deployId" :label="item" :value="item">
                        </el-option>
                      </el-select>
                      <el-select v-model="list.score" placeholder="请选择分数" class="width_100Pie" size="mini"
                        :disabled="!list.status" v-else>
                        <el-option v-for="item in list.score1List" :key="item.deployId" :label="item" :value="item">
                        </el-option>
                      </el-select>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogReason = false">取 消</el-button>
        <el-button type="primary" @click="onBtnSubmitReason">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 快捷操作 -->
    <el-dialog title="快捷操作" :visible.sync="dialogVisibleSave" width="35%" :close-on-click-modal="false"
      :close-on-press-escape="false" :before-close="beforeClose">
      <el-row :gutter="20">
        <el-col :span="12" class="operateCol" v-if="currentType == 1">
          <el-button type="primary" icon="el-icon-right" @click="onReserveContinue"
            class="width_100Pie">保留评价项目继续</el-button>
        </el-col>
        <el-col :span="12" class="operateCol" v-if="currentType == 1">
          <el-button type="primary" icon="el-icon-right" @click="onReserveObject"
            class="width_100Pie">保留评价对象继续</el-button>
        </el-col>
        <el-col :span="24" class="operateCol" v-if="currentType == 1">
          <el-button type="primary" icon="el-icon-right" @click="onReserveClass"
            class="width_100Pie">下一个班级继续</el-button>
          <div class="operateDivTag">
            <el-row :gutter="20">
              <el-col :span="4" v-for="(p, index) in nextClass" :key="index">
                <el-tag type="success" class="operateTag"
                  @click="onClickClass(p.collegeId, p.value)">{{p.label}}</el-tag>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :span="24" class="operateCol" v-if="currentType == 2">
          <el-button type="primary" icon="el-icon-right" @click="onReserveRoom" class="width_100Pie">下一个宿舍继续</el-button>
          <div class="operateDivTag">
            <div v-for="(p, index) in nextRooms" :key="index" style="flex: 1">
              <el-tag type="success" class="operateTag"
                @click="onClickRoom(p.buildingId, p.floorId, p.value)">{{p.floor}}{{p.label}}</el-tag>
            </div>
          </div>
        </el-col>
        <el-col :span="24" class="operateCol">
          <el-button @click="onBack()" class="width_100Pie">返 回</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <el-dialog title='提示' :visible.sync="isErrors" width="35%" :close-on-click-modal="false"
      :close-on-press-escape="false" :before-close="onCloseSave">
      <div v-if="errorType == 1">
        <div v-for="(item,index) in errors" :key="index">
          <span>{{item}}</span>
        </div>
      </div>
      <div v-else>
        <div v-for="(item,index) in errors" :key="index">
          <span>{{item}}</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" v-if="errorType != 1">
        <el-button type="primary" @click="onLastSave()">继续 新增</el-button>
      </span>
    </el-dialog>
    <div class="row" style="margin-top: 10px;">
      <div class="col-12 ">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
        <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
        <el-button type="primary" icon="el-icon-circle-check" @click="onSave()">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comUpload from '@/components/Upload.vue';
  import Vue from "vue";

  Vue.directive("el-select-loadmore", {
    bind(el, binding) {
      //获取element-ui定义好的scroll盒子
      const SELECTWRAP_DOM = el.querySelector(".el-select-dropdown .el-select-dropdown__wrap")
      SELECTWRAP_DOM.addEventListener("scroll", function() {
        //scrollHeight 获取元素内容高度
        //scrollTop 获取或设置元素的偏移值，常用于计算滚动条的位置，当一个元素的容器没有产生垂直方向的滚动条，则默认scrollTop=0.
        //clientHeight 读取元素的可见高度
        //ele.scrollHeight - ele.scrollTop === ele.clientHeight;如果元素滚到底等式返回true，没有返回false；
        const condition = this.scrollHeight - this.scrollTop <= this.clientHeight
        if (condition) {
          binding.value();
        }
      })
    }
  });
   let pinyin = require('js-pinyin');
  pinyin.setOptions({checkPolyphone: false, charCase: 1});
  export default {
    components: {
      comUpload
    },
    data() {
      return {
        isReasonLoaded: false,
        visibleOptReason: [], // 用于存储可见的原因
        rangeNumber: 10,
        // 加载动画
        fullscreenLoading: false,
        //添加学生信息弹出框
        dialogVisible: false,
        dialogVisibleForBed: false,
        dialogVisibleSave: false,
        // 页面标题
        currentTitle: "",
        //刷新组件
        random: 0,
        // 上传图片数组
        uploadFiles: [],
        // 表单对象
        ruleForm: {
          reasonId: null,
          reasonIds: [],
          // 记录时间
          logDate: null,
          // 备注
          remark: null,
          // 分数
          score: null,
          scoreId: null,
          // 附件
          attachment: null,
          // 人员
          members: [],
          // 班级ID
          classIds: null,
          // 计量
          number: null,
          //宿舍ID
          roomIds: [],
          roomId: null,
        },
        ruleFormReset: null,
        ruleFormReserve: null,
        // 行为类型 1：班级 2：个人 3：宿舍 4：宿舍个人
        currentType: null,
        // 扣分原因数组
        // 班级数组
        optClass: [],
        allClass: [],
        nextClass: [],
        // 学生数组m
        optStudent: [],
        allStudent: [],
        currentClassId: null,
        currentClassName: null,
        currentClassIdReset: null,

        currentBedId: [],
        // 选择学生的ID
        currentStudentId: [],
        // 验证
        rules: {
          reasonIds: [{
            required: true,
            message: '请选择管理原因',
            trigger: 'change'
          }],
          scoreId: [{
            required: true,
            message: '请选择分数',
            trigger: 'change'
          }],
          score: [{
            required: true,
            message: '请输入分数',
            trigger: 'change'
          }, {
            validator: (rule, value, callback) => {
              let that = this;
              var reg = /^(\-|\+)?\d+(\.\d{1,2})?$/;
              if (value != 0 && !reg.exec(value)) {
                callback(new Error("分数只能输入带1-2位小数的正数或负数！"));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }],
          number: [{
            required: true,
            message: '请输入计量数',
            trigger: 'change'
          }, {
            validator: (rule, value, callback) => {
              let _this = this;
              var reg = /^\+?[1-9][0-9]*$/;
              if (value != 0 && !reg.exec(value)) {
                callback(new Error("计量数只能输入正整数！"));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }],
          logDate: [{
            required: true,
            message: '请选择行为时间',
            trigger: 'change'
          }],
          classIds: [{
            required: true,
            message: '请选择班级',
            trigger: 'change'
          }],
          roomIds: [{
            required: true,
            message: '请选择宿舍',
            trigger: 'change'
          }],
        },
        // 录入分类类型
        itemReason: {
          inputScoreMode: 0,
          reasonDeploys: [],
        },
        itemReasonReserveContinue: null,

        typeScoreMode: {
          labelName: '',
          valTotalScore: 0,
          tipFont: '',
        },
        typeScoreModeReset: null,
        // 宿舍数组
        optRooms: [],
        allRooms: [],
        nextRooms: [],
        optBeds: [],

        //宿舍混寝班级数据
        optRoomClass: [],

        // 多条原因数组
        tbReasonDataReserve: null,
        tbReasonDesc: [],
        tempStudent: null,

        // 行为规范原因弹出框
        dialogReason: false,
        optReason: [], // 原因数组
        optReasonReset: [], // 原因数组重置
        itemReasonData: [], // 原因Table
        itemReasonDataReset: [], // 用于原因重置
        ckNumber: 0,

        isPower: {
          school_record_classlog_create: false,
          school_record_log_create: false,
          school_record_dormitorylog_create: false,
          school_record_dplog_create: false,
        },
        errors: '',
        errorType: 1,
        isErrors: false,
      };
    },
    created() {
      let that = this;
      isPower(that, "school_record_classlog_create", "school_record_classlog_create");
      isPower(that, "school_record_log_create", "school_record_log_create");
      isPower(that, "school_record_dormitorylog_create", "school_record_dormitorylog_create");
      isPower(that, "school_record_dplog_create", "school_record_dplog_create");
      that.currentType = that.$route.params.type;
      InitType(that);
    },
    methods: {
      handleChange(e) {
        let that = this;
        console.log(e)
      },
      givenNamePinyin(val){
          return pinyin.getCamelChars(val)
      },
      filterValue(query) {
        if (query !== "") {
          this.optStudent = this.allStudent.filter((item) => {
            // 这里是用的value选项筛选，默认是label
            return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        } else {
          this.optStudent = this.allStudent;
        }
      },
      //下拉框滚动事件
      loadMore() {
        //每次滚动到底部新增条数，可自定义
        return () => this.rangeNumber += 10;
      },
      //下拉框失去焦点事件
      blur() {
        this.rangeNumber = 10;
      },


      /**
       * @description 选择宿舍继续
       */
      onClickRoom(buildingId, floorId, roomId) {
        let that = this;
        let data = {
          logDate: new Date(),
          remark: that.ruleForm.remark,
          attachment: null,
          classId: null,
          classIds: [],
          roomId: roomId,
          roomIds: [buildingId, floorId, roomId],
        };
        that.itemReasonData = [];
        that.optStudent = that.optBeds.filter(x => x.parentId == data.roomId);
        that.optReason = JSON.parse(JSON.stringify(that.optReasonReset));
        // 保存数据
        that.ruleFormReserve = JSON.parse(JSON.stringify(data));
        // 还原数据
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReserve));
        // 重置
        that.uploadFiles = [];
        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}

        that.dialogVisibleSave = false;
      },
      /**
       * @description 选择班级继续
       */
      onClickClass(collegeId, classId) {
        let that = this;
        let data = {
          logDate: new Date(),
          remark: that.ruleForm.remark,
          attachment: null,
          classId: classId,
          classIds: [collegeId, classId],
          roomId: null,
          roomIds: [],
        };

        // 保存数据
        that.ruleFormReserve = JSON.parse(JSON.stringify(data));
        // 还原数据
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReserve));
        // 重置
        that.uploadFiles = [];
        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}

        that.dialogVisibleSave = false;

      },
      beforeClose() {
        this.onBack();
      },
      /**
       * @description 弹出框中 原因名称的生成
       */
      onGetLabel(objItem) {
        let that = this;
        if (!regNullArray(objItem.children)) {
          let tpCKNumber = objItem.children.filter(item => item.status);
          return regNullArray(tpCKNumber) ? objItem.name + '（ 0 ）' : objItem.name + '（ ' + tpCKNumber.length + ' ）';
        } else {
          return objItem.name + '（ 0 ）';
        }
      },
      /**
       * @description 关闭原因弹出框
       */
      onCloseReason() {
        let that = this;
        that.dialogReason = false;
      },
      /**
       * @description 点击原因,进行勾选事项
       */
      onCheckReason(objItem) {
        let that = this;
        if (!regNull(objItem)) {
          that.optReason.forEach(item => {
            if (item.id == objItem.parentId) {
              if (!regNullArray(item.children)) {
                item.children.forEach(list => {
                  if (list.id == objItem.id) {
                    if (objItem.status) {

                      list.status = false;
                      that.ckNumber = that.ckNumber > 0 ? that.ckNumber - 1 : 0;
                      list.score = '';
                      list.selectstudents = [];
                      // 重置分数
                      // if (list.inputScoreMode == 1) {
                      //   let tpDefaultScore = regNullArray(list.reasonDeploys) ? null : list.reasonDeploys.find(
                      //     fd => fd.isDefault);
                      //   list.score = regNull(tpDefaultScore) ? 0 : tpDefaultScore.score;
                      // } else {
                      //   list.score = 0;
                      // }

                    } else {
                      if (regNullArray(list.selectstudents)) {
                        list.score = list.score1List[0];
                      } else {
                        list.score = list.score2List[0];
                      }
                      list.status = true;
                      that.ckNumber += 1;
                    }
                  }
                });
              }
            }
          });
        }
      },
      /**
       * @description 提交原因
       */
      onBtnSubmitReason() {
        let that = this;
        if (!regNullArray(that.optReason)) {
          that.optReason.forEach(item => {
            if (!regNullArray(item.children)) {
              item.children.forEach(list => {
                if (list.status) {
                  if (regNull(that.itemReasonData.find(fd => fd.id == list.id))) {
                    that.itemReasonData.push(list);
                  }
                } else {
                  that.itemReasonData = that.itemReasonData.filter(fl => fl.id != list.id);
                }
              });
            }
          });
        }
        that.onCloseReason();
      },

      /**
       * @description 删除原因
       * @param {Object} objItem
       */
      onDelReason(objItem) {
        let that = this;
        if (!regNull(objItem)) {
          that.itemReasonData = that.itemReasonData.filter(item => item.id != objItem.id);
          that.onCheckReason(objItem);
        }
      },
      /**
       * @description 新加原因数量
       */
      onAddReason() {
        let that = this;
        that.dialogReason = true;
      },
      /**
       * @description 生成输入框的标题
       * @return {String} 标题
       */
      onGetUnitLabel() {
        return '计量数（单位：' + this.itemReason.reasonDeploys[0].unit + '）';
      },
      /**
       * @description 管理原因选择事件
       * @return {void}
       */
      onChangeReason(_val) {
        let that = this;
        that.typeScoreMode = JSON.parse(JSON.stringify(that.typeScoreModeReset));
        that.ruleForm.scoreId = null;
        that.ruleForm.score = null;
        that.ruleForm.number = null;
      },
      /**
       * @description 返回
       * @return {void}
       */
      onBack() {
        let that = this;
        that.$router.push({
          path: '/Admin/Adviser/Morality/Record?type=' + that.currentType
        });
      },
      /**
       * @description 下一个班级继续
       */
      onReserveClass() {
        let that = this;
        let data = {
          logDate: new Date(),
          remark: that.ruleForm.remark,
          attachment: null,
          classId: null,
          classIds: [],
          roomId: null,
          roomIds: [],
        };
        if (!regNullArray(that.ruleForm.classIds)) {
          // 年级Id
          let collegeId = that.ruleForm.classIds[0];
          // 班级Id
          let classId = that.ruleForm.classIds[1];
          let college = that.optClass.find(x => x.value == collegeId);
          if (!regNull(college)) {
            if (!regNullArray(college.children)) {
              let classIndex = college.children.findIndex(x => x.value == classId);
              // 如果是最后一个班级
              if (college.children.length == classIndex + 1) {
                let collegeIndex = that.optClass.findIndex(x => x.value == collegeId);
                // 如果是最后一个年级
                if (that.optClass.length == collegeIndex + 1) {
                  setTimeout(() => {
                    warning(that, `这是最后一个班级`);
                  }, 500)
                } else {
                  data.classIds = [that.optClass[collegeIndex + 1].value, that.optClass[collegeIndex + 1].children[0]
                    .value
                  ];
                }
              } else {
                data.classIds = [college.value, college.children[classIndex + 1].value];
              }
            }
          }
        }
        // 保存数据
        that.ruleFormReserve = JSON.parse(JSON.stringify(data));
        // 还原数据
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReserve));
        // 重置
        that.uploadFiles = [];
        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
        that.dialogVisibleSave = false;
      },
      /**
       * @description 下一个宿舍继续
       */
      onReserveRoom() {
        let that = this;
        let data = {
          logDate: new Date(),
          remark: that.ruleForm.remark,
          attachment: null,
          classId: null,
          classIds: [],
          roomId: null,
          roomIds: [],
        };
        if (!regNullArray(that.ruleForm.roomIds)) {
          let tempBuildId = that.ruleForm.roomIds[0]; // 获取宿舍楼ID
          let rooms = that.allRooms.filter(item => item.buildingId == tempBuildId);
          if (!regNullArray(rooms)) {
            // 获取宿舍下标
            let roomIndex = rooms.findIndex(x => x.value == that.ruleForm.roomIds[2]);
            if (rooms.length == roomIndex + 1) {
              setTimeout(() => {
                warning(that, `这是宿舍楼【${rooms[0].building}】的最后一个宿舍`);
              }, 500)
            } else {
              data.roomId = rooms[roomIndex + 1].value;
              data.roomIds = [rooms[roomIndex + 1].buildingId, rooms[roomIndex + 1].floorId, rooms[roomIndex + 1].value]
              // for (var it of that.itemReasonData) {
              //   it.selectstudents = [];
              // };
              that.itemReasonData = [];
              that.optReason = JSON.parse(JSON.stringify(that.optReasonReset));
              that.optStudent = that.optBeds.filter(x => x.parentId == data.roomId);
            }
          }
        }
        // 保存数据
        that.ruleFormReserve = JSON.parse(JSON.stringify(data));
        // 还原数据
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReserve));
        // 重置
        that.uploadFiles = [];
        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
        that.dialogVisibleSave = false;
      },
      /**
       * @description 保留评价对象继续
       */
      onReserveObject() {
        let that = this;
        let data = null;
        // 班级
        if (that.currentType == 1) {
          data = {
            logDate: new Date(),
            remark: null,
            attachment: null,
            classId: that.ruleForm.classId,
            classIds: that.ruleForm.classIds,
          }
        }
        // 宿舍
        if (that.currentType == 2) {
          data = {
            logDate: new Date(),
            remark: null,
            attachment: null,
            classId: that.ruleForm.classId,
            classIds: that.ruleForm.classIds,
            roomId: that.ruleForm.roomId,
            roomIds: that.ruleForm.roomIds,
          }
        }

        // 个人 || 宿舍个人
        // if (that.currentType == 2 || that.currentType == 3) {
        //   data = {
        //     logDate: new Date(),
        //     reasonId: null,
        //     reasonIds: null,
        //     remark: null,
        //     scoreId: null,
        //     score: null,
        //     number: null,
        //     attachment: null,
        //     members: that.ruleForm.members,
        //   }
        // }

        // 保存数据
        that.ruleFormReserve = JSON.parse(JSON.stringify(data));
        // 还原数据
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReserve));
        that.itemReasonData = [];
        that.itemReasonDataReset = [];
        // 重置
        // that.onReset();
        that.optReason = JSON.parse(JSON.stringify(that.optReasonReset));
        that.ckNumber = 0;
        that.uploadFiles = [];
        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}

        that.dialogVisibleSave = false;

      },
      /**
       * @description 保留评价项目继续
       */
      onReserveContinue() {
        let that = this;
        let data = null;
        // 班级
        if (that.currentType == 1) {
          data = {
            logDate: new Date(),
            remark: that.ruleForm.remark,
            attachment: null,
            classId: null,
            classIds: [],
          }
        }
        // 宿舍
        if (that.currentType == 3) {
          data = {
            logDate: new Date(),
            remark: that.ruleForm.remark,
            attachment: null,
            classId: null,
            classIds: [],
            roomId: null,
            roomIds: [],
          }
        }
        // 个人 || 宿舍个人
        if (that.currentType == 2 || that.currentType == 3) {
          data = {
            logDate: new Date(),
            reasonId: that.ruleForm.reasonId,
            reasonIds: that.ruleForm.reasonIds,
            remark: that.ruleForm.remark,
            scoreId: that.ruleForm.scoreId,
            score: that.ruleForm.score,
            number: that.ruleForm.number,
            attachment: null,
            members: [],
          }
        }
        that.itemReasonDataReset = JSON.parse(JSON.stringify(that.itemReasonData))
        // 保存数据
        that.ruleFormReserve = JSON.parse(JSON.stringify(data));
        // 重置
        // that.onReset();
        // 还原数据
        that.ruleForm = JSON.parse(JSON.stringify(data));
        that.dialogVisibleSave = false;
      },
      onLastSave() {
        let that = this;
        let dataPerson = {
          category: 3,
          logDate: timeTransform(that.ruleForm.logDate),
        }
        dataPerson.items = [];
        for (var it of that.itemReasonData) {
          dataPerson.items.push({
            reasonId: it.id,
            score: it.score,
            remark: it.remark,
            attachment: that.ruleForm.attachment,
            students: it.selectstudents.map(item => ({
              studentId: item[item.length-1],
              score: it.score,
              remark: it.remark,
              attachment: that.ruleForm.attachment,
              classId: item[0],
            }))
          })
        }
        ajaxPost(that, "/api/admin/moralperson", dataPerson, function(res) {
          setTimeout(() => {
            that.onBack()
          }, 500)
        });
      },
      onCloseSave() {
        let that = this;
        that.isErrors = false;
      },
      /**
       * @description 提交事件
       */
      onSave() {
        let that = this;

        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            if (that.currentType == 3) {
              if (regNullArray(that.itemReasonData)) {
                warning(that, "请选择原因");
                return false;
              }
              that.fullscreenLoading = true;
              let dataPerson = {
                category: 3,
                logDate: timeTransform(that.ruleForm.logDate),
              }
              dataPerson.items = [];
              for (var it of that.itemReasonData) {
                dataPerson.items.push({
                  reasonId: it.id,
                  score: it.score,
                  remark: it.remark,
                  attachment: that.ruleForm.attachment,
                  students: it.selectstudents.map(item => ({
                    studentId: item[item.length-1],
                    score: it.score,
                    remark: it.remark,
                    attachment: that.ruleForm.attachment,
                    classId: item[0],
                  }))
                })
              }
              let data = {
                logDate: timeTransform(that.ruleForm.logDate),
                items: that.itemReasonData.map(item => ({
                  reasonId: item.id,
                  score: Math.abs(item.score * item.selectstudents.length),
                }))
              }
              ajaxPostVali(that, '/api/admin/moralperson/validate', data, function(r) {
                that.fullscreenLoading = false;
                if (r.result == 1) {
                  that.isErrors = true;
                  that.errorType = r.result;
                  that.errors = r.errors;
                } else if (r.result == 2) {
                  that.isErrors = true;
                  that.errorType = r.result;
                  that.errors = r.errors;
                } else if (r.result == 3) {
                  ajaxPost(that, "/api/admin/moralperson", dataPerson, function(res) {
                    setTimeout(() => {
                      that.onBack()
                    }, 500)
                  });
                }
              })

            } else if (that.currentType == 1) {
              // 选择原因
              if (regNullArray(that.itemReasonData)) {
                warning(that, "请选择原因");
                return false;
              }
              // 班级
              let tpParams = [];
              let tpClassId = that.ruleForm.classIds[that.ruleForm.classIds.length - 1];
              that.itemReasonData.forEach((item) => {
                tpParams.push({
                  reasonId: item.id,
                  score: parseFloat(item.score),
                  attachment: that.ruleForm.attachment,
                  remark: item.remark,
                });
              });
              let data = {
                classId: tpClassId,
                category: 1,
                logDate: timeTransform(that.ruleForm.logDate),
                items: tpParams,
              }
              that.fullscreenLoading = true;
              ajaxPost(that, '/api/admin/moralclass', data, function(r) {
                that.fullscreenLoading = false;
                let index = that.allClass.findIndex(x => x.value == that.ruleForm.classIds[1]);
                that.nextClass = that.allClass.slice(index + 1, index + 7);
                setTimeout(() => {
                  that.dialogVisibleSave = true;
                }, 500)
              });
            } else if (that.currentType == 2) {
              // 选择原因
              if (regNullArray(that.itemReasonData)) {
                warning(that, "请选择原因");
                return false;
              }
              //宿舍
              let members = [];
              if (that.ruleForm.classId) {
                members.push({
                  classId: that.ruleForm.classId
                })
              }
              let dateDorm = {
                category: 2,
                logDate: timeTransform(that.ruleForm.logDate),
                items: [],
              }
              if (!regNull(that.ruleForm.roomIds[that.ruleForm.roomIds.length - 1])) {
                dateDorm.roomId = that.ruleForm.roomIds[that.ruleForm.roomIds.length - 1];
              }
              for (var it of that.itemReasonData) {
                dateDorm.items.push({
                  reasonId: it.id,
                  score: it.score,
                  remark: it.remark,
                  attachment: that.ruleForm.attachment,
                  students: it.selectstudents.map(item => ({
                    studentId: that.optStudent.find(item2 => (item2.value == item)).studentId,
                    score: it.score,
                    remark: it.remark,
                    bedId: item,
                    classId: that.optStudent.find(item2 => (item2.value == item)).classId,
                    attachment: that.ruleForm.attachment,
                  }))
                })
              }
              that.fullscreenLoading = true;
              ajaxPost(that, '/api/admin/moraldorm', dateDorm, function(r) {
                that.fullscreenLoading = false;
                let rooms = that.allRooms.filter(x => x.buildingId == that.ruleForm.roomIds[0]);
                let index = rooms.findIndex(y => y.value == that.ruleForm.roomIds[2]);
                that.nextRooms = rooms.slice(index + 1, index + 7);
                setTimeout(() => {
                  that.dialogVisibleSave = true;
                }, 500)
              });
            }
          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              var el = isError[0].querySelector('input') || isError[0].querySelector('textarea') || isError[0]
                .querySelector('select');
              if (el) {
                el.focus();
              }
            }, 1)
            return false;
          }
        });
      },
      /**
       * @description 重置事件
       * @return {void}
       */
      onReset() {
        let that = this;
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
        that.currentClassId = JSON.parse(JSON.stringify(that.currentClassIdReset));
        that.typeScoreMode = JSON.parse(JSON.stringify(that.typeScoreModeReset));
        that.itemReasonData = JSON.parse(JSON.stringify(that.itemReasonDataReset));
        that.optReason = JSON.parse(JSON.stringify(that.optReasonReset));
        that.ckNumber = 0;
        that.uploadFiles = [];
        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      /**
       * @description 删除学生名单
       * @param {Object} objValID 学生ID
       * @return {void}
       */
      onDeleteMember(objValID) {
        let that = this;
        that.ruleForm.members = that.ruleForm.members.filter(item => item.studentId != objValID);
        // 弹出框中学生的名单也要删除
        if (!regNullArray(that.currentBedId)) {
          that.currentBedId = that.currentBedId.filter(item => {
            if (regNull(item.find(fd => fd == objValID))) {
              return item;
            }
          });
        }
      },
      /**
       * @description 【个人】中选择班级的事件
       * @param {*} objItem
       */
      onChangeClass(objItem) {
        let that = this;

        that.currentClassName = that.optClass.find(x => x.value == objItem[0]).children.find(y => y.value == objItem[1])
          .label;
        // 根据班级id获取学生信息
        ajaxGet(that, '/api/admin/student/class/' + objItem[1], null, function(resClass) {
          that.optStudent = resClass;
          if (!regNullArray(that.ruleForm.members)) {
            that.ruleForm.members.forEach(element => {
              that.currentStudentId.push(element.studentId);
            })
          }
        });
      },
      /**
       * @description [宿舍]选择宿舍事件
       * @param {*} e
       */
      onChangeRoom(e) {
        let that = this;
        that.optClass = [];
        that.ruleForm.classId = null;
        that.ruleForm.members = [];
        that.itemReasonData = [],
          that.optStudent = that.optBeds.filter(x => x.parentId == e[e.length - 1]);
        // if (!regNullArray(e)) {
        //   ajaxGet(that, "/api/admin/moraldormitorylog/roomclass/" + e[2], null, function(r) {
        //     if (r && r.length > 0) {
        //       that.optClass = r;
        //     }
        //   })
        // }
      },
      /**
       * @description 关闭弹窗
       */
      onCloseStudent() {
        let that = this;
        that.optStudent = [];
        that.currentStudentId = [];
        that.currentClassId = [];
        that.dialogVisible = false;
      },
      /**
       * @description [个人]勾选学生，确认事件
       * @return {void}
       */
      onChangeStudent(objItem) {
        let that = this;
        that.optReason.forEach(item => {
          if (item.id == objItem.parentId) {
            if (!regNullArray(item.children)) {
              item.children.forEach(list => {
                if (list.id == objItem.id) {
                  if (!regNullArray(list.selectstudents)) {
                    list.score = list.score1List[0];
                  } else {
                    list.score = list.score2List[0];
                  }
                }
              })
            }
          }
        });
      },

      /**
       * @description 数据递归
       * @param {Array} data 需要递归的原数据
       * @return {Array} 返回递归后的数据
       */
      getTreeData(data) {
        // 循环遍历json数据
        for (var i = 0; i < data.length; i++) {
          if (data[i].children.length < 1) {
            // children若为空数组，则将children设为undefined
            data[i].children = undefined;
          } else {
            // children若不为空数组，则继续 递归调用 本方法
            this.getTreeData(data[i].children);
          }
        }
        return data;
      },
      /**
       * @description 上传图片成功后事件
       * @param {Object} param 上传成功后的地址
       * @return {void}
       */
      onPictureHandleSuccess(param) {
        let that = this;
        that.ruleForm.attachment = "";
        for (var i in param) {
          if (that.ruleForm.attachment == "") {
            that.ruleForm.attachment = param[i].path;
          } else {
            that.ruleForm.attachment += "|" + param[i].path;
          }
        }
      },
    },
    watch: {
      $route() {
        let that = this;
        that.onReset();
        that.itemReasonData = [];
        InitType(that);
      },
    },
  };

  // 初始化数据加载
  function InitType(that) {
    that.tempStudent = JSON.parse(that.$GetKey(that.$cacheStudent));
    that.currentClassIdReset = JSON.parse(JSON.stringify(that.currentClassId));
    that.typeScoreModeReset = JSON.parse(JSON.stringify(that.typeScoreMode));
    that.itemReasonDataReset = JSON.parse(JSON.stringify(that.itemReasonData));
    that.currentType = that.$route.params.type;
    var reasonUrl = "";
    if (that.currentType == 1) {
      that.currentTitle = "【班级】";
      that.typeScoreMode.labelName = "班级";
      reasonUrl = "/api/admin/moralclass/reason";
    } else if (that.currentType == 3) {
      that.currentTitle = "【个人】";
      that.typeScoreMode.labelName = "学生";
      reasonUrl = "/api/admin/moralperson/reason";
    } else if (that.currentType == 2) {
      that.currentTitle = "【宿舍】";
      that.typeScoreMode.labelName = "宿舍";
      reasonUrl = "/api/admin/moraldorm/reason";
    }
    if (reasonUrl != "") {
      that.optReason = [];
      that.fullscreenLoading = true;
      ajaxGet(that, reasonUrl, null, function(resData) {
        let reasonList = resData;
        for (var it of reasonList) {
          if (it.category == null) {
            it.category = '其他';
          }
        }
        reasonList = Array.from(new Map(reasonList.map(item => [item.categoryId, item])).values());
        for (var it2 of reasonList) {
          that.optReason.push({
            id: it2.categoryId,
            name: it2.category,
            status: false,
            children: [],
          })
        }
        for (var it3 of resData) {
          for (var it4 of that.optReason) {
            if (it3.categoryId == it4.id) {
              it4.children.push({
                id: it3.reasonId,
                name: it3.name,
                parentId: it3.categoryId,
                score1List: it3.score1List,
                score2List: it3.score2List,
                status: false,
                remark: null,
              })
            }
          }
        }
        that.optReason.forEach(item => {
          if (item.id == null) {
            item.id = '00000000000'
          }
        })
        that.optReasonReset = JSON.parse(JSON.stringify(that.optReason));
        that.fullscreenLoading = false;
      });
    }

    if (that.currentType == 1) {
      that.fullscreenLoading = true;
      // 获取班级数据
      that.allClass = [];
      ajaxGet(that, '/api/admin/college/normallinkage', null, function(resClass) {
        that.optClass = resClass;
        that.optClass.forEach(element => {
          element.value = element.collegeId;
          element.label = element.name;
          element.children = [];
          if (!regNullArray(element.classes)) {
            element.classes.forEach(chil => {
              element.children.push({
                value: chil.classId,
                label: chil.alias
              })
              that.allClass.push({
                collegeId: element.collegeId,
                value: chil.classId,
                label: chil.alias
              })
            })
          }
        })
      })
    }

    if (that.currentType == 2) {
      that.fullscreenLoading = true;
      ajaxGet(that, '/api/admin/building/checkin', null, function(resCheckin) {
        that.fullscreenLoading = false;
        if (!regNullArray(resCheckin)) {
          // 楼
          let tpBuilding = onArrUnique(resCheckin.map(item => ({
            value: item.buildingId,
            label: item.building,
            parentId: null,
          })));
          // 层
          let tpFloorArr = onArrUnique(resCheckin.map(item => ({
            value: item.floorId,
            label: item.floor + '（层）',
            parentId: item.buildingId,
            currentIndex: setInt(item.floor)
          }))).sort(function(a, b) {
            return a.currentIndex - b.currentIndex;
          });
          // 室
          let tpRoomArr = onArrUnique(resCheckin.map(item => ({
            value: item.roomId,
            label: item.room + '（室）',
            floor: item.floor + '（层）',
            floorId: item.floorId,
            building: item.building,
            buildingId: item.buildingId,
            parentId: item.floorId,
            currentIndex: setInt(item.room)
          }))).sort(function(a, b) {
            return a.currentIndex - b.currentIndex;
          });
          that.allRooms = JSON.parse(JSON.stringify(tpRoomArr));

          that.optRooms = onGetJsonToTree([...tpBuilding, ...tpFloorArr, ...tpRoomArr], 'value', 'parentId',
            'children');
          // 床位
          if (that.currentType == 2) {
            let tpBedArr = onArrUnique(resCheckin.map(item => ({
              value: item.bedId,
              label: '床号：' + item.name + "（" + item.studentName + "，" + item.className + "）",
              studentId: item.studentId,
              studentName: item.studentName,
              classId: item.classId,
              className: item.className,
              bed: item.name,
              room: item.room,
              floor: item.floor,
              building: item.building,
              photo: item.photo,
              parentId: item.roomId,
              bedId: item.bedId,
              currentIndex: setInt(item.name),
            }))).sort(function(a, b) {
              return a.currentIndex - b.currentIndex;
            });
            that.optBeds = tpBedArr;
          }
        }
      });
    }
    if (that.currentType == 3) {
      that.optStudent = [];
      that.fullscreenLoading = true;
      ajaxGet(that, '/api/admin/class/linkage', {
        status: 1
      }, function(resStudent) {
        that.optStudent = resStudent.map(item => ({
          label: item.alias,
          value: item.classId,
          children: item.students.map(item => ({
            label: item.name+'-'+that.givenNamePinyin(item.name),
            value: item.studentId,
          }))
        }));
      that.fullscreenLoading = false;
        that.allStudent = that.optStudent;
      })
    }
    that.ruleForm.logDate = new Date();
    that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
  }
</script>

<style scoped="scoped" lang="less">
  .studentBox {
    background: #e2e2e2;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
  }

  .operateCol {
    margin: 10px 0;
  }

  .operateDivTag {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    flex-wrap: wrap;
    text-align: center;
    margin-top: 20px;

    .operateTag {
      cursor: pointer;
    }
  }

  .divDialogReasonBox {
    .divCheckNum {
      margin-top: -20px;
      margin-bottom: 15px;

      b {
        color: #E6A23C;
        margin: 0 5px;
      }
    }

    .tabBody {
      padding: 5px;

      .listBox {
        color: #909199;

        &:hover {
          color: #303133;
          cursor: pointer;
        }
      }

      .actList {
        color: #409EFF;

        &:hover {
          color: #409EFF;
          cursor: pointer;
        }
      }
    }

  }

  .nav-item {
    padding: 0 10px;
  }

  .divDialogBox {
    margin: 0 auto;
    width: 80%;
  }

  .scoreBox .spLabel {
    padding-left: 2px;
    padding-right: 2px;
    font-weight: bolder;
  }

  .scoreBox .itemList {
    padding-top: 5px;
    padding-bottom: 5px;
  }


  .stuInfo {
    position: relative;
    height: 100%;
    min-height: 80px;
    margin: 15px;
  }

  .stuInfo .stuInfoBox {
    display: flex;
  }

  .stuInfo .divInfoBox {
    padding-left: 10px;
  }


  .stuInfo .stuImgCont {
    width: 70px;
    height: 70px;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #DDDDDD;
  }



  .stuInfo .stuName {
    color: #212E48;
    font-size: 18px;
    font-weight: bold;
  }

  .stuInfo .stuRole {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
  }

  .stuInfo .stuRoom {
    font-size: 12px;
    color: #767676;
  }

  .stuInfo .stuOther {
    color: #767676;
    font-size: 12px;
  }

  .stuInfo .stuRemove {
    display: none;
  }

  .stuInfo:hover .stuRemove {
    display: block;
    position: absolute;
    top: -0.9375rem;
    left: -0.625rem;
    font-size: 22px;
    cursor: pointer;
  }


  .transferBox {
    margin: 0 auto;
  }

  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }


  .tabListCont .divNavTitle .divTitleCont {
    padding: 10px 0px 10px 10px;
    color: #DEE2E6;
    cursor: pointer;
    font-size: 18px;
  }

  .tabListCont .divNavTitle .divTitleCont .spNum {
    background-color: #DEE2E6;
    font-family: 'OswaldLight';
    padding: 2px 5px;
    border-radius: 4px;
    margin-left: 5px;
    font-size: 14px;
    color: #FFFFFF;
  }

  .tabListCont .divNavTitle .divTitleAct {
    color: #3e3e3e;
  }

  .tabListCont .divNavTitle .divTitleAct .spNum {
    background-color: #17A2B8;
    color: #FFFFFF;
  }

  .tabListCont .divNavTitle .nav-link {
    font-size: 14px;
    color: #B1B1B1;
  }

  .tabListCont .divNavTitle .nav-link .spNum {
    font-family: 'OswaldLight';
    font-weight: normal;
    font-size: 14px;
    padding-left: 10px;
  }

  .tabListCont .divNavTitle .active {
    color: #007bff;
  }

  .divAlertCont {
    padding-left: 30px;
    padding-top: 10px;
    color: #9595A0;
  }
</style>

<style>
  .transferCont .el-transfer__buttons {
    width: 20%;
  }

  .transferCont .el-transfer__buttons .el-transfer__button:first-child {
    float: left;
  }

  .transferCont .el-transfer__buttons .el-transfer__button:last-child {
    float: right;
  }

  .transferCont .el-transfer-panel {
    width: 40%;
  }

  .formGroupCont .el-date-editor--datetimerange.el-input__inner {
    width: 100%;
  }

  .stuInfo .stuImgCont .image-slot {
    font-size: 28px;
    color: #DDDDDD;
  }

  .navTabsCont .nav-link {
    font-size: 12px;
  }

  .navToolsCont {
    margin-top: 1px;
  }

  .dialog-footer-operate {
    text-align: center;
  }

  .colOperate {
    margin-bottom: 20px;
  }
</style>
