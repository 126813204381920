<!--
  系統管理 - 数据字典 - 新增
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{Title}}
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="奖励类型" prop="name">
                <el-input v-model="ruleForm.name" placeholder="请输入奖励类型"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="优先级" prop="priority">
                <el-input @change="onPriorityChange()" v-model="ruleForm.priority" placeholder="请输入优先级"></el-input>
              </el-form-item>
            </el-col>

          </el-row>
        </div>
      </el-card>
      <div v-if="configs.length > 0">
        <comLiteracyConfig  :configList='configs' :defaultConfig ='defaultConfigs' ref="comLiteracyConfig" @change="handleTypesChange">
        </comLiteracyConfig>
      </div>
      <!-- <div v-if="configsClass.length > 0">
        <comLiteracyConfig  :configList='configsClass' :defaultConfig ='defaultConfigs' ref="comLiteracyConfig2" :title="'班级评价类型'" @change="handleTypesChange">
        </comLiteracyConfig>
      </div>      -->
      <div class="row" style="margin-top: 10px;">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
          <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
          <el-button type="primary" icon="el-icon-circle-check" @click="onSave()">提交</el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comLiteracyConfig from '@/components/Admin/LiteracyConfigScore.vue';
  export default {
    components:{
      comLiteracyConfig
    },
    data() {
      return {
        defaultConfigs:[],
        //综合类型
        configs:[],
        //班级评价类型
        configsClass:[],
        ruleForm: //表单数据
        {
          typeId: null, //奖励类型主键，新增时为null
          name: null, //奖励类型
          priority: null, //优先级
          configs: [],
        },
        ruleFormReset: null, //重置时的表单数据
        Title: "新增奖励类型",
        fullscreenLoading: false,
        rules: {
          name: [{
              required: true,
              message: '奖励类型不能为空',
              trigger: 'change'
            },
            {
              max: 128,
              message: '奖励类型不能超过128个字符',
              trigger: 'change'
            },
          ],
          priority: [{
            validator: (rule, value, callback) => {
              let that = this;
              var reg = /^-?[1-9]\d*$/;
              if (value != 0 && !reg.exec(value)) {
                callback(new Error("优先级只能输入整数！"));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }],
        },
      };
    },
    created() {
      let that = this;
      let id = that.$route.params.id;
      var detailUrl = null;
      if (id != null && typeof(id) != "undefined" && id != "") {
        that.Title = "编辑奖励类型";
        that.ruleForm.reasonId = id;
        detailUrl = "/api/admin/prizeType/" + id;
      }

      that.fullscreenLoading = true;
      ajaxGet(that, detailUrl, null, function(r) {
        if (!regNull(r)) {
          that.ruleForm.typeId = r.typeId;
          that.ruleForm.name = r.name;
          that.ruleForm.priority = r.priority;
          that.ruleForm.configs = r.configs;
          that.defaultConfigs = r.configs;
        }

        that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));

        ajaxGet(that, "/api/admin/GrowthConfig", null, function(r1) {
          that.fullscreenLoading = false;
          that.configs = r1.filter(val => {return val.configType == 1});
          // that.configsClass = r1.filter(val => {return val.configType == 2});
        });
      });
    },
    methods: {
      handleTypesChange(objItem, objValID) {
        let that = this;
        if (!regNull(objItem)) {
          if (regNull(that.ruleForm.configs)) {
            that.ruleForm.configs.push(objItem);
          } else {
            let tpInfo = that.ruleForm.configs.find(item => item.configId == objItem.configId);
            if (regNull(tpInfo)) {
              that.ruleForm.configs.push(objItem);
            } else {
              that.ruleForm.configs.forEach((item) => {
                if (item.configId == objItem.configId) {
                  item.score = objItem.score;
                }
              });
            }
          }
        } else if (!regNull(objValID)) {
          that.ruleForm.configs = that.ruleForm.configs.filter(item => item.configId != objValID);
        }
      },
      onReset() {
        let that = this;
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
        that.$refs.comLiteracyConfig.onReset();
        // that.$refs.comLiteracyConfig2.onReset();
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      onPriorityChange() {
        let that = this;
        var value = setInt(that.ruleForm.priority);
        that.ruleForm.priority = value;
      },
      onSave() //保存（新增和编辑）
      {
        let that = this;
        if (that.ruleForm.priority == null || that.ruleForm.priority == "") {
          that.ruleForm.priority = "0"
        }

        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            if (that.ruleForm.typeId == null) {
              that.fullscreenLoading = true;
              ajaxPost(that, "/api/admin/prizeType", that.ruleForm, function(r) {
                that.fullscreenLoading = false;
                that.onBack();
              });
            } else {
              that.fullscreenLoading = true;
              ajaxPut(that, "/api/admin/prizeType/" + that.ruleForm.typeId, that.ruleForm, function(r) {
                that.fullscreenLoading = false;
                that.onBack();
              });
            }
          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              isError[0].querySelector('input').focus();
            }, 1)
            return false;
          }
        });
      },
      onBack() //返回
      {
        let that = this;
        back(that);
      },

    }
  };
</script>

<style scoped="scoped">
  .rowConfig {
    display: flex;
    flex-wrap: wrap;
    /* background-color: gainsboro; */
  }

  .colConfig {

    /* border: 1px solid black; */
  }

  label {
    margin-bottom: 0;
  }

  .spIcon {
    width: 5px;
    height: 20px;
    border-radius: 5px;
    background-image: linear-gradient(#4545FF, #2D8EFF);
  }

  .spTitle {
    float: left;
    margin-left: 20px;
    color: #323E56;
    letter-spacing: 2px;
    font-size: 16px;
    margin-top: -3px;
  }
</style>
