<!--
  数据字典 - 组织管理
-->
<template>
  <div class="SubWebPage"  v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          组织管理
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <search :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null"></search>
            </li>
            <li class="nav-item">
              <el-button v-if="isPower.organization_create == true" type="success" icon="el-icon-circle-plus-outline"  @click="onCreate()">新增</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table :data="itemData" stripe class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="name" label="组织名称" class="elColumn"></el-table-column>
          <el-table-column label="操作" width="250">
            <template slot-scope="scope">
            <!--  <operationBtn v-if="isPower.organization_details == true" :btnName="'查看'" @click.native="onDetail(scope.row.organizationId)"></operationBtn> -->
                <operationBtn v-if="isPower.organization_edit == true" :btnName="'编辑'" @click.native="onEdit(scope.row.organizationId)"></operationBtn>
                <operationBtn v-if="isPower.organization_delete == true" :btnName="'删除'" @click.native="onDelete(scope.row.organizationId)"></operationBtn>
              </template>
          </el-table-column>
        </el-table>
        <comPage
          :paging="page"
          :pageBtnList="pageBtnList"
          @pagingClick="pagingClick"
          @pageBtnClick="pageBtnClick"
        ></comPage>
      </div>

      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>

    <el-dialog :title="dialogTitle"
    :visible.sync="dialogVisible"
     width="30%"
    :close-on-click-model="false"
    :close-on-press-escape="false"
    :append-to-body="true"
    :fullscreen="false"
    >
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="90px">
      	<el-row v-if="isDetail!=true">
      		<el-col :span="24">
      			<el-form-item label="组织名称" prop="name">
      				<el-input v-model="ruleForm.name" placeholder="请输入组织名称"></el-input>
      			</el-form-item>
      		</el-col>
      	</el-row>
      	<el-row v-else>
      		<el-col :span="24">
      			<el-form-item label="组织名称">
      				{{ruleForm.name}}
      			</el-form-item>
      		</el-col>
      	</el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
      	<div v-if="isDetail!=true">
      		<el-button @click="dialogVisible = false">取 消</el-button>
      		<el-button @click="onReset()">重 置</el-button>
      		<el-button type="primary" @click="onSave()">确 定</el-button>
      	</div>
      	<div v-else>
      		<el-button @click="dialogVisible = false">关 闭</el-button>
      	</div>
      </span>
    </el-dialog>


  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';

  export default{
    components:{
      comPage,operationBtn,search
    },
    data(){
      return{
        //加载动画
        fullscreenLoading:false,
        //搜索
        SearchList:null,
        //分页查询接口返回的结果
        page:null,
        //待重置时的表单数据
        ruleFormReset:null,
        //列表数据
        itemData:[],
        //分页左侧按钮集合
        pageBtnList:null,
        //分页数据
        paging: {
          // 总共页数
        	pageLength: 0,
          // 每页请求的数据量
        	Size: 1,
           //当前在第几页
        	Index: 1
        },
        dialogVisible:false,
        dialogTitle:"新增",//弹出框标题
        isDetail:false, //是否是查看弹出框
        ruleForm:{
        	name:null,//组织名称
        },
        rules: {
        	name: [
        		{ required: true, message: '组织名称不能为空', trigger: 'change' },
        		{ max: 64,message: '组织名称不能超过64个字符', trigger: 'change' },
        	],
        },
        isPower: {
        		//增删改查 权限管理
        		organization_details: false,
        		organization_create: false,
        		organization_edit: false,
        		organization_delete: false,
        },
      };
    },
    methods:{
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },
      onSave()//保存（新增和编辑）
      {
      	let that = this;
      	that.$refs["ruleForm"].validate(valid => {
      			if (valid) {
      				if(that.ruleForm.organizationId == null)
      				{
      					that.fullscreenLoading = true;
      					ajaxPost(that,"/api/admin/organization",that.ruleForm,function(r){
      						that.fullscreenLoading = false;
      						that.dialogVisible = false;
      						pagingPage(that);
      					});
      				}
      				else{
      					that.fullscreenLoading = true;
      					ajaxPut(that,"/api/admin/organization/" + that.ruleForm.organizationId,that.ruleForm,function(r){

      						that.fullscreenLoading = false;
      						that.dialogVisible = false;
      						pagingPage(that);
      					});
      				}
      			}
      			else {

      					setTimeout(() => {
      							var isError = document.getElementsByClassName("is-error");
      							isError[0].querySelector('input').focus();
      					}, 1)
      					return false;
      			}
      	});
      },
      onCreate()//新增
      {
       let that = this;
       	that.dialogTitle = "新增";
       	var ruleForm = JSON.parse(JSON.stringify(that.ruleForm));
       	for(var i in ruleForm)
       	{
       	  ruleForm[i] = null;
       	}
       	that.ruleForm = JSON.parse(JSON.stringify(ruleForm));
       	that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
       	that.dialogVisible = true;
       	that.isDetail = false;
       	try
       	{
       		that.$refs["ruleForm"].resetFields();
       	} catch{}
       },
      onDetail(Id)//查看
      {
       	let that = this;
       	that.fullscreenLoading = true;
       	that.isDetail = true;
       	ajaxGet(that,"/api/admin/organization/" + Id,null,function(r){
       		that.fullscreenLoading = false;
       		that.dialogTitle = "查看";
       		that.ruleForm = r;
       		that.dialogVisible = true;
       	});
      },
      onEdit(Id)//编辑
      {
       	let that = this;
       	that.fullscreenLoading = true;
       	that.isDetail = false;
       	ajaxGet(that,"/api/admin/organization/" + Id,null,function(r){
       		that.fullscreenLoading = false;
       		that.dialogTitle = "编辑";
       		that.ruleForm = r;
       		that.dialogVisible = true;
       		that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
       	});
      },
      onSearch(params)// 搜索
      {
      	let _this = this;
      	let data = {};
      	let searchData = params;
      	searchData.forEach(element => {
      		if (element.data) {
      			data[element.zhi] = element.data;
      		}
      	});
      	data.PageNumer = 1;

      	routerPath(_this, "/Admin/DataDictionary/Tissue", data, null, "PageSize");
      },
      onReset() //重置
      {
      	let that = this;
      	this.ruleForm = JSON.parse(JSON.stringify(this.ruleFormReset));
      	try
      	{
      		that.$refs["ruleForm"].resetFields();
      	} catch{}
      },
      onDelete(Id) //删除
      {
      	let that = this;
      	confirmDelete(that,null,function(res){
      		if(res == true)
      		{
      			that.fullscreenLoading = true;
      			ajaxDelete(that,"/api/admin/organization/" + Id,null,function(r){
      				that.fullscreenLoading = false;
      				pagingPage(that);
      			});
      		}
      	})
      },
      pageBtnClick(index) {
      	//分页组件左侧的按钮事件，按顺序进行判断
      },
      // 分页面按钮
      pagingClick(type, val)
      {
      	let that = this;
      	let url = "/Admin/DataDictionary/Tissue";
      	if (type == 1) {//更改每页条数触发的事件
      		routerPath(
      			that,
      			url,
      			"PageSize",
      			val,
      			"PageNumer",
      			"Name",
      		);
      	} else { //更改当前页时触发的事件
      		routerPath(
      			that,
      			url,
      			"PageNumer",
      			val,
      			"PageSize",
      			"Name",
      		);
      	}
      }
    },
    created()
    {
      let that = this;
      isPower(that, "organization_details","organization_details");
      isPower(that, "organization_create","organization_create");
      isPower(that, "organization_edit","organization_edit");
      isPower(that, "organization_delete","organization_delete");
      pagingPage(that);
    },
    watch: {
    	$route() {
    		let that = this;
    		pagingPage(that);
    	}
    }
  };

  function pagingPage(that) {
  	  paging(
  		  that,
  		  function (r) {
  			  let apiUrl = "/api/admin/organization/search";
  			  let data = {
  				  PageNumer: r.PageNumer,
  				  PageSize: r.PageSize,
  				  Name: r.Name,
  			  };
  			  that.SearchList = [
  				  {
  					  type: "input",
  					  Name: "组织名称",
  					  data: r.Name,
  					  holder: "请输入组织名称关键字",
  					  zhi: "Name"
  				  },
  			  ];
          that.paging.Index=r.PageNumer;
          that.paging.Size=r.PageSize;
  			  that.fullscreenLoading = true;
  			  ajaxGet(that, apiUrl, data, function (r) {
  				  that.page = r;
  				  that.itemData = r.items;
  				  that.fullscreenLoading = false;
  			  });
  		  },
  		  "Name",
  	  );
  }
</script>

<style scoped="scoped">

</style>
