<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <search :ColNumber="2" :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null">
    </search>
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          安全考核奖
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <el-button type="success" size="mini" icon="el-icon-circle-plus-outline" @click="onCreate"
                v-if="isPower.admin_safetyaward_create">新增</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table stripe :data="itemData" class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="100" :index="indexMethod"></el-table-column>
          <el-table-column prop="year" label="学年" min-width="140"></el-table-column>
          <el-table-column prop="className" label="班级" min-width="140"></el-table-column>
          <el-table-column prop="teacher" label="班主任" min-width="140"></el-table-column>
          <el-table-column prop="bonus" label="奖励金额" min-width="140"></el-table-column>
          <el-table-column prop="user" label="记录人" min-width="140"></el-table-column>
          <el-table-column label="操作" width="200" fixed="right">
            <template slot-scope="scope">
              <operationBtn :btnName="'查看'" @click.native="onDetail(scope.row)" v-if="isPower.admin_safetyaward_detail"></operationBtn>
              &nbsp;
              <el-dropdown v-if="isPower.admin_safetyaward_edit || isPower.admin_safetyaward_delete" trigger="click"
                @command="handleCommand($event,scope.row)">
                <el-button size="mini">
                  更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="isPower.admin_safetyaward_edit" class="color-warning"
                    command="onEdit">编辑</el-dropdown-item>
                  <el-dropdown-item divided v-if="isPower.admin_safetyaward_delete" class="color-danger"
                    command="onDelete">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>

    <el-dialog :title="ruleForm.assessmentId!=null ? '编辑' : '新增'" :visible.sync="dialogVisible" width="50%"
      :close-on-click-modal="false" :close-on-press-escape="false" :append-to-body="true" :fullscreen="false"
      :before-close="handleClose">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" v-if="isUpdate">
        <el-row :gutter="20">
          <el-col :span="23">
            <el-form-item label="学年" prop="yearId">
              <el-select v-model="ruleForm.yearId" placeholder="请选择学年" class="width_100Pie">
                <el-option v-for="item in optYear" :key="item.yearId" :label="item.name" :value="item.yearId">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="23">
            <el-form-item label="班级" prop="classId">
              <el-select v-model="ruleForm.classId" placeholder="请选择班级" class="width_100Pie" @change="onChangeClass">
                <el-option v-for="item in optClass" :key="item.classId" :label="item.name" :value="item.classId">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="23">
            <el-form-item label="班主任">
              <el-input v-model="ruleForm.teacher" :disabled="true" class="width_100Pie">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="23">
            <el-form-item label="奖励金额" prop="bonus">
              <el-input v-model="ruleForm.bonus" placeholder="请输入奖励金额" class="width_100Pie"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="row" v-else>
        <div class="col-lg-6 colInfo">
          <label>学年</label>
          <div class="colFonts">
            {{ruleForm.year | nullData}}
          </div>
        </div>
        <div class="col-lg-6 colInfo">
          <label>班级</label>
          <div class="colFonts">
            {{ruleForm.className | nullData}}
          </div>
        </div>
        <div class="col-lg-6 colInfo">
          <label>班主任</label>
          <div class="colFonts">
            {{ruleForm.teacher | nullData}}
          </div>
        </div>
        <div class="col-lg-6 colInfo">
          <label>奖励金额</label>
          <div class="colFonts">
            {{ruleForm.bonus | nullData}}
          </div>
        </div>
        <div class="col-lg-6 colInfo">
          <label>记录人</label>
          <div class="colFonts">
            {{ruleForm.user | nullData}}
          </div>
        </div>
        <div class="col-lg-6 colInfo">
          <label>创建时间</label>
          <div class="colFonts">
            {{ruleForm.createDate | dateformatMinute}}
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer ">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="onSave()" v-if="isUpdate">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';

  export default {
    components: {
      comPage,
      operationBtn,
      search
    },
    data() {
      return {
        fullscreenLoading: false,
        dialogTitle: '',
        dialogVisible: false,
        page: null, //分页查询接口返回的结果
        itemData: [], //列表数据
        pageBtnList: null, //分页左侧按钮集合
        paging: { //分页数据
          pageLength: 0, // 总共页数
          Size: 10, // 当前请求数量
          Index: 1 //当前在第几页
        },
        SearchList: null, //搜索集合
        isPower: {
          admin_safetyaward_edit: false,
          admin_safetyaward_detail: false,
          admin_safetyaward_create: false,
          admin_safetyaward_delete: false,
        },
        ruleForm: {
          assessmentId: null,
          classId: null,
          teacherId: null,
          bonus: null,
          yearId: null,
        },
        ruleFormReset: null,
        rules: {
          bonus: [{
            required: true,
            message: '请输入奖励金额',
            trigger: 'change'
          }, {
            validator: (rule, value, callback) => {
              let _this = this;
              var reg = /^(\+)?\d+(\.\d{1})?$/;
              if (value != 0 && !reg.exec(value)) {
                callback(new Error("奖励金额只能输入正数且小数最多带1位"));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }],
          yearId: [{
            required: true,
            message: '请选择学年',
            trigger: 'change'
          }, ],
          classId: [{
            required: true,
            message: '请选择班级',
            trigger: 'change'
          }, ],
        },


        optYear: [],
        optClass: [],
        isUpdate: true,
      };
    },
    created() {
      let that = this;
      isPower(that, 'admin_safetyaward_edit', 'admin_safetyaward_edit');
      isPower(that, 'admin_safetyaward_detail', 'admin_safetyaward_detail');
      isPower(that, 'admin_safetyaward_create', 'admin_safetyaward_create');
      isPower(that, 'admin_safetyaward_delete', 'admin_safetyaward_delete');
      pagingPage(that);
    },
    methods: {
      /**
       * @description 更多操作
       * @param {Object} command
       * @param {Object} objItem
       */
      handleCommand(command, objItem) {
        let that = this;
        switch (command) {
          case 'onEdit':
            that.onEdit(objItem);
            break;
          case 'onDelete':
            that.onDelete(objItem.assessmentId);
            break;
        }
      },
      /**
       * @description 弹窗关闭
       * @param {Object} done
       */
      handleClose() {
        let that = this;
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
        that.dialogVisible = false;
        that.$refs.ruleForm.clearValidate();
        that.$refs['ruleForm'].resetFields();
      },
      /**
       * @description
       */
      onSave() {
        let that = this;
        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            that.fullscreenLoading = true;
            if (regNull(that.ruleForm.assessmentId)) {
              ajaxPost(that, '/api/admin/adviseryearsafetyassessment', that.ruleForm, function(res) {
                that.fullscreenLoading = false;
                that.handleClose();
                pagingPage(that);
              });
            } else {
              ajaxPut(that, '/api/admin/adviseryearsafetyassessment/' + that.ruleForm.assessmentId, that.ruleForm,
                function(res) {
                  that.fullscreenLoading = false;
                  that.handleClose();
                  pagingPage(that);
                });
            }
          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              isError[0].querySelector('input').focus();
            }, 1);
            return false;
          }
        });
      },
      onEdit(objItem) {
        let that = this;
        that.ruleForm = objItem;
        that.isUpdate = true;
        that.dialogVisible = true;
      },
      /**
       * @description 班级选择事件
       * @param {Object} objVal
       */
      onChangeClass(objVal) {
        let that = this,
          temp = that.optClass.find(item => item.classId === objVal);
        if (temp != undefined) {
          that.ruleForm.teacher = temp.teacher;
          that.ruleForm.teacherId = temp.teacherId;
        }
      },
      /**
       * @description 获取班级数据
       */
      onGetClass(that) {
        ajaxGet(that, '/api/admin/class', null, function(resData) {
          that.optClass = resData;
        });
      },
      /**
       * @description 新增
       */
      onCreate() {
        let that = this;
        that.isUpdate = true;
        that.dialogVisible = true;
      },
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },

      /**
       * @description 详情
       * @param {Object} Id
       */
      onDetail(objItem) {
        let that = this;
        that.isUpdate = false;
        that.ruleForm = objItem;
        that.dialogVisible = true;
      },
      /**
       * @description 删除
       * @param {Object} objID
       */
      onDelete(objID) {
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, "/api/admin/adviseryearsafetyassessment/" + objID, null, function(r) {
              that.fullscreenLoading = false;
              pagingPage(that);
            });
          }
        })

      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      onSearch(params) {
        let that = this,
          data = {},
          searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = Join(element.data);
          }
        });
        data.PageNumer = 1;
        routerPath(that, "/Admin/SafetyAward/Index", data, null, "PageSize");
      },
      pagingClick(type, val) {
        let that = this;
        let url = '/Admin/SafetyAward/Index';
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "yearId",
            "teacher",
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "yearId",
            "teacher",
          );
        }
      },
    },
    watch: {
      $route() {
        let that = this;
        pagingPage(that);
      }
    }
  };

  function pagingPage(that) {
    paging(
      that,
      function(res) {
        ajaxGet(that, '/api/admin/academic', null, function(resacademic) {
          that.onGetClass(that);

          that.optYear = resacademic;
          let yearlist = [];
          for (var it of resacademic) {
            yearlist.push({
              Value: it.yearId,
              Title: it.name,
            })
          }
          let yearId = res.yearId;
          that.SearchList = [];
          that.SearchList.push({
            type: "select",
            Name: "学年",
            data: yearId,
            select: yearlist,
            zhi: "yearId",
          }, {
            type: "input",
            Name: "班主任",
            data: res.teacher,
            holder: "请输入班主任姓名",
            zhi: "teacher"
          });

          let tempData = {
            PageNumer: res.PageNumer,
            PageSize: res.PageSize,
            YearId: res.yearId,
            TeacherName: res.teacher,
          }
          that.itemData = [];
          ajaxGet(that, '/api/admin/adviseryearsafetyassessment/search', tempData, function(resData) {
            that.fullscreenLoading = false;
            if (!regNullArray(resData.items)) {
              that.page = resData;
              that.itemData = resData.items;
            }
            that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
          });
        })
      },
      "yearId",
      "teacher",
    );
  }
</script>

<style scoped="scoped">
</style>
