<!--
  日常事务 - 日常动态管理 - 行为规范
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">

    <el-form :model="ruleForm" ref="ruleForm" label-width="100px">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            查看处分撤销记录
          </h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-3 colInfo">
              <label>班级名称</label>
              <div class="colFonts">{{onNullData(ruleForm.className)}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>姓名</label>
              <div class="colFonts">{{ruleForm.studentName}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>学号</label>
              <div class="colFonts">{{ruleForm.studentNo}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>撤销申请时间</label>
              <div class="colFonts">{{onNullData(ruleForm.applyDate)|dateformat("YYYY-MM-DD")}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>操作人</label>
              <div class="colFonts">{{onNullData(ruleForm.user)}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>撤销时间</label>
              <div class="colFonts">{{onNullData(ruleForm.revokeDate)|dateformat("YYYY-MM-DD")}}</div>
            </div>
            <div class="col-lg-12 colInfo">
              <label>撤销原因</label>
              <div class="colFonts">{{onNullData(ruleForm.reason)}}</div>
            </div>
          </div>
        </div>
      </el-card>
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            学生处分历史记录
          </h3>
        </div>
        <div class="card-body p-0">
          <el-table stripe :data="studentPunishData">
            <el-table-column type="index" prop="index" label="序号"></el-table-column>
            <el-table-column prop="punishNo" label="处分号"></el-table-column>
            <el-table-column prop="reason" label="处分原因"></el-table-column>
            <el-table-column prop="type" label="处分类型"></el-table-column>
            <el-table-column prop="punishDate" label="处分时间">
              <template slot-scope="scope">
                {{scope.row.punishDate | dateformatDay}}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-card>
      <el-card class="box-card" v-if="uploadFiles!=null && uploadFiles.length!=0">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            附件
          </h3>
        </div>
        <div class="card-body">
          <el-carousel height="500px" :autoplay="!showViewer">
            <el-carousel-item v-for="(item,index) in uploadFiles" :key="index" class="VerticalMid">
              <img :src="item" @click="onPreview">
            </el-carousel-item>
          </el-carousel>
        </div>
      </el-card>
    </el-form>

    <div class="row" style="margin-top: 10px;">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
      </div>
    </div>

    <!--配合走马灯效果放大图片组件-->
    <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="uploadFiles" />

  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import ElImageViewer from 'element-ui/packages/image/src/image-viewer';

  export default {
    components: {
      ElImageViewer
    },
    data() {
      return {
        showViewer: false,
        random: 0,
        fullscreenLoading: false,
        uploadFiles: [],
        ruleForm: {
          class: null,
          reasonName: null,
          remark: null,
          score: null,
          attachment: null,
          members: [],
        },
        PunishData: [],
        studentPunishData: [],
      };
    },
    created() {

      let that = this;
      var detailUrl = null;
      if (!regNull(that.$route.params.id)) {
        ajaxGet(that, "/api/admin/punishrevoke/" + that.$route.params.id, null, function(r) {
          that.ruleForm = r;
          that.ruleForm.reasonName = r.reason;
          that.uploadFiles = [];
          // 处理附件
          if (!regNull(r.attachment)) {
            var files = r.attachment.split('|');
            for (var i in files) {
              that.uploadFiles.push(that.$ServerUrl() + files[i]);
            }
            var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
            that.random += random;
          }


          let data = {
            Student: that.ruleForm.studentNo,
          }
          ajaxGet(that, '/api/admin/punish/search', data, function(resData) {
            // that.studentPunishData = resData.items;
            if (!regNullArray(resData.items)) {
              resData.items.forEach((item) => {
                if(compareDate(item.punishDate, that.ruleForm.revokeDate) || item.punishDate == that.ruleForm.revokeDate) {
                  that.studentPunishData.push({
                    punishNo: item.punishNo,
                    reason: item.reason,
                    punishDate: timeTransform(item.punishDate,'YYYY-MM-DD'),
                    type:item.members.find(list=> list.name==r.studentName).level
                  })
                }

              });
            }
            // var score = 0;
            // for (var i in that.PunishData) {
            //   for (var j in that.PunishData[i].members) {
            //     if (that.PunishData[i].members[j].name == r.name) {
            //       for (var m in that.PunishData[i].members[j].configs) {
            //         score += that.PunishData[i].members[j].configs[m].score;
            //       }
            //     }
            //   }
            //   that.studentPunishData.push({
            //     score: score,
            //     punishNo: that.PunishData[i].punishNo,
            //     reasonName: that.PunishData[i].reasonName,
            //     name: that.PunishData[i].level.name,
            //     punishDate: that.PunishData[i].punishDate,
            //   })

            // }
          })
          that.fullscreenLoading = false;

        });
      }
    },
    formatter(row, column) {
      let data = row[column.property]
      let dt = new Date(data)
      return dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate()
    },
    methods: {
      onGetDateFormat(_val) {
        return dateFormat(_val, '.', 'YYYY-MM-DD');
      },
      /**
       * @description 打开放大图片
       */
      onPreview() {
        this.showViewer = true
      },
      /**
       * @description 关闭图片预览图
       */
      closeViewer() {
        this.showViewer = false
      },
      /**
       * @description 返回上一页
       */
      onBack() {
        let that = this;
        back(that);
      },
      /**
       * @description 判断字段是否有数据
       * @param {Object} _val
       * @return {String} 没有数据返回‘暂无数据’
       */
      onNullData(_val) {
        return regNull(_val) ? '暂无数据' : _val;
      }
    }
  };
</script>

<style scoped="scoped">
  .stuInfo {
    position: relative;
    height: 100%;
    min-height: 80px;
    padding-left: 80px;
    margin: 15px;
  }

  .stuInfo .stuFraction {
    position: absolute;
    top: -5px;
    bottom: 0;
    right: -5px;
    font-family: 'OswaldLight';
    font-size: 26px;
    color: #28A745;
  }

  .stuInfo .stuBtnDown {
    position: absolute;
    bottom: 20px;
    right: 0;
  }

  .stuInfo .stuBtnDown .aView {
    margin-right: 5px;
    margin-left: 5px;
  }

  .stuInfo .stuBtnDown .aView .faxSize {
    font-size: 12px;
    color: #343a40;
  }

  .stuInfo .stuBtnDown .aView:hover .faxSize {
    color: #1666B3;
  }

  .stuInfo .stuImgCont {
    position: absolute;
    top: 0;
    left: 0;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
  }

  .stuInfo .stuImgCont img {
    width: 100%;
    padding-top: 10px;
  }

  .stuInfo .stuName {
    color: #212E48;
    font-size: 18px;
    font-weight: bold;
  }

  .stuInfo .stuRole {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
  }

  .stuRole .spStatus {
    font-size: 12px;
    color: #B1B1B1;
  }

  .stuInfo .stuOther {
    color: #767676;
    font-size: 12px;
  }

  .stuInfo .stuRemove {
    display: none;
  }

  .stuInfo:hover .stuRemove {
    display: block;
    position: absolute;
    top: 0rem;
    bottom: 0rem;
    right: 0rem;
    font-size: 22px;
    cursor: pointer;
  }


  .transferBox {
    margin: 0 auto;
  }

  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }

  .tabListCont .divNavTitle {}

  .tabListCont .divNavTitle {}

  .tabListCont .divNavTitle .divTitleCont {
    padding: 10px 0px 10px 10px;
    color: #DEE2E6;
    cursor: pointer;
    font-size: 18px;
  }

  .tabListCont .divNavTitle .divTitleCont .spNum {
    background-color: #DEE2E6;
    font-family: 'OswaldLight';
    padding: 2px 5px;
    border-radius: 4px;
    margin-left: 5px;
    font-size: 14px;
    color: #FFFFFF;
  }

  .tabListCont .divNavTitle .divTitleAct {
    color: #3e3e3e;
  }

  .tabListCont .divNavTitle .divTitleAct .spNum {
    background-color: #17A2B8;
    color: #FFFFFF;
  }

  .tabListCont .divNavTitle .nav-link {
    font-size: 14px;
    color: #B1B1B1;
  }

  .tabListCont .divNavTitle .nav-link .spNum {
    font-family: 'OswaldLight';
    font-weight: normal;
    font-size: 14px;
    padding-left: 10px;
  }

  .tabListCont .divNavTitle .active {
    color: #007bff;
  }

  .divAlertCont {
    padding-left: 30px;
    padding-top: 10px;
    color: #9595A0;
  }
</style>

<style>
  .transferCont .el-transfer__buttons {
    width: 20%;
  }

  .transferCont .el-transfer__buttons .el-transfer__button:first-child {
    float: left;
  }

  .transferCont .el-transfer__buttons .el-transfer__button:last-child {
    float: right;
  }

  .transferCont .el-transfer-panel {
    width: 40%;
  }

  .formGroupCont .el-date-editor--datetimerange.el-input__inner {
    width: 100%;
  }
</style>
