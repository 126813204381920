import { render, staticRenderFns } from "./DormitoryImplement.vue?vue&type=template&id=be6e56a6&scoped=true"
import script from "./DormitoryImplement.vue?vue&type=script&lang=js"
export * from "./DormitoryImplement.vue?vue&type=script&lang=js"
import style0 from "./DormitoryImplement.vue?vue&type=style&index=0&id=be6e56a6&prod&scoped=scoped&lang=css"
import style1 from "./DormitoryImplement.vue?vue&type=style&index=1&id=be6e56a6&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be6e56a6",
  null
  
)

export default component.exports