<template>
  <div class="bottom-right-table-1">
    <dv-border-box-12 class="dvBox">
      <div class="divTitle">
        学生评价动态
      </div>
      <div v-if="!regNullArray(optData)">
        <div class="divItemList" v-for="(item,index) in optData.slice(0,6)" :key="index">
          <div class="divAva ">
            <span class="VerticalMid" v-if="!regNull(item.photo)">
              <img :src="onImgSpliceUrl(item.photo)" alt="" width="100%">
            </span>
            <span class="VerticalMid" v-else>
              {{onGetSurname(item.studentName)}}
            </span>
          </div>
          <div class="divInfo">
            <div>
              <span class="spName">{{item.studentName}}</span>
              <span>
                {{item.className}}
              </span>
            </div>
            <div>
              {{item.reason}}
            </div>
          </div>
          <div class="divTime">
            {{item.logDate | flLogDate}}
          </div>
        </div>
      </div>
      <div class="divNullData" v-else>
        <dv-loading>Loading...</dv-loading>
      </div>
    </dv-border-box-12>
  </div>
</template>

<script>
  export default {
    name: 'BottomRightTable1',
    data() {
      return {
        optData: []
      }
    },
    methods: {
      /**
       * @description 加载数据
       * @param {Object} objData
       */
      onCreateData(objData) {
        let that = this;
        if (!regNullArray(objData)) {
          that.optData = JSON.parse(JSON.stringify(objData))
        }
      },
      onGetSurname(objItem) {
        return regNull(objItem) ? '' : objItem.substring(0, 1);
      }
    },
    filters: {
      flLogDate(objItem) {
        return regNull(objItem) ? timeTransform(new Date(), 'MM-DD') : timeTransform(objItem, 'MM-DD');
      }
    }
  }
</script>

<style lang="less" scoped>
  .divNullData {
    padding-top: 30%;
  }

  .divTitle {
    color: #FFF;
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 15px;
  }

  .divItemList {
    display: flex;
    position: relative;
    padding-top: 15px;
    padding-bottom: 15px;

    .divAva {
      span {
        color: #FFF;
        background-color: #2596b4;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        font-weight: bold;
        overflow: hidden;
      }
    }

    .divInfo {
      margin-left: 30px;
      width: calc(~"100% - 200px");
      ;

      .spName {
        margin-right: 5px;
      }
    }

    .divTime {
      position: absolute;
      top: 10px;
      right: 0;
      font-size: 14px;
    }
  }

  .dvBox {
    padding: 30px;
  }

  .bottom-right-table-1 {
    width: 100%;
    height: 100%;
  }
</style>
