<!--
  处分信息
-->
<template>
  <div class="SubWebPageStudent" v-loading.fullscreen.lock="fullscreenLoading">

    <!-- 搜索 -->
<!--    <comSearch :key="random" :ColNumber="2" :SearchList="SearchList" ref="SearchList" @onSearch="onSearch"
      v-if="SearchList!=null">
    </comSearch> -->
    <!-- 列表-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          处分信息
        </h3>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table :data="itemData" stripe class="elTableData">
          <el-table-column type="index" label="序号" width="80"></el-table-column>
          <el-table-column prop="punishNo" label="处分号" class="elColumn"></el-table-column>
          <el-table-column prop="reason" label="处分原因" class="elColumn"></el-table-column>
          <el-table-column prop="level" label="处分等级">
          </el-table-column>
          <el-table-column label="处分时间" width="190px">
            <template slot-scope="scope">
              <span>{{scope.row.punishDate | dateformat('YYYY-MM-DD')}}</span>
            </template>
          </el-table-column>
<!--          <el-table-column label="操作时间" width="190px">
            <template slot-scope="scope">
              <span>{{scope.row.lastUpdateDate | dateformat('YYYY-MM-DD')}}</span>
            </template>
          </el-table-column> -->
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <operationBtn  :btnName="'查看'"
                @click.native="onDetail(scope.row.id)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
<!--        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage> -->
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>

    </el-card>
  </div>
</template>

<script>
  import '@/assets/css/Student.css';
  // import comPage from '@/components/Page.vue';
  // import comSearch from '@/components/search.vue';
  import operationBtn from '@/components/operationBtn.vue';

  export default {
    components: {
      // comSearch,
      operationBtn,
      // comPage
    },
    data() {
      return {
        // 动画
        fullscreenLoading: false,
        // 搜索
        SearchList: null,
        //列表数据
        itemData: [],
        //分页查询接口返回的结果
        page: null,
        //分页左侧按钮集合
        pageBtnList: null,
        //分页数据
        paging: {
          // 总共页数
          pageLength: 0,
          // 每页请求的数据量
          Size: 1,
          //当前在第几页
          Index: 1
        },
        // isPower: {
        //   //增删改查 权限管理
        //   punish_detail: false,
        // },
      };
    },
    methods: {
      onDetail(Id) {
        let that = this;
        that.$router.push({
          path: "/Student/Punish/Details/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
    },
    created() {
      let that = this;
      // isPower(that, "punish_detail", "punish_detail");
      onGetDataPage(that);
    },
    mounted() {

    },
    watch: {
      $route() {
        let that = this;
        onGetDataPage(that);
      }
    }
  };

  function onGetDataPage(that) {
    // paging(
    //   that,
    //   function(res) {
        let apiUrl = "/api/student/punish/search";
        that.fullscreenLoading = true;
        // that.SearchList = [{
        //     type: "time",
        //     Name: "处分时间",
        //     data: res.PunishDate,
        //     zhi: "PunishDate"
        //   },
        //   {
        //     type: "input",
        //     Name: "处分原因",
        //     data: res.Reason,
        //     holder: "请输入处分原因",
        //     zhi: "Reason"
        //   },
        // ];
        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;


        // let data = {
        //   PageNumer: res.PageNumer,
        //   PageSize: res.PageSize,
        //   PunishDate: res.PunishDate,
        //   Reason: res.Reason,
        // };

        ajaxGet(that, apiUrl, null, function(resData) {

          that.page = resData;
          that.itemData = resData.items;
          that.fullscreenLoading = false;
        });
      // },
    //   "PunishDate",
    //   "Reason"
    // );
  }
</script>

<style scoped="scoped">

</style>
