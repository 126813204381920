<!--
  社团职位管理
-->
<template>
  <div class="SubWebPageStudent" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          我的社团信息
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <el-button type="success" icon="" size="mini" @click="onViewMore()">浏览所有社团活动</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table :data="itemData" stripe class="elTableData">
          <el-table-column type="index" label="序号" width="130"></el-table-column>
          <el-table-column prop="name" label="社团名称" min-width="300"></el-table-column>
          <el-table-column prop="number" label="社团人数" min-width="120"></el-table-column>
          <el-table-column prop="studentname" label="社团最高负责人" min-width="160"></el-table-column>
          <el-table-column label="操作" width="260" fixed="right">
            <template slot-scope="scope">
              <operationBtn :btnName="'查看'" @click.native="onDetail(scope.row.clubId)" :btnType="'primary'">
              </operationBtn>
              <operationBtn :btnName="'新增活动'" @click.native="onCreate(scope.row.clubId)" :btnType="'success'"
                v-if="scope.row.isPower.isDirector || scope.row.isPower.isManager"></operationBtn>
              <comImport @onImportSuccess="onImportSuccess" :template="'/templates/社团成员导入模版.xlsx'"
                :path="onGetApi(scope.row.clubId)" :btnSize="'mini'" :btnClass="'clubBtnImport'" :btnIcon="''"
                :title="'批量导入'" :btnName="'导入'" ref="conImportInfoFile" v-if="scope.row.isPower.isDirector">
              </comImport>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>


  </div>
</template>

<script>
  import '@/assets/css/Student.css';
  import operationBtn from '@/components/operationBtn.vue';
  import comImport from '@/components/Import.vue';

  export default {
    components: {
      operationBtn,

      comImport,
    },
    data() {
      return {
        //加载动画
        fullscreenLoading: false,
        //搜索
        SearchList: null,

        //列表数据
        itemData: [],

        // 上传学生信息接口
        uploadInfoApi: '/api/student/club/clubmember/',


      };
    },
    methods: {
      onViewMore() {
        let _this = this;
        _this.$router.push({
          path: '/Student/Club/ClubActivities/Index',
          query: {
            url: _this.$router.history.current.fullPath
          }
        });
      },

      /**
       * @description 新增社团活动
       * @param {Object} _clubId
       */
      onCreate(_clubId) {
        let _this = this;
        _this.$router.push({
          path: '/Student/Club/ClubActivities/Create/' + _clubId,
          query: {
            url: _this.$router.history.current.fullPath
          }
        });
      },

      /**
       * @description 生成API地址
       * @param {Object} _dataID
       * @return {String} 组合成的新Api接口地址
       */
      onGetApi(_dataID) {
        return this.uploadInfoApi + _dataID;
      },

      /**
       * @description 导入名单
       * @param {Object} param
       */
      onImportSuccess(param) {
        let _this = this;
        onGetData(_this);
      },

      /**
       * @description 查看详情
       * @param {Object} Id
       */
      onDetail(Id) {
        let _this = this;
        _this.$router.push({
          path: '/Student/Club/ClubMGMT/Details/' + Id,
          query: {
            url: _this.$router.history.current.fullPath
          }
        });
      },

      /**
       * @description 获取社团最高负责人
       * @param {Object} _arrData
       * @return {String} 社团最高负责人的姓名和学号
       */
      onGetAdminName(_arrData) {
        for (let item of _arrData) {
          if (item.isDirector) {
            return item.studentName + '（' + item.studentNo + '）';
          }
        }
        return '暂无人员担任';
      },

      /**
       * @description 获取当前学生的的社团权限
       * @param {Object} _this
       * @param {Object} _arrData
       * @param {Object} _studentNo
       * @return {Object} 当前学生的社团权限
       */
      onGetIsPower(_this, _arrData, _studentNo) {
        let temp = {
          isDirector: false,
          isManager: false,
        }
        for (let item of _arrData) {
          if (item.studentNo == JSON.parse(_studentNo)) {
            temp.isDirector = item.isDirector;
            temp.isManager = item.isManager;
            return temp;
          }
        }
        return temp;
      }

    },
    created() {
      let _this = this;
      onGetData(_this);
      console
    },

  };

  /**
   * @description 分页加载数据
   * @param {Object} _this
   */
  function onGetData(_this) {
    _this.fullscreenLoading = true;
    ajaxGet(_this, '/api/student/club', null, function(resData) {

      _this.itemData = [];
      let tempNo = _this.$GetKey('StudentNo');
      for (let item of resData) {
        _this.itemData.push({
          clubId: item.clubId,
          name: item.name,
          description: item.description,
          attachment: item.attachment,
          number: item.member.length,
          studentname: _this.onGetAdminName(item.member),
          isPower: _this.onGetIsPower(_this, item.member, tempNo),
        });
      }
      _this.fullscreenLoading = false;
    });
  }
</script>

<style scoped="scoped">

</style>
<style>
  .iconClass {
    font-size: 22px;
    color: #406AD3;
  }

  .iconNoClass {
    font-size: 22px;
    color: #EFEFEF;
  }

  .clubBtnImport {
    margin-left: 2px;
    display: inline-block;
  }
</style>
