<!--
  获奖申请 - 详情
-->
<template>
  <div class="SubWebPageStudent">
    <div class="card">
      <div class="card-header border-0">
        <div class="d-flex justify-content-between">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            获奖申请信息
          </h3>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-3 colInfo">
            <label>学期</label>
            <div class="colFonts">2020年 - 2021年第1学期</div>
          </div>
          <div class="col-lg-3 colInfo">
            <label>姓名</label>
            <div class="colFonts">杨娜娜</div>
          </div>
          
          <div class="col-lg-3 colInfo">
            <label>获奖类型</label>
            <div class="colFonts">优秀团员</div>
          </div>
          <div class="col-lg-3 colInfo">
            <label>获奖级别</label>
            <div class="colFonts">（省级）三等奖</div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header border-0">
        <div class="d-flex justify-content-between">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            英特尔杯全国中学生电子设计竞赛嵌入式专题竞赛
          </h3>
          <div class="card-tools">
            <a href="#" class="btn btn-info btn-xs">
              <svg class="icon" aria-hidden="true">
              	<use xlink:href="#fax-export-box-alt"></use>
              </svg>
              下载附件
            </a>
          </div>
        </div>
      </div>
      <div class="card-body text-center">
        <img src="https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1607600689256&di=63ec29aa21ff97d8a458bd598a362bff&imgtype=0&src=http%3A%2F%2Fplzz.cn%2FuploadDir%2Fjpg%2F20180619%2F1529376644281.jpg" alt="" width="100%">
      </div>
    </div>


    <router-link to="" class="btn btn-default">
      <span @click="$router.back(-1)">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#fax-arrow-circle-left"></use>
        </svg>
        返回
      </span>
    </router-link>



  </div>
</template>

<script>
    import '@/assets/css/Student.css';
    export default{};
</script>

<style>
</style>
