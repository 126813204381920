import moment from 'moment'; //moment.js
 /**
  * 获取当前月份的第一天00:00:00和最后一天23:59:59
  *
  * @returns 返回当前月份的第一天和最后一天的Date对象数组，格式如[firstDay, lastDay]
  */
 export default () => {
    const now = new Date();
    // 获取当前月的第一天
    const firstDay = moment(new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0)).format('YYYY-MM-DD HH:mm:ss');
    // 获取当前月的最后一天
    // 先获取下个月的第一天，再减去一天
    const lastDay = moment(new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59, 999)).format('YYYY-MM-DD HH:mm:ss');

    return [firstDay,lastDay ]
 }