<!--
  班主任手册-双优生分析
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">

    <!-- 搜索 -->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          搜索
        </h3>
      </div>
      <div class="card-body ">
        <el-row :gutter="20">
          <el-col :lg="6" :sm="12" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">日期</span>
            </div>
            <div class="selBodyBox">
              <el-date-picker placeholder="请选择"  v-model="parms.Date" type="date" class="width_100Pie"  value-format="yyyy-MM-dd">
              </el-date-picker>
            </div>
          </el-col>
          <el-col :lg="6" :sm="12" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">年级</span>
            </div>
            <div class="selBodyBox">
              <el-select v-model="parms.CollegeId" filterable clearable placeholder="请选择" class="width_100Pie">
                <el-option v-for="(item, index) in gradeList" :key="index" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </el-col>
       
        </el-row>
      </div>
      <div class="card-footer text-center">
        <el-button type="primary" class="mr-2" slot="append" icon="el-icon-search" @click.native="onSearch()">查询
        </el-button>
        <el-button slot="append" class="mr-2" icon="el-icon-refresh" @click.native="onResetSearch()">重置</el-button>
      </div>
    </el-card>
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          【{{parms.Date}}】全校学生请假统计
          <small class=" color-danger">（点击数字可查看具体请假名单)</small>
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item" v-if="isPower.admin_statistics_moral_export">
              <el-button @click="onExportData()" icon="el-icon-download"
                style="background-color:#17a2b8	;color: white;">
                导出
              </el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0">
        <el-table :data="tableData" stripe class="elTableData">
           <el-table-column type="index" label="序号" class="elColumn" align="center" width="80"></el-table-column>
          <el-table-column prop="className" label="班级"></el-table-column>
          <el-table-column prop="adviser" label="班主任"></el-table-column>
          <el-table-column prop="number" label="普通请假">
            <template slot-scope="scope">
              <span @click="onDetails(scope.row.normal,scope.row.className,'普通请假')">{{scope.row.normal.length || 0}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="number" label="课间操请假">
            <template slot-scope="scope">
              <span @click="onDetails(scope.row.exercise,scope.row.className,'课间操请假')">{{scope.row.exercise.length || 0}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="number" label="午休请假">
            <template slot-scope="scope">
              <span @click="onDetails(scope.row.break,scope.row.className,'午休请假')">{{scope.row.break.length || 0}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="number" label="进寝室请假">
            <template slot-scope="scope">
              <span  @click="onDetails(scope.row.dormitory,scope.row.className,'进寝室请假')">{{scope.row.dormitory.length || 0}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="number" label="临时通校请假">
            <template slot-scope="scope">
              <span @click="onDetails(scope.row.temporary,scope.row.className,'临时通校请假')">{{scope.row.temporary.length || 0}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="number" label="总请假次数" align="center">
            <template slot-scope="scope">
              <span>{{getLeaveCount(scope.row)}}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
<!-- :before-close="handleClose" -->
    <el-dialog
      :title="`${className}班【${parms.Date} | ${categoryName}】详情`" :visible.sync="dialogLeave" width="70%"
      >
      <div>
        <el-table :data="itemData" border stripe class="elTableData">
           <el-table-column type="index" label="序号" class="elColumn" width="80"></el-table-column>
          <el-table-column prop="studentNo" label="学号"></el-table-column>
          <el-table-column prop="studentName" label="姓名"></el-table-column>
          <el-table-column prop="" label="请假类型">
            <template slot-scope="scope">
              <span >{{ scope.row.typeName ||scope.row.categoryName }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="startDate" label="请假开始时间">
            <template slot-scope="scope">
              <span >{{handleDate(scope.row.startDate)}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="number" label="请假结束时间">
            <template slot-scope="scope">
              <span >{{handleDate(scope.row.endDate)}}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogLeave = false">关 闭</el-button>
      </span>
    </el-dialog>


  </div>
</template>

<script>
  import moment from 'moment'; //moment.js
  import '@/assets/css/Admin.css';
  export default {
    components: {

    },
    data() {
      return {
        fullscreenLoading: false,
        parms:{
          CollegeId:'',
          Date:moment(new Date()).format('yyyy-MM-DD'),
        },
        //选中的班级名称
        className:'',
        //选中的请假类型
        categoryName:'',
        //年级下拉数据
        gradeList:[],
        //列表数据
        tableData:[],
        //弹窗数据
        itemData: [], 
        // 学期
        optSemester: [],
        // 月份
        optMonth: [],
        // 校历周
        optWeek: [],
        // 班级
        optClass: [],
        searchForm: {
          SemesterId: null,
          Month: null,
          WeekIndex: null,
          ClassId: null,
        },
        searchFormReset: null,
        isPower: {
        },
        isTeacher: false,
        currentDay: moment(new Date()).format('YYYY-MM-DD'),
        dialogLeave: false,
      };
    },
    created() {
      this.gradeList = this.getGradeClassLinkageData()
      this.parms.CollegeId = this.gradeList[0].value
      this.onGetData();
    },
    methods: {
      /**
       * 处理时间格式
       * @param {*} datestr 时间字符串
       */
      handleDate(datestr){
        if(datestr){
          return moment(datestr).format('YYYY-MM-DD HH:mm')
        }else{
          return ''
        }
       
      },
      /**
       * @description 打开详情弹窗
       * @param {*} list 详情弹窗数据
       */

      onDetails(list,className,categoryName){
        // if(Array.isArray(list) && list.length > 0) {
          this.itemData = list
          this.className = className
          this.categoryName = categoryName
          
          this.dialogLeave = true;
        // }
      },
      /**
       * @description 计算总请假次数
       * @param row 请假数据单条数据 
       */
      getLeaveCount(row) {
        return (row.normal.length || 0 )+ (row.exercise.length|| 0)  + (row.break.length || 0) + (row.dormitory.length) || 0 + (row.temporary.length || 0)
      },
         /**
         * @description 获取年级数据  
         */
				 getGradeClassLinkageData (){
          let tempUser = onGetNowUser(this)
          let tempArr =  tempUser.isTeacher ?  tempUser.manageClass: tempUser.allClass
					let res = []
					tempArr.forEach(v => {
						const o = res.find(vv => vv.value === v.collegeId)
						if (!o) {
							res.push({
								label: v.college,
								value: v.collegeId,
							})
						}
					})
					return res
				},
      //获取列表数据
       onGetData() {
          ajaxGet(this, '/api/admin/statistics/leavestatis', this.parms, (res)=> {
           this.tableData = res;
         });
       },

      /**
       * @description 导出
       */
      onExportData() {
        // let that = this;
        // let data = {
        //   WeekIndex: that.searchForm.WeekIndex,
        //   ClassId: null,
        //   SemesterId: that.searchForm.SemesterId,
        //   Month: that.searchForm.Month,
        // }
        // let fileName = '【2023-03-03】学生请假统计' + getTimeID() + '.xls';
        // that.fullscreenLoading = true;
        // ajaxExport(that, '/api/admin/statistics/exportclasscredit', data, fileName, function(res) {
        //   that.fullscreenLoading = false;
        // });
      },
      /**
       * @description 搜索
       */
      onSearch() {
        this.onGetData();
      },
      /**
       * @description 重置搜索
       */
      onResetSearch() {
        for(let key in this.parms ){
          this.parms[key] = ''; 
        }
        this.onGetData();
      },

      
    },
    watch: {
      $route() {
        this.onGetData();
      }
    }
  }

  

</script>

<style scoped="scoped" lang="less">
  .selTitleBox {
    font-size: 14px;
  }

  .selTitleBox .spTitle {
    color: #303133;
  }

  .contentHidden {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
  }

  .divBtnCont {
    text-align: right;
    padding-bottom: 0.9375rem;
    /*15px*/
  }

</style>

<style lang="less">
  .elTableData {
    .tdClass {
      background-color: #edf5ff !important;
    }

    .tdDormitory {
      background-color: #f0f9eb !important;
    }
  }

</style>

