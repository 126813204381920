<!--
  成长档案 - 详情
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">

    <!--学年学期-->
    <div class="divPageHeaderBox">
      <ul class="ulTabsBox">
        <li class="liTab" :class="{liAction:currsorIndex==index}" v-for="(p,index) in optYear" :key="index"
          @click="onClickTab(p.yearId,index)">{{p.name}}</li>
      </ul>
      <div class="divSemesterBox">
        <div class="divTitle">学期</div>
        <el-select v-model="strSemester" placeholder="请选择" class="selForm width_300" @change="handSemesterChange">
          <el-option v-for="item in optSemester" :key="item.semesterId" :label="item.name" :value="item.semesterId">
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="pageBox">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-card class="box-card" v-if="!regNull(itemInfo)">
            <div class="card-body">
              <div class="studentInfoBox">
                <div class="studentName">
                  {{regNull(itemInfo.name)?'暂无姓名':itemInfo.name}}
                  <el-tag class="spLabelList" type="danger"
                    v-if="!regNull(itemInfo.punishLevel) && !regNullArray(itemPunish) && itemPunish.length > 0">{{itemInfo.punishLevel}}</el-tag>
                </div>
                <div class="studentLabel">
                  <span class="spLabel">
                    {{regNull(itemInfo.college)?'':itemInfo.college+'&nbsp;/&nbsp;'}}
                    {{regNull(itemInfo.major)?'':itemInfo.major+'&nbsp;/&nbsp;'}}
                    {{regNull(itemInfo.class)?'暂无班级':itemInfo.class}}
                  </span>
                  <span class="spLabel">
                    {{regNull(itemInfo.studentNo)?'暂无学号':itemInfo.studentNo}}
                  </span>
                  <span class="spLabel" v-if="!regNull(itemInfo.originalPlace)">
                    {{itemInfo.originalPlace}}
                  </span>
                </div>
                <div class="studentLabel">
                  <span class="spLabel">
                    {{regNull(itemInfo.entryYear)?'暂无':itemInfo.entryYear}}
                  </span>
                  <span class="spLabel">
                    {{regNull(itemInfo.mobile)?'暂无联系电话':itemInfo.mobile}}
                  </span>
                  <span class="spLabel" v-if="!regNull(itemInfo.political)">
                    {{itemInfo.political}}
                  </span>
                  <span class="spLabel" v-if="!regNull(itemInfo.nation)">
                    {{itemInfo.nation}}
                  </span>
                </div>
                <div class="studentImageBox">
                  <div class="studentGender VerticalMid" :class="{'genderFemale':!onGender(itemInfo.gender)}">
                    <svg class="icon" aria-hidden="true" v-if="onGender(itemInfo.gender)">
                      <use xlink:href="#fax-male-alt"></use>
                    </svg>
                    <svg class="icon" aria-hidden="true" v-else>
                      <use xlink:href="#fax-female-alt"></use>
                    </svg>
                  </div>
                  <img :src="imageUrl" alt="">
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>

      <el-card class="box-card mt-4" v-if="!regNullArray(tabslist)">
        <div slot="header" class="card-header tabsHeader">
          <el-tabs v-model="tabsIndex" type="card" @tab-click="onTabClick">
            <el-tab-pane v-for="(item,index) in tabslist" :key="index" :label="item.name"
              :name="item.value"></el-tab-pane>
          </el-tabs>
        </div>
        <div class="card-body">
          <el-row :gutter="0">
            <el-col :md="8" :sm="24">
              <div class="echartsBox">
                <comRabar :comHeight="500" :comChartData="chartData" :comID="'rabarLeft'" ref="rabarLeft"
                  :key="rabarComKey"></comRabar>
              </div>
            </el-col>
            <el-col :md="16" :sm="24">
              <div>
                <comLine :comHeight="500" :comChartData="reportData" :comID="'lineRight'" ref="lineRight"
                  :key="lineComKey"></comLine>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-card>


      <el-row :gutter="20" class="mt-4">
        <!--参与活动-->
        <el-col :xl="4" :sm="12" :lg="6" :md="8">
          <div class="card cardTypeLable" :class="{cardTypeAct:currsorBoxIndex==1}" data-type="activity"
            @click="onBoxTabs(1)">
            <div class="card-body">
              <div class="typeLabelBox clearfix">
                <div class="leftBox VerticalMid">
                  <div class="iconBox VerticalMid labelColor_2">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#fax-paper-plane-alt"></use>
                    </svg>
                  </div>
                </div>
                <div class="rightBox">
                  <div class="numberBox">{{regNullArray(itemActivity)?0:itemActivity.length}}<span
                      class="spUnit">次</span></div>
                  <div class="typeName">参与活动</div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <!-- 学习成绩 -->
        <el-col :xl="4" :sm="12" :lg="6" :md="8">
          <div class="card cardTypeLable" :class="{cardTypeAct:currsorBoxIndex==8}" data-type="activity"
            @click="onBoxTabs(8)">
            <div class="card-body">
              <div class="typeLabelBox clearfix">
                <div class="leftBox VerticalMid">
                  <div class="iconBox VerticalMid labelColor_1">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#fax-circle-exam"></use>
                    </svg>
                  </div>
                </div>
                <div class="rightBox">
                  <div class="numberBox">{{regNullArray(itemAcademic)?0:itemAcademic.length}}<span
                      class="spUnit">学期</span></div>
                  <div class="typeName">学习成绩</div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <!--行为规范-->
        <el-col :xl="4" :sm="12" :lg="6" :md="8">
          <div class="card cardTypeLable" :class="{cardTypeAct:currsorBoxIndex==2}" data-type="daily"
            @click="onBoxTabs(2)">
            <div class="card-body">
              <div class="typeLabelBox clearfix">
                <div class="leftBox VerticalMid">
                  <div class="iconBox VerticalMid labelColor_2">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#fax-user-behavior"></use>
                    </svg>
                  </div>
                </div>
                <div class="rightBox">
                  <div class="numberBox">{{regNullArray(itemMorality)?0:itemMorality.length}}<span
                      class="spUnit">次</span></div>
                  <div class="typeName">行为规范</div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <!--学生奖励-->
        <el-col :xl="4" :sm="12" :lg="6" :md="8">
          <div class="card cardTypeLable" :class="{cardTypeAct:currsorBoxIndex==3}" data-type="award"
            @click="onBoxTabs(3)">
            <div class="card-body">
              <div class="typeLabelBox clearfix">
                <div class="leftBox VerticalMid">
                  <div class="iconBox VerticalMid labelColor_3">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#fax-medals-alt"></use>
                    </svg>
                  </div>
                </div>
                <div class="rightBox">
                  <div class="numberBox">{{regNullArray(itemPrize)?0:itemPrize.length}}<span class="spUnit">次</span>
                  </div>
                  <div class="typeName">学生奖励</div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <!--学业成绩-->
        <!-- <el-col :xl="4" :sm="12" :lg="6" :md="8">
          <div class="card cardTypeLable" :class="{cardTypeAct:currsorBoxIndex==11}" data-type="award"
            @click="onBoxTabs(11)">
            <div class="card-body">
              <div class="typeLabelBox clearfix">
                <div class="leftBox VerticalMid">
                  <div class="iconBox VerticalMid labelColor_3">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#fax-medals-alt"></use>
                    </svg>
                  </div>
                </div>
                <div class="rightBox">
                  <div class="numberBox">{{regNullArray(itemAranscript)?0:itemAranscript.length}}<span
                      class="spUnit">次</span>
                  </div>
                  <div class="typeName">学业成绩</div>
                </div>
              </div>
            </div>
          </div>
        </el-col> -->
        <!--处分记录-->
        <el-col :xl="4" :sm="12" :lg="6" :md="8">
          <div class="card cardTypeLable" :class="{cardTypeAct:currsorBoxIndex==4}" data-type="punishment"
            @click="onBoxTabs(4)">
            <div class="card-body">
              <div class="typeLabelBox clearfix">
                <div class="leftBox VerticalMid">
                  <div class="iconBox VerticalMid labelColor_6">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#fax-file-punish"></use>
                    </svg>
                  </div>
                </div>
                <div class="rightBox">
                  <div class="numberBox">{{regNullArray(itemPunish)?0:itemPunish.length}}<span class="spUnit">次</span>
                  </div>
                  <div class="typeName">处分记录</div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <!-- 获奖上报 -->
        <el-col :xl="4" :sm="12" :md="8" :lg="6">
          <div class="card cardTypeLable" :class="{cardTypeAct:currsorBoxIndex==6}" data-type="punishment"
            @click="onBoxTabs(6)">
            <div class="card-body">
              <div class="typeLabelBox clearfix">
                <div class="leftBox VerticalMid">
                  <div class="iconBox VerticalMid labelColor_4">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#fax-file-user-o"></use>
                    </svg>
                  </div>
                </div>
                <div class="rightBox">
                  <div class="numberBox">{{regNullArray(itemSelfReport.items)?0:itemSelfReport.items.length}}<span
                      class="spUnit">次</span></div>
                  <div class="typeName">获奖上报</div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <!-- 综合课堂 -->
        <!-- <el-col :xl="4" :sm="12" :md="8" :lg="6">
          <div class="card cardTypeLable" :class="{cardTypeAct:currsorBoxIndex==7}" data-type="punishment"
            @click="onBoxTabs(7)">
            <div class="card-body">
              <div class="typeLabelBox clearfix">
                <div class="leftBox VerticalMid">
                  <div class="iconBox VerticalMid labelColor_1">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#fax-book-open"></use>
                    </svg>
                  </div>
                </div>
                <div class="rightBox">
                  <div class="numberBox">
                    {{regNullArray(itemLiteracyCourse.items)?0:itemLiteracyCourse.items.length}}<span
                      class="spUnit">次</span>
                  </div>
                  <div class="typeName">综合课堂</div>
                </div>
              </div>
            </div>
          </div>
        </el-col> -->
        <!--宿舍记录-->
<!--        <el-col :xl="4" :sm="12" :md="8" :lg="6">
          <div class="card cardTypeLable" :class="{cardTypeAct:currsorBoxIndex==5}" data-type="dorm"
            @click="onBoxTabs(5)">
            <div class="card-body">
              <div class="typeLabelBox clearfix">
                <div class="leftBox VerticalMid">
                  <div class="iconBox VerticalMid labelColor_4">
                    <svg class="icon font-size-42" aria-hidden="true">
                      <use xlink:href="#fax-building"></use>
                    </svg>
                  </div>
                </div>
                <div class="rightBox">
                  <div class="numberBox">{{regNullArray(itemMoralityDb)?0:itemMoralityDb.length}}<span
                      class="spUnit">项</span></div>
                  <div class="typeName">
                    宿舍记录
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-col> -->
        <!-- 履历记录 -->
        <el-col :xl="4" :sm="12" :lg="6" :md="8">
          <div class="card cardTypeLable" :class="{cardTypeAct:currsorBoxIndex==9}" data-type="punishment"
            @click="onBoxTabs(9)">
            <div class="card-body">
              <div class="typeLabelBox clearfix">
                <div class="leftBox VerticalMid">
                  <div class="iconBox VerticalMid labelColor_2">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#fax-file-user-o"></use>
                    </svg>
                  </div>
                </div>
                <div class="rightBox">
                  <div class="numberBox">{{regNull(ResumeCount)?0:ResumeCount}}
                    <!-- {{regNullArray(itemClassCadre)?0:itemClassCadre.length}} -->
                    <span class="spUnit">次</span>
                  </div>
                  <div class="typeName">履历记录</div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <!-- 班主任评语 -->
        <el-col :xl="4" :sm="12" :lg="6" :md="8">
          <div class="card cardTypeLable" :class="{cardTypeAct:currsorBoxIndex==10}" data-type="punishment"
            @click="onBoxTabs(10)">
            <div class="card-body">
              <div class="typeLabelBox clearfix">
                <div class="leftBox VerticalMid">
                  <div class="iconBox VerticalMid labelColor_7">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#fax-message-alt"></use>
                    </svg>
                  </div>
                </div>
                <div class="rightBox">
                  <div class="numberBox">{{regNullArray(itemComment)?0:itemComment.length}}<span class="spUnit">条</span>
                  </div>
                  <div class="typeName">品德评语</div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>

      <!--参与活动-->
      <el-card class="box-card activity" v-show="currsorBoxIndex==1">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            参与活动
          </h3>
        </div>
        <div class="card-body listBox" v-if="itemActivity!=null && itemActivity.length>0">
          <el-table :data="itemActivity" stripe class="elTableData" :key="Math.random()">
            <el-table-column type="index" label="序号" width="80"></el-table-column>
            <el-table-column label="活动名称">
              <template slot-scope="scope">
                <span class="spLevel color-danger" v-if="scope.row.level.indexOf('校')>-1">校</span>
                <span class="spLevel color-warning" v-else-if="scope.row.level.indexOf('部')>-1">部</span>
                <span class="spLevel color-primary" v-else-if="scope.row.level.indexOf('班')>-1">班</span>
                <span class="spLevel color-primary" v-else>{{scope.row.level}}</span>
                {{scope.row.name}}
              </template>
            </el-table-column>
            <el-table-column label="附件">
              <template slot-scope="scope">
                <div class="imgListBox">
                  <a href="javascript:void(0);" class="aLink aLeftOperation">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#fax-chevron-left"></use>
                    </svg>
                  </a>
                  <a href="javascript:void(0);" class="aLink aRightOperation">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#fax-chevron-right"></use>
                    </svg>
                  </a>
                  <div class="imgContent">
                    <div class="imgBox clearfix" data-left="0" style="margin-left: 0px;">
                      <span class="spImg" v-for="(d,index) in scope.row.photoList" :key="index">
                        <el-image class="itemImg" :src="d" :preview-src-list="scope.row.photoList"></el-image>
                      </span>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="结束时间" width="140">
              <template slot-scope="scope">
                {{scope.row.endDate | dateformatDay}}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="card-body p-4 text-center" v-else>
          <div class="NoDataTipCont">
            <svg aria-hidden="true" class="icon faxIcon color-warning">
              <use xlink:href="#fax-exclamation-triangle-alt"></use>
            </svg> 暂无参与活动
          </div>
        </div>
      </el-card>
      <!--学生获奖-->
      <el-card class="box-card award" v-show="currsorBoxIndex==3">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            学生获奖
          </h3>
        </div>
        <div class="card-body listBox" v-if="!regNullArray(itemPrize)">
          <el-table :data="itemPrize" stripe style="width: 100%" :key="Math.random()">
            <el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
            <el-table-column label="奖励名称">
              <template slot-scope="scope">
                {{scope.row.name}}
              </template>
            </el-table-column>
            <el-table-column label="奖励级别">
              <template slot-scope="scope">
                {{scope.row.level.name}}
              </template>
            </el-table-column>
            <el-table-column label="附件" width="200">
              <template slot-scope="scope">
                <div class="imgListBox" v-if="currsorBoxIndex==3">
                  <a href="javascript:void(0);" class="aLink aLeftOperation">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#fax-chevron-left"></use>
                    </svg>
                  </a>
                  <a href="javascript:void(0);" class="aLink aRightOperation">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#fax-chevron-right"></use>
                    </svg>
                  </a>
                  <div class="imgContent">
                    <div class="imgBox clearfix" data-left="0" style="margin-left: 0px;">
                      <span class="spImg" v-for="(d,index) in scope.row.photoList" :key="index">
                        <el-image class="itemImg" :src="d" :preview-src-list="scope.row.photoList"></el-image>
                      </span>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="获奖时间" width="160">
              <template slot-scope="scope">
                {{scope.row.prizeDate | dateformatDay}}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="card-body p-4 text-center" v-else>
          <div class="NoDataTipCont">
            <svg aria-hidden="true" class="icon faxIcon color-warning">
              <use xlink:href="#fax-exclamation-triangle-alt"></use>
            </svg> 暂无学生获奖
          </div>
        </div>
      </el-card>
      <!--行为规范-->
      <el-card class="box-card daily" v-show="currsorBoxIndex==2">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            行为规范
          </h3>
        </div>
        <div class="card-body listBox" v-if="itemMorality!=null && itemMorality.length>0">
          <el-table :data="itemMorality" stripe class="elTableData" :key="Math.random()">
            <el-table-column type="index" label="序号" width="80"></el-table-column>
            <el-table-column prop="reason" label="行为原因"></el-table-column>
            <el-table-column prop="score" label="分值" width="100">
            </el-table-column>
            <el-table-column label="结束时间" width="140">
              <template slot-scope="scope">
                {{scope.row.logDateString | dateformatMinute}}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="card-body p-4 text-center" v-else>
          <div class="NoDataTipCont">
            <svg aria-hidden="true" class="icon faxIcon color-warning">
              <use xlink:href="#fax-exclamation-triangle-alt"></use>
            </svg> 暂无行为规范
          </div>
        </div>
      </el-card>
      <!--处分记录-->
      <el-card class="box-card punishment" v-show="currsorBoxIndex==4">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            处分记录
          </h3>
        </div>
        <div class="card-body listBox" v-if="itemPunish!=null && itemPunish.length>0">
          <el-table :data="itemPunish" stripe class="elTableData" :key="Math.random()">
            <el-table-column type="index" label="序号" width="80"></el-table-column>
            <el-table-column prop="punishNo" label="处分号" min-width="200" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="reason" label="原因" min-width="200" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column label="等级" width="160">
              <template slot-scope="scope">
                {{onGetPunishesLevel(scope.row.members)}}
              </template>
            </el-table-column>
            <el-table-column label="处分时间" width="140">
              <template slot-scope="scope">
                {{scope.row.punishDate | dateformatDay}}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="card-body p-4 text-center" v-else>
          <div class="NoDataTipCont">
            <svg aria-hidden="true" class="icon faxIcon color-warning">
              <use xlink:href="#fax-exclamation-triangle-alt"></use>
            </svg> 暂无处分记录
          </div>
        </div>
      </el-card>
      <!--处分撤销记录-->
      <el-card class="box-card punishment mt-3" v-show="currsorBoxIndex==4"
        v-if="itemPunishRevoke.items!=null && itemPunishRevoke.items.length>0">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            处分撤销记录
          </h3>
        </div>
        <div class="card-body listBox">
          <el-table :data="itemPunishRevoke.items" stripe class="elTableData" :key="Math.random()">
            <el-table-column type="index" label="序号" width="80"></el-table-column>
            <el-table-column prop="reason" label="撤销原因" min-width="200" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="user" label="记录人" width="140" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column label="申请撤销时间" width="140">
              <template slot-scope="scope">
                {{scope.row.applyDate | dateformatDay}}
              </template>
            </el-table-column>
            <el-table-column label="处分撤销时间" width="140">
              <template slot-scope="scope">
                {{scope.row.revokeDate | dateformatDay}}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-card>
      <!--获奖上报-->
      <el-card class="box-card punishment" v-show="currsorBoxIndex==6">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            获奖上报
          </h3>
        </div>
        <div class="card-body listBox" v-if="itemSelfReport.items!=null && itemSelfReport.items.length>0">
          <el-table :data="itemSelfReport.items" stripe class="elTableData" :key="Math.random()">
            <el-table-column type="index" label="序号" width="80"></el-table-column>
            <el-table-column prop="name" label="获奖名称" min-width="100" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="place" label="颁奖单位" min-width="100" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="auditStateString" label="审核状态" width="160">
              <template slot-scope="scope">
                <span v-if="scope.row.auditState==2" class="color-success">审核通过</span>
                <span v-else-if="scope.row.auditState==3" class="color-danger">审核不通过</span>
                <span v-else-if="scope.row.auditState==1" class="color-LightGray">待审核</span>
              </template>
            </el-table-column>
            <el-table-column label="是否记录综合分" prop="isReckon" width="160">
              <template slot-scope="scope">
                <span v-if="scope.row.isReckon" class="color-danger">是</span>
                <span v-else class="color-LightGray">否</span>
              </template>
            </el-table-column>
            <el-table-column label="颁奖时间" width="140">
              <template slot-scope="scope">
                {{scope.row.recordDate | dateformatDay}}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="card-body p-4 text-center" v-else>
          <div class="NoDataTipCont">
            <svg aria-hidden="true" class="icon faxIcon color-warning">
              <use xlink:href="#fax-exclamation-triangle-alt"></use>
            </svg> 暂无获奖上报
          </div>
        </div>
      </el-card>
      <!--宿舍记录-->
      <el-card class="box-card dorm" v-show="currsorBoxIndex==5">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            宿舍记录
          </h3>
        </div>
        <div class="card-body listBox" v-if="itemMoralityDb!=null && itemMoralityDb.length>0">
          <el-table :data="itemMoralityDb" stripe class="elTableData" :key="Math.random()">
            <el-table-column type="index" label="序号" width="80"></el-table-column>
            <el-table-column prop="reason" label="记录原因" min-width="100" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="score" label="分值" width="100">
            </el-table-column>
            <el-table-column label="行为时间" width="140">
              <template slot-scope="scope">
                {{scope.row.logDateString | dateformatMinute}}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="card-body p-4 text-center" v-else>
          <div class="NoDataTipCont">
            <svg aria-hidden="true" class="icon faxIcon color-warning">
              <use xlink:href="#fax-exclamation-triangle-alt"></use>
            </svg> 暂无处分记录
          </div>
        </div>
      </el-card>
      <!-- 班级履历记录 -->
      <el-card class="box-card" v-if="currsorBoxIndex==9">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            班级履历记录
          </h3>
        </div>
        <div class="card-body p-0" v-if="!regNullArray(itemClassCadre)">
          <el-table :data="itemClassCadre" stripe style="width: 100%" :key="Math.random()">
            <el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
            <el-table-column prop="class" label="班级"></el-table-column>
            <el-table-column prop="position" label="职位"></el-table-column>
            <el-table-column label="上任时间">
              <template slot-scope="scope">
                {{scope.row.createDate | dateformatDay}}
              </template>
            </el-table-column>
            <el-table-column label="离任时间">
              <template slot-scope="scope">
                {{onGetResignDate(scope.row.departureDate)}}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="card-body p-4 text-center" v-else>
          <div class="NoDataTipCont">
            <svg aria-hidden="true" class="icon faxIcon color-warning">
              <use xlink:href="#fax-exclamation-triangle-alt"></use>
            </svg> 暂无班级履历记录
          </div>
        </div>
      </el-card>
      <!-- 学生会履历记录 -->
      <el-card class="box-card" v-if="currsorBoxIndex==9 && !regNullArray(itemAssociation)" style="margin-top: 20px;">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            学生会履历记录
          </h3>
        </div>
        <div class="card-body p-0">
          <el-table :data="itemAssociation" stripe style="width: 100%" :key="Math.random()">
            <el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
            <el-table-column prop="association" label="学生会"></el-table-column>
            <el-table-column prop="position" label="职位"></el-table-column>
            <el-table-column label="上任时间">
              <template slot-scope="scope">
                {{scope.row.startDate | dateformatDay}}
              </template>
            </el-table-column>
            <el-table-column label="离任时间">
              <template slot-scope="scope">
                {{ onGetResignDate(scope.row.endDate)}}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-card>
      <!-- 班主任评语 -->
      <div v-if="currsorBoxIndex==10">
        <div v-if="!regNullArray(itemComment)">
          <el-card class="box-card mb-3" v-for="(p) in itemComment" :key='p.commentId'>
            <div slot="header" class="card-header">
              <h3 class="card-title titleCont">
                <span class="spIcon"></span>
                {{p.semester}} 评语
              </h3>
              <div class="card-tools">
                <ul class="nav">
                  <li class="nav-item">
                    <el-tag>等第：{{p.commentsRankString}}</el-tag>
                  </li>
                  <li class="nav-item ml-3">
                    <el-tag type="info">德育操行成绩：{{p.score}}</el-tag>
                  </li>
                </ul>
              </div>
            </div>
            <div class="card-body">
              <div class="remarksCont" v-html="p.description">

              </div>
            </div>
          </el-card>
        </div>
        <div v-else>
          <el-card class="box-card ">
            <div slot="header" class="card-header">
              <h3 class="card-title titleCont">
                <span class="spIcon"></span>
                班主任评语
              </h3>
            </div>
            <div class="card-body p-4 text-center">
              <div class="NoDataTipCont">
                <svg aria-hidden="true" class="icon faxIcon color-warning">
                  <use xlink:href="#fax-exclamation-triangle-alt"></use>
                </svg> 暂无班主任评语
              </div>
            </div>
          </el-card>
        </div>
      </div>
      <!-- 成绩 -->
      <div v-if="currsorBoxIndex==8">
        <el-card class="box-card mb-3" v-for="(p,index) in itemAcademic" :key='index'>
          <div slot="header" class="card-header ">
            <h3 class="card-title titleCont">
              <span class="spIcon"></span>
              {{p.Semester}}
            </h3>
          </div>
          <div class="card-body p-0" v-if="!regNullArray(p.Academic)">
            <el-table :data="p.Academic" stripe style="width: 100%" :key="Math.random()">
              <el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
              <el-table-column prop="courseName" label="课程"></el-table-column>
              <el-table-column label="平时">
                <template slot-scope="scope">
                  {{scope.row.peaceTime | flScore}}
                </template>
              </el-table-column>
              <el-table-column label="期中">
                <template slot-scope="scope">
                  {{scope.row.midTerm | flScore}}
                </template>
              </el-table-column>
              <el-table-column label="期末">
                <template slot-scope="scope">
                  {{scope.row.final | flScore}}
                </template>
              </el-table-column>
              <el-table-column label="总评">
                <template slot-scope="scope">
                  {{scope.row.total | flScore}}
                </template>
              </el-table-column>
              <el-table-column label="绩点">
                <template slot-scope="scope">
                  {{scope.row.gpa | flScore}}
                </template>
              </el-table-column>
              <el-table-column label="学分">
                <template slot-scope="scope">
                  {{scope.row.credit | flScore}}
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="card-body p-4 text-center" v-else>
            <div class="NoDataTipCont" style="text-align: center;">
              <svg aria-hidden="true" class="icon faxIcon color-warning">
                <use xlink:href="#fax-exclamation-triangle-alt"></use>
              </svg> 暂无成绩
            </div>
          </div>
        </el-card>
      </div>
      <!--综合课堂-->
      <el-card class="box-card punishment" v-if="currsorBoxIndex==7">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            综合课堂
          </h3>
        </div>
        <div class="card-body listBox" v-if="itemLiteracyCourse.items!=null && itemLiteracyCourse.items.length>0">
          <el-table :data="itemLiteracyCourse.items" stripe class="elTableData">
            <el-table-column type="index" label="序号" width="80"></el-table-column>
            <el-table-column prop="name" label="名称" min-width="100" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="type" label="类型" width="160"></el-table-column>
            <el-table-column label="课程时间" width="240">
              <template slot-scope="scope">
                {{scope.row.starDate | dateformatDay}} 至 {{scope.row.endDate | dateformatDay}}
              </template>
            </el-table-column>
            <el-table-column label="完成度" width="120">
              <template slot-scope="scope">
                {{onGetProgress(scope.row.duration,scope.row.watchTime)}}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="card-body p-4 text-center" v-else>
          <div class="NoDataTipCont">
            <svg aria-hidden="true" class="icon faxIcon color-warning">
              <use xlink:href="#fax-exclamation-triangle-alt"></use>
            </svg> 暂无综合课堂记录
          </div>
        </div>
      </el-card>
      <!-- 学业成绩 -->
      <div v-if="currsorBoxIndex==11">
        <div v-if="!regNullArray(itemAranscript)">
          <el-card class="box-card mt-3" v-for="(p,index) in itemAranscript" :key='index'>
            <div slot="header" class="card-header">
              <h3 class="card-title titleCont">
                <span class="spIcon"></span>
                {{p.semesterName}}
              </h3>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-lg-3 colInfo">
                  <label>综合积分</label>
                  <div class="colFonts">
                    {{p.moralCredit | flNoValue}}
                  </div>
                </div>
                <div class="col-lg-3 colInfo">
                  <label>操行等级</label>
                  <div class="colFonts">
                    {{p.behaviorCreditString | flNoValue}}
                  </div>
                </div>
                <div class="col-lg-3 colInfo">
                  <label>奖学金</label>
                  <div class="colFonts">
                    {{p.scholarship | flNoValue}}
                  </div>
                </div>
                <div class="col-lg-3 colInfo">
                  <label>上课总天数</label>
                  <div class="colFonts">
                    {{p.days | flNoValue}}
                  </div>
                </div>
                <div class="col-lg-3 colInfo">
                  <label>出勤</label>
                  <div class="colFonts">
                    {{p.attendance | flNoValue}}
                  </div>
                </div>
                <div class="col-lg-3 colInfo">
                  <label>缺课</label>
                  <div class="colFonts">
                    {{p.absence | flNoValue}}
                  </div>
                </div>
                <div class="col-lg-3 colInfo">
                  <label>旷课</label>
                  <div class="colFonts">
                    {{p.skip | flNoValue}}
                  </div>
                </div>
              </div>
            </div>
          </el-card>
        </div>
        <el-card class="box-card" v-else>
          <div slot="header" class="card-header">
            <h3 class="card-title titleCont">
              <span class="spIcon"></span>
              学业成绩
            </h3>
          </div>
          <div class="card-body ">
            <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
          </div>
        </el-card>
      </div>

    </div>

    <div class="pt-10">
      <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
    </div>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comRabar from '@/components/Admin/Echarts/Chart_Radar.vue';
  import comLine from '@/components/Admin/Echarts/Chart_Line.vue';
  export default {
    components: {
      comRabar,
      comLine
    },
    data() {
      return {
        fullscreenLoading: false,
        studentId: null,
        random: 0, //刷新组件
        itemInfo: {}, //学生基础数据
        itemAcademic: [], //获取成绩
        itemAranscript: [], //学业成绩
        itemComment: [], //评语数据
        itemMorality: [], //个人行为规范数据
        itemMoralityDb: [], //寝室得分数据
        itemActivity: [], //活动数据
        itemPunish: [], //处分数据
        itemDormitory: [], //宿舍数据
        itemPrize: [], //获奖数据
        itemSelfReport: [], //自我记录数据
        itemLiteracyCourse: [], //综合课堂数据
        itemPunishRevoke: [], //处分撤销数据
        itemClassCadre: [], //履历数据
        itemAssociation: [], //学生会履历数据
        imageUrl: null,
        optSemester: [], //学期
        strSemester: null,
        optYear: [], //学年
        strYear: null,
        SearchList: null,
        data: {
          PageSize: 99999999, //每页的条数
          PageNumber: null, //页码
          StudentId: null, //学生id
          YearId: null, //学年
          SemesterId: null, //学期
        },
        dataActivity: null,
        dataMorality: null,
        dataMoralityDb: null,
        dataPunish: null,
        dataReset: null,

        // 学年
        currsorIndex: 0,
        // 数据类型
        currsorBoxIndex: 1,
        ResumeCount: 0,

        tabslist: [],
        tabsIndex: null,
        // 雷达图
        chartData: [],
        // 折线图
        reportData: {},
        lineComKey: Math.random(10000),
        rabarComKey:Math.random(10000),

      };
    },
    filters: {
      flNoValue(obj) {
        if (obj == null || typeof(obj) == "undefined" || obj == '') {
          return '暂无数据';
        }
        return obj;
      },
      flScore(objItem) {
        let that = this;
        if (objItem != null && objItem != '') {
          return objItem;
        } else {
          return '-';
        }
      }
    },
    methods: {
      /**
       * @description Tabs切换事件
       * @param {Object} objIiem
       */
      onTabClick() {
        let that = this;
        let tempData = {
          YearId: that.data.YearId,
          SemesterId: that.data.SemesterId,
          ConfigId: that.tabsIndex == 0 ? null : that.tabsIndex,
          StudentId: that.data.StudentId
        };
        that.lineComKey = Math.random(1000);
        that.rabarComKey= Math.random(10000);
        ajaxGet(that, '/api/admin/growtharchives/studentreport', tempData, function(resReport) {
          ajaxGet(that, '/api/admin/growth/chartdata', tempData, function(resData) {
            that.chartData = resData;
            that.reportData = resReport;
            if (!regNullArray(resData)) {
              that.rabarComKey= Math.random(10000);
              that.$refs.rabarLeft.onGetRadar(resData);
            }
            if (!regNullArray(resReport)) {
              that.lineComKey = Math.random(1000);
              that.$refs.lineRight.onGetRadar(resReport);
            }
          });
        });
      },
      /**
       * @description 离任时间
       * @param {Object} objDate
       */
      onGetResignDate(objDate) {
        let that = this;
        if (!regNull(objDate)) {
          return timeTransform(objDate, 'YYYY-MM-DD');
        } else {
          return '-';
        }
      },
      /**
       * @description 获取处分类型
       * @param {Object} objItem
       */
      onGetPunishesLevel(objItem) {
        let that = this;
        if (!regNullArray(objItem)) {
          let tpItem = objItem.find(item => item.studentId == that.studentId);
          if (!regNull(tpItem)) {
            return tpItem.levelName;
          } else {
            return '-';
          }
        } else {
          return '-';
        }
      },
      onBoxTabs(_index) {
        this.currsorBoxIndex = _index;
      },
      //选择学年
      onClickTab(val, _index) {
        var that = this;
        that.currsorIndex = _index;
        if (val == 0) {
          that.onClickTab2();
        } else {
          that.strSemester = "";
          // that.data=JSON.parse(JSON.stringify(that.dataReset));
          that.data.YearId = val;
          that.data.SemesterId = "";
          that.itemAcademic = [];
          that.fullscreenLoading = true;
          ajaxGet(that, "/api/admin/semester/year/" + val, null, function(r2) {
            ajaxGet(that, "/api/admin/growtharchives/academicsearch/" + that.studentId + '?yearId=' + val, null,
              function(r) { //根据学年获取学生成绩
                that.fullscreenLoading = false;
                that.optSemester = r2;
                var SemesterList = [];
                for (var i in r) {
                  if (SemesterList.indexOf(r[i].semester) < 0) {
                    SemesterList.push(r[i].semester);
                  }
                }
                for (var i in SemesterList) {
                  var it = {
                    Semester: null,
                    Academic: [],
                  }
                  it.Semester = SemesterList[i];
                  for (var j in r) {
                    if (SemesterList[i] == r[j].semester) {
                      it.Academic.push(r[j]);
                    }
                  }
                  that.itemAcademic.push(it);
                }
              });
            SearchliteracyCourse(that);
            GetAranscript(that);
            SearchActivity(that);
            SearchMorality(that);
            SearchPunish(that);
            SearchComment(that);
            SearchPrize(that);
            SearchselfReport(that);
            SearchClassCadre(that);
            SearchPunishRevoke(that);
            that.onTabClick();
          });
        }
      },
      //选择全部学年
      onClickTab2() {
        let that = this;
        that.strSemester = "";
        that.data = JSON.parse(JSON.stringify(that.dataReset));
        that.fullscreenLoading = true;
        ajaxGet(that, "/api/admin/semester", null, function(r) {
          that.fullscreenLoading = false;
          that.optSemester = r;
        });
        SearchliteracyCourse(that);
        GetAranscript(that);
        SearchActivity(that);
        SearchMorality(that);
        SearchPunish(that);
        SearchComment(that);
        GetAcademic(that);
        SearchPrize(that);
        SearchselfReport(that);
        SearchClassCadre(that);
        SearchPunishRevoke(that);
        that.onTabClick();
      },
      //选择学期
      handSemesterChange(val) {
        let that = this;
        that.data.SemesterId = val;
        that.itemAcademic = [];
        ajaxGet(that, "/api/admin/growtharchives/academicsearch/" + that.studentId + '?SemesterId=' + val, null,
          function(r) {
            that.fullscreenLoading = false;
            var SemesterList = [];
            for (var i in r) {
              if (SemesterList.indexOf(r[i].semester) < 0) {
                SemesterList.push(r[i].semester);
              }
            }
            for (var i in SemesterList) {
              var it = {
                Semester: null,
                Academic: [],
              }
              it.Semester = SemesterList[i];
              for (var j in r) {
                if (SemesterList[i] == r[j].semester) {
                  it.Academic.push(r[j]);
                }
              }
              that.itemAcademic.push(it);
            }
          });
        SearchliteracyCourse(that);
        GetAranscript(that);
        SearchActivity(that);
        SearchMorality(that);
        SearchPunish(that);
        SearchComment(that);
        SearchPrize(that);
        SearchselfReport(that);
        SearchClassCadre(that);
        SearchPunishRevoke(that);
        that.onTabClick();
      },
      //计算完成度
      onGetProgress(_targetVar, _nowVar) {
        if (_targetVar == -1) { // PDF
          if (_nowVar == -1) {
            return '100.00 %';
          } else {
            return '0.00 %'
          }
        } else {
          if (_nowVar == 0) {
            return '0.00 %';
          } else {
            let strTime = setTwoDecimal((_nowVar / _targetVar) * 100, 2);
            return strTime >= 100 ? '100.00 %' : strTime + ' %';
          }
        }
      },
      /**
       * //返回
       */
      onBack() {
        let that = this;
        back(that);
      },
      /**
       * //头像
       * @param {Object} _data
       */
      onAvatorUrl(_data) {
        let that = this;
        let tempUrl = '';
        if (_data.photo == null || typeof(_data.photo) == "undefined" || _data.photo == '') {
          if (that.onGender(_data.gender)) {
            // 男
            tempUrl = require('@/assets/image/defaultAvatar_Male.jpg');
          } else {
            tempUrl = require('@/assets/image/defaultAvatar_Female.jpg');
            // 女
          }
        } else {
          tempUrl = that.onImgSpliceUrl(_data.photo);
        }
        return tempUrl;
      },
      /**
       *  //性别判断，男生:true  女生:false
       * @param {Object} obj
       */
      onGender(obj) {
        if (obj == '男') {
          return true;
        } else {
          return false;
        }
      },
    },
    created() {
      let that = this;
      that.studentId = that.$route.params.id;
      that.data.StudentId = that.$route.params.id;

      
      that.fullscreenLoading = true;

      that.dataReset = JSON.parse(JSON.stringify(that.data));
      ajaxGet(that, "/api/admin/student/" + that.$route.params.id, null, function(res) { //获取学生基础数据
        ajaxGet(that, "/api/admin/semester/student/" + that.studentId, null, function(resultSemeter) { //获取学期
          ajaxGet(that, "/api/admin/academic/year/" + that.studentId, null, function(resYear) { // 获取学生学年

            if(Array.isArray(resultSemeter)){
              resultSemeter.forEach(v=>{
               console.log()
                if(v.isCurrent){
                  that.strSemester = v.semesterId
                  that.data.SemesterId = v.semesterId
                  that.data.YearId = v.yearId
                }
              })
            }
            that.fullscreenLoading = false;
            that.itemInfo = res;
            that.optSemester = resultSemeter;
            that.imageUrl = that.onAvatorUrl(res);
            that.optYear = resYear;
            let arrTemp = [];
            arrTemp.push({
              yearId: 0,
              name: '全部档案'
            })
            for (let item of resYear) {
           
              arrTemp.push({
                yearId: item.yearId,
                name: item.name
              })
            }
            that.optYear = arrTemp;

            const index  = that.optYear.findIndex(v=>v.yearId === that.data.YearId)

            if(index != -1){
              that.currsorIndex = index
            }

         
            SearchliteracyCourse(that);
            GetAranscript(that);
            SearchActivity(that);
            SearchMorality(that);
            SearchPunish(that);
            GetAcademic(that);
            SearchComment(that);
            SearchPrize(that);
            SearchselfReport(that);
            SearchClassCadre(that);
            SearchPunishRevoke(that);
            onGetChartData(that);
            onGetChartReport(that);

          });
        });
      });

    

    },
    mounted() {
      // 向左切图
      $('.listBox').on('click', '.aLeftOperation', function() {
        let imgTotal = $(this).parent('.imgListBox').find('.imgContent').find('.spImg').length;
        let mLeftValue = calculationLeft($(this).parent('.imgListBox').find('.imgContent').find('.imgBox').attr(
          'data-left'), 'LEFT', imgTotal);
        $(this).parent('.imgListBox').find('.imgContent').find('.imgBox').attr('data-left', mLeftValue);
        $(this).parent('.imgListBox').find('.imgContent').find('.imgBox').animate({
          'margin-left': mLeftValue + 'px'
        });
      });
      // 向右切图
      $('.listBox').on('click', '.aRightOperation', function() {
        let imgTotal = $(this).parent('.imgListBox').find('.imgContent').find('.spImg').length;
        let mLeftValue = calculationLeft($(this).parent('.imgListBox').find('.imgContent').find('.imgBox').attr(
          'data-left'), 'RIGHT', imgTotal);
        $(this).parent('.imgListBox').find('.imgContent').find('.imgBox').attr('data-left', mLeftValue);
        $(this).parent('.imgListBox').find('.imgContent').find('.imgBox').animate({
          'margin-left': mLeftValue + 'px'
        });
      });
      // 计算位置
      function calculationLeft(obj, type, total) {
        let intValue = parseInt(obj);
        if (type.toUpperCase() == 'LEFT') {
          if ((parseInt(total - 1) * 50) > (intValue * -1)) {
            intValue = intValue - 50;
          }
        } else {
          if (parseInt(intValue) != 0) {
            intValue = intValue + 50;
          }
        }
        return intValue;
      };
    },

  };
  //活动记录
  function SearchActivity(that) {
    that.fullscreenLoading = true;
    var dataActivity = JSON.parse(JSON.stringify(that.data));
    ajaxGet(that, '/api/admin/growtharchives/activitysearch', dataActivity, function(resData) {
      that.fullscreenLoading = false;
      that.itemActivityTotal = resData.items.length;
      for (var i in resData.items) {
        if (resData.items[i].attachment != null) {
          var list = resData.items[i].attachment.split('|');
          var it = [];
          for (var j in list) {
            it.push(that.onImgSpliceUrl(list[j]));
          }
          resData.items[i].photoList = it;
        }
        if (!regNullArray(resData.items[i].photo)) {
          var list = resData.items[i].photo.split('|');
          var it = [];
          for (var j in list) {
            it.push(that.$ServerUrl() + list[j]);
          }
          resData.items[i].photoList = it;
          // resData.items[i].photoList.push(...it);
        }
      }
      that.itemActivity = resData.items;
    });
  }
  //行为规范
  function SearchMorality(that) {
    that.fullscreenLoading = true;
    var dataMorality = JSON.parse(JSON.stringify(that.data));
    ajaxGet(that, '/api/admin/growtharchives/moralsearch', dataMorality, function(resData) {
      that.fullscreenLoading = false;
      that.itemMorality = resData.items;
    });
  }
  //处分记录
  function SearchPunish(that) {
    that.fullscreenLoading = true;
    var dataPunish = JSON.parse(JSON.stringify(that.data));
    ajaxGet(that, '/api/admin/growtharchives/punishsearch', dataPunish, function(resData) {
      that.fullscreenLoading = false;
      that.itemPunish = resData.items;
    });
  }
  //处分撤销记录
  function SearchPunishRevoke(that) {
    that.fullscreenLoading = true;
    var dataPunishRevoke = JSON.parse(JSON.stringify(that.data));

    console.log(dataPunishRevoke,'')
    ajaxGet(that, '/api/admin/growtharchives/punishrevokesearch', dataPunishRevoke, function(resData) {
      that.fullscreenLoading = false;
      that.itemPunishRevoke = resData;
    });
  }
  //自我记录
  function SearchselfReport(that) {
    that.fullscreenLoading = true;
    var dataSelfReport = JSON.parse(JSON.stringify(that.data));
    var id = that.$route.params.id;
    ajaxGet(that, '/api/admin/growtharchives/selfreportsearch', dataSelfReport, function(r) {
      that.fullscreenLoading = false;
      that.itemSelfReport = r;
    })
  }
  //综合课堂
  function SearchliteracyCourse(that) {
    that.fullscreenLoading = true;
    var dataLiteracyCourse = JSON.parse(JSON.stringify(that.data));
    ajaxGet(that, '/api/admin/growtharchives/literacycoursesearch', dataLiteracyCourse, function(r) {
      that.fullscreenLoading = false;
      that.itemLiteracyCourse = r;
    })
  }
  //成绩
  function GetAcademic(that) {
    that.fullscreenLoading = true;
    var id = that.$route.params.id;
    that.itemAcademic = [];
    ajaxGet(that, "/api/admin/growtharchives/academicsearch/" + id, null, function(r) {
      that.fullscreenLoading = false;
      var SemesterList = [];
      for (var i in r) {
        if (SemesterList.indexOf(r[i].semester) < 0) {
          SemesterList.push(r[i].semester);
        }
      }
      for (var i in SemesterList) {
        var it = {
          Semester: null,
          Academic: [],
        }
        it.Semester = SemesterList[i];
        for (var j in r) {
          if (SemesterList[i] == r[j].semester) {
            it.Academic.push(r[j]);
          }
        }
        that.itemAcademic.push(it);
      }
    })
  }
  //学业成绩 itemAranscript
  function GetAranscript(that) {
    that.fullscreenLoading = true;
    that.itemAranscript = [];
    var dataLiteracyCourse = JSON.parse(JSON.stringify(that.data));
    ajaxGet(that, "/api/admin/manual/search/transcript", dataLiteracyCourse, function(resData) {
      that.fullscreenLoading = false;
      var SemesterList = [];
      that.itemAranscript = resData.items;
    })
  }
  //老师评语
  function SearchComment(that) {
    that.fullscreenLoading = true;
    var dataComment = JSON.parse(JSON.stringify(that.data));
    ajaxGet(that, '/api/admin/growtharchives/commentsearch', dataComment, function(resData) {
      that.fullscreenLoading = false;
      that.itemComment = resData.items;
    })
  }
  //奖励
  function SearchPrize(that) {
    that.fullscreenLoading = true;
    var dataPrize = JSON.parse(JSON.stringify(that.data));
    let newdataPrize = {
      YearId: dataPrize.YearId,
      StudentId: dataPrize.StudentId,
      SemesterId: dataPrize.SemesterId
    }
    ajaxGet(that, '/api/admin/growtharchives/prizesearch', newdataPrize, function(resData) {
      that.fullscreenLoading = false;
      for (var i in resData.items) {
        resData.items[i].photoList = [];
        if (resData.items[i].attachment != null) {
          var list = resData.items[i].attachment.split('|');
          var it = [];
          for (var j in list) {
            it.push(that.$ServerUrl() + list[j]);
          }
          resData.items[i].photoList.push(...it);
        }
      }
      that.itemPrize = resData.items;
    });
  }
  //履历记录
  function SearchClassCadre(that) {
    that.fullscreenLoading = true;
    var id = that.$route.params.id;
    var dataClassCadre = JSON.parse(JSON.stringify(that.data));
    var data = {
      id: id,
      yearId: dataClassCadre.YearId,
      semesterId: dataClassCadre.SemesterId,
    }
    that.ResumeCount = 0;
    ajaxGet(that, '/api/admin/growtharchives/classcadresearch/' + id, data, function(r) {
      that.itemClassCadre = r;
      if (!regNullArray(that.itemClassCadre)) {
        that.ResumeCount += that.itemClassCadre.length;
      }
      that.fullscreenLoading = false;
      //学生会履历记录
      SearchAssociation(that);
    });
  }
  //学生会履历记录
  function SearchAssociation(that) {
    that.fullscreenLoading = true;
    var id = that.$route.params.id;
    var dataAssociation = JSON.parse(JSON.stringify(that.data));
    var data = {
      id: id,
      yearId: dataAssociation.YearId,
      semesterId: dataAssociation.SemesterId,
    }
    ajaxGet(that, '/api/admin/growtharchives/associationsearch/' + id, data, function(r) {
      that.itemAssociation = r;
      if (!regNullArray(that.itemAssociation)) {
        that.ResumeCount += that.itemAssociation.length;
      }
      that.fullscreenLoading = false;
    });
  }
  /**
   * @description 获取雷达图
   * @param {Object} that
   * @param {Object} _type
   */
  function onGetChartData(that) {
    let tempData = {
      YearId: that.data.YearId,
      SemesterId: that.data.SemesterId,
      StudentId: that.data.StudentId
    };

    ajaxGet(that, '/api/admin/growth/chartdata', tempData, function(resData) {
      that.chartData = resData;
      if (!regNullArray(resData)) {
        that.tabslist = [{
          name: '总评',
          value: null
        }];
        that.tabslist.push(
          ...resData.map(item => ({
            name: item.name,
            value: item.configId
          }))
        );
      }
    });
  }

  function onGetChartReport(that) {
    let tempData = {
      YearId: that.data.YearId,
      SemesterId: that.data.SemesterId,
      StudentId: that.data.StudentId,
    };
    ajaxGet(that, '/api/admin/growtharchives/studentreport', tempData, function(resData) {
      that.$refs.lineRight.onGetRadar(resData);
      that.reportData = resData;
    });
  }
</script>

<style scoped="scoped" lang="less">
  .echartsBox {
    border-right: 2px dashed #efefef;
  }

  .tabsHeader {
    margin: -18px -20px;
    background-color: #f5f7fa;
    position: relative;

    .divSemesterBox {
      position: absolute;
      right: 6px;
      top: 6px;

      .spTitle {
        font-size: 14px;
      }
    }
  }

  .award {
    .awardImg {
      width: 90%;
      margin: 0px auto;
      padding-top: 15px;
    }

    .awardName {
      background-color: #8fb8ff;
      text-align: center;
      padding: 2px 5px;
      color: #FFFFFF;
      width: 90%;
      margin: 0px auto;
    }

    .awardType {
      text-align: center;
      color: #b0b5c9;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .awardTime {
      padding-bottom: 15px;
      color: #414b62;
      text-align: center;
    }
  }

  .box-card {
    margin: 0;
  }

  .commentLevel1 {
    color: #F8AD3A !important;
  }

  .commentLevel2 {
    color: #3DDC18 !important;
  }

  .commentLevel3 {
    color: #FF3368 !important;
  }

  .commentLevel4 {
    color: #ACB2C6 !important;
  }

  .commentLevel .icon {
    font-size: 32px;
  }

  .pageBox {
    .commentHeader {
      padding-bottom: 10px;

      .leftBox {
        float: left;
        font-size: 24px;
      }

      .rightBox {
        float: right;
        padding-top: 5px;
      }
    }

    .commentList {
      background-color: #F8F9FF;
      padding: 20px;
      border-radius: 10px;
      margin-top: 15px;
      margin-bottom: 15px;

      .semesterName {
        font-size: 22px;
        color: #0B1D59;
      }

      .levelBox {
        font-weight: bold;
        font-size: 22px;
        padding-top: 10px;
        padding-bottom: 10px;
      }

      .teacherBox {
        position: relative;
        height: 30px;

        .teacherImage {
          position: absolute;
          left: 0;
          top: 0;
          width: 50px;
          height: 70px;
        }

        .maleBox {
          background-color: #23A3F8;
        }

        .femaleBox {
          background-color: #FFB7C2;
        }

        .teacherName {

          font-size: 18px;
          color: #0B1D59;
          padding-top: 10px;
        }

        .commentTime {

          color: #8EA5C0;
          padding-top: 2px;
        }
      }

      .commentFont {
        padding-top: 15px;
        color: #0B1D59;
      }
    }

    .listHeader {
      padding: 10px;

      .leftBox {
        float: left;
        font-size: 32px;
        padding-left: 10px;
      }

      .rightBox {
        float: right;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 15px;
      }
    }

    .listBox {
      padding: 0;

      .schoolYearBox {
        display: none;
      }
    }

    .schoolYear {
      position: relative;

      .schoolYearBox {
        position: absolute;
        display: inline-block;
        background-color: #307bff;
        color: #FFFFFF;
        top: 0;
        left: 0;
        height: 30px;
        line-height: 30px;
        padding-left: 15px;
        padding-right: 15px;
        border-radius: 4px 0px 0px 0px;
      }
    }
  }

  .pageBox .schoolYear .tableFiles th {
    padding-top: 40px;
  }


  .tableFiles {
    width: 100%;
  }

  .tableFiles th {
    background-color: #EAEFFF;
    font-weight: normal;
    color: #323D56;
    padding: 15px;
    font-size: 18px;
  }

  .tableFiles td {
    color: #323D56;
    font-weight: normal;
    height: 50px;
    padding: 0px 15px;
  }

  .tableFiles tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  .pageBox .listBox .spLevel {
    background-color: #F8F9FA;
    font-size: 14px;
    padding: 2px;
    margin-right: 5px;
  }

  .pageBox .imgListBox {
    height: 50px;
    position: relative;
    width: 99.9%;
    overflow: hidden;
    padding-left: 35px;
    padding-right: 35px;
  }

  .pageBox .singleImageBox {
    padding-left: 15px;
    padding-right: 15px;
    width: 80px;
  }

  .pageBox .imgListBox .aLink {
    position: absolute;
    font-size: 32px;
    top: 8px;
    bottom: 0;
  }

  .pageBox .imgListBox .aLeftOperation {
    float: left;
    left: 0;
    width: 30px;
  }

  .pageBox .imgListBox .aRightOperation {
    float: right;
    right: 0;
    width: 30px;
  }

  .pageBox .imgListBox .imgContent {
    position: absolute;
    left: 30px;
    right: 30px;
    top: 0;
    bottom: 0;
    white-space: nowrap;
    overflow: hidden;
  }

  .pageBox .imgListBox .imgContent .imgBox {
    height: 50px;
    width: 1000px;
  }

  .pageBox .imgListBox .imgContent .spImg {
    float: left;
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 4px;
    border: 1px solid #EEEEEE;
    margin: 5px;
  }

  .pageBox .imgListBox .imgContent .spImg .itemImg {
    width: 150%;
  }

  .pageBox .singleImageBox .imgContent {
    left: 0;
    right: 0;
  }

  .pageFooter {
    padding: 10px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.03);
    height: 50px;
  }


  .pageBox .cardTypeLable {
    border: 2px solid #FFFFFF;
  }

  .pageBox .cardTypeAct,
  .pageBox .cardTypeLable:hover {
    cursor: pointer;
    border: 2px solid #3364FF;
  }

  .pageBox .studentInfoBox {
    background-color: #FFFFFF;
    padding: 20px 30px;
    border-radius: 10px;
    position: relative;
  }

  .pageBox .studentInfoBox .studentName {
    font-size: 32px;
    color: #323D56;
    display: flex;

    .spLabelList {
      margin-top: 7px;
      margin-left: 15px;
      margin-right: 15px;
      font-size: 16px;
    }
  }

  .pageBox .studentInfoBox .studentLabel {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .pageBox .studentInfoBox .studentLabel .spLabel {
    border-right: 2px solid #E4E4E4;
    padding-left: 30px;
    padding-right: 30px;
    color: #323D56;
    white-space: nowrap;
    /*强制span不换行*/
    letter-spacing: 1px;
  }

  .pageBox .studentInfoBox .studentLabel .spLabel:first-child {
    padding-left: 0;
  }

  .pageBox .studentInfoBox .studentLabel .spLabel:last-child {
    padding-right: 0;
    border-right: none;
  }

  .pageBox .studentInfoBox .studentImageBox {
    position: absolute;
    top: 30px;
    right: 40px;
    width: 100px;
    height: 140px;
  }

  .pageBox .studentInfoBox .studentImageBox .studentGender {
    position: absolute;
    top: -15px;
    right: -15px;
    width: 30px;
    height: 30px;
    background-color: #1298FC;
    color: #FFFFFF;
    border-radius: 50%;
  }

  .pageBox .studentInfoBox .studentImageBox .genderFemale {
    background-color: #FF3EC9;
  }

  .pageBox .studentInfoBox .studentImageBox img {
    width: 100px;
    height: 140px;
    border-radius: 4px;
    border: 1px solid #EEEEEE;
  }


  .pageBox .typeLabelBox {
    height: 80px;
  }

  .pageBox .typeLabelBox .rightBox,
  .pageBox .typeLabelBox .leftBox {
    float: left;
  }

  .pageBox .typeLabelBox .leftBox {
    text-align: center;
    width: 100px;
    height: 80px;
  }

  .pageBox .typeLabelBox .iconBox {
    background-color: #EEEEEE;
    color: #000000;
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }

  .pageBox .typeLabelBox .iconBox .icon {
    font-size: 34px;
  }

  .pageBox .typeLabelBox .numberBox {
    color: #323D56;
    font-family: 'OswaldLight';
    font-size: 34px;
    letter-spacing: 1px;
    font-weight: bold;
  }

  .pageBox .typeLabelBox .numberBox .spUnit {
    font-size: 18px;
    padding-left: 5px;
  }

  .pageBox .typeLabelBox .typeName {
    color: #ACB2C6;
    letter-spacing: 1px;
  }








  .pageBox .typeLabelBox .labelColor_1 {
    background-color: #E5FBFA;
    color: #17DCCD;
  }

  .pageBox .typeLabelBox .labelColor_2 {
    background-color: #EAEFFF;
    color: #3364FF;
  }

  .pageBox .typeLabelBox .labelColor_3 {
    background-color: #FFF5F8;
    color: #FFA1C0;
  }

  .pageBox .typeLabelBox .labelColor_4 {
    background-color: #FFF8EB;
    color: #FFBC20;
  }

  .pageBox .typeLabelBox .labelColor_5 {
    background-color: #E9FBE5;
    color: #3DDC18;
  }

  .pageBox .typeLabelBox .labelColor_6 {
    background-color: #FFEAF4;
    color: #FF3368;
  }

  .pageBox .typeLabelBox .labelColor_7 {
    background-color: #EAFFFE;
    color: #33C1FF;
  }




  .divPageHeaderBox {
    display: flex;
    padding-bottom: 5px;
  }

  .divPageHeaderBox .ulTabsBox {
    flex: 1;
    display: flex;
    padding-top: 10px;
  }

  .divPageHeaderBox .liTab {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 18px;
    border-right: 2px solid #CECECE;
    color: #767676;
    cursor: pointer;
  }

  .divPageHeaderBox .liTab:hover {
    color: #3E3E3C;
  }

  .divPageHeaderBox .liTab:last-child {
    border-right: none;
  }

  .divPageHeaderBox .liAction:hover,
  .divPageHeaderBox .liAction {
    color: #3364FF;
  }

  .divPageHeaderBox .divSemesterBox {
    display: flex;
  }

  .divPageHeaderBox .divSemesterBox .divTitle {
    height: 40px;
    line-height: 40px;
    letter-spacing: 2px;
    color: #323E56;
    padding-right: 10px;
  }
</style>


<style scoped="scoped">
  @media screen and (max-width: 1440px) {}
</style>
<style lang="less">
  .tabsHeader {
    .el-tabs__header {
      margin: 0;
      margin-bottom: -1px;
    }

    .el-tabs--card>.el-tabs__header .el-tabs__nav {
      border: none;
    }

    .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
      background-color: #FFFFFF;
    }
  }


  .pageBox .commentHeader .selDivForm .el-input__inner {
    border: 1px solid #307BFF;
    height: 30px;
    border-radius: 30px;
    color: #307BFF;
  }

  .pageBox .commentHeader .selDivForm .el-input__icon {
    line-height: 25px;
  }

  .pageBox .commentHeader .selDivForm .el-select__caret {
    color: #307BFF;
  }

  .schoolYearBox {
    background: skyblue;
    height: 50px;
    text-align: center;
    line-height: 50px;
    font-size: 20px;
  }
</style>
