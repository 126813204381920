<!--
  综合活动 活动详情
-->
<template>
  <div class="SubWebPageStudent DetailsPro">
    <!-- <div class="divBreadcrumbCont">
     <router-link to="/Student/Home/Index" class="alinkUrl">首页</router-link>
     /
     <router-link to="/Student/LiteracyActivities/Index" class="alinkUrl">日常活动</router-link>
     /
     <span class="spNowUrl">活动详情</span>
    </div> -->
    <!--活动报名-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          活动详情
        </h3>
      </div>
      <div class="card-body p-0">
        <el-row :gutter="24" v-if="imgArr!=''">
          <el-col :span="8">
            <el-carousel height="300px" style="padding-left: 20px;">
              <el-carousel-item v-for="(item,index) in imgArr" :key="index">
                <img :src="item" alt="" width="100%">
              </el-carousel-item>
            </el-carousel>
          </el-col>
          <el-col :span="16">
            <div class="infoList clearfix">
              <span class="spName">{{itemData.name}}</span>
            </div>
            <div class="infoList clearfix" v-if="itemData.applyStartDate!=null">
              <span class="spFonts">
                <span class="spLabel">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#fax-clock-o"></use>
                  </svg>
                  &nbsp;
                  报名时间
                </span>
                <span>{{itemData.applyStartDate|dateformatMinute}} 至 {{itemData.applyEndDate|dateformatMinute}}</span>
              </span>
            </div>
            <div class="infoList clearfix" v-if="itemData.startDate!=null">
              <span class="spFonts">
                <span class="spLabel">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#fax-date-clock-o"></use>
                  </svg>
                  &nbsp;
                  活动时间
                </span>
                &nbsp;
                <span>{{itemData.startDate|dateformatMinute}} 至 {{itemData.endDate|dateformatMinute}}</span>
              </span>
            </div>
            <div class="infoList clearfix">
              <!--  -->
              <span class="spFonts" v-if="itemData.numberLimit!=null">
                <span class="spLabel">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#fax-cube-users-alt"></use>
                  </svg>
                  &nbsp;
                  报名人数
                </span>
                &nbsp;
                <span class="spNum">{{itemData.numberLimit}}</span>
              </span>
              <span class="spFonts" v-if="itemData.place!=null">
                <span class="spLabel">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#fax-fax-map-marker-o"></use>
                  </svg>
                  &nbsp;
                  地址
                </span>
                {{itemData.place}}
              </span>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="24" v-else>
          <el-col :span="24">
            <div class="infoList clearfix">
              <span class="spName">{{itemData.name}}</span>
            </div>
            <div class="infoList clearfix" v-if="itemData.applyStartDate!=null">
              <span class="spFonts">
                <span class="spLabel">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#fax-clock-o"></use>
                  </svg>
                  &nbsp;
                  报名时间
                </span>
                <span>{{itemData.applyStartDate|dateformatMinute}} 至 {{itemData.applyEndDate|dateformatMinute}}</span>
              </span>
            </div>
            <div class="infoList clearfix" v-if="itemData.startDate!=null">
              <span class="spFonts">
                <span class="spLabel">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#fax-date-clock-o"></use>
                  </svg>
                  &nbsp;
                  活动时间
                </span>
                &nbsp;
                <span>{{itemData.startDate|dateformatMinute}} 至 {{itemData.endDate|dateformatMinute}}</span>
              </span>
            </div>
            <div class="infoList clearfix">
              <!--  -->
              <span class="spFonts" v-if="itemData.numberLimit!=null">
                <span class="spLabel">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#fax-cube-users-alt"></use>
                  </svg>
                  &nbsp;
                  报名人数
                </span>
                &nbsp;
                <span class="spNum">{{itemData.numberLimit}}</span>
              </span>
              <span class="spFonts" v-if="itemData.place!=null">
                <span class="spLabel">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#fax-fax-map-marker-o"></use>
                  </svg>
                  &nbsp;
                  地址
                </span>
                {{itemData.place}}
              </span>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-card>

    <!--活动内容-->
    <el-card class="box-card" style="margin-top: 16px;" v-if="itemData.description!=null">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          活动内容
        </h3>
      </div>
      <div class="card-body">
        <div v-html="itemData.description">

        </div>
      </div>
    </el-card>
    <!-- 上传附件 -->
    <el-card class="box-card" style="margin-top: 16px;">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          上传附件
        </h3>
      </div>
      <div class="card-body pt-5">
        <el-row :gutter="24">
          <el-col :span="24">
            <comUpload :key="random" @onPictureHandleSuccess="onPictureHandleSuccess" :files="uploadFiles"></comUpload>
            <!-- <el-alert style="margin-top: -30px;margin-bottom: 10px;" :title="uploadTitle" type="info" show-icon
              :closable="false">
            </el-alert>
            <el-upload class="upload-demo" action="" :on-preview="handlePreview" :file-list="fileList"
              :http-request="uploadSectionFile" :auto-upload="true" :multiple="true" :before-upload="beforeUpload"
              :limit="8" :on-remove="handleRemove">
              <div style="float:left;">
                <el-button slot="trigger" size="small" type="primary" icon="el-icon-plus">上传文件</el-button>
              </div>
            </el-upload> -->
          </el-col>
        </el-row>
      </div>
    </el-card>




    <div class="row" style="margin-top: 10px;">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
        <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
        <el-button type="primary" icon="el-icon-circle-check" @click="onSave()">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import '@/assets/css/Student.css';
  import comUpload from '@/components/Upload.vue';
  export default {
    components: {
      comUpload
    },
    data() {
      return {
        // 动画
        fullscreenLoading: false,
        dialogVisible: false,
        random: 0, //刷新组件
        uploadFiles: [],
        filesReset: null,
        //列表数据
        itemData: [],
        ruleForm: {
          activityId: "",
          attachment: "",
        },
        imgArr: [],
        fileList: [],
        fileListReset: null,
        uploadTitle: "",
        newFileType: [], //存放文件上传的类型
      };
    },
    mounted() {

    },
    methods: {
      handleRemove(file) {
        let that = this;
        var number = -1;
        for (var i in that.fileList) {
          var it = that.fileList[i];
          if (it.uid == file.uid) {
            number = i;
            break;
          }
        }
        if (number >= 0) {
          that.fileList.splice(i, 1);
        }
      },
      beforeUpload(file) {
        let that = this;

        var fileTypeLenght = file.name.split('.').length;
        var fileType = file.name.split('.')[fileTypeLenght - 1].toLocaleLowerCase();
        var isResult = false;
        for (var it of that.newFileType) {
          if (fileType == it) {
            isResult = true;
          }
        }
        // const isJPG = fileType === 'jpg';
        // const isJPEG = fileType === 'jpeg';
        // const isPNG = fileType === 'png';
        // const isBMP = fileType === 'bmp';
        // const isDOC = fileType === 'doc';
        // const isDOCX = fileType === 'docx';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isResult) {
          warning(that, that.uploadTitle);
        }
        if (!isLt2M) {
          warning(that, '上传图片大小不能超过 2MB!');
        }
        return isResult && isLt2M;

      },
      uploadSectionFile(param) {
        let that = this;
        var fileObj = param.file;

        // 接收上传文件的后台地址
        var url = "/api/admin/file/upload";
        let files = new FormData();
        files.append('file', fileObj);
        // files.append('tag', "punish");
        that.fullscreenLoading = true;

        ajaxUpload(that, url, files, function(r) {
          that.fullscreenLoading = false;

          if (r.result == true) {
            var file = {
              uid: param.file.uid,
              url: that.$ServerUrl() + r.path,
              path: r.path,
              name: param.file.name,
              size: param.file.size,
              duration: that.videoDuration
            };
            that.fileList.push(file);
            that.onPictureHandleSuccess(that.fileList);
          } else {
            warning(that, r.message);
          }

        });
      },
      handlePreview(file) {
        window.open(file.url);
      },
      onBack() {
        let that = this;
        back(that);
      },
      onSave() {
        let that = this;
        that.fullscreenLoading = true;
        ajaxPut(that, '/api/student/activity/upload/' + that.ruleForm.activityId + '?attachment=' + that.ruleForm
          .attachment, null,
          function(r) {
            that.fullscreenLoading = false;
            that.onBack();
          })
      },
      onReset() {
        let that = this;
        that.fileList = JSON.parse(JSON.stringify(that.fileListReset));
        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;
      },
      onPictureHandleSuccess(param) {
        let that = this;
        that.ruleForm.attachment = "";
        for (var i in param) {
          if (that.ruleForm.attachment == "") {
            that.ruleForm.attachment = param[i].path;
          } else {
            that.ruleForm.attachment += "|" + param[i].path;
          }
        }
      },
    },
    created() {
      let that = this;
      let id = that.$route.params.id;
      that.ruleForm.activityId = id;
      that.fullscreenLoading = true;
      ajaxGet(that, "/api/student/activity/" + id, null, function(r) {

        if (r.attachment != null) {
          var attachments = r.attachment.split('|');
          for (var i in attachments) {
            that.imgArr.push(that.$ServerUrl() + attachments[i]);
          }
        }
        that.fullscreenLoading = false;
        that.itemData = r;

        that.uploadFiles = [];
        that.ruleForm.attachment = r.selfAttachment;
        if (r.selfAttachment != null && typeof(r.selfAttachment) != "undefined" && r.selfAttachment != "") {
          var files = r.selfAttachment.split('|');
          for (var i in files) {
            var it = i + 1;
            that.uploadFiles.push({
              uid: it,
              path: files[i],
            });
          }
          var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
          that.random += random;
        }

        if (that.uploadFiles != null && that.uploadFiles.length > 0) {
          that.fileList = JSON.parse(JSON.stringify(that.uploadFiles));
          for (var i in that.fileList) {
            that.fileList[i].url = that.$ServerUrl() + that.fileList[i].path;
            if (that.fileList[i].name == null || typeof(that.fileList[i].name) == "undefined" || that.fileList[i]
              .name ==
              "") {
              var urls = that.fileList[i].path.split('/');
              that.fileList[i].name = urls[urls.length - 1];
            }
          }
        } else {
          that.fileList = [];
        }
        that.fileListReset = JSON.parse(JSON.stringify(that.fileList));

        //获取文件上传的类型
        var fileTypeList = that.itemData.fileType.split(',');
        that.newFileType = [];
        that.uploadTitle = '只允许上传';
        for (var it of fileTypeList) {
          if (it == '1') {
            that.newFileType.push('jpg');
            that.uploadTitle += ' jpg ';
          } else if (it == '2') {
            that.newFileType.push('jpeg');
            that.uploadTitle += ' jpeg ';
          } else if (it == '3') {
            that.newFileType.push('png');
            that.uploadTitle += ' png ';
          } else if (it == '4') {
            that.newFileType.push('bmp');
            that.uploadTitle += ' bmp ';
          } else if (it == '5') {
            that.newFileType.push('doc');
            that.uploadTitle += ' doc ';
          } else {
            that.newFileType.push('docx');
            that.uploadTitle += ' docx ';
          }
        }
        that.uploadTitle += ' 格式的文件';




      });
    },
  };
</script>

<style scoped="scoped">
  .dialogBox {
    padding: 10px 5px;
    height: 140px;
    overflow: hidden;
  }

  .dialogVideoBox {
    padding: 10px 5px;
  }

  .dialogBox .mailbox-attachment-icon {
    font-size: 32px;
    padding: 15px 10px;
  }

  .dialogBox .mailbox-attachment-size>span {
    font-size: 12px;
  }

  .dialogBox .mailbox-attachment-info {
    padding: 7px 10px;
  }



  .bodyAwardsCont .stuAwardsCont {
    position: relative;
    padding: 0px 15px 45px 15px;
  }

  .bodyAwardsCont .stuAwardsCont .stuAvatar {
    position: absolute;
    left: 15px;
    top: 0px;
    border: 1px solid #eee;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 5px;
  }

  .bodyAwardsCont .stuAwardsLabel {
    font-size: 12px;
    padding-left: 65px;
    color: #B1B1B1;
  }

  .bodyAwardsCont .awardsName {
    padding-top: 10px;
    color: #3e3e3e;
  }

  .bodyAwardsCont .stuAwardsCont .stuName {
    font-size: 16px;
    padding-left: 65px;
  }

  .bodyAwardsCont .stuAwardsFont {
    font-size: 14px;
    padding-left: 65px;
    color: #666;
  }

  .bodyAwardsCont .stuFileCont {
    padding-top: 5px;
  }




  .bodyRecordCont .stuRecordCont {
    position: relative;
    padding: 0px 15px 45px 15px;
    padding-left: 75px;

  }

  .bodyRecordCont .stuRecordCont .stuAvatar {
    position: absolute;
    left: 15px;
    top: 0px;
    border: 1px solid #eee;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 5px;
  }


  .bodyRecordCont .stuRecordCont .stuName {
    font-size: 16px;
  }

  .bodyRecordCont .stuRecordFont {
    font-size: 12px;
    padding-top: 5px;
    color: #767676;
  }

  .bodyRecordCont .stuRecordLabel {
    font-size: 12px;
    padding-top: 5px;
    color: #B1B1B1;
  }

  .bodyRecordCont .stuFileCont {
    padding-top: 10px;
  }

  .bodyRecordCont .stuFileCont .divFileIcon {
    border: 1px solid #eee;
    width: 100%;
    height: 80px;
    overflow: hidden;
    border-radius: 4px;
  }

  .bodyRecordCont .stuFileCont .divFileIcon .elIcon {
    font-size: 32px;
  }





  .colRecordOther {}

  .colRecordOther .divList {
    float: left;
    width: 33.3%;
  }

  .colRecordCont {
    padding: 5px;
    font-size: 12px;
    color: #767676;
  }

  .divVideoCont {
    overflow-y: auto;
    width: 100%;
    height: 500px;
    padding-right: 10px;
  }

  .divVideoCont ul li .aItem {
    display: block;
    padding: 7px 0px;
    padding-left: 30px;
    border-bottom: 1px dotted #DDDDDD;
    position: relative;
  }

  .divVideoCont ul li:last-child .aItem {
    border-bottom: none;
  }

  .divVideoCont ul li:hover {
    background-color: #5AAAFF;
    color: #FFFFFF;
  }

  .divVideoCont ul li .aItem span {
    font-family: 'OswaldLight';
    font-size: 14px;
    margin-left: 10px;
  }

  .divVideoCont ul li .aItem .faxIcon {
    position: absolute;
    top: 11px;
    left: 5px;
    display: none;
  }

  .divVideoCont ul li .aItemAct {
    background-color: #007BFF;
    color: #FFFFFF;
    border-bottom: none;
  }

  .divVideoCont ul li .aItemAct .faxIcon {
    display: block;
  }


  .bodyImgCont .mailbox-attachment-icon {
    min-height: 165px;
  }

  .bodyFileCont .mailbox-attachments li,
  .bodyImgCont .mailbox-attachments li {
    width: 250px;
  }

  .bodyImgCont .mailbox-attachment-icon {
    height: 198px;
  }

  .bodyFileCont .mailbox-attachment-size .spFont,
  .bodyImgCont .mailbox-attachment-size .spFont {
    font-size: 12px;
  }





  .tabListCont .divNavTitle {}

  .tabListCont .divNavTitle {}

  .tabListCont .divNavTitle .divTitleCont {
    padding: 10px 0px 10px 10px;
    color: #DEE2E6;
    cursor: pointer;
    font-size: 18px;
  }

  .tabListCont .divNavTitle .divTitleCont .spNum {
    background-color: #DEE2E6;
    font-family: 'OswaldLight';
    padding: 2px 5px;
    border-radius: 4px;
    margin-left: 5px;
    font-size: 14px;
    color: #FFFFFF;
  }

  .tabListCont .divNavTitle .divTitleAct {
    color: #3e3e3e;
  }

  .tabListCont .divNavTitle .divTitleAct .spNum {
    background-color: #17A2B8;
    color: #FFFFFF;
  }

  .tabListCont .divNavTitle .nav-link {
    font-size: 14px;
    color: #B1B1B1;
  }

  .tabListCont .divNavTitle .nav-link .spNum {
    font-family: 'OswaldLight';
    font-weight: normal;
    font-size: 14px;
    padding-left: 10px;
  }

  .tabListCont .divNavTitle .active {
    color: #007bff;
  }




  .DetailsPro .divRoleTitle {
    font-size: 18px;
    color: #323E56;
    padding-top: 20px;
  }

  .DetailsPro .divRoleTitle .spItem {
    float: right;
    margin-left: 5px;
    font-size: 14px;
    color: #28A745;
  }

  .DetailsPro .divRoleTitle .spItem em {
    font-style: normal;
    background-color: #28A745;
    color: #FFFFFF;
    margin-left: 5px;
    margin-right: 5px;
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 12px;
    font-family: 'OswaldLight';
  }

  .DetailsPro .divRoleTitle .el-button {
    padding: 0;
    border: none;
  }

  .DetailsPro .divRoleTitle .el-button:hover {
    background-color: transparent;
  }





  .stuInfo {
    position: relative;
    height: 100%;
    min-height: 80px;
    padding-left: 70px;
    margin: 15px;
  }

  .stuInfo .stuFraction {
    position: absolute;
    top: -5px;
    bottom: 0;
    right: -5px;
    font-family: 'OswaldLight';
    font-size: 26px;
    color: #28A745;
  }

  .stuInfo .stuBtnDown {
    position: absolute;
    bottom: 20px;
    right: 0;
  }

  .stuInfo .stuBtnDown .aView {
    margin-right: 5px;
    margin-left: 5px;
  }

  .stuInfo .stuBtnDown .aView .faxSize {
    font-size: 12px;
    color: #343a40;
  }

  .stuInfo .stuBtnDown .aView:hover .faxSize {
    color: #1666B3;
  }

  .stuInfo .stuImgCont {
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 80px;
    border-radius: 4px;
    overflow: hidden;
  }

  .stuInfo .stuImgCont img {
    width: 100%;
  }

  .stuInfo .stuName {
    color: #212E48;
    font-size: 18px;
    font-weight: bold;
  }

  .stuInfo .stuRole {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
  }

  .stuRole .spStatus {
    font-size: 12px;
    color: #B1B1B1;
  }

  .stuInfo .stuOther {
    color: #767676;
    font-size: 12px;
  }


  .cardTabs .abtnTabs {
    font-size: 16px;
    color: #B1B1B1;
    margin-left: 15px;
    position: relative;
    display: block;

    float: left;
    text-align: center;
  }

  .cardTabs .spDivide {
    float: left;
    padding-left: 15px;
  }


  .cardTabs .abtnTabs:hover {
    color: #323E56;
  }

  .cardTabs .abtnTabs .spFaxIcon {
    display: none;
  }

  .cardTabs .abtnTabs:first-child {
    margin-left: 0;
  }

  .cardTabs .abtnTabs .spNumber {
    background-color: #DEE2E6;
    font-size: 12px;
    color: #FFFFFF;
    font-family: 'OswaldLight';

    padding: 1px 5px;
    border-radius: 5px;
  }



  .cardTabs .abtnTabAct {
    font-size: 1.1rem;
    color: #323E56;
  }

  .cardTabs .abtnTabAct .spFaxIcon {
    display: block;
    position: absolute;
    top: 22px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 12px;
  }

  .cardTabs .abtnTabAct .spNumber {
    background-color: #1666B3;
    color: #FFFFFF;
    font-size: 14px;
  }



  .infoList {
    padding-bottom: 17px;
    padding-left: 30px;
  }

  .infoList .spName {
    font-size: 32px;
    color: #212E48;
    padding-right: 30px;
    height: 40px;
  }


  .infoList .spFonts {
    color: #3B475D;
    padding-right: 30px;
    padding-left: 30px;
    border-right: 1px solid #D6D6D6;
  }

  .infoList .spFonts:first-child {
    padding-left: 0;
  }

  .infoList .spFonts:last-child {
    border-right: none;
  }

  .infoList .spMedal {
    padding-right: 15px;
  }

  .infoList .spFonts .faxSize {
    font-size: 18px;
  }

  .infoList .spFonts .icon {
    color: #FFFFFF;
  }

  .infoList .spStatus {
    border: 1px solid #007BFF;
    background-color: #007BFF;
    color: #FFFFFF;
    padding: 5px 20px;
    border-radius: 5px;

  }

  .infoList .spNStatus {
    border: 1px solid #007BFF;
    background-color: #007BFF;
    color: #FFFFFF;
    padding: 5px 20px;
    border-radius: 5px;
  }

  .infoList .spFonts .spLabel {
    font-size: 14px;
    padding: 2px 5px;
    border-radius: 5px;
    background-color: #B1B1B1;
    color: #FFFFFF;
    margin-right: 10px;
  }

  .infoList .spFonts .spNum {
    padding-left: 10px;
    padding-right: 5px;
    font-family: 'OswaldLight';
    font-size: 16px;
    font-weight: bold;
    color: #DC3545;
  }

  .DetailsPro .colRemarksCont {
    font-size: 14px;
    letter-spacing: 1px;
  }
</style>
