<!--
  学生管理-学生基础信息
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <!-- 搜索 -->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          搜索
        </h3>
      </div>
      <div class="card-body ">
        <el-row :gutter="20">
          <el-col :lg="6" :sm="12" class="mb-3" v-if="!isTeacher">
            <div class="selTitleBox">
              <span class="spTitle">年级</span>
            </div>
            <div class="selBodyBox">
              <el-select v-model="searchForm.selCollegeVar" filterable clearable placeholder="请选择" class="width_100Pie"
                @change="onChangeCollege" @clear="onClearCollege">
                <el-option v-for="item in optCollege" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :lg="6" :sm="12" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">班级</span>
            </div>
            <div class="selBodyBox">
              <el-select v-model="searchForm.selClassVar" filterable :clearable="!isTeacher" placeholder="请选择"
                class="width_100Pie">
                <el-option v-for="item in arrClass" :key="item.value" :label="item.alias" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :lg="6" :sm="12" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">学生</span>
            </div>
            <div class="selBodyBox">
              <el-input placeholder="请输入学号/姓名" v-model="searchForm.Student" clearable></el-input>
            </div>
          </el-col>

          <el-col :lg="6" :sm="12" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">身份证号</span>
            </div>
            <div class="selBodyBox">
              <el-input placeholder="请输入身份证号" v-model="searchForm.IdCardNo" clearable></el-input>
            </div>
          </el-col>

          <el-col :lg="6" :sm="12" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">入学年份</span>
            </div>
            <div class="selBodyBox">
              <el-input placeholder="请输入入学年份" v-model="searchForm.EntryYear" clearable></el-input>
            </div>
          </el-col>
          <el-col :lg="6" :sm="12" class="mb-3">
            <div class="selTitleBox">
              学籍状态
            </div>
            <div class="selBodyBox">
              <el-select v-model="searchForm.selStatus" filterable placeholder="请选择" class="width_100Pie">
                <el-option v-for="item in arrStatus" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :lg="6" :sm="12" class="mb-3">
            <div class="selTitleBox">
              门禁组
            </div>
            <div class="selBodyBox">
              <el-select v-model="searchForm.DuMuGroupId" filterable placeholder="请选择" class="width_100Pie" clearable>
                <el-option v-for="item in updateGroup.option" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="card-footer text-center">
        <el-button type="primary" class="mr-2" slot="append" icon="el-icon-search" @click.native="onSearch()">查询
        </el-button>
        <el-button slot="append" class="mr-2" icon="el-icon-refresh" @click.native="onResetSearch()">重置</el-button>
      </div>
    </el-card>
    <!-- 导入提示 -->
    <el-alert title="导入失败的学生原因" type="warning" show-icon class="divAlertCont" v-if="errTip!=null && errTip!=''">
      <div Slot="title" class="divTipCot" v-html="errTip"></div>
    </el-alert>


    <!-- 列表-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          学生信息
        </h3>
        <div class="card-tools">
          <ul class="nav nav-pills ml-auto">

            <li class="nav-item">
              <!--导入-->
              <comImport @onImportSuccess="onImportSuccess" :template="'/templates/学生门禁组导入模版.xlsx'" :path="uploadInfoApi"
                :btnIcon="'el-icon-upload2'" :title="'批量导入'" :btnName="'导入'" ref="conImportInfoFile"
                v-if="isPower.schoolstudentmanagement_import == true">
              </comImport>
            </li>
            <li class="nav-item" v-if="isPower.schoolstudentmanagement_alldelete">
              <el-button type="danger" @click="onAllDelete">
                清空
              </el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table :data="itemData" stripe class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="studentNo" label="学号" class="elColumn" min-width="120"></el-table-column>
          <el-table-column prop="name" label="姓名" class="elColumn" min-width="140" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="gender" label="性别" class="elColumn" min-width="80"></el-table-column>
          <el-table-column prop="entryYear" label="入学年份" class="elColumn" min-width="100"></el-table-column>
          <el-table-column prop="college" label="年级" class="elColumn" min-width="160" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="class" label="班级" class="elColumn" min-width="160" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="group" label="门禁组" class="elColumn" min-width="160" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="groupDescription" label="备注" class="elColumn" min-width="160" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="操作" :width="thWidth" fixed="right" v-if="isPower.schoolstudentmanagement_setup">
            <template slot-scope="scope">
                <operationBtn  :btnName="'设置门禁组'" class="color-warning"
                  @click.native="Dumu(scope.row.studentId,scope.row.duMuGroupId)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>

      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
    <el-dialog :title="'设置门禁组'" :visible.sync="dialogVisibleDumu" width="30%" :before-close="handleClose"
      :close-on-click-modal="false" :close-on-press-escape="false" :append-to-body="true" :fullscreen="false">
      <div class="row">
        <div class="col-lg-12 colInfo ">
          <label>门禁组</label>
          <div class="colFonts">
            <el-select v-model="updateGroup.groupId" filterable placeholder="请选择门禁组" style="width: 100%;">
              <el-option v-for="item in updateGroup.option" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleDumu = false">取 消</el-button>
        <el-button type="primary" @click="onUpdateGroup">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import comImport from '@/components/Import.vue';
  export default {
    name: '',
    components: {
      comPage,
      operationBtn,
      comImport,
    },
    data() {
      return {
        // 动画
        fullscreenLoading: false,
        dialogVisible: false,
        dialogVisiblePhoto: false,
        dialogVisiblePrint: false,
        dialogVisibleStatus: false,
        dialogVisibleClass: false,
        optCollege: [],
        arrClass: [],
        arrStatus: [],
        // 更换学籍状态
        updateStatus: {
          studentId: '',
          dialogTitle: '',
          statusVal: '',
          option: [],
        },
        // 搜索
        SearchList: null,
        //列表数据
        itemData: [],

        //分页查询接口返回的结果
        page: null,
        //分页左侧按钮集合
        pageBtnList: null,
        //分页数据
        paging: {
          // 总共页数
          pageLength: 0,
          // 每页请求的数据量
          Size: 10,
          //当前在第几页
          Index: 1
        },
        // 上传学生信息接口
        uploadInfoApi: '/api/admin/student/importgroup',
        filePhotoList: [],
        isPower: {
          //增删改查 权限管理
          schoolstudentmanagement_alldelete:false,
          schoolstudentmanagement_import:false,
          schoolstudentmanagement_setup:false,
        },
        // 搜索集合
        searchForm: {
          selCollegeVar: '',
          optCollege: [],
          selStatus: '',
          selClassVar: '',
          optClass: [],
          Student: '',
          //所有班级数据
          allClassData: [],
          IdCardNo: '', //身份证号
          EntryYear: '', //入学年份
        },
        searchFormReset: null,

        errTip: null,
        thWidth: 100,



        tipSize: webUploadFileSize(),
        //转班
        updateClass: {
          studentId: '',
          option: [],
          classId: '',
        },

        // 判断是否为班主任
        isTeacher: false,
        updateGroup:{
          option:[],
          studentId:'',
          groupId:'',
        },
        dialogVisibleDumu:false,
      };
    },
    methods: {
      onAllDelete(){
        let that = this;
        confirmDelete(that, '清空后数据无法恢复，是否确认继续？', function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxPut(that, "/api/admin/student/cleargroup", null, function(r) {
              that.fullscreenLoading = false;
              pagingPage(that);
            });
          }
        })
      },
      onChangeDumu(_valueID,_groupId){
        let that = this;
        that.groupId = '';
        that.updateGroup.studentId = _valueID;
        that.updateGroup.groupId = _groupId;
        that.dialogVisibleDumu = true;
      },
      onUpdateGroup(){
        let that = this;
        let data = {
          id:that.updateGroup.studentId,
          groupId : that.updateGroup.groupId,
        }
        if(regNull(that.updateGroup.groupId)){
          warning(that,'请选择门禁组');
          return false;
        }
        ajaxPut(that,'/api/admin/student/dumugroup?id=' + that.updateGroup.studentId +'&groupId='+that.updateGroup.groupId,null,function(res){
            that.dialogVisibleDumu = false;
            pagingPage(that);
        })
      },
      /**
       * @description 重置搜索
       */
      onResetSearch() {
        let that = this;
        that.searchForm = JSON.parse(JSON.stringify(that.searchFormReset));
        that.paging.Index = 1;
        newRouterPath(that);
      },
      /**
       * @description 更多操作
       * @param {Object} command
       * @param {Object} _valueID
       */
      Dumu( _valueID,_groupId) {
        let that = this;
            // 门禁组
            that.groupId = '';
            that.updateGroup.studentId = _valueID;
            that.updateGroup.groupId = _groupId;
            that.dialogVisibleDumu = true;
      },
      /**
       * @description 清除年级事件
       */
      onClearCollege() {
        let that = this;
        that.arrClass = [];
        that.searchForm.selClassVar = '';
        for (let item of that.searchForm.allClassData) {
          that.arrClass.push({
            value: item.classId,
            label: item.name,
            alias:item.alias,
          });
        }
      },
      /**
       * @description 选择年级事件
       * @param {Object} res
       */
      onChangeCollege(res) {
        let that = this;
        that.searchForm.selClassVar = '';
        let arrTemp = that.searchForm.allClassData.filter(item => item.collegeId == res);
        that.arrClass = [];
        for (let item of arrTemp) {
          that.arrClass.push({
            value: item.classId,
            label: item.name,
            alias:item.alias,
          });
        }
      },
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },
      /**
       * @description 关闭弹出框提示
       * @param {Object} done
       */
      handleClose(done) {
        done();
      },
      /**
       * @description 导入学生信息成功后执行
       * @param {Object} param
       */
      onImportSuccess(param) {
        let that = this;
        pagingPage(that);
      },
      /**
       * @description 搜索事件
       */
      onSearch() {
        let that = this;
        that.paging.Index = 1;
        newRouterPath(that);
      },
      //分页组件左侧的按钮事件，按顺序进行判断
      pageBtnClick(index) {},
      /**
       * @description 分页面按钮
       * @param {Object} type
       * @param {Object} val
       */
      pagingClick(type, val) {
        let that = this;
        if (type == 1) {
          //更改每页条数触发的事件
          that.paging.Size = val;
          that.paging.Index = 1;
        } else {
          //更改当前页时触发的事件
          that.paging.Index = val;
        }
        newRouterPath(that);
      },
      /**
       * @description 去除数组内的重复
       * @param {Object} arr
       */
      onArrUnique(arr) {
        const res = new Map();
        return arr.filter((arr) => !res.has(arr.label) && res.set(arr.label, 1));
      },
    },
    beforeRouteEnter(to, from, next) {
      if (from.fullPath == '/admin/Preparations/index') {
        let {
          Name,
          ClassId
        } = to.query;
      }
      next()
    },
    created() {
      let that = this;


      let tempUser = onGetNowUser(that);
      that.isTeacher = tempUser.isTeacher;
      isPower(that,'schoolstudentmanagement_import','schoolstudentmanagement_import');
      isPower(that,'schoolstudentmanagement_alldelete','schoolstudentmanagement_alldelete');
      isPower(that,'schoolstudentmanagement_setup','schoolstudentmanagement_setup');
      let resArrUnique = new Map(),
        arrCollege = [];
      ajaxGet(that, '/api/admin/student/status', null, function(resStatus) {
        that.searchForm.allClassData = tempUser.allClass;
        // 学籍状态
        that.arrStatus = [];
        resStatus.forEach(function(item) {
          that.arrStatus.push({
            label: item.Text,
            value: item.Value,
          });
        });

        ajaxGet(that,'/api/admin/dumugroup',null,function(res){
          res.forEach(function(item){
            if(item.groupType == 4){
              that.updateGroup.option.push({
                value: item.groupId,
                label: regNull(item.description) ? item.groupName :item.groupName +'('+item.description+')'
              })
            }
          });
        })
        // 年级
        if (!regNullArray(tempUser.allClass)) {
          tempUser.allClass.filter((arr) => !resArrUnique.has(arr.collegeId) && resArrUnique.set(arr.collegeId,
              1))
            .forEach(function(item) {
              arrCollege.push({
                label: item.college,
                value: item.collegeId,
              });
            });
          // 班级
          if (that.isTeacher) {
            that.arrClass = [];
            for (let item of tempUser.manageClass) {
              that.arrClass.push({
                value: item.classId,
                label: item.name,
                alias:item.alias,
              });
            }
            that.searchForm.selClassVar = that.arrClass[0].value;
          } else {
            for (let item of tempUser.allClass) {
              that.arrClass.push({
                value: item.classId,
                label: item.name,
                alias:item.alias,
              });
            }
          }
          that.optCollege = arrCollege;

        }
        that.searchFormReset = JSON.parse(JSON.stringify(that.searchForm));
        let queryDate = that.$route.query;
        for (const key in queryDate) {
          if (queryDate[key] != null && queryDate[key] != "" && typeof(queryDate[key]) != "undefined") {
            if (key == "PageSize") {
              that.paging.Size = queryDate[key];
            } else if (key == "PageNumer") {
              that.paging.Index = queryDate[key];
            } else if (key == "Student") {
              that.searchForm.Student = queryDate[key];
            } else if (key == "ClassId") {
              that.searchForm.selClassVar = queryDate[key];
            } else if (key == "CollegeId") {
              that.searchForm.selCollegeVar = queryDate[key];
            } else if (key == "Status") {
              that.searchForm.selStatus = queryDate[key];
            } else if (key == "IdCardNo") {
              that.searchForm.IdCardNo = queryDate[key];
            } else if (key == "EntryYear") {
              that.searchForm.EntryYear = queryDate[key];
            }else if(key == "DuMuGroupId"){
              that.searchForm.DuMuGroupId = queryDate[DuMuGroupId]
            }
          }
        }
        pagingPage(that);
      });

      //用于重置搜索
      that.thWidth = onGetWidth(that, [
        that.isPower.student_details,
        that.isPower.student_edit || that.isPower.student_updatestatus || that.isPower.student_resetpwd || that
        .isPower.student_delete
      ]) + 50;
    },
    watch: {
      '$route': function(to) {
        let that = this;
        pagingPage(that);
      },
      searchFormReset(newVal) {
        this.searchFormReset = newVal;
      }
    }

  };

  function newRouterPath(that) {
    let data = {
      PageNumer: that.paging.Index,
      PageSize: that.paging.Size,
      Student: that.searchForm.Student,
      EntryYear: that.searchForm.EntryYear,
      IdCardNo: that.searchForm.IdCardNo,
      ClassId: that.searchForm.selClassVar,
      CollegeId: that.searchForm.selCollegeVar,
      Status: that.searchForm.selStatus,
      DuMuGroupId:that.searchForm.DuMuGroupId,
    };
    let routeData = {};
    for (const key in data) {
      if (data[key] != null && data[key] != "" && typeof(data[key]) != "undefined") {
        routeData[key] = data[key];
      }
    }
    that.$router.push({
      path: "/Admin/TXstudent/Index",
      query: routeData
    })
  }
  // 加载数据
  function pagingPage(that) {
    that.fullscreenLoading = true;
    if (that.$route.query.url == '/admin/Preparations/index') {
      const {
        Name,
        ClassId
      } = that.$route.query
      if (Name) {
        that.searchForm.Student = that.$route.query.Name
      }
      if (ClassId) {
        that.searchForm.selClassVar = that.$route.query.ClassId
      }
    }

    // 学籍有默认值
    if (regNull(that.searchForm.selStatus)) {
      that.searchForm.selStatus = '1';
    }


    let data = {
      PageNumer: that.paging.Index,
      PageSize: that.paging.Size,
      Student: that.searchForm.Student,
      EntryYear: that.searchForm.EntryYear,
      IdCardNo: that.searchForm.IdCardNo,
      ClassId: that.searchForm.selClassVar,
      CollegeId: that.searchForm.selCollegeVar,
      Status: that.searchForm.selStatus,
      DuMuGroupId:that.searchForm.DuMuGroupId,
      IsReside:false,
    };
    ajaxGet(that, "/api/admin/student/search", data, function(resData) {
      that.page = resData;
      that.itemData = resData.items;
      that.fullscreenLoading = false;
      if (that.$route.query.url == '/admin/Preparations/index') {
        that.$router.push({
          path: '/Admin/TXstudent/Index'
        })
      }

    });
  }

  // 初始化密码确认弹出框
  function confirmResetPwd(that, msg, callback) {
    if (msg == null || typeof(msg) == "undefined" || msg == "") {
      msg = "您确定要初始化密码吗？ 初始化后的密码是yaoyaokeji";
    }
    confirm(that, msg, callback);
  }

</script>

<style scoped="scoped" lang="less">
  .webPrintView {
    width: 100%;
    height: 400px;
    overflow-y: auto;
    margin-top: 30px;
  }

  .webDialogPrintBody {
    margin: 0 auto;
    position: relative;
    width: 100%;

    .A4CardBox {
      height: 339.37mm;
      width: 262mm;
      padding-top: 10px;
      // border: 1px solid #000;
    }

    .divPrintBox {
      display: flex;
      margin-bottom: 10px;
      padding-left: 13px;
      padding-right: 13px;
    }

    .printBody {
      margin: 0 auto;
      width: 51.5mm;
      height: 81.5mm;
      position: relative;
      padding-top: 11mm;

      .photoBody {
        width: 100%;
        position: relative;
        text-align: center;
        z-index: 10;
        border-radius: 1.84mm;
        width: 20.2mm;
        height: 27.1mm;
        overflow: hidden;
        margin: 0 auto;

        .photoBox {}
      }

      .infoBody {
        position: relative;
        z-index: 10;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        padding-top: 9px;

        .fontInfo {
          height: 21px;
          font-size: 14px;
          color: rgba(255, 255, 255, 1);
        }

        .fontName {
          font-size: 16px;
          color: rgba(255, 255, 255, 1);
        }
      }

      .qrcodeBody {
        position: relative;
        z-index: 10;
        text-align: center;
        padding-top: 18px;

        .qrBox {
          margin: 0 auto;
        }

      }

      .bgImage {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .selTitleBox {
    font-size: 14px;
  }

  .selTitleBox .spTitle {
    color: #303133;
  }

  .selBodyBox {
    padding-top: 5px;
  }

  .divAlertCont {
    margin-bottom: 0.9375rem;
  }

  .divAlertCont .divTipCot p {
    margin-bottom: 0.3125rem !important;
  }

  .btnCheckAll label {
    margin-bottom: 0;
  }

</style>
<style>
  .studentIndex .importStyle {
    font-size: 12px;
    font-weight: normal;
    padding: 9px 15px;
  }

</style>

<style scoped>
  @media print {
    @page {
      size: portrait;
      margin: 0;
    }

    * {
      -webkit-print-color-adjust: exact !important;
      -moz-print-color-adjust: exact !important;
      -ms-print-color-adjust: exact !important;
      print-color-adjust: exact !important;
    }
  }

</style>

