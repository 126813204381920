<!--
  基础信息-班级管理
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">

    <div>
      <search :ColNumber="4" :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null">
      </search>
    </div>
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          班级信息
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <el-button v-if="isPower.class_create == true" type="success" icon="el-icon-circle-plus-outline"
                @click="onCreate()">新增</el-button>
            </li>
            <li class="nav-item ml-1">
              <comImport @onImportSuccess="onImportSuccess" :template="'/templates/班级信息导入模版.xlsx'"
                :path="'/api/admin/class/import'" :btnIcon="'el-icon-upload2'" :title="'批量导入班级'" :btnName="'导入班级'"
                ref="conImportInfoFile" v-if="isPower.class_import">
              </comImport>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table :data="itemData" stripe class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>

          <el-table-column prop="name" label="班级名称" class="elColumn" min-width="140"
            :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="alias" label="别名" class="elColumn" min-width="140"
              :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="teacher" label="班主任" class="elColumn" min-width="120"
            :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="year" label="入学年份" class="elColumn" min-width="120"></el-table-column>
          <el-table-column prop="college" label="所属年级" class="elColumn" min-width="140"
            :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="statusStr" label="状态" class="elColumn" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.status!=0">{{scope.row.statusStr}}</span>
              <span v-else class="color-LightGray">暂未设置</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <operationBtn v-if="isPower.class_details == true" :btnName="'查看'" ref="detail"
                @click.native="onDetail(scope.row.classId)"></operationBtn>
              &nbsp;
              <el-dropdown v-if="isPower.class_edit || isPower.class_state || isPower.class_delete" trigger="click"
                @command="handleCommand($event,scope.row)">
                <el-button size="mini">
                  更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="isPower.class_edit" class="color-warning"
                    command="Update">编辑</el-dropdown-item>
                  <el-dropdown-item v-if="isPower.class_state" class="color-warning"
                    command="Status">更改状态</el-dropdown-item>
                  <el-dropdown-item v-if="isPower.class_state" class="color-warning"
                    command="Graduation">班级毕业</el-dropdown-item>
                  <el-dropdown-item divided class="color-primary" command="QRCode">二维码</el-dropdown-item>
                  <el-dropdown-item divided v-if="isPower.class_delete" class="color-danger"
                    command="Remove">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
    <el-dialog :title="getTitle" :visible.sync="dialogVisible" width="25%" :before-close="handleClose">
      <span class="class-change-text">班级状态更改</span>
      <el-select v-model="status" placeholder="请选择班级状态" style="width:100%">
        <el-option v-for="item in classStatus" :key="item.Value" :label="item.Text" :value="item.Value">
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer" style="textAlign:center">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="onSubmitStatus">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 二维码 -->
    <el-dialog title="班级二维码" :visible.sync="dialogVisibleRQcode" width="50%" :close-on-click-modal="false"
      :close-on-press-escape="false">
      <div class="divQrcodeBox" id="qrcode">
        <vue-qr ref="Qrcode" :logoSrc="imageQRCodeUrl" :text="RQcodeForm.url" :size="400">
        </vue-qr>
        <div class="divQRName">{{RQcodeForm.college}} / {{RQcodeForm.name}}</div>
      </div>
      <span slot="footer" class="dialog-footer" style="textAlign:center">
        <el-button @click="dialogVisibleRQcode = false">关 闭</el-button>
        <el-button type="primary" @click="onDownload">下 载</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import vueQr from 'vue-qr';
  import html2canvas from 'html2canvas';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';
  import comImport from '@/components/Import.vue';
  export default {
    components: {
      vueQr,
      comPage,
      operationBtn,
      search,
      comImport
    },
    data() {
      return {
        dialogVisible: false,
        dialogVisibleRQcode: false,
        currentDetail: null,
        classStatus: [], //班级状态列表
        status: null, //班级状态
        yearOpt: [], //年纪搜索
        collegeOpt: [], //年级opt
        //加载动画
        fullscreenLoading: false,
        //搜索
        SearchList: null,
        //分页查询接口返回的结果
        page: null,
        //列表数据
        itemData: [],
        //分页左侧按钮集合
        pageBtnList: null,
        //分页数据
        paging: {
          // 总共页数
          pageLength: 0,
          // 每页请求的数据量
          Size: 10,
          //当前在第几页
          Index: 1
        },

        isPower: {
          //增删改查 权限管理
          class_details: false,
          class_create: false,
          class_edit: false,
          class_delete: false,
          class_state: false,
          class_import: false,
        },
        imageQRCodeUrl: require("@/assets/image/logo1.png"),
        RQcodeForm: {
          name: null,
          classId: null,
          url: null,
          college: null,
        }
      };
    },
    computed: {
      getTitle() {
        let tipStr = ''
        if (this.currentDetail) {
          tipStr = `${this.currentDetail.name}`
        }
        return tipStr
      }
    },
    methods: {
      /**
       * @description 更多操作
       * @param {Object} command
       * @param {Object} _valueID
       */
      handleCommand(command, objItem) {
        let that = this;
        switch (command) {
          case 'Update':
            that.onUpdate(objItem.classId);
            break;
          case 'Status':
            that.onChangeClassStatus(objItem);
            break;
          case 'Graduation':
            that.onChangeClassGraduation(objItem.classId);
            break;
          case 'QRCode':
            that.onQRcode(objItem);
            break;
          case 'Remove':
            that.onRemove(objItem.classId);
            break;
        }
      },
      //下载
      onDownload() {
        let that = this;
        html2canvas(document.querySelector("#qrcode")).then(canvas => {
          let dataURL = canvas.toDataURL("image/png");
          if (dataURL !== "") {
            var a = document.createElement('a');
            a.download = that.RQcodeForm.name + '_二维码';
            a.href = dataURL;
            a.click();
          }
        });
      },
      //打开二维码
      onQRcode(objItem) {
        let that = this;
        that.RQcodeForm.college = objItem.college;
        that.RQcodeForm.name = objItem.name;
        that.RQcodeForm.classId = objItem.classId;
        that.RQcodeForm.url =that.$WebUrl()+ "/mobile/#/pages/QRCode?Id=" + objItem.classId+'&type=0';
        that.dialogVisibleRQcode = true;
      },
      handleClose() {
        this.dialogVisible = false
      },
      /**
      @deprecated 班级状态提交
       */
      onSubmitStatus() {
        let that = this
        confirm(that, '改变该班级后，所有学生状态也会相应更改，且无法恢复,您还需要更改吗？', (r) => {
          if (r) {
            if (!that.status) {
              warning(that, '请选择班级状态')
              return
            }
            let url = `/api/admin/class/changestatus/${that.currentDetail.classId}?status=${that.status}`
            that.fullscreenLoading = true
            ajaxPut(that, url, null, function(res) {
              onGetDataPage(that)
              that.fullscreenLoading = false
              that.dialogVisible = false
            })
          }
        })
      },
      // 班级设置为毕业
      onChangeClassGraduation(id) {
        let that = this
        confirm(that, '确定将该班级状态设置为毕业？改变该班级后，所有学生状态也会相应更改', (r) => {
          if (r) {
            that.fullscreenLoading = true
            ajaxPut(that, `/api/admin/class/changestatus/${id}?status=${3}`, null, function(res) {
              onGetDataPage(that)
              that.fullscreenLoading = false
              that.dialogVisible = false
            })
          }
        })
      },
      /**
       * @description班级状态更改
       */
      onChangeClassStatus(row) {
        this.status = null
        this.currentDetail = row
        this.dialogVisible = true
      },
      /**
       * @description 导入名单
       * @param {Object} param
       */
      onImportSuccess(param) {
        let that = this;
          onGetDataPage(that);
      },
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },
      // 跳转新增页面
      onCreate() {
        let that = this;
        that.$router.push({
          path: '/Admin/Class/Create',
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      // 搜索
      onSearch(params) {
        let that = this;
        let data = {};
        let searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = element.data;
          }
        });
        data.PageNumer = 1;

        routerPath(that, "/Admin/Class/Index", data, null, "PageSize");
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      // 分页面按钮
      pagingClick(type, val) {
        let that = this;
        let url = "/Admin/Class/Index";
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "Name",
            "Teacher",
            "year",
            "college"
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "Name",
            "Teacher",
            "year",
            "college"
          );
        }
      },
      // 编辑跳转
      onUpdate(objID) {
        let that = this;
        that.$router.push({
          path: "/Admin/Class/Edit/" + objID,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      // 详情
      onDetail(objID) {
        let that = this;
        that.$router.push({
          path: "/Admin/Class/Detail/" + objID,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      // 删除
      onRemove(objID) {
        let that = this;
        confirm(that, '删除该班级后，所有学生状态也会相应删除，且无法恢复,您还需要删除吗？', (r) => {
          let apiUrl = '/api/admin/class/' + objID;
          that.fullscreenLoading = true;
          ajaxDelete(that, apiUrl, null, function(obj) {
            onGetDataPage(that);
          });
        })
      }
    },
    created() {
      let that = this;
      isPower(that, "class_details", "class_details");
      isPower(that, "class_create", "class_create");
      isPower(that, "class_edit", "class_edit");
      isPower(that, "class_delete", "class_delete");
      isPower(that, 'class_state', 'class_state');
      isPower(that, 'class_import', 'class_import');
      that.fullscreenLoading = true;
      ajaxGet(that, '/api/admin/class/status', null, function(res) {
        that.classStatus = res
        that.yearOpt = getYear(5)
        ajaxGet(that, '/api/admin/college', null, function(res1) {
          if (res1) {
            that.collegeOpt = res1.map(item => {
              return {
                Title: item.name,
                Value: item.collegeId
              }
            })
          }
          that.fullscreenLoading = false
          onGetDataPage(that);
        })
      })
    },
    watch: {
      $route() {
        let that = this;
        onGetDataPage(that);
      }
    }
  };

  function getYear(num) {
    let nowYear = new Date().getFullYear() + 1
    let yearArr = []
    for (let i = 1; i <= num; i++) {
      let year = {}
      year.Title = `${nowYear - i}学年`
      year.Value = nowYear - i
      yearArr.push(year)
    }
    return yearArr
  }

  function onGetDataPage(that) {
    paging(
      that,
      function(r) {
        let apiUrl = "/api/admin/class/search";

        that.SearchList = [{
            type: "select",
            Name: "所属年级",
            data: r.college,
            select: that.collegeOpt,
            zhi: "college"
          },
          {
            type: "input",
            Name: "班级信息",
            data: r.Name,
            holder: "请输入班级关键字",
            zhi: "Name"
          },
          {
            type: "input",
            Name: "班主任姓名",
            data: r.Teacher,
            holder: "请输入班主任名称",
            zhi: "Teacher"
          },
          {
            type: "select",
            Name: "入学年份",
            data: r.year,
            select: that.yearOpt,
            zhi: "year"
          },
        ];
        let data = {
          PageNumer: r.PageNumer,
          PageSize: r.PageSize,
          Name: r.Name,
          CollegeId: r.college,
          Teacher: r.Teacher,
          Year: r.year,
        };
        that.paging.Index = r.PageNumer;
        that.paging.Size = r.PageSize;
        that.fullscreenLoading = true;
        ajaxGet(that, apiUrl, data, function(r) {
          that.page = r;
          that.itemData = r.items;
          that.fullscreenLoading = false;
        });
      },
      "Name",
      "Teacher",
      "year",
      "college"
    );
  }

</script>

<style scoped="scoped" lang="less">
  .divQrcodeBox {
    margin: 0 auto;
    border: 1px solid #dedede;
    width: 420px;
    height: 460px;
    text-align: center;
    padding-top: 10px;

    .divQRName {
      font-size: 18px;
      text-align: center;
    }
  }

  .dialog-footer {
    text-align: center !important;
    display: flex;
    justify-content: center;
  }

  .class-change-text {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
    display: block;
  }

</style>

