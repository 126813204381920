<!--
  数据字典 - 处分类型
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          考核自定义项配置
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <search :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null"></search>
            </li>
            <li class="nav-item" v-if="isPower.Custom_add">
              <el-button  type="success" icon="el-icon-circle-plus-outline"  @click="onAdd()">新增</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="item!=null && item.length!=0">
        <el-table stripe :data="item" class="elTableData">
          <el-table-column type="index" width="80" label="序号"></el-table-column>
          <el-table-column prop="name" label="自定义项目名称" min-width="200" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <span>{{scope.row.name}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="score" label="默认分" width="100"></el-table-column>
          <el-table-column prop="priority" label="优先级" width="100"></el-table-column>
<!--          <el-table-column prop="lastUpdateDateString" label="操作时间"></el-table-column> -->
          <el-table-column label="操作" width="160" fixed="right">
          <template slot-scope="scope">
            <operationBtn  v-if="isPower.Custom_edit" :btnName="'编辑'" @click.native="onEdit(scope.row)"></operationBtn>
            <operationBtn  v-if="isPower.Custom_delete" :btnName="'删除'" @click.native="onDelete(scope.row.reasonId)"></operationBtn>
          </template>
         </el-table-column>
        </el-table>
        <comPage
          :paging="paging"
          :pageBtnList="pageBtnList"
          @pagingClick="pagingClick"
          @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>

    </el-card>


	<el-dialog :title="isEdit ? '编辑' : '新增'"
	:visible.sync="dialogVisible"
	width="30%"
	:close-on-click-modal="false"
	:close-on-press-escape="false"
	:append-to-body="true"
	:fullscreen="false">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
				<el-row v-if="isDetail!=true">
					 <el-col :span="24">
						<el-form-item label="项目名称" prop="name">
							<el-input v-model="ruleForm.name" placeholder="请输入"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="默认分" prop="score">
							<el-input @change="onCoefficientChange()" v-model="ruleForm.score" placeholder="请输入默认分"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="优先级" prop="priority">
							<el-input @change="onPriorityChange()" v-model="ruleForm.priority" placeholder="请输入优先级"></el-input>
						</el-form-item>
					</el-col>
				</el-row>


			</el-form>
      <span slot="footer" class="dialog-footer">
				<div v-if="isDetail!=true">
					<el-button @click="dialogVisible = false">取 消</el-button>
					<el-button @click="onReset()">重 置</el-button>
					<el-button type="primary" @click="onSave()">确 定</el-button>
				</div>
				<div v-else>
					<el-button @click="dialogVisible = false">关 闭</el-button>
				</div>
      </span>
	</el-dialog>


  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';
  export default{
    components:{
      comPage,operationBtn,search
    },
    data(){
      return{
				fullscreenLoading:false,
				dialogVisible:false,
				ruleForm:{
					name:null,
					score:null,
					priority:0,
				},
        isEdit:false,//是否编辑
        currentEdit:{},
				ruleFormReset:null,//待重置时的表单数据
				page:null,//分页查询接口返回的结果
				item:[], //列表数据
				pageBtnList:null,//分页左侧按钮集合
				dialogTitle:"新增",//弹出框标题
				isDetail:false, //是否是查看弹出框
				paging: { //分页数据
					totalCount: 0, // 总共页数
					PageSize: 10, // 当前请求数量
					PageNumer: 1 //当前在第几页
				},
				SearchList: null,//搜索集合
				isPower: {
						//增删改查 权限管理
						Custom_edit: false,
						Custom_delete: false,
						Custom_add: false,
				},
				rules: {
					name: [
						{ required: true, message: '名称不能为空', trigger: 'change' },
						{ max: 128,message: '名称不能超过128个字符', trigger: 'change' },
					],
          score:[
            {
              required:true,
              message:'分数不能为空',
              trigger: 'change',
            }
          ],
				},
      };
    },
	created()
	{
		let that = this;
    isPower(that,'Custom_edit','Custom_edit')
    isPower(that,'Custom_delete','Custom_delete')
    isPower(that,'Custom_add','Custom_add')
		pagingPage(that);
	},
	methods:{
		onPriorityChange()
		{
			let that = this;
			var value = setInt(that.ruleForm.priority);
			that.ruleForm.priority = value;
		},
		onCoefficientChange()
		{
			let that = this;
			var value = setTwoDecimal(setTwoFloat(that.ruleForm.score));
			that.ruleForm.score = value;
		},
		onSearch(params)
		{
			let that = this;
			let data = {};
			let searchData = params;
			searchData.forEach(element => {
				if (element.data) {
					data[element.zhi] = element.data;
				}
			});
      data.radom = Math.random(1000)
			data.PageNumer = 1;
			routerPath(that, "/Admin/Assessment/Customize", data, null, "PageSize");
		},
    onBack() {
      let that = this;
      back(that);
    },
		onSave()//保存（新增和编辑）
		{
       let that = this
       if(that.ruleForm.priority==null || that.ruleForm.priority == "")
       {
       	that.ruleForm.priority = 0
       }
       if(!that.isEdit){
         that.$refs['ruleForm'].validate(valid =>{
           if(valid){
             let url = '/api/admin/advisercustomreason'
             that.fullscreenLoading = true
             ajaxPost(that,url,that.ruleForm,function(res){
               that.fullscreenLoading = false
               that.dialogVisible = false
               pagingPage(that)
             })
           }else{return false}
         })
       }else{
       that.$refs['ruleForm'].validate(valid =>{
         if(valid){
           let url = `/api/admin/advisercustomreason/${that.currentEdit.reasonId}`
           that.fullscreenLoading = true
           that.ruleForm.reasonId = that.currentEdit.reasonId
           ajaxPut(that,url,that.ruleForm,function(res){
             that.fullscreenLoading = false
             that.dialogVisible = false
             pagingPage(that)
           })
         }else{return false}
       })
       }
		},
		onAdd()//新增
		{
			let that = this;
      that.isEdit = false
			that.dialogTitle = "新增";
			var ruleForm = JSON.parse(JSON.stringify(that.ruleForm));
			for(var i in ruleForm)
			{
				ruleForm[i] = null;
			}
			that.ruleForm = JSON.parse(JSON.stringify(ruleForm));
			that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
			that.dialogVisible = true;
			that.isDetail = false;
			try
			{
				that.$refs["ruleForm"].resetFields();
			} catch{}
		},
		onEdit(obj)//编辑
		{
      let that = this
      that.currentEdit = obj
      that.ruleForm.name = obj.name
      that.ruleForm.priority = obj.priority
      that.ruleForm.score = obj.score
      that.isEdit = true
      that.dialogVisible = true
		},
		onReset() //重置
		{
			let that = this;
      if(that.isEdit){
        that.ruleForm.name = that.currentEdit.name
        that.ruleForm.priority = that.currentEdit.priority
        that.ruleForm.score = that.currentEdit.score
      }else{
        that.$refs["ruleForm"].resetFields()
      }
		},
		onDelete(Id) //删除
		{
			let that = this;
			confirmDelete(that,null,function(res){
				if(res == true)
				{
					that.fullscreenLoading = true;
					ajaxDelete(that,`/api/admin/advisercustomreason/${Id}`,null,function(r){
						that.fullscreenLoading = false;
						pagingPage(that);
					});
				}
			})
		},
		pageBtnClick(index) {
			//分页组件左侧的按钮事件，按顺序进行判断
		},
		pagingClick(type, val) {
			let that = this;
			let url = "/Admin/Assessment/Customize";
			if (type == 1) {//更改每页条数触发的事件
				routerPath(
					that,
					url,
					"PageSize",
					val,
					"PageNumer",
					"Name",

				);
			} else { //更改当前页时触发的事件
				routerPath(
					that,
					url,
					"PageNumer",
					val,
					"PageSize",
					"Name",
				);
			}
		},
	},
	watch: {
		$route() {
			let that = this;
			pagingPage(that);
		}
	}
  };

 function pagingPage(that) {
	  paging(
		  that,
		  function (r) {
			  let Myurl = "/api/admin/advisercustomreason/search";
			  let data = {
				  PageNumer: r.PageNumer,
				  PageSize: r.PageSize,
          Name:r.Name
			  };
			  that.SearchList = [
				  {
					  type: "input",
					  Name: "处分等级",
					  data: r.Name,
					  holder: "请输入处分等级",
					  zhi: "Name"
				  },
			  ];
			  that.fullscreenLoading = true;
			  ajaxGet(that, Myurl, data, function (r) {
				  that.paging.totalCount = r.totalCount;
				  that.item = r.items;
				  that.fullscreenLoading = false;
			  });
		  },"Name");
  }
</script>

<style scoped="scoped">

</style>
