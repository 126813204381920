<!--
  处分管理 - 新增
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading" v-if="itemForm!=null">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          查看请假信息
        </h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-4 colInfo">
            <label>班级</label>
            <div class="colFonts">
              {{itemForm.className}}
            </div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>学号</label>
            <div class="colFonts">
              {{itemForm.studentNo}}
            </div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>姓名</label>
            <div class="colFonts">
              {{itemForm.studentName}}
            </div>
          </div>

          <div class="col-lg-4 colInfo">
            <label>请假分类</label>
            <div class="colFonts">
              {{itemForm.categoryName}}
            </div>
          </div>
          <div class="col-lg-4 colInfo" v-if="!regNull(itemForm.typeName)">
            <label>请假类型</label>
            <div class="colFonts">
              {{itemForm.typeName}}
            </div>
          </div>
          <div class="col-lg-4 colInfo" v-if="itemForm.category == 1">
            <label>当前位置</label>
            <div class="colFonts" >
              {{itemForm.location == true ?'在校' :'在家'}}
            </div>
          </div>
          <div class="col-lg-4 colInfo" v-if="itemForm.type == 1">
            <label>疾病名称</label>
            <div class="colFonts">
              {{itemForm.sickness}}
            </div>
          </div>
          <div class="col-lg-4 colInfo" v-if="itemForm.type == 1">
            <label>主要症状</label>
            <div class="colFonts" v-html='itemForm.symptom'>
            </div>
          </div>
          <div class="col-lg-4 colInfo" v-if="itemForm.category == 5">
            <label>临时通校</label>
            <div class="colFonts">
              {{itemForm.syncGroupName}}
            </div>
          </div>

          <div class="col-lg-4 colInfo">
            <label>开始时间</label>
            <div class="colFonts" v-if="itemForm.category == 2 || itemForm.category == 3 || itemForm.category == 5">
              {{itemForm.startDate | dateformatDay}}
            </div>
            <div class="colFonts" v-else>
              {{itemForm.startDate | dateformatMinute}}
            </div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>结束时间</label>
            <div class="colFonts" v-if="itemForm.category == 2 || itemForm.category == 3 || itemForm.category == 5">
              {{itemForm.endDate | dateformatDay}}
            </div>
            <div class="colFonts" v-else>
              {{itemForm.endDate | dateformatMinute}}
            </div>
          </div>
          <div class="col-lg-4 colInfo" v-if="!regNull(itemForm.outDate)">
            <label>出校时间</label>
            <div class="colFonts">
              {{itemForm.outDate | dateformatMinute}}
            </div>
          </div>
          <div class="col-lg-4 colInfo" v-if="!regNull(itemForm.inDate)">
            <label>进校时间</label>
            <div class="colFonts">
              {{itemForm.inDate | dateformatMinute}}
            </div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>时长（天）</label>
            <div class="colFonts">
              {{itemForm.days}}
            </div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>状态</label>
            <div class="colFonts">
              <span class="color-warning" v-if="itemForm.status==1 || itemForm.status==4">
                {{itemForm.statusName}}</span>
              <span class="color-danger" v-else-if="itemForm.status==-3 || itemForm.status==-2">
                {{itemForm.statusName}}</span>
              <span class="color-success" v-else-if="itemForm.status==2 || itemForm.status==8">
                {{itemForm.statusName}}</span>
              <span v-else> {{itemForm.statusName}}</span>
            </div>
          </div>
          <div class="col-lg-12 colInfo" v-if="isPower.admin_check_reason">
            <label>请假事由</label>
            <div class="colFonts" v-if="itemForm.reason!=null && itemForm.reason.length>0" v-html="itemForm.reason">
            </div>
            <div class="colFonts" v-else>暂无数据</div>
          </div>
        </div>
      </div>
    </el-card>

    <!--附件-->
    <comShowImage ref="comShowImage" :uploadFiles="uploadFiles"></comShowImage>


    <el-card class="box-card" v-if="itemForm.audits!=null && itemForm.audits.length!=0">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          请假审核信息
        </h3>
      </div>
      <div class="card-body">
        <el-table :data="itemForm.audits" border class="elTableData">
          <el-table-column label="审核日期" width="180">
            <template slot-scope="scope">
              {{scope.row.createDate | dateformatMinute}}
            </template>
          </el-table-column>
          <el-table-column prop="auditor" label="审核人员" width="140"></el-table-column>
          <el-table-column prop="statusName" label="审核状态" width="160"></el-table-column>
          <el-table-column prop="reason" label="审核原因"></el-table-column>
        </el-table>
      </div>
    </el-card>

    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0" v-if="isShowStats">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            审核原因
          </h3>
        </div>
        <div class="card-body">
          <el-form-item label="" prop="reason">
            <el-input type="textarea" :rows="10" placeholder="请输入审核原因" v-model="ruleForm.reason"></el-input>
          </el-form-item>
          <div class="color-danger divFonts" v-if="isShowErr">审核不通过，需要填写审核原因</div>
        </div>
      </el-card>
    </el-form>



    <div class="row">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
        <el-button type="danger" icon="el-icon-circle-close" @click="onAudit(false)" v-if="isShowStats">审核不通过
        </el-button>
        <el-button type="primary" icon="el-icon-circle-check" @click="onAudit(true)" v-if="isShowStats">审核通过</el-button>
      </div>
    </div>

  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comShowImage from '@/components/showImage.vue';
  export default {
    components: {
      comShowImage,
    },
    data() {
      return {
        fullscreenLoading: false,

        random: 0, //刷新组件

        itemForm: null,

        uploadFiles: [],
        arrGrowthConfigs: [],
        styleWidth: '25%',

        ruleForm: {
          leaveId: null,
          status: null,
          reason: null,
        },
        rules: {
          reason: [{
            max: 500,
            message: '审核原因不能超过500个字符',
            trigger: 'change'
          }, ],
        },
        isPower: {
          leavemgt_audit: false,
          leavemgt_class_audit: false,
          leavemgt_studentoffice_audit: false, // 分管校长审核
          admin_check_reason:false,
        },
        isShowStats: false,
        isShowErr: false,
        myInfo: null,
        isTeacher: false,
      };
    },
    filters: {
      flNoValue(obj) {
        if (obj == null || typeof(obj) == "undefined" || obj == '') {
          return '暂无数据';
        }
        return obj;
      }
    },
    created() {
      let that = this;
      isPower(that, 'leavemgt_audit', 'leavemgt_audit');
      isPower(that, 'leavemgt_class_audit', 'leavemgt_class_audit');
      isPower(that,'leavemgt_studentoffice_audit','leavemgt_studentoffice_audit')
      isPower(that,'admin_check_reason','admin_check_reason');
      that.myInfo = JSON.parse(that.$GetKey(that.$cacheTeacher));
      if (!regNull(that.$route.params.Id)) {
        that.fullscreenLoading = true;
        ajaxGet(that, '/api/admin/askforleave/' + that.$route.params.Id, null, function(resData) {
          that.fullscreenLoading = false;
          that.itemForm = resData;
          that.ruleForm.leaveId = resData.leaveId;
          that.isTeacher = !regNull(that.myInfo.manageClass.find(item => item.classId == resData.classId));
          if (that.isTeacher && resData.status == 1 && that.isPower.leavemgt_class_audit) {
          that.isShowStats = true;
        } else if (resData.status == 4 && that.isPower.leavemgt_audit) {
          that.isShowStats = true;
        } else if (resData.status == 16 && that.isPower.leavemgt_studentoffice_audit) {
          that.isShowStats = true;
        }



          that.uploadFiles = [];
          if (resData.attachment != null && typeof(resData.attachment) != "undefined" && resData.attachment != "") {
            var files = resData.attachment.split('|');
            for (var i in files) {
              that.uploadFiles.push(that.$ServerUrl() + files[i]);
            }
            var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
            that.random += random;
          }



        });
      }
    },

    methods: {
      /**
       * @description 审核
       * @param {Object} objVal
       */
      onAudit(objVal) {
        let that = this;
        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            // 待审核，需要班主任审核
            if (that.itemForm.status == 1) {
              // 判断
              if (objVal == true) {
                that.ruleForm.status = 2;
              } else {
                // 审核不通过
                that.ruleForm.status = -2;
              }
            } else if (that.itemForm.status == 4) {
              // 判断
              if (objVal == true) {
                that.ruleForm.status = 8;
              } else {
                // 审核不通过
                that.ruleForm.status = -3;
              }
            }
          // 学生处审核
          else if (that.itemForm.status == 16) {
            // 判断
            if (objVal == true) {
              that.ruleForm.status = 32;
            } else {
              // 审核不通过
              that.ruleForm.status = -4;
            }
          }
            if (that.ruleForm.status < 0) {
              if (regNull(that.ruleForm.reason)) {
                warning(that, '审核不通过，请输入原因');
                that.isShowErr = true;
                return false;
              } else {
                that.isShowErr = false;
              }
            }

            that.fullscreenLoading = true;
            ajaxPut(that, '/api/admin/askforleave/' + that.itemForm.leaveId, that.ruleForm, function(res) {
              that.fullscreenLoading = false;
              back(that);
            });

          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              isError[0].querySelector('input').focus();
            }, 1)
            return false;
          }
        });

      },
      onBack() {
        let that = this;
        back(that);
      },
    },
  };


  /**
   * @description 递归综合类型数据
   * @param {Object} that
   * @param {Object} _data 数据组
   */
  function RecursionData(that, _data) {
    if (_data.length !== 0) {
      _data.forEach((item) => {
        if (item.parentId == "00000000-0000-0000-0000-000000000000") {
          that.arrGrowthConfigs.push({
            configId: item.configId,
            name: item.name,
            parentId: item.parentId,
            score: item.score,
            children: _data.filter(val => val.parentId == item.configId)
          });
        }
      });
    }
  }
</script>

<style scoped="scoped">
  label {
    margin-bottom: 0;
  }

  .divFonts {
    font-size: 12px;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }

  .spIcon {
    width: 5px;
    height: 20px;
    border-radius: 5px;
    background-image: linear-gradient(#4545FF, #2D8EFF);
  }

  .spTitle {
    float: left;
    margin-left: 20px;
    color: #323E56;
    letter-spacing: 2px;
    font-size: 16px;
    margin-top: -3px;
  }

  .wMax {
    width: 100% !important;
  }
</style>

<!-- 综合类型 -->
<style scoped="scoped">
  .divTagBox {
    padding: -5px;
  }

  .divTagBox .spTab {
    padding: 5px;
  }

  .divTagBox .spScore {
    color: #fa3534;
  }

  .divLiteracyBox {
    padding: 15px;
  }

  .divLiteracyBox .listBox {
    text-align: center;
    padding: 20px;
    border: 2px solid #FFFFFF;
    border-radius: 5px;
  }

  .divLiteracyBox .listBox:hover {
    border: 2px solid #409eff;
    cursor: pointer;
  }

  .divLiteracyBox .literacyName {
    font-size: 24px;
    letter-spacing: 2px;
    padding-bottom: 10px;
  }

  .divLiteracyBox .literacyNum {
    padding-top: 10px;
    font-family: 'OswaldLight';
    border-top: 2px solid #EEEEEE;
    font-size: 32px;
    color: #409eff;
  }
</style>
