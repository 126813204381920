<!--
  社团信息 新增/编辑
-->
<template>
  <div class="SubWebPage " v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="ruleForm">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{Title}}
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24">
            <el-col :span="onNullId()?16:24">
              <el-form-item label="社团名称" prop="name">
                <el-input v-model="ruleForm.name" placeholder="请输入社团名称" class="formControl"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="onNullId()">
              <el-form-item label="选择最高负责人" prop="selectStudentId">
                <el-cascader :show-all-levels="false" filterable class="width_100Pie" clearable
                  placeholder="可通过学生姓名或学号搜索" v-model="selectStudentId" :options="StudentList" @change="onStudentChange">
                </el-cascader>
              </el-form-item>

              <!-- <el-form-item label="选择最高负责人" prop="selectStudentId">
                <el-select @change="onStudentChange" v-model="selectStudentId" placeholder="请输入学号或姓名"
                  class="wMax formControl" filterable clearable remote :remote-method="remoteMethod">
                  <el-option v-for="item in StudentList" :key="item.studentId"
                    :label="item.name + '（' + item.studentNo + '）'" :value="item.studentId"></el-option>
                </el-select>
              </el-form-item> -->
            </el-col>
            <el-col :span="24">
              <el-form-item label="社团描述" prop="description">
                <el-input class="formControl" type="textarea" :rows="4" maxlength="500" show-word-limit
                  placeholder="请输入内容" v-model="ruleForm.description">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>


      <!--附件-->
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            附件
          </h3>
        </div>
        <div class="card-body">
          <div class="pt-30">
            <comUpload :key="random" @onPictureHandleSuccess="onPictureHandleSuccess" :files="uploadFiles"></comUpload>
          </div>
        </div>
      </el-card>


      <div class="row">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
          <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
          <el-button type="primary" icon="el-icon-circle-check" @click="onSave()">提交</el-button>
        </div>
      </div>
    </el-form>




  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comUpload from '@/components/Upload.vue';


  export default {
    components: {
      comUpload
    },
    data() {
      return {
        // 加载动画
        fullscreenLoading: false,

        random: 0, //刷新组件


        //表单数据
        ruleForm: {
          clubId: null,
          name: null,
          description: null,
          member: [],
          attachment: null,
          position: null,
        },
        // 验证
        rules: {
          name: [{
              required: true,
              message: '社团名称不能为空',
              trigger: 'change'
            },
            {
              max: 40,
              message: '社团名称不能超过40个字符',
              trigger: 'change'
            },
          ],
          description: [{
            max: 500,
            message: '描述不能超过500个字符',
            trigger: 'change'
          }, ],
        },
        //重置时的表单数据
        ruleFormReset: null,

        //所有学生集合
        selectStudentId: null,
        StudentList: [], //班级学生集合


        Title: "新增社团信息",
        uploadFiles: [],






      };
    },
    watch: {
      selectStudentId: function(newVal) {
        if (regNull(newVal)) {
          onGetStudent(this, null);
        }
      }
    },
    created() {
      let that = this;
      let id = that.$route.params.Id;
      var url = that.$route.path.toLowerCase();
      var detailUrl = null;
      if (id != null && typeof(id) != "undefined" && id != "") {
        that.Title = "编辑社团信息";
        that.ruleForm.clubId = id;
        detailUrl = "/api/admin/club/" + id;
        onGetInfo(that);
      }
      that.fullscreenLoading = true;
      ajaxGet(that, '/api/admin/clubposition', null, function(resData) {
        that.fullscreenLoading = false;
        that.ruleForm.position = resData.find(val => val.isDirector == true);
        if (that.ruleForm.position == undefined) {
          that.ruleForm.position.isDirector = false;
          warning(that, '社员职务中未设置最高负责人！');
        }
        onGetStudent(that, null);
      });
      // onGetData(that);
    },

    methods: {
      /**
       * @description 判断是否有ID，没有则为新增
       * @return {Boolean} true:空
       */
      onNullId() {
        return regNull(this.ruleForm.clubId);
      },

      remoteMethod(query) {
        onGetStudent(this, query);
      },

      /**
       * @description 递归数据
       * @param {Array} data
       * @return {Array} 递归完成后的数组
       */
      getTreeData(data) {
        // 循环遍历json数据
        for (var i = 0; i < data.length; i++) {

          if (data[i].children.length < 1) {
            // children若为空数组，则将children设为undefined
            data[i].children = undefined;
          } else {
            // children若不为空数组，则继续 递归调用 本方法
            this.getTreeData(data[i].children);
          }
        }
        return data;
      },

      /**
       * @description 上传图片成功
       * @param {Object} param
       */
      onPictureHandleSuccess(param) {
        let that = this;
        that.ruleForm.attachment = "";
        for (var i in param) {
          if (that.ruleForm.attachment == "") {
            that.ruleForm.attachment = param[i].path;
          } else {
            that.ruleForm.attachment += "|" + param[i].path;
          }
        }
      },


      /**
       * @description 重置事件
       */
      onReset() {
        let that = this;
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
        that.selectStudentList = JSON.parse(JSON.stringify(that.selectStudentListReset));
        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },

      /**
       * @description 提交按钮事件
       */
      onSave() {
        let that = this;

        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            if (that.ruleForm.member != null && that.ruleForm.member.length > 0) {
              if (regNull(that.ruleForm.clubId)) {
                // 新增
                that.fullscreenLoading = true;
                ajaxPost(that, '/api/admin/club', that.ruleForm, function(res) {
                  that.fullscreenLoading = false;
                  that.onBack(that);
                })
              } else {
                // 编辑
                that.fullscreenLoading = true;
                ajaxPut(that, '/api/admin/club/' + that.ruleForm.clubId, that.ruleForm, function(res) {
                  that.fullscreenLoading = false;
                  that.onBack(that);
                })
              }
            }


          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              isError[0].querySelector('input').focus();
            }, 1)
            return false;
          }
        });
      },



      /**
       * @description 学生选择事件
       */
      onStudentChange(res) {
        let that = this;
        that.ruleForm.member = [];
        that.ruleForm.member.push({
          studentId: res[res.length - 1],
          positionId: that.ruleForm.position.positionId
        });

      },

      /**
       * @description 返回事件
       */
      onBack() {
        let that = this;
        back(that);
      },
    },
  };

  function onGetInfo(that) {
    that.fullscreenLoading = true;
    ajaxGet(that, '/api/admin/club/' + that.ruleForm.clubId, null, function(resData) {
      that.fullscreenLoading = false;
      that.ruleForm = resData;
      that.uploadFiles=[];
      if (!regNull(resData.attachment)) {
        var files = resData.attachment.split('|');
        for (var i in files) {
          var it = i + 1;
          that.uploadFiles.push({
            uid: it,
            path: files[i],
          });
        }
        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;
      }


    });
  }

  function onGetData(that) {

  }

  function onGetStudent(that) {
    that.fullscreenLoading = true;
    ajaxGet(that, '/api/admin/class/linkage', null, function(resData) {
      that.fullscreenLoading = false;
      that.StudentList = [];
      for (let item of resData) {
        that.StudentList.push({
          value: item.classId,
          label: item.alias,
          children: onGetChildren(item.students)
        });
      }
    });
  }

  function onGetChildren(_data) {
    let temp = [];
    if (_data != null && _data.length > 0) {
      for (let item of _data) {
        temp.push({
          value: item.studentId,
          label: item.name + '（' + item.studentNo + '）'
        });
      }
    }
    return temp;
  }
</script>

<style scoped="scoped">
  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }

  .spIcon {
    width: 5px;
    height: 20px;
    border-radius: 5px;
    background-image: linear-gradient(#4545FF, #2D8EFF);
  }

  .spTitle {
    float: left;
    margin-left: 20px;
    color: #323E56;
    letter-spacing: 2px;
    font-size: 16px;
    margin-top: -3px;
  }

  .wMax {
    width: 100% !important;
  }
</style>
