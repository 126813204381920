<!--
 ECHARTS 的 雷达图
-->
<template>
  <!-- 250px -->
  <div :id="comID" :style="pieStyle"></div>
</template>

<script>
  // 按需引入
  import 'echarts/lib/component/tooltip';
  import 'echarts/lib/component/legend';
  import 'echarts/lib/component/grid';

  import * as echarts from 'echarts/lib/echarts';
  import {
    GridComponent
  } from 'echarts/components';
  echarts.use([GridComponent]);


  // 引入柱状图组件
  require('echarts/lib/chart/line');
  export default {
    props: {
      comID: {
        type: String,
        default: 'myChartLine'
      },
      comHeight: {
        type: Number,
        default: 250
      },
      /**
       * @description 数据
       */
      comChartData: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        pieStyle: '',
        myChart: {},
        chartData: [],

        xAxisData: [],
      };
    },
    created() {
      let that = this;

      if (!regNull(that.comChartData)) {
        that.onGetRadar(that.comChartData);
      }
    },
    mounted() {
      let that = this;
      window.onresize = function() {
        that.myChart.resize();
        that.pieStyle = 'width: 100%; height:' + (that.comHeight * 0.0625) + 'rem;';
      }
    },
    methods: {
      /**
       * @description 调用方法
       * @param {Object} objData
       */
      onGetRadar(objData) {
        let that = this;
        that.chartData = [];
        that.xAxisData = [];
        if (!regNull(objData)) {
          if(!regNullArray(objData.time)){
            that.xAxisData = objData.time;
          }
          if (!regNullArray(objData.scoreData)) {
            objData.scoreData.forEach((item) => {
              that.chartData.push({
                name: item.configName,
                type: 'line',
                showSymbol: false,
                data: item.score,
              })
            });
          }
          that.$nextTick(() => {
            that.drawLine();
          });

          that.pieStyle = 'width: 100%; height:' + (that.comHeight * 0.0625) + 'rem;';
        }
      },
      /**
       * @description 生成雷达图
       */
      drawLine() {
        let that = this;
        // 基于准备好的dom，初始化echarts实例
        let myChart = echarts.init(document.getElementById(that.comID));

        if (!regNullArray(that.chartData) && !regNullArray(that.xAxisData)) {
          // 绘制图表
          myChart.setOption({
            tooltip: {
              trigger: 'axis'
            },
            legend: {},
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: that.xAxisData
            },
            yAxis: {
              type: 'value',
            },
            series: that.chartData

          });
        }

      },
      drawLineTwo() {
        // 基于准备好的dom，初始化echarts实例
        let myChart = echarts.init(document.getElementById(this.comID));
        // 绘制图表
        myChart.setOption({
          grid: { // 控制图的大小，调整下面这些值就可以，
            x: 40,
            x2: 1000,
            y2: 1050 // y2可以控制 X轴跟Zoom控件之间的间隔，避免以为倾斜后造成 label重叠到zoom上
          },
          tooltip: {
            padding: [10, 10],
          },
          legend: {
            data: ['班级平均分', '学生个人分'],
            bottom: 0,
          },
          color: ['#C13531', '#1E90FF'],
          radar: {
            shape: 'circle',
            radius: '50%',
            name: {
              textStyle: {
                color: '#fff',
                backgroundColor: '#999',
                borderRadius: 3,
                padding: [5, 10],
                fontSize: 16
              },
            },
            indicator: [{
                name: '课堂教学',
                max: 100
              },
              {
                name: '社会实践',
                max: 100
              },
              {
                name: '学生社团',
                max: 100
              },
              {
                name: '主题活动',
                max: 100
              },
              {
                name: '评比',
                max: 100
              },
              {
                name: '综合选修课',
                max: 100
              },
            ]
          },
          series: [{
            name: '责任担当',
            type: 'radar',
            data: [{
                value: [93, 60, 78, 85, 71, 63],
                name: '班级平均分',
              },
              {
                value: [50, 74, 58, 81, 69, 66],
                name: '学生个人分',
              },
            ],
          }]
        });
      },
      drawLineThr() {
        // 基于准备好的dom，初始化echarts实例
        let myChart = echarts.init(document.getElementById(this.comID));
        // 绘制图表
        myChart.setOption({

          tooltip: {
            padding: [10, 10],
          },
          color: ['#C13531', '#1E90FF'],
          radar: {
            shape: 'polygon',
            center: ["80%", "50%"],
            radius: '60%',
            name: {
              textStyle: {
                color: '#fff',
                backgroundColor: '#999',
                borderRadius: 3,
                padding: [5, 10],
                fontSize: 16
              },
            },
            indicator: [{
                name: '课堂教学',
                max: 100
              },
              {
                name: '社会实践',
                max: 100
              },
              {
                name: '学生社团',
                max: 100
              },
              {
                name: '主题活动',
                max: 100
              },
              {
                name: '评比',
                max: 100
              },
              {
                name: '综合选修课',
                max: 100
              },
            ]
          },
          series: [{
            name: '责任担当',
            type: 'radar',
            data: [{
                value: [93, 60, 78, 85, 71, 63],
                name: '班级平均分',
              },
              {
                value: [50, 74, 58, 81, 69, 66],
                name: '学生个人分',
              },
            ],
          }]
        });

      }
    }
  };
</script>

<style>

</style>
