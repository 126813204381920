<!--
  班主任手册-班级备忘录
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <search :ColNumber="2" :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null">
    </search>
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          班级备忘录
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <el-button
                v-if="isPower.adviserclassworkmemo_create"
                type="success" icon="el-icon-circle-plus-outline" @click="onAdd()">新增</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body" v-if="item==null || item.length==0">
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>

      <div class="card-body" v-for="(p,index) in item" :key="index">
        <div class="weekHeaderBox">
          <div class="elTagBox">
            <el-tag>{{"第 "+p.date+" 周"}}</el-tag>
          </div>
          <div class="cardTools">
            <operationBtn
              v-if="isPower.adviserclassworkmemo_edit == true  && isCurrentTeacher == true"
              :btnName="'编辑'" @click.native="onEdit(p.memoId)"></operationBtn>
            <operationBtn
              v-if="isPower.adviserclassworkmemo_delete == true  && isCurrentTeacher == true"
              :btnName="'删除'" @click.native="onDelete(p.memoId)"></operationBtn>
          </div>
        </div>
        <div class="card-body DetailsFonts">
          <div v-html="p.content"></div>
        </div>
        <!--分隔线-->
        <el-divider v-if="index<item.length-1">
          <svg aria-hidden="true" class="icon faxIcon">
            <use xlink:href="#fax-dot-circle-o"></use>
          </svg>
        </el-divider>
      </div>
      <comPage v-if="item && item.length>0" :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
      </comPage>
    </el-card>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';
  import comPage from '@/components/Page.vue';
  export default {
    name: '',
    components: {
      operationBtn,
      search,
      comPage
    },
    data() {
      return {
        fullscreenLoading: false,
        page: null, //分页查询接口返回的结果
        item: [], //列表数据
        pageBtnList: null, //分页左侧按钮集合
        paging: { //分页数据
          pageLength: 0, // 总共页数
          Size: 10, // 当前请求数量
          Index: 1 //当前在第几页
        },
        SearchList: null, //搜索集合
        isPower: {
          //增删改查 权限管理
          adviserclassworkmemo_create: false,
          adviserclassworkmemo_edit: false,
          adviserclassworkmemo_delete: false,
          adviserclassworkmemo_user: false,
        },
        isCurrentTeacher: true, //历史班主任是否为当前用户
        ManagerClass: [], //班主任管理的班级的集合

        TeacherInfo:{},
      };
    },
    created() {
      let that = this;
      that.TeacherInfo= onGetNowUser(that);
      isPower(that, "adviserclassworkmemo_delete", "adviserclassworkmemo_delete");
      isPower(that, "adviserclassworkmemo_create", "adviserclassworkmemo_create");
      isPower(that, "adviserclassworkmemo_edit", "adviserclassworkmemo_edit");
      isPower(that, "adviserclassworkmemo_user", "adviserclassworkmemo_user");
      pagingPage(that);
    },
    methods: {
      onDelete(Id) //删除
      {
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, "/api/admin/adviserclassworkmemo/" + Id, null, function(r) {
              that.fullscreenLoading = false;
              pagingPage(that);
            });
          }
        })
      },
      onEdit(Id) {
        let that = this;
        that.$router.push({
          path: "/Admin/Adviser/ClassWorkMemo/Edit/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onAdd() {
        let that = this;
        that.$router.push({
					path: '/Admin/Adviser/ClassWorkMemo/Create',
					query: {
					  url: that.$router.history.current.fullPath
					}
				});
      },
      onSearch(params) {
        let that = this;
        let data = {};

        let searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = Join(element.data);
          }
        });
        data.PageNumer = 1;
        routerPath(that, "/Admin/Adviser/ClassWorkMemo/Index", data, null, "PageSize");
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      pagingClick(type, val) {
        let that = this;
        let url = "/Admin/Adviser/ClassWorkMemo/Index";
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "SemesterId",
            "ClassId",
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "SemesterId",
            "ClassId",
          );
        }
      },
    },
    watch: {
      $route() {
        let that = this;
        pagingPage(that);
      }
    }
  };

  function pagingPage(that) {
    paging(
      that,
      function(r) {
        that.fullscreenLoading = true;
        ajaxGet(that, "/api/admin/Semester", null, function(semester) {
          if(!regNullArray(semester)){
            let resultClass=that.TeacherInfo.isTeacher ? that.TeacherInfo.manageClass : that.TeacherInfo.allClass;
            let Myurl = that.TeacherInfo.isTeacher? "/api/admin/adviserclassworkmemo/search" : "/api/admin/adviserclassworkmemo/user/search";
            var SemesterId = r.SemesterId;
            var semesters = [];
            for (var i in semester) {
              var it = {
                Title: semester[i].name,
                Value: semester[i].semesterId,
              };
              if (regNull(SemesterId)) {
                if (semester[i].isCurrent == true) {
                  SemesterId = semester[i].semesterId;
                }
              }
              semesters.push(it);
            }

            var ClassId = r.ClassId;
            var classList = [];

            if (regNull(ClassId) && !regNullArray(resultClass)) {
              ClassId = resultClass[0].classId;
            }

            for (var i in resultClass) {
              classList.push({
                Title: resultClass[i].alias,
                Value: resultClass[i].classId,
              });
            }

            that.SearchList = [{
                type: "select",
                Name: "学期",
                data: SemesterId,
                select: semesters, //选择的数据
                zhi: "SemesterId",
                isClear: false, //select是否可以清除
                resetData: semesters[0].Value,//重置数据默认第一个
              },
              {
                type: "select",
                Name: "班级",
                data: ClassId,
                select: classList,
                zhi: "ClassId",
                isClear: false, //select是否可以清除
                resetData: resultClass[0].classId,//重置数据默认第一个
              },

            ];

            let data = {
              PageNumer: r.PageNumer,
              PageSize: r.PageSize,
              SemesterId: SemesterId,
              ClassId: ClassId,
            };
            ajaxGet(that, Myurl, data, function(r1) {
              that.page = r1;
              that.item = r1.items;
              var name = JSON.parse(that.$GetKey("Name"));
              that.isCurrentTeacher = true;
              if (that.item != '') {
                if (that.item[0].adviser != name) {
                  that.isCurrentTeacher = false;
                }
              }

              that.fullscreenLoading = false;
            });

          }else{
            that.fullscreenLoading = false;
          }


        });
      },
      "SemesterId",
      "ClassId",
    );
  }
</script>

<style scoped="scoped">
  .cardList {
    border-bottom: 1px solid #DDDDDD;
  }

  .weekHeaderBox {
    display: flex;
  }

  .weekHeaderBox .elTagBox {
    flex: 1;
  }
</style>
