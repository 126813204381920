<!--
  社团信息 新增/编辑
-->
<template>
  <div class="SubWebPageStudent" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="ruleForm">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{Title}}
          </h3>
        </div>
        <div class="card-body ">
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="社团活动名称" prop="name">
                <el-input v-model="ruleForm.name" placeholder="请输入社团活动名称" class="formControl"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="isSelectClub">
              <el-form-item label="社团" prop="clubId">
                <el-select v-model="ruleForm.clubId" placeholder="请选择社团" class="width_100Pie">
                  <el-option v-for="item in optClub" :key="item.clubId" :label="item.name" :value="item.clubId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="isSelectClub?8:16">
              <el-form-item label="活动地点" prop="place">
                <el-input v-model="ruleForm.place" placeholder="请输入社团活动地点" class="formControl"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="活动时间" prop="activityDate">
                <el-date-picker v-model="ruleForm.activityDate" type="date" placeholder="请选择活动日期" class="width_100Pie">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="活动描述" prop="description">
                <el-input class="formControl" type="textarea" :rows="4" maxlength="500" show-word-limit
                  placeholder="请输入内容" v-model="ruleForm.description">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>


      <!--附件-->
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            附件
          </h3>
        </div>
        <div class="card-body">
          <div class="pt-30">
            <comUpload :key="random" @onPictureHandleSuccess="onPictureHandleSuccess" :files="uploadFiles"></comUpload>
          </div>
        </div>
      </el-card>

      <div class="row">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
          <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
          <el-button type="primary" icon="el-icon-circle-check" @click="onSave()">提交</el-button>
        </div>
      </div>
    </el-form>




  </div>
</template>

<script>
  import '@/assets/css/Student.css';
  import comUpload from '@/components/Upload.vue';


  export default {
    components: {
      comUpload
    },
    data() {
      return {
        // 加载动画
        fullscreenLoading: false,

        random: 0, //刷新组件


        //表单数据
        ruleForm: {
          activityId: null,
          clubId: null,
          name: null,
          place: null,
          activityDate: null,
          description: null,
          attachment: null,
          roster: [],
        },
        // 验证
        rules: {
          clubId:[{
              required: true,
              message: '请选择社团',
              trigger: 'change'
            },],
          name: [{
              required: true,
              message: '社团活动名称不能为空',
              trigger: 'change'
            },
            {
              max: 40,
              message: '社团活动名称不能超过40个字符',
              trigger: 'change'
            },
          ],
          place: [{
              required: true,
              message: '活动地址不能为空',
              trigger: 'change'
            },
            {
              max: 40,
              message: '活动地址不能超过40个字符',
              trigger: 'change'
            },
          ],
          activityDate: [{
            required: true,
            message: '社团活动名称不能为空',
            trigger: 'change'
          }],
          description: [{
            max: 500,
            message: '描述不能超过500个字符',
            trigger: 'change'
          }, ],
        },
        //重置时的表单数据
        ruleFormReset: null,



        Title: "新增社团活动",
        uploadFiles: [],
        uploadFilesReset: null,



        isSelectClub: false,
        optClub:[],
      };
    },

    created() {
      let _this = this;
      let clubId = _this.$route.params.clubId;
      let activityId = _this.$route.params.Id;
      var detailUrl = null;
      if (clubId != null && typeof(clubId) != "undefined" && clubId != "") {
        if (clubId != 0) {
          _this.ruleForm.clubId = clubId;
        } else {
          _this.isSelectClub = true;
          onGetClub(_this);
        }

        if (activityId != null && typeof(activityId) != "undefined" && activityId != "") {
          _this.Title = "编辑社团活动信息";
          _this.ruleForm.activityId = activityId;
          onGetInfo(_this);
        }
      }
    },

    methods: {
      /**
       * @description 上传图片成功
       * @param {Object} param
       */
      onPictureHandleSuccess(param) {
        let _this = this;
        _this.ruleForm.attachment = "";
        for (var i in param) {
          if (_this.ruleForm.attachment == "") {
            _this.ruleForm.attachment = param[i].path;
          } else {
            _this.ruleForm.attachment += "|" + param[i].path;
          }
        }
      },


      /**
       * @description 重置事件
       */
      onReset() {
        let _this = this;
        _this.ruleForm = JSON.parse(JSON.stringify(_this.ruleFormReset));
        _this.uploadFiles = JSON.parse(JSON.stringify(_this.uploadFilesReset));
        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        _this.random += random;
        try {
          _this.$refs["ruleForm"].resetFields();
        } catch {}
      },

      /**
       * @description 提交按钮事件
       */
      onSave() {
        let _this = this;

        _this.$refs["ruleForm"].validate(valid => {
          if (valid) {

            if (regNull(_this.ruleForm.activityId)) {
              // 新增
              _this.fullscreenLoading = true;
              ajaxPost(_this, '/api/student/clubactivity', _this.ruleForm, function(res) {
                _this.fullscreenLoading = false;
                _this.onBack(_this);
              })
            } else {
              // 编辑
              _this.fullscreenLoading = true;
              ajaxPut(_this, '/api/student/clubactivity/' + _this.ruleForm.activityId, _this.ruleForm, function(
              res) {
                _this.fullscreenLoading = false;
                _this.onBack(_this);
              })
            }

          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              isError[0].querySelector('input').focus();
            }, 1)
            return false;
          }
        });
      },





      /**
       * @description 返回事件
       */
      onBack() {
        let _this = this;
        back(_this);
      },
    },
  };

  function onGetClub(_this){
    _this.fullscreenLoading=true;
    ajaxGet(_this,'/api/student/club',null,function(resData){
      _this.fullscreenLoading=false;
      _this.optClub=[];

      for(let item of resData){
        let myInfo=item.member.find(val=> val.studentNo==JSON.parse(_this.$GetKey('StudentNo')));
        if(myInfo.isDirector || myInfo.isManager){
          _this.optClub.push(item);
        }
      }
    });
  }

  function onGetInfo(_this) {
    _this.fullscreenLoading = true;
    ajaxGet(_this, '/api/student/clubactivity/' + _this.ruleForm.activityId, null, function(resData) {
      _this.fullscreenLoading = false;
      _this.ruleForm = resData;

      _this.uploadFiles = [];
      if (resData.attachment != null && typeof(resData.attachment) != "undefined" && resData.attachment != "") {
        var files = resData.attachment.split('|');
        for (var i in files) {
          var it = i + 1;
          _this.uploadFiles.push({
            uid: it,
            path: files[i],
          });
        }
        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        _this.random += random;
      }
      _this.uploadFilesReset = JSON.parse(JSON.stringify(_this.uploadFiles));
    });
  }
</script>

<style scoped="scoped">
  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }

  .spIcon {
    width: 5px;
    height: 20px;
    border-radius: 5px;
    background-image: linear-gradient(#4545FF, #2D8EFF);
  }

  .spTitle {
    float: left;
    margin-left: 20px;
    color: #323E56;
    letter-spacing: 2px;
    font-size: 16px;
    margin-top: -3px;
  }

  .wMax {
    width: 100% !important;
  }
</style>
