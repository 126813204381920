<!--
  新增流程活动
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">

    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{Title}}
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="课程名称" prop="name">
                <el-input v-model="ruleForm.name" placeholder="请输入" class="formControl"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="课程类型" prop="typeId">
                <el-cascader v-model="ruleForm.typeId" :show-all-levels="false" class="width_100Pie" :options="optData"
                  @change="onChangeType">
                </el-cascader>
              </el-form-item>
            </el-col>
<!--            <el-col :span="12">
              <el-form-item class="elFormItemBox">
                <el-checkbox v-model="ruleForm.isImport">是否导入指定名单</el-checkbox>
              </el-form-item>
            </el-col> -->
            <el-col :span="12">
              <el-form-item label="课程时间" prop="courseTime">
                <el-date-picker style="width: 100% !important;" v-model="ruleForm.courseTime" type="datetimerange"
                  range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right" class="formControl" format="yyyy-MM-dd HH:mm">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>

      <!--班级范围-->
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            班级范围
          </h3>
        </div>
        <div class="card-body ">
          <el-row :gutter="24">
            <el-col :span="24">
              <el-input placeholder="输入关键字进行过滤" v-model="filterTextSignUp">
              </el-input>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="EqualDiv" style="margin-top: 10px;">
            <el-col :span="24">
              <el-col :span="10" class="divTreeCont" style="height: 300px; overflow-y: auto;">
                <el-tree :default-checked-keys="defaultcheckedRangesSignUp" :filter-node-method="filterNodeSignUp"
                  ref="treeSignUp" default-expand-all :data="signUpColleges" :props="defaultProps" show-checkbox
                  node-key="id" @check-change="handleCheckChangeSignUp"></el-tree>
              </el-col>
              <el-col :span="4" class="VerticalMid" style="padding-top: 120px;">
                <svg class="icon iconBox" aria-hidden="true">
                  <use xlink:href="#fax-arrow-left-right-alt"></use>
                </svg>
              </el-col>
              <el-col :span="10" class="divTreeCont" style="height: 300px; overflow-y: auto;">
                <el-tree default-expand-all :data="selectTreeSigeUpRanges" :props="defaultProps" node-key="id">
                </el-tree>
              </el-col>
            </el-col>
          </el-row>
        </div>
      </el-card>

      <!--综合类型-->
       <div v-if="configs.length>0">
        <SuYanType
          ref="LiteracyRef"
          :configList='configs'
          :defaultConfig ='defaultConfigs'
          @change="handleTypesChange"
        />
      </div>
      <!--课程附件-->
      <el-card class="box-card mt-3">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            课程附件
          </h3>
        </div>
        <div class="card-body p-5">
          <comUpload :key="random" @onPictureHandleSuccess="onPictureHandleSuccess" :files="uploadFiles"
            :isPicture="false" :pictureMaxCount="1" :isVideoOrPdf="true"></comUpload>
        </div>
      </el-card>

      <!--课程内容-->
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            课程内容
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item prop="code" label=" " label-width="0px">
                <quill-editor height="300px" v-model="ruleForm.description" ref="text" class="myQuillEditor"
                  :options="editorOption" />
                <!-- <el-input type="textarea" v-model="ruleForm.description" :autosize="{ minRows: 5}" maxlength="500"
                show-word-limit></el-input> -->
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>


      <div class="row" style="margin-top: 10px;">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
          <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
          <el-button type="primary" icon="el-icon-circle-check" @click="onSave()">提交</el-button>
        </div>
      </div>

    </el-form>



  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comUpload from '@/components/Upload.vue';
  import SuYanType from '@/components/Admin/suYanLeiXing.vue'
  import {
    quillEditor,
  } from 'vue-quill-editor';
  import * as Quill from 'quill' //引入编辑器
  var fonts = ['SimSun', 'SimHei', 'Microsoft-YaHei', 'KaiTi', 'FangSong', 'Arial', 'Times-New-Roman', 'sans-serif'];
  var Font = Quill.import('formats/font');
  Font.whitelist = fonts; //将字体加入到白名单
  Quill.register(Font, true);


  export default {
    components: {
      comUpload,
      quillEditor,
      SuYanType
    },
    data() {
      return {
        // 加载动画
        fullscreenLoading: false,
        configs:[],
        defaultConfigs:[],

        editorOption: {
          placeholder: '请在此输入内容',
          modules: {
            toolbar: [
              [{
                'font': fonts
              }], //字体，把上面定义的字体数组放进来
              ['bold', 'italic', 'underline',
              'strike'], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
              ['blockquote', 'code-block'], // 引用  代码块-----['blockquote', 'code-block']
              [{
                header: 1
              }, {
                header: 2
              }], // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
              [{
                list: 'ordered'
              }, {
                list: 'bullet'
              }], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
              [{
                script: 'sub'
              }, {
                script: 'super'
              }], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
              [{
                indent: '-1'
              }, {
                indent: '+1'
              }], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
              [{
                'direction': 'rtl'
              }], // 文本方向-----[{'direction': 'rtl'}]
              [{
                size: ['small', false, 'large', 'huge']
              }], // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
              [{
                header: [1, 2, 3, 4, 5, 6, false]
              }], // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
              [{
                color: []
              }, {
                background: []
              }], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
              [{
                align: []
              }], // 对齐方式-----[{ align: [] }]
              ['clean'], // 清除文本格式-----['clean']
              ['image'], // 链接、图片、视频-----['link', 'image', 'video']
            ]
          }
        },
        //学生范围树形默认勾选
        defaultcheckedRangesSignUp: [],
        //刷新组件
        random: 0,
        // 上传附件
        uploadFiles: [],
        uploadFilesReset: [],

        // 范围
        filterTextSignUp: '',

        // 数据集合
        ruleForm: {
          courseId: null, //主键
          name: null, //课程名称
          startDate: null, //开始时间
          endDate: null, //结束时间
          typeId: null, //类型主键
          attachment: null, //附件
          duration: 0, //观看时长 单位：秒
          isTest: false, //是否需要测验
          floor: 0, //通过最低分
          description: null, //描述
          status: 1, //状态
          configs: [], //综合评价配置
          ranges: [], //报名范围
          courseTime: null, //时间范围
          signUpRanges: [], //报名范围
          isImport: true, //是否导入指定名单（报名范围）
 					GrowthConfigs:[],//综合类型集合，放在ruleForm里是为了选中后进行验证
					treeList:[],//遍历综合类型tree
        },
        // 验证重置
        ruleFormReset: {},
        // 网站标题
        Title: "新增综合课堂",
        // 综合评价
        currentConfig: [],
        currentConfigReset: [],
        //类型集合
        optData: [],

        //学生范围集合(树形结构)
        colleges: [],
        signUpColleges: [],
        signUpCollegesReset: [],
        //学生范围集合
        allColleges: [],
        //选中的学生范围集合
        selectSigeUpRanges: [],
        //选中的学生范围集合（树形结构）
        selectTreeSigeUpRanges: [],
        selectTreeSigeUpRangesReset: [],

        // 学生范围的数据格式
        defaultProps: {
          children: 'children',
          label: 'name'
        },
        // 验证
        rules: {
          name: [{
              required: true,
              message: '综合课堂名称不能为空',
              trigger: 'change'
            },
            {
              max: 256,
              message: '综合课堂不能超过256个字符',
              trigger: 'change'
            },
          ],
          typeId: [{
            // type: 'array',
            required: true,
            message: '请选择课程类型',
            trigger: 'change'
          }],
          courseTime: [{
            required: true,
            message: '课程时间不能为空',
            trigger: 'change'
          }]
        },
      };
    },
    created() {
      let _this = this;
      let dataID = _this.$route.params.id;
      var detailUrl = null;
      if (dataID != null && typeof(dataID) != "undefined" && dataID != "") {
        _this.Title = "编辑综合课堂";
        _this.ruleForm.courseId = dataID;
        detailUrl = "/api/admin/literacycourse/" + dataID;
      }
      onGetData(_this, detailUrl);
    },

    computed:{
      getTableProp(){
        return function(index,index2){
          return `treeList[${index}].children[${index2}].score`
        }
      }
    },
    methods: {
      handleTypesChange(val){
        this.ruleForm.GrowthConfigs = JSON.parse(JSON.stringify(val))
      },
			onPriorityChange()
			{
				let that = this;
				var value = setInt(that.ruleForm.priority);
				that.ruleForm.priority = value;
			},
			onScoreChange(Id,obj)//成绩改变
			{
				let that = this;
				for(var i in that.ruleForm.GrowthConfigs)
				{
					if(that.ruleForm.GrowthConfigs[i].configId == Id)
					{
						var score = setTwoDecimal(setTwoFloat(obj.score));
						that.ruleForm.GrowthConfigs[i].score = score;
						that.$set(that.ruleForm.GrowthConfigs, i, that.ruleForm.GrowthConfigs[i]);
					}
				}
			},
			onSelectGrowthConfig(Id,obj)//复选框选中
			{
				let that = this;
				for(let i of that.ruleForm.GrowthConfigs)
				{
					if(i.configId == Id)
					{
						i.isSelect = obj.isSelect;
					}
				}
			},
      // 选择类型
      onChangeType(_value) {
        let _this = this;
        onGetGrowthConfig(_this, _value[_value.length - 1]);

      },
      onPictureHandleSuccess(param) {
        let _this = this;
        _this.ruleForm.attachment = "";
        for (var i in param) {
          if (_this.ruleForm.attachment == "") {
            _this.ruleForm.attachment = param[i].path;
            _this.ruleForm.duration = param[i].duration;

          } else {
            _this.ruleForm.attachment += "|" + param[i].path;
          }
        }
      },
      // 范围搜索
      filterNodeSignUp(value, data) {
        if (!value) return true;
        return data.name.indexOf(value) !== -1;
      },
      // 范围选择
      handleCheckChangeSignUp(data, checked, indeterminate) {
        let _this = this;
        if (checked == true && data.classId != null && typeof(data.classId) != "undefined") {
          _this.ruleForm.signUpRanges.push(data.classId);
        } else if (checked == false && data.classId != null && typeof(data.classId) != "undefined") {
          let newRanges = _this.ruleForm.signUpRanges.filter((item, index, arr) => {
            return item != data.classId;
          });
          _this.ruleForm.signUpRanges = newRanges;
        }
        _this.selectSigeUpRanges = [];
        for (var classId of _this.ruleForm.signUpRanges) {
          GetSelectSigeUpRanges(_this, classId);
        }
        _this.selectTreeSigeUpRanges = [];
        _this.selectTreeSigeUpRanges = toTreeByRecursion(_this.selectSigeUpRanges, 'id', 'parentId', null, 'children');
      },
      // 重置事件
      onReset() {
        let _this = this;
        _this.ruleForm = JSON.parse(JSON.stringify(_this.ruleFormReset));
        _this.signUpColleges = JSON.parse(JSON.stringify(_this.signUpCollegesReset));
        _this.selectTreeSigeUpRanges = JSON.parse(JSON.stringify(_this.selectTreeSigeUpRangesReset));
        _this.uploadFiles = JSON.parse(JSON.stringify(_this.uploadFilesReset));
        // 重置ID
        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        _this.random += random;
        try {
          _this.$refs.LiteracyRef.onReset()
          _this.$refs["ruleForm"].resetFields();
        } catch {}
      },
      // 返回
      onBack() {
        let _this = this;
        back(_this);
      },
      // 数据处理
      onGetOptions(_this, _data) {
        let arrTemp = [];
        if (_data.length != 0) {
          let temp = [];
          for (let item of _data) {
            if (item.children.length != 0) {
              temp.push({
                value: item.id,
                label: item.name,
                children: _this.onGetOptions(_this, item.children)
              });
            } else {
              temp.push({
                value: item.id,
                label: item.name,
              });
            }
          }
          arrTemp = temp;
        }
        return arrTemp;
      },



      // 保存
      onSave() {
        let _this = this;
        // 验证
        let isError = false
        _this.$refs.LiteracyRef.validateForm((flag) =>{
          isError = flag
        })
        _this.$refs["ruleForm"].validate((valid) => {
          if (valid && isError) {
            // 综合配置
          let configs = [];
          for (let i in _this.ruleForm.GrowthConfigs) {
            let config = _this.ruleForm.GrowthConfigs[i];
            if (config.isSelect == true) {
              let newConfig = {
                configId: config.configId,
                score: config.score
              };
              configs.push(newConfig);
            }
          }
          _this.ruleForm.configs = configs;
            // 报名范围
            _this.ruleForm.ranges = [];
            // if (!_this.ruleForm.isImport) {
              for (var it of _this.ruleForm.signUpRanges) {
                if (it != null) {
                  _this.ruleForm.ranges.push({
                    classId: it,
                  });
                }
              }
            // }


            // 课程时间
            _this.ruleForm.startDate = null;
            _this.ruleForm.endDate = null;
            if (_this.ruleForm.courseTime != null && _this.ruleForm.courseTime.length > 0) {
              if (_this.ruleForm.courseTime[0] != null) {
                _this.ruleForm.startDate = timeTransform(_this.ruleForm.courseTime[0]);
              }
              if (_this.ruleForm.courseTime[1] != null) {
                _this.ruleForm.endDate = timeTransform(_this.ruleForm.courseTime[1]);
              }
            }
            const {courseId,courseTime,signUpRanges,treeList,GrowthConfigs,...params} = _this.ruleForm;
            // 判断是否为编辑
            if (_this.ruleForm.courseId == null) { // 新增
            _this.ruleForm.typeId = _this.ruleForm.typeId[_this.ruleForm.typeId.length - 1];
              _this.fullscreenLoading = true;
              ajaxPost(_this, "/api/admin/literacycourse",params, function(r) {
                _this.fullscreenLoading = false;
                _this.onBack();
              });
            } else { // 编辑
              _this.fullscreenLoading = true;
              ajaxPut(_this, "/api/admin/literacycourse/" + _this.ruleForm.courseId,_this.ruleForm, function(r) {
                _this.fullscreenLoading = false;
                _this.onBack();
              });
            }
          } else {
            return false;
          }
        });
      },
    },
    watch: {
      filterTextSignUp(val) {
        this.$refs.treeSignUp.filter(val);
      },
    }
  };
  // 获取数据
  function onGetData(_this, detailUrl) {

    //_this.fullscreenLoading = true;
    ajaxGet(_this, detailUrl, null, function(r) {
      if (r != null) {

        for (let key in _this.ruleForm) {
          for (var it in r) {
            if (key.toLowerCase() == it.toLowerCase()) {
              _this.ruleForm[key] = r[it];
            }
          }
        }
        _this.ruleForm.courseTime = [];
        if (_this.ruleForm.startDate != null) {
          _this.ruleForm.courseTime.push(_this.ruleForm.startDate);
        }
        if (_this.ruleForm.endDate != null) {
          _this.ruleForm.courseTime.push(_this.ruleForm.endDate);
        }
        _this.uploadFiles = [];
        if (_this.ruleForm.attachment != null && typeof(_this.ruleForm.attachment) != "undefined" && _this
          .ruleForm
          .attachment != "") {
          var files = _this.ruleForm.attachment.split('|');
          for (var i in files) {
            var it = i + 1;
            _this.uploadFiles.push({
              uid: it,
              path: files[i],
            });
          }
          var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
          _this.random += random;
        }
        _this.uploadFilesReset = JSON.parse(JSON.stringify(_this.uploadFiles));
      }

      // 课程类型
      _this.fullscreenLoading = true;
      ajaxGet(_this, "/api/admin/literacycoursetype/treelist", null, function(resType) {
          if(r != null)
          {
            _this.ruleForm.typeId = r.typeId
          }
        ajaxGet(_this, "/api/admin/GrowthConfig", null, function(r1) {
          _this.configs = r1.filter(val => {return val.configType == 1});
          _this.optData = _this.onGetOptions(_this, resType);//设置课程类型opt
					_this.fullscreenLoading = false;

					if(_this.ruleForm.courseId == null) //新增时
					{

					}else{//编辑时
              _this.defaultConfigs = r.configs

					}
					_this.$set(_this.ruleForm, "GrowthConfigs", r1);//更新综合类型列表
					_this.ruleFormReset = JSON.parse(JSON.stringify(_this.ruleForm));//设置重置的初始值
          ajaxGet(_this, "/api/admin/college/linkage", null, function(colleges) {
          //设置班级映射
          let tree = []
          for(let item of colleges){
            let child = {
              id:item.collegeId,
              children:item.classes,
              name:item.name,
              parentId:'00000000-0000-0000-0000-000000000000'
            }
            if(child.children!==null && child.children.length>0){
              for(let children of child.children){
                children.id = children.classId
                children.parentId = child.id
                children.children = children.classes
                if(children.children!=null && children.children.length>0){
                    for (let it of children.children) {
                      it.parentId = children.id;
                      it.id = it.classId;
                    }
                }
              }
            }
            tree.push(child)
          }
            _this.colleges = [{
              id: "00000000-0000-0000-0000-000000000000",
              name: "全校学生",
              children: tree,
              parentId: null,
            }];
            _this.allColleges = [];
            InitCollege(_this, _this.colleges);
            _this.signUpColleges = JSON.parse(JSON.stringify(_this.colleges));
            _this.signUpCollegesReset = JSON.parse(JSON.stringify(_this.signUpColleges));
            if (_this.ruleForm.courseId != null) {
              _this.defaultcheckedRangesSignUp = [];
              if (r.ranges != null && r.ranges.length > 0) {
                _this.selectSigeUpRanges = [];
                for (var range of r.ranges) {
                  _this.ruleForm.signUpRanges.push(range.classId);
                  _this.defaultcheckedRangesSignUp.push(range.classId);
                  GetSelectSigeUpRanges(_this, range.classId);
                }
                _this.selectTreeSigeUpRanges = [];
                _this.selectTreeSigeUpRanges = toTreeByRecursion(_this.selectSigeUpRanges, 'id',
                  'parentId', null,
                  'children');
                _this.selectTreeSigeUpRangesReset = JSON.parse(JSON.stringify(_this
                  .selectTreeSigeUpRanges));
              }
            }

            _this.ruleFormReset = JSON.parse(JSON.stringify(_this.ruleForm));

          });

        });
      });




      // 设置自定义ID
      var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
      _this.random += random;
    });

  }

  // 综合配置
  function onGetGrowthConfig(_this, _typeID) {
    _this.fullscreenLoading = true;
    ajaxGet(_this, '/api/admin/literacycoursetype/' + _typeID, null, function(resInfo) {
      ajaxGet(_this, "/api/admin/GrowthConfig", null, function(resData) {
        _this.fullscreenLoading = false;
        _this.defaultConfigs = []
        _this.ruleForm.typeId = resInfo.typeId
        _this.defaultConfigs.push(...resInfo.configs)
        _this.$set(_this.ruleForm, "GrowthConfigs", resData);
      });
    });

  }

  // 设置班级范围数据
  function InitCollege(_this, val) {
    if (val.length !== 0) {
      val.forEach((item) => {
        _this.allColleges.push(item);
        if (item.children != null && typeof(item.children) != "undefined" && item.children.length >= 1) {
          InitCollege(_this, item.children);
        }
      });
    }
  }

  // 班级范围
  function GetSelectSigeUpRanges(that, objID) {
    let temp = that.allColleges.find(item => item.id == objID);
    if (temp != undefined) {
      if (that.selectSigeUpRanges.find(item => item.id == temp.id) == undefined) {
        that.selectSigeUpRanges.push(temp);
        if (!regNull(temp.parentId)) {
          GetSelectSigeUpRanges(that, temp.parentId);
        }
      }
    }
  }
</script>

<style scoped="scoped">
  .elFormItemBox {
    min-height: 48px;
  }

  .transferBox {
    margin: 0 auto;
  }

  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    /* padding-top: 15px; */
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }

  .tabListCont .divNavTitle {}

  .tabListCont .divNavTitle {}

  .tabListCont .divNavTitle .divTitleCont {
    padding: 10px 0px 10px 10px;
    color: #DEE2E6;
    cursor: pointer;
    font-size: 18px;
  }

  .tabListCont .divNavTitle .divTitleCont .spNum {
    background-color: #DEE2E6;
    font-family: 'OswaldLight';
    padding: 2px 5px;
    border-radius: 4px;
    margin-left: 5px;
    font-size: 14px;
    color: #FFFFFF;
  }

  .tabListCont .divNavTitle .divTitleAct {
    color: #3e3e3e;
  }

  .tabListCont .divNavTitle .divTitleAct .spNum {
    background-color: #17A2B8;
    color: #FFFFFF;
  }

  .tabListCont .divNavTitle .nav-link {
    font-size: 14px;
    color: #B1B1B1;
  }

  .tabListCont .divNavTitle .nav-link .spNum {
    font-family: 'OswaldLight';
    font-weight: normal;
    font-size: 14px;
    padding-left: 10px;
  }

  .tabListCont .divNavTitle .active {
    color: #007bff;
  }

  .divItemTitle {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #606266;
  }

  .divAlertCont {
    padding-left: 30px;
    padding-top: 10px;
    color: #9595A0;
  }


  .divTreeCont {
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    padding: 15px;
    min-height: 150px;
  }

  .iconBox {
    font-size: 52px;
  }
</style>

<style>
  .divTreeCont .el-checkbox {
    margin-bottom: 0 !important;
  }

  .transferCont .el-transfer__buttons {
    width: 20%;
  }

  .transferCont .el-transfer__buttons .el-transfer__button:first-child {
    float: left;
  }

  .transferCont .el-transfer__buttons .el-transfer__button:last-child {
    float: right;
  }

  .transferCont .el-transfer-panel {
    width: 40%;
  }

  .formGroupCont .el-date-editor--datetimerange.el-input__inner {
    width: 100%;
  }

  .checkListTitle .el-form-item__label {
    display: block;
    width: 100%;
  }
</style>
<style>
  .ql-toolbar.ql-snow {
    line-height: 20px;
  }

  .ql-snow .ql-tooltip[data-mode=link]::before {
    content: "请输入链接地址:";
  }

  .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    border-right: 0px;
    content: '保存';
    padding-right: 0px;
  }

  .ql-snow .ql-tooltip[data-mode=video]::before {
    content: "请输入视频地址:";
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: '14px';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=small]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
    content: '10px';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=large]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
    content: '18px';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=huge]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
    content: '32px';
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=SimSun]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=SimSun]::before {
    content: "宋体";
    font-family: "SimSun";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=SimHei]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=SimHei]::before {
    content: "黑体";
    font-family: "SimHei";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=Microsoft-YaHei]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=Microsoft-YaHei]::before {
    content: "微软雅黑";
    font-family: "Microsoft YaHei";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=KaiTi]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=KaiTi]::before {
    content: "楷体";
    font-family: "KaiTi";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=FangSong]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=FangSong]::before {
    content: "仿宋";
    font-family: "FangSong";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=Arial]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=Arial]::before {
    content: "Arial";
    font-family: "Arial";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=Times-New-Roman]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=Times-New-Roman]::before {
    content: "Times New Roman";
    font-family: "Times New Roman";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=sans-serif]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=sans-serif]::before {
    content: "sans-serif";
    font-family: "sans-serif";
  }

  .ql-font-SimSun {
    font-family: "SimSun";
  }

  .ql-font-SimHei {
    font-family: "SimHei";
  }

  .ql-font-Microsoft-YaHei {
    font-family: "Microsoft YaHei";
  }

  .ql-font-KaiTi {
    font-family: "KaiTi";
  }

  .ql-font-FangSong {
    font-family: "FangSong";
  }

  .ql-font-Arial {
    font-family: "Arial";
  }

  .ql-font-Times-New-Roman {
    font-family: "Times New Roman";
  }

  .ql-font-sans-serif {
    font-family: "sans-serif";
  }
</style>
