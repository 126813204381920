<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            新增学生总评配置
            <small class="color-danger">（达标分数与名次至少选择一项输入）</small>
          </h3>
        </div>
        <div class="card-body ">
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="类型" prop="type">
                <el-radio v-model="ruleForm.type" label="1">范围</el-radio>
                <el-radio v-model="ruleForm.type" label="2">学年</el-radio>
                <el-radio v-model="ruleForm.type" label="3">学期</el-radio>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="ruleForm.type==1">
              <el-form-item label="入学年份" prop="startDate">
                <el-input v-model="ruleForm.startDate" placeholder="请输入入学年份" @change="onYearVerification"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="ruleForm.type==1">
              <el-form-item label="毕业年份" prop="endDate">
                <el-input v-model="ruleForm.endDate" placeholder="请输入毕业年份" @change="onYearVerification"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="ruleForm.type==2">
              <el-form-item label="学年" prop="yearId">
                <el-select v-model="ruleForm.yearId" placeholder="请选择学年" class="width_100Pie">
                  <el-option v-for="item in optYear" :key="item.yearId" :label="item.name" :value="item.yearId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="ruleForm.type==3">
              <el-form-item label="学期" prop="semesterId">
                <el-select v-model="ruleForm.semesterId" placeholder="请选择学期" class="width_100Pie">
                  <el-option v-for="item in optSemester" :key="item.semesterId" :label="item.name"
                    :value="item.semesterId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>


          <el-row :gutter="24" v-for="(item,index) in optConfigure" :key="index">
            <el-col :span="2">
              <el-form-item :label="index+1+'、'">
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="总评名称" prop="name">
                <el-input v-model="item.name" placeholder="请输入总评名称" class="width_100Pie">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="达标分数" prop="score">
                <el-input v-model="item.score" placeholder="请输入达标分数" class="width_100Pie" @change="resetValidateScore">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="名次（%）" prop="ratio">
                <el-input v-model="item.ratio" placeholder="请输入名次" class="width_100Pie" @change="resetValidateRatio">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-button type="danger" icon="el-icon-delete" circle @click="onDelete(item)"></el-button>
            </el-col>
          </el-row>
        </div>
      </el-card>


      <div class="row " style="margin-top: 10px;">
        <div class="col-6">
          <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
          <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
          <el-button type="primary" icon="el-icon-circle-check" @click="onSubmit()">提交</el-button>
        </div>
        <div class="col-6 text-right">
          <el-button type="success" icon="el-icon-circle-plus-outline" @click="onCreate()">新增配置项</el-button>
        </div>
      </div>
    </el-form>

  </div>

</template>

<script>
  import '@/assets/css/Admin.css';
  export default {
    components: {},
    data() {
      return {
        // 加载动画
        fullscreenLoading: false,
        ruleFormReset: null,
        // 表单对象
        ruleForm: {
          type: null,
          startDate: null,
          endDate: null,
          yearId: null,
          semesterId: null,
        },
        // 动态配置
        optConfigure: [{
          id: getTimeID(),
          name: null,
          score: null,
          ratio: null,
        }],
        rules: {
          type: [{
            required: true,
            message: '请至少选择一个总评类型',
            trigger: 'change'
          }],
          startDate: [{
            required: true,
            message: '入学年份不能为空',
            trigger: 'change',

          }, {
            validator: (rule, value, callback) => {
              let that = this;
              var reg = /^[+]{0,1}(\d+)$/;
              if (!regNull(value)) {
                if (!reg.exec(value)) {
                  callback(new Error("请正常输入入学年份！"));
                } else {
                  callback();
                }
              }
            },
          }],
          endDate: [{
            required: true,
            message: '毕业年份不能为空',
            trigger: 'blur'
          }, {
            validator: (rule, value, callback) => {
              let that = this;
              var reg = /^[+]{0,1}(\d+)$/;
              if (!regNull(value)) {
                if (!reg.exec(value)) {
                  callback(new Error("请正常输入毕业年份！"));
                } else {
                  callback();
                }
              }
            },
          }],
          yearId: [{
            required: true,
            message: '请选择学年',
            trigger: 'change'
          }],
          semesterId: [{
            required: true,
            message: '请选择学年',
            trigger: 'change'
          }],
        },

        optYear: [], // 学年
        optSemester: [], // 学期
      };
    },
    created() {
      let that = this;
      that.fullscreenLoading = true;
      ajaxGet(that, '/api/admin/academic', null, function(resYear) {
        ajaxGet(that, '/api/admin/semester', null, function(resSemester) {
          that.fullscreenLoading = false;
          that.optYear = resYear;
          that.optSemester = resSemester;
          that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
        });
      });
    },
    methods: {
      /**
       * @description 年份大小比对
       */
      onYearVerification() {
        let that = this;
        if (!regNull(that.ruleForm.startDate) && !regNull(that.ruleForm.endDate)) {
          if (setInt(that.ruleForm.endDate) - setInt(that.ruleForm.startDate) < 0) {
            that.ruleForm.endDate = null;
            warning(that, '毕业年份不能小于入学年份');
          }
        }
      },
      /**
       * @description 名次
       * @param {Object} objVal
       */
      resetValidateRatio(objVal) {
        let that = this;
        if (!regNull(objVal)) {
          var reg = /^\d+(\.\d{1,1})?$/;
          if (objVal != 0 && !reg.exec(objVal)) {
            warning(that, '名次小数保留1位');
          }
        }
      },
      /**
       * @description 动态验证分数
       * @param {Object} objVal
       */
      resetValidateScore(objVal) {
        let that = this;
        if (objVal != null && objVal != '' && objVal.length != 0) {
          var reg = /^[+]{0,1}(\d+)$/;
          if (!reg.exec(objVal)) {
            warning(that, '达标分数请输入数字');
          }
        }
      },
      /**
       * @description 动态验证总评名称
       * @param {Object} objVal
       */
      resetValidateName(objVal) {
        let that = this;
        if (regNull(objVal)) {
          warning(that, '总评名份不能为空');
        }
      },
      /**
       * @description 新增配置
       */
      onCreate() {
        let that = this;
        that.optConfigure.push({
          id: getTimeID(),
          name: null,
          score: null,
          ratio: null,
        })
      },
      /**
       * @description 删除
       * @param {Object} _valID ID
       */
      onDelete(objItem) {
        let that = this;
        if (that.optConfigure.length == 1) {
          warning(that, '至少需要保留一项配置');
          return false;
        } else {
          that.optConfigure = that.optConfigure.filter(item => item.id != objItem.id);
        }
      },

      /**
       * @description 提交事件
       */
      onSubmit() {
        let that = this;
        that.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            let tpData = [];
            let isPost = true;
            that.optConfigure.forEach((item, index) => {
              if (!regNull(item.name)) {
                if (regNull(item.ratio) && regNull(item.score)) {
                  warning(that, `第 ${index+1} 行，总评名称【${item.name}】的达标分数与名次两项必须选填一项`);
                  isPost = false;
                  return false;
                } else {
                  if (regNull(that.ruleForm.startDate)) {
                    tpData.push({
                      name: item.name,
                      semesterId: that.ruleForm.semesterId,
                      yearId: that.ruleForm.yearId,
                      score: item.score,
                      ratio: regNull(item.ratio) ? null : setTwoDecimal((item.ratio / 100), 2),
                    });
                  } else {
                    tpData.push({
                      name: item.name,
                      semesterId: that.ruleForm.semesterId,
                      yearId: that.ruleForm.yearId,
                      score: item.score,
                      ratio: regNull(item.ratio) ? null : setTwoDecimal((item.ratio / 100), 2),
                      startYear: setInt(that.ruleForm.startDate),
                      endYear: setInt(that.ruleForm.endDate),
                    });
                  }

                }
              } else {
                warning(that, `第 ${index+1} 行，总评名称不能为空`);
                isPost = false;
                return false;
              }
            });
            if (isPost) {
              that.fullscreenLoading = true;
              ajaxPost(that, '/api/admin/growthlevel/addrange', tpData, function(res) {
                that.fullscreenLoading = false;
                if (res.result == true) {
                  back(that);
                }
              });
            }



          } else {
            return false;
          }
        });
      },

      /**
       * @description 返回
       */
      onBack() {
        back(this);
      },
      onReset() {
        let that = this;
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
        that.optConfigure = [{
          id: getTimeID(),
          name: null,
          score: null,
          ratio: null,
        }]
      },

    }
  };
</script>

<style scoped="scoped">
  .icoImageBox {
    width: 100px;
    height: 100px;
    margin: 5px auto;
    border: 1px dotted #DDDDDD;
    border-radius: 10px;
    padding: 5px;
    cursor: pointer;
  }

  .actIcon {
    border: 1px solid #007bff;
  }


  .divCheckedBox {
    width: 300px;
  }

  .divCheckedBox .spChecked {
    float: left;
    padding-right: 30px;
  }
</style>
