<!--
  处分管理 - 新增
-->
<template>
  <div class="SubWebPageStudent" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{Title}}
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="奖项名称" prop="name">
                <el-input v-model="ruleForm.name" placeholder="请输入奖项名称" class="formControl"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="奖励日期" prop="prizeDate" :rules="[{ required: true, message: '请输入奖励日期', trigger: 'blur' }]">
                <el-date-picker value-format="yyyy-MM-dd" :key="random"
                  v-model="ruleForm.prizeDate" type="date" placeholder="请选择学期范围内的日期" class="wMax formControl">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="奖励级别" prop="levelId" :rules="[{ required: true, message: '请选择奖励级别', trigger: 'change' }]">
                <el-cascader v-model="level" placeholder="请选择" style="width:100% !important"
                  expand-trigger="hover" class="formControl" @change="handleCascaderChange"
                  :options="prizeLevels"></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备注" prop="remark">
                <el-input  class="formControl" type="textarea" :rows="4" maxlength="500" show-word-limit
                  placeholder="请输入内容" v-model="ruleForm.remark">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>
      <!--附件-->
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            附件
          </h3>
        </div>
        <div class="card-body pt-5">
          <comUpload :key="random" @onPictureHandleSuccess="onPictureHandleSuccess" :files="uploadFiles"></comUpload>
        </div>
      </el-card>
      <!--附件-->
      <el-card class="box-card" v-if="auditRecords.length>0">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            审核记录
          </h3>
        </div>
        <div class="card-body p-0">
          <el-table :data="auditRecords" stripe class="elTableData">
            <el-table-column type="index" label="序号" width="80"></el-table-column>
            <el-table-column prop="type" label="审核时间" width="160">
              <template slot-scope="scope">
                {{scope.row.createDate | dateformatDay}}
              </template>
            </el-table-column>
            <el-table-column prop="auditReason" label="备注">
              <template v-slot='scope'>
                <span class="recodeStyle" v-html="scope.row.auditReason"></span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-card>
      <div class="row" style="margin-top: 10px;">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
          <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
          <el-button type="primary" icon="el-icon-circle-check" @click="onSave()">提交</el-button>
        </div>
      </div>
    </el-form>


  </div>
</template>

<script>
  import '@/assets/css/Student.css';
  import comUpload from '@/components/Upload.vue';
  export default {
    components: {
      comUpload
    },
    data() {
      return {
        prizeTypes:[],//奖励类型集合
        prizeLevels:[],//奖励级别集合
        auditRecords:[],//奖励集合
        Title: "新增奖励信息",
        fullscreenLoading: false,
        uploadFiles: [],
        random: 0, //刷新组件
        applyId:null,
        Semester:[],//当前学期最大最小
        prizeDateMin: null,
        prizeDateMax: null,
        level:[],
        ruleForm:{
          typeId:null,
          levelId:null,
          name:null,
          prizeDate:null,
          attachment:null,
          remark:null
        },
        pickerOptions: {
          disabledDate:date =>{
            let prizeDateMin = this.Semester[0]
            let prizeDateMax = this.Semester[1]
            if (prizeDateMin != null && typeof(prizeDateMin) != "undefined" && prizeDateMax != null && typeof(prizeDateMax) !=
              "undefined") {
              return new Date(date).getTime() < new Date(prizeDateMin).getTime() || new Date(date).getTime() > new Date(
                prizeDateMax).getTime();
            } else if (prizeDateMin != null && typeof(prizeDateMin) != "undefined" && (prizeDateMax == null || typeof(
                prizeDateMax) == "undefined")) {
              return new Date(date).getTime() < new Date(prizeDateMin).getTime();
            } else if ((prizeDateMin == null || typeof(prizeDateMin) == "undefined") && prizeDateMax != null && typeof(
                prizeDateMax) != "undefined") {
              return new Date(date).getTime() > new Date(prizeDateMax).getTime();
            }
            return false;
          }
        },//奖励时间不能超出学期的时间范围
        rules: {
          name: [
            {
              required: true,
              message: '奖项名称不能为空',
              trigger: 'change'
            },
          ]
        },
      };
    },
    created() {
      let that = this;
      let id = that.$route.query.Id;
      if (id != null && typeof(id) != "undefined" && id != "") {
         that.applyId = id;
         that.Title = "编辑奖励信息";
      }
      that.initFormData();
    },
    methods: {
      getArrayId(childId,options){
				let Ids = [];
				let that = this;
				options.filter((item,index) =>{
					let childIndex = item.children && item.children.findIndex(it =>it.value == childId);
					if(childIndex!=-1){
						Ids = [options[index].value,item.children[childIndex].value];
					}
				});
				return Ids;
      },
      initFormData(){
        let that = this;
        that.fullscreenLoading = true;
        ajaxGet(that,'/api/student/prizeapply/prizetype',null,function(r1){
          that.prizeTypes = r1;
          ajaxGet(that,'/api/student/prizeapply/prizelevel',null,function(r2){
            that.prizeLevels = r2.map(item =>mapTree(item));
            ajaxGet(that,'/api/student/semester/student',null,function(r3){
              that.Semester = [r3[0].startDate,r3[0].endDate];
              if(that.applyId){
                ajaxGet(that,`/api/student/prizeapply/${that.applyId}`,null,function(r3){
                    if(r3.audit.length>0){
                      that.auditRecords = r3.audit;
                    }
                    that.uploadFiles = r3.attachment.split('|').map(item =>{ return {path:item} })
                    that.ruleForm.name = r3.name;
                    that.ruleForm.remark = r3.remark;
                    that.ruleForm.attachment = r3.attachment;
                    that.ruleForm.typeId = r3.typeId;
                    that.ruleForm.prizeDate = r3.prizeDate;
                    that.level = that.getArrayId(r3.levelId,that.prizeLevels);
                    that.ruleForm.levelId = that.level[1];
                    var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
                    that.random += random;
                    // if(r3.attachment){
                    //   that.uploadFiles = r3.attachment.split('|').map(item =>`${that.$ServerUrl()}${item}`)
                    // }
                    that.fullscreenLoading = false;
                })
              }else{
                  that.fullscreenLoading = false;
              }
            })
          })
        })
      },
      onPictureHandleSuccess(param) {
        let that = this;
        that.ruleForm.attachment = "";
        for (let i in param) {
          if (that.ruleForm.attachment == "") {
            that.ruleForm.attachment = param[i].path;
          } else {
            that.ruleForm.attachment += "|" + param[i].path;
          }
        }
      },
      handleCascaderChange(value) {
        let that = this;
        that.ruleForm.levelId = value[value.length - 1];
      },
      onReset() {
        let that = this;
        if(!that.applyId){
          that.$refs.ruleForm.resetFields()
          that.level = []
          that.uploadFiles = []
        }else{
          that.initFormData()
        }
      },
      onSave() {
        let that = this;
        let data = JSON.parse(JSON.stringify(that.ruleForm))
        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            that.fullscreenLoading = true
            if(!that.applyId){
                ajaxPost(that,'/api/student/prizeapply',data,function(r1){
                back(that)
                that.$refs.ruleForm.resetFields()
                that.fullscreenLoading = false
              })
            }else{
              data.applyId = that.applyId
              ajaxPut(that,`/api/student/prizeapply/${that.applyId}`,data,function(r1){
                back(that)
                that.$refs.ruleForm.resetFields()
                that.fullscreenLoading = false
              })
            }
          }else{return false}
        });
      },
      onBack() {
        let that = this;
        back(that);
      },
    },
  };
    function mapTree(obj){
          const haveChildren = Array.isArray(obj.children) && obj.children.length > 0
          let data ={
              value:obj.id,
              label:obj.name,
          }
          if(haveChildren){
                data.children = obj.children.map(i =>mapTree(i))
          }
          return data
    }
</script>

<style scoped="scoped">
  label {
    margin-bottom: 0;
  }
  .recodeStyle p{
    padding: none!important;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }

  .spIcon {
    width: 5px;
    height: 20px;
    border-radius: 5px;
    background-image: linear-gradient(#4545FF, #2D8EFF);
  }

  .spTitle {
    float: left;
    margin-left: 20px;
    color: #323E56;
    letter-spacing: 2px;
    font-size: 16px;
    margin-top: -3px;
  }

  .wMax {
    width: 100% !important;
  }
</style>
