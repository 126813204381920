<!--
  学生管理-奖励信息
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <search :ColNumber="3" :SearchList="SearchList" :key="random" ref="SearchList" @onSearch="onSearch"
      v-if="SearchList!=null"></search>
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          奖励信息
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <comImport v-if="isPower.prize_import" @onImportSuccess="onImportSuccess" :title="'奖励信息导入'"
                :template="'/Templates/奖励导入模版.xlsx'" :path="'/api/admin/prize/import'" ref="conImportFile">
              </comImport>
            </li>
            <li class="nav-item">
              <el-button v-if="isPower.prize_create" type="success" icon="el-icon-circle-plus-outline"
                @click="onAdd()">新增</el-button>
            </li>
            <li class="nav-item ml-1" v-if="isPower.prize_teacher_audit">
              <el-button icon="el-icon-edit" @click="onAudit()">审核</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="item!=null && item.length!=0">
        <el-table stripe :data="item" class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="name" label="奖项" min-width="300" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <span>{{scope.row.name}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="member" label="学生" min-width="120" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <div v-html="scope.row.member"></div>
            </template>
          </el-table-column>
<!--          <el-table-column prop="type" label="奖励类型" width="100" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <span>{{scope.row.type.name}}</span>
            </template>
          </el-table-column> -->
          <el-table-column prop="level" label="奖励级别" width="140" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <span v-if="scope.row.level!=null">{{scope.row.level.name}}</span>
            </template>
          </el-table-column>
          <el-table-column label="奖励时间" width="120">
            <template slot-scope="scope">
              {{onGetDateFormat(scope.row.prizeDate)}}
            </template>
          </el-table-column>
          <el-table-column label="操作" :width="200" fixed="right">
            <template slot-scope="scope">

              <operationBtn v-if="isPower.prize_detail" :btnName="'查看'" @click.native="onDetail(scope.row.prizeId)">
              </operationBtn>
              &nbsp;
              <el-dropdown v-if="isPower.prize_edit || isPower.prize_delete " trigger="click"
                @command="handleCommand($event,scope.row.prizeId)">
                <el-button size="mini">
                  更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="isPower.prize_edit " class="color-warning"
                    command="onEdit">编辑</el-dropdown-item>
                  <el-dropdown-item divided v-if="isPower.prize_delete " class="color-danger"
                    command="onDelete">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>


            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>


  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comImport from '@/components/Import.vue';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';

  export default {
    name: '',
    components: {
      comImport,
      comPage,
      operationBtn,
      search
    },
    data() {
      return {
        fullscreenLoading: false,
        itemData: [],
        dialogTitle: '',
        dialogVisible: false,
        random: 0, //刷新组件
        page: null, //分页查询接口返回的结果
        item: [], //列表数据
        pageBtnList: null, //分页左侧按钮集合
        paging: { //分页数据
          pageLength: 0, // 总共页数
          Size: 10, // 当前请求数量
          Index: 1 //当前在第几页
        },
        SearchList: null, //搜索集合
        isPower: {
          //增删改查 权限管理
          prize_detail: false,
          prize_create: false,
          prize_edit: false,
          prize_delete: false,
          prize_import: false,

          prize_teacher_details: false,
          prize_teacher_create: false,
          prize_teacher_edit: false,
          prize_teacher_delete: false,
          prize_teacher_import: false,

          prize_teacher_audit: false,
          prize_teacher_audit_details:false,
        },
        url: null, //当前页面的路径

        // 教师信息
        TeacherInfo: {},
      };
    },
    created() {
      let that = this;
      isPower(that, "prize_detail", "prize_detail");
      isPower(that, "prize_create", "prize_create");
      isPower(that, "prize_edit", "prize_edit");
      isPower(that, "prize_delete", "prize_delete");
      isPower(that, "prize_import", "prize_import");
      isPower(that, "prize_teacher_audit", "prize_teacher_audit");
      isPower(that, "prize_teacher_audit_details", "prize_teacher_audit_details");

      that.TeacherInfo = onGetNowUser(that);

      pagingPage(that);
    },
    methods: {
      /**
       * @description 更多操作
       * @param {Object} command
       * @param {Object} objValID
       */
      handleCommand(command, objValID) {
        let that = this;
        switch (command) {
          case 'onEdit':
            that.onEdit(objValID);
            break;
          case 'onDelete':
            that.onDelete(objValID);
            break;
        }
      },
      /**
       * @description 审核
       * @param {Object} _valID
       */
      onAudit(_valID) {
        let that = this;
        that.$router.push({
          path: "/Admin/Adviser/StudentPrize/List",
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },
      onGetDateFormat(_val) {
        return dateFormat(_val, '.', 'YYYY-MM-DD');
      },

      onImportSuccess(param) {
        let that = this;
        pagingPage(that);
      },
      onAdd() {
        let that = this;
        that.$router.push({
          path: '/Admin/StudentPrize/Create',
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onEdit(Id) {
        let that = this;
        that.$router.push({
          path: "/Admin/StudentPrize/Edit/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onDetail(Id) {
        let that = this;
        that.$router.push({
          path: "/Admin/StudentPrize/Detail/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onDelete(Id) {
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, "/api/admin/prize/" + Id, null, function(r) {
              that.fullscreenLoading = false;
              pagingPage(that);
            });
          }
        })
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      onSearch(params) {
        let that = this;
        let data = {};
        let searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = Join(element.data);
          }
        });
        data.PageNumer = 1;
        routerPath(that, "/Admin/StudentPrize/Index", data, null, "PageSize");
      },
      pagingClick(type, val) {
        let that = this;
        let url = "/Admin/StudentPrize/Index";

        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "SemesterId",
            "ClassId",
            "Student",
            "PrizeDate",
            "Name",
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "SemesterId",
            "ClassId",
            "Student",
            "PrizeDate",
            "Name",
          );
        }
      },
    },
    watch: {
      $route() {
        let that = this;
        pagingPage(that);
      }
    }
  };

  function pagingPage(that) {
    paging(
      that,
      function(r) {
        let Myurl = "/api/admin/prize/search";
        that.url = that.$route.path.toLowerCase();

        that.fullscreenLoading = true;
        ajaxGet(that, "/api/admin/Semester", null, function(semester) {

          let resultClass = that.TeacherInfo.isTeacher ? that.TeacherInfo.manageClass : that.TeacherInfo.allClass;

          var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
          that.random += random;

          var ClassId = r.ClassId;
          var classList = [];

          for (var i in resultClass) {
            var it = {
              Title: resultClass[i].alias,
              Value: resultClass[i].classId,
            };
            classList.push(it);

          }

          if (that.TeacherInfo.isTeacher) {
            if (ClassId == null || typeof(ClassId) == "undefined" || ClassId == "") {
              ClassId = resultClass[0].classId;
            }
          }




          that.SearchList = [];
          that.SearchList.push({
            type: "select",
            Name: "班级",
            data: ClassId,
            select: classList,
            zhi: "ClassId",
            isClear: !that.TeacherInfo.isTeacher, //select是否可以清除
          }, {
            type: "input",
            Name: "学生",
            data: r.Student,
            holder: "请输入学号/姓名",
            zhi: "Student"
          }, {
            type: "time",
            Name: "奖励时间",
            data: r.PrizeDate,
            zhi: "PrizeDate"
          }, {
            type: "input",
            Name: "奖项",
            data: r.Name,
            holder: "请输入奖项",
            zhi: "Name"
          }, );


          let data = {
            PageNumer: r.PageNumer,
            PageSize: r.PageSize,
            Name: r.Name,
            ClassId: ClassId,
            Student: r.Student,
            PrizeDate: r.PrizeDate,
          };
          that.paging.Index = r.PageNumer;
          that.paging.Size = r.PageSize;

          ajaxGet(that, Myurl, data, function(r1) {
            that.page = r1;
            that.item = r1.items;
            that.fullscreenLoading = false;
            for (var i in that.item) {
              var it = that.item[i];
              it.member = "";
              for (var j in it.members) {
                var member = it.members[j];
                var score = 0;
                for (var m in member.configs) {
                  score += member.configs[m].score;
                }
                if (it.member == "") {
                  it.member = member.student.name;
                } else {
                  it.member += "、" + member.student.name;
                }
              }
            }
          });


        });

      },
      // "SemesterId",
      "ClassId",
      "Student",
      "PrizeDate",
      "Name",
    );
  }

</script>

<style scoped="scoped">
</style>

