<!--
  班主任手册-学期总结
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <search :ColNumber="2" :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null">
    </search>

    <el-card class="box-card" v-if="item==null || item.length==0">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          学期总结
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <el-button v-if="isPower.adviserworksummary_create == true && isCurrentTeacher == true" size="small"
                type="success" icon="el-icon-circle-plus-outline" @click="onAdd()">新增</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body">
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>


    <el-card class="box-card" v-for="(p,index) in item" :key="index">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          学期总结
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <operationBtn v-if="isPower.adviserworksummary_edit == true && isCurrentTeacher == true" :btnName="'编辑'"
                @click.native="onEdit(p.summaryId)"></operationBtn>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body DetailsFonts">
        <div v-html="p.content"></div>
      </div>
    </el-card>



  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';

  export default {
    name: '',
    components: {
      operationBtn,
      search
    },
    data() {
      return {
        fullscreenLoading: false,
        page: null, //分页查询接口返回的结果
        item: [], //列表数据
        pageBtnList: null, //分页左侧按钮集合
        paging: { //分页数据
          pageLength: 0, // 总共页数
          Size: 10, // 当前请求数量
          Index: 1 //当前在第几页
        },
        SearchList: null, //搜索集合
        isPower: {
          //增删改查 权限管理
          adviserworksummary_create: false,
          adviserworksummary_edit: false,
          adviserworksummary_user: false,
        },
        isCurrentTeacher: true, //历史班主任是否为当前用户
        ManagerClass: [], //班主任管理的班级的集合
        TeacherInfo: {}, // 教师信息
      };
    },
    created() {
      let that = this;
      that.TeacherInfo = onGetNowUser(that);
      isPower(that, "adviserworksummary_create", "adviserworksummary_create");
      isPower(that, "adviserworksummary_edit", "adviserworksummary_edit");
      isPower(that, "adviserworksummary_user", "adviserworksummary_user");
      pagingPage(that);
    },
    methods: {
      onEdit(Id) {
        let that = this;
        that.$router.push({
          path: "/Admin/Adviser/WorkSummary/Edit/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onAdd() {
        let that = this;
        that.$router.push({
          path: '/Admin/Adviser/WorkSummary/Create',
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onSearch(params) {
        let that = this;
        let data = {};
        let searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = Join(element.data);
          }
        });
        data.PageNumer = 1;
        routerPath(that, "/Admin/Adviser/WorkSummary/Index", data, null, "PageSize");
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      pagingClick(type, val) {
        let that = this;
        let url = "/Admin/Adviser/WorkSummary/Index";
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "SemesterId",
            "ClassId",
            "SummaryType"
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "SemesterId",
            "ClassId",
            "SummaryType"
          );
        }
      },
    },
    watch: {
      $route() {
        let that = this;
        pagingPage(that);
      }
    }
  };

  function pagingPage(that) {
    paging(
      that,
      function(r) {
        that.fullscreenLoading = true;
        ajaxGet(that, "/api/admin/Semester", null, function(semester) {
          if (!regNullArray(semester)) {
            ajaxGet(that, "/api/admin/adviserworksummary/type", null, function(resultType) {
              let resultClass = that.TeacherInfo.isTeacher ? that.TeacherInfo.manageClass : that.TeacherInfo.allClass;
              let Myurl = that.TeacherInfo.isTeacher ? "/api/admin/adviserworksummary/search" : "/api/admin/adviserworksummary/user/search";

              var SemesterId = r.SemesterId;
              var semesters = [];
              for (var i in semester) {
                var it = {
                  Title: semester[i].name,
                  Value: semester[i].semesterId,
                };
                if (regNull(SemesterId)) {
                  if (semester[i].isCurrent == true) {
                    SemesterId = semester[i].semesterId;
                  }
                }
                semesters.push(it);
              }

              var ClassId = r.ClassId;
              var classList = [];

              if (regNull(ClassId) && !regNullArray(resultClass)) {
                ClassId = resultClass[0].classId;
              }

              for (var i in resultClass) {
                classList.push({
                  Title: resultClass[i].alias,
                  Value: resultClass[i].classId,
                });
              }

              var SummaryType = r.SummaryType;
              var typeList = [];

              if (regNull(SummaryType)) {
                SummaryType = resultType[0].Value;
              }

              for (var i in resultType) {
                typeList.push({
                  Title: resultType[i].Text,
                  Value: resultType[i].Value,
                });
              }

              that.SearchList = [{
                  type: "select",
                  Name: "学期",
                  data: SemesterId,
                  select: semesters, //选择的数据
                  zhi: "SemesterId",
                  isClear: false, //select是否可以清除
                  resetData: semesters[0].Value,//重置数据默认第一个
                },
                {
                  type: "select",
                  Name: "班级",
                  data: ClassId,
                  select: classList,
                  zhi: "ClassId",
                  isClear: false, //select是否可以清除
                  resetData: resultClass[0].classId,//重置数据默认第一个
                },
              ];

              let data = {
                PageNumer: r.PageNumer,
                PageSize: r.PageSize,
                SemesterId: SemesterId,
                ClassId: ClassId,
              };
              ajaxGet(that, Myurl, data, function(r1) {
                that.page = r1;
                that.item = r1;
                var name = JSON.parse(that.$GetKey("Name"));
                that.isCurrentTeacher = true;
                if (that.item != '') {
                  if (that.item[0].adviser != name) {
                    that.isCurrentTeacher = false;
                  }
                }
                that.fullscreenLoading = false;
              });
            })

          } else {
            that.fullscreenLoading = false;
          }
        });
      },
      "SemesterId",
      "ClassId",
      "SummaryType"
    );
  }
</script>

<style scoped="scoped">
</style>
