<!--
  成长档案
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">

    <!-- 搜索 -->
    <comSearch :ColNumber="3" :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null">
    </comSearch>

    <!-- 列表-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          成长档案
        </h3>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table :data="itemData" stripe class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="studentNo" label="学号" class="elColumn" min-width="120"></el-table-column>
          <el-table-column prop="name" label="姓名" class="elColumn" min-width="140" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="gender" label="性别" class="elColumn" min-width="80"></el-table-column>
          <el-table-column prop="entryYear" label="入学年份" class="elColumn" min-width="120"></el-table-column>
          <el-table-column prop="college" label="年级" class="elColumn" min-width="160" :show-overflow-tooltip="true">
          </el-table-column>
          <!-- <el-table-column prop="major" label="专业" class="elColumn" min-width="160" :show-overflow-tooltip="true"></el-table-column> -->
          <el-table-column prop="class" label="班级" class="elColumn" min-width="160" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="操作" width="100" fixed="right">
            <template slot-scope="scope">
              <operationBtn :btnName="'查看'" ref="detail" @click.native="onDetail(scope.row.studentId)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>

      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>



  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  // import comImport from '@/components/Import.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import comSearch from '@/components/search.vue';

  export default {
    name: '',
    components: {
      comPage,
      // comImport,
      operationBtn,
      comSearch
    },
    data() {
      return {
        // 动画
        fullscreenLoading: false,
        // 搜索
        SearchList: null,
        //列表数据
        itemData: [],
        //分页查询接口返回的结果
        page: null,
        //分页左侧按钮集合
        pageBtnList: null,
        //分页数据
        paging: {
          // 总共页数
          pageLength: 0,
          // 每页请求的数据量
          Size: 10,
          //当前在第几页
          Index: 1
        },
        isPower: {
          prize_score_import: false,
        },
        uploadScoreApi: '/api/admin/manual/import/transcript',

        TeacherInfo: {}, // 教师信息
      };
    },
    methods: {
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },
      // 搜索
      onSearch(params) {
        let that = this,
          data = {},
          searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = element.data;
          }
        });
        data.PageNumer = 1;
        routerPath(that, "/Admin/GrowthArchives/Index", data, null, "PageSize");
      },
      // 搜索重置
      onResetSearch() {
        let data = {
          PageNumer: 1,
        };
      },
      // 导入成功后执行
      onImportSuccess(param) {

        let that = this;
        onGetDataPage(that);
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      // 分页面按钮
      pagingClick(type, val) {
        let that = this;
        let url = "/Admin/GrowthArchives/Index";
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "Student",
            "ClassId"
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "Student",
            "ClassId"
          );
        }
      },
      // 详情
      onDetail(objID) {
        let that = this;
        that.$router.push({
          path: "/Admin/GrowthArchives/Details/" + objID,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
    },
    created() {
      let that = this;
      that.TeacherInfo = onGetNowUser(that);
      isPower(that, 'prize_score_import', 'prize_score_import');
      onGetDataPage(that);
    },
    watch: {
      $route() {
        let that = this;
        onGetDataPage(that);
      }
    }

  };


  function onGetDataPage(that) {
    paging(
      that,
      function(res) {
        let apiUrl = "/api/admin/student/search";
        that.fullscreenLoading = true;

        let resultClass = that.TeacherInfo.isTeacher ? that.TeacherInfo.manageClass : that.TeacherInfo.allClass;

        var classList = [];
        for (var i in resultClass) {
          classList.push( {
            Title: resultClass[i].name,
            Value: resultClass[i].classId,
          });
        }

        if(that.TeacherInfo.isTeacher){
          if(regNull(res.ClassId)){
            res.ClassId=resultClass[0].classId
          }
        }

        that.SearchList = [

          {
            type: "select",
            Name: "班级",
            data: res.ClassId,
            select: classList,
            zhi: "ClassId",
            isClear: !that.TeacherInfo.isTeacher
          },
          {
            type: "input",
            Name: "学生",
            data: res.Student,
            holder: "请输入学号/姓名",
            zhi: "Student"
          },
        ];
        let data = {
          PageNumer: res.PageNumer,
          PageSize: res.PageSize,
          Student: res.Student,
          ClassId: res.ClassId,
          SemesterId: res.SemesterId,
        };
        that.paging.Index = res.PageNumer;
        that.paging.Size = res.PageSize;
        ajaxGet(that, apiUrl, data, function(resData) {
          that.page = resData;
          that.itemData = resData.items;
          that.fullscreenLoading = false;
        });

      },
      "Student",
      "ClassId",
    );
  }

</script>

<style scoped="scoped">
  .btnCheckAll label {
    margin-bottom: 0;
  }

</style>
<style>
  .studentIndex .importStyle {
    font-size: 12px;
    font-weight: normal;
    padding: 9px 15px;
  }

</style>

