<!--

-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <!-- 搜索 -->
    <comSearch :ColNumber="3" :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null">
    </comSearch>
    <!-- 列表-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          社团活动信息
        </h3>
      </div>

      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">

        <el-table :data="itemData" stripe class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="name" label="活动名称" min-width="160" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column label="活动时间" width="190">
            <template slot-scope="scope">
              {{scope.row.activityDate | dateformat}}
            </template>
          </el-table-column>
          <el-table-column prop="place" label="记录地点" min-width="120" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="auditState" label="参与人数" width="100">
            <template slot-scope="scope">
              {{scope.row.roster.length}}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100" fixed="right">
            <template slot-scope="scope">
              <operationBtn v-if="isPower.clubactivities_details" :btnName="'查看'"
                @click.native="onDetail(scope.row.activityId)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>

        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>

    <div class="row mt-10" v-if="IsShowBack">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
      </div>
    </div>

  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import comSearch from '@/components/search.vue';
  export default {
    components: {
      operationBtn,
      comPage,
      comSearch,
    },
    data() {
      return {
        // 动画
        fullscreenLoading: false,
        dialogVisible: false,
        dialogVisiblePhoto: false,
        // 搜索
        SearchList: null,
        //列表数据
        itemData: [],
        //分页查询接口返回的结果
        page: null,
        //分页左侧按钮集合
        pageBtnList: null,
        //分页数据
        paging: {
          // 总共页数
          pageLength: 0,
          // 每页请求的数据量
          Size: 1,
          //当前在第几页
          Index: 1
        },
        isPower: {
          isDirector: false,
          isManager: false,
          // 查看权限
          clubactivities_details: false,
          // 删除权限
          clubactivities_delete: false,
        },

        myClubId: '',
        IsShowBack: false,
      };
    },
    methods: {
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },


      onBack() {
        back(this);
      },

      /**
       * @description 搜索事件
       * @param {Object} params
       */
      onSearch(params) {
        let that = this;
        let data = {};
        let searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = element.data;
          }
        });
        routerPath(that, "/Admin/Club/ClubActivities/Index", data, null, "PageSize");
      },

      /**
       * @description 查看社团活动详情
       * @param {Object} Id
       */
      onDetail(_activityId) {
        let that = this;
        that.$router.push({
          path: '/Admin/Club/ClubActivities/Details/' + _activityId,
          query: {
            url: that.$router.history.current.fullPath
          }
        });

      },

      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      // 分页面按钮
      pagingClick(type, val) {
        let that = this;
        let url = "Admin/Club/ClubActivities/Index";
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "clubId",
            "Name"
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "clubId",
            "Name"
          );
        }
      }
    },
    created() {
      let that = this;
      isPower(that, "clubactivities_details", "clubactivities_details");
      isPower(that, "clubactivities_delete", "clubactivities_delete");
      let isType = that.$route.params.type;
      if (isType != null && typeof(isType) != "undefined" && isType != "") {
        that.IsShowBack = true;
        that.myClubId = isType;
        onGetDataPage(that);
      } else {
        that.IsShowBack = false;
        that.myClubId = null;
        onGetDataPage(that);
      }


    },

    watch: {
      $route() {
        let that = this;
        onGetDataPage(that);
      }
    }
  };

  function onGetDataPage(that) {
    paging(
      that,
      function(res) {
        that.fullscreenLoading = true;
        ajaxGet(that, "/api/admin/club", null, function(resClub) {
          let arrClub = [];
          for (var i in resClub) {
            let arrTemp = {
              Title: resClub[i].name,
              Value: resClub[i].clubId
            };

            arrClub.push(arrTemp);
          }
          if(!regNull(that.myClubId)){
            res.clubId = that.myClubId;
          }


          that.SearchList = [{
              type: "input",
              Name: "活动名称",
              data: res.Name,
              holder: "请输入记录名称",
              zhi: "Name"
            },

            {
              type: "select",
              Name: "社团",
              data: res.clubId,
              holder: "请选择社团",
              select: arrClub, //选择的数据
              zhi: "clubId", // 传值
              isClear: false, //select是否可以清除
              resetData: that.myClubId,
              isShow: that.IsShowBack,
            },
          ];
          let data = {
            PageNumer: res.PageNumer,
            PageSize: res.PageSize,
            ClubId: res.clubId,
            Name: res.Name
          };
          that.paging.Index=res.PageNumer;
          that.paging.Size=res.PageSize;
          ajaxGet(that, '/api/admin/clubactivity/search', data, function(resData) {

            that.page = resData;
            that.itemData = resData.items;
            that.fullscreenLoading = false;
          });
        });
      },
      "Name",
      "clubId",
    );
  }
</script>

<style>
  .clubBtnImport {
    margin-left: 2px;
    display: inline-block;
  }
</style>
