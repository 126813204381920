<!--
  日常事务 - 日常动态管理 - 行为规范
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            新增行为规范{{currentTitle}}
          </h3>
        </div>
        <div class="card-body tabListCont">
          <el-row :gutter="24">
            <el-col :span="currentType==1?12:24">
              <el-form-item label="管理原因" prop="reasonIds">
                <el-cascader @change="onChangeReason()" style="width:100% !important;" v-model="ruleForm.reasonIds"
                  :options="reasons" :show-all-levels="false" filterable
                  :props="{ expandTrigger: 'hover',label:'name',value:'id',children:'children' }"></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="currentType==1">
              <el-form-item label="宿舍" prop="roomIds">
                <el-cascader style="width:100% !important;" v-model="ruleForm.roomIds" :options="rooms"
                  :show-all-levels="false" filterable
                  :props="{ expandTrigger: 'hover',label:'name',value:'id',children:'children' }"></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="分数" prop="score">
                <el-input @change="onScoreChange()" v-model="ruleForm.score" placeholder="请输入分数"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="行为时间" prop="logDate">
                <el-date-picker style="width:100% !important;" type="datetime" v-model="ruleForm.logDate" placeholder="选择行为时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备注" prop="description">
                <el-input class="formControl" v-model="ruleForm.remark" type="textarea" :rows="4" show-word-limit
                  placeholder="请输入备注" maxlength="500">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            行为图片
          </h3>
        </div>
        <div class="card-body tabListCont">
          <el-row :gutter="24" style="margin-top: 20px;">
            <el-col :span="24">
              <comUpload :key="random" @onPictureHandleSuccess="onPictureHandleSuccess" :files="uploadFiles">
              </comUpload>
            </el-col>
          </el-row>
        </div>
      </el-card>
      <el-card class="box-card" v-if="currentType==2">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont" style="float: left;">
            <span class="spIcon"></span>
            学生名单
          </h3>
          <div style="float:right;width: 40%; text-align: right;">
            <span style="font-size: 14px;padding-right: 5px;">床位</span>
            <el-cascader :key="randomStudent" @change="onChangeStudent()" style="width:80% !important;"
              v-model="currentBedId" :options="rooms" :show-all-levels="false" filterable
              :props="{ expandTrigger: 'hover',label:'name',value:'id',children:'children' }"></el-cascader>
          </div>
        </div>
        <div class="card-body tabListCont">
          <el-row :gutter="24" v-if="ruleForm.members!=null && ruleForm.members.length>0">
            <el-col :span="4" v-for="(p,index) in ruleForm.members" :key="index">
              <div class="stuInfo">
                <div class="stuImgCont">
                  <el-image :src="p.photo">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </div>
                <div class="stuName">{{p.name}}</div>
                <div class="stuOther">{{p.studentNo}}</div>
                <div class="stuRole">{{p.className}}</div>
                <div class="stuRemove color-danger">
                  <i class="el-icon-error" @click="onDeleteMember(p.studentId)"></i>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24" v-else>
            <el-col :span="24" style="text-align: center;line-height: 40px;font-size: 14px;color: #909399;">
              请选择学生
            </el-col>
          </el-row>
        </div>
      </el-card>
    </el-form>

    <div class="row" style="margin-top: 10px;">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
        <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
        <el-button type="primary" icon="el-icon-circle-check" @click="onSave()">提交</el-button>
      </div>
    </div>


  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comUpload from '@/components/Upload.vue';

  export default {
    components: {
      comUpload
    },
    data() {
      return {
        currentTitle: "",
        fullscreenLoading: false,
        randomStudent: 0, //刷新组件
        uploadFiles: [],
        ruleForm: {
          reasonId: null,
          reasonIds: [],
          logDate: null,
          remark: null,
          score: null,
          attachment: null,
          members: [],
          roomId: null,
          roomIds: [],
        },
        ruleFormReset: null,
        rooms: [],
        InitRooms: [],
        roomsReset: [],
        allReasons: [],
        currentType: null,
        reasons: null,
        currentBedId: null,
        random: 0, //刷新组件
        rules: {
          reasonIds: [{
            required: true,
            message: '请选择管理原因',
            trigger: 'change'
          }, ],
          score: [{
            required: true,
            message: '请输入分数',
            trigger: 'change'
          }, ],
          logDate: [{
            required: true,
            message: '请选择行为时间',
            trigger: 'change'
          }, ],
          roomIds: [{
            required: true,
            message: '请选择宿舍',
            trigger: 'change'
          }, ],
        },
      };
    },
    created() {
      let that = this;
      that.currentType = that.$route.params.type;
      var reasonUrl = "";
      if (that.currentType == 1) {
        that.currentTitle = "【宿舍】";
        reasonUrl = "/api/admin/MoralityDormitoryReason/usertreelist";
      } else if (that.currentType == 2) {
        that.currentTitle = "【宿舍个人】";
        reasonUrl = "/api/admin/MoralityDpReason/usertreelist";
      }
      if (reasonUrl != "") {
        var param = {
          ReasonType: 2,
        }
        that.fullscreenLoading = true;
        ajaxGet(that, reasonUrl, param, function(r1) {
          InitItem(that, r1);
          that.reasons = that.getTreeData(r1);
          that.fullscreenLoading = false;
        });
      }

      that.fullscreenLoading = true;
      ajaxGet(that, '/api/admin/Building/tree', null, function(r) {

        for (var building of r) {
          building.id = building.buildingId;
          building.children = building.floors;
          for (var floor of building.children) {
            floor.id = floor.floorId;
            floor.children = floor.rooms;
            for (var room of floor.children) {
              room.id = room.roomId;
              room.name = room.name + "（" + room.className + "）";
              if (that.currentType == 2) {
                room.children = room.beds;
                for (var bed of room.children) {
                  bed.id = bed.bedId;
                  bed.name = bed.name + "（" + bed.studentName + "，" + bed.className + "，" + bed.studentNo + "）";
                }
              }
            }
          }
        }
        that.rooms = r;
        that.InitRooms = JSON.parse(JSON.stringify(r));
        that.roomsReset = JSON.parse(JSON.stringify(that.rooms));
        that.fullscreenLoading = false;

      });
      that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
    },
    methods: {
      onChangeReason() {
        let that = this;
        if (that.allReasons != null && that.allReasons.length > 0) {
          for (var it of that.allReasons) {
            if (it.id == that.ruleForm.reasonIds[that.ruleForm.reasonIds.length - 1]) {
              that.ruleForm.score = it.score;
            }
          }
        }
      },
      onScoreChange() {
        let that = this;
        var value = setTwoDecimal(setTwoFloat(that.ruleForm.score));
        that.ruleForm.score = value;
      },
      onBack() {
        let that = this;
        back(that);
      },
      onSave() {
        let that = this;
        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            that.ruleForm.logDate = timeTransform(that.ruleForm.logDate);
            if (that.currentType == 2) {
              if (that.ruleForm.members == null || that.ruleForm.members.length <= 0) {
                warning(that, "请选择学生");
                return false;
              }
            }
            for (var it of that.ruleForm.members) {
              it.score = that.ruleForm.score;
            }
            var postUrl = "";
            if (that.currentType == 1) {
              postUrl = "/api/admin/MoralityDormitoryLog";
              that.ruleForm.roomId = that.ruleForm.roomIds[that.ruleForm.roomIds.length - 1];
            } else if (that.currentType == 2) {
              postUrl = "/api/admin/MoralityDpLog";
            }
            if (postUrl != "") {
              that.ruleForm.reasonId = that.ruleForm.reasonIds[that.ruleForm.reasonIds.length - 1];
              that.fullscreenLoading = true;

              ajaxPost(that, postUrl, that.ruleForm, function(r) {
                that.fullscreenLoading = false;
                that.onBack();
              });
            }

          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              isError[0].querySelector('input').focus();
            }, 1)
            return false;
          }
        });
      },
      onReset() {
        let that = this;
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
        that.rooms = JSON.parse(JSON.stringify(that.roomsReset));
        that.uploadFiles = [];
        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      onDeleteMember(studentId) {
        let that = this;
        var i = -1;
        var member = null;
        for (var it of that.ruleForm.members) {
          i++;
          if (it.studentId == studentId) {
            member = JSON.parse(JSON.stringify(it));

            break;
          }
        }
        if (i >= 0) {
          that.ruleForm.members.splice(i, 1);
          var roomList = JSON.parse(JSON.stringify(that.InitRooms));
          for (var item of that.ruleForm.members) {
            for (var building of roomList) {
              for (var floor of building.children) {
                for (var room of floor.children) {
                  room.children = room.children.filter(
                    function(val) {
                      return val.studentId != item.studentId
                    }
                  )
                  var randomStudent = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
                  that.randomStudent += randomStudent;
                  break;
                }
              }
            }
          }
          that.rooms = roomList;
        }
      },
      onChangeStudent() {
        let that = this;
        var currentBedId = that.currentBedId[that.currentBedId.length - 1];
        for (var building of that.rooms) {
          for (var floor of building.children) {
            for (var room of floor.children) {
              if (that.currentType == 2) {
                var i = -1;
                for (var bed of room.children) {
                  i++;
                  if (bed.id == currentBedId) {
                    var photo = "";
                    if (bed.photo != null && bed.photo != "") {
                      photo = that.$ServerUrl() + bed.photo;
                    }
                    that.ruleForm.members.push({
                      studentId: bed.studentId,
                      score: 0,
                      photo: photo,
                      originalPhoto: bed.photo,
                      originalName: bed.name,
                      name: bed.studentName,
                      studentNo: bed.studentNo,
                      className: bed.className,
                      bedId: bed.id,
                      roomId: room.id,
                      classId: bed.classId,
                    });
                    that.currentBedId = null;
                    var randomStudent = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
                    that.randomStudent += randomStudent;
                    break;
                  }
                }
                if (i >= 0) {
                  room.children.splice(i, 1);
                }
              }
            }
          }
        }
      },
      getTreeData(data) {
        // 循环遍历json数据
        for (var i = 0; i < data.length; i++) {

          if (data[i].children.length < 1) {
            // children若为空数组，则将children设为undefined
            data[i].children = undefined;
          } else {
            // children若不为空数组，则继续 递归调用 本方法
            this.getTreeData(data[i].children);
          }
        }
        return data;
      },
      onPictureHandleSuccess(param) {
        let that = this;
        that.ruleForm.attachment = "";
        for (var i in param) {
          if (that.ruleForm.attachment == "") {
            that.ruleForm.attachment = param[i].path;
          } else {
            that.ruleForm.attachment += "|" + param[i].path;
          }
        }
      },

    }
  };

  function InitItem(that, val) {
    if (val.length !== 0) {
      val.forEach((item) => {
        that.allReasons.push(item);
        if (item.children.length != null && typeof(item.children.length) != "undefined" && item.children.length >=
          1) {
          InitItem(that, item.children);
        }
      });

    }
  }
</script>

<style scoped="scoped">
  .stuInfo {
    position: relative;
    height: 100%;
    min-height: 80px;
    padding-left: 70px;
    margin: 15px;
  }

  .stuInfo .stuFraction {
    position: absolute;
    top: -5px;
    bottom: 0;
    right: -5px;
    font-family: 'OswaldLight';
    font-size: 26px;
    color: #28A745;
  }

  .stuInfo .stuBtnDown {
    position: absolute;
    bottom: 20px;
    right: 0;
  }

  .stuInfo .stuBtnDown .aView {
    margin-right: 5px;
    margin-left: 5px;
  }

  .stuInfo .stuBtnDown .aView .faxSize {
    font-size: 12px;
    color: #343a40;
  }

  .stuInfo .stuBtnDown .aView:hover .faxSize {
    color: #1666B3;
  }

  .stuInfo .stuImgCont {
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 80px;
    border-radius: 4px;
    overflow: hidden;
  }

  .stuInfo .stuImgCont img {
    width: 100%;
  }

  .stuInfo .stuName {
    color: #212E48;
    font-size: 18px;
    font-weight: bold;
  }

  .stuInfo .stuRole {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
  }

  .stuRole .spStatus {
    font-size: 12px;
    color: #B1B1B1;
  }

  .stuInfo .stuOther {
    color: #767676;
    font-size: 12px;
  }

  .stuInfo .stuRemove {
    display: none;
  }

  .stuInfo:hover .stuRemove {
    display: block;
    position: absolute;
    top: 0rem;
    bottom: 0rem;
    right: 0rem;
    font-size: 22px;
    cursor: pointer;
  }


  .transferBox {
    margin: 0 auto;
  }

  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }

  .tabListCont .divNavTitle {}

  .tabListCont .divNavTitle {}

  .tabListCont .divNavTitle .divTitleCont {
    padding: 10px 0px 10px 10px;
    color: #DEE2E6;
    cursor: pointer;
    font-size: 18px;
  }

  .tabListCont .divNavTitle .divTitleCont .spNum {
    background-color: #DEE2E6;
    font-family: 'OswaldLight';
    padding: 2px 5px;
    border-radius: 4px;
    margin-left: 5px;
    font-size: 14px;
    color: #FFFFFF;
  }

  .tabListCont .divNavTitle .divTitleAct {
    color: #3e3e3e;
  }

  .tabListCont .divNavTitle .divTitleAct .spNum {
    background-color: #17A2B8;
    color: #FFFFFF;
  }

  .tabListCont .divNavTitle .nav-link {
    font-size: 14px;
    color: #B1B1B1;
  }

  .tabListCont .divNavTitle .nav-link .spNum {
    font-family: 'OswaldLight';
    font-weight: normal;
    font-size: 14px;
    padding-left: 10px;
  }

  .tabListCont .divNavTitle .active {
    color: #007bff;
  }

  .divAlertCont {
    padding-left: 30px;
    padding-top: 10px;
    color: #9595A0;
  }
</style>

<style>
  .transferCont .el-transfer__buttons {
    width: 20%;
  }

  .transferCont .el-transfer__buttons .el-transfer__button:first-child {
    float: left;
  }

  .transferCont .el-transfer__buttons .el-transfer__button:last-child {
    float: right;
  }

  .transferCont .el-transfer-panel {
    width: 40%;
  }

  .formGroupCont .el-date-editor--datetimerange.el-input__inner {
    width: 100%;
  }
</style>
