<!--
  综合课堂
-->
<template>
  <div class="SubWebPage LiteracyCoursePage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          搜索
        </h3>
      </div>
      <div class="card-body">

        <el-row :gutter="20">
          <el-col :span="8" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">课程名称</span>
            </div>
            <div class="selBodyBox">
              <el-input v-model="infoForm.name"></el-input>
            </div>
          </el-col>
          <el-col :span="8" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">课程类型</span>
            </div>
            <div class="selBodyBox">
              <el-cascader v-model="infoForm.typeId" class="width_100Pie" :options="optType" @change="onChangeType">
              </el-cascader>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="card-footer text-center">
        <el-button type="primary" class="mr-2" slot="append" icon="el-icon-search" @click.native="onSearch()">查询
        </el-button>
        <el-button slot="append" class="mr-2" icon="el-icon-refresh" @click.native="onResetSearch()">重置</el-button>
      </div>
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          综合课堂信息
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <el-button v-if="isPower.literacycourse_create == true" type="success" icon="el-icon-circle-plus-outline"
                @click="onAdd()">新增</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="item!=null && item.length!=0">
        <el-table stripe :data="item" class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="name" label="课程名称" min-width="300" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column label="课程时间" width="290">
            <template slot-scope="scope">
              {{onGetDateFormat(scope.row.startDate)}} - {{onGetDateFormat(scope.row.endDate)}}
            </template>
          </el-table-column>
          <el-table-column prop="type" label="类型" width="120"></el-table-column>

          <el-table-column prop="statusString" label="课程状态" width="100"></el-table-column>
          <el-table-column label="操作" :width="200" fixed="right">
            <template slot-scope="scope">
              <operationBtn v-if="isPower.literacycourse_details" :btnName="'查看'"
                @click.native="onDetail(scope.row.courseId)"></operationBtn>
              &nbsp;
              <el-dropdown
                v-if="isPower.literacycourse_edit || isPower.literacycourse_delete || isPower.literacycourse_release || (isPower.literacycourse_import && scope.row.isImport && scope.row.status==1)"
                trigger="click" @command="handleCommand($event,scope.row)">
                <el-button size="mini">
                  更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="isPower.literacycourse_edit" class="color-warning"
                    command="onEdit">编辑</el-dropdown-item>
                  <el-dropdown-item divided v-if="isPower.literacycourse_import == true && scope.row.isImport">
                    <comImport @onImportSuccess="onImportSuccess" :template="'/templates/综合课程学生范围导入模版.xlsx'"
                      :path="'/api/admin/literacycourse/range/'+scope.row.courseId" :btnIcon="''" :title="'导入范围名单'" :btnClass="'btnClass_NoBorder'"
                      :btnName="'导入名单'" :btnSize="'mini'" :btnType="'primary'" ref="conImportInfoFile" >
                    </comImport>
                  </el-dropdown-item>
                  <el-dropdown-item v-if="isPower.literacycourse_release" class="color-primary"
                    command="onRelease">{{(scope.row.status==1)?'发布':'取消发布'}}</el-dropdown-item>
                  <el-dropdown-item divided v-if="isPower.literacycourse_delete" class="color-danger"
                    command="onDelete">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>

            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>

      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>



  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import comImport from '@/components/Import.vue';
  export default {
    components: {
      comPage,
      operationBtn,
      comImport
    },
    data() {
      return {
        // 加载动画
        fullscreenLoading: false,
        //分页查询接口返回的结果
        page: null,
        //列表数据
        item: [],
        //分页左侧按钮集合
        pageBtnList: null,
        //分页数据
        paging: {
          pageLength: 0, // 总共页数
          Size: 10, // 当前请求数量
          Index: 1 //当前在第几页
        },
        // 搜索集合
        infoForm: {
          name: '',
          typeId: '',
        },
        optType: [],
        infoFormReset: null,
        //增删改查 权限管理
        isPower: {
          literacycourse_details: false,
          literacycourse_create: false,
          literacycourse_edit: false,
          literacycourse_delete: false,
          literacycourse_release: false,
          literacycourse_import: false,
        },
        thWidth: 100,

        // 导入学生接口
        uploadInfoApi: '/api/admin/literacycourse/range/',


      };
    },
    created() {
      let that = this;
      that.infoFormReset = JSON.parse(JSON.stringify(that.infoForm));
      that.onIsPower(that);
      pagingPage(that);
    },
    methods: {
      /**
       * @description 更多操作
       * @param {Object} command
       * @param {Object} objValID
       */
      handleCommand(command, objItem) {
        let that = this;
        switch (command) {
          case 'onEdit':
            that.onEdit(objItem.courseId);
            break;
          case 'onDelete':
            that.onDelete(objItem.courseId);
            break;
          case 'onRelease':
            that.onRelease(objItem.status, objItem.courseId);
            break;
        }
      },
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },
      // 导出学生信息成功后执行
      onImportSuccess(param) {

        let that = this;
        pagingPage(that);
      },
      // 时间格式
      onGetDateFormat(_value) {
        return dateFormat(_value, '.', 'YYYY-MM-DD HH:MM');
      },

      // 发布或取消发布
      onRelease(_type, _dataID) {
        let that = this;
        let strTip = '是否确认发布综合课堂',
          apiUrl = '/api/admin/literacycourse/release/' + _dataID;
        if (_type != 1) {
          strTip = '是否确认取消综合课堂的发布';
          apiUrl = '/api/admin/literacycourse/cancelrelease/' + _dataID;
        }
        confirmDelete(that, strTip, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxPut(that, apiUrl, null, function(r) {
              that.fullscreenLoading = false;
              pagingPage(that);
            });
          }
        })
      },
      // 数据处理
      onGetOptions(that, _data) {
        let arrTemp = [];
        if (_data.length != 0) {
          let temp = [];
          for (let item of _data) {
            if (item.children.length != 0) {
              temp.push({
                value: item.id,
                label: item.name,
                children: that.onGetOptions(that, item.children)
              });
            } else {
              temp.push({
                value: item.id,
                label: item.name,
              });
            }
          }
          arrTemp = temp;
        }
        return arrTemp;
      },
      // 综合课堂类型
      onChangeType(value) {},
      // 判断权限
      onIsPower(that) {
        isPower(that, "literacycourse_details", "literacycourse_details");
        isPower(that, "literacycourse_create", "literacycourse_create");
        isPower(that, "literacycourse_edit", "literacycourse_edit");
        isPower(that, "literacycourse_delete", "literacycourse_delete");
        isPower(that, "literacycourse_release", "literacycourse_release");
        isPower(that, "literacycourse_import", "literacycourse_import");
      },
      // 新增
      onAdd() {
        let that = this;
        that.$router.push({
          path: '/Admin/LiteracyCourse/Create',
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      // 编辑
      onEdit(_dataID) {
        let that = this;
        this.$router.push({
          path: "/Admin/LiteracyCourse/Edit/" + _dataID,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      // 查看详情
      onDetail(_dataID) {
        let that = this;
        this.$router.push({
          path: "/Admin/LiteracyCourse/Details/" + _dataID,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      // 删除
      onDelete(_dataID) {
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, "/api/admin/literacycourse/" + _dataID, null, function(r) {
              that.fullscreenLoading = false;
              pagingPage(that);
            });
          }
        })
      },
      // 分页组件左侧的按钮事件，按顺序进行判断
      pageBtnClick(index) {},
      // 搜索方法
      onSearch(params) {
        let that = this;
        pagingPage(that);
      },
      // 重置搜索内容
      onResetSearch() {
        let that = this;
        that.infoForm = JSON.parse(JSON.stringify(that.infoFormReset));
        pagingPage(that);
      },
      // 分页按钮
      pagingClick(type, val) {
        let _this = this;
        if (type == 1) {
          _this.paging.Index = 1;
          _this.paging.Size = val;
        } else {
          _this.paging.Index = val;
        }
        pagingPage(_this);
      },
    },
    watch: {
      $route() {
        let that = this;
        pagingPage(that);
      }
    }
  };

  function pagingPage(that) {
    // 加载动画
    that.fullscreenLoading = true;

    // 选择的类型
    let typeID = '';
    if (that.infoForm.typeId != '') {
      typeID = that.infoForm.typeId[that.infoForm.typeId.length - 1]
    }

    // 搜索条件
    let data = {
      PageNumer: that.paging.Index,
      PageSize: that.paging.Size,
      Name: that.infoForm.name,
      TypeId: typeID,
    };

    // 综合课堂类型
    ajaxGet(that, "/api/admin/literacycoursetype/treelist", null, function(resType) {
      // 综合课堂数据
      ajaxGet(that, '/api/admin/literacycourse/search', data, function(resData) {
        that.fullscreenLoading = false;
        // 复制类型数据
        that.optType = that.onGetOptions(that, resType);
        that.page = resData;
        that.item = resData.items;
      });

    });

  }

</script>

<style scoped="scoped">
  .selTitleBox {

    font-size: 14px;
  }

  .selTitleBox .spTitle {
    color: #303133;
  }

  .selBodyBox {
    padding-top: 5px;
  }

</style>

