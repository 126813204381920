<!--
  处分管理 - 新增
-->
<template>
  <div class="SubWebPageStudent" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form label-width="100px" :model="ruleForm" :rules="rules" ref="ruleForm">

      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{webTitle}}
          </h3>
        </div>
        <div class="card-body ">
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="批次" prop="batchId">
                <el-select v-model="ruleForm.batchId" class="width_100Pie" placeholder="请选择" disabled>
                  <el-option v-for="item in optBatch" :key="item.batchId" :label="item.yearName" :value="item.batchId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="申报等级" prop="applyLevel">
                <el-select v-model="ruleForm.applyLevel" class="width_100Pie" placeholder="请选择" disabled>
                  <el-option v-for="item in optLevel" :key="item.Value" :label="item.Text" :value="item.Value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>
      <div id="" v-if="ruleForm.recordList.length>0">
        <el-card class="box-card" v-for="(item, index) in ruleForm.recordList" :key="index">
          <div slot="header" class="card-header">
            <h3 class="card-title titleCont">
              <span class="spIcon"></span>
              {{item[0].semester | flNoValue}}
            </h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-lg-3 colInfo" v-for="(it, index) in item" :key="index">
                <label>{{it.courseName}}</label>
                <div class="colFonts">
                  {{it.total | flNoValue}}
                </div>
              </div>
            </div>
          </div>
        </el-card>
      </div>
      <!--附件-->
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            主要事迹
          </h3>
        </div>
        <div class="card-body p-0">
          <quill-editor v-model="ruleForm.description" ref="text" class="myQuillEditor" :options="editorOption" />
        </div>
      </el-card>

      <!--附件-->
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            其他附件
          </h3>
        </div>
        <div class="card-body">
          <comUpload @onPictureHandleSuccess="onPictureHandleSuccess" :files="uploadFiles"></comUpload>
        </div>
      </el-card>

      <el-card class="box-card" v-if="itemData!=null && itemData.length!=0">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            审核记录
          </h3>
        </div>
        <div class="card-body ">
          <el-table :data="itemData" border style="width: 100%">
            <el-table-column prop="status" label="审核结果" width="200"></el-table-column>
            <el-table-column prop="auditor" label="审核人" width="140"></el-table-column>
            <el-table-column prop="reason" label="审核意见"></el-table-column>
            <el-table-column label="审核时间" width="140">
              <template slot-scope="scope">
                {{scope.row.createDate | dateformatMinute}}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-card>


      <div class="row" style="margin-top: 10px;">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack">返回</el-button>
          <el-button icon="el-icon-refresh" @click="onReset">重 置</el-button>
          <el-button type="primary" icon="el-icon-circle-check" @click="onSave">提交</el-button>
        </div>
      </div>

    </el-form>
  </div>
</template>

<script>
  import '@/assets/css/Student.css';
  import comUpload from '@/components/Upload.vue';
  import {
    quillEditor
  } from 'vue-quill-editor';
  import * as Quill from 'quill' //引入编辑器
  var fonts = ['SimSun', 'SimHei', 'Microsoft-YaHei', 'KaiTi', 'FangSong', 'Arial', 'Times-New-Roman', 'sans-serif'];
  var Font = Quill.import('formats/font');
  Font.whitelist = fonts; //将字体加入到白名单
  Quill.register(Font, true);
  export default {
    components: {
      comUpload,
      quillEditor
    },
    data() {
      return {
        fullscreenLoading: false,
        //富文本框
        editorOption: {
          placeholder: '请在此输入内容',
          modules: {
            toolbar: [
              [{
                'font': fonts
              }], //字体，把上面定义的字体数组放进来
              ['bold', 'italic', 'underline',
                'strike'
              ], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
              ['blockquote', 'code-block'], // 引用  代码块-----['blockquote', 'code-block']
              [{
                header: 1
              }, {
                header: 2
              }], // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
              [{
                list: 'ordered'
              }, {
                list: 'bullet'
              }], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
              [{
                script: 'sub'
              }, {
                script: 'super'
              }], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
              [{
                indent: '-1'
              }, {
                indent: '+1'
              }], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
              [{
                'direction': 'rtl'
              }], // 文本方向-----[{'direction': 'rtl'}]
              [{
                size: ['small', false, 'large', 'huge']
              }], // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
              [{
                header: [1, 2, 3, 4, 5, 6, false]
              }], // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
              [{
                color: []
              }, {
                background: []
              }], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
              [{
                align: []
              }], // 对齐方式-----[{ align: [] }]
              ['clean'], // 清除文本格式-----['clean']
              ['image'], // 链接、图片、视频-----['link', 'image', 'video']
            ]
          }
        },

        // 批次数据
        optBatch: [],
        // 申报类型
        optLevel: [],
        //表单数据
        ruleForm: {
          applyId: null,
          batchId: null,
          applyLevel: null,
          applyId: null,
          attachment: null,
          recordList: [],
        },
        ruleFormReset: null,
        // 验证
        rules: {
          batchId: [{
            required: true,
            message: '请选择奖学金申报批次',
            trigger: 'change'
          }, ],
          applyLevel: [{
            required: true,
            message: '请选择申报等级',
            trigger: 'change'
          }, ],
        },
        // 页面标题
        webTitle: "申请奖学金",
        uploadFiles: [],

        itemData: [],
      };
    },
    filters: {
      flNoValue(obj) {
        if (obj == null || typeof obj == "undefined" || obj == "") {
          return "暂无数据";
        }
        return obj;
      },
    },
    created() {
      let that = this;
      let yearId = that.$route.query.yearId;
      if (!regNull(that.$route.params.Id)) {
        that.webTitle = "编辑奖励信息";
        that.ruleForm.applyId = that.$route.params.Id;
        ajaxGet(that, '/api/student/semester/year/' + yearId, null, function(r1) {
          for (var i in r1) {
            let data = {
              StudentId: that.$route.query.studentId,
              semesterId: r1[i].semesterId,
            }
            ajaxGet(that, '/api/student/growth/academicsearch', data, function(r2) {
              if (r2.length > 0) {
                that.ruleForm.recordList.push(r2)
              }
              that.fullscreenLoading = false;
              if (that.ruleForm.recordList != []) {
              }
            })
          }
          that.onEditData(that);
        });
      } else {
        ajaxGet(that, '/api/student/semester/year/' + yearId, null, function(resdata) {
          for (var i in resdata) {
            let data = {
              StudentId: that.$route.query.studentId,
              semesterId: resdata[i].semesterId,
            }
            ajaxGet(that, '/api/student/growth/academicsearch', data, function(res) {
              if (res.length > 0) {
                that.ruleForm.recordList.push(res)
              }
            })
          }
        })
        that.onGetData(that);
      }
    },

    methods: {
      /**
       * @description 编辑数据
       * @param {Object} that
       */
      onEditData(that) {
        that.fullscreenLoading = true;
        ajaxGet(that, '/api/student/scholarshipbatch', null, function(resBatch) {
          ajaxGet(that, '/api/student/scholarshipapply/leveltype', null, function(resLevel) {
            that.optBatch = resBatch;
            that.optLevel = resLevel;
            ajaxGet(that, '/api/student/scholarshipapply/' + that.ruleForm.applyId, null, function(resData) {
              that.ruleForm = resData;
              that.ruleForm.applyLevel = resData.applyLevel.toString();
              that.uploadFiles = [];
              that.ruleForm.recordList = [];

              if (!regNull(resData.attachment)) {
                var files = resData.attachment.split('|');
                for (var i in files) {
                  var it = i + 1;
                  that.uploadFiles.push({
                    uid: it,
                    path: files[i],
                  });
                }
              }
              ajaxGet(that, '/api/student/scholarshipapply/audit/' + that.ruleForm.applyId, null, function(
                resAudit) {
                that.itemData = resAudit;
              });
            })
          });
        });
      },
      /**
       * @description 获取数据
       * @param {Object} that
       */
      onGetData(that) {
        that.fullscreenLoading = true;
        ajaxGet(that, '/api/student/scholarshipbatch', null, function(resBatch) {
          ajaxGet(that, '/api/student/scholarshipapply/leveltype', null, function(resLevel) {
            that.fullscreenLoading = false;
            that.optBatch = resBatch;
            that.optLevel = resLevel;
            that.ruleForm.batchId = that.$route.query.batchId;
            that.ruleForm.applyLevel = that.$route.query.level;
          });
        });
      },

      /**
       * @description 上传图片
       * @param {Object} param
       */
      onPictureHandleSuccess(param) {
        let that = this;
        that.ruleForm.attachment = "";
        for (var i in param) {
          if (that.ruleForm.attachment == "") {
            that.ruleForm.attachment = param[i].path;
          } else {
            that.ruleForm.attachment += "|" + param[i].path;
          }
        }
      },
      /**
       * @description 重置
       */
      onReset() {

      },
      /**
       * @description 提交
       */
      onSave() {
        let that = this;
        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            that.fullscreenLoading = true;
            that.ruleForm.applyLevel = setInt(that.ruleForm.applyLevel);
            if (regNull(that.ruleForm.applyId)) {
              // 新增
              ajaxPost(that, '/api/student/scholarshipapply', that.ruleForm, function(resData) {
                that.fullscreenLoading = false;
                back(that);
              });
            } else {
              ajaxPut(that, '/api/student/scholarshipapply/' + that.ruleForm.applyId, that.ruleForm, function(
                resData) {
                that.fullscreenLoading = false;
                back(that);
              });
            }
          }
        });
      },
      /**
       * @description 返回
       */
      onBack() {
        let that = this;
        back(that);
      },
    },
  };
</script>

<style scoped="scoped">
  .myQuillEditor {
    height: 300px;
    margin-bottom: 40px;
  }

  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }

  .spIcon {
    width: 5px;
    height: 20px;
    border-radius: 5px;
    background-image: linear-gradient(#4545FF, #2D8EFF);
  }

  .spTitle {
    float: left;
    margin-left: 20px;
    color: #323E56;
    letter-spacing: 2px;
    font-size: 16px;
    margin-top: -3px;
  }

  .wMax {
    width: 100% !important;
  }
</style>
