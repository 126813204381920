<!--
  数据字典 - 处分类型
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          处分等级
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <search :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null"></search>
            </li>
            <li class="nav-item">
              <el-button v-if="isPower.punishLevel_create == true" type="success" icon="el-icon-circle-plus-outline"
                @click="onAdd()">新增</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="item!=null && item.length!=0">
        <el-table stripe :data="item" class="tableTwo table-hover text-nowrap " style="width: 100%; margin-top: 0px; ">
          <el-table-column type="index" width="120px;" label="序号"></el-table-column>
          <el-table-column prop="name" label="处分等级">
            <template slot-scope="scope">
              <span>{{scope.row.name}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="lastUpdateDate" label="操作时间">
            <template slot-scope="scope">
              {{scope.row.lastUpdateDate | dateformat('YYYY-MM-DD') }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="240px">
            <template slot-scope="scope">
              <operationBtn v-if="isPower.punishLevel_detail == true" :btnName="'查看'"
                @click.native="onDetail(scope.row.levelId)"></operationBtn>
              <operationBtn v-if="isPower.punishLevel_edit == true" :btnName="'编辑'"
                @click.native="onEdit(scope.row.levelId)"></operationBtn>
              <operationBtn v-if="isPower.punishLevel_delete == true" :btnName="'删除'"
                @click.native="onDelete(scope.row.levelId)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';
  export default {
    components: {
      comPage,
      operationBtn,
      search,
    },
    data() {
      return {
        fullscreenLoading: false,
        page: null, //分页查询接口返回的结果
        item: [], //列表数据
        pageBtnList: null, //分页左侧按钮集合
        dialogTitle: "新增", //弹出框标题
        isDetail: false, //是否是查看弹出框
        paging: { //分页数据
          pageLength: 0, // 总共页数
          Size: 10, // 当前请求数量
          Index: 1 //当前在第几页
        },
        SearchList: null, //搜索集合
        isPower: {
          //增删改查 权限管理
          punishLevel_detail: false,
          punishLevel_create: false,
          punishLevel_edit: false,
          punishLevel_delete: false,
        },
        rules: {
          name: [{
              required: true,
              message: '处分等级不能为空',
              trigger: 'change'
            },
            {
              max: 128,
              message: '处分等级不能超过128个字符',
              trigger: 'change'
            },
          ],
          priority: [{
            validator: (rule, value, callback) => {
              let that = this;
              var reg = /^-?[1-9]\d*$/;
              if (value != 0 && !reg.exec(value)) {
                callback(new Error("优先级只能输入整数！"));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }],
        },
      };
    },
    created() {
      let that = this;
      isPower(that, "punishLevel_detail", "punishLevel_detail");
      isPower(that, "punishLevel_create", "punishLevel_create");
      isPower(that, "punishLevel_edit", "punishLevel_edit");
      isPower(that, "punishLevel_delete", "punishLevel_delete");

      pagingPage(that);
    },
    methods: {
      onSearch(params) {
        let that = this;
        let data = {};
        let searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = element.data;
          }
        });
        data.PageNumer = 1;
        routerPath(that, "/Admin/DataDictionary/Punish/Type", data, null, "PageSize");
      },
      onAdd() { //新增
        let that = this;
        that.$router.push({
          path: '/Admin/DataDictionary/Punish/Type/Create',
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onDetail(Id) { //查看
        let that = this;
        that.$router.push({
          path: "/Admin/DataDictionary/Punish/Type/Detail/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onEdit(Id) { //编辑
        let that = this;
        that.$router.push({
          path: "/Admin/DataDictionary/Punish/Type/Edit/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onDelete(Id) { //删除
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, "/api/admin/punishlevel/" + Id, null, function(r) {
              that.fullscreenLoading = false;
              pagingPage(that);
            });
          }
        })
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      pagingClick(type, val) {
        let that = this;
        let url = "/Admin/DataDictionary/Punish/Type";
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "Name",

          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "Name",
          );
        }
      },
    },
    watch: {
      $route() {
        let that = this;
        pagingPage(that);
      }
    }
  };

  function pagingPage(that) {
    paging(
      that,
      function(r) {
        let Myurl = "/api/admin/punishlevel/search";
        let data = {
          PageNumer: r.PageNumer,
          PageSize: r.PageSize,
          Name: r.Name,
        };

        that.SearchList = [{
          type: "input",
          Name: "处分等级",
          data: r.Name,
          holder: "请输入处分等级",
          zhi: "Name"
        }, ];
        that.fullscreenLoading = true;
        ajaxGet(that, Myurl, data, function(r) {
          that.page = r;
          that.item = r.items;
          that.fullscreenLoading = false;
        });
      },
      "Name",
    );
  }
</script>

<style scoped="scoped">

</style>