<!--
  用户管理 - 新增
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            AI评语设置
            <small class="color-danger">（不选班级则对全校学生进行AI评语生成）</small>
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24" style="margin-top: 20px">
            <el-col :span="24">
              <el-form-item label="学期">
                <el-select v-model="ruleForm.semesterId" placeholder="请选择">
                  <el-option v-for="item in optSemester" :key="item.semesterId" :label="item.name" :value="item.semesterId"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="年级">
                <el-select v-model="ruleForm.collegeId" placeholder="请选择年级" @change="onChangeGrade">
                  <el-option v-for="item in optGrade" :key="item.collegeId" :label="item.name" :value="item.collegeId"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="班级">
                <el-select v-model="ruleForm.classId" placeholder="请选择班级">
                  <el-option v-for="item in optClass" :key="item.classId" :label="item.alias" :value="item.classId"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>
      <div class="row" style="margin-top: 10px" v-if="isPower.admin_ai_create">
        <div class="col-12">
          <el-button type="primary" icon="el-icon-circle-check" @click="onSave()">提交</el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import '@/assets/css/Admin.css';

export default {
  components: {},
  data() {
    return {
      fullscreenLoading: false,
      optSemester: [],
      optGrade: [],
      optClass: [],
      ruleForm: {
        semesterId: null,
        yearId: null,
        classId: null,
        collegeId: null
      },
      rules: null,

      isPower: {
        admin_ai_create: false
      }
    };
  },
  created() {
    let that = this;
    isPower(that, 'admin_ai_create', 'admin_ai_create');
    that.fullscreenLoading = true;
    ajaxGet(that, '/api/admin/semester/readingsemester', null, function (resSemester) {
      that.optSemester = resSemester;
      if (!regNullArray(resSemester)) {
        let tempSemester = resSemester.find((item) => item.isCurrent == true);
        that.ruleForm.semesterId = regNull(tempSemester) ? resSemester[0].semesterId : tempSemester.semesterId;
        that.ruleForm.yearId = regNull(tempSemester) ? resSemester[0].yearId : tempSemester.yearId;
        ajaxGet(that, '/api/admin/college/readingcollege', null, function (resGrade) {
          that.optGrade = resGrade;
          that.fullscreenLoading = false;
        });
      } else {
        that.fullscreenLoading = false;
      }
    });
  },
  methods: {
    /**
     * @description 学期选择事件
     * @param {Object} objValueID
     */
    onChangeSemester(objValueID) {
      let that = this;
      if (!regNull(objValueID)) {
        let tempSemester = that.optSemester.find((item) => item.semesterId == objValueID);
        if (!regNull(tempSemester)) {
          that.ruleForm.yearId = tempSemester.yearId;
          ajaxGet(that, '/api/admin/college/byyear/' + tempSemester.yearId, null, function (resGrade) {
            that.optGrade = resGrade;
            that.ruleForm.classId = null;
            that.ruleForm.collegeId = null;
          });
        }
      }
    },
    /**
     * @description 年级选择事件
     * @param {Object} objValueID
     */
    onChangeGrade(objValueID) {
      let that = this;
      if (!regNull(objValueID)) {
        ajaxGet(that, '/api/admin/class/classbycollegeid?collegeId=' + objValueID, null, function (resClass) {
          that.optClass = resClass.sort((a, b) => parseInt(a.alias) - parseInt(b.alias));
        });
      }
    },
    /**
     * @description  提交数据
     */
    onSave() {
      let that = this;
      if (regNull(that.ruleForm.semesterId)) {
        warning(that, '请选择学期');
        return false;
      }
      let tpParams = {
        SemesterId: that.ruleForm.semesterId,
        ClassId: that.ruleForm.classId
      };

      let tpFonts = regNull(that.ruleForm.classId) ? '请确认是否生成该学期的全校学生评语' : '请确认是否生成该学期当前班级的学生评语';

      confirmDelete(that, tpFonts, function (res) {
        if (res == true) {
          that.fullscreenLoading = true;
          ajaxGet(that, '/api/studentcommenttemp/ai', tpParams, function (res) {
            that.fullscreenLoading = false;
            if (!regNull(res)) {
              if (res.result) {
                success(that, res.message);
              } else {
                warning(that, res.message);
              }
            }
          });
        }
      });
    }
  }
};
</script>

<style scoped="scoped">
label {
  margin-bottom: 0;
}

.inputGroupCont {
  padding-top: 15px;
}

.inputGroupCont .inputList {
  padding-bottom: 10px;
}

.spIcon {
  width: 5px;
  height: 20px;
  border-radius: 5px;
  background-image: linear-gradient(#4545ff, #2d8eff);
}

.spTitle {
  float: left;
  margin-left: 20px;
  color: #323e56;
  letter-spacing: 2px;
  font-size: 16px;
  margin-top: -3px;
}

.wMax {
  width: 100% !important;
}
</style>
