import { render, staticRenderFns } from "./Test.vue?vue&type=template&id=c89a3b54&scoped=true"
import script from "./Test.vue?vue&type=script&lang=js"
export * from "./Test.vue?vue&type=script&lang=js"
import style0 from "./Test.vue?vue&type=style&index=0&id=c89a3b54&prod&scoped=scoped&lang=less"
import style1 from "./Test.vue?vue&type=style&index=1&id=c89a3b54&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c89a3b54",
  null
  
)

export default component.exports