<!--
   管理原因设置
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">

    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          行为规范原因
        </h3>
        <div class="card-tools">
          <ul class="nav nav-pills ml-auto">
            <li class="nav-item pr-1">
              <search :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList != null"
                :SearchTitles='SearchTitles' :key="keyname">
              </search>
            </li>
<!--            <li class="nav-item pr-1">
              <el-button type="primary" icon="el-icon-circle-plus-outline" @click="onAuthorization(1)">批量权授教师</el-button>
            </li> -->
<!--            <li class="nav-item pr-1">
              <el-button type="primary" icon="el-icon-circle-plus-outline" @click="onAuthorization(2)">批量权授学生会</el-button>
            </li> -->
            <li class="nav-item pr-1">
              <el-button type="success" icon="el-icon-circle-plus-outline" @click="onAdd()">新增</el-button>
            </li>

          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="item!=null && item.length!=0">
        <el-table stripe :data="item" class="elTableData">
          <!-- <el-table-column type="index" width="120px;" label="序号"></el-table-column> -->
          <el-table-column prop="name" label="管理原因" min-width="300">
            <template slot-scope="scope">
              <span>{{ scope.row.name }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="inputScoreModeString" label="录入方式" min-width="140"></el-table-column> -->
          <el-table-column prop="classCategory" label="所属班级分类" min-width="180"></el-table-column>
          <el-table-column prop="dormitoryCategory" label="所属寝室分类" min-width="180"></el-table-column>
          <el-table-column prop="personCategory" label="所属个人分类" min-width="180"></el-table-column>
<!--          <el-table-column prop="configs" label="综合类型" min-width="180">
            <template slot-scope="scope">{{scope.row.configs}}</template>
          </el-table-column> -->
          <el-table-column label="操作" width="280px" fixed="right">
            <template slot-scope="scope">
              <operationBtn :btnName="'编辑'" @click.native="onEdit(scope.row.reasonId)"></operationBtn>
              <operationBtn :btnName="'删除'" @click.native="onDelete(scope.row.reasonId)"></operationBtn>
              <operationBtn :btnName="'规则'" @click.native="onRule(scope.row.reasonId)"></operationBtn>
              <operationBtn :btnName="'角色'" @click.native="onRole(scope.row)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
    <el-dialog :title="dialogRuleTitle + ''" :visible.sync="dialogVisibleRule" width="50%" :data="item">
      <!--按选择模式录入-->
      <div class="divSelScoreBx">
        <div class="divFontBox">
          <span class="spFont">
            1、该规则的分数是在设定的分数中选择，不能自定义录入
          </span>
        </div>
        <div class="divScoreTable">
          <el-table border class="elTableData" :data="reasonDeploys">
            <el-table-column label="序号" width="120">
              <template slot-scope="scope">
                {{ scope.$index + 1 }} </template>
            </el-table-column>
            <el-table-column prop="score" label="分值"></el-table-column>
            <el-table-column prop="relatedScore" label="集体分值"></el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>
    <!-- 角色弹出框 -->
    <el-dialog :title="'角色内容'" :visible.sync="dialogVisibleRole" width="50%" v-if="itemRole!=null">
      <div class="divDialogBody">
        <div v-if="itemRole.roleConfigs!=null && itemRole.roleConfigs.length!=0">
          <div class="divTitle">
            教师角色
          </div>
          <div class="divCont mb-4">
            <span v-for="item in itemRole.roleConfigs" :key="item.roleId" class="spTag">
              <el-tag type="info" effect="plain">
                {{ item.name }}
              </el-tag>
            </span>
          </div>
        </div>
        <div v-if="itemRole.associationConfigs!=null && itemRole.associationConfigs.length!=0">
          <div class="divTitle">学生会角色</div>
          <div class="divCont">
            <span v-for="item in itemRole.associationConfigs" :key="item.associationId" class="spTag">
              <el-tag type="info" effect="plain">
                {{ item.name }}
              </el-tag>
            </span>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import Vue from 'vue';
  import "@/assets/css/Admin.css";
  import search from "@/components/search.vue";
  import comPage from "@/components/Page.vue";
  import operationBtn from "@/components/operationBtn.vue";
  export default {
    name: "",
    components: {
      search,
      comPage,
      operationBtn,
    },
    data() {
      return {
        SearchTitles: [],
        page: null, //分页查询接口返回的结果
        item: [], //列表数据
        pageBtnList: null, //分页左侧按钮集合
        paging: {
          //分页数据
          pageLength: 0, // 总共页数
          Size: 10, // 当前请求数量
          Index: 1, //当前在第几页
        },
        moralityTree: [],
        SearchList: null, //搜索集合
        uploadInfoApi: null,
        isPower: {
        },
        fullscreenLoading: false,
        roles: [],
        studentRoles: [],
        currentType: 1,
        isDetail: false,
        random: null,
        dialogTitle: null,
        cols: 8,
        top: 10,
        isConfig: false,
        growthConfigs: [],
        ruleForm: {
          reasonId: null,
          name: null,
          score: null,
          priority: null,
          parentId: null,
          description: null,
          configs: null,
          reasonRoles: [],
          reasonStudentRoles: [],
          reasonType: 2,
        },
        ruleFormReset: null,
        growthConfigsReset: null,
        rules: {
          name: [{
              required: true,
              message: "原因不能为空",
              trigger: "change",
            },
            {
              max: 64,
              message: "原因不能超过64个字符",
              trigger: "change",
            },
          ],
          parentId: [{
            required: true,
            message: "请选择类型",
            trigger: "change",
          }, ],
          score: [{
            required: true,
            message: "请输入分值",
            trigger: "change",
          }, ],
          priority: [{
            validator: (rule, value, callback) => {
              let that = this;
              var reg = /^-?[1-9]\d*$/;
              if (value != 0 && !reg.exec(value)) {
                callback(new Error("优先级只能输入整数！"));
              } else {
                callback();
              }
            },
            trigger: "change",
          }, ],
        },
        activityroleReset: null,

        // 扣分规则弹出框的标题
        dialogRuleTitle: "",
        // 规则弹出框
        dialogVisibleRule: false,
        // 角色弹出框
        dialogVisibleRole: false,
        // 规则弹出框模式
        inputScoreMode: "",
        // 规则弹出框内容
        reasonDeploys: [],
        // 角色弹出框内容
        rolepopupContent: [],
        // 按次数累加的周期
        cycleString: "",
        reasonStudentRoles: [],

        dialogRoleTitle: null, // 角色提示框标题
        itemRole: null,
        keyname:onGetRandomGUID(),
      };
    },
    created() {
      let that = this;
      InitType(that);
      //获取活动角色-综合类型
      ajaxGet(that, "/api/admin/GrowthConfig", null, function(r2) {
        for (var it of r2) {
          it.isSelect = false;
          it.score = 0;
        }
        that.growthConfigs = JSON.parse(JSON.stringify(r2));
      });

      ajaxGet(that, "/api/admin/sysRole", null, function(r) {
        ajaxGet(that, "/api/admin/studentroleinfo", null, function(r1) {
          that.roles = r;
          that.studentRoles = r1;
        });
      });
    },
    methods: {
      onImportSuccess(param) {
        let that = this;
        pagingPage(that);
      },
      /**
       * @description 打开扣分规则
       * @param {Object} _dataID 原因ID
       */
      onRule(_dataID) {
        let that = this;
        that.dialogVisibleRule = true;
        that.item.forEach(function(r, index) {
          if (r.reasonId == _dataID) {
            that.reasonDeploys = r.scores;
          }
          return index
        });

      },
      /**
       * @description 查看角色情况
       * @param {Object} objItem
       */
      onRole(objItem) {
        let that = this;
        that.dialogVisibleRole = true;
        that.itemRole = objItem;
      },
      getValue(value) {
        let that = this;
        that.ruleForm.parentId = value;
      },
      onReset() {
        let that = this;
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
        that.growthConfigs = JSON.parse(JSON.stringify(that.growthConfigsReset));
      },
      onScoreChange() {
        let that = this;
        var value = setTwoDecimal(that.ruleForm.score);
        that.ruleForm.score = value;
      },
      onPriorityChange() {
        let that = this;
        var value = setInt(that.ruleForm.priority);
        that.ruleForm.priority = value;
      },
      /**
       * @description 批量授权
       */
      onAuthorization(type){
        let that=this;
        that.$router.push({
          path: "/Admin/MoralityReason/Authorization/" + type,
          query: {
            url: that.$router.history.current.fullPath,
          },
        });
      },
      onAdd() {
        let that = this;
        that.$router.push({
          path: "/Admin/MoralityReason/Create/" + that.currentType,
          query: {
            url: that.$router.history.current.fullPath,
          },
        });
      },
      onEdit(_dataID) {
        let that = this;
        that.$router.push({
          path: "/Admin/MoralityReason/Update/" + that.currentType + "/" + _dataID,
          query: {
            url: that.$router.history.current.fullPath,
          },
        });
      },
      onDelete(id) {
        let that = this;
        var deleteUrl = "";

        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, "/api/admin/moralreason/"+id, null, function(r) {
              that.fullscreenLoading = false;
              InitType(that);
            });
          }
        });
      },
      onSearch(params) {
        let that = this;
        let data = {};
        let searchData = params;
        searchData.forEach((element) => {
          if (element.data) {
            if (Array.isArray(element.data)) {
              data[element.zhi] = element.data[element.data.length - 1];
            } else {
              data[element.zhi] = element.data;
            }
          }
        });
        data["type"] = that.currentType;
        data.PageNumer = 1;
        routerPath(that, "/Admin/MoralityReason/Index", data, null, "PageSize");
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      pagingClick(type, val) {
        let that = this;
        let url = "/Admin/MoralityReason/Index?type=" + that.currentType;
        if (type == 1) {
          //更改每页条数触发的事件
          routerPath(that, url, "PageSize", val, "PageNumer", "Name", "ParentId", "AssociationId", "RoleId");
        } else {
          //更改当前页时触发的事件
          routerPath(that, url, "PageNumer", val, "PageSize", "Name", "ParentId", "AssociationId", "RoleId");
        }
      },
    },
    watch: {
      $route() {
        let that = this;
        InitType(that);
      },
    },
  };

  function InitType(that) {
    that.currentType = that.$route.query.type;
    //moralityTree
    pagingPage(that);
  }

  function pagingPage(that) {
    that.fullscreenLoading = true;
    that.data = [];


    paging(
      that,
      function(r) {
        let param = {
          reasonTypeEnum: 1,
        };
          ajaxGet(that, '/api/admin/association', null, function(res) {
            ajaxGet(that, '/api/admin/sysrole', null, function(resRole) {
              that.keyname=onGetRandomGUID();
              const associationList = res.map(it => ({
                Value: it.associationId,
                Title: it.name,
              }))
              const roleList = resRole.map(it => ({
                Value: it.roleId,
                Title: it.name,
              }))
              let typeList = [];
              that.SearchList = [{
                type: "input",
                Name: "管理原因",
                data: r.Name,
                holder: "请输入管理原因",
                zhi: "Name",
              },
              // {
              //   type: "cascaderreason",
              //   Name: "原因类型",
              //   data: r.ParentId,
              //   select: that.moralityTree,
              //   holder: "请选择原因类型",
              //   zhi: "ParentId",
              // }, {
              //   type: "select",
              //   Name: "学生会角色",
              //   data: r.AssociationId,
              //   select: associationList,
              //   holder: "请选择学生会角色",
              //   zhi: "AssociationId",
              // }, {
              //   type: "select",
              //   Name: "教师角色",
              //   data: r.RoleId,
              //   select: roleList,
              //   holder: "请选择教师角色",
              //   zhi: "RoleId"
              // },
              ];
              let data = {
                PageNumer: r.PageNumer,
                PageSize: r.PageSize,
                Name: r.Name,
                ParentId: r.ParentId,
                AssociationId: r.AssociationId,
                RoleId: r.RoleId,
                ReasonType: 2,
              };
              that.fullscreenLoading = true;
              ajaxGet(that, "/api/admin/moralreason/search", data, function(r) {
                that.page = r;
                that.item = r.items;
                that.fullscreenLoading = false;
              })
            })
          });
      },
      "Name",
      "Type",
      "ParentId",
      "AssociationId",
      "RoleId"
    );
  }
</script>
<style scoped="scoped" lang="less">
  .divDialogBody {
    .divTitle {
      border: 1px solid #409EFF;
      width: 100px;
      color: #ffffff;
      background-color: #409EFF;
      text-align: center;
      padding: 5px;
      border-radius: 5px;
    }

    .divCont {
      padding: 15px;

      .spTag {
        margin-right: 5px;
      }
    }
  }

  .divSelScoreBx {}

  .divSelScoreBx .divFontBox {
    padding-bottom: 15px;
  }

  .divSelScoreBx .divFontBox .spFont {
    display: block;
    color: #000000;
  }

  .divSelScoreBx .divContFontBox {
    padding-left: 15px;
  }

  .divSelScoreBx .divContFontBox .fontList {
    padding-bottom: 15px;
    font-size: 16px;
  }

  .divSelScoreBx .divContFontBox .spLabel {}
</style>
<style>
  .navTabsCont .nav-link {
    font-size: 12px;
  }

  .navToolsCont {
    margin-top: 1px;
  }
</style>
