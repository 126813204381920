<!--
  学生管理-学生奖励
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <search :ColNumber="3" :SearchList="SearchList" :key="random" ref="SearchList" @onSearch="onSearch"
      v-if="SearchList!=null"></search>
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          民主评议
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <!--导入-->
              <comImport @onImportSuccess="onImportSuccess" :template="'/templates/民主评议导入模版.xlsx'" :path="uploadInfoApi"
                :btnIcon="'el-icon-upload2'" :title="'批量导入民主评议 '" :btnName="'导入'" ref="conImportInfoFile"
                v-if="isPower.admin_assessment_review_import">
              </comImport>
            </li>
            <li class="nav-item">
              <el-button type="success" icon="el-icon-circle-plus-outline" v-if="isPower.admin_assessment_review_create"
                @click.native="onCalculation()">
                新增民主评议</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table stripe :data="itemData" class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod">
          </el-table-column>
          <el-table-column prop="semester" label="学期" min-width="200"></el-table-column>
          <el-table-column prop="class" label="班级" min-width="140" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="teacher" label="班主任" width="100" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="reviewTypeStr" label="评议类型" min-width="120" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="score" label="评议分数" min-width="100"></el-table-column>
          <el-table-column label="操作" :width="thWidth" fixed="right">
            <template slot-scope="scope">
              <operationBtn :btnName="'查看'" v-if="isPower.admin_assessment_review_details"
                @click.native="onDetail(scope.row.reviewId)"></operationBtn>
              <operationBtn :btnName="'编辑'" v-if="isPower.admin_assessment_review_edit"
                @click.native="onEdit(scope.row.reviewId)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible2" width="50%" :close-on-click-modal="false"
      :close-on-press-escape="false" :append-to-body="true" :fullscreen="false" :before-close="onCancel">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="90px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="班级" prop="classId">
              <el-select v-model="ruleForm.classId" filterable @change="onChangeClass" placeholder="请选择"
                class="width_100Pie">
                <el-option v-for="item in optClass" :key="item.classId" :label="item.alias" :value="item.classId">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="班主任" prop="teacher">
              <el-input class="width_100Pie" placeholder="请先选择班级" v-model="ruleForm.teacher" :disabled="true">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="学期" prop="semesterId">
              <el-select v-model="ruleForm.semesterId" placeholder="请选择学期" style="width: 100%;">
                <el-option v-for="item in semesterlist" :key="item.Value" :label="item.Title " :value="item.Value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="评议类型" prop="reviewType">
              <el-select v-model="ruleForm.reviewType" placeholder="请选择评议类型" style="width: 100%;">
                <el-option v-for="item in typelist" :key="item.Value" :label="item.Text " :value="item.Value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="分数" prop="score">
              <el-input class="width_100Pie" placeholder="请输入分数" v-model="ruleForm.score">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注" prop="description">
              <el-input class="formControl" type="textarea" :rows="4" show-word-limit placeholder="请输入备注"
                maxlength="500" v-model="ruleForm.description">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <div>
          <el-button @click="onCancel">取 消</el-button>
          <el-button @click="onReset()">重 置</el-button>
          <el-button type="primary" @click="onSave()">确 定</el-button>
        </div>
      </span>
    </el-dialog>
    <el-dialog title="查看民主评议详情" :visible.sync="dialogVisibleView" width="50%" v-if="viewData!=null">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-12 colInfo">
            <label>学期</label>
            <div class="colFonts">
              {{viewData.semester}}
            </div>
          </div>
          <div class="col-lg-12 colInfo">
            <label>班级</label>
            <div class="colFonts">
              {{viewData.class}}
            </div>
          </div>
          <div class="col-lg-12 colInfo">
            <label>班主任</label>
            <div class="colFonts">
              {{viewData.teacher}}
            </div>
          </div>
          <div class="col-lg-12 colInfo">
            <label>民主评议类型</label>
            <div class="colFonts">
              {{viewData.reviewTypeStr}}
            </div>
          </div>
          <div class="col-lg-12 colInfo">
            <label>分数</label>
            <div class="colFonts">
              {{viewData.score}}
            </div>
          </div>
          <div class="col-lg-12 colInfo">
            <label>描述</label>
            <div class="colFonts">
              {{viewData.description}}
            </div>
          </div>
          <div class="col-lg-12 colInfo">
            <label>记录人</label>
            <div class="colFonts">
              {{viewData.user}}
            </div>
          </div>
          <div class="col-lg-12 colInfo">
            <label>创建时间</label>
            <div class="colFonts">
              {{viewData.createDate | dateformat('YYYY-MM-DD') }}
            </div>
          </div>
        </div>
      </div>
    </el-dialog>


  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';
  import comImport from '@/components/Import.vue';
  export default {
    name: '',
    components: {
      comPage,
      operationBtn,
      comImport,
      search
    },
    data() {
      return {
        textarea: null,
        fullscreenLoading: false,
        dialogVisibleView: false,
        dialogTitle: '',
        dialogVisible2: false,
        page: null, //分页查询接口返回的结果
        itemData: [], //列表数据
        pageBtnList: null, //分页左侧按钮集合
        paging: { //分页数据
          pageLength: 0, // 总共页数
          Size: 10, // 当前请求数量
          Index: 1 //当前在第几页
        },
        random: null,
        rules: {
          classId: [{
            required: true,
            message: '班级不能为空',
            trigger: 'change'
          }],
          semesterId: [{
            required: true,
            message: '学期不能为空',
            trigger: 'change'
          }],
          reviewType: [{
            required: true,
            message: '评分类型不能为空',
            trigger: 'change'
          }],
          score: [{
            required: true,
            message: '分数不能为空',
            trigger: 'change'
          }]
        },
        ruleForm: {
          reviewId: null,
        },
        isPower: {
          admin_assessment_review_create: false,
          admin_assessment_review_import: false,
          admin_assessment_review_edit: false,
          admin_assessment_review_details: false,
        },
        uploadInfoApi: '/api/admin/adviserreview/import',
        ruleFormReset: {},
        viewData: {},
        SearchList: null, //搜索集合
        itemdata: {},
        semesterlist: [],
        optClass: [],
        typelist: [],
        thWidth: 100,
      };
    },
    created() {
      let that = this;
      that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
      isPower(that, 'admin_assessment_review_create', 'admin_assessment_review_create');
      isPower(that, 'admin_assessment_review_import', 'admin_assessment_review_import');
      isPower(that, 'admin_assessment_review_edit', 'admin_assessment_review_edit');
      isPower(that, 'admin_assessment_review_details', 'admin_assessment_review_details');
      ajaxGet(that, '/api/admin/sysuser/userfilternograduate', null, function(resClass) {
        that.optClass = resClass.filter(item => item.teacherId);
        ajaxGet(that, '/api/admin/adviserreview/type', null, function(res) {
          that.typelist = res;
        })
      });
      pagingPage(that);


      that.thWidth = onGetWidth(that, [
        that.isPower.admin_assessment_review_details,
        that.isPower.admin_assessment_review_edit
      ]);
    },
    methods: {
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },
      onCalculation() {
        let that = this;
        that.dialogVisible2 = true;
        that.dialogTitle = '新增民主评分'
      },
      onReset() {
        let that = this;
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      //导入成功后
      onImportSuccess(param) {

        let that = this;
       pagingPage(that);

      },
      onEdit(Id) {
        let that = this;
        ajaxGet(that, '/api/admin/adviserreview/' + Id, null, function(res) {
          that.dialogVisible2 = true;
          that.ruleForm = res;
          that.ruleForm.reviewType = that.ruleForm.reviewType.toString()
          that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
        })
      },
      onDetail(Id) {
        let that = this;
        that.fullscreenLoading = true;
        ajaxGet(that, '/api/admin/adviserreview/' + Id, null, function(res) {
          that.dialogVisibleView = true;
          that.fullscreenLoading = false;
          that.viewData = res
        })
      },
      onSave() {
        let that = this;
        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            that.ruleForm.reviewType = parseInt(that.ruleForm.reviewType)
            that.fullscreenLoading = true;
            if (that.ruleForm.reviewId == null) {
              ajaxPost(that, '/api/admin/adviserreview', that.ruleForm, function(resdata) {
                that.fullscreenLoading = false;
                that.dialogVisible2 = false;
                pagingPage(that);
              })
            } else {
              ajaxPut(that, '/api/admin/adviserreview/' + that.ruleForm.reviewId, that.ruleForm, function(r) {
                that.fullscreenLoading = false;
                that.dialogVisible2 = false;
                pagingPage(that);
              })
            }
          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              isError[0].querySelector('input').focus();
            }, 1)
            return false;
          }
        });
      },
      /**
       * @description 关闭弹出框事件
       */
      onCancel() {
        let that = this;
        try {
          that.dialogVisible2 = false;
          that.ruleForm = {};
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      /**
       * @description 选择班级事件
       * @param {Object} objID
       */
      onChangeClass(objID) {
        let that = this;
        let temp = that.optClass.find(item => item.classId == objID);
        if (temp != undefined) {
          if (!regNull(temp.teacherId)) {
            that.ruleForm.teacher = temp.teacher;
            that.ruleForm.teacherId = temp.teacherId;
          } else {
            that.ruleForm.teacher = '暂无班主任';
            that.ruleForm.teacherId = null;
          }
        }
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      onSearch(params) {
        let that = this,
          data = {},
          searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = Join(element.data);
          }
        });
        data.PageNumer = 1;
        routerPath(that, "/Admin/Assessment/ReView/Index", data, null, "PageSize");
      },
      pagingClick(type, val) {
        let that = this;
        let url = '/Admin/Assessment/ReView/Index';
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "semesterId",
            "teacher",
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "semesterId",
            "teacher",
          );
        }
      },
    },
    watch: {
      $route() {
        let that = this;
        pagingPage(that);
      }
    }
  };

  function pagingPage(that) {
    paging(
      that,
      function(r) {
        ajaxGet(that, '/api/admin/semester', null, function(ressemester) {
          let semester = [];
          for (var it of ressemester) {
            semester.push({
              Value: it.semesterId,
              Title: it.name,
            })
            that.semesterlist = semester;
          }
          let semesterId = r.semesterId;
          that.SearchList = [];
          that.SearchList.push({
            type: "select",
            Name: "学期",
            data: semesterId,
            select: that.semesterlist,
            zhi: "semesterId",
            isClear: false, //select是否可以清除
          }, {
            type: "input",
            Name: "班主任",
            data: r.teacher,
            holder: "请输入班主任姓名",
            zhi: "teacher"
          });
          let tempData = {
            PageNumer: r.PageNumer,
            PageSize: r.PageSize,
            SemesterId: semesterId,
            TeacherName: r.teacher,
          }
          that.itemData = [];
          ajaxGet(that, '/api/admin/adviserreview/search', tempData, function(resData) {
            that.fullscreenLoading = false;
            if (!regNullArray(resData.items)) {
              that.page = resData;
              that.itemData = resData.items;
            }
          });
        });
      },
      "semesterId",
      "teacher",
    );
  }
</script>

<style scoped="scoped">
</style>
