<template>
  <div class="bottom-right-table-1">
    <dv-border-box-12 class="dvBox">
      <div class="divTitle">
        全校学生综合总分班级排行
      </div>
      <div v-if="!regNullArray(optData)">
        <div class="divForBox" v-for="(item,index) in optData" :key="index">
          <div class="divItemTitle">
            {{item.collegeName}}
          </div>
          <div class="divItem" v-for="(list,indexTwo) in item.data.slice(0,5)" :key="indexTwo">
            <div class="divImg">
              <span class="spRank" v-if="list.ranking==1">
                <img src="@/assets/image/icon/no_1.png" class="iconImg">
              </span>
              <span class="spRank" v-else-if="list.ranking==2">
                <img src="@/assets/image/icon/no_2.png" class="iconImg">
              </span>
              <span class="spRank" v-else-if="list.ranking==3">
                <img src="@/assets/image/icon/no_3.png" class="iconImg">
              </span>
              <span class="spRankOther" v-else>
                {{list.ranking}}
              </span>
            </div>
            <div class="divClass">{{list.className}}</div>
            <div class="divAvg"><b>{{list.average | flScore}}</b>分/人</div>
            <div class="divTotal">
              <span class="spNum">{{list.score | flScore}}</span>
              <span class="spFont">分</span>
            </div>
          </div>
        </div>
      </div>
      <div class="divNoData" v-else>
        <dv-loading>Loading...</dv-loading>
      </div>
    </dv-border-box-12>
  </div>
</template>

<script>
  export default {
    name: 'ClassRank',
    data() {
      return {
        optData: []

      }
    },
    methods: {
      /**
       * @description 加载数据
       * @param {Object} objData
       */
      onCreateData(objData) {
        let that = this;
        that.optData = JSON.parse(JSON.stringify(objData));
      }
    },
    filters:{
      /**
       * @description 过滤处理分数
       * @param {Object} obj
       */
      flScore(obj){
        return obj!=0? setTwoDecimal(obj, 2):'0.00';
      }
    }
  }
</script>

<style lang="less" scoped>
  .divTitle {
    color: #FFF;
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .divForBox {
    border-bottom: 1px solid #042153;
    padding-bottom: 6px;
    margin-bottom: 6px;

    &:last-child {
      border-bottom: none;
    }

    .divItemTitle {
      color: #FFF;
      font-size: 22px;
      font-weight: bold;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .divItem {
      display: flex;
      font-size: 18px;
      padding-bottom: 5px;
      padding-top: 5px;
      width: 100%;

      .divImg {
        .spRank {
          .iconImg {
            width: 30px;

          }
        }

        .spRankOther {
          background: url(../../assets/image/icon/no_other.png) no-repeat;
          display: block;
          height: 36px;
          background-size: 100%;
          width: 30px;
          text-align: center;
          line-height: 32px;
          font-size: 12px;
          //<img src="@" class="iconImg">
        }
      }

      .divClass {
        padding-left: 15px;
        width: 150px;
        line-height: 32px;
      }

      .divAvg {
        flex: 1;
        line-height: 32px;

        b {
          margin-right: 4px;
        }
      }

      .divTotal {
        display: flex;
        line-height: 32px;

        .spNum {
          font-weight: bold;
          color: #00c0ff;
        }

        .spFont {}
      }
    }
  }

  .dvBox {
    padding: 30px;
  }
  .divNoData{
    text-align: center;
    padding-top: 30%;
  }
  .bottom-right-table-1 {
    width: 100%;
    height: 100%;
    margin: 0 -5px;


  }
</style>
