<!--社会实践-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">


    <el-card class="box-card">
      <div slot="header" class="card-header">
        <span>奖学金批次</span>
        <div style="float:right;">
          <el-button type="success" icon="el-icon-circle-plus-outline" @click="onCreate">新增批次</el-button>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table stripe :data="itemData" class="elTableData">
          <el-table-column type="index" label="序号" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="yearName" label="学年" min-width="120" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="completed" label="是否完成" width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.completed==false">
                未完成
              </span>
              <span v-if="scope.row.completed==true">
                已完成
              </span>
            </template>
          </el-table-column>
          <el-table-column label="批次时间" width="240">
            <template slot-scope="scope">
              {{scope.row.startDate | dateformatDay}} 至 {{scope.row.endDate | dateformatDay}}
            </template>
          </el-table-column>
          <el-table-column prop="createDate" label="创建时间" width="190">
            <template slot-scope="scope">
              {{scope.row.createDate | dateformatMinute}}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200" fixed="right">
            <template slot-scope="scope">
              <operationBtn :btnName="'查看'" v-if="isPower.scholarshipmgt_batch_details"
                @click.native="onDetail(scope.row.batchId)"></operationBtn>
              &nbsp;
              <el-dropdown class="elDropdown" @command="handleCommand($event,scope.row.batchId)"
                v-if="isPower.scholarship_roster_index||isPower.scholarship_info_index||isPower.scholarship_batch_edit">
                <el-button size="mini">
                  更多操作<i class="el-icon-arrow-down el-icon--right "></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item class="color-primary" command="onMember" v-if="isPower.scholarship_roster_index">
                    候选人名单</el-dropdown-item>
                  <el-dropdown-item class="color-primary" command="onEvaluate" v-if="isPower.scholarship_info_index">
                    奖学金评定</el-dropdown-item>
                  <el-dropdown-item class="color-warning" command="onEdit" v-if="isPower.scholarship_batch_edit">编辑
                  </el-dropdown-item>
                  <el-dropdown-item class="color-warning" command="onComplete" v-if="isPower.scholarship_batch_edit">
                    批次完成</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>


    <el-dialog :title="boxTitle" :visible.sync="dialogVisible" width="40%" :before-close="onCancel">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px">
        <el-row :gutter="24">
          <el-col :span="23" class="pr-4">
            <el-form-item label="学年" prop="selYearId">
              <el-select v-model="ruleForm.selYearId" placeholder="请选择" class="width_100Pie">
                <el-option v-for="item in optYear" :key="item.yearId" :label="item.name" :value="item.yearId">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="23" class="pr-4">
            <el-form-item label="申请时段" prop="selDateTime">
              <el-date-picker class="width_100Pie" v-model="ruleForm.selDateTime" type="daterange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="onSave">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="查看奖学金批次" :visible.sync="dialogVisibleView" width="30%" v-if="viewData!=null">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-12 colInfo">
            <label>学年</label>
            <div class="colFonts">
              {{viewData.yearName}}
            </div>
          </div>
          <div class="col-lg-12 colInfo">
            <label>批次时段</label>
            <div class="colFonts">
              {{viewData.startDate | dateformatDay}} 至 {{viewData.endDate | dateformatDay}}
            </div>
          </div>
          <div class="col-lg-12 colInfo">
            <label>创建时间</label>
            <div class="colFonts">
              {{viewData.createDate | dateformatMinute}}
            </div>
          </div>
        </div>
      </div>
    </el-dialog>



  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  export default {
    components: {
      comPage,
      operationBtn,
    },
    data() {
      return {
        // 加载动画
        fullscreenLoading: false,
        // 学年数据
        optYear: [],

        itemData: [], //列表数据
        page: null, //分页查询接口返回的结果
        pageBtnList: null, //分页左侧按钮集合
        paging: { //分页数据
          pageLength: 0, // 总共页数
          Size: 10, // 当前请求数量
          Index: 1 //当前在第几页
        },
        // 弹出框状态
        dialogVisible: false,
        // 查看弹出框
        dialogVisibleView: false,
        viewData: null,
        // 弹出框标题
        boxTitle: '新增奖学金批次',
        // 表单对象
        ruleForm: {
          // 选中的学年ID
          selYearId: null,
          // 选中的时段数组
          selDateTime: null,
          batchId: null,
        },
        ruleFormReset: null,
        // 表单验证
        rules: {
          selYearId: [{
            required: true,
            message: '请选择学年',
            trigger: 'change'
          }, ],
          selDateTime: [{
            required: true,
            message: '请选择时段',
            trigger: 'change'
          }, ],
        },
        // 权限
        isPower: {
          scholarship_roster_index: false,
          scholarship_info_index: false,
          scholarship_batch_edit: false,
          scholarship_batch_create: false,
          scholarshipmgt_batch_details: false,
        }
      };
    },
    created() {
      let that = this;
      isPower(that, 'scholarship_roster_index', 'scholarship_roster_index');
      isPower(that, 'scholarship_info_index', 'scholarship_info_index');
      isPower(that, 'scholarship_batch_edit', 'scholarship_batch_edit');
      isPower(that, 'scholarship_batch_create', 'scholarship_batch_create');
      isPower(that, 'scholarshipmgt_batch_details', 'scholarshipmgt_batch_details');
      that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
      that.onGetData(that);
      that.onGetYear(that);
    },
    methods: {
      /**
       * @description 获取学年数据
       * @param {Object} that
       */
      onGetYear(that) {
        ajaxGet(that, '/api/admin/academic', null, function(resData) {
          that.optYear = resData;
        });
      },

      /**
       * @description 加载数据
       * @param {Object} that
       */
      onGetData(that) {
        that.fullscreenLoading = true;
        let tempData = {
          PageNumer: that.paging.Index,
          PageSize: that.paging.Size,
        };
        ajaxGet(that, '/api/admin/scholarshipbatch/search', tempData, function(resData) {
          that.fullscreenLoading = false;
          that.page = resData;
          that.itemData = resData.items;
          that.paging.pageLength = resData.totalPages;
        });
      },

      /**
       * @description 保存
       */
      onSave() {
        let that = this;
        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            let tempData = {
              yearId: that.ruleForm.selYearId,
              startDate: timeTransform(that.ruleForm.selDateTime[0]),
              endDate: timeTransform(that.ruleForm.selDateTime[1]),
              batchId: that.ruleForm.batchId,
            }
            // 判断ID是否为空，空不新增
            that.fullscreenLoading = true;
            if (regNull(that.ruleForm.batchId)) {
              confirmGenerate(that, null, function(res) {
                if (res == true) {
                  ajaxPost(that, '/api/admin/scholarshipbatch', tempData, function(res) {
                    that.fullscreenLoading = false;
                    that.onCancel();
                    that.onGetData(that);
                  });
                } else {
                  that.fullscreenLoading = false;
                }
              })
            } else {
              ajaxPut(that, '/api/admin/scholarshipbatch/' + tempData.batchId, tempData, function(res) {
                that.fullscreenLoading = false;
                that.onCancel();
                that.onGetData(that);
              });
            }
          }
        });
      },

      /**
       * @description 关闭弹出框事件
       */
      onCancel() {
        let that = this;
        try {
          that.dialogVisible = false;
          that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },

      /**
       * @description 更多操作点击事件
       * @param {Object} objType
       * @param {Object} objID
       */
      handleCommand(objType, objID) {
        let that = this;
        switch (objType) {
          case 'onEdit':
            that.onEdit(objID);
            break;
          case 'onMember':
            that.onMember(objID);
            break;
          case 'onEvaluate':
            that.onEvaluate(objID);
            break;
          case 'onComplete':
            that.onComplete(objID);
        }
      },

      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },

      /**
       * @description 新增批次
       */
      onCreate() {
        let that = this;
        that.dialogVisible = true;
        that.boxTitle = '新增奖学金批次';
      },
      /**
       * @description  完成批次
       */
      onComplete(objID) {
        let that = this;
        confirmComplete(that, null, function(r) {
          if (r == true) {
            that.fullscreenLoading = true;
            let data = {
              batchId: objID,
            }
            ajaxPut(that, '/api/admin/scholarshipbatch/complete/' + objID, data, function(res) {
              that.fullscreenLoading = false;
              that.onGetData(that);
            })
          }
        })

      },
      /**
       * @description 名单
       * @param {Object} objID
       */
      onMember(objID) {
        let that = this;
        that.$router.push({
          path: "/Admin/ScholarshipMGT/Roster/Index/" + objID,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      /**
       * @description 评定
       * @param {Object} objID
       */
      onEvaluate(objID) {
        let that = this;
        that.$router.push({
          path: "/Admin/ScholarshipMGT/Info/Index/" + objID,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },

      /**
       * @description 详情
       * @param {Object} objID
       */
      onDetail(objID) {
        let that = this;
        that.viewData = that.itemData.find(item => item.batchId == objID);
        if (that.viewData != undefined) {
          that.dialogVisibleView = true;
        }

      },

      /**
       * @description 编辑
       * @param {Object} objID
       */
      onEdit(objID) {
        let that = this;
        let temp = that.itemData.find(item => item.batchId == objID);

        if (temp != undefined) {
          that.boxTitle = '编辑奖学金批次';
          that.ruleForm.batchId = temp.batchId;
          that.ruleForm.selYearId = temp.yearId;
          that.ruleForm.selDateTime = [dateFormat(temp.startDate, '-', 'YYYY-MM-DD'), dateFormat(temp.endDate, '-',
            'YYYY-MM-DD')];
          that.dialogVisible = true;
        }
      },

      /**
       * @description 删除
       * @param {Object} objID
       */
      onDelete(objID) {
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {

          }
        })
      },

      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },

      /**
       * @description 页码事件
       * @param {Object} type
       * @param {Object} val
       */
      pagingClick(type, val) {
        let that = this;
        if (type == 1) { //更改每页条数触发的事件
          that.paging.Index = 1;
          that.paging.Size = val;
        } else { //更改当前页时触发的事件
          that.paging.Index = val;
        }
        that.onGetData(that);
      }
    },
  };

  function confirmGenerate(that, msg, callback) {
    if (msg == null || typeof(msg) == "undefined" || msg == "") {
      msg = "您确定要生成批次吗？ 生成需要等待一些时间来生成候选人名单";
    }
    confirm(that, msg, callback);
  }

  function confirmComplete(that, msg, callback) {
    if (msg == null || typeof(msg) == "undefined" || msg == "") {
      msg = "您确定要将当前批次完成吗";
    }
    confirm(that, msg, callback);
  }
</script>

<style scoped="scoped">
  .elDropdown {
    margin-left: 2px;
  }
</style>
