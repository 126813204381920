<!--
  日常事务 - 日常动态管理 - 自我记录 - 详情
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          获奖上报详情
        </h3>
      </div>
      <div class="card-body">
        <el-row :gutter="24">
          <el-col :span="24" class="colInfo">
            <label>获奖名称</label>
            <div class="colFonts">{{itemData.name | flNoValue}}</div>
          </el-col>
          <el-col :span="8" class="colInfo">
            <label>获奖时间</label>
            <div class="colFonts">{{itemData.recordDate | dateformatDay}}</div>
          </el-col>
          <el-col :span="8" class="colInfo">
            <label>颁奖单位</label>
            <div class="colFonts"> {{itemData.place | flNoValue}}</div>
          </el-col>
          <el-col :span="8" class="colInfo">
            <label>审核状态</label>
            <div class="colFonts">
              <span class="color-warning" v-if="itemData.auditState==1"> {{itemData.auditStateString}}</span>
              <span class="color-success" v-else-if="itemData.auditState==2"> {{itemData.auditStateString}}</span>
              <span class="color-danger" v-else-if="itemData.auditState==3"> {{itemData.auditStateString}}</span>
              <span v-else>{{itemData.auditStateString}}</span>
            </div>
          </el-col>
          <el-col :span="8" class="colInfo">
            <label>班级</label>
            <div class="colFonts">{{itemData.class | flNoValue}}</div>
          </el-col>
          <el-col :span="8" class="colInfo">
            <label>学号</label>
            <div class="colFonts">{{itemData.studentNo | flNoValue}}</div>
          </el-col>
          <el-col :span="8" class="colInfo">
            <label>学生</label>
            <div class="colFonts">{{itemData.studentName | flNoValue}}</div>
          </el-col>
          <el-col :span="8" class="colInfo" v-if="itemData.auditState==2">
            <label>是否计入综合分</label>
            <div class="colFonts">
              <span class="color-success" v-if="itemData.isReckon"> 已计入综合分</span>
              <span v-else>未计入综合分</span>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-card>


    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          备注
        </h3>
      </div>
      <div class="card-body">
        <div class="colRemarksCont" v-if="itemData.description!=null" v-html="itemData.description">
        </div>
        <div class="NoDataTipCont" style="text-align: center;" v-else>
          <svg aria-hidden="true" class="icon">
            <use xlink:href="#fax-exclamation-triangle-o"></use>
          </svg> 暂无备注
        </div>
      </div>
    </el-card>


    <comShowImage :uploadFiles="uploadFiles"></comShowImage>


    <div class="row" style="margin-top: 10px;">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
      </div>
    </div>


  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comShowImage from '@/components/showImage.vue';
  export default {
    components: {
      comShowImage,
    },
    data() {
      return {
        fullscreenLoading: false,
        showViewer: false,
        //列表数据
        itemData: [],
        uploadFiles: [],
        random: 0,
        radio: '',
        imgArr: [], //存放图片
        videoArr: [], //存放视频

        // 弹出框标题
        dialogScoreName: '',
        dialogScoreVisible: false,
        dialogArray: [],
        GrowthConfigs: [], //综合类型集合
      };
    },
    methods: {
      /**
       * @description 打开综合类型弹出框
       * @param {Object} _data
       */
      onOpenTypeInfo(_data) {
        let _this = this;
        _this.dialogScoreName = _data.name;
        _this.dialogArray = [];
        if (_data != null) {
          for (let item of _data.children) {
            if (item.score > 0) {
              _this.dialogArray.push({
                name: item.name,
                score: item.score
              })
            }
          }
        }



        if (_this.dialogArray.length > 0) {
          _this.dialogScoreVisible = true;
        } else {
          info(_this, '未配综合类型');
        }
      },


      /**
       * @description 计算综合分数
       * @param {Object} _data
       * @return {Number} 计算出的总分
       */
      onCalculationScore(_data) {
        let temp = 0;
        if (_data != null && _data.length > 0) {
          for (let item of _data) {
            temp = item.score + temp;
          }
        }
        return temp;
      },


      /**
       * @description 打开放大图片
       */
      onPreview() {
        this.showViewer = true
      },


      /**
       * @description 关闭图片预览图
       */
      closeViewer() {
        this.showViewer = false
      },


      /**
       * @description 返回上一页
       */
      onBack() {
        let that = this;
        back(that);
      },
    },

    filters: {
      flNoValue(obj) {
        if (obj == null || typeof(obj) == "undefined" || obj == '') {
          return '暂无数据';
        }
        return obj;
      }
    },
    created() {
      let that = this;
      Init(that);
    }
  };

  function Init(_this) {
    let Id = _this.$route.params.id;
    _this.fullscreenLoading = true;

    ajaxGet(_this, "/api/admin/selfreport/" + Id, null, function(resData) {
        _this.fullscreenLoading = false;
        _this.itemData = resData;
        _this.uploadFiles = [];
        if (resData.attachment != null && typeof(resData.attachment) != "undefined" && resData.attachment !=
          "") {
          var files = resData.attachment.split('|');
          for (var i in files) {
            _this.uploadFiles.push(_this.$ServerUrl() + files[i]);
          }
        }

    })
  }

  /**
   * @description 递归综合类型数据
   * @param {Object} _this
   * @param {Object} _data 数据组
   */
  function RecursionData(_this, _data) {
    if (_data.length !== 0) {
      _data.forEach((item) => {
        if (item.parentId == "00000000-0000-0000-0000-000000000000") {
          _this.GrowthConfigs.push({
            configId: item.configId,
            name: item.name,
            parentId: item.parentId,
            score: item.score,
            children: _data.filter(val => val.parentId == item.configId)
          });
        }
      });
    }
  }
</script>

<style scoped="scoped">
  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }

  .spIcon {
    width: 5px;
    height: 20px;
    border-radius: 5px;
    background-image: linear-gradient(#4545FF, #2D8EFF);
  }

  .spTitle {
    float: left;
    margin-left: 20px;
    color: #323E56;
    letter-spacing: 2px;
    font-size: 16px;
    margin-top: -3px;
  }



  .divReviewRadio {
    padding: 30px 0px 30px 30px;

  }

  .infoList {
    padding-bottom: 17px;
    padding-left: 30px;
  }

  .infoList .spName {
    font-size: 32px;
    color: #212E48;
    padding-right: 30px;
    height: 40px;
  }


  .infoList .spFonts {
    color: #3B475D;
    padding-right: 30px;
    padding-left: 30px;
    border-right: 1px solid #D6D6D6;
  }

  .infoList .spFonts:first-child {
    padding-left: 0;
  }

  .infoList .spFonts:last-child {
    border-right: none;
  }

  .infoList .spMedal {
    padding-right: 15px;
  }

  .infoList .spFonts .faxSize {
    font-size: 18px;
  }

  .infoList .spFonts .icon {
    color: #FFFFFF;
  }

  .infoList .spStatus {
    border: 1px solid #007BFF;
    background-color: #007BFF;
    color: #FFFFFF;
    padding: 5px 20px;
    border-radius: 5px;

  }

  .infoList .spNStatus {
    border: 1px solid #007BFF;
    background-color: #007BFF;
    color: #FFFFFF;
    padding: 5px 20px;
    border-radius: 5px;
  }

  .infoList .spFonts .spLabel {
    font-size: 14px;
    padding: 2px 5px;
    border-radius: 5px;
    background-color: #B1B1B1;
    color: #FFFFFF;
    margin-right: 10px;
  }

  .infoList .spFonts .spNum {
    padding-left: 10px;
    padding-right: 5px;
    font-family: 'OswaldLight';
    font-size: 16px;
    font-weight: bold;
    color: #DC3545;
  }

  .cardTabs .abtnTabs {
    font-size: 16px;
    color: #B1B1B1;
    margin-left: 15px;
    position: relative;
    display: block;

    float: left;
    text-align: center;
  }

  .cardTabs .spDivide {
    float: left;
    padding-left: 15px;
  }


  .cardTabs .abtnTabs:hover {
    color: #323E56;
  }

  .cardTabs .abtnTabs .spFaxIcon {
    display: none;
  }

  .cardTabs .abtnTabs:first-child {
    margin-left: 0;
  }

  .cardTabs .abtnTabs .spNumber {
    background-color: #DEE2E6;
    font-size: 12px;
    color: #FFFFFF;
    font-family: 'OswaldLight';

    padding: 1px 5px;
    border-radius: 5px;
  }



  .cardTabs .abtnTabAct {
    font-size: 1.1rem;
    color: #323E56;
  }

  .cardTabs .abtnTabAct .spFaxIcon {
    display: block;
    position: absolute;
    top: 22px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 12px;
  }

  .cardTabs .abtnTabAct .spNumber {
    background-color: #1666B3;
    color: #FFFFFF;
    font-size: 14px;
  }



  .bodyImgCont .mailbox-attachment-icon {
    min-height: 165px;
  }

  .bodyFileCont .mailbox-attachments li,
  .bodyImgCont .mailbox-attachments li {
    width: 250px;
  }

  .bodyImgCont .mailbox-attachment-icon {
    height: 198px;
  }

  .bodyFileCont .mailbox-attachment-size .spFont,
  .bodyImgCont .mailbox-attachment-size .spFont {
    font-size: 12px;
  }

  .divVideoCont {
    overflow-y: auto;
    width: 100%;
    height: 500px;
    padding-right: 10px;
  }

  .divVideoCont ul li .aItem {
    display: block;
    padding: 7px 0px;
    padding-left: 30px;
    border-bottom: 1px dotted #DDDDDD;
    position: relative;
  }

  .divVideoCont ul li:last-child .aItem {
    border-bottom: none;
  }

  .divVideoCont ul li:hover {
    background-color: #5AAAFF;
    color: #FFFFFF;
  }

  .divVideoCont ul li .aItem span {
    font-family: 'OswaldLight';
    font-size: 14px;
    margin-left: 10px;
  }

  .divVideoCont ul li .aItem .faxIcon {
    position: absolute;
    top: 11px;
    left: 5px;
    display: none;
  }

  .divVideoCont ul li .aItemAct {
    background-color: #007BFF;
    color: #FFFFFF;
    border-bottom: none;
  }

  .divVideoCont ul li .aItemAct .faxIcon {
    display: block;
  }
</style>


<style>
  .divReviewRadio .el-radio__inner,
  .divReviewRadio .el-radio__label {
    font-size: 16px;
  }
</style>

<!-- 综合类型 -->
<style scoped="scoped">
  .divTagBox {
    padding: -5px;
  }

  .divTagBox .spTab {
    padding: 5px;
  }

  .divTagBox .spScore {
    color: #fa3534;
  }

  .divLiteracyBox {
    padding: 15px;
  }

  .divLiteracyBox .listBox {
    text-align: center;
    padding: 20px;
    float: left;
    border: 2px solid #FFFFFF;
    border-radius: 5px;
  }

  .divLiteracyBox .listBox:hover {
    border: 2px solid #409eff;
    cursor: pointer;
  }

  .divLiteracyBox .literacyName {
    font-size: 24px;
    letter-spacing: 2px;
    padding-bottom: 10px;
  }

  .divLiteracyBox .literacyNum {
    padding-top: 10px;
    font-family: 'OswaldLight';
    border-top: 2px solid #EEEEEE;
    font-size: 32px;
    color: #409eff;
  }
</style>
