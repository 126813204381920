<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <div class="divTipCont">
      <svg class="icon" aria-hidden="true">
        <use xlink:href="#fax-exclamation-triangle-o"></use>
      </svg>
      <div class="divTipFont">
        {{errFont}}
      </div>
    </div>
    <div class="divBottom" v-if="isStatus">
      <el-button @click="onLogin">请通过钉钉应用重新登录</el-button>
    </div>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  export default {
    data() {
      return {
        // 动画
        fullscreenLoading: false,
        isStatus: true,
        errFont: '钉钉认证失败',
      };
    },
    methods: {


    },
    created() {
      let that = this;
      if(!regNull(that.$route.query.logout)){
      	that.errFont="注销成功";
      	that.isStatus=false;
      }
    },
  };
</script>

<style scoped="scoped">
  .divBottom {
    padding-top: 15px;
    text-align: center;
  }

  .divTipCont {
    padding-top: 15%;
    text-align: center;
  }

  .divTipCont .icon {
    font-size: 7.5rem;
    color: #E6A23C;
  }

  .divTipFont {
    font-size: 1rem;
    padding-top: 0.9375rem;
    color: #000000;
  }
</style>
