<!--
  导入
-->
<template>
  <span style="margin-right: 5px;" v-loading.fullscreen.lock="fullscreenLoading">
    <!--
			<el-button type="text" size="medium" @click="dialogVisible = true" class="btn btn-info ml-1 mr-1 pl-2 pr-2 btn-mini" v-html="onGetHtml()"></el-button>
		!-->
    <el-button :type="btnType" :icon="btnIcon" @click="dialogVisible = true" :class="btnClass" :size="btnSize">
      {{btnName}}
    </el-button>

    <!-- 提示导入 -->
    <el-dialog :title="title" :visible.sync="dialogVisible" width="50%" :before-close="handleClose"
      :close-on-click-modal="false" :close-on-press-escape="false" :append-to-body="true" :fullscreen="false">
      <!--弹出框内容-->
      <el-upload v-if="uploadPath!=null && uploadPath.length>0" ref="import" class="upload-demo" :action="uploadPath"
        :limit="uploadFileCount" :before-upload="beforeUpload" :file-list="fileList" :auto-upload="false"
        :http-request="uploadFile" :on-change="handleChange" :before-remove="beforeRemove" :on-exceed="handleExceed">
        <div style="float:left;">
          <el-button size="small" type="primary">点击上传文件</el-button>
        </div>
        <div style="float:left;margin-left: 10px;">
          <div slot="tip" class="el-upload__tip">只允许上传一个xls或xlsx格式的文件，且不超过{{tipSize}}M</div>
        </div>
      </el-upload>
      <div v-else style="font-size:14px;">
        请提供上传模版的路径，参数名：path
      </div>
      <!--弹出框内容-->
      <span slot="footer" class="dialog-footer">
        <div style="float:left;font-size:13px;line-height: 40px;" v-if="templatePath!=null && templatePath.length>0">
          <a :href="templatePath" target="_blank" v-if="isDownload">下载模版</a>
          <el-button type="text" v-else @click="onDownload">下载模版</el-button>
        </div>
        <div v-else style="font-size:14px;float:left;">
          请提供下载模版的路径，参数名：template
        </div>
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitUpload()" v-if="uploadPath!=null && uploadPath.length>0">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 错误原因 -->
    <el-dialog :title="dialogTitleErr" :visible.sync="dialogVisibleErr" width="60%" :close-on-click-modal="false"
      :close-on-press-escape="false" :append-to-body="true" :fullscreen="false">
      <el-table :data="itemDataErr" stripe class="elTableData" border height="500">
        <el-table-column label="行号" class="elColumn" width="160">
          <template slot-scope="scope">
            第 {{(scope.row.index)}} 行
          </template>
        </el-table-column>
        <el-table-column prop="reason" label="错误原因" class="elColumn"></el-table-column>
      </el-table>
    </el-dialog>
  </span>
</template>

<script>
  export default {
    props: {
      //模版路径
      template: {
        type: String,
        default: ''
      },
      //上传路径
      path: {
        type: String,
        default: null
      },
      // 弹出框标题
      title: {
        type: String,
        default: '导入'
      },
      // 导入按钮显示名
      btnName: {
        type: String,
        default: '导入'
      },
      // 导入按钮样式 特殊样式请看下方<style>
      btnClass: {
        type: String,
        default: ''
      },
      // 按钮图标，基于ELEMENT图标
      btnIcon: {
        type: String,
        default: 'el-icon-upload2'
      },
      params: {
        type: Object,
        default: null,
      },
      // 导入按钮尺寸
      btnSize: {
        type: String,
        default: ''
      },
      // 导入按钮颜色类型
      btnType: {
        type: String,
        default: 'info'
      },
      // 是否有下载地址
      isDownload: {
        type: Boolean,
        default: true
      },

    },
    data() {
      return {
        fullscreenLoading: false,
        // 上传文件数量
        uploadFileCount: 1,
        //弹出框属性
        dialogVisible: false,
        //上传组件地址
        fileList: [],
        //上传路径
        uploadPath: null,
        //模版路径
        templatePath: null,

        tipSize: webUploadFileSize(),


        // 导入提示错误
        dialogVisibleErr: false,
        dialogTitleErr: null,
        itemDataErr: [],
      };
    },
    created() {
      let that = this;
      // 判断上传路径是否为空
      if (that.path != null && that.path.length > 0) {
        that.uploadPath = that.path;
      }
      // 判断模版路径是否为空
      if (that.template != null && that.template.length > 0) {
        // 模版放置至前端,文件夹名为public/templates,下载地址路径列如：/templates/成绩导入模版.xlsx
        that.templatePath = that.$ServerUrl() + that.template; //that.$ServerUrl() + that.template;
      }
    },
    computed: {
      /**
       * @description 监控上传API接口地址
       */
      comUploadPath: function() {
        return this.uploadPath;
      }
    },
    watch: {
      // 监控传过来的接口值是否变化
      path: function(newValue, oldValue) {
        this.uploadPath = newValue;
      },
    },
    methods: {
      handleExceed(files, fileList) {
        // let that=this;
        // that.fileList = fileList.slice(0);
        // warning(that,`当前限制选择 ${that.uploadFileCount} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
      },
      beforeRemove(file, fileList) {},
      handleChange(file, fileList) {
        // // this.$refs.import.clearFiles();

      },
      /**
       * @description 关闭弹出框
       * @param {Object} done
       */
      handleClose(done) {
        let that = this;
        this.dialogVisible = false;
      },
      /**
       * @description 确认导入按钮事件
       */
      submitUpload() {
        let that = this;
        that.$refs.import.submit();
      },
      /**
       * @description 通过接口下载模版
       */
      onDownload() {
        let that = this;
        that.$emit('onDownloadFile');
      },
      /**
       * @description 开始上传文件
       * @param {Object} content
       */
      uploadFile(content) {
        let that = this;
        // 接收上传文件的后台地址
        var url = that.uploadPath;
        let files = new FormData();
        files.append('file', content.file);
        if (that.params != null) {
          for (var it of that.params) {
            files.append(it, that.params[it]);
          }
        }
        that.fullscreenLoading = true;
        ajaxImport(that, url, files, function(r) {
          that.fullscreenLoading = false;
          if (r.success >= 0 || r.success == true) {
            if (r.result == true) {
              var it = {
                result: r.result, //导入结果
                total: r.total, //导入记录总数
                success: r.success, //导入成功记录数
              };
              that.$refs.import.abort(content.file);
              that.fileList.splice(that.fileList.indexOf(content.file), 1);
              that.dialogVisible = false;



              that.$emit('onImportSuccess', it);
            } else {
              var it = {
                result: r.result, //导入结果
                total: r.total, //导入记录总数
                error: r.error, //导入错误原因
                message: r.message
              };
              that.$refs.import.abort(content.file);
              that.fileList.splice(that.fileList.indexOf(content.file), 1);
              that.dialogVisible = false;

              if(!regNullArray(it.error)){
                that.dialogVisibleErr = true;
                that.itemDataErr = it.error;
                that.dialogTitleErr = it.message;
              }


              that.$emit('onImportSuccess', it);
            }
          } else {
            var it = {
              result: false,
              message: r.message
            }
            that.$refs.import.abort(content.file);
            that.fileList.splice(that.fileList.indexOf(content.file), 1);
            that.dialogVisible = false;
            that.$emit('onImportSuccess', it);
          }

        });
      },
      /**
       * @description 上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传。
       * @param {Object} file
       */
      beforeUpload(file) {
        let that = this;
        const isXls = file.type === 'application/vnd.ms-excel' || file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        if (!isXls) {
          warning(that, '仅支持xls和xlsx格式的文件上传!');
        }
        const isLt10M = file.size / 1024 / 1024 < webUploadFileSize();
        if (!isLt10M) {
          warning(that, '上传文件大小不能超过 ' + webUploadFileSize() + 'M!');
        }
        return isXls && isLt10M;
      },
    }
  };

</script>

<style lang="less">
  // 无框按钮，一般应用在【更多操作】内
  .btnClass_NoBorder{

      color:#409EFF !important;
      border: none;
      background-color: inherit;
      font-size: 14px;
      text-align: left;
      padding: 0;
      &:hover{
        background-color: inherit;
      }
  }

  .boxBtnImport {
    background-color: transparent !important;
    border:none;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0 !important;
    left: 0;
    z-index: 100;
    width: 100%;
  }
</style>

