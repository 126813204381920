<!--成绩管理-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">

    <!-- 搜索 -->
    <comSearch :ColNumber="4" :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null"></comSearch>

    <!--列表-->
    <el-card class="box-card" v-if="itemData.tdData!=null && itemData.tdData.length!=0">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          学生成绩信息
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <el-button type="success" class="btn btn-info btn-mini">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#fax-sync-alt"></use>
                </svg>
                同步
              </el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData.tdData!=null && itemData.tdData.length!=0">
        <el-table :data="itemData.tdData" stripe class="elTableData">
          <el-table-column type="index" fixed label="序号" width="80"></el-table-column>
          <el-table-column prop="studentNo" fixed label="学号" class="elColumn"></el-table-column>
          <el-table-column prop="studentName" fixed label="姓名" class="elColumn"></el-table-column>
          <el-table-column v-for="(item,count) in itemData.thData" :label="item" :key="count" class="elColumn">
            <template slot-scope="scope">
              <span class="color-gray" v-if="scope.row.scores[count]==''||scope.row.scores[count]==null">暂无数据</span>
              <span v-else>{{scope.row.scores[count]}}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>

    <!--没有数据显示-->
    <el-card class="box-card" v-else>
      <div class="card-body">
        <div class="NoDataTipCont">
          <svg class="icon faxIcon color-warning" aria-hidden="true">
            <use xlink:href="#fax-exclamation-triangle-alt"></use>
          </svg>
          该班级还未有成绩数据，点击
          <el-button type="text" class="btn btn-info ml-1 mr-1 pl-2 pr-2 btn-mini">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#fax-sync-alt"></use>
            </svg>
            同步
          </el-button>
        </div>
      </div>
    </el-card>



  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comSearch from '@/components/search.vue';
  export default {
    components: {
      comSearch
    },
    data() {
      return {
        // 数据加载动画
        fullscreenLoading: false,

        // 搜索数据集
        SearchList: null,

        itemData: {
          thData: null,
          tdData: null,
        },

        arrSearch: {
          semesterId: '',
          classId: '',
          type: '',
        },
      };
    },
    methods: {
      // 获取数据

      // 搜索
      onSearch(params) {
        let _this = this,
          data = {},
          searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = element.data;
          }
        });
        _this.arrSearch = data;
        onGetData(_this);
        //routerPath(_this, "/Admin/Score/Index", data, null);
      },


    },
    filters: {
      filterFraction(_Value) {

        if (_Value == '' || _Value == null) {
          return '暂无数据'
        }
        return _Value;
      }
    },
    created() {
      let _this = this;
      onGetData(_this);
    },
    watch: {
      $route() {
        let _this = this;
        onGetData(_this);
      }
    }

  };



  ///加载列表数据
  // 获取数据
  function onGetData(_this, sda) {

    _this.fullscreenLoading = true;
    ajaxGet(_this, "/api/admin/semester", null, function(resSemester) {
      ajaxGet(_this, "/api/admin/sysuser/userfilternograduate", null, function(resClass) {
        ajaxGet(_this, '/api/admin/academicrecord/type', null, function(resType) {

          //_this.arrSearch=JSON.parse(JSON.stringify(_this.$route.query));

          // 学期ID
          let SemesterId = _this.arrSearch.semesterId;
          // 获得学期数据
          let arrSemester = [];
          for (let item of resSemester) {
            let arrTemp = {
              Title: item.name, //semester[item].name,
              Value: item.semesterId //semester[item].semesterId,
            };
            // 默认学期显示当前学期
            if (SemesterId == null || typeof(SemesterId) == "undefined" || SemesterId == "") {
              if (item.isCurrent == true) {
                SemesterId = item.semesterId;
              }
            }
            arrSemester.push(arrTemp);
          };



          let ClassId = _this.arrSearch.classId;
          // 获得班级数据
          let arrClass = [];
          for (let item of resClass) {
            let arrTemp = {
              Title: item.alias,
              Value: item.classId,
            };
            arrClass.push(arrTemp);
          }
          // 莫扔
          if (ClassId == null || typeof(ClassId) == "undefined" || ClassId == "") {
            ClassId = arrClass[0].Value; //"5d7969c2-ae54-4d2c-9cd5-ce6757e843db"; //arrClass[0].Value;
          }




          let typeId = _this.arrSearch.type;
          let arrType = [];
          for (let item of resType) {
            let arrTemp = {
              Title: item.Text,
              Value: item.Value,
            }
            arrType.push(arrTemp);
          }
          //************************************
          if (typeId == null || typeof(typeId) == "undefined" || typeId == "") {
            typeId = arrType[0].Value;
          }


          //搜索集合
          _this.SearchList = [
            // 学期
            {
              type: "select",
              Name: "学期",
              data: SemesterId,
              select: arrSemester, //选择的数据
              zhi: "semesterId", // 传值
              isClear: false, //select是否可以清除
            },
            // 班级
            {
              type: "select",
              Name: "班级",
              data: ClassId, //_data.classId,
              select: arrClass,
              zhi: "classId" //传值
            },
            // 成绩类型
            {
              type: "select",
              Name: "成绩类型",
              data: typeId, //_data.type,
              select: arrType,
              zhi: "type", //传值
              isClear: false, //select是否可以清除
            },
          ];


          // 接口需要的值
          let getData = {
            SemesterId: SemesterId,
            ClassId: ClassId,
            Type: typeId,
          };


          // 获取列表所需要的值
          ajaxGet(_this, '/api/admin/academicrecord/class', getData, function(resData) {
            _this.itemData.tdData = resData.items;
            _this.itemData.thData = resData.titles;
            _this.fullscreenLoading = false;
          });


        });
      });
    });

  }
</script>

<style scoped="scoped">
  .ruleForm .el-form-item {
    margin-bottom: 5px;
  }
</style>
