<!--
  ECHARTS 拆线统计图
-->
<template>
  <!-- 250px -->
  <div :id="ChartID" :style="pieStyle" style="width: 100%;"></div>
</template>

<script>
  import 'echarts/lib/component/tooltip';
  let echarts = require('echarts/lib/echarts');

  require('echarts/lib/chart/line');
  export default {
    name: 'chart_pie',
    props: {
      // ID
      ChartID: {
        type: String,
        default: 'myChart'
      },
      // 高度
      ChartHeight: {
        type: Number,
        default: 250
      },
      // 颜色数组
      ChartColor: {
        type: Array,
        default: () => {
          return ['#7964E4', '#2291D9', '#73CF43', '#F9BB4A'];
        }
      },
      // X轴标题
      ChartXTitle: {
        type: Array,
        default: () => {
          return [];
        }
      },
      // 轴线名称
      ChartSevName: {
        type: Array,
        default: () => {
          return [];
        }
      },
      // 数据
      ChartData: {
        type: Array,
        default: () => {
          return [];
        }
      },
    },

    data() {
      return {
        pieStyle: '',
        myChart: {}
      };
    },
    created() {
      let that=this;
      if(that.ChartData!=null && that.ChartData.length!=0){
        that.$nextTick(() => {
          that.drawLine();

        })
        that.pieStyle = 'width: 100%; height:' + (that.ChartHeight * 0.0625) + 'rem;';
      }

    },
    mounted() {

      let that = this;
      window.onresize = function() {
        that.myChart.resize();
        that.pieStyle = 'width: 100%; height:' + (this.ChartHeight * 0.0625) + 'rem;';
      }


    },
    methods: {
      // 生成
      onGetRadar(valChartData,valChartSevName,valChartXTitle,valChartColor) {
        let that = this;
        if(valChartData!=null && valChartData.length!=0){
          that.ChartData = valChartData;
          that.ChartSevName=valChartSevName;
          that.ChartXTitle=valChartXTitle;
          that.ChartColor=valChartColor;
          that.$nextTick(() => {
            that.drawLine();
          })
          that.pieStyle = 'width: 100%; height:' + (that.comHeight * 0.0625) + 'rem;';
        }
      },
      drawLine() {
        let _this = this;
        let colorData = _this.ChartColor;
        let xAxisData = _this.ChartXTitle;
        let serverName = _this.ChartSevName;
        let dataArr = _this.ChartData;

        // 基于准备好的dom，初始化echarts实例
        _this.myChart = echarts.init(document.getElementById(_this.ChartID));



        // 绘制图表
        _this.myChart.setOption({
          grid: {
            top: '10%',
            left: '4%',
            right: '4%',
            bottom: '10%'
          },
          tooltip: {
            trigger: 'axis',
            backgroundColor: '#fff',
            textStyle: {
              color: '#5c6c7c',
            },
            padding: [10, 10],
            extraCssText: 'box-shadow: 1px 0 2px 0 rgba(163,163,163,0.5)'
          },
          xAxis: [{
            type: 'category',
            data: xAxisData,
            axisLine: {
              lineStyle: {
                color: "#F3F3F3",
                width: 1,
              },
            },
            axisTick: {
              show: false //不显示刻度
            },
            axisLabel: {
              color: "#939393",
              fontSize: 14,
              interval: 0,
              formatter: function(value) {
                var str = "";
                str += "\n" + value;
                return str;

              },
            },
            boundaryGap: false
          }],
          yAxis: [{
            type: 'value',
            axisLabel: {
              show: true,
              textStyle: {
                fontSize: 14,
                color: "#939393"
              },
            },
            axisLine: {
              show: false
            },
            splitLine: {
              lineStyle: {
                color: "#F3F3F3",
              },
            },
            axisTick: {
              show: false,
            },
          }],
          series: funseries(dataArr, serverName, colorData),
        });



      }
    }
  };


  function funseries(_dataArr, _serverName, _colorData) {
    var serie = [];
    for (var i = 0; i < _dataArr.length; i++) {
      var item = {
        name: _serverName[i],
        type: 'line',
        data: _dataArr[i],
        symbolSize: 1,
        symbol: 'circle',
        //smooth: true,
        yAxisIndex: 0,
        showSymbol: false,
        lineStyle: {
          width: 2,
          shadowColor: _colorData[i],
          shadowBlur: 10,
          shadowOffsetY: 2
        },
        itemStyle: {
          normal: {
            color: _colorData[i],
            borderColor: _colorData[i]
          }
        }
      };
      serie.push(item);
    }
    return serie;
  }
</script>

<style>
</style>
