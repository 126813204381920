<!--
  数据字典 - 处分原因
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          处分原因
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <search :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null"></search>
            </li>
            <li class="nav-item">
              <el-button v-if="isPower.punishReason_create == true" type="success" icon="el-icon-circle-plus-outline"
                @click="onAdd()">新增</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="item!=null && item.length!=0">
        <el-table stripe :data="item" class="elTableData">
          <!-- @cell-click="rowClick" -->
          <!-- <el-table-column type="index" width="120px;" label="序号"></el-table-column> -->
          <el-table-column prop="name" label="处分原因" min-width="400">
            <template slot-scope="scope">
              <span>{{scope.row.name}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="level" label="默认处分级别" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.level!=null">{{scope.row.level.name}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="lastUpdateDateString" label="操作时间" min-width="180"></el-table-column>
          <el-table-column label="操作" width="220px" fixed="right">
            <template slot-scope="scope">
              <operationBtn v-if="isPower.punishReason_detail == true" :btnName="'查看'"
                @click.native="onDetail(scope.row.reasonId)"></operationBtn>
              <operationBtn v-if="isPower.punishReason_edit == true" :btnName="'编辑'"
                @click.native="onEdit(scope.row.reasonId)"></operationBtn>
              <operationBtn v-if="isPower.punishReason_delete == true" :btnName="'删除'"
                @click.native="onDelete(scope.row.reasonId)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>

      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';
  // import comImport from '@/components/Import.vue';
  export default {
    components: {
      comPage,
      operationBtn,
      search,
      // comImport
    },
    data() {
      return {
        fullscreenLoading: false,
        page: null, //分页查询接口返回的结果
        item: [], //列表数据
        pageBtnList: null, //分页左侧按钮集合
        paging: { //分页数据
          pageLength: 0, // 总共页数
          Size: 10, // 当前请求数量
          Index: 1 //当前在第几页
        },
        SearchList: null, //搜索集合
        isPower: {
          //增删改查 权限管理
          punishReason_create: false,
          punishReason_detail: false,
          punishReason_edit: false,
          punishReason_delete: false,
          // punishReason_import: false,
        },
      };
    },
    created() {
      let that = this;
      isPower(that, "punishReason_create", "punishReason_create");
      isPower(that, "punishReason_detail", "punishReason_detail");
      isPower(that, "punishReason_edit", "punishReason_edit");
      isPower(that, "punishReason_delete", "punishReason_delete");
      // isPower(that, "punishReason_import","punishReason_import");
      pagingPage(that);
    },
    methods: {

      onAdd() {
        let that = this;
        that.$router.push({
          path: '/Admin/DataDictionary/Punish/Reason/Create',
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onEdit(Id) {
        let that = this;
        this.$router.push({
          path: "/Admin/DataDictionary/Punish/Reason/Edit/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onDetail(Id) {
        let that = this;
        that.$router.push({
          path: "/Admin/DataDictionary/Punish/Reason/Detail/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onDelete(Id) {
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, "/api/admin/punishReason/" + Id, null, function(r) {
              that.fullscreenLoading = false;
              pagingPage(that);
            });
          }
        })
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      onSearch(params) {
        let that = this;
        let data = {};
        let searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = element.data;
          }
        });
        data.PageNumer = 1;
        routerPath(that, "/Admin/DataDictionary/Punish/Reason", data, null, "PageSize");
      },
      pagingClick(type, val) {
        let that = this;
        let url = "/Admin/DataDictionary/Punish/Reason";
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "Name",

          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "Name",
          );
        }
      },
    },
    watch: {
      $route() {
        let that = this;
        pagingPage(that);
      }
    }
  };

  function pagingPage(that) {
    paging(
      that,
      function(r) {
        let Myurl = "/api/admin/punishReason/search";
        let data = {
          PageNumer: r.PageNumer,
          PageSize: r.PageSize,
          Name: r.Name,
        };

        that.SearchList = [{
          type: "input",
          Name: "处分原因",
          data: r.Name,
          holder: "请输入处分原因",
          zhi: "Name"
        }, ];
        that.fullscreenLoading = true;
        ajaxGet(that, Myurl, data, function(r) {

          that.page = r;
          that.item = r.items;
          that.fullscreenLoading = false;
        });
      },
      "Name",
    );
  }
</script>

<style scoped="scoped">

</style>