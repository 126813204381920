<!--
  班主任手册-行为偏常辅导
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <search :ColNumber="3" :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null">
    </search>
    <el-card class="box-card" v-if="item==''">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          行为偏常辅导
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <el-button v-if="isPower.adviserabnormalbehavior_create == true  && isCurrentTeacher == true"
                type="success" icon="el-icon-circle-plus-outline" @click="onAdd()">新增</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0">
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
    <div class="divBtnCont" v-else>
      <el-button v-if="isPower.adviserabnormalbehavior_create == true && isCurrentTeacher == true" type="success"
        icon="el-icon-circle-plus-outline" @click="onAdd()">新增行为偏常辅导</el-button>
    </div>
    <el-row :gutter="20" v-if="item!=null && item.length!=0">
      <el-col :span="8" v-for="(p,index) in item" :key="index">
        <el-card class="box-card">
          <div slot="header" class="card-header">
            <h3 class="card-title titleCont" style="padding-left: 0px;">
              {{p.studentName}}
            </h3>
            <div class="card-tools">
              <ul class="nav">
                <li class="nav-item">
                  <operationBtn v-if="isPower.adviserabnormalbehavior_edit == true && isCurrentTeacher == true"
                    :btnName="'编辑'" @click.native="onEdit(p.abnormalId)"></operationBtn>
                </li>
                <li class="nav-item">
                  <operationBtn v-if="isPower.adviserabnormalbehavior_delete == true && isCurrentTeacher == true"
                    :btnName="'删除'" @click.native="onDelete(p.abnormalId)"></operationBtn>
                </li>
              </ul>
            </div>
          </div>
          <div class="card-body DetailsFonts min-h-170">
            <div class="contentHidden">

              <div class="spFontTitle">主要偏常行为</div>
              <div>{{onDelHtml(p.abnormalBehavior)}}</div>

            </div>
          </div>
          <div class="card-footer">
            <operationBtn v-if="isPower.adviserabnormalbehavior_details == true" :btnName="'查看'"
              @click.native="onDetail(p.abnormalId)"></operationBtn>
          </div>
        </el-card>
      </el-col>
      <el-col :span="24">
        <comPage :paging="paging" :pageSizes="[12,24,48,96]" @pagingClick="pagingClick">
        </comPage>
      </el-col>
    </el-row>

  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';
  import comPage from '@/components/Page.vue';
  export default {
    name: '',
    components: {
      operationBtn,
      search,
      comPage
    },
    data() {
      return {
        fullscreenLoading: false,
        page: null, //分页查询接口返回的结果
        item: [], //列表数据
        pageBtnList: null, //分页左侧按钮集合
        paging: { //分页数据
          totalCount: 0, // 总共页数
          PageSize: 12, // 当前请求数量
          currentPage: 1 //当前在第几页
        },
        SearchList: null, //搜索集合
        isPower: {
          //增删改查 权限管理
          adviserabnormalbehavior_details: false,
          adviserabnormalbehavior_create: false,
          adviserabnormalbehavior_edit: false,
          adviserabnormalbehavior_delete: false,
          adviserabnormalbehavior_user: false,
        },
        TeacherInfo: {}, // 教师信息
        isCurrentTeacher: true, //历史班主任是否为当前用户
        ManagerClass: [], //班主任管理的班级的集合
      };
    },
    created() {
      let that = this;
      that.TeacherInfo = onGetNowUser(that);
      isPower(that, "adviserabnormalbehavior_details", "adviserabnormalbehavior_details");
      isPower(that, "adviserabnormalbehavior_create", "adviserabnormalbehavior_create");
      isPower(that, "adviserabnormalbehavior_edit", "adviserabnormalbehavior_edit");
      isPower(that, "adviserabnormalbehavior_delete", "adviserabnormalbehavior_delete");
      isPower(that, "adviserabnormalbehavior_user", "adviserabnormalbehavior_user");
      pagingPage(that);
    },
    methods: {
      /**
       * @description 去除HTML和截取字符串，最多140个字符
       * @param {Object} _value
       */
      onDelHtml(_value) {
        let strFont = delHtml(_value);
        if (strFont.length > 140) {
          return strFont.substring(0, 140) + '...';
        } else {
          return strFont;
        }
      },
      onDetail(Id) {
        let that = this;
        that.$router.push({
          path: "/Admin/Adviser/AbnormalBehavior/Details/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onDelete(Id) //删除
      {
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, "/api/admin/adviserabnormalbehavior/" + Id, null, function(r) {
              that.fullscreenLoading = false;
              pagingPage(that);
            });
          }
        })
      },
      onEdit(Id) {
        let that = this;
        that.$router.push({
          path: "/Admin/Adviser/AbnormalBehavior/Edit/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onAdd() {
        let that = this;
        that.$router.push({
					path: '/Admin/Adviser/AbnormalBehavior/Create',
					query: {
					  url: that.$router.history.current.fullPath
					}

				});
      },
      onSearch(params) {
        let that = this;
        let data = {};
        let searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = Join(element.data);
          }
        });
        data.radom = Math.random(1000)
        data.PageNumer = 1;
        data.PageSize = that.paging.pageSize;
        routerPath(that, "/Admin/Adviser/AbnormalBehavior/Index", data, null, "PageSize");
        //判断查询的班级是否为班主任管理的班级
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      pagingClick(type, val) {
        let that = this;
        let url = "/Admin/Adviser/AbnormalBehavior/Index?PageSize=" + that.paging.pageSize;
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "SemesterId",
            "ClassId",
            "Student"
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "SemesterId",
            "ClassId",
            "Student"
          );
        }
      },
    },
    watch: {
      $route() {
        let that = this;
        pagingPage(that);
      }
    }
  };

  function pagingPage(that) {
    paging(
      that,
      function(r) {
        if (regNull(r.PageSize)) {
          r.PageSize = 12;
        }

        that.fullscreenLoading = true;
        ajaxGet(that, "/api/admin/Semester", null, function(semester) {
          if(!regNullArray(semester)) {
            let resultClass = that.TeacherInfo.isTeacher ? that.TeacherInfo.manageClass : that.TeacherInfo.allClass;
            let Myurl = that.TeacherInfo.isTeacher ? "/api/admin/adviserabnormalbehavior/search" : "/api/admin/adviserabnormalbehavior/user/search";

            var SemesterId = r.SemesterId;
            var semesters = [];
            for (var i in semester) {
              var it = {
                Title: semester[i].name,
                Value: semester[i].semesterId,
              };
              if (regNull(SemesterId)) {
                if (semester[i].isCurrent == true) {
                  SemesterId = semester[i].semesterId;
                }
              }
              semesters.push(it);
            }

            var ClassId = r.ClassId;
            var classList = [];

            if (regNull(ClassId) && !regNullArray(resultClass)) {
              ClassId = resultClass[0].classId;
            }

            for (var i in resultClass) {
              classList.push({
                Title: resultClass[i].alias,
                Value: resultClass[i].classId,
              });
            }


            that.SearchList = [{
                type: "select",
                Name: "学期",
                data: SemesterId,
                select: semesters, //选择的数据
                zhi: "SemesterId",
                isClear: false, //select是否可以清除
                resetData: semesters[0].Value,//重置数据默认第一个
              },
              {
                type: "select",
                Name: "班级",
                data: ClassId,
                select: classList,
                zhi: "ClassId",
                isClear: false, //select是否可以清除
                resetData: resultClass[0].classId,//重置数据默认第一个
              },
              {
                type: "input",
                Name: "姓名",
                data: r.Student,
                holder: "请输入学号/姓名",
                zhi: "Student"
              },
            ];

            let data = {
              PageNumer: r.PageNumer,
              PageSize: r.PageSize,
              SemesterId: SemesterId,
              ClassId: ClassId,
              Student: r.Student
            };
            ajaxGet(that, Myurl, data, function(r1) {
              that.paging = r1;
              that.item = r1.items;
              var name = JSON.parse(that.$GetKey("Name"));
              that.isCurrentTeacher = true;
              if (that.item != '') {
                if (that.item[0].adviser != name) {
                  that.isCurrentTeacher = false;
                }
              }
              that.fullscreenLoading = false;
            });

          }else{
            that.fullscreenLoading = false;
          }

        });
      },
      "SemesterId",
      "ClassId",
      "Student"
    );
  }

</script>

<style scoped="scoped">
  .contentHidden {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
  }

  .divBtnCont {
    text-align: right;
    padding-bottom: 0.9375rem;
    /*15px*/
  }

</style>

