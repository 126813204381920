<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="ruleForm">

      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{currentTitle}}
          </h3>
        </div>
        <div class="card-body tabListCont ">
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="项目名称" prop="reasonId">
                <el-select v-model="ruleForm.reasonId" placeholder="请选择" class="width_100Pie">
                  <el-option v-for="item in optReason" :key="item.reasonId" :label="item.name" :value="item.reasonId" @change="onChangeReason">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="分数" prop="score">
                <el-input v-model="ruleForm.score" placeholder="请输入分数" class="width_100Pie">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="班级" prop="classId">
                <el-select v-model="ruleForm.classId" filterable @change="onChangeClass" placeholder="请选择"
                  class="width_100Pie">
                  <el-option v-for="item in optClass" :key="item.classId" :label="item.alias" :value="item.classId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="班主任">
                <el-input class="width_100Pie" placeholder="请先选择班级" v-model="ruleForm.teacher" :disabled="true">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="记录时间" prop="logDate">
                <el-date-picker type="date" class="width_100Pie" v-model="ruleForm.logDate" value-format="yyyy-MM-dd"
                  placeholder="选择行为时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备注" prop="remark">
                <el-input class="formControl" v-model="ruleForm.remark" type="textarea" :rows="4" show-word-limit
                  placeholder="请输入备注" maxlength="500">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>


    </el-form>


    <div class="row" style="margin-top: 10px;">
      <div class="col-12 ">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
        <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
        <el-button type="primary" icon="el-icon-circle-check" @click="onSave()">提交</el-button>
      </div>
    </div>


  </div>
</template>

<script>
  import '@/assets/css/Admin.css';

  export default {
    components: {},
    data() {
      return {
        // 加载动画
        fullscreenLoading: false,
        // 页面标题
        currentTitle: "新增班主任月考核加减分项",

        // 表单对象
        ruleForm: {
          classId: null,
          teacherId: null,
          reasonId: null,
          score: 0,
          remark: null,
          logDate: null,
          teacher: null,
          logId:null,
        },
        ruleFormReset: null,
        // 验证
        rules: {
          reasonId: [{
            required: true,
            message: '请选择项目原因',
            trigger: 'change'
          }, ],
          score: [{
            required: true,
            message: '请输入分数',
            trigger: 'change'
          }, {
            validator: (rule, value, callback) => {
              let _this = this;
              var reg = /^(\-|\+)?\d+(\.\d{1,2})?$/;
              if (value != 0 && !reg.exec(value)) {
                callback(new Error("分数只能输入带1-2位小数的正数或负数！"));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }],
          logDate: [{
            required: true,
            message: '请选择记录时间',
            trigger: 'change'
          }, ],
          teacherId: [{
            required: true,
            message: '请选择班主任',
            trigger: 'change'
          }, ],
          classId: [{
            required: true,
            message: '请选择班级',
            trigger: 'change'
          }, ],
          remark: [{
            min: 0,
            max: 500,
            message: '长度在 500 个字符内',
            trigger: 'change'
          }, ],
        },
        optReason: [],
        optClass: [],
        optTeacher: [],
      };
    },
    created() {
      let that = this;
       if (!regNull(that.$route.params.Id)) {
         that.ruleForm.logId=that.$route.params.Id;
         that.currentTitle='编辑月度考核加减分项';
         that.onEditData(that);
       }else{
         that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
         that.onGetData(that);
       }
    },
    methods: {
      /**
       * @description
       * @param {Object} objVal
       */
      onChangeReason(objVal) {
        let that=this;
        let temp=that.optOldReason.find(item=> item.reasonId==objVal);
        if(temp!=undefined){
          that.ruleForm.score=temp.score;
        }
      },
      /**
       * @description 编辑数据
       * @param {Object} that
       */
      onEditData(that){
        that.fullscreenLoading = true;
        ajaxGet(that, '/api/admin/advisermonthlyreason', null, function(resReason) {
          ajaxGet(that, '/api/admin/sysuser/userfilternograduate', null, function(resClass) {
            that.optReason = resReason;
            that.optClass = resClass;
            ajaxGet(that,'/api/admin/advisermonthlylog/'+that.ruleForm.logId,null,function(resData){
              that.ruleForm=resData;
              that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
            });
            that.fullscreenLoading = false;
          });
        });
      },
      /**
       * @description 获取数据
       * @param {Object} that
       */
      onGetData(that) {
        that.fullscreenLoading = true;
        ajaxGet(that, '/api/admin/advisermonthlyreason', null, function(resReason) {
          ajaxGet(that, '/api/admin/sysuser/userfilternograduate', null, function(resClass) {
            that.optReason = resReason;
            that.optClass = resClass.filter(item =>item.teacherId);
            that.fullscreenLoading = false;
          });
        });
      },
      /**
       * @description 选择班级事件
       * @param {Object} objID
       */
      onChangeClass(objID) {
        let that = this;
        let temp = that.optClass.find(item => item.classId == objID);
        if (temp != undefined) {
          if (!regNull(temp.teacherId)) {
            that.ruleForm.teacher = temp.teacher;
            that.ruleForm.teacherId = temp.teacherId;
          } else {
            that.ruleForm.teacher = '暂无班主任';
            that.ruleForm.teacherId = null;
          }
        }
      },

      /**
       * @description 返回
       * @return {void}
       */
      onBack() {
        let that = this;
        back(that);
      },

      /**
       * @description 提交事件
       * @return {void}
       */
      onSave() {
        let that = this;
        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            that.fullscreenLoading=true;
            that.ruleForm.score=setTwoDecimal(that.ruleForm.score,1);
            that.ruleForm.logDate=timeTransform(that.ruleForm.logDate);
            if(regNull(that.ruleForm.logId)){
              ajaxPost(that,'/api/admin/advisermonthlylog',that.ruleForm,function(res){
                that.fullscreenLoading=false;
                back(that);
              });
            }else{
              ajaxPut(that,'/api/admin/advisermonthlylog/'+that.ruleForm.logId,that.ruleForm,function(res){
                that.fullscreenLoading=false;
                back(that);
              });
            }
          } else {
            var isError = document.getElementsByClassName("is-error");
          }
        });
      },

      /**
       * @description 重置事件
       * @return {void}
       */
      onReset() {
        let that = this;
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },



    }
  };


</script>

<style scoped="scoped">
  .nav-item {
    padding: 0 10px;
  }

  .divDialogBox {
    margin: 0 auto;
    width: 80%;
  }

  .scoreBox .spLabel {
    padding-left: 2px;
    padding-right: 2px;
    font-weight: bolder;
  }

  .scoreBox .itemList {
    padding-top: 5px;
    padding-bottom: 5px;
  }


  .stuInfo {
    position: relative;
    height: 100%;
    min-height: 80px;
    margin: 15px;
  }

  .stuInfo .stuInfoBox {
    display: flex;
  }

  .stuInfo .divInfoBox {
    padding-left: 10px;
  }


  .stuInfo .stuImgCont {
    width: 70px;
    height: 70px;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #DDDDDD;
  }



  .stuInfo .stuName {
    color: #212E48;
    font-size: 18px;
    font-weight: bold;
  }

  .stuInfo .stuRole {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
  }

  .stuInfo .stuRoom {
    font-size: 12px;
    color: #767676;
  }

  .stuInfo .stuOther {
    color: #767676;
    font-size: 12px;
  }

  .stuInfo .stuRemove {
    display: none;
  }

  .stuInfo:hover .stuRemove {
    display: block;
    position: absolute;
    top: -0.9375rem;
    left: -0.625rem;
    font-size: 22px;
    cursor: pointer;
  }


  .transferBox {
    margin: 0 auto;
  }

  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }

  .tabListCont .divNavTitle {}

  .tabListCont .divNavTitle {}

  .tabListCont .divNavTitle .divTitleCont {
    padding: 10px 0px 10px 10px;
    color: #DEE2E6;
    cursor: pointer;
    font-size: 18px;
  }

  .tabListCont .divNavTitle .divTitleCont .spNum {
    background-color: #DEE2E6;
    font-family: 'OswaldLight';
    padding: 2px 5px;
    border-radius: 4px;
    margin-left: 5px;
    font-size: 14px;
    color: #FFFFFF;
  }

  .tabListCont .divNavTitle .divTitleAct {
    color: #3e3e3e;
  }

  .tabListCont .divNavTitle .divTitleAct .spNum {
    background-color: #17A2B8;
    color: #FFFFFF;
  }

  .tabListCont .divNavTitle .nav-link {
    font-size: 14px;
    color: #B1B1B1;
  }

  .tabListCont .divNavTitle .nav-link .spNum {
    font-family: 'OswaldLight';
    font-weight: normal;
    font-size: 14px;
    padding-left: 10px;
  }

  .tabListCont .divNavTitle .active {
    color: #007bff;
  }

  .divAlertCont {
    padding-left: 30px;
    padding-top: 10px;
    color: #9595A0;
  }
</style>

<style>
  .transferCont .el-transfer__buttons {
    width: 20%;
  }

  .transferCont .el-transfer__buttons .el-transfer__button:first-child {
    float: left;
  }

  .transferCont .el-transfer__buttons .el-transfer__button:last-child {
    float: right;
  }

  .transferCont .el-transfer-panel {
    width: 40%;
  }

  .formGroupCont .el-date-editor--datetimerange.el-input__inner {
    width: 100%;
  }

  .stuInfo .stuImgCont .image-slot {
    font-size: 28px;
    color: #DDDDDD;
  }
</style>
