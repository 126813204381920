<template>
  <div id="topLeftCmp">
    <div class="divTotal">
      <div class="divTitle">全校总分</div>
      <div class="divTotalNo">
        {{nowTotal}}
      </div>
      <dv-decoration-3 class="dvDecoThr" />
    </div>
    <div class="divSubBox clearfix" v-if="!regNullArray(configData)">
      <div class="digital-flop-item" v-for="(item,index) in configData" :key="index">
        <div class="divIconBox VerticalMid" :class="'color'+(index+1)">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#fax-star-up-copy"></use>
          </svg>
        </div>
        <div class="divInfo">
          <div class="digital-flop">
            {{item.score}}
            <span class="unit">分</span>
          </div>
          <div class="digital-flop-title">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="divNullData" v-else>
      <dv-loading>Loading...</dv-loading>
    </div>



  </div>
</template>

<script>
  export default {
    name: 'DigitalFlop',
    data() {
      return {
        configData: [],
        nowTotal: 0,

      }
    },
    methods: {
      /**
       * @description 加载数据
       * @param {Object} objData
       */
      onCreateData(objData) {
        let that = this;
        if (!regNull(objData)) {
          that.nowTotal = objData.scoreSchool;
          that.configData = objData.config;
        }
      },
    },
    mounted() {

    }
  }
</script>

<style lang="less" scoped>
  #topLeftCmp {
    .divNullData {
      padding-top: 5%;
    }

    .color1 {
      background-color: #f46827 !important;
    }

    .color2 {
      background-color: #4d99fc !important;
    }

    .color3 {
      background-color: #133dff !important;
    }

    .color4 {
      background-color: #f94f76 !important;
    }

    .color5 {
      background-color: #f0cb56 !important;
    }

    .color6 {
      background-color: #36cce7 !important;
    }

    .color7 {
      background-color: #f46827 !important;
    }


    .divTotal {
      padding-top: 20px;
      padding-left: 20px;
      padding-right: 20px;
      position: relative;

      .divTitle {
        font-size: 18px;
        color: #fff;
        font-weight: bolder;
      }

      .divTotalNo {
        text-align: left;
        font-size: 62px;
        color: #00c0ff;
      }

      .dvDecoThr {
        margin-top: 0px;
        margin-bottom: 5px;
      }
    }

    .divSubBox {
      margin-left: 15px;
      margin-right: 15px;
      padding-top: 15px;
      padding-left: 15px;
      padding-right: 15px;

      .digital-flop-item {
        width: 49.9%;
        float: left;
        display: flex;
        margin-bottom: 15px;

        .divIconBox {
          width: 50px;
          height: 50px;
          background-color: #2e6099;
          border-radius: 5px;

          .icon {
            font-size: 28px;
          }
        }

        .divInfo {
          margin-left: 8px;

          .digital-flop-title {
            color: #fff;
            margin-top: -5px;
          }

          .digital-flop {
            font-size: 24px;
            color: #00c0ff;
            font-weight: bold;

            .unit {
              font-size: 22px;
              font-weight: normal;
            }
          }
        }
      }
    }
  }
</style>
