<template>
  <div id="data-view">


    <dv-full-screen-container v-if="isLoading">
      <dv-loading>Loading...</dv-loading>
    </dv-full-screen-container>

    <dv-full-screen-container v-else>

      <!-- 头部标题、时间、学期 -->
      <topHeader :comSemesterName="semesterName"></topHeader>

      <div class="dataBody">
        <!-- 左侧 -->
        <div class="divWebLeft">
          <!-- 总分 -->
          <div class="divTotalBox">
            <dv-border-box-12 class="left-chart-container">
              <Top-Left-Cmp ref="comTopLeftCmp" />
            </dv-border-box-12>
          </div>
          <!-- 学生评价动态 -->
          <div class="divLeftBottom">
            <comEvaluate ref="comEvaluate" />
          </div>
        </div>
        <!-- 中间 -->
        <div class="divWebMid">
          <!-- 统计图 -->
          <div class="midTopBox">
            <TopMiddleCmp ref="TopMiddleCmp" />
          </div>
          <!-- 按综合类型学生排行 -->
          <div class="divMidBottom">
            <comStudentRank ref="comStudentRank" />
          </div>
        </div>
        <!-- 右侧 -->
        <div class="divWebRight">
          <!-- 各年级班级总分排行 -->
          <comClassRank ref="comClassRank" />
        </div>
      </div>

    </dv-full-screen-container>
  </div>
</template>

<script>
  import TopLeftCmp from './TopLeftCmp';
  import TopMiddleCmp from './TopMiddleCmp';
  import comClassRank from './ClassRank';
  import comEvaluate from './Evaluate';
  import comStudentRank from './StudentRankType';
  import topHeader from './topHeader';
  export default {
    components: {
      topHeader,
      TopLeftCmp,
      TopMiddleCmp,
      comClassRank,
      comEvaluate,
      comStudentRank,
    },
    props: {
      comData: {
        type: Array,
        default: () => []
      },
    },
    data() {
      return {
        isLoading: true,
        // 学期名称
        semesterName: null,
        // 当前时间
        nowDateTime: '',
        tabTtimer: null,

        itemParams: null,
      }
    },
    created() {
      let that = this;
      setTimeout(function() {
        that.isLoading = false;
        return false;
      }, 1500);


    },
    mounted() {
      let that = this;
      that.tabTtimer = setInterval(() => {
        if (!regNull(that.itemParams)) {
          that.onGetBigScreen(that.itemParams);
        }
      }, 600000);
    },
    methods: {
      /**
       * @description 加载
       * @param {Object} objData
       */
      onGetBigScreen(objData) {
        let that = this;
        if (!regNull(objData)) {
          that.itemParams = objData;
          that.semesterName = objData.Name;
          ajaxGet(that, '/api/admin/statistics/bigscreen', objData, function(resData) {

            if (!regNull(resData)) {
              // 总分、类型总分
              if (!regNullArray(resData.configData)) {
                that.$refs.comTopLeftCmp.onCreateData({
                  scoreSchool: setTwoDecimal(resData.totalScore, 2),
                  config: resData.configData.map(item => ({
                    name: item.configName,
                    score: setTwoDecimal(item.score, 2)
                  }))
                });
              }

              // 全校学生综合总分班级排行
              that.$refs.comClassRank.onCreateData(resData.collegeData);

              // 折线统计图
              that.$refs.TopMiddleCmp.onGetRadar(resData.chartData);

              // 评价动态
              that.$refs.comEvaluate.onCreateData(resData.logData);

              // 综合类型排行榜
              that.$refs.comStudentRank.onCreateData(resData.configData);
            }

          });
        }

      },
      onStopTime() {
        let that=this;
        clearInterval(that.tabTtimer);
        that.$refs.comStudentRank.onStopTime();
      },

    }
  }
</script>

<style lang="less">
  #data-view {
    width: 100%;
    height: 100%;
    background-color: #030409;
    color: #fff;


    .dataBody {
      display: flex;
      height: calc(~"100% - 100px");
    }

    .divWebLeft {
      width: 490px;
      padding-left: 10px;

      .divTotalBox {
        width: 100%;
        height: calc(~"100% - 640px");

        .divTop {
          width: 100%;
          height: 400px;
        }
      }

      .divLeftBottom {
        margin-top: 10px;
        width: 100%;
        height: calc(~"100% - 360px");
      }
    }

    .divWebMid {
      width: 940px;

      .midTopBox {
        width: 100%;
        height: calc(~"100% - 490px");
      }

      .divMidBottom {
        width: 100%;
        height: 480px;
        padding-top: 10px;
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    .divWebRight {
      height: calc(~"100% - 10px");
      width: 490px;
    }

    #dv-full-screen-container {
      background-image: url('./img/bg.png');
      background-size: 100% 100%;
      box-shadow: 0 0 3px blue;
      display: flex;
      flex-direction: column;
    }



  }

  .border {
    border: none !important;
  }
</style>
