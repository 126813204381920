<!--
  学生管理-学生奖励
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <search :ColNumber="2" :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null">
    </search>
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          班主任月考核加减分项
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <!--导入-->
              <comImport @onImportSuccess="onImportSuccess" :template="'/templates/班主任月考核加减分导入模版.xlsx'"
                :path="uploadInfoApi" :btnIcon="'el-icon-upload2'" :title="'批量导入班主任月考核加减分 '" :btnName="'导入'"
                ref="conImportInfoFile" v-if="isPower.admin_month_plusminus_import">
              </comImport>
            </li>
            <li class="nav-item">
              <el-button type="success" v-if="isPower.admin_month_plusminus_create" icon="el-icon-circle-plus-outline"
                @click="onCreate">新增</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table stripe :data="itemData" class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod">
          </el-table-column>
          <el-table-column prop="teacher" label="班主任" min-width="100" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="className" label="班级" min-width="120" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="reason" label="原因" min-width="200" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="score" label="分数" min-width="80"></el-table-column>
          <el-table-column prop="logDate" label="时间" width="130">
            <template slot-scope="scope">
              {{scope.row.logDate | dateformatDay}}
            </template>
          </el-table-column>
          <el-table-column prop="auditStatusStr" label="审核状态" width="100"></el-table-column>
          <el-table-column label="操作" :width="thWidth" fixed="right">
            <template slot-scope="scope">
              <div v-if="isPower.admin_month_plusminus_audit">
                <operationBtn :btnName="'审核'" v-if="isPower.admin_month_plusminus_audit && scope.row.auditStatus==0"
                  @click.native="onAudit(scope.row.logId)"></operationBtn>
                <operationBtn :btnName="'查看'" v-if="isPower.admin_month_plusminus_details && scope.row.auditStatus!==0"
                  @click.native="onDetail(scope.row.logId)"></operationBtn>
              </div>
              <div v-else>
                <operationBtn :btnName="'查看'" v-if="isPower.admin_month_plusminus_details"
                  @click.native="onDetail(scope.row.logId)"></operationBtn>
                <operationBtn :btnName="'编辑'" v-if="isPower.admin_month_plusminus_edit"
                  @click.native="onEdit(scope.row.logId)"></operationBtn>
                <operationBtn :btnName="'删除'" v-if="isPower.admin_month_plusminus_delete"
                  @click.native="onDelete(scope.row.logId)"></operationBtn>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>

  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';
  import comImport from '@/components/Import.vue';

  export default {
    name: '',
    components: {
      comImport,
      comPage,
      operationBtn,
      search
    },
    data() {
      return {
        fullscreenLoading: false,
        dialogTitle: '',
        dialogVisible: false,
        page: null, //分页查询接口返回的结果
        itemData: [], //列表数据
        pageBtnList: null, //分页左侧按钮集合
        paging: { //分页数据
          pageLength: 0, // 总共页数
          Size: 10, // 当前请求数量
          Index: 1 //当前在第几页
        },
        uploadInfoApi: '/api/admin/advisermonthlylog/import',
        SearchList: null, //搜索集合
        isPower: {
          admin_month_plusminus_create: false,
          admin_month_plusminus_delete: false,
          admin_month_plusminus_edit: false,
          admin_month_plusminus_details: false,
          admin_month_plusminus_audit: false,
          admin_month_plusminus_import: false,
        },
        thWidth: 100,

      };
    },
    created() {
      let that = this;
      isPower(that, 'admin_month_plusminus_import', 'admin_month_plusminus_import');
      isPower(that, 'admin_month_plusminus_create', 'admin_month_plusminus_create');
      isPower(that, 'admin_month_plusminus_delete', 'admin_month_plusminus_delete');
      isPower(that, 'admin_month_plusminus_edit', 'admin_month_plusminus_edit');
      isPower(that, 'admin_month_plusminus_details', 'admin_month_plusminus_details');
      isPower(that, 'admin_month_plusminus_audit', 'admin_month_plusminus_audit')
      pagingPage(that);

      if (that.isPower.admin_month_plusminus_audit) {
        that.thWidth = onGetWidth(that, [
          that.isPower.admin_month_plusminus_audit,
          that.isPower.admin_month_plusminus_details
        ]);
      } else {
        that.thWidth = onGetWidth(that, [
          that.isPower.admin_month_plusminus_details,
          that.isPower.admin_month_plusminus_edit,
          that.isPower.admin_month_plusminus_delete
        ]);
      }
    },
    methods: {
      /**
       * @description 新增
       */
      onCreate() {
        let that = this;
        that.$router.push({
          path: "/Admin/Assessment/PlusMinus/Create",
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },
      //导入成功后
      onImportSuccess(param) {
        let that = this;
        pagingPage(that);
      },
      /**
       * @description 详情
       * @param {Object} Id
       */
      onDetail(Id) {
        let that = this;
        that.$router.push({
          path: "/Admin/Assessment/PlusMinus/Details/" + Id,
          query: {
            url: that.$router.history.current.fullPath,
            isAudit: false
          }
        });
      },
      onAudit(Id) {
        let that = this;
        that.$router.push({
          path: "/Admin/Assessment/PlusMinus/Details/" + Id,
          query: {
            url: that.$router.history.current.fullPath,
            isAudit: true
          }
        });
      },
      /**
       * @description 编辑
       * @param {Object} Id
       */
      onEdit(Id) {
        let that = this;
        that.$router.push({
          path: "/Admin/Assessment/PlusMinus/Edit/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      /**
       * @description 删除
       * @param {Object} objID
       */
      onDelete(objID) {
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, "/api/admin/advisermonthlylog/" + objID, null, function(r) {

              that.fullscreenLoading = false;
              pagingPage(that);
            });
          }
        })

      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      onSearch(params) {
        let that = this,
          data = {},
          searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = Join(element.data);
          }
        });
        data.PageNumer = 1;
        routerPath(that, "/Admin/Assessment/PlusMinus/Index", data, null, "PageSize");
      },
      pagingClick(type, val) {
        let that = this;
        let url = '/Admin/Assessment/PlusMinus/Index';
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "txtTime",
            "TeacherName",
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "txtTime",
            "TeacherName",
          );
        }
      },
    },
    watch: {
      $route() {
        let that = this;
        pagingPage(that);
      }
    }
  };

  function pagingPage(that) {
    paging(
      that,
      function(res) {
        that.SearchList = [];
        that.SearchList.push({
          type: "time",
          Name: "时间",
          data: res.txtTime,
          holder: "请输入时间",
          zhi: "txtTime",
        }, {
          type: "input",
          Name: "班主任",
          data: res.TeacherName,
          holder: "请输入班主任姓名",
          zhi: "TeacherName"
        });
        let txtTime = res.txtTime != null ? res.txtTime.split(',') : null;
        let LogDateStart = txtTime != null ? txtTime[0] : null;
        let LogDateEnd = txtTime != null ? txtTime[1] : null;
        let tempData = {
          PageNumer: res.PageNumer,
          PageSize: res.PageSize,
          TeacherName: res.TeacherName,
          LogDateStart: LogDateStart,
          LogDateEnd: LogDateEnd
        }
        that.itemData = [];
        ajaxGet(that, '/api/admin/advisermonthlylog/search', tempData, function(resData) {
          that.fullscreenLoading = false;
          if (!regNullArray(resData.items)) {
            that.page = resData;
            that.itemData = resData.items;
          }
        });
      },
      "txtTime",
      "TeacherName",
    );
  }

</script>

<style scoped="scoped">
</style>

