
<template>
  <div class="SubWebPage page" v-loading.fullscreen.lock="fullscreenLoading">


    <el-card class="card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="width:100%;padding-left: 0px;"
          v-if="!regNullArray(optTabs)">
          <ul class="nav nav-pills navTabsCont">
            <li class="nav-item" v-for="(item,index) in optTabs" :key="index" @click="onGetTabs(index)">
              <span :class="index==currentTabs?'nav-link active':'nav-link'">
                 {{item.name}}
              </span>
            </li>
          </ul>

          <div class="btnAddBox">
            <el-button type="success"  size="small" @click="onCreate()">学生加分</el-button>
          </div>
        </h3>
      </div>
      <div class="card-body">
        <el-row :gutter="24">
          <el-col :span="8" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">学期</span>
            </div>
            <div class="selBodyBox">
              <el-select v-model="arrSearch.strSemester" style="width: 100%;" placeholder="请选择"
                @change="onChangeSemester">
                <el-option v-for="(item,index) in arrSearch.optSemester" :key="index" :label="item.name"
                  :value="item.semesterId">
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="8" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">班级</span>
            </div>
            <div class="selBodyBox">
              <el-select v-model="arrSearch.strClass" style="width: 100%;" placeholder="请选择" filterable>
                <el-option v-for="(item,index) in arrSearch.optClass" :key="index" :label="item.alias"
                  :value="item.classId">
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="8" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">学生</span>
            </div>
            <div class="selBodyBox">
              <el-input v-model="arrSearch.Student" placeholder="请输入学号/姓名"></el-input>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="card-footer text-center">
        <el-button type="primary" icon="el-icon-search" @click.native="onSearch()">查询</el-button>
        <el-button icon="el-icon-refresh" @click.native="onResetSearch()">重置</el-button>
      </div>
    </el-card>


    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          列表信息
        </h3>
      </div>
      <div class="card-body p-0" v-if="!regNullArray(itemData)">
        <el-table :data="itemData" stripe class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="70"></el-table-column>
          <el-table-column prop="studentNo" label="学号" class="elColumn" min-width="120" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="studentName" label="姓名" class="elColumn" min-width="120" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="class" label="班级" class="elColumn" min-width="120" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="bonusTypeStr" label="职务" class="elColumn" min-width="120" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="score" label="分数" class="elColumn" min-width="120" :show-overflow-tooltip="true">
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>


    <el-dialog title="学生加分" :visible.sync="isDialogCreate" width="40%">
      <div class="dialogBody">
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="colTitleBox">
              {{createTitle}}
            </div>
            <comLiteracyConfig :configList='optConfigs' :defaultConfig ='optDefaultConfigs' ref="comConfig_classcadre" @change="handleTypesChange" :key="keyGUID"></comLiteracyConfig>
          </el-col>
<!--          <el-col :span="8">
            <div class="colTitleBox">
              学生会
            </div>
            <comLiteracyConfig :configList='optConfigs' :defaultConfig ='optDefaultConfigs' ref="comConfig_studentunion" @change="handleTypesChange2" :key="keyGUID"></comLiteracyConfig>
          </el-col>
          <el-col :span="8">
            <div class="colTitleBox">
              寝室长
            </div>
            <comLiteracyConfig :configList='optConfigs' :defaultConfig ='optDefaultConfigs' ref="comConfig_dormitoryhead" @change="handleTypesChange3" :key="keyGUID"></comLiteracyConfig>
          </el-col> -->
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isDialogCreate = false">取 消</el-button>
        <el-button type="primary" @click="onSave()">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import comLiteracyConfig from '@/components/Admin/LiteracyConfigScore.vue';

  export default {
    components: {
      comPage,
      comLiteracyConfig
    },
    data() {
      return {
        // 弹出框
        isDialogCreate:false,

        fullscreenLoading: false,
        // 搜索数据集
        SearchList: null,
        currentTabs:0,
        // 列表数据
        itemData: null,
        BonusType: 1,
        createTitle:'班干部',
        // 搜索数据集
        arrSearch: {
          // 学期
          strSemester: '',
          semesterId: '',
          optSemester: [],
          // 班级
          strClass: '',
          classId: '',
          optClass: [],
          // 学生
          Student: null,
        },
        arrSearchReast:null,
        // 综合
        optConfigs:[],
        optDefaultConfigs: [],
        optCheckConfigsCadre: [],//班干部
        optCheckConfigsUnion: [],//学生会
        optCheckConfigsDorm: [],//寝室长
        keyGUID:onGetRandomGUID(),

        isPower: {
          //增删改查 权限管理
          admin_extrapoints_classcadre: false,
          admin_extrapoints_dormitoryhead: false,
          admin_extrapoints_studentunion: false,
        },
        page: null, //分页查询接口返回的结果
        pageBtnList: null, //分页左侧按钮集合
        paging: { //分页数据
          pageLength: 0, // 总共页数
          Size: 10, // 当前请求数量
          Index: 1 //当前在第几页
        },
      };
    },

    methods: {
      /**
       * @description 选择综合事件
       * @param {@} objItem
       * @param {*} objValID
       */
      handleTypesChange(objItem, objValID) {
        let that = this;
        if (!regNull(objItem)) {
          if (regNullArray(that.optCheckConfigsCadre)) {
            that.optCheckConfigsCadre.push(objItem);
          } else {
            let tpInfo = that.optCheckConfigsCadre.find(item => item.configId == objItem.configId);
            if (regNull(tpInfo)) {
              that.optCheckConfigsCadre.push(objItem);
            } else {
              that.optCheckConfigsCadre.forEach((item) => {
                if (item.configId == objItem.configId) {
                  item.score = objItem.score;
                }
              });
            }
          }
        } else if (!regNull(objValID)) {
          that.optCheckConfigsCadre = that.optCheckConfigsCadre.filter(item => item.configId != objValID);
        }
      },
      // /**
      //  * @description 选择综合事件
      //  * @param {@} objItem
      //  * @param {*} objValID
      //  */
      // handleTypesChange2(objItem, objValID) {
      //   let that = this;
      //   if (!regNull(objItem)) {
      //     if (regNullArray(that.optCheckConfigsUnion)) {
      //       that.optCheckConfigsUnion.push(objItem);
      //     } else {
      //       let tpInfo = that.optCheckConfigsUnion.find(item => item.configId == objItem.configId);
      //       if (regNull(tpInfo)) {
      //         that.optCheckConfigsUnion.push(objItem);
      //       } else {
      //         that.optCheckConfigsUnion.forEach((item) => {
      //           if (item.configId == objItem.configId) {
      //             item.score = objItem.score;
      //           }
      //         });
      //       }
      //     }
      //   } else if (!regNull(objValID)) {
      //     that.optCheckConfigsUnion = that.optCheckConfigsUnion.filter(item => item.configId != objValID);
      //   }
      // },
      // /**
      //  * @description 选择综合事件
      //  * @param {@} objItem
      //  * @param {*} objValID
      //  */
      // handleTypesChange3(objItem, objValID) {
      //   let that = this;
      //   if (!regNull(objItem)) {
      //     if (regNullArray(that.optCheckConfigsDorm)) {
      //       that.optCheckConfigsDorm.push(objItem);
      //     } else {
      //       let tpInfo = that.optCheckConfigsDorm.find(item => item.configId == objItem.configId);
      //       if (regNull(tpInfo)) {
      //         that.optCheckConfigsDorm.push(objItem);
      //       } else {
      //         that.optCheckConfigsDorm.forEach((item) => {
      //           if (item.configId == objItem.configId) {
      //             item.score = objItem.score;
      //           }
      //         });
      //       }
      //     }
      //   } else if (!regNull(objValID)) {
      //     that.optCheckConfigsDorm = that.optCheckConfigsDorm.filter(item => item.configId != objValID);
      //   }
      // },
      onCreate(){
        let that = this;
        if(that.BonusType == 1){
          that.createTitle = '班干部';
        }else if(that.BonusType == 2){
          that.createTitle = '学生会';
        }else if(that.BonusType == 3){
          that.createTitle = '寝室长';
        }
        that.isDialogCreate = true;
        if(!regNull(that.$refs.comConfig_classcadre)){
          that.$refs.comConfig_classcadre.onReset();
        }
        that.optCheckConfigs = [];
      },
      onSave(){
        let that = this;
        let data = {
          semesterId:that.arrSearch.strSemester,
          BonusType:that.BonusType,
          configs:that.optCheckConfigsCadre,
        }
        that.fullscreenLoading = true;
        ajaxPost(that,'/api/admin/studentbonus',data,function(res){
          that.fullscreenLoading = false;
          that.isDialogCreate = false;
          onGetData(that);
        })
      },
      /**
       * @description Tabs切换事件
       * @param {*} objItem
       */
      onGetTabs(objIndex){
        let that=this;
        that.currentTabs=objIndex;
        that.BonusType = that.optTabs[objIndex].type;
        onGetData(that);
      },
      /**
       * @description 重置搜索
       */
      onResetSearch() {
        let that = this;
        that.arrSearch.strSemester = that.arrSearch.optSemester.find(item => item.isCurrent == true).semesterId;
        // if (that.arrSearch.optClass && that.arrSearch.optClass.length > 0) {
        //   that.arrSearch.strClass = that.arrSearch.optClass[0].classId;
        // };
        that.arrSearch.strCourse = null;
        that.arrSearch.Student = null;
        that.arrSearch.courseType = null;
        that.arrSearch = JSON.parse(JSON.stringify(that.arrSearchReast))
        that.paging.Index = 1;
        onGetData(that);
      },
      //分页组件左侧的按钮事件，按顺序进行判断
      pageBtnClick(index) {},
      // 分页面按钮
      pagingClick(type, val) {
        let that = this;
        if (type == 1) {
          //更改每页条数触发的事件
          that.paging.Size = val;
          that.paging.Index = 1;
        } else {
          //更改当前页时触发的事件
          that.paging.Index = val;
        }
        onGetData(that);
      },
      /**
       * @description 学期改变
       */
      onChangeSemester() { //
        let that = this;
        that.handleChangeClass(that.arrSearch.strClass)
      },

      /**
       * @description 搜索数据
       */
      onSearch() {
        let that = this;
        onGetData(that);
      },
    },
    created() {
      let that = this;
      isPower(that, "admin_extrapoints_studentunion", "admin_extrapoints_studentunion");
      isPower(that, "admin_extrapoints_dormitoryhead", "admin_extrapoints_dormitoryhead");
      isPower(that, "admin_extrapoints_classcadre", "admin_extrapoints_classcadre");

      that.optTabs = [];
      if (that.isPower.admin_extrapoints_studentunion == true) {
        that.optTabs.push({
          name: '班干部',
          api: '',
          type:1,
        });
      }
      if (that.isPower.admin_extrapoints_dormitoryhead == true) {
        that.optTabs.push({
          name: '学生会',
          api: '',
          type:2,
        });
      }
      if (that.isPower.admin_extrapoints_classcadre == true) {
        that.optTabs.push({
          name: '寝室长',
          api: '',
          type:3,
        });
      }

    that.fullscreenLoading = true;
      // 搜索 下拉学期
    ajaxGet(that, "/api/admin/semester", null, function(resSemester) {
      if (!regNullArray(resSemester)) {
        // 学期
        that.arrSearch.optSemester = resSemester;
        let tpSemester = resSemester.find(item => item.isCurrent == true);
        // 判断是否有当前学期，没有则默认显示第一个学期
        if (!regNull(tpSemester)) {
          that.arrSearch.strSemester = tpSemester.semesterId;
        } else {
          that.arrSearch.strSemester = resSemester[0].semesterId;
        }

        // 搜索下拉班级
        let tempUserInfo = onGetNowUser(that);
        that.arrSearch.optClass = tempUserInfo.isTeacher ? tempUserInfo.manageClass : tempUserInfo.allClass;
        if (tempUserInfo.isTeacher) {
          that.arrSearch.strClass = that.arrSearch.optClass[0].classId
        }

        that.fullscreenLoading = true;
        ajaxGet(that, "/api/admin/GrowthConfig", null, function(resConfig) {
          if(!regNullArray(resConfig)){
            for (var item of resConfig) {
              item.isSelect = false;
            }
            that.optConfigs = JSON.parse(JSON.stringify(resConfig.filter(val => {return val.configType == 1})));
            that.defaultConfigs = JSON.parse(JSON.stringify(resConfig.filter(val => {return val.configType == 1})));
            if(!regNull(that.$refs.comConfig_classcadre)){
              that.$refs.comConfig_classcadre.onGetTreeList(that.optConfigs);
            }
            if(!regNull(that.$refs.comConfig_studentunion)){
              that.$refs.comConfig_studentunion.onGetTreeList(that.optConfigs);
            }
            if(!regNull(that.$refs.comConfig_dormitoryhead)){
              that.$refs.comConfig_dormitoryhead.onGetTreeList(that.optConfigs);
            }
          }
          that.fullscreenLoading = false;

        });
        that.arrSearchReast = JSON.parse(JSON.stringify(that.arrSearch));

        onGetData(that);

      } else {
        that.arrSearch.optSemester = [];
        that.fullscreenLoading = false;
      }
    });

    },
    watch: {
      $route() {
        let that = this;
        onGetData(that);
      },
    }
  };



  ///加载列表数据
  // 获取数据
  function onGetData(that) {
      let data = {
        SemesterId:that.arrSearch.strSemester,
        Student:that.arrSearch.Student,
        ClassId:that.arrSearch.strClass,
        BonusType:that.BonusType,
        PageNumer: that.paging.Index,
        PageSize: that.paging.Size,
      }
      that.fullscreenLoading = true;
      ajaxGet(that,'/api/admin/studentbonus/search',data,function(resData){
        if(!regNullArray(resData.items)){
          that.page = resData;
          that.itemData = resData.items;
        }else{
          that.itemData = [];
        }

        that.fullscreenLoading=false;
      });
  }

</script>

<style scoped="scoped" lang="less">
.dialogBody{
    .colTitleBox{
      text-align: center;
      background: #409EFF;
      color: #FFFFFF;
      font-size: 18px;
      padding: 10px 0;
      border-radius: 5px;
      margin-bottom: 15px
    }
  }

  .titleCont{
    position: relative;
    .btnAddBox{
      position: absolute;
      bottom: 0;
      top: 0;
      right: 0;
    }
  }

  .divUploadBox {
    margin-top: 25px;
    padding-left: 15px;
    position: relative;

    .divTipFont {
      position: absolute;
      top: 8px;
      left: 150px;
      font-size: 12px;
    }
  }


  .select-text {
    display: block;
    box-sizing: border-box;
    font-size: 14px;
    margin-bottom: 5px;
  }

  .divImpBtn {
    margin-top: 5px;
  }

  .selTitleBox {
    font-size: 14px;
  }

  .divdvContBox {
    padding-left: 15px;
    margin-top: -15px;
    display: flex;
  }

  .divdvContBox .divSelect {
    padding-right: 15px;
    width: 70%;
  }

  .selTitleBox .spTitle {
    color: #303133;
  }

  .selBodyBox {
    padding-top: 5px;
  }

  .navTabsCont .nav-link {
    font-size: 12px;
  }

</style>


