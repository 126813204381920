<!--
  处分管理 - 新增
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          {{ruleForm.status==2 ? '审核奖励信息' : '查看奖励信息'}}
        </h3>
      </div>
      <div class="card-body">
        <div class="row" v-if="ruleForm.name">
          <div class="col-lg-12 colInfo">
            <label>奖项名称</label>
            <div class="colFonts">
              {{ruleForm.name}}
            </div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>奖励级别</label>
            <div class="colFonts">
              {{ruleForm.level.name}}
            </div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>奖励日期</label>
            <div class="colFonts">
              {{ruleForm.prizeDate | dateformatDay}}
            </div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>学号</label>
            <div class="colFonts">
              {{ruleForm.studentNo ? ruleForm.studentNo : '暂无' }}
            </div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>姓名</label>
            <div class="colFonts">
              {{ruleForm.studentName}}
            </div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>申请时间</label>
            <div class="colFonts">
              {{ruleForm.createDate | dateformatDay}}
            </div>
          </div>
          <div class="col-lg-12 colInfo">
            <label>备注</label>
            <div class="colFonts" v-if="ruleForm.remark!=null && ruleForm.remark.length>0" v-html="ruleForm.remark"></div>
            <div class="colFonts" v-else>暂无数据</div>
          </div>
        </div>
      </div>

    </el-card>


    <!--附件-->
    <el-card class="box-card" v-if="ruleForm.attachment">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          附件<small class="color-danger">(点击图片查看原图)</small>
        </h3>
      </div>
      <div class="card-body ">
        <el-carousel height="500px" :autoplay="!showViewer">
          <el-carousel-item v-for="(item,index) in uploadFiles" :key="index" class="VerticalMid">
            <img :src="item" @click="onPreview" height="100%">
          </el-carousel-item>
        </el-carousel>
      </div>
    </el-card>

    <!--附件-->
    <el-card class="box-card" v-if="Auditrecords.length>0">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          审核记录
        </h3>
      </div>
      <div class="card-body p-0">
        <el-table :data="Auditrecords" stripe class="elTableData" v-if="Auditrecords.length>0">
              <el-table-column type="index"></el-table-column>
          <el-table-column prop="type" label="审核时间" width="160">
            <template slot-scope="scope">
              {{scope.row.createDate | dateformatDay}}
            </template>
          </el-table-column>
          <el-table-column label="备注">
            <template slot-scope="scope">
              <span v-if="scope.row.auditReason" v-html="scope.row.auditReason"></span>
              <span v-else>暂无</span>
              <!-- {{scope.row.auditReason ? scope.row.auditReason : '暂无'}} -->
            </template>
          </el-table-column>
          <el-table-column label="审核状态">
            <template slot-scope="scope">
              <span v-if="scope.row.status==2">待审核</span>
              <span v-else-if="scope.row.status==3">已拒绝</span>
              <span v-else-if="scope.row.status==4">已通过</span>
            </template>
          </el-table-column>
        </el-table>
        <el-empty v-else :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>

    <el-card class="box-card" v-if="ruleForm.status== 2">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
           审核备注
        </h3>
      </div>
      <div class="card-body">
        <el-input class="formControl"  type="textarea" :rows="6" maxlength="500" show-word-limit placeholder="请输入内容"
          v-model="AuditRuleForm.remark"></el-input>
      </div>
    </el-card>
    <div class="row">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
        <el-button type="primary" @click="passAudit" v-if="ruleForm.status == 2">通过</el-button>
        <el-button type="warning" @click="rejectAudit" v-if="ruleForm.status == 2">拒绝</el-button>
      </div>
    </div>
    <!--配合走马灯效果放大图片组件-->
    <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="uploadFiles" />
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import ElImageViewer from 'element-ui/packages/image/src/image-viewer';

  export default {
    components: {
      ElImageViewer
    },
    data() {
      return {
        Id:null,//审核Id
        Auditrecords:[],//审核记录
        ruleForm:{},//基础信息
        fullscreenLoading: false,
        showViewer: false,//是否进行轮播
        uploadFiles:[],
        AuditRuleForm:{
          remark:''
        },
      };
    },

    created() {
      let that = this
      that.Id = that.$route.params.Id
      that.fullscreenLoading = true
      ajaxGet(that,`/api/admin/prizeapply/${that.Id}`,null,function(res){
        if(res.attachment){
          that.uploadFiles = res.attachment.split('|').map(item =>`${that.$ServerUrl()}${item}`)
        }
        that.fullscreenLoading = false
        that.Auditrecords = res.audit || []
        that.ruleForm = res
      })
    },

    methods: {
      rejectAudit(){//拒绝审核
        let that = this
        if(!that.AuditRuleForm.remark){
          warning(that,'请填写备注信息')
          return
        }
        let data ={
          applyId:that.Id,
          status:3,
          auditReason:that.AuditRuleForm.remark
        }
        that.fullscreenLoading = true
        ajaxPut(that,`/api/admin/prizeapply/audit/${that.Id}`,data,function(res){
          that.fullscreenLoading = false
          that.onBack()
        })
      },
      passAudit(){
        let that = this
        let data ={
          applyId:that.Id,
          status:4,
          auditReason:that.AuditRuleForm.remark
        }
        that.fullscreenLoading = true
        ajaxPut(that,`/api/admin/prizeapply/audit/${that.Id}`,data,function(res){
          that.fullscreenLoading = false
          that.onBack()
        })
      },
      onPreview() {//放大图片
        this.showViewer = true
      },
      closeViewer() {//关闭预览图
        this.showViewer = false
      },
      onBack() {//返回
        let that = this;
        back(that);
      },
    },
  };
</script>

<style scoped="scoped">
  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }

  .spIcon {
    width: 5px;
    height: 20px;
    border-radius: 5px;
    background-image: linear-gradient(#4545FF, #2D8EFF);
  }

  .spTitle {
    float: left;
    margin-left: 20px;
    color: #323E56;
    letter-spacing: 2px;
    font-size: 16px;
    margin-top: -3px;
  }

  .wMax {
    width: 100% !important;
  }
</style>

<!-- 综合类型 -->
<style scoped="scoped">
  .divTagBox {
    padding: -5px;
  }

  .divTagBox .spTab {
    padding: 5px;
  }

  .divTagBox .spScore {
    color: #fa3534;
  }

  .divLiteracyBox {
    padding: 15px;
  }

  .divLiteracyBox .listBox {
    text-align: center;
    padding: 20px;
    float: left;
    border: 2px solid #FFFFFF;
    border-radius: 5px;
  }

  .divLiteracyBox .listBox:hover {
    border: 2px solid #409eff;
    cursor: pointer;
  }

  .divLiteracyBox .literacyName {
    font-size: 24px;
    letter-spacing: 2px;
    padding-bottom: 10px;
  }

  .divLiteracyBox .literacyNum {
    padding-top: 10px;
    font-family: 'OswaldLight';
    border-top: 2px solid #EEEEEE;
    font-size: 32px;
    color: #409eff;
  }
</style>
