<!--
  年级信息
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">

    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          年级信息
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <search :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null"></search>
            </li>
            <li class="nav-item">
              <el-button type="success" v-if="isPower.college_create == true" icon="el-icon-circle-plus-outline"
                @click="onCreate()">新增</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table :data="itemData" stripe class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="code" label="年级编号" class="elColumn"></el-table-column>
          <el-table-column prop="name" label="年级名称" class="elColumn"></el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <operationBtn v-if="isPower.college_details == true" :btnName="'查看'" @click.native="onDetail(scope.row.collegeId)"></operationBtn>
              &nbsp;
              <el-dropdown v-if="isPower.college_edit || isPower.college_delete" trigger="click"
                @command="handleCommand($event,scope.row.collegeId)">
                <el-button size="mini">
                  更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="isPower.college_edit" class="color-warning"
                    command="onUpdate">编辑</el-dropdown-item>
                  <el-dropdown-item divided v-if="isPower.college_delete" class="color-danger"
                    command="onDelete">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>

    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%" :close-on-click-modal="false"
      :close-on-press-escape="false" :append-to-body="true" :fullscreen="false">
      <el-form :model="collegeForm" :rules="rules" ref="collegeForm" label-width="100px">
        <el-row v-if="isDetail!=true">
          <el-col :span="24">
            <el-form-item label="年级编号" prop="code">
              <el-input v-model.trim="collegeForm.code"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="年级名称" prop="name">
              <el-input v-model.trim="collegeForm.name"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="row" v-else>
          <div class="col-lg-12 colInfo">
            <label>年级编号</label>
            <div class="colFonts">{{collegeForm.code}}</div>
          </div>
          <div class="col-lg-12 colInfo">
            <label>年级名称</label>
            <div class="colFonts">{{collegeForm.name}}</div>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <div v-if="isDetail!=true">
          <el-button @click="onResetForm('collegeForm')">取 消</el-button>
          <el-button @click="onReset()">重 置</el-button>
          <el-button type="primary" @click="onVerification('collegeForm')">确 定</el-button>
        </div>
        <div v-else>
          <el-button @click="dialogVisible = false">关 闭</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';

  export default {
    components: {
      operationBtn,
      comPage,
      search
    },
    data() {
      return {
        //分页查询接口返回的结果
        page: null,
        //分页左侧按钮集合
        pageBtnList: null,
        //分页数据
        paging: {
          // 总共页数
          pageLength: 0,
          // 每页请求的数据量
          Size: 1,
          //当前在第几页
          Index: 1
        },
        isDetail: false, //是否查看
        dialogTitle: "新增",
        // 加载动画
        fullscreenLoading: false,
        //搜索
        SearchList: null,
        // 判断弹出窗
        dialogVisible: false,
        // 年级（学院
        collegeForm: {
          //id
          collegeId: '',
          // 年级（学院）编码
          code: '',
          // 年级（学院）名称
          name: ''
        },
        collegeFormReset: {},
        rules: {
          code: [{
            required: true,
            message: '编号不能为空',
            trigger: 'blur'
          }, {
            min: 0,
            max: 64,
            message: '编号不能超过64个字符',
            trigger: 'blur'
          }],
          name: [{
            required: true,
            message: '年级名称不能为空',
            trigger: 'blur'
          }, {
            min: 0,
            max: 64,
            message: '年级名称不能超过64个字符',
            trigger: 'blur'
          }]
        },
        // 数据
        itemData: [],
        IsUpdate: false,
        isPower: {
          //增删改查 权限管理
          college_details: false,
          college_create: false,
          college_edit: false,
          college_delete: false,
        },
      };
    },
    methods: {
      /**
       * @description 更多操作
       * @param {Object} command
       * @param {Object} objValID
       */
      handleCommand(command, objValID) {
        let that = this;
        switch (command) {
          case 'onUpdate':
            that.onUpdate(objValID);
            break;
          case 'onDelete':
            that.onDelete(objValID);
            break;
        }
      },
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },
      //重置
      onReset() {
        let that = this;
        that.collegeForm = JSON.parse(JSON.stringify(that.collegeFormReset));
        try {
          that.$refs["collegeForm"].resetFields();
        } catch {}
      },
      //关闭弹出窗
      handleClose(done) {
        let that = this;
        done();
        that.onResetForm('collegeForm');
      },
      //验证表单
      onVerification(objForm) {
        let that = this;
        // 验证
        that.$refs[objForm].validate((valid) => {
          if (valid) {
            that.onApiData(that);
          } else {
            return false;
          }
        });
      },
      // 关闭验证
      onResetForm(objForm) {
        let that = this;
        that.$refs[objForm].resetFields();
        // 闭关弹出窗
        that.dialogVisible = false;
      },



      // 新增
      onCreate() {
        let that = this;
        that.dialogTitle = "新增";
        that.isDetail = false;
        that.dialogVisible = true;
        that.IsUpdate = false;
        var collegeForm = JSON.parse(JSON.stringify(that.collegeForm));
        for (var i in collegeForm) {
          collegeForm[i] = null;
        }
        that.collegeForm = JSON.parse(JSON.stringify(collegeForm));
        that.collegeFormReset = JSON.parse(JSON.stringify(that.collegeForm));
        try {
          that.$refs["collegeForm"].resetFileds();
        } catch {}
      },
      // 编辑
      onUpdate(objID) {
        let that = this;
        that.isDetail = false;
        that.dialogTitle = "编辑";
        that.IsUpdate = true;
        // 打开弹出窗
        that.dialogVisible = true;
        // 加载数据动画
        that.fullscreenLoading = true;
        // Api
        let apiUrl = '/api/admin/college/' + objID;
        ajaxGet(that, apiUrl, null, function(res) {
          //关闭动画
          that.fullscreenLoading = false;
          that.collegeForm = res;
          that.collegeFormReset = JSON.parse(JSON.stringify(that.collegeForm));

        });
      },
      //查看
      onDetail(objID) {
        let that = this;
        that.isDetail = true;
        that.dialogTitle = "查看";
        // 打开弹出窗
        that.dialogVisible = true;
        // 加载数据动画
        that.fullscreenLoading = true;
        // Api
        let apiUrl = '/api/admin/college/' + objID;
        ajaxGet(that, apiUrl, null, function(res) {
          //关闭动画
          that.fullscreenLoading = false;
          that.collegeForm = res;
        });
      },
      // 删除
      onDelete(objID) {
        let that = this;
        //提示是否删除
        confirmDelete(that, null, function(res) {
          if (res == true) {
            ajaxDelete(that, '/api/admin/college/' + objID, null, function(r) {
              onGetAllData(that);
            });
          }
        })
      },
      // 数据交涉
      onApiData(objThis) {
        let that = objThis;
        // API
        let apiUrl = '/api/admin/college';
        // Data
        let exampleValue = null;
        that.fullscreenLoading = true;
        if (that.IsUpdate) {
          apiUrl = apiUrl + '/' + that.collegeForm.collegeId;
          exampleValue = {
            'collegeId': that.collegeForm.collegeId,
            'code': that.collegeForm.code,
            'name': that.collegeForm.name
          };
          //更新数据
          ajaxPut(that, apiUrl, exampleValue, function(res) {
            onGetAllData(that);
          });
        } else {
          exampleValue = {
            'code': that.collegeForm.code,
            'name': that.collegeForm.name
          }
          //新增数据
          ajaxPost(that, apiUrl, exampleValue, function(res) {
            //that.collegeForm = null;
            onGetAllData(that);
          });
        }
        that.dialogVisible = false;

      },
      onSearch(params) // 搜索
      {
        let that = this;
        let data = {};
        let searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = element.data;
          }
        });
        data.PageNumer = 1
        routerPath(that, "/Admin/College/Index", data, null, "PageSize");
      },
      // 加载数据
      // onGetAllData(objThis) {
      //   let Myurl = "/api/admin/college";
      //   let that = objThis;
      //   that.fullscreenLoading = true;
      //   ajaxGet(that, Myurl, null, function(r) {
      //     that.itemData = r;
      //     that.fullscreenLoading = false;

      //   });
      // }
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      // 分页面按钮
      pagingClick(type, val) {
        let that = this;
        let url = "/Admin/College/Index";
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "Name",
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "Name",
          );
        }
      },
    },
    created() {
      let that = this;
      isPower(that, "college_details", "college_details");
      isPower(that, "college_create", "college_create");
      isPower(that, "college_edit", "college_edit");
      isPower(that, "college_delete", "college_delete");
      onGetAllData(that);
    },
    watch: {
      $route() {
        let that = this;
        onGetAllData(that);
      }
    }
  };
  // 分页获取数据
  function onGetAllData(that) {
    paging(
      that,
      function(r) {
        let apiUrl = "/api/admin/college/search";
        let data = {
          PageNumer: r.PageNumer,
          PageSize: r.PageSize,
          Name: r.Name,
        };
        that.SearchList = [{
          type: "input",
          Name: "年级名称",
          data: r.Name,
          holder: "请输入年级名称关键字",
          zhi: "Name"
        }, ];
        that.paging.Index=r.PageNumer;
        that.paging.Size=r.PageSize;
        that.fullscreenLoading = true;
        ajaxGet(that, apiUrl, data, function(r) {
          that.page = r;
          that.itemData = r.items;
          that.fullscreenLoading = false;
        });
      },
      "Name",
    );
  }
</script>

<style scoped="scoped">
  .navTabsCont .nav-link {
    font-size: 12px;
  }

  .navToolsCont {
    margin-top: 1px;
  }

  .formGroupCont {
    padding-bottom: 0;
  }
</style>
