<!--社会实践-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <!-- <search :ColNumber="4" :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null">
    </search> -->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <span>{{currentActivityName}}</span>
        <div style="float:right;">
          <comImport v-if="joinType==1" @onImportSuccess="onImportSuccess" :title="'导入活动获奖'"
            :template="'/Templates/活动获奖导入模版.xlsx'" :path="'/api/admin/Activity/importprize/' + id" ref="conImportFile">
          </comImport>
          <el-button type="success" icon="el-icon-circle-plus-outline" @click="onAdd()">新增</el-button>
        </div>
      </div>
      <div class="card-body p-0" v-if="item!=null && item.length!=0">
        <el-table stripe :data="item" class="tableTwo table-hover text-nowrap " style="width: 100%; margin-top: 0px; ">
          <el-table-column type="index" width="90px;" label="序号"></el-table-column>
          <el-table-column prop="name" label="获奖名称"></el-table-column>
          <el-table-column prop="className" label="班级"></el-table-column>
          <el-table-column prop="studentName" label="学生"></el-table-column>
          <el-table-column prop="studentNo" label="学号"></el-table-column>
          <el-table-column prop="roleName" label="角色"></el-table-column>
          <el-table-column prop="coefficient" label="综合分系数"></el-table-column>
          <el-table-column label="操作" width="120px">
            <template slot-scope="scope">
              <!--
								接口未实现，查看和编辑功能不予实现
								<operationBtn :btnName="'查看'" @click.native="onDetail(scope.row.prizeId)"></operationBtn>
								<operationBtn :btnName="'查看'" @click.native="onEdit(scope.row.prizeId)"></operationBtn>
							！-->
              <operationBtn :btnName="'删除'" @click.native="onDelete(scope.row.prizeId)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="paging" :isPage="false"></comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>



    <div class="row" style="margin-top: 10px;">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  // import search from '@/components/search.vue';
  import comImport from '@/components/Import.vue';
  export default {
    components: {
      comPage,
      operationBtn,
      // search,
      comImport
    },
    data() {
      return {
        itemData: [],
        dialogTitle: '',
        dialogVisible: false,
        SearchList: null, //搜索集合
        currentActivityName: null, //当前活动类型
        fullscreenLoading: false,
        item: [], //列表数据
        paging: { //分页数据
          totalCount: 0,
        },
        id: null,
        parentType: null,
        joinType: null,
      };
    },
    created() {

      let that = this;
      that.id = that.$route.params.id;
      that.parentType = that.$route.params.type;
      let Myurl = "/api/admin/activity/" + that.id;
      that.fullscreenLoading = true;
      ajaxGet(that, Myurl, null, function(r1) {
        that.joinType = r1.joinType;
        that.currentActivityName = r1.name + '【评奖】';
        that.fullscreenLoading = false;
      });
      Init(that);
    },
    mounted() {

    },
    methods: {
      onImportSuccess(param) {
        let that = this;
        Init(that);
      },
      onBack() {
        let that = this;
        back(that);
      },
      onAdd() {
        let that = this;
        that.$router.push({
          path: "/Admin/activity/prizeCreate/" + that.id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onEdit(Id) {
        let that = this;
        this.$router.push({
          path: "/Admin/activity/prizeEdit/" + that.id + "/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onDetail(Id) {
        let that = this;
        this.$router.push({
          path: "/Admin/activity/prizeDetail/" + that.id + "/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onDelete(Id) {
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, "/api/admin/Activity/prize/" + Id, null, function(r) {
              that.fullscreenLoading = false;
              Init(that);
            });
          }
        })
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      onSearch(params) {
        let that = this;
        let data = {};
        let searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = element.data;
          }
        });
        data["url"] = that.$route.query.url;
        data.PageNumer = 1;
        routerPath(that, "/Admin/activity/prize/" + that.parentType + "/" + that.id, data, null, "PageSize");
      },
      pagingClick(type, val) {
        let that = this;
        let url = "/Admin/activity/Index/" + that.parentType;
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "Name",
            "PrizeName",
            "ClassId",
            "StudentName",
            "Url"
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "Name",
            "PrizeName",
            "ClassId",
            "StudentName",
            "Url"
          );
        }
      },
    },
    watch: {
      $route() {
        let that = this;
        Init(that);
      },
    },
  };

  function Init(that) {
    pagingPage(that);
  }

  function pagingPage(that) {
    paging(
      that,
      function(r) {
        let Myurl = "/api/admin/activity/prize/" + that.id;

        that.SearchList = [{
            type: "input",
            Name: "获奖名称",
            data: r.PrizeName,
            holder: "请输入获奖名称",
            zhi: "PrizeName"
          },
          {
            type: "input",
            Name: "班级",
            data: r.ClassId,
            holder: "请输入班级",
            zhi: "ClassId"
          },
          {
            type: "input",
            Name: "姓名",
            data: r.Name,
            holder: "请输入学生姓名",
            zhi: "Name"
          },
          {
            type: "input",
            Name: "学号",
            data: r.StudentNo,
            holder: "请输入学号",
            zhi: "StudentNo"
          },
        ];
        let data = {
          StudentNo: r.StudentNo,
          Name: r.Name,
          ClassId: r.ClassId,
          PrizeName: r.PrizeName,
        };
        that.fullscreenLoading = true;
        ajaxGet(that, Myurl, null, function(r1) {
          var item = JSON.parse(JSON.stringify(r1));
          if (data != null) {
            var newItem = [];
            if (data.StudentNo != null && data.StudentNo != "") {
              newItem = [];
              for (var it of item) {
                if (it.studentNo.indexOf(data.StudentNo) >= 0) {
                  newItem.push(it);
                }
              }
              item = JSON.parse(JSON.stringify(newItem));
            }

            if (data.Name != null && data.Name != "") {
              newItem = [];
              for (var it of item) {
                if (it.studentName.indexOf(data.Name) >= 0) {
                  newItem.push(it);
                }
              }
              item = JSON.parse(JSON.stringify(newItem));
            }

            if (data.PrizeName != null && data.PrizeName != "") {
              newItem = [];
              for (var it of item) {
                if (it.name.indexOf(data.PrizeName) >= 0) {
                  newItem.push(it);
                }
              }
              item = JSON.parse(JSON.stringify(newItem));
            }

            if (data.ClassId != null && data.ClassId != "") {
              newItem = [];
              for (var it of item) {
                if (it.className.indexOf(data.ClassId) >= 0) {
                  newItem.push(it);
                }
              }
              item = JSON.parse(JSON.stringify(newItem));
            }
          }
          that.item = item;
          that.paging.totalCount = item.length;
          that.fullscreenLoading = false;
        });


      },
      "PrizeName",
      "ClassId",
      "Name",
      "StudentNo",
    );
  }

</script>

<style scoped="scoped">
  .navTabsCont .nav-link {
    font-size: 12px;
  }

  .navToolsCont {
    margin-top: 1px;
  }

  .btn-mini {
    margin-left: 15px;
    margin-right: 5px;
    height: 40px;
    line-height: 25px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 14px;
  }

  .spIcon {
    background-color: #F8F9FA;
    font-size: 14px;
    padding: 2px 5px;
  }

  .spBan {
    color: #409EFF;
  }

  .spBu {
    color: #FAC10B;
  }

  .spXiao {
    color: #F6402C;
  }

</style>

