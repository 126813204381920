<!--
  学生管理-学生奖励
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <search :ColNumber="3" :SearchList="SearchList" :key="random" ref="SearchList" @onSearch="onSearch"
      v-if="SearchList!=null"></search>
    <el-card class="box-card">

      <div slot="header" class="card-header">
        <h3 class="card-title">
          <span>批次：<b class="color-danger">{{yearName}}</b></span>
          <span class="ml-5">批次时间：<b class="color-danger">{{startDate}} 至 {{endDate}}</b></span>
        </h3>
        <div style="float:right;">
          <el-button type="success" icon="el-icon-circle-plus-outline" v-if="isPower.scholarship_roster_create"
            @click.native="onCreate">新增名单</el-button>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table stripe :data="itemData" class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="className" label="班级" min-width="100"></el-table-column>
          <el-table-column prop="studentNo" label="学号" min-width="100"></el-table-column>
          <el-table-column prop="studentName" label="姓名" min-width="100"></el-table-column>
          <el-table-column prop="levelString" label="奖学金名称" min-width="100"></el-table-column>
          <el-table-column label="操作" width="240px" fixed="right">
            <template slot-scope="scope">
              <!-- 管理员端 -->
              <operationBtn :btnName="'查看'" v-if="isPower.scholarship_roster_details"
                @click.native="onDetail(scope.row.candidateId)"></operationBtn>
              <operationBtn :btnName="'编辑'" v-if="isPower.scholarship_roster_edit"
                @click.native="onEdit(scope.row.candidateId)"></operationBtn>
              <operationBtn :btnName="'删除'" v-if="isPower.scholarship_roster_delete"
                @click.native="onDelete(scope.row.candidateId)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
    <div class="row" style="margin-top: 10px;">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack">返回</el-button>
      </div>
    </div>
    <el-dialog :title="boxTitle" :visible.sync="dialogVisible" width="40%" :before-close="onCancel">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px">
        <el-row :gutter="24">
          <el-col :span="23" class="pr-4">
            <el-form-item label="班级" prop="classId">
              <el-select v-model="ruleForm.classId" placeholder="请选择" class="width_100Pie" @change="onChangeClass"
                filterable>
                <el-option v-for="item in classList" :key="item.classId" :label="item.alias" :value="item.classId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="学生" prop="studentId">
              <el-select v-model="ruleForm.studentId" placeholder="请选择" class="width_100Pie" filterable>
                <el-option v-for="item in studentList" :key="item.studentId" :label="item.name" :value="item.studentId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="奖学金等级" prop="level">
              <el-select v-model="ruleForm.level" placeholder="请选择" class="width_100Pie">
                <el-option v-for="item in typeList" :key="item.Value" :label="item.Text" :value="item.Value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="onSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';

  export default {
    name: '',
    components: {
      comPage,
      operationBtn,
      search
    },
    data() {
      return {
        dialogVisible: false,
        fullscreenLoading: false,
        itemData: [],
        random: null,
        page: null, //分页查询接口返回的结果
        pageBtnList: null, //分页左侧按钮集合
        paging: { //分页数据
          pageLength: 0, // 总共页数
          Size: 10, // 当前请求数量
          Index: 1 //当前在第几页
        },
        rules: {
          classId: [{
            required: true,
            message: '请选择班级',
            trigger: 'change'
          }],
          studentId: [{
            required: true,
            message: '请选择学生',
            trigger: 'change'
          }],
          level: [{
            required: true,
            message: '请选择奖学金等级',
            trigger: 'change'
          }]
        },
        boxTitle: '新增候选人',
        BatchId: null,
        SearchList: null, //搜索集合
        ruleForm: {
          candidateId: null,
        },
        yearName: null,
        endDate: null,
        startDate: null,
        ruleFormReset: {},
        classList: [], //班级集合
        studentList: [], //学生集合
        typeList: [], //奖励类型集合
        isPower: {
          scholarship_roster_create: false,
          scholarship_roster_details: false,
          scholarship_roster_edit: false,
          scholarship_roster_delete: false,
        }
      };
    },
    created() {
      let that = this;
      let dataID = that.$route.params.Id;
      isPower(that, 'scholarship_roster_create', 'scholarship_roster_create');
      isPower(that, 'scholarship_roster_details', 'scholarship_roster_details');
      isPower(that, 'scholarship_roster_edit', 'scholarship_roster_edit');
      isPower(that, 'scholarship_roster_delete', 'scholarship_roster_delete');
      that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
      that.BatchId = dataID;
      that.fullscreenLoading = true;
      ajaxGet(that, '/api/admin/scholarshipbatch/' + dataID, null, function(res) {
        that.fullscreenLoading = false;
        that.yearName = res.yearName;
        that.endDate = dateFormat(res.endDate, '-', 'YYYY-MM-DD');
        that.startDate = dateFormat(res.startDate, '-', 'YYYY-MM-DD');
      })
      pagingPage(that);
    },
    methods: {
      /**
       * @description 返回
       */
      onBack() {
        this.$router.push({
          path: "/Admin/ScholarshipMGT/Batch/Index",
        });
      },
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },
      onDetail(objID) {
        let that = this;
        that.$router.push({
          path: "/Admin/ScholarshipMGT/Roster/Details/" + objID,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onDelete(objID) {
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, '/api/admin/scholarshipcandidate/' + objID, null, function(res) {
              that.fullscreenLoading = false;
              pagingPage(that);
            })
          }
        })
      },
      /**
       * @description  编辑
       */
      onEdit(objID) {
        let that = this;
        that.fullscreenLoading = true;
        ajaxGet(that, '/api/admin/scholarshipcandidate/' + objID, null, function(res) {
          ajaxGet(that, '/api/admin/scholarshipapply/leveltype', null, function(r1) {
            ajaxGet(that, '/api/admin/class/linkage', null, function(resClass) {
              that.classList = resClass;
              that.dialogVisible = true;
              that.typeList = r1;
              if (!regNullArray(res)) {
                that.fullscreenLoading = false;
                that.ruleForm = res;
                that.ruleForm.level = that.ruleForm.level.toString();
                that.dialogVisible = true;
                that.onChangeClass(that.ruleForm.classId)
              }
            });
          });
        });
      },
      /**
       * @description  新增
       */
      onCreate() {
        let that = this;
        that.fullscreenLoading = true;
        ajaxGet(that, '/api/admin/scholarshipapply/leveltype', null, function(res) {
          ajaxGet(that, '/api/admin/class/linkage', null, function(resClass) {
            that.classList = resClass;
            that.fullscreenLoading = false;
            that.dialogVisible = true;
            that.fullscreenLoading = false;
            that.typeList = res;
          })
        });

      },
      /**
       * @description 选择班级事件
       * @return {void}
       */
      onChangeClass(_dataID) {
        let _this = this;
        _this.studentList = [];
        ajaxGet(_this, '/api/admin/student/class/' + _dataID, null, function(resData) {
          if (_this.ruleForm.members != null && _this.ruleForm.members.length != 0) {
            _this.studentList = resData.filter(function(obj) {
              return _this.ruleForm.members.find(item => item.studentId == obj.studentId) == undefined;
            });
          } else {
            _this.studentList = resData;
          }
        });

      },
      /**
       * @description  提交
       */
      onSave() {
        let that = this;
        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            let data = {
              batchId: that.BatchId,
              studentId: that.ruleForm.studentId,
              classId: that.ruleForm.classId,
              level: parseInt(that.ruleForm.level),
            }
            if (that.ruleForm.candidateId == null) {
              that.ruleForm.level = parseInt(that.ruleForm.level)
              that.fullscreenLoading = true;
              ajaxPost(that, '/api/admin/scholarshipcandidate', data, function(res) {
                that.onCancel();
                that.fullscreenLoading = false;
                pagingPage(that)
              })
            } else {
              data.candidateId = that.ruleForm.candidateId;
              that.fullscreenLoading = true;
              ajaxPut(that, '/api/admin/scholarshipcandidate/' + that.ruleForm.candidateId, data, function(
                resdata) {
                that.onCancel();
                that.fullscreenLoading = false;
                pagingPage(that)
              })
            }
          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              isError[0].querySelector('input').focus();
            }, 1)
            return false;
          }
        })
      },
      /**
       * @description 关闭弹出框事件
       */
      onCancel() {
        let that = this;
        try {
          that.dialogVisible = false;
          that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      onSearch(params) {
        let that = this,
          data = {},
          searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = Join(element.data);
          }
        });
        data.PageNumer = 1;
        routerPath(that, "/Admin/ScholarshipMGT/Roster/Index/" + that.BatchId, data, null, "PageSize");

      },
      pagingClick(type, val) {
        let that = this;
        let url = '/Admin/ScholarshipMGT/Roster/Index/' + that.BatchId;
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "ClassId",
            "Student",
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "ClassId",
            "Student",
          );
        }
      },
    },
    watch: {
      $route() {
        let that = this;
        pagingPage(that);
      }
    }
  };

  function pagingPage(that) {
    paging(
      that,
      function(r) {
        ajaxGet(that, '/api/admin/class/linkage', null, function(resClass) {
          let tempClassId = r.ClassId,
            tempCalss = [];
          resClass.forEach(function(item) {
            tempCalss.push({
              Title: item.alias,
              Value: item.classId,
            });
          });
          that.SearchList = [];
          that.SearchList.push({
            type: "select",
            Name: "班级",
            data: r.ClassId,
            select: tempCalss,
            zhi: "ClassId",
          }, {
            type: "input",
            Name: "学生",
            data: r.Student,
            holder: "请输入学号/姓名",
            zhi: "Student"
          });
          let data = {
            PageNumer: r.PageNumer,
            PageSize: r.PageSize,
            Batch: that.BatchId,
            Class: tempClassId,
            Student: r.Student,
          }
          ajaxGet(that, '/api/admin/scholarshipcandidate/search', data, function(res) {
            that.itemData = res.items;
            that.page = res;
          });
        });
      },
      "ClassId",
      "Student",
    );
  }
</script>

<style scoped="scoped">
</style>
