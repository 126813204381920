<!--
  处分管理 - 新增
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          查看奖励信息
        </h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-12 colInfo">
            <label>奖项名称</label>
            <div class="colFonts">
              {{ruleForm.name | flNoValue}}
            </div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>奖励级别</label>
            <div class="colFonts">
              {{ruleForm.level.name | flNoValue}}
            </div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>奖励日期</label>
            <div class="colFonts">
              {{ruleForm.prizeDateString | flNoValue}}
            </div>
          </div>
          <div class="col-lg-12 colInfo">
            <label>备注</label>
            <div class="colFonts" v-if="ruleForm.remark!=null && ruleForm.remark.length>0" v-html="ruleForm.remark">
            </div>
            <div class="colFonts" v-else>暂无数据</div>
          </div>
        </div>
      </div>

    </el-card>

    <!--学生列表-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          获奖名单
        </h3>
      </div>
      <div class="card-body p-0">
        <el-table stripe :data="ruleForm.members">
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column prop="student.studentNo" label="学号"></el-table-column>
          <el-table-column prop="student.name" label="学生"></el-table-column>
          <el-table-column prop="student.className" label="班级"></el-table-column>
          <el-table-column label="分数">
           <template slot-scope="scope">
             <span v-if="!regNullArray(scope.row.configs)">
               {{ parseFloat(scope.row.configs.map(item=> item.score).reduce((n,m)=> n+m)).toFixed(1) }}
             </span>
             <span v-else>0</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>

    <!--附件-->
    <comShowImage ref="comShowImage" :uploadFiles="uploadFiles"></comShowImage>



    <div class="row">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
      </div>
    </div>

  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comShowImage from '@/components/showImage.vue';
  export default {
    components: {
      comShowImage,
    },
    data() {
      return {
        // 综合类型详情框
        dialogScoreVisible: false,
        // 综合类型弹出框名称
        dialogScoreName: null,
        // 综合弹出框内的数据
        dialogArray: [],

        // 学生历史数据弹出框
        dialogTableVisible: false,
        // 学生历史弹出框 学生名称
        dialogStudentName: null,
        // 学生历史弹出框 班级名称
        dialogClassName: null,
        // 学生历史弹出框 学号名称
        dialogStudentNo: null,
        // 单个学生的历史处分
        studentPunsihs: [],
        // 学生列表
        selectStudentList: [],
        isOne: false, //是否只能选择一个学生
        //单个学生的历史奖励
        studentPrizes: [],
        selectStudentListReset: [], //选中的学生集合
        random: 0, //刷新组件




        ruleForm: //表单数据
        {
          name: null,
          prizeId: null, //奖励主键，新增时为null
          semesterId: null, //学期
          typeId: null, //奖励类型
          levelId: null, //奖励等级主键
          prizeDate: null, //奖励日期
          remark: null, //备注
          GrowthConfigs: [], //综合类型集合
          members: [],
          attachment: null,
          level: {
            name: null,
          },
          type: {
            name: null,
          }
        },
        allStudents: [], //所有学生集合
        selectClassId: null,
        selectStudentId: null,

        StudentList: [], //班级学生集合

        fullscreenLoading: false,

        uploadFiles: [],
        arrGrowthConfigs: [],
        styleWidth: '25%',
      };
    },
    filters: {
      flNoValue(obj) {
        if (obj == null || typeof(obj) == "undefined" || obj == '') {
          return '暂无数据';
        }
        return obj;
      }
    },
    created() {
      let that = this;
      let id = that.$route.params.id;
      var detailUrl = null;
      if (id != null && typeof(id) != "undefined" && id != "") {
        that.ruleForm.reasonId = id;
        detailUrl = "/api/admin/prize/" + id;
      }

      that.fullscreenLoading = true;
      ajaxGet(that, detailUrl, null, function(resData) {

        if (resData != null) {
          that.ruleForm = resData;
          that.uploadFiles = [];
          if (resData.attachment != null && typeof(resData.attachment) != "undefined" && resData.attachment != "") {
            var files = resData.attachment.split('|');
            for (var i in files) {
              that.uploadFiles.push(that.$ServerUrl() + files[i]);
            }
            var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
            that.random += random;

          }
        }

        that.fullscreenLoading = false;
      });
    },

    methods: {
      /**
       * @description 打开综合类型弹出框
       * @param {Object} _data
       */
      onOpenTypeInfo(_data) {
        let that = this;
        that.dialogScoreName = _data.name;
        that.dialogArray = [];
        for (let item of _data.children) {
          if (item.score != 0) {
            that.dialogArray.push({
              name: item.name,
              score: item.score
            })
          }
        }

        if (that.dialogArray.length != 0) {
          that.dialogScoreVisible = true;
        } else {
          info(that, '未配综合类型');
        }
      },

      /**
       * @description 计算综合分数
       * @param {Object} _data
       * @return {Number} 计算出的总分
       */
      onCalculationScore(_data) {
        let temp = 0;
        for (let item of _data) {
          temp = item.score + temp;
        }
        return temp;
      },


      getTreeData(data) {
        // 循环遍历json数据
        for (var i = 0; i < data.length; i++) {

          if (data[i].children.length < 1) {
            // children若为空数组，则将children设为undefined
            data[i].children = undefined;
          } else {
            // children若不为空数组，则继续 递归调用 本方法
            this.getTreeData(data[i].children);
          }
        }
        return data;
      },
      onPictureHandleSuccess(param) {
        let that = this;
        that.ruleForm.attachment = "";
        for (var i in param) {
          if (that.ruleForm.attachment == "") {
            that.ruleForm.attachment = param[i].path;
          } else {
            that.ruleForm.attachment += "|" + param[i].path;
          }
        }
      },
      getDialogSummaries(param) {
        const {
          columns,
          data
        } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '累计分数';
            return;
          }
          const values = data.map(item => Number(item[column.property]));
          if (column.property === 'score') {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index];
          } else {
            sums[index] = '--';
          }
        });

        return sums;
      },
      getSummaries(param) {
        const {
          columns,
          data
        } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '累计分数';
            return;
          }
          const values = data.map(item => Number(item[column.property]));
          if (column.property === 'score') {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index];
          } else {
            sums[index] = '--';
          }
        });

        return sums;
      },
      onStudentPrizeDetail(studentName, className, studentNo, studentId) {
        let that = this;
        that.dialogStudentName = studentName;
        that.dialogClassName = className;
        that.dialogStudentNo = studentNo;
        that.dialogTableVisible = true;
        that.studentPrizes = [];
        for (var i in that.allStudents) {
          if (that.allStudents[i].studentId == studentId) {
            that.studentPrizes = that.allStudents[i].prizes;
          }
        }

        that.studentPrizes.sort(function(a, b) {
          return new Date(b.prizeDate).getTime() - new Date(a.prizeDate).getTime();
        });

      },
      onBack() {
        let that = this;
        back(that);
      },
    },
  };

</script>

<style scoped="scoped">
  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }

  .spIcon {
    width: 5px;
    height: 20px;
    border-radius: 5px;
    background-image: linear-gradient(#4545FF, #2D8EFF);
  }

  .spTitle {
    float: left;
    margin-left: 20px;
    color: #323E56;
    letter-spacing: 2px;
    font-size: 16px;
    margin-top: -3px;
  }

  .wMax {
    width: 100% !important;
  }
</style>

<!-- 综合类型 -->
<style scoped="scoped">
  .divTagBox {
    padding: -5px;
  }

  .divTagBox .spTab {
    padding: 5px;
  }

  .divTagBox .spScore {
    color: #fa3534;
  }

  .divLiteracyBox {
    padding: 15px;
  }

  .divLiteracyBox .listBox {
    text-align: center;
    padding: 20px;
    border: 2px solid #FFFFFF;
    border-radius: 5px;
  }

  .divLiteracyBox .listBox:hover {
    border: 2px solid #409eff;
    cursor: pointer;
  }

  .divLiteracyBox .literacyName {
    font-size: 24px;
    letter-spacing: 2px;
    padding-bottom: 10px;
  }

  .divLiteracyBox .literacyNum {
    padding-top: 10px;
    font-family: 'OswaldLight';
    border-top: 2px solid #EEEEEE;
    font-size: 32px;
    color: #409eff;
  }
</style>
