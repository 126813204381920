<!--
  用户管理 - 新增
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{Title}}
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24" v-if="ruleForm.userId == null">
            <div v-if="isSysUserRange=='true'">
              <el-col :span="24">
                <el-steps :active="1" align-center>
                  <el-step title="用户信息"></el-step>
                  <el-step title="学生范围"></el-step>
                </el-steps>
              </el-col>
            </div>
          </el-row>
          <el-row :gutter="24" style="margin-top: 20px;">
            <el-col  :span="6" class="text-center divStuAva">
              <el-upload class="avatar-uploader" action="" style="width: 180px;height:252px;margin:0 auto;"
                :auto-upload="true" :show-file-list="false" :before-upload="beforeAvatarUpload"
                :http-request="uploadSectionFile">
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <span class="spImgFont" v-if="regNull(imageUrl)">上传照片</span>
              </el-upload>
            </el-col>
            <el-col :span="12">
              <el-form-item label="用户名" prop="userName">
                <el-input show-word-limit auto-complete="new-userName" maxlength="20" v-model="ruleForm.userName"
                  placeholder="请输入用户名,作为登录的唯一标识,长度不能超过20个字符" class="formControl"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="姓名" prop="name">
                <el-input show-word-limit auto-complete="new-name" maxlength="20" v-model="ruleForm.name"
                  placeholder="请输入姓名,长度不能超过20个字符" class="formControl"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="ruleForm.userId == null">
              <el-form-item prop="password">
                <label slot="label"><span style="color:#f66c6c">* </span>输入密码</label>
                <el-input show-word-limit auto-complete="new-password" show-password maxlength="16"
                  v-model.trim="ruleForm.password" placeholder="请输入密码,密码为8-16位，数字和字母组成，不能是纯数字或纯字母" class="formControl">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="ruleForm.userId == null">
              <el-form-item prop="againPassword">
                <label slot="label"><span style="color:#f66c6c">* </span>确认密码</label>
                <el-input show-word-limit auto-complete="new-againPassword" show-password maxlength="16"
                  v-model.trim="ruleForm.againPassword" placeholder="请确认密码,密码为8-16位，数字和字母组成，不能是纯数字或纯字母"
                  class="formControl"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="所属年级">
                <el-select v-model="ruleForm.collegeId" placeholder="请选择所属年级" class="width_100Pie" clearable>
                  <el-option v-for="item in optCollege" :key="item.collegeId" :label="item.name"
                    :value="item.collegeId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="启用">
                <el-switch v-model="ruleForm.enable">
                </el-switch>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="角色" v-if="roles!=null">
                <el-checkbox-group v-model="userRoles">
                  <el-checkbox v-for="role in roles" :label="role.roleId" :key="role.roleId">{{role.name}}</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
        </div>

      </el-card>
      <div class="row " style="margin-top: 10px;">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
          <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
          <el-button type="primary" icon="el-icon-circle-check" @click="onSave()">提交</el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';

  export default {
    components: {},
    data() {
      return {
        isSysUserRange: null,
        idArr: [],
        allDept: [],
        depts: [],
        roles: null,
        userDepartments: [], //部门
        userRoles: [],
        imageUrl:'',
        ruleForm: //表单数据
        {
          userId: null, //用户主键，新增时为null
          userName: null, //用户名
          password: null, //密码
          name: null, //姓名
          enable: true, //启用
          userDepartments: null, //部门
          userRoles: null, //角色
          collegeId: null, // 所属年级
        },
        userDepartmentsReset: null,
        userRolesReset: null,
        ruleFormReset: null,
        Title: "新增用户信息",
        fullscreenLoading: false,
        optCollege: [], // 所属年级

        rules: {
          name: [{
              required: true,
              message: '姓名不能为空',
              trigger: 'change'
            },
            {
              max: 20,
              message: '姓名不能超过20个字符',
              trigger: 'change'
            },
          ],
          userName: [{
              required: true,
              message: '用户名不能为空',
              trigger: 'change'
            },
            {
              max: 20,
              message: '用户名不能超过20个字符',
              trigger: 'change'
            },
          ],
          againPassword: [{
            validator: (rule, value, callback) => {
              let that = this;
              if (that.ruleForm.userId == null) {
                if (that.ruleForm.againPassword == null || that.ruleForm.againPassword.length <= 0) {
                  callback(new Error("请确认密码！"));
                } else {
                  if (that.ruleForm.password != value) {
                    callback(new Error("两次密码不一致！"));
                  }
                }
              }
              callback();
            },
            trigger: 'change'
          }],
          password: [{
            validator: (rule, value, callback) => {
              let that = this;
              if (that.ruleForm.userId == null) {
                if (that.ruleForm.password == null || that.ruleForm.password.length <= 0) {
                  callback(new Error("请输入密码！"));
                } else {
                  var reg = /^.{8,16}$/;
                  if (value != 0 && !reg.exec(value)) {
                    callback(new Error("密码为8-16位"));
                  }
                }
              }
              callback();
            },
            trigger: 'change'
          }],
        },
      };
    },
    created() {
      let that = this;
      let id = that.$route.params.id;
      var flag = that.$route.query.flag;
      that.isSysUserRange = flag;
      var detailUrl = null;
      if (id != null && typeof(id) != "undefined" && id != "") {
        that.Title = "编辑用户信息";
        that.ruleForm.reasonId = id;
        detailUrl = "/api/admin/sysUser/" + id;
      }
      that.fullscreenLoading = true;
      ajaxGet(that, '/api/admin/college', null, function(resCollege) {
        that.optCollege = [{
          collegeId : null,
          name:'无'
        }];
        for(var it of resCollege){
          that.optCollege.push({
            collegeId:it.collegeId,
            name:it.name
          })
        }
        ajaxGet(that, detailUrl, null, function(r) {
          if (r != null) {
            that.ruleForm = r;
            that.userRoles = [];
            for (var it of that.ruleForm.userRoles) {
              that.userRoles.push(it.roleId);
            }
          }
          if(!regNull(that.ruleForm.photo)){
            that.imageUrl = that.$ServerUrl() + that.ruleForm.photo;
          }
          that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
          that.userRolesReset = JSON.parse(JSON.stringify(that.userRoles));

          ajaxGet(that, "/api/admin/department/treelist", null, function(r1) {
            that.depts = that.getTreeData(r1);
            that.allDept = [];
            InitItem(that, that.depts);
            if (r != null) {
              that.userDepartments = [];
              for (var it of that.ruleForm.userDepartments) {
                that.idArr = [];
                GetKeys(that, it.departmentId);
                var temp = [];
                if (that.idArr.length == 1) {
                  temp = that.idArr;
                } else {
                  for (var i = (that.idArr.length - 1); i >= 0; i--) {
                    temp.push(that.idArr[i]);
                  }
                }
                that.userDepartments.push(temp);
              }
            }

            that.userDepartmentsReset = JSON.parse(JSON.stringify(that.userDepartments));
          })

          ajaxGet(that, "/api/admin/sysRole", null, function(r1) {
            that.roles = r1;
          })

          that.fullscreenLoading = false;

        });
      });
    },
    methods: {
      beforeAvatarUpload(file) {
        let that = this;
        const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < webUploadImgSize();
        if (!isJPG) {
          warning(that, '上传头像图片只能是 JPG 格式!');
        }
        if (!isLt2M) {
          warning(that, '上传头像图片大小不能超过 ' + webUploadImgSize() + 'M!');
        }
        return isJPG && isLt2M;
      },
      // 上传照片
      uploadSectionFile(param) {
        let that = this;
        var fileObj = param.file;
        // 接收上传文件的后台地址
        var url = "/api/admin/file/upload";
        let files = new FormData();
        files.append('file', fileObj);
        files.append('tag', "avatar"); //头像存放地址
        that.fullscreenLoading = true;
        ajaxUpload(that, url, files, function(r) {
          that.fullscreenLoading = false;
          if (r.result == true) {
            let file = {
              uid: param.file.uid,
              url: that.$ServerUrl() + r.path,
              path: r.path,
              name: param.file.name,
              size: param.file.size,
            };

            that.ruleForm.photo = file.path;
            that.imageUrl = file.url;
          } else {
            warning(that, r.message);
          }
        });
      },
      getTreeData(data) {
        // 循环遍历json数据
        for (var i = 0; i < data.length; i++) {

          if (data[i].children.length < 1) {
            // children若为空数组，则将children设为undefined
            data[i].children = undefined;
          } else {
            // children若不为空数组，则继续 递归调用 本方法
            this.getTreeData(data[i].children);
          }
        }
        return data;
      },
      onSave() {
        let that = this;
        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            that.ruleForm.userRoles = [];
            for (var it of that.userRoles) {
              that.ruleForm.userRoles.push({
                roleId: it,
              });
            }
            that.ruleForm.userDepartments = [];
            for (var it of that.userDepartments) {
              that.ruleForm.userDepartments.push({
                departmentId: it[it.length - 1],
              });
            }
            //新增
            if (that.ruleForm.userId == null) {
              var data = {
                userName: that.ruleForm.userName,
                password: that.$md5(that.ruleForm.password),
                name: that.ruleForm.name,
                enable: that.ruleForm.enable,
                photo:that.ruleForm.photo,
                userDepartments: that.ruleForm.userDepartments,
                userRoles: that.ruleForm.userRoles,
              }
              if (data.userRoles == "") {
                confirmDelete(that, '当前没有设置角色，会造成无法登录。确定要这样设置吗', function(res) {
                  if (res == true) {
                    that.fullscreenLoading = true;
                    ajaxPost(that, "/api/admin/sysUser", data, function(r) {
                      that.fullscreenLoading = false;
                      if (that.isSysUserRange == 'true') {
                        that.$router.push({
                          path: '/Admin/User/Filter/' + r.message,
                          query: {
                            flag: that.isSysUserRange
                          },
                        });
                      } else {
                        that.$router.push('/Admin/User/Index');
                      }
                    });
                  }
                })
              } else {
                that.fullscreenLoading = true;
                ajaxPost(that, "/api/admin/sysUser", data, function(r) {
                  that.fullscreenLoading = false;
                  if (that.isSysUserRange == 'true') {
                    that.$router.push({
                      path: '/Admin/User/Filter/' + r.message,
                      query: {
                        flag: that.isSysUserRange
                      },
                    });
                  } else {
                    that.$router.push('/Admin/User/Index');
                  }
                });
              }
            } else {
              that.fullscreenLoading = true;
              ajaxPut(that, "/api/admin/sysUser/" + that.ruleForm.userId, that.ruleForm, function(r) {
                that.fullscreenLoading = false;
                that.onBack();
              });
            }
          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              isError[0].querySelector('input').focus();
            }, 1)
            return false;
          }
        });
      },
      onReset() {
        let that = this;
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
        that.userDepartments = JSON.parse(JSON.stringify(that.userDepartmentsReset));
        that.userRoles = JSON.parse(JSON.stringify(that.userRolesReset));
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      onBack() {
        let that = this;
        back(that);
      },
    },
  };

  function InitItem(that, val) {
    if (val.length !== 0) {
      val.forEach((item) => {
        that.allDept.push(item);
        if (item.children != null && typeof(item.children) != "undefined" && item.children.length >= 1) {
          InitItem(that, item.children);
        }
      });

    }
  }

  function GetKeys(that, id) {
    for (var i in that.allDept) {
      if (that.allDept[i].id == id) {
        that.idArr.push(id);
        if (that.allDept[i].parentId != null && that.allDept[i].parentId != '11111111-1111-1111-1111-111111111111') {
          GetKeys(that, that.allDept[i].parentId);
        }
      }
    }
  }
</script>

<style scoped="scoped">
  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }

  .spIcon {
    width: 5px;
    height: 20px;
    border-radius: 5px;
    background-image: linear-gradient(#4545FF, #2D8EFF);
  }

  .spTitle {
    float: left;
    margin-left: 20px;
    color: #323E56;
    letter-spacing: 2px;
    font-size: 16px;
    margin-top: -3px;
  }

  .wMax {
    width: 100% !important;
  }
  .divStuAva {
    padding-bottom: 30px;
  }

  .divStuAva .avatar {
    width: 180px;
    display: block;
    border-radius: 5px;
  }
  .divStuAva .spImgFont {
    font-size: 12px;
    color: #767676;
    ;
  }
</style>
<style>
  .divStuAva .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .divStuAva .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .divStuAva .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 180px;
    height: 252px;
    text-align: center;
    line-height: 252px;
  }
</style>
