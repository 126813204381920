<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          班主任月度考核详情
        </h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-3 colInfo">
            <label>考核时间</label>
            <div class="colFonts">
              {{ruleForm.time | nullData}}
            </div>
          </div>
          <div class="col-lg-3 colInfo">
            <label>班级</label>
            <div class="colFonts">
              {{ruleForm.className | nullData}}
            </div>
          </div>
          <div class="col-lg-3 colInfo">
            <label>班主任</label>
            <div class="colFonts">
              {{ruleForm.teacher | nullData}}
            </div>
          </div>
          <div class="col-lg-3 colInfo">
            <label>月度考核分数</label>
            <div class="colFonts">
              {{ruleForm.score | nullData}}
            </div>
          </div>
          <div class="col-lg-3 colInfo">
            <label>月度考核结果</label>
            <div class="colFonts">
              {{ruleForm.resultStr | nullData}}
            </div>
          </div>
        </div>
      </div>
    </el-card>

    <el-card class="box-card" v-for="(item,index) in ruleForm.items" :key="index">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          {{item.name}}
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <el-tag>小计：{{item.score}}分</el-tag>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body">
        <div class="divSubBox" v-for="(list,index2) in item.details" :key="index2">
          <div class="divSubTitle">
            <span class="spTitle">{{list.name}}</span>
            <span class="spFont">小计：{{list.score}}分</span>
          </div>
          <div class="divChildrenBox">
            <el-table :data="onGetChildren(list.children)" border class="elTableData">
              <el-table-column prop="name" label="项名" min-width="400">
              </el-table-column>
              <el-table-column prop="" label="分数" min-width="100">
                <template slot-scope="scope">
                  <span class="color-success" v-if="scope.row.score<0">{{scope.row.score}}</span>
                  <span class="color-danger" v-else>{{'+'+scope.row.score}}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          备注
        </h3>
      </div>
      <div class="card-body">
        <div class="colRemarksCont" v-html="ruleForm.description | nullData"></div>
      </div>
    </el-card>



    <div class="row">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack">返回</el-button>
      </div>
    </div>


  </div>
</template>

<script>
  import "@/assets/css/Admin.css";

  export default {
    components: {},
    data() {
      return {
        fullscreenLoading: false,
        ruleForm: {},

      };
    },
    created() {
      let that = this;
      if (!regNull(that.$route.params.Id)) {
        that.fullscreenLoading = true;
        ajaxGet(that, '/api/admin/advisermonthlyassessment/' + that.$route.params.Id, null, function(resData) {
          that.fullscreenLoading = false;
          that.ruleForm = resData;
        });
      }
    },
    methods: {
      onGetChildren(objData){
        return objData.filter(item=> item.score!=0);
      },
      /**
       * @description 返回
       */
      onBack() {
        let that = this;
        back(that);
      },
    },
  };
</script>

<style scoped="scoped">
  .divSubBox {
    padding-bottom: 30px;
  }

  .divChildrenBox {
    margin-top: 15px;
  }

  .divSubTitle {
    background-color: #409EFF;
    width: 300px;
    padding: 5px 10px;
    display: flex;
    color: #FFFFFF;
    margin-top: 30px;
    border-radius: 5px;
  }

  .divSubTitle:first-child {
    margin-top: 0px;
  }

  .divSubTitle .spTitle {
    flex: 1;
  }

  .divSubTitle .spFont {}

  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }

  .spIcon {
    width: 5px;
    height: 20px;
    border-radius: 5px;
    background-image: linear-gradient(#4545ff, #2d8eff);
  }
</style>
