<!--
  日常事务 - 日常动态管理 - 自我记录
-->
<template>
  <div class="SubWebPageStudent" v-loading.fullscreen.lock="fullscreenLoading">
    <!-- 搜索 -->
    <comSearch class="dataRange" :ColNumber="3" :SearchList="SearchList" ref="SearchList" @onSearch="onSearch"
      v-if="SearchList!=null">
    </comSearch>
    <!-- 列表-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          获奖上报
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <el-button type="success" icon="el-icon-circle-plus-outline"
                @click="onCreate()">新增</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table :data="itemData" stripe class="elTableData">
          <!-- <el-table-column type="index" label="序号" width="80"></el-table-column> -->
          <el-table-column prop="name" label="获奖名称" min-width="200"></el-table-column>
          <el-table-column label="获奖时间" min-width="140">
            <template slot-scope="scope">
              {{scope.row.recordDate | dateformatDay}}
            </template>
          </el-table-column>
          <el-table-column prop="place" label="颁奖单位" min-width="200"></el-table-column>
          <el-table-column prop="auditState" label="状态" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.auditState==1">{{scope.row.auditStateString}}</span>
              <span v-if="scope.row.auditState==2 || scope.row.auditState==4" class="color-success">{{scope.row.auditStateString}}</span>
              <span v-if="scope.row.auditState==3 || scope.row.auditState==5" class="color-danger">{{scope.row.auditStateString}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="220" fixed="right">
            <template slot-scope="scope">
              <operationBtn  :btnName="'查看'"
                @click.native="onDetail(scope.row.reportId)"></operationBtn>
              <operationBtn v-if="scope.row.auditState==1 " :btnName="'编辑'"
                @click.native="onEdit(scope.row.reportId)"></operationBtn>
              <operationBtn v-if="scope.row.auditState==1" :btnName="'删除'"
                @click.native="onDelete(scope.row.reportId)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>

        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>


    </el-card>
  </div>
</template>

<script>
  import '@/assets/css/Student.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import comSearch from '@/components/search.vue';

  export default {
    components: {
      operationBtn,
      comPage,
      comSearch
    },
    data() {
      return {
        // 动画
        fullscreenLoading: false,
        dialogVisible: false,
        dialogVisiblePhoto: false,
        // 搜索
        SearchList: null,
        //列表数据
        itemData: [],
        //分页查询接口返回的结果
        page: null,
        //分页左侧按钮集合
        pageBtnList: null,
        //搜索条件日期
        PunishDate: [],
        RecordDateStart: null,
        RecordDateEnd: null,
        //分页数据
        paging: {
          // 总共页数
          pageLength: 0,
          // 每页请求的数据量
          Size: 1,
          //当前在第几页
          Index: 1
        },
      };
    },
    methods: {
      onSearch(params) // 搜索
      {
        let that = this;
        let data = {};
        let searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = Join(element.data);
          }
        });

        routerPath(that, "/Student/SelfReport/Index", data, null, "PageSize");
      },
      onCreate() {
        let that = this;
        that.$router.push({
          path: "/Student/SelfReport/Create",
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onDetail(Id) {
        let that = this;
        that.$router.push({
          path: "/Student/SelfReport/Details/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onEdit(Id) {
        let that = this;
        that.$router.push({
          path: "/Student/SelfReport/Edit/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onDelete(Id) //删除
      {
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, "/api/student/selfreport/" + Id, null, function(r) {
              that.fullscreenLoading = false;
              onGetDataPage(that);
            });
          }
        })
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      // 分页面按钮
      pagingClick(type, val) {
        let that = this;
        let url = "/Student/SelfReport/Index";
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "SemesterId",
            "PunishDate",
            "Name"
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "SemesterId",
            "PunishDate",
            "Name"
          );
        }
      }
    },
    created() {
      let that = this;
      onGetDataPage(that);
    },
    mounted() {

    },
    watch: {
      $route() {
        let that = this;
        onGetDataPage(that);
      }
    }
  };

  function onGetDataPage(that) {
    paging(
      that,
      function(res) {
        let apiUrl = "/api/student/selfreport/search";
        that.fullscreenLoading = true;
        // 搜索 下拉学期
        ajaxGet(that, "/api/student/semester/student", null, function(resSemester) {
          // 获得学期数据
          var SemesterId = res.SemesterId;
          let arrSemester = [];
          for (var i in resSemester) {
            let arrTemp = {
              Title: resSemester[i].name, //semester[item].name,
              Value: resSemester[i].semesterId //semester[item].semesterId,
            };
            if (SemesterId == null || typeof(SemesterId) == "undefined" || SemesterId == "") {
              if (resSemester[i].isCurrent == true) {
                SemesterId = resSemester[i].semesterId;
              }
            }
            arrSemester.push(arrTemp);
          }
          that.SearchList = [{
              type: "input",
              Name: "获奖名称",
              data: res.Name,
              holder: "请输入获奖名称",
              zhi: "Name"
            },
            {
              type: "time",
              Name: "获奖时间",
              data: res.PunishDate,
              zhi: "PunishDate"
            },
            // 学期
            {
              type: "select",
              Name: "学期",
              data: SemesterId,
              holder: "请选择学期",
              select: arrSemester, //选择的数据
              zhi: "SemesterId", // 传值
              isClear: false, //select是否可以清除
              resetData: SemesterId,
            },

          ];


          let data = {
            PageNumer: res.PageNumer,
            PageSize: res.PageSize,
            SemesterId: SemesterId,
            Name: res.Name,
          };
          if (res.PunishDate != null) {
            that.PunishDate = res.PunishDate.split(",");
            that.RecordDateStart = that.PunishDate[0];
            that.RecordDateEnd = that.PunishDate[1];
            data = ({
              PageNumer: res.PageNumer,
              PageSize: res.PageSize,
              SemesterId: SemesterId,
              Name: res.Name,
              RecordDateStart: that.RecordDateStart,
              RecordDateEnd: that.RecordDateEnd,
            })
          }

          ajaxGet(that, apiUrl, data, function(resData) {
            that.page = resData;
            that.itemData = resData.items;
            that.fullscreenLoading = false;
          });
        })

      },
      "Name",
      "SemesterId",
      "PunishDate",
    );
  }
</script>
<style scoped="scoped">
  .dataRange>>>.el-range-separator {
    width: 10%;
  }
</style>
