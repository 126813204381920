<!--
  学生管理-学生基础信息
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <div style="display: flex;">
          <h3 class="card-title titleCont" style="width:100%; padding-left: 0px; flex: 1;">
            <ul class="nav nav-pills navTabsCont">
              <li class="nav-item" v-for="(p,index) in SearchTitles" :key="index">
                <router-link :to="p.url" :class="p.isCurrent==true?'nav-link active':'nav-link'">
                  {{p.name}}
                </router-link>
              </li>
            </ul>
          </h3>
        </div>
      </div>
      <div class="card-body ">
        <el-row :gutter="20">
          <el-col :lg="6" :sm="12" class="mb-3" v-if="!TeacherInfo.isTeacher">
            <div class="selTitleBox">
              <span class="spTitle">宿舍楼</span>
            </div>
            <div class="selBodyBox">
              <el-cascader ref="searchBuilding" v-model="searchForm.buildingIds" :options="optBuilding"
                :props="{ expandTrigger: 'hover' ,checkStrictly:true}" filterable clearable class="width_100Pie"
                @change="onChangeBuilding"></el-cascader>
            </div>
          </el-col>
          <el-col :lg="6" :sm="12" class="mb-3" v-if="!TeacherInfo.isTeacher">
            <div class="selTitleBox">
              <span class="spTitle">年级</span>
            </div>
            <div class="selBodyBox">
              <el-select v-model="searchForm.CollegeId" filterable clearable placeholder="请选择" class="width_100Pie"
                @change="onChangeCollege" @clear="onClearCollege">
                <el-option v-for="item in optCollege" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :lg="6" :sm="12" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">班级</span>
            </div>
            <div class="selBodyBox">
              <el-select v-model="searchForm.ClassId" filterable :clearable='!TeacherInfo.isTeacher'
                placeholder="请选择" class="width_100Pie">
                <el-option v-for="item in optClass" :key="item.classId" :label="item.alias" :value="item.classId">
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :lg="6" :sm="12" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">学生</span>
            </div>
            <div class="selBodyBox">
              <el-input placeholder="请输入学号/姓名" v-model="searchForm.Student" clearable></el-input>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="card-footer text-center">
        <el-button type="primary" class="mr-2" slot="append" icon="el-icon-search" @click.native="onSearch()">查询
        </el-button>
        <el-button slot="append" class="mr-2" icon="el-icon-refresh" @click.native="onResetSearch()">重置
        </el-button>
      </div>
    </el-card>

    <!-- 列表-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          {{Title}}名单
        </h3>
        <div class="card-tools">
          <ul class="nav nav-pills ml-auto">
            <li class="nav-item">
              <el-button v-if="isPower.dormstaysuspend_create == true" type="success" icon="el-icon-circle-plus-outline"
                @click="onCreate()">新增</el-button>
            </li>
            <!-- 留宿 -->
            <li class="nav-item ml-1"
              v-if="currentType == 1 && (isPower.dormstaysuspend_export)">
              <el-button @click="onOpenExportStay()" icon="el-icon-download"
                style="background-color:#17a2b8	;color: white;">
                导出
              </el-button>
            </li>
            <li class="nav-item ml-1"
              v-if="isPower.dormstaysuspend_import">
              <comImport @onImportSuccess="onImportSuccess" :template="'/templates/学生留宿信息导入模版.xlsx'" :path="uploadInfoApi"
                :btnIcon="'el-icon-upload2'" :title="'批量导入留宿记录'" :btnName="'导入'" ref="conImportInfoFile">
              </comImport>
            </li>
            <!-- 停宿 -->
            <li class="nav-item ml-1"
              v-if="currentType == 2 && (isPower.dormstaysuspend_export)">
              <el-button @click="onOpenExportStop()" icon="el-icon-download"
                style="background-color:#17a2b8	;color: white;">
                导出
              </el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table :data="itemData" stripe class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod">
          </el-table-column>
          <el-table-column prop="building" label="楼名" class="elColumn"></el-table-column>
          <el-table-column prop="floor" label="楼层" class="elColumn"></el-table-column>
          <el-table-column prop="room" label="房号" class="elColumn"></el-table-column>
          <el-table-column prop="bed" label="床位" class="elColumn"></el-table-column>
          <el-table-column prop="studentNo" label="学号" class="elColumn" min-width="120"></el-table-column>
          <el-table-column prop="name" label="姓名" class="elColumn" min-width="140" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="mobile" label="联系电话" class="elColumn" width="140"></el-table-column>
          <el-table-column label="操作" width="200" fixed="right">
            <template slot-scope="scope">
              <operationBtn :btnName="'查看'"
                v-if="currentType == 1 && isPower.dormstaysuspend_details"
                @click.native="onDetails(scope.row.stayId)"></operationBtn>
              <operationBtn :btnName="'查看'"
                v-if="currentType != 1 && isPower.dormstaysuspend_details"
                @click.native="onDetails(scope.row.suspendId)"></operationBtn>
              &nbsp;
              <el-dropdown
                v-if="currentType == 1 && (isPower.dormstaysuspend_edit  || isPower.dormstaysuspend_delete )"
                trigger="click" @command="handleCommand($event,scope.row.stayId)">
                <el-button size="mini">
                  更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="isPower.dormstaysuspend_edit" class="color-warning"
                    command="onEdit">编辑</el-dropdown-item>
                  <el-dropdown-item divided
                    v-if="isPower.dormstaysuspend_delete"
                    class="color-danger" command="onDelete">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-dropdown
                v-if="currentType != 1 && (isPower.dormstaysuspend_edit  || isPower.dormstaysuspend_delete )"
                trigger="click" @command="handleCommandSuspend($event,scope.row.suspendId)">
                <el-button size="mini">
                  更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="isPower.dormstaysuspend_edit" class="color-warning"
                    command="onEdit">编辑</el-dropdown-item>
                  <el-dropdown-item divided
                    v-if="isPower.dormstaysuspend_delete"
                    class="color-danger" command="onDelete">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>

      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import comImport from '@/components/Import.vue';
  export default {
    name: '',
    components: {
      comPage,
      operationBtn,
      comImport,
    },
    data() {
      return {
        // 动画
        fullscreenLoading: false,
        dialogVisible: false,
        optCollege: [],
        optClass: [],
        optClassInit: null,
        Title: null,
        SearchTitles: [],
        // 搜索
        SearchList: null,
        //列表数据
        itemData: [],

        //分页查询接口返回的结果
        page: null,
        //分页左侧按钮集合
        pageBtnList: null,
        //分页数据
        paging: {
          // 总共页数
          pageLength: 0,
          // 每页请求的数据量
          Size: 10,
          //当前在第几页
          Index: 1
        },
        uploadInfoApi:'/api/admin/dormstay/import',
        isPower: {
          //增删改查 权限管理
          dormstaysuspend_details: false,
          dormstaysuspend_create: false,
          dormstaysuspend_edit: false,
          dormstaysuspend_delete: false,
          dormstaysuspend_export: false,
          dormstaysuspend_import:false,

        },

        // 搜索集合
        searchForm: {
          Student: null,
          CollegeId: null,
          ClassId: null,
        },
        searchFormReset: null,
        thWidth: 100,
        currentType: 1,

        optBuilding: [],

        TeacherInfo: {}, // 教师信息
      };
    },
    methods: {
      onImportSuccess(param) {
        let that = this;
        that.paging.Index = 1;
        Init(that);
      },
      /**
       * @description 更多操作
       * @param {Object} command
       * @param {Object} objValID
       */
      handleCommand(command, objValID) {
        let that = this;
        switch (command) {
          case 'onEdit':
            that.onEdit(objValID);
            break;
          case 'onTeacherEdit':
            that.onTeacherEdit(objValID);
            break;
          case 'onDelete':
            that.onDelete(objValID);
            break;
        }
      },
      /**
       * @description 更多操作
       * @param {Object} command
       * @param {Object} objValID
       */
      handleCommandSuspend(command, objValID) {
        let that = this;
        switch (command) {
          case 'onEdit':
            that.onEdit(objValID);
            break;
          case 'onTeacherEdit':
            that.onTeacherEdit(objValID);
            break;
          case 'onDelete':
            that.onDelete(objValID);
            break;
        }
      },
      /**
       * @description 留宿
       */
      onOpenExportStay() {
        let that = this;
        that.fullscreenLoading = true;
        let tpBuildingId = null;
        let tpFloorId = null;

        if (!regNullArray(that.searchForm.buildingIds)) {
          if (that.searchForm.buildingIds.length == 1) {
            tpBuildingId = that.searchForm.buildingIds[0];
          } else if (that.searchForm.buildingIds.length == 2) {
            tpBuildingId = that.searchForm.buildingIds[0];
            tpFloorId = that.searchForm.buildingIds[1];
          }
        }
        let data = {
          Student: that.searchForm.Student,
          ClassId: that.searchForm.ClassId,
          CollegeId: that.searchForm.CollegeId,
          BuildingId: tpBuildingId, // 楼
          FloorId: tpFloorId, // 层
        };
        ajaxExport(that, "/api/admin/dormstay/export", data, '学生留宿记录_' + timeTransform(new Date(), 'YYYYMMDDHHmmss') +
          '.xls',
          function(resData) {
            that.fullscreenLoading = false;
          });
      },
      /**
       * @description 停宿
       */
      onOpenExportStop() {
        let that = this;
        that.fullscreenLoading = true;
        let tpBuildingId = null;
        let tpFloorId = null;

        if (!regNullArray(that.searchForm.buildingIds)) {
          if (that.searchForm.buildingIds.length == 1) {
            tpBuildingId = that.searchForm.buildingIds[0];
          } else if (that.searchForm.buildingIds.length == 2) {
            tpBuildingId = that.searchForm.buildingIds[0];
            tpFloorId = that.searchForm.buildingIds[1];
          }
        }
        let data = {
          Student: that.searchForm.Student,
          ClassId: that.searchForm.ClassId,
          CollegeId: that.searchForm.CollegeId,
          BuildingId: tpBuildingId, // 楼
          FloorId: tpFloorId, // 层
        };
        ajaxExport(that, "/api/admin/dormsuspend/export", data, '学生停宿记录_' + timeTransform(new Date(),
            'YYYYMMDDHHmmss') + '.xls',
          function(resData) {
            that.fullscreenLoading = false;
          });
      },
      onChangeBuilding() {
        let that = this;
        that.$refs.searchBuilding.dropDownVisible = false;
      },
      onResetSearch() {
        let that = this;
        that.searchForm = JSON.parse(JSON.stringify(that.searchFormReset));
        that.paging.Index = 1;
        that.searchForm.buildingIds = [];
        newRouterPath(that)
      },
      onClearCollege() {
        let that = this;
        that.searchForm.ClassId = null;
        that.optClass = [];
        that.optClass = that.optClassInit;
      },
      onChangeCollege(res) {
        let that = this;
        that.searchForm.ClassId = null;
        that.optClass = [];
        that.optClass = that.optClassInit.filter(item => item.collegeId == res);
      },
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },
      /**
       * @description 搜索事件
       */
      onSearch() {
        let that = this;
        that.paging.Index = 1;
        newRouterPath(that);
      },
      //分页组件左侧的按钮事件，按顺序进行判断
      pageBtnClick(index) {},
      /**
       * @description 分页面按钮
       * @param {Object} type
       * @param {Object} val
       */
      pagingClick(type, val) {
        let that = this;
        if (type == 1) {
          //更改每页条数触发的事件
          that.paging.Size = val;
          that.paging.Index = 1;
        } else {
          //更改当前页时触发的事件
          that.paging.Index = val;
        }
        newRouterPath(that);
      },
      /**
       * @description 编辑跳转
       * @param {Object} objID
       */
      onEdit(objID) {
        let that = this;
        that.$router.push({
          path: "/Admin/DormStaySuspend/Edit/" + that.currentType + "/" + objID,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      /**
       * @description 班主任编辑跳转
       * @param {Object} objID
       */
      onTeacherEdit(objID) {
        let that = this;
        that.$router.push({
          path: "/Admin/DormStaySuspend/TeacherEdit/" + that.currentType + "/" + objID,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      /**
       * @description 详情
       * @param {Object} objID
       */
      onDetails(objID) {
        let that = this;
        that.$router.push({
          path: "/Admin/DormStaySuspend/Details/" + that.currentType + "/" + objID,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      /**
       * @description 新增跳转
       */
      onCreate() {
        let that = this;
        that.$router.push({
          path: "/Admin/DormStaySuspend/Create/" + that.currentType,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      /**
       * @description 班主任新增
       */
      onTeacherCreate() {
        let that = this;
        that.$router.push({
          path: "/Admin/DormStaySuspend/TeacherCreate/" + that.currentType,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      /**
       * @description 删除
       * @param {Object} Id
       */
      onDelete(Id) {
        let that = this;
        let apiUrl = '';
        if (that.currentType == 1) {
          apiUrl = '/api/admin/dormstay/' + Id;
        } else {
          apiUrl = '/api/admin/dormsuspend/' + Id;
        }
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, apiUrl, null, function(r) {
              that.fullscreenLoading = false;
              pagingPage(that);
            });
          }
        })
      },
    },
    created() {
      let that = this;
      that.TeacherInfo = onGetNowUser(that);
      isPower(that, "dormstaysuspend_details", "dormstaysuspend_details");
      isPower(that, "dormstaysuspend_create", "dormstaysuspend_create");
      isPower(that, "dormstaysuspend_edit", "dormstaysuspend_edit");
      isPower(that, "dormstaysuspend_delete", "dormstaysuspend_delete");
      isPower(that, "dormstaysuspend_export", "dormstaysuspend_export");
      isPower(that,"dormstaysuspend_import","dormstaysuspend_import");
      isPower(that, "teacher_dormstaysuspend_edit", "teacher_dormstaysuspend_edit");
      isPower(that, "teacher_dormstaysuspend_delete", "teacher_dormstaysuspend_delete");
      isPower(that, "teacher_dormstaysuspend_details", "teacher_dormstaysuspend_details");
      isPower(that, "teacher_dormstaysuspend_create", "teacher_dormstaysuspend_create");
      isPower(that, 'teacher_dormstaysuspend_index', 'teacher_dormstaysuspend_index');
      isPower(that, 'teacher_dormstaysuspend_export', 'teacher_dormstaysuspend_export');



      Init(that);

    },
    watch: {
      '$route': function(to) {
        let that = this;
        that.paging.Index = 1;
        that.paging.Size = 10;
        Init(that);
      },
      searchFormReset(newVal) {
        this.searchFormReset = newVal;
      }
    }

  };

  function Init(that) {

    // 非班主任可以不用寝室楼搜索
    if (!that.TeacherInfo.isTeacher) {
      ajaxGet(that, '/api/admin/building/usertree', null, function(resBuilding) {
        let tpData = [];
        if (!regNullArray(resBuilding)) {
          resBuilding.forEach((item) => {
            tpData.push({
              value: item.buildingId,
              label: item.name,
              parentId: null,
            });
            if (!regNullArray(item.floors)) {
              item.floors.forEach((list) => {
                tpData.push({
                  value: list.floorId,
                  label: list.name + '（层）',
                  parentId: item.buildingId,
                });
              });
            }
          });
          if (!regNullArray(tpData)) {
            that.optBuilding = onGetJsonToTree(tpData, 'value', 'parentId', 'children');
          }
        }
      });
    }



    that.currentType = that.$route.query.type ? that.$route.query.type : 1;
    that.SearchTitles = [];
    // that.SearchTitles.push({
    //   name: '留宿',
    //   isCurrent: that.currentType == 1 ? true : false,
    //   url: '/Admin/DormStaySuspend/Index?type=1'
    // }, {
    //   name: '停宿',
    //   isCurrent: that.currentType == 2 ? true : false,
    //   url: '/Admin/DormStaySuspend/Index?type=2'
    // })
    that.Title = that.currentType == 1 ? "留宿" : "停宿";

    let resArrUnique = new Map(),
      arrCollege = [];

    let resultClass = that.TeacherInfo.isTeacher ? that.TeacherInfo.manageClass : that.TeacherInfo.allClass;


    resultClass.filter((arr) => !resArrUnique.has(arr.collegeId) && resArrUnique.set(arr.collegeId, 1))
      .forEach(function(item) {
        arrCollege.push({
          label: item.college,
          value: item.collegeId,
        });
      });
    that.optCollege = arrCollege;
    that.optClass = resultClass;
    that.optClassInit = JSON.parse(JSON.stringify(resultClass));
    // that.searchForm.CollegeId = that.optCollege[0].value;
    that.searchForm.ClassId = null;

    if (that.TeacherInfo.isTeacher) {
      that.searchForm.ClassId = resultClass[0].classId;
    }

    that.searchForm.Student = null;
    that.searchFormReset = JSON.parse(JSON.stringify(that.searchForm));

    let queryDate = that.$route.query;
    for (const key in queryDate) {
      if (queryDate[key] != null && queryDate[key] != "" && typeof(queryDate[key]) != "undefined") {
        if (key == "PageSize") {
          that.paging.Size = queryDate[key];
        } else if (key == "PageNumer") {
          that.paging.Index = queryDate[key];
        } else {
          that.searchForm[key] = queryDate[key];
        }
      }
    }

    pagingPage(that);
    that.thWidth = onGetWidth(that, [
      that.isPower.dormstaysuspend_details,
      that.isPower.dormstaysuspend_edit,
      that.isPower.dormstaysuspend_delete,
    ]);

    //用于重置搜索
    that.thWidth = onGetWidth(that, [
      that.isPower.student_details,
      that.isPower.student_edit || that.isPower.student_updatestatus || that.isPower.student_resetpwd || that
      .isPower.student_delete
    ]) + 50;
  }

  function newRouterPath(that) {
    let data = {
      PageNumer: that.paging.Index,
      PageSize: that.paging.Size,
      Student: that.searchForm.Student,
      ClassId: that.searchForm.ClassId,
      CollegeId: that.searchForm.CollegeId,
      buildingIds: that.searchForm.buildingIds,
    };
    let routeData = {};
    for (const key in data) {
      if (data[key] != null && data[key] != "" && typeof(data[key]) != "undefined") {
        routeData[key] = data[key];
      }
    }
    that.$router.push({
      path: "/Admin/DormStaySuspend/Index?type=" + that.currentType,
      query: routeData
    })
  }
  // 加载数据
  function pagingPage(that) {
    that.fullscreenLoading = true;
    let tpBuildingId = null;
    let tpFloorId = null;

    if (!regNullArray(that.searchForm.buildingIds)) {
      if (that.searchForm.buildingIds.length == 1) {
        tpBuildingId = that.searchForm.buildingIds[0];
      } else if (that.searchForm.buildingIds.length == 2) {
        tpBuildingId = that.searchForm.buildingIds[0];
        tpFloorId = that.searchForm.buildingIds[1];
      }
    }

    let data = {
      PageNumer: that.paging.Index,
      PageSize: that.paging.Size,
      Student: that.searchForm.Student,
      ClassId: that.searchForm.ClassId,
      CollegeId: that.searchForm.CollegeId,
      BuildingId: tpBuildingId, // 楼
      FloorId: tpFloorId, // 层
    };
    let url = "";
    if (that.currentType == 1) {
      url = "/api/admin/dormstay/search";
    } else {
      url = "/api/admin/dormsuspend/search";
    }
    ajaxGet(that, url, data, function(resData) {
      that.page = resData;
      that.itemData = resData.items;
      that.fullscreenLoading = false;
    });
  }

</script>

<style scoped="scoped">
  .navTabsCont .nav-link {
    font-size: 12px;
  }


  .selTitleBox {

    font-size: 14px;
  }

  .selTitleBox .spTitle {
    color: #303133;
  }

  .selBodyBox {
    padding-top: 5px;
  }

  .divAlertCont {
    margin-bottom: 0.9375rem;
  }

  .divAlertCont .divTipCot p {
    margin-bottom: 0.3125rem !important;
  }

  .btnCheckAll label {
    margin-bottom: 0;
  }

</style>
<style>
  .studentIndex .importStyle {
    font-size: 12px;
    font-weight: normal;
    padding: 9px 15px;
  }

  label {
    margin-bottom: 0;
  }

</style>

