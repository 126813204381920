<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          搜索
        </h3>
      </div>
      <div class="card-body ">
        <el-form ref="form" :model="SearchList" label-width="80px">
          <el-row :gutter="20">
            <el-col :lg="8" :sm="12">
              <div class="selTitleBox">
                <span class="spTitle">类型</span>
              </div>
              <div class="selBodyBox">
                <el-select v-model="SearchList.typeId" placeholder="请选择" class="width_100Pie"
                  @change="onChangeType">
                  <el-option v-for="item in SearchList.optType" :key="item.value" :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :lg="8" :sm="12" v-if="SearchList.typeId==2">
              <div class="selTitleBox">
                <span class="spTitle">学年</span>
              </div>
              <div class="selBodyBox">
                <el-select v-model="SearchList.yearID" placeholder="请选择" clearable class="width_100Pie"
                  @change="onChangeYear">
                  <el-option v-for="item in SearchList.optYear" :key="item.yearId" :label="item.year"
                    :value="item.yearId">
                  </el-option>
                </el-select>
              </div>
            </el-col>
           <el-col :lg="8" :sm="12" v-if="SearchList.typeId==3">
              <div class="selTitleBox">
                <span class="spTitle">学期</span>
              </div>
              <div class="selBodyBox">
                <el-select v-model="SearchList.semesterID" clearable class="width_100Pie" placeholder="请选择">
                  <el-option v-for="item in SearchList.optSemester" :key="item.semesterId" :label="item.name"
                    :value="item.semesterId">
                  </el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="card-footer text-center">
        <el-button type="primary" class="mr-2" slot="append" icon="el-icon-search" @click.native="onSearch()">查询
        </el-button>
        <el-button slot="append" class="mr-2" icon="el-icon-refresh" @click.native="onResetSearch()">重置</el-button>
      </div>
    </el-card>


    <!-- 列表-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          总评配置
        </h3>
        <div class="card-tools" v-if="isPower.evaluationconfig_create">
          <ul class="nav nav-pills ml-auto">
            <li class="nav-item">
              <el-button type="success" icon="el-icon-circle-plus-outline" @click="onCreate()">新增</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table :data="itemData" stripe class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column label="评比时间" class="elColumn" min-width="180">
            <template slot-scope="scope">
              {{onGetIsNullTwo(scope.row)}}
            </template>
          </el-table-column>
          <el-table-column prop="name" label="级别名称" class="elColumn" min-width="100"></el-table-column>
          <el-table-column label="评比要求" class="elColumn" min-width="200">
            <template slot-scope="scope">
              &nbsp;
              <span class="badge badge-success" v-if="!onGetIsNull(scope.row.score)">
                {{'达标分数：'+scope.row.score}}
              </span>
              &nbsp;
              <span class="badge badge-success" v-if="!onGetIsNull(scope.row.ratio)">
                {{'名次：'+((scope.row.ratio)*100)+'%'}}
              </span>
              &nbsp;
            </template>
          </el-table-column>
          <el-table-column label="操作" width="160" fixed="right">
            <template slot-scope="scope">
              <operationBtn :btnName="'编辑'" v-if="isPower.evaluationconfig_edit" ref="update"
                @click.native="onUpdate(scope.row.levelId)"></operationBtn>
              <operationBtn :btnName="'删除'" v-if="isPower.evaluationconfig_delete" ref="update"
                @click.native="onDelete(scope.row.levelId)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>

      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>



    <!-- 弹出框 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="40%" :close-on-click-modal="false"
      :before-close="handleCloseDialog">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px">
        <el-row :gutter="24">
          <el-col :span="24" class="pr-4" v-if="ruleForm.levelId!=null">
            <el-form-item label="总评类型">
                  <div class="divCheckedBox clearfix ">
                    <span class="spChecked" v-if="!onGetIsNull(ruleForm.nowSemester.startYear)">
                      范围
                    </span>
                    <span class="spChecked " v-if="!onGetIsNull(ruleForm.nowSemester.yearId)">
                      学年
                    </span>
                    <span class="spChecked " v-if="!onGetIsNull(ruleForm.nowSemester.semesterId)">
                     学期
                    </span>
                  </div>
              <span class="text-gray" v-if="!onGetIsNull(ruleForm.nowSemester.startYear)">{{ruleForm.nowSemester.startYear}}-{{ruleForm.nowSemester.endYear}}</span>
              <span class="text-gray" v-if="!onGetIsNull(ruleForm.nowSemester.yearId)">{{ruleForm.nowSemester.academicYear}}</span>
              <span class="text-gray" v-if="!onGetIsNull(ruleForm.nowSemester.semesterId)">{{ruleForm.nowSemester.semester}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="24" class="pr-4">
            <el-form-item label="总评名称" prop="name">
              <el-input placeholder="请输入总评名称" v-model="ruleForm.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" class="pr-4">
            <el-form-item label="达标分数">
              <el-input placeholder="请输入达标分数" v-model="ruleForm.score" @change="onChangeScore"></el-input>
              <div class="divErrCont" v-if="isInputBoxScore">
                {{isInputFontScore}}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24" class="pr-4">
            <el-form-item label="名次（%）">
              <el-input placeholder="请输入名次百分比" v-model="ruleForm.ratio" @change="onChangeRatio"></el-input>
              <div class="divErrCont" v-if="isInputBoxRatio">
                {{isInputFontRatio}}
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseDialog">取 消</el-button>
        <el-button type="primary" @click="onSubmit">确 定</el-button>
      </span>
    </el-dialog>





  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  export default {
    components: {
      comPage,
      operationBtn
    },
    data() {
      return {
        dialogTitle: '新增配置',
        // 弹出框
        dialogVisible: false,
        // 加载动画
        fullscreenLoading: false,
        // 新增数组
        ruleForm: {
          levelId: null,
          name: null,
          score: null,
          ratio: null,
          isSeach: 0,
          nowSemester: {
            semesterId: null,
            yearId: null
          },
        },
        ruleFormReset: null,
        rules: {
          name: [{
              required: true,
              message: '总评名称不能为空',
              trigger: 'change'
            },
            {
              max: 20,
              message: '名称不能超过20个字符',
              trigger: 'change'
            },
          ],
        },
        optallYear: [],
        optallSemester: [],
        isCheckBoxTip: false,
        isInputBoxScore: false,
        isInputBoxRatio: false,
        isInputFontScore: '达标分数或名次请设置其中一项',
        isInputFontRatio: '达标分数或名次请设置其中一项',

        //分页查询接口返回的结果
        page: null,
        //分页左侧按钮集合
        pageBtnList: null,
        //分页数据
        paging: {
          // 总共页数
          pageLength: 0,
          // 当前请求数量
          Size: 10,
          //当前在第几页
          Index: 1
        },
        // 列表数据
        itemData: [],
        //搜索集合
        SearchList: {
          typeId:0,
          yearID: '',
          semesterID: '',
          optYear: [],
          optSemester: [],
          allSemester: [],
          optType:[
            {
              value:0,
              label:'全部'
            },
            {
              value:1,
              label:'入学年份'
            },
            {
              value:2,
              label:'学年'
            },
            {
              value:3,
              label:'学期'
            }
          ]
        },
        SearchListReset: null,
        viewForm: {},
        //增删改查 权限管理
        isPower: {
          evaluationconfig_details: false,
          evaluationconfig_edit: false,
          evaluationconfig_create: false,
          evaluationconfig_delete: false,
        },
      }
    },
    watch: {
      $route() {
        let that = this;
        onGetData(that);
      }
    },
    methods: {
      /**
       * @description 类型
       * @param {Object} objVal
       */
      onChangeType(objVal){

      },
      /**
       * @description 删除事件
       * @param {Object} val
       */
      onDelete(val) {
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, "/api/admin/growthlevel/" + val, null, function(r) {
              that.fullscreenLoading = false;
              onGetData(that);
            });
          }
        })

      },
      /**
       * @description 名次比例输入事件
       * @param {Object} val
       */
      onChangeRatio(val) {
        let that = this;
        if (!regNull(val)) {
          if (regFraction(val)) {
            that.isInputFontRatio = '请输入有效的名次比例';
            that.isInputBoxRatio = true;
          } else {
            that.isInputBoxRatio = false;
            that.isInputBoxScore = false;
          }
        } else {
          that.isInputBoxRatio = false;
          that.isInputBoxScore = false;
        }
      },
      /**
       * @description 分数输入事件
       * @param {Object} val
       */
      onChangeScore(val) {
        let that = this;
        if (!regNull(val)) {
          if (regFraction(val)) {
            that.isInputFontScore = '请输入有效的达标分数';
            that.isInputBoxScore = true;
          } else {
            that.isInputBoxScore = false;
            that.isInputBoxRatio = false;
          }
        } else {
          that.isInputBoxRatio = false;
          that.isInputBoxScore = false;
        }
      },
      /**
       * @description 判断学期、学年的选择事件
       * @param {Object} val
       */
      onChangeCk(val) {
        let that = this;
        if (that.ruleForm.isSeach != 0) {
          that.isCheckBoxTip = false;
        } else {
          that.isCheckBoxTip = true;
        }
      },

      /**
       * @description 关闭弹出框事件
       */
      handleCloseDialog() {
        let that = this;
        that.isInputBoxScore = false;
        that.isInputBoxRatio = false;
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
        that.$refs['ruleForm'].resetFields();
        that.dialogVisible = false;
      },
      onGetIsNull(_val) {
        if(_val!=0){
          return regNull(_val);
        }
        return _val;
      },
      /**
       * @description 列表中显示评比时间内容
       * @param {Object} objItem
       */
      onGetIsNullTwo(objItem) {
        if (regNull(objItem.semesterId) && regNull(objItem.yearId)) {
          return objItem.startYear+'学年 - '+objItem.endYear+'学年';
        } else if (regNull(objItem.semesterId) && !regNull(objItem.yearId)) {
          return objItem.academicYear;
        } else if (!regNull(objItem.semesterId)) {
          return objItem.semester;
        }
      },
      onUpdate(_valID) {
        let that = this;
        that.dialogTitle = '编辑配置';
        let temp = that.itemData.find(val => val.levelId == _valID);
        that.ruleForm = {
          levelId: temp.levelId,
          name: temp.name,
          score: temp.score == null ? null : setTwoDecimal(temp.score, 1),
          ratio: temp.ratio == null ? null : setTwoDecimal((temp.ratio * 100), 2),
          isSeach: 0,
          nowSemester: {
            semesterId: temp.semesterId,
            year: temp.academicYear,
            name: temp.semester,
            yearId: temp.yearId,
            endYear:temp.endYear,
            startYear:temp.startYear,
            academicYear:temp.academicYear,
            semester:temp.semester,
          },
        };
        if (temp.score == "0") {
          that.ruleForm.score = temp.score;
        } else {
          that.ruleForm.score = regNull(temp.score) ? null : setTwoDecimal(temp.score, 1);
        };
        if (temp.ratio == "0") {
          that.ruleForm.ratio = temp.ratio;
        } else {
          that.ruleForm.ratio = regNull(temp.ratio) ? null : setTwoDecimal((temp.ratio * 100), 2);
        };
        that.dialogVisible = true;
      },
      /**
       * @description 添加配置
       */
      onSubmit() {
        let that = this;
        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            let _data = {
              levelId: that.ruleForm.levelId,
              name: that.ruleForm.name,
              semesterId: that.ruleForm.isSeach == -1 || that.ruleForm.isSeach == 1 ? null : that.ruleForm
                .nowSemester.semesterId,
              yearId: that.ruleForm.isSeach == -1 || that.ruleForm.isSeach == 2 ? null : that.ruleForm
                .nowSemester.yearId,
              score: that.ruleForm.score == null ? null : setTwoDecimal(that.ruleForm.score, 1),
              ratio: that.ruleForm.ratio == null ? null : setTwoDecimal((that.ruleForm.ratio / 100), 2),
              startYear:that.ruleForm.nowSemester.startYear == null ? null : that.ruleForm.nowSemester.startYear,
              endYear:that.ruleForm.nowSemester.endYear == null ? null : that.ruleForm.nowSemester.endYear,
            }
            if (that.ruleForm.score == "0") {
              _data.score = that.ruleForm.score;
              that.ruleForm.score=String(that.ruleForm.score);
            } else {
              _data.score = regNull(that.ruleForm.score) ? null : setTwoDecimal(that.ruleForm.score, 1);
            };
            if (that.ruleForm.ratio == "0") {
              _data.ratio = that.ruleForm.ratio;
              that.ruleForm.ratio=String(that.ruleForm.ratio);
            } else {
              _data.ratio = regNull(that.ruleForm.ratio) ? null : setTwoDecimal((that.ruleForm.ratio / 100), 2);
            };
            if (regNull(that.ruleForm.levelId)) {
              if (that.ruleForm.isSeach != 0) {
                if (!regNull(that.ruleForm.score) || !regNull(that.ruleForm.ratio)) {
                  if (!that.isInputBoxScore && !that.isInputBoxRatio) {
                    that.fullscreenLoading = true;
                    ajaxPost(that, '/api/admin/growthlevel', _data, function(res) {
                      that.fullscreenLoading = false;
                      that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
                      that.$refs['ruleForm'].resetFields();
                      that.dialogVisible = false;
                      onGetData(that);
                    });
                  }
                } else {
                  that.isInputBoxScore = true;
                  that.isInputBoxRatio = true;
                  that.isInputFontScore = '达标分数或名次请设置其中一项';
                  that.isInputFontRatio = '达标分数或名次请设置其中一项';
                }
              } else {
                that.isCheckBoxTip = true;
              }
            } else {
              if ((that.ruleForm.score!= '' &&that.ruleForm.score!= null) || (that.ruleForm.ratio!= '' &&that.ruleForm.ratio!= null)) {
                if (!that.isInputBoxScore && !that.isInputBoxRatio) {
                  that.fullscreenLoading = true;
                  ajaxPut(that, '/api/admin/growthlevel/' + that.ruleForm.levelId, _data, function(res) {
                    that.fullscreenLoading = false;
                    that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
                    that.$refs['ruleForm'].resetFields();
                    that.dialogVisible = false;
                    onGetData(that);
                  });
                }
              } else {
                that.isInputBoxScore = true;
                that.isInputBoxRatio = true;
                that.isInputFontScore = '达标分数或名次请设置其中一项';
                that.isInputFontRatio = '达标分数或名次请设置其中一项';
              }
            }
          }
        });
      },
      /**
       * @param {Object} res
       */
      onChangeYear(res) {
        let that = this;
        that.SearchList.semesterID = null;
        // that.SearchList.optSemester = that.SearchList.allSemester.filter(val => val.yearId == res);
      },

      /**
       * @description 去除数组内的重复
       * @param {Object} arr 需要去重复值的数据
       */
      onArrUnique(arr) {
        const res = new Map();
        return arr.filter((arr) => !res.has(arr.yearId) && res.set(arr.yearId, 1));
      },
      /**
       * @description 权限验证
       * @param {Object} that
       */
      onIsPower(that) {
        isPower(that, "evaluationconfig_details", "evaluationconfig_details");
        isPower(that, "evaluationconfig_edit", "evaluationconfig_edit");
        isPower(that, "evaluationconfig_create", "evaluationconfig_create");
        isPower(that, "evaluationconfig_delete", "evaluationconfig_delete");
      },


      /**
       * @description 搜索方法
       */
      onSearch() {
        let that = this;
        that.paging.Index = 1;
        onGetData(that);
      },
      /**
       * @description 重置搜索内容
       */
      onResetSearch() {
        let that = this;
        that.SearchList = JSON.parse(JSON.stringify(that.SearchListReset));
        onGetData(that);
      },
      /**
       * @description 分页按钮
       * @param {Object} type
       * @param {Object} val
       */
      pagingClick(type, val) {
        let that = this;
        if (type == 1) { //更改每页条数触发的事件
          that.paging.Size = val;
          that.paging.Index = 1;
          onGetData(that);
        } else { //更改当前页时触发的事件
          that.paging.Index = val;
          onGetData(that);
        }
      },
      /**
       * @description 分页组件左侧的按钮事件，按顺序进行判断
       * @param {Object} index
       */
      pageBtnClick(index) {},
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },
      /**
       * @description 新增
       */
      onCreate() {
        let that = this;
        // that.dialogTitle = '新增配置';
        // that.dialogVisible = true;
        that.$router.push({
          path: "/Admin/EvaluationConfig/Create",
          query: {
            type: that.currentId
          }
        });
      },
    },
    created() {
      let that = this;
      ajaxGet(that, '/api/admin/academic', null, function(resData) {
        that.optallYear = resData;
        onGetSemester(that);
        that.onIsPower(that);
      });
    }
  };

  /**
   * @description 加载列表数据
   * @param {Object} that
   */
  function onGetData(that) {
    that.fullscreenLoading = true;
    let _data = {
      Name: '',
      IsCalculation: false,
      PageNumer: that.paging.Index,
      PageSize: that.paging.Size,
      SemesterId: that.SearchList.semesterID,
      YearId: that.SearchList.semesterID == null ? that.SearchList.yearID : null,
      Type:that.SearchList.typeId
    }
    ajaxGet(that, '/api/admin/growthlevel/search', _data, function(resData) {
      that.fullscreenLoading = false;
      that.page = resData;
      that.itemData = resData.items;
    });


  }


  function onGetSemester(that) {
    that.fullscreenLoading = true;
    ajaxGet(that, '/api/admin/semester', null, function(resSemester) {
      that.fullscreenLoading = false;
      that.SearchList.optYear = that.onArrUnique(resSemester);
      that.SearchList.optSemester =  resSemester;
      that.SearchListReset = JSON.parse(JSON.stringify(that.SearchList));
      that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
      onGetData(that);
    });
  }
</script>

<style scoped="scoped">
  .divErrCont {
    font-size: 0.75rem;
    margin-top: -5px;
    display: block;
    width: 100%;
    color: #F56C6C;
  }
  

  .selTitleBox {
    font-size: 14px;
  }

  .selTitleBox .spTitle {
    color: #303133;
  }
</style>
