<!--
  用户管理 - 新增
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
		<el-form ref="ruleForm" label-width="100px">
			<el-card class="box-card">
			 <div slot="header" class="card-header">
					<h3 class="card-title titleCont">
						<span class="spIcon"></span>
						{{Title}}
					</h3>
				</div>
				<div class="card-body">
					<el-row :gutter="24" v-if="isCreateFlag == 'true'">
						<el-col :span="24">
							<el-steps :active="2" align-center>
							  <el-step title="角色信息"></el-step>
							  <el-step title="功能权限"></el-step>
							</el-steps>
						</el-col>
					</el-row>
					<el-row :gutter="24" style="margin-top: 20px;">
						<el-col :span="12">
							<el-form-item label="角色名称">
								{{roleName}}
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="描述">
								<div v-html="roleDescription"></div>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="权限">
								<div style="border: 1px solid #efefef; border-radius: 3px; padding: 10px;">
									<el-tree :data="actions" :props="defaultProps" ref="menuTree" v-if="actions.length>0"
									      node-key="actionId" show-checkbox
									      auto-expand-parent
									      :expand-on-click-node="false"
												:default-checked-keys="idArr"
											 :default-expanded-keys="idArr"
									>
									</el-tree>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
				</div>

			</el-card>
			<div class="row" style="margin-top: 10px;">
					<div class="col-12">
						<el-button  icon="el-icon-back" @click="onBack()">返回</el-button>
						<el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
						<el-button type="primary" icon="el-icon-circle-check" @click="onSave()">提交</el-button>
					</div>
			</div>
		</el-form>
  </div>
</template>

<script>
	import '@/assets/css/Admin.css';

  export default{
    components:{
    },
    data()
		{
      return{
        isCreateFlag:null,
				height:380,
				idArr:[],
				allActions:[],
				selectActions:[],
				idInit:[],
				actions:[],
				roleName:null,
				roleDescription:null,
				Title:"授权",
				fullscreenLoading:false,
				defaultProps: {
				  children: 'children',
				  label: 'name',
					id:'actionId'
				},
      };
    },
		created()
		{
			let that = this;
			let id = that.$route.params.id;
      var flag=that.$route.query.flag;
      if(flag!=null)
      {
        that.isCreateFlag=flag;
      }
			var detailUrl = null;
			if(id!=null && typeof(id)!="undefined" && id!="")
			{
				that.Title = "授权";
				detailUrl = "/api/admin/sysRole/" + id;
			}
			that.fullscreenLoading = true;

			ajaxGet(that, detailUrl, null, function (r) {
				if(r != null)
				{
					that.roleName = r.name;
					that.roleDescription = r.description;
				}

				ajaxGet(that, "/api/admin/sysAction/treelist?isEnable=true", null, function (r1) {
					that.actions = r1;
					that.allActions = [];
					InitItem(that,that.actions);

					ajaxGet(that, "/api/admin/sysRole/action/" + id, null, function (r1) {
						that.idArr = [];
						for(var t1 of r1)
						{
							for(var t2 of that.allActions)
							{
								if(t1 == t2.actionId && (t2.children == null || t2.children.length<=0))
								{
									that.idArr.push(t1);
								}
							}
						}
						that.idInit = JSON.parse(JSON.stringify(that.idArr));
					});
				});



				that.fullscreenLoading = false;

			});
		},
		mounted()
		{
			let that = this;
			that.height = document.body.clientHeight - 388;
		},
		methods:{
			onSave()
			{
				let that = this;
				var t = that.$refs.menuTree.getCheckedNodes();
				var arr = [];
				that.selectActions = [];
				for(var it of t)
				{
					arr.push(it.actionId);
				}

				for(var it of arr)
				{
					GetKeys(that,it);
				}
				that.fullscreenLoading = true;
				const res = new Map();
				var selects = that.selectActions.filter((a)=>!res.has(a) && res.set(a,1));
				ajaxPut(that,"/api/admin/sysRole/Authorize/" + that.$route.params.id,selects,function(r){
					that.fullscreenLoading = false;
					that.onBack();
				});
			},
			onReset()
			{
				let that = this;
				that.$refs.menuTree.setCheckedKeys(JSON.parse(JSON.stringify(that.idInit)));
			},
			onBack()
			{
				let that = this;
				back(that);
			},
		},
  };

	function InitItem(that,val)
	{
		 if (val.length !== 0) {
			 val.forEach((item) => {
				 item.id = item.actionId;
				 that.allActions.push(item);
					if (item.children.length >= 1) {
						InitItem(that,item.children);
					}
				});

		 }
	}

	function GetKeys(that,id)
	{
		for(var i in that.allActions)
		{
			if(that.allActions[i].actionId == id)
			{
				that.selectActions.push(id);
				if(that.allActions[i].parentId!=null)
				{
					GetKeys(that,that.allActions[i].parentId);
				}
			}
		}
	}
</script>

<style scoped="scoped">
  label{
    margin-bottom: 0;
  }
  .inputGroupCont{
    padding-top: 15px;
  }
  .inputGroupCont .inputList{
    padding-bottom: 10px;
  }

	.spIcon {
	    width: 5px;
	    height: 20px;
	    border-radius: 5px;
	    background-image: linear-gradient(#4545FF, #2D8EFF);
	}

	.spTitle{
		float:left;
		margin-left: 20px;
		color: #323E56;
		letter-spacing: 2px;
		font-size: 16px;
		margin-top: -3px;
	}

	.wMax {
	    width:100% !important;
	}
</style>
