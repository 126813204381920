<!--
  日常事务 - 竞赛获奖
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">

    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          <ul class="nav nav-pills navTabsCont">
            <li class="nav-item" v-if="isPower.dormitory_index == true">
              <router-link to="/Admin/DormManage/Index" class="nav-link ">
                宿舍信息
              </router-link>
            </li>
            <li class="nav-item" v-if="isPower.dormitory_preview == true">
              <router-link to="/Admin/DormManage/Floor" class="nav-link active">
                宿舍视图
              </router-link>
            </li>
          </ul>
        </h3>
      </div>
      <div class="card-body pb-0">
        <el-row :gutter="24" class="align-items-stretch">
          <el-col :xl="6" :lg="8" :md="12" :sm="24" v-for="(item,index) in itemData" :key="index">
            <el-card class="box-card bg-light">
              <div class="card-header border-bottom-0 buildTitleCont p-3">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#fax-layer-group-alt"></use>
                </svg>
                {{item.name}}
              </div>
              <div class="card-body pt-0">
                <div class="row">
                  <div class="col-12 VerticalMid buildImage">
                    <img :src="onImage(item.photo)" :alt="item.name" width="100%"/>
                  </div>
                  <div class="col-12">
                    <p class="text-sm buildCont">
                      <span>楼层数：<em>{{item.floors}}</em></span>
                      <span>房间数：<em>{{item.rooms}}</em></span>
                      <span>床位数：<em>{{item.beds }}</em></span>
                      <span>入住数：<em>{{item.used}}</em></span>
                      <span>剩余数：<em>{{item.remain}}</em></span>
                    </p>
                    <p class="pProgressCont">
                      <el-progress :text-inside="true" :stroke-width="20"
                        :percentage="onCalculation(item.beds,item.used)" :format="onFormat" :class="onProgressClass(item.beds,item.used)">

                      </el-progress>
                    </p>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="text-right">
                  <el-button type="primary" size="mini" @click="onDetail(item.buildingId)" v-if="isPower.dormitory_preview_detail==true">
                    浏览详情
                  </el-button>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </el-card>






  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  export default {
    name: '',
    components: {

    },
    data() {
      return {
        // 加载动画
        fullscreenLoading: false,
        itemData: [],
				isPower:{
					dormitory_index:false,
					dormitory_preview:false,
					dormitory_preview_detail:false,
				}
      };
    },
		created()
		{
		  let _this = this;
		  onGetData(_this);
			isPower(_this, "dormitory_index","dormitory_index");
			isPower(_this, "dormitory_preview","dormitory_preview");
			isPower(_this, "dormitory_preview_detail","dormitory_preview_detail");
		},
    methods: {
      // 图片地址
      onImage(_imgSrc){
        if(_imgSrc==null){
          return require('@/assets/image/img_building.jpeg');
        }
        return this.$ServerUrl()+'/'+_imgSrc;
      },
      // 浏览详情
      onDetail(_dataID){
        let _this = this;
        _this.$router.push({
          path: "/Admin/DormManage/Floor/" + _dataID,
          query: {
            url: _this.$router.history.current.fullPath
          }
        });
      },
      // 根据入住率的显示文字的颜色
      onProgressClass(_intBeds, _intUsed){
        if(this.onCalculation(_intBeds, _intUsed)>25){
          return null;
        }
        return 'progressStyle';
      },
      // 重新计算
      onCalculation(_intBeds, _intUsed) {
        let strValue = setTwoDecimal(setInt(_intUsed) / setInt(_intBeds));
        strValue = strValue != '' ? strValue : 0;
        return parseFloat(strValue);
      },
      // 入住率
      onFormat(percentage) {
        return '入住率 ' + `${percentage}%`;
      }
    },
  };


  // 加载数据
  function onGetData(_this) {
    _this.fullscreenLoading = true;
    ajaxGet(_this, '/api/admin/dormitory/preview', null, function(resData) {
      _this.itemData = resData;
      _this.fullscreenLoading = false;
    });
  }
</script>

<style scoped="scoped">

  .buildImage{
    height: 180px;
    width: 300px;
    overflow: hidden;
  }
  .buildTitleCont {
    color: #000000;
  }

  .buildTitleCont .icon {
    color: #307BFF;
  }

  .buildCont {
    padding-top: 5px;
    margin-bottom: 5px;
  }

  .buildCont span {
    padding-right: 15px;
    white-space: nowrap;

  }

  .buildCont span em {
    font-family: 'OswaldLight';
    font-style: normal;
    letter-spacing: 1px;
    font-size: 14px;
    color: #B0A8BB;
  }

  .pProgressCont {
    padding-top: 10px;
  }

  .navTabsCont .nav-link {
    font-size: 12px;
  }

  .navToolsCont {
    margin-top: 1px;
  }
</style>
<style>
  .progressStyle .el-progress-bar__innerText {
    color: #3E3E3E;
  }
</style>
