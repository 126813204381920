<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          班主任常规考核项详情
        </h3>
      </div>
      <div class="card-body" v-if="ruleForm!=null">
        <div class="row">
          <div class="col-lg-12 colInfo">
            <label>加减项名</label>
            <div class="colFonts">
              {{ ruleForm.reason | flNoValue}}
            </div>
          </div>
          <div class="col-lg-3 colInfo">
            <label>分数</label>
            <div class="colFonts">
              {{ruleForm.score | flNoValue}}
            </div>
          </div>
          <div class="col-lg-3 colInfo">
            <label>班主任</label>
            <div class="colFonts">
              {{ruleForm.teacher | flNoValue}}
            </div>
          </div>
          <div class="col-lg-3 colInfo">
            <label>班级</label>
            <div class="colFonts">
              {{ruleForm.className | flNoValue}}
            </div>
          </div>
          <div class="col-lg-3 colInfo">
            <label>记录时间</label>
            <div class="colFonts">
              {{ruleForm.logDate | dateformatDay}}
            </div>
          </div>
          <div class="col-lg-3 colInfo">
            <label>记录人</label>
            <div class="colFonts">
              {{ruleForm.user | flNoValue}}
            </div>
          </div>
          <div class="col-lg-3 colInfo">
            <label>创建时间</label>
            <div class="colFonts">
              {{ruleForm.createDate | dateformatMinute}}
            </div>
          </div>
          <div class="col-lg-12 colInfo">
            <label>备注</label>
            <div class="colFonts">
              {{ruleForm.remark | flNoValue}}
            </div>
          </div>
        </div>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>





    <div class="row">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack">返回</el-button>
      </div>
    </div>


  </div>
</template>

<script>
  import "@/assets/css/Admin.css";

  export default {
    components: {
    },
    data() {
      return {
        fullscreenLoading: false,
        ruleForm: {},
      };
    },
    filters: {
      flNoValue(obj) {
        if (obj == null || typeof obj == "undefined" || obj == "") {
          return "暂无数据";
        }
        return obj;
      },
    },
    created() {
      let that = this;
       if (!regNull(that.$route.params.Id)) {
         that.fullscreenLoading=true;
         ajaxGet(that,'/api/admin/advisergeneral/'+that.$route.params.Id,null,function(resData){
           that.fullscreenLoading=false;
           that.ruleForm=resData;
         });
       }
    },
    methods: {
      /**
       * @description 返回
       */
      onBack() {
        let that = this;
        back(that);
      },
    },
  };
</script>

<style scoped="scoped">
  .divSubTitle{
    background-color: #409EFF;
    width: 300px;
    padding: 5px 10px;
    display: flex;
    color: #FFFFFF;
    margin-top: 30px;
    border-radius: 5px;
  }
  .divSubTitle:first-child{
    margin-top: 0px;
  }
  .divSubTitle .spTitle{
    flex: 1;
  }
  .divSubTitle .spFont{

  }
  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }

  .spIcon {
    width: 5px;
    height: 20px;
    border-radius: 5px;
    background-image: linear-gradient(#4545ff, #2d8eff);
  }

</style>
