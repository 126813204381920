<template>
   <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
     <el-card class="box-card">
       <div slot="header" class="card-header">
         <h3 class="card-title titleCont">
           <span class="spIcon"></span>
           基本信息
         </h3>
       </div>
       <div class="card-body">
         <div class="row">
           <div class="col-lg-4 colInfo">
             <label>批次学年</label>
             <div class="colFonts">
               {{ruleForm.year | flNoValue}}
             </div>
           </div>
           <div class="col-lg-4 colInfo">
             <label>批次时段</label>
             <div class="colFonts">
               {{ruleForm.startDate | dateformatDay}} 至 {{ruleForm.startDate | dateformatDay}}
             </div>
           </div>
           <div class="col-lg-4 colInfo">
             <label>申请学生</label>
             <div class="colFonts">
               {{ruleForm.studentName | flNoValue}}
             </div>
           </div>
           <div class="col-lg-4 colInfo">
             <label>学号</label>
             <div class="colFonts">
               {{ruleForm.studentNo | flNoValue}}
             </div>
           </div>
           <div class="col-lg-4 colInfo">
             <label>班级</label>
             <div class="colFonts">
               {{ruleForm.className | flNoValue}}
             </div>
           </div>
           <div class="col-lg-4 colInfo">
             <label>申请时间</label>
             <div class="colFonts">
               {{ruleForm.createDate | dateformatMinute}}
             </div>
           </div>
           <div class="col-lg-4 colInfo">
             <label>申请等级</label>
             <div class="colFonts">
               {{ruleForm.applyLevelString | flNoValue}}
             </div>
           </div>
           <div class="col-lg-4 colInfo">
             <label>状态</label>
             <div class="colFonts">
               {{ruleForm.statusString | flNoValue}}
             </div>
           </div>
           <div class="col-lg-4 colInfo" v-if="ruleForm.level!=null">
             <label>评定等级</label>
             <div class="colFonts">
               {{ruleForm.levelString | flNoValue}}
             </div>
           </div>
         </div>
       </div>
     </el-card>

     <div v-if="arrScore!=null && arrScore.length!=0">
       <el-card class="box-card" v-for="(item,index) in arrScore" :key="index">
         <div slot="header" class="card-header">
           <h3 class="card-title titleCont">
             <span class="spIcon"></span>
             {{item.semesterName}}
           </h3>
         </div>
         <div class="card-body">
           <div class="row">
             <div class="col-lg-4 colInfo" v-for="(list,indexTwo) in item.children" :key="indexTwo">
               <label>{{list.courseName}}</label>
               <div class="colFonts">
                 {{list.total | flNoValue}}
               </div>
             </div>
           </div>
         </div>
       </el-card>
     </div>

     <el-card class="box-card" v-if="ruleForm.description!=null">
       <div slot="header" class="card-header">
         <h3 class="card-title titleCont">
           <span class="spIcon"></span>
           主要事迹
         </h3>
       </div>
       <div class="card-body">
         <div v-html="ruleForm.description"></div>
       </div>
     </el-card>


     <!--证明材料-->
     <comShowImage :uploadFiles="uploadFiles" :comName="'证明材料'"></comShowImage>


     <el-card class="box-card" v-if="itemData!=null && itemData.length!=0">
       <div slot="header" class="card-header">
         <h3 class="card-title titleCont">
           <span class="spIcon"></span>
           审核记录
         </h3>
       </div>
       <div class="card-body ">
         <el-table :data="itemData" border style="width: 100%">
           <el-table-column prop="status" label="审核结果" width="200"></el-table-column>
           <el-table-column prop="auditor" label="审核人" width="140"></el-table-column>
           <el-table-column label="审核意见">
             <template slot-scope="scope">
               {{scope.row.reason!=null?scope.row.reason:'无'}}
             </template>
           </el-table-column>
           <el-table-column label="审核时间" width="160">
             <template slot-scope="scope">
               {{scope.row.createDate | dateformatMinute}}
             </template>
           </el-table-column>
         </el-table>
       </div>
     </el-card>

     <el-card class="box-card"
       v-if="(isPower.scholarship_headteacher_audit && ruleForm.status==1) || (isPower.scholarship_college_audit && ruleForm.status==2) || (isPower.scholarship_school_audit && ruleForm.status==3)">
       <div slot="header" class="card-header">
         <h3 class="card-title titleCont">
           <span class="spIcon"></span>
           审核意见
         </h3>
       </div>
       <div class="card-body ">
         <el-input class="formControl" v-model="txtRemark" type="textarea" :rows="4" show-word-limit placeholder="请输入备注"
           maxlength="500">
         </el-input>
       </div>
     </el-card>



     <div class="row" style="margin-top: 10px;"
       v-if="(isPower.scholarship_headteacher_audit && ruleForm.status==1) || (isPower.scholarship_college_audit && ruleForm.status==2) || (isPower.scholarship_school_audit && ruleForm.status==3)">
       <div class="col-12">
         <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
         <el-button icon="el-icon-circle-close" type="danger" @click="onAudit(false)">审核不通过</el-button>
         <el-button icon="el-icon-circle-check" type="primary" @click="onAudit(true)">审核通过</el-button>
       </div>
     </div>
     <div class="row" style="margin-top: 10px;"
       v-else-if="isPower.scholarship_school__assess && ruleForm.status==4 && isLevel==null">
       <div class="col-12">
         <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
       </div>
     </div>



     <div class="row" style="margin-top: 10px;" v-else>
       <div class="col-12">
         <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
       </div>
     </div>


   </div>
 </template>

 <script>
   import "@/assets/css/Admin.css";
   import comShowImage from '@/components/showImage.vue';

   export default {
     components: {
       comShowImage
     },
     data() {
       return {
         // 加载动画
         fullscreenLoading: false,
         // 对象
         ruleForm: {
           applyId: null
         },
         //审核记录
         auditRecords: [],
         arrScore: [],

         // 放大图片需要
         showViewer: false,
         // 上传的附件图片
         uploadFiles: [],
         styleWidth: "25%",
         auditType: null,
         txtRemark: null,
         isPower: {
           // 学校审核
           scholarship_school_audit: false,
           // 班主任审核
           scholarship_headteacher_audit: false,
           // 学院审核
           scholarship_college_audit: false,
           scholarship_school_assess: false,
         },
         itemData: [],
         isLevel:null,
         optLevel:[],
       };
     },
     filters: {
       /**
        * @description 判断是否有数据
        * @param {Object} obj
        */
       flNoValue(obj) {
         if (obj == null || typeof obj == "undefined" || obj == "") {
           return "暂无数据";
         }
         return obj;
       },
     },
     created() {
       let that = this;
       let tempID = that.$route.params.Id;
       if (!regNull(tempID)) {
         isPower(that, "scholarship_school_audit", "scholarship_school_audit");
         isPower(that, "scholarship_headteacher_audit", "scholarship_headteacher_audit");
         isPower(that, "scholarship_college_audit", "scholarship_college_audit");
         isPower(that, "scholarship_school_assess", "scholarship_school_assess");
         that.ruleForm.applyId = tempID;
         that.onGetData(that);
       }
     },
     methods: {
       /**
        * @description 获取数据
        * @param {Object} that
        */
       onGetData(that) {
         that.fullscreenLoading = true;
         ajaxGet(that, '/api/admin/scholarshipapply/' + that.ruleForm.applyId, null, function(resApply) {
           if (!regNull(resApply)) {
             that.isLevel=resApply.level;
             that.fullscreenLoading = false;
             that.ruleForm = resApply;
             that.arrScore = [];
             if (!regNullArray(resApply.total)) {
               let tempScore = that.onArrUnique(resApply.total);
               tempScore.forEach(function(item) {
                 that.arrScore.push({
                   semesterId: item.semesterId,
                   semesterName: item.semesterName,
                   children: resApply.total.filter(list => list.semesterId == item.semesterId),
                 });
               });
             }

             that.uploadFiles = [];
             if (!regNull(resApply.attachment)) {
               var files = resApply.attachment.split('|');
               for (var i in files) {
                 that.uploadFiles.push(that.$ServerUrl() + files[i]);
               }
             }

             ajaxGet(that, '/api/admin/scholarshipapply/audit/' + resApply.applyId, null, function(resAudit) {
               that.itemData = resAudit;
             });

             //评定
             if(that.isPower.scholarship_school__assess && resApply.status==4 && resApply.level==null){
               ajaxGet(that,'/api/admin/scholarshipapply/leveltype',null,function(resType){
                 that.optLevel=resType;
                 that.ruleForm.level=that.ruleForm.applyLevel.toString();
               });
             }
           }
         });
       },
       /**
        * @description 审核
        * @param {Object} objType
        */
       onAudit(objType) {
         let that = this;
         let apiUrl = null;
         let tempStatus = that.ruleForm.status;
         let tempType = 0;
         if (tempStatus == 1) {
           apiUrl = '/api/admin/scholarshipapply/teacheraudit/' + that.ruleForm.applyId;
         } else if (tempStatus == 2) {
           apiUrl = '/api/admin/scholarshipapply/collegeaudit/' + that.ruleForm.applyId;
         } else if (tempStatus == 3) {
           apiUrl = '/api/admin/scholarshipapply/universityaudit/' + that.ruleForm.applyId;
         }

         if (!objType) {
           if (regNull(that.txtRemark)) {
             warning(that, '审核不通过需要填写审核意见');
             return false;
           }
         }

         let tempData = {
           applyId: that.ruleForm.applyId,
           status: objType,
           reason: that.txtRemark
         }
         that.fullscreenLoading = true;
         ajaxPut(that, apiUrl, tempData, function(res) {
           that.fullscreenLoading = false;
           that.onGetData(that);
         });
       },
       /**
        * @description 去重
        * @param {Object} arr
        */
       onArrUnique(arr) {
         const res = new Map();
         return arr.filter((arr) => !res.has(arr.semesterId) && res.set(arr.semesterId, 1));
       },

       /**
        * @description 返回
        */
       onBack() {
         let that = this;
         back(that);
       },
     },
   };
 </script>

 <style scoped="scoped">
   label {
     margin-bottom: 0;
   }

   .inputGroupCont {
     padding-top: 15px;
   }

   .inputGroupCont .inputList {
     padding-bottom: 10px;
   }

   .spIcon {
     width: 5px;
     height: 20px;
     border-radius: 5px;
     background-image: linear-gradient(#4545ff, #2d8eff);
   }

   .spTitle {
     float: left;
     margin-left: 20px;
     color: #323e56;
     letter-spacing: 2px;
     font-size: 16px;
     margin-top: -3px;
   }

   .wMax {
     width: 100% !important;
   }
 </style>
