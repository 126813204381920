<!--
  基础信息-校历管理
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          校历管理
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <el-select v-model="value" placeholder="请选择学期" @change="onChange(value)">
                <el-option v-for="item in SemesterList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="data!=null && data.length!=0" width="100%">
        <table class="tableTwo">
          <thead>
            <tr>
              <th class="thTitle">星期</th>
              <th class="thTitle">日</th>
              <th class="thTitle">一</th>
              <th class="thTitle">二</th>
              <th class="thTitle">三</th>
              <th class="thTitle">四</th>
              <th class="thTitle">五</th>
              <th class="thTitle">六</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(r,index) in data" :key="index">
              {{onview(r)}}
              <td class="tdWeekName">{{r.WeekIndex}}</td>
              <td class="tdDayCont" v-for="(d,index) in r.item" :key="index" @click="onEdit(d)" >

                <span class="spDesc" v-if="onIsShowDesc(d.Description)">
                  <el-tooltip effect="dark" :content="d.Description" placement="top">
                    <i class="el-icon-warning"></i>
                  </el-tooltip>
                </span>


                <!-- 今天 -->
                <span v-if="d.Day==CurrDateTime.day && d.Month==CurrDateTime.month && d.Year==CurrDateTime.year"
                  class="spDay nowDay VerticalMid">{{d.Day}}</span>
                  <!-- 其他日期 -->
                <span v-else class="spDay">
                  {{d.Day}}
                </span>
                <span class="spHoliday VerticalMid" v-if="d.Holida">休</span>
                <span class="spMonth" v-if="d.IsFirst">{{d.Month}}月</span>
                <span class="spYear" v-if="(d.Month==1 && d.Day==1) || (d.Month==12 &&  d.Day==31)">{{d.Year}}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-body" v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>

    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%" :close-on-click-modal="false"
      :close-on-press-escape="false" :append-to-body="true" :fullscreen="false">
      <el-form :model="ruleForm" ref="ruleForm" label-width="90px">
        <el-col :span="24">
          <el-form-item label="日期" prop="code">
            {{ruleForm.dateTime}}
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注" prop="Description">
            <el-input class="formControl" type="textarea" :rows="4" show-word-limit placeholder="请输入备注" maxlength="500"
              v-model="ruleForm.Description">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="是否节假日" prop="isHoliday">
            <el-switch v-model="ruleForm.isHoliday" active-color="#409EFF" inactive-color="#C0CCDA">
            </el-switch>
          </el-form-item>
        </el-col>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button @click="onReset()">重 置</el-button>
        <el-button type="primary" @click="onSave()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>



<script>
  import '@/assets/css/Admin.css';

  export default {
    data() {
      return {
        //加载动画
        fullscreenLoading: false,
        //待重置时的表单数据
        ruleFormReset: null,
        ruleForm: {
          CurrSemesterId: null,
          dateTime: null,
          id: null,
          Description: null,
          isHoliday: false,
        },
        itemData: [],
        //全部学期
        SemesterList: [],
        //选择的学期
        value: null,
        //数据列表
        data: [],
        CurrDateTime: {
          year: null,
          month: null,
          day: null,
        },
        dialogTitle: "编辑",
        dialogVisible: false,
      };
    },
    methods: {
      onIsShowDesc(objVal){
        return !regNull(objVal);
      },
      onview(objItem){

      },
      onSave() //保存（新增和编辑）
      {
        let that = this;

        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            if (that.ruleForm.id != null) {
              if (that.ruleForm.Description != null) {
                that.fullscreenLoading = true;
                ajaxPut(that, "/api/admin/semesterday/holiday?id=" + that.ruleForm.id + "&isHoliday=" + that
                  .ruleForm.isHoliday + "&Description=" + that.ruleForm.Description, null,
                  function(r) {
                    that.fullscreenLoading = false;
                    that.dialogVisible = false;

                    that.data = [];
                    that.itemData = [];
                    let data = {
                      SemesterId: that.ruleForm.CurrSemesterId,
                    };
                    Getdata(that, data);
                  });
              } else {
                that.fullscreenLoading = true;
                ajaxPut(that, "/api/admin/semesterday/holiday?id=" + that.ruleForm.id + "&isHoliday=" + that
                  .ruleForm.isHoliday, null,
                  function(r) {
                    that.fullscreenLoading = false;
                    that.dialogVisible = false;

                    that.data = [];
                    that.itemData = [];
                    let data = {
                      SemesterId: that.ruleForm.CurrSemesterId,
                    };
                    Getdata(that, data);
                  });
              }
            }
          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              isError[0].querySelector('input').focus();
            }, 1)
            return false;
          }
        });
      },
      onReset() //重置
      {
        let that = this;
        this.ruleForm = JSON.parse(JSON.stringify(this.ruleFormReset));
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      onEdit(val) //编辑
      {
        if (val.Day != "") {
          let that = this;
          that.ruleForm.id = val.Id;
          that.ruleForm.dateTime = val.DateTime;
          that.ruleForm.Description = val.Description;
          that.ruleForm.isHoliday = val.Holida;
          that.dialogVisible = true;
          that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
        }
      },
      onChange(val) // 搜索
      {
        let that = this;
        that.ruleForm.CurrSemesterId = val;
        that.data = [];
        that.itemData = [];
        let data = {
          SemesterId: val,
        };
        Getdata(that, data);
      },
    },
    created() {
      let that = this;
      var date = new Date(); //获取当前日期
      var newCurrDateTime = {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
      }
      that.CurrDateTime = newCurrDateTime; //表示当前日期
      Init(that);
    },

  };


  function Init(that) {
    that.fullscreenLoading = true;
    ajaxGet(that, "/api/admin/semester", null, function(r) {
      var semesters = {};
      for (var i in r) {
        if (r[i].isCurrent == true) {
          that.value = r[i].name;
          that.ruleForm.CurrSemesterId = r[i].semesterId;
          let data = {
            SemesterId: r[i].semesterId,
          };
          Getdata(that, data);
        }
        var label = r[i].name;
        var value = r[i].semesterId;
        var semesters = {
          label,
          value
        };
        that.SemesterList.push(semesters);
      }
      that.fullscreenLoading = false;
    });
  }

  //绑定数据
  function Getdata(that, val) {
    that.fullscreenLoading = true;
    ajaxGet(that, "/api/admin/semesterday/search/", val, function(r) {
      //得到最后一周是第几周，确定总周数
      var maxWeekIndex = 0;
      for (var i in r) {
        if (maxWeekIndex < r[i].weekIndex) {
          maxWeekIndex = r[i].weekIndex;
        }
      }
      //给itemData赋值
      for (var j = 1; j <= maxWeekIndex; j++) {
        var newItemData = {
          WeekDayList: [],
          item: [],
        };
        for (var i in r) {
          //截取日期
          var dateString = r[i].day.split('T', 1);
          var dateObject = new Date(dateString);
          //年份
          r[i].Year = dateObject.getFullYear();
          //月份
          r[i].Month = dateObject.getMonth() + 1;
          //日期
          r[i].Day = dateObject.getDate();
          r[i].IsFirst = false;
          //年/月/日
          r[i].DateTime = r[i].Year + "/" + r[i].Month + "/" + r[i].Day;
          if (r[i].Day == 1) {
            r[i].DayTwo = r[i].Day + "/" + r[i].Month;
            r[i].IsFirst = true;
          }
          // if(i == 0)
          // {
          // 	r[i].Day=r[i].Day+"/"+r[i].Month;
          // }
          //周几
          r[i].WeekDay = dateObject.getDay();
          if (j == r[i].weekIndex) {
            newItemData.WeekDayList.push(r[i].WeekDay);
            var newItem = {
              Id: r[i].dayId,
              Holida: r[i].holiday,
              Description: r[i].description,
              Day: r[i].Day,
              DayTwo: r[i].DayTwo,
              WeekDay: r[i].WeekDay,
              Month: r[i].Month,
              Year: r[i].Year,
              DateTime: r[i].DateTime,
              IsFirst: r[i].IsFirst,
            }
            newItemData.item.push(newItem);
          }
        }
        that.itemData.push(newItemData);
      }
      //给data赋值
      for (var j in that.itemData) {
        var a = parseInt(j) + 1;
        var newData = {
          WeekIndex: "第" + a + "周",
          item: [],
        };
        for (var k = 0; k < 7; k++) {
          if (that.itemData[j].WeekDayList.indexOf(k) < 0) {
            var newItem = {
              Day: "",
              WeekDay: "",
              Month: "",
            }
            newData.item.push(newItem);
          } else {
            for (var l in that.itemData[j].item) {
              if (that.itemData[j].item[l].WeekDay == k) {
                var newItem = that.itemData[j].item[l];
                newData.item.push(newItem);
              }
            }
          }
        }
        that.data.push(newData);
      }
      if(!regNullArray(that.data)){
        that.data[0].first=true;
      }
    });

    that.fullscreenLoading = false;
  }
</script>

<style scoped="scoped" lang="less">
  .tableTwo{
    .thTitle{
      text-align: center;
      height: 80px;
      font-size: 24px;
      width: 12.5%;
    }
    .tdWeekName{
      text-align: center;
    }

    .tdDayCont {
      overflow-y: hidden;
      text-align: center;
      position: relative;
      color: #303133;
      font-size: 24px;
      height: 130px;
      .spDesc{
        position: absolute;
        bottom: 5px;
        right: 5px;
        color: #F56C6C;
      }
      .spHoliday{
        position: absolute;
        top: 5px;
        right: 5px;
        background-color: #E6A23C;
        color: #ffffff;
        font-size: 12px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
      .spMonth{
        position: absolute;
        left: 5px;
        top: 5px;
        font-size: 14px;
        background-color:#409EFF;
        color: #FFFFFF;
        padding: 3px 10px;
        border-radius: 3px;
      }
      .spDay {
      }
      .spYear{
        position: absolute;
        bottom: 5px;
        left: 5px;
        font-size: 14px;
        background-color:#409EFF;
        color: #FFFFFF;
        padding: 3px 10px;
        border-radius: 3px;
      }
      &:hover{
        background-color:#ecf5ff;
      }

      .nowDay {
        font-weight: bold;
        background-color: #307BFF;
        color: #FFFFFF;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        margin: 0 auto;
      }
    }


    td{
      collapse : border;
      border: 1px solid #ddd;
      font-size: 18px;
    }

  }

  /*
  td {
    height: 40px;
    overflow-y: hidden;
  }

  td.TableHover .remark {
    display: none;
  }

  td.TableHover .remark_none {
    display: block;
  }

  td.TableHover:hover {
    background-color: lightgray;
    cursor: pointer;
  }

  td.TableHover:hover .remark {
    display: block;
  }

  td.TableHover:hover .remark_none {
    display: none;
  }
*/


</style>
