<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">

    <search :ColNumber="3" :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null">
    </search>

    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          奖学金审核名单
        </h3>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table stripe :data="itemData" class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="className" label="班级" min-width="120" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="studentNo" label="学号" min-width="100"></el-table-column>
          <el-table-column prop="studentName" label="姓名" min-width="120" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="applyLevelString" label="申报等级" min-width="120" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="statusString" label="审核状态" min-width="120" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column label="申请时间" width="190">
            <template slot-scope="scope">
              {{scope.row.createDate | dateformatMinute}}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120" fixed="right">
            <template slot-scope="scope">
              <operationBtn :btnName="'审核'" @click.native="onDetail(scope.row.applyId)"
                v-if="(isPower.scholarship_headteacher_audit && scope.row.status==1) || (isPower.scholarship_college_audit && scope.row.status==2) || (isPower.scholarship_school_audit && scope.row.status==3)">
              </operationBtn>
              <!-- <operationBtn :btnName="'评定'" @click.native="onDetail(scope.row.applyId)" v-else-if="isPower.scholarship_school_assess && scope.row.status==4 && scope.row.level==null"></operationBtn> -->
              <operationBtn :btnName="'查看'" @click.native="onDetail(scope.row.applyId)" v-else></operationBtn>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
    <div class="row" style="margin-top: 10px;">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack">返回</el-button>
      </div>
    </div>

  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';
  // import comImport from '@/components/Import.vue';

  export default {
    name: '',
    components: {
      comPage,
      operationBtn,
      // comImport,
      search
    },
    data() {
      return {
        // 加载动画
        fullscreenLoading: false,
        dialogVisible: false,
        // 列表数据
        itemData: [],
        page: null, //分页查询接口返回的结果
        pageBtnList: null, //分页左侧按钮集合
        paging: { //分页数据
          pageLength: 0, // 总共页数
          Size: 10, // 当前请求数量
          Index: 1 //当前在第几页
        },
        isBtnAudit: false,
        //搜索集合
        SearchList: null,
        isPower: {
          // 学校审核
          scholarship_school_audit: false,
          // 班主任审核
          scholarship_headteacher_audit: false,
          // 学校评定
          scholarship_school_assess: false,
          // 学院审核
          scholarship_college_audit: false,
        },
        uploadInfoApi: '/api/admin/student/import',

        TeacherInfo:{},// 教师信息
      };
    },
    created() {
      let that = this;
      that.TeacherInfo=onGetNowUser(that);
      isPower(that, "scholarship_school_audit", "scholarship_school_audit");
      isPower(that, "scholarship_headteacher_audit", "scholarship_headteacher_audit");
      isPower(that, "scholarship_school_assess", "scholarship_school_assess");
      isPower(that, "scholarship_college_audit", "scholarship_college_audit");
      pagingPage(that);
    },
    methods: {
      /**
       * @description 返回
       */
      onBack() {
        back(this);
      },
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },
      /**
       * @description 详情
       * @param {Object} objID
       */
      onDetail(objID) {
        let that = this;
        that.$router.push({
          path: "/Admin/ScholarshipMGT/Audit/Details/" + objID,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      /**
       * @description 搜索
       * @param {Object} params
       */
      onSearch(params) {
        let that = this,
          data = {},
          searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = Join(element.data);
          }
        });
        data.PageNumer = 1;
        routerPath(that, "/Admin/ScholarshipMGT/Audit/Index", data, null, "PageSize");
      },
      /**
       * @description 页码
       * @param {Object} type
       * @param {Object} val
       */
      pagingClick(type, val) {
        let that = this;
        let url = '/Admin/ScholarshipMGT/Audit/Index';
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "BatchId",
            "ClassId",
            "Student",
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "SemesterId",
            "BatchId",
            "ClassId",
            "Student",
          );
        }
      },
    },
    watch: {
      $route() {
        let that = this;
        pagingPage(that);
      }
    }
  };

  function pagingPage(that) {
    paging(
      that,
      function(res) {

        that.fullscreenLoading = true;
        let tempURL = '',
          tempStatus = null,
          tempIsAudit = false;
        // 获取批次
        ajaxGet(that, '/api/admin/scholarshipbatch/now', null, function(resBatch) {



            if (that.isPower.scholarship_headteacher_audit && !that.isPower.scholarship_college_audit && !that
              .isPower.scholarship_school_audit) {
              // 班主任
              tempStatus = 1;
            } else if (that.isPower.scholarship_college_audit) {
              // 学院审核
              tempStatus = 2;
            } else if (that.isPower.scholarship_school_audit) {
              // 学校审核
              tempStatus = 3;
            } else if (that.isPower.scholarship_school_assess) {
              // 学校评定
              tempStatus = 4;
              tempIsAudit = true;
            }


            let resClass=that.TeacherInfo.isTeacher ? that.TeacherInfo.manageClass : that.TeacherInfo.allClass;


              that.SearchList = [];
              // 班级ID，
              let tempClassId = res.ClassId,
                tempCalss = [];
              resClass.forEach(function(item) {
                tempCalss.push({
                  Title: item.name,
                  Value: item.classId,
                });
              });
              if (regNull(res.ClassId) && that.TeacherInfo.isTeacher) {
                tempClassId = regNullArray(resClass) ? null : resClass[0].classId;
              }
              // 批次ID
              let tempBatchId = res.BatchId,
                tempBatch = [];

              resBatch.forEach(function(item) {
                tempBatch.push({
                  Title: item.yearName,
                  Value: item.batchId,
                });
              });

              if (regNull(res.BatchId) && !regNullArray(resBatch)) {
                tempBatchId = regNullArray(resBatch) ? null : resBatch[0].batchId;
                that.uploadInfoApi = '/api/admin/scholarshipapply/import(' + resBatch[0].batchId + ')';
              }

              that.SearchList.push({
                type: "select",
                Name: "批次",
                data: tempBatchId,
                select: tempBatch,
                zhi: "BatchId",
                isClear: false,
              }, {
                type: "select",
                Name: "班级",
                data: tempClassId,
                select: tempCalss,
                zhi: "ClassId",
                isClear: !that.TeacherInfo.isTeacher, //select是否可以清除
              }, {
                type: "input",
                Name: "学生",
                data: res.Student,
                holder: "请输入学号/姓名",
                zhi: "Student"
              });

              let tempData = {
                BatchId: tempBatchId,
                ClassId: tempClassId,
                Name: res.Student,
                // Status: tempStatus,
                // IsAudit: tempIsAudit,
                PageNumer: res.PageNumer,
                PageSize: res.PageSize,
              }
              ajaxGet(that, '/api/admin/scholarshipapply/search', tempData, function(resData) {
                that.fullscreenLoading = false;
                if (!regNullArray(resData.items)) {
                  that.page = resData;
                  that.itemData = resData.items;
                } else {
                  that.itemData = [];
                }

              });
          

        });
      },
      "BatchId",
      "ClassId",
      "Student",
    );
  }
</script>

<style scoped="scoped">
</style>
