<!--
 ECHARTS 的 雷达图
-->
<template>
  <!-- 250px -->
  <div :id="comID" :style="pieStyle"></div>
</template>

<script>
  import 'echarts/lib/component/tooltip';
  import 'echarts/lib/component/legend';

  // 引入基本模板
  let echarts = require('echarts/lib/echarts');
  // 引入柱状图组件
  require('echarts/lib/chart/radar');
  export default {
    name: 'chart_radar',
    props: {
      comID: {
        type: String,
        default: 'myChart'
      },
      comHeight: {
        type: Number,
        default: 250
      },
      comData: {
        type: Array,
        default: () => {
          return [];
        }
      }
    },
    data() {
      return {
        pieStyle: '',
        myChart: {},
        arrData: [],
      };
    },
    watch: {
      'arrData': {
        handler: function(newVar, oldVar) {
          this.arrData = newVar;
        },
        deep: true
      },
    },
    created() {
      let _this = this;
      _this.arrData = _this.comData;
      _this.$nextTick(() => {
        _this.drawLine();
      });
      _this.pieStyle = 'width: 100%; height:' + (_this.comHeight * 0.0625) + 'rem;';
    },
    mounted() {
      let _this = this;
      window.onresize = function() {
        _this.myChart.resize();
        _this.pieStyle = 'width: 100%; height:' + (_this.ChartHeight * 0.0625) + 'rem;';
      }
    },
    methods: {
      // 生成
      onGetRadar(_data) {
        let _this = this;
        _this.arrData = _data;
        _this.$nextTick(() => {
          _this.drawLine();
        })
        _this.pieStyle = 'width: 100%; height:' + (_this.comHeight * 0.0625) + 'rem;';
      },
      // 绘画雷达图
      drawLine() {
        let _this = this,
          arrTitle = [],
          arrClass = [],
          arrOneself = [];
        // 数据处理
        if (_this.arrData.length != 0) {
          for (let item of _this.arrData) {
            arrTitle.push({
              name: item.name,
              max: item.ceiling==0?(item.score+item.baseScore)+50:item.ceiling
            });
            // arrClass.push(item.median>=item.ceiling?item.ceiling:item.median);
            // 判断是否有上限分
            if(item.ceiling==0){
             arrOneself.push(item.score+item.baseScore);
            }else {
              // 判断学生总分是否超过上限分
              if((item.score+item.baseScore)>=item.ceiling){
                arrOneself.push(item.ceiling);
              }else{
                arrOneself.push(item.score+item.baseScore);
              }
            }
          }


          // 基于准备好的dom，初始化echarts实例
          _this.myChart = echarts.init(document.getElementById(_this.comID));

          // 绘制图表
          _this.myChart.setOption({
            tooltip: {
              padding: [10, 10],
            },
            // legend: {
            //   data: ['班级平均分', '学生个人分'],
            //   bottom: 0,
            // },
            color: ['#1E90FF','#C13531'],
            radar: {
              radius: '50%',
              shape: 'circle',
              name: {
                textStyle: {
                  color: '#fff',
                  backgroundColor: '#999',
                  borderRadius: 3,
                  padding: [5, 10],
                  fontSize: 16
                },
              },
              indicator: arrTitle
            },
            series: [{
              name: '总评',
              type: 'radar',
              data: [
                // {
                //   value: arrClass,
                //   name: '班级中位分',
                // },
                {
                  value: arrOneself,
                  name: '个人综合分',
                },
              ],
            }]
          });
        }

      }
    }
  };
</script>

<style>

</style>
