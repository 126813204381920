<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          搜索
        </h3>
      </div>
      <div class="card-body ">
        <el-form ref="form" :model="searchForm" label-width="120px">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="时间范围">
                <el-date-picker v-model="searchForm.timeRange" class="width_100Pie" type="daterange" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="card-footer text-center">
        <el-button type="primary" class="mr-2" slot="append" icon="el-icon-search" @click.native="onSearch()">查询
        </el-button>
        <el-button slot="append" class="mr-2" icon="el-icon-refresh" @click.native="onResetSearch()">重置</el-button>
      </div>
    </el-card>


    <!-- 列表-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          学生信息
          <small class="color-gray">（点击图标可浏览大图）</small>
        </h3>
        <div class="card-tools">
          <ul class="nav nav-pills ml-auto">
            <li class="nav-item">
              <el-button type="success" icon="el-icon-circle-plus-outline" @click="onCalculation()">计算综合之星</el-button>
              <!-- <el-button type="success" icon="el-icon-circle-plus-outline" @click="onCreate2()">新增</el-button> -->
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table :data="itemData" stripe class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column  label="综合之星名称" class="elColumn" min-width="160">
             <template slot-scope="scope">
                  {{scope.row.star}}
             </template>
          </el-table-column>
          <el-table-column  label="图标" class="elColumn" width="120">
             <template slot-scope="scope">
              <el-image style="width: 40px; height: 40px" :src="scope.row.icon" fit="contain" :preview-src-list="[scope.row.icon]"></el-image>
             </template>
          </el-table-column>
          <el-table-column prop="class" label="班级" class="elColumn" width="140" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="studentNo" label="学号" class="elColumn" width="120" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="studentName" label="姓名" class="elColumn" width="120" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column  label="计算时间" class="elColumn" width="180">
            <template slot-scope="scope">
               {{scope.row.createDate  | dateformatMinute}}
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="paging" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  // import operationBtn from '@/components/operationBtn.vue';
  export default {
    components: {
      comPage,
      // operationBtn
    },
    data() {
      return {
        // 加载动画
        fullscreenLoading: false,
        // 数据
        itemData: [],
        //分页查询接口返回的结果
        page: null,
        //分页左侧按钮集合
        pageBtnList: null,
        //分页数据
        paging: {
          // 总共页数
          totalCount: 0,
          // 当前请求数量
          PageSize: 10,
          //当前在第几页
          PageNumer: 1
        },
        searchForm: {
          timeRange: [],
        },

        isPower:{
          literacystar_default:false
        }
      }
    },
    methods: {
      onResetSearch(){
        this.searchForm.timeRange = []
        onGetAllData(this)
      },
      onSearch() {
        onGetAllData(this);
      },
      /**
       * @description 分页按钮
       * @param {Object} type
       * @param {Object} val
       */
      pagingClick(type, val) {
        let that = this;
        if (type == 1) { //更改每页条数触发的事件
          that.paging.PageSize = val;
          that.paging.PageNumer = 1;
          onGetAllData(that);
        } else { //更改当前页时触发的事件
          that.paging.PageNumer = val;
          onGetAllData(that);
        }

      },

      /**
       * @description 分页组件左侧的按钮事件，按顺序进行判断
       * @param {Object} index
       */
      pageBtnClick(index) {},
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.PageNumer - 1) * this.paging.PageSize + index + 1;
      },





      /**
       * @description 新增
       */
      onCalculation() {
        let that = this;
        that.$router.push({
          path: "/Admin/LiteracyStar/Default",
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },

    },
    created() {
      let that=this;
      isPower(that,'literacystar_default','literacystar_default');
      onGetAllData(that);
    }
  };


  /**
   * @description
   * @param {Object} that
   */
  function onGetAllData(that) {
    that.fullscreenLoading = true;
    let _data = {
      StartDate: that.searchForm.timeRange != null && that.searchForm.timeRange.length == 2 ? that.searchForm
        .timeRange[0] : null,
      EndDate: that.searchForm.timeRange != null && that.searchForm.timeRange.length == 2 ? that.searchForm
        .timeRange[1] : null,
      PageNumer: that.paging.PageNumer,
      PageSize: that.paging.PageSize,
    }
    ajaxGet(that, '/api/admin/literacystar/searchresult', _data, function(resData) {
      that.fullscreenLoading = false;
      that.itemData = resData.items.map(item =>{
        item.icon = `${that.$ServerUrl()}${item.icon}`
        return item
      })
      that.paging.totalCount = resData.totalCount;
    });
  }
</script>

<style>
  .imgBox{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
</style>
