<!--
  系统管理 - 部门管理
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
		<el-card class="box-card">
		 <div slot="header" class="card-header">
				<h3 class="card-title titleCont" style="padding-left: 0px;">
					部门管理
				</h3>
			</div>
			<div class="card-body">
				<el-row :gutter="24">
					<el-col :span="6">
						<div class="divTreeCont">
              <el-input
                placeholder="输入关键字进行过滤"
                v-model="filterText">
                <el-button slot="append" icon="el-icon-refresh-right" @click="onFilterText()"></el-button>
              </el-input>

							<el-tree :data="data" :props="defaultProps" ref="tree" v-if="data.length>0" style="margin-top:10px;"
							      node-key="id" :accordion="true"
							      @node-click="handleNodeClick" auto-expand-parent
							      :expand-on-click-node="false" :render-content="renderContent"
										:default-expanded-keys="idArr"
                    :filter-node-method="filterNode"
							>
							</el-tree>
						</div>
					</el-col>
					<el-col :span="18">
						<el-row :gutter="24">
							<el-col :span="20">
								<el-breadcrumb separator-class="el-icon-arrow-right">
								  <el-breadcrumb-item v-for="(p,index) in currentNav" :key="index">{{p}}</el-breadcrumb-item>
								</el-breadcrumb>
							</el-col>
							<el-col :span="4" style="text-align: right;">
								<el-button v-if="isPower.department_create == true"  @click="onAdd()" type="success" icon="el-icon-circle-plus-outline">新增</el-button>
							</el-col>
						</el-row>
						<el-table stripe :data="item" class="tableTwo table-hover text-nowrap "
											style="width: 100%; margin-top: 0px; ">
								<el-table-column type="index" width="120px;" label="序号"></el-table-column>
								<el-table-column prop="name" label="名称">

								</el-table-column>
								<el-table-column prop="code" label="代码"></el-table-column>
								<el-table-column label="操作" width="180px">
									<template slot-scope="scope">
										<operationBtn v-if="isPower.department_edit == true" :btnName="'编辑'" @click.native="onEdit(scope.row)"></operationBtn>
										<operationBtn v-if="isPower.department_delete == true" :btnName="'删除'" @click.native="onDelete(scope.row.id)"></operationBtn>
									</template>
								</el-table-column>
						</el-table>
					</el-col>
				</el-row>
			</div>
		</el-card>

		<el-dialog :title="dialogTitle"
		:visible.sync="dialogVisible"
		width="40%"  top="20px" :before-close="onClose"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		:append-to-body="true"
		:fullscreen="false">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="90px">
					<el-row v-if="isDetail!=true">
						 <el-col :span="24">
							<el-form-item label="部门名称" prop="name">
								<el-input maxlength="20" v-model="ruleForm.name" placeholder="请输入部门名称,最多20个字符"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="代码" prop="code">
								<el-input maxlength="20" v-model="ruleForm.code" placeholder="请输入代码,最多20个字符"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="所属类型" prop="parentId">
								<treeSelect :key="random" @getValue="getValue" :options="data" :value="ruleForm.parentId" ></treeSelect>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
		    <span slot="footer" class="dialog-footer">
					<div v-if="isDetail!=true">
						<el-button @click="onClose()">取 消</el-button>
						<el-button @click="onReset()">重 置</el-button>
						<el-button type="primary" @click="onSave()">确 定</el-button>
					</div>
					<div v-else>
						<el-button @click="dialogVisible = false">关 闭</el-button>
					</div>
		    </span>
		</el-dialog>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
	import operationBtn from '@/components/operationBtn.vue';
	import treeSelect from '@/components/TreeSelect.vue';
  export default{
    name: '',
    components:{
      operationBtn,treeSelect
    },
    data(){
      return{
        filterText: '',//查找树形菜单
				currentNav:[],
				idArr:[],
				isInit:true,
				random:0, //刷新组件
				currentId:null,
				allMenu:[],
				isDetail:false,
				dialogTitle:"新增部门",
				dialogVisible:false,
				currentTitle:"请在左侧选择部门",
				item:null,
				fullscreenLoading:false,
        data: [],
        defaultProps: {
          children: 'children',
          label: 'name',
					id:'id'
        },
				ruleForm:{
					id:null,
					name:null,
					parentId:null,
					code:null,

				},
				ruleFormInit:null,
				ruleFormReset:null,
				isPower: {
						//增删改查 权限管理
						department_create: false,
						department_edit: false,
						department_delete: false,
				},
				rules: {
					name: [
						{ required: true, message: '部门名称不能为空', trigger: 'change' },
						{ max: 20,message: '部门名称不能超过20个字符', trigger: 'change' },
					],
					code: [
						{ required: true, message: '代码不能为空', trigger: 'change' },
						{ max: 20,message: '代码不能超过20个字符', trigger: 'change' },
					],
					priority: [
						{
							validator: (rule, value, callback) => {
								let that = this;
								var reg =/^-?[1-9]\d*$/;
								if (value!=0 && !reg.exec(value)) {
									callback(new Error("优先级只能输入整数！"));
								}
								else {
									callback();
								}
							},
							trigger: 'change'
						}
					],
				},
      };
    },
		created()
		{
			let that = this;
			isPower(that, "department_create","department_create");
			isPower(that, "department_edit","department_edit");
			isPower(that, "department_delete","department_delete");
			that.ruleFormInit = JSON.parse(JSON.stringify(that.ruleForm));
			Init(that);
		},
    methods:{
      onFilterText()
      {
      	this.filterText = "";
      },
      filterNode(value, data)
      {
        if (!value) return true;
        return data.name.indexOf(value) !== -1;
      },
			onPriorityChange()
			{
				let that = this;
				var value = setInt(that.ruleForm.priority);
				that.ruleForm.priority = value;
			},
      onClose()
      {
      	let that = this;
      	that.onReset();
      	that.dialogVisible = false;
      },
			onSave()
			{
				let that = this;
				if(that.ruleForm.priority==null || that.ruleForm.priority == "")
				{
					that.ruleForm.priority = "0"
				}
				that.$refs["ruleForm"].validate(valid => {
						if (valid) {
							if(that.ruleForm.parentId == "11111111-1111-1111-1111-111111111111")
							{
								that.ruleForm.parentId = null;
							}
							if(that.ruleForm.id == "11111111-1111-1111-1111-111111111111")
							{
								that.ruleForm.id = null;
							}
							if(that.ruleForm.id == null)
							{
								that.fullscreenLoading = true;
								ajaxPost(that,"/api/admin/department",that.ruleForm,function(r){
									that.fullscreenLoading = false;
									that.dialogVisible = false;
									that.currentId = that.ruleForm.parentId;
									Init(that);
								});
							}
							else{
								that.fullscreenLoading = true;
								that.ruleForm.departmentId = that.ruleForm.id;
								ajaxPut(that,"/api/admin/department/" + that.ruleForm.id,that.ruleForm,function(r){
									that.fullscreenLoading = false;
									that.dialogVisible = false;
									that.currentId = that.ruleForm.parentId;
									Init(that);
								});
							}
						}
						else {

								setTimeout(() => {
										var isError = document.getElementsByClassName("is-error");
										isError[0].querySelector('input').focus();
								}, 1)
								return false;
						}
				});
			},
			onReset()
			{
				let that = this;
				this.ruleForm = JSON.parse(JSON.stringify(this.ruleFormReset));
				var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
				that.random += random;
				try
				{
					that.$refs["ruleForm"].resetFields();
				} catch{}
			},
			getValue(value)
			{
				let that = this;
				that.ruleForm.parentId = value;
			},
			onAdd()
			{
				let that = this;
				that.dialogVisible = true;
				that.dialogTitle = "新增部门";
				that.isDetail = false;
				that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormInit));
				that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleFormInit));
				that.ruleForm.parentId = that.currentId;
				var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
				that.random += random;
				try
				{
					that.$refs["ruleForm"].resetFields();
				} catch{}
			},
			onEdit(data)
			{
				let that = this;
				that.ruleForm = JSON.parse(JSON.stringify(data));
				that.dialogVisible = true;
				that.dialogTitle = "编辑部门";
				that.isDetail = false;
				that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
				var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
				that.random += random;
			},
			onDelete(id)
			{
				let that = this;
				confirmDelete(that,null,function(res){
					if(res == true)
					{
						that.fullscreenLoading = true;
						ajaxDelete(that,"/api/admin/department/" + id,null,function(r){
							that.fullscreenLoading = false;
							Init(that);
						});
					}
				})
			},
      handleNodeClick(data)
			{
				let that = this;
				that.item = data.children;
				that.currentTitle = data.title;
				that.currentId = data.id;
      },
			renderContent(h, { node, data, store })
			{
				return (
					<span class="custom-tree-node">
						<span style="font-size:14px;">{node.label}</span>
					</span>);
			},
    },
    mounted() {

    },
		watch:
		{
			currentTitle(val) {
				let that = this;
				var titles = val.split('/');
				that.currentNav = ["部门管理"];
				for(var i in titles)
				{
					if(titles[i] != "部门管理")
					{
						that.currentNav.push(titles[i]);
					}
				}
			},
      filterText(val) {
        this.$refs.tree.filter(val);
      }
		}
  };

	function GetItem(that,val)
	{
		 if (val.length !== 0) {
			 val.forEach((item) => {
					if(item.id == that.currentId)
					{
						that.item = item.children;
						that.currentTitle = item.title;
					}
					else if (item.children.length >= 1) {
						GetItem(that,item.children);
					}
				});

		 }
	}

	function InitItem(that,val)
	{
		 if (val.length !== 0) {
			 val.forEach((item) => {
				 that.allMenu.push(item);
					if (item.children.length >= 1) {
						InitItem(that,item.children);
					}
				});

		 }
	}

	function Init(that)
	{
		that.fullscreenLoading = true;
		that.data = [];
		ajaxGet(that, "/api/admin/department/treelist", null, function (r) {
			that.fullscreenLoading = false;
			//that.data = r;
			var tree = {
				id:"11111111-1111-1111-1111-111111111111",
				name:"部门管理",
				title:"部门管理",
				children:r,
			};
			that.data.push(tree);
			if(that.isInit == true)
			{
				that.currentId = that.data[0].id;
				that.isInit = false;
			}

			if(that.currentId == null)
			{
				that.currentId = '11111111-1111-1111-1111-111111111111';
			}


			that.allMenu = [];
			InitItem(that,that.data);
			GetItem(that,that.data);

			that.idArr = [];
			GetKeys(that,that.currentId);

		});
	}

	function GetKeys(that,id)
	{
		for(var i in that.allMenu)
		{
			if(that.allMenu[i].id == id)
			{
				that.idArr.push(id);
				if(that.allMenu[i].parentId!=null && that.allMenu[i].parentId != '11111111-1111-1111-1111-111111111111')
				{
					GetKeys(that,that.allMenu[i].parentId);
				}
			}
		}
	}
</script>

<style scoped="scoped">
  .divTreeCont{
    border: 1px solid #DDDDDD;
    padding: 15px;
    border-radius: 4px;
  }



</style>
