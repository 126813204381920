<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          搜索
        </h3>
      </div>
      <div class="card-body  ">
        <el-form ref="form" :model="searchForm" label-width="80px">
          <el-row :gutter="15">
            <el-col :span="12">
              <el-form-item label="时间范围">
                <el-date-picker v-model="searchForm.timeRange" type="daterange" range-separator="至" start-placeholder="开始日期"
                  end-placeholder="结束日期"  class="width_100Pie">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="评比范围">
                 <el-select v-model="searchForm.rangeVal" placeholder="请选择"  class="width_100Pie">
                    <el-option
                      v-for="item in optRange"
                      :key="item.Value"
                      :label="item.Text"
                      :value="item.Value">
                    </el-option>
                  </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="状态">
                <el-select v-model="searchForm.isCalculation" placeholder="请选择" class="width_100Pie">
                  <el-option v-for="item in optStatus" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

          </el-row>
        </el-form>
      </div>
      <div class="card-footer text-center">
        <el-button type="primary" class="mr-2" slot="append" icon="el-icon-search" @click.native="onSearch()">查询
        </el-button>
        <el-button slot="append" class="mr-2" icon="el-icon-refresh" @click.native="onResetSearch()">重置</el-button>
      </div>
    </el-card>


    <!-- 列表-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          综合之星信息
        </h3>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table :data="itemData" stripe class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80"></el-table-column>
          <el-table-column prop="name" label="综合之星名称" class="elColumn" min-width="200"></el-table-column>
          <el-table-column prop="rangeString" label="评比范围" class="elColumn" min-width="80"></el-table-column>
          <el-table-column label="时间范围" class="elColumn" min-width="200">
            <template slot-scope="scope">
              {{scope.row.startDate | dateformatDay}} 至 {{scope.row.endDate | dateformatDay}}
            </template>
          </el-table-column>
          <el-table-column label="状态" class="elColumn" min-width="200">
            <template slot-scope="scope">
              <span class="color-success" v-if="scope.row.isCalculation">
                已计算
              </span>
              <span class="color-gray" v-else>未计算</span>
            </template>
          </el-table-column>

          <el-table-column label="操作" width="120" fixed="right">
            <template slot-scope="scope">
              <operationBtn :btnName="'重新计算'" :btnType="'warning'"  ref="detail" v-if="scope.row.isCalculation" @click.native="onCalculation(scope.row.starId)"></operationBtn>
              <operationBtn :btnName="'开始计算'" :btnType="'primary'"  ref="update" v-else @click.native="onCalculation(scope.row.starId)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList"  @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
      </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  export default {
    components: {
      comPage,
      operationBtn
    },
    data() {
      return {
        // 加载动画
        fullscreenLoading: false,

        // 数据加载
        itemData:[],
        //分页查询接口返回的结果
        page: null,
        //分页左侧按钮集合
        pageBtnList: null,
        //分页数据
        paging: {
          // 总共页数
          pageLength: 0,
          // 当前请求数量
          Size: 10,
          //当前在第几页
          Index: 1
        },
        // 搜索
        searchForm:{
          timeRange:[],
          rangeVal:'',
          isCalculation:0,
        },
        searchFormRest:null,
        // 状态
        optStatus:[
          {
            value:0,
            label:'全部'
          },
          {
            value:1,
            label:'未计算'
          },
          {
            value:2,
            label:'已计算'
          },
        ],
        // 范围
        optRange:[],
      }
    },
    methods: {
      /**
       * @description 计算
       * @param {Object} valID
       */
      onCalculation(valID){
        let that=this;
        that.fullscreenLoading=true;
        ajaxPut(that,'/api/admin/literacystar/calculation/'+valID,null,function(res){
          that.fullscreenLoading=false;
        });
      },
      /**
       * @description 分页按钮
       * @param {Object} type
       * @param {Object} val
       */
      pagingClick(type, val) {
        let _this = this;
        if (type == 1) { //更改每页条数触发的事件
          _this.paging.Size = val;
          _this.paging.Index = 1;
          onGetAllData(_this);
        } else { //更改当前页时触发的事件
          _this.paging.Index = val;
          onGetAllData(_this);
        }
      },

      /**
       * @description 分页组件左侧的按钮事件，按顺序进行判断
       * @param {Object} index
       */
      pageBtnClick(index) {},
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },

      /**
       * @description 搜索事件
       */
      onSearch(){
        let _this=this;
        onGetAllData(_this);
      },
      /**
       * @description 重置搜索
       */
      onResetSearch(){
        let _this=this;
        _this.searchForm = JSON.parse(JSON.stringify(_this.searchFormRest));
      },
    },
    created() {
      let _this=this;
      // onGetType(_this);
      _this.fullscreenLoading=true;
      ajaxGet(_this,'/api/admin/literacystar/type',null,function(resData){
        _this.fullscreenLoading=false;
        _this.optRange=resData;
      onGetAllData(_this);
      _this.searchFormRest = JSON.parse(JSON.stringify(_this.searchForm));
      });
    }
  };

  function onGetAllData(_this){
    _this.fullscreenLoading=true;
    // 判断是否计算
    let tempBoolen=null;
    switch(Number(_this.searchForm.isCalculation)){
      case 1:
      tempBoolen=false;
      break;
      case 2:
      tempBoolen=true;
      break;
      default:
      tempBoolen=null;
      break;
    }
    //


    let _data={
      StartDate:_this.searchForm.timeRange.length==2?_this.searchForm.timeRange[0]:null,
      EndDate:_this.searchForm.timeRange.length==2?_this.searchForm.timeRange[1]:null,
      Range:regNull(_this.searchForm.rangeVal)?null:Number(_this.searchForm.rangeVal),
      IsCalculation:tempBoolen,
      PageNumer: _this.paging.Index,
      PageSize: _this.paging.Size,
    }
    ajaxGet(_this,'/api/admin/literacystar/search',_data,function(resData){
      _this.fullscreenLoading=false;
      _this.page = resData;
      _this.itemData = resData.items;
    });
  }

  function onGetType(_this){

  }
</script>

<style>
</style>
