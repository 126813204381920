<!--
  学生管理-学生奖励
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <search :ColNumber="3" :SearchList="SearchList" :key="random" ref="SearchList" @onSearch="onSearch"
      v-if="SearchList!=null"></search>
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          班级综合积分
        </h3>
        <div class="card-tools">
          <ul class="nav">

            <li class="nav-item">
              <!--导入-->
              <comImport @onImportSuccess="onImportSuccess" :template="'/templates/班级综合积分记录导入模版.xlsx'"
                :path="uploadInfoApi" :btnIcon="'el-icon-upload2'" :title="'批量导入'" :btnName="'导入'"
                ref="conImportInfoFile" v-if="isPower.classcreditlog_import == true">
              </comImport>
            </li>
            <li class="nav-item ml-1">
              <el-button type="success" icon="el-icon-circle-plus-outline" v-if="isPower.classcreditlog_create"
                @click="onCreate">新增</el-button>
            </li>

          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table stripe :data="itemData" class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="reason" label="原因" min-width="200" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="class" label="班级" width="140"></el-table-column>
          <el-table-column label="学生" min-width="120" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              {{onGetStudentInfo(scope.row.members)}}
            </template>
          </el-table-column>
          <el-table-column prop="score" label="分数" width="100"></el-table-column>
          <el-table-column label="行为时间" width="180">
            <template slot-scope="scope">
              {{scope.row.logDate | dateformatDay}}
            </template>
          </el-table-column>
          <el-table-column prop="auditor" label="记录人" width="100"></el-table-column>
          <el-table-column label="操作" :width="thWidth" fixed="right" v-if="isPower.classcreditlog_details || isPower.classcreditlog_edit || isPower.classcreditlog_delete">
            <template slot-scope="scope">

              <operationBtn :btnName="'查看'" v-if="isPower.classcreditlog_details"
                @click.native="onDetail(scope.row.logId)"></operationBtn>
              <operationBtn :btnName="'编辑'" v-if="isPower.classcreditlog_edit"
                @click.native="onEdit(scope.row.logId)"></operationBtn>
              <operationBtn :btnName="'删除'" v-if="isPower.classcreditlog_delete"
                @click.native="onDelete(scope.row.logId)"></operationBtn>

            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>

    <el-dialog :title="stuInformation.studentName" :visible.sync="dialogVisible" width="80%">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-lg-12 colInfo">
            <label>原因</label>
            <div class="colFonts">{{stuInformation.reason}}</div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>分数</label>
            <div class="colFonts">{{stuInformation.score}}</div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>行为时间</label>
            <div class="colFonts">{{stuInformation.logDate| dateformatDay}}</div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>操作时间</label>
            <div class="colFonts">{{stuInformation.createDate| dateformatMinute}}</div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>记录人</label>
            <div class="colFonts">{{stuInformation.auditor}}</div>
          </div>
          <div class="col-lg-12 colInfo">
            <label>学生</label>

            <el-row :gutter="20" v-if="stuInformation.members!=null && stuInformation.members.length>0">
              <el-col :lg="8" :md="8" :sm="12" v-for="(p,index) in stuInformation.members" :key="index">
                <div class="stuInfo">
                  <div class="stuInfoBox">
                    <div class="stuImgCont VerticalMid">
                      <el-image :src="p.photo">
                        <div slot="error" class="image-slot">
                          <svg class="icon" aria-hidden="true">
                            <use xlink:href="#fax-image-split"></use>
                          </svg>
                        </div>
                      </el-image>
                    </div>
                    <div class="divInfoBox">
                      <div class="stuName">{{p.studentName}}</div>
                      <div class="stuOther">{{p.studentNo}}</div>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>

          </div>
        </div>
      </div>
    </el-dialog>


  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';
  import comImport from '@/components/Import.vue';

  export default {
    name: '',
    components: {
      comImport,
      comPage,
      operationBtn,
      search
    },
    data() {
      return {
        dialogVisible: false,
        random: 0, //刷新组件
        fullscreenLoading: false,
        itemData: [],
        page: null, //分页查询接口返回的结果
        pageBtnList: null, //分页左侧按钮集合
        paging: { //分页数据
          pageLength: 0, // 总共页数
          Size: 10, // 当前请求数量
          Index: 1 //当前在第几页
        },
        isPower: {
          moraleducation_user: false,
          classcreditlog_edit: false,
          classcreditlog_create: false,
          classcreditlog_details: false,
          classcreditlog_import: false,
        },
        stuInformation: {},
        SearchList: null, //搜索集合
        thWidth: 100,

        TeacherInfo: {},

        uploadInfoApi: '/api/admin/classcreditlog/classcreditlogimport',
      };
    },
    created() {
      let that = this;
      that.TeacherInfo = onGetNowUser(that);
      isPower(that, 'moraleducation_user', 'moraleducation_user')
      isPower(that, 'classcreditlog_edit', 'classcreditlog_edit')
      isPower(that, 'classcreditlog_create', 'classcreditlog_create')
      isPower(that, 'classcreditlog_details', 'classcreditlog_details')
      isPower(that, 'classcreditlog_delete', 'classcreditlog_delete')
      isPower(that, 'classcreditlog_import', 'classcreditlog_import')
      pagingPage(that);

      that.thWidth = onGetWidth(that, [
        that.isPower.classcreditlog_details,
        that.isPower.classcreditlog_edit && that.isPower.moraleducation_user == false,
        that.isPower.classcreditlog_delete
      ]);
    },
    methods: {
      /**
       * @description 导出学生信息成功后执行
       * @param {Object} param
       */
      onImportSuccess(param) {
        let that = this;
        pagingPage(that);
      },
      /**
       * @description 获取学生信息
       * @param {Object} objVal
       */
      onGetStudentInfo(objVal) {
        let tempArr = new Array(objVal.map(item => item.studentName));
        return tempArr.join('、');
      },
      /**
       * @description 返回
       */
      onBack() {
        back(this);
      },
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },
      onDelete(objID) {
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, '/api/admin/classcreditlog/' + objID, null, function(res) {
              that.fullscreenLoading = false;
              pagingPage(that);
            });
          }
        })
      },
      /**
       * @description 详情
       * @param {Object} objID
       */
      onDetail(objID) {
        let that = this;
        that.fullscreenLoading = true;
        ajaxGet(that, '/api/admin/classcreditlog/' + objID, null, function(resdata) {
          that.dialogVisible = true;
          that.fullscreenLoading = false;
          that.stuInformation = resdata;
          that.stuInformation.photo = that.$ServerUrl() + that.stuInformation.photo;
        })
      },
      /**
       * @description 编辑
       */
      onEdit(objID) {
        let that = this;
        that.$router.push({
          path: "/Admin/MoralEducation/Edit/" + objID,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      /**
       * @description 新增
       */
      onCreate() {
        let that = this;
        that.$router.push({
          path: "/Admin/MoralEducation/Create",
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      onSearch(params) {
        let that = this;
        let searchData = params;
        let data = {};
        searchData.forEach(element => {
          data[element.zhi] = Join(element.data);
        });
        data.PageNumer = 1;
        data.radom = Math.random(1000)
        routerPath(that, "/Admin/MoralEducation/Index", data, null, "PageSize");

      },
      pagingClick(type, val) {
        let that = this;
        let url = "/Admin/MoralEducation/Index";
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "ClassId",
            "Student"
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "ClassId",
            "Student"
          );
        }
      },
    },
    watch: {
      $route() {
        let that = this;
        pagingPage(that);
      }
    }
  };

  function pagingPage(that) {
    paging(
      that,
      function(r) {
        that.fullscreenLoading = true;

        let resultClass = that.TeacherInfo.isTeacher ? that.TeacherInfo.manageClass : that.TeacherInfo.allClass;
         let ClassId = r.ClassId;
        if(!regNullArray(resultClass)){

          var classList = [];

          if (ClassId == null || typeof(ClassId) == "undefined" || ClassId == "") {
            ClassId = resultClass[0].classId;
          }

          for (var i in resultClass) {
            classList.push({
              Title: resultClass[i].alias,
              Value: resultClass[i].classId,
            });
          }
        }

        that.SearchList = [{
          type: "select",
          Name: "班级",
          data: ClassId,
          select: classList,
          zhi: "ClassId",
          isClear: false, //select是否可以清除
          resetData: ClassId,
        }, {
          type: "input",
          Name: "学生",
          data: r.Student,
          holder: "请输入学号/姓名",
          zhi: "Student"
        }];
        let data = {
          ClassId: ClassId,
          Student: r.Student,
          PageNumer: r.PageNumer,
          PageSize: r.PageSize,
        }
        that.paging.Index = r.PageNumer;
        that.paging.Size = r.PageSize;
        ajaxGet(that, '/api/admin/classcreditlog/search', data, function(res) {
          that.fullscreenLoading = false;
          that.itemData = res.items;
          that.page = res;
        });

      },
      "ClassId",
      "Student",
    );
  }

</script>

<style scoped="scoped">
  .stuInfo {
    position: relative;
    height: 100%;
    min-height: 80px;
    margin: 15px;
  }

  .stuInfo .stuInfoBox {
    display: flex;
  }

  .stuInfo .divInfoBox {
    padding-left: 10px;
  }


  .stuInfo .stuImgCont {
    width: 50px;
    height: 50px;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #DDDDDD;
  }



  .stuInfo .stuName {
    color: #212E48;
    font-size: 18px;
    font-weight: bold;
  }

  .stuInfo .stuRole {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
  }

  .stuInfo .stuRoom {
    font-size: 12px;
    color: #767676;
  }

  .stuInfo .stuOther {
    color: #767676;
    font-size: 12px;
  }

  .stuInfo .stuRemove {
    display: none;
  }

  .stuInfo:hover .stuRemove {
    display: block;
    position: absolute;
    top: -0.9375rem;
    left: -0.625rem;
    font-size: 22px;
    cursor: pointer;
  }



  .tabListCont .divNavTitle {}

  .tabListCont .divNavTitle {}

  .tabListCont .divNavTitle .divTitleCont {
    padding: 10px 0px 10px 10px;
    color: #DEE2E6;
    cursor: pointer;
    font-size: 18px;
  }

  .tabListCont .divNavTitle .divTitleCont .spNum {
    background-color: #DEE2E6;
    font-family: 'OswaldLight';
    padding: 2px 5px;
    border-radius: 4px;
    margin-left: 5px;
    font-size: 14px;
    color: #FFFFFF;
  }

  .tabListCont .divNavTitle .divTitleAct {
    color: #3e3e3e;
  }

  .tabListCont .divNavTitle .divTitleAct .spNum {
    background-color: #17A2B8;
    color: #FFFFFF;
  }

  .tabListCont .divNavTitle .nav-link {
    font-size: 14px;
    color: #B1B1B1;
  }

  .tabListCont .divNavTitle .nav-link .spNum {
    font-family: 'OswaldLight';
    font-weight: normal;
    font-size: 14px;
    padding-left: 10px;
  }

  .tabListCont .divNavTitle .active {
    color: #007bff;
  }

</style>

