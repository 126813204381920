<!--
  我的成绩 - 成绩详情
-->
<template>
  <div class="SubWebPageStudent" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form label-width="100px">
      <!-- <div class="divBreadcrumbCont">
       <router-link to="/Student/Home/Index" class="alinkUrl">首页</router-link>
       /
       <router-link to="/Student/AcademicRecord/Index" class="alinkUrls">我的成绩</router-link>
       /
       <span class="spNowUrl">成绩详情</span>
      </div> -->
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            查看我的成绩
          </h3>
        </div>
        <div class="card-body p-0">
          <el-row :gutter="24">
          	<el-col :span="24">
          		<el-form-item label="课程">
          			{{'['+itemData.courseCode+'] '+ itemData.courseName | flNoValue}}
          		</el-form-item>
          	</el-col>
          	<el-col :span="12" >
          		<el-form-item label="平时">
          			{{itemData.peaceTime | flNoValue }}
          		</el-form-item>
          	</el-col>
          	<el-col :span="12">
          		<el-form-item label="期中">
          			{{itemData.midTerm | flNoValue}}
          		</el-form-item>
          	</el-col>
          	<el-col :span="12">
          		<el-form-item label="期末">
          			{{itemData.final | flNoValue}}
          		</el-form-item>
          	</el-col>
          	<el-col :span="12">
          		<el-form-item label="总评">
          			{{itemData.total | flNoValue}}
          		</el-form-item>
          	</el-col>
          	<el-col :span="12">
          		<el-form-item label="绩点">
          			{{itemData.gpa | flNoValue}}
          		</el-form-item>
          	</el-col>
            <el-col :span="12">
            	<el-form-item label="学分">
            		{{itemData.credit | flNoValue}}
            	</el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>
      <div class="row" style="margin-top: 10px;">
      		<div class="col-12">
      			<el-button  icon="el-icon-back" @click="onBack()">返回</el-button>
      		</div>
      </div>
    </el-form>
  </div>
</template>

<script>

  import '@/assets/css/Student.css';
  export default{
    components:{

    },
    data(){
      return{
        // 动画
        fullscreenLoading: false,
        dialogVisible: false,
        //列表数据
        itemData: [],
      };
    },
    methods:{
      onBack()
      {
      	let that = this;
      	back(that);
      },
    },
    created()
    {
      let that = this;
      let id = that.$route.params.id;
      that.fullscreenLoading = true;
      ajaxGet(that,"/api/student/academicrecord/"+id,null,function(r){
        that.fullscreenLoading = false;
        that.itemData=r;
      })
    },
    mounted()
    {

    },
    filters:{
      flNoValue(obj){
        if (obj == null || typeof(obj) == "undefined" || obj == ''){
          return '暂无数据';
        }
        return obj;
      }
    },

  };


</script>

<style scoped="scoped">

</style>
