<!--
  综合活动 - 确认名单
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <search :SearchTitles="SearchTitles" :ColNumber="3" :SearchList="SearchList" ref="SearchList" @onSearch="onSearch"
      v-if="SearchList!=null"></search>
    <!-- 列表-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          {{Title}}
          <small class="color-gray"
            v-if="activityData!=null && activityData.numberLimit!=null && activityData.numberLimit>0">计划报名人数 <span
              class="color-danger">{{activityData.numberLimit}}</span></small>
        </h3>
        <div class="card-tools">
          <comImport v-if="ImportFile!=null" @onImportSuccess="onImportSuccess" :template="ImportFile" :path="ImportUrl"
            :btnSize="'small'" :btnName="'导入名单'" ref="conImportFile">
          </comImport>

          <el-button @click="onCheckAll" size="medium">
            <svg class="icon" aria-hidden="true" v-if="iconAllCheck">
              <use xlink:href="#fax-circle-check"></use>
            </svg>
            <svg class="icon" aria-hidden="true" v-else>
              <use xlink:href="#fax-circle-o"></use>
            </svg>
            全选通过或拒绝
          </el-button>
        </div>
      </div>
      <div class="card-body p-0" v-if="item!=null && item.length!=0">


        <el-table v-if="item!=null" stripe sortable="custom" :data="item"
          style="width: 100%; margin-top: 10px; padding-left: 10px; padding-right: 10px;">
          <el-table-column type="index" width="100px;" label="序号"></el-table-column>
          <el-table-column prop="className" label="班级"></el-table-column>
          <el-table-column prop="studentNo" label="学号">
            <template slot-scope="scope">
              <span v-if="scope.row.studentNo == null || scope.row.studentNo == ''">/</span>
              <span v-else>{{scope.row.studentNo}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="studentName" label="学生">
            <template slot-scope="scope">
              <span v-if="scope.row.studentName == null || scope.row.studentName == ''">/</span>
              <span v-else>{{scope.row.studentName}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="roleName" label="角色"></el-table-column>
          <el-table-column prop="applyDate" label="报名时间">
            <template slot-scope="scope">
              {{scope.row.applyDate | dateformatMinute}}
            </template>
          </el-table-column>
          <el-table-column prop="statusString" label="状态">
            <template slot-scope="scope">
              <el-radio-group v-model="scope.row.status" @change="onChangeRadio($event, scope.row)">
                <el-radio :label="3">通过</el-radio>
                <el-radio :label="2">拒绝</el-radio>
              </el-radio-group>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>

    <div class="row" style="margin-top: 10px;">
      <div class="col-6">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
        <el-button type="primary" icon="el-icon-circle-check" @click="onSave()">提交</el-button>
      </div>
    </div>

  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import comImport from '@/components/Import.vue';
  import search from '@/components/search.vue';
  export default {
    components: {
      comPage,
      comImport,
      search
    },
    data() {
      return {
        iconAllCheck: false,
        itemData: [],
        dialogTitle: '',
        dialogVisible: false,
        SearchTitles: [],
        SearchList: null, //搜索集合
        id: null,
        parentType: null,
        fullscreenLoading: false,
        Title: null,
        ImportTitle: null,
        ImportUrl: null,
        ImportFile: null,
        item: null,
        page: null, //分页查询接口返回的结果
        pageBtnList: null, //分页左侧按钮集合
        paging: { //分页数据
          pageLength: 0, // 总共页数
          Size: 10, // 当前请求数量
          Index: 1 //当前在第几页
        },
        selectRosters: [],
        allRosters: [],
        activityData: null,

        // 导入提示错误
        dialogVisibleErr: false,
        dialogTitleErr: null,
        itemDataErr: [],

      };
    },
    created() {
      let that = this;
      that.id = that.$route.params.id;
      that.parentType = that.$route.params.type;
      var detailUrl = null;
      if (that.id != null && typeof(that.id) != "undefined" && that.id != "") {
        detailUrl = "/api/admin/activity/" + that.id;

        that.fullscreenLoading = true;
        ajaxGet(that, detailUrl, null, function(r) {
          if (r != null) {
            that.activityData = r;
            that.Title = r.name;
            if (r.isApply == true) {
              that.Title = that.Title + '【确认名单】';
              that.ImportTitle = '导入报名名单';
              that.ImportUrl = '/api/admin/activity/registerroster/' + that.id;
              that.ImportFile = '/Templates/活动名单导入模版.xlsx';
            } else if (r.isApply == false && r.isUpload == true) {
              that.Title = that.Title + '【确认名单】';
              that.ImportTitle = '导入参与名单';
              that.ImportUrl = '/api/admin/activity/registerroster/' + that.id;
              that.ImportFile = '/Templates/活动名单导入模版.xlsx';
            }
          }
          that.fullscreenLoading = false;
        });
      }

      Init(that);

      // 获取确认名单全部数据
      that.fullscreenLoading = true;
      ajaxGet(that, '/api/admin/activity/roster/' + that.id, null, function(r) {
        that.fullscreenLoading = false;
        that.allRosters = JSON.parse(JSON.stringify(r));

        if (that.selectRosters && that.selectRosters.length == 0) {
          r.forEach(it => {
            if (it.status == 2) {
              that.selectRosters.push({
                RosterId: it.rosterId,
                IsPass: false,
              });
            }
            if (it.status == 3) {
              that.selectRosters.push({
                RosterId: it.rosterId,
                IsPass: true,
              });
            }
          })
        }

      })
    },

    methods: {
      /**
       * @description
       */
      onChangeRadio(e, value) {
        let that = this;
        let it = that.selectRosters.find(x => x.RosterId == value.rosterId);
        if (it) {
          if (value.status == 2) {
            it.IsPass = false;
          } else if (value.status == 3) {
            it.IsPass = true;
          }
        } else {
          if (value.status == 2) {
            that.selectRosters.push({
              RosterId: value.rosterId,
              IsPass: false,
            });
          } else if (value.status == 3) {
            that.selectRosters.push({
              RosterId: value.rosterId,
              IsPass: true,
            });
          }
        }


        let temp = that.selectRosters.filter(val => val.IsPass == true);
        if (!regNull(that.activityData.numberLimit) && setInt(that.activityData.numberLimit) > 0) {
          if (temp.length > setInt(that.activityData.numberLimit)) {
            warning(that, '活动计划报名人数为：' + that.activityData.numberLimit + ' 人，现已超出 ' + (temp.length - setInt(that
              .activityData.numberLimit)) + ' 人')
          }
        }

      },

      /**
       * @description 全选
       */
      onCheckAll() {
        let that = this;
        that.selectRosters = [];
        if (that.iconAllCheck) { // 全部拒绝
          that.iconAllCheck = false;
          that.allRosters.forEach(it => {
            that.selectRosters.push({
              RosterId: it.rosterId,
              IsPass: false,
            })
          })

          that.selectRosters.forEach(ele => {
            let selectItem = that.item.find(x => { return x.rosterId == ele.RosterId });
            if (selectItem) { selectItem.status = 2 }
          })
        } else { // 全部通过
          that.iconAllCheck = true;
          that.allRosters.forEach(it => {
            that.selectRosters.push({
              RosterId: it.rosterId,
              IsPass: true,
            })
          })

          if (!regNull(that.activityData.numberLimit) && that.selectRosters.length > setInt(that.activityData
              .numberLimit)) {
            warning(that, '活动计划报名人数为：' + that.activityData.numberLimit + ' 人，现已超出 ' + (that.selectRosters.length -
              setInt(that.activityData.numberLimit)) + ' 人');
          }

          that.selectRosters.forEach(ele => {
            let selectItem = that.item.find(x => { return x.rosterId == ele.RosterId });
            if (selectItem) { selectItem.status = 3 }
          })
        }


        // if (that.iconAllCheck) {
        //   that.iconAllCheck = false;
        // } else {
        //   that.iconAllCheck = true;
        // }
        // let tempArr = [];
        // for (var item of that.item) {
        //   tempArr.push({
        //     applyDate: item.applyDate,
        //     attachment: item.attachment,
        //     attended: item.attended,
        //     className: item.className,
        //     photo: item.photo,
        //     roleId: item.roleId,
        //     roleName: item.roleName,
        //     rosterId: item.rosterId,
        //     scoring: item.scoring,
        //     status: that.iconAllCheck ? 3 : 2,
        //     statusString: item.statusString,
        //     studentId: item.studentId,
        //     studentName: item.studentName,
        //     studentNo: item.studentNo,
        //   });
        // }
        // that.item = tempArr;

      },

      onImportSuccess(param) {
        let that = this;
        Init(that);
      },
      onSave() {
        let that = this;
        if (that.selectRosters.length <= 0) {
          warning(that, "没有选择学生，不能提交");
          return false;
        }
        // 认数超出规定数量不可以提交报名
        var rostersPass = that.selectRosters.filter(x => x.IsPass == true);
        if (!regNull(that.activityData.numberLimit) && rostersPass.length > setInt(that.activityData.numberLimit)) {
          warning(that, '活动计划报名人数为：' + that.activityData.numberLimit + ' 人，现已超出 ' + (rostersPass.length - setInt(that
            .activityData.numberLimit)) + ' 人')
          return false;
        }
        that.fullscreenLoading = true;
        ajaxPut(that, "/api/admin/activity/RegisterConfirm/" + that.id, that.selectRosters, function(r) {
          that.fullscreenLoading = false;
          that.onBack();
        });
      },
      onBack() {
        let that = this;
        back(that);
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      onSearch(params) {
        let that = this;
        let data = {};
        let searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = element.data;
          }
        });
        data["url"] = that.$route.query.url;
        data.PageNumer = 1;
        routerPath(that, "/Admin/activity/confirm/" + that.parentType + "/" + that.id, data, null, "PageSize");
      },
      pagingClick(type, val) {


        let that = this;
        let url = "/Admin/activity/confirm/" + that.parentType + "/" + that.id;
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "ClassId",
            "Student",
            "url"
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "ClassId",
            "Student",
            "url"
          );
        }
      },
    },
    watch: {
      $route() {
        let that = this;
        Init(that);
      },
    },
  };

  function Init(that) {
    pagingPage(that);
  }

  function pagingPage(that) {
    paging(
      that,
      function(r) {

        ajaxGet(that, "/api/admin/sysuser/userfilternograduate", null, function(resultClass) {
          var classList = [];
          for (var i in resultClass) {
            var it = {
              Title: resultClass[i].alias,
              Value: resultClass[i].classId,
            };
            classList.push(it);
          }

          that.SearchList = [{
              type: "select",
              Name: "班级",
              data: r.ClassId,
              select: classList,
              zhi: "ClassId"
            },
            {
              type: "input",
              Name: "学生",
              data: r.Student,
              holder: "请输入学号/姓名",
              zhi: "Student"
            },
          ];

          let data = {
            ActivityId: that.id,
            Student: r.Student,
            ClassId: r.ClassId,
            PageNumer: r.PageNumer,
            PageSize: r.PageSize,
          };

          ajaxGet(that, "/api/admin/activity/rostersearch", data, function(r1) {
            that.page = r1;
            that.item = r1.items;
            if (that.selectRosters && that.selectRosters.length > 0) {
              that.item.forEach(ele => {
                ele.status = null;
                let it = that.selectRosters.find(x => x.RosterId == ele.rosterId);
                if (!regNull(it)) {
                  if (it.IsPass) {
                    ele.status = 3
                  } else {
                    ele.status = 2
                  }
                }
              })
            }
          });
        });

      },
      "Student",
      "ClassId"
    );
  }

</script>


<style scoped="scoped">


</style>

