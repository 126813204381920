<!--
  学生会事务 - 行为规范
-->
<template>
  <div class="SubWebPageStudent" v-loading.fullscreen.lock="fullscreenLoading">
    <!-- 搜索 -->
    <search :key="random" :SearchTitles="SearchTitles" :ColNumber="2" :SearchList="SearchList" ref="SearchList"
      @onSearch="onSearch" v-if="SearchList!=null"></search>
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          行为规范
          <span class="titleErr" v-if="currentType == 1 || currentType == 2">
            （记录由{{currentType == 1 ? '班级' : '宿舍'}}个人关联而来的，无法删除）
          </span>
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <el-button type="success" icon="el-icon-circle-plus-outline" @click="onCreate()">新增
              </el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table :data="itemData" stripe class="elTableData" border>
          <el-table-column type="index" label="序号" class="elColumn" width="80"  align="center"></el-table-column>
          <el-table-column prop="className" label="班级" width="160" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.related == true" style="color: #409EFF;font-size:16px">关联</span>
              <span v-if="scope.row.transformStatus == 2" style="color: #409EFF">已落实</span>
              {{scope.row.class ? scope.row.class : scope.row.className}}
            </template>
          </el-table-column>
          <el-table-column prop="room" label="宿舍"  width="120" align="center">
          </el-table-column>
          <el-table-column  label="责任人"  width="80" align="center" v-if="currentType == 3">
            <template slot-scope="scope">
              {{scope.row.student ? scope.row.student : scope.row.responsible}}
            </template>
          </el-table-column>
          <el-table-column label="原因" min-width="300" :show-overflow-tooltip="true" align="center">
              <template slot-scope="scope">
                    <span v-if="scope.row.appealStatus == 1" style="color: #409EFF">申诉中</span>
                    <span v-if="scope.row.appealStatus == 2" style="color: #67C23A">申诉成功</span>
                    <span v-if="scope.row.appealStatus == 3" style="color: #F56C6C">申诉驳回</span>
                    <span v-if="scope.row.appealStatus == 4" style="color: #909399">申诉过期</span>
                    <span v-html="onGetReason(scope.row)"></span>
              </template>
          </el-table-column>
          <el-table-column prop="remark" label="备注" width="120" align="center"></el-table-column>
          <el-table-column prop="score" label="行为分数" width="120" align="center"></el-table-column>
          <el-table-column label="行为时间" width="150">
            <template slot-scope="scope">
              <span>{{scope.row.logDate | dateformatMinute}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="240" fixed="right" align="center">
            <template slot-scope="scope">
              <operationBtn :btnName="'查看'" @click.native="onDetail(scope.row.memberId, scope.row.logId)">
              </operationBtn>
              <operationBtn v-if="scope.row.related != true " :btnName="'删除'" @click.native="onDelete(scope.row)">
              </operationBtn>
              <operationBtn v-if="scope.row.appealStatus == 1" :btnName="'申述处理'" @click.native="onApprove(scope.row.memberId, scope.row.logId)">
              </operationBtn>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>

      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>


    <!-- 申诉处理 -->
    <el-dialog :title="'申诉处理'" :visible.sync="dialogVisibleApprove" width="30%" :before-close="handleCloseApprove"
      :close-on-click-modal="false" :close-on-press-escape="false" :append-to-body="true" :fullscreen="false">
      <el-form ref="ruleFormApprove" :model="ruleFormApprove" :rules="rulesApprove">
        <el-row :gutter="20">
            <el-col :span="24" class="colInfo">
              <label>申诉理由</label>
              <div class="colFonts">{{regNull(ruleFormApprove.appealReason) ? '暂无数据' : ruleFormApprove.appealReason}}</div>
            </el-col>
        </el-row>
        <el-form-item label="处理意见" prop="approveReason">
          <el-input type="textarea" v-model="ruleFormApprove.approveReason" :rows="5" placeholder="请输入处理意见"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleApprove = false">取 消</el-button>
        <el-button type="danger" @click="onUpdateApprove('Reject')">驳 回</el-button>
        <el-button type="primary" @click="onUpdateApprove('Pass')">通 过</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
  import '@/assets/css/Student.css';
  import comPage from '@/components/Page.vue';
  import search from '@/components/search.vue';
  import operationBtn from '@/components/operationBtn.vue';
import { element } from 'screenfull';

  export default {
    components: {
      search,
      operationBtn,
      comPage
    },
    data() {
      return {
        random: 0, //刷新组件
        // 动画
        fullscreenLoading: false,
        dialogVisibleApprove: false,
        // 搜索
        SearchList: null,
        //列表数据
        itemData: [],
        //分页查询接口返回的结果
        page: null,
        //分页左侧按钮集合
        pageBtnList: null,
        //分页数据
        paging: {
          // 总共页数
          pageLength: 0,
          // 每页请求的数据量
          Size: 1,
          //当前在第几页
          Index: 1
        },
        SearchTitles: [],
        currentType: null,
        ruleFormApprove: {
          logId: null,
          memberId: null,
          approveStatus: null,
          approveReason: null,
          appealReason: null,
        },
        rulesApprove: {
          approveReason:[{ max: 256, message: '处理意见不能超过256个字符', trigger: 'change'}],
        },
        ruleFormApproveResert: null,
        optAppealStatus: [],
        optAll: [{
          Value: null,
          Title: '全部'
        },{
          Value: 'true',
          Title: '已落实'
        },{
          Value: 'false',
          Title: '未落实'
        }]
      };
    },
    methods: {
      /**
       * @description 展示关联的学生名单
       */
       onGetRelatedStudent(objItem){
        if(!regNullArray(objItem)){
          return '关联学生：'+objItem.map(item=> {return item.name+'【'+item.studentNo+'】'}).join('、');
        }else{
          return '暂无关联学生';
        }
      },
      /**
       * @description 申述处理
       * @param {Object} typeString Pass通过；Reject驳回
       */
      onUpdateApprove(typeString) {
        let that = this;
        if (typeString == 'Pass') {
          that.ruleFormApprove.approveStatus = 2;
        } else if (typeString == 'Reject') {
          that.ruleFormApprove.approveStatus = 3;
        }
        that.$refs["ruleFormApprove"].validate(valid => {
          if (valid) {
            let url = "";
            that.fullscreenLoading = true;
              ajaxPut(that, "/api/student/studentunionmoral/approve", that.ruleFormApprove, (r) => {
                that.fullscreenLoading = false;
                that.dialogVisibleApprove = false;
                Init(that);
              })
          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              var el = isError[0].querySelector('input') || isError[0].querySelector('textarea') || isError[0].querySelector('select');
              if(el) {
                el.focus();
              }
            }, 1)
            return false;
          }
        })
      },
      handleCloseApprove() {
        let that = this;
        that.dialogVisibleApprove = false;
      },
      /**
       * @description 申述处理
       * @param {Object} memberId
       * @param {Object} logId
       */
      onApprove(memberId, logId) {
        let that = this;
        that.ruleFormApprove = JSON.parse(JSON.stringify(that.ruleFormApproveResert));
        that.ruleFormApprove.logId = logId;
        that.$nextTick(() => {
          that.$refs.ruleFormApprove.clearValidate();
        })
        that.dialogVisibleApprove = true;
      },
      /**
       * @description 显示原因
       * @param {Object} objItem
       */
      onGetReason(objItem) {
        return objItem.reason + (regNull(delHtml(objItem.remark)) ? '' : '<span class="color-gray">（' + delHtml(objItem
          .remark) + '）</span>');
      },
      onDelete(objItem) {
        let that = this;
        var deleteUrl = "/api/student/studentunionmoral/" + objItem.logId;
        if (deleteUrl != "") {
          confirmDelete(that, null, function(res) {
            if (res == true) {
              that.fullscreenLoading = true;
              ajaxDelete(that, deleteUrl, null, function(r) {
                that.fullscreenLoading = false;
                Init(that);
              });
            }
          })
        }
      },
      onCreate() //新增
      {
        let that = this;
        that.$router.push({
          path: "/Student/BehaviorRecord/Create/" + that.currentType,
          query: {
            type: that.currentId
          }
        });
      },
      onSearch(params) // 搜索
      {
        let that = this;
        let data = {};
        let searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = Join(element.data);
          }
        });
        data.PageNumer = 1;
        data["type"] = that.currentType;
        routerPath(that, "/Student/BehaviorRecord/Index", data, null, "PageSize");
      },
      onDetail(memberId, logId) {
        let that = this;
        let url = "/Student/BehaviorRecord/Details/" + that.currentType + "/" + logId
        that.$router.push({
          path: url,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      // 分页面按钮
      pagingClick(type, val) {
        let that = this;
        let url = "/Student/BehaviorRecord/Index?type=" + that.currentType;
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "SemesterId",
            "Reason",
            "StudentInfo",
            "LogDate",
            "ClassId",
            "AppealStatus",
            "All"
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "SemesterId",
            "Reason",
            "StudentInfo",
            "LogDate",
            "ClassId",
            "AppealStatus",
            "All"
          );
        }
      }
    },
    created() {
      let that = this;
      that.ruleFormApproveResert = JSON.parse(JSON.stringify(that.ruleFormApprove));
      ajaxGet(that, "/api/dict/appealstatus", null, (r) => {
        if (!regNullArray(r)) {
          r.forEach(element => {
            that.optAppealStatus.push({
              Value: element.Value,
              Title: element.Text,
            })
          });
        }

        Init(that);
      })
    },
    // mounted() {

    // },
    watch: {
      $route() {
        let that = this;
        Init(that);

      }
    }
  };

  function Init(that) {
    let tempStudent = JSON.parse(that.$GetKey(that.$cacheStudent));


    that.currentType = that.$route.query.type;
    if (that.currentType == null || typeof(that.currentType) == "undefined" || that.currentType == "") {
      if (tempStudent.isMoralityClass) {
        that.currentType = 1;
      } else if (tempStudent.isMorality) {
        that.currentType = 2;
      } else if (tempStudent.isMoralityDormitory) {
        that.currentType = 3;
      } else if (tempStudent.isMoralityDp) {
        that.currentType = 4;
      }
    }


    that.SearchTitles = [];
    if (tempStudent.isMoralityClass) { // 班级
      that.SearchTitles.push({
        name: '班级',
        isCurrent: that.currentType == 1 ? true : false,
        url: '/Student/BehaviorRecord/Index?type=1'
      });
    }
    if (tempStudent.isMoralityDormitory) { // 宿舍
      that.SearchTitles.push({
        name: '宿舍',
        isCurrent: that.currentType == 2 ? true : false,
        url: '/Student/BehaviorRecord/Index?type=2'
      });
    }
    if (tempStudent.isMorality) { // 班级个人
      that.SearchTitles.push({
        name: '个人',
        isCurrent: that.currentType == 3 ? true : false,
        url: '/Student/BehaviorRecord/Index?type=3'
      });
    }

    onGetDataPage(that);
  }

  function onGetDataPage(that) {
    paging(
      that,
      function(res) {
        let apiUrl = null;
        let apiClassUrl = null;
        if (that.currentType == 1) {
          apiUrl = "/api/student/studentunionmoral/classsearch";
          apiClassUrl = "/api/student/student/classloglinkage";
        } else if (that.currentType == 3) {
          apiUrl = "/api/student/studentunionmoral/personsearch";
          apiClassUrl = "/api/student/student/moralityloglinkage";
        } else if (that.currentType == 2) {
          apiUrl = "/api/student/studentunionmoral/dormsearch";
          apiClassUrl = "/api/student/student/linkage";
        }
        let classList = [];
        that.fullscreenLoading = true;
        // 搜索 下拉学期
        ajaxGet(that, "/api/student/semester/student", null, function(resSemester) {
          ajaxGet(that, apiClassUrl, null, function(resClass) {
            that.fullscreenLoading = false;
            for (var it of resClass) {
              classList.push({
                Title:it.alias,
                Value:it.classId,
              })
            }
            // 获得学期数据
            var SemesterId = res.SemesterId;
            let arrSemester = [];
            for (var i in resSemester) {
              let arrTemp = {
                Title: resSemester[i].name, //semester[item].name,
                Value: resSemester[i].semesterId //semester[item].semesterId,
              };
              if (regNullArray(SemesterId)) {
                if (resSemester[i].isCurrent == true) {
                  SemesterId = resSemester[i].semesterId;
                }
              }
              arrSemester.push(arrTemp);
            }
            that.SearchList = [
              // 学期
              {
                type: "select",
                Name: "学期",
                data: SemesterId,
                select: arrSemester, //选择的数据
                zhi: "SemesterId", // 传值
              },
              {
                type: "input",
                Name: "行为原因",
                data: res.Reason,
                holder: "请输入行为原因",
                zhi: "Reason"
              },
              {
                type: "time",
                Name: "行为时间",
                data: res.LogDate,
                zhi: "LogDate"
              },
              {
                type: "select",
                Name: "班级",
                data: res.ClassId,
                select: classList,
                zhi: "ClassId"
              },// 申诉状态
              {
                type: "select",
                Name: "申诉状态",
                data: res.AppealStatus,
                select: that.optAppealStatus, //选择的数据
                zhi: "AppealStatus", // 传值
                isClear: true, //select是否可以清除
              },

            ];
            // if (that.currentType == 3) {
            //   that.SearchList.push({
            //     type: "select",
            //     Name: "是否落实",
            //     data: res.All,
            //     select: that.optAll, //选择的数据
            //     zhi: "All", // 传值
            //     isClear: true, //select是否可以清除
            //   });
            // }
            if (that.currentType == 3) {
              that.SearchList.push({
                type: "input",
                Name: "学生",
                data: res.StudentInfo,
                holder: "请输入学生姓名/学号",
                zhi: "StudentInfo"
              });
            }
            var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
            that.random += random;

            var LogDateList = [];
            LogDateList = res.LogDate != null ? JSON.parse(JSON.stringify(res.LogDate)).split(',') : [];
            let data = {
              PageNumer: res.PageNumer,
              PageSize: res.PageSize,
              SemesterId: SemesterId,
              Reason: res.Reason,
              ClassId:res.ClassId,
              StudentInfo:res.StudentInfo,
              LogDateStart: LogDateList[0],
              LogDateEnd: LogDateList[1],
              AppealStatus: res.AppealStatus,
              All: res.All,
            };
            ajaxGet(that, apiUrl, data, function(resData) {
              that.page = resData;
              that.itemData = resData.items;
              that.fullscreenLoading = false;
            });
          })
        });
      },
      "SemesterId",
      "Reason",
      "StudentInfo",
      "LogDate",
      "ClassId",
      "AppealStatus",
      "All"
    );
  }
</script>

<style scoped="scoped">
  .titleErr {
    font-size: 12px;
    color: #F6402C;
  }

  .paperclip {
    margin-right: 5px;
    color: #409EFF;
  }

  .navTabsCont .nav-link {
    font-size: 12px;
  }

  .navToolsCont {
    margin-top: 1px;
  }

  /*重置样式*/
  .table tbody td,
  .table thead th {
    font-size: 0.875rem;
    /*14*/
  }

  .table tr td,
  .table tr th {
    text-align: center;
  }

  .table tr th:nth-child(1),
  .table tr th:nth-child(2),
  .table tr td:nth-child(1),
  .table tr td:nth-child(2) {
    text-align: left;
  }
</style>
