<!--行为规范-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <search :key="random" :SearchTitles="SearchTitles" :ColNumber="3" :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null">
    </search>

    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          行为规范
          <span class="titleErr" v-if="currentType == 1">
            （<i class="el-icon-paperclip"></i> 表示该记录由班级个人关联而来，无法申诉、落实、删除）
          </span>
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <el-button v-if="currentType == 1  && isPower.moralityclasslog_create == true" type="success" icon="el-icon-circle-plus-outline" @click="onAdd()">
                新增
              </el-button>
            </li>
            <li class="nav-item">
              <el-button v-if="currentType == 2  && isPower.moralitylog_create == true" type="success" icon="el-icon-circle-plus-outline" @click="onAdd()">
                新增
              </el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="item != null && item.length != 0" :key="random">
        <el-table stripe :data="item" class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="reason" label="管理原因">
            <template slot-scope="scope">
              <el-tag type="warning" size="mini" effect="plain" class="mr-1" v-if="scope.row.transform">{{currentType == 1?'已落实':'落实'}}</el-tag>
              <el-tag type="danger" size="mini" class="mr-1" v-if="scope.row.appeal!=null">存在反馈信息</el-tag>
              <span v-if="scope.row.relatedLogId" class="paperclip">
                <i class="el-icon-paperclip"></i>
              </span>
              <span v-html="onGetReason(scope.row)"></span>
            </template>
          </el-table-column>
          <el-table-column prop="studentName" width="120" label="学生" v-if="currentType==2">
          </el-table-column>
          <el-table-column prop="class" width="120" label="班级">
            <template slot-scope="scope">
              {{scope.row.class ? scope.row.class : scope.row.className}}
            </template>
          </el-table-column>
          <el-table-column label="申诉状态" width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.appealStatus == 1" style="color: #409EFF">{{scope.row.appealStatusName}}</span>
              <span v-if="scope.row.appealStatus == 2" style="color: #67C23A">{{scope.row.appealStatusName}}</span>
              <span v-if="scope.row.appealStatus == 3" style="color: #F56C6C">{{scope.row.appealStatusName}}</span>
              <span v-if="scope.row.appealStatus == 4" style="color: #909399">{{scope.row.appealStatusName}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="score" label="分数" width="80">
            <template slot-scope="scope">
              <div v-html="scope.row.score"></div>
            </template>
          </el-table-column>
          <el-table-column prop="createdBy" label="记录人" width="80">
            <template slot-scope="scope">
              <span>{{scope.row.createdBy}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="logDate" label="行为时间" width="150">
            <template slot-scope="scope">
              <span>{{scope.row.logDate | dateformatMinute}}</span>
            </template>
          </el-table-column>

          <!-- 班级操作 -->
          <el-table-column label="操作" :width="200" fixed="right" align="center" v-if="currentType == 1">
            <template slot-scope="scope">
              <div class="t-r">
                <operationBtn v-if="isPower.moralityclasslog_details" :btnName="'查看'" @click.native="onDetail(scope.row.logId)"></operationBtn>
                <el-dropdown
                v-if="(isPower.moralityclasslog_appeal && scope.row.isAppeal)
                || (isPower.moralityclasslog_approve && scope.row.appealStatus == 1)
                || (isPower.moralityclasslog_teacher_create && scope.row.isImplement)
                || (isPower.moralityclasslog_delete && scope.row.isDelete)"
                trigger="click" @command="handleCommand($event, scope.row.logId, scope.row)" class="ml-1">
                  <el-button size="mini">
                    更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-if="isPower.moralityclasslog_appeal && scope.row.isAppeal" class="color-primary" command="onAppeal">申诉</el-dropdown-item>
                    <el-dropdown-item v-if="isPower.moralityclasslog_appeal && scope.row.isCancel" class="color-primary" command="onCancel">取消申诉</el-dropdown-item>
                    <el-dropdown-item v-if="isPower.moralityclasslog_approve && scope.row.appealStatus == 1" class="color-primary" command="onApprove">申诉处理</el-dropdown-item>
                    <el-dropdown-item v-if="isPower.moralityclasslog_teacher_create && scope.row.isImplement" class="color-warning" command="onImplement">落实</el-dropdown-item>
                    <el-dropdown-item v-if="isPower.moralityclasslog_delete && scope.row.isDelete" class="color-danger" command="onDelete">删除</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>

          <!-- 班级个人操作 -->
          <el-table-column label="操作" :width="200" fixed="right" align="center" v-if="currentType == 2">
            <template slot-scope="scope">
              <div class="t-r">
                <operationBtn v-if="isPower.moralitylog_details" :btnName="'查看'" @click.native="onDetail(scope.row.memberId)"></operationBtn>
                <el-dropdown
                v-if="(isPower.moralitylog_appeal && scope.row.isAppeal)
                || (isPower.moralitylog_approve && scope.row.appealStatus == 1)
                || (isPower.moralitylog_delete && scope.row.isDelete)"
                trigger="click" @command="handleCommand($event, scope.row.logId, scope.row)" class="ml-1">
                  <el-button size="mini">
                    更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-if="isPower.moralitylog_appeal && scope.row.isAppeal" class="color-primary" command="onAppeal">申诉</el-dropdown-item>
                    <el-dropdown-item v-if="isPower.moralitylog_appeal && scope.row.isCancel" class="color-primary" command="onCancel">取消申诉</el-dropdown-item>
                    <el-dropdown-item v-if="isPower.moralitylog_approve && scope.row.appealStatus == 1" class="color-primary" command="onApprove">申诉处理</el-dropdown-item>
                    <el-dropdown-item v-if="isPower.moralitylog_delete && scope.row.isDelete" class="color-danger" command="onDelete">删除</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>

      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>

    <!-- 申诉 -->
    <el-dialog :title="'申诉'" :visible.sync="dialogVisibleAppeal" width="30%" :before-close="handleCloseAppeal"
      :close-on-click-modal="false" :close-on-press-escape="false" :append-to-body="true" :fullscreen="false">
      <el-form ref="ruleFormAppeal" :model="ruleFormAppeal" :rules="rulesAppeal">
        <el-form-item label="申诉理由" prop="appealReason">
          <el-input type="textarea" v-model="ruleFormAppeal.appealReason" :rows="5" placeholder="请输入申诉理由"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleAppeal = false">取 消</el-button>
        <el-button type="primary" @click="onUpdateAppeal">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 申诉处理 -->
    <el-dialog :title="'申诉处理'" :visible.sync="dialogVisibleApprove" width="30%" :before-close="handleCloseApprove"
      :close-on-click-modal="false" :close-on-press-escape="false" :append-to-body="true" :fullscreen="false">
      <el-form ref="ruleFormApprove" :model="ruleFormApprove" :rules="rulesApprove">
        <el-row :gutter="20">
            <el-col :span="24" class="colInfo">
              <label>申诉理由</label>
              <div class="colFonts">{{regNull(ruleFormApprove.appealReason) ? '暂无数据' : ruleFormApprove.appealReason}}</div>
            </el-col>
        </el-row>
        <el-form-item label="处理意见" prop="approveReason">
          <el-input type="textarea" v-model="ruleFormApprove.approveReason" :rows="5" placeholder="请输入处理意见"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleApprove = false">取 消</el-button>
        <el-button type="danger" @click="onUpdateApprove('Reject')">驳 回</el-button>
        <el-button type="primary" @click="onUpdateApprove('Pass')">通 过</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';
  export default {
    components: {
      comPage,
      operationBtn,
      search
    },
    data() {
      return {
        random: 0, //刷新组件
        SearchTitles: [],
        SearchList: null, //搜索集合
        currentType: null, //当前类型
        fullscreenLoading: false,
        page: null, //分页查询接口返回的结果
        item: [], //列表数据
        pageBtnList: null, //分页左侧按钮集合
        paging: { //分页数据
          pageLength: 0, // 总共页数
          Size: 10, // 当前请求数量
          Index: 1 //当前在第几页
        },
        dialogVisible: false,
        dialogVisibleAppeal: false,
        dialogVisibleApprove: false,

        ruleForm: {
          logId: null,
          appeal: '',
        },
        // 申诉
        ruleFormAppeal: {
          logId: null,
          memberId: null,
          appealReason: null,
        },
        ruleFormAppealReset: null,
        rulesAppeal: {
          appealReason:[{ max: 256, message: '申诉理由不能超过256个字符', trigger: 'change'}],
        },
        // 申诉处理
        ruleFormApprove: {
          logId: null,
          memberId: null,
          approveStatus: null,
          approveReason: null,
          appealReason: null,
        },
        rulesApprove: {
          approveReason:[{ max: 256, message: '处理意见不能超过256个字符', trigger: 'change'}],
        },
        ruleFormApproveResert: null,
        optAppealStatus: [],
        optAll: [{
          Value: 'true',
          Title: '未申诉'
        },{
          Value: 'false',
          Title: '已申诉'
        }],
        isPower: {
          // 班级权限
          moralityclasslog_index: false,
          moralityclasslog_details: false,
          moralityclasslog_create: false,
          moralityclasslog_delete: false,
          moralityclasslog_appeal: false,
          moralityclasslog_approve: false,
          moralityclasslog_teacher_create:false,
          // 班级个人权限
          moralitylog_index: false,
          moralitylog_details: false,
          moralitylog_create: false,
          moralitylog_delete: false,
          moralitylog_appeal: false,
          moralitylog_approve: false,
        },
        LogDateStart: null,
        LogDateEnd: null,
        url: null,

        // 教师信息
        TeacherInfo: {},

      };
    },
    created() {
      let that = this;
      that.TeacherInfo = onGetNowUser(that);

      that.ruleFormAppealReset = JSON.parse(JSON.stringify(that.ruleFormAppeal));
      that.ruleFormApproveResert = JSON.parse(JSON.stringify(that.ruleFormApprove));

      isPower(that, "moralityclasslog_index", "moralityclasslog_index");
      isPower(that, "moralityclasslog_details", "moralityclasslog_details");
      isPower(that, "moralityclasslog_create", "moralityclasslog_create");
      isPower(that, "moralityclasslog_delete", "moralityclasslog_delete");
      isPower(that, "moralityclasslog_approve", "moralityclasslog_approve");
      isPower(that, "moralityclasslog_appeal", "moralityclasslog_appeal");
      isPower(that, "moralityclasslog_teacher_create", "moralityclasslog_teacher_create");

      isPower(that, "moralitylog_index", "moralitylog_index");
      isPower(that, "moralitylog_details", "moralitylog_details");
      isPower(that, "moralitylog_create", "moralitylog_create");
      isPower(that, "moralitylog_delete", "moralitylog_delete");
      isPower(that, "moralitylog_approve", "moralitylog_approve");
      isPower(that, "moralitylog_appeal", "moralitylog_appeal");

      ajaxGet(that, "/api/dict/appealstatus", null, (r) => {
        if (!regNullArray(r)) {
          r.forEach(element => {
            that.optAppealStatus.push({
              Value: element.Value,
              Title: element.Text,
            })
          });
        }
        Init(that);
      })
    },
    methods: {
      /**
       * @description 申述处理
       * @param {Object} typeString Pass通过；Reject驳回
       */
      onUpdateApprove(typeString) {
        let that = this;
        if (typeString == 'Pass') {
          that.ruleFormApprove.approveStatus = 2;
        } else if (typeString == 'Reject') {
          that.ruleFormApprove.approveStatus = 3;
        }
        that.$refs["ruleFormApprove"].validate(valid => {
          if (valid) {
            let url = "";
            if (that.currentType == 1) {
              url = "/api/admin/moralityclasslog/approve";
            } else if (that.currentType == 2) {
              url = "/api/admin/moralitylog/approve";
            }
            that.fullscreenLoading = true;
            ajaxPut(that, url, that.ruleFormApprove, (r) => {
              that.fullscreenLoading = false;
              that.dialogVisibleApprove = false;
              Init(that);
            })
          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              var el = isError[0].querySelector('input') || isError[0].querySelector('textarea') || isError[0].querySelector('select');
              if(el) {
                el.focus();
              }
            }, 1)
            return false;
          }
        })
      },
      handleCloseApprove() {
        let that = this;
        that.dialogVisibleApprove = false;
      },
      /**
       * @description 提交申诉
       */
      onUpdateAppeal() {
        let that = this;
        that.$refs["ruleFormAppeal"].validate(valid => {
          if (valid) {
            let url = "";
            if (that.currentType == 1) {
              url = "/api/admin/moralityclasslog/appeal";
            } else {
              url = "/api/admin/moralitylog/appeal";
            }
            that.fullscreenLoading = true;
            ajaxPut(that, url, that.ruleFormAppeal, (r) => {
              that.fullscreenLoading = false;
              that.dialogVisibleAppeal = false;
              Init(that);
            })
          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              var el = isError[0].querySelector('input') || isError[0].querySelector('textarea') || isError[0].querySelector('select');
              if(el) {
                el.focus();
              }
            }, 1)
            return false;
          }
        })
      },
      /**
       * @description 反馈弹出框
       */
      handleClose() {
        let that = this;
        that.dialogVisible = false;
      },
      handleCloseAppeal() {
        let that = this;
        that.dialogVisibleAppeal = false;
      },
      onAppeal(obj) {
        let that = this;
        that.ruleFormAppeal = JSON.parse(JSON.stringify(that.ruleFormAppealReset));
        that.ruleFormAppeal.logId = obj.logId;
        that.ruleFormAppeal.memberId = obj.memberId;
        that.$nextTick(() => {
          that.$refs.ruleFormAppeal.clearValidate();
        })
        that.dialogVisibleAppeal = true;
      },
      onApprove(obj) {
        let that = this;
        that.ruleFormApprove = JSON.parse(JSON.stringify(that.ruleFormApproveResert));
        that.ruleFormApprove.appealReason = obj.appealReason;
        that.ruleFormApprove.memberId = obj.memberId;
        that.ruleFormApprove.logId = obj.logId;
        that.$nextTick(() => {
          that.$refs.ruleFormApprove.clearValidate();
        })
        that.dialogVisibleApprove = true;
      },
      /**
       * @description 更多操作
       * @param {Object} command
       * @param {Object} _valueID
       * @param {Object} objVal
       */
      handleCommand(objType, objValID, objVal) {
        let that = this;
        switch (objType) {
          case 'onImplement':
            if (objVal.appealStatus == 1) {
              warning(that, "待处理数据无法落实，请先取消申诉");
              return false;
            }
            that.onImplement(objValID);
            break;
          case 'onDelete':
            that.onDelete(objVal);
            break;
          case 'onAppeal':
            that.onAppeal(objVal);
            break;
          case 'onApprove':
            that.onApprove(objVal);
            break;
        }
      },
      /**
       * @description 显示原因
       * @param {Object} objItem
       */
      onGetReason(objItem) {
        return objItem.reason + (regNull(delHtml(objItem.remark)) ? '' : '<span class="color-gray">（' + delHtml(objItem
          .remark) + '）</span>');
      },
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },

      /**
       * @description 落实
       * @param {Object} Id
       */
      onImplement(Id) {
        let that = this;
        that.$router.push({
          path: '/Admin/Morality/Implement/' + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onAdd() {
        let that = this;
        this.$router.push({
          path: "/Admin/Morality/Create/" + that.currentType,
          query: {
            type: that.currentId
          }
        });
      },
      onDetail(Id) {
        let that = this;
        that.$router.push({
          path: "/Admin/Morality/Detail/" + that.currentType + "/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onDelete(objVal) {
        let that = this;
        var deleteUrl = "";
        if (that.currentType == 1) {
          deleteUrl = "/api/admin/moralityclassLog/" + objVal.logId;
        } else if (that.currentType == 2) {
          deleteUrl = "/api/admin/moralitylog/" + objVal.memberId;
        }
        if (deleteUrl != "") {
          confirmDelete(that, null, function(res) {
            if (res == true) {
              that.fullscreenLoading = true;
              ajaxDelete(that, deleteUrl, null, function(r) {
                that.fullscreenLoading = false;
                Init(that);
              });
            }
          })
        }
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      onSearch(params) {
        let that = this;
        let data = {};
        let searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = Join(element.data);
          }
        });
        data.PageNumer = 1;
        data["type"] = that.currentType;
        routerPath(that, "/Admin/Morality/Index", data, null, "PageSize");
      },
      pagingClick(type, val) {
        let that = this;
        let url = "/Admin/Morality/Index?type=" + that.currentType;
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "Reason",
            "ClassId",
            "StudentInfo",
            "LogDate",
            "LastUpdateDate",
            "AppealStatus",
            "All"
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "Reason",
            "ClassId",
            "StudentInfo",
            "LogDate",
            "LastUpdateDate",
            "AppealStatus",
            "All"
          );
        }
      },
    },
    watch: {
      $route() {
        let that = this;
        Init(that);
      },
    },
  };

  function Init(that) {
    that.currentType = that.$route.query.type;

    if (that.currentType == null || typeof(that.currentType) == "undefined" || that.currentType == "") {
      if (that.isPower.moralityclasslog_index == true) {
        that.currentType = 1;
      } else if (that.isPower.moralitylog_index == true) {
        that.currentType = 2;
      } else {
        that.currentType = 1;
      }
    }
    that.SearchTitles = [];
    if (that.isPower.moralityclasslog_index == true) {
      that.SearchTitles.push({
        name: '班级',
        isCurrent: that.currentType == 1 ? true : false,
        url: '/Admin/Morality/Index?type=1'
      });
    }
    if (that.isPower.moralitylog_index == true) {
      that.SearchTitles.push({
        name: '班级个人',
        isCurrent: that.currentType == 2 ? true : false,
        url: '/Admin/Morality/Index?type=2'
      });
    }



    pagingPage(that);
  }

  function pagingPage(that) {
    paging(
      that,
      function(r) {
        that.fullscreenLoading = true;
        let Myurl = null;
        if (that.currentType == 1) {
          Myurl = "/api/admin/moralityclasslog/search";
        } else if (that.currentType == 2) {
          Myurl = "/api/admin/moralitylog/search";
        }

        let classList = [];
        ajaxGet(that, '/api/admin/college/normallinkage', null, function(resClass) {
          resClass.forEach(element => {
            var it = {
              Value: element.collegeId,
              Title: element.name,
              Childrens: []
            }

            if (!regNullArray(element.classes)) {
              element.classes.forEach(chil => {
                it.Childrens.push({
                  Value: chil.classId,
                  Title: chil.alias
                })
              })
            }
            classList.push(it);
          })

          that.SearchList = [{
            type: "cascader",
            Name: "班级",
            data: r.ClassId,
            select: classList,
            zhi: "ClassId"
          },
          {
            type: "input",
            Name: "管理原因",
            data: r.Reason,
            holder: "请输入管理原因",
            zhi: "Reason"
          },
          {
            type: "time",
            Name: "行为时间",
            data: r.LogDate,
            zhi: "LogDate"
          },
          {
            type: "time",
            Name: "操作时间",
            data: r.LastUpdateDate,
            zhi: "LastUpdateDate"
          },
          // 申诉状态
          {
            type: "select",
            Name: "申诉状态",
            data: r.AppealStatus,
            select: that.optAppealStatus, //选择的数据
            zhi: "AppealStatus", // 传值
            isClear: true, //select是否可以清除
          },
          {
            type: "select",
            Name: "是否申诉",
            data: r.All,
            select: that.optAll, //选择的数据
            zhi: "All", // 传值
            isClear: true, //select是否可以清除
          }];

          if (that.currentType == 2) {
            that.SearchList.push({
              type: "input",
              Name: "学生",
              data: r.StudentInfo,
              holder: "请输入学生姓名/学号",
              zhi: "StudentInfo"
            });
          }

          var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
          that.random += random;

          var LogDateList = [];
          var LastUpdateDateList = [];
          var ClassId = null;
          let data = {};
          if (!regNull(r.ClassId)) {
            ClassId = JSON.parse(JSON.stringify(r.ClassId)).split(',')[1];
          }
          if (r.LogDate != null && r.LastUpdateDate != null) {
            LogDateList = JSON.parse(JSON.stringify(r.LogDate)).split(',');
            LastUpdateDateList = JSON.parse(JSON.stringify(r.LastUpdateDate)).split(',');
            data = {
              type: that.currentType,
              PageNumer: r.PageNumer,
              PageSize: r.PageSize,
              Reason: r.Reason,
              ClassId: ClassId,
              StudentInfo: r.StudentInfo,
              LogDateStart: LogDateList[0],
              LogDateEnd: LogDateList[1],
              LastUpdateDateStart: LastUpdateDateList[0],
              LastUpdateDateEnd: LastUpdateDateList[1],
              AppealStatus: r.AppealStatus,
              All: r.All,
            }
          } else if (r.LogDate != null) {
            LogDateList = JSON.parse(JSON.stringify(r.LogDate)).split(',');
            data = {
              type: that.currentType,
              PageNumer: r.PageNumer,
              PageSize: r.PageSize,
              Reason: r.Reason,
              ClassId: ClassId,
              StudentInfo: r.StudentInfo,
              LogDateStart: LogDateList[0],
              LogDateEnd: LogDateList[1],
              AppealStatus: r.AppealStatus,
              All: r.All,
            }
          } else if (r.LastUpdateDate != null) {
            LastUpdateDateList = JSON.parse(JSON.stringify(r.LastUpdateDate)).split(',');
            data = {
              type: that.currentType,
              PageNumer: r.PageNumer,
              PageSize: r.PageSize,
              Reason: r.Reason,
              ClassId: ClassId,
              StudentInfo: r.StudentInfo,
              LastUpdateDateStart: LastUpdateDateList[0],
              LastUpdateDateEnd: LastUpdateDateList[1],
              AppealStatus: r.AppealStatus,
              All: r.All,
            }
          } else {
            data = {
              type: that.currentType,
              PageNumer: r.PageNumer,
              PageSize: r.PageSize,
              Reason: r.Reason,
              ClassId: ClassId,
              StudentInfo: r.StudentInfo,
              AppealStatus: r.AppealStatus,
              All: r.All,
            }
          }
          that.paging.Index = r.PageNumer;
          that.paging.Size = r.PageSize;

          if (that.$route.query.url == '/admin/Preparations/index') {
            for (let key in that.SearchList) {
              if (that.SearchList[key].zhi == 'StudentInfo') {
                that.SearchList[key].data = that.$route.query.Student
              }
            }
            data.StudentInfo = that.$route.query.Student
          }
          ajaxGet(that, Myurl, data, function(r1) {
            that.fullscreenLoading = false;
            that.page = r1;
            that.item = r1.items;
            for (var i in that.item) {
              var it = that.item[i];

              // 判断是否可以删除按钮isDelte，true可以删除，false不能删除
              it.isDelete = !that.TeacherInfo.isTeacher;
              // 判断是否可以申诉按钮isAppeal，true可以申诉，false不能申诉
              it.isAppeal = true;
              // 判断是否可以取消申诉按钮isCancel，true可以取消申诉，false不能取消申诉
              it.isCancel = true;
              // 判断是否可以落实，true可以落实，false不能落实
              it.isImplement = true;
              // 班主任自己新建的行为规范扣分可以删除，其他的无法删除
              if (that.TeacherInfo.isTeacher && that.TeacherInfo.userId == it.createdByTeacherId) {
                it.isDelete = true;
              }
              // 有申诉状态说明已发起过申诉，无法再次申诉（申诉只能一次）
              if (!regNull(it.appealStatus)) {
                it.isAppeal = false;
              }
              // 班主任自己新建的数据，无需申诉按钮，无需申诉取消按钮
              if (that.TeacherInfo.isTeacher && that.TeacherInfo.userId == it.createdByTeacherId) {
                it.isAppeal = false;
                it.isCancel = false;
              }
              // 只有当申诉状态是带申诉的时候才出现申诉取消按钮，appealStatus == 1，其余状态均不显示
              if (it.appealStatus != 1) {
                it.isCancel = false;
              }
              // 是否转化，转化则已落实，未转化则未落实
              if (it.transform) {
                it.isImplement = false;
              }
              // 由班级个人关联过来的数据，不需要申诉、落实、删除
              if (!regNull(it.relatedLogId)) {
                it.isDelete = false;
                it.isAppeal = false;
                it.isCancel = false;
                it.isImplement = false;
              }
            }
          });
        })

      },
      "Reason",
      "ClassId",
      "StudentInfo",
      "LogDate",
      "LastUpdateDate",
      "AppealStatus",
      "All"
    );
  }

</script>

<style scoped="scoped">
  .navTabsCont .nav-link {
    font-size: 12px;
  }

  .navToolsCont {
    margin-top: 1px;
  }

  .btn-mini {
    margin-left: 15px;
    margin-right: 5px;
    height: 40px;
    line-height: 25px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 14px;
  }

  .spIcon {
    background-color: #F8F9FA;
    font-size: 14px;
    padding: 2px 5px;
  }

  .spBan {
    color: #409EFF;
  }

  .spBu {
    color: #FAC10B;
  }

  .spXiao {
    color: #F6402C;
  }

  .titleErr {
    font-size: 14px;
    color: #F6402C;
  }

  .paperclip {
    margin-right: 5px;
    color: #409EFF;
  }
</style>
