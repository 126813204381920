<!--
  用户管理 - 新增
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form ref="ruleForm" label-width="100px">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{Title}}
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24" style="margin-top: 20px;">
            <el-col :span="12">
              <el-form-item label="角色名称">
                {{roleName}}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="描述">
                <div v-html="roleDescription"></div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="权限">
                <div style="overflow-y: auto; border: 1px solid #efefef; border-radius: 3px; padding: 10px;"
                  :style="'height:' + height + 'px'">
                  <el-tree :data="actions" :props="defaultProps" ref="menuTree" v-if="actions.length>0"
                    node-key="actionId" show-checkbox auto-expand-parent :expand-on-click-node="false"
                    :default-checked-keys="idArr" :default-expanded-keys="idArr">
                  </el-tree>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </div>

      </el-card>
      <div class="row" style="margin-top: 10px;">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';

  export default {
    components: {},
    data() {
      return {
        height: 380,
        idArr: [],
        idInit: [],
        actions: [],
        roleName: null,
        roleDescription: null,
        Title: "查看",
        fullscreenLoading: false,
        defaultProps: {
          children: 'children',
          label: 'name',
          id: 'actionId'
        },
      };
    },
    created() {

      let that = this;
      let id = that.$route.params.id;
      var detailUrl = null;
      if (id != null && typeof(id) != "undefined" && id != "") {
        that.Title = "查看";
        detailUrl = "/api/admin/StudentRoleInfo/" + id;
      }
      that.fullscreenLoading = true;

      ajaxGet(that, detailUrl, null, function(r) {
        if (r != null) {
          that.roleName = r.name;
          that.roleDescription = r.description;
        }

        ajaxGet(that, "/api/admin/studentAction/treelist", null, function(r1) {
          that.actions = r1;
          GetItem(that, that.actions);
        });

        ajaxGet(that, "/api/admin/StudentRoleInfo/action/" + id, null, function(r1) {
          that.idArr = r1;
          that.idInit = JSON.parse(JSON.stringify(that.idArr));
        });

        that.fullscreenLoading = false;

      });
    },
    mounted() {
      let that = this;
      that.height = document.body.clientHeight - 388;
    },
    methods: {
      onBack() {
        let that = this;
        back(that);
      },
    },
  };

  function GetItem(that, val) {
    if (val.length !== 0) {
      val.forEach((item) => {
        item.disabled = true;
        if (item.children.length >= 1) {
          GetItem(that, item.children);
        }
      });

    }
  }
</script>

<style scoped="scoped">
  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }

  .spIcon {
    width: 5px;
    height: 20px;
    border-radius: 5px;
    background-image: linear-gradient(#4545FF, #2D8EFF);
  }

  .spTitle {
    float: left;
    margin-left: 20px;
    color: #323E56;
    letter-spacing: 2px;
    font-size: 16px;
    margin-top: -3px;
  }

  .wMax {
    width: 100% !important;
  }
</style>
