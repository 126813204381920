<!--
  社团职位管理
-->
<template>
  <div class="SubWebPage " v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          社团信息管理
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <search :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null"></search>
            </li>
            <li class="nav-item">
              <el-button v-if="isPower.club_create == true" type="success" icon="el-icon-circle-plus-outline"
                @click="onCreate()">新增</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table :data="itemData" stripe class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="name" label="社团名称" min-width="140" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="number" label="社团人数" min-width="100"></el-table-column>
          <el-table-column prop="studentname" label="社团最高负责人" min-width="160" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="操作" fixed="right" :width="200" align="center">
            <template slot-scope="scope">
              <div class="t-r">
                <operationBtn v-if="isPower.club_details == true" :btnName="'查看'" @click.native="onDetail(scope.row.clubId)"></operationBtn>
                <el-dropdown v-if="isPower.club_edit || isPower.club_delete || isPower.club_import" trigger="click"
                  @command="handleCommand($event,scope.row.clubId)" class="ml-1">
                  <el-button size="mini">
                    更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-if="isPower.club_edit" class="color-warning" command="onEdit">编辑</el-dropdown-item>
                    <el-dropdown-item divided v-if="isPower.club_import">
                      <comImport @onImportSuccess="onImportSuccess" :template="'/templates/社团成员导入模版.xlsx'"
                        :path="onGetApi(scope.row.clubId)" :btnSize="'mini'" :btnClass="'btnClass_NoBorder'" :btnIcon="''"
                        :title="'批量导入'" :btnName="'导入人员'" ref="conImportInfoFile" >
                      </comImport>
                    </el-dropdown-item>
                    <el-dropdown-item divided v-if="isPower.club_delete" class="color-danger" command="onDelete">注销</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              
             
              
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>

  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';
  import comImport from '@/components/Import.vue';

  export default {
    components: {
      comPage,
      operationBtn,
      search,
      comImport,
    },
    data() {
      return {
        //加载动画
        fullscreenLoading: false,
        //搜索
        SearchList: null,
        //分页查询接口返回的结果
        page: null,
        //列表数据
        itemData: [],
        //分页左侧按钮集合
        pageBtnList: null,
        //分页数据
        paging: {
          // 总共页数
          pageLength: 0,
          // 每页请求的数据量
          Size: 1,
          //当前在第几页
          Index: 1
        },
        // 上传学生信息接口
        uploadInfoApi: '/api/admin/club/clubmember/',

        // 权限限制
        isPower: {
          //增删改查 权限管理
          club_details: false,
          club_delete: false,
          club_edit: false,
          club_create: false,
          club_import: false
        },


      };
    },
    methods: {
      /**
       * @description 更多操作
       * @param {Object} command
       * @param {Object} objValID
       */
      handleCommand(command, objValID) {
        let that = this;
        switch (command) {
          case 'onEdit':
            that.onEdit(objValID);
            break;
          case 'onDelete':
            that.onDelete(objValID);
            break;
        }
      },


      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },
      onGetApi(_dataID) {
        return this.uploadInfoApi + _dataID;
      },
      onImportSuccess(param) {

        let that = this;
        pagingPage(that);
      },
      /**
       * @description 打开新增弹出框
       */
      onCreate() {
        let that = this;
        that.$router.push({
          path: '/Admin/Club/ClubMGMT/Create',
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      /**
       * @description 查看详情
       * @param {Object} Id
       */
      onDetail(Id) {
        let that = this;
        that.$router.push({
          path: '/Admin/Club/ClubMGMT/Details/' + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      /**
       * @description 打开编辑弹出框
       * @param {String} Id
       */
      onEdit(Id) {
        let that = this;
        that.$router.push({
          path: '/Admin/Club/ClubMGMT/Edit/' + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      /**
       * @description 搜索事件
       * @param {Object} params 条件
       */
      onSearch(params) {
        let that = this;
        let data = {};
        let searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = element.data;
          }
        });
        data.PageNumer = 1
        routerPath(that, "/Admin/Club/ClubMGMT/Index", data, null, "PageSize");
      },

      /**
       * @description 删除事件
       * @param {Object} Id
       */
      onDelete(Id) {
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxPut(that, "/api/admin/club/revoke/" + Id, null, function(r) {
              that.fullscreenLoading = false;
              pagingPage(that);
            });
          }
        })
      },
      /**
       * @description 分页组件左侧的按钮事件，按顺序进行判断
       * @param {Object} index
       */
      pageBtnClick(index) {},
      /**
       * @description 分页面按钮
       * @param {Object} type
       * @param {Object} val
       */
      pagingClick(type, val) {
        let that = this;
        let url = "/Admin/Club/ClubMGMT/Index";
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "Name",
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "Name",
          );
        }
      },
      onGetAdminName(_arrData) {
        for (let item of _arrData) {
          if (item.isDirector) {
            return item.studentName + '（' + item.studentNo + '）';

          }
        }
      }
    },
    created() {
      let that = this;

      isPower(that, "club_details", "club_details");
      isPower(that, "club_delete", "club_delete");
      isPower(that, "club_edit", "club_edit");
      isPower(that, "club_create", "club_create");
      isPower(that, "club_import", "club_import");

      pagingPage(that);
    },
    watch: {
      $route() {
        let that = this;
        pagingPage(that);
      }
    }
  };

  /**
   * @description 分页加载数据
   * @param {Object} that
   */
  function pagingPage(that) {
    paging(
      that,
      function(res) {
        let apiUrl = "/api/admin/club/search";
        let data = {
          PageNumer: res.PageNumer,
          PageSize: res.PageSize,
          Name: res.Name,
          isRevoke: false,
        };
        that.SearchList = [{
          type: "input",
          Name: "社团名称",
          data: res.Name,
          holder: "请输入社团名称关键字",
          zhi: "Name"
        }, ];
        that.paging.Index = res.PageNumer;
        that.paging.Size = res.PageSize;
        that.fullscreenLoading = true;
        ajaxGet(that, apiUrl, data, function(resData) {
          that.page = resData;
          that.itemData = [];

          for (let item of resData.items) {
            that.itemData.push({
              clubId: item.clubId,
              name: item.name,
              description: item.description,
              attachment: item.attachment,
              number: item.member.length,
              studentname: that.onGetAdminName(item.member)
            });
          }
          that.fullscreenLoading = false;
        });
      },
      "Name",
    );
  }
</script>

<style scoped="scoped">

</style>
<style>
  .iconClass {
    font-size: 22px;
    color: #406AD3;
  }

  .iconNoClass {
    font-size: 22px;
    color: #EFEFEF;
  }

  .clubBtnImport {
    margin-left: 2px;
    display: inline-block;
  }
</style>
