<template>
  <div class="">
    <el-card class="box-card" v-if="uploadFiles!=null && uploadFiles.length>0">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          {{comName}}<small class="color-danger">（点击图片查看原图）</small>
        </h3>
      </div>
      <div class="card-body ">
        <el-carousel height="500px" :autoplay="!showViewer">
          <el-carousel-item v-for="(item,index) in uploadFiles" :key="index" class="VerticalMid">
            <img :src="item" @click="onPreview" height="100%">
          </el-carousel-item>
        </el-carousel>
      </div>
    </el-card>

    <!--配合走马灯效果放大图片组件-->
    <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="uploadFiles" />
  </div>
</template>

<script>
  import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
  export default {
    components: {
      ElImageViewer,
    },
    props: {
      uploadFiles: {
        type: Array,
        default: () => [],
      },
      comName:{
        type:String,
        default:'附件'
      }
    },
    data() {
      return {
        showViewer: false,
      }
    },
    methods:{
      /**
       * @description 打开放大图片
       */
      onPreview() {
        this.showViewer = true
      },


      /**
       * @description 关闭图片预览图
       */
      closeViewer() {
        this.showViewer = false
      },


    }
  };
</script>

<style>
</style>
