<!-- 成长档案 - 详情 -->

<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          基础信息
        </h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-1 text-center"></div>
          <div class="col-lg-10 position-relative">
            <div class="infoList clearfix">
              <span class="spName">{{itemData.teacher}}</span>
              <span class="spLabel">
                <label>{{itemData.rank}}</label>
              </span>
            </div>
            <div class="infoList clearfix">
              <span class="spFonts">
                <span v-if="itemData.class!=null&&itemData.class!=''">班级:{{itemData.class}}</span>
                <span v-else>暂无班级</span>
              </span>
            </div>
            <div class="divReportBox">
              <div class="divFraction">
                {{itemData.score}}
              </div>
              <div class="divAssessment">
                <el-tag type="success">核定总分</el-tag>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          考核加分项详情
        </h3>
      </div>
      <div class="card-body ">
        <el-table :data="itemData.semesterproject"  class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80"></el-table-column>
          <el-table-column prop="reason" label="考核项目" class="elColumn"></el-table-column>
          <el-table-column prop="score" label="分值" class="elColumn" width="120" fixed="right">
            <template slot-scope="scope" >
              <div class="divResult">
                <el-tag v-if="scope.row.score>0">{{scope.row.score}}</el-tag>
                <el-tag type="danger" v-else-if="scope.row.score<0">{{scope.row.score}}</el-tag>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          月考核折算分
        </h3>
      </div>
      <div class="card-body">
        <div class="row" v-for="item in itemData.bysemester" :key="item.assessmentId">
          <div class="col-lg-4 colInfo" >
            <label>{{item.time}}</label>
            <div class="colFonts">
              {{item.conversionScore}}
            </div>
          </div>
        </div>
      </div>
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          民主评议
        </h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-4 colInfo" v-for="item in itemData.review" :key="item.reviewId">
            <label>{{item.reviewTypeStr}}</label>
            <div class="colFonts">
              {{item.score}}
            </div>
          </div>
        </div>
      </div>
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          备注
        </h3>
      </div>
      <div class="card-body">
        <div class="remarksCont">
          暂无数据
        </div>
      </div>
    </el-card>

    <div class="row mt-5">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  export default {
    components: {

    },
    data() {
      return {
        fullscreenLoading: false,
        itemData:{
          bysemester:[],//月考核折算分
          semesterproject:[],//考核加分项
          review:[],//民主评议
        },
      };
    },
    methods: {


      //返回
      onBack() {
        let that = this;
        back(that);
      },

      //性别判断，男生:true  女生:false
      onGender(obj) {
        return obj == '男' ? true : false;
      },

    },
    created() {

      let that = this;
      let id = that.$route.params.Id;
      that.fullscreenLoading=true;
      ajaxGet(that,'/api/admin/advisersemesterassessment/'+id,null,function(r){
        that.itemData=r;
        that.fullscreenLoading=false;
        let data={
          ClassId:r.classId,
          TeacherName:r.teacher,
          SemesterId:r.semesterId,
          PageSize:1000,
        }
        that.fullscreenLoading=true;
        ajaxGet(that,'/api/admin/advisersemesterproject/search',data,function(r1){
          that.itemData.semesterproject=r1.items;
          that.fullscreenLoading=false;
          let data1={
            teacherId:r.teacherId
          }
          that.fullscreenLoading=true;
          ajaxGet(that,'/api/admin/advisermonthlyassessment/bysemester/'+r.semesterId,data1,function(r2){
            that.itemData.bysemester=r2;
            that.fullscreenLoading=false;
            let review={
              ClassId:r.classId,
              TeacherName:r.teacher,
              SemesterId:r.semesterId,
            }
            that.fullscreenLoading=true;
            ajaxGet(that,'/api/admin/adviserreview/search',review,function(r3){
              that.itemData.review=r3.items;
              that.fullscreenLoading=false;
            });
          });
        });
      });
    },
  };
</script>

<style scoped="scoped">
  .divResult{
    padding-left: 15px;
  }
  .divReportBox {
    position: absolute;
    top: 10px;
    right: 0px;
    bottom: 0;
    display: flex;
  }

  .divReportBox .divFraction {
    font-family: 'OswaldLight';
    font-size: 62px;
    letter-spacing: 2px;
    font-weight: bold;
  }

  .divReportBox .divAssessment {
    padding-top: 15px;
    padding-left: 15px;
  }

  .nav-item {
    font-size: 12px;
    margin-top: -2px;
    cursor: pointer;
  }

  .nav-link {
    padding: 5px 10px;
  }



  .imgAvatar {
    width: 100px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }

  .infoList {
    padding-bottom: 17px;
  }

  .infoList .spName {
    font-size: 32px;
    color: #212E48;
    padding-right: 30px;
    height: 40px;
  }

  .infoList .spGender {
    font-size: 32px;
    padding-right: 30px;
    height: 40px;
  }

  .infoList .spLabel {
    height: 40px;
  }

  .infoList .spLabel label {
    position: absolute;
    top: 8px;
    background-color: #ff9900;
    border: 1px solid #f29100;
    border-radius: 5px;
    font-weight: normal;
    color: #FFFFFF;
    padding: 2px 10px;
    margin-bottom: 0;
  }

  .infoList .spFonts {
    color: #3B475D;
    padding-right: 30px;
    padding-left: 30px;
    border-right: 1px solid #D6D6D6;
  }

  .infoList .spFonts:first-child {
    padding-left: 0;
  }

  .infoList .spFonts:last-child {
    border-right: none;
  }

  .infoList .spMedal {
    padding-right: 15px;
  }

  .infoList .spFonts .faxSize {
    font-size: 18px;
  }

  .infoList .spFonts .icon {
    color: #B1B1B1;
  }
</style>
