<!--
  奖励记录-奖励详情
-->
<template>
  <div class="SubWebPageStudent" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          查看我的请假
        </h3>
      </div>
      <div class="card-body">
        <el-row :gutter="24">
          <el-col :span="24" class="colInfo">
            <label>请假类型</label>
            <div class="colFonts">{{ itemData.typeName }}</div>
          </el-col>
          <el-col :span="6" class="colInfo">
            <label>请假分类</label>
            <div class="colFonts">
              {{itemData.categoryName}}
            </div>
          </el-col>
          <el-col :span="6" class="colInfo" v-if="itemData.category == 1">
            <label>当前位置</label>
            <div class="colFonts">
              {{itemData.location == true ?'在校' :'在家'}}
            </div>
          </el-col>
          <el-col :span="6" class="colInfo" v-if="itemData.type == 1">
            <label>疾病名称</label>
            <div class="colFonts">
              {{itemData.sickness}}
            </div>
          </el-col>
          <el-col :span="6" class="colInfo" v-if="itemData.type == 1">
            <label>主要症状</label>
            <div class="colFonts" v-html='itemData.symptom'>
            </div>
          </el-col>
          <el-col :span="6" class="colInfo" v-if="itemData.category == 5">
            <label>临时通校</label>
            <div class="colFonts">
              {{itemData.syncGroupName}}
            </div>
          </el-col>
          <el-col :span="6" class="colInfo">
            <label>请假开始时间</label>
            <div class="colFonts" v-if="itemData.category == 2 || itemData.category == 3 || itemData.category == 5">
              {{itemData.startDate | dateformatDay}}
            </div>
            <div class="colFonts" v-else>
              {{itemData.startDate | dateformatMinute}}
            </div>
          </el-col>
          <el-col :span="6" class="colInfo">
            <label>请假结束时间</label>
            <div class="colFonts" v-if="itemData.category == 2 || itemData.category == 3 || itemData.category == 5">
              {{itemData.endDate | dateformatDay}}
            </div>
            <div class="colFonts" v-else>
              {{itemData.endDate | dateformatMinute}}
            </div>
          </el-col>
          <el-col :span="6" class="colInfo">
            <label>请假时长（天）</label>
            <div class="colFonts">{{ itemData.days }}</div>
          </el-col>
          <el-col :span="6" class="colInfo">
            <label>请假状态</label>
            <div class="colFonts">{{ itemData.statusName }}</div>
          </el-col>
        </el-row>
      </div>
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          请假事由
        </h3>
      </div>
      <div class="card-body">
        <div class="colRemarksCont" v-html="itemData.reason"></div>
      </div>
    </el-card>


    <comShowImage :uploadFiles="uploadFiles"></comShowImage>

    <el-card class="box-card" v-if="itemData.audits!=null && itemData.audits.length!=0">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          审核记录
        </h3>
      </div>
      <div class="card-body">
        <el-table :data="itemData.audits" border class="elTableData">
          <el-table-column prop="auditor" label="审核人" width="140"></el-table-column>
          <el-table-column label="审核时间" width="180">
            <template slot-scope="scope">
              {{scope.row.createDate | dateformatMinute}}
            </template>
          </el-table-column>
          <el-table-column prop="statusName" label="审核结果" width="180"></el-table-column>
          <el-table-column prop="reason" label="原因"></el-table-column>
        </el-table>
      </div>
    </el-card>

    <div class="row mt-1">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
      </div>
    </div>

    <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="uploadFiles" />

  </div>
</template>

<script>
  import '@/assets/css/Student.css';
  import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
  import comShowImage from '@/components/showImage.vue';
  export default {
    components: {
      ElImageViewer,
      comShowImage
    },
    data() {
      return {
        // 动画
        fullscreenLoading: false,
        dialogVisible: false,
        //列表数据
        itemData: [{
          typeName: null,
          levelName: null,
        }, ],
        //放大图片
        showViewer: false,
        uploadFiles: [],
        //弹出标题框
        dialogScoreName: '',
        dialogScoreVisible: false,
        dialogArray: [],
        GrowthConfigs: [], //综合类型集合
      };
    },
    methods: {

      /**
       * @description 打开放大图片
       */
      onPreview() {
        this.showViewer = true;
      },
      /**
       * @description 关闭图片预览图
       */
      closeViewer() {
        this.showViewer = false
      },
      onBack() {
        let that = this;
        back(that);

      },
      onCalculationScore(_data) {
        let temp = 0;

        if (_data != null && _data.length > 0) {
          for (let item of _data) {
            temp = item.score + temp;
          }
        }
        return temp;
      },

      flNoValue(obj) {
        if (obj == null || typeof obj == "undefined" || obj == "") {
          return "暂无数据";
        }
        return obj;
      },
    },
    created() {
      let that = this;
      that.fullscreenLoading = true;
      ajaxGet(that, "/api/student/askforleave/" + that.$route.params.Id, null, function(resData) {
        that.fullscreenLoading = false;
        that.itemData = resData;
        that.uploadFiles = [];
        if (!regNull(resData.attachment)) {
          var files = resData.attachment.split('|');
          for (var i in files) {
            that.uploadFiles.push(that.$ServerUrl() + files[i]);
          }
        }
      });
    },
  };

  function RecursionData(that, _data) {
    if (_data.length !== 0) {
      _data.forEach((item) => {
        if (item.parentId == "00000000-0000-0000-0000-000000000000") {
          that.GrowthConfigs.push({
            configId: item.configId,
            name: item.name,
            parentId: item.parentId,
            score: item.score,
            children: _data.filter(val => val.parentId == item.configId)
          });
        }
      });
    }
  }
</script>

<style scoped="scoped">
  .divLiteracyBox {
    padding: 15px;
  }

  .divLiteracyBox .listBox {
    text-align: center;
    padding: 20px;
    float: left;
    border: 2px solid #FFFFFF;
    border-radius: 5px;
  }

  .divLiteracyBox .listBox:hover {
    border: 2px solid #409eff;
    cursor: pointer;
  }

  .divLiteracyBox .literacyName {
    font-size: 24px;
    letter-spacing: 2px;
    padding-bottom: 10px;
  }

  .divLiteracyBox .literacyNum {
    padding-top: 10px;
    font-family: 'OswaldLight';
    border-top: 2px solid #EEEEEE;
    font-size: 32px;
    color: #409eff;
  }
</style>
