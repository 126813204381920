<!--
  处分管理 - 新增
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{Title}}
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="奖项名称" prop="name">
                <el-input v-model="ruleForm.name" placeholder="请输入奖项名称" class="formControl"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="奖励日期" prop="prizeDate">
                <el-date-picker value-format="yyyy-MM-dd" :key="random" :picker-options="pickerOptions"
                  v-model="ruleForm.prizeDate" type="date" placeholder="请选择学期范围内的日期" class="wMax formControl">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="奖励类型" prop="typeId">
                <el-select v-model="ruleForm.typeId" placeholder="请选择" class="wMax formControl" filterable>
                  <el-option v-for="item in optClassPrizeType" :key="item.typeId" :label="item.name"
                    :value="item.typeId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="奖励级别" prop="levelId">
                <el-cascader v-model="ruleForm.levelId" placeholder="请选择" style="width:100% !important"
                  expand-trigger="hover" class="formControl" :props="defaultParams" :options="optLevels"></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="选择班级" prop="arrClass">
                <el-select v-model="ruleForm.arrClass" placeholder="请选择班级(可多选)" class="wMax formControl" filterable
                  multiple :disabled="isDisabledClass">
                  <el-option v-for="item in optClass" :key="item.classId" :label="item.alias" :value="item.classId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备注" prop="remark">
                <el-input class="formControl" type="textarea" :rows="4" maxlength="500" show-word-limit
                  placeholder="请输入内容" v-model="ruleForm.remark">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>




      <!--附件-->
      <el-card style="margin-top: 10px;">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            附件
          </h3>
        </div>
        <div class="card-body">
          <comUpload :key="random" @onPictureHandleSuccess="onPictureHandleSuccess" :files="uploadFiles"></comUpload>
        </div>
      </el-card>


      <div class="row" style="margin-top: 10px;">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
          <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
          <el-button type="primary" icon="el-icon-circle-check" @click="onSave()">提交</el-button>
        </div>
      </div>
    </el-form>








  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comUpload from '@/components/Upload.vue';

  var prizeDateMin = null;
  var prizeDateMax = null;

  function disabledDateTest(date) {
    if (prizeDateMin != null && typeof(prizeDateMin) != "undefined" && prizeDateMax != null && typeof(prizeDateMax) !=
      "undefined") {
      return new Date(date).getTime() < new Date(prizeDateMin).getTime() || new Date(date).getTime() > new Date(
        prizeDateMax).getTime();
    } else if (prizeDateMin != null && typeof(prizeDateMin) != "undefined" && (prizeDateMax == null || typeof(
        prizeDateMax) == "undefined")) {
      return new Date(date).getTime() < new Date(prizeDateMin).getTime();
    } else if ((prizeDateMin == null || typeof(prizeDateMin) == "undefined") && prizeDateMax != null && typeof(
        prizeDateMax) != "undefined") {
      return new Date(date).getTime() > new Date(prizeDateMax).getTime();
    }
    return false;
  }
  export default {
    components: {
      comUpload
    },
    data() {
      return {


        random: 0, //刷新组件
        prizeDateMin: null,
        prizeDateMax: null,
        //奖励时间不能超出学期的时间范围
        pickerOptions: {
          disabledDate(time) {
            return disabledDateTest(time);
          },
        },
        ruleForm: //表单数据
        {
          prizeId: null, //奖励主键，新增时为null
          name: null, //奖励名称
          typeId: null, //奖励类型
          levelId: null, //奖励等级主键
          classId: null,
          prizeDate: null, //奖励日期
          attachment: null,
          remark: null, //备注
          arrClass: [],
        },
        ruleFormReset: null, //重置时的表单数据

        //奖励类型集合
        optClassPrizeType: [],
        //奖励级别集合
        optLevels: [],
        optAllLevel: [],
        //奖励级别的格式
        defaultParams: {
          label: 'name', //要显示元素的名称
          value: 'levelId', //显示元素对应的id
          children: 'children' //子集名称
        },
        //班级集合
        optClass: [],


        Title: "新增奖励信息",
        fullscreenLoading: false,
        uploadFiles: [],
        rules: {
          name: [{
              required: true,
              message: '奖项名称不能为空',
              trigger: 'change'
            },
            {
              max: 100,
              message: '奖项名称不能超过100个字符',
              trigger: 'change'
            },
          ],
          remark: [{
            max: 500,
            message: '备注不能超过500个字符',
            trigger: 'change'
          }, ],
          levelId: [{
            required: true,
            message: '请选择奖励级别',
            trigger: 'change'
          }, ],

          prizeDate: [{
            required: true,
            message: '请选择奖励时间',
            trigger: 'change'
          }, ],
          typeId: [{
            required: true,
            message: '请选择奖励类型',
            trigger: 'change'
          }, ],
          arrClass: [{
            required: true,
            message: '请选择班级',
            trigger: 'change'
          }, ],

        },
        coefficient: null, //系数
        isDisabledClass: false,
        // 教师
        TeacherInfo: {},
      };
    },
    created() {
      let that = this;
      that.TeacherInfo = onGetNowUser(that);
      that.fullscreenLoading = true;
      // 奖励类型
      ajaxGet(that, "/api/admin/classprizetype", null, function(resType) {
        // 奖励级别
        ajaxGet(that, '/api/admin/classprizelevel', null, function(resLevel) {
          // 班级
          let resClass = that.TeacherInfo.isTeacher ? that.TeacherInfo.manageClass : that.TeacherInfo.allClass;

          // 设置奖励类型
          if (!regNullArray(resType)) {
            that.optClassPrizeType = resType;
          }
          // 设置奖励级别
          if (!regNullArray(resLevel)) {
            that.optAllLevel = resLevel;
            that.optLevels = onGetJsonToTree(resLevel, 'levelId', 'parentId', 'children');
          }
          // 设置班级
          if (!regNullArray(resClass)) {
            that.optClass = resClass;
          }

          if (!regNull(that.$route.params.id)) {
            ajaxGet(that, '/api/admin/classprize/' + that.$route.params.id, null, function(resData) {
              that.fullscreenLoading = false;
              if (!regNull(resData)) {
                that.ruleForm.levelId = [];
                that.ruleForm.prizeId = resData.prizeId;
                that.ruleForm.name = resData.name;
                that.ruleForm.typeId = resData.typeId;
                that.ruleForm.arrClass.push(resData.classId);
                that.ruleForm.classId = resData.classId;
                that.ruleForm.prizeDate = resData.prizeDate;
                that.ruleForm.remark = resData.remark;
                that.uploadFiles = [];
                that.onGetLevelIds(that, resData.levelId);
                that.isDisabledClass = true;

                if (!regNull(resData.attachment)) {
                  var files = resData.attachment.split('|');
                  for (var i in files) {
                    var it = i + 1;
                    that.uploadFiles.push({
                      uid: it,
                      path: files[i],
                    });
                  }
                  var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
                  that.random += random;
                }

                that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
              }
            });
          } else {
            that.fullscreenLoading = false;
            that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
          }


        });

      })
    },

    methods: {
      /**
       * @description 编辑时获取奖励级别ID组
       * @param {Object} that
       * @param {Object} objVal
       */
      onGetLevelIds(that, objVal) {
        let temp = that.optAllLevel.find(item => item.levelId == objVal);
        if (temp != undefined) {
          that.ruleForm.levelId.unshift(temp.levelId);
          if (temp.parentId != "00000000-0000-0000-0000-000000000000") {
            that.onGetLevelIds(that, temp.parentId);
          }
        }
      },
      /**
       * @description 上传附件成功事件
       * @param {Object} param
       */
      onPictureHandleSuccess(param) {
        let that = this;
        that.ruleForm.attachment = "";
        for (var i in param) {
          if (that.ruleForm.attachment == "") {
            that.ruleForm.attachment = param[i].path;
          } else {
            that.ruleForm.attachment += "|" + param[i].path;
          }
        }
      },
      /**
       * @description 重置事件
       */
      onReset() {
        let that = this;
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      /**
       * @description 提交数据
       */
      onSave() {
        let that = this;

        that.$refs["ruleForm"].validate(valid => {
          if (valid) {

            that.ruleForm.configs = [];
            let tpConfigs = [];
            let tpType = that.optClassPrizeType.find(item => item.typeId == that.ruleForm.typeId);
            if (tpType != undefined) {
              tpConfigs = tpType.configs;
            }

            that.ruleForm.levelId = that.ruleForm.levelId[that.ruleForm.levelId.length - 1];
            let tpLevel = that.optAllLevel.find(item => item.levelId == that.ruleForm.levelId);
            if (tpLevel != undefined) {
              if (!regNullArray(tpConfigs)) {
                tpConfigs.forEach((item) => {
                  that.ruleForm.configs.push({
                    configId: item.configId,
                    score: item.score * tpLevel.coefficient
                  })
                });
              }
            }
            if (regNull(that.ruleForm.prizeId)) {
              that.fullscreenLoading = true;
              that.ruleForm.classes = that.ruleForm.arrClass;
              ajaxPost(that, "/api/admin/classprize/batch", that.ruleForm, function(r) {
                // 记录添加失败的班级数据
              });
              setTimeout(function() {
                that.fullscreenLoading = false;
                that.onBack();
                return false;
              }, 1500);
            } else {
              that.fullscreenLoading = true;
              ajaxPut(that, "/api/admin/classprize/" + that.ruleForm.prizeId, that.ruleForm, function(r) {
                that.fullscreenLoading = false;
                that.onBack();
              });

            }
          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              isError[0].querySelector('input').focus();
            }, 1)
            return false;
          }
        });
      },
      /**
       * @description 返回
       */
      onBack() {
        let that = this;
        back(that);
      },
    },
  };

</script>

<style scoped="scoped">
  label {
    margin-bottom: 0;
  }

  .inputGroupCont {}

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }

  .spIcon {
    width: 5px;
    height: 20px;
    border-radius: 5px;
    background-image: linear-gradient(#4545FF, #2D8EFF);
  }

  .spTitle {
    float: left;
    margin-left: 20px;
    color: #323E56;
    letter-spacing: 2px;
    font-size: 16px;
    margin-top: -3px;
  }

  .wMax {
    width: 100% !important;
  }

</style>

