<!--
   管理原因设置
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          批量授权
        </h3>
        <div class="card-tools">
          <ul class="nav nav-pills ml-auto">
            <li class="nav-item pr-1" v-if="type == 1">
              角色：
              <el-select v-model="ruleForm.roleId" clearable placeholder="请选择" @change='onChangeRole'>
                <el-option v-for="item in optRole" :key="item.roleId" :label="item.name" :value="item.roleId">
                </el-option>
              </el-select>
            </li>
            <li class="nav-item pr-1" v-if="type == 2">
              学生会：
              <el-select v-model="ruleForm.associationId" clearable placeholder="请选择" @change='onChangeRole'>
                <el-option v-for="item in optAtion" :key="item.associationId" :label="item.name"
                  :value="item.associationId">
                </el-option>
              </el-select>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="!regNullArray(itemData)">
        <div style="border: 1px solid #efefef; border-radius: 3px; padding: 10px;">
          <el-tree :data="itemData" :props="defaultProps" ref="menuTree" v-if="itemData.length>0" show-checkbox
            auto-expand-parent :expand-on-click-node="false" :default-checked-keys="idArr"
            :default-expanded-keys="idArr" default-expand-all node-key='id'>
          </el-tree>
        </div>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>

    <div class="row mt-3">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack">返回</el-button>
        <el-button icon="el-icon-refresh" @click="onReset()">重置</el-button>
        <el-button type="primary" icon="el-icon-circle-check" @click="onSubmit()">提交</el-button>
      </div>
    </div>


  </div>
</template>

<script>
  import "@/assets/css/Admin.css";
  export default {
    components: {},
    data() {
      return {
        fullscreenLoading: false,
        itemData: [], //列表数据
        currentType: 1,
        optTabs: [],
        reason: [],
        idInit: [],
        selectreason: [],
        allreason: [],
        idArr: [],
        isPower: {
          moralityclassreason_index: false,
          moralityreason_index: false,
          moralitydormitoryreason_index: false,
          moralitydpreason_index: false,
        },
        defaultProps: {
          children: 'children',
          label: 'name',
          id: 'name'
        },
        ruleForm: {
          roleId: "",
          associationId: "",
        },
        optRole: [],
        optAtion: [],
        type: 1,
      };
    },
    created() {
      let that = this;
      isPower(that, 'moralityclassreason_index', "moralityclassreason_index");
      isPower(that, 'moralityreason_index', "moralityreason_index");
      isPower(that, 'moralitydormitoryreason_index', "moralitydormitoryreason_index");
      that.type = that.$route.params.Type;
      that.ruleForm.roleId = that.$route.query.roleId;
      that.ruleForm.associationId = that.$route.query.associationId;
      ajaxGet(that, '/api/admin/sysrole', null, function(resRole) {
        that.optRole = resRole;
      })
      ajaxGet(that, '/api/admin/association', null, function(res) {
        that.optAtion = res;
      })
      that.optTabs = [];
      ajaxGet(that, "/api/admin/moralreason/list", null, function(res) {
        that.itemData = [];
        that.allreason = res;
        let reasonList = res;
        for (var it of reasonList) {
          if (it.category == null) {
            it.category = '其他';
          }
        }
        reasonList = Array.from(new Map(reasonList.map(item => [item.categoryId, item])).values());
        for (var it2 of reasonList) {
          that.itemData.push({
            id: it2.categoryId,
            name: it2.category,
            children: [],
          })
        }
        for (var it3 of res) {
          for (var it4 of that.itemData) {
            if (it3.categoryId == it4.id) {
              it4.children.push({
                id: it3.reasonId,
                name: it3.name,
              })
            }
          }
        }
        that.itemData.forEach(item => {
          if (item.id == null) {
            item.id = '00000000000'
          }
        })
        that.onChangeRole();
      })
    },
    methods: {
      onChangeRole() {
        let that = this;
        let url = null;
        if (that.type == 1) {
          url = '/api/admin/moralreason/byrole?roleId=' + that.ruleForm.roleId + '&categoryType=' + that.currentType
        } else {
          url = '/api/admin/moralreason/byassociation?associationId=' + that.ruleForm.associationId + '&categoryType=' +
            that.currentType
        }
        that.idArr = [];
        if (!regNull(that.ruleForm.roleId) || !regNull(that.ruleForm.associationId)) {
          ajaxGet(that, url, null, function(r1) {
            that.idArr = r1;
            that.idInit = r1;
            that.$refs.menuTree.setCheckedKeys(JSON.parse(JSON.stringify(that.idArr)));

          })
        } else {
          // that.idArr = [];
          that.$refs.menuTree.setCheckedKeys([]);
        }
      },
      /**
       * @description 返回按钮
       * @return {void}
       */
      onBack() {
        let that = this;
        back(that);
      },
      /**
       * @description 重置
       */
      onReset() {
        let that = this;
        that.$refs.menuTree.setCheckedKeys(JSON.parse(JSON.stringify(that.idInit)));
      },
      /**
       * @description 提交
       */
      onSubmit() {
        let that = this;
        var t = that.$refs.menuTree.getCheckedNodes();
        var arr = [];
        let url = that.type == 1 ? '/api/admin/moralreason/roleauthorize/' + that.ruleForm.roleId :
          '/api/admin/moralreason/associationauthorize/' + that.ruleForm.associationId;
        that.selectreason = [];
        for (var it of t) {
          arr.push(it.id);
        }

        for (var it of arr) {
          GetKeys(that, it);
        }
        const res = new Map();
        var selects = that.selectreason.filter((a) => !res.has(a) && res.set(a, 1));
        ajaxPut(that, url, selects, function(r) {
          that.fullscreenLoading = false;
          that.onBack();
        });
      }

    },
    watch: {
      $route() {
        let that = this;
        InitType(that);
      },
    },
  };

  function GetKeys(that, id) {
    for (var i in that.allreason) {
      if (that.allreason[i].reasonId == id) {
        that.selectreason.push(id);
        // if (that.allreason[i].parentId != null) {
        //   GetKeys(that, that.allreason[i].parentId);
        // }
      }
    }
  }
</script>
<style scoped="scoped" lang="less">

</style>
