<!--
  班主任手册-学期活动计划
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <search :ColNumber="2" :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null">
    </search>


    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          学期活动计划
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <el-button v-if="isPower.adviseractivityplan_create == true && isCurrentTeacher == true" type="success"
                icon="el-icon-circle-plus-outline" @click="onAdd()">新增</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="item==null || item.length==0">

        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>

      </div>
      <div class="card-body" v-for="(p,index) in item" :key="index">
        <div class="weekHeaderBox">
          <div class="elTagBox">
            <el-tag>{{"第 "+p.date+" 周"}}</el-tag>
          </div>
          <div class="cardTools">
            <operationBtn v-if="isPower.adviseractivityplan_edit == true  && isCurrentTeacher == true" :btnName="'编辑'"
              @click.native="onEdit(p.planId)"></operationBtn>
            <operationBtn v-if="isPower.adviseractivityplan_delete == true && isCurrentTeacher == true" :btnName="'删除'"
              @click.native="onDelete(p.planId)"></operationBtn>
          </div>
        </div>
        <div class="card-body DetailsFonts">
          <div v-html="p.content"></div>
        </div>
        <!--分隔线-->
        <el-divider v-if="index<item.length-1">
          <svg aria-hidden="true" class="icon faxIcon">
            <use xlink:href="#fax-dot-circle-o"></use>
          </svg>
        </el-divider>

      </div>
      <comPage v-if="item && item.length>0" :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick"
        @pageBtnClick="pageBtnClick">
      </comPage>
    </el-card>





  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';
  import comPage from '@/components/Page.vue';

  export default {
    name: '',
    components: {
      operationBtn,
      search,
      comPage
    },
    data() {
      return {
        fullscreenLoading: false,
        page: null, //分页查询接口返回的结果
        item: [], //列表数据
        pageBtnList: null, //分页左侧按钮集合
        paging: { //分页数据
          pageLength: 0, // 总共页数
          Size: 10, // 当前请求数量
          Index: 1 //当前在第几页
        },
        SearchList: null, //搜索集合
        isPower: {
          //增删改查 权限管理
          adviseractivityplan_create: false,
          adviseractivityplan_edit: false,
          adviseractivityplan_delete: false,
          adviseractivityplan_user: false,
        },
        isCurrentTeacher: true, //历史班主任是否为当前用户
        ManagerClass: [], //班主任管理的班级的集合

        TeacherInfo: {}, // 教师信息
      };
    },
    created() {
      let that = this;
      that.TeacherInfo = onGetNowUser(that);
      pagingPage(that);
    },
    methods: {
      onDelete(Id) //删除
      {
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, "/api/admin/adviseractivityplan/" + Id, null, function(r) {
              that.fullscreenLoading = false;
              pagingPage(that);
            });
          }
        })
      },
      onEdit(Id) {
        let that = this;
        that.$router.push({
          path: "/Admin/Adviser/ActivityPlan/Edit/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onAdd() {
        let that = this;
        that.$router.push({
					path: '/Admin/Adviser/ActivityPlan/Create',
						query: {
						  url: that.$router.history.current.fullPath
						}
				});
      },
      onSearch(params) {
        let that = this;
        let data = {};

        let searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = Join(element.data);
          }
        });
        data.PageNumer = 1;
        routerPath(that, "/Admin/Adviser/ActivityPlan/Index", data, null, "PageSize");
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      pagingClick(type, val) {
        let that = this;
        let url = "/Admin/Adviser/ActivityPlan/Index";
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "SemesterId",
            "ClassId",
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "SemesterId",
            "ClassId",
          );
        }
      },
    },
    watch: {
      $route() {
        let that = this;
        pagingPage(that);
      }
    }
  };

  function pagingPage(that) {
    paging(
      that,
      function(r) {
        isPower(that, "adviseractivityplan_details", "adviseractivityplan_details");
        isPower(that, "adviseractivityplan_create", "adviseractivityplan_create");
        isPower(that, "adviseractivityplan_edit", "adviseractivityplan_edit");
        isPower(that, "adviseractivityplan_user", "adviseractivityplan_user");
        that.fullscreenLoading = true;
        ajaxGet(that, "/api/admin/Semester", null, function(semester) {
          if(!regNullArray(semester)){
            var ClassId = r.ClassId;
            var classList = [];
            let resultClass = that.TeacherInfo.isTeacher ? that.TeacherInfo.manageClass : that.TeacherInfo.allClass;

            var SemesterId = r.SemesterId;
            var semesters = [];
            for (var i in semester) {
              var it = {
                Title: semester[i].name,
                Value: semester[i].semesterId,
              };
              if (regNull(SemesterId)) {
                if (semester[i].isCurrent == true) {
                  SemesterId = semester[i].semesterId;
                }
              }
              semesters.push(it);
            }

            let Myurl = that.TeacherInfo.isTeacher ? "/api/admin/adviseractivityplan/search" : "/api/admin/adviseractivityplan/user/search";
            for (var i in resultClass) {
              classList.push({
                Title: resultClass[i].alias,
                Value: resultClass[i].classId,
              });
            }

            if (regNull(ClassId) && !regNullArray(resultClass)) {
              ClassId = resultClass[0].classId;
            }

            that.SearchList = [{
                type: "select",
                Name: "学期",
                data: SemesterId,
                select: semesters, //选择的数据
                zhi: "SemesterId",
                isClear: false, //select是否可以清除
                resetData: SemesterId,
              },
              {
                type: "select",
                Name: "班级",
                data: ClassId,
                select: classList,
                zhi: "ClassId",
                isClear: !that.TeacherInfo.isTeacher, //select是否可以清除
                resetData: ClassId,
              },
            ];

            let data = {
              PageNumer: r.PageNumer,
              PageSize: r.PageSize,
              SemesterId: SemesterId,
              ClassId: ClassId,
            };
            ajaxGet(that, Myurl, data, function(r1) {
              that.page = r1;
              that.item = r1.items;
              that.isCurrentTeacher = true;
              var name = JSON.parse(that.$GetKey("Name"));
              if (that.item != '') {
                if (that.item[0].adviser != name) {
                  that.isCurrentTeacher = false;
                }
              }
              that.fullscreenLoading = false;
            });

          }else{
            that.fullscreenLoading = false;
          }


        });
      },
      "SemesterId",
      "ClassId",
    );
  }

</script>

<style scoped="scoped">
  .cardList {
    border-bottom: 1px solid #DDDDDD;
  }

  .weekHeaderBox {
    display: flex;
  }

  .weekHeaderBox .elTagBox {
    flex: 1;
  }

</style>

