<!--
  评语短语
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <search :ColNumber="3" :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList != null"></search>
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px">评语短语</h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item pr-2" v-if="isPower.commentphrase_import">
              <comImport
                @onImportSuccess="onImportSuccess"
                :template="'/templates/评语短语导入模板.xlsx'"
                :path="'/api/admin/commentphrase/import'"
                :btnIcon="'el-icon-upload2'"
                :title="'批量导入短语'"
                :btnName="'批量导入短语'"
                ref="conImportInfoFile"
              ></comImport>
            </li>
            <li class="nav-item">
              <el-button v-if="isPower.commentphrase_create == true" type="success" icon="el-icon-circle-plus-outline" @click="onCreate()">新增</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="!regNullArray(itemData)">
        <el-table :data="itemData" stripe class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="type" label="短语级别" class="elColumn"></el-table-column>
          <el-table-column prop="content" label="短语内容" class="elColumn" min-width="300" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column label="所属维度" class="elColumn" width="120">
            <template slot-scope="scope">
              {{ regNull(scope.row.configId) ? '总评' : scope.row.config }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="240">
            <template slot-scope="scope">
              <operationBtn v-if="isPower.commentphrase_detail == true" :btnName="'查看'" @click.native="onDetail(scope.row)"></operationBtn>
              <operationBtn v-if="isPower.commentphrase_edit == true" :btnName="'编辑'" @click.native="onEdit(scope.row)"></operationBtn>
              <operationBtn v-if="isPower.commentphrase_delete == true" :btnName="'删除'" @click.native="onDelete(scope.row.phraseId)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick"></comPage>
      </div>
      <div class="card-body" v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>

    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="50%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :append-to-body="true"
      :fullscreen="false"
    >
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px">
        <el-row v-if="isDetail != true">
          <!-- <el-col :span="24">
              <el-form-item label="短语简称" prop="addr">
                <el-input v-model="ruleForm.addr" placeholder="请输入短语简称"></el-input>
              </el-form-item>
            </el-col> -->
          <el-col :span="24">
            <el-form-item label="短语内容" prop="content">
              <el-input v-model="ruleForm.content" placeholder="请输入短语内容" type="textarea" :rows="4"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="短语级别" prop="typeId">
              <el-select v-model="ruleForm.typeId" placeholder="请选择短级别">
                <el-option v-for="item in optType" :key="item.Value" :label="item.Title" :value="item.Value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="所属维度">
              <el-select v-model="ruleForm.configId" placeholder="请选择所属维度">
                <el-option v-for="item in optConfig" :key="item.Value" :label="item.Title" :value="item.Value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-show="false">
            <el-form-item label="优先级" prop="priority">
              <el-input v-model="ruleForm.priority" placeholder="请输入优先级"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="row" v-else>
          <!-- <div class="col-lg-12 colInfo">
              <label>短语简称</label>
              <div class="colFonts">{{ruleForm.addr}}</div>
            </div> -->
          <div class="col-lg-12 colInfo">
            <label>短语类型</label>
            <div class="colFonts">{{ ruleForm.type }}</div>
          </div>
          <div class="col-lg-12 colInfo">
            <label>所属维度</label>
            <div class="colFonts">{{ regNull(ruleForm.config) ? '总评' : ruleForm.config }}</div>
          </div>
          <div class="col-lg-12 colInfo">
            <label>短语内容</label>
            <div class="colFonts">{{ ruleForm.content }}</div>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <div v-if="isDetail != true">
          <el-button @click="onReset()">取 消</el-button>
          <el-button type="primary" @click="onSave()">确 定</el-button>
        </div>
        <div v-else>
          <el-button @click="dialogVisible = false">关 闭</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import '@/assets/css/Admin.css';
import comPage from '@/components/Page.vue';
import operationBtn from '@/components/operationBtn.vue';
import search from '@/components/search.vue';
import comImport from '@/components/Import.vue';

export default {
  components: {
    comPage,
    operationBtn,
    comImport,
    search
  },
  data() {
    return {
      //加载动画
      fullscreenLoading: false,
      //搜索
      SearchList: null,
      //分页查询接口返回的结果
      page: null,
      //待重置时的表单数据
      ruleFormReset: null,
      //列表数据
      itemData: [],
      //分页左侧按钮集合
      pageBtnList: null,
      //分页数据
      paging: {
        // 总共页数
        pageLength: 0,
        // 每页请求的数据量
        Size: 1,
        //当前在第几页
        Index: 1
      },
      dialogVisible: false,
      dialogTitle: '新增', //弹出框标题
      isDetail: false, //是否是查看弹出框
      ruleForm: {
        addr: null, //短语简称
        content: null, //短语内容
        priority: 0, //排序优先级
        isPublic: true,
        configId: null // 所属维度
      },
      rules: {
        addr: [{ required: true, message: '短语简称不能为空', trigger: 'change' }],
        typeId: [{ required: true, message: '短语级别不能为空', trigger: 'change' }],
        content: [{ required: true, message: '短语内容不能为空', trigger: 'change' }],
        priority: [
          {
            validator: (rule, value, callback) => {
              let that = this;
              var reg = /^-?[1-9]\d*$/;
              if (value != 0 && !reg.exec(value)) {
                callback(new Error('优先级只能输入整数！'));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ]
      },
      isPower: {
        //增删改查 权限管理
        commentphrase_detail: false,
        commentphrase_create: false,
        commentphrase_edit: false,
        commentphrase_delete: false,
        commentphrase_import: false
      },
      isTeacher: false, //是否是老师
      optType: [], // 短语级别

      optConfig: [
        {
          Title: '总评',
          Value: '00000000-0000-0000-0000-000000000000'
        }
      ] // 所属维度
    };
  },
  created() {
    let that = this;
    isPower(that, 'commentphrase_detail', 'commentphrase_detail');
    isPower(that, 'commentphrase_create', 'commentphrase_create');
    isPower(that, 'commentphrase_edit', 'commentphrase_edit');
    isPower(that, 'commentphrase_delete', 'commentphrase_delete');
    isPower(that, 'commentphrase_import', 'commentphrase_import');

    that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));

    // 获取教师信息
    let tpUserInfo = onGetNowUser(that);
    // 判断是否为班主任
    that.isTeacher = tpUserInfo.isTeacher;

    that.fullscreenLoading = true;
    ajaxGet(that, '/api/admin/growthconfig', null, function (resConfig) {
      ajaxGet(that, '/api/admin/commentphrasetype', null, function (resType) {
        that.fullscreenLoading = false;
        // 整理短语级别数据
        if (!regNullArray(resType)) {
          that.optType = resType.map((item) => ({
            Title: item.name,
            Value: item.typeId
          }));
        }

        // 整理维度数据
        if (!regNullArray(resConfig)) {
          that.optConfig.push(
            ...resConfig
              .filter((item) => regNull(item.parentId) == true)
              .map((item) => ({
                Title: item.name,
                Value: item.configId
              }))
          );
        }

        pagingPage(that);
      });
    });
  },
  methods: {
    /**
     * @description 导出学生信息成功后执行
     * @param {Object} param
     */
    onImportSuccess(param) {
      let that = this;
      if (param.result == true) {
        pagingPage(that);
      }
    },
    /**
     * @description 列表序号索引
     * @param {Object} index
     */
    indexMethod(index) {
      return (this.paging.Index - 1) * this.paging.Size + index + 1;
    },
    /**
     * @description 新增编辑
     */
    onSave() {
      let that = this;
      that.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let tpParams = JSON.parse(JSON.stringify(that.ruleForm));
          tpParams.priority = 0;
          tpParams.configId = that.ruleForm.configId == '00000000-0000-0000-0000-000000000000' ? null : that.ruleForm.configId;

          if (regNull(that.ruleForm.phraseId)) {
            that.fullscreenLoading = true;
            if (that.isTeacher) {
              //老师
              tpParams.isPublic = false;
            } else {
              //管理员
              tpParams.isPublic = true;
            }
            ajaxPost(that, '/api/admin/commentphrase', tpParams, function (r) {
              that.fullscreenLoading = false;
              that.dialogVisible = false;
              pagingPage(that);
            });
          } else {
            that.fullscreenLoading = true;
            ajaxPut(that, '/api/admin/commentphrase/' + that.ruleForm.phraseId, tpParams, function (r) {
              that.fullscreenLoading = false;
              that.dialogVisible = false;
              pagingPage(that);
            });
          }
        } else {
          setTimeout(() => {
            var isError = document.getElementsByClassName('is-error');
            isError[0].querySelector('input').focus();
          }, 1);
          return false;
        }
      });
    },
    /**
     * @description 新增按钮
     */
    onCreate() {
      let that = this;
      that.dialogTitle = '新增';
      that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
      that.dialogVisible = true;
      that.isDetail = false;
      try {
        that.$refs['ruleForm'].resetFields();
      } catch {}
    },
    /**
     * @description 详情按钮事件
     * @param {*} Id
     */
    onDetail(objEvent) {
      let that = this;
      that.isDetail = true;
      that.dialogTitle = '查看';
      that.ruleForm = JSON.parse(JSON.stringify(objEvent));
      that.dialogVisible = true;
    },
    /**
     * @description 编辑按钮事件
     * @param {*} Id
     */
    onEdit(objEvent) {
      let that = this;
      that.isDetail = false;
      that.dialogTitle = '编辑';
      that.ruleForm = JSON.parse(JSON.stringify(objEvent));
      that.ruleForm.configId = regNull(that.ruleForm.configId) ? '00000000-0000-0000-0000-000000000000' : that.ruleForm.configId;
      that.dialogVisible = true;
    },
    /**
     * @description 搜索事件
     * @param {*} params
     */
    onSearch(params) {
      let _this = this;
      let data = {};
      let searchData = params;
      searchData.forEach((element) => {
        if (element.data) {
          data[element.zhi] = element.data;
        }
      });
      data.PageNumer = 1;
      routerPath(_this, '/Admin/CommentPhrase/Index', data, null, 'PageSize');
    },
    /**
     * @description 取消
     */
    onReset() {
      let that = this;
      that.dialogVisible = false;
      this.ruleForm = JSON.parse(JSON.stringify(this.ruleFormReset));
      try {
        that.$refs['ruleForm'].resetFields();
      } catch {}
    },
    /**
     * @description 删除
     * @param {*} Id
     */
    onDelete(Id) {
      let that = this;
      confirmDelete(that, null, function (res) {
        if (res == true) {
          that.fullscreenLoading = true;
          ajaxDelete(that, '/api/admin/commentphrase/' + Id, null, function (r) {
            that.fullscreenLoading = false;
            pagingPage(that);
          });
        }
      });
    },
    pageBtnClick(index) {
      //分页组件左侧的按钮事件，按顺序进行判断
    },
    // 分页面按钮
    pagingClick(type, val) {
      let that = this;
      let url = '/Admin/CommentPhrase/Index';
      if (type == 1) {
        //更改每页条数触发的事件
        routerPath(that, url, 'PageSize', val, 'PageNumer', 'Content', 'TypeId', 'ConfigId');
      } else {
        //更改当前页时触发的事件
        routerPath(that, url, 'PageNumer', val, 'PageSize', 'Content', 'TypeId', 'ConfigId');
      }
    }
  },

  watch: {
    $route() {
      let that = this;
      pagingPage(that);
    }
  }
};

function pagingPage(that) {
  paging(
    that,
    function (r) {
      let tpParams = {
        PageNumer: r.PageNumer,
        PageSize: r.PageSize,
        Content: r.Content,
        TypeId: r.TypeId,
        IsPublic: !that.isTeacher,
        ConfigId: r.ConfigId
      };
      that.SearchList = [
        {
          type: 'input',
          Name: '短语内容',
          data: r.Content,
          holder: '请输入内容关键字',
          zhi: 'Content'
        },
        {
          type: 'select',
          Name: '短语级别',
          data: r.TypeId,
          select: that.optType,
          zhi: 'TypeId'
        },
        {
          type: 'select',
          Name: '所属维度',
          data: r.ConfigId,
          select: that.optConfig,
          zhi: 'ConfigId'
        }
      ];
      that.paging.Index = r.PageNumer;
      that.paging.Size = r.PageSize;
      that.fullscreenLoading = true;
      ajaxGet(that, '/api/admin/commentphrase/search', tpParams, function (resData) {
        that.page = resData;
        that.itemData = resData.items;
        that.fullscreenLoading = false;
      });
    },
    'Content',
    'TypeId',
    'ConfigId'
  );
}
</script>

<style scoped="scoped"></style>
