<!--
  自我记录-记录详情
-->
<template>
  <div class="SubWebPageStudent" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form label-width="100px">

      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            查看获奖上报
          </h3>
        </div>
        <div class="card-body">

            <el-row :gutter="24">
              <el-col :span="24" class="colInfo">
                <label>获奖名称</label>
                <div class="colFonts">{{itemData.name | flNoValue}}</div>
              </el-col>
              <el-col :span="8" class="colInfo">
                <label>获奖日期</label>
                <div class="colFonts">{{itemData.recordDate | dateformatDay}}</div>
              </el-col>
              <el-col :span="8" class="colInfo">
                <label>颁奖单位</label>
                <div class="colFonts"> {{itemData.place | flNoValue}}</div>
              </el-col>
              <el-col :span="8" class="colInfo">
                <label>审核状态</label>
                <div class="colFonts">
                  <span class="color-warning" v-if="itemData.auditState==1"> {{itemData.auditStateString}}</span>
                  <span class="color-success" v-else-if="itemData.auditState==2"> {{itemData.auditStateString}}</span>
                  <span class="color-danger" v-else-if="itemData.auditState==3"> {{itemData.auditStateString}}</span>
                  <span v-else>{{itemData.auditStateString}}</span>
                </div>
              </el-col>
            </el-row>

        </div>
      </el-card>

      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            备注
          </h3>
        </div>
        <div class="card-body">
          <div class="colRemarksCont" v-if="itemData.description!=null" v-html="itemData.description">
          </div>
          <div class="NoDataTipCont" style="text-align: center;" v-else>
            <svg aria-hidden="true" class="icon">
              <use xlink:href="#fax-exclamation-triangle-o"></use>
            </svg> 暂无备注
          </div>
        </div>
      </el-card>


      <comShowImage :uploadFiles="uploadFiles"></comShowImage>



      <div class="row">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
        </div>
      </div>


    </el-form>
  </div>
</template>

<script>
  import '@/assets/css/Student.css';
  import comShowImage from '@/components/showImage.vue';
  export default {
    components: {
      comShowImage
    },
    data() {
      return {
        // 动画
        fullscreenLoading: false,
        dialogVisible: false,
        //列表数据
        itemData: [],
        uploadFiles: [],
        random: 0,
        videoArr: [], //存放视频
        showViewer: false,
        // 弹出框标题
        dialogScoreName: '',
        dialogScoreVisible: false,
        dialogArray: [],
        GrowthConfigs: [], //综合类型集合
      };
    },
    methods: {
      onOpenTypeInfo(_data) {
        let that = this;
        that.dialogScoreName = _data.name;
        that.dialogArray = [];
        if (_data != null) {
          for (let item of _data.children) {
            if (item.score > 0) {
              that.dialogArray.push({
                name: item.name,
                score: item.score
              })
            }
          }
        }
        if (that.dialogArray.length > 0) {
          that.dialogScoreVisible = true;
        } else {
          info(that, '未配综合类型');
        }
      },
      /**
       * @description 计算综合分数
       * @param {Object} _data
       * @return {Number} 计算出的总分
       */
      onCalculationScore(_data) {
        let temp = 0;
        if (_data != null && _data.length > 0) {
          for (let item of _data) {
            temp = item.score + temp;
          }
        }
        return temp;
      },

      onBack() {
        let that = this;
        back(that);
      },
    },
    created() {
      let that = this;
      that.fullscreenLoading = true;
      ajaxGet(that, "/api/student/selfreport/" + that.$route.params.id, null, function(r) {
          that.fullscreenLoading = false;
          that.itemData = r;
          that.uploadFiles = [];
          if (r.attachment != null && typeof(r.attachment) != "undefined" && r.attachment != "") {
            var files = r.attachment.split('|');
            for (var i in files) {
              that.uploadFiles.push(that.$ServerUrl() + files[i]);
            }
          }
      })
    },

    filters: {
      flNoValue(obj) {
        if (obj == null || typeof(obj) == "undefined" || obj == '') {
          return '暂无数据';
        }
        return obj;
      }
    },

  };


</script>

<style scoped="scoped">
  .imgBox {
    width: 100%;
    height: 200px;
    border: 1px solid #DDDDDD;
    border-radius: 5px;
  }

  .infoList {
    padding-bottom: 17px;
    padding-left: 30px;
  }

  .infoList .spName {
    font-size: 32px;
    color: #212E48;
    padding-right: 30px;
    height: 40px;
  }

  .infoList .spFonts .spLabel {
    font-size: 14px;
    padding: 2px 5px;
    border-radius: 5px;
    background-color: #B1B1B1;
    color: #FFFFFF;
    margin-right: 10px;
  }

  .spIcon {
    width: 5px;
    height: 20px;
    border-radius: 5px;
    background-image: linear-gradient(#4545FF, #2D8EFF);
  }

  .divLiteracyBox {
    padding: 15px;
  }

  .divLiteracyBox .listBox {
    text-align: center;
    padding: 20px;
    float: left;
    border: 2px solid #FFFFFF;
    border-radius: 5px;
  }

  .divLiteracyBox .listBox:hover {
    border: 2px solid #409eff;
    cursor: pointer;
  }

  .divLiteracyBox .literacyName {
    font-size: 24px;
    letter-spacing: 2px;
    padding-bottom: 10px;
  }

  .divLiteracyBox .literacyNum {
    padding-top: 10px;
    font-family: 'OswaldLight';
    border-top: 2px solid #EEEEEE;
    font-size: 32px;
    color: #409eff;
  }
</style>


<style>
  .imgBox .el-image__inner {
    height: auto;
  }
</style>
