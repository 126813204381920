<!--
  职位管理
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          职位管理
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <search :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null"></search>
            </li>
            <li class="nav-item">
              <el-button v-if="isPower.classposition_create == true" type="success" icon="el-icon-circle-plus-outline"
                @click="onCreate()">新增</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table :data="itemData" stripe class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="name" label="职位名称" class="elColumn"></el-table-column>
          <el-table-column prop="number" label="人数" class="elColumn"  width="120">
            <template slot-scope="scope">
              {{onGetNumber(scope.row.number)}}
            </template>
          </el-table-column>
          <el-table-column prop="priority" label="优先级" class="elColumn"  width="120"></el-table-column>
          <el-table-column prop="enable" label="启用状态" class="elColumn" width="120">
            <template slot-scope="scope">
              <el-switch active-color="#409EFF" inactive-color="#C0CCDA" v-model="scope.row.enable" disabled>
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <operationBtn v-if="isPower.classposition_details == true" :btnName="'查看'" @click.native="onDetail(scope.row.positionId)"></operationBtn>
              &nbsp;
              <el-dropdown v-if="isPower.classposition_edit || isPower.classposition_delete" trigger="click"
                @command="handleCommand($event,scope.row.positionId)">
                <el-button size="mini">
                  更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="isPower.classposition_edit" class="color-warning"
                    command="onEdit">编辑</el-dropdown-item>
                  <el-dropdown-item divided v-if="isPower.classposition_delete" class="color-danger"
                    command="onDelete">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>


    <!-- 弹出框 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%" :close-on-click-modal="false"
      :close-on-press-escape="false" :append-to-body="true" :fullscreen="false">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="90px">
        <el-row v-if="isDetail!=true">
          <el-col :span="24">
            <el-form-item label="职位名称" prop="name">
              <el-input v-model="ruleForm.name" placeholder="请输入职位名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="人数" prop="">
              <el-input v-model="ruleForm.number" placeholder="请输入人数，不填写为人数不限制"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="优先级">
              <el-input v-model="ruleForm.priority" placeholder="请输入优先级"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="启用状态" prop="enable">
              <el-switch v-model="ruleForm.enable" active-color="#409EFF" inactive-color="#C0CCDA">
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="row" v-else>
          <div class="col-lg-12 colInfo">
            <label>职位名称</label>
            <div class="colFonts">{{ruleForm.name}}</div>
          </div>
          <div class="col-lg-12 colInfo">
            <label>人数</label>
            <div class="colFonts">{{ruleForm.number}}</div>
          </div>
          <div class="col-lg-12 colInfo">
            <label>优先级</label>
            <div class="colFonts">{{ruleForm.priority}}</div>
          </div>
          <div class="col-lg-12 colInfo">
            <label>启用状态</label>
            <div class="colFonts">
              <span class="color-success" v-if="ruleForm.enable">已启用</span>
              <span class="color-gray" v-else>未启用</span>
            </div>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <div v-if="isDetail!=true">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button @click="onReset()">重 置</el-button>
          <el-button type="primary" @click="onSave()">确 定</el-button>
        </div>
        <div v-else>
          <el-button @click="dialogVisible = false">关 闭</el-button>
        </div>
      </span>
    </el-dialog>

  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';

  export default {
    components: {
      comPage,
      operationBtn,
      search
    },
    data() {
      return {
        //加载动画
        fullscreenLoading: false,
        //搜索
        SearchList: null,
        //分页查询接口返回的结果
        page: null,
        //待重置时的表单数据
        ruleFormReset: null,
        //列表数据
        itemData: [],
        //分页左侧按钮集合
        pageBtnList: null,
        //分页数据
        paging: {
          // 总共页数
          pageLength: 0,
          // 每页请求的数据量
          Size: 1,
          //当前在第几页
          Index: 1
        },
        dialogVisible: false,
        dialogTitle: "新增", //弹出框标题
        isDetail: false, //是否是查看弹出框
        ruleForm: {
          positionId: null,
          name: null, //职位名称
          enable: true,
          number: null, //人数
          priority: 0, // 优先级
        },
        rules: {
          name: [{
              required: true,
              message: '民族不能为空',
              trigger: 'change'
            },
            {
              max: 64,
              message: '民族不能超过64个字符',
              trigger: 'change'
            },
          ],
          number: [{
              required: true,
              message: '人数不能为空',
              trigger: 'change'
            },
            {
              validator: (rule, value, callback) => {
                let that = this;
                var reg = /^-?[1-9]\d*$/;
                if (value != 0 && !reg.exec(value)) {
                  callback(new Error("优先级只能输入整数！"));
                } else {
                  callback();
                }
              },
              trigger: 'change'
            }
          ],
        },
        isPower: {
          //增删改查 权限管理
          classposition_details: false,
          classposition_create: false,
          classposition_edit: false,
          classposition_delete: false,
        },
      };
    },
    methods: {
      /**
       * @description 更多操作
       * @param {Object} command
       * @param {Object} objValID
       */
      handleCommand(command, objValID) {
        let that = this;
        switch (command) {
          case 'onEdit':
            that.onEdit(objValID);
            break;
          case 'onDelete':
            that.onDelete(objValID);
            break;
        }
      },
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },
      /**
       * @description 判断人数
       *
       */
      onGetNumber(_number) {
        return _number == null ? '无限制' : _number;
      },
      /**
       * @description 新增/编辑
       */
      onSave() {
        let that = this;
        that.$refs["ruleForm"].validate(valid => {
          if (valid) {

            if (regNull(that.ruleForm.priority)) {
              that.ruleForm.priority = 0;
            }
            if(that.ruleForm.number == ''){
              that.ruleForm.number =null;
            }
            if (that.ruleForm.positionId == null) {
              if (that.ruleForm.enable == null) {
                that.ruleForm.enable = false;
              }
              that.fullscreenLoading = true;

              ajaxPost(that, "/api/admin/classposition", that.ruleForm, function(r) {
                that.fullscreenLoading = false;
                that.dialogVisible = false;
                pagingPage(that);
              });
            } else {
              that.fullscreenLoading = true;

              ajaxPut(that, "/api/admin/classposition/" + that.ruleForm.positionId, that.ruleForm, function(r) {
                that.fullscreenLoading = false;
                that.dialogVisible = false;
                pagingPage(that);
              });
            }
          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              isError[0].querySelector('input').focus();
            }, 1)
            return false;
          }
        });
      },
      onCreate() //新增
      {
        let that = this;
        that.dialogTitle = "新增";
        var ruleForm = JSON.parse(JSON.stringify(that.ruleForm));
        for (var i in ruleForm) {
          ruleForm[i] = null;
        }
        that.ruleForm = JSON.parse(JSON.stringify(ruleForm));
        that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
        that.ruleForm.enable =true;
        that.dialogVisible = true;
        that.isDetail = false;
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      onDetail(Id) //查看
      {
        let that = this;
        that.fullscreenLoading = true;
        that.isDetail = true;
        ajaxGet(that, "/api/admin/classposition/" + Id, null, function(r) {
          that.fullscreenLoading = false;
          that.dialogTitle = "查看";
          that.ruleForm = r;
          that.dialogVisible = true;
        });
      },
      onEdit(Id) //编辑
      {
        let that = this;
        that.fullscreenLoading = true;
        that.isDetail = false;
        ajaxGet(that, "/api/admin/classposition/" + Id, null, function(r) {
          that.fullscreenLoading = false;
          that.dialogTitle = "编辑";
          that.ruleForm = r;
          that.dialogVisible = true;
          that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
        });
      },
      onSearch(params) // 搜索
      {
        let _this = this;
        let data = {};
        let searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = element.data;
          }
        });
        data.PageNumer = 1
        routerPath(_this, "/Admin/Adviser/ClassPosition/Index", data, null, "PageSize");
      },
      onReset() //重置
      {
        let that = this;
        this.ruleForm = JSON.parse(JSON.stringify(this.ruleFormReset));
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      onDelete(Id) //删除
      {
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, "/api/admin/classposition/" + Id, null, function(r) {
              that.fullscreenLoading = false;
              pagingPage(that);
            });
          }
        })
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      // 分页面按钮
      pagingClick(type, val) {
        let that = this;
        let url = "/Admin/Adviser/ClassPosition/Index";
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "Name",
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "Name",
          );
        }
      }
    },
    created() {
      let that = this;
      isPower(that, "classposition_details", "classposition_details");
      isPower(that, "classposition_create", "classposition_create");
      isPower(that, "classposition_edit", "classposition_edit");
      isPower(that, "classposition_delete", "classposition_delete");
      pagingPage(that);
    },
    watch: {
      $route() {
        let that = this;
        pagingPage(that);
      }
    }
  };

  function pagingPage(that) {
    paging(
      that,
      function(r) {
        let apiUrl = "/api/admin/classposition/search";
        let data = {
          PageNumer: r.PageNumer,
          PageSize: r.PageSize,
          Name: r.Name,
        };
        that.SearchList = [{
          type: "input",
          Name: "职位名称",
          data: r.Name,
          holder: "请输入职位名称关键字",
          zhi: "Name"
        }, ];
        that.paging.Index=r.PageNumer;
        that.paging.Size=r.PageSize;
        that.fullscreenLoading = true;
        ajaxGet(that, apiUrl, data, function(r) {
          that.page = r;
          that.itemData = r.items;
          that.fullscreenLoading = false;
        });
      },
      "Name",
    );
  }
</script>

<style scoped="scoped">

</style>
