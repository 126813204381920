<!--
  学生管理-学生奖励
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <search :ColNumber="3" :SearchList="SearchList" :key="random" ref="SearchList" @onSearch="onSearch"
      v-if="SearchList!=null"></search>
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          奖励申请信息
        </h3>
      </div>
      <div class="card-body p-0" v-if="item!=null && item.length!=0">
        <el-table stripe :data="item" class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="name" label="奖项" min-width="150">
            <template slot-scope="scope">
              <span>{{scope.row.name}}</span>
            </template>
          </el-table-column>
          <el-table-column label="学生" min-width="100">
            <template slot-scope="scope">
              <div v-html="scope.row.studentName"></div>
            </template>
          </el-table-column>
          <el-table-column prop="level" label="奖励级别" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.level!=null">{{scope.row.level.name}}</span>
            </template>
          </el-table-column>
          <el-table-column label="奖励时间" min-width="120">
            <template slot-scope="scope">
              {{onGetDateFormat(scope.row.prizeDate)}}
            </template>
          </el-table-column>
          <el-table-column label="申请时间" min-width="120">
            <template slot-scope="scope">
              {{onGetDateFormat(scope.row.createDate)}}
            </template>
          </el-table-column>
           <el-table-column label="审核结果" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.status==2">待审核</span>
              <span v-else-if="scope.row.status==3">已拒绝</span>
              <span v-else-if="scope.row.status==4">已通过</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100px" fixed="right">
            <template slot-scope="scope">
              <operationBtn v-if="scope.row.status == 2" :btnName="'审核'" @click.native="onAudit(scope.row.applyId)"></operationBtn>
              <operationBtn v-else :btnName="'查看'" @click.native="onAudit(scope.row.applyId)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="paging" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>

    <div class="row">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
      </div>
    </div>

  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';
  export default {
    name: '',
    components: {
      comPage,
      operationBtn,
      search
    },
    data() {
      return {
        fullscreenLoading: false,
        itemData: [],
        dialogTitle: '',
        dialogVisible: false,
        random: 1, //刷新组件
        item: [], //列表数据
        pageBtnList: null, //分页左侧按钮集合
        paging: { //分页数据
          totalCount: 10, // 总共页数
          pageSize: 10, // 当前请求数量
          currentPage: 1 //当前在第几页
        },
        SearchList:null,//搜索集合
        url: null, //当前页面的路径
        isTeacher: false,
        statusList:[
          // {
          //   Title:'已取消',
          //   Value:'1'
          // },
          {
            Title:'待审核',
            Value:'2'
          },
          {
            Title:'已拒绝',
            Value:'3'
          },
          {
            Title:'已通过',
            Value:'4'
          }
        ]
      };
    },
    created() {
      let that = this;
      pagingPage(that,true);
    },
    methods: {
      onSearch(params){//搜索
        let that = this;
        let data = {};
        let searchData = params
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = Join(element.data);
          }
        });
        data.PageNumer = 1;
        data.random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        routerPath(that, "/Admin/Adviser/StudentPrize/List", data, null, "PageSize");
      },
      onBack(){
          let that = this;
          back(that);

      },
      /**
       * @description 审核
       * @param {Object} _valID
       */
      onAudit(_valID) {
        let that = this;
        that.$router.push({
          path: "/Admin/Adviser/StudentPrize/Audit/"+_valID,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.currentPage - 1) * this.paging.pageSize + index + 1;
      },
      onGetDateFormat(_val) {
        return dateFormat(_val, '.', 'YYYY-MM-DD');
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      pagingClick(type, val) {
        let that = this;
        let url = '/Admin/Adviser/StudentPrize/List';
        if (type == 1) { //更改每页条数触发的事件
          routerPath(that,url,"PageSize",val,"PageNumer","Name","Student","Status");
        } else { //更改当前页时触发的事件
          routerPath(that,url,"PageNumer",val,"PageSize", "Name","Student","Status");
        }
      },
    },
    watch: {
      $route() {
        let that = this;
        pagingPage(that);
      }
    }
  };
  function pagingPage(that,isInit){
     paging(that,function(r){
       let random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
       that.random+=random
       that.SearchList = [
            {
              type: "select",
              Name: "状态",
              data: r.Status,
              select: that.statusList,
              zhi: "Status",
              isClear: false, //select是否可以清除
            },
            {
              type: "input",
              Name: "奖励名称",
              data: r.Name,
              holder: "请输入奖励名称",
              zhi: "Name",
            },
            {
              type: "input",
              Name: "学生姓名",
              data: r.Student,
              holder: "请输入学号/姓名",
              zhi: "Student"
            }
       ]
       let url = '/api/admin/prizeapply/search'
        that.fullscreenLoading = true
        let data ={
          PageNumer:parseInt(r.PageNumer),
          PageSize:r.PageSize,
          Student:r.Student,
          Name:r.Name,
          Status:r.Status
        }
        that.paging.currentPage = data.PageNumer
        that.paging.pageSize = data.PageSize
        ajaxGet(that,url,data,function(res){
          that.fullscreenLoading = false
          that.paging.totalCount = res.totalCount
          that.item = res.items
        })
     },"Student","Name","Status")
  }

</script>

<style scoped="scoped">
</style>
