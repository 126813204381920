<template>
  <div class="SubWebPage " v-loading.fullscreen.lock="fullscreenLoading">

    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
      <el-card class="card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{pageTitle}}
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24">
            <el-col :lg="8" :sm="12">
              <el-form-item label="课程名称" prop="name" class="width_100Pie">
                <el-input v-model="ruleForm.name" placeholder="请输入课程名称"></el-input>
              </el-form-item>
            </el-col>

            <el-col :lg="8" :sm="12">
              <el-form-item label="学期" prop="semesterId">
                <el-select v-model="ruleForm.semesterId" collapse-tags  class="width_100Pie" placeholder="请选择学期">
                  <el-option v-for="(item,index) in semesterList" :key="index" :label="item.name"
                    :value="item.semesterId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
             <el-col :lg="8" :sm="12">
              <el-form-item label="班级" prop="classId">
                <el-select v-model="ruleForm.classId" filterable collapse-tags  class="width_100Pie" placeholder="请选择班级">
                  <el-option v-for="(item,index) in classList" :key="index" :label="item.alias" :value="item.classId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
             <el-col :lg="8" :sm="12">
              <el-form-item label="课程类型" >
                <el-select v-model="ruleForm.courseType"  class="width_100Pie" placeholder="请选择课程类型">
                  <el-option v-for="(item,index) in optCourseType" :key="index" :label="item.Text" :value="item.Value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>

      <el-card class="card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            课程配置
          </h3>
          <div class="card-tools">
            <ul class="nav">
              <li class="nav-item">
                <el-button type="success" icon="el-icon-circle-plus-outline" @click="onOpenDialogForm()">新增配置
                </el-button>
              </li>
            </ul>
          </div>
        </div>
        <div class="card-body p-0">
          <el-table :data="ruleForm.configs" stripe class="elTableData"
            v-if="ruleForm.configs!=null && ruleForm.configs.length>0 ">
            <el-table-column type="index" label="序号" width="80"></el-table-column>
            <el-table-column label="配置区间" >
              <template slot-scope="scope">
                <span>{{scope.row.typeFloor}} 至 {{scope.row.typeCeiling}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="综合类型" class="elColumn">
              <template slot-scope="scope">
                <span v-for="(item,index) in scope.row.children" :key="index" class="badge badge-dark ml-1 mr-1">
                  {{item.configName}}&nbsp;{{item.score}}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="250">
              <template slot-scope="scope">
                <operationBtn :btnName="'删除'" ref="remove" @click.native="onRemove(scope.row.tbID)"></operationBtn>
              </template>
            </el-table-column>
          </el-table>
          <div class="card-body " v-else>
            <el-empty :image-size="200" description="暂无课程配置" name="3"></el-empty>
          </div>
        </div>
      </el-card>

      <div class="row" style="margin-top: 10px;">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
          <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
          <el-button type="primary" icon="el-icon-circle-check" @click="onVerification('ruleForm')">提交</el-button>
        </div>
      </div>
    </el-form>

    <el-form :model="ruleFormDialog" ref="ruleFormDialog" class="ruleForm">
      <!--弹出框-->
      <el-dialog title="新增课程配置" :visible.sync="dialogVisible" width="90%">
        <!-- :before-close="handleClose" -->
        <div class="elDialogCont">
          <div class="card-body ">
            <div class="ruleForm">
              <el-row :gutter="24">

                <el-col :span="24" class="intervalColBox ">
                  <div class="colTitleBox">分数区间</div>
                  <div class="formItemCont clearfix">
                    <el-form-item prop="typeFloor" class="courseGroup"
                      :rules="[{ required: true, message: '分数底限不能为空', trigger: 'change' }]">
                      <el-input v-model="ruleFormDialog.typeFloor" class="formControl" placeholder="请输入底限"
                        @change="handleChangeInterval($event,'floor')"></el-input>
                    </el-form-item>
                    <div class="divMidFont">至</div>
                    <el-form-item prop="typeCeiling" class="courseGroup"
                      :rules="[{ required: true, message: '分数上限不能为空', trigger: 'change' }]">
                      <el-input v-model="ruleFormDialog.typeCeiling" class="formControl" placeholder="请输入上限"
                        @change="handleChangeInterval($event,'')"></el-input>
                    </el-form-item>
                  </div>
                </el-col>
                <el-col :span="24" class="typeBox">

                  <div v-if="ruleFormDialog.GrowthConfigs.length>0">
                    <SuYanType ref="LiteracyRef" :configList='ruleFormDialog.GrowthConfigs'
                      :defaultConfig='ruleFormDialog.defaultConfigs' @change="handleTypesChange" />
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-6">&nbsp;</div>
              <div class="col-6 text-right">
                <el-button @click="onCloseDialogForm()">取 消</el-button>
                <el-button type="primary" @click="onGetTypeData()">确 定</el-button>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>

    </el-form>

  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import operationBtn from '@/components/operationBtn.vue';
  import SuYanType from '@/components/Admin/suYanLeiXing.vue'
  // import comLiteracyType from '@/components/Admin/LiteracyType.vue';
  export default {
    components: {
      operationBtn,
      SuYanType
      // comLiteracyType
    },
    data() {
      return {

        // 加载动画
        fullscreenLoading: false,
        // 弹出框
        dialogVisible: false,
        // 页面标题
        pageTitle: '新增课程信息',
        semesterList: [],
        classList: [],
        ruleForm: {
          courseId: '', //ID
          name: '', //
          courseType: '', //课程类型ID
          courseTypeName: '',
          isCourse: false,
          semesterId: null,
          classId: null,
          configs: [], //提交后的综合类型
        },
        // 弹出框集合
        ruleFormDialog: {
          defaultConfigs: [],
          courseType: '', //课程类型ID
          GrowthConfigs: [], //绑定时的综合类型
          typeLevelName: '',
          typeFloor: 0, //底限
          typeCeiling: 0, //上限
        },

        // 验证
        rules: {
          name: [{
              required: true,
              message: '课程名称不能为空',
              trigger: 'blur'
            },
            {
              max: 256,
              message: '课程名称不能超过256个字符',
              trigger: 'blur'
            },
          ],
          courseType: [{
            required: true,
            message: '请选择课程类型',
            trigger: 'change'
          }],
          classId: [{
            required: true,
            message: '请选择班级',
            trigger: 'change'
          }],
          semesterId: [{
            required: true,
            message: '请选择学期',
            trigger: 'change'
          }]
        },


        // 重置时的表单数据
        ruleFormReset: null,
        // 类型
        optCourseType: [],
        // false:新增 | true:编辑
        isType: false,
        // 年级
        optGrades: [],
        // 专业
        optMajors: [],
      };
    },
    methods: {
      handleTypesChange(val) {
        let that = this
        that.ruleFormDialog.GrowthConfigs = val
      },
      // 获取 综合类型
      onGetOriginalData(that) {
        let apiUrl = '/api/admin/GrowthConfig'; ///treelist
        that.fullscreenLoading = true;
        that.ruleFormDialog.GrowthConfigs = [];
        ajaxGet(that, apiUrl, null, function(obj) {
          that.fullscreenLoading = false;
          that.ruleFormDialog.GrowthConfigs = obj
        });
      },
      // 弹出框数据提交
      onGetTypeData() {
        let that = this
        let isError = false
        let arrTemp = [];
        that.$refs.LiteracyRef.validateForm((flag) => {
          isError = flag
        })
        that.$refs['ruleFormDialog'].validate((valid) => {
          if (valid && isError) {
            // 处理 底限和上限 当用户次序填错了,将其更改回来
            let iFloor = setTwoDecimal(setTwoFloat(that.ruleFormDialog.typeFloor), 1);
            if (iFloor == '') {
              iFloor = 0;
            }
            let iCeiling = setTwoDecimal(setTwoFloat(that.ruleFormDialog.typeCeiling), 1);
            if (iCeiling == '') {
              iCeiling = 0;
            }
            if (iFloor <= 0 && iCeiling <= 0) {
              warning(that, '分数区间底限和上限不能同时为0');
              return false;
            }
            if (iFloor / 100 > iCeiling / 100) {
              let iTemp = iFloor;
              iFloor = iCeiling;
              iCeiling = iTemp;
            }
            // 循环制作列表所需要的数据
            for (let item of that.ruleFormDialog.GrowthConfigs) {
              if (item.isSelect) {
                let obj = {
                  floor: iFloor,
                  ceiling: iCeiling,
                  configId: item.configId,
                  configName: item.name,
                  score: item.score,
                }
                arrTemp.push(obj)
              }
            };
            // 验证是否 配置了综合类型
            if (arrTemp.length <= 0) {
              warning(that, '请配置综合类型');
              return false;
            } else {
              let arr = {
                tbID: onGetRandomGUID(),
                typeLevelName: that.ruleFormDialog.typeLevelName,
                typeFloor: iFloor,
                typeCeiling: iCeiling,
                children: JSON.parse(JSON.stringify(arrTemp))
              }
              that.ruleForm.configs.push(JSON.parse(JSON.stringify(arr)));
              that.onResetForm('ruleFormDialog');
              that.dialogVisible = false;
              that.ruleForm.isCourse = true;
            }
          } else {
            return false;
          }
        });
      },
      // 将分数转换成浮点型
      handleInput(_ID) {
        let that = this;
        for (let item of that.ruleFormDialog.GrowthConfigs) {
          for (let child of item.children) {
            if (child.configId == _ID) {
              let iScore = setTwoDecimal(setTwoFloat(child.score));
              child.score = (iScore.length > 0) ? iScore : 0;
              return;
            }
          }
        }
      },
      //关闭弹出窗
      handleClose(done) {
        let that = this;
        that.$confirm('确认关闭？').then(_ => {
          that.ruleFormDialog = JSON.parse(JSON.stringify(that.ruleFormReset));
          that.onResetForm('ruleFormDialog');
          done();
        }).catch(_ => {});
      },
      // 区间验证
      handleChangeInterval(obj, _type) {
        let that = this;
        if (_type == 'floor') {
          that.ruleFormDialog.typeFloor = setTwoDecimal(setTwoFloat(obj), 1);
        } else {
          that.ruleFormDialog.typeCeiling = setTwoDecimal(setTwoFloat(obj), 1);
        }
      },
      // 打开弹出框
      onOpenDialogForm() {
        let that = this;

          that.dialogVisible = true;
          that.ruleFormDialog.typeLevelName = null;
          try {
            that.onResetForm('ruleFormDialog');
          } catch {}
          that.onGetOriginalData(that);

      },
      // 关闭弹出框
      onCloseDialogForm() {
        let that = this;
        that.dialogVisible = false;
      },
      // 返回
      onBack() {
        let that = this;
        back(that);
      },
      // 重置
      onReset() {
        let that = this;
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
        try {
          that.onResetForm('ruleForm');
        } catch {}
      },
      // 删除配置
      onRemove(_dataID) {
        let that = this;
        let arr = that.ruleForm.configs.filter((item) => {
          return item.tbID != _dataID;
        });
        that.ruleForm.configs = JSON.parse(JSON.stringify(arr));
        if (that.ruleForm.configs.length <= 0) {
          that.ruleForm.isCourse = false;
        }
      },
      // 关闭验证
      onResetForm(objForm) {
        let that = this;
        that.$refs[objForm].resetFields();
      },
      //验证表单
      onVerification(objForm) {
        let that = this;
        // 验证
        that.$refs[objForm].validate((valid) => {
          if (valid) {
            that.onPostData(that);
          } else {
            return false;
          }
        });
      },

      // 提交
      onPostData(that) {
        that.fullscreenLoading = true;
        // API
        let apiUrl = '/api/admin/academiccourse';
        let _data = {
          code: that.ruleForm.code,
          name: that.ruleForm.name,
          classId: that.ruleForm.classId,
          semesterId: that.ruleForm.semesterId,
          configs: []
        };

        if(!regNull(that.ruleForm.courseType)){
          _data.courseType= setInt(that.ruleForm.courseType);
        }

        that.ruleForm.configs.forEach((item) => {
          item.children.forEach((child) => {
            let arr = {
              "name": child.name,
              "floor": child.floor,
              "ceiling": child.ceiling,
              "configId": child.configId,
              "score": child.score
            }
            _data.configs.push(JSON.parse(JSON.stringify(arr)));
          });
        });
        if (!regNull(that.ruleForm.courseId)) {
          _data.courseId = that.ruleForm.courseId
          apiUrl = apiUrl + '/' + that.ruleForm.courseId;
          ajaxPut(that, apiUrl, _data, function(res) {
            that.fullscreenLoading = false;
            if (res.result) {
              that.onBack();
            } else {
              warning(that, res.message);
            }

          });
        } else {
          ajaxPost(that, apiUrl, _data, function(res) {
            that.fullscreenLoading = false;
            that.onBack();
          });
        }
      },

      /**
       * @description 获取年级数据
       */
      onGetGrades(that) {
        that.fullscreenLoading = true;
        ajaxGet(that, '/api/admin/course/grade', null, function(resData) {
          that.optGrades = resData;
          that.fullscreenLoading = false;
        });
      },
      /**
       * @description 获取专业
       * @param {Object} that
       */
      onGetMajor(that) {
        that.fullscreenLoading = true;
        ajaxGet(that, '/api/admin/major', null, function(resData) {
          that.optMajors = [];
          that.optMajors.push({
            majorId: -1,
            name: '全部',
            disabled: false,
          });
          for (let item of resData) {
            that.optMajors.push({
              majorId: item.majorId,
              name: item.name,
              disabled: false,
            });
          }

          that.fullscreenLoading = false;
        });
      },
      // 获取编辑数据
      onGetEditData(that) {

      },
      // 去重复值
      onArrUnique(arr) {
        const res = new Map();

        let temp = arr.filter((item) => {
          item.typeLevelName = String(item.typeFloor) +String(item.typeCeiling);
          return item;
        });
        let arrTemp = temp.filter((arr) => !res.has(arr.typeLevelName) && res.set(arr.typeLevelName, 1));
        temp = arrTemp.filter((item) => {
          item.typeLevelName = '';
          return item;
        });
        return temp;

      },
      // 整理需要编辑的综合配置数据 this,原数据,课程类型,等级,底限,上限
      onGetEditChildrenData(that, _data, _Name, _Floor, _Ceiling) {
        let arrTemp = [];
        _data.configs.forEach((item, index) => {

          if (item.floor == _Floor && item.ceiling == _Ceiling) {
            let arr = {
              name: item.name,
              floor: item.floor,
              ceiling: item.ceiling,
              configId: item.configId,
              configName: item.configName,
              score: item.score,
            }
            arrTemp.push(arr);
          }

        });
        return arrTemp;
      }
    },

    created() {
      let that = this;
      that.isType = false;

      that.fullscreenLoading = true;
      ajaxGet(that, '/api/admin/course/type', null, function(resType) {
        ajaxGet(that, "/api/admin/semester", null, function(resSemester) {
          ajaxGet(that, "/api/admin/sysuser/userfilternograduate", null, function(resClass) {
            that.classList = resClass;
            that.semesterList = resSemester
            that.optCourseType = resType;
            that.fullscreenLoading = false;
            //判断是否有传值过来
            if (!regNull(that.$route.params.id)) {
              that.pageTitle = "编辑课程信息";
              that.ruleForm.courseId = that.$route.params.id;
              that.isType = true;
              that.fullscreenLoading = true;
              ajaxGet(that, '/api/admin/academiccourse/' + that.$route.params.id, null, function(resData) {
                that.ruleForm.name = resData.name;
                that.ruleForm.courseType =regNull(resData.courseType)?null: String(resData.courseType);
                that.ruleForm.classId = resData.classId
                that.ruleForm.semesterId = resData.semesterId
                that.ruleForm.isCourse = regNull(resData.courseType) ? false : true;

                let arrTemp = [];
                resData.configs.forEach((item, index) => {
                  arrTemp.push({
                    tbID: onGetRandomGUID(),
                    typeLevelName: item.name,
                    typeFloor: item.floor,
                    typeCeiling: item.ceiling,
                    children: that.onGetEditChildrenData(that, resData,item
                      .name, item.floor, item.ceiling, )
                  });
                })
                that.ruleForm.configs = JSON.parse(JSON.stringify(that.onArrUnique(arrTemp)));
                that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
                that.fullscreenLoading = false;
              })
            } else {
              that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
            }
          });
        });
      });






    },
  };
</script>

<style scoped="scoped">
  .elDialogCont {
    margin: -30px -20px;
  }

  .elDialogCont .card-body {
    padding: 30px 40px;
  }

  .borderBox {}

  .typeBox .lblTypeName {
    background-color: #409eff;
    color: #FFFFFF;
    display: inline-block;
    width: 100%;
    text-align: center;
  }
</style>
<style>
  .typeBox .el-form-item__label {
    width: 100%;
    float: inherit;
  }

  .typeBox .inputGroupCont .el-checkbox {
    margin-bottom: 0;
  }

  .intervalColBox .colTitleBox {
    font-size: 14px;
    color: #606266;
    font-weight: 700;
    padding-bottom: 5px;
  }

  .intervalColBox .formItemCont {}

  .intervalColBox .formItemCont .courseGroup {
    float: left;
  }

  .intervalColBox .formItemCont .divMidFont {
    float: left;
    color: #495057;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    line-height: 40px;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: bold;
    height: 40px;
    position: relative;
    z-index: 10;
    margin-left: -5px;
    margin-right: -5px;
  }
</style>
