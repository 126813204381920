<!--社会实践-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <search :SearchTitles="SearchTitles" :ColNumber="3" :SearchList="SearchList" ref="SearchList" @onSearch="onSearch"
      :key="random2" v-if="SearchList!=null"></search>

    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          {{currentActivityType}}
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <el-button v-if="isPower.activity_create == true" type="success" icon="el-icon-circle-plus-outline"
                @click="onAdd()">新增</el-button>
            </li>

          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="item!=null && item.length!=0">
        <el-table stripe :data="item" class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="name" label="名称" v-show="isField.isName==true" min-width="300"
            :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <span class="spIcon spBan">{{scope.row.level}}</span>
              {{scope.row.name}}
            </template>
          </el-table-column>
          <el-table-column prop="place" label="地点" v-show="isField.isPlace==true" min-width="200"
            :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <span>{{scope.row.place}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="startDate" label="活动时间" min-width="320" v-if="isField.isTime==true">
            <template slot-scope="scope">
              <span>{{scope.row.startDate | dateformatMinute}} 至 {{scope.row.endDate | dateformatMinute}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态" min-width="140">
            <template slot-scope="scope">
              {{scope.row.arrOperate.statusName}}
            </template>
          </el-table-column>
          <el-table-column label="操作" :width="200" fixed="right">
            <template slot-scope="scope">
              <operationBtn v-if="isPower.activity_details" :btnName="'查看'"
                @click.native="onDetail(scope.row.activityId)"></operationBtn>

              <!-- 代报名 活动报名开始后 到 活动时间开始前 显示 -->
              <operationBtn v-if="isPower.activity_delegate && scope.row.arrOperate.isBtnReplace" :btnType="'info'"
                :btnName="'代报名'" @click.native="onDelegate(scope.row.activityId)"></operationBtn>
              &nbsp;
              <el-dropdown
                v-if="scope.row.arrOperate.isBtnShow && (scope.row.arrOperate.isBtnImportScope || scope.row.arrOperate.isBtnConfirm || scope.row.arrOperate.isBtnAwards || scope.row.arrOperate.isBtnFinally || scope.row.arrOperate.isBtnEdit || scope.row.arrOperate.isBtnDelete || scope.row.arrOperate.isBtnRevoke)"
                @command="handleCommand($event,scope.row.activityId,scope.row)">
                <el-button size="mini">
                  更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown" style="width:140px;">
                  <el-dropdown-item
                    v-if="isPower.activity_audit && scope.row.arrOperate.isBtnImportScope && scope.row.isAdmin"
                    class="color-primary" command="onRange">导入报名范围</el-dropdown-item>
                  <el-dropdown-item
                    v-if="isPower.activity_audit && scope.row.arrOperate.isBtnConfirm  && scope.row.isAdmin"
                    command="onConfirm">确认名单</el-dropdown-item>

                  <el-dropdown-item
                    v-if="isPower.activity_prize && scope.row.arrOperate.isBtnAwards && scope.row.isAdmin"
                    command="onPrize">评奖</el-dropdown-item>
                  <el-dropdown-item v-if="isPower.activity_img_audit && scope.row.isAdmin && scope.row.isUpload"
                    class="color-warning" command="onAudit">审核附件</el-dropdown-item>
                  <el-dropdown-item
                    v-if="isPower.activity_audit && scope.row.arrOperate.isBtnFinally  && scope.row.isAdmin"
                    class="color-primary" command="onRoster">计入综合分</el-dropdown-item>
                  <el-dropdown-item v-if="isPower.activity_summarize  && scope.row.isAdmin" class="color-primary"
                    command="onSummarize">活动总结</el-dropdown-item>
                  <el-dropdown-item v-if="isPower.activity_edit && scope.row.arrOperate.isBtnEdit  && scope.row.isAdmin"
                    class="color-warning" command="onEdit">编辑</el-dropdown-item>
                  <el-dropdown-item class="color-warning" command="onImportPhoto"
                    v-if="scope.row.isAdmin">上传活动照片</el-dropdown-item>
                  <el-dropdown-item
                    v-if="isPower.activity_delete && scope.row.arrOperate.isBtnDelete  && scope.row.isAdmin"
                    class="color-danger" command="onDelete">删除</el-dropdown-item>
                  <el-dropdown-item
                    v-if="isPower.activity_revoke && scope.row.arrOperate.isBtnRevoke  && scope.row.isAdmin"
                    class="color-gray" command="onRevoke">撤销</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>

        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>

    </el-card>


    <el-dialog title="上传活动照片" :visible.sync="dialogVisible" :close-on-click-modal="false" :close-on-press-escape="false"
      width="80%" :before-close="handleClose">
      <div>
        <comUpload v-if="dialogVisible" :key="random" @onPictureHandleSuccess="onPhotoHandleSuccess"
          :files="uploadPhotoFiles" :pictureMaxCount="30"></comUpload>
      </div>
      <div style="text-align:center;margin-top:20px;">
        <el-button @click="handleClose">取 消</el-button>
        <el-button @click="onImportReset">重 置</el-button>
        <el-button type="primary" @click="onImportSave">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';
  import comUpload from '@/components/Upload.vue';
  export default {
    components: {
      comPage,
      operationBtn,
      search,
      comUpload
    },
    data() {
      return {
        dialogVisible: false,
        uploadInfoApi: '/api/admin/activity/import', //导入url
        currentActiveTypeId: {},
        SearchTitles: [],
        SearchList: null, //搜索集合
        currentId: null, //当前活动类型主键
        parentType: null, //父级活动类型主键
        currentChild: null, //当前子级活动类型主键
        currentActivityType: null, //当前活动类型
        activityTypes: [], //活动类型集合
        childActivityTypes: [], //子级活动类型集合
        fullscreenLoading: false,
        page: null, //分页查询接口返回的结果
        item: [], //列表数据
        pageBtnList: null, //分页左侧按钮集合
        paging: { //分页数据
          pageLength: 0, // 总共页数
          Size: 10, // 当前请求数量
          Index: 1 //当前在第几页
        },
        isPower: {
          //增删改查 权限管理
          activity_create: false,
          activity_details: false,
          activity_edit: false,
          activity_delete: false,
          activity_revoke: false,
          activity_audit: false,
          activity_prize: false,
          activity_delegate: false,
          activity_img_audit: false,
          activity_import: false,
        },
        isField: {
          //字段是否需要显示
          isName: false, //活动名称
          isPlace: false, //活动地点
          isTime: false, //活动时间
          isLevel: false, //活动类型
        },
        currentUser: '',
        thWidth: 100,
        ruleForm: {
          id: null,
          photo: null,
        },
        ruleFormReset: null,
        random: 0,
        random2: 0,
        uploadPhotoFiles: [],
        uploadPhotoFilesReset: [],
      };
    },
    created() {
      let that = this;
      isPower(that, "activity_create", "activity_create");
      isPower(that, "activity_details", "activity_details");
      isPower(that, "activity_edit", "activity_edit");
      isPower(that, "activity_delete", "activity_delete");
      isPower(that, "activity_revoke", "activity_revoke");
      isPower(that, "activity_audit", "activity_audit");
      isPower(that, "activity_prize", "activity_prize");
      isPower(that, "activity_delegate", "activity_delegate");
      isPower(that, "activity_img_audit", "activity_img_audit");
      isPower(that, 'activity_import', 'activity_import')
      Init(that);

      that.currentUser = that.$GetKey('currentUserID');

      that.thWidth = onGetWidth(that, [
        that.isPower.activity_details,
        that.isPower.activity_delegate,
        that.isPower.activity_audit || that.isPower.activity_prize || that.isPower.activity_img_audit || that
        .isPower.activity_summarize ||
        that.isPower.activity_edit || that.isPower.activity_delete || that.isPower.activity_revoke
      ]) + 20;
    },
    methods: {
      onImportSave() {
        let that = this;
        that.fullscreenLoading = true;
        ajaxPut(that, "/api/admin/activity/uploadphoto/" + that.ruleForm.id + "?photo=" + that.ruleForm.photo, null,
          function(res) {
            that.fullscreenLoading = false;
            that.dialogVisible = false;
            Init(that);
          })
      },
      onImportReset() {
        let that = this;
        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
        that.uploadPhotoFiles = JSON.parse(JSON.stringify(that.uploadPhotoFilesReset));
      },
      /**
       * @description 活动图片上传成功后事件
       * @param {Object} param
       */
      onPhotoHandleSuccess(param) {
        let that = this;
        that.ruleForm.photo = "";
        for (var i in param) {
          if (that.ruleForm.photo == "") {
            that.ruleForm.photo = param[i].path;
          } else {
            that.ruleForm.photo += "|" + param[i].path;
          }
        }
      },
      handleClose() {
        let that = this;
        that.dialogVisible = false;
      },
      onImportPhoto(_valueID, _value) {
        let that = this;
        that.ruleForm.id = _valueID;
        that.ruleForm.photo = _value.photo;
        that.uploadPhotoFiles = [];
        if (_value.photo != null && typeof(_value.photo) != "undefined" && _value.photo != "") {
          var files = _value.photo.split('|');
          for (var i in files) {
            var it = i + 1;
            that.uploadPhotoFiles.push({
              uid: it,
              path: files[i],
            });
          }
        }

        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;
        that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
        that.uploadPhotoFilesReset = JSON.parse(JSON.stringify(that.uploadPhotoFiles));
        that.dialogVisible = true;
      },
      /**
       * @description 更多下接中的事件
       * @param {Object} _type 事件类型
       * @param {Object} _valueID  ID
       */
      handleCommand(_type, _valueID, _value) {
        let that = this;
        switch (_type) {
          //导入报名范围
          case 'onRange':
            that.onRange(_valueID);
            break;
            //确认名单
          case 'onConfirm':
            that.onConfirm(_valueID);
            break;
            // 代报名
          case 'onDelegate':
            that.onDelegate(_valueID);
            break;
            // 评奖
          case 'onPrize':
            that.onPrize(_valueID);
            break;
            // 计入综合分
          case 'onRoster':
            that.onRoster(_valueID);
            break;
            // 编辑
          case 'onEdit':
            that.onEdit(_valueID);
            break;
            // 上传活动照片
          case 'onImportPhoto':
            that.onImportPhoto(_valueID, _value);
            break;
            // 删除
          case 'onDelete':
            that.onDelete(_valueID);
            break;
            // 撤销
          case 'onRevoke':
            that.onRevoke(_valueID);
            break;
            // 审核附件
          case 'onAudit':
            that.onAudit(_valueID);
            break;
        }
      },



      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },
      //对比是否在审核人名单内
      onGetIsReviewer(_valArr) {
        // let that=this;
        // let myID=that.$GetKey('currentUserID');
        // if(_valArr!=null && _valArr.length>0){
        //   if(_valArr.find(val=> val.teacherId==myID)==undefined){
        //     return false;
        //   }else{
        //     return true;
        //   }
        // }else{
        //   return false;
        // }
      },

      /**
       * @description 审核附件
       */
      onAudit(valID) {
        let that = this;
        that.$router.push({
          path: "/Admin/activity/AuditAT/" + valID,
          query: {
            type: that.currentId
          }
        });
      },
      onAdd() {
        let that = this;
        that.$router.push({
          path: "/Admin/activity/create/" + that.parentType,
          query: {
            type: that.currentId
          }
        });
      },
      onEdit(Id) {
        let that = this;
        that.$router.push({
          path: "/Admin/activity/Edit/" + that.parentType + "/" + Id,
          query: {
            url: that.$router.history.current.fullPath,
            type: that.currentId
          }
        });
      },
      onRoster(Id) {
        let that = this;
        this.$router.push({
          path: "/Admin/activity/Roster/" + that.parentType + "/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onPrize(Id) {
        let that = this;
        this.$router.push({
          path: "/Admin/activity/prize/" + that.parentType + "/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      /**
       * @description 代报名
       * @param {Object} Id
       */
      onDelegate(Id) {
        let that = this;
        this.$router.push({
          path: "/Admin/Activity/Delegate/" + that.parentType + "/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onRange(Id) {
        let that = this;
        this.$router.push({
          path: "/Admin/activity/range/" + that.parentType + "/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onConfirm(Id) {
        let that = this;
        this.$router.push({
          path: "/Admin/activity/confirm/" + that.parentType + "/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onDetail(Id) {
        let that = this;
        this.$router.push({
          path: "/Admin/activity/detail/" + that.parentType + "/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onDelete(Id) {
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, "/api/admin/Activity/" + Id, null, function(r) {
              that.fullscreenLoading = false;
              Init(that);
            });
          }
        })
      },
      onRevoke(Id) {
        let that = this;
        confirmDelete(that, "撤销后将不能对该活动进行任何操作，您确定撤销吗？", function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxPut(that, "/api/admin/Activity/Revoke/" + Id, null, function(r) {
              that.fullscreenLoading = false;
              Init(that);
            });
          }
        })
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      onSearch(params) {
        let that = this;
        let data = {};
        let searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = Join(element.data);
          }
        });
        data.PageNumer = 1;

        console.log(that.currentId)

        return 
        routerPath(that, "/Admin/activity/Index/" + that.currentId, data, null, "PageSize");
      },
      pagingClick(type, val) {
        let that = this;
        let url = "/Admin/activity/Index/" + that.currentId;
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "Name",
            "Place",
            "ClassId",
            "StudentName",
            "Time",
            "LevelId"
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "Name",
            "Place",
            "ClassId",
            "StudentName",
            "Time",
            "LevelId"
          );
        }
      },
    },
    watch: {
      $route() {
        let that = this;
        Init(that);
      },
    },
  };

  function Init(that) {
    that.activityTypes = JSON.parse(that.$GetKey("activityTypes"));
    that.parentType = that.$route.params.type;
    that.currentChild = that.$route.query.id;
    var count = 0;
    that.currentId = that.$route.params.type;
    if ((that.currentChild != null && typeof(that.currentChild) != "undefined") && that.currentChild != "") {
      that.currentId = that.currentChild;
    } else {
      for (var it of that.activityTypes) {
        if (it.id == that.parentType && it.children != null && it.children.length > 0) {
          if (count <= 0) {
            that.currentId = it.children[0].id;
            count++;
          }
        }
      }
    }

    count = 0;
    for (var it of that.activityTypes) {
      if ((that.currentChild == null || typeof(that.currentChild) == "undefined")) {
        if (it.id == that.parentType && it.children != null && it.children.length > 0) {
          for (var child of it.children) {
            if (count <= 0) {
              that.currentChild = child.id;
              count++;
            }
          }
        }
      }

      if (it.id == that.parentType) {
        that.childActivityTypes = it.children;
        that.currentActivityType = it.name;

        that.SearchTitles = [];
        if (that.childActivityTypes != null && that.childActivityTypes.length > 0) {
          for (var it1 of that.childActivityTypes) {
            var isCurrent = it1.id == that.currentChild ? true : false;
            if (isCurrent == true) {
              that.currentActivityType = it1.name;
            }
            that.SearchTitles.push({
              name: it1.name,
              isCurrent: isCurrent,
              url: '/admin/activity/index/' + it1.parentId + '?id=' + it1.id
            });
          }
        }
      }
    }
    pagingPage(that);
  }

  function pagingPage(that) {
    paging(
      that,
      function(resParam) {
        // 加载动画
        that.fullscreenLoading = true;
        // 活动名称
        that.isField.isName = false;
        // 活动地点
        that.isField.isPlace = false;
        // 活动时间
        that.isField.isTime = false;
        //活动类型
        that.isField.isLevel = false;

        // 获取活动类型
        ajaxGet(that, "/api/admin/activitytype/" + that.currentId, null, function(types) {
          // 判断活动类型数据
          if (types.fieldConfigs != null && types.fieldConfigs.length > 0) {
            // 根据活动的配置进行显示
            for (var field of types.fieldConfigs) {
              if (field.name == 1) {
                that.isField.isName = true;
              } else if (field.name == 2) {
                that.isField.isPlace = true;
              } else if (field.name == 3) {
                that.isField.isLevel = true;
              } else if (field.name == 5) {
                that.isField.isTime = true;
              }
            }
          }

          // 获取活动层级
          ajaxGet(that, "/api/admin/activitylevel", null, function(types) {
            // 获取当前教师的学生范围
            ajaxGet(that, "/api/admin/sysuser/userfilter", null, function(resultClass) {
              // 获取学生范围内的班级列表
              var classList = [];
              for (var i in resultClass) {
                var it = {
                  Title: resultClass[i].alias,
                  Value: resultClass[i].classId,
                };
                classList.push(it);
              }
              // 设置活动层级
              for (var type of types) {
                type.Title = type.name;
                type.Value = type.levelId;
              }
              // 搜索参数
              that.SearchList = [];
              // 根据配置判断是否显示活动名称
              if (that.isField.isName == true) {
                that.SearchList.push({
                  type: "input",
                  Name: "活动名称",
                  data: resParam.Name,
                  holder: "请输入活动名称",
                  zhi: "Name"
                });
              }
              // 根据配置判断是否显示活动地点
              if (that.isField.isPlace == true) {
                that.SearchList.push({
                  type: "input",
                  Name: "地点",
                  data: resParam.Place,
                  holder: "请输入地点",
                  zhi: "Place"
                }, );
              }
              // 根据配置判断是否显示活动时间
              if (that.isField.isTime == true) {
                that.SearchList.push({
                  type: "time",
                  Name: "活动时间",
                  data: resParam.Time,
                  holder: "请输入活动名称",
                  zhi: "Time"
                }, );
              }
              // 根据配置判断是否显示活动类型
              if (that.isField.isLevel == true) {
                that.SearchList.push({
                  type: "select",
                  Name: "活动层级",
                  data: resParam.LevelId,
                  select: types,
                  zhi: "LevelId"
                }, );
              }
              let activityDate = [];
              if (resParam.Time != null) {
                activityDate = resParam.Time.split(',');
              }
              let StartDate = activityDate != null && activityDate.length == 2 ? dateFormat(activityDate[
                0], '-', 'YYYY-MM-DD') : null;
              let EndDate = activityDate != null && activityDate.length == 2 ? dateFormat(activityDate[1],
                '-', 'YYYY-MM-DD') : null;
              // 设置搜索参数
              let data = {
                typeId: that.currentId,
                PageNumer: resParam.PageNumer,
                PageSize: resParam.PageSize,
                Name: resParam.Name,
                LevelId: resParam.LevelId,
                StartDate: StartDate,
                EndDate: EndDate,
                Place: resParam.Place,
              }
              that.paging.Index = resParam.PageNumer;
              that.paging.Size = resParam.PageSize;
              // 获取当前用户信息
              ajaxGet(that, '/api/admin/sysuser/currentuser', null, function(resUser) {
                // 获取活动信息
                ajaxGet(that, '/api/admin/activity/search', data, function(resData) {

                  ajaxGet(that, '/api/admin/class/userclass', null, function(resMyClass) {

                    // 分页数据
                    that.page = resData;
                    // 赋值
                    //that.item = resData.items;

                    /*
                    可报名的活动流程：
                    导入报名范围（状态是待报名，且没有选择报名范围）->确认名单（报名结束，活动开始前）->确认最终名单（活动结束）
                    不报名但需要学生上传文件的活动流程：导入参与范围（状态是待报名）->确认最终名单（活动结束）
                    */

                    // 开始数据循环处理
                    that.item = [];
                    for (let it of resData.items) {
                      that.item.push({
                        photo: it.photo,
                        level: it.level,
                        name: it.name,
                        place: it.place,
                        startDate: it.startDate,
                        endDate: it.endDate,
                        statusString: it.statusString,
                        activityId: it.activityId,
                        isUpload: it.isUpload,
                        isAdmin: it.auditors.find(val => val.teacherId == resUser
                          .userId) != undefined ? true : false, // 是否为审核人员
                        arrOperate: onGetActivityStatus(
                          it.isApply, // 是否需要报名
                          it.status, // 活动当前状态
                          it.isImport, //是否导入报名范围
                          it.isUpload, //是否上传
                          it.applyStartDate, //报名开始时间
                          it.applyEndDate, //报名结束时间
                          it.startDate, //活动开始时间
                          it.endDate, //活动结束时间
                          it.uploadDeadline, //学生上传文件截止时间
                          it.auditors.find(val => val.teacherId == resUser.userId) !=
                          undefined ? true : false, //是否为审核员
                          it.isDelegate ? it.ranges.find(val => resMyClass.find(list =>
                            list.name == val.className) != undefined) != undefined ?
                          true : false : false, //是否可以代报名
                          false, //是否为学生
                          false, //是否可参加活动
                          0, //名单状态
                          it.isAttendance, //是否需要签到
                          false, //学生签到情况
                          it.isApplyAudit, //是否需要报名审核
                        )
                      });
                    }
                    that.fullscreenLoading = false;

                    let random2 = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
                    that.random2 += random2;
                  });
                });
              });
            });
          });
        });

      },
      "Name",
      "Place",
      "ClassId",
      "StudentName",
      "Time",
      "LevelId"
    );
  }
</script>

<style scoped="scoped">
  .navTabsCont .nav-link {
    font-size: 12px;
  }

  .navToolsCont {
    margin-top: 1px;
  }

  .btn-mini {
    margin-left: 15px;
    margin-right: 5px;
    height: 40px;
    line-height: 25px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 14px;
  }

  .spIcon {
    background-color: #F8F9FA;
    font-size: 14px;
    padding: 2px 5px;
  }

  .spBan {
    color: #409EFF;
  }

  .spBu {
    color: #FAC10B;
  }

  .spXiao {
    color: #F6402C;
  }
</style>

<style>
  .el-dropdown {
    vertical-align: top;
  }

  .el-dropdown+.el-dropdown {
    margin-left: 15px;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }
</style>
