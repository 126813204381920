<!--
  基础信息-班级管理
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">


    <search :ColNumber="4" :SearchList="SearchList" ref="SearchList" @onSearch="onSearch"
      v-if="!regNullArray(SearchList)"></search>

    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          班级信息
        </h3>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table :data="itemData" stripe class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="teacher" label="班主任" class="elColumn" min-width="120"
            :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="year" label="入学年份" class="elColumn" width="100"></el-table-column>
          <el-table-column prop="alias" label="班级名称" class="elColumn" min-width="140"
            :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="college" label="所属年级" class="elColumn" min-width="140"
            :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="statusStr" label="状态" class="elColumn" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.status!=0">{{scope.row.statusStr}}</span>
              <span v-else class="color-LightGray">暂未设置</span>
            </template>
          </el-table-column>
          <el-table-column prop="xz" label="学制（年）" class="elColumn" width="120"></el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <operationBtn :btnName="'查看'" ref="detail" @click.native="onDetail(scope.row.classId)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick"
          v-if="!regNull(page)">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';
  export default {
    components: {
      comPage,
      operationBtn,
      search,
    },
    data() {
      return {
        dialogVisible: false,
        currentDetail: null,
        classStatus: [], //班级状态列表
        status: null, //班级状态
        yearOpt: [], //年纪搜索
        collegeOpt: [], //年级opt
        //加载动画
        fullscreenLoading: false,
        //搜索
        SearchList: null,
        //分页查询接口返回的结果
        page: null,
        //列表数据
        itemData: [],
        //分页左侧按钮集合
        pageBtnList: null,
        //分页数据
        paging: {
          // 总共页数
          pageLength: 0,
          // 每页请求的数据量
          Size: 10,
          //当前在第几页
          Index: 1
        },
        isPower: {
          //增删改查 权限管理
        },

        TeacherInfo: {}, // 教师信息
      };
    },
    computed: {
      getTitle() {
        let tipStr = ''
        if (this.currentDetail) {
          tipStr = `${this.currentDetail.name}`
        }
        return tipStr
      }
    },
    methods: {
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },
      // 搜索
      onSearch(params) {
        let that = this;
        let data = {};
        let searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = element.data;
          }
        });
        data.PageNumer = 1;

        routerPath(that, "/Admin/GrowthClass/Index", data, null, "PageSize");
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      // 分页面按钮
      pagingClick(type, val) {
        let that = this;
        let url = "/Admin/GrowthClass/Index";
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "Name",
            "Teacher",
            "year",
            "college"
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "Name",
            "Teacher",
            "year",
            "college"
          );
        }
      },

      // 详情
      onDetail(objID) {
        let that = this;
        that.$router.push({
          path: "/Admin/GrowthClass/Details/" + objID,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
    },
    created() {
      let that = this;
      that.TeacherInfo = onGetNowUser(that);
      that.fullscreenLoading = true;
      ajaxGet(that, '/api/admin/class/status', null, function(res) {
        that.classStatus = res;
        that.yearOpt = getYear(5);
        ajaxGet(that, '/api/admin/college', null, function(res1) {
          if (res1) {
            that.collegeOpt = res1.map(item => {
              return {
                Title: item.name,
                Value: item.collegeId
              }
            });
          }
          that.fullscreenLoading = false
          onGetDataPage(that);
        })
      })
    },
    watch: {
      $route() {
        let that = this;
        onGetDataPage(that);
      }
    }
  };

  function getYear(num) {
    let nowYear = new Date().getFullYear() + 1;
    let yearArr = [];
    for (let i = 1; i <= num; i++) {
      let year = {};
      year.Title = `${nowYear - i}学年`;
      year.Value = nowYear - i;
      yearArr.push(year);
    }
    return yearArr;
  }

  function onGetDataPage(that) {
    paging(
      that,
      function(r) {

        if (that.TeacherInfo.isTeacher) {
          that.SearchList = [];
          if (that.TeacherInfo.manageClass.length > 1) {
            that.paging.Index = 1;
            that.paging.Size = 10;
            that.page = null;
            that.itemData = that.TeacherInfo.manageClass;
          } else {
            that.onDetail(that.TeacherInfo.manageClass[0].classId);
          }

        } else {
          let apiUrl = "/api/admin/class/search";
          that.SearchList = [{
              type: "select",
              Name: "所属年级",
              data: r.college,
              select: that.collegeOpt,
              zhi: "college"
            },
            {
              type: "input",
              Name: "班级信息",
              data: r.Name,
              holder: "请输入班级关键字",
              zhi: "Name"
            },
            {
              type: "input",
              Name: "班主任姓名",
              data: r.Teacher,
              holder: "请输入班主任名称",
              zhi: "Teacher"
            },
            {
              type: "select",
              Name: "入学年份",
              data: r.year,
              select: that.yearOpt,
              zhi: "year"
            },
          ];


          let data = {
            PageNumer: r.PageNumer,
            PageSize: r.PageSize,
            Name: r.Name,
            CollegeId: r.college,
            Teacher: r.Teacher,
            Year: r.year,
          };
          that.paging.Index = r.PageNumer;
          that.paging.Size = r.PageSize;
          that.fullscreenLoading = true;
          ajaxGet(that, apiUrl, data, function(r) {
            that.page = r;
            that.itemData = r.items;
            that.fullscreenLoading = false;
          });
        }


      },
      "Name",
      "Teacher",
      "year",
      "college"
    );
  }

</script>

<style scoped="scoped">
  .dialog-footer {
    text-align: center !important;
    display: flex;
    justify-content: center;
  }

  .class-change-text {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
    display: block;
  }

</style>

