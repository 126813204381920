<!--
  宿舍管理 - 宿舍记录
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">

    <!-- 搜索 -->
    <comSearch :key="random" :ColNumber="3" :SearchList="SearchList" ref="SearchList" @onSearch="onSearch"
      v-if="SearchList!=null"></comSearch>

    <!-- 列表-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          宿舍记录
        </h3>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table :data="itemData" stripe class="elTableData" @sort-change="sortChange">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="studentNo" label="学号" class="elColumn" min-width="120"></el-table-column>
          <el-table-column prop="name" label="姓名" class="elColumn" min-width="140"
            :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="building" label="楼名" class="elColumn" min-width="120"
            :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="floor" label="楼层" class="elColumn" width="80"></el-table-column>
          <el-table-column prop="room" label="房号" class="elColumn" width="80"></el-table-column>
          <el-table-column prop="bed" label="床位" class="elColumn" width="80"></el-table-column>
          <el-table-column label="入住时间" class="elColumn" min-width="190" sortable="custom" prop="checkinDate">
            <template slot-scope="scope">
              {{scope.row.checkinDate | dateformatMinute}}
            </template>
          </el-table-column>
          <el-table-column label="退住时间" class="elColumn" min-width="190" sortable="custom" prop="checkoutDate">
            <template slot-scope="scope">
              <span v-if="scope.row.checkoutDate!=null">
                {{scope.row.checkoutDate | dateformatMinute}}
              </span>
              <span class="spFont" v-else>
                暂未退住
              </span>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>

      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>



  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import comSearch from '@/components/search.vue';

  export default {
    name: '',
    components: {
      comPage,
      comSearch
    },
    data() {
      return {
        //刷新组件
        random: 0,
        // 加载动画
        fullscreenLoading: false,
        // 搜索集合
        SearchList: null,
        //分页查询接口返回的结果
        page: null,
        //列表数据
        itemData: [],
        //分页左侧按钮集合
        pageBtnList: null,
        //分页数据
        paging: {
          // 总共页数
          pageLength: 0,
          // 每页请求的数据量
          Size: 10,
          //当前在第几页
          Index: 1
        },
        OrderBy: null,
        TeacherInfo: {}, // 教师信息
      };
    },
    methods: {
      sortChange(column) {
        //打印看看参数有哪些？
        let that = this;



        //排序默认是从第一页开始
        if (column.prop == 'checkinDate') {
          if (column.order == 'ascending') {
            that.OrderBy = 'CheckinDate_asc';

          } else if (column.order == 'descending') {
            that.OrderBy = 'CheckinDate_desc';

          }
        }
        if (column.prop == 'checkoutDate' && column.order == 'ascending') {

          that.OrderBy = 'CheckoutDate_asc';

        }
        if (column.order == 'descending' && column.prop == 'checkoutDate') {
          that.OrderBy = 'CheckoutDate_desc';

        }
        onGetDataPage(that);

      },
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },
      // 搜索
      onSearch(params) {
        let that = this;
        let data = {};
        let searchData = params;
        searchData.forEach(element => {

          if (element.data) {
            data[element.zhi] = element.data;
          }
        });
        data.PageNumer = 1;
        routerPath(that, "/Admin/DormCheck/Index", data, null, "PageSize");
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      //分页面按钮
      pagingClick(type, val) {
        let that = this;
        let url = "/Admin/DormCheck/Index";
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "Student",
            "ClassId",
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "Student",
            "ClassId",
          );
        }
      },
    },
    created() {
      let that = this;
      that.TeacherInfo = onGetNowUser(that);
      onGetDataPage(that);
    },
    watch: {
      $route() {
        let that = this;
        onGetDataPage(that);
      }
    }
  };

  // 加载数据
  function onGetDataPage(that) {
    paging(
      that,
      function(r) {

        that.fullscreenLoading = true;

        let resClass = that.TeacherInfo.isTeacher ? that.TeacherInfo.manageClass : that.TeacherInfo.allClass;

        // 班级
        let classList = [];
        for (let item of resClass) {
          classList.push({
            Title: item.alias,
            Value: item.classId
          });
        }

        if (that.TeacherInfo.isTeacher) {
          if (regNull(r.ClassId)) {
            r.ClassId = resClass[0].classId;
          }
        }

        that.SearchList = [{
            type: "select",
            Name: "班级",
            data: r.ClassId,
            select: classList,
            zhi: "ClassId",
            isClear: !that.TeacherInfo.isTeacher, //select是否可以清除
          }, {
            type: "input",
            Name: "学生",
            data: r.Student,
            holder: "请输入学号/姓名",
            zhi: "Student"
          },

        ];
        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;
        let data = {
          PageNumer: r.PageNumer,
          PageSize: r.PageSize,
          Student: r.Student,
          ClassId: r.ClassId,
          OrderBy: that.OrderBy,
        };
        that.paging.Index = r.PageNumer;
        that.paging.Size = r.PageSize;
        ajaxGet(that, '/api/admin/check/search', data, function(resData) {


          that.page = resData;
          that.itemData = resData.items;
          that.fullscreenLoading = false;
        });


      },
      "Student",
      "ClassId"
    );
  }

</script>

<style scoped="scoped">
  .spFont {
    color: #CECECE;
  }

</style>

