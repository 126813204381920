import { render, staticRenderFns } from "./Tissue.vue?vue&type=template&id=195ba4bb&scoped=true"
import script from "./Tissue.vue?vue&type=script&lang=js"
export * from "./Tissue.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "195ba4bb",
  null
  
)

export default component.exports