<!--
  学生管理-请假信息
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <search :ColNumber="3" :SearchList="SearchList" :key="random" ref="SearchList" @onSearch="onSearch"
      v-if="SearchList != null"></search>
    <el-card class="card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          搜索
        </h3>
      </div>
      <div class="card-body">
        <el-row :gutter="24">
          <el-col :span="6" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">姓名</span>
            </div>
            <div class="selBodyBox ">
              <el-input v-model="searchForm.Name" placeholder="请输入姓名"></el-input>
            </div>
          </el-col>
          <el-col :span="6" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">设备名称</span>
            </div>
            <div class="selBodyBox ">
              <el-input v-model="searchForm.DeviceName" placeholder="请输入设备名称"></el-input>
            </div>
          </el-col>
          <el-col :span="6" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">班级</span>
            </div>
            <div class="selBodyBox ">
              <el-select v-model="searchForm.classId" placeholder="请选择班级" filterable class="width_100Pie"
                @change="onChangeStudent" clearable>
                <el-option v-for="(item, index) in optClass" :key="index" :label="item.alias" :value="item.classId">
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="6" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">学生</span>
            </div>
            <div class="selBodyBox ">
              <el-select v-model="searchForm.studentId" placeholder="请选择学生" filterable class="width_100Pie"
                 clearable>
                <el-option v-for="(item, index) in optStudent" :key="index" :label="item.name" :value="item.studentId">
                </el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="card-footer text-center">
        <el-button type="primary" class="mr-2" @click="onSearch()">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#fax-search"></use>
          </svg>
          查询
        </el-button>
        <el-button class="ml-2" @click="onReset()">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#fax-redo-alt"></use>
          </svg>
          重置
        </el-button>
      </div>
    </el-card>
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          学生门禁记录
        </h3>
      </div>
      <div class="card-body p-0" v-if="itemData != null && itemData.length != 0">
        <el-table ref="multipleTable" :data="itemData" tooltip-effect="dark" style="width: 100%">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="name" label="姓名" min-width="120" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="class" label="班级" min-width="120" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="deviceName" label="设备名称" min-width="120" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="checkTimeDate" label="核验时间" min-width="120" :show-overflow-tooltip="true">
              <template slot-scope='scope'>
                      {{ scope.row.checkTimeDate| dateformatMinute}}
                </template>
          </el-table-column>
<!--          <el-table-column prop="createDate" label="创建时间" min-width="120" :show-overflow-tooltip="true">
            <template slot-scope='scope'>
                    {{ scope.row.createDate| dateformatMinute}}
              </template>
          </el-table-column>
          <el-table-column prop="handleDate" label="处理时间" min-width="120" :show-overflow-tooltip="true">
            <template slot-scope='scope'>
                    {{ scope.row.handleDate| dateformatMinute}}
              </template>
          </el-table-column> -->
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import search from '@/components/search.vue';

  export default {
    name: '',
    components: {
      comPage,
      search
    },
    data() {
      return {
        fullscreenLoading: false,
        dialogVisible: false, //批量审核弹窗
        multipleSelection: [], //列表选中数据
        random: 0, //刷新组件
        page: null, //分页查询接口返回的结果
        itemData: [], //列表数据
        pageBtnList: null, //分页左侧按钮集合
        paging: { //分页数据
          pageLength: 0, // 总共页数
          Size: 10, // 当前请求数量
          Index: 1 //当前在第几页
        },
        SearchList: null, //搜索集合
        tpGrade: [],
        searchForm: {
          // 学期
          optSemester: [],
          semesterId: null,
          // 请假类型
          optType: [],
          typeId: null,
          // 学生
          student: null,
          //年级
          collegeId: null,
          Identity: null,
          // 班级
          classId: null,
          // 请假时间
          dateRange: [],

        },
        optIdentity:[
          {
            value:2,
            label:'学生',
          },
          {
            value:1,
            label:'教师',
          },
          {
            value:null,
            label:'全部',
          }
        ],
        optStudent:[],
        optUser:[],//教师信息
        optClass: [], //所有班级
        url: null, //当前页面的路径
        // 判断是否为班主任
        isTeacher: false,
        myInfo: null,
        ruleForm: {
          reason: null,
        },
        isShowErr: null,
        // 教师信息
        TeacherInfo: {},
      };
    },
    created() {
      let that = this;
      that.myInfo = onGetNowUser(that);
      that.optClass = that.myInfo.allClass;
      ajaxGet(that,'/api/admin/sysuser',null,function(res){
        that.optUser = res;
      })
      that.searchFormReset = JSON.parse(JSON.stringify(that.searchForm));
      pagingPage(that);
    },
    methods: {
      onChangeStudent(e){
        let that =this;
        ajaxGet(that,'/api/admin/student/class/'+that.searchForm.classId,null,function(res){
          that.optStudent = res;
        })
      },
      /**
       * @description 重置
       */
      onReset() {
        let that = this;
        that.searchForm = JSON.parse(JSON.stringify(that.searchFormReset));
        that.optStudent = [];
        pagingPage(that);
      },
      //去除数组内的重复
      onArrUnique(arr) {
        const res = new Map();
        return arr.filter((arr) => !res.has(arr.value) && res.set(arr.value, 1));
      },
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      /**
       * @description 搜索
       */
      onSearch() {
        let that = this;
        that.paging.Index = 1;
        pagingPage(that);
      },
      pagingClick(type, val) {
        let that = this;
        if (type == 1) {
          //更改每页条数触发的事件
          that.paging.Size = val;
          that.paging.Index = 1;
        } else {
          //更改当前页时触发的事件
          that.paging.Index = val;
        }
        pagingPage(that);
      },

    },
    watch: {
      $route() {
        let that = this;
        pagingPage(that);
      }
    }
  };

  function pagingPage(that) {



    that.fullscreenLoading = true;
    let tpParams = {
      PageNumer: that.paging.Index,
      PageSize: that.paging.Size,
      Name: that.searchForm.Name,
      DeviceName: that.searchForm.DeviceName,
      StudentId: that.searchForm.studentId,
      ClassId: that.searchForm.classId,
      Identity: 2,
    };
    ajaxGet(that, '/api/admin/dumurecord/search', tpParams, function(resData) {
      that.fullscreenLoading = false;
      that.page = resData;
      that.itemData = resData.items;
    });

  }
</script>

<style scoped="scoped">
  .selTitleBox {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .selTitleBox .spTitle {
    color: #303133;
  }
</style>
