<!--
  新增流程活动
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <!-- 主题班会 -->
    <el-form :model="ruleForm" ref="ruleForm" label-width="90px" v-if="pageType == 5">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            主题班会查看
          </h3>
        </div>
        <div class="card-body">

          <div class="row">
            <div class="col-lg-3 colInfo">
              <label>学期</label>
              <div class="colFonts">{{ruleForm.semester | flNoValue}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>班级</label>
              <div class="colFonts">{{ruleForm.class | flNoValue}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>第几周</label>
              <div class="colFonts">{{ruleForm.week | flNoValue}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>星期几</label>
              <div class="colFonts">{{ruleForm.dayOfWeek | flNoValue}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>课节</label>
              <div class="colFonts">{{'第'+ruleForm.schedule+'节' | flNoValue}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>节数</label>
              <div class="colFonts">{{'共用'+ruleForm.duration+'节' | flNoValue}}</div>
            </div>
          </div>
        </div>
      </el-card>
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            班会课题
          </h3>
        </div>
        <div class="card-body">
          <div class="colRemarksCont" v-html="ruleForm.topic"></div>
        </div>
      </el-card>

      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            本课目的
          </h3>
        </div>
        <div class="card-body">
          <div class="colRemarksCont" v-html="ruleForm.objective"></div>
        </div>
      </el-card>

      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            本课形式
          </h3>
        </div>
        <div class="card-body">
          <div class="colRemarksCont" v-html="ruleForm.form"></div>
        </div>
      </el-card>

      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            过程设计
          </h3>
        </div>
        <div class="card-body">
          <div class="colRemarksCont" v-html="ruleForm.form"></div>
        </div>
      </el-card>
    </el-form>
    <!-- 学期计划 -->
    <el-form :model="ruleForm" ref="ruleForm" label-width="90px" v-if="pageType == 1">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            学期计划查看
          </h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-3 colInfo">
              <label>学期</label>
              <div class="colFonts">{{ruleForm.semester | flNoValue}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>班级</label>
              <div class="colFonts">{{ruleForm.class | flNoValue}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>班主任</label>
              <div class="colFonts">{{ruleForm.adviser | flNoValue}}</div>
            </div>
          </div>
        </div>
      </el-card>
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            计划内容
          </h3>
        </div>
        <div class="card-body">
          <div class="colRemarksCont" v-if="ruleForm.content">
            <span v-html="ruleForm.content"></span>
          </div>
          <div v-else>暂无数据</div>
        </div>
      </el-card>
    </el-form>
    <el-form :model="ruleForm" ref="ruleForm" label-width="90px" v-if="pageType == 2">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            活动计划查看
          </h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-3 colInfo">
              <label>学期</label>
              <div class="colFonts">{{ruleForm.semester | flNoValue}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>日期</label>
              <div class="colFonts">第{{ruleForm.date}}周</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>班级</label>
              <div class="colFonts">{{ruleForm.class | flNoValue}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>班主任</label>
              <div class="colFonts">{{ruleForm.adviser | flNoValue}}</div>
            </div>
          </div>
        </div>
      </el-card>
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            活动内容
          </h3>
        </div>
        <div class="card-body">
          <div class="colRemarksCont" v-if="ruleForm.content">
            <span v-html="ruleForm.content"></span>
          </div>
          <div v-else>暂无数据</div>
        </div>
      </el-card>
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            备注
          </h3>
        </div>
        <div class="card-body">
          <div class="colRemarksCont" v-if="ruleForm.remark">
            <span v-html="ruleForm.remark"></span>
          </div>
          <div v-else>暂无数据</div>
        </div>
      </el-card>
    </el-form>
    <!-- 班级备忘录 -->
    <el-form :model="ruleForm" ref="ruleForm" label-width="90px" v-if="pageType == 4">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            班级备忘录查看
          </h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-3 colInfo">
              <label>学期</label>
              <div class="colFonts">{{ruleForm.semester | flNoValue}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>日期</label>
              <div class="colFonts">第{{ruleForm.date}}周</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>班级</label>
              <div class="colFonts">{{ruleForm.class | flNoValue}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>班主任</label>
              <div class="colFonts">{{ruleForm.adviser | flNoValue}}</div>
            </div>
          </div>
        </div>
      </el-card>
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            内容
          </h3>
        </div>
        <div class="card-body">
          <div class="colRemarksCont" v-if="ruleForm.content">
            <span v-html="ruleForm.content"></span>
          </div>
          <div v-else class="colFonts">暂无数据</div>
        </div>
      </el-card>
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            备注
          </h3>
        </div>
        <div class="card-body">
          <div class="colRemarksCont" v-if="ruleForm.remark">
            <span v-html="ruleForm.remark"></span>
          </div>
          <div v-else class="colInfo">暂无数据</div>
        </div>
      </el-card>
    </el-form>
    <!-- 个别谈话       -->
    <el-form :model="ruleForm" ref="ruleForm" label-width="120px" v-if="pageType==10">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            个别谈话查看
          </h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-3 colInfo">
              <label>学期</label>
              <div class="colFonts">{{ruleForm.semester | flNoValue}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>班级</label>
              <div class="colFonts">{{ruleForm.class | flNoValue}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>学生</label>
              <div class="colFonts">{{ruleForm.studentName | flNoValue}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>谈话时间</label>
              <div class="colFonts">{{ruleForm.talkDate | dateformat("YYYY-MM-DD hh:mm")}}</div>
            </div>
          </div>
        </div>
      </el-card>

      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            谈话内容
          </h3>
        </div>
        <div class="card-body">
          <div class="colRemarksCont" v-html="ruleForm.content"></div>
        </div>
      </el-card>
    </el-form>
    <!-- 行为偏长辅导    -->
    <el-form :model="ruleForm" ref="ruleForm" label-width="120px" v-if="pageType == 7">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            行为偏长辅导查看
          </h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-4 colInfo">
              <label>学期</label>
              <div class="colFonts">{{ruleForm.semester | flNoValue}}</div>
            </div>
            <div class="col-lg-4 colInfo">
              <label>班级</label>
              <div class="colFonts">{{ruleForm.class | flNoValue}}</div>
            </div>
            <div class="col-lg-4 colInfo">
              <label>学生</label>
              <div class="colFonts">{{ruleForm.studentName | flNoValue}}</div>
            </div>
          </div>
        </div>
      </el-card>

      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            主要偏常行为
          </h3>
        </div>
        <div class="card-body">
          <div class="colRemarksCont" v-html="ruleForm.abnormalBehavior"></div>
        </div>
      </el-card>

      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            主要优点
          </h3>
        </div>
        <div class="card-body">
          <div class="colRemarksCont" v-html="ruleForm.merits"></div>
        </div>
      </el-card>

      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            家庭情况简要
          </h3>
        </div>
        <div class="card-body">
          <div class="colRemarksCont" v-html="ruleForm.familySituation"></div>
        </div>
      </el-card>
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            辅导记录
          </h3>
        </div>
        <div class="card-body" v-for="(item,index) in ruleForm.abnormalTutors" :key="index">
          <el-descriptions :title="`第${index+1}次辅导`" labelStyle="word-break:keep-all">
            <el-descriptions-item label="内容">
              <span class="colRemarksCont" v-html="item.tutorSituation"></span>

            </el-descriptions-item>
          </el-descriptions>
        </div>
      </el-card>
    </el-form>
    <!-- 双优生分析 -->
    <el-form :model="ruleForm" ref="ruleForm" label-width="120px" v-if="pageType == 6">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            双优生分析查看
          </h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-4 colInfo">
              <label>学期</label>
              <div class="colFonts">{{ruleForm.semester}}</div>
            </div>
            <div class="col-lg-4 colInfo">
              <label>班级</label>
              <div class="colFonts">{{ruleForm.class}}</div>
            </div>
            <div class="col-lg-4 colInfo">
              <label>学生</label>
              <div class="colFonts">{{ruleForm.studentName}}</div>
            </div>
          </div>
        </div>
      </el-card>

      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            主要优点
          </h3>
        </div>
        <div class="card-body">
          <div class="colRemarksCont" v-html="ruleForm.merits"></div>
        </div>
      </el-card>

      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            不足
          </h3>
        </div>
        <div class="card-body">
          <div class="colRemarksCont" v-html="ruleForm.demerits"></div>
        </div>
      </el-card>

      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            扬长避短措施
          </h3>
        </div>
        <div class="card-body">
          <div class="colRemarksCont" v-html="ruleForm.measure"></div>
        </div>
      </el-card>
    </el-form>
    <el-form :model="ruleForm" ref="ruleForm" label-width="120px" v-if="pageType == 9">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            家长学校活动查看
          </h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-3 colInfo">
              <label>学期</label>
              <div class="colFonts">{{ruleForm.semester | flNoValue}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>班级</label>
              <div class="colFonts">{{ruleForm.class | flNoValue}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>联系日期</label>
              <div class="colFonts">{{ruleForm.date | dateformat('YYYY-MM-DD')}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>人数</label>
              <div class="colFonts">{{ruleForm.numberOfPeople | flNoValue}}</div>
            </div>

            <div class="col-lg-3 colInfo">
              <label>组织者</label>
              <div class="colFonts">{{ruleForm.organizer | flNoValue}}</div>
            </div>
            <div class="col-lg-6 colInfo">
              <label>地点</label>
              <div class="colFonts">{{ruleForm.place | flNoValue}}</div>
            </div>
          </div>
        </div>
      </el-card>

      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            目的
          </h3>
        </div>
        <div class="card-body">
          <div class="colRemarksCont" v-html="ruleForm.objective"></div>
        </div>
      </el-card>

      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            形式
          </h3>
        </div>
        <div class="card-body">
          <div class="colRemarksCont" v-html="ruleForm.form"></div>
        </div>
      </el-card>


      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            过程
          </h3>
        </div>
        <div class="card-body">
          <div class="colRemarksCont" v-html="ruleForm.process"></div>
        </div>
      </el-card>


      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            效果
          </h3>
        </div>
        <div class="card-body">
          <div class="colRemarksCont" v-html="ruleForm.effect"></div>
        </div>
      </el-card>
    </el-form>
    <el-form :model="ruleForm" ref="ruleForm" label-width="120px" v-if="pageType == 11">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            学期总结查看
          </h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-3 colInfo">
              <label>学期</label>
              <div class="colFonts">{{ruleForm.semester | flNoValue}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>班级</label>
              <div class="colFonts">{{ruleForm.class | flNoValue}}</div>
            </div>

            <div class="col-lg-6 colInfo">
              <label>班主任</label>
              <div class="colFonts">{{ruleForm.adviser | flNoValue}}</div>
            </div>
          </div>
        </div>
      </el-card>
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            工作总结
          </h3>
        </div>
        <div class="card-body">
          <div class="colRemarksCont" v-html="ruleForm.content"></div>
        </div>
      </el-card>
    </el-form>
    <el-form :model="ruleForm" label-width="120px" v-if="pageType == 8">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            家校联系记录查看
          </h3>
        </div>
        <div class="card-body">

          <div class="row">
            <div class="col-lg-3 colInfo">
              <label>学期</label>
              <div class="colFonts">{{ruleForm.semester | flNoValue}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>班级</label>
              <div class="colFonts">{{ruleForm.class | flNoValue}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>学生</label>
              <div class="colFonts">{{ruleForm.studentName | flNoValue}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>联系日期</label>
              <div class="colFonts">{{ruleForm.contactDate | dateformat('YYYY-MM-DD HH:mm')}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>联系对象</label>
              <div class="colFonts">{{ruleForm.relationShip | flNoValue}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>联系方式</label>
              <div class="colFonts">{{ruleForm.contactWay | flNoValue}}</div>
            </div>
          </div>
        </div>
      </el-card>

      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            联系原因
          </h3>
        </div>
        <div class="card-body">
          <div class="colRemarksCont" v-html="ruleForm.contactReason"></div>
        </div>
      </el-card>


      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            效果
          </h3>
        </div>
        <div class="card-body">
          <div class="colRemarksCont" v-html="ruleForm.effect"></div>
        </div>
      </el-card>
    </el-form>
    <!-- 学生获奖     -->
    <div class="row">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';


  export default {
    components: {

    },
    data() {
      return {
        fullscreenLoading: false,
        pageType: null,
        ruleForm: {},
        query: null
      };
    },
    created() {
      let that = this
      that.query = JSON.parse(JSON.stringify(that.$route.query))
      let id = that.$route.params.Id;
      let type = that.$route.query.type
      that.pageType = type
      let mapUrl = {
        1: `/api/admin/adviserworkplan/${id}`, //学期工作计划
        2: `/api/admin/adviseractivityplan/${id}`, //活动计划
        4: `/api/admin/adviserclassworkmemo/${id}`, //班级备忘录
        5: `/api/admin/adviserclassmeeting/${id}`, //主题班会
        6: `/api/admin/adviserdoubleeugenics/${id}`, //双优生分析
        7: `/api/admin/adviserabnormalbehavior/${id}`, //行为偏长辅导
        8: `/api/admin/advisercontactrecord/${id}`, //家校联系记录
        9: `/api/admin/adviserparentactivityrecord/${id}`, //家校活动记录
        10: `/api/admin/adviserindividualeducation/${id}`, //个别谈话
        11: `/api/admin/adviserworksummary/${id}` //学期总结
      }
      that.fullscreenLoading = true
      ajaxGet(that, mapUrl[type], null, function(res) {
        that.ruleForm = res
        that.fullscreenLoading = false
      })
    },
    methods: {
      onBack() {
        let that = this;
        that.$router.push({
          path: '/Admin/Adviser/ManualInspection/Index',
          query: that.query
        })
      },
    },
    filters: {
      flNoValue(obj) {
        if (obj == null || typeof(obj) == "undefined" || obj == '') {
          return '暂无数据';
        }
        return obj;
      },
      weekFormat(val) {
        let obj = {
          1: '星期一',
          2: '星期二',
          3: '星期三',
          4: '星期四',
          5: '星期五',
          6: '星期六',
          7: '星期天'
        }
        if (obj[val]) {
          return obj[val]
        }
        return '暂无数据'
      }
    },
  };
</script>

<style scoped="scoped">

</style>