<!--
  学生管理-学生奖励
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <!-- 搜索 -->
    <el-card class="card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="width: 100%; padding-left: 0px; flex: 1"
          v-if="SearchTitles != null && SearchTitles.length > 0">
          <ul class="nav nav-pills navTabsCont">
            <li class="nav-item" v-for="(p, index) in SearchTitles" :key="index">
              <router-link :to="p.url" :class="p.isCurrent == true ? 'nav-link active' : 'nav-link'">
                {{ p.name }}
              </router-link>
            </li>
          </ul>
        </h3>
      </div>
      <div class="card-body" v-if="currentType == 3">
        <el-row :gutter="24">
          <el-col :span="6" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">年级</span>
            </div>
            <div class="selBodyBox">
              <el-select v-model="searchForm.collegeId" filterable placeholder="请选择年级" class="width_100Pie"
                @change="onChangeCollege">
                <el-option v-for="item in optCollege" :key="item.collegeId" :label="item.name" :value="item.collegeId">
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="6" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">班级</span>
            </div>
            <div class="selBodyBox">
              <el-select v-model="searchForm.classId" placeholder="请选择班级" filterable class="width_100Pie" clearable>
                <el-option v-for="(item, index) in optClass" :key="index" :label="item.alias" :value="item.classId">
                </el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="card-footer text-center" v-if="currentType == 3">
        <el-button type="primary" class="mr-2" @click="onSearch()">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#fax-search"></use>
          </svg>
          查询
        </el-button>
        <el-button class="ml-2" @click="onReset()">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#fax-redo-alt"></use>
          </svg>
          重置
        </el-button>
      </div>
    </el-card>

    <el-card class="box-card" v-if="currentType == 1">
      <div slot="header" class="card-header">
        <h3 class="card-title">未出校</h3>
      </div>
      <div class="card-body p-0" v-if="!regNullArray(itemData.notLeaves)">
        <div class="tabRoleCont">
          <div class="row">
            <div class="col-2" v-for="(list, index2) in itemData.notLeaves" :key="index2" style="margin-top: 15px;">
              <div class="stuInfoBox clearfix">
                <div class="stuInfoCont" @click="onDetails(list)">
                  <el-tooltip class="item" effect="dark" :content="onTitle(list)" placement="top-start">
                    <el-tag effect="plain" size="medium">
                      {{ list.studentName }}</el-tag>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body" v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
    <el-card class="box-card" v-if="currentType == 1">
      <div slot="header" class="card-header">
        <h3 class="card-title">已出校</h3>
      </div>
      <div class="card-body p-0" v-if="!regNullArray(itemData.Leaves)">
        <div class="tabRoleCont">
          <div class="row">
            <div class="col-2" v-for="(list, index2) in itemData.Leaves" :key="index2" style="margin-top: 15px;">
              <div class="stuInfoBox clearfix">
                <div class="stuInfoCont" @click="onDetails(list)">
                  <el-tooltip class="item" effect="dark" :content="
                      '班级:' +
                      list.className +
                      '时间' +
                      onDateformatMinute(list.startDate) +
                      '-' +
                      onDateformatMinute(list.endDate)
                    " placement="top-start">
                    <el-tag effect="plain" type="success" size="medium">
                      {{ list.studentName }}</el-tag>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body" v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
    <el-card class="box-card" v-if="currentType == 2">
      <div slot="header" class="card-header">
        <h3 class="card-title">未返校</h3>
      </div>
      <div class="card-body p-0" v-if="!regNullArray(itemData.notReturns)">
        <div class="tabRoleCont">
          <div class="row">
            <div class="col-2" v-for="(list, index2) in itemData.notReturns" :key="index2" style="margin-top: 15px;">
              <div class="stuInfoBox clearfix">
                <div class="stuInfoCont" @click="onDetails(list)" role="button">
                  <el-tooltip class="item" effect="dark" :content="
                      '班级：' +
                      list.className +
                      ' | 时间：' +
                      onDateformatMinute(list.startDate)+
                      ' 至 ' +
                      onDateformatMinute(list.endDate)
                    " placement="top-start">
                    <el-tag effect="plain" size="medium">
                      {{ list.studentName }}</el-tag>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body" v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
    <el-card class="box-card" v-if="currentType == 2">
      <div slot="header" class="card-header">
        <h3 class="card-title">已返校</h3>
      </div>
      <div class="card-body p-0" v-if="!regNullArray(itemData.returns)">
        <div class="tabRoleCont">
          <div class="row">
            <div class="col-2" v-for="(list, index2) in itemData.returns" :key="index2" style="margin-top: 15px;">
              <div class="stuInfoBox clearfix">
                <div class="stuInfoCont" @click="onDetails(list)" role="button">
                  <el-tooltip class="item" effect="dark" :content="
                      '班级：' +
                      list.className +
                      ' | 时间：' +
                      onDateformatMinute(list.startDate) +
                      ' 至 ' +
                      onDateformatMinute(list.endDate)
                    " placement="top-start">
                    <el-tag effect="plain" type="success" size="medium">
                      {{ list.studentName }}</el-tag>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body" v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
    <el-card class="box-card" v-if="currentType == 3">
      <div slot="header" class="card-header">
        <h3 class="card-title">今日通校</h3>
      </div>
      <div class="card-body p-0" v-if="!regNullArray(itemData.Tx)">
        <div class="tabRoleCont">
          <div class="row">
            <div class="col-2" v-for="(list, index2) in itemData.Tx" :key="index2" style="margin-top: 15px;">
              <div class="stuInfoBox clearfix">
                <div class="stuInfoCont" @click="onDetails(list)" role="button">
                  <!-- <el-tooltip class="item" effect="dark" :content="'班级:' + list.className" placement="top-start"> -->
                  <el-tag effect="plain" size="medium">
                    {{ list.name }}</el-tag>
                  <!-- </el-tooltip> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body" v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
    <el-dialog title="详情" :visible.sync="dialogVisible" width="40%" :before-close="handleClose">
      <div class="text-center">
        <el-image style="width: 300px; height: 300px ;" :src="ruleForm.photo" fit="contain"></el-image>
      </div>

      <div class="px-5">
        <el-row :gutter="20">
          <el-col :lg="12" :sm="24" class="colInfo" v-if="!regNull(ruleForm.className)">
            <label>班级</label>
            <div class="colFonts">{{ruleForm.className}}</div>
          </el-col>
          <el-col :lg="12" :sm="24" class="colInfo" v-if="!regNull(ruleForm.class)">
            <label>班级</label>
            <div class="colFonts">{{ruleForm.class}}</div>
          </el-col>
          <el-col :lg="12" :sm="24" class="colInfo">
            <label>姓名</label>
            <div class="colFonts">{{ruleForm.name}}{{ruleForm.studentName}}</div>
          </el-col>
          <el-col :lg="12" :sm="24" class="colInfo" v-if="!regNull(ruleForm.startDate)">
            <label>开始时间</label>
            <div class="colFonts">{{ruleForm.startDate | dateformatMinute}}</div>
          </el-col>
          <el-col :lg="12" :sm="24" class="colInfo" v-if="!regNull(ruleForm.outDate)">
            <label>实际出校时间</label>
            <div class="colFonts">{{ruleForm.outDate | dateformatMinute}}</div>
          </el-col>
          <el-col :lg="12" :sm="24" class="colInfo" v-if="!regNull(ruleForm.endDate)">
            <label>结束时间</label>
            <div class="colFonts">{{ruleForm.endDate | dateformatMinute}}</div>
          </el-col>
          <el-col :lg="12" :sm="24" class="colInfo" v-if="!regNull(ruleForm.inDate)">
            <label>实际返校时间</label>
            <div class="colFonts">{{ruleForm.inDate | dateformatMinute}}</div>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import "@/assets/css/Admin.css";
  import comPage from "@/components/Page.vue";
  import operationBtn from "@/components/operationBtn.vue";

  export default {
    name: "",
    components: {
      // comPage,
      // operationBtn,
    },
    data() {
      return {
        fullscreenLoading: false,
        random: 0, //刷新组件
        page: null, //分页查询接口返回的结果
        dialogVisible: false,
        itemData: {
          notLeaves: [],
          leaves: [],
          notReturns: [],
          returns: [],
          Tx: [],
        }, //列表数据
        currentType: 1,
        SearchList: null, //搜索集合
        searchForm: {
          // 班级
          classId: null,
          collegeId: null,
        },
        optClass: [],
        allClass: [],
        optCollege: [],
        ruleForm: {},
        searchFormReset: null,
        SearchTitles: [],

        url: null, //当前页面的路径
      };
    },
    beforeDestroy() {
      clearInterval(this.intervalId); // 在组件销毁时清除定时器
    },
    created() {
      let that = this;
      // 请假类型
      ajaxGet(that, "/api/admin/college", null, (resCollege) => {
        ajaxGet(that, "/api/admin/sysuser/userfilter", null, function(resClass) {
          that.optClass = resClass;
          that.allClass = resClass;
          that.optCollege = resCollege;
          that.searchFormReset = JSON.parse(JSON.stringify(that.searchForm));
          Init(that);
          that.intervalId = setInterval(() => {
            Init(that);
          }, 5 * 60 * 1000);
        });
      });
    },
    methods: {
      onDateformatMinute(objItem) {
        return dateFormat(objItem, '-', 'YYYY-MM-DD HH:MM')
      },
      onTitle(val) {
        return '班级：' + val.className + ' |  时间：' + dateFormat(val.startDate, '-', 'YYYY-MM-DD HH:MM') + ' 至 ' +
          dateFormat(
            val
            .endDate, '-', 'YYYY-MM-DD HH:MM')
      },
      onChangeCollege() {
        let that = this;
        that.searchForm.classId = '';
        if (!regNull(that.searchForm.collegeId)) {
          that.optClass = [];
          for (var it of that.allClass) {
            if (it.collegeId = that.searchForm.collegeId) {
              that.optClass.push(it);
            }
          }
        }
      },
      handleClose() {
        let that = this;
        that.dialogVisible = false;
      },
      onDetails(objValue) {
        let that = this;
        that.ruleForm.className = objValue.className;
        that.ruleForm.class = objValue.class;
        that.ruleForm.name = objValue.name;
        that.ruleForm.startDate = objValue.startDate;
        that.ruleForm.outDate = objValue.outDate;
        that.ruleForm.inDate = objValue.inDate || '';
        that.ruleForm.endDate = objValue.endDate;
        that.ruleForm.studentName = objValue.studentName;
        that.ruleForm.photo = that.$ServerUrl() + objValue.photo;
        that.dialogVisible = true;
      },
      /**
       * @description 重置
       */
      onReset() {
        let that = this;
        that.optClass = that.allClass;
        that.searchForm = JSON.parse(JSON.stringify(that.searchFormReset));
        pagingPage(that);
      },
      /**
       * @description 搜索
       */
      onSearch() {
        let that = this;
        // that.paging.Index = 1;
        pagingPage(that);
      },
    },
    watch: {
      $route() {
        let that = this;
        Init(that);
      },
    },
  };

  function Init(that) {
    that.currentType = regNull(that.$route.query.type) ?
      1 :
      that.$route.query.type;
    that.SearchTitles = [{
        url: "/Admin/GuardSecurity/Index?type=" + 1,
        name: "今日请假出校",
        isCurrent: that.currentType == 1 ? true : false,
      },
      {
        url: "/Admin/GuardSecurity/Index?type=" + 2,
        name: "今日返校出校",
        isCurrent: that.currentType == 2 ? true : false,
      },
      {
        url: "/Admin/GuardSecurity/Index?type=" + 3,
        name: "今日通校",
        isCurrent: that.currentType == 3 ? true : false,
      },
    ];
    pagingPage(that);
  }

  function pagingPage(that) {
    that.fullscreenLoading = true;
    let tpParams = {
      collegeId: that.searchForm.collegeId,
      classId: that.searchForm.classId,
    };
    ajaxGet(that, "/api/admin/askforleave/leavetoday", null, function(resData) {
      ajaxGet(that, "/api/admin/askforleave/regulartoday", tpParams, function(resRegular) {
        that.itemData = resData;
        that.itemData.Tx = resRegular;
        that.fullscreenLoading = false;
      });
    });
  }
</script>

<style scoped="scoped">
  .selTitleBox {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .selTitleBox .spTitle {
    color: #303133;
  }

  .navTabsCont .nav-link {
    font-size: 12px;
  }

  .stuInfoBox {
    margin-bottom: 0.9375rem;
    display: flex;
  }

  .stuInfoBox .stuInfoCont {
    padding-left: 0.9375rem;
    padding-right: 30px;
    min-width: 150px;
  }

  .stuInfoBox .stuInfoCont .stuName {
    color: #212e48;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    font-size: 24px;
  }
</style>
