<!--
  编辑学生信息
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="arrInfo" :rules="rules" ref="arrInfo" label-width="100px">
      <!-- 基础信息 -->
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            基础信息
          </h3>
        </div>
        <div class="card-body pb-5">
          <el-row :gutter="24">
            <!--照片-->
            <el-col :lg="6" :sm="24" class="text-center divStuAva">
              <el-upload
                class="avatar-uploader"
                action=""
                style="width: 180px; height: 252px; margin: 0 auto"
                :auto-upload="true"
                :show-file-list="false"
                :before-upload="beforeAvatarUpload"
                :http-request="uploadSectionFile"
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <span class="spImgFont" v-if="imageUrl == ''">上传照片</span>
              </el-upload>
              <br />
              <div class="studentName">{{ arrInfo.name }}</div>
              <div class="studentNo">{{ arrInfo.studentNo }}</div>
            </el-col>
            <!--基础信息-->
            <el-col :lg="18" :sm="24">
              <el-row :gutter="24">
                <el-col :span="8">
                  <el-form-item label="姓名" prop="name">
                    <el-input
                      v-model="arrInfo.name"
                      placeholder="请输入姓名"
                      class="formControl"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8" class="otherInfo">
                  <el-form-item label="学号" prop="studentNo">
                    <el-input
                      v-model="arrInfo.studentNo"
                      placeholder="请输入学号"
                      class="formControl"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8" class="otherInfo">
                  <el-form-item label="入学年份" prop="entryYear">
                    <el-select
                      v-model="arrInfo.entryYear"
                      filterable
                      placeholder="请输入入学年份"
                      style="width: 100%"
                    >
                      <el-option
                        v-for="item in optEntryYear"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8" class="otherInfo">
                  <el-form-item label="性别" prop="gender">
                    <el-select
                      v-model="arrInfo.gender"
                      placeholder="请输入性别"
                      style="width: 100%"
                      @change="handleChangeGender"
                    >
                      <el-option
                        v-for="item in optGender"
                        :key="item.genderId"
                        :label="item.name"
                        :value="item.genderId"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8" class="otherInfo">
                  <el-form-item label="班级" prop="class">
                    <el-select
                      v-model="arrInfo.class"
                      filterable
                      placeholder="请选择班级"
                      style="width: 100%"
                      @change="handleChangeClass"
                    >
                      <el-option-group
                        v-for="group in optClass"
                        :key="group.label"
                        :label="group.label"
                      >
                        <el-option
                          v-for="item in group.options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-option-group>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8" class="otherInfo">
                  <el-form-item label="民族" prop="nation">
                    <el-select
                      v-model="arrInfo.nation"
                      filterable
                      placeholder="请选择民族"
                      style="width: 100%"
                      @change="handleChangeNation"
                    >
                      <el-option
                        v-for="item in optNation"
                        :key="item.nationId"
                        :label="item.name"
                        :value="item.nationId"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8" class="otherInfo">
                  <el-form-item label="身份证号" prop="idCardNo">
                    <div style="position: relative">
                      <el-input
                        v-model="arrInfo.idCardNo"
                        placeholder="请输入身份证号"
                        class="formControl"
                      ></el-input>
                      <span
                        style="
                          color: #f56c6c;
                          font-size: 12px;
                          position: absolute;
                          width: 100%;
                          top: 35px;
                          left: 0;
                        "
                        >{{ idCardError }}</span
                      >
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="8" class="otherInfo">
                  <el-form-item label="学籍状态" prop="status">
                    <el-select
                      v-model="arrInfo.status"
                      placeholder="请选择学籍状态"
                      class="formControl"
                    >
                      <el-option
                        v-for="item in optStatus"
                        :key="item.Value"
                        :label="item.Text"
                        :value="item.Value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8" class="otherInfo">
                  <el-form-item label="出生日期" prop="birthday">
                    <el-date-picker
                      v-model="arrInfo.birthday"
                      type="date"
                      placeholder="选择日期"
                      style="width: 100%"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>

                <el-col :span="24" class="otherInfo">
                  <el-form-item prop="password" label="输入密码">
                    <div style="position: relative">
                      <el-input
                        show-word-limit
                        auto-complete="new-password"
                        show-password
                        maxlength="16"
                        v-model.trim="arrInfo.password"
                        placeholder="请输入密码,密码为8-16位，数字和字母组成，不能是纯数字或纯字母"
                        class="formControl"
                      ></el-input>

                      <span
                        style="
                          color: #f56c6c;
                          font-size: 12px;
                          position: absolute;
                          width: 100%;
                          top: 35px;
                          left: 0;
                        "
                        >{{ passWordError }}</span
                      >
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="24" class="otherInfo">
                  <el-form-item prop="againPassword" label="确认密码">
                    <div style="position: relative">
                      <el-input
                        show-word-limit
                        auto-complete="new-againPassword"
                        show-password
                        maxlength="16"
                        v-model.trim="arrInfo.againPassword"
                        placeholder="请确认密码,密码为8-16位，数字和字母组成，不能是纯数字或纯字母"
                        class="formControl"
                      ></el-input>

                      <span
                        style="
                          color: #f56c6c;
                          font-size: 12px;
                          position: absolute;
                          width: 100%;
                          top: 35px;
                          left: 0;
                        "
                        >{{ passWordError }}</span
                      >
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="24" class="otherInfo">
                  <el-form-item label="籍贯" prop="originalPlace">
                    <el-input
                      v-model="arrInfo.originalPlace"
                      placeholder="请输入籍贯"
                      class="formControl"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24" class="otherInfo">
                  <el-form-item label="户籍地" prop="sourcePlace">
                    <el-input
                      v-model="arrInfo.sourcePlace"
                      placeholder="请输入户籍地"
                      class="formControl"
                    ></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="24" class="otherInfo">
                  <el-form-item label="家庭地址" prop="homeAddress">
                    <el-input
                      v-model="arrInfo.homeAddress"
                      placeholder="请输入家庭地址"
                      class="formControl"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8" class="otherInfo">
                  <el-form-item label="邮箱" prop="email">
                    <el-input
                      v-model="arrInfo.email"
                      placeholder="请输入邮箱"
                      class="formControl"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8" class="otherInfo">
                  <el-form-item label="QQ" prop="qq">
                    <el-input
                      v-model="arrInfo.qq"
                      placeholder="请输入QQ"
                      class="formControl"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8" class="otherInfo">
                  <el-form-item label="微信号" prop="weChat">
                    <el-input
                      v-model="arrInfo.weChat"
                      placeholder="请输入微信号"
                      class="formControl"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8" class="otherInfo">
                  <el-form-item label="手机号码" prop="mobile">
                    <el-input
                      v-model="arrInfo.mobile"
                      placeholder="请输入手机号码"
                      class="formControl"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8" class="otherInfo">
                  <el-form-item label="手机短号" prop="short">
                    <el-input
                      v-model="arrInfo.short"
                      placeholder="请输入手机短号"
                      class="formControl"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8" class="otherInfo">
                  <el-form-item label="政治面貌" prop="political">
                    <el-select
                      v-model="arrInfo.political"
                      filterable
                      placeholder="请输入政治面貌"
                      style="width: 100%"
                      @change="handleChangePolitical"
                    >
                      <el-option
                        v-for="item in optPolitical"
                        :key="item.politicalId"
                        :label="item.name"
                        :value="item.politicalId"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8" class="otherInfo">
                  <el-form-item label="是否困难生" prop="isPoor">
                    <el-switch v-model="arrInfo.isPoor" class="formControl">
                    </el-switch>
                  </el-form-item>
                </el-col>

                <!--                <el-col :span="24">
                  <el-form-item label="所属角色" v-if="roles!=null">
                    <el-checkbox-group v-model="studentRoles">
                      <el-checkbox v-for="role in roles" :label="role.roleId" :key="role.roleId">{{role.name}}
                      </el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>
                </el-col> -->
              </el-row>
            </el-col>
          </el-row>
        </div>
      </el-card>

      <!--家庭情况-->
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            家庭情况
          </h3>
          <div class="card-tools">
            <ul class="nav">
              <li class="nav-item">
                <el-button
                  type="success"
                  icon="el-icon-circle-plus-outline"
                  @click="addFamily()"
                  >新增家庭信息</el-button
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="card-body">
          <el-row :gutter="24" v-if="arrInfo.families.length != 0">
            <el-col
              :span="6"
              v-for="(item, index) in arrInfo.families"
              :key="index"
            >
              <div class="familyList">
                <div
                  class="emergencyCont"
                  @click="onRemoveMember(item.familyId)"
                >
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#fax-times"></use>
                  </svg>
                </div>
                <div class="divTopCont">
                  <div class="appellativeCont VerticalMid">
                    {{ item.title }}
                  </div>
                  <div class="memberName">
                    {{ item.name }}&nbsp;<span
                      class="spLabel"
                      v-if="item.isEmer"
                      >紧急联系人</span
                    >
                  </div>
                  <div class="memberPhone">{{ item.telephone }}</div>
                  <div class="memberPosition">
                    <span class="spFont">{{ item.position }}</span>
                  </div>
                </div>
                <div class="divBottomCont">
                  <span class="spFont">{{ item.company }}</span>
                </div>
              </div>
            </el-col>
          </el-row>
          <span class="spNullData" v-else>暂无数据</span>
        </div>
      </el-card>

      <!--教育经历-->
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            教育经历
          </h3>
          <div class="card-tools">
            <ul class="nav">
              <li class="nav-item">
                <el-button
                  type="success"
                  icon="el-icon-circle-plus-outline"
                  @click="addEducation()"
                  >新增教育经历</el-button
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="card-body">
          <el-row :gutter="24" v-if="arrInfo.educations.length != 0">
            <el-col
              :span="6"
              v-for="(item, index) in arrInfo.educations"
              :key="index"
            >
              <div class="familyList educationList">
                <div
                  class="emergencyCont"
                  @click="onRemoveEducation(item.educationId)"
                >
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#fax-times"></use>
                  </svg>
                </div>
                <div class="divTopCont">
                  <div class="memberName">{{ item.school }}</div>
                  <div class="memberPosition">
                    <span class="spFont">{{ item.title }}</span>
                  </div>
                </div>
                <div class="divBottomCont">
                  <span class="spFont"
                    >{{ item.startDate | filterDate }}&nbsp;-&nbsp;{{
                      item.endDate | filterDate
                    }}</span
                  >
                </div>
              </div>
            </el-col>
          </el-row>
          <span class="spNullData" v-else>暂无数据</span>
        </div>
      </el-card>

      <!--兴趣特长-->
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            兴趣特长
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24">
            <el-col :span="24" class="hobbiesCont">
              <span class="spTitle">兴趣</span>
              <el-input
                class="txtInput"
                type="textarea"
                v-model="arrInfo.hobby"
                :autosize="{ minRows: 5 }"
                maxlength="200"
                show-word-limit
              ></el-input>
            </el-col>
            <el-col :span="24" class="hobbiesCont">
              <span class="spTitle">特长</span>
              <el-input
                class="txtInput"
                type="textarea"
                v-model="arrInfo.speciality"
                :autosize="{ minRows: 5 }"
                maxlength="200"
                show-word-limit
              ></el-input>
            </el-col>
          </el-row>
        </div>
      </el-card>

      <!--备注-->
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            备注
          </h3>
        </div>
        <div class="card-body">
          <div class="remarksCont">
            <el-input
              type="textarea"
              v-model="arrInfo.remark"
              :autosize="{ minRows: 5 }"
              maxlength="200"
              show-word-limit
            ></el-input>
          </div>
        </div>
      </el-card>

      <div class="row">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
          <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
          <el-button
            type="primary"
            icon="el-icon-circle-check"
            @click="onVerification('arrInfo')"
            >提交</el-button
          >
        </div>
      </div>
    </el-form>

    <el-dialog
      title="新增家庭信息"
      :visible.sync="dialogVisibleFamily"
      width="30%"
      top="40px"
      :before-close="handleFamilyClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :append-to-body="true"
      :fullscreen="false"
    >
      <el-form
        :model="familyForm"
        :rules="familyRules"
        ref="familyForm"
        label-width="90px"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="成员称谓" prop="title">
              <el-input
                v-model="familyForm.title"
                placeholder="请输入成员称谓"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="成员姓名" prop="name">
              <el-input
                v-model="familyForm.name"
                placeholder="请输入成员姓名"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="联系电话" prop="telephone">
              <el-input
                v-model="familyForm.telephone"
                placeholder="请输入联系电话"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="工作单位">
              <el-input
                v-model="familyForm.company"
                placeholder="请输入工作单位"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="职务">
              <el-input
                v-model="familyForm.position"
                placeholder="请输入职务"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="紧急联系人">
              <el-switch
                v-model="familyForm.isEmer"
                active-color="#409EFF"
                inactive-color="#C0CCDA"
              >
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleFamilyClose()">取 消</el-button>
        <el-button @click="onFamilyReset()">重 置</el-button>
        <el-button type="primary" @click="onVerificationFamily()"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="新增教育经历"
      :visible.sync="dialogVisiblEducation"
      width="30%"
      :before-close="handleEducationClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :append-to-body="true"
      :fullscreen="false"
    >
      <el-form
        :model="educationForm"
        :rules="educationRules"
        ref="educationForm"
        label-width="90px"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="学校名称" prop="school">
              <el-input
                v-model="educationForm.school"
                placeholder="请输入学校名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="学位">
              <el-input
                v-model="educationForm.title"
                placeholder="请输入学位"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="日期范围" prop="date">
              <el-date-picker
                v-model="educationForm.date"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleEducationClose()">取 消</el-button>
        <el-button @click="onEducationReset()">重 置</el-button>
        <el-button type="primary" @click="onVerificationEducation()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import "@/assets/css/Admin.css";

export default {
  name: "s_Edit",
  components: {},
  data() {
    return {
      fullscreenLoading: false,
      dialogVisibleFamily: false,
      dialogVisiblEducation: false,
      studentRoles: [],
      passWordError: "",
      roles: null,
      // 数据
      arrInfo: {
        studentNo: null,
        name: null,
        photo: null,
        genderId: null,
        gender: null,
        birthday: null,
        classId: null,
        class: null,
        nationId: null,
        nation: null,
        politicalId: null,
        political: null,
        entryYear: null,
        idCardNo: null,
        homeAddress: null,
        mobile: null,
        short: null,
        email: null,
        qq: null,
        weChat: null,
        status: "1",
        isPoor: false,
        originalPlace: null,
        sourcePlace: null,
        hobby: null,
        speciality: null,
        remark: null,
        educations: [],
        families: [],
        studentRoles: null,
        againPassword: null,
        password: null,
      },
      //
      arrInfoReset: [],

      // 学生ID
      studentId: null,

      // 民族下拉数据
      optNation: [],
      // 正治面貌
      optPolitical: [],
      // 班级
      optClass: [],
      // 入学年份
      optEntryYear: [],
      //身份证错误提示信息
      idCardError: "",
      // 新增
      optGender: [],
      optStatus: [],
      // 验证
      rules: {
        againPassword: [
          {
            required: true,
            message: "请确认密码",
            trigger: "change",
          },
          {
            validator: (rule, value, callback) => {
              let that = this;
              if (
                that.arrInfo.againPassword == null ||
                that.arrInfo.againPassword.length <= 0
              ) {
                callback(new Error("请确认密码！"));
                that.passWordError = "";
              } else {
                if (that.arrInfo.password != value) {
                  callback(new Error("两次密码不一致！"));
                  that.passWordError = "";
                }
              }
              callback();
              that.passWordError = "密码规则是fz加身份证后六位";
            },
            trigger: "change",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "change",
          },
          {
            validator: (rule, value, callback) => {
              let that = this;
              if (
                that.arrInfo.password == null ||
                that.arrInfo.password.length <= 0
              ) {
                callback(new Error("请输入密码！"));
                that.passWordError = "";
              } else {
                var reg = /^.{8,16}$/;
                if (value != 0 && !reg.exec(value)) {
                  callback(new Error("密码为8-16位"));
                  that.passWordError = "";
                }
              }
              that.passWordError = "密码规则是fz加身份证后六位";
              callback();
            },
            trigger: "change",
          },
        ],
        status: [
          {
            required: true,
            message: "请选择学籍状态",
            trigger: "change",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入学生姓名",
            trigger: "change",
          },
          {
            min: 0,
            max: 256,
            message: "学生姓名不能超过256个字符",
            trigger: "change",
          },
        ],
        studentNo: [
          {
            required: true,
            message: "请输入学生学号",
            trigger: "change",
          },
          {
            min: 0,
            max: 32,
            message: "学生学号不能超过32个字符",
            trigger: "change",
          },
        ],
        gender: [
          {
            required: true,
            message: "请选择性别",
            trigger: "change",
          },
        ],
        entryYear: [
          {
            required: true,
            message: "请选择入学年份",
            trigger: "change",
          },
        ],
        class: [
          {
            required: true,
            message: "请选择班级",
            trigger: "change",
          },
        ],

        idCardNo: [
          {
            required: true,
            trigger: "change",
             type: 'number',
            validator: (rule, value, callback) => {
              if (!value ) {
                callback(new Error("身份证号或护照号必须至少6位"));
              }
              if (value.length < 6) {
                this.idCardError = "";
                this.arrInfo.password = "";
                this.arrInfo.againPassword = "";
                this.passWordError = '';
                callback(new Error("身份证号或护照号必须至少6位"));
              } else if (value.length !== 15 && value.length !== 18) {
                this.arrInfo.password = "fz" + value.slice(-6);
                this.arrInfo.againPassword = "fz" + value.slice(-6);
                this.idCardError = "建议使用15位或18位身份证号或护照号";
                callback(); // 不阻止提交
              } else {
                this.arrInfo.password = "fz" + value.slice(-6);
                this.arrInfo.againPassword = "fz" + value.slice(-6);
                callback(); // 验证通过
              }
            },
          },
        ],
        email: [
          {
            validator: (rule, value, callback) => {
              let that = this;
              var reg =
                /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
              if (value == null) {
                callback();
              } else if (value != 0 && !reg.exec(value)) {
                callback(new Error("请输入有效邮箱地址"));
              } else {
                callback();
              }
            },
            trigger: "change",
          },
        ],
        mobile: [
          {
            validator: checkPhone,
            trigger: "change",
          },
        ],
        short: [
          {
            validator: (rule, value, callback) => {
              let that = this;
              var reg = /^\d{6}$/;
              if (value == null) {
                callback();
              } else if (value != 0 && !reg.exec(value)) {
                callback(new Error("请输入有效短号"));
              } else {
                callback();
              }
            },
            trigger: "change",
          },
        ],
        qq: [
          {
            validator: (rule, value, callback) => {
              let that = this;
              var reg = /^[0-9]*$/;
              if (value == null) {
                callback();
              } else if (value != 0 && !reg.exec(value)) {
                callback(new Error("请输入有效QQ"));
              } else {
                callback();
              }
            },
            trigger: "change",
          },
        ],
      },

      // 家庭信息
      familyForm: {
        familyId: "",
        title: "",
        name: "",
        telephone: "",
        company: "",
        position: "",
        isEmer: false,
      },
      familyFormReset: {},
      familyRules: {
        title: [
          {
            required: true,
            message: "请输入家庭成员称谓",
            trigger: "change",
          },
          {
            min: 0,
            max: 20,
            message: "长度在 20 个字符内",
            trigger: "change",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入家庭成员姓名",
            trigger: "change",
          },
          {
            min: 0,
            max: 20,
            message: "长度在 20 个字符内",
            trigger: "change",
          },
        ],
        telephone: [
          {
            required: true,
            message: "请输入成员联系电话",
            trigger: "change",
          },
          {
            validator: checkPhone,
            trigger: "change",
          },
        ],
        company: [
          {
            required: true,
            message: "请输入成员工作单位",
            trigger: "change",
          },
          {
            min: 0,
            max: 20,
            message: "长度在 20 个字符内",
            trigger: "change",
          },
        ],
        position: [
          {
            required: true,
            message: "请输入成员工作职务",
            trigger: "change",
          },
          {
            min: 0,
            max: 20,
            message: "长度在 20 个字符内",
            trigger: "change",
          },
        ],
      },

      // 教育经历
      educationForm: {
        educationId: "",
        title: "", //学位
        school: "", //学校名称
        date: [],
      },
      educationFormReset: null,
      educationRules: {
        school: [
          {
            required: true,
            message: "请输入学校名称",
            trigger: "change",
          },
          {
            min: 0,
            max: 20,
            message: "长度在 20 个字符内",
            trigger: "change",
          },
        ],
        title: [
          {
            required: true,
            message: "请输入学位名称",
            trigger: "change",
          },
          {
            min: 0,
            max: 20,
            message: "长度在 20 个字符内",
            trigger: "change",
          },
        ],
        date: [
          {
            required: true,
            message: "请选择日期范围",
            trigger: "change",
          },
        ],
      },

      imageUrl: "",
    };
  },
  methods: {
    // 删除教育经历信息
    onRemoveEducation(_DataID) {
      let that = this;
      let arrTemp = that.arrInfo.educations.filter((item) => {
        if (item.educationId != _DataID) return item;
      });
      that.arrInfo.educations = JSON.parse(JSON.stringify(arrTemp));
    },
    // 验证提交教育经历信息
    onVerificationEducation() {
      let that = this;
      // 验证
      that.$refs["educationForm"].validate((valid) => {
        if (valid) {
          let arrTemp = {
            educationId: onGetRandomGUID(),
            title: that.educationForm.title,
            startDate: that.educationForm.date[0],
            endDate: that.educationForm.date[1],
            school: that.educationForm.school,
            description: "",
          };
          that.arrInfo.educations.push(JSON.parse(JSON.stringify(arrTemp)));
          that.dialogVisiblEducation = false;
        } else {
          return false;
        }
      });
    },
    // 删除家庭成员信息
    onRemoveMember(_DataID) {
      let that = this;
      let arrTemp = that.arrInfo.families.filter((item) => {
        if (item.familyId != _DataID) return item;
      });
      that.arrInfo.families = JSON.parse(JSON.stringify(arrTemp));
    },
    // 验证提交家庭成员信息
    onVerificationFamily() {
      let that = this;
      // 验证
      that.$refs["familyForm"].validate((valid) => {
        if (valid) {
          // 随机临时ID
          that.familyForm.familyId = onGetRandomGUID();
          that.arrInfo.families.push(
            JSON.parse(JSON.stringify(that.familyForm))
          );
          that.dialogVisibleFamily = false;
        } else {
          return false;
        }
      });
    },
    // 添加家庭成员
    addFamily() {
      let that = this;
      that.familyForm = JSON.parse(JSON.stringify(that.familyFormReset));
      that.$nextTick(() => {
        that.$refs["familyForm"].resetFields();
      });
      that.dialogVisibleFamily = true;
    },
    // 添加教育经历
    addEducation() {
      let that = this;
      that.educationForm = JSON.parse(JSON.stringify(that.educationFormReset));
      that.$nextTick(() => {
        that.$refs["educationForm"].resetFields();
      });
      that.dialogVisiblEducation = true;
    },
    // 关闭弹出窗
    handleFamilyClose() {
      let that = this;
      that.dialogVisibleFamily = false;
    },
    // 关闭教育经历弹出窗
    handleEducationClose() {
      let that = this;
      that.dialogVisiblEducation = false;
    },
    // 入学年份
    onGetEntryYear(that) {
      let nowYear = parseInt(new Date().getFullYear()) + 1;
      for (let i = nowYear; i > nowYear - 20; i--) {
        let arr = {
          value: i,
          label: i,
        };
        that.optEntryYear.push(arr);
      }
    },
    // 获取学院
    onGetClass(that) {
      that.fullscreenLoading = true;
      ajaxGet(that, "/api/admin/student/status", null, function (resStatus) {
        that.optStatus = resStatus;
        ajaxGet(
          that,
          "/api/admin/sysuser/userfilter",
          null,
          function (resData) {
            let arrMajor = [];
            for (let item of resData) {
              let arr = {
                label: item.major,
                options: that.onGetMajor(that, resData, item.major),
              };
              arrMajor.push(arr);
            }
            that.optClass = JSON.parse(
              JSON.stringify(that.onArrUnique(arrMajor))
            );
            that.fullscreenLoading = false;
          }
        );
      });
    },
    // 获取专业数据
    onGetMajor(that, _data, _type) {
      let arrTemp = [];
      for (let item of _data) {
        let arr = [];
        if (item.major == _type) {
          arr = {
            value: item.classId,
            label: item.alias,
          };
          arrTemp.push(arr);
        }
      }
      return arrTemp;
    },
    // 去除重复数据
    onArrUnique(arr) {
      const res = new Map();
      return arr.filter((arr) => !res.has(arr.label) && res.set(arr.label, 1));
    },
    // 民族
    onGetNation(that) {
      that.fullscreenLoading = true;
      ajaxGet(that, "/api/admin/nation", null, function (resData) {
        that.optNation = resData.filter((item) => {
          if (item.enable) return item;
        });
        that.fullscreenLoading = false;
      });
    },
    // 政治面貌
    onGetPolitical(that) {
      that.fullscreenLoading = true;
      ajaxGet(that, "/api/admin/political", null, function (resData) {
        that.optPolitical = resData.filter((item) => {
          if (item.enable) return item;
        });
        that.fullscreenLoading = false;
      });
    },
    // 性别
    onGender(that) {
      that.fullscreenLoading = true;
      ajaxGet(that, "/api/admin/gender", null, function (resData) {
        that.optGender = resData;
        that.fullscreenLoading = false;
      });
    },
    // 班级
    handleChangeClass(_data) {
      let that = this;
      that.arrInfo.classId = _data;
    },
    // 民族
    handleChangeNation(_data) {
      let that = this;
      that.arrInfo.nationId = _data;
    },
    // 政治面貌
    handleChangePolitical(_data) {
      let that = this;
      that.arrInfo.politicalId = _data;
    },
    //性别
    handleChangeGender(_data) {
      let that = this;
      that.arrInfo.genderId = _data;
    },

    // 上传照片
    uploadSectionFile(param) {
      let that = this;
      var fileObj = param.file;
      // 接收上传文件的后台地址
      var url = "/api/admin/file/upload";
      let files = new FormData();
      files.append("file", fileObj);
      files.append("tag", "avatar"); //头像存放地址
      that.fullscreenLoading = true;
      ajaxUpload(that, url, files, function (r) {
        that.fullscreenLoading = false;
        if (r.result == true) {
          let file = {
            uid: param.file.uid,
            url: that.$ServerUrl() + r.path,
            path: r.path,
            name: param.file.name,
            size: param.file.size,
          };

          that.arrInfo.photo = file.path;
          that.imageUrl = file.url;
        } else {
          warning(that, r.message);
        }
      });
    },
    beforeAvatarUpload(file) {
      let that = this;
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < webUploadImgSize();
      if (!isJPG) {
        warning(that, "上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        warning(that, "上传头像图片大小不能超过 " + webUploadImgSize() + "M!");
      }
      return isJPG && isLt2M;
    },
    //返回上一层
    backHistory() {
      this.$router.go(-1);
    },
    // 返回上一页
    onBack() {
      let that = this;
      back(that);
    },
    //验证表单
    onVerification(objForm) {
      let that = this;
      // 验证
      that.$refs[objForm].validate((valid) => {
        if (valid) {
          let tpParams = JSON.parse(JSON.stringify(that.arrInfo));
          tpParams.studentRoles = [];
          for (var it of that.studentRoles) {
            tpParams.studentRoles.push({
              roleId: it,
            });
          }
          tpParams.password = that.$md5(that.arrInfo.password);
          let apiUrl = "/api/admin/student";
          that.fullscreenLoading = true;
          if (tpParams.birthday != "" && tpParams.birthday != null) {
            tpParams.birthday = timeTransform(that.arrInfo.birthday);
          }
          tpParams.status = setInt(tpParams.status);

          ajaxPost(that, apiUrl, tpParams, function (resMessage) {
            that.onBack();
            that.fullscreenLoading = false;
          });
        } else {
          return false;
        }
      });
    },
    // 提交数据
    onPostData(that) {},
    // 重置
    onReset() {
      let that = this;
      that.arrInfo = JSON.parse(JSON.stringify(that.arrInfoReset));
      try {
        that.$refs["arrInfo"].resetFields();
      } catch {}
    },
    // 重置家庭信息
    onFamilyReset() {
      let that = this;
      that.familyForm = JSON.parse(JSON.stringify(that.familyFormReset));
      try {
        that.$refs["familyForm"].resetFields();
      } catch {}
    },
    // 重置教育经历
    onEducationReset() {
      let that = this;
      that.educationForm = JSON.parse(JSON.stringify(that.educationFormReset));
      try {
        that.$refs["educationForm"].resetFields();
      } catch {}
    },
  },
  created() {
    //先进行下拉数据的创建
    let that = this;
    that.onGetEntryYear(that);
    // 民族
    that.onGetNation(that);
    // 政治面貌
    that.onGetPolitical(that);
    // 学院/专业/班级
    that.onGetClass(that);
    //性别
    that.onGender(that);
    that.arrInfoReset = JSON.parse(JSON.stringify(that.arrInfo));
    that.familyFormReset = JSON.parse(JSON.stringify(that.familyForm));
    that.educationFormReset = JSON.parse(JSON.stringify(that.educationForm));
    try {
      that.$refs["arrInfo"].resetFields();
    } catch {}
    ajaxGet(that, "/api/admin/studentRoleInfo", null, function (r1) {
      that.roles = r1;
      for (var item of r1) {
        if (item.isDefault == true) {
          that.studentRoles.push(item.roleId);
        }
      }
    });
  },

  filters: {
    // 过滤时间
    filterDate(_data) {
      return dateFormat(_data, ".", "YYYY-MM");
    },
  },
};

// 验证手机号码
let checkPhone = (rule, value, callback) => {
  let that = this;
  var reg = /^1\d{10}$/;
  if (value == null) {
    callback();
  } else if (value != 0 && !reg.exec(value)) {
    callback(new Error("请输入有效的手机号码"));
  } else {
    callback();
  }
};
</script>

<style scoped="scoped">
.studentName {
  font-size: 32px;
  padding-top: 15px;
}

.studentNo {
  font-size: 16px;
  color: #767676;
}

.remarksCont {
  font-size: 14px;
  color: #b0a8bb;
}

.schoolInfo {
  color: #4d5669;
}

.schoolInfo .spTime {
  color: #aeaebe;
  font-size: 14px;
}

.schoolLevel {
  padding-top: 5px;
}

.schoolLevel .spFont {
  font-size: 14px;
  color: #aeaebe;
  border: 1px solid #dfdfdf;
  border-radius: 30px;
  padding: 3px 30px;
}

.hobbiesCont {
  padding-bottom: 15px;
  color: #465167;
}

.hobbiesCont .spTitle {
  color: #aeaebe;
  border: 1px solid #e0e0e0;
  padding: 2px 25px;
  border-radius: 30px;
  font-size: 14px;
  margin-right: 15px;
}

.hobbiesCont .txtInput {
  width: 100%;
  margin-top: 15px;
  padding-left: 2px;
  padding-right: 2px;
  border: none;
}

.otherInfo {
  padding-bottom: 0px;
  padding-left: 0px;
}

.otherInfo .spTitle {
  color: #3b465d;
}

.otherInfo .spFonts {
  color: #a8a8a8;
}

.otherInfo .txtInput {
  border: none;

  padding-left: 10px;
  padding-right: 10px;
  color: #a8a8a8;
  letter-spacing: 1px;
}

.otherInfo .txtInput textarea {
  text-decoration: none !important;
}

.otherInfo .txtInput::-webkit-input-placeholder {
  color: #a8a8a8;
}

.otherInfo .txtInput::-moz-placeholder {
  color: #a8a8a8;
}

.otherInfo .txtInput:-ms-input-placeholder {
  color: #a8a8a8;
}

.titleCont {
  position: relative;
  padding-left: 20px;
  color: #323e56;
  letter-spacing: 2px;
}

.titleCont .spIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 20px;
  border-radius: 5px;
  background-image: linear-gradient(#4545ff, #2d8eff);
}

.QRCodeBox {
  position: absolute;
  top: 0;
  right: 30px;
  bottom: 0;
}

.QRCodeBox img {
  width: 150px;
}

.infoList {
  padding-bottom: 17px;
}

.infoList .spName {
  font-size: 32px;
  color: #212e48;
  padding-right: 30px;
  height: 40px;
}

.infoList .spGender {
  font-size: 32px;
  padding-right: 30px;
  height: 40px;
}

.infoList .spLabel {
  height: 40px;
}

.infoList .spLabel label {
  position: absolute;
  top: 10px;
  background-color: #dd4b39;
  border: 1px solid #cf3421;
  border-radius: 5px;
  font-weight: normal;
  color: #ffffff;
  padding: 2px 10px;
  margin-bottom: 0;
}

.infoList .spFonts {
  color: #3b475d;
  padding-right: 30px;
  padding-left: 30px;
  border-right: 1px solid #d6d6d6;
}

.infoList .spFonts:first-child {
  padding-left: 0;
}

.infoList .spFonts:last-child {
  border-right: none;
}

.infoList .spMedal {
  padding-right: 15px;
}

.infoList .spFonts .faxSize {
  font-size: 18px;
}

.infoList .spFonts .icon {
  color: #b1b1b1;
}

.addSchoolInfo,
.addFamilyList {
  border: 2px solid #f0f0f0;
  border-radius: 5px;
  padding: 5px;
  position: relative;
  height: 149px;
  color: #dddddd;
  font-size: 56px;
}

.addSchoolInfo {
  height: 50px;
  font-size: 32px;
}

.addSchoolInfo:hover,
.addFamilyList:hover {
  cursor: pointer;
  color: #28a745;
}

.familyList {
  border: 2px solid #f0f0f0;
  border-radius: 5px;
  padding: 5px;
  position: relative;
  overflow: hidden;
}

.familyList .divTopCont {
  padding-left: 80px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.familyList .divTopCont .appellativeCont {
  background-color: #069df7;
  color: #ffffff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 22px;
  font-weight: bold;
  position: absolute;
  top: 15px;
  left: 15px;
}

.familyList .divTopCont .memberName {
  font-size: 18px;
  color: #303c54;
  font-weight: bold;
}

.familyList .divTopCont .memberName .spLabel {
  font-size: 12px;
  background-color: #e7f2ff;
  color: #2296ec;
  padding: 4px 10px;
  border-radius: 4px;
}

.familyList .divTopCont .memberPhone {
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 1px;
  color: #313d55;
}

.familyList .divTopCont .memberPosition .spFont {
  background-color: #7582a5;
  color: #ffffff;
  font-size: 14px;
  padding: 3px 10px;
}

.familyList .divBottomCont {
  border-top: 1px solid #f8f8f8;
  padding-top: 5px;
}

.familyList .divBottomCont .spFont {
  background-color: #f9f9f9;
  color: #3e4960;
  display: block;
  text-align: center;
  padding: 5px;
  letter-spacing: 1px;
}

.familyList .emergencyCont {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #dc3545;
  color: #ffffff;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 0px 5px 0px 5px;
  cursor: pointer;
}

.familyList .emergencyCont:hover {
  background-color: #c82333;
}

.dormAddrCont {
  font-size: 16px;
  color: #38435a;
}

.dormTimeCont {
  font-size: 14px;
  color: #c7c7d1;
}

.divStuAva {
  padding-top: 30px;
}

.divStuAva .spImgFont {
  font-size: 12px;
  color: #767676;
}

.spNullData {
  color: #dddddd;
}

.ruleForm .el-form-item {
  margin-bottom: 0;
}

.educationList .divTopCont {
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
}
</style>


<style>
.divStuAva .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.divStuAva .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.divStuAva .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 180px;
  height: 252px;
  text-align: center;
  line-height: 252px;
}

.divStuAva .avatar {
  width: 180px;
  display: block;
  border-radius: 5px;
}

.otherInfo .spFonts .selAddr .el-input__inner,
.otherInfo .spFonts .selSingle .el-input__inner,
.otherInfo .spFonts .txtDateInput .el-input__inner {
  height: 25px;
  line-height: 25px;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #dddddd;
  color: #a8a8a8;
  font-size: 16px;
}

.otherInfo .spFonts .txtDateInput .el-input__suffix,
.otherInfo .spFonts .txtDateInput .el-input__prefix {
  top: -8px;
}

.otherInfo .spFonts .selSingle .el-input__suffix {
  top: 7px;
}

.otherInfo .spFonts .selSingle .el-input__suffix-inner {
  position: absolute;
  right: 0px;
  top: -12px;
}

.cascaderMajor .el-input__inner::-webkit-input-placeholder,
.cascaderMajor .el-input__inner:-moz-placeholder,
.cascaderMajor .el-input__inner::-moz-placeholder,
.cascaderMajor .el-input__inner:-ms-input-placeholder {
  color: #606266;
}
</style>
