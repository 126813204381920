<!--
  综合课堂 操作页面
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{Title}}
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item label="类型名称" prop="name">
                <el-input v-model="ruleForm.name" placeholder="请输入综合课堂类型"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="优先级" prop="priority">
                <el-input @change="onPriorityChange()" v-model="ruleForm.priority" placeholder="请输入优先级"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="所属类型" prop="parentId">

                  <treeSelect :key="random" @getValue="getValue" :options="optData" :value="ruleForm.parentId">
                  </treeSelect>

              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item label="类型描述" prop="description">
                <el-input type="textarea" :rows="4" placeholder="请输入综合课堂类型描述" v-model="ruleForm.description">
                </el-input>
              </el-form-item>
            </el-col>

          </el-row>
        </div>
      </el-card>
       <div v-if="configs.length>0">
        <SuYanType
          ref="LiteracyRef"
          :configList='configs' 
          :defaultConfig ='defaultConfigs'
          @change="handleTypesChange"
        />
      </div>
      <div class="row" style="margin-top: 10px;">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
          <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
          <el-button type="primary" icon="el-icon-circle-check" @click="onSave()">提交</el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import treeSelect from '@/components/TreeSelect.vue';
  import SuYanType from '@/components/Admin/suYanLeiXing.vue'
  export default {
    components: {
      treeSelect,
      SuYanType
    },
    data() {
      return {
        // 所属分类数据
        optData: [],
        // 刷新所属类型组件
        random: 0,
        configs:[],
        defaultConfigs:[],
        currentId: null,
        //表单数据
        ruleForm: {
          typeId: null, //类型主键，新增时为null
          name: null, //类型
          priority: null, //优先级
          parentId: null, // 父节点ID
          description: null,
          configs: [], //综合类型集合，放在ruleForm里是为了选中后进行验证
        },
        //重置时的表单数据
        ruleFormReset: null,
        // 页面名称
        Title: "新增综合课堂类型",
        // 加载动画
        fullscreenLoading: false,
        // 验证
        rules: {
          name: [{
              required: true,
              message: '综合课堂类型不能为空',
              trigger: 'change'
            },
            {
              max: 128,
              message: '综合课堂类型不能超过128个字符',
              trigger: 'change'
            },
          ],
          priority: [{
            validator: (rule, value, callback) => {
              let that = this;
              var reg = /^-?[1-9]\d*$/;
              if (value != 0 && !reg.exec(value)) {
                callback(new Error("优先级只能输入整数！"));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }],
        },
      };
    },
    created() {
      let _this = this;
      let dataID = _this.$route.params.id;
      var detailUrl = '';
      if (dataID != null && typeof(dataID) != "undefined" && dataID != "") {
        _this.Title = "编辑类型";
        _this.ruleForm.reasonId = dataID;
        // 编辑API
        detailUrl = "/api/admin/literacycoursetype/" + dataID;
      }

      onGetData(_this, detailUrl);

    },
    methods: {
      handleTypesChange(val){
        this.ruleForm.GrowthConfigs = JSON.parse(JSON.stringify(val))
      },
      // 所属类型ID
      getValue(value) {
        let _this = this;
        _this.ruleForm.parentId = value;

      },
      // 重置
      onReset() {
        let _this = this;
        _this.ruleForm = JSON.parse(JSON.stringify(_this.ruleFormReset));
        _this.$refs.LiteracyRef.onReset()
        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        _this.random += random;
        try {
          _this.$refs["ruleForm"].resetFields();
        } catch {}
      },
      // 优先级
      onPriorityChange() {
        let _this = this;
        var value = setInt(_this.ruleForm.priority);
        _this.ruleForm.priority = value;
      },
      // 提交（新增和编辑）
      onSave() {
        let _this = this;
        // 默认优先级
        let isError = false
        _this.$refs.LiteracyRef.validateForm((flag) =>{
          isError = flag
        })
        if (_this.ruleForm.priority == null || _this.ruleForm.priority == "") {
          _this.ruleForm.priority = "0"
        }

        // 验证
        _this.$refs["ruleForm"].validate(valid => {
          if (valid && isError) {
            // 配置综合类型
          let configs = [];
          for (let i in _this.ruleForm.GrowthConfigs) {
            let config = _this.ruleForm.GrowthConfigs[i];
            if (config.isSelect == true) {
              let newConfig = {
                configId: config.configId,
                score: config.score
              };
              configs.push(newConfig);
            }
          }
          _this.ruleForm.configs = configs;
            // 判断是否为新增
            if (_this.ruleForm.typeId == null) {
              _this.fullscreenLoading = true;
              ajaxPost(_this, "/api/admin/literacycoursetype", _this.ruleForm, function(r) {
                _this.fullscreenLoading = false;
                _this.currentId = _this.ruleForm.parentId;
                _this.onBack();
              });
            } else {
              _this.fullscreenLoading = true;
              ajaxPut(_this, "/api/admin/literacycoursetype/" + _this.ruleForm.typeId, _this.ruleForm, function(r) {
                _this.fullscreenLoading = false;
                _this.currentId = _this.ruleForm.parentId;
                _this.onBack();
              });
            }
          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              isError[0].querySelector('input').focus();
            }, 1);
            return false;
          }
        });
      },
      // 返回
      onBack() {
        let _this = this;
        back(_this);
      },

    }
  };

  // 获取数据
  function onGetData(that, _apiUrl) {
      that.fullscreenLoading = true;
      ajaxGet(that, _apiUrl, null, function(r) {
        if (r != null) {
          that.ruleForm = r;
          that.defaultConfigs = r.configs
        }
        ajaxGet(that, "/api/admin/GrowthConfig", null, function(r1) {
          that.configs = r1.filter(val => {return val.configType == 1});
          that.fullscreenLoading = false;
          that.$set(that.ruleForm, "GrowthConfigs", r1);
          that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
        });
      });
  }




</script>

<style scoped="scoped">
  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }

  .spIcon {
    width: 5px;
    height: 20px;
    border-radius: 5px;
    background-image: linear-gradient(#4545FF, #2D8EFF);
  }

  .spTitle {
    float: left;
    margin-left: 20px;
    color: #323E56;
    letter-spacing: 2px;
    font-size: 16px;
    margin-top: -3px;
  }
</style>
