<template>

  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">


    <el-card class="box-card">
      <div slot="header" class="card-header">
        <div style="float:left">
          <ul class="nav nav-pills navTabsCont">
            <li class="liItemBox" v-for="(p,index) in optTabs" :key="index" :class="{liActive:currentTabs==index}">
             <span @click="onChangeTabs(index)">
               {{p.attendanceTypeStr}}
             </span>
            </li>
          </ul>
        </div>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <el-date-picker v-model="startDate" type="date" placeholder="选择日期" @change="onChangeDate">
              </el-date-picker>
            </li>
          </ul>
        </div>
      </div>
    </el-card>

    <el-row :gutter="20" class="elRowBox" v-if="!regNullArray(itemData)">
      <el-col :md="12" :xs="24" v-for="(item,index) in itemData" :key="index" class=" mb-3">

        <el-card class="box-card" style="height: 100%;">
          <div slot="header" class="card-header">
            <h3 class="card-title titleCont">
              <span class="spIcon"></span>
              {{item.text}}
            </h3>
            <div class="card-tools">
              <ul class="nav">
                <li class="nav-item">
                  <el-button @click="onGoNoPunchin(item.id)" >未打卡名单</el-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="card-body">

            <div class="divProgressBody" v-for="(it,index) in item.floorList" :key="index">
              <div class="divFloorName">{{it.floor}} 层</div>
              <div class="divGraph">
                <div class="progress">
                  <div class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" :style="'width: '+it.scale+'%'">{{it.scale}}%</div>
                </div>
              </div>
              <div class="divNumber">{{it.actual}}/{{it.should}}</div>
            </div>

          </div>
        </el-card>

      </el-col>
    </el-row>
    <div class="card-body " v-else>
      <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
    </div>


  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  export default {
    components: {},
    data() {
      return {
        fullscreenLoading: false,
        startDate: new Date(), // 默认当前时间
        attendanceId: null,
        itemData: [],
        optTabs: [],
        currentTabs: 0,
        optType: [],
      };
    },
    created() {
      let that = this;
      if(!regNullArray(that.$route.query.date)){
        that.startDate=that.$route.query.date;
      }
      onGetData(that);
    },
    methods: {
      //切换头部
      onChangeTabs(index) {
        let that = this;
        that.currentTabs = index;
        that.attendanceId = that.optTabs[index].attendanceId;
        that.onGetStatistics();
      },
      onGoNoPunchin(id){
        let that = this;
        that.$router.push({
          path: "/Admin/Statistics/PunchInDetails",
          query: {
            attendanceId: that.attendanceId,
            BuildingId:id,
            date:timeTransform(that.startDate,'YYYY-MM-DD'),
            url: that.$router.history.current.fullPath
          }
        });
      },
      //获取统计数据
      onGetStatistics() {
        let that = this;
        ajaxGet(that, '/api/attendance/statisbybuilding?attendanceId=' + that.attendanceId, null, function(res) {
          if(!regNullArray(res)){
            that.itemData = res;
            that.fullscreenLoading = true; // 数据加载完成
            that.itemData.forEach(item => {
              ajaxGet(that, '/api/attendance/statisbyfloor?attendanceId=' + that
                .attendanceId + '&buildingId=' + item.id, null,
                function(resfloor) {
                  item.floorList = resfloor;
                });
              setTimeout(() => {
                that.fullscreenLoading = false; // 数据加载完成
              }, 1000);
            });
          }


        })
      },
      onChangeDate(){
        let that = this;
        onGetData(that)
      },
    },
    watch: {
      $route() {
        let that = this;
        onGetData(that);
      }
    }
  }

  function onGetData(that) {
    that.fullscreenLoading = true;
    that.itemData = [];
    let data = {
      StartDate: timeTransform(that.startDate, 'YYYY-MM-DD'),
      EndDate: timeTransform(that.startDate, 'YYYY-MM-DD'),
    }
    ajaxGet(that, '/api/attendance/search', data, function(res) {
      that.fullscreenLoading = false;
      if (!regNullArray(res.items)) {
        that.optTabs = res.items;
        that.attendanceId = that.optTabs[that.currentTabs].attendanceId;
        that.onGetStatistics();
      }
    });
  }
</script>

<style scoped="scoped" lang="less">
  .elRowBox{
    display: flex;
    flex-wrap: wrap;
  }
  .divProgressBody{
    display: flex;
    padding:10px 0;
    .divFloorName{
      width: 80px;
      text-align: right;
      padding-right: 15px;
    }
    .divGraph{
      padding-top: 5px;
      flex: 1;
    }
    .divNumber{
      padding-left: 15px;
      width: 100px;
      text-align: left;
    }
  }


  .navTabsCont {
    .liItemBox {
      font-size: 12px;
      border: 1px solid #FFFFFF;
      padding: 0.5rem 1rem;
      border-radius: 0.25rem;
      color: #6c757d;
    }

    .liActive {
      background: #007bff;
      color: #FFFFFF;
    }
  }
</style>
