<!--
  登录日志
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <search
      :ColNumber="2"
      :SearchList="SearchList"
      ref="SearchList"
      @onSearch="onSearch"
      v-if="SearchList != null"
    >
    </search>
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px">操作日志</h3>
      </div>
      <div class="card-body p-0" v-if="item != null && item.length != 0">
        <el-table :data="item" stripe class="elTableData">
          <!-- <el-table-column type="index" label="序号" width="80"></el-table-column> -->
          <el-table-column
            prop="name"
            label="姓名"
            class="elColumn"
            min-width="120"
          ></el-table-column>
          <el-table-column
            prop="url"
            label="链接地址"
            class="elColumn"
            min-width="600"
          ></el-table-column>
          <el-table-column
            prop="message"
            label="操作内容"
            class="elColumn"
            min-width="400"
          ></el-table-column>
          <el-table-column label="操作时间" class="elColumn" min-width="140">
            <template slot-scope="scope">
              {{ scope.row.createDate | dateformat }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100" fixed="right">
            <template slot-scope="scope">
              <operationBtn
                :btnName="'查看'"
                @click.native="onDetails(scope.row.logId)"
              ></operationBtn>
            </template>
          </el-table-column>
        </el-table>
        <comPage
          :paging="page"
          :pageBtnList="pageBtnList"
          @pagingClick="pagingClick"
          @pageBtnClick="pageBtnClick"
        >
        </comPage>
      </div>

      <div class="card-body" v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
  </div>
</template>

<script>
import "@/assets/css/Admin.css";
import comPage from "@/components/Page.vue";
import operationBtn from "@/components/operationBtn.vue";
import search from "@/components/search.vue";

export default {
  name: "",
  components: {
    comPage,
    operationBtn,
    search,
  },
  data() {
    return {
      fullscreenLoading: false,
      page: null, //分页查询接口返回的结果
      item: [], //列表数据
      pageBtnList: null, //分页左侧按钮集合
      paging: {
        //分页数据
        pageLength: 0, // 总共页数
        Size: 10, // 当前请求数量
        Index: 1, //当前在第几页
      },
      SearchList: null, //搜索集合
      isPower: {
        //增删改查 权限管理
        parentactivity_details: false,
      },
    };
  },
  created() {
    let that = this;

    pagingPage(that);
  },
  mounted() {},
  methods: {
    onDetails(Id) {
      let that = this;
      that.$router.push({
        path: "/Admin/OperationLog/Teacher/Details/" + Id,
        query: {
          url: that.$router.history.current.fullPath,
        },
      });
    },
    onSearch(params) {
      let that = this;
      let data = {};

      let searchData = params;
      searchData.forEach((element) => {
        if (element.data) {
          data[element.zhi] = Join(element.data);
        }
      });
      data.PageNumer = 1;

      routerPath(
        that,
        "/Admin/OperationLog/Teacher/Index",
        data,
        null,
        "PageSize"
      );
    },
    pageBtnClick(index) {
      //分页组件左侧的按钮事件，按顺序进行判断
    },
    pagingClick(type, val) {
      let that = this;
      let url = "/Admin/OperationLog/Teacher/Index";
      if (type == 1) {
        //更改每页条数触发的事件
        routerPath(
          that,
          url,
          "PageSize",
          val,
          "PageNumer",
          "Name",
          "Time",
          "Message"
        );
      } else {
        //更改当前页时触发的事件
        routerPath(
          that,
          url,
          "PageNumer",
          val,
          "PageSize",
          "Name",
          "Time",
          "Message"
        );
      }
    },
  },
  watch: {
    $route() {
      let that = this;
      pagingPage(that);
    },
  },
};

function pagingPage(that) {
  paging(
    that,
    function (r) {
      let Myurl = "/api/admin/oplog/search";
      that.fullscreenLoading = true;
      console.log(r, "rrrrrr");
      that.SearchList = [
        {
          type: "input",
          Name: "姓名",
          data: r.Name,
          holder: "请输入姓名关键字",
          zhi: "Name",
        },
        {
          type: "time",
          Name: "操作时间",
          data: r.Time,
          zhi: "Time",
        },
        {
          type: "input",
          Name: "操作内容",
          data: r.Message,
          holder: "请输入操作内容关键字",
          zhi: "Message",
        },
      ];
      let data = {};
      if (r.Time != null) {
        var DateList = r.Time.split(",");
        data = {
          PageNumer: r.PageNumer,
          PageSize: r.PageSize,
          Name: r.Name,
          Message: r.Message,
          StartTime: DateList[0],
          EndTime: DateList[1],
        };
      } else {
        data = {
          PageNumer: r.PageNumer,
          PageSize: r.PageSize,
          Name: r.Name,
          Message: r.Message,
        };
      }
      ajaxGet(that, Myurl, data, function (r1) {
        that.page = r1;
        that.item = r1.items;

        that.fullscreenLoading = false;
      });
    },
    "Name",
    "Time",
    "Message"
  );
}
</script>

<style scoped="scoped"></style>
