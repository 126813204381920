<!--
  留宿名单 —— 新增
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="ruleForm">

      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{Title2}}{{Title}}名单
          </h3>
        </div>
        <div class="card-body tabListCont ">
          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item :label="`${Title}时间`" prop="date">
                <el-date-picker v-model="ruleForm.date" type="datetimerange" range-separator="至" style="width:100%"
                  start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd HH:mm">
                </el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item :label="`${Title}原因`" prop="reason">
                <el-input class="formControl" v-model="ruleForm.reason" type="textarea" :rows="4" show-word-limit
                  :placeholder="`请输入${Title}原因`" maxlength="500">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>
      <!-- 学生名单 -->
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont" style="float: left;">
            <span class="spIcon"></span>
            学生名单
          </h3>
          <div class="card-tools">
            <ul class="nav">
              <li class="nav-item">
                <el-button type="success" icon="el-icon-circle-plus-outline" size="mini"
                  @click="onOpenDialogVisibleForBed()" v-if="isCreate">
                  新增学生名单
                </el-button>
              </li>
              <li class="nav-item">
                <el-button type="danger" icon="el-icon-close" size="mini" @click="onEmptyList()" v-if="isCreate">清空名单
                </el-button>
              </li>
            </ul>
          </div>
        </div>
        <div class="card-body tabListCont">
          <el-row :gutter="20" v-if="ruleForm.members!=null && ruleForm.members.length>0">
            <el-col :lg="8" :md="8" :sm="12" v-for="(p,index) in ruleForm.members" :key="index">
              <div class="stuInfo">
                <div class="stuInfoBox">
                  <div class="stuImgCont VerticalMid">
                    <el-image :src="p.photo">
                      <div slot="error" class="image-slot">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#fax-use-circle-alt"></use>
                        </svg>
                      </div>
                    </el-image>
                  </div>
                  <div class="divInfoBox">
                    <div class="stuName">{{p.name}}</div>
                    <div class="stuOther">{{p.studentNo}}</div>
                    <div class="stuRole">{{p.className}}</div>
                  </div>
                </div>
                <div class="stuRoom">
                  {{p.buildingName}}（楼）{{p.floorName}}（层）{{p.roomName}}（室）{{p.bedName}}（床位）
                </div>

                <div class="stuRemove color-danger">
                  <i class="el-icon-error" @click="onDeleteMember(p.studentId)" v-if="isCreate"></i>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24" v-else>
            <el-col :span="24" style="text-align: center;line-height: 40px;font-size: 14px;color: #909399;">
              请选择学生
            </el-col>
          </el-row>
        </div>
      </el-card>
    </el-form>


    <div class="row" style="margin-top: 10px;">
      <div class="col-12 ">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
        <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
        <el-button type="primary" icon="el-icon-circle-check" @click="onSave()">提交</el-button>
      </div>
    </div>

    <el-dialog title="添加学生名单" :visible.sync="dialogVisibleForBed" width="30%" :before-close="handleClose">
      <div class="divDialogBox">
        <el-form class="ruleForm">

          <el-form-item label="学生">
            <el-cascader class="width_100Pie" v-model="currentBedId" :options="optRooms" :show-all-levels="false"
              filterable :props="{ expandTrigger: 'hover',label:'name',value:'id',children:'children' }">
            </el-cascader>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleForBed = false">取 消</el-button>
        <el-button type="primary" @click="onChangeBed">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="失败原因" :visible.sync="dialogVisibleError" width="30%" :before-close="handleCloseError">
      <el-table :data="errorTableData" style="width: 100%">
        <el-table-column prop="index" label="序号" width="80px"></el-table-column>
        <el-table-column prop="reason" label="原因"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseError">关 闭</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
  import '@/assets/css/Admin.css';

  export default {
    components: {},
    data() {
      return {
        // 加载动画
        fullscreenLoading: false,
        //添加学生信息弹出框
        dialogVisibleForBed: false,
        //新增失败原因弹窗
        dialogVisibleError: false,
        //刷新组件
        random: 0,
        // 表单对象
        ruleForm: {
          id: null,
          date: [],
          startDate: null,
          endDate: null,
          bedId: null,
          studentId: null,
          reason: null,
          members: []
        },
        ruleFormReset: null,
        // 学生数组
        studentItem: [],
        InitStudentItem: [],
        // 选择学生的ID
        currentStudentId: null,
        currentBedId: null,
        // 验证
        rules: {
          reason: [{
            required: true,
            message: '请输入留宿原因',
            trigger: 'change'
          }],
          date: [{
            required: true,
            message: '请输入留宿时间',
            trigger: 'change'
          }],
        },
        // 宿舍数组
        optRooms: [],
        InitRooms: [],
        roomsReset: [],
        currentType: null,
        Title: null,
        Title2: null,
        isCreate: true,
        errorTableData: [],


        TeacherInfo: {}, // 教师信息
      };
    },
    created() {
      let that = this;
      that.TeacherInfo = onGetNowUser(that);
      that.fullscreenLoading = true;
      that.currentType = that.$route.params.type ? that.$route.params.type : 1;
      that.ruleForm.id = that.$route.params.id;
      that.Title = that.currentType == 1 ? "留宿" : "停宿";
      that.Title2 = that.ruleForm.id ? "编辑" : "新增";
      that.isCreate = that.ruleForm.id ? false : true;
      let apiUrl = that.TeacherInfo.isTeacher ? '/api/admin/building/advisertree' : '/api/admin/building/usertree';



      ajaxGet(that, apiUrl, null, function(resData) {
        for (var building of resData) {
          building.id = building.buildingId;
          building.children = building.floors;
          for (var floor of building.children) {
            floor.id = floor.floorId;
            floor.children = floor.rooms;
            for (var room of floor.children) {
              room.id = room.roomId;
              room.roomName = room.name;
              room.name = room.name;
              room.children = room.beds;
              for (var bed of room.children) {
                bed.id = bed.bedId;
                bed.bedName = bed.name;
                bed.name = bed.name + "（" + bed.studentName + "，" + bed.className + "，" + bed.studentNo + "）";
              }
            }
          }
        }
        that.optRooms = resData;
        that.InitRooms = resData;
        that.roomsReset = JSON.parse(JSON.stringify(resData));
        that.fullscreenLoading = false;

      });

      if (that.ruleForm.id) { //编辑
        let url = "";
        if (that.currentType == 1) {
          url = "/api/admin/dormstay/" + that.ruleForm.id;
        } else {
          url = "/api/admin/dormsuspend/" + that.ruleForm.id;
        }
        ajaxGet(that, url, null, function(r) {
          that.ruleForm.date = [r.startDate, r.endDate];
          that.ruleForm.reason = r.reason;
          that.ruleForm.members.push({
            bedId: r.bedId,
            bedName: r.bed,
            buildingName: r.building,
            classId: r.classId,
            className: r.className,
            floorName: r.floor,
            id: null,
            photo: regNull(r.photo) ? '' : that.$ServerUrl() + r.photo,
            roomId: null,
            roomName: r.floor,
            score: null,
            name: r.name,
            studentId: r.studentId,
            studentNo: r.studentNo,
          })
          that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
        })

      }

    },
    methods: {
      /**
       * @description 确认床位
       * @return {void}
       */
      onChangeBed() {
        let that = this;
        if (that.currentBedId != null && that.currentBedId.length != 0) {
          let tempID = that.currentBedId[that.currentBedId.length - 1];
          for (let valBuilding of that.optRooms) {
            for (let valFloor of valBuilding.children) {
              for (let valRoom of valFloor.children) {
                for (let valbed of valRoom.children) {
                  if (valbed.bedId == tempID) {
                    that.ruleForm.members.push({
                      id: valbed.id,
                      studentId: valbed.studentId,
                      score: 0,
                      photo: regNull(valbed.photo) ? '' : that.$ServerUrl() + valbed.photo,
                      name: valbed.studentName,
                      studentNo: valbed.studentNo,
                      className: valbed.className,
                      bedId: valbed.bedId,
                      roomId: valRoom.roomId,
                      classId: valbed.classId,
                      bedName: valbed.bedName,
                      roomName: valRoom.roomName,
                      buildingName: valBuilding.name,
                      floorName: valFloor.name
                    });
                    break;
                  }
                }
              }
            }
          }
        }
        that.currentBedId = null;
        that.dialogVisibleForBed = false;
      },


      /**
       * @description 关闭弹出框
       * @return {void}
       */
      handleClose() {
        this.dialogVisibleForBed = false;
      },

      handleCloseError() {
        this.dialogVisibleError = false;
        this.ruleForm.members = [];
      },

      /**
       * @description 清空名单
       * @return {void}
       */
      onEmptyList() {
        this.ruleForm.members = [];
      },

      /**
       * @description 打开名单
       * @return {void}
       */
      onOpenDialogVisibleForBed() {
        let that = this;
        if (that.ruleForm.members != null && that.ruleForm.members.length > 0) {

          for (let item of that.ruleForm.members) {
            that.optRooms = that.onFilterData(that.optRooms, item.id);
          }
        } else {
          let apiUrl = that.TeacherInfo.isTeacher ? '/api/admin/building/advisertree' : '/api/admin/building/usertree';
          ajaxGet(that, apiUrl, null, function(resData) {
            for (var building of resData) {
              building.id = building.buildingId;
              building.children = building.floors;
              for (var floor of building.children) {
                floor.id = floor.floorId;
                floor.children = floor.rooms;
                for (var room of floor.children) {
                  room.id = room.roomId;
                  room.roomName = room.name;
                  room.name = room.name;
                  room.children = room.beds;
                  for (var bed of room.children) {
                    bed.id = bed.bedId;
                    bed.bedName = bed.name;
                    bed.name = bed.name + "（" + bed.studentName + "，" + bed.className + "，" + bed.studentNo + "）";
                  }
                }
              }
            }
            that.optRooms = resData;
          });
        }
        that.dialogVisibleForBed = true;

      },
      /**
       * @description 数据处理
       * @param {Object} arr 需要整理的数据
       * @param {Object} dataID 学生ID
       */
      onFilterData(arr, dataID) {
        let newArr = arr.filter(item => item.id != dataID);
        return newArr.map(item => {
          if (item.children) {
            item.children = this.onFilterData(item.children, dataID);
          }
          return item
        })
      },

      /**
       * @description 返回
       * @return {void}
       */
      onBack() {
        let that = this;
        back(that);
      },

      /**
       * @description 提交事件
       * @return {void}
       */
      onSave() {
        let that = this;
        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            if (that.ruleForm.members == null || that.ruleForm.members.length <= 0) {
              warning(that, "请选择学生");
              return false;
            }
            let data = {
              startDate: that.ruleForm.date[0],
              endDate: that.ruleForm.date[1],
              reason: that.ruleForm.reason,
            }

            data.startDate = timeTransform(that.ruleForm.date[0]);
            data.endDate = timeTransform(that.ruleForm.date[1]);

            that.fullscreenLoading = true;
            if (that.isCreate) {
              let studentIdList = [];
              that.ruleForm.members.forEach(ele => {
                studentIdList.push(ele.studentId);
              })
              data.studentIdList = studentIdList;

              let url = "";
              if (that.currentType == 1) { //留宿
                url = "/api/admin/dormstay";
              } else { //停宿
                url = "/api/admin/dormsuspend";
              }
              ajaxPostError(that, url, data, function(r) {
                that.fullscreenLoading = false;
                if (r.error != null && r.error.length > 0) {
                  that.errorTableData = r.error;
                  that.dialogVisibleError = true;
                  setTimeout(() => {
                    warning(that, r.message);
                  }, 200)
                } else {
                  that.onBack();
                }
              })
            } else {
              data.studentId = that.ruleForm.members[0].studentId;

              let url = "";
              if (that.currentType == 1) { //留宿
                data.stayId = that.ruleForm.id;
                url = "/api/admin/dormstay/" + that.ruleForm.id;
              } else { //停宿
                data.suspendId = that.ruleForm.id;
                url = "/api/admin/dormsuspend/" + that.ruleForm.id;
              }
              ajaxPut(that, url, data, function(r) {
                that.fullscreenLoading = false;
                that.onBack();
              })
            }

          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              var el = isError[0].querySelector('input') || isError[0].querySelector('textarea');
              if (el) {
                el.focus();
              }

            }, 1)
            return false;
          }
        });
      },

      /**
       * @description 重置事件
       * @return {void}
       */
      onReset() {
        let that = this;
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },

      /**
       * @description 删除学生名单
       * @param {Object} studentId 学生ID
       * @return {void}
       */
      onDeleteMember(studentId) {
        let that = this;
        that.ruleForm.members = that.ruleForm.members.filter(
          function(val) {
            return val.studentId != studentId
          }
        )
        var studentList = JSON.parse(JSON.stringify(that.InitStudentItem));
        for (var item of that.ruleForm.members) {
          studentList = studentList.filter(
            function(val) {
              return val.studentId != item.studentId
            }
          )
        }
        that.studentItem = studentList;
      },


      /**
       * @description 数据递归
       * @param {Array} data 需要递归的原数据
       * @return {Array} 返回递归后的数据
       */
      getTreeData(data) {
        // 循环遍历json数据
        for (var i = 0; i < data.length; i++) {
          if (data[i].children.length < 1) {
            // children若为空数组，则将children设为undefined
            data[i].children = undefined;
          } else {
            // children若不为空数组，则继续 递归调用 本方法
            this.getTreeData(data[i].children);
          }
        }
        return data;
      },
    }
  };
</script>

<style scoped="scoped">
  .nav-item {
    padding: 0 10px;
  }

  .divDialogBox {
    margin: 0 auto;
    width: 80%;
  }

  .scoreBox .spLabel {
    padding-left: 2px;
    padding-right: 2px;
    font-weight: bolder;
  }

  .scoreBox .itemList {
    padding-top: 5px;
    padding-bottom: 5px;
  }


  .stuInfo {
    position: relative;
    height: 100%;
    min-height: 80px;
    margin: 15px;
  }

  .stuInfo .stuInfoBox {
    display: flex;
  }

  .stuInfo .divInfoBox {
    padding-left: 10px;
  }


  .stuInfo .stuImgCont {
    width: 70px;
    height: 70px;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #DDDDDD;
  }



  .stuInfo .stuName {
    color: #212E48;
    font-size: 18px;
    font-weight: bold;
  }

  .stuInfo .stuRole {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
  }

  .stuInfo .stuRoom {
    font-size: 12px;
    color: #767676;
  }

  .stuInfo .stuOther {
    color: #767676;
    font-size: 12px;
  }

  .stuInfo .stuRemove {
    display: none;
  }

  .stuInfo:hover .stuRemove {
    display: block;
    position: absolute;
    top: -0.9375rem;
    left: -0.625rem;
    font-size: 22px;
    cursor: pointer;
  }


  .transferBox {
    margin: 0 auto;
  }

  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }


  .tabListCont .divNavTitle .divTitleCont {
    padding: 10px 0px 10px 10px;
    color: #DEE2E6;
    cursor: pointer;
    font-size: 18px;
  }

  .tabListCont .divNavTitle .divTitleCont .spNum {
    background-color: #DEE2E6;
    font-family: 'OswaldLight';
    padding: 2px 5px;
    border-radius: 4px;
    margin-left: 5px;
    font-size: 14px;
    color: #FFFFFF;
  }

  .tabListCont .divNavTitle .divTitleAct {
    color: #3e3e3e;
  }

  .tabListCont .divNavTitle .divTitleAct .spNum {
    background-color: #17A2B8;
    color: #FFFFFF;
  }

  .tabListCont .divNavTitle .nav-link {
    font-size: 14px;
    color: #B1B1B1;
  }

  .tabListCont .divNavTitle .nav-link .spNum {
    font-family: 'OswaldLight';
    font-weight: normal;
    font-size: 14px;
    padding-left: 10px;
  }

  .tabListCont .divNavTitle .active {
    color: #007bff;
  }

  .divAlertCont {
    padding-left: 30px;
    padding-top: 10px;
    color: #9595A0;
  }
</style>

<style>
  .transferCont .el-transfer__buttons {
    width: 20%;
  }

  .transferCont .el-transfer__buttons .el-transfer__button:first-child {
    float: left;
  }

  .transferCont .el-transfer__buttons .el-transfer__button:last-child {
    float: right;
  }

  .transferCont .el-transfer-panel {
    width: 40%;
  }

  .formGroupCont .el-date-editor--datetimerange.el-input__inner {
    width: 100%;
  }

  .stuInfo .stuImgCont .image-slot {
    font-size: 28px;
    color: #DDDDDD;
  }
</style>
