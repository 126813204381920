<template>
  <div class="SubWebPageStudent" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="ruleForm">

      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{ ruleForm.logId == null ? '新增综合积分' : '编辑综合积分' }}
          </h3>
        </div>
        <div class="card-body tabListCont ">
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="原因" prop="reasonIds">
                <el-cascader v-model="reasonIds" :options="optReason" @change="handleChange"
                  style="width:100% !important;" :props="{ label: 'name', value: 'reasonId', children: 'children' }"
                  ref="Cascader"></el-cascader>
              </el-form-item>
            </el-col>
            <!--按选择模式录入-->
            <el-col :lg="8" :sm="12" v-if="ruleForm.inputScoreMode == 1">
              <el-form-item label="分数" prop="score">
                <el-select v-model="ruleForm.score" class="width_100Pie" filterable placeholder="请选择">
                  <el-option v-for="item in ruleForm.points" :key="item.score" :label="item.score" :value="item.score">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="8" :sm="12" v-if="ruleForm.inputScoreMode == 4">
              <el-form-item label="分数" prop="score">
                <el-input v-model="ruleForm.score" placeholder="请输入内容" class="width_100Pie"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="行为时间" prop="logDate">
                <el-date-picker type="date" class="width_100Pie" v-model="ruleForm.logDate" placeholder="选择行为时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>
      <!-- 学生名单 -->
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont" style="float: left;">
            <span class="spIcon"></span>
            学生名单
          </h3>
          <div class="card-tools" v-if="ruleForm.logId == null">
            <ul class="nav">
              <li class="nav-item" v-if="ruleForm.logId == null">
                <el-button type="success" icon="el-icon-circle-plus-outline" size="mini" @click="onOpenDialogVisible()">
                  新增学生名单
                </el-button>
              </li>
              <li class="nav-item">
                <el-button type="danger" icon="el-icon-close" size="mini" @click="onEmptyList()">清空名单</el-button>
              </li>
            </ul>
          </div>
        </div>
        <div class="card-body tabListCont">
          <el-row :gutter="20" v-if="ruleForm.members != null && ruleForm.members.length > 0">
            <el-col :lg="8" :md="8" :sm="12" v-for="(p, index) in ruleForm.members" :key="index">
              <div class="stuInfo">
                <div class="stuInfoBox">
                  <div class="stuImgCont VerticalMid">
                    <el-image :src="p.photo">
                      <div slot="error" class="image-slot">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#fax-image-split"></use>
                        </svg>
                      </div>
                    </el-image>
                  </div>
                  <div class="divInfoBox">
                    <div class="stuName">{{ p.name }}</div>
                    <div class="stuName">{{ p.studentName }}</div>
                    <div class="stuOther">{{ p.studentNo }}</div>
                  </div>
                </div>
                <div class="stuRemove color-danger" v-if="ruleForm.logId == null">
                  <i class="el-icon-error" @click="onDeleteMember(p.studentId)"></i>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24" v-else>
            <el-col :span="24" style="text-align: center;line-height: 40px;font-size: 14px;color: #909399;">
              请选择学生
            </el-col>
          </el-row>
        </div>
      </el-card>
    </el-form>
    <div class="row" style="margin-top: 10px;">
      <div class="col-12 ">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
        <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
        <el-button type="primary" icon="el-icon-circle-check" @click="onSave()">提交</el-button>
      </div>
    </div>
    <!-- 添加学生名单弹出框  班级个人-->
    <el-dialog title="添加学生名单" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <div class="divDialogBox">
        <el-form class="ruleForm">
          <el-form-item label="学生">
            <el-select v-model="currentStudentId" class="width_100Pie" filterable placeholder="请选择">
              <el-option v-for="item in studentItem" :key="item.studentId" :label="item.name" :value="item.studentId">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onChangeStudent">确 定</el-button>
      </span>
    </el-dialog>




  </div>
</template>

<script>
import '@/assets/css/Student.css';

export default {
  components: {},
  data() {
    return {
      // 加载动画
      fullscreenLoading: false,
      //添加学生信息弹出框
      dialogVisible: false,
      // 表单对象
      ruleForm: {
        logId: null,
        reason: null,
        // 记录时间
        logDate: null,
        // 分数
        score: null,
        // 人员
        members: [],
        inputScoreMode: null,
        points: [],

      },
      ruleFormReset: null,
      reasonIds: null,
      optReason: [],


      // 学生数组
      studentItem: [],
      InitStudentItem: [],
      currentClassId: null,
      currentClassIdReset: null,
      // 选择学生的ID
      currentStudentId: null,
      // 验证
      rules: {
        reason: [{
          required: true,
          message: '请输入行为原因',
          trigger: 'change'
        }],
        score: [{
          required: true,
          message: '请输入分数',
          trigger: 'change'
        }, {
          validator: (rule, value, callback) => {
            let _this = this;
            var reg = /^(\-|\+)?\d+(\.\d{1,2})?$/;
            if (value != 0 && !reg.exec(value)) {
              callback(new Error("分数只能输入带1-2位小数的正数或负数！"));
            } else {
              callback();
            }
          },
          trigger: 'change'
        }],
        logDate: [{
          required: true,
          message: '请选择记录时间',
          trigger: 'change'
        },],
        currentClassId: [{
          required: true,
          message: '请选择班级',
          trigger: 'change'
        },],

      },

    };
  },
  created() {

    let that = this;
    let student = JSON.parse(that.$GetKey('Student'));
    let data = {
      classId : student.classId,
    };
    ajaxGet(that, '/api/student/classcreditlog/reason', data, function (resReason) {
      that.optReason = resReason;
      that.optReason.forEach(item => {
        removeChildren(item);
      })
    if (!regNull(that.$route.params.Id)) {
      ajaxGet(that, '/api/student/classcreditlog/' + that.$route.params.Id, null, function (resData) {
        that.ruleForm = resData;
        that.reasonIds = findPath(that.optReason, that.ruleForm.reasonId);
        that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
      });
    } else {
      that.onGetClass(that);
      that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
    }
  })
  },
  methods: {
    handleChange(val) {
      let that = this;
      that.ruleForm.reasonId = val[val.length - 1];
      that.ruleForm.score = '';
      ajaxGet(that, '/api/student/classcreditlog/reason/' + that.ruleForm.reasonId, null, function (res) {
        that.ruleForm.points = res.points;
        that.ruleForm.inputScoreMode = res.inputScoreMode;
        if (that.ruleForm.inputScoreMode == 1) {
          for (var it of res.points) {
            if (it.isDefault) {
              that.ruleForm.score = it.score;
            }
          }
        }
      })
    },
    /**
     * @description 关闭弹出框
     * @return {void}
     */
    handleClose() {
      this.dialogVisible = false;
    },
    /**
     * @description 清空名单
     * @return {void}
     */
    onEmptyList() {
      let that = this;
      that.ruleForm.members.forEach(function (item) {
        that.studentItem.push(item);
      });
      that.studentItem.sort((a, b) => setInt(a.studentNo) - setInt(b.studentNo));
      that.ruleForm.members = [];

    },
    /**
     * @description 打开名单
     * @return {void}
     */
    onOpenDialogVisible() {
      let that = this;
      that.dialogVisible = true;
    },
    /**
     * @description 返回
     * @return {void}
     */
    onBack() {
      let that = this;
      back(that);
    },
    /**
     * @description 提交事件
     * @return {void}
     */
    onSave() {
      let that = this;
      that.$refs["ruleForm"].validate(valid => {
        if (valid) {
          let studentId = [];
          that.ruleForm.logDate = timeTransform(that.ruleForm.logDate);
          // let data = {};
          that.fullscreenLoading = true;
          that.ruleForm.members.forEach(function (item) {
            item.score = that.ruleForm.score;
          });
          if (regNull(that.ruleForm.logId)) {
            // 新增
            ajaxPost(that, '/api/student/classcreditlog', that.ruleForm, function (resdata) {
              that.fullscreenLoading = false;
              that.onBack();
            })
          } else {
            ajaxPut(that, '/api/student/classcreditlog/' + that.ruleForm.logId, that.ruleForm, function (res) {
              that.fullscreenLoading = false;
              that.onBack();
            })
            // 编辑
          }
        } else {
          setTimeout(() => {
            var isError = document.getElementsByClassName("is-error");
            isError[0].querySelector('input').focus();
          }, 1)
          return false;
        }
      });
    },


    /**
     * @description 重置事件
     * @return {void}
     */
    onReset() {
      let that = this;
      that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
      that.reasonIds = [];
      that.onChangeClass(that.ruleForm.classId)
      if (!regNull(that.ruleForm.reasonId)) {
        that.reasonIds = findPath(that.optReason, that.ruleForm.reasonId);
      }
      that.currentClassId = JSON.parse(JSON.stringify(that.currentClassIdReset));
      try {
        that.$refs["ruleForm"].resetFields();
      } catch { }
    },
    /**
     * @description 删除学生名单
     * @param {Object} studentId 学生ID
     * @return {void}
     */
    onDeleteMember(studentId) {
      let that = this;
      let temp = that.ruleForm.members.find(item => item.studentId == studentId);
      if (temp != undefined) {
        that.studentItem.push(temp);
      }
      that.studentItem.sort((a, b) => setInt(a.studentNo) - setInt(b.studentNo));
      that.ruleForm.members = that.ruleForm.members.filter(item => item.studentId != studentId);
    },
    /**
     * @description
     * @param {Object} that
     */
    onGetClass(that) {
      that.studentItem = [];
      ajaxGet(that, '/api/student/student/classmates', null, function (resData) {
        that.studentItem = resData.sort((a, b) => setInt(a.studentNo) - setInt(b.studentNo));
      });
    },

    /**
     * @description 选择学生事件
     * @return {void}
     */
    onChangeStudent() {

      let that = this;
      let temp = that.studentItem.find(item => item.studentId == that.currentStudentId);
      if (temp != undefined) {
        that.ruleForm.members.push(temp);
      }
      that.studentItem = that.studentItem.filter(item => item.studentId != that.currentStudentId);
      that.currentStudentId = null;
      that.dialogVisible = false;
    },
  }
};
function removeChildren(tree) {
  if (tree.children && tree.children.length > 0) {
    tree.children.forEach(child => {
      removeChildren(child);
    });
  } else {
    tree.children = undefined; // 或者 tree.children = undefined;
  }
}
function findPath(tree, id) {
  for (let i = 0; i < tree.length; i++) {
    if (tree[i].reasonId === id) {
      return [tree[i].reasonId];
    }
    if (tree[i].children) {
      let path = findPath(tree[i].children, id);
      if (path) {
        return [tree[i].reasonId].concat(path);
      }
    }
  }
}
</script>

<style scoped="scoped">
.nav-item {
  padding: 0 10px;
}

.divDialogBox {
  margin: 0 auto;
  width: 80%;
}

.scoreBox .spLabel {
  padding-left: 2px;
  padding-right: 2px;
  font-weight: bolder;
}

.scoreBox .itemList {
  padding-top: 5px;
  padding-bottom: 5px;
}


.stuInfo {
  position: relative;
  height: 100%;
  min-height: 80px;
  margin: 15px;
}

.stuInfo .stuInfoBox {
  display: flex;
}

.stuInfo .divInfoBox {
  padding-left: 10px;
}


.stuInfo .stuImgCont {
  width: 50px;
  height: 50px;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #DDDDDD;
}



.stuInfo .stuName {
  color: #212E48;
  font-size: 18px;
  font-weight: bold;
}

.stuInfo .stuRole {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px;
}

.stuInfo .stuRoom {
  font-size: 12px;
  color: #767676;
}

.stuInfo .stuOther {
  color: #767676;
  font-size: 12px;
}

.stuInfo .stuRemove {
  display: none;
}

.stuInfo:hover .stuRemove {
  display: block;
  position: absolute;
  top: -0.9375rem;
  left: -0.625rem;
  font-size: 22px;
  cursor: pointer;
}


.transferBox {
  margin: 0 auto;
}

label {
  margin-bottom: 0;
}

.inputGroupCont {
  padding-top: 15px;
}

.inputGroupCont .inputList {
  padding-bottom: 10px;
}

.tabListCont .divNavTitle {}

.tabListCont .divNavTitle {}

.tabListCont .divNavTitle .divTitleCont {
  padding: 10px 0px 10px 10px;
  color: #DEE2E6;
  cursor: pointer;
  font-size: 18px;
}

.tabListCont .divNavTitle .divTitleCont .spNum {
  background-color: #DEE2E6;
  font-family: 'OswaldLight';
  padding: 2px 5px;
  border-radius: 4px;
  margin-left: 5px;
  font-size: 14px;
  color: #FFFFFF;
}

.tabListCont .divNavTitle .divTitleAct {
  color: #3e3e3e;
}

.tabListCont .divNavTitle .divTitleAct .spNum {
  background-color: #17A2B8;
  color: #FFFFFF;
}

.tabListCont .divNavTitle .nav-link {
  font-size: 14px;
  color: #B1B1B1;
}

.tabListCont .divNavTitle .nav-link .spNum {
  font-family: 'OswaldLight';
  font-weight: normal;
  font-size: 14px;
  padding-left: 10px;
}

.tabListCont .divNavTitle .active {
  color: #007bff;
}

.divAlertCont {
  padding-left: 30px;
  padding-top: 10px;
  color: #9595A0;
}
</style>

<style>
.transferCont .el-transfer__buttons {
  width: 20%;
}

.transferCont .el-transfer__buttons .el-transfer__button:first-child {
  float: left;
}

.transferCont .el-transfer__buttons .el-transfer__button:last-child {
  float: right;
}

.transferCont .el-transfer-panel {
  width: 40%;
}

.formGroupCont .el-date-editor--datetimerange.el-input__inner {
  width: 100%;
}

.stuInfo .stuImgCont .image-slot {
  font-size: 28px;
  color: #DDDDDD;
}
</style>
