<!--
  综合课堂详情
-->
<template>
  <div class="SubWebPage DetailsPro" v-loading.fullscreen.lock="fullscreenLoading">

    <!--基本信息-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          综合课堂详情
        </h3>
      </div>
      <div class="card-body pb-5">
        <div class="infoList clearfix">
          <span class="spName">{{infoData.name}}</span>
        </div>
        <!--活动状态-->
        <div class="infoList clearfix pb-4">
          <span class="spStatus">{{infoData.statusString}}</span>
          <span class="spStatus">{{infoData.type}}</span>
          <span class="spStatus" v-if="infoData.isImport">需要导入名单</span>
          <span class="spStatus" v-if="infoData.isTest">{{infoData.isTest?'需要测验':null}}</span>
        </div>
        <div class="infoList clearfix">
          <span class="spFonts">
            <span class="spLabel">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#fax-clock-o"></use>
              </svg>
              &nbsp;
              课程时间
            </span>
            {{onDateFormat(infoData.startDate)}} 至 {{onDateFormat(infoData.endDate)}}
          </span>
          <span class="spFonts" v-if="isTeaher == false">
            <span class="spLabel">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#fax-percent-akt"></use>
              </svg>
              &nbsp;
              完成度
            </span>
            &nbsp;
            <span class="spNum">{{completeVal}} %</span>
          </span>
          <span class="spFonts" v-if="infoData.isTest">
            <span class="spLabel">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#fax-basis-o"></use>
              </svg>
              &nbsp;
              通过最低分
            </span>
            &nbsp;
            <span class="spNum">{{infoData.floor}}</span>
          </span>
        </div>
      </div>
    </el-card>

    <!--课程详情-->
    <el-card class="box-card" v-if="!regNull(infoData.description)">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          课程介绍
        </h3>
      </div>
      <div class="card-body ">
        <div class="colRemarksCont">
          <span v-html="infoData.description"></span>
          <!-- {{infoData.description}} -->
        </div>
      </div>
    </el-card>
    <!--课程内容-->
    <el-card class="box-card" v-if="infoData.attachment!=''">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          课程内容
        </h3>
      </div>
      <div class="card-body bodyVideoCont" v-if="isVideo">
        <video width="100%" height="600" controls>
          <source :src="infoData.attachment" type="video/mp4">
          您的浏览器不支持 HTML5 video 标签。
        </video>
      </div>

      <div class="card-body bodyVideoCont" v-else>
        <ul class="mailbox-attachments d-flex align-items-stretch clearfix">
          <li>
            <span class="mailbox-attachment-icon">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#fax-file-pdf-o"></use>
              </svg>
            </span>
            <div class="mailbox-attachment-info">
              <span class="mailbox-attachment-size clearfix mt-1 text-center">
                <a :href="infoData.attachment" class="btn btn-default btn-sm" target="_blank">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#fax-search-square-o"></use>
                  </svg>
                  点击浏览
                </a>
              </span>
            </div>
          </li>
        </ul>
      </div>
    </el-card>
    <!--范围-->
    <div  v-if="isTeaher == false">

      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            范围名单
          </h3>
          <div class="card-tools" >
            <ul class="nav">
              <li class="nav-item" style="margin-right: 5px;" v-if="type == 2">
                <el-select v-model="ruleForm.classId" placeholder="请选择"  @change='onChangeClass()'>
                  <el-option v-for="item in options" :key="item.classId" :label="item.className"
                   :value="item.classId">
                  </el-option>
                </el-select>
              </li>
              <li class="nav-item" style="margin-right: 5px;" v-if="arrData.length == 1">
                <el-select v-model="ruleForm.classId" placeholder="请选择"  @change='onChangeClass()'>
                  <el-option v-for="item in options" :key="item.classId" :label="item.className"
                   :value="item.classId">
                  </el-option>
                </el-select>
              </li>
              <li class="nav-item"  v-if="arrData.length > 1&& type == 2" >
                <el-button type="primary"   @click="onswitch(1)">切换视图</el-button>
              </li>
              <li class="nav-item" v-if="arrData.length > 1 && type == 1">
                <el-button type="primary"   @click="onswitch(2)">切换视图</el-button>
              </li>
            </ul>
          </div>
        </div>

        <div class="card-body p-0" v-if="arrData.length == 1">
          <el-table :data='arrStudent' class="elTableData" stripe>
            <el-table-column type="index" label="序号" class="elColumn" width="80" ></el-table-column>
            <el-table-column prop="studentNo" label="学号" min-width="200"></el-table-column>
            <el-table-column prop="studentName" label="姓名" min-width="200"></el-table-column>
            <el-table-column prop="score" label="完成度" width="200"></el-table-column>
          </el-table>
        </div>
        <div class="card-body tabListCont 3Cont" v-if="arrData.length > 1">
        <div v-if="type == 1">
          <el-table :data='arrData' class="elTableData" stripe >
            <el-table-column type="index" label="序号" class="elColumn" width="80" ></el-table-column>
            <el-table-column prop="className" label="班级" min-width="200"></el-table-column>
            <el-table-column  label="学生数量(完成人数/总人数)" min-width="200">
              <template slot-scope="scope">
              <span v-if="scope.row.allNumber!=0">{{scope.row.nowNumber}}/{{scope.row.allNumber}}</span>
                <span v-else>0</span>
              </template>
            </el-table-column>
            <el-table-column  label="完成度" width="200">
              <template slot-scope="scope">
                <span v-if="scope.row.score!=0">{{scope.row.score}}%</span>
                  <span v-else>0</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
          <el-table :data='itemData' class="elTableData" stripe v-if="type == 2">
            <el-table-column type="index" label="序号" class="elColumn" width="80" ></el-table-column>
            <el-table-column prop="studentNo" label="学号" min-width="200"></el-table-column>
            <el-table-column prop="studentName" label="姓名" min-width="200"></el-table-column>
            <el-table-column prop="score" label="完成度" width="200"></el-table-column>
          </el-table>
        </div>
      </el-card>

      <!--名单-->
<!--      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            导入范围名单
          </h3>
        </div>
        <div class="card-body p-0" v-if="arrStudent.length>0">
          <el-table :data='arrStudent' class="elTableData" stripe>
            <el-table-column type="index" label="序号" class="elColumn" width="80" ></el-table-column>
            <el-table-column prop="studentNo" label="学号" min-width="200"></el-table-column>
            <el-table-column prop="studentName" label="姓名" min-width="200"></el-table-column>
            <el-table-column prop="className" label="班级" min-width="200"></el-table-column>
            <el-table-column prop="score" label="完成度" width="200"></el-table-column>
          </el-table>
        </div>
        <div class="card-body tabRoleCont" v-else>
          <div class="divNullDataBox">
            <svg class="icon faxSize" aria-hidden="true">
              <use xlink:href="#fax-exclamation-triangle-o"></use>
            </svg>

            暂未导入名单
          </div>
        </div>
      </el-card> -->

    </div>


    <div class="row" style="margin-top: 10px;">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="$router.back(-1)">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  //import comPDF from 'vue-pdf';
  export default {
    components: {
      //comPDF
    },
    data() {
      return {
        type : 1,// 2代表查看单个班级完成详情，1代表各班完成
        // 数据加载动画
        fullscreenLoading: false,
        // 数据集合
        infoData: {
          courseId: null,
        },
        ruleForm:{
          classId:null,
        },
        itemData:[],//学生数据
        // 判断附件是否为 视频
        isVideo: false,
        // PDF设置
        // pdfSetUp: {
        //   // pdf文件页码
        //   currentPage: 0,
        //   // pdf文件总页数
        //   pageCount: 0,
        // },
        options:[],//班级
        // 数据列表
        arrData: [],
        arrStudent: [],
        currentIndex: 0,
        isPower: {
          literacycourse_details: false,
          literacycourse_teacher_details: false,
        },
        // 完成度
        completeVal: 0,
        isTeaher: false,
      };
    },
    created() {
      let that = this;
      let dataID = that.$route.params.id;
      isPower(that, 'literacycourse_teacher_details', 'literacycourse_teacher_details'); //教师查看
      isPower(that, 'literacycourse_details', 'literacycourse_details'); //查看
      if (that.isPower.literacycourse_details && that.isPower.literacycourse_teacher_details) {
        that.isTeaher = false;
      } else if (that.isPower.literacycourse_teacher_details) {
        that.isTeaher = true;
      } else if (that.isPower.literacycourse_details) {
        that.isTeaher = false;
      }
      if (dataID != null && typeof(dataID) != "undefined" && dataID != "") {
        that.infoData.courseId = dataID;

        //e5676a4d-c0ad-4f31-9531-06f59584ae97
      }
      onGetData(that);
    },
    watch: {
      currentIndex: function(switchData) {
        this.arrStudent = this.arrData[switchData].children
      }
    },
    methods: {
      onswitch(type){
        let that = this;
        that.type = type;
      },
      // 格式化时间
      onDateFormat(_val) {
        return dateFormat(_val, '.', 'YYYY-MM-DD HH:MM');
      },
      onChangeClass(){
        let that = this;
        that.itemData = [];
        for(var it of that.options){
          if(that.ruleForm.classId == it.classId){
            that.itemData = it.children;
          }
        }
      },
      // 班级切换动画
      // onCurrent(_index) {
      //   this.currentIndex = _index;
      // },
      // 计算时间
      onGetScore(_type, _allTime, _nowTime, _score, _isNull) {
        if (_type) {
          // 成绩
          return _isNull ? _score : 0;
        } else {
          // 时长
          return _isNull ? setTwoDecimal((_nowTime / _allTime) * 100) + '%' : '0.00%';
        }
      },
      // 去除重复数据
      onArrUnique(arr) {
        const res = new Map();
        return arr.filter((arr) => !res.has(arr.className) && res.set(arr.className, 1));
      },
      // 处理数据
      onGetChildren(that, _ranges, _rosters, _isTest, _duration) {
        let arrTemp = [];

        for (let item of _ranges) {
          let temp = _rosters.find(arr => arr.studentId == item.studentId);
          if (temp != undefined) {
            arrTemp.push({
              studentId: item.studentId,
              studentNo: item.studentNo,
              studentName: item.studentName,
              isStart: true,
              photo: onGetAvatorUrl(that, item.photo, item.gender),
              score: that.onGetScore(_isTest, _duration, temp.length, temp.score, true)
            });
          } else {
            arrTemp.push({
              studentId: item.studentId,
              studentNo: item.studentNo,
              studentName: item.studentName,
              photo: onGetAvatorUrl(that, item.photo, item.gender),
              isStart: false,
              score: that.onGetScore(_isTest, _duration, 0, 0, false)
            })
          }
        }
        return arrTemp;
      },
      onGetNowNumber(that, _ranges, _rosters, _duration) {
        let iCount = 0;
        for (let item of _ranges) {
          let temp = _rosters.find(arr => arr.studentId == item.studentId);
          if (temp != undefined) {
            if (temp.length >= _duration) {
              iCount++;
            }
          }
        }
        return iCount;
      }
    }
  };



  // 加载数据
  function onGetData(that) {
    that.fullscreenLoading = true;
    ajaxGet(that, '/api/admin/literacycourse/' + that.infoData.courseId, null, function(resData) {
      that.fullscreenLoading = false;
      that.infoData = resData;
      //用于判断附件是视频还是PDF
      let temp = resData.attachment;
      if (!regNull(temp)) {
        that.infoData.attachment = that.$ServerUrl() + resData.attachment;
        if (temp.substring(temp.length - 3).toLowerCase() == 'pdf') {
          that.isVideo = false;
        } else {
          that.infoData.attachment = resData.attachment;
          that.isVideo = true;
        }
      }




      // 用于记录完成度数据
      let rangesVal = 0,
        rostersVal = 0;


      // 数据处理
      that.arrData = [];
      if (resData.ranges.length != 0) {
        let icount = 0;
        // 确认是导入名单 还是指定范围
        if (resData.isImport) {
          // 导入名单
          let arrTemp = that.onArrUnique(resData.ranges);
          for (let arrClass of arrTemp) {
            rangesVal++;
            that.arrData.push({
              classId: arrClass.classId,
              className: arrClass.className,
              children: that.onGetChildren(that, resData.ranges.filter(item => item.className === arrClass
                .className), resData.rosters, resData.isTest, resData.duration)
              });
          }
          // setTimeout(function(){
            for (var it of that.arrData) {
              for(var it2 of it.children){
                that.arrStudent.push({
                  className:it.className,
                  studentId: it2.studentId,
                  studentNo: it2.studentNo,
                  studentName: it2.studentName,
                  score :it2.score
                });
              }
            }
          // },1000)

        } else {
          for (let arrClass of resData.ranges) {
            ajaxGet(that, '/api/admin/student/class/' + arrClass.classId, null, function(resClass) {
              let temp = [];
              for (let item of resClass) {
                rangesVal++;
                temp.push({
                  studentId: item.studentId,
                  studentNo: item.studentNo,
                  studentName: item.name,
                  photo: item.photo,
                  gender: item.gender
                });
              }
              that.arrData.push({
                classId: arrClass.classId,
                className: arrClass.className,
                nowNumber: that.onGetNowNumber(that, temp, resData.rosters, resData.duration),
                allNumber: resClass.length,
                score : resClass!=0 ?((that.onGetNowNumber(that, temp, resData.rosters, resData.duration) / resClass.length)*100).toFixed(2):0,
                children: that.onGetChildren(that, temp, resData.rosters, resData.isTest, resData
                  .duration)
              });
              if (that.arrData.length == 1) {
                that.arrStudent = that.arrData[0].children;
              };
            });
          }
          that.fullscreenLoading = true;
          setTimeout(function(){
            that.options = that.arrData;
            that.ruleForm.classId = that.options[0].classId;
            that.fullscreenLoading = false;
          },1000)

        }

      }


      // 记算完成度
      setTimeout(function() {
        for (let item of resData.rosters) {
          if (item.length >= resData.duration) {
            rostersVal++;
          }
        }
        let tempVal = (rostersVal / rangesVal) * 100;
        that.completeVal = tempVal > 0 ? setTwoDecimal(tempVal, 2) : tempVal;
        return false;
      }, 500);


    });
  }
</script>

<style scoped="scoped">
  .divNullDataBox {
    text-align: center;
    padding: 30px;
    color: #9E9E9E;
  }

  .divPdfCont {
    width: 80%;
    margin: 0 auto;
  }

  .divPdfCont .divPageCont {
    padding-top: 15px;
  }


  .dialogBox {
    padding: 10px 5px;
    height: 140px;
    overflow: hidden;
  }

  .dialogVideoBox {
    padding: 10px 5px;
  }

  .dialogBox .mailbox-attachment-icon {
    font-size: 32px;
    padding: 15px 10px;
  }

  .dialogBox .mailbox-attachment-size>span {
    font-size: 12px;
  }

  .dialogBox .mailbox-attachment-info {
    padding: 7px 10px;
  }



  .bodyAwardsCont .stuAwardsCont {
    position: relative;
    padding: 0px 15px 45px 15px;
  }

  .bodyAwardsCont .stuAwardsCont .stuAvatar {
    position: absolute;
    left: 15px;
    top: 0px;
    border: 1px solid #eee;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 5px;
  }

  .bodyAwardsCont .stuAwardsLabel {
    font-size: 12px;
    padding-left: 65px;
    color: #B1B1B1;
  }

  .bodyAwardsCont .awardsName {
    padding-top: 10px;
    color: #3e3e3e;
  }

  .bodyAwardsCont .stuAwardsCont .stuName {
    font-size: 16px;
    padding-left: 65px;
  }

  .bodyAwardsCont .stuAwardsFont {
    font-size: 14px;
    padding-left: 65px;
    color: #666;
  }

  .bodyAwardsCont .stuFileCont {
    padding-top: 5px;
  }




  .bodyRecordCont .stuRecordCont {
    position: relative;
    padding: 0px 15px 45px 15px;
    padding-left: 75px;

  }

  .bodyRecordCont .stuRecordCont .stuAvatar {
    position: absolute;
    left: 15px;
    top: 0px;
    border: 1px solid #eee;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 5px;
  }


  .bodyRecordCont .stuRecordCont .stuName {
    font-size: 16px;
  }

  .bodyRecordCont .stuRecordFont {
    font-size: 12px;
    padding-top: 5px;
    color: #767676;
  }

  .bodyRecordCont .stuRecordLabel {
    font-size: 12px;
    padding-top: 5px;
    color: #B1B1B1;
  }

  .bodyRecordCont .stuFileCont {
    padding-top: 10px;
  }

  .bodyRecordCont .stuFileCont .divFileIcon {
    border: 1px solid #eee;
    width: 100%;
    height: 80px;
    overflow: hidden;
    border-radius: 4px;
  }

  .bodyRecordCont .stuFileCont .divFileIcon .elIcon {
    font-size: 32px;
  }





  .colRecordOther {}

  .colRecordOther .divList {
    float: left;
    width: 33.3%;
  }

  .colRecordCont {
    padding: 5px;
    font-size: 12px;
    color: #767676;
  }

  .divVideoCont {
    overflow-y: auto;
    width: 100%;
    height: 500px;
    padding-right: 10px;
  }

  .divVideoCont ul li .aItem {
    display: block;
    padding: 7px 0px;
    padding-left: 30px;
    border-bottom: 1px dotted #DDDDDD;
    position: relative;
  }

  .divVideoCont ul li:last-child .aItem {
    border-bottom: none;
  }

  .divVideoCont ul li:hover {
    background-color: #5AAAFF;
    color: #FFFFFF;
  }

  .divVideoCont ul li .aItem span {
    font-family: 'OswaldLight';
    font-size: 14px;
    margin-left: 10px;
  }

  .divVideoCont ul li .aItem .faxIcon {
    position: absolute;
    top: 11px;
    left: 5px;
    display: none;
  }

  .divVideoCont ul li .aItemAct {
    background-color: #007BFF;
    color: #FFFFFF;
    border-bottom: none;
  }

  .divVideoCont ul li .aItemAct .faxIcon {
    display: block;
  }


  .bodyImgCont .mailbox-attachment-icon {
    min-height: 165px;
  }

  .bodyFileCont .mailbox-attachments li,
  .bodyImgCont .mailbox-attachments li {
    width: 250px;
  }

  .bodyImgCont .mailbox-attachment-icon {
    height: 198px;
  }

  .bodyFileCont .mailbox-attachment-size .spFont,
  .bodyImgCont .mailbox-attachment-size .spFont {
    font-size: 12px;
  }





  .tabListCont .divNavTitle {}

  .tabListCont .divNavTitle {}

  .tabListCont .divNavTitle .divTitleCont {
    padding: 10px 0px 10px 10px;
    color: #DEE2E6;
    cursor: pointer;
    font-size: 18px;
  }

  .tabListCont .divNavTitle .divTitleCont .spNum {
    background-color: #DEE2E6;
    font-family: 'OswaldLight';
    padding: 2px 5px;
    border-radius: 4px;
    margin-left: 5px;
    font-size: 14px;
    color: #FFFFFF;
  }

  .tabListCont .divNavTitle .divTitleAct {
    color: #3e3e3e;
  }

  .tabListCont .divNavTitle .divTitleAct .spNum {
    background-color: #17A2B8;
    color: #FFFFFF;
  }

  .tabListCont .divNavTitle .nav-link {
    font-size: 14px;
    color: #B1B1B1;
  }

  .tabListCont .divNavTitle .nav-link .spNum {
    font-family: 'OswaldLight';
    font-weight: normal;
    font-size: 14px;
    padding-left: 10px;
  }

  .tabListCont .divNavTitle .active {
    color: #007bff;
  }




  .DetailsPro .divRoleTitle {
    font-size: 18px;
    color: #323E56;
    padding-top: 20px;
  }

  .DetailsPro .divRoleTitle .spItem {
    float: right;
    margin-left: 5px;
    font-size: 14px;
    color: #28A745;
  }

  .DetailsPro .divRoleTitle .spItem em {
    font-style: normal;
    background-color: #28A745;
    color: #FFFFFF;
    margin-left: 5px;
    margin-right: 5px;
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 12px;
    font-family: 'OswaldLight';
  }

  .DetailsPro .divRoleTitle .el-button {
    padding: 0;
    border: none;
  }

  .DetailsPro .divRoleTitle .el-button:hover {
    background-color: transparent;
  }





  .stuInfo {
    position: relative;
    margin: 15px;
    display: flex;
    width: 100%;
  }

  .stuInfo .stuOtherBox {
    padding-left: 10px;
  }

  .stuInfo .stuOtherBox .stuName {
    color: #212E48;
    font-size: 18px;
    font-weight: bold;
    width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .stuInfo .stuOtherBox .stuOther {
    color: #767676;
    font-size: 12px;
  }


  .stuInfo .stuFraction {
    font-family: 'OswaldLight';
    font-size: 1.25rem;
    color: #fa3534;
    padding-left: 0.9375rem;
  }



  .stuInfo .stuImgCont {

    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
  }

  .stuInfo .stuImgCont img {
    width: 100%;
  }








  .infoList {
    padding-bottom: 17px;
    padding-left: 30px;
  }

  .infoList .spName {
    font-size: 32px;
    color: #212E48;
    padding-right: 30px;
  }


  .infoList .spFonts {
    color: #3B475D;
    padding-right: 30px;
    padding-left: 30px;
    border-right: 1px solid #D6D6D6;
  }

  .infoList .spFonts:first-child {
    padding-left: 0;
  }

  .infoList .spFonts:last-child {
    border-right: none;
  }

  .infoList .spMedal {
    padding-right: 15px;
  }

  .infoList .spFonts .faxSize {
    font-size: 18px;
  }

  .infoList .spFonts .icon {
    color: #FFFFFF;
  }

  .infoList .spStatus {
    background-color: #EBF3FA;
    color: #61AFFE;
    padding: 5px 20px;
    margin-right: 10px;
  }

  .infoList .spFonts .spLabel {
    font-size: 14px;
    padding: 2px 5px;
    border-radius: 5px;
    background-color: #B1B1B1;
    color: #FFFFFF;
    margin-right: 10px;
  }

  .infoList .spFonts .spNum {
    padding-left: 10px;
    padding-right: 5px;
    font-family: 'OswaldLight';
    font-size: 16px;
    font-weight: bold;
    color: #DC3545;
  }

  .DetailsPro .colRemarksCont {
    font-size: 14px;
    letter-spacing: 1px;
  }
</style>
