<!--
  班主任手册-主题班会
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <search :ColNumber="2" :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null">
    </search>
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          主题班会
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <el-button v-if="isPower.adviserclassmeeting_create == true" type="success"
                icon="el-icon-circle-plus-outline" @click="onAdd()">新增</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="item!=null && item.length!=0">
        <el-table :data="item" stripe class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column label="班会课题" class="elColumn" min-width="400">
            <template slot-scope='scope'>
              {{onGetNoHtml(scope.row.topic)}}
            </template>
          </el-table-column>
          <el-table-column label="时间" class="elColumn" min-width="120">
            <template slot-scope="scope">
              {{'第'+scope.row.week+'周'}}{{scope.row.dayOfWeek}}
            </template>
          </el-table-column>
          <el-table-column label="课节" class="elColumn" min-width="120">
            <template slot-scope="scope">
              {{'第'+scope.row.schedule+'节'}}
            </template>
          </el-table-column>
          <el-table-column label="节数" class="elColumn" min-width="120">
            <template slot-scope="scope">
              {{'共用'+scope.row.duration+'节'}}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="220" fixed="right">
            <template slot-scope="scope">
              <operationBtn v-if="isPower.adviserclassmeeting_details == true" :btnName="'查看'"
                @click.native="onDetails(scope.row.meetingId)"></operationBtn>
              <operationBtn v-if="isPower.adviserclassmeeting_edit == true  && isCurrentTeacher == true" :btnName="'编辑'"
                @click.native="onEdit(scope.row.meetingId)"></operationBtn>
              <operationBtn v-if="isPower.adviserclassmeeting_delete == true  && isCurrentTeacher == true"
                :btnName="'删除'" @click.native="onDelete(scope.row.meetingId)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';

  export default {
    name: '',
    components: {
      comPage,
      operationBtn,
      search
    },
    data() {
      return {
        fullscreenLoading: false,
        page: null, //分页查询接口返回的结果
        item: [], //列表数据
        pageBtnList: null, //分页左侧按钮集合
        paging: { //分页数据
          pageLength: 0, // 总共页数
          Size: 10, // 当前请求数量
          Index: 1 //当前在第几页
        },
        SearchList: null, //搜索集合
        isPower: {
          //增删改查 权限管理
          adviserclassmeeting_details: false,
          adviserclassmeeting_create: false,
          adviserclassmeeting_edit: false,
          adviserclassmeeting_delete: false,
          adviserclassmeeting_user: false,
        },
        TeacherInfo: {},
        isCurrentTeacher: true, //历史班主任是否为当前用户
        ManagerClass: [], //班主任管理的班级的集合
      };
    },
    created() {
      let that = this;
      that.TeacherInfo = onGetNowUser(that);
      isPower(that, "adviserclassmeeting_details", "adviserclassmeeting_details");
      isPower(that, "adviserclassmeeting_create", "adviserclassmeeting_create");
      isPower(that, "adviserclassmeeting_edit", "adviserclassmeeting_edit");
      isPower(that, "adviserclassmeeting_delete", "adviserclassmeeting_delete");
      isPower(that, "adviserclassmeeting_user", "adviserclassmeeting_user");
      pagingPage(that);
    },
    methods: {
      onGetNoHtml(objVal) {
        let tpVal = delHtml(objVal).replace("&nbsp;&nbsp;&nbsp;&nbsp;", '');
        if (regNull(tpVal)) {
          return '-'
        } else {
          let strLength = tpVal.length;
          if (strLength <= 21) {
            return tpVal;
          } else {
            return tpVal.substring(0, 19) + '...';
          }
        }
      },
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },
      onDelete(Id) //删除
      {
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, "/api/admin/adviserclassmeeting/" + Id, null, function(r) {
              that.fullscreenLoading = false;
              pagingPage(that);
            });
          }
        })
      },
      onDetails(Id) {
        let that = this;
        that.$router.push({
          path: "/Admin/Adviser/ClassMeeting/Details/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onEdit(Id) {
        let that = this;
        that.$router.push({
          path: "/Admin/Adviser/ClassMeeting/Edit/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onAdd() {
        let that = this;
        that.$router.push({
					path: '/Admin/Adviser/ClassMeeting/Create',
					query: {
					  url: that.$router.history.current.fullPath
					}
				});
      },
      onSearch(params) {
        let that = this;
        let data = {};
        let searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = Join(element.data);
          }
        });
        data.PageNumer = 1;
        routerPath(that, "/Admin/Adviser/ClassMeeting/Index", data, null, "PageSize");
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      pagingClick(type, val) {
        let that = this;
        let url = "/Admin/Adviser/ClassMeeting/Index";
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "SemesterId",
            "ClassId",
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "SemesterId",
            "ClassId",
          );
        }
      },
    },
    watch: {
      $route() {
        let that = this;
        pagingPage(that);
      }
    }
  };

  function pagingPage(that) {
    paging(
      that,
      function(r) {
        that.fullscreenLoading = true;
        ajaxGet(that, "/api/admin/Semester", null, function(semester) {
          if(!regNullArray(semester)){
            let resultClass = that.TeacherInfo.isTeacher ? that.TeacherInfo.manageClass : that.TeacherInfo.allClass;
            let Myurl = that.TeacherInfo.isTeacher ? "/api/admin/adviserclassmeeting/search" : "/api/admin/adviserclassmeeting/user/search";

            var SemesterId = r.SemesterId;
            var semesters = [];
            for (var i in semester) {
              var it = {
                Title: semester[i].name,
                Value: semester[i].semesterId,
              };
              if (regNull(SemesterId)) {
                if (semester[i].isCurrent == true) {
                  SemesterId = semester[i].semesterId;
                }
              }
              semesters.push(it);
            }

            var ClassId = r.ClassId;
            var classList = [];

            if (regNull(ClassId) && !regNullArray(resultClass)) {
              ClassId = resultClass[0].classId;
            }

            for (var i in resultClass) {
              classList.push({
                Title: resultClass[i].alias,
                Value: resultClass[i].classId,
              });
            }

            that.SearchList = [{
                type: "select",
                Name: "学期",
                data: SemesterId,
                select: semesters, //选择的数据
                zhi: "SemesterId",
                isClear: false, //select是否可以清除
                resetData: semesters[0].Value,//重置数据默认第一个
              },
              {
                type: "select",
                Name: "班级",
                data: ClassId,
                select: classList,
                zhi: "ClassId",
                isClear: false, //select是否可以清除
                resetData: resultClass[0].classId,//重置数据默认第一个
              },

            ];

            let data = {
              PageNumer: r.PageNumer,
              PageSize: r.PageSize,
              SemesterId: SemesterId,
              ClassId: ClassId,
            };
            ajaxGet(that, Myurl, data, function(r1) {
              that.page = r1;
              that.item = r1.items;
              var name = JSON.parse(that.$GetKey("Name"));
              that.isCurrentTeacher = true;
              if (that.item != '') {
                if (that.item[0].adviser != name) {
                  that.isCurrentTeacher = false;
                }
              }
              that.fullscreenLoading = false;
            });
          } else{
            that.fullscreenLoading = false;
          }
        });
      },
      "SemesterId",
      "ClassId",
    );
  }

</script>

<style scoped="scoped">
</style>

