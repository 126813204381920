<!--
  综合活动 - 导入范围
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <!-- 列表-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          {{Title}}
        </h3>
        <div class="card-tools">
          <comImport @onImportSuccess="onImportSuccess" :title="ImportTitle" :template="ImportFile" :path="ImportUrl"
            ref="conImportFile" v-if="ImportUrl!=null">
          </comImport>
        </div>
      </div>
      <div class="card-body p-0" v-if="item!=null && item.length!=0">
        <el-row :gutter="24" style="padding-left: 10px; padding-right: 10px;margin-top: 15px;">
          <el-col :span="24">
            <el-alert title="如果以下列表中的学号和学生姓名是/,表示该班级全员可以报名参加该活动" type="info" :closable="false">
            </el-alert>
          </el-col>
        </el-row>
        <div class="text item">
          <el-table v-if="item!=null" stripe sortable="custom" :data="item"
            style="width: 100%; margin-top: 10px; padding-left: 10px; padding-right: 10px;">
            <el-table-column type="index" width="100px;" label="序号"></el-table-column>
            <el-table-column prop="className" label="班级"></el-table-column>
            <el-table-column prop="studentNo" label="学号">
              <template slot-scope="scope">
                <span v-if="scope.row.studentNo == null || scope.row.studentNo == ''">/</span>
                <span v-else>{{scope.row.studentNo}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="studentName" label="学生">
              <template slot-scope="scope">
                <span v-if="scope.row.studentName == null || scope.row.studentName == ''">/</span>
                <span v-else>{{scope.row.studentName}}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <comPage :isPage="false" :paging="paging"></comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>

    <div class="row" style="margin-top: 10px;">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
      </div>
    </div>

  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import comImport from '@/components/Import.vue';
  export default {
    components: {
      comPage,
      comImport
    },
    data() {
      return {
        id: null,
        fullscreenLoading: false,
        itemData: [],
        dialogTitle: '',
        dialogVisible: false,
        Title: null,
        ImportTitle: null,
        ImportUrl: null,
        ImportFile: null,
        item: null,
        paging: {
          totalCount: 0,
        },

       

      };
    },
    created() {
      let that = this;
      that.id = that.$route.params.id;
      Init(that);
    },

    methods: {
      onImportSuccess(param) {
        let that = this;
        Init(that);
      },
      onBack() {
        let that = this;
        back(that);
      },
    }
  };

  function Init(that) {
    var detailUrl = null;
    if (that.id != null && typeof(that.id) != "undefined" && that.id != "") {
      detailUrl = "/api/admin/activity/" + that.id;
    }
    that.fullscreenLoading = true;
    ajaxGet(that, detailUrl, null, function(r) {
      if (r != null) {
        that.Title = r.name;
        that.item = r.ranges;
        that.paging.totalCount = that.item.length;
        if (r.isApply == true) {
          that.Title = that.Title + '【确认报名范围名单】';
          that.ImportTitle = '导入报名范围名单';
          that.ImportUrl = '/api/admin/activity/range/' + that.id;
          that.ImportFile = '/Templates/活动报名范围导入模版.xlsx';
        } else if (r.isApply == false && r.isUpload == true) {
          that.Title = that.Title + '【确认参与范围名单】';
          that.ImportTitle = '导入参与范围名单';
          that.ImportUrl = '/api/admin/activity/range/' + that.id;
          that.ImportFile = '/Templates/活动参与范围导入模版.xlsx';
        }
      }
      that.fullscreenLoading = false;
    });
  }
</script>


<style scoped="scoped">


</style>
