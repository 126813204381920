<!--
  基础信息 - 活动配置
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="width:100%;padding-left: 0px;">
          活动配置
        </h3>
      </div>
      <div class="card-body">
        <el-row :gutter="24">
          <el-col :span="6">
            <div class="divTreeCont">
              <el-input placeholder="输入关键字进行过滤" v-model="filterText">
                <el-button slot="append" icon="el-icon-refresh-right" @click="onFilterText()"></el-button>
              </el-input>

              <el-tree :data="data" :props="defaultProps" ref="tree" style="margin-top:10px;" node-key="id"
                default-expand-all @node-click="handleNodeClick" :expand-on-click-node="false"
                :render-content="renderContent" :filter-node-method="filterNode">
              </el-tree>
            </div>
          </el-col>
          <el-col :span="18">
            <el-row :gutter="24">
              <el-col :span="20">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item v-for="(p,index) in currentNav" :key="index">{{p}}</el-breadcrumb-item>
                </el-breadcrumb>
              </el-col>
              <el-col :span="4" style="text-align: right;">
                <el-button @click="onAdd()" type="success" icon="el-icon-circle-plus-outline">新增</el-button>
              </el-col>
            </el-row>

            <div v-if="item!=null && item.length!=0">
              <el-table stripe :data="item" class="tableTwo table-hover text-nowrap "
                style="width: 100%; margin-top: 0px; ">
                <el-table-column type="index" width="120px;" label="序号"></el-table-column>
                <el-table-column prop="name" label="活动名称"></el-table-column>
                <el-table-column prop="priority" label="优先级"></el-table-column>
                <el-table-column label="操作" width="180px">
                  <template slot-scope="scope">
                    <!-- <operationBtn :btnName="'配置'" @click.native="onConfig(scope.row.id)"></operationBtn> -->
                    <operationBtn :btnName="'编辑'" @click.native="onEdit(scope.row)"></operationBtn>
                    <operationBtn :btnName="'删除'" @click.native="onDelete(scope.row.id)"></operationBtn>
                  </template>
                </el-table-column>
              </el-table>
            </div>


            <div v-else>
              <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
            </div>

          </el-col>
        </el-row>
      </div>
    </el-card>


    <!-- 弹出框 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" top="10px" width="95%" :close-on-click-modal="false"
      :close-on-press-escape="false" :append-to-body="true" :fullscreen="false">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="90px">
        <el-row v-if="isDetail!=true">
          <!-- 活动类型 -->
          <el-card class="box-card">
            <div slot="header" class="card-header">
              <h3 class="card-title titleCont">
                <span class="spIcon"></span>
                活动类型
              </h3>
            </div>
            <div class="card-body">
              <el-row :gutter="24">
                <el-col :span="8">
                  <el-form-item label="活动名称" prop="name">
                    <el-input v-model="ruleForm.name" placeholder="请输入活动名称"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="优先级" prop="priority">
                    <el-input @change="onPriorityChange()" v-model="ruleForm.priority" placeholder="请输入优先级"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="所属类型" prop="parentId">
                    <treeSelect :key="random" @getValue="getValue" :options="selectData" :value="ruleForm.parentId">
                    </treeSelect>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </el-card>
          <!--综合类型-->
          <el-card style="margin-top: 10px;" id="LiteracyType">
            <div slot="header" class="card-header">
              <h3 class="card-title titleCont">
                <span class="spIcon"></span>
                综合类型
              </h3>
            </div>
            <div class="card-body tabListCont">
              <el-row :gutter="24">
                <el-col :span="24">
                  <el-tabs v-model="activeName" tab-position="left" type="border-card" @tab-click="handleTabChange">
                    <template v-for="item in ruleForm.activityrole">
                      <el-tab-pane :key="item.name" :name="item.name">
                        <span slot="label">
                          <el-checkbox v-model="item.checked" @change="handleCheckChange"></el-checkbox> {{item.name}}
                        </span>
                        <el-row :gutter="24" class="rowConfig">
                          <el-col :span="24" v-for="(p,index) in ruleForm.treeList" :key="p.configId" class="colConfig mb-5 elLiteracyType" >
                                <div v-if="p.parentId==null || p.parentId=='00000000-0000-0000-0000-000000000000'">
                                  <label class="mb-3">{{p.name}}</label>
                                </div>
                                <el-row>
                                <el-col :xl="4" :lg="6" :md="8" :sm="12" :xs="24" v-if="p.parentId==null || (p.parentId=='00000000-0000-0000-0000-000000000000'&&p.children==null)">
                                    <div class="inputList">
                                        <el-form-item v-if="p.isSelect == true" label-width="0px" :prop="'treeList.' + index + '.score'" :rules="getScoreVerify">
                                            <el-input  @change="onScoreChange(p.configId,p)"  placeholder="请输入分值" v-model="p.score" style="width:200px">
                                              <template slot="prepend">
                                                  <el-checkbox v-model="p.isSelect" @change="onSelectGrowthConfig(p.configId,p)">{{p.name}}</el-checkbox>
                                              </template>
                                            </el-input>
                                        </el-form-item>
                                        <el-input v-else placeholder="请输入分值" v-model="p.score" :disabled="true" style="width:200px">
                                            <template slot="prepend">
                                                <el-checkbox :disabled='isDisabled' v-model="p.isSelect"  @change="onSelectGrowthConfig(p.configId,p)">{{p.name}}</el-checkbox>
                                            </template>
                                        </el-input>
                                    </div>
                                </el-col>
                              <el-col :xl="4" :lg="6" :md="8" :sm="12" :xs="24"  v-for="(p1,index1) in p.children" :key="index1">
                                <div class="inputGroupCont">
                                    <div class="inputList">
                                        <el-form-item v-if="p1.isSelect == true" label-width="0px" :prop="getTableProp(index,index1)" :rules="getScoreVerify">
                                          <el-input  @change="onScoreChange(p1.configId,p1)"  placeholder="请输入分值" v-model="p1.score" style="width:200px">
                                            <template slot="prepend">
                                              <el-checkbox v-model="p1.isSelect" @change="onSelectGrowthConfig(p1.configId,p1)">{{p1.name}}</el-checkbox>
                                            </template>
                                          </el-input>
                                        </el-form-item>
                                        <el-input v-else placeholder="请输入分值" v-model="p1.score" :disabled="true" style="width:200px" >
                                          <template slot="prepend">
                                            <el-checkbox :disabled='isDisabled' v-model="p1.isSelect"  @change="onSelectGrowthConfig(p1.configId,p1)">{{p1.name}}</el-checkbox>
                                          </template>
                                        </el-input>
                                    </div>
                                  </div>
                              </el-col>
                            </el-row>
                          </el-col>
                        </el-row>
                      </el-tab-pane>
                    </template>
                  </el-tabs>
                </el-col>
              </el-row>
            </div>
          </el-card>
          <!-- 活动配置 -->
          <el-card class="box-card" style="margin-top: 16px;">
            <div slot="header" class="card-header">
              <h3 class="card-title titleCont">
                <span class="spIcon"></span>
                活动配置
              </h3>
            </div>
            <div class="card-body">
              <template>
                <div style="margin: 15px 0;"></div>
                <el-checkbox-group v-model="ruleForm.activityitems">
                  <el-checkbox v-for="p in activityitem" :label="p.Value" :key="p.Value" style="margin-bottom: 15px;"
                    @change="onSelect(p,ruleForm.activityitems)">{{p.Text}}</el-checkbox>
                </el-checkbox-group>
              </template>
            </div>

          </el-card>

        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
        <el-button type="primary" icon="el-icon-circle-check" @click="onSave()">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import operationBtn from '@/components/operationBtn.vue';
  import treeSelect from '@/components/TreeSelect.vue';
  export default {
    name: '',
    components: {
      operationBtn,
      treeSelect
    },
    data() {
      return {
        treeList:[],
        isDisabled:false,
        filterText: '', //查找树形菜单
        activeName: "",
        currentNav: [],
        random: 0, //刷新组件
        currentId: null,
        isDetail: false,
        dialogTitle: "新增活动类型",
        dialogVisible: false,
        currentTitle: "请在左侧选择综合类型",
        item: null,
        fullscreenLoading: false,
        data: [],
        selectData: [],
        defaultProps: {
          children: 'children',
          label: 'name'
        },
        ruleForm: {
          typeId: null,
          name: null,
          priority: null,
          parentId: null,
          treeList:[],
          activityitems: [],
          checked: false,
          GrowthConfigs:[],
          activityrole: [],
        },
        activityroleReset: [],
        activityitem: [],
        ruleFormInit: null,
        ruleFormReset: null, //重置时的表单数据
        rules: {
          name: [{
              required: true,
              message: '活动名称不能为空',
              trigger: 'change'
            },
            {
              max: 256,
              message: '活动名称不能超过256个字符',
              trigger: 'change'
            },
          ],
          priority: [{
            validator: (rule, value, callback) => {
              let that = this;
              var reg = /^-?[1-9]\d*$/;
              if (value != 0 && !reg.exec(value)) {
                callback(new Error("优先级只能输入整数！"));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }],
        },
      };
    },
    computed:{
      getScoreVerify(){
        let rules = [
          {
            required: true,
            message: '综合类型的分值必须填写',
            trigger: 'change'
          },
          {
            pattern:/^([0-9]+\.?[0-9]*|-[0-9]+\.?[0-9]*)$/,
            message:'请输入数字',
            trigger:'change'
          }
        ]
        return rules
      },
      getTableProp(){
        return function(index,index2){
          return `treeList[${index}].children[${index2}].score`
        }
      }
    },
    created() {
      let that = this;
      that.fullscreenLoading = true;
      that.ruleFormInit = JSON.parse(JSON.stringify(that.ruleForm));
      Init(that);
    },
    methods: {
      handleCheckChange(val){
        this.isDisabled = !val;
      },
      handleTabChange(){
        if(this.ruleForm.activityrole.length>0) {
          let current = this.ruleForm.activityrole.find(it =>it.name == this.activeName);
          this.isDisabled = !current.checked;
          this.ruleForm.treeList = treeData(current.configs);
          mapTreeChildren(this.ruleForm.treeList);
          this.ruleForm.GrowthConfigs = current.configs;
        }
      },
			onScoreChange(Id,obj)//成绩改变
			{
				let that = this;
        let reg = /^([0-9]+\.?[0-9]*|-[0-9]+\.?[0-9]*)$/
        if(reg.test(obj.score)){
          for(let i in that.ruleForm.GrowthConfigs)
          {
            if(that.ruleForm.GrowthConfigs[i].configId == Id)
            {
              let score = Number(obj.score).toFixed(1);
              obj.score = Number(obj.score).toFixed(1)
              that.ruleForm.GrowthConfigs[i].score = score;
              that.$set(that.ruleForm.GrowthConfigs, i, that.ruleForm.GrowthConfigs[i]);
            }
          }
        };
        for(var i in that.ruleForm.activityrole){
          for(var it of that.ruleForm.activityrole[i].configs)
          {
            if((it.roleId==obj.roleId)&&(it.configId==obj.configId)){
              it.score = obj.score;
            }
          }
        }
			},
			onSelectGrowthConfig(Id,obj)//复选框选中
			{
				let that = this;
				for(let i of that.ruleForm.GrowthConfigs)
				{
					if(i.configId == Id)
					{
						i.isSelect = obj.isSelect;
					}
				}
			},
      onFilterText() {
        this.filterText = "";
      },
      filterNode(value, data) {
        if (!value) return true;
        return data.name.indexOf(value) !== -1;
      },
      onAdd() //新增
      {
        let that = this;
        that.$router.push({
          path: "/Admin/ActiveConfig/Create",
          query: {
            url: that.$router.history.current.fullPath,
          }
        })
        // that.dialogVisible = true;
        // that.dialogTitle = "新增活动类型";
        // that.isDetail = false;
        // that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormInit));
        // that.ruleForm.parentId = that.currentId;

        // //获取活动字段，默认勾选前三个
        // ajaxGet(that, "/api/admin/activitytype/type", null, function(r) {
        //   for (var i = 0; i < 3; i++) {
        //     that.ruleForm.activityitems.push(r[i].Value);
        //   }
        //   that.ruleForm.activityitems.push(r[4].Value);
        //   that.ruleForm.activityitems.push('11');
        // })


        // //获取活动角色-综合类型
        // ajaxGet(that, "/api/admin/GrowthConfig", null, function(r2) {
        //   for (var it of r2) {
        //     it.isSelect = false;
        //     it.score = 0;
        //   }
        //   ajaxGet(that, "/api/admin/activityrole", null, function(r1) {
        //     for (var it of r1) {
        //       it.checked = false;
        //       it.configs = JSON.parse(JSON.stringify(r2)).map(item =>{
        //         item.roleId = it.roleId
        //         return item
        //       });
        //     }
        //     that.ruleForm.activityrole = r1;
        //     that.ruleForm.GrowthConfigs = that.ruleForm.activityrole[0].configs
        //     that.ruleForm.activityrole[0].checked = true
        //     that.ruleForm.treeList = treeData(r1[0].configs)
        //     mapTreeChildren(that.ruleForm.treeList)
        //     that.activeName = r1[0].name;
        //     that.handleCheckChange()
        //     that.handleTabChange()
        //     that.activityroleReset = JSON.parse(JSON.stringify(that.ruleForm.activityrole));
        //     that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
        //   })
        // })

        // var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        // that.random += random;
        // try {
        //   that.$refs["ruleForm"].resetFields();
        // } catch {}
      },
      onEdit(data) //编辑
      {
        let that = this;
        that.$router.push({
          path: "/Admin/ActiveConfig/Edit/"+data.id,
          query: {
            url: that.$router.history.current.fullPath,
          }
        })
        // that.ruleForm.typeId = data.id;
        // that.ruleForm.name = data.name;
        // that.ruleForm.priority = data.priority;
        // that.ruleForm.parentId = data.parentId;
        // that.ruleForm.activityitems = [];
        // that.dialogVisible = true;
        // that.dialogTitle = "编辑活动类型";
        // that.isDetail = false;
        // var Id = data.id;
        // var editUrl = "/api/admin/activitytype/" + Id;
        // ajaxGet(that, editUrl, null, function(r) {
        //   //获取活动字段
        //   ajaxGet(that, "/api/admin/activitytype/type", null, function(r1) {
        //     for (var i in r1) {
        //       for (var j in r.fieldConfigs) {
        //         if (r.fieldConfigs[j].name == r1[i].Value) {
        //           that.ruleForm.activityitems.push(r1[i].Value);
        //         }
        //       }
        //     }
        //   });

        //   //获取活动角色-综合类型
        //   that.activeName = "";
        //   ajaxGet(that, "/api/admin/GrowthConfig", null, function(r2) {
        //     ajaxGet(that, "/api/admin/activityrole", null, function(r1) {
        //       for (var it of r1) {
        //         for (var it1 of r2) {
        //           it1.isSelect = false;
        //           it1.score = 0;
        //         }
        //         it.checked = false;
        //         it.configs = [];
        //         for (var j in r.configs) {
        //           if (r.configs[j].roleId == it.roleId) {
        //             for (var x of r2) {
        //               if (r.configs[j].configId == x.configId) {
        //                 x.isSelect = true;
        //                 x.score = r.configs[j].score;
        //                 x.roleId = it.roleId;
        //               }
        //               if (x.score == null || x.score == "") {
        //                 x.score = 0;
        //                 x.roleId = it.roleId;
        //               }
        //             }
        //             it.checked = true;
        //             if (that.activeName == "") {
        //               that.activeName = it.name;
        //             }
        //           }
        //         }
        //         that.ruleForm.treeList = treeData(r2)
        //         mapTreeChildren(that.ruleForm.treeList)
        //         that.ruleForm.GrowthConfigs = r2
        //         it.configs = JSON.parse(JSON.stringify(r2));
        //       }
        //       that.ruleForm.activityrole = r1;
        //       that.activeName = r1[0].name
        //       that.handleCheckChange()
        //       that.handleTabChange()
        //       if (that.activeName == "") {
        //         that.activeName = r1[0].name;
        //       }
        //       that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
        //       that.activityroleReset = JSON.parse(JSON.stringify(that.ruleForm.activityrole));
        //     })
        //   });
        // });
        // var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        // that.random += random;
      },
      onDelete(Id) //删除
      {
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, "/api/admin/activitytype/" + Id, null, function(r) {
              that.fullscreenLoading = false;
              Init(that);
            });
          }
        })
      },
      onReset() //重置
      {
        let that = this;
        that.ruleForm = JSON.parse(JSON.stringify(this.ruleFormReset));
        that.ruleForm.activityrole = JSON.parse(JSON.stringify(that.activityroleReset));
        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}

      },
      onPriorityChange() {
        let that = this;
        var value = setInt(that.ruleForm.priority);
        that.ruleForm.priority = value;
      },
      handleNodeClick(data) {
        let that = this;
        that.item = data.children;
        that.currentTitle = data.title;
        that.currentId = data.id;
      },
      renderContent(h, {
        node,
        data,
        store
      }) {
        return ( < span class = "custom-tree-node" >
          <
          span style = "font-size:14px;" > {
            node.label
          } < /span> </span > );
      },
      getValue(value) {
        let that = this;
        that.ruleForm.parentId = value;
      },
      onSave() //保存（新增和编辑）
      {
        let that = this;
        if (that.ruleForm.priority == null || that.ruleForm.priority == "") {
          that.ruleForm.priority = "0"
        }

        var configs = [];
        for (var i in that.ruleForm.activityrole) {
          var config = that.ruleForm.activityrole[i];
          if (config.checked == true) {
            for (var t of config.configs) {
              if (t.isSelect == true) {
                var newConfig = {
                  roleId: config.roleId,
                  configId: t.configId,
                  score: t.score
                };
                configs.push(newConfig);
              }
            }
          }
        }
        that.ruleForm.configs = configs;
        var fieldConfigs = [];
        for (var i in that.ruleForm.activityitems) {
          var fieldConfig = {
            name: parseInt(that.ruleForm.activityitems[i]),
          };
          fieldConfigs.push(fieldConfig);
        }
        that.ruleForm.fieldConfigs = fieldConfigs;
        if(!regNullArray( that.ruleForm.configs)){
          that.$refs["ruleForm"].validate(valid => {
            if (valid) {
              if (that.ruleForm.typeId == null) {
                that.fullscreenLoading = true;
                ajaxPost(that, "/api/admin/activitytype", that.ruleForm, function(r) {
                  that.fullscreenLoading = false;
                  that.dialogVisible = false;
                  that.currentId = that.ruleForm.parentId;
                  Init(that);
                });
              } else {
                that.fullscreenLoading = true;
                ajaxPut(that, "/api/admin/activitytype/" + that.ruleForm.typeId, that.ruleForm, function(r) {
                  that.fullscreenLoading = false;
                  that.dialogVisible = false;
                  that.currentId = that.ruleForm.parentId;
                  Init(that);
                });
              }
            } else {
              setTimeout(() => {
                var isError = document.getElementsByClassName("is-error");
                isError[0].querySelector('input').focus();
              }, 1)
              return false;
            }
          });
        }else{
          warning(that, "请确认综合类型的配置");
        }

      },
      onSelect(p, d) {
        if (p.Value == 3 || p.Value == 2 || p.Value == 1 || p.Value == 11) {
          if (d.indexOf(p.Value) < 0) {
            d.push(p.Value);
          }
        }
      },
      onBack() {
        let that = this;
        back(that);
      },
    },
    watch: {
      currentTitle(val) {
        let that = this;
        var titles = val.split('/');
        that.currentNav = ["活动配置"];
        for (var i in titles) {
          if (titles[i] != "活动配置") {
            that.currentNav.push(titles[i]);
          }
        }
      },
      filterText(val) {
        this.$refs.tree.filter(val);
      }
    },
    mounted() {

    },
  };
  function treeData(data) {
        let cloneData = JSON.parse(JSON.stringify(data))
        return cloneData.filter(father => {
            let branchArr = cloneData.filter(child => father['configId'] == child['parentId']);
            branchArr.length > 0 ? father['children'] = branchArr :  father['children'] = null;
            return father['parentId'] == '00000000-0000-0000-0000-000000000000';
        })
  }
  function mapTreeChildren(data){
        if(data && data.length>0){
            data.forEach(item => {
                if(item.parentId === '00000000-0000-0000-0000-000000000000'){
                    if(item.children && item.children.length>0){
                        item.children = flapTree(item.children)
                    }
                }
            });
        }
  }
    //将树菜单的孩子变成列表
  function flapTree(data,arr = []){
        data.forEach(item =>{
            arr.push(item)
            if(item.children && item.children.length>0){
                flapTree(item.children,arr)
            }
        })
        return arr
  }
  function GetItem(that, val) {
    if (val.length !== 0) {
      val.forEach((item) => {
        if (item.id == that.currentId) {
          that.item = item.children;
          that.currentTitle = item.title;
        } else if (item.children.length >= 1) {
          GetItem(that, item.children);
        }
      });

    }
  }
  function setDefaultConfig(that,sign,configs){
        for(let it of sign){
            if(configs.length>0){
             configs.forEach(item =>{
                 if(it.configId == item.configId){
                    it.isSelect = true
                    it.score = item.score
                 }
            })
            }
        }
        that.$emit('change',JSON.parse(JSON.stringify(sign)))
  }
  function setTreeScore(tree,config){
    let data = JSON.parse(JSON.stringify(config))
	  let end = JSON.parse(JSON.stringify(tree))
	  end.forEach(item =>{
		  for(let i = 0;i<data.length;i++){
			  if(item.configId === data[i].configId){
				  item.isSelect = true
				  item.score = data[i].score
			  }
			  if(item.children && item.children.length>0){
				  let current = item.children.findIndex(it =>it.configId == data[i].configId)
				  if(current!=-1){
					  item.children[current].isSelect = true
					  item.children[current].score = data[i].score
				  }
			  }
		  }
	  })
	  return end
  }
  function Init(that) {
    that.fullscreenLoading = true;
    that.data = [];
    that.selectData = [];
    ajaxGet(that, "/api/admin/activitytype/treelist", null, function(r) {
      that.fullscreenLoading = false;
      //that.data = r;
      var tree = {
        id: null,
        value: null,
        name: "活动配置",
        title: "活动配置",
        //label:"活动配置",
        children: r,
      };
      /*用于新增、编辑时的选择，下拉框只能选择第一级*/
      var newTree = JSON.parse(JSON.stringify(tree));
      for (var j = 0; j < newTree.children.length; j++) {
        newTree.children[j].children = [];
      }
      that.selectData.push(newTree);
      that.data.push(tree);
      GetItem(that, that.data);
    });
    //获取活动字段
    ajaxGet(that, "/api/admin/activitytype/type", null, function(r1) {
      that.fullscreenLoading = false;
      that.activityitem = r1;

    });
  }
</script>

<style scoped="scoped">
  .divTreeCont {
    border: 1px solid #DDDDDD;
    padding: 15px;
    border-radius: 4px;
  }

  label {
    margin-bottom: 0;
  }

  .inputGroupCont {

  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }

  .spIcon {
    width: 5px;
    height: 20px;
    border-radius: 5px;
    background-image: linear-gradient(#4545FF, #2D8EFF);
  }

  .spTitle {
    float: left;
    margin-left: 20px;
    color: #323E56;
    letter-spacing: 2px;
    font-size: 16px;
    margin-top: -3px;
  }
</style>
