<!--
  社团职位管理
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          社团职位管理
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <search :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null"></search>
            </li>
            <li class="nav-item">
              <el-button v-if="isPower.clubposition_create == true" type="success" icon="el-icon-circle-plus-outline"
                @click="onCreate()">新增</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0">
        <el-table :data="itemData" stripe class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="name" label="职位名称" class="elColumn" min-width="140"></el-table-column>
          <el-table-column prop="number" label="人数" class="elColumn" min-width="80"></el-table-column>
          <el-table-column label="是否最高负责人" class="elColumn" min-width="100">
            <template slot-scope="scope">
              <svg class="icon iconClass" aria-hidden="true" v-if="scope.row.isDirector">
                <use xlink:href="#fax-circle-check-o"></use>
              </svg>
              <svg class="icon iconNoClass" aria-hidden="true" v-else>
                <use xlink:href="#fax-circle-o"></use>
              </svg>
            </template>
          </el-table-column>
          <el-table-column label="是否管理人员" class="elColumn" min-width="100">
            <template slot-scope="scope">
              <svg class="icon iconClass" aria-hidden="true" v-if="scope.row.isManager">
                <use xlink:href="#fax-circle-check-o"></use>
              </svg>
              <svg class="icon iconNoClass" aria-hidden="true" v-else>
                <use xlink:href="#fax-circle-o"></use>
              </svg>
            </template>
          </el-table-column>
          <el-table-column label="启用状态" class="elColumn" min-width="100">
            <template slot-scope="scope">
              <svg class="icon iconClass" aria-hidden="true" v-if="scope.row.enable">
                <use xlink:href="#fax-circle-check-o"></use>
              </svg>
              <svg class="icon iconNoClass" aria-hidden="true" v-else>
                <use xlink:href="#fax-circle-o"></use>
              </svg>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="160" fixed="right">
            <template slot-scope="scope">
              <operationBtn v-if="isPower.clubposition_update == true" :btnName="'编辑'"
                @click.native="onEdit(scope.row.positionId)"></operationBtn>
              <operationBtn v-if="isPower.clubposition_delete == true" :btnName="'删除'"
                @click.native="onDelete(scope.row.positionId)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
      </comPage>
    </el-card>

    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%" :close-on-click-modal="false"
      :close-on-press-escape="false" :append-to-body="true" :fullscreen="false">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
        <el-row v-if="isDetail!=true">
          <el-col :span="24">
            <el-form-item label="职位名称" prop="name">
              <el-input v-model="ruleForm.name" placeholder="请输入职位名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="人数" prop="number">
              <el-input v-model="ruleForm.number" placeholder="请输入人数"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="是否社团最高负责人" prop="isDirector">
              <el-switch v-model="ruleForm.isDirector" :inactive-value="false" active-color="#409EFF"
                inactive-color="#C0CCDA">
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="是否社团管理人员" prop="isManager" class="itemBox">
              <div>
                <el-switch v-model="ruleForm.isManager" :inactive-value="false" active-color="#409EFF"
                  inactive-color="#C0CCDA">
                </el-switch>
              </div>

            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="启用状态" prop="enable">
              <el-switch v-model="ruleForm.enable" :inactive-value="false" active-color="#409EFF"
                inactive-color="#C0CCDA">
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-else>
          <el-col :span="24">
            <el-form-item label="职位名称">
              {{ruleForm.name}}
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="人数">
              {{ruleForm.number}}
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="启用状态">
              <el-switch v-model="ruleForm.enable" active-color="#409EFF" inactive-color="#C0CCDA" disabled>
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <div v-if="isDetail!=true">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button @click="onReset()">重 置</el-button>
          <el-button type="primary" @click="onSave()">确 定</el-button>
        </div>
        <div v-else>
          <el-button @click="dialogVisible = false">关 闭</el-button>
        </div>
      </span>
    </el-dialog>

  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';

  export default {
    components: {
      comPage,
      operationBtn,
      search
    },
    data() {
      return {
        //加载动画
        fullscreenLoading: false,
        //搜索
        SearchList: null,
        //分页查询接口返回的结果
        page: null,
        //待重置时的表单数据
        ruleFormReset: null,
        //列表数据
        itemData: [],
        //分页左侧按钮集合
        pageBtnList: null,
        //分页数据
        paging: {
          // 总共页数
          pageLength: 0,
          // 每页请求的数据量
          Size: 1,
          //当前在第几页
          Index: 1
        },
        dialogVisible: false,
        dialogTitle: "新增", //弹出框标题
        isDetail: false, //是否是查看弹出框
        ruleForm: {
          positionId: null,
          name: null, //职位名称
          enable: false,
          number: null, //人数
          isDirector: false,
          isManager: false,
        },
        rules: {
          name: [{
              required: true,
              message: '职务不能为空',
              trigger: 'change'
            },
            {
              max: 64,
              message: '职务名称字数不能超过64个字符',
              trigger: 'change'
            },
          ],
          number: [{
              required: true,
              message: '人数不能为空',
              trigger: 'change'
            },
            {
              validator: (rule, value, callback) => {
                let that = this;
                var reg = /^-?[1-9]\d*$/;
                if (value != 0 && !reg.exec(value)) {
                  callback(new Error("优先级只能输入整数！"));
                } else {
                  callback();
                }
              },
              trigger: 'change'
            }
          ],
        },
        // 权限限制
        isPower: {
          //增删改查 权限管理
          clubposition_create: false,
          clubposition_update: false,
          clubposition_delete: false,
          clubposition_details: false,
        },
      };
    },
    methods: {
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },


      /**
       * @description //保存（新增和编辑）
       */
      onSave() {
        let that = this;
        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            if (that.ruleForm.positionId == null) {
              
              that.ruleForm.enable = that.ruleForm.enable==null?false:that.ruleForm.enable;
              that.ruleForm.isDirector = that.ruleForm.isDirector==null?false:that.ruleForm.isDirector;
              that.ruleForm.isManager = that.ruleForm.isManager==null?false:that.ruleForm.isManager;
             
              that.fullscreenLoading = true;
              ajaxPost(that, "/api/admin/clubposition", that.ruleForm, function(r) {
                that.fullscreenLoading = false;
                that.dialogVisible = false;
                pagingPage(that);
              });
            } else {
              that.fullscreenLoading = true;

              ajaxPut(that, "/api/admin/clubposition/" + that.ruleForm.positionId, that.ruleForm, function(r) {
                that.fullscreenLoading = false;
                that.dialogVisible = false;
                pagingPage(that);
              });
            }
          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              isError[0].querySelector('input').focus();
            }, 1)
            return false;
          }
        });
      },
      /**
       * @description 打开新增弹出框
       */
      onCreate() {
        let that = this;
        that.dialogTitle = "新增";
        var ruleForm = JSON.parse(JSON.stringify(that.ruleForm));
        for (var i in ruleForm) {
          ruleForm[i] = null;
        }
        that.ruleForm = JSON.parse(JSON.stringify(ruleForm));
        that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
        that.dialogVisible = true;
        that.isDetail = false;
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      /**
       * @description 查看详情
       * @param {Object} Id
       */
      onDetail(Id) {
        let that = this;
        that.fullscreenLoading = true;
        that.isDetail = true;
        ajaxGet(that, "/api/admin/clubposition/" + Id, null, function(r) {
          that.fullscreenLoading = false;
          that.dialogTitle = "查看";
          that.ruleForm = r;
          that.dialogVisible = true;
        });
      },
      /**
       * @description 打开编辑弹出框
       * @param {String} Id
       */
      onEdit(Id) {
        let that = this;
        that.fullscreenLoading = true;
        that.isDetail = false;
        ajaxGet(that, "/api/admin/clubposition/" + Id, null, function(r) {
          that.fullscreenLoading = false;
          that.dialogTitle = "编辑";
          that.ruleForm = r;
          that.dialogVisible = true;
          that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
        });
      },
      /**
       * @description 搜索事件
       * @param {Object} params 条件
       */
      onSearch(params) {
        let that = this;
        let data = {};
        let searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = element.data;
          }
        });
        data.PageNumer = 1
        routerPath(that, "/Admin/Club/ClubPosition/Index", data, null, "PageSize");
      },
      /**
       * @description 弹出框的重置事件
       */
      onReset() {
        let that = this;
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      /**
       * @description 删除职务
       * @param {Object} Id
       */
      onDelete(Id) {
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, "/api/admin/clubposition/" + Id, null, function(r) {
              that.fullscreenLoading = false;
              pagingPage(that);
            });
          }
        })
      },
      /**
       * @description 分页组件左侧的按钮事件，按顺序进行判断
       * @param {Object} index
       */
      pageBtnClick(index) {},
      /**
       * @description 分页面按钮
       * @param {Object} type
       * @param {Object} val
       */
      pagingClick(type, val) {
        let that = this;
        let url = "/Admin/Club/ClubPosition/Index";
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "Name",
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "Name",
          );
        }
      }
    },
    created() {
      let that = this;
      isPower(that, "clubposition_details", "clubposition_details");
      isPower(that, "clubposition_create", "clubposition_create");
      isPower(that, "clubposition_update", "clubposition_update");
      isPower(that, "clubposition_delete", "clubposition_delete");


      pagingPage(that);
    },
    watch: {
      $route() {
        let that = this;
        pagingPage(that);
      }
    }
  };

  /**
   * @description 分页加载数据
   * @param {Object} that
   */
  function pagingPage(that) {
    paging(
      that,
      function(res) {
        let apiUrl = "/api/admin/clubposition/search";
        let data = {
          PageNumer: res.PageNumer,
          PageSize: res.PageSize,
          Name: res.Name,
        };
        that.SearchList = [{
          type: "input",
          Name: "职位名称",
          data: res.Name,
          holder: "请输入职位名称关键字",
          zhi: "Name"
        }, ];
        that.paging.Index=res.PageNumer;
        that.paging.Size=res.PageSize;
        that.fullscreenLoading = true;
        ajaxGet(that, apiUrl, data, function(resData) {
          that.page = resData;
          that.itemData = resData.items;
          that.fullscreenLoading = false;
        });
      },
      "Name",
    );
  }
</script>

<style scoped="scoped">

</style>
<style>
  .iconClass {
    font-size: 22px;
    color: #406AD3;
  }

  .iconNoClass {
    font-size: 22px;
    color: #EFEFEF;
  }
</style>
