<!--
  日常事务 - 竞赛获奖
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">

    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          宿舍楼层详情
          <!-- <span class="color-danger">（第19周）</span> -->
        </h3>
        <div class="card-tools">
          楼层：
          <el-select v-model="floorId" placeholder="请选择" @change="onChageData">
            <el-option v-for="item in optFloor" :key="item.floorId" :label="item.name" :value="item.floorId">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="card-body pb-0"  v-if="!regNullArray(itemData)">
        <el-row :gutter="20" class="align-items-stretch">
          <el-col :xl="6" :lg="8" :md="12" :sm="24" class="d-flex align-items-stretch" v-for="(room,index) in itemData" :key="index">
            <el-card class="box-card bg-light w-100">
              <div class="card-header border-bottom-0 buildTitleCont p-1 pl-1 clearfix">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#fax-dot-circle-o"></use>
                </svg>
                {{room.name}}
                <div class="divPoints color-success" v-if="room.score!=0">
                {{room.score}}
                </div>
              </div>
              <div class="card-body p-0">
                <div class="bedCont" v-for="(bed,count) in room.beds" :key="count">

                  <div class="divInfo" v-if="!regNull(bed.studentName)">
                    <div class="divIndex">{{count+1}}、</div>
                    <div class="divPhoto">
                      <el-avatar shape="square" size="large" :src="onGetImg(bed.photo)" v-if="bed.photo"></el-avatar>
                      <el-avatar shape="square" size="large" :src="'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png'" v-else></el-avatar>
                    </div>
                    <div class="divBase" @click="onDetail(bed.studentId)">
                      <span class="spName" :class="bed.studentName.length>5?'font-size-12':''">[{{bed.studentNo}}]&nbsp;{{bed.studentName}}</span>
                      <span class="spClass">{{bed.class}}</span>
                    </div>
                    <div class="disScore VerticalMid color-success" v-if="bed.score!=0">
                    {{bed.score}}
                    </div>
                    <div class="divStatus VerticalMid" v-if="!regNull(bed.studentName) && bed.isLeaving">
                      <el-tag type="danger">请假</el-tag>
                    </div>
                    <div class="divStatus VerticalMid" v-else-if="!regNull(bed.studentName) && !bed.isLeaving && bed.isSuspend">
                      <el-tag type="danger">停宿</el-tag>
                    </div>
                    <div class="divLeader VerticalMid color-danger" v-if="bed.leader">
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#fax-star-alt"></use>
                      </svg>
                    </div>
                  </div>
                  <div class="divNoBed VerticalMid" v-if="regNull(bed.studentName)">
                    （空闲）
                  </div>

                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
        
      </div>
      <div v-else class="card-body VerticalMid">
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>




    <div class="row">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
      </div>
    </div>


  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  export default {
    name: '',
    components: {

    },
    data() {
      return {
        // 加载动态
        fullscreenLoading: false,
        // 宿舍楼ID
        buildingId: '',
        //
        itemData: [],

        optFloor: [],
        floorId: '',
      };
    },
    methods: {
      // 返回
      onBack() {
        back(this);
      },
      // 楼层下拉
      onChageData() {
        let that = this;
        onGetData(that);
      },
      onDetail(objID) {
        if(objID) {
          let that = this;
          that.$router.push({
            path: "/Admin/Student/Detail/" + objID,
            query: {
              url: that.$router.history.current.fullPath
            }
          });
        }
      },
      onGetImg(_valSrc) {
        return this.$ServerUrl() + _valSrc;
      }
    },
    created() {
      let that = this;
      that.buildingId = that.$route.params.id;
      onGetData(that);
    },
  };

  function onGetData(that) {
    that.fullscreenLoading = true;
    that.itemData = [];
    ajaxGet(that, '/api/admin/floor/building/enable/' + that.buildingId, null, function(resFloor) {
      that.optFloor = resFloor;
      if (resFloor.length != 0) {
        if (that.floorId == '') {
          that.floorId = resFloor[0].floorId;
        }
        ajaxGet(that, '/api/admin/dormitory/dormitoryfloor/' + that.floorId, null, function(resRoom) {
          that.fullscreenLoading = false;
          that.itemData = resRoom;
        });
      }

      that.fullscreenLoading = false;
    });
  }
</script>

<style scoped="scoped" lang="less">
  .bedCont {
    background-color: #FFFFFF;
    border-bottom: 1px solid #EEEEEE;
    padding:10px 5px;
    position: relative;
    color: #3E3E3C;

    &:last-child {
      border-bottom: none;
    }

    .divInfo {

      display: flex;
      .divPhoto{

      }
      .divBase{
       cursor: pointer;
       margin-left: 15px;
       .spName{
         font-size: 14px;
       }
      }
      .disScore{
        margin-left: 10px;
        font-family: 'OswaldLight';
        width: 50px;
      }
      .divStatus{

        margin-left: 10px;
      }
      span {
        display: block;
      }
      .spClass {
        color: #B3B3B3;
        font-size: 14px;
      }
      .divIndex {
        width: 40px;
        line-height: 40px;
        padding-left: 5px;
      }



      .divLeader {
        width: 30px;
      }
    }

    .divNoBed {
      color: #DDDDDD;
      font-size: 14px;
      height: 40px;
    }


  }


  .buildTitleCont {
    color: #000000;
    position: relative;

    .divPoints{
      float: right;
      padding-right: 10px;
    }
    .icon {
      color: #307BFF;
    }
  }




  .navTabsCont .nav-link {
    font-size: 12px;
  }

  .navToolsCont {
    margin-top: 1px;
  }

  .tipNoData {
    height: 70px;
    font-size: 26px;
    color: #767676;
    padding-bottom: 10px;
  }





  .card-body.p-0 .table tbody>tr>td:last-of-type,
  .card-body.p-0 .table tbody>tr>td:first-of-type {
    padding-left: 0;
    padding-right: 0;
  }

  .tableTwo tr td {
    padding: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left !important;
    padding-right: 10px !important;
  }

  .tableTwo tr td:first-child {
    width: 50px;
    text-indent: 15px;

  }
</style>


<style>
  .align-items-stretch {
    display: flex;
    flex-flow: row wrap;
  }

  .align-items-stretch .d-flex {
    height: 100%;
  }
</style>
