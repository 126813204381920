<!--
  系统管理-用户管理
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">

    <search :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null"></search>
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          用户管理
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <el-button type="success" v-if="isPower.sysuser_create == true" icon="el-icon-circle-plus-outline"
                @click="onAdd()">新增</el-button>
            </li>
            <li class="nav-item ml-1">
              <comImport @onImportSuccess="onImportSuccess" :template="'/templates/用户信息导入模版.xlsx'" :path="uploadInfoApi"
                :btnIcon="'el-icon-upload2'" :title="'批量导入用户'" :btnName="'导入用户'" ref="conImportInfoFile"
                v-if="isPower.sysuser_import == true">
              </comImport>
            </li>
            <li class="nav-item ml-1">
              <comImport @onImportSuccess="onImportSuccess" :template="'/templates/用户学生范围导入模版.xlsx'" :path="'/api/admin/sysuser/importuserfile'"
                :btnIcon="'el-icon-upload2'" :title="'批量导入用户学生范围'" :btnName="'导入学生范围'" ref="conImportInfoFile"
                v-if="isPower.sysuser_import_range == true">
              </comImport>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="item!=null && item.length!=0">
        <el-table stripe :data="item" class="elTableData">
          <el-table-column prop="userName" label="用户名" min-width="140" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <span>{{scope.row.userName}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="姓名" min-width="120" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <span>{{scope.row.name}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="userRoles" label="角色" min-width="180" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <span v-for="(p,index1) in scope.row.userRoles" :key="index1">
                <span v-if="index1==0">{{p.name}}</span>
                <span v-else>，{{p.name}}</span>
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="college" label="所属年级" min-width="180" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="enable" label="启用" min-width="80">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.enable" disabled>
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200px" fixed="right">
            <template slot-scope="scope">
              <operationBtn :btnName="'查看'" v-if="isPower.sysuser_details == true"
                @click.native="onDetail(scope.row.userId)"></operationBtn>

              &nbsp;
              <el-dropdown
                v-if="isPower.sysuser_edit || isPower.sysuser_range || isPower.sysuser_initPassword || (isPower.sysuser_delete && scope.row.isDelete)"
                trigger="click" @command="handleCommand($event,scope.row.userId)">
                <el-button size="mini">
                  更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="isPower.sysuser_edit" class="color-warning"
                    command="onEdit">编辑</el-dropdown-item>
                  <el-dropdown-item divided v-if="isPower.sysuser_range" class="color-primary"
                    command="onFilter">学生范围</el-dropdown-item>
                  <el-dropdown-item v-if="isPower.sysuser_initPassword" class="color-gray"
                    command="onResetPwd">初始化密码</el-dropdown-item>
                  <el-dropdown-item divided v-if="isPower.sysuser_delete && scope.row.isDelete" class="color-danger"
                    command="onDelete">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>

    </el-card>



  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';
  import comImport from '@/components/Import.vue';
  export default {
    components: {
      comPage,
      operationBtn,
      search,
      comImport
    },
    data() {
      return {
        fullscreenLoading: false,
        page: null, //分页查询接口返回的结果
        item: [], //列表数据
        pageBtnList: null, //分页左侧按钮集合
        paging: { //分页数据
          pageLength: 0, // 总共页数
          Size: 10, // 当前请求数量
          Index: 1 //当前在第几页
        },
        uploadInfoApi: '/api/admin/sysuser/import',
        isPower: {
          //增删改查 权限管理
          sysuser_details: false,
          sysuser_create: false,
          sysuser_edit: false,
          sysuser_delete: false,
          sysuser_range: false,
          sysuser_initPassword: false,
          sysuser_import: false,
          sysuser_import_range:false,
        },
        SearchList: null, //搜索集合

        optRole: [], //角色下拉框
        optCollege: [], //年级下拉框
        optEnable: [{
            Value: "true",
            Title: '启用'
          },
          {
            Value: "false",
            Title: '禁用'
          }
        ], //启用禁用下拉框
      };
    },
    created() {
      let that = this;
      isPower(that, "sysuser_details", "sysuser_details");
      isPower(that, "sysuser_create", "sysuser_create");
      isPower(that, "sysuser_edit", "sysuser_edit");
      isPower(that, "sysuser_delete", "sysuser_delete");
      isPower(that, "sysuser_range", "sysuser_range");
      isPower(that, "sysuser_initPassword", "sysuser_initPassword");
      isPower(that, "sysuser_import", "sysuser_import");
      isPower(that,'sysuser_import_range','sysuser_import_range');
      ajaxGet(that, "/api/admin/college/linkage", null, function(resCollege) {
        ajaxGet(that, "/api/admin/sysrole", null, function(resRole) {
          if (resCollege && resCollege.length > 0) {
            resCollege.forEach(x => {
              that.optCollege.push({
                Value: x.collegeId,
                Title: x.name
              });
            });
          }
          if (resRole && resRole.length > 0) {
            resRole.forEach(x => {
              that.optRole.push({
                Value: x.roleId,
                Title: x.name
              });
            })
          }
          pagingPage(that);
        });
      });
    },
    methods: {
      /**
       * @description 更多操作
       * @param {Object} command
       * @param {Object} objValID
       */
      handleCommand(command, objValID) {
        let that = this;
        switch (command) {
          case 'onEdit':
            that.onEdit(objValID);
            break;
          case 'onFilter':
            that.onFilter(objValID);
            break;
          case 'onResetPwd':
            that.onResetPwd(objValID);
            break;
          case 'onDelete':
            that.onDelete(objValID);
            break;
        }
      },
      /**
       * @description 导出学生信息成功后执行
       * @param {Object} param
       */
      onImportSuccess(param) {
        let that = this;
       pagingPage(that);
      },
      onSearch(params) {
        let that = this;
        let data = {};
        let searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = element.data;
          }
        });
        data.PageNumer = 1;
        routerPath(that, "/Admin/user/index", data, null, "PageSize");
      },
      onAdd() //新增
      {
        let that = this;
        // that.$router.push('/Admin/user/Create');
        that.$router.push({
          path: "/Admin/user/Create/",
          query: {
            flag: that.isPower.sysuser_range
          }
        });
      },
      onDetail(Id) //查看
      {
        let that = this;
        that.$router.push({
          path: "/Admin/user/Detail/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });

      },
      onEdit(Id) //编辑
      {
        let that = this;
        that.$router.push({
          path: "/Admin/user/Edit/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onFilter(Id) {
        let that = this;
        that.$router.push({
          path: "/Admin/user/Filter/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onDelete(Id) //删除
      {
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, "/api/admin/sysUser/" + Id, null, function(r) {
              that.fullscreenLoading = false;
              pagingPage(that);
            });
          }
        })
      },
      onResetPwd(Id) //初始化密码
      {
        let that = this;
        confirmResetPwd(that, '重置后密码为xyfz手机号后4位', function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxPut(that, "/api/admin/sysuser/resetpassword/" + Id, null, function(r) {
              that.fullscreenLoading = false;
              pagingPage(that);
            });
          }
        })
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      pagingClick(type, val) {
        let that = this;
        let url = "/Admin/user/index";
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "Name",
            "RoleId",
            "CollegeId",
            "Enable",
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "Name",
            "RoleId",
            "CollegeId",
            "Enable",
          );
        }
      },
    },
    watch: {
      $route() {
        let that = this;
        pagingPage(that);
      }
    }
  };

  function pagingPage(that) {
    paging(
      that,
      function(r) {
        let Myurl = "/api/admin/sysUser/search";
        let enable = r.Enable;
        if (!regNull(enable)) {
          enable = r.Enable == "true" ? true : false;
        } else {
          enable = null;
        }
        let data = {
          PageNumer: r.PageNumer,
          PageSize: r.PageSize,
          Name: r.Name,
          RoleId: r.RoleId,
          CollegeId: r.CollegeId,
          Enable: enable,
        };

        that.SearchList = [{
            type: "input",
            Name: "用户",
            data: r.Name,
            holder: "请输入用户名或姓名",
            zhi: "Name"
          },
          {
            type: "select",
            Name: "角色",
            data: r.RoleId,
            select: that.optRole, //选择的数据
            isClear: true, //select是否可以清除
            holder: "请选择角色",
            zhi: "RoleId"
          },
          {
            type: "select",
            Name: "年级",
            data: r.CollegeId,
            select: that.optCollege, //选择的数据
            isClear: true, //select是否可以清除
            holder: "请选择年级",
            zhi: "CollegeId"
          },
          {
            type: "select",
            Name: "是否启用",
            data: r.Enable,
            select: that.optEnable, //选择的数据
            isClear: true, //select是否可以清除
            holder: "请选择是否启用",
            zhi: "Enable"
          },
        ];
        that.fullscreenLoading = true;
        ajaxGet(that, Myurl, data, function(r) {

          that.page = r;
          that.item = r.items;

          for (var it of that.item) {
            it.isDelete = true;
            if (it.userName.toLowerCase() == JSON.parse(that.$GetKey(that.$cacheTeacherName)).sysName
              .toLowerCase()) {
              it.isDelete = false;
            }
          }
          that.fullscreenLoading = false;
        });
      },
      "Name",
      "RoleId",
      "CollegeId",
      "Enable",
    );
  }

  // 初始化密码确认弹出框
  function confirmResetPwd(that, msg, callback) {
    if (msg == null || typeof(msg) == "undefined" || msg == "") {
      msg = "您确定要初始化密码吗？ 初始化后的密码是yaoyaokeji";
    }
    confirm(that, msg, callback);
  }

</script>

<style scoped="scoped">

</style>

