<template>
  <div class="divLinkageClass">
    <el-cascader
      ref="derLinkageClass"
      v-model="classIds"
      :options="optClass"
      :placeholder="comPlaceholder"
      :props="{
        expandTrigger: 'hover',
        checkStrictly: comCheckStrictly,
        multiple: comMultiple,
      }"
      :collapse-tags="comMultiple"
      @change="handleChange"
      class="width_100Pie"
      filterable
      :class="comClass"
    ></el-cascader>
  </div>
</template>

<script>
export default {
  props: {
    /**
     * @description 设置选择方式
     * @param {boolean} true: 可以选任意一级
     * @param {boolean} false: 只能选择最后一级
     */
    comCheckStrictly: {
      type: Boolean,
      default: false,
    },
    /**
     * @description 获取值时.是否需要完整的值
     * @param {boolean} true: 完整的值，值为数组
     * @param {boolean} false: 只获取最后一级的值
     */
    comComplete: {
      type: Boolean,
      default: false,
    },
    /**
     * @description 设置是否需要默认值
     * @param {boolean} true: 设置显示默认值
     * @param {boolean} false: 不设置显示默认值
     */
    comDefault: {
      type: Boolean,
      default: false,
    },
    /**
     * @description  设置展示到最后一层
     * @param {Number} 1: 年级
     * @param {Number} 2: 班级
     * @param {Number} 3:学生
     */
    comType: {
      type: Number,
      default: 3,
    },

    /**
     * @description 设置是单选 还是 多选
     * @param {boolean} true: 设置多选
     * @param {boolean} false: 设置单选
     */
    comMultiple: {
      type: Boolean,
      default: false,
    },
    /**
     * @description 提示语
     */
    comPlaceholder: {
      type: String,
      default: "请选择",
    },
    /**
     * @description class的名称
     */
    comClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 选中的班级ID
      classIds: [],
      // 班级集合
      optClass: [],
      // 所有数据
      optAllClass: [],
      nowType: 3,
      nowComplete:false,
      nowDefault: false,
    };
  },
  created() {
    let that = this;
    that.nowType = that.comType;
    that.nowComplete=that.comComplete;
    that.nowDefault=that.comDefault;
  },
  methods: {
    /**
     * @description 处理数据，生成下拉选项
     * @param {*} objData
     */
    onGetData(objData) {
      let that = this;
      // 班级集合
      that.optClass = [];
      // 判断是否有值
      if (!regNullArray(objData)) {
        // 保留原始数据
        that.optAllClass = JSON.parse(JSON.stringify(objData));
        // 年级
        let tpCollegeArr = that.onArrUnique(
          objData.map((item) => ({
            value: item.collegeId,
            label: item.college,
            parentId: null,
          }))
        );
        // 班级
        let tpClassArr = that.onArrUnique(
          objData.map((item) => ({
            value: item.classId,
            label: item.class,
            parentId: item.collegeId,
          }))
        );
        // 学生
        let tpStudentArr = objData.map((item) => ({
          value: item.studentId,
          label: item.name + "【" + item.studentNo + "】",
          parentId: item.classId,
        }));

        // 判断显示哪一层级
        if (that.nowType == 1) {
          // 只显示年级
          that.optClass = onGetJsonToTree(
            [...tpCollegeArr],
            "value",
            "parentId",
            "children"
          );
          // 设置默认值
          if (that.nowDefault) {
            that.classIds = [objData[0].collegeId];
            that.$emit("default", objData[0].collegeId);
          }
        } else if (that.nowType == 2) {
          // 只显示班级
          that.optClass = onGetJsonToTree(
            [...tpCollegeArr, ...tpClassArr],
            "value",
            "parentId",
            "children"
          );
          // 设置默认值
          if (that.nowDefault) {
            that.classIds = [objData[0].collegeId, objData[0].classId];
            // 获取值是否为完整的
            if (that.nowComplete) {
              that.$emit("default", that.classIds);
            } else {
              that.$emit("default", objData[0].classId);
            }
          }
        } else if (that.nowType == 3) {
          // 只显示学生
          that.optClass = onGetJsonToTree(
            [...tpCollegeArr, ...tpClassArr, ...tpStudentArr],
            "value",
            "parentId",
            "children"
          );

          // 设置默认值
          if (that.nowDefault) {
            that.classIds = [
              objData[0].collegeId,
              objData[0].classId,
              objData[0].studentId,
            ];
            // 获取值是否为完整的
            if (that.nowComplete) {
              that.$emit("default", that.classIds);
            } else {
              that.$emit("default", objData[0].studentId);
            }
          }
        }
      }
    },
    /**
     * @description 当重置时，又有默认选值时，需要显示调用，
     * @param {Object} objValID ID值
     * @param {Object} objType 1：CollegeID（年级） 2:ClassID（班级） 3:StudentID（学生）
     */
    onSetDefault(objValID, objType) {
      let that = this;
      let tempInfo = null; // 临时存值
      that.classIds = [];
      if (objType == 1) {
        // 入学年份
        that.classIds = [objValID];
      } else if (objType == 2) {
        // 班级
        tempInfo = that.optAllClass.find((item) => item.classId == objValID);
        if (!regNull(tempInfo)) {
          that.classIds = [tempInfo.collegeId, tempInfo.classId];
        }
      } else if (objType == 3) {
        // 学生
        tempInfo = that.optAllClass.find((item) => item.studentId == objValID);
        if (!regNull(tempInfo)) {
          that.classIds = [
            tempInfo.collegeId,
            tempInfo.classId,
            tempInfo.studentId,
          ];
        }
      }
    },
    /**
     * @description 多选状态下，如果父页面有联动动态数据变化，可调用此方法
     * @param {*} objValIdArr
     */
    onMultipleData(objValIdArr) {
      let that = this;
      // 控件显示值
      that.classIds = [];
      // 判断是否有值
      if (!regNullArray(objValIdArr)) {
        // 循环判断，找到数据
        objValIdArr.forEach((item) => {
          let temp = that.optAllClass.find((fd) => fd.studentId == item);
          if (!regNull(temp)) {
            if (that.nowType == 1) {
              // 年级
              that.classIds.push([temp.collegeId]);
            } else if (that.nowType == 2) {
              // 班级
              that.classIds.push([temp.collegeId, temp.classId]);
            } else if (that.nowType == 3) {
              // 学生
              that.classIds.push([temp.collegeId, temp.classId, temp.studentId]);
            }
          }
        });
      }
    },
    /**
     * @description 选中值后处罚事件
     * @param {Object} objItem
     */
    handleChange(objItem) {
      let that = this;
      // 判断选择方式
      if (that.comCheckStrictly) {
        that.$refs.derLinkageClass.dropDownVisible = false;
      }

      // 判断是否多选 还是 单选
      if (that.comMultiple) {
        // 多选
        // 判断是否传完整值
        if (that.comComplete) {
          that.$emit("change", objItem);
        } else {
          that.$emit(
            "change",
            objItem.map((item) => item[item.length - 1])
          );
        }
      } else {
        // 单选
        // 判断是否传完整值
        if (that.comComplete) {
          that.$emit("change", objItem);
        } else {
          that.$emit("change", objItem[objItem.length - 1]);
        }
      }
    },
    /**
     * @description 重置
     */
    onReset() {
      let that = this;
      if (that.comDefault == false) {
        that.classIds = [];
      }
    },
    /**
     * @description 去除数组内重复值
     * @param {*} arr
     */
    onArrUnique(arr) {
      const res = new Map();
      return arr.filter((arr) => !res.has(arr.value) && res.set(arr.value, 1));
    },
  },
};
</script>

<style>
.el-checkbox {
  margin-bottom: 0;
}
</style>
