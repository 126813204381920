<!-- 评语类型 -->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px">短语级别</h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <search :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList != null"></search>
            </li>
            <li class="nav-item">
              <el-button v-if="isPower.admin_commenttype_create == true" type="success" icon="el-icon-circle-plus-outline" @click="onCreate()">新增</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="!regNullArray(itemData)">
        <el-table :data="itemData" stripe class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80"></el-table-column>
          <el-table-column prop="name" label="短语级别名称" class="elColumn"></el-table-column>
          <el-table-column prop="ratio" label="比例（%）" class="elColumn"></el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <operationBtn v-if="isPower.admin_commenttype_edit" :btnName="'编辑'" @click.native="onEdit(scope.row)"></operationBtn>
              <operationBtn v-if="isPower.admin_commenttype_delete" :btnName="'删除'" @click.native="onDelete(scope.row.typeId)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="card-body" v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>

    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :append-to-body="true"
      :fullscreen="false"
    >
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="短语级别名称" prop="name">
              <el-input v-model="ruleForm.name" placeholder="请输入短语级别"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="比例（%）" prop="ratio">
              <el-input v-model="ruleForm.ratio" placeholder="请输入比例"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onReset">取 消</el-button>
        <el-button type="primary" @click="onSave()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import '@/assets/css/Admin.css';
import operationBtn from '@/components/operationBtn.vue';
import search from '@/components/search.vue';

export default {
  components: {
    operationBtn,
    search
  },
  data() {
    return {
      //加载动画
      fullscreenLoading: false,
      //搜索
      SearchList: null,
      //分页查询接口返回的结果
      page: null,
      //待重置时的表单数据
      ruleFormReset: null,
      //列表数据
      itemData: [],

      dialogVisible: false,
      dialogTitle: '新增', //弹出框标题

      ruleForm: {
        typeId: null,
        name: null, //类型名称
        ratio: null //比例
      },
      rules: {
        name: [
          {
            required: true,
            message: '短语级别名称不能为空',
            trigger: 'change'
          }
        ],
        ratio: [
          {
            required: true,
            message: '比例不能为空',
            trigger: 'change'
          },
          {
            validator: (rule, value, callback) => {
              let that = this;
              var reg = /^([0-9]|[0-9]+\.?[0-9]|-[0-9]+\.?[0-1]|-[0-9])$/;
              if (value != 0 && !reg.exec(value)) {
                callback(new Error('比例只能输入数字（小数保留1位）'));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ]
      },
      isPower: {
        //增删改查 权限管理
        admin_commenttype_create: false,
        admin_commenttype_edit: false,
        admin_commenttype_delete: false
      }
    };
  },
  created() {
    let that = this;
    isPower(that, 'admin_commenttype_create', 'admin_commenttype_create');
    isPower(that, 'admin_commenttype_edit', 'admin_commenttype_edit');
    isPower(that, 'admin_commenttype_delete', 'admin_commenttype_delete');

    that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));

    pagingPage(that);
  },
  methods: {
    /**
     * @description 新增、编辑
     */
    onSave() {
      let that = this;

      that.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let tpParams = JSON.parse(JSON.stringify(that.ruleForm));
          if (that.ruleForm.typeId == null) {
            that.fullscreenLoading = true;
            ajaxPost(that, '/api/admin/commentphrasetype', tpParams, function (r) {
              that.fullscreenLoading = false;
              that.dialogVisible = false;
              pagingPage(that);
            });
          } else {
            that.fullscreenLoading = true;
            ajaxPut(that, '/api/admin/commentphrasetype/' + that.ruleForm.typeId, tpParams, function (r) {
              that.fullscreenLoading = false;
              that.dialogVisible = false;
              pagingPage(that);
            });
          }
        } else {
          setTimeout(() => {
            var isError = document.getElementsByClassName('is-error');
            isError[0].querySelector('input').focus();
          }, 1);
          return false;
        }
      });
    },
    /**
     * @description 新增按钮
     */
    onCreate() {
      let that = this;
      that.dialogTitle = '新增';
      that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
      that.dialogVisible = true;
      try {
        that.$refs['ruleForm'].resetFields();
      } catch {}
    },
    /**
     * @description 编辑按钮
     * @param {*} objEvent
     */
    onEdit(objEvent) {
      let that = this;
      that.dialogTitle = '编辑';
      that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
      that.ruleForm = JSON.parse(JSON.stringify(objEvent));
      that.dialogVisible = true;
      try {
        that.$refs['ruleForm'].resetFields();
      } catch {}
    },
    onSearch(
      params // 搜索
    ) {
      let that = this;
      let data = {};
      let searchData = params;
      searchData.forEach((element) => {
        if (element.data) {
          data[element.zhi] = element.data;
        }
      });
      data.PageNumer = 1;
      routerPath(that, '/Admin/Adviser/CommentType/Index', data, null, 'PageSize');
    },
    /**
     * @description 关闭弹出框
     */
    onReset() {
      let that = this;
      that.dialogVisible = false;
      that.ruleForm = JSON.parse(JSON.stringify(this.ruleFormReset));
      try {
        that.$refs['ruleForm'].resetFields();
      } catch {}
    },
    /**
     * @description 删除弹出框
     * @param {*} Id
     */
    onDelete(Id) {
      let that = this;
      confirmDelete(that, null, function (res) {
        if (res == true) {
          that.fullscreenLoading = true;
          ajaxDelete(that, '/api/admin/commentphrasetype/' + Id, null, function (r) {
            that.fullscreenLoading = false;
            pagingPage(that);
          });
        }
      });
    }
  },
  watch: {
    $route() {
      let that = this;
      pagingPage(that);
    }
  }
};

function pagingPage(that) {
  paging(
    that,
    function (r) {
      let data = {
        PageNumer: 1,
        PageSize: 99999,
        Name: r.Name
      };
      that.SearchList = [
        {
          type: 'input',
          Name: '短语类型名称',
          data: r.Name,
          holder: '请输入类型名称关键字',
          zhi: 'Name'
        }
      ];
      that.fullscreenLoading = true;
      ajaxGet(that, '/api/admin/commentphrasetype/search', data, function (resData) {
        that.itemData = resData.items;
        that.fullscreenLoading = false;
      });
    },
    'Name'
  );
}
</script>

<style scoped="scoped"></style>
