<!--
  我的活动荣誉
-->
<template>
  <div class="SubWebPageStudent" v-loading.fullscreen.lock="fullscreenLoading">


    <!-- 列表-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          我的活动荣誉
        </h3>
        <!-- 搜索 -->
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <comSearch :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null">
              </comSearch>
            </li>
          </ul>
        </div>

      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length !=0">
        <el-table :data="itemData" stripe class="elTableData">
          <el-table-column type="index" label="序号" width="80"></el-table-column>
          <el-table-column prop="semester" label="学期" class="elColumn"></el-table-column>
          <el-table-column prop="class" label="班级" class="elColumn"></el-table-column>
          <el-table-column prop="adviser" label="班主任" class="elColumn"></el-table-column>
          <el-table-column prop="awardsType" label="荣誉类型" class="elColumn"></el-table-column>
          <el-table-column prop="brief" label="简要" class="elColumn"></el-table-column>
          <!-- <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <operationBtn :btnName="'查看'" @click.native="onDetail(scope.row.awardsId)"></operationBtn>
            </template>
          </el-table-column> -->
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
  </div>
</template>

<script>
  import '@/assets/css/Student.css';
  import comPage from '@/components/Page.vue';
  import comSearch from '@/components/search.vue';

  export default {
    components: {
      comSearch,
      comPage
    },
    data() {
      return {
        // 动画
        fullscreenLoading: false,
        // 搜索
        SearchList: null,
        //列表数据
        itemData: [],
        //分页查询接口返回的结果
        page: null,
        //分页左侧按钮集合
        pageBtnList: null,
        //分页数据
        paging: {
          // 总共页数
          pageLength: 0,
          // 每页请求的数据量
          Size: 1,
          //当前在第几页
          Index: 1
        },

      };
    },
    methods: {
      onSearch(params) // 搜索
      {
        let that = this;
        let data = {};
        let searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = element.data;
          }
        });
        routerPath(that, "/Student/Myself/Reward/Index", data, null, "PageSize");
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      // 分页面按钮
      pagingClick(type, val) {
        let that = this;
        let url = "/Student/Myself/Reward/Index";
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "SemesterId",
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "SemesterId",
          );
        }
      }
    },
    created() {
      let that = this;
      onGetDataPage(that);
    },
    mounted() {

    },
    watch: {
      $route() {
        let that = this;
        onGetDataPage(that);
      }
    }
  };

  function onGetDataPage(that) {
    paging(
      that,
      function(res) {
        let apiUrl = "/api/student/studentawards/search";
        that.fullscreenLoading = true;

        // 搜索 下拉学期
        ajaxGet(that, "/api/student/semester/student", null, function(resSemester) {
          // 获得学期数据
          var SemesterId = res.SemesterId;
          let arrSemester = [];
          for (var i in resSemester) {
            let arrTemp = {
              Title: resSemester[i].name, //semester[item].name,
              Value: resSemester[i].semesterId //semester[item].semesterId,
            };
            if (SemesterId == null || typeof(SemesterId) == "undefined" || SemesterId == "") {
              if (resSemester[i].isCurrent == true) {
                SemesterId = resSemester[i].semesterId;
              }
            }
            arrSemester.push(arrTemp);
          }
          that.SearchList = [
            // 学期
            {
              type: "select",
              Name: "学期",
              data: SemesterId,
              holder: "请选择学期",
              select: arrSemester, //选择的数据
              zhi: "SemesterId", // 传值
            },
          ];

          let data = {
            PageNumer: res.PageNumer,
            PageSize: res.PageSize,
            SemesterId: SemesterId,
          };
          ajaxGet(that, apiUrl, data, function(resData) {
            that.page = resData;
            that.itemData = resData.items;

            that.fullscreenLoading = false;
          });
        })
      },
      "SemesterId",
    );
  }
</script>

<style scoped="scoped">

</style>
