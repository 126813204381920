<!--
  奖励记录-奖励详情
-->
<template>
  <div class="SubWebPageStudent" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card" v-if="itemData.name">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          查看申请信息
        </h3>
      </div>
      <div class="card-body">
        <el-row :gutter="24">
          <el-col :span="24" class="colInfo">
            <label>奖项</label>
            <div class="colFonts">{{ flNoValue(itemData.name) }}</div>
          </el-col>

          <el-col :span="6" class="colInfo">
            <label>奖励级别</label>
            <div class="colFonts">{{ flNoValue(itemData.level.name) }}</div>
          </el-col>
          <el-col :span="6" class="colInfo">
            <label>操作时间</label>
            <div class="colFonts">
              {{
                flNoValue(itemData.lastUpdateDate) | dateformat("YYYY-MM-DD")
              }}
            </div>
          </el-col>
        </el-row>
      </div>
    </el-card>

    <el-card
      class="box-card"
      v-if="itemData.remark != null && itemData.remark != ''"
    >
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          备注
        </h3>
      </div>
      <div class="card-body">
        <div class="colRemarksCont" v-html="flNoValue(itemData.remark)"></div>
      </div>
    </el-card>

    <el-card
      class="box-card"
      v-if="uploadFiles!=null && uploadFiles.length!=0"
    >
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          附件<small class="color-danger">(点击图片查看原图)</small>
        </h3>
      </div>
      <div class="card-body ">
        <el-carousel height="500px" :autoplay="!showViewer">
          <el-carousel-item v-for="(item,index) in uploadFiles " :key="index" class="VerticalMid">
            <img :src="item" @click="onPreview" height="100%">
          </el-carousel-item>
        </el-carousel>
      </div>
    </el-card>
      <el-card class="box-card" v-if="auditReacord.length>0">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            审核记录
          </h3>
        </div>
        <div class="card-body p-0">
          <el-table :data="auditReacord" stripe class="elTableData">
            <el-table-column type="index" label="序号" width="80"></el-table-column>
            <el-table-column label="审核时间" width="160">
              <template slot-scope="scope">
                {{scope.row.createDate | dateformatDay}}
              </template>
            </el-table-column>
            <el-table-column prop="auditReason" label="备注">
               <template slot-scope="scope">
                <span v-if="scope.row.auditReason" v-html="scope.row.auditReason"></span>
                <span v-else>暂无</span>
                 <!-- {{scope.row.auditReason ? scope.row.auditReason : '暂无'}} -->
               </template>
            </el-table-column>
          </el-table>
        </div>
      </el-card>
    <div class="row mt-1">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
      </div>
    </div>
     <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="uploadFiles" />
  </div>
</template>

<script>
import '@/assets/css/Student.css';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
export default {
  components: {
    ElImageViewer
  },
  data() {
    return {
      fullscreenLoading: false,
      dialogVisible: false,
      auditReacord:[],
      itemData: {},
      showViewer: false,
      uploadFiles: [],
    };
  },
  methods: {
    onPreview() {
      this.showViewer = true;
    },
    closeViewer() {
        this.showViewer = false
      },
    onBack() {
      let that = this;
      back(that);
    },
    flNoValue(obj) {
      if (obj == null || typeof obj == "undefined" || obj == "") {
        return "暂无数据";
      }
      return obj;
    },
  },
  created() {
    let that = this
    let Id = that.$route.params.id
    that.fullscreenLoading = true
    that.type=that.$route.query.type;
    if(that.type==2){
      ajaxGet(that,`/api/student/prizeapply/${Id}`,null,function(res){
        that.fullscreenLoading = false
        that.auditReacord = res.audit
        if(res.attachment){
         that.uploadFiles = res.attachment.split('|').map(item =>`${that.$ServerUrl()}${item}`)
        }
        that.itemData = res
      })
    }else if(that.type==1){
      ajaxGet(that,`/api/student/prize/${Id}`,null,function(res){
        that.fullscreenLoading = false
        // that.auditReacord = res
        if(res.attachment){
         that.uploadFiles = res.attachment.split('|').map(item =>`${that.$ServerUrl()}${item}`)
        }
        that.itemData = res
      })
    }
  }
};
</script>

<style scoped="scoped">
  .divLiteracyBox {
     padding: 15px;
   }

   .divLiteracyBox .listBox {
     text-align: center;
     padding: 20px;
     float: left;
     border: 2px solid #FFFFFF;
     border-radius: 5px;
   }

   .divLiteracyBox .listBox:hover {
     border: 2px solid #409eff;
     cursor: pointer;
   }

   .divLiteracyBox .literacyName {
     font-size: 24px;
     letter-spacing: 2px;
     padding-bottom: 10px;
   }

   .divLiteracyBox .literacyNum {
     padding-top: 10px;
     font-family: 'OswaldLight';
     border-top: 2px solid #EEEEEE;
     font-size: 32px;
     color: #409eff;
   }
</style>
