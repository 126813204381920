<!--
  学生管理-学生奖励
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <search :ColNumber="3" :SearchList="SearchList" :key="random" ref="SearchList" @onSearch="onSearch"
      v-if="SearchList!=null"></search>
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          综合积分
        </h3>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table stripe :data="itemData" class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="className" label="班级" min-width="100"></el-table-column>
          <el-table-column prop="studentNo" label="学号" min-width="100"></el-table-column>
          <el-table-column prop="studentName" label="姓名" min-width="200"></el-table-column>
          <el-table-column prop="score" label="分数" min-width="100"></el-table-column>
          <el-table-column prop="credit" label="学分" min-width="100"></el-table-column>
          <el-table-column label="月份" min-width="100">
            <template slot-scope="scope">
              {{scope.row.month | dateformat('YYYY-MM')}}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100px" fixed="right">
            <template slot-scope="scope">
              <operationBtn :btnName="'查看'" @click.native="onDetail(scope.row.creditId)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
    <div class="row" style="margin-top: 10px;">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack">返回</el-button>
      </div>
    </div>

  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';

  export default {
    name: '',
    components: {
      comPage,
      operationBtn,
      search
    },
    data() {
      return {
        random: null,
        fullscreenLoading: false,
        itemData: [],
        page: null, //分页查询接口返回的结果
        pageBtnList: null, //分页左侧按钮集合
        paging: { //分页数据
          pageLength: 0, // 总共页数
          Size: 10, // 当前请求数量
          Index: 1 //当前在第几页
        },
        isPower: {
          moraleducation_user: false,
        },
        SearchList: null, //搜索集合
      };
    },
    created() {
      let that = this;
      isPower(that, 'moraleducation_user', 'moraleducation_user')
      pagingPage(that);
    },
    methods: {
      /**
       * @description 返回
       */
      onBack() {
        back(this);
      },
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },

      /**
       * @description 月度测评
       */
      onEvaluation() {
        let that = this;
        that.$router.push({
          path: "/Admin/MoralEducation/Evaluation",
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      /**
       * @description 详情
       * @param {Object} objID
       */
      onDetail(objID) {
        let that = this;
        that.$router.push({
          path: "/Admin/MoralEducation/Details/" + objID,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },

      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      onSearch(params) {
        let that = this;
        let searchData = params;
        let data = {};
        searchData.forEach(element => {
          data[element.zhi] = Join(element.data);
        });
        data.PageNumer = 1;
        data.radom = Math.random(1000)
        routerPath(that, "/Admin/MoralEducation/Evaluation", data, null, "PageSize");

      },
      pagingClick(type, val) {
        let that = this;
        let url = "/Admin/MoralEducation/Evaluation";
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "ClassId",
            "Student"
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "ClassId",
            "Student"
          );
        }
      },
    },
    watch: {
      $route() {
        let that = this;
        pagingPage(that);
      }
    }
  };

  function pagingPage(that) {
    paging(
      that,
      function(r) {
        ajaxGet(that, '/api/admin/sysuser/userfilter', null, function(resultClass) {
          ajaxGet(that, '/api/admin/class/userclass', null, function(resultManageClass) {
            let ClassId = r.ClassId;
            var classList = [];
            if (that.isPower.moraleducation_user == true) {
              for (var i in resultClass) {
                var it = {
                  Title: resultClass[i].alias,
                  Value: resultClass[i].classId,
                };
                if (ClassId == null || typeof(ClassId) == "undefined" || ClassId == "") {
                  ClassId = resultClass[0].classId;
                }
                classList.push(it);
              }

              if (resultManageClass != '') {
                //判断当前的班级是否为班主任管理的班级
                that.ManagerClass = resultManageClass;
                for (var item of resultManageClass) {
                  if (item.classId == ClassId) {
                    that.isTeacher = true;

                  }
                }
              }
            } else {
              if (resultManageClass != '') {
                for (var i in resultManageClass) {
                  var it = {
                    Title: resultManageClass[i].alias,
                    Value: resultManageClass[i].classId,
                  };
                  if (ClassId == null || typeof(ClassId) == "undefined" || ClassId == "") {
                    ClassId = resultManageClass[0].classId;
                  }
                  classList.push(it);
                }
              }
              that.isTeacher = true;
            }
            that.SearchList = [{
                type: "select",
                Name: "班级",
                data: ClassId,
                select: classList,
                zhi: "ClassId",
                isClear: false, //select是否可以清除
                resetData: ClassId,
              },
              {
                type: "monthtime",
                Name: "月份",
                data: r.month,
                zhi: "month",
              },
              {
                type: "input",
                Name: "学生",
                data: r.Student,
                holder: "请输入学号/姓名",
                zhi: "Student"
              }
            ];
            let MonthDate = null;
            if (r.month != null && r.month != "") {
              MonthDate = dateFormat(r.month, "-", "YYYY-MM");
            }

            let data = {
              Name: r.Student,
              Month: MonthDate,
              ClassId: r.ClassId,
              PageNumer: r.PageNumer,
              PageSize: r.PageSize,
            };
            that.fullscreenLoading = true;
            that.itemData = [];
            ajaxGet(that, '/api/admin/classcreditmonth/search', data, function(resdata) {
              that.fullscreenLoading = false;
              if (resdata.items != "") {
                that.itemData = resdata.items;
                that.page = resdata;
              }
            })
          })
        })
      },
      "ClassId",
      "month",
      "Student",
    );
  }
</script>

<style scoped="scoped">
</style>
