<!--
  学生成绩录入
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">

    <el-form label-width="60px">
      <el-card class="card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            请在下方选择课程后再录入成绩
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24">
            <el-col :span="6">
              <el-form-item label="学期">
                <el-select v-model="arrSearch.strSemester" style="width: 100%;" placeholder="请选择" filterable
                  @change="onChangeSemester">
                  <el-option v-for="(item,index) in arrSearch.optSemester" :key="index" :label="item.name"
                    :value="item.semesterId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="班级">
                <el-select v-model="arrSearch.strClass" style="width: 100%;" placeholder="请选择" filterable
                  @change="handleChangeClass">
                  <el-option v-for="(item,index) in arrSearch.optClass" :key="index" :label="item.alias"
                    :value="item.classId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="课程">
                <el-select v-model="arrSearch.strCourse" style="width: 100%;" placeholder="请选择" filterable
                  @change="handleChangeCourse">
                  <el-option v-for="(item,index) in arrSearch.optCourse" :key="index" :label="item.name"
                    :value="item.courseId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>
    </el-form>
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          {{getScoreTitle}}
        </h3>
      </div>
      <div class="card-body width_100Pie" v-if="ruleForm.itemData!=null && ruleForm.itemData.length!=0">
        <el-form :rules="ruleForm.rules" ref='ruleForm' :model="ruleForm">
          <el-table :data="ruleForm.itemData" class="width_100Pie">
            <el-table-column type="index" label="序号" class="elColumn" width="80"></el-table-column>
            <el-table-column prop="studentNo" label="学号" width="200"></el-table-column>
            <el-table-column prop="studentName" label="姓名" width="200"></el-table-column>
            <el-table-column prop="address" :label="item.Text" v-for="(item,index) in arrSearch.optType" :key="index">
              <template slot-scope="scope">
                <el-form-item :rules="ruleForm.rules[scoreMap[item.Value]]"
                  :prop='`itemData.${scope.$index}.${scoreMap[item.Value]}`'>
                  <el-input v-model="scope.row[scoreMap[item.Value]]" placeholder="请录入成绩"> </el-input>
                </el-form-item>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>




    <div class="row" style="margin-top: 10px;">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
        <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
        <el-button type="primary" icon="el-icon-circle-check" @click="onPostData">提交</el-button>
      </div>
    </div>




  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  //import qs from 'qs';
  export default {
    name: '',
    components: {

    },
    data() {
      return {
        ruleForm: {
          itemData: [],
          rules: {}
        },
        scoreMap: {
          "1": 'peaceTime', //平时
          "2": 'midTerm', //期中
          "3": 'final', //期末
          "4": 'total', //总评
          "5": 'gpa', //绩点
          '6': 'credit' //学分
        },
        fullscreenLoading: false,
        //考查课配置类型
        options: [],
        //考试课分数配置范围
        courseConfigRange: [],
        // 搜索数据集
        arrSearch: {
          // 学期
          strSemester: '',
          semesterId: '',
          optSemester: [],
          // 班级
          strClass: '',
          classId: '',
          optClass: [],
          // 课程
          strCourse: '',
          courseId: '',
          optCourse: [],
          // 学生
          strStudent: '',
          studentId: '',
          optStudent: [],
          strType: '',
          optType: [],
        },
        arrSearchReset: null,

        // 学生数据
        itemData: [],

        // 课程名称
        thCourseName: '',
        // 记录课程类型
        isCourseType: 1,
        TeacherInfo: {},
      };
    },
    computed: {
      getScoreTitle() {
        if (this.arrSearch.strCourse) {
          let temp = this.arrSearch.optCourse.find(it => it.courseId == this.arrSearch.strCourse)
          if (temp) {
            return `请录入${temp.name}课程的成绩`
          }
          return '暂无录入课程'
        }
        return '暂无录入课程'
      }
    },
    methods: {
      /**
       * @description 学期选中事件
       */
      onChangeSemester() {
        // this.arrSearch.strClass = null
        this.handleChangeClass(this.arrSearch.strClass)
        this.onSearch();
      },

      /**
       * @description 班级下接事件
       * @param {Object} _valueID
       */
      handleChangeClass() {
        let that = this;
        that.arrSearch.strCourse = null;
        let _data = {
          classId: this.arrSearch.strClass,
          semesterId: that.arrSearch.strSemester,
        }
        let url = `/api/admin/academicrecord/course/${_data.semesterId}/${_data.classId}`
        if (!_data.classId) {
          url = `/api/admin/academiccourse/course/${_data.semesterId}`
        }
        ajaxGet(that, url, null, function(resData) {
          that.arrSearch.optCourse = resData;
          that.onSearch();
        });
      },

      /**
       * @description 课程下接事件
       * @param {Object} _valueID
       */
      handleChangeCourse(_valueID) {
        let that = this;
        let temp = that.arrSearch.optCourse.find(item => item.courseId == _valueID);
        that.isCourseType = temp.courseType;
        that.thCourseName = temp.name;
        let rules = {}
        if (that.isCourseType == 2 || !that.isCourseType) { //考查课
          rules = {
            gpa: [{
              pattern: /^([0-9]+\.?[0-9]*|-[0-9]+\.?[0-9]*)$/,
              message: '请输入数字',
              trigger: 'change'
            }],
            credit: [{
              pattern: /^([0-9]+\.?[0-9]*|-[0-9]+\.?[0-9]*)$/,
              message: '请输入数字',
              trigger: 'change'
            }]
          }
        } else { //考试课
          rules = {
            gpa: [{
              pattern: /^([0-9]+\.?[0-9]*|-[0-9]+\.?[0-9]*)$/,
              message: '请输入数字',
              trigger: 'change'
            }],
            credit: [{
              pattern: /^([0-9]+\.?[0-9]*|-[0-9]+\.?[0-9]*)$/,
              message: '请输入数字',
              trigger: 'change'
            }],
            peaceTime: [{
              pattern: /^([0-9]+\.?[0-9]*|-[0-9]+\.?[0-9]*)$/,
              message: '请输入数字',
              trigger: 'change'
            }],
            midTerm: [{
              pattern: /^([0-9]+\.?[0-9]*|-[0-9]+\.?[0-9]*)$/,
              message: '请输入数字',
              trigger: 'change'
            }],
            final: [{
              pattern: /^([0-9]+\.?[0-9]*|-[0-9]+\.?[0-9]*)$/,
              message: '请输入数字',
              trigger: 'change'
            }],
            total: [{
              pattern: /^([0-9]+\.?[0-9]*|-[0-9]+\.?[0-9]*)$/,
              message: '请输入数字',
              trigger: 'change'
            }],
          }
        }
        that.ruleForm.rules = rules
        that.$set(that.ruleForm, "rules", rules)
        that.onSearch();
      },

      /**
       * @description 成绩类型选中事件
       */
      onChangeType() {
        this.onSearch();
      },

      /**
       * @description 搜索数据
       */
      onSearch() {
        let that = this;
        // 四个条件必须要有
        if (!regNull(that.arrSearch.strSemester) && !regNull(that.arrSearch.strClass) && !regNull(that.arrSearch
            .strCourse)) {
          let _data = {
            SemesterId: that.arrSearch.strSemester,
            ClassId: that.arrSearch.strClass,
            CourseId: that.arrSearch.strCourse,
          };
          that.fullscreenLoading = true;
          ajaxGet(that, '/api/admin/academicrecord/courseallscore', _data, function(resData) {
            that.ruleForm.itemData = [];
            let len = resData.length
            for (let i = len; i--;) {
              let item = resData[i]
              that.ruleForm.itemData.push({
                studentId: item.studentId,
                studentName: item.studentName,
                studentNo: item.studentNo,
                semesterId: that.arrSearch.strSemester,
                courseId: that.arrSearch.strCourse,
                peaceTime: item.peaceTime, //平时成绩
                midTerm: item.midTerm, //期中成绩
                final: item.final, //期末成绩
                total: item.total, //总评
                gpa: item.gpa, //绩点
                credit: item.credit ///学分
              })
            }
            that.fullscreenLoading = false;
          });
        } else {
          that.ruleForm.itemData = [];
        }
      },

      /**
       * @description 返回
       */
      onBack() {
        let that = this;
        back(that);
      },
      /**
       * @description 页面重置
       */
      onReset() {
        let that = this;
        that.arrSearch.strSemester = that.arrSearch.optSemester.find(item => item.isCurrent == true).semesterId;
        that.arrSearch.strClass = this.arrSearch.optClass[0].classId
        that.handleChangeClass(this.arrSearch.optClass[0].classId)
        that.arrSearch.strCourse = null
        that.arrSearch.strType = that.arrSearch.optType[0].Value
      },
      /**
       * @description 搜索重置
       */
      onResetSearch() {
        let that = this;
        that.arrSearch = JSON.parse(JSON.stringify(that.arrSearchReset));
      },


      /**
       * @description 提交数据
       */
      onPostData() {
        let that = this;
        if (that.ruleForm.itemData && that.ruleForm.itemData.length == 0) {
          warning(that, '无班级学生数据，请重新搜索');
        } else {
          that.$refs.ruleForm.validate(valid => {
            if (valid) {
              that.ruleForm.itemData.forEach(item => {
                for (let type of that.arrSearch.optType) {
                  if (!item[that.scoreMap[type.Value]]) {
                    item[that.scoreMap[type.Value]] = null
                  }
                }
              })
              that.fullscreenLoading = true
              ajaxPut(that, "/api/admin/academicrecord/enterallscore", that.ruleForm.itemData, function(res) {
                that.fullscreenLoading = false
              })
            } else { return false }
          })
        }
      },

    },
    created() {
      let that = this;
      // 获取教师信息
      that.TeacherInfo = onGetNowUser(that);
      onGetData(that);
    },
  };


  // 获取学生列表数据
  function onGetData(that) {
    that.fullscreenLoading = true;
    // 获取学期数据
    ajaxGet(that, '/api/admin/semester', null, function(resSemester) {
      that.arrSearch.optSemester = resSemester;
      that.arrSearch.strSemester = resSemester.find(item => item.isCurrent == true).semesterId;
      // 获取我的范围内班级数据

      // 教师信息

      let resClass = that.TeacherInfo.isTeacher ? that.TeacherInfo.manageClass : that.TeacherInfo.allClass;

      that.arrSearch.optClass = resClass;
      that.arrSearch.strClass = resClass[0].classId
      that.handleChangeClass(resClass[0].classId);
      // 获取成绩类型
      ajaxGet(that, '/api/admin/academicrecord/type', null, function(resType) {
        that.arrSearch.optType = resType;
        that.arrSearch.strType = resType[0].Value.toString();
        that.fullscreenLoading = false;
        that.arrSearchReset = JSON.parse(JSON.stringify(that.arrSearch));
      });


    });
  };

</script>

<style scoped="scoped">
  .inGradesCont .itemList {
    padding-bottom: 15px;
  }

  .elTableData .el-form-item {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }

</style>
<style>
  .inGradesCont .el-input-group__prepend {
    font-size: 14px;
  }

</style>

