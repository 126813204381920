<!--
  日常事务 - 日常动态管理 - 行为规范
-->
<template>
  <div class="SubWebPageStudent" v-loading.fullscreen.lock="fullscreenLoading">
    <!-- 搜索 -->
    <search :key="random"  :ColNumber="2" :SearchList="SearchList" ref="SearchList"
      @onSearch="onSearch" v-if="SearchList!=null"></search>
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          行为规范
        </h3>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table :data="itemData" stripe class="elTableData">
          <el-table-column label="原因" min-width="300" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <span v-html="onGetReason(scope.row)"></span>
            </template>
          </el-table-column>
          <el-table-column label="申诉状态" width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.appealStatus == 1" style="color: #409EFF">{{scope.row.appealStatusName}}</span>
              <span v-if="scope.row.appealStatus == 2" style="color: #67C23A">{{scope.row.appealStatusName}}</span>
              <span v-if="scope.row.appealStatus == 3" style="color: #F56C6C">{{scope.row.appealStatusName}}</span>
              <span v-if="scope.row.appealStatus == 4" style="color: #909399">{{scope.row.appealStatusName}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="score" label="分数" width="120"></el-table-column>
          <!-- <el-table-column prop="createdBy" label="记录人" width="80"></el-table-column> -->
          <el-table-column label="行为时间" width="150">
            <template slot-scope="scope">
              <span>{{scope.row.logDate | dateformatMinute}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180" fixed="right">
            <template slot-scope="scope">
              <operationBtn :btnName="'查看'" @click.native="onDetail(scope.row.logId)">
              </operationBtn>
              <operationBtn v-if="!scope.row.appealStatus" :btnName="'申诉'" @click.native="onAppeal(scope.row)">
              </operationBtn>
              <operationBtn v-if="scope.row.appealStatus == 1" :btnName="'取消申诉'" @click.native="onCancel(scope.row)">
              </operationBtn>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>

      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>

    <!-- 申诉 -->
    <el-dialog :title="'申诉'" :visible.sync="dialogVisibleAppeal" width="30%" :before-close="handleCloseAppeal"
      :close-on-click-modal="false" :close-on-press-escape="false" :append-to-body="true" :fullscreen="false">
      <el-form ref="ruleFormAppeal" :model="ruleFormAppeal" :rules="rulesAppeal">
        <el-form-item label="申诉理由" prop="appealReason">
          <el-input type="textarea" v-model="ruleFormAppeal.appealReason" :rows="5" placeholder="请输入申诉理由"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleAppeal = false">取 消</el-button>
        <el-button type="primary" @click="onUpdateAppeal">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import '@/assets/css/Student.css';
  import comPage from '@/components/Page.vue';
  import search from '@/components/search.vue';
  import operationBtn from '@/components/operationBtn.vue';

  export default {
    components: {
      search,
      operationBtn,
      comPage
    },
    data() {
      return {
        // 动画
        fullscreenLoading: false,
        dialogVisibleAppeal: false,
        ruleFormAppeal: {
          memberId: null,
          appealReason: null,
        },
        ruleFormAppealReset: null,
        rulesAppeal: {
          appealReason:[{ max: 256, message: '申诉理由不能超过256个字符', trigger: 'change'}],
        },
        // 搜索
        SearchList: null,
        //列表数据
        itemData: [],
        //分页查询接口返回的结果
        page: null,
        //分页左侧按钮集合
        pageBtnList: null,
        //分页数据
        paging: {
          // 总共页数
          pageLength: 0,
          // 每页请求的数据量
          Size: 1,
          //当前在第几页
          Index: 1
        },
        random: 0, //刷新组件
        SearchTitles: [],
        currentType: null,
        optAppealStatus: [],
        optAll: [{
          Value: 'true',
          Title: '未申诉'
        },{
          Value: 'false',
          Title: '已申诉'
        }]
      };
    },
    methods: {
      /**
       * @description 提交申诉
       */
      onUpdateAppeal() {
        let that = this;
        that.$refs["ruleFormAppeal"].validate(valid => {
          if (valid) {
            let url = "";
            that.fullscreenLoading = true;
            ajaxPut(that, '/api/student/moral/appeal', that.ruleFormAppeal, (r) => {
              that.fullscreenLoading = false;
              that.dialogVisibleAppeal = false;
              Init(that);
            })
          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              var el = isError[0].querySelector('input') || isError[0].querySelector('textarea') || isError[0].querySelector('select');
              if(el) {
                el.focus();
              }
            }, 1)
            return false;
          }
        })
      },
      /**
       * @description 关闭申诉弹窗
       */
      handleCloseAppeal() {
        let that = this;
        that.dialogVisibleAppeal = false;
      },
      /**
       * @description 取消申诉
       * @param {Object} obj
       */
      onCancel(obj) {
        let that = this;
        let url = "/api/student/moral/unappeal?id="+obj.logId;
        that.fullscreenLoading = true;
        ajaxPut(that, url, null, (r) => {
          that.fullscreenLoading = false;
          Init(that);
        })
      },
      /**
       * @description 打开申诉弹窗
       * @param {Object} obj
       */
      onAppeal(obj) {
        let that = this;
        let show = compareDate(new Date(),new Date(obj.appealDeadline));
        if(show == true){
        that.ruleFormAppeal = JSON.parse(JSON.stringify(that.ruleFormAppealReset));
        that.ruleFormAppeal.logId = obj.logId;
        that.$nextTick(() => {
          that.$refs.ruleFormAppeal.clearValidate();
        })
        that.dialogVisibleAppeal = true;
        }else{
          warning(that,'已超出申诉时效!')
        }
      },
      /**
       * @description 显示原因
       * @param {Object} objItem
       */
      onGetReason(objItem) {
        return objItem.reason + (regNull(delHtml(objItem.remark)) ? '' : '<span class="color-gray">（' + delHtml(objItem
          .remark) + '）</span>');
      },
      onSearch(params) // 搜索
      {
        let that = this;
        let data = {};
        let searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = element.data;
          }
        });
        data.PageNumer = 1;
        data["type"] = that.currentType;
        routerPath(that, "/Student/MyBehaviorRecord/Index", data, null, "PageSize");
      },
      onDetail(Id) {
        let that = this;
        that.$router.push({
          path: "/Student/MyBehaviorRecord/Details/" + that.currentType + "/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      // 分页面按钮
      pagingClick(type, val) {
        let that = this;
        let url = "/Student/MyBehaviorRecord/Index?type=" + that.currentType;
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "SemesterId",
            "Reason",
            "AppealStatus",
            "All"
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "SemesterId",
            "Reason",
            "AppealStatus",
            "All"
          );
        }
      }
    },
    created() {
      let that = this;
      that.ruleFormAppealReset = JSON.parse(JSON.stringify(that.ruleFormAppeal));
      ajaxGet(that, "/api/dict/appealstatus", null, (r) => {
        if (!regNullArray(r)) {
          r.forEach(element => {
            that.optAppealStatus.push({
              Value: element.Value,
              Title: element.Text,
            })
          });
        }

        Init(that);
      })
    },
    mounted() {

    },
    watch: {
      $route() {
        let that = this;
        Init(that);
      }
    }
  };

  function Init(that) {
    that.currentType = that.$route.query.type;
    if (that.currentType == null || typeof(that.currentType) == "undefined" || that.currentType == "") {
      that.currentType = 1;
    }
    onGetDataPage(that);
  }

  function onGetDataPage(that) {
    paging(
      that,
      function(res) {
        let apiUrl = null;
        that.fullscreenLoading = true;
        // 搜索 下拉学期
        ajaxGet(that, "/api/student/semester/student", null, function(resSemester) {
          // 获得学期数据
          var SemesterId = res.SemesterId;
          let arrSemester = [];
          for (var i in resSemester) {
            let arrTemp = {
              Title: resSemester[i].name, //semester[item].name,
              Value: resSemester[i].semesterId //semester[item].semesterId,
            };
            if (SemesterId == null || typeof(SemesterId) == "undefined" || SemesterId == "") {
              if (resSemester[i].isCurrent == true) {
                SemesterId = resSemester[i].semesterId;
              }
            }
            arrSemester.push(arrTemp);
          }
          that.SearchList = [
            // 学期
            {
              type: "select",
              Name: "学期",
              data: SemesterId,
              select: arrSemester, //选择的数据
              zhi: "SemesterId", // 传值
              isClear: false, //select是否可以清除
              resetData: SemesterId,
            },
            {
              type: "input",
              Name: "行为原因",
              data: res.Reason,
              holder: "请输入行为原因",
              zhi: "Reason"
            },
            // 申诉状态
            {
              type: "select",
              Name: "申诉状态",
              data: res.AppealStatus,
              select: that.optAppealStatus, //选择的数据
              zhi: "AppealStatus", // 传值
              isClear: true, //select是否可以清除
            },
            {
              type: "select",
              Name: "是否申诉",
              data: res.All,
              select: that.optAll, //选择的数据
              zhi: "All", // 传值
              isClear: true, //select是否可以清除
            },

          ];
          let data = {
            PageNumer: res.PageNumer,
            PageSize: res.PageSize,
            SemesterId: SemesterId,
            Reason: res.Reason,
            AppealStatus: res.AppealStatus,
            All: res.All,
          };
            ajaxGet(that, '/api/student/moral/search', data, function(resData) {
              that.page = resData;
              that.itemData = resData.items;
              that.fullscreenLoading = false;
            });
        })
      },
      "SemesterId",
      "Reason",
      "AppealStatus",
      "All"
    );
  }
</script>

<style scoped="scoped">
  .navTabsCont .nav-link {
    font-size: 12px;
  }

  .navToolsCont {
    margin-top: 1px;
  }

  /*重置样式*/
  .table tbody td,
  .table thead th {
    font-size: 0.875rem;
    /*14*/
  }

  .table tr td,
  .table tr th {
    text-align: center;
  }

  .table tr th:nth-child(1),
  .table tr th:nth-child(2),
  .table tr td:nth-child(1),
  .table tr td:nth-child(2) {
    text-align: left;
  }
</style>
