<!--

-->
<template>
  <div class="SubWebPageStudent" v-loading.fullscreen.lock="fullscreenLoading">
    <!-- 搜索 -->
    <comSearch :ColNumber="3" :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null">
    </comSearch>
    <!-- 列表-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          社团活动信息
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <el-button v-if="isPower.isDirector || isPower.isManager" type="success" icon="el-icon-circle-plus-outline"
                @click="onCreate()">新增社团活动</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">

        <el-table :data="itemData" stripe class="elTableData">
          <el-table-column prop="name" label="活动名称" min-width="200"></el-table-column>
          <el-table-column label="活动时间" min-width="180">
            <template slot-scope="scope">
              {{scope.row.activityDate | dateformat}}
            </template>
          </el-table-column>
          <el-table-column prop="place" label="记录地点" min-width="200"></el-table-column>
          <el-table-column prop="auditState" label="参与人数" min-width="120">
            <template slot-scope="scope">
              {{scope.row.roster.length}}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="280" fixed="right">
            <template slot-scope="scope">
              <operationBtn :btnName="'查看'" @click.native="onDetail(scope.row.activityId)"></operationBtn>
              <operationBtn v-if="isPower.isManager || isPower.isDirector" :btnName="'编辑'"
                @click.native="onEdit(scope.row.activityId)"></operationBtn>
              <operationBtn v-if="isPower.isManager || isPower.isDirector" :btnName="'删除'"
                @click.native="onDelete(scope.row.activityId)"></operationBtn>
                <comImport @onImportSuccess="onImportSuccess" :template="'/templates/社团成员导入模版.xlsx'"
                  :path="onGetApi(scope.row.activityId)" :btnSize="'mini'" :btnClass="'clubBtnImport'" :btnIcon="''"
                  :title="'导入参与名单'" :btnName="'导入'" ref="conImportInfoFile" v-if="isPower.isManager || isPower.isDirector">
                </comImport>
            </template>
          </el-table-column>
        </el-table>

        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>

      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>

    <div class="row mt-10">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
      </div>
    </div>

  </div>
</template>

<script>
    import '@/assets/css/Student.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import comSearch from '@/components/search.vue';
  import comImport from '@/components/Import.vue';
  export default {
    components: {
      operationBtn,
      comPage,
      comSearch,
      comImport
    },
    data() {
      return {
        // 动画
        fullscreenLoading: false,
        dialogVisible: false,
        dialogVisiblePhoto: false,
        // 搜索
        SearchList: null,
        //列表数据
        itemData: [],
        //分页查询接口返回的结果
        page: null,
        //分页左侧按钮集合
        pageBtnList: null,
        //分页数据
        paging: {
          // 总共页数
          pageLength: 0,
          // 每页请求的数据量
          Size: 1,
          //当前在第几页
          Index: 1
        },
        url:null,
        isPower: {
          isDirector: false,
          isManager: false,
        },
    
        myClubId: '',
        uploadInfoApi: '/api/student/clubactivity/activityroster/',
      };
    },
    methods: {
      /**
       * @description 生成API地址
       * @param {Object} _dataID
       * @return {String} 组合成的新Api接口地址
       */
      onGetApi(_dataID) {
        return this.uploadInfoApi + _dataID;
      },


      /**
       * @description 导入名单
       * @param {Object} param
       */
      onImportSuccess(param) {
        let that = this;
          onGetDataPage(that);
      },


      onBack(){
        back(this);
      },

      /**
       * @description 搜索事件
       * @param {Object} params
       */
      onSearch(params)
      {
        let that = this;
        let data = {};
        let searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = element.data;
          }
        });
        routerPath(that, "/Student/Club/ClubActivities/Index", data, null, "PageSize");
      },
      onCreate() {
        let that = this;
        that.$router.push({
          path: '/Student/Club/ClubActivities/Create/0',
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      /**
       * @description 查看社团活动详情
       * @param {Object} Id
       */
      onDetail(_activityId) {
        let that = this;
        that.$router.push({
          path: '/Student/Club/ClubActivities/Details/' + _activityId,
          query: {
            url: that.$router.history.current.fullPath
          }
        });

      },
      /**
       * @description 编辑社团活动
       * @param {Object} Id
       */
      onEdit(_activityId) {
        let that = this;
        that.$router.push({
          path: '/Student/Club/ClubActivities/Edit/' + that.myClubId + '/' + _activityId,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      /**
       * @description 删除社团活动
       * @param {Object} Id
       */
      onDelete(Id) {
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, "/api/student/clubactivity/" + Id, null, function(r) {
              that.fullscreenLoading = false;
              onGetDataPage(that);
            });
          }
        })
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      // 分页面按钮
      pagingClick(type, val) {
        let that = this;
        let url = "/Student/Club/ClubActivities/Index";
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "clubId",
            "Name"
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "clubId",
            "Name"
          );
        }
      }
    },
    created() {
      let that = this;
      that.url= that.$route.query.url;
      onGetDataPage(that);
    },
    mounted() {

    },
    watch: {
      $route() {
        let that = this;
        onGetDataPage(that);
      }
    }
  };

  function onGetDataPage(that) {
    paging(
      that,
      function(res) {
        // if(!regNull(res.clubId)){
          that.fullscreenLoading = true;
          ajaxGet(that, "/api/student/club", null, function(resClub) {
            that.fullscreenLoading = false;
            if(resClub.length>0){
            var clubId = res.clubId;
            that.myClubId = res.clubId;
            let arrClub = [];
            for (var i in resClub) {
              let arrTemp = {
                Title: resClub[i].name,
                Value: resClub[i].clubId
              };

              arrClub.push(arrTemp);
            }

            if (clubId == null || typeof(clubId) == "undefined" || clubId == "") {
              clubId = resClub[0].clubId;
              that.myClubId = resClub[0].clubId;
            }


            that.SearchList = [{
                type: "input",
                Name: "活动名称",
                data: res.Name,
                holder: "请输入记录名称",
                zhi: "Name"
              },

              {
                type: "select",
                Name: "社团",
                data: clubId,
                holder: "请选择社团",
                select: arrClub, //选择的数据
                zhi: "clubId", // 传值
                isClear: false, //select是否可以清除
                resetData: clubId,
              },


            ];
            let data = {
              PageNumer: res.PageNumer,
              PageSize: res.PageSize,
              ClubId: clubId,
              Name: res.Name
            };
            that.fullscreenLoading = true;
            ajaxGet(that, '/api/student/clubactivity/search', data, function(resData) {
              ajaxGet(that, '/api/student/club/memberofme/' + clubId, null, function(resPosition) {
                that.page = resData;
                that.itemData = resData.items;
                that.fullscreenLoading = false;
                that.isPower = resPosition;
              });
            });
            }


          });
        // }


      },
      "Name",
      "clubId",
    );
  }
</script>

<style>
  .clubBtnImport{
    margin-left: 2px;
        display: inline-block;
  }
</style>
