<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          基本信息
        </h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-4 colInfo">
            <label>候选人</label>
            <div class="colFonts">
              {{ruleForm.studentName | flNoValue}}
            </div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>学号</label>
            <div class="colFonts">
              {{ruleForm.studentNo | flNoValue}}
            </div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>班级</label>
            <div class="colFonts">
              {{ruleForm.className | flNoValue}}
            </div>
          </div>
          <div class="col-lg-4 colInfo">
            <label>获奖等级</label>
            <div class="colFonts">
              {{ruleForm.levelString | flNoValue}}
            </div>
          </div>
        </div>
      </div>
    </el-card>

    <el-card class="box-card" v-for="(item, index) in totalList" :key="index">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          评比信息 {{item[0].semester}}
        </h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-4 colInfo" v-for="(it, index) in item" :key="index">
            <label>{{it.courseName}}</label>
            <div class="colFonts">
              {{it.total | flNoValue}}
            </div>
          </div>
        </div>
      </div>
    </el-card>
    <div class="row" style="margin-top: 10px;">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
      </div>
    </div>
    <!--配合走马灯效果放大图片组件-->
    <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="uploadFiles" />

  </div>
</template>

<script>
  import "@/assets/css/Admin.css";
  import ElImageViewer from "element-ui/packages/image/src/image-viewer";

  export default {
    components: {
      ElImageViewer,
    },
    data() {
      return {
        itemData: [],
        ruleForm: {},
        auditRuleForm: {
          reason: '',
          applyId: '',
        },
        auditRecords: [], //审核记录
        fullscreenLoading: false,
        showViewer: false,
        uploadFiles: [],
        styleWidth: "25%",
        totalList: [],
      };
    },
    filters: {
      flNoValue(obj) {
        if (obj == null || typeof obj == "undefined" || obj == "") {
          return "暂无数据";
        }
        return obj;
      },
    },
    created() {
      let _this = this;
      let id = _this.$route.params.Id;
      var detailUrl = null;
      if (id != null && typeof id != "undefined" && id != "") {
        _this.auditRuleForm.applyId = id
        _this.initRuleForm(id)
      }
    },

    methods: {

      handleRefused(status) {
        let that = this
        let data = JSON.parse(JSON.stringify(that.auditRuleForm))
        data.status = status
        if (status == 2 && !data.reason) {
          warning(that, '拒绝审核需填写审核意见')
          return
        }
        that.fullscreenLoading = true
        ajaxPut(that, `/api/admin/poorapply/audit/${data.applyId}`, data, function(res) {
          back(that)
          that.fullscreenLoading = false
        })
      },
      initRuleForm(id) {
        let that = this;
        // that.fullscreenLoading = true;
        ajaxGet(that, '/api/admin/scholarshipcandidate/' + id, null, function(res) {
          that.ruleForm = res;
          ajaxGet(that, '/api/admin/semester/year/' + res.yearId, null, function(r1) {
            for (var i in r1) {
              let data = {
                StudentId: res.studentId,
                semesterId: r1[i].semesterId,
              }
              ajaxGet(that, '/api/admin/growth/academicsearch', data, function(r2) {
                if(r2!=[]){
                  that.totalList.push(r2);
                }
                that.fullscreenLoading = false;
                // ajaxGet(that, '/api/admin/manual/transcript/bysemester/' + r1[i].semesterId + '/' + res
                //   .studentId, null,
                //   function(res) {

                //   });
              });

            }
          });
        });
      },
      onPreview() { //放大图片
        this.showViewer = true;
      },
      closeViewer() { //关闭预览
        this.showViewer = false;
      },
      onBack() {
        let that = this;
        back(that);
      },
    },
  };
</script>

<style scoped="scoped">
  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }

  .spIcon {
    width: 5px;
    height: 20px;
    border-radius: 5px;
    background-image: linear-gradient(#4545ff, #2d8eff);
  }

  .spTitle {
    float: left;
    margin-left: 20px;
    color: #323e56;
    letter-spacing: 2px;
    font-size: 16px;
    margin-top: -3px;
  }

  .wMax {
    width: 100% !important;
  }
</style>

<!-- 综合类型 -->
<style scoped="scoped">
  .divTagBox {
    padding: -5px;
  }

  .divTagBox .spTab {
    padding: 5px;
  }

  .divTagBox .spScore {
    color: #fa3534;
  }

  .divLiteracyBox {
    padding: 15px;
  }

  .divLiteracyBox .listBox {
    text-align: center;
    padding: 20px;
    float: left;
    border: 2px solid #ffffff;
    border-radius: 5px;
  }

  .divLiteracyBox .listBox:hover {
    border: 2px solid #409eff;
    cursor: pointer;
  }

  .divLiteracyBox .literacyName {
    font-size: 24px;
    letter-spacing: 2px;
    padding-bottom: 10px;
  }

  .divLiteracyBox .literacyNum {
    padding-top: 10px;
    font-family: "OswaldLight";
    border-top: 2px solid #eeeeee;
    font-size: 32px;
    color: #409eff;
  }
</style>
