<!--
  奖励信息
-->
<template>
  <div class="SubWebPageStudent" v-loading.fullscreen.lock="fullscreenLoading">

    <!-- 搜索 -->
    <search :key="random" :ColNumber="2" :SearchList="SearchList" ref="SearchList"
      @onSearch="onSearch" v-if="SearchList!=null"></search>
    <!-- 列表-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="width:100%;padding-left: 0px;font-size: 12px;">
          <ul class="nav nav-pills navTabsCont">
            <li class="nav-item">
              <router-link :to="'/Student/Prize/Index'" class="nav-link active">
                我的奖励
              </router-link>
            </li>
            <li class="nav-item">
              <router-link :to="'/Student/Prize/List'" class="nav-link">
                我的申请
              </router-link>
            </li>
          </ul>
        </h3>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table :data="itemData" stripe class="elTableData">
          <el-table-column type="index" label="序号" width="80"></el-table-column>
          <el-table-column prop="name" label="奖项" min-width="300"></el-table-column>

          <el-table-column prop="level" label="奖励级别" min-width="120">
            <template slot-scope="scope">
          	  <span v-if="scope.row.level!=null && scope.row.level!=''">{{scope.row.level.name}}</span>
            </template>
          </el-table-column>
          <el-table-column label="奖励时间" min-width="120">
            <template slot-scope="scope">
              <span>{{scope.row.prizeDate | dateformat('YYYY-MM-DD')}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作时间" min-width="120">
            <template slot-scope="scope">
              <span>{{scope.row.lastUpdateDate | dateformat('YYYY-MM-DD')}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120" fixed="right">
            <template slot-scope="scope">
              <operationBtn :btnName="'查看'" @click.native="onDetail(scope.row.prizeId)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
        <comPage
        	:paging="page"
        	:pageBtnList="pageBtnList"
        	@pagingClick="pagingClick"
        	@pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>

    </el-card>
  </div>
</template>

<script>
  import '@/assets/css/Student.css';
  import comPage from '@/components/Page.vue';
  import search from '@/components/search.vue';
  import operationBtn from '@/components/operationBtn.vue';

  export default{
    components:{
      search,operationBtn,comPage
    },
    data(){
      return{
        random: 0, //刷新组件
        // 动画
        fullscreenLoading: false,
        // 搜索
        SearchList: null,
        //列表数据
        itemData: [],
        //分页查询接口返回的结果
        page: null,
        //分页左侧按钮集合
        pageBtnList: null,
        //分页数据
        paging: {
          // 总共页数
          pageLength: 0,
          // 每页请求的数据量
          Size: 1,
          //当前在第几页
          Index: 1
        },
        isPower: {
        		//增删改查 权限管理
        		prize_detail: false,
        },
      };
    },
    methods:{
      onSearch(params)// 搜索
      {
      	let that = this;
      	let data = {};
      	let searchData = params;
      	searchData.forEach(element => {
      		if (element.data) {
      			data[element.zhi] = Join(element.data);
      		}
      	});
      	data.PageNumer = 1;
      	routerPath(that, "/Student/Prize/Index", data, null, "PageSize");
      },
      onDetail(Id)
      {
        let that=this;
        that.$router.push({
          path: "/Student/Prize/Details/"+Id,
          query: { url: that.$router.history.current.fullPath,
                    type:1,}
        });
      },

      pageBtnClick(index) {
      	//分页组件左侧的按钮事件，按顺序进行判断
      },
      // 分页面按钮
      pagingClick(type, val)
      {
      	let that = this;
      	let url = "/Student/Prize/Index";
      	if (type == 1) {//更改每页条数触发的事件
      		routerPath(
      			that,
      			url,
      			"PageSize",
      			val,
      			"PageNumer",
            "PrizeDate",
            "Name",
      		);
      	} else { //更改当前页时触发的事件
      		routerPath(
      			that,
      			url,
      			"PageNumer",
      			val,
      			"PageSize",
            "PrizeDate",
            "Name",
      		);
      	}
      }
    },
    created() {
      let that = this;
      onGetDataPage(that);
    },
    mounted() {

    },
    watch: {
    	$route() {
    		let that = this;
    		onGetDataPage(that);
    	}
    }
  };

  function onGetDataPage(that) {
    paging(
      that,
      function(res) {
        let apiUrl = "/api/student/prize/search";
        that.fullscreenLoading = true;
        that.SearchList = [
          {
            type: "time",
            Name: "奖励时间",
            data: res.PrizeDate,
            zhi: "PrizeDate"
          },
          {
            type: "input",
            Name: "奖项",
            data: res.Name,
            holder: "请输入奖项",
            zhi: "Name"
          },
        ];

        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;

        let data = {
          PageNumer: res.PageNumer,
          PageSize: res.PageSize,
          PrizeDate: res.PrizeDate,
          Name:res.Name,
        };
        ajaxGet(that, apiUrl, data, function(resData) {
          that.page = resData;
          that.itemData = resData.items;
          that.fullscreenLoading = false;
        });
      },
      "Name",
      "PrizeDate",
    );
  }
</script>

<style scoped="scoped">

</style>
