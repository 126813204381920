<!--
 统计管理里的柱状图
-->
<template>
  <!-- 250px -->
  <div :id="comID" :style="pieStyle"></div>
</template>

<script>
  import 'echarts/lib/component/tooltip';
  import 'echarts/lib/component/legend';


  import * as echarts from 'echarts/core';
  import {
    TooltipComponent,
    GridComponent
  } from 'echarts/components';
  import {
    BarChart, LineChart,PieChart
  } from 'echarts/charts';
  import {
    CanvasRenderer
  } from 'echarts/renderers';

  echarts.use([TooltipComponent, GridComponent, BarChart,LineChart, PieChart, CanvasRenderer]);

  export default {
    props: {
      /**
       * @description 控件ID
       */
      comID: {
        type: String,
        default: 'myChart'
      },
      /**
       * @description 高度
       */
      comHeight: {
        type: Number,
        default: 250
      },
      /**
       * @description 数据
       */
      comChartData: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        pieStyle: '',
        myChart: {},

        xAxisData: [],
        seriesData: [],
      };
    },
    created() {
      let that = this;
    },
    mounted() {
      let that = this;
      window.onresize = function() {
        that.myChart.resize();
        that.pieStyle = 'width: 100%; height:' + (that.comHeight * 0.0625) + 'rem;';
      }
    },
    methods: {
      /**
       * @description 调用方法
       * @param {Object} objData
       */
      onGetRadar(objData) {
        let that = this;

        if (!regNull(objData)) {
          that.$nextTick(() => {
            that.drawLine(objData);
          })
          that.pieStyle = 'width: 100%; height:' + (that.comHeight * 0.0625) + 'rem;';
        }
      },
      /**
       * @description 生成雷达图
       */
      drawLine(objData) {
        let that = this;
        // 基于准备好的dom，初始化echarts实例
        let myChart = echarts.init(document.getElementById(that.comID));
        // 绘制图表
        myChart.setOption(objData);



      },
    }
  };
</script>

<style>

</style>
