<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">

      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            查看综合之星配置
          </h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-4">
              <el-image :src="onGetImage(infoData.icon)">

                <div slot="error" class="image-slot">
                          <svg  style="width: 50px;height: 50px;" class="icon" aria-hidden="true">
                            <use xlink:href="#fax-image-split"></use>
                          </svg>
                        </div>
              </el-image>
            </div>
            <div class="col-lg-8">
              <div class="row">
                <div class="col-lg-6 colInfo">
                  <label>综合之星名称</label>
                  <div class="colFonts">{{infoData.name}}</div>
                </div>
                <div class="col-lg-6 colInfo">
                  <label>评比范围</label>
                  <div class="colFonts">{{infoData.rangeString}}</div>
                </div>
                <div class="col-lg-12 colInfo">
                  <label>评比时间范围</label>
                  <div class="colFonts">
                    <!-- 2012-01-01 至 2012-01-31（校历1月） -->
                    {{infoData.startDate | dateformatDay}} 至 {{infoData.endDate | dateformatDay}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-card>

      <el-card class="box-card" v-if="infoData.configs!=null && infoData.configs.length!=0">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            配置列表
          </h3>
        </div>
        <div class="card-body p-0">
            <el-table :data="infoData.configs" stripe class="elTableData">
              <el-table-column type="index" label="序号" class="elColumn" width="80"></el-table-column>
              <el-table-column prop="configName" label="评比纬度" class="elColumn" min-width="200"></el-table-column>
              <el-table-column label="评比要求" class="elColumn">
                <template slot-scope="scope">
                  &nbsp;
                  <span class="badge badge-success" v-if="scope.row.score">
                    {{'达标分数：'+scope.row.score}}
                  </span>
                  &nbsp;
                  <span class="badge badge-success" v-if="scope.row.ratio">
                    {{'名次：'+((scope.row.ratio)*100)+'%'}}
                  </span>
                  &nbsp;
                </template>
              </el-table-column>
            </el-table>
        </div>
      </el-card>

      <div class="row">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
        </div>
      </div>
  </div>
</template>

<script>
   import '@/assets/css/Admin.css';
    export default {
      data() {
        return{
          // 加载动画
          fullscreenLoading:false,
          infoData:{
            starId:null,
          }

        };
      },
      methods:{
        /**
         * @description 返回
         */
        onBack(){
          back(this);
        },
        /**
         * @description 获取图标地址
         * @param {Object} _valSrc 地址
         */
        onGetImage(_valSrc){
          return this.$ServerUrl()+_valSrc;
        },
      },
      created() {
        let _this = this;
        let dataID = _this.$route.params.id;
        if (dataID != null && typeof(dataID) != "undefined" && dataID != "") {
          _this.infoData.starId = dataID;
          onGetData(_this);
        }
      }
    };

    function onGetData(_this){
      _this.fullscreenLoading=true;
      ajaxGet(_this,'/api/admin/literacystar/'+_this.infoData.starId,null,function(resData){
        _this.fullscreenLoading=false;
        _this.infoData=resData;
      });
    }
</script>

<style>
</style>
