<template>

  <!-- 多条件搜索 -->
  <div v-if="SearchList.length != 1">
    <el-card class="box-card">
      <!--标题-->
      <div slot="header" class="card-header">
        <div style="display: flex;">
          <h3 class="card-title titleCont" style="width:100%; padding-left: 0px; flex: 1;"
            v-if="SearchTitles != null && SearchTitles.length > 0">
            <ul class="nav nav-pills navTabsCont">
              <li class="nav-item" v-for="(p, index) in SearchTitles" :key="index">
                <router-link :to="p.url" :class="p.isCurrent == true ? 'nav-link active' : 'nav-link'">
                  {{ p.name }}
                </router-link>
              </li>
            </ul>
          </h3>
          <h3 v-else class="card-title titleCont" style="padding-left: 0px; flex: 1;">
            {{ SearchTitle }}
          </h3>
          <div style="cursor: pointer; " v-if="isAllowClose == true">
            <i class="el-icon-arrow-down" style="font-size: 16px; " @click="onClose()" v-if="isClose == false"></i>
            <i class="el-icon-arrow-up" style="font-size: 16px; " @click="onClose()" v-else></i>
          </div>
        </div>
      </div>


      <!-- 内容 -->
      <div class="card-body" v-if="isClose == false">
        <el-row :gutter="24">
          <el-col :span="ColCount" v-for="(site, index) in data" :key="index" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle" v-if="site.hide == null || site.hide == false">{{ site.Name }} </span>
              <span class="spTitle" v-else>&nbsp;</span>
            </div>
            <div class="selBodyBox">
              <el-date-picker class="wMax" v-if="site.type == 'time'" v-model="site.data" type="daterange"
                :clearable="site.isClear" format="yyyy年 MM月 dd日" value-format="yyyy-MM-dd" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>

              <el-date-picker v-if="site.type == 'oddTime'" v-model="site.data" type="datetime" class="wMax"
                value-format="yyyy-MM-dd HH:mm:ss" placeholder="请选择日期时间"></el-date-picker>
              <el-date-picker v-if="site.type == 'onetime'" v-model="site.data" type="date" class="wMax"
                value-format="yyyy-MM-dd " placeholder="请选择日期时间"></el-date-picker>
              <el-input v-if="site.type == 'input'" :placeholder="site.holder" v-model="site.data" class="wMax"
                v-show="site.hide == null || site.hide == false"></el-input>

              <el-select v-model="site.data" placeholder="请选择" v-if="site.type == 'select' && site.isClear != false"
                class="wMax" clearable filterable :disabled="site.isShow" @change="onChange(site.zhi, site.data)">
                <el-option v-for="item in site.select" :key="item.Value" :label="item.Title" :value="item.Value">
                </el-option>
              </el-select>
              <el-select v-model="site.data" placeholder="请选择" v-if="site.type == 'select' && site.isClear == false"
                class="wMax" filterable :disabled="site.isShow" @change="onChange(site.zhi, site.data)">
                <el-option v-for="item in site.select" :key="item.Value" :label="item.Title" :value="item.Value">
                </el-option>
              </el-select>
              <el-cascader style="width:100%;" ref="cascaderHandle" v-if="site.type == 'cascaderreason'"
                :options="site.select" v-model="site.data"
                :props="{ label: 'name', value: 'id', children: 'children', checkStrictly: true }" filterable
                @change="close"></el-cascader>
              <el-cascader style="width:100%;" v-if="site.type == 'cascader'" :options="site.select" v-model="site.data"
                expand-trigger="hover" :props="defaultProps"></el-cascader>
            </div>

            <!-- <el-row :gutter="0" v-if="false">
              <el-col :span="6" class="searchSpanTitle">
                <span class="searchSpan" v-if="site.hide==null || site.hide==false">{{site.Name}}</span>
                <span class="searchSpan" v-else style="height:40px;">&nbsp;</span>
              </el-col>
              <el-col :span="18">
                <div class="grid-content">
                  <el-date-picker class="wMax" v-if="site.type=='time'" v-model="site.data" type="daterange"
                    format="yyyy年 MM月 dd日" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期"
                    end-placeholder="结束日期"></el-date-picker>

                  <el-date-picker v-if="site.type=='oddTime'" v-model="site.data" type="datetime" class="wMax"
                    value-format="yyyy-MM-dd HH:mm:ss" placeholder="请选择日期时间"></el-date-picker>
                  <el-date-picker v-if="site.type=='onetime'" v-model="site.data" type="date" class="wMax"
                    value-format="yyyy-MM-dd " placeholder="请选择日期时间"></el-date-picker>
                  <el-input v-if="site.type=='input'" :placeholder="site.holder" v-model="site.data" class="wMax"
                    v-show="site.hide==null || site.hide==false"></el-input>

                  <el-select v-model="site.data" placeholder="请选择" v-if="site.type=='select' && site.isClear != false"
                    class="wMax" clearable filterable :disabled="site.isShow" @change="onChange(site.zhi,site.data)">
                    <el-option v-for="item in site.select" :key="item.Value" :label="item.Title" :value="item.Value">
                    </el-option>
                  </el-select>
                  <el-select v-model="site.data" placeholder="请选择" v-if="site.type=='select' && site.isClear == false"
                    class="wMax" filterable :disabled="site.isShow" @change="onChange(site.zhi,site.data)">
                    <el-option v-for="item in site.select" :key="item.Value" :label="item.Title" :value="item.Value">
                    </el-option>
                  </el-select>

                  <el-cascader style="width:100%;" v-if="site.type=='cascader'" :options="site.select"
                    v-model="site.data" expand-trigger="hover" :props="defaultProps"></el-cascader>
                </div>
              </el-col>
            </el-row> -->
          </el-col>



          <!-- for 循环的值 -->
        </el-row>
      </div>
      <!--搜索按钮-->
      <div class="card-footer text-center" v-if="isClose == false">
        <el-button type="primary" slot="append" icon="el-icon-search" @click.native="onSearch()">查询</el-button>
        <el-button slot="append" icon="el-icon-refresh" @click.native="onResetSearch()">重置</el-button>
      </div>
    </el-card>
  </div>
  <!-- 单独一条搜索 -->
  <span v-else>
    <el-input v-if="data[0].type == 'input'" @keyup.enter.native="onSearch()" v-model="data[0].data"
      :placeholder="data[0].holder" style="width:300px;margin-right:5px;">
      <el-button slot="append" icon="el-icon-search" @click.native="onSearch()"></el-button>
      <el-button slot="append" icon="el-icon-refresh" @click.native="onResetSearch()"></el-button>
    </el-input>
    <el-select @change="onSearch()" v-if="data[0].type == 'select'" v-model="data[0].data" :placeholder="data[0].holder"
      :disabled="data[0].isShow">
      <el-option v-for="item in data[0].select" :key="item.Value" :label="item.Title" :value="item.Value">
      </el-option>
    </el-select>
    <el-select @change="onSearch()" v-if="data[0].type == 'select' && data[0].isClear == false" v-model="data[0].data"
      :placeholder="data[0].holder" clearable :disabled="data[0].isShow">
      <el-option v-for="item in data[0].select" :key="item.Value" :label="item.Title" :value="item.Value">
      </el-option>
    </el-select>
  </span>

</template>
<script>
export default {
  props: {
    SearchList: Array,
    // 列数
    ColNumber: {
      type: Number,
      default: 2
    },
    SearchTitle: {
      type: String,
      default: "搜索"
    },
    SearchTitles: Array,

  },
  watch: {
    SearchTitles(newVal) {
      this.SearchTitles = newVal;
    }
  },
  created() {
    let that = this;
    let data = this.SearchList;
    data.forEach(e => {
      if (isNaN(e.data) && e.data && e.data.indexOf(",") != -1) {
        let str = e.data.split(",");
        e.data = str;
      }
    });
    for (var it of data) {
      it.type = it.type.toLowerCase();
    }
    this.data = data; // 无法直接调用父组件传来的值 需要重新转换一下
    if (this.data != null) {
      for (var i = 0; i < this.data.length; i++) {
        var site = this.data[i];
      }
    }
    that.ColCount = 24 / that.ColNumber;
  },
  data() {
    return {
      defaultProps: {
        //用来设置值 比如原先的label是label  现在改为Title
        value: "Value",
        label: "Title",
        children: "Childrens"
      },
      data: null,
      ColCount: 12,
      isClose: false,
      isAllowClose: true,
    };
  },
  methods: {
    close(val) {
      this.$refs.cascaderHandle[0].dropDownVisible = false;
    },
    onChange(zhi, value) {
      let that = this;
      for (var it of that.data) {
        if (it.zhi == zhi && it.children != null && typeof (it.children) != "undefined" && it.children.length > 0) {
          for (var select of it.select) {
            if (select.Value == value) {
              for (var it1 of that.data) {
                if (it1.zhi.toLowerCase() == zhi.toLowerCase()) {
                  it1.children = select.children;
                }
              }
            }
          }
        }
      }
    },
    onClose() {
      let that = this;
      that.isClose = !that.isClose;
    },
    onSearch() {
      let that = this;
      for (var it of that.data) {
        if (it.data != null) {
          // if(it.data.indexOf(",")!=-1)
          // {
          if (it.type == "cascader") {
            var temp = "";
            for (var da of it.data) {
              if (temp == "") {
                temp = da;
              } else {
                temp = temp + "," + da;
              }
            }
            it.data = temp;
          }
          // }
        }
      }
      this.$emit('onSearch', that.data);
    },
    onResetSearch() {
      let that = this;
      for (var i in that.data) {
        if (that.data[i].resetData == null || typeof (that.data[i].resetData) == "undefined" || that.data[i]
          .resetData == "") {
          that.data[i].data = "";
        } else {
          that.data[i].data = that.data[i].resetData;
        }
      }
      this.$emit('onSearch', that.data);
    },
    Reset() {
      this.data.forEach(element => {
        element.data = null;
      });
    },
  }
};
</script>
<style lang="less" scoped>
.searchSpanTitle {
  text-align: right;
}

.selTitleBox {

  font-size: 14px;
}

.selTitleBox .spTitle {
  color: #303133;
}

.selBodyBox {
  padding-top: 5px;
}


.wMax {
  width: 100% !important;
}

.navTabsCont .nav-link {
  font-size: 12px;
}

.navToolsCont {
  margin-top: 1px;
}

.btn-mini {
  margin-left: 15px;
  margin-right: 5px;
  height: 40px;
  line-height: 25px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
}
</style>
