<!--
  处分记录-处分详情
-->
<template>
  <div class="SubWebPageStudent" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form label-width="100px">

      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            查看处分信息
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24">
            <el-col :span="24" class="colInfo">
              <label>处分原因</label>
              <div class="colFonts">{{itemData.reason | flNoValue}}</div>
            </el-col>
            <el-col :span="8" class="colInfo">
              <label>处分号</label>
              <div class="colFonts"> {{itemData.punishNo | flNoValue}}</div>
            </el-col>
            <el-col :span="8" class="colInfo">
              <label>处分级别</label>
              <div class="colFonts"> {{itemData.level | flNoValue}}</div>
            </el-col>
            <el-col :span="8" class="colInfo">
              <label>处分日期</label>
              <div class="colFonts"> {{itemData.punishDate | dateformatDay }}</div>
            </el-col>
          </el-row>

        </div>
      </el-card>


      <comShowImage :uploadFiles="uploadFiles"></comShowImage>



      <div class="row" style="margin-top: 10px;">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
        </div>
      </div>
    </el-form>


  </div>
</template>

<script>
  import '@/assets/css/Student.css';
  import comShowImage from '@/components/showImage.vue';
  export default {
    components: {
      comShowImage
    },
    data() {
      return {
        // 动画
        fullscreenLoading: false,
        dialogVisible: false,
        //列表数据
        itemData: [{
          levelName: null,
        }],
        //放大图片
        showViewer: false,
        uploadFiles: [],
        //弹出标题框
        dialogScoreName: '',
        dialogScoreVisible: false,
        dialogArray: [],
        GrowthConfigs: [], //综合类型集合
      };
    },
    methods: {
      onOpenTypeInfo(_data) {
        let that = this;
        that.dialogScoreName = _data.name;
        that.dialogArray = [];
        if (_data != null) {
          for (let item of _data.children) {
            if (item.score < 0) {
              that.dialogArray.push({
                name: item.name,
                score: item.score
              })
            }
          }
        }
        if (that.dialogArray.length > 0) {
          that.dialogScoreVisible = true;
        } else {
          info(that, '未配综合类型');
        }
      },

      onBack() {
        let that = this;
        back(that);
      },
      onCalculationScore(_data) {
        let temp = 0;

        if (_data != null && _data.length > 0) {
          for (let item of _data) {
            temp = item.score + temp;
          }
        }
        return temp;
      },

      flNoValue(obj) {
        if (obj == null || typeof obj == "undefined" || obj == "") {
          return "暂无数据";
        }
        return obj;
      },
    },
    created() {
      let that = this;
      let id = that.$route.params.id;
      that.fullscreenLoading = true;
      ajaxGet(that, "/api/student/punish/" + id, null, function(r) {
        let punishRecord = [];
        that.itemData = r;
        punishRecord = r.logs;
        that.fullscreenLoading = false;
        //新增时
        if (punishRecord != null && punishRecord.length > 0) {
          that.itemData.levelName = r.level.name;
          that.uploadFiles = [];
          if (r.attachment != null && typeof(r.attachment) != "undefined" && r.attachment != "") {
            var files = r.attachment.split('|');
            for (var i in files) {
              that.uploadFiles.push(that.$ServerUrl() + files[i]);
            }
          }
        }
      })
    },
    mounted() {

    },
    filters: {
      flNoValue(obj) {
        if (obj == null || typeof(obj) == "undefined" || obj == '') {
          return '暂无数据';
        }
        return obj;
      }
    },

  };

  function RecursionData(that, _data) {
    if (_data.length !== 0) {
      _data.forEach((item) => {
        if (item.parentId == "00000000-0000-0000-0000-000000000000") {
          that.GrowthConfigs.push({
            configId: item.configId,
            name: item.name,
            parentId: item.parentId,
            score: item.score,
            children: _data.filter(val => val.parentId == item.configId)
          });
        }
      });
    }
  }
</script>

<style scoped="scoped">
  .divLiteracyBox {
    padding: 15px;
  }

  .divLiteracyBox .listBox {
    text-align: center;
    padding: 20px;
    float: left;
    border: 2px solid #FFFFFF;
    border-radius: 5px;
  }

  .divLiteracyBox .listBox:hover {
    border: 2px solid #409eff;
    cursor: pointer;
  }

  .divLiteracyBox .literacyName {
    font-size: 24px;
    letter-spacing: 2px;
    padding-bottom: 10px;
  }

  .divLiteracyBox .literacyNum {
    padding-top: 10px;
    font-family: 'OswaldLight';
    border-top: 2px solid #EEEEEE;
    font-size: 32px;
    color: #409eff;
  }
</style>
