<!--
  新增流程活动
-->
<template>
  <div class="SubWebPage">

    <el-form :model="ruleForm" ref="ruleForm" label-width="100px">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{Title}}
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="获奖名称" label-width="100px">
                <span>{{ruleForm.name}}</span>
              </el-form-item>
            </el-col>
						<el-col :span="24">
              <el-form-item label="综合分系数" label-width="100px">
               {{ruleForm.coefficient}}
              </el-form-item>
            </el-col>
						<el-col :span="24">
						  <el-form-item label="学生" prop="studentId" label-width="100px">
						    <el-select v-model="ruleForm.studentId" placeholder="请选择" style="width: 100% !important;"  class="formControl" filterable>
						        <el-option
						          v-for="item in students"
						          :key="item.studentId"
						          :label="item.label"
						          :value="item.studentId">
						          <span style="float: left">{{ item.studentName }}（{{item.className}}，{{item.studentNo}}，{{item.roleName}}）</span>
						          <span style="float: right; color: #8492a6; font-size: 13px" v-if="item.attended == true">{{ item.statusString }}，已签到</span>
											<span style="float: right; color: #8492a6; font-size: 13px" v-else-if="item.attended == false">{{ item.statusString }}，未签到</span>
						        </el-option>
						      </el-select>
						  </el-form-item>
						</el-col>
          </el-row>
        </div>
      </el-card>
			<div class="row" style="margin-top: 10px;">
					<div class="col-12">
						<el-button  icon="el-icon-back" @click="onBack()">返回</el-button>
						<el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
						<el-button type="primary" icon="el-icon-circle-check" @click="onSave()">提交</el-button>
					</div>
			</div>

    </el-form>



  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  export default {
    components: {
    },
    data() {

      return {
				ruleForm:{
					activityId:null,//活动主键
					prizeId:null,//获奖主键
					name:null,//获奖名称
					studentId:null,//学生主键
					coefficient:null,//系数
				},
				Title:"查看",
				students:[],
      };
    },
		created() {
			let that = this;
			let id = that.$route.params.id;
			let activityId = that.$route.params.activityId;
			that.ruleForm.activityId = activityId;
			var detailUrl = null;
			that.fullscreenLoading = true;
			ajaxGet(that, detailUrl, null, function (r) {
				let Myurl = "/api/admin/activity/" + that.ruleForm.activityId;
				ajaxGet(that, Myurl, null, function (r1) {
					if(r == null)
					{
						that.Title = '新增【' + r1.name + '—评奖】';
					}
					else{
						that.Title = '查看【' + r1.name + '—评奖】';
					}
					that.fullscreenLoading = false;
				});
				
				let studentUrl = "/api/admin/activity/prize/" + that.ruleForm.activityId;
				let rosterUrl = "/api/admin/activity/roster/" + that.ruleForm.activityId;
				ajaxGet(that, studentUrl, null, function (students) {
					ajaxGet(that, rosterUrl, null, function (rosters) {
						that.students = [];
						for(var it of rosters)
						{
							var exist = false;
							for(var stu of students)
							{
								if(stu.studentId == it.studentId)
								{
									exist = true;
								}
							}
							if(exist == false)
							{
								that.students.push(it);
							}
						}
						
						for(var it of that.students)
						{
							var temp = '未签到';
							if(it.attended == true)
							{
								temp = '已签到';
							}
							it.label = it.studentName + '（' + it.className + '，' + it.studentNo + '，' + it.roleName + '，' + it.statusString + '，' + temp +'）'
						}
					});
				});
			});
		},
    
		mounted() {
    },
    methods: {
			onReset()
			{
				
			},
			onBack()
			{
				let that = this;
				back(that);
			},
			onCoefficientChange()
			{
				let that = this;
				that.ruleForm.coefficient = setTwoDecimal(setTwoFloat(that.ruleForm.coefficient));
			},
			onSave()
			{
				let that = this;
				that.$refs["ruleForm"].validate(valid => {
						if (valid) {
							if(that.ruleForm.prizeId == null)
							{
								that.fullscreenLoading = true;
								ajaxPost(that,"/api/admin/activity/prize",that.ruleForm,function(r){
									that.fullscreenLoading = false;
									that.onBack();
								});
							}
						}
						else {
								setTimeout(() => {
										var isError = document.getElementsByClassName("is-error");
										isError[0].querySelector('input').focus();
								}, 1)
								return false;
						}
				});
			},
    
		},
		watch: {

		}
  };
	


</script>

<style scoped="scoped">

</style>

