<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{Title}}
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24">
            <el-col :span="12">

              <el-form-item label="选择学生" prop="student">
                <el-cascader filterable
                  v-model="ruleForm.student"
                  :options="optClass"
                  @change="onStudentChange"  class="width_100Pie"></el-cascader>
              </el-form-item>
            </el-col>
<!--            <el-col :span="12">
              <el-form-item label="选择学生" prop="studentId">
                <el-select @change="onStudentChange" v-model="ruleForm.studentId" placeholder="请选择"
                  class="wMax formControl" filterable>
                  <el-option v-for="item in StudentList" :key="item.studentId" :label="item.name "
                    :value="item.studentId"></el-option>
                </el-select>
              </el-form-item>
            </el-col> -->
            <el-col :span="12">
              <el-form-item label="撤销申请日期" prop="applyDate">
                <el-date-picker :key="random" value-format="yyyy-MM-dd" :picker-options="pickerOptions"
                  v-model="ruleForm.applyDate" type="date" placeholder="请选择学期范围内的日期" class="width_100Pie">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="撤销日期" prop="revokeDate">
                <el-date-picker :key="random" value-format="yyyy-MM-dd" :picker-options="pickerOptions"
                  v-model="ruleForm.revokeDate" type="date" placeholder="请选择学期范围内的日期" class="wMax formControl">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="撤销原因" prop="reason">
                <el-input class="formControl" type="textarea" :rows="4" maxlength="500" show-word-limit
                  placeholder="请输入内容" v-model="ruleForm.reason">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>

      </el-card>
      <el-card class="box-card" v-if="ruleForm.members.length>0">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            学生处分历史记录
          </h3>
        </div>
        <div class="card-body p-0">
          <el-table stripe :data="selectStudentList.punishs">
            <el-table-column type="index" prop="index" label="序号"></el-table-column>
            <el-table-column type="punishNo" prop="punishNo" label="处分号"></el-table-column>
            <el-table-column type="reason" prop="reason" label="处分原因"></el-table-column>
            <el-table-column type="level" prop="level" label="处分类型"></el-table-column>
            <el-table-column type="score" prop="score" label="分数"></el-table-column>
            <el-table-column type="punishDate" prop="punishDate" label="处分时间"></el-table-column>

          </el-table>
        </div>
      </el-card>
      <!--附件-->
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            附件
          </h3>
        </div>
        <div class="card-body pt-5">
          <comUpload :key="random" @onPictureHandleSuccess="onPictureHandleSuccess" :files="uploadFiles"></comUpload>
        </div>
      </el-card>
      <div class="row">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
          <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
          <el-button type="primary" icon="el-icon-circle-check" @click="onSave()">提交</el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comUpload from '@/components/Upload.vue';
  var punishDateMin = null;
  var punishDateMax = null;

  function disabledDateTest(date) {
    if (punishDateMin != null && typeof(punishDateMin) != "undefined" && punishDateMax != null && typeof(
        punishDateMax) != "undefined") {
      return new Date(date).getTime() < new Date(punishDateMin).getTime() || new Date(date).getTime() > new Date(
        punishDateMax).getTime();
    } else if (punishDateMin != null && typeof(punishDateMin) != "undefined" && (punishDateMax == null || typeof(
        punishDateMax) == "undefined")) {
      return new Date(date).getTime() < new Date(punishDateMin).getTime();
    } else if ((punishDateMin == null || typeof(punishDateMin) == "undefined") && punishDateMax != null && typeof(
        punishDateMax) != "undefined") {
      return new Date(date).getTime() > new Date(punishDateMax).getTime();
    }
    return false;
  }
  export default {
    components: {
      comUpload
    },
    data() {
      return {
        content: '',
        editorOption: {},
        dialogTableVisible: false,
        dialogStudentName: null,
        dialogClassName: null,
        dialogStudentNo: null,
        student:[],
        isOne: false, //是否只能选择一个学生
        studentPunsihs: [], //单个学生的历史处分
        selectStudentList: [], //选中的学生集合
        random: 0, //刷新组件
        punishDateMin: null,
        punishDateMax: null,
        pickerOptions: { //处分时间不能超出学期的时间范围
          disabledDate(time) {
            return disabledDateTest(time);
          },
        },
        optClass:[],
        ruleForm: //表单数据
        {
          reason: null, //备注
          members: [],
          attachment: null,
          punishs: {},
          student:[],
        },
        allStudents: [], //所有学生集合
        selectClassId: null,
        studentId: null,
        ruleFormReset: null, //重置时的表单数据
        ClassList: [], //班级集合
        StudentList: [], //班级学生集合
        Semesters: [], //学期集合
        Title: "新增处分撤销信息",
        fullscreenLoading: false,
        uploadFiles: [],
        rules: {
          student:[{
            type:'array',
            required: true,
            message: '请选择学生',
            trigger: 'change',
          }],
          applyDate: [{
            required: true,
            message: '请选择撤销申请日期',
            trigger: 'change',
          }],
          revokeDate: [{
            required: true,
            message: '请选择撤销日期',
            trigger: 'change',
          }],
          reason: [{
            max: 500,
            required: true,
            message: '撤销原因不能超过500个字符',
            trigger: 'change'
          }, ],

        },

        TeacherInfo:{},
      };
    },
    created() {
      let that = this;
      let id = that.$route.params.id;
      that.TeacherInfo=onGetNowUser(that);
      var detailUrl = null;
      if (id != null && typeof(id) != "undefined" && id != "") {
        that.Title = "编辑处分撤销信息";
        that.ruleForm.reasonId = id;
        detailUrl = "/api/admin/punish/" + id;
      }
      that.fullscreenLoading = true;
      ajaxGet(that, detailUrl, null, function(r) {
        if (r != null) {
          that.ruleForm = r;
          that.uploadFiles = [];
          if (r.attachment != null && typeof(r.attachment) != "undefined" && r.attachment != "") {
            var files = r.attachment.split('|');
            for (var i in files) {
              var it = i + 1;
              that.uploadFiles.push({
                uid: it,
                path: files[i],
              });
            }
            var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
            that.random += random;
          }
        }
        that.allStudents = [];
        that.optClass = [];
        ajaxGet(that, "/api/admin/Punish/Class", null, function(r1) {

            let resClass= that.TeacherInfo.isTeacher ? that.TeacherInfo.manageClass : that.TeacherInfo.allClass;

            for (var i in r1) {
              for (var j in resClass) {
                if (r1[i].classId == resClass[j].classId) {
                  that.ClassList.push(r1[i]);
                }
              }
            }
            for (var i in that.ClassList) {
              var it = that.ClassList[i];
                that.optClass.push({
                  value:it.classId,
                  label:it.name,
                  children:[],
                })
              for (var j in it.students) {
                var stu = it.students[j];
                stu.classId = it.classId;
                stu.className = it.name;
                that.allStudents.push(stu);
              }
            };
            for(var it of that.allStudents){
              for( var i in that.optClass){
                if(that.optClass[i].value == it.classId){
                  that.optClass[i].children.push({
                    value:it.studentId,
                    label:it.name+'-'+it.studentNo,
                  })
                }
              }
            }



          that.fullscreenLoading = false;
          if (r == null) //新增时
          {} else {
            var configs = [];
            for (var i in r.members) {
              var member = r.members[i];
              for (var m in member.configs) {
                var studentConfig = member.configs[m];
                var isExist = false;
                for (var j in configs) {
                  if (configs[j].configId == studentConfig.configId) {
                    isExist = true;
                  }
                }
                if (isExist == false) {
                  configs.push(studentConfig);
                }
              }
            }
            that.selectStudentList = [];
            for (var i in r.members) {
              var member = r.members[i];
              var punishCount = 0;
              var score = 0;
              if (that.isOne != true) {
                for (var j in that.allStudents) {
                  if (member.student.studentId == that.allStudents[j].studentId) {
                    punishCount = that.allStudents[j].punishs.length;
                    for (var m in that.allStudents[j].punishs) {
                      score += that.allStudents[j].punishs[m].score;
                    }
                  }
                }
                that.selectStudentList.push({
                  studentId: member.student.studentId,
                  punishCount: punishCount,
                  score: score,
                  className: member.student.className,
                  studentName: member.student.name,
                  studentNo: member.student.studentNo,
                });
              } else {
                for (var i in that.allStudents) {
                  var stu = that.allStudents[i];
                  if (stu.studentId == member.studentId) {
                    that.ruleForm.members.push(stu);
                    that.studentPunsihs = stu.punishs;
                    that.studentPunsihs.sort(function(a, b) {
                      return new Date(b.punishDate).getTime() - new Date(a.punishDate).getTime();
                    })
                    for (var j in that.studentPunsihs) {
                      that.studentPunsihs[j].className = stu.className;
                      that.studentPunsihs[j].studentName = stu.name;
                      that.studentPunsihs[j].studentNo = stu.studentNo;
                    }
                  }
                }
              }
            }
          }
          that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
        })
        that.fullscreenLoading = false;
      });

    },

    methods: {
      /**
       * @description 附件上传成功后事件
       * @param {Object} param
       */
      onPictureHandleSuccess(param) {
        let that = this;
        that.ruleForm.attachment = "";
        for (var i in param) {
          if (that.ruleForm.attachment == "") {
            that.ruleForm.attachment = param[i].path;
          } else {
            that.ruleForm.attachment += "|" + param[i].path;
          }
        }
      },


      /**
       * @description 列表合计事件
       * @param {Object} param
       */
      getDialogSummaries(param) {
        const {
          columns,
          data
        } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '累计分数';
            return;
          }
          const values = data.map(item => Number(item[column.property]));
          if (column.property === 'score') {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index];
          } else {
            sums[index] = '--';
          }
        });

        return sums;
      },
      getSummaries(param) {
        const {
          columns,
          data
        } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '累计分数';
            return;
          }
          const values = data.map(item => Number(item[column.property]));
          if (column.property === 'score') {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index];
          } else {
            sums[index] = '--';
          }
        });

        return sums;
      },
      /**
       * @description 查看学生历史处分记录
       * @param {Object} studentName
       * @param {Object} className
       * @param {Object} studentNo
       * @param {Object} studentId
       */
      onStudentPunishDetail(studentName, className, studentNo, studentId) {
        let that = this;
        that.dialogStudentName = studentName;
        that.dialogClassName = className;
        that.dialogStudentNo = studentNo;
        that.dialogTableVisible = true;
        that.studentPunsihs = [];
        for (var i in that.allStudents) {
          if (that.allStudents[i].studentId == studentId) {
            that.studentPunsihs = that.allStudents[i].punishs;
          }
        }

        that.studentPunsihs.sort(function(a, b) {
          return new Date(b.punishDate).getTime() - new Date(a.punishDate).getTime();
        })
      },


      /**
       * @description 重置事件
       */
      onReset() {
        let that = this;
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
        that.selectClassId = JSON.parse(JSON.stringify(''));
        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },


      /**
       * @description 提交事件
       */
      onSave() {
        let that = this;

        // if (that.ruleForm.selectClassId == "" || typeof(that.ruleForm.selectClassId) == "undefined") {
        //   warning(that, "请选择班级")
        //   return false;
        // }


        if (that.selectStudentList.punishs == null || typeof(that.selectStudentList.punishs) == "undefined" || that
          .selectStudentList.punishs.length <= 0) {
          warning(that, "当前学生没有处分记录");
          return false;
        }

        if (that.selectStudentList.punishs != "") {
          that.$refs["ruleForm"].validate(valid => {
            if (valid) {


              if (that.ruleForm.studentId == "" || typeof(that.ruleForm.studentId) == "undefined") {
                warning(that, "请选择学生")
                return false;
              }




              for (var i in that.ruleForm.members) {
                var member = that.ruleForm.members[i];
                for (var j in that.allStudents) {
                  var stu = that.allStudents[j];
                  if (stu.studentId == member.studentId) {
                    member.classId = stu.classId;
                  }
                }
              }
              if (that.ruleForm.punishId == null) {
                that.fullscreenLoading = true;
                ajaxPost(that, "/api/admin/punishrevoke", that.ruleForm, function(r) {
                  that.fullscreenLoading = false;
                  that.onBack();
                });
              } else {
                that.fullscreenLoading = true;
                ajaxPut(that, "/api/admin/punishrevoke" + that.ruleForm.punishId, that.ruleForm, function(r) {
                  that.fullscreenLoading = false;
                  that.onBack();
                });
              }
            } else {
              setTimeout(() => {
                var isError = document.getElementsByClassName("is-error");
                isError[0].querySelector('input').focus();
              }, 1)
              return false;
            }
          });
        }
      },


      /**
       * @description 选择学生下拉选项事件
       */
      onStudentChange(_val) {
        let that = this;
        let tempInfo = that.allStudents.find(item => item.studentId == _val[_val.length-1]);
        that.ruleForm.studentId = tempInfo.studentId;
        if (tempInfo != undefined) {
          that.ruleForm.members.push(tempInfo);
          that.studentPunsihs = tempInfo.punishs;
          that.studentPunsihs.sort(function(a, b) {
            return new Date(b.punishDate).getTime() - new Date(a.punishDate).getTime();
          });
          for (var j in that.studentPunsihs) {
            that.studentPunsihs[j].className = tempInfo.className;
            that.studentPunsihs[j].studentName = tempInfo.name;
            that.studentPunsihs[j].studentNo = tempInfo.studentNo;
          }
        }
        that.selectStudentList = [];
        for (var m in that.ruleForm.members) {

          var member = that.ruleForm.members[m];

        }
        that.selectStudentList = member;
        that.ruleForm.punishs = [];
        that.ruleForm.punishs = that.selectStudentList.punishs;

      },
      onClassChange() {
        let that = this;
        that.StudentList = [];
        that.studentId = null;
        for (var i in that.ClassList) {
          var it = that.ClassList[i];
          if (it.classId == that.ruleForm.selectClassId) {
            for (var j in it.students) {
              var isExist = false;
              var stu = it.students[j];
              for (var m in that.ruleForm.members) {
                var member = that.ruleForm.members[m];
                if (member.studentId == stu.studentId) {
                  isExist = true;
                  break;
                }
              }
              if (isExist == false) {
                that.StudentList.push(stu);
              }
            }
          }
        }
        that.$set(that, "StudentList", that.StudentList);
      },

      onSemesterChange() {
        let that = this;
        for (var i in that.Semesters) {
          if (that.Semesters[i].semesterId == that.ruleForm.semesterId) {
            punishDateMin = that.Semesters[i].startDate;
            punishDateMax = that.Semesters[i].endHoliday;
            that.jobnumberKey = "1234324324";

            var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
            that.random += random;
          }
        }
      },
      onReasonChange() {
        let that = this;
        for (var i in that.Reasons) {
          if (that.ruleForm.reasonId == that.Reasons[i].reasonId) {
            that.ruleForm.levelId = that.Reasons[i].levelId;
          }
        }
      },
      onLevelChange() {
        let that = this;
        that.fullscreenLoading = true;
        ajaxGet(that, '/api/admin/punishlevel/' + that.ruleForm.levelId, null, function(r) {

          that.fullscreenLoading = false;
          for (var i in that.Reasons) {
            if (that.ruleForm.reasonId == that.Reasons[i].reasonId) {}
          }
        })
      },
      onBack() {
        let that = this;
        back(that);
      },
    },
  };
</script>

<style scoped="scoped">
  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }

  .spIcon {
    width: 5px;
    height: 20px;
    border-radius: 5px;
    background-image: linear-gradient(#4545FF, #2D8EFF);
  }

  .spTitle {
    float: left;
    margin-left: 20px;
    color: #323E56;
    letter-spacing: 2px;
    font-size: 16px;
    margin-top: -3px;
  }

  .wMax {
    width: 100% !important;
  }
</style>
