<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{Title}}
          </h3>
          <div class="card-tools">
            <ul class="nav nav-pills ml-auto">
              <li class="nav-item">
                <el-checkbox v-model="isSchoolCalendar">是否调用校历</el-checkbox>
              </li>
            </ul>
          </div>
        </div>
        <div class="card-body ">
          <el-row :gutter="24">
            <el-col :span="12" v-if="!isSchoolCalendar">
              <el-form-item label="评比时间范围" prop="arrTimeRange">
                <el-date-picker   v-model="ruleForm.arrTimeRange" type="daterange" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期" class="width_100Pie">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="isSchoolCalendar">
              <el-form-item label="选择时间周期" prop="cycleVal">
                <el-select v-model="ruleForm.cycleVal" class="width_100Pie" placeholder="请选择">
                  <el-option v-for="item in optCycle" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="ruleForm.cycleVal==0 && isSchoolCalendar">
              <el-form-item label="学期" prop="semesterVal">
                <el-select v-model="ruleForm.semesterVal" class="width_100Pie" placeholder="请选择">
                  <el-option v-for="item in optSemester" :key="item.semesterId" :label="item.name"
                    :value="item.semesterId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="ruleForm.cycleVal==1 && isSchoolCalendar">
              <el-form-item label="月份" prop="monthVal">
                <el-select v-model="ruleForm.monthVal" class="width_100Pie" placeholder="请选择">
                  <el-option v-for="item in optMonth" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="ruleForm.cycleVal==2 && isSchoolCalendar">
              <el-form-item label="周" prop="weekVal">
                <el-select v-model="ruleForm.weekVal" class="width_100Pie" placeholder="请选择">
                  <el-option v-for="item in optWeek" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="综合之星名称" prop="name">
                <el-input v-model="ruleForm.name" placeholder="请输入综合之星名称" class="formControl"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="评比范围" prop="range">
                <el-select v-model="ruleForm.range" class="width_100Pie" placeholder="请选择">
                  <el-option v-for="item in optRange" :key="item.Value" :label="item.Text" :value="item.Value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>


      <!-- 配置单 -->
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            添加评比配置单
          </h3>
          <div class="card-tools" v-if="!ruleForm.isCalculation">
            <ul class="nav nav-pills ml-auto">
              <li class="nav-item">
                <el-button size="mini" type="success" @click="dialogVisible = true">新增配置单</el-button>
              </li>
            </ul>
          </div>
        </div>

        <div class="card-body p-0" v-if="ruleForm.configs!=null && ruleForm.configs.length!=0">
          <el-table :data="ruleForm.configs" stripe class="elTableData">
            <el-table-column type="index" label="序号" class="elColumn" width="80"></el-table-column>
            <el-table-column prop="configName" label="综合纬度" class="elColumn" min-width="200"></el-table-column>
            <el-table-column label="评比要求" class="elColumn" min-width="300">
              <template slot-scope="scope">
                &nbsp;
                <span class="badge badge-success" v-if="scope.row.score">
                  {{'达标分数：'+scope.row.score}}
                </span>
                &nbsp;
                <span class="badge badge-success" v-if="scope.row.ratio">
                  {{'名次：'+((scope.row.ratio)*100)+'%'}}
                </span>
                &nbsp;
              </template>
            </el-table-column>
            <el-table-column label="操作" width="100" fixed="right" v-if="!ruleForm.isCalculation">
              <template slot-scope="scope">
                <operationBtn :btnName="'删除'" ref="delete" @click.native="onDelete(scope.row.configId)"></operationBtn>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="card-body " v-else>
          <el-empty :image-size="200" description="暂无配置" name="3"></el-empty>
        </div>
      </el-card>

      <!-- 上传图标 -->
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            综合之星图标
          </h3>
<!--          <div class="card-tools">
            <ul class="nav nav-pills ml-auto">
              <li class="nav-item">
                <el-checkbox v-model="isDefaultIco">是否使用默认图标</el-checkbox>
              </li>
            </ul>
          </div> -->
        </div>
        <div class="card-body mt-4" v-if="!isDefaultIco">
          <comUpload :key="random" @onPictureHandleSuccess="onPictureHandleSuccess" :pictureMaxCount="1"
            :files="uploadFiles" ref="uploadBox"></comUpload>
        </div>
        <div class="card-body mt-4 " v-else>
          <el-row :gutter="20">
            <el-col class="text-center" :span="4" v-for="(item,index) in arrIconList" :key="index">
              <el-image class="icoImageBox" :class="{'actIcon':iconCurrent==index}" :src="item" fit="cover"
                @click="onClickIcon(index)"></el-image>
            </el-col>
          </el-row>
        </div>
      </el-card>






      <div class="row" style="margin-top: 10px;">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
          <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
          <el-button type="primary" icon="el-icon-circle-check" @click="onSubmit()">提交</el-button>
        </div>
      </div>
    </el-form>


    <!-- 弹出框 -->
    <el-form :model="frameFrom" :rules="rulesFrameFrom" ref="frameFrom" label-width="120px">
      <el-dialog title="新增配置" :visible.sync="dialogVisible" width="50%" :close-on-click-modal="false"
        :before-close="onHandleClose">
        <el-row :gutter="24">
          <el-col :span="24" class="pr-4">
            <el-form-item label="综合纬度" prop="growthVal">
              <el-cascader class="width_100Pie" v-model="frameFrom.growthVal" :options="optGrowth" :props="{
                    checkStrictly: true,
                    label:'name',
                    value:'id'
                  }" clearable @change="onCloseCascader" ref="cascaderHandle"></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="24" class="pr-4">
            <el-form-item label="评比要求">
              <div class="divCheckedBox clearfix ">
                <span class="spChecked">
                  <el-checkbox v-model="frameFrom.ckScore" @change="onChangeCk">达标分数</el-checkbox>
                </span>
                <span class="spChecked">
                  <el-checkbox v-model="frameFrom.ckRankPec" @change="onChangeCk">名次（%）</el-checkbox>
                </span>
                <div class="divErrCont" v-if="isCheckBoxTip">
                  评比要求至少要选择一项
                </div>
              </div>

            </el-form-item>
          </el-col>
          <el-col :span="24" class="pr-4" v-if="frameFrom.ckScore">
            <el-form-item label="达标分数" prop="txtFraction">
              <el-input placeholder="请输入达标分数" v-model="frameFrom.txtFraction"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" class="pr-4" v-if="frameFrom.ckRankPec">
            <el-form-item label="名次（%）" prop="txtRanking">
              <el-input placeholder="请输入名次百分比" v-model="frameFrom.txtRanking"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
          <el-button @click="onHandleClose">取 消</el-button>
          <el-button type="primary" @click="onClickConfig">确 定</el-button>
        </span>
      </el-dialog>
    </el-form>

  </div>

</template>

<script>
  import '@/assets/css/Admin.css';
  import comUpload from '@/components/Upload.vue';
  import chooseDate from '../../../plugins/chooseDate.js';
  import operationBtn from '@/components/operationBtn.vue';
  export default {
    components: {
      comUpload,
      operationBtn
    },
    data() {
      return {
        // 加载动画
        dateRange:[],
        fullscreenLoading: false,
        // 上传图标对象
        uploadFiles: [],
        uploadFilesReset: [],
        // 页面标题
        Title: '添加综合之星',
        // 表单对象
        ruleForm: {
          starId: null,
          name: '', // 综合之星名称
          startDate: '', // 开始时间
          endDate: '', // 结束时间
          range: null, // 评比范围
          icon: '', // 图标
          configs: [], //综合配置
          isCalculation: false,
          // 时间范围
          arrTimeRange: [],
          // 校历选择的值
          cycleVal: null,
          // 学期
          semesterVal: '',
          monthVal: '',
        },
        // 验证
        rules: {
          name: [{
              required: true,
              message: '综合之星名称不能为空',
              trigger: 'change'
            },
            {
              max: 20,
              message: '名称不能超过20个字符',
              trigger: 'change'
            },
          ],

          arrTimeRange: [{
            required: true,
            message: '评比时间范围',
            trigger: 'change'
          }, ],
          cycleVal: [{
            required: true,
            message: '请选择时间周期',
            trigger: 'change'
          }, ],
          semesterVal: [{
            required: true,
            message: '请选择学期',
            trigger: 'change'
          }, ],
          monthVal: [{
            required: true,
            message: '请选择月份',
            trigger: 'change'
          }, ],
          weekVal: [{
            required: true,
            message: '请选择周',
            trigger: 'change'
          }, ],
          range: [{
            required: true,
            message: '请选择评比范围',
            trigger: 'change'
          }, ],
        },
        // 是否调用校历
        isSchoolCalendar: false,
        // 是否使用默认图标
        isDefaultIco: false,
        // 调用控件随机ID
        random: '',
        // 新增配置单弹出框
        dialogVisible: false,

        // 评比范围
        optRange: [],

        // 综合评价下拉选择项
        allGrowth: [],
        optGrowth: [],

        // 弹出框
        frameFrom: {
          // 达标分数
          ckScore: false,
          // 名次百分比
          ckRankPec: false,
          // 综合评价选择项值
          growthVal: '',
          // 达标分数
          txtFraction: '',
          // 名次
          txtRanking: '',
        },
        isCheckBoxTip: false,
        rulesFrameFrom: {
          growthVal: [{
            required: true,
            message: '请选择综合纬度',
            trigger: 'change'
          }, ],
          txtRanking: [{
            required: true,
            message: '名次不能为空',
            trigger: 'change'
          }],
          txtFraction: [{
              required: true,
              message: '综合之星名称不能为空',
              trigger: 'change'
            },
            {
              max: 20,
              message: '名称不能超过20个字符',
              trigger: 'change'
            },
          ],
        },
        frameFromReset: null,

        // 综合之星的图标，
        arrIconList: [
          '/ico/icon_maxstar.png',
          '/ico/icon_star_green.png',
          '/ico/icon_star_purple.png',
          '/ico/icon_star_red.png',
          '/ico/icon_star_yellow.png',
        ],
        iconCurrent: -1,


        // 调用校历选择时间段
        optCycle: [{
            label: '学期',
            value: 0
          },
          {
            label: '当前学期月份',
            value: 1
          },
          {
            label: '当前学期周',
            value: 2
          }
        ],

        // 时间范围
        scopeVal: null,


        // 所有校历数据
        arrAllSemesterday: [],

        // 学期
        optSemester: [],


        // 月份
        optMonth: [],

        // 周
        optWeek: [],
        weekVal: '',
        options: [],
        checked1: false,
      };
    },
    created() {
      let that = this;
      that.frameFromReset = JSON.parse(JSON.stringify(that.frameFrom));

      onGetAllSemester(that);
      onGetGrowthConfig(that);

      let dataID = that.$route.params.id;
      if (dataID != null && typeof(dataID) != "undefined" && dataID != "") {
        that.ruleForm.starId = dataID;
        onGetData(that);
        that.Title="编辑综合之星";
      } else {
        onGetRange(that);
      }

      var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
      that.random += random;
      that.uploadFilesReset = JSON.parse(JSON.stringify(that.uploadFiles));

    },
    methods: {
      /**
       * @description 多级联动下拉框点击自动关闭选项事件
       * @param {Object} val
       */
      onCloseCascader(val) {
        this.$refs.cascaderHandle.dropDownVisible = false; //监听值发生变化就关闭它
      },

      /**
       * @param {Object} val
       */
      onChangeCk(val) {
        let that = this;
        if (!that.frameFrom.ckScore && !that.frameFrom.ckRankPec) {
          that.isCheckBoxTip = true;
        } else {
          that.isCheckBoxTip = false;
        }
      },

      /**
       * @description 关闭弹出框
       */
      onHandleClose() {
        let that = this;
        // 重置
        that.frameFrom = JSON.parse(JSON.stringify(that.frameFromReset));
        that.dialogVisible = false;
      },

      /**
       * @description 删除
       * @param {Object} _valID ID
       */
      onDelete(_valID) {
        let that = this;
        that.ruleForm.configs = that.ruleForm.configs.filter(val => val.configId != _valID);
        //that.optGrowth.push(that.allGrowth.find(val => val.configId == _valID));
      },

      /**
       * @description 提交事件
       */
      onSubmit() {
        let that = this;

        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            if (regNull(that.ruleForm.icon)) {
              warning(that, '请上传或选择综合之星图标');
            } else {
              if (that.ruleForm.configs != null && that.ruleForm.configs.length != 0) {
                let tempArrTime = [];
                // 判断是否调用校历数据
                if (that.isSchoolCalendar) {
                  switch (that.ruleForm.cycleVal) {
                    case 0:
                      // 学期
                      let tempSemester = that.optSemester.find(val => val.semesterId == that.ruleForm
                        .semesterVal);
                      tempArrTime = [];
                      tempArrTime.push({
                        startDate: timeTransform(tempSemester.startDate),
                        endDate: timeTransform(tempSemester.endDate)
                      });

                      break;
                    case 1:
                      // 月份
                      let tempMonth = that.optMonth.find(val => val.value == that.ruleForm.monthVal);
                      // 判断是否为每月
                      tempArrTime = [];
                      if (regNull(tempMonth.startTime)) {
                        for (let item of that.optMonth) {
                          if (!regNull(item.startTime)) {
                            tempArrTime.push({
                              startDate: timeTransform(item.startTime),
                              endDate: timeTransform(item.endTime)
                            });
                          }
                        }
                      } else {
                        tempArrTime.push({
                          startDate: timeTransform(tempMonth.startTime),
                          endDate: timeTransform(tempMonth.endTime)
                        });
                      }
                      break;
                    case 2:
                      // 周
                      let tempWeek = that.optWeek.find(val => val.value == that.weekVal);
                      // 判断是否为每月
                      tempArrTime = [];
                      if (regNull(tempWeek.startTime)) {
                        for (let item of that.optWeek) {
                          if (!regNull(item.startTime)) {
                            tempArrTime.push({
                              startDate: timeTransform(item.startTime),
                              endDate: timeTransform(item.endTime)
                            });
                          }
                        }
                      } else {
                        tempArrTime.push({
                          startDate: timeTransform(tempWeek.startTime),
                          endDate: timeTransform(tempWeek.endTime)
                        });
                      }
                      break;
                  }
                } else {
                  // 自己选择时间范围
                  tempArrTime = [];
                  tempArrTime.push({
                    startDate: timeTransform(that.ruleForm.arrTimeRange[0]),
                    endDate: timeTransform(that.ruleForm.arrTimeRange[1])
                  });
                }

                that.ruleForm.range = parseInt(that.ruleForm.range);
                // 添加配置
                if (regNull(that.ruleForm.starId)) {
                  for (let item of tempArrTime) {
                    that.fullscreenLoading = true;
                    that.ruleForm.startDate = item.startDate;
                    that.ruleForm.endDate = item.endDate;
                    ajaxPost(that, '/api/admin/literacystar', that.ruleForm, function(res) {
                      that.fullscreenLoading = false;
                     back(that);
                    });
                  }
                } else {
                  for (let item of tempArrTime) {
                    that.fullscreenLoading = true;
                    that.ruleForm.startDate = item.startDate;
                    that.ruleForm.endDate = item.endDate;
                    ajaxPut(that, '/api/admin/literacystar/' + that.ruleForm.starId, that.ruleForm, function(
                      res) {
                      that.fullscreenLoading = false;
                      back(that);
                    });
                  }
                }
              } else {
                warning(that, '请添加评比配置单');
              }
            }
          }
        });



      },


      /**
       * @description 返回
       */
      onBack() {
        back(this);
      },


      /**
       * @description 默认图标点击事件
       * @param {Object} _index 当前下标
       */
      onClickIcon(_index) {
        let that = this;
        that.iconCurrent = _index;
        that.ruleForm.icon = that.arrIconList[_index];
      },

      /**
       * @description 弹出框配置提交事件
       */
      onClickConfig() {
        let that = this;
        // 验证
        that.$refs["frameFrom"].validate(valid => {
          if (valid) {
            // 判断是否有选择有评比要求
            if (!that.frameFrom.ckScore && !that.frameFrom.ckRankPec) {
              that.isCheckBoxTip = true;
            } else {
              that.isCheckBoxTip = false;

              // 获取最终的ID
              let tempID = that.frameFrom.growthVal[that.frameFrom.growthVal.length - 1];

              // 判断是否已存在，已存在
              if (that.ruleForm.configs.find(val => val.configId == tempID) == undefined) {
                let tempData = that.allGrowth.find(val => val.id == tempID);
                // 添加配置清单
                if (tempData != undefined) {
                  that.ruleForm.configs.push({
                    configId: tempData.id,
                    configName: tempData.title,
                    score: regNull(that.frameFrom.txtFraction) ? null : setTwoDecimal(that.frameFrom.txtFraction,
                      1),
                    ratio: regNull(that.frameFrom.txtRanking) ? null : setTwoDecimal((that.frameFrom.txtRanking /
                      100), 2)
                  });
                  // 把选中的删除掉
                  that.optGrowth = that.optGrowth.filter(val => val.configId != that.frameFrom.growthVal);

                } else {
                  warning(that, '配置出错，请重新配置');
                }
                // 重置
                that.frameFrom = JSON.parse(JSON.stringify(that.frameFromReset));
                that.dialogVisible = false;
              } else {
                warning(that, '当前综合之星已配置此综合纬度，请选择其它纬度');
              }
            }
          }
        });
      },

      /**
       * @description 上传图标成功后事件
       */
      onPictureHandleSuccess(param) {
        let that = this;
        that.ruleForm.icon = "";
        that.ruleForm.icon = param[0].path;
      },




    }
  };

  /**
   * @description 获取数据
   * @param {Object} that
   */
  function onGetData(that) {
    that.fullscreenLoading = true;

    ajaxGet(that, '/api/admin/literacystar/type', null, function(resType) {
      ajaxGet(that, '/api/admin/literacystar/' + that.ruleForm.starId, null, function(resData) {
        that.fullscreenLoading = false;
        that.optRange = resType;
        that.ruleForm = resData;
        that.ruleForm.range = resType.find(val => val.Value == resData.range).Value;
        that.$set(that.ruleForm,'arrTimeRange',[`${resData.startDate}`,`${resData.endDate}`])
        that.uploadFiles = [];
        if (!regNull(resData.icon)) {
          that.uploadFiles.push({
            uid: Math.floor(Math.random() * (100000 - 1 + 1)) + 10000,
            path: resData.icon,
          });

          that.$refs.uploadBox.uploadImage(that.uploadFiles);
        }
        for (let item of resData.configs) {
          that.optGrowth = that.optGrowth.filter(val => val.configId != item.configId);
        }
      });
    });
  }

  /**
   * @description 获取所有学期数据
   * @param {Object} that
   */
  function onGetAllSemester(that) {
    that.fullscreenLoading = true;
    // 获取学期数据
    ajaxGet(that, '/api/admin/semester', null, function(resSemester) {

      that.optSemester = resSemester;
      // 获取当前学期
      let temp = resSemester.find(val => val.isCurrent == true);
      that.ruleForm.semesterVal = temp != undefined ? temp.semesterId : '';
      let data = {
        SemesterId: temp != undefined ? temp.semesterId : ''
      }
      // 重置月份和周
      that.optMonth = [];
      that.optWeek = [];

      // 获取校历数据
      ajaxGet(that, '/api/admin/semesterday/search', data, function(resDay) {
        that.fullscreenLoading = false;
        // 本学期月份数据
        that.optMonth.push({
          value: Math.floor(Math.random() * (100000 - 1 + 1)) + 10000,
          startTime: null,
          endTime: null,
          label: '本学期每月'
        });
        // 默认第一条数据
        // that.ruleForm.monthVal = that.optMonth[0].value;
        that.$set(that.ruleForm,'monthVal',that.optMonth[0].value)
        for (let item of resDay) {
          let now = new Date(item.day);
          let tempTime = chooseDate(3, item.day);
          let tempMonth = (now.getMonth() + 1) + '月';
          if (that.optMonth.find(val => val.label == tempMonth) == undefined) {
            that.optMonth.push({
              value: Math.floor(Math.random() * (100000 - 1 + 1)) + 10000,
              startTime: tempTime.startTime,
              endTime: tempTime.endTime,
              label: tempMonth
            });
          }
        };

        // 本学期每周
        that.optWeek.push({
          value: Math.floor(Math.random() * (100000 - 1 + 1)) + 10000,
          startTime: null,
          endTime: null,
          label: '本学期每周'
        });
        that.weekVal = that.optWeek[0].value;
        let iCount = 0;
        for (let item of resDay) {
          let tempWeek = chooseDate(2, item.day);
          if (that.optWeek.find(val => val.startTime == tempWeek.startTime) == undefined) {
            iCount++;
            that.optWeek.push({
              value: Math.floor(Math.random() * (100000 - 1 + 1)) + 10000,
              startTime: tempWeek.startTime,
              endTime: tempWeek.endTime,
              label: '第 ' + iCount + ' 周'
            });
          }
        }
      });
    });
  }

  /**
   * @description 评比范围
   * @param {Object} that
   */
  function onGetRange(that) {
    that.fullscreenLoading = true;
    ajaxGet(that, '/api/admin/literacystar/type', null, function(resData) {
      that.fullscreenLoading = false;
      that.optRange = resData;
    });
  }

  /**
   * @description 综合评价
   * @param {Object} that
   */
  function onGetGrowthConfig(that) {
    that.fullscreenLoading = true;
    ajaxGet(that, '/api/admin/growthconfig/treelist', null, function(resData) {
      that.fullscreenLoading = false;
      that.optGrowth = resData;
      that.allGrowth = treeConvertList(resData);
    });
  }

  /**
   * @description 将树型数据转换为线型数组
   * @param {Object} arr
   */
  function treeConvertList(arr) {
    let res = []
    arr.forEach((item) => {
      res.push(item)
      if (item.children) {
        res.push(...treeConvertList(item.children))
      }
      //delete item.children;
    })
    return res
  }
</script>

<style scoped="scoped">
  .icoImageBox {
    width: 100px;
    height: 100px;
    margin: 5px auto;
    border: 1px dotted #DDDDDD;
    border-radius: 10px;
    padding: 5px;
    cursor: pointer;
  }

  .actIcon {
    border: 1px solid #007bff;
  }


  .divCheckedBox {
    width: 300px;
  }

  .divCheckedBox .spChecked {
    padding-right: 30px;
  }

  .divErrCont {
    font-size: 0.75rem;
    margin-top: -20px;
    display: block;
    width: 100%;
    color: #F56C6C;
  }
</style>
