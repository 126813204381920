<!--
  基础信息-学期管理
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">

    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          学期信息
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <search :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null"></search>
            </li>
            <li class="nav-item">
              <el-button v-if="isPower.semester_create == true" type="success" icon="el-icon-circle-plus-outline"  @click="onCreate()">新增</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table :data="itemData" stripe class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="name" label="学期名称" class="columnBox" min-width="240"  :show-overflow-tooltip="true">
            <template slot-scope="scope">
              {{scope.row.name}}
              <svg class="icon color-danger" aria-hidden="true" v-if="scope.row.isCurrent">
                <use xlink:href="#fax-star-alt"></use>
              </svg>
            </template>
          </el-table-column>
          <el-table-column prop="date" label="日期范围" min-width="240"  :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <span>{{scope.row.startDate | dateformat('YYYY-MM-DD')}} 至 {{scope.row.endDate | dateformat('YYYY-MM-DD')}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="holiday" label="假期范围" min-width="240"  :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <span v-if="scope.row.startHoliday">{{scope.row.startHoliday | dateformat('YYYY-MM-DD')}} 至 {{scope.row.endHoliday | dateformat('YYYY-MM-DD')}}</span>
              <span v-else>暂无假期范围</span>
            </template>
          </el-table-column>
          <el-table-column prop="year" label="学年度" width="140"  :show-overflow-tooltip="true"></el-table-column>
          <el-table-column label="操作" width="200" fixed="right">
            <template slot-scope="scope">
              <operationBtn v-if="isPower.semester_details == true" :btnName="'查看'" @click.native="onView(scope.row.semesterId)"></operationBtn>
              &nbsp;
              <el-dropdown v-if="isPower.semester_edit || isPower.semester_delete" trigger="click"
                @command="handleCommand($event,scope.row.semesterId)">
                <el-button size="mini">
                  更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="isPower.semester_edit" class="color-warning"
                    command="onEdit">编辑</el-dropdown-item>
                  <el-dropdown-item divided v-if="isPower.semester_delete" class="color-danger"
                    command="onDelete">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>


  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';
  export default {
    name: '',
    components: {
      comPage,
      operationBtn,search
    },
    data() {
      return {
        fullscreenLoading: false,
        //搜索
        SearchList:null,
        //分页查询接口返回的结果
        page: null,
        //列表数据
        itemData: [],
        //分页左侧按钮集合
        pageBtnList: null,
        //分页数据
        paging: {
          // 总共页数
          pageLength: 0,
          // 每页请求的数据量
          Size: 1,
          //当前在第几页
          Index: 1
        },
        isPower: {
        		//增删改查 权限管理
        		semester_details: false,
        		semester_create: false,
        		semester_edit: false,
        		semester_delete: false,
        },
      };
    },
    methods: {
      /**
       * @description 更多操作
       * @param {Object} command
       * @param {Object} objValID
       */
      handleCommand(command, objValID) {
        let that = this;
        switch (command) {
          case 'onEdit':
            that.onEdit(objValID);
            break;
          case 'onDelete':
            that.onDelete(objValID);
            break;
        }
      },
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },
      // 查看
      onView(dataID) {
        let that = this;
        that.$router.push({
          path: "/Admin/Semester/Detail/" + dataID,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      // 新增
      onCreate() {
        let that = this;
        that.$router.push({
          path: "/Admin/Semester/Create/",
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      // 编辑
      onEdit(dataID) {
        let that = this;
        that.$router.push({
          path: "/Admin/Semester/Edit/" + dataID,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      // 删除
      onDelete(dataID)
      {
       let that = this;
       confirmDelete(that,null,function(res){
       	if(res == true)
       	{
       		let apiUrl = '/api/admin/semester/' + dataID;
       		that.fullscreenLoading = true;
       		ajaxDelete(that, apiUrl, null, function(obj) {
       		  onGetDataPage(that);
       		});
       	}
       })
      },
      onSearch(params)// 搜索
      {
      	let that = this;
      	let data = {};
      	let searchData = params;
      	searchData.forEach(element => {
      		if (element.data) {
      			data[element.zhi] = element.data;
      		}
      	});
      	data.PageNumer = 1
      	routerPath(that, "/Admin/Semester/Index", data, null, "PageSize");
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      // 分页面按钮
      pagingClick(type, val) {
        let that = this;
        let url = "/Admin/Semester/Index";
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "Name",
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "Name",
          );
        }
      },
    },
    created() {
      let that = this;
      isPower(that, "semester_details","semester_details");
      isPower(that, "semester_create","semester_create");
      isPower(that, "semester_edit","semester_edit");
      isPower(that, "semester_delete","semester_delete");
      onGetDataPage(that);
    },
    watch: {
      $route() {
        let that = this;
        onGetDataPage(that);
      }
    },
    filters:{
      // 时间格式转换
      formatDate(obj){
        return dateFormat(obj);
      }
    },
  };

  // 分页获取数据
  function onGetDataPage(that) {
    paging(
      that,
      function(r) {
        let apiUrl = "/api/admin/semester/search";
        let data = {
          PageNumer: r.PageNumer,
          PageSize: r.PageSize,
          Name: r.Name,
        };
        that.SearchList = [
          {
        	  type: "input",
        	  Name: "学期名称",
        	  data: r.Name,
        	  holder: "请输入学期关键字",
        	  zhi: "Name"
          },
        ];
        that.paging.Index=r.PageNumer;
        that.paging.Size=r.PageSize;
        that.fullscreenLoading = true;
        ajaxGet(that, apiUrl, data, function(r) {
          that.page = r;
          that.itemData = r.items;
          that.fullscreenLoading = false;
        });
      },
      "Name",
    );
  }
</script>

<style scoped="scoped">
  .navTabsCont .nav-link {
    font-size: 12px;
  }

  .navToolsCont {
    margin-top: 1px;
  }
</style>
