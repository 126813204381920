<!--
  基础数据-专业管理
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">

    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          专业信息
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <comSearch :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null">
              </comSearch>
            </li>
            <!-- <li class="nav-item">
              <el-button type="info" icon="el-icon-upload2" style="margin-right:5px;">导入</el-button>
            </li> -->
            <li class="nav-item">
              <el-button v-if="isPower.major_create == true" type="success" icon="el-icon-circle-plus-outline"
                @click="onCreate()">新增</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="item!=null && item.length!=0">
        <el-table :data="item" stripe class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="code" label="专业编号"></el-table-column>
          <el-table-column prop="name" label="专业名称"></el-table-column>
          <el-table-column prop="abbr" label="专业简介"></el-table-column>
          <el-table-column prop="college" label="年级"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <operationBtn :btnName="'查看'" @click.native="onDetail(scope.row.majorId)"></operationBtn>
              <operationBtn :btnName="'编辑'" @click.native="onEdit(scope.row.majorId)"></operationBtn>
              <operationBtn :btnName="'删除'" @click.native="onDelete(scope.row.majorId)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>

      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>

    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%" :close-on-click-modal="false"
      :close-on-press-escape="false" :append-to-body="true" :fullscreen="false">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="90px">
        <el-row v-if="isDetail!=true">
          <el-col :span="24">
            <el-form-item label="专业编号" prop="code">
              <el-input v-model="ruleForm.code"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="专业名称" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="年级" prop="college">
              <el-select v-model="ruleForm.college" placeholder="请选择年级" style="width: 100%;"
                @change="onChange(ruleForm.college)">
                <el-option v-for="item in options" :key="item.collegeId" :label="item.name" :value="item.collegeId">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="专业简介">
              <el-input type="textarea" :rows="4" show-word-limit placeholder="请输入备注" maxlength="200"
                v-model="ruleForm.abbr">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="row" v-else>
          <div class="col-lg-12 colInfo">
            <label>专业编号</label>
            <div class="colFonts">{{ruleForm.code}}</div>
          </div>
          <div class="col-lg-12 colInfo">
            <label>专业名称</label>
            <div class="colFonts">{{ruleForm.name}}</div>
          </div>
          <div class="col-lg-12 colInfo">
            <label>年级</label>
            <div class="colFonts">{{ruleForm.college}}</div>
          </div>
          <div class="col-lg-12 colInfo">
            <label>专业简介</label>
            <div class="colFonts">{{ruleForm.abbr}}</div>
          </div>
        </div>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <div v-if="isDetail!=true">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button @click="onReset()">重 置</el-button>
          <el-button type="primary" @click="onSave()">确 定</el-button>
        </div>
        <div v-else>
          <el-button @click="dialogVisible = false">关 闭</el-button>
        </div>
      </span>
    </el-dialog>
  </div>

</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import comSearch from '@/components/search.vue';
  export default {
    components: {
      operationBtn,
      comSearch,
      comPage
    },
    data() {
      return {
        //加载动画
        fullscreenLoading: false,
        //搜索
        SearchList: null,
        //分页查询接口返回的结果
        page: null,
        //待重置时的表单数据
        ruleFormReset: null,
        //列表数据
        item: [],
        //分页左侧按钮集合
        pageBtnList: null,
        //分页数据
        paging: {
          // 总共页数
          pageLength: 0,
          // 每页请求的数据量
          Size: 1,
          //当前在第几页
          Index: 1
        },
        dialogVisible: false,
        dialogTitle: "新增", //弹出框标题
        isDetail: false, //是否是查看弹出框
        ruleForm: {
          code: null,
          name: null,
          college: null,
          abbr: null,
        },
        rules: {
          code: [{
              required: true,
              message: '专业编号不能为空',
              trigger: 'blur'
            },
            {
              min: 0,
              max: 32,
              message: '专业编号不能超过32个字符',
              trigger: 'blur'
            }
          ],
          name: [{
              required: true,
              message: '专业名称不能为空',
              trigger: 'blur'
            },
            {
              min: 0,
              max: 256,
              message: '专业名称不能超过256个字符',
              trigger: 'blur'
            }
          ],
          college: [{
            required: true,
            message: '请选择专业院部',
            trigger: 'change'
          }, ]
        },
        options: [], // 下拉选择
        value: null, //存放下拉选择的值
        isPower: {
          //增删改查 权限管理
          major_details: false,
          major_create: false,
          major_edit: false,
          major_delete: false,
          major_import: false,
        },
      };
    },
    methods: {
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },
      onChange(val) {
        let that = this;
        that.ruleForm.collegeId = val;
      },
      onSearch(params) {
        let that = this;
        let data = {};
        let searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = element.data;
          }
        });
        data.PageNumer = 1;
        routerPath(that, "/Admin/Major/Index", data, null, "PageSize");
      },
      onSave() //保存（新增和编辑）
      {
        let that = this;
        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            if (that.ruleForm.majorId == null) {
              that.fullscreenLoading = true;
              ajaxPost(that, "/api/admin/major", that.ruleForm, function(r) {
                that.fullscreenLoading = false;
                that.dialogVisible = false;
                pagingPage(that);
              });
            } else {
              that.fullscreenLoading = true;
              ajaxPut(that, "/api/admin/major/" + that.ruleForm.majorId, that.ruleForm, function(r) {
                that.fullscreenLoading = false;
                that.dialogVisible = false;
                pagingPage(that);
              });
            }
          } else {

            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              isError[0].querySelector('input').focus();
            }, 1)
            return false;
          }
        });
      },
      onCreate() //新增
      {
        let that = this;
        that.dialogTitle = "新增";
        var ruleForm = JSON.parse(JSON.stringify(that.ruleForm));
        for (var i in ruleForm) {
          ruleForm[i] = null;
        }
        that.ruleForm = JSON.parse(JSON.stringify(ruleForm));
        that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
        that.dialogVisible = true;
        that.isDetail = false;
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      onDetail(Id) //查看
      {
        let that = this;
        that.fullscreenLoading = true;
        that.isDetail = true;
        ajaxGet(that, "/api/admin/major/" + Id, null, function(r) {
          that.fullscreenLoading = false;
          that.dialogTitle = "查看";
          that.ruleForm = r;
          that.dialogVisible = true;
        });
      },
      onEdit(Id) //编辑
      {
        let that = this;
        that.fullscreenLoading = true;
        that.isDetail = false;
        ajaxGet(that, "/api/admin/major/" + Id, null, function(r) {
          that.fullscreenLoading = false;
          that.dialogTitle = "编辑";
          that.ruleForm = r;
          that.dialogVisible = true;
          that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
        });

      },
      onReset() //重置
      {
        let that = this;
        this.ruleForm = JSON.parse(JSON.stringify(this.ruleFormReset));
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      onDelete(Id) //删除
      {
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, "/api/admin/major/" + Id, null, function(r) {
              that.fullscreenLoading = false;
              pagingPage(that);
            });
          }
        })
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      pagingClick(type, val) {
        let that = this;
        let url = "/Admin/Major/Index";
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "Name",

          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "Name",
          );
        }
      },
    },
    created() {
      let that = this;
      isPower(that, "major_details", "major_details");
      isPower(that, "major_create", "major_create");
      isPower(that, "major_edit", "major_edit");
      isPower(that, "major_delete", "major_delete");
      isPower(that, "major_import", "major_import");
      pagingPage(that);
    },
    watch: {
      $route() {
        let that = this;
        pagingPage(that);
      }
    }
  };


  function pagingPage(that) {
    paging(
      that,
      function(r) {
        let Myurl = "/api/admin/major/search";
        let data = {
          PageNumer: r.PageNumer,
          PageSize: r.PageSize,
          Name: r.Name,
        };

        that.SearchList = [{
          type: "input",
          Name: "专业编号",
          data: r.Name,
          holder: "请输入专业编号",
          zhi: "Name"
        }, ];
        that.paging.Index=r.PageNumer;
        that.paging.Size=r.PageSize;
        that.fullscreenLoading = true;
        ajaxGet(that, Myurl, data, function(r) {
          that.page = r;
          that.item = r.items;
          that.fullscreenLoading = false;
        });
      },
      "Name",
    );

    ajaxGet(that, "/api/admin/college", null, function(r) {
      that.options = r;
    });
  }
</script>

<style scoped="scoped">
  .navTabsCont .nav-link {
    font-size: 12px;
  }

  .navToolsCont {
    margin-top: 1px;
  }
</style>
