<!-- 成长档案 - 详情 -->

<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          基础信息
        </h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-2 text-center">
            <img :src="stuInformation.photo" alt="" class="imgAvatar">
          </div>
          <div class="col-lg-10 position-relative">
            <div class="infoList clearfix">
              <span class="spName">{{stuInformation.studentName}}</span>
              <span class="spGender">
                <svg class="icon maleColor" aria-hidden="true" v-if="onGender()">
                  <use xlink:href="#fax-mars-circle-alt"></use>
                </svg>
                <svg class="icon femaleColor" aria-hidden="true" v-else>
                  <use xlink:href="#fax-mars-circle-alt"></use>
                </svg>
              </span>
            </div>
            <!--            <div class="infoList clearfix">
              <span class="spFonts">
                民族
              </span>
              <span class="spFonts">
                政治面貌
              </span>
            </div> -->
            <div class="infoList clearfix">
              <span class="spFonts">
                <svg class="icon faxSize" aria-hidden="true">
                  <use xlink:href="#fax-font-number-o"></use>
                </svg>
                {{stuInformation.studentNo}}
              </span>
              <span class="spFonts">
                <svg class="icon faxSize" aria-hidden="true">
                  <use xlink:href="#fax-font-ban-o"></use>
                </svg>
                {{stuInformation.className}}&nbsp;/&nbsp;
              </span>
            </div>
            <div class="divReportBox divReportBoxTwo">
              <div class="divFraction">
                {{stuInformation.score}}
              </div>
              <div class="divAssessment">
                <el-tag type="success">总分数</el-tag>
              </div>
            </div>
            <div class="divReportBox">
              <div class="divFraction">
                {{stuInformation.credit}}
              </div>
              <div class="divAssessment">
                <el-tag type="success">总学分</el-tag>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-card>

    <el-card class="box-card">
      <div id="" v-if="stuInformation.reasons!=''">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            月度
          </h3>
          <div class="card-tools">
            <ul class="nav">
              <li class="nav-item">
                <el-tag type="danger">总分数</el-tag>
              </li>
            </ul>
          </div>
        </div>
        <div class="card-body">
          <el-table :data="stuInformation.reasons" border style="width: 100%">
            <el-table-column prop="reason" label="原因">
            </el-table-column>
            <el-table-column prop="score" label="分数" width="180">
            </el-table-column>
            <el-table-column prop="date" label="时间" width="180">
            </el-table-column>
            <el-table-column prop="monitor" label="执行人" width="180">
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>


    <div class="row mt-5">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  export default {
    components: {

    },
    data() {
      return {
        fullscreenLoading: false,
        stuInformation: [], //学生基础信息
        evaluations: {}, //评价结果信息
        studentId: null,
        picUrl: null,
        // itemData: {}, //获取基础学生数据

        paging: {
          totalCount: 100,
          pageSize: 10,
          currentPage: 1
        }
      };
    },
    methods: {
      //返回
      onBack() {
        let that = this;
        back(that);
      },
      //性别判断，男生:true  女生:false
      onGender(obj) {
        return obj == '男' ? true : false;
      },
    },
    created() {
      let that = this;
      let creditId = that.$route.params.Id;
  
      ajaxGet(that, '/api/admin/classcreditmonth/' + creditId, null, function(resdata) {
        
        that.stuInformation = resdata;
        that.stuInformation.photo = that.$ServerUrl() + that.stuInformation.photo;
      })
    },
  };
</script>
<style scoped="scoped">
  .turnOnModel {
    cursor: pointer;
  }

  .turnOnModel .bodyIconBox {
    height: 150px;
    width: 100%;
    overflow: hidden;
    font-size: 62px;
    color: #999999;
    position: relative;
  }

  .turnOnModel:hover .bodyIconBox {
    color: #409eff;
  }

  .turnOnModel .divCheckBox {
    font-size: 28px;
    color: #67c23a;
    position: absolute;
    z-index: 99;
    bottom: 0px;
    right: 10px;
  }

  .unopenedModel .bodyIconBox {
    height: 150px;
    font-size: 62px;
    color: #cecece;
  }

  .unopenedModel .card-title {
    color: #cecece;
  }

  .unopenedModel .divCheckBox {
    display: none;
  }

  .divReportBox {
    position: absolute;
    top: 30px;
    right: 50px;
    bottom: 0;
    display: flex;
  }

  .divReportBoxTwo {
    right: 300px;
  }

  .divReportBox .divFraction {
    font-family: 'OswaldLight';
    font-size: 62px;
    letter-spacing: 2px;
    font-weight: bold;
  }

  .divReportBox .divAssessment {
    padding-top: 15px;
    padding-left: 15px;
  }

  .nav-item {
    font-size: 12px;
    margin-top: -2px;
    cursor: pointer;
  }

  .nav-link {
    padding: 5px 10px;
  }



  .imgAvatar {
    width: 100px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }

  .infoList {
    padding-bottom: 17px;
  }

  .infoList .spName {
    font-size: 32px;
    color: #212E48;
    padding-right: 30px;
    height: 40px;
  }

  .infoList .spGender {
    font-size: 32px;
    padding-right: 30px;
    height: 40px;
  }

  .infoList .spLabel {
    height: 40px;
  }

  .infoList .spLabel label {
    position: absolute;
    top: 8px;
    background-color: #ff9900;
    border: 1px solid #f29100;
    border-radius: 5px;
    font-weight: normal;
    color: #FFFFFF;
    padding: 2px 10px;
    margin-bottom: 0;
  }

  .infoList .spFonts {
    color: #3B475D;
    padding-right: 30px;
    padding-left: 30px;
    border-right: 1px solid #D6D6D6;
  }

  .infoList .spFonts:first-child {
    padding-left: 0;
  }

  .infoList .spFonts:last-child {
    border-right: none;
  }

  .infoList .spMedal {
    padding-right: 15px;
  }

  .infoList .spFonts .faxSize {
    font-size: 18px;
  }

  .infoList .spFonts .icon {
    color: #B1B1B1;
  }
</style>
