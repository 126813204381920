<!--
 ECHARTS 的 雷达图
-->
<template>
  <!-- 250px -->
  <div :id="comID" :style="pieStyle"></div>
</template>

<script>
  import 'echarts/lib/component/tooltip';
  import 'echarts/lib/component/legend';

  // 引入基本模板
  let echarts = require('echarts/lib/echarts');
  // 引入柱状图组件
  require('echarts/lib/chart/radar');
  export default {
    name: 'report_radar',
    props: {
      comID: {
        type: String,
        default: 'myChart'
      },
      comHeight: {
        type: Number,
        default: 250
      },
      comData: {
        type: Array,
        default: () => {
          return [];
        }
      }
    },
    data() {
      return {
        pieStyle: '',
        myChart: {},
        arrData: [],
      };
    },
    watch: {
      'arrData': {
        handler: function(newVar, oldVar) {
          this.arrData = newVar;
        },
        deep: true
      },
    },
    created() {
      let that = this;
      if (that.comData)
        that.arrData = that.comData;
      that.$nextTick(() => {
        that.drawLine();
      });
      that.pieStyle = 'width: 100%; height:' + (that.comHeight * 0.0625) + 'rem;';
    },
    mounted() {
      let that = this;
      window.onresize = function() {
        that.myChart.resize();
        that.pieStyle = 'width: 100%; height:' + (that.ChartHeight * 0.0625) + 'rem;';
      }
    },
    methods: {
      // 生成
      onGetRadar(_data) {
        let that = this;
        that.arrData = _data;
        that.$nextTick(() => {
          that.drawLine();
        })
        that.pieStyle = 'width: 100%; height:' + (that.comHeight * 0.0625) + 'rem;';

      },
      // 绘画雷达图
      drawLine() {
        let that = this,
          arrTitle = [],
          arrClass = [],
          arrOneself = [];
        // 数据处理

        for (let item of that.arrData) {
          arrTitle.push({
            name: item.name,
            max: item.ceiling == 0 ? (item.score + item.baseScore) + 50 : item.ceiling
          });
          // 判断是否有上限分
          if (item.ceiling == 0) {
            arrOneself.push(item.score + item.baseScore);
          } else {
            // 判断学生总分是否超过上限分
            if ((item.score + item.baseScore) >= item.ceiling) {
              arrOneself.push(item.ceiling);
            } else {
              arrOneself.push(item.score + item.baseScore);
            }
          }
        }


        // 基于准备好的dom，初始化echarts实例
        that.myChart = echarts.init(document.getElementById(that.comID));

        // 绘制图表
        that.myChart.setOption({
          tooltip: {
            padding: [10, 10],
          },
          // legend: {
          //   data: ['班级中位分', '学生个人分'],
          //   bottom: 0,
          // },
          // color: ['#C13531', '#1E90FF'],
          color: ['#1E90FF'],
          radar: {
            center: ["50%", "50%"],
            radius: '60%',
            shape: 'circle',
            // shape: 'polygon',
            name: {
              textStyle: {
                color: '#fff',
                backgroundColor: '#999',
                borderRadius: 3,
                padding: [5, 10],
                fontSize: 16
              },
            },
            indicator: arrTitle
          },
          series: [{
            name: '总评',
            type: 'radar',
            data: [
              // {
              //   value: arrClass,
              //   name: '班级中位分',
              // },
              {
                value: arrOneself,
                name: '个人综合分',
                label: {
                  show: true,
                  formatter: function(params) {
                    return params.value;
                  }
                }
              },

            ],
            areaStyle: {
              color: new echarts.graphic.RadialGradient(0.6, 0.6, 1, [{
                  color: 'rgba(38, 111, 188, 0.3)',
                  offset: 0
                },
                {
                  color: 'rgba(40, 63, 151, 0.9)',
                  offset: 1
                }
              ])
            },
          }]
        });
      }
    }
  };
</script>

<style>

</style>
