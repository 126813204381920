<!--
  基础信息-学年信息
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <search :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null"></search>
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          门禁组信息
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <el-button v-if="isPower.admin_personnelgroup_create == true" type="success"
                icon="el-icon-circle-plus-outline" @click="onCreate()">新增</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table :data="itemData" stripe class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="groupId" label="门禁组Id" class="elColumn"></el-table-column>
          <el-table-column prop="groupName" label="门禁组名称" class="elColumn"></el-table-column>
          <el-table-column prop="groupTypeStr" label="门禁组类型" class="elColumn"></el-table-column>
          <el-table-column prop="description" label="备注" class="elColumn"></el-table-column>
          <el-table-column prop="groupTypeStr" label="是否默认" class="elColumn">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.isDefault" disabled>
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="240">
            <template slot-scope="scope">
              <operationBtn v-if="isPower.admin_personnelgroup_details == true" :btnName="'编辑'"
                @click.native="onEdit(scope.row.groupId)"></operationBtn>
              <operationBtn v-if="isPower.admin_personnelgroup_details == true" :btnName="'删除'"
                @click.native="onDelete(scope.row.groupId)"></operationBtn>
                <operationBtn v-if="isPower.admin_personnelgroup_default == true && scope.row.groupType == 4 && !scope.row.isDefault"
                  :btnName="'设为默认'" @click.native="onDefault(scope.row.groupId)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>

    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%" :close-on-click-modal="false"
      :close-on-press-escape="false" :append-to-body="true" :fullscreen="false">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="90px" label-position="top">
        <el-row>
          <el-col :span="24">
            <el-form-item label="门禁组Id" prop="groupId">
              <el-input v-model="ruleForm.groupId" placeholder="请输入门禁组Id"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="门禁组名称" prop="groupName">
              <el-input v-model="ruleForm.groupName" placeholder="请输入门禁组名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="门禁组类型" prop="groupType">
              <el-select v-model="ruleForm.groupType" placeholder="请选择门禁组类型" style="width: 100%;">
                <el-option v-for="item in optType" :key="item.Value" :label="item.Title" :value="item.Value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注" >
              <el-input v-model="ruleForm.description" type="textarea" placeholder="请输入备注"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <div v-if="isDetail!=true">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button @click="onReset()">重 置</el-button>
          <el-button type="primary" @click="onSave()">确 定</el-button>
        </div>
        <div v-else>
          <el-button @click="dialogVisible = false">关 闭</el-button>
        </div>
      </span>
    </el-dialog>

  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';

  export default {
    components: {
      comPage,
      operationBtn,
      search
    },
    data() {
      return {
        //加载动画
        fullscreenLoading: false,
        //搜索
        SearchList: null,
        //分页查询接口返回的结果
        page: null,
        //待重置时的表单数据
        ruleFormReset: null,
        //列表数据
        itemData: [],
        //分页左侧按钮集合
        pageBtnList: null,
        //分页数据
        paging: {
          // 总共页数
          pageLength: 0,
          // 每页请求的数据量
          Size: 1,
          //当前在第几页
          Index: 1
        },
        dialogVisible: false,
        dialogTitle: "新增", //弹出框标题
        isDetail: false, //是否是查看弹出框
        ruleForm: {
          name: null, //学年名称
          year: null,
        },
        rules: {
          groupId: [{
            required: true,
            message: '请输入门禁组id',
            trigger: 'change'
          }],
          groupName: [{
            required: true,
            message: '请输入门禁组名称',
            trigger: 'change'
          }],
          groupType: [{
            required: true,
            message: '请选择门禁组类型',
            trigger: 'change'
          }],
        },
        isCreate:false,
        isPower: {
          //增删改查 权限管理
          admin_personnelgroup_details: false,
          admin_personnelgroup_create: false,
          admin_personnelgroup_edit: false,
          admin_personnelgroup_delete: false,
          admin_personnelgroup_default: false,
        },
        optType: [],
      };
    },
    methods: {
      /**
       * @description 更多操作
       * @param {Object} command
       * @param {Object} objValID
       */
      handleCommand(command, objValID) {
        let that = this;
        switch (command) {
          case 'onEdit':
            that.onEdit(objValID);
            break;
          case 'onDelete':
            that.onDelete(objValID);
            break;
        }
      },
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },
      onSave() //保存（新增和编辑）
      {
        let that = this;
        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            that.ruleForm.groupType = parseInt(that.ruleForm.groupType);
            if (that.isCreate) {
              that.fullscreenLoading = true;
              ajaxPost(that, "/api/admin/dumugroup", that.ruleForm, function(r) {
                that.fullscreenLoading = false;
                that.dialogVisible = false;
                pagingPage(that);
              });
            } else {
              that.fullscreenLoading = true;
              ajaxPut(that, "/api/admin/dumugroup/" + that.ruleForm.groupId, that.ruleForm, function(r) {

                that.fullscreenLoading = false;
                that.dialogVisible = false;
                pagingPage(that);
              });
            }
          } else {

            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              isError[0].querySelector('input').focus();
            }, 1)
            return false;
          }
        });
      },
      onCreate() //新增
      {
        let that = this;
        that.dialogTitle = "新增";
        var ruleForm = JSON.parse(JSON.stringify(that.ruleForm));
        for (var i in ruleForm) {
          ruleForm[i] = null;
        }
        that.isCreate = true;
        that.ruleForm = JSON.parse(JSON.stringify(ruleForm));
        that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
        that.dialogVisible = true;
        that.isDetail = false;
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      onDetail(Id) //查看
      {
        let that = this;
        that.fullscreenLoading = true;
        that.isDetail = true;
        ajaxGet(that, "/api/admin/dumugroup/" + Id, null, function(r) {
          that.fullscreenLoading = false;
          that.dialogTitle = "查看";
          that.ruleForm = r;
          that.dialogVisible = true;
        });
      },
      onEdit(Id) //编辑
      {
        let that = this;
        that.fullscreenLoading = true;
        that.isDetail = false;
        that.isCreate = false;
        ajaxGet(that, "/api/admin/dumugroup/" + Id, null, function(r) {
          that.fullscreenLoading = false;
          that.dialogTitle = "编辑";
          that.ruleForm = r;
          that.ruleForm.groupType = that.ruleForm.groupType.toString();
          that.dialogVisible = true;
          that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
        });
      },
      onSearch(params) // 搜索
      {
        let _this = this;
        let data = {};
        let searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = element.data;
          }
        });
        data.PageNumer = 1;
        routerPath(_this, "/Admin/PersonnelGroup/Index", data, null, "PageSize");
      },
      onReset() //重置
      {
        let that = this;
        this.ruleForm = JSON.parse(JSON.stringify(this.ruleFormReset));
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },

      onDefault(Id){
        let that = this;
        confirmDelete(that, "请确定是否将此门禁组设为默认", function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxPut(that, "/api/admin/dumugroup/defult?id="+Id,null, function(r) {
              that.fullscreenLoading = false;
              pagingPage(that);
            });
          }
        })
      },
      onDelete(Id) //删除
      {
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, "/api/admin/dumugroup/" + Id, null, function(r) {
              that.fullscreenLoading = false;
              pagingPage(that);
            });
          }
        })
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      // 分页面按钮
      pagingClick(type, val) {
        let that = this;
        let url = "/Admin/PersonnelGroup/Index";
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "GroupName",
            "GroupType",
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "GroupName",
            "GroupType",
          );
        }
      }
    },
    created() {
      let that = this;
      isPower(that, "admin_personnelgroup_details", "admin_personnelgroup_details");
      isPower(that, "admin_personnelgroup_create", "admin_personnelgroup_create");
      isPower(that, "admin_personnelgroup_edit", "admin_personnelgroup_edit");
      isPower(that, "admin_personnelgroup_delete", "admin_personnelgroup_delete");
      isPower(that, "admin_personnelgroup_default", "admin_personnelgroup_default");
      ajaxGet(that, '/api/admin/dumugroup/grouptype', null, function(res) {
        for (var it of res) {
          that.optType.push({
            Value: it.Value,
            Title: it.Text
          })
        }
        pagingPage(that);
      })
    },
    watch: {
      $route() {
        let that = this;
        pagingPage(that);
      }
    },
    filters: {
      flGrade(obj) {
        let gradeYear = new Date().getFullYear() + 1;
        return gradeYear - obj;
      },
    }
  };

  function pagingPage(that) {
    paging(
      that,
      function(r) {
        let data = {
          PageNumer: r.PageNumer,
          PageSize: r.PageSize,
          GroupName: r.GroupName,
          GroupType: r.GroupType,
        };
        that.SearchList = [{
          type: "input",
          Name: "门禁组名称",
          data: r.GroupName,
          holder: "请输入门禁组名称",
          zhi: "GroupName"
        }, {
          type: "select",
          Name: "门禁组类型",
          data: r.GroupType,
          select: that.optType,
          holder: "请选择门禁组类型",
          zhi: "GroupType"
        }, ];
        that.paging.Index = r.PageNumer;
        that.paging.Size = r.PageSize;
        that.fullscreenLoading = true;
        ajaxGet(that, "/api/admin/dumugroup/search", data, function(r) {
          that.page = r;
          that.itemData = r.items;
          that.fullscreenLoading = false;
        });
      },
      "GroupName",
      "GroupType",
    );
  }
</script>

<style scoped="scoped">

</style>
