<!--
  综合活动 - 确认名单
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <search :SearchTitles="SearchTitles" :ColNumber="3" :SearchList="SearchList" ref="SearchList" @onSearch="onSearch"
      v-if="SearchList!=null"></search>
    <!-- 列表-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          {{Title}}
        </h3>
        <div class="card-tools">
          <comImport v-if="ImportFile!=null && ImportUrl!=null" @onImportSuccess="onImportSuccess" :title="ImportTitle"
            :template="ImportFile" :path="ImportUrl" ref="conImportFile" :btnSize="'small'" :btnName="'导入名单'">
          </comImport>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length>0">
        <div class="text item">
          <el-table :data="itemData">
            <el-table-column align="center" type="index" width="100px;" label="序号"></el-table-column>
            <el-table-column align="center" prop="className" label="班级" min-width="120"></el-table-column>
            <el-table-column align="center" prop="studentNo" label="学号" min-width="120">
              <template slot-scope="scope">
                <span v-if="scope.row.studentNo == null || scope.row.studentNo == ''">/</span>
                <span v-else>{{scope.row.studentNo}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="学生" width="120">
              <template slot-scope="scope">
                <span v-if="scope.row.studentName == null || scope.row.studentName == ''">/</span>
                <span v-else>{{scope.row.studentName}}</span>
              </template>
            </el-table-column>
            <el-table-column align="left" label="上传（点击图片浏览）" min-width="400">
              <template slot-scope="scope">
                <el-image v-for="(item,index) in scope.row.uploadFild" fit="contain" :key="index"
                  style="width: 50px; height: 50px;marginLeft:10px" :src="item"
                  :preview-src-list="scope.row.uploadFild">
                </el-image>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="status" label="审核状态" min-width="200px" fixed="right">
              <template slot-scope="scope">
                <div>
                  <operationBtn @click.native="handleAuditStatus(scope.row,true)" btnType="success" :btnName="'通过'">
                  </operationBtn>
                  <operationBtn @click.native="handleAuditStatus(scope.row,false)" btnType="danger" :btnName="'不通过'">
                  </operationBtn>
                </div>
                <el-radio-group v-model="scope.row.isAudit" v-if="false">
                  <el-radio :label="true" :disabled="scope.row.scoring">通过</el-radio>
                  <el-radio :label="false" :disabled="scope.row.scoring">不通过</el-radio>
                </el-radio-group>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <comPage :paging="paging" @pagingClick="pagingClick" v-if="false">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>
    <div class="row" style="margin-top: 10px;">
      <div class="col-6">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
        <el-button v-if="false" slot="reference" type="primary" icon="el-icon-circle-check" @click="onSave()">提交
        </el-button>
      </div>
      <div class="col-6 text-right" v-if="false">
        <el-button @click="onCheckAll">
          <svg class="icon" aria-hidden="true" v-if="iconAllCheck">
            <use xlink:href="#fax-circle-check"></use>
          </svg>
          <svg class="icon" aria-hidden="true" v-else>
            <use xlink:href="#fax-circle-o"></use>
          </svg>
          全部通过
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import comImport from '@/components/Import.vue';
  import search from '@/components/search.vue';
  import operationBtn from '@/components/operationBtn.vue';
  export default {
    components: {
      comPage,
      comImport,
      search,
      operationBtn
    },
    data() {
      return {
        // 用于全选状态
        iconAllCheck: false,
        itemData: [],
        dialogTitle: '',
        dialogVisible: false,
        SearchTitles: [],
        SearchList: null, //搜索集合
        id: null,
        fullscreenLoading: false,
        Title: null,
        ImportTitle: null,
        ImportUrl: null,
        ImportFile: null,
        paging: { //分页数据
          totalCount: 0, // 总共页数
          pageSize: 10, // 当前请求数量
          currentPage: 1 //当前在第几页
        },
      };
    },
    created() {
      let that = this;
      that.id = that.$route.params.Id;
      that.Title = '活动附件审核'
      if (that.id != null && typeof(that.id) != "undefined" && that.id != "") {
        Init(that)
      }
    },

    methods: {
      handleAuditStatus(val, falg) {
        let that = this;
        let msg = falg ? '确定要审核通过吗?' : '您确定要拒绝该审核吗?';
        confirm(that, msg, function(res) {
          if (res) {
            let url = '/api/admin/activity/attachaudit/' + val.rosterId + '?auditResult=' + falg;
            that.fullscreenLoading = true
            ajaxPut(that, url, null, function(r) {
              that.fullscreenLoading = false
              pagingPage(that);
            })
          }
        })
      },
      onCheckAll(val) {
        this.iconAllCheck = !this.iconAllCheck
        if (this.iconAllCheck) {

        }
      },
      onImportSuccess(param) {
        let that = this;
        Init(that);
      },
      onSave() {

      },
      onBack() {
        let that = this;
        back(that);
      },
      onSearch(params) {

      },
      pagingClick(type, val) {
        if (type == 1) {

        } else {

        }
      },
    },
    watch: {
      $route() {
        let that = this;
        Init(that);
      },
    },
  };

  function Init(that) {
    pagingPage(that);
  }

  function pagingPage(that) {
    that.fullscreenLoading = true
    ajaxGet(that, `/api/admin/activity/roster/${that.id}`, null, function(res) {
      that.fullscreenLoading = false
      that.itemData = res.filter(item => (item.attachment && item.attachAudit == null)).map(it => {
        it.uploadFild = it.attachment.split('|').map(cur => `${that.$ServerUrl()}${cur}`)
        it.isAudit = false
        return it
      })
      that.paging.totalCount = that.itemData.length
    });
  }
</script>


<style scoped="scoped">
  /deep/ .el-table th>.cell {
    text-align: center;
  }
</style>
