<!--
  学生管理-学生奖励
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <search :ColNumber="2" :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null">
    </search>
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          班主任学年考核
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <el-button type="success" size="mini" icon="el-icon-circle-plus-outline" @click="onCreate" v-if="isPower.admin_assessment_academic_craete">新增</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData!=null && itemData.length!=0">
        <el-table stripe :data="itemData" class="elTableData">
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod" fixed="left">
          </el-table-column>
          <el-table-column prop="teacher" label="班主任" min-width="100" fixed="left"></el-table-column>
          <el-table-column prop="className" label="班级" min-width="100" fixed="left"></el-table-column>
          <el-table-column prop="resultStr" label="结果" min-width="300"></el-table-column>
          <el-table-column prop="year" label="学年" min-width="100"></el-table-column>
          <el-table-column label="操作" width="200" fixed="right">
            <template slot-scope="scope">
              <operationBtn :btnName="'查看'" @click.native="onDetail(scope.row.assessmentId)" v-if="isPower.admin_assessment_academic_detail"></operationBtn>
              &nbsp;
              <el-dropdown v-if="isPower.admin_assessment_academic_edit || isPower.admin_assessment_academic_delete" trigger="click"
                @command="handleCommand($event,scope.row.assessmentId)">
                <el-button size="mini">
                  更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="isPower.admin_assessment_academic_edit" class="color-warning"
                    command="onEdit">编辑</el-dropdown-item>
                  <el-dropdown-item divided v-if="isPower.admin_assessment_academic_delete" class="color-danger"
                    command="onDelete">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>




  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';

  export default {
    name: '',
    components: {
      comPage,
      operationBtn,
      search
    },
    data() {
      return {
        fullscreenLoading: false,
        dialogTitle: '',
        dialogVisible: false,
        page: null, //分页查询接口返回的结果
        itemData: [], //列表数据
        pageBtnList: null, //分页左侧按钮集合
        paging: { //分页数据
          pageLength: 0, // 总共页数
          Size: 10, // 当前请求数量
          Index: 1 //当前在第几页
        },
        SearchList: null, //搜索集合
        isPower:{
          admin_assessment_academic_edit:false,
          admin_assessment_academic_detail:false,
          admin_assessment_academic_craete:false,
          admin_assessment_academic_delete:false,
        }
      };
    },
    created() {
      let that = this;
      isPower(that,'admin_assessment_academic_edit','admin_assessment_academic_edit');
      isPower(that,'admin_assessment_academic_detail','admin_assessment_academic_detail');
      isPower(that,'admin_assessment_academic_craete','admin_assessment_academic_craete');
      isPower(that,'admin_assessment_academic_delete','admin_assessment_academic_delete');
      pagingPage(that);
    },
    methods: {
      /**
       * @description 更多操作
       * @param {Object} command
       * @param {Object} objValID
       */
      handleCommand(command, objValID) {
        let that = this;
        switch (command) {
          case 'onEdit':
            that.onEdit(objValID);
            break;
          case 'onDelete':
            that.onDelete(objValID);
            break;
        }
      },
      /**
       * @description 新增
       */
      onCreate() {
        let that = this;
        that.$router.push({
          path: "/Admin/Assessment/AcademicYear/Create",
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },

      /**
       * @description 详情
       * @param {Object} Id
       */
      onDetail(Id) {
        let that = this;
        that.$router.push({
          path: "/Admin/Assessment/AcademicYear/Details/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      /**
       * @description 编辑
       * @param {Object} Id
       */
      onEdit(Id) {
       let that = this;
       that.$router.push({
         path: "/Admin/Assessment/AcademicYear/Edit/" + Id,
         query: {
           url: that.$router.history.current.fullPath
         }
       });
      },
      /**
       * @description 删除
       * @param {Object} objID
       */
      onDelete(objID){
        let that = this;
        confirmDelete(that,null , function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, "/api/admin/adviseryearassessment/" + objID, null, function(r) {

              that.fullscreenLoading = false;
               pagingPage(that);
            });
          }
        })

      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      onSearch(params) {
        let that = this,
          data = {},
          searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = Join(element.data);
          }
        });
        data.PageNumer = 1;
        routerPath(that, "/Admin/Assessment/AcademicYear/Index", data, null, "PageSize");
      },
      pagingClick(type, val) {
        let that = this;
        let url = 'Admin/Assessment/AcademicYear/Index';
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "yearId",
            "teacher",
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "yearId",
            "teacher",
          );
        }
      },
    },
    watch: {
      $route() {
        let that = this;
        pagingPage(that);
      }
    }
  };

  function pagingPage(that) {
    paging(
      that,
      function(res) {
        ajaxGet(that,'/api/admin/academic',null,function(resacademic){
          let yearlist=[];
          for(var it of resacademic)
          {
            yearlist.push({
              Value:it.yearId,
              Title:it.name,
            })
          }
          let yearId=res.yearId;
        that.SearchList = [];
        that.SearchList.push(
        {
          type: "select",
          Name: "学年",
          data: yearId,
          select: yearlist,
          zhi: "yearId",
          isClear: false, //select是否可以清除
        }, {
          type: "input",
          Name: "班主任",
          data: res.teacher,
          holder: "请输入班主任姓名",
          zhi: "teacher"
        });

        let tempData={
          PageNumer: res.PageNumer,
          PageSize: res.PageSize,
          YearId:res.yearId,
          TeacherName:res.teacher,
        }
        that.itemData=[];
        ajaxGet(that,'/api/admin/adviseryearassessment/search',tempData,function(resData){
          that.fullscreenLoading = false;
          if (!regNullArray(resData.items)) {

            that.page = resData;
            that.itemData = resData.items;
          }
        });
        })
      },
      "yearId",
      "teacher",
    );
  }
</script>

<style scoped="scoped">
</style>
