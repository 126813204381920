<!--
  新增家校联系
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="ruleForm" ref="ruleForm" label-width="120px">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            查看
          </h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-4 colInfo">
              <label>学期</label>
              <div class="colFonts">{{ruleForm.semester | flNoValue}}</div>
            </div>
            <div class="col-lg-4 colInfo">
              <label>班级</label>
              <div class="colFonts">{{ruleForm.class | flNoValue}}</div>
            </div>
            <div class="col-lg-4 colInfo">
              <label>学生</label>
              <div class="colFonts">{{ruleForm.studentName | flNoValue}}</div>
            </div>
          </div>
        </div>
      </el-card>

      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            主要偏常行为
          </h3>
        </div>
        <div class="card-body">
          <div class="colRemarksCont" v-html="ruleForm.abnormalBehavior"></div>
        </div>
      </el-card>

      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            主要优点
          </h3>
        </div>
        <div class="card-body">
          <div class="colRemarksCont" v-html="ruleForm.merits"></div>
        </div>
      </el-card>

      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            家庭情况简要
          </h3>
        </div>
        <div class="card-body">
          <div class="colRemarksCont" v-html="ruleForm.familySituation"></div>
        </div>
      </el-card>
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            辅导记录
          </h3>
        </div>
        <div class="card-body" v-for="(item,index) in ruleForm.abnormalTutors" :key="index">
          <div class="colRemarksCont" v-html="item.tutorSituation"></div>
        </div>
      </el-card>
      <div class="row">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';


  export default {
    components: {

    },
    data() {
      return {
        fullscreenLoading: false,
        ruleForm: {},
      };
    },
    created() {
      let that = this;
      let id = that.$route.params.Id;
      that.ruleForm.abnormalId = id;
      that.fullscreenLoading = true;
      ajaxGet(that, "/api/admin/adviserabnormalbehavior/" + id, null, function(res) {
        that.fullscreenLoading = false;
        that.ruleForm = res;

      })
      that.fullscreenLoading = true;
    },

    mounted() {

    },
    methods: {
      onBack() {
        let that = this;
        back(that);
      },
    },
    watch: {

    },
    filters: {
      flNoValue(obj) {
        if (obj == null || typeof(obj) == "undefined" || obj == '') {
          return '暂无数据';
        }
        return obj;
      }
    },
  };
</script>

<style scoped="scoped">

</style>
