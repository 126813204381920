<!--
  房源管理 - 新增
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">

    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{pageTitle}}
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="宿舍楼名" prop="name">
                <el-input v-model="ruleForm.name" placeholder="请输入宿舍楼名" style="width: 70%;"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="楼层数量" prop="floor">
                <el-input v-model="ruleForm.floor" placeholder="请输入楼层数量" style="width: 70%;"
                  :disabled="ruleForm.isFloor"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- <el-form-item label="允许性别">
                <el-switch v-model="ruleForm.genderId" v-if="ruleForm.optGender.length!=0"
                  :active-text="ruleForm.optGender[0].name" :inactive-text="ruleForm.optGender[1].name"
                  class="formControl" :active-value="ruleForm.optGender[0].genderId"
                  :inactive-value="ruleForm.optGender[1].genderId">
                </el-switch>
              </el-form-item> -->
              <el-form-item label="允许性别">
                <el-radio-group v-model="ruleForm.genderId" v-if="ruleForm.optGender.length!=0">
                  <el-radio :label="option.genderId" v-for="option in ruleForm.optGender" :key="option.genderId">
                    {{ option.name }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="启用状态">
                <el-switch v-model="ruleForm.enable" class="formControl">
                </el-switch>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="优先级" prop="priority">
                <el-input v-model="ruleForm.priority" placeholder="请输入优先级"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="宿舍楼描述" prop="description">
                <el-input type="textarea" v-model="ruleForm.description" :autosize="{ minRows: 5}" maxlength="500"
                  show-word-limit>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>
    </el-form>






    <!--宿舍楼相关图片-->
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          宿舍楼相关图片
        </h3>
      </div>
      <div class="card-body p-5">
        <comUpload :key="random" @onPictureHandleSuccess="onPictureHandleSuccess" :files="uploadFiles"></comUpload>
      </div>
      <!-- <div class="card-body p-4">
        <div class="mt-4">
          <comUpload :key="random" @onPictureHandleSuccess="onPictureHandleSuccess" :files="uploadFiles"
            :pictureMaxCount="1" v-if="uploadFiles.length!=0"></comUpload>
        </div>
      </div> -->
    </el-card>




    <div class="row">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
        <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
        <el-button type="primary" icon="el-icon-circle-check" @click="onVerification('ruleForm')">{{btnTitle}}
        </el-button>
      </div>
    </div>





  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comUpload from '@/components/Upload.vue';

  export default {
    components: {
      comUpload
    },
    data() {
      return {
        // 加载动画
        fullscreenLoading: false,
        // 标题
        pageTitle: '新增宿舍楼信息',
        btnTitle: '下一步，完成房间信息',
        // 表单数据
        ruleForm: {
          buildingId: '',
          name: '', // 楼名
          genderId: '', // 性别ID
          optGender: [],
          enable: true, // 是否启用
          photo: '', // 照片
          description: '', // 描述
          floor: '', // 层数
          isFloor: false,
          priority:'',
        },
        random: 0, //刷新组件

        // 表单验证
        rules: {
          name: [{
              required: true,
              message: '宿舍楼名不能为空',
              trigger: 'change'
            },
            {
              max: 20,
              message: '长度在 20 个字符内',
              trigger: 'change'
            },
          ],
          floor: [{
              required: true,
              message: '楼层数量不能为空',
              trigger: 'change'
            },
            {
              validator: checkInt,
              trigger: 'change'
            }
          ],
          priority: [{
            validator: (rule, value, callback) => {
              let that = this;
              var reg = /^-?[1-9]\d*$/;
              if (value != 0 && !reg.exec(value)) {
                callback(new Error("优先级只能输入整数！"));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }],
        },
        // 重置表单
        ruleFormReset: null,
        // 存放图片
        uploadFiles: [],


      };
    },
    methods: {
      // 上传图片获取图片地址
      onPictureHandleSuccess(param) {
        let _this = this;
        _this.ruleForm.photo = "";
        for(var i in param)
        {
        	if(_this.ruleForm.photo == "")
        	{
        		_this.ruleForm.photo = param[i].path;
        	}
        	else{
        		_this.ruleForm.photo += "|" + param[i].path;
        	}
        }
      },
      // 返回
      onBack() {
        let _this = this;
        back(_this);
      },
      // 重置
      onReset() {
        let _this = this;
        _this.ruleForm = JSON.parse(JSON.stringify(_this.ruleFormReset));
        try {
          _this.$refs["ruleForm"].resetFields();
        } catch {}
      },
      // 验证
      onVerification(_formType) {
        let _this = this;
        _this.$refs[_formType].validate((valid) => {
          if (valid) {
            if(regNull(_this.ruleForm.priority)){
              _this.ruleForm.priority = 0;
            }
            if(_this.ruleForm.isFloor){
              _this.onEditData(_this);
            }else{
              _this.onCreateData(_this);
            }
          } else {
            return false;
          }
        });
      },
      // 新增宿舍楼
      onCreateData(_this) {
        _this.fullscreenLoading = true;
        ajaxPost(_this, '/api/admin/building', _this.ruleForm, function(res) {

            _this.onGoRoom(_this,res.message);
          _this.fullscreenLoading = false;
        });
      },
      // 编辑宿舍楼
      onEditData(_this){
        let apiUrl='/api/admin/building/'+_this.ruleForm.buildingId;
        _this.fullscreenLoading=true;
        ajaxPut(_this,apiUrl,_this.ruleForm,function(res){
          _this.onBack();
          _this.fullscreenLoading = false;
        });
      },
      // 加载性别数据
      onGetGender(_this) {
        _this.fullscreenLoading = true;
        ajaxGet(_this, '/api/admin/gender', null, function(resData) {
          _this.ruleForm.optGender = resData;
          _this.ruleForm.optGender.push({
            name: '混寝',
            genderId: null
          });
          _this.fullscreenLoading = false;
        });
      },
      // 获取需要编辑的信息
      onGetBuildingInfo(_this, _dataID) {
        let apiUrl = '/api/admin/building/' + _dataID;
        _this.fullscreenLoading = true;
        ajaxGet(_this, apiUrl, null, function(resData) {
          ajaxGet(_this, '/api/admin/floor/building/' + _dataID, null, function(resFloor) {
            _this.ruleForm.isFloor = true;
            _this.ruleForm.floor = resFloor.length;
            _this.ruleForm.buildingId = resData.buildingId;
            _this.ruleForm.name = resData.name;
            _this.ruleForm.genderId = resData.genderId;
            _this.ruleForm.enable = resData.enable;
            _this.ruleForm.photo = resData.photo;
            _this.ruleForm.description = resData.description;
            _this.ruleForm.priority = resData.priority;
            _this.fullscreenLoading = false;
            _this.uploadFiles=[];
            if(_this.ruleForm.photo!=null && typeof(_this.ruleForm.photo)!="undefined" && _this.ruleForm.photo!="")
            {
              var files = _this.ruleForm.photo.split('|');
              for(var i in files)
              {
              	var it = i + 1;
              	_this.uploadFiles.push({
              		uid: it,
              		path: files[i],
              	});
              }
              var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
              _this.random += random;
            }

            _this.ruleFormReset = JSON.parse(JSON.stringify(_this.ruleForm));
          });
        });
      },
      // 到房间信息页面
      onGoRoom(_this,_dataID)
      {
        _this.$router.push({
          path: "/Admin/Dorm/Room/"+_dataID,
          query: {
            url: _this.$router.history.current.fullPath
          }
        });
      },
    },
    created() {
      let _this = this,
        dataID = _this.$route.params.id;
      _this.ruleFormReset = JSON.parse(JSON.stringify(_this.ruleForm));
      if (dataID != null && typeof(dataID) != "undefined" && dataID != "") {
        _this.pageTitle = '编辑宿舍楼信息';
        _this.btnTitle = '提交'
        _this.onGetBuildingInfo(_this, dataID);
      }
      _this.onGetGender(_this);
    },


  };


  // 验证身份证号
  let checkInt = (rule, value, callback) => {
    if (regInt(value)) {
      callback(new Error("请输入正整数！"));
    } else {
      callback();
    }
  };
</script>

<style scoped="scoped">
  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }
</style>
