<template>
  <div class="bottom-right-table-3">
    <dv-border-box-12 class="dvBox">
      <div class="divTabs" v-if="!regNullArray(optType)">
        <span v-for="(item,index) in optType" :key="index+Math.random()" :class="{spAct:currentTabs==index}"
          @click="onTabs(index)">{{item.configName}}排行榜</span>
      </div>
      <div class="divItemBox" v-if="!regNullArray(optData)">
        <div class="divList" v-for="(item,index) in optData.slice(0,3)" :key="index+Math.random()">
          <div class="divListItem rightB" v-for="(list,indexTwo) in item.data.slice(0,5)" :key="indexTwo+Math.random()">
            <div class="divIcon">
              <span class="spRank" v-if="list.ranking==1">
                <img src="@/assets/image/icon/no_1.png" class="iconImg">
              </span>
              <span class="spRank" v-else-if="list.ranking==2">
                <img src="@/assets/image/icon/no_2.png" class="iconImg">
              </span>
              <span class="spRank" v-else-if="list.ranking==3">
                <img src="@/assets/image/icon/no_3.png" class="iconImg">
              </span>
              <span class="spRankOther" v-else>
                {{list.ranking}}
              </span>
            </div>
            <div class="divinfo">
              <div class="divName">{{list.studentName}}</div>
              <div class="divClass">{{list.className}}</div>
            </div>
            <div class="divTotal">
              <span>{{list.score | flScore}}</span>
              分
            </div>
          </div>
        </div>
      </div>
      <div class="divNullData" v-else>
        <dv-loading>Loading...</dv-loading>
      </div>
    </dv-border-box-12>
  </div>
</template>

<script>
  export default {
    name: 'BottomRightTable3',
    data() {
      return {
        optType: [],
        currentTabs: 0,
        optData: [],
        optAllData: [],
        tabTtimer:null,
        iCount:0,
      }
    },
    mounted() {
      let that = this;
      that.tabTtimer = setInterval(() => {
        if(that.iCount>=that.optType.length-1){
          that.iCount=0;
          that.onTabs(that.iCount);
        }else{
          that.iCount+=1;
          that.onTabs(that.iCount);
        }
      }, 10000);
    },
    methods: {
      /**
       * @description 加载数据
       * @param {Object} objData
       */
      onCreateData(objData) {
        let that = this;
        if (!regNullArray(objData)) {
          that.optType = objData.map(item => ({
            configName: item.configName,
            configId: 0,
          }));
          that.optAllData = JSON.parse(JSON.stringify(objData));
          that.optData = objData[0].data;
        }
      },
      onTabs(objIndex) {
        let that = this;
        that.currentTabs = objIndex;
        if(!regNullArray(that.optAllData[objIndex].data)){
          that.optData = that.optAllData[objIndex].data;
        }
      },
      onStopTime(){
        clearInterval(this.tabTtimer);
      },
    },
    filters: {
      /**
       * @description 过滤处理分数
       * @param {Object} obj
       */
      flScore(obj) {
        return obj != 0 ? setTwoDecimal(obj, 2) : '0.00';
      }
    }
  }
</script>

<style lang="less" scoped>
  .divNullData {
    padding-top: 20%;
  }

  .rightB {
    border-right: 1px dotted #2e6099;
  }

  .divItemBox {
    display: flex;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 0px;

    .divList {
      width: 100%;
      margin-right: 10px;
      margin-left: 10px;

      .divListItem {
        display: flex;
        margin: 28px 0;

        .divIcon {
          height: 40px;
          width: 40px;

          .spRank {
            .iconImg {
              margin-top: 4px;
              width: 30px;

            }
          }

          .spRankOther {
            margin-top: 4px;
            background: url(../../assets/image/icon/no_other.png) no-repeat;
            display: block;
            height: 36px;
            background-size: 100%;
            width: 30px;
            text-align: center;
            line-height: 32px;
            font-size: 12px;
            //<img src="@" class="iconImg">
          }

          // span{
          //   margin-top: 5px;
          //   height: 35px;
          //   width: 35px;
          //   display: block;
          //   text-align: center;
          //   line-height: 35px;
          //   border-radius: 10px;
          //   background-color: #40faee;
          //   color: #2e6099;
          // }
        }

        .divinfo {
          flex: 1;
          margin-left: 10px;
        }

        .divTotal {
          padding-right: 10px;
          padding-top: 5px;

          span {
            font-size: 26px;
            color: #00c0ff;
            font-weight: bolder;
          }
        }
      }
    }
  }

  .dvBox {
    padding: 10px;
  }

  .divTabs {
    display: flex;
    padding-top: 30px;
    padding-left: 10px;
    padding-right: 10px;

    span {
      font-size: 18px;
      width: 100%;
      text-align: center;
      color: #95cef9;
      cursor: pointer;
    }

    .spAct {
      color: #FFF;
      font-weight: bolder;
    }
  }

  .bottom-right-table-3 {
    width: 100%;
    height: 100%;
  }
</style>
