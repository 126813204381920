<!--

-->
<template>
  <div class="SubWebPage DetailsPro" v-loading.fullscreen.lock="fullscreenLoading">

    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          社团详情
        </h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-3 text-center">
            <el-carousel height="150px" class="elCarouselBox">
              <el-carousel-item v-for="(item,index) in infoData.imageList" :key="index">
                <img :src="item" alt="" height="100%">
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="col-lg-8">
            <div class="infoList clearfix">
              <span class="spName">{{infoData.name}}</span>
            </div>
            <div class="infoList clearfix">
              <span class="spFonts">
                <span class="spLabel">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#fax-user"></use>
                  </svg>
                  &nbsp;
                  {{infoData.managerTitle}}
                </span>
                {{infoData.managerName}}
              </span>
            </div>
            <div class="infoList clearfix">
              <span class="spFonts">
                <span class="spLabel">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#fax-cube-users-alt"></use>
                  </svg>
                  &nbsp;
                  管理人员
                </span>
                &nbsp;
                <span class="spNum">{{infoData.managerNum}}</span>
              </span>
              <span class="spFonts">
                <span class="spLabel">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#fax-user-crowd-alt"></use>
                  </svg>
                  &nbsp;
                  普通成员
                </span>
                &nbsp;
                <span class="spNum">{{infoData.ordinaryNum}}</span>
              </span>
              <span class="spFonts">
                <span class="spLabel">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#fax-child-alt"></use>
                  </svg>
                  &nbsp;
                  已举办活动
                </span>
                &nbsp;
                <span class="spNum">{{activityData.length}}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </el-card>



    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          社团简介
        </h3>
      </div>
      <div class="card-body ">
        <div class="colRemarksCont" v-html="onGetRemarks()">
        </div>
      </div>
    </el-card>

    <el-card class="box-card" v-if="activityData!=null && activityData.length!=0">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          最新社团活动
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <el-button size="mini" type="info" icon="el-icon-d-arrow-right" @click="onGetMore">浏览更多活动</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body bodyAwardsCont ">
        <el-row :gutter="20">
          <el-col :span="6" v-for="(item,index) in activityData" :key="index">
            <div class="stuAwardsCont" @click="onDetailActivities(item.activityId)">
              <div class="awardsName">
                {{item.name}}
              </div>
              <div class="stuFileCont VerticalMid">
                <el-image :src="onGetAttachment(item.attachment)">
                  <div slot="error" class="image-slot errorBox">
                    <div class="spIcon">
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#fax-image-split"></use>
                      </svg>
                    </div>
                    <div class="spError">
                      加载失败
                    </div>
                  </div>
                </el-image>
                <div class="divTimeBox">
                  {{onDateFormat(item.activityDate)}}
                </div>
              </div>

            </div>
          </el-col>
        </el-row>
      </div>
    </el-card>


    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          社团成员
        </h3>
      </div>
      <div class="card-body p-0">
        <el-table :data="infoData.member" stripe class="elTableData">
          <el-table-column type="index" label="序号" width="100"></el-table-column>
          <el-table-column prop="studentName" label="姓名" min-width="120"></el-table-column>
          <el-table-column prop="studentNo" label="学号" min-width="100"></el-table-column>
          <el-table-column prop="className" label="班级" min-width="120"></el-table-column>
          <el-table-column label="职位" min-width="100">
            <template slot-scope="scope">
              <span class="spPosition" :class="{'color-danger':scope.row.isManager}">
                {{scope.row.position}}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180" fixed="right">
            <template slot-scope="scope">
              <operationBtn :btnName="'调职'" :btnType="'warning'"
                @click.native="onTransfer(scope.row.memberId,scope.row.studentId,scope.row.position)"></operationBtn>
              <operationBtn :btnName="'移除'" :btnType="'danger'"
                @click.native="onDelete(scope.row.clubId,scope.row.memberId)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>



    <!--文档弹出框-->
    <el-dialog title="社团务职调整" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <el-form class="ruleForm">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="当前职务">
              <div class="infoFontBox">
                {{clubPositionForm.positionName}}
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="职位">
              <el-select v-model="clubPositionForm.positionId" placeholder="请选择" class="formControl">
                <el-option v-for="item in arrClubPosition" :key="item.positionId" :label="item.name"
                  :value="item.positionId">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">

        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSaveTransfer()">确 定</el-button>

      </span>
    </el-dialog>


    <div class="row mt-10">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
      </div>
    </div>



  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import operationBtn from '@/components/operationBtn.vue';
  import comPage from '@/components/Page.vue';
  export default {
    components: {
      operationBtn
    },
    data() {
      return {
        // 加载动画
        fullscreenLoading: false,

        dialogVisible: false,

        // 社团活动列表
        activityData: [],




        // 社团信息对象
        infoData: {
          clubId: '',
          imageList: [],
          managerNum: 0, // 管理人数
          ordinaryNum: 0, // 普通人数
          managerName: '', // 最高负责人
          managerTitle: '', // 最高负责的职位
        },
        arrClubPosition: [],
        clubPositionName: '',
        clubPositionForm: {
          memberId: '',
          studentId: '',
          positionId: '',
          positionName: '',
          clubId: '',
        },
        clubPositionReset: null,
      };
    },
    methods: {
      onDetailActivities(_activityId) {
        let _this = this;
        _this.$router.push({
          path: '/Admin/Club/ClubActivities/Details/' + _activityId,
          query: {
            url: _this.$router.history.current.fullPath
          }
        });
      },

      onDateFormat(_val) {
        return dateFormat(_val, '.', 'YYYY-MM-DD');
      },
      onGetAttachment(_val) {
        let _this = this;
        if (regNull(_val)) {
          return null;
        } else {
          let attachments = _val.split('|');
          for (let item of attachments) {
            return _this.$ServerUrl() + item;
          }
        }
      },

      onGetMore() {
        let _this = this;
        _this.$router.push({
          path: '/Admin/Club/MyClubActivities/Index/' + _this.infoData.clubId,
          query: {
            url: _this.$router.history.current.fullPath
          }
        });
      },
      onBack() {
        back(this);
      },

      onSaveTransfer() {

        let _this = this;
        _this.fullscreenLoading = true;
        ajaxPut(_this, '/api/admin/club/member/' + _this.clubPositionForm.memberId, _this.clubPositionForm, function(
          res) {
          _this.fullscreenLoading = false;
          _this.dialogVisible = false;
          _this.clubPositionForm = JSON.parse(JSON.stringify(_this.clubPositionReset));
          onGetInfo(_this);
        });
      },




      onTransfer(_memberId, _studentId, _name) {
        let _this = this;
        _this.clubPositionForm.memberId = _memberId;
        _this.clubPositionForm.studentId = _studentId;
        _this.clubPositionForm.positionName = _name;
        _this.dialogVisible = true;
      },

      onDelete(_clubId, _studentId) {
        let _this = this;
        confirmDelete(_this, null, function(res) {
          if (res == true) {
            _this.fullscreenLoading = true;
            let data = [];
            data.push(_studentId);
            ajaxPut(_this, "/api/admin/club/quitclub/" + _clubId, data, function(r) {
              _this.fullscreenLoading = false;
              onGetInfo(_this);
            });
          }
        })
      },

      /**
       * @description 显示社团简介
       * @return {String} 如没有数据返回”暂无数据”
       */
      onGetRemarks() {
        let _this = this;
        if (regNull(_this.infoData.description)) {
          return '暂无数据';
        } else {
          return _this.infoData.description;
        }
      },

      // 弹出窗关闭
      handleClose(done) {
        let _this = this;
        _this.clubPositionForm = JSON.parse(JSON.stringify(_this.clubPositionReset));
        done();
      }
    },
    created() {
      let _this = this;
      let id = _this.$route.params.Id;
      if (id != null && typeof(id) != "undefined" && id != "") {
        _this.infoData.clubId = id;
        _this.clubPositionReset = JSON.parse(JSON.stringify(_this.clubPositionForm));
        onGetInfo(_this);
      } else {
        back(_this);
      }
    }
  };

  function onGetInfo(_this) {
    _this.fullscreenLoading = true;
    ajaxGet(_this, '/api/admin/club/' + _this.infoData.clubId, null, function(resData) {

      _this.fullscreenLoading = false;
      _this.infoData = resData;
      // 设置图片
      _this.infoData.imageList = [];
      if (!regNull(resData.attachment)) {
        let attachments = resData.attachment.split('|');
        for (let item of attachments) {
          _this.infoData.imageList.push(_this.$ServerUrl() + item);
        }
      }
      // 人员管理
      _this.infoData.managerNum = 0;
      _this.infoData.ordinaryNum = 0;
      for (let item of resData.member) {
        if (item.isManager) {
          _this.infoData.managerNum++;
        } else {
          _this.infoData.ordinaryNum++;
        }
        if (item.isDirector) {
          _this.infoData.managerName = item.studentName;
          _this.infoData.managerTitle = item.position;
        }
      }
      _this.clubPositionForm.clubId = resData.clubId;
      onGetActivity(_this);
      onGetPosition(_this);
    });
  }


  function onGetActivity(_this) {
    _this.fullscreenLoading = true;
    let _data = {
      ClubId: _this.infoData.clubId,
      PageNumer: 1,
      PageSize: 8,
    }
    ajaxGet(_this, '/api/admin/clubactivity/search', _data, function(resData) {
      _this.fullscreenLoading = false;
      _this.activityData = resData.items;
    });
  }

  function onGetPosition(_this) {
    _this.fullscreenLoading = true;
    ajaxGet(_this, '/api/admin/clubposition/enable', null, function(resData) {
      _this.fullscreenLoading = false;
      _this.arrClubPosition = resData;
    });
  }
</script>

<style scoped="scoped">
  .infoFontBox {
    display: block;
    margin-top: 40px;
    padding-left: 15px;
  }

  .elTableData .spPosition {
    color: #767676;
  }

  .elCarouselBox {
    border: 1px solid #EFEFEF;
  }

  .dialogBox {
    padding: 10px 5px;
    height: 140px;
    overflow: hidden;
  }

  .dialogVideoBox {
    padding: 10px 5px;
  }

  .dialogBox .mailbox-attachment-icon {
    font-size: 32px;
    padding: 15px 10px;
  }

  .dialogBox .mailbox-attachment-size>span {
    font-size: 12px;
  }

  .dialogBox .mailbox-attachment-info {
    padding: 7px 10px;
  }


  .bodyAwardsCont .stuAwardsCont {
    position: relative;
    padding: 0px 15px 45px 15px;
    height: 260px;
  }

  .bodyAwardsCont .stuAwardsCont:hover {
    cursor: pointer;
  }

  .bodyAwardsCont .stuAwardsCont:hover .awardsName {
    color: #000;
  }

  .bodyAwardsCont .stuAwardsCont:hover .stuFileCont {
    border-color: #D9ECFF;
  }

  .bodyAwardsCont .stuAwardsCont .awardFooter {
    text-align: center;
    padding-top: 5px;
  }

  .bodyAwardsCont .stuAwardsCont .awardFooter .divBtnBox {}

  .bodyAwardsCont .awardsName {
    padding-top: 10px;
    color: #666;
  }

  .bodyAwardsCont .stuFileCont {
    padding-top: 5px;
    border: 1px solid #EEEEEE;
    height: 150px;
    overflow: hidden;
    width: 100%;
    margin-top: 5px;
    position: relative;
  }

  .bodyAwardsCont .stuFileCont .divTimeBox {
    position: absolute;
    right: 0;
    bottom: 0;
    background: url(../../../../assets/image/bg_50_opacity.png) repeat;
    color: #FFFFFF;
    font-size: 12px;
    padding: 2px 5px;
  }





  .bodyRecordCont .stuRecordCont {
    position: relative;
    padding: 0px 15px 45px 15px;
    padding-left: 75px;

  }

  .bodyRecordCont .stuRecordCont .stuAvatar {
    position: absolute;
    left: 15px;
    top: 0px;
    border: 1px solid #eee;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 5px;
  }


  .bodyRecordCont .stuRecordCont .stuName {
    font-size: 16px;
  }

  .bodyRecordCont .stuRecordFont {
    font-size: 12px;
    padding-top: 5px;
    color: #767676;
  }

  .bodyRecordCont .stuRecordLabel {
    font-size: 12px;
    padding-top: 5px;
    color: #B1B1B1;
  }

  .bodyRecordCont .stuFileCont {
    padding-top: 10px;
  }

  .bodyRecordCont .stuFileCont .divFileIcon {
    border: 1px solid #eee;
    width: 100%;
    height: 80px;
    overflow: hidden;
    border-radius: 4px;
  }

  .bodyRecordCont .stuFileCont .divFileIcon .elIcon {
    font-size: 32px;
  }





  .colRecordOther {}

  .colRecordOther .divList {
    float: left;
    width: 33.3%;
  }

  .colRecordCont {
    padding: 5px;
    font-size: 12px;
    color: #767676;
  }

  .divVideoCont {
    overflow-y: auto;
    width: 100%;
    height: 500px;
    padding-right: 10px;
  }

  .divVideoCont ul li .aItem {
    display: block;
    padding: 7px 0px;
    padding-left: 30px;
    border-bottom: 1px dotted #DDDDDD;
    position: relative;
  }

  .divVideoCont ul li:last-child .aItem {
    border-bottom: none;
  }

  .divVideoCont ul li:hover {
    background-color: #5AAAFF;
    color: #FFFFFF;
  }

  .divVideoCont ul li .aItem span {
    font-family: 'OswaldLight';
    font-size: 14px;
    margin-left: 10px;
  }

  .divVideoCont ul li .aItem .faxIcon {
    position: absolute;
    top: 11px;
    left: 5px;
    display: none;
  }

  .divVideoCont ul li .aItemAct {
    background-color: #007BFF;
    color: #FFFFFF;
    border-bottom: none;
  }

  .divVideoCont ul li .aItemAct .faxIcon {
    display: block;
  }


  .bodyImgCont .mailbox-attachment-icon {
    min-height: 165px;
  }

  .bodyFileCont .mailbox-attachments li,
  .bodyImgCont .mailbox-attachments li {
    width: 250px;
  }

  .bodyImgCont .mailbox-attachment-icon {
    height: 198px;
  }

  .bodyFileCont .mailbox-attachment-size .spFont,
  .bodyImgCont .mailbox-attachment-size .spFont {
    font-size: 12px;
  }





  .tabListCont .divNavTitle {}

  .tabListCont .divNavTitle {}

  .tabListCont .divNavTitle .divTitleCont {
    padding: 10px 0px 10px 10px;
    color: #DEE2E6;
    cursor: pointer;
    font-size: 18px;
  }

  .tabListCont .divNavTitle .divTitleCont .spNum {
    background-color: #DEE2E6;
    font-family: 'OswaldLight';
    padding: 2px 5px;
    border-radius: 4px;
    margin-left: 5px;
    font-size: 14px;
    color: #FFFFFF;
  }

  .tabListCont .divNavTitle .divTitleAct {
    color: #3e3e3e;
  }

  .tabListCont .divNavTitle .divTitleAct .spNum {
    background-color: #17A2B8;
    color: #FFFFFF;
  }

  .tabListCont .divNavTitle .nav-link {
    font-size: 14px;
    color: #B1B1B1;
  }

  .tabListCont .divNavTitle .nav-link .spNum {
    font-family: 'OswaldLight';
    font-weight: normal;
    font-size: 14px;
    padding-left: 10px;
  }

  .tabListCont .divNavTitle .active {
    color: #007bff;
  }




  .DetailsPro .divRoleTitle {
    font-size: 18px;
    color: #323E56;
    padding-top: 20px;
  }

  .DetailsPro .divRoleTitle .spItem {
    float: right;
    margin-left: 5px;
    font-size: 14px;
    color: #28A745;
  }

  .DetailsPro .divRoleTitle .spItem em {
    font-style: normal;
    background-color: #28A745;
    color: #FFFFFF;
    margin-left: 5px;
    margin-right: 5px;
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 12px;
    font-family: 'OswaldLight';
  }

  .DetailsPro .divRoleTitle .el-button {
    padding: 0;
    border: none;
  }

  .DetailsPro .divRoleTitle .el-button:hover {
    background-color: transparent;
  }





  .stuInfo {
    position: relative;
    height: 100%;
    min-height: 80px;
    padding-left: 70px;
    margin: 15px;
  }

  .stuInfo .stuFraction {
    position: absolute;
    top: -5px;
    bottom: 0;
    right: -5px;
    font-family: 'OswaldLight';
    font-size: 26px;
    color: #28A745;
  }

  .stuInfo .stuBtnDown {
    position: absolute;
    bottom: 20px;
    right: 0;
  }

  .stuInfo .stuBtnDown .aView {
    margin-right: 5px;
    margin-left: 5px;
  }

  .stuInfo .stuBtnDown .aView .faxSize {
    font-size: 12px;
    color: #343a40;
  }

  .stuInfo .stuBtnDown .aView:hover .faxSize {
    color: #1666B3;
  }

  .stuInfo .stuImgCont {
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 80px;
    border-radius: 4px;
    overflow: hidden;
  }

  .stuInfo .stuImgCont img {
    width: 100%;
  }

  .stuInfo .stuName {
    color: #212E48;
    font-size: 18px;
    font-weight: bold;
  }

  .stuInfo .stuRole {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
  }

  .stuRole .spStatus {
    font-size: 12px;
    color: #B1B1B1;
  }

  .stuInfo .stuOther {
    color: #767676;
    font-size: 12px;
  }


  .cardTabs .abtnTabs {
    font-size: 16px;
    color: #B1B1B1;
    margin-left: 15px;
    position: relative;
    display: block;

    float: left;
    text-align: center;
  }

  .cardTabs .spDivide {
    float: left;
    padding-left: 15px;
  }


  .cardTabs .abtnTabs:hover {
    color: #323E56;
  }

  .cardTabs .abtnTabs .spFaxIcon {
    display: none;
  }

  .cardTabs .abtnTabs:first-child {
    margin-left: 0;
  }

  .cardTabs .abtnTabs .spNumber {
    background-color: #DEE2E6;
    font-size: 12px;
    color: #FFFFFF;
    font-family: 'OswaldLight';

    padding: 1px 5px;
    border-radius: 5px;
  }



  .cardTabs .abtnTabAct {
    font-size: 1.1rem;
    color: #323E56;
  }

  .cardTabs .abtnTabAct .spFaxIcon {
    display: block;
    position: absolute;
    top: 22px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 12px;
  }

  .cardTabs .abtnTabAct .spNumber {
    background-color: #1666B3;
    color: #FFFFFF;
    font-size: 14px;
  }



  .infoList {
    padding-bottom: 17px;
    padding-left: 30px;
  }

  .infoList .spName {
    font-size: 32px;
    color: #212E48;
    padding-right: 30px;
    height: 40px;
  }


  .infoList .spFonts {
    color: #3B475D;
    padding-right: 30px;
    padding-left: 30px;
    border-right: 1px solid #D6D6D6;
  }

  .infoList .spFonts:first-child {
    padding-left: 0;
  }

  .infoList .spFonts:last-child {
    border-right: none;
  }

  .infoList .spMedal {
    padding-right: 15px;
  }

  .infoList .spFonts .faxSize {
    font-size: 18px;
  }

  .infoList .spFonts .icon {
    color: #FFFFFF;
  }

  .infoList .spStatus {
    border: 1px solid #007BFF;
    background-color: #007BFF;
    color: #FFFFFF;
    padding: 5px 20px;
    border-radius: 5px;

  }

  .infoList .spNStatus {
    border: 1px solid #007BFF;
    background-color: #007BFF;
    color: #FFFFFF;
    padding: 5px 20px;
    border-radius: 5px;
  }

  .infoList .spFonts .spLabel {
    font-size: 14px;
    padding: 2px 5px;
    border-radius: 5px;
    background-color: #B1B1B1;
    color: #FFFFFF;
    margin-right: 10px;
  }

  .infoList .spFonts .spNum {
    padding-left: 10px;
    padding-right: 5px;
    font-family: 'OswaldLight';
    font-size: 16px;
    font-weight: bold;
    color: #DC3545;
  }

  .DetailsPro .colRemarksCont {
    font-size: 14px;
    letter-spacing: 1px;
  }
</style>
