<!--
  学期成绩汇总
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="width:100%;padding-left: 0px;"
          v-if="SearchTitles!=null && SearchTitles.length>0">
          <ul class="nav nav-pills navTabsCont">
            <li class="nav-item" v-for="(p,index) in SearchTitles" :key="index">
              <router-link :to="p.url" :class="p.isCurrent==true?'nav-link active':'nav-link'">
                {{p.name}}
              </router-link>
            </li>
          </ul>
        </h3>
      </div>
      <div class="card-body">
        <el-row :gutter="24">
          <el-col :span="8" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">学期</span>
            </div>
            <div class="selBodyBox">
              <el-select v-model="searcForm.SemesterId" style="width: 100%;" placeholder="请选择"
                @change="onChangeSemester">
                <el-option v-for="(item,index) in searchOptions.semesterList" :key="index" :label="item.Title"
                  :value="item.Value">
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="8" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">班级</span>
            </div>
            <div class="selBodyBox">
              <el-select v-model="searcForm.ClassId" :clearable="!isTeacher" style="width: 100%;" placeholder="请选择"
                @change="handleChangeClass" filterable>
                <el-option v-for="(item,index) in searchOptions.classList" :key="index" :label="item.alias"
                  :value="item.Value">
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="8" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">课程</span>
            </div>
            <div class="selBodyBox">
              <el-select v-model="searcForm.CourseIds" collapse-tags clearable multiple style="width: 100%;"
                placeholder="请选择" filterable>
                <el-option v-for="(item,index) in searchOptions.courseList" :key="index" :label="item.name"
                  :value="item.courseId">
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="8" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">成绩类型</span>
            </div>
            <div class="selBodyBox">
              <el-select v-model="searcForm.Type" style="width: 100%;" placeholder="请选择" filterable>
                <el-option v-for="(item,index) in searchOptions.typeList" :key="index" :label="item.Title"
                  :value="item.Value">
                </el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="card-footer text-center">
        <el-button type="primary" icon="el-icon-search" @click.native="onSearch()">查询</el-button>
        <el-button icon="el-icon-refresh" @click.native="onResetSearch()">重置</el-button>
      </div>
    </el-card>




    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          学期成绩汇总
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li v-if="isPower.course_export" class="nav-item" style="margin-right: 5px;">
              <el-button @click="onOpenExport()" icon="el-icon-download"
                style="background-color:#17a2b8	;color: white;">
                导出
              </el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData.tdData!=null && itemData.tdData.length>0">
        <el-table :data="itemData.tdData" stripe>
          <!--class="elTableData"!-->
          <el-table-column type="index" fixed label="序号" width="90px"></el-table-column>
          <el-table-column prop="studentNo" fixed label="学号" class="elColumn"></el-table-column>
          <el-table-column prop="studentName" fixed label="姓名" class="elColumn"></el-table-column>
          <el-table-column v-for="(item,count) in itemData.thData" :label="item" :key="count" class="elColumn">
            <template slot-scope="scope">
              <span class="color-gray" v-if="scope.row.scores[count]==''||scope.row.scores[count]==null">暂无数据</span>
              <span v-else>{{scope.row.scores[count]}}</span>
            </template>
          </el-table-column>
          <comPage :isPage="false" :paging="paging"></comPage>
        </el-table>
      </div>
      <div class="card-body p-0" v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>

  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import qs from 'qs';
  import comPage from '@/components/Page.vue';
  export default {
    components: {
      comPage
    },
    data() {
      return {
        isTeacher: false,
        SearchTitles: [],
        currentYear: null,
        searchOptions: {
          semesterList: [],
          classList: [],
          courseList: [],
          typeList: []
        },
        searcForm: {
          SemesterId: null,
          ClassId: null,
          CourseIds: [],
          Type: null
        },
        fullscreenLoading: false,
        // 搜索数据集
        SearchList: null,

        itemData: {
          thData: null,
          tdData: null,
        },
        paging: {
          totalCount: 0,
        },

        arrSearch: {
          semesterId: '',
          classId: '',
          type: '',
        },
        isPower: {
          //增删改查 权限管理
          academicrecord_index: false,
          academicrecord_class_index: false,
          course_export: false,
        },
      };
    },
    methods: {
      /**
       * @description 重置搜索
       */
      onResetSearch() {
        let that = this
        that.searcForm.SemesterId = that.currentYear;
        if (that.isTeacher) {
          that.searcForm.ClassId = that.searchOptions.classList[0].Value;
        } else {
          that.searcForm.ClassId = null;
        }
        that.searcForm.Type = that.searchOptions.typeList[0].Value;
        that.searcForm.CourseIds = [];
        that.handleChangeClass();
        onGetData(that);
      },
      /**
       * @description 学期选择事件
       * @param {Object} val
       */
      onChangeSemester(val) {
        this.handleChangeClass();
      },
      /**
       * @description 班级选择事件
       */
      handleChangeClass() {
        let that = this;
        let _data = {
          classId: that.searcForm.ClassId,
          semesterId: that.searcForm.SemesterId,
        }
        let url = `/api/admin/academicrecord/course/${_data.semesterId}/${_data.classId}`;
        if (!that.searcForm.ClassId) {
          url = `/api/admin/academiccourse/course/${_data.semesterId}`;
        }
        that.searcForm.CourseIds = null;
        ajaxGet(that, url, null, function(resData) {
          that.searchOptions.courseList = resData;
        });
      },
      /**
       * @description 导出
       */
      onOpenExport() {
        let that = this;
        const { SemesterId, ClassId, CourseId } = that.searcForm
        let data = {
          SemesterId: SemesterId,
          ClassId: ClassId,
        }
        that.fullscreenLoading = true;
        ajaxExport(that, '/api/admin/academicrecord/export', data, "学生学期成绩.xls", function(res) {
          that.fullscreenLoading = false;
        });
      },
      /**
       * @description 搜索
       */
      onSearch() {
        let that = this
        onGetData(that);
      },
    },
    created() {
      let that = this;
      isPower(that, "academicrecord_index", "academicrecord_index");
      isPower(that, "academicrecord_class_index", "academicrecord_class_index");
      isPower(that, "course_export", "course_export");

      that.SearchTitles = [];
      if (that.isPower.academicrecord_class_index == true) {
        that.SearchTitles.push({
          name: '课程成绩',
          isCurrent: false,
          url: '/Admin/Score/Adviser'
        });
      }
      if (that.isPower.academicrecord_index == true) {
        that.SearchTitles.push({
          name: '学期成绩汇总',
          isCurrent: true,
          url: '/Admin/Score/Course'
        });
      }

      // 获取用户信息
      let tpUserInfo = onGetNowUser(that);

      that.isTeacher = tpUserInfo.isTeacher;
      ajaxGet(that, "/api/admin/semester", null, function(resSemester) {
        ajaxGet(that, "/api/admin/academicrecord/type", null, function(resType) {
          // 学期
          if (!regNullArray(resSemester)) {
            let SemesterId = that.searcForm.SemesterId;
            // 获得学期数据
            for (let item of resSemester) {
              let arrTemp = {
                Title: item.name, //semester[item].name,
                Value: item.semesterId //semester[item].semesterId,
              };
              // 默认学期显示当前学期
              if (SemesterId == null || typeof(SemesterId) == "undefined" || SemesterId == "") {
                if (item.isCurrent == true) {
                  that.currentYear = item.semesterId
                  that.searcForm.SemesterId = item.semesterId;
                }
              }
              that.searchOptions.semesterList.push(arrTemp);
            };

            let resClass = tpUserInfo.isTeacher ? tpUserInfo.manageClass : tpUserInfo.allClass;
            if (!regNullArray(resClass)) {
              let ClassId = that.searcForm.ClassId;
              // 获得班级数据
              for (let item of resClass) {
                let arrTemp = {
                  Title: item.name,
                  Value: item.classId,
                  alias:item.alias,
                };
                that.searchOptions.classList.push(arrTemp);
              }
              if (ClassId == null || typeof(ClassId) == "undefined" || ClassId == "") {
                if (that.searchOptions.classList.length != 0) {
                  that.searcForm.ClassId = that.searchOptions.classList[0].Value;
                }
              }
            }

            // 成绩类型
            if (!regNullArray(resType)) {
              let typeId = that.searcForm.Type;
              for (let item of resType) {
                let arrTemp = {
                  Title: item.Text,
                  Value: item.Value,
                }
                that.searchOptions.typeList.push(arrTemp);
              }
              if (typeId == null || typeof(typeId) == "undefined" || typeId == "") {
                if (that.searchOptions.typeList.length != 0) {
                  that.searcForm.Type = that.searchOptions.typeList[0].Value
                }
              }
            }
            onGetData(that, true);
          }
        });
      });


    },
    watch: {
      $route() {
        let that = this;
        onGetData(that);
      }
    },
  };


  ///加载列表数据
  // 获取数据
  function onGetData(that, isInit) {
    that.fullscreenLoading = true;
    if (that.$route.query.url == '/admin/Preparations/index') {
      let { ClassId } = that.$route.query
      if (ClassId) {
        that.searcForm.ClassId = that.$route.query.ClassId
        that.searcForm.Type = that.$route.query.TypeId
      }
    }
    let getData = {
      semesterId: that.searcForm.SemesterId,
      classId: that.searcForm.ClassId,
      courseIds: that.searcForm.CourseIds || [],
      type: that.searcForm.Type ? parseInt(that.searcForm.Type) : parseInt(that.searchOptions.typeList[0].Value)
    };
    if (isInit) {
      that.handleChangeClass()
    }
    if (!getData.classId || !getData.semesterId) {
      that.fullscreenLoading = false
      that.itemData.tdData = [];
      that.itemData.thData = [];
      return
    }
    ajaxPostToGet(that, `/api/admin/academicrecord/class`, getData, function(resData) {
      that.itemData.tdData = resData.items;
      that.itemData.thData = resData.titles;
      if (resData.items.length != null) {
        that.paging.totalCount = resData.items.length;
      }
      if (that.$route.query.url == '/admin/Preparations/index') {
        that.$router.push({ path: '/Admin/Score/Course' })
      }
      that.fullscreenLoading = false;
    });
  }

</script>

<style scoped="scoped">
  .navTabsCont .nav-link {
    font-size: 12px;
  }

  /*重置样式*/
  .table tbody td,
  .table thead th {
    font-size: 0.875rem;
    /*14*/
  }

  .table tr td,
  .table tr th {
    text-align: center;
  }

  .table tr th:nth-child(1),
  .table tr th:nth-child(2),
  .table tr td:nth-child(1),
  .table tr td:nth-child(2) {
    text-align: left;
  }

  .selTitleBox {
    font-size: 14px;
  }

  .selTitleBox .spTitle {
    padding-bottom: 5px;
    display: block;
    color: #303133;
  }

</style>

