<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          班主任学期考核加减分项{{isAudit ? '审核' : ''}}
        </h3>
      </div>
      <div class="card-body" v-if="ruleForm!=null">
        <div class="row">
          <div class="col-lg-12 colInfo">
            <label>加减考核项名称</label>
            <div class="colFonts">
              {{ ruleForm.reason | flNoValue}}
            </div>
          </div>
          <div class="col-lg-3 colInfo">
            <label>分数</label>
            <div class="colFonts">
              {{ruleForm.score | flNoValue}}
            </div>
          </div>
          <div class="col-lg-3 colInfo" v-if="!isAudit">
            <label>实际分值</label>
            <div class="colFonts">
              {{ruleForm.actualScore | flNoValue}}
            </div>
          </div>
          <div class="col-lg-3 colInfo">
            <label>班主任</label>
            <div class="colFonts">
              {{ruleForm.teacher | flNoValue}}
            </div>
          </div>
          <div class="col-lg-3 colInfo">
            <label>班级</label>
            <div class="colFonts">
              {{ruleForm.class | flNoValue}}
            </div>
          </div>
          <div class="col-lg-3 colInfo">
            <label>学期</label>
            <div class="colFonts">
              {{ruleForm.semester | flNoValue}}
            </div>
          </div>
          <div class="col-lg-3 colInfo">
            <label>记录人</label>
            <div class="colFonts">
              {{ruleForm.user | flNoValue}}
            </div>
          </div>
          <div class="col-lg-3 colInfo">
            <label>审核状态</label>
            <div class="colFonts">
              {{ruleForm.auditStatusStr | flNoValue}}
            </div>
          </div>
          <div class="col-lg-3 colInfo">
            <label>创建时间</label>
            <div class="colFonts">
              {{ruleForm.createDate | dateformatMinute}}
            </div>
          </div>
          <div class="col-lg-12 colInfo">
            <label>备注</label>
            <div class="colFonts">
              {{ruleForm.remark | flNoValue}}
            </div>
          </div>
          <div class="col-lg-12 colInfo" v-if="!isAudit">
            <label>意见</label>
            <div class="colFonts">
              {{ruleForm.description | flNoValue}}
            </div>
          </div>
        <div class="col-lg-4 colInfo" v-if="isAudit">
          <label class="myReason">实际分值</label>
          <div class="myReason">
              <el-input v-model="auditRuleForm.actualScore" placeholder="请输入实际分值"></el-input>
          </div>
        </div>
        <div class="col-lg-12 colInfo" v-if="isAudit">
          <label class="myReason">意见</label>
          <el-input
            class="myReason"
            type="textarea"
            :rows="2"
            :autosize="autosize"
            placeholder="请输入内容"
            v-model="auditRuleForm.description">
          </el-input>
        </div>
        </div>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>





    <div class="row">
      <div class="col-12" v-if="!isAudit">
        <el-button icon="el-icon-back" @click="onBack">返回</el-button>
      </div>
      <div class="col-12" v-else>
          <el-button icon="el-icon-back" @click="onBack">返回</el-button>
          <el-button icon="el-icon-check" @click="onPassAudit(1)" type="success">审核通过</el-button>
          <el-button icon="el-icon-close" @click="onPassAudit(2)" type="danger">拒绝</el-button>
      </div>
    </div>


  </div>
</template>

<script>
  import "@/assets/css/Admin.css";

  export default {
    components: {
    },
    data() {
      return {
        autosize:{
          minRows:8,
          maxRows:40
        },
        isAudit:false,
        auditRuleForm:{
          actualScore:null,//实际分值
          description:null,//描述
        },
        fullscreenLoading: false,
        projectId:null,
        ruleForm: {},
      };
    },
    filters: {
      flNoValue(obj) {
        if (obj == null || typeof obj == "undefined" || obj == "") {
          return "暂无数据";
        }
        return obj;
      },
    },
    created() {
      let that = this;
      that.isAudit = that.$route.query.isAudit
       if (!regNull(that.$route.params.Id)) {
         that.projectId = that.$route.params.Id
         that.fullscreenLoading=true;
         ajaxGet(that,'/api/admin/advisersemesterproject/'+that.$route.params.Id,null,function(resData){
           that.fullscreenLoading=false;
           that.auditRuleForm.actualScore = resData.score
           that.ruleForm=resData;
         });
       }
    },
    methods: {
      /**
       * @description 返回
       */
      onBack() {
        let that = this;
        back(that);
      },
      onPassAudit(status){
        let that = this
        let reg = /^(\-|\+)?\d+(\.\d+)?$/
        if(status!=2 && !(that.auditRuleForm.actualScore && reg.test(that.auditRuleForm.actualScore))){
          warning(that,'分值只能为(正数,负数,小数)')
          return
        }
        that.auditRuleForm.auditStatus = status
        that.auditRuleForm.projectId = that.projectId
        that.fullscreenLoading = true
        ajaxPut(that,`/api/admin/advisersemesterproject/audit/${that.projectId}`,that.auditRuleForm,function(res){
          that.fullscreenLoading = false
          back(that);
        })
      },
    },
  };
</script>

<style scoped="scoped">
  .divSubTitle{
    background-color: #409EFF;
    width: 300px;
    padding: 5px 10px;
    display: flex;
    color: #FFFFFF;
    margin-top: 30px;
    border-radius: 5px;
  }
  .divSubTitle:first-child{
    margin-top: 0px;
  }
  .divSubTitle .spTitle{
    flex: 1;
  }
  .divSubTitle .spFont{

  }
  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }

  .spIcon {
    width: 5px;
    height: 20px;
    border-radius: 5px;
    background-image: linear-gradient(#4545ff, #2d8eff);
  }

</style>
