<!--
  学生成绩录入
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">

    <el-form class="ruleForm">
      <el-card class="card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            查看
          </h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-3 colInfo">
              <label>学期</label>
              <div class="colFonts">{{itemData.semester}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>班级</label>
              <div class="colFonts">{{itemData.className}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>课程</label>
              <div class="colFonts">{{itemData.courseName}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>学生</label>
              <div class="colFonts">{{itemData.studentName}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>平时</label>
              <div class="colFonts">{{itemData.peaceTime}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>期中</label>
              <div class="colFonts">{{itemData.midTerm}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>期末</label>
              <div class="colFonts">{{itemData.final}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>总评</label>
              <div class="colFonts">{{itemData.total}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>绩点</label>
              <div class="colFonts">{{itemData.gpa}}</div>
            </div>
            <div class="col-lg-3 colInfo">
              <label>学分</label>
              <div class="colFonts">{{itemData.credit}}</div>
            </div>
          </div>
        </div>
      </el-card>
    </el-form>

    
    <div class="row" style="margin-top: 10px;">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
      </div>
    </div>




  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  //import qs from 'qs';
  export default {
    name: '',
    components: {

    },
    data() {
      return {
        fullscreenLoading: false,
        // 学生数据
        itemData: [],
      };
    },
    methods: {
      onBack()
      {
        let _this=this;
        back(_this);
      }

    },
    created()
    {
      let _this = this;
      _this.fullscreenLoading=true;
      let id = _this.$route.params.id;

      ajaxGet(_this,"/api/admin/academicrecord/"+id,null,function(r){
        _this.fullscreenLoading=false;
        _this.itemData=r;

      })
    },

  };


</script>

<style scoped="scoped">
  .inGradesCont .itemList {
    padding-bottom: 15px;
  }

  .elTableData .el-form-item {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }
</style>
<style>
  .inGradesCont .el-input-group__prepend {
    font-size: 14px;
  }
</style>
